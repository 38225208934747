<div class="app-prelevement-zone" *ngIf="isLoaded">
    <besoins-pu-panel
        class="besoins-pu-panel"
        [zone]="selectedZone"
        [volumes]="volumes"
        [besoinsPrelevement]="besoins"
        [indexBesoinSelected]="indexBesoinSelected"
        [isInEditMode]="isInEditMode"
        [showActions]="showActions"
        (besoinActionEmitter)="besoinSelected($event)"
    ></besoins-pu-panel>
    <div class="content">
        <div class="verification-card-container">
            <verification-card
                *ngIf="form"
                [isInEditMode]="isInEditMode"
                [isValidated]="isValidated"
                (onCancel)="cancelModification()"
                (onSave)="saveModification()"
                (onValidate)="validateTab()"
                (onEdit)="startModification()"
                [form]="form"
            >
                Veuillez vérifier les données de zones
            </verification-card>
        </div>
        <div class="form">
            <app-zone-propriete-modal class="w-100" #zoneProprieteModalComponent></app-zone-propriete-modal>
        </div>
    </div>
</div>

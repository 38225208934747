"use strict";
import { cn_element } from "../model/cn_element";
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_roof_slopes  : Manipulation of roof slopes and heights
//***********************************************************************************
//***********************************************************************************

import {cn_roof_height} from "../model/cn_roof_height";
import { cnx_clone, cn_dist } from "../utils/cn_utilities";
import { cn_edition_handler } from "./cn_edition_handler";
import { cn_edit_box } from "./cn_edit_box";
import { cn_number_input } from "./cn_inputs";
import { cn_mouse_event } from "./cn_mouse_event";
import { cn_roof_height_handler } from "./cn_roof_height_handler";
import { cn_snap } from "./cn_snap";
import {cn_svg_tool_creation} from "./cn_svg_tool_creation";

export class cn_svg_tool_roof_slopes extends cn_svg_tool_creation {
	constructor(svg_map) {
		super(svg_map);

		this._svg = "";
		this._mouseover_height = null;
		this._mouseover_height_box = null;
		this._mouseover_height_drag = -1;

		this._dragged_slab = null;
		this._previous_slab_direction = null;

		this.ghost = null;
		this.element_filter = function(element) {return element.constructor == cn_roof_height;};
	}

	//***********************************************************************************
	//**** Reset all heights
	//***********************************************************************************
	reset_heights()
	{
		var obj = this;
		var scene = this._scene;
		this.push_transaction("Réinitialisation des hauteurs","",function() {scene.update();scene.update_deep();obj.call("roof_update");});

		for (var k in this._scene.heights)
		{
			var h= this._scene.heights[k];
			this.push_item_set(h,["neighbour_lock","locks"]);
			for (var i in h.slabs)
				h.locks[i] = false;
			h.neighbour_lock = true;
		}
		this._scene.update();
		this._scene.update_deep();
		this.call("roof_update");
	}

	parent_call(msg) {
		if (this._svg_parent)
			this._svg_parent.call(msg);
		else
			this.call(msg);
	}
	//***********************************************************************************
	//**** Set border heights
	//***********************************************************************************
	get_border_heights()
	{
		var v = false;
		for (var k in this._scene.heights)
		{
			var h= this._scene.heights[k];
			if (h.slab == null) continue;
			var vv = h.values[0];
			if (v == null || v >= vv)
				v = vv;
		}
		// @ts-ignore
		if (v === false) v = 0;
		return v + this._scene.storey.height;
	}

	set_border_heights(v)
	{
		var obj = this;
		var scene = this._scene;
		this.push_transaction("Hauteurs périphériques","",function() {scene.update();scene.update_deep();obj.call("roof_update");});

		var vv = v - this._scene.storey.height;
		for (var k in this._scene.heights)
		{
			var h= this._scene.heights[k];
			if (h.slab == null) continue;
			this.push_item_set(h,["locks","values"]);
			h.locks[0] = true;
			h.values[0] = vv;
		}
		this._scene.update();
		this._scene.update_deep();
		this.call("roof_update");
	}

	//***********************************************************************************
	//**** Enable new height mode
	//***********************************************************************************
	set_new_height_mode(value) {
		if (value)
		{
			this.ghost = cn_roof_height.new_custom(this._scene);
			new cn_roof_height([0,0],this._scene);
			this.ghost.custom = true;
			this.ghost["valid"] = false;
		}
		else
			this.ghost = null;
	}

	//***********************************************************************************
	//**** custom heights
	//***********************************************************************************
	custom_heights_selected() {
		return (this._selected_custom_height != null);
	}

	delete_custom_heights() {
		if (this._selected_custom_height)
		{
			var rh = this._selected_custom_height;
			var scene = this._scene;
			var obj = this;

			// @ts-ignore
			function delete_height() {
				var index = scene.heights.indexOf(rh);
				if (index >= 0)
					scene.heights.splice(index,1);
				else
					scene.heights.push(rh);
				scene.update();
				scene.update_deep();
				obj.call("roof_update");
			}

			this.push_transaction("Suppression de hauteur","",delete_height);
			this.push_item_set(scene,[]);
			delete_height();
			this._selected_custom_height = null;
		}
	}
	//***********************************************************************************
	//**** Draws  specific svg for the tool. Returns svg string
	//***********************************************************************************
	draw(camera) {
		var html = "";

		//*** draw slabs
		for (var i in this._scene.slabs)
		{
			var slab = this._scene.slabs[i];
			html += slab.draw_slope(camera,this._controller && this._controller.is_selected(slab));
		}

		//*** draw heights
		for (var i in this._scene.heights)
		{
			var height = this._scene.heights[i];

			var status = -1;
			if (height == this._mouseover_height)
				status = this._mouseover_height_box;

			const status_3d = (camera.is_3d() && this._mouseover_height == height)?this._mouseover_height_drag:-1;
			html += height.draw(camera,[],status, status_3d);
		}

		//*** draw ghost
		if (this.ghost)
			html += this.ghost.draw(camera,[],-1);
		html += this._svg;

		return html + super.draw(camera);
	}

	//***********************************************************************************
	//**** Mouse callbacks
	//***********************************************************************************
	clear_move() {
		this._mouseover_height = null;
		this._mouseover_height_drag = -1;
		this._mouseover_height_box = -1;
		if (this.ghost) this.ghost.visible = false;
		super.clear_move();
	}

	click(ev) {
		if (super.click(ev)) return true;
		var obj = this;

		//*** Create a custom height */
		if (this.ghost && this.ghost.visible)
		{
			this._create_height(ev);
			return true;
		}

		//*** If we clicked on a height widget : */
		if (this._mouseover_height && this._mouseover_height_box >= 0)
		{
			this._current_height = this._mouseover_height;
			this._current_height_slab = this._mouseover_height_box;

			//*** Maybe we clicked on the neighbour lock */
			if (this._mouseover_height_box == this._current_height.slabs.length)
			{
				var scene = this._scene;
				var obj = this;
				this.push_transaction("Lien entre hauteurs","",function() {scene.update();scene.update_deep();obj.parent_call("roof_update");});
				this.push_item_set(this._mouseover_height,["neighbour_lock","locks","values"]);

				this._mouseover_height.switch_neighbour_lock();
				this._scene.update();
				this._scene.update_deep();
				this.parent_call("roof_update");
			}
			//*** Or we clicked on the height value */
			else if (this._mouseover_height_box < this._mouseover_height.slabs.length)
			{
				this._height_clicked(this._mouseover_height,this._mouseover_height_box);
				console.log("after click 1");
			}
			return true;
		}

		return false;
	}

	grab (ev) {
		if (super.grab(ev)) return true;
		
		if (this._mouseover_height)
			return true;

		if (this.ghost && this.ghost.visible)
			return true;
			
		return false;
	}

	drop (ev) {
		this._svg = "";
		if (super.drop(ev)) return true;

		if (this.ghost && this.ghost.visible)
		{
			this._create_height(ev);
			return true;
		}

		return true;
	}

	move (ev) {
		if (super.move(ev)) return true;
		
		if (this.update_mouseover(ev)) return true;

		if (this.ghost)
		{
			this._update_ghost(ev);
			return true;
		}

		return true;
	}

	drag (ev) {
		this._svg = "";
		if (super.drag(ev)) return true;
		const obj = this;

		//*** Move height in 3D */
		if (ev.camera.is_3d() && this._mouseover_height && this._mouseover_height_drag >= 0)
		{
			this._drag_height(ev);
			return true;
		}

		//*** move new custom height */
		if (this.ghost && this.ghost.visible)
		{
			this._update_ghost(ev);
			return true;
		}

		this.clear_move();
		return false;
	}

	_update_ghost(ev) {
		this.ghost.position = ev.mouse_world;
		this.ghost.slab = this._scene.find_slab(ev.mouse_world);
		this.ghost.visible = (this.ghost.slab != null);
		this.ghost.update();
		return true;
	}

	_create_height(ev) {
		if (this.ghost && this.ghost.slab) 
		{
			var obj = this;
			var scene = this._scene;
			var rh = this.ghost;
			this.push_transaction("Hauteur personnalisée","",function() {
				var index = scene.heights.indexOf(rh);
				if (index >= 0)
					scene.heights.splice(index,1);
				else
					scene.heights.push(rh);
				scene.update();
				scene.update_deep();
				obj.parent_call("roof_update");
			});
			this.push_item_set(this._scene,[]);
			this._scene.heights.push(rh);
			this.parent_call("selection_change");
			this._initiate_edition([rh]);
		}
		this.ghost = null;
		this.call("new_height_end");
	}

	/**
	 * Drag height, in 3D
	 * @param {cn_mouse_event} ev
	 */
	_drag_height(ev) {
		const obj = this;

		const pos = cnx_clone(this._mouseover_height.position);
		pos[2] = this._mouseover_height.values[this._mouseover_height_drag];

		if (!ev.move_to_plane(pos)) return;
		var height_value = ev.mouse_world[2];

		this._current_height = this._mouseover_height;
		this._current_height_slab = this._mouseover_height_drag;
		pos[2] = height_value;
		const sc0 = ev.camera.world_to_screen(pos);

		//*** try to snap with heights that lie on the same slab */
		const candidate_slabs = (this._current_height.neighbour_lock)?this._current_height.slabs:[this._current_height.slabs[this._mouseover_height_drag]];
		var distance = ev.camera.snap_screen_distance;
		var snap_points = [];
		this._scene.heights.forEach(height =>{
			if (height != this._current_height)
			{
				height.slabs.forEach(function(slab, index){
					if (height.locks[index] && candidate_slabs.indexOf(slab) >= 0)
					{
						const pp = cnx_clone(height.position);
						pp[2]=height.values[index];
						snap_points.push(pp);
					}
				});
			}
		});

		//*** Add zero altitude snap */
		pos[2] = 0;
		snap_points.push(cnx_clone(pos));

		//*** snap with various heights */
		if (!this._current_height.neighbour_lock)
		{
			for (var i=0;i<this._current_height.values.length;i++)
			{
				if (i != this._mouseover_height_drag)
				{
					pos[2] = this._current_height.values[i];
					snap_points.push(cnx_clone(pos));
				}
			}
		}

		//*** actually compute snap */
		snap_points.forEach(pp => {
			pos[2] = pp[2];
			const sc = ev.camera.world_to_screen(pos);
			if (sc.length > 0 && cn_dist(sc,sc0) < distance)
			{
				distance = cn_dist(sc,sc0);
				height_value = pp[2];

				obj._svg = cn_snap.draw_snap_line(ev.camera,pos,pp,1);
			}
		});

		this._set_height_value(this._current_height,this._current_height_slab,true,height_value);
	}

	//************************************************
	//*** Passive move
	//************************************************
	update_mouseover(ev)
	{
		this.clear_move();

		//*** fake draw, to compute height data */
		this.draw(ev.camera);

		//*** Maybe mouse over a height ?
		for (var i in this._scene.heights)
		{
			var height = this._scene.heights[i];
			if (!height.draw_checked) continue;

			//*** maybe mouse over main height ? */
			if (ev.camera.is_3d())
			{
				this._mouseover_height_drag = height.mouseover_3d_height(ev);
				if (this._mouseover_height_drag >= 0)
				{
					this._mouseover_height = height;
					return true;
				}
			}

			//*** Maybe mouse over a box? */
			this._mouseover_height_box = height.mouseover(ev.mouse_world,ev.camera);
			if (this._mouseover_height_box >= 0)
			{
				this._mouseover_height = height;
				return true;
			}
		}

		return false;
	}

	_height_clicked(height, index)
	{
		console.log("ask for height ",height);
		const obj = this;
		const input = new cn_number_input("Nouvelle hauteur",100*(height.values[index]+this._scene.storey.height),"cm",0);
		input.checkbox_label = "Automatique";
		input.checkbox_status = !height.locks[index];
		input.callback = function() {
			obj._set_height_value(height,index, !input.checkbox_status,input.value/100 - obj._scene.storey.height);
			return true;
		}
		this.call("number_input",input);
	}

	//***********************************************************************************
	//**** Change current slab's height
	//***********************************************************************************
	_set_height_value(height, index, locked, value) {
		const obj = this;
		//*** Value check
		if (locked && (typeof(value) != 'number' || Math.abs(value) > 1000)) return false;

		console.log("change height to " + value);

		const was_lock = height.locks[index];
		const was_height = height.values[index];
		//*** Apply transaction
		var scene = this._scene;
		this.push_transaction("Hauteur sous toiture",height.ID,function() {scene.update();scene.update_deep();obj.parent_call("roof_update");});
		this.push_item_set(height,["locks","values"]);

		//*** Sets new value (again)
		height.set(index,locked,value);

		//*** update
		this._scene.update();
		this._scene.update_deep();

		//*** check that no height goes below 0 */
		const zmin = -this._scene.storey.height;
		if (this._scene.heights.some(height => height.values.some(v => v < zmin)))
		{
			this._current_height.set(index,was_lock,was_height);
			this._scene.update();
			this._scene.update_deep();
			return false;
		}

		this.parent_call("roof_update");
		this._map.refresh();
		return true;
	}

	//***********************************************************************************
	//**** Edition elements
	//***********************************************************************************
	/**
	 * TODO : derivate in order to allow edition of other element in the process of creation
	 * @param {cn_mouse_event} mouse_event 
	 * @returns {cn_element}
	 */
	_find_other_element(mouse_event) {
		if (mouse_event.camera.is_3d()) return null;
		//*** Maybe mouse over a height ?
		for (var i in this._scene.heights)
		{
			var height = this._scene.heights[i];
			if (!height.draw_checked) continue;
			if (!height.custom) continue;

			if (height.custom_mouseover(mouse_event.mouse_world,mouse_event.camera))
				return height;
		}

		return null;
	}

	/**
	 * TODO : derivate in order to provide an edition handler
	 * @param {Array<cn_roof_height>} elements 
	 * @returns {cn_edition_handler}
	 */
	_build_edition_handler(elements) {
		return new cn_roof_height_handler(elements,this._map,true);
	}
	
}



import { cn_sampling, cn_storey, cn_uuid } from '..';
import { cn_element } from '../model/cn_element';
import { cn_marker } from '../model/cn_marker';
import { cn_roof } from '../model/cn_roof';
import { cn_scene } from '../model/cn_scene';

//***********************************************************************************
//***********************************************************************************
//**** cn_clipboard : stores clipboard data
//***********************************************************************************
//***********************************************************************************

export class cn_clipboard {

    /**
     *  Constructor
     * @param {cn_scene} scene
     * @param {Array<cn_element>} scene
     */
    constructor(scene, elements) {
        this.serialized_storey_elements = [];
        const all_elements = [];
        elements.forEach(element => {
            if (element instanceof cn_marker)
            {
                const serialized = element.serialize();
                serialized.constructor = element.constructor;
                if (element.element) serialized.element_id = element.element.ID;
                this.serialized_storey_elements.push(serialized);
            }
            else
            {
                all_elements.push(element);
                if (element.constructor == 'cn_wall')
                {
                    element.openings.forEach(opening => all_elements.push(opening));
                }
            }
        });
        this.data_constructor = scene.constructor;
        this.serialized_data = scene.serialize(all_elements);
        this.data = null;
        this.storey_elements = [];
    }

    /**
     * Unserialize data
     * @param {cn_storey} storey 
     */
    unserialize(storey) {
        const building = storey.building;
        this.storey = new cn_storey(building);
        this.storey.height = storey.height;

		if (this.serialized_data.element_types)
			building.tmp_element_types = this.serialized_data.element_types;
		if (this.serialized_data.objects)
			building.tmp_objects = this.serialized_data.objects;
            
        switch(this.data_constructor) {
            case cn_scene: {
                this.storey.scene = cn_scene.unserialize(this.serialized_data,building);
                this.storey.scene.storey = this.storey;
                break;
            }
            case cn_roof: {
                this.storey.roof = cn_roof.unserialize(this.serialized_data,building);
                break;
            }
            default:
                return;
        }
        
		building.tmp_element_types = [];
		building.tmp_objects = [];

        this.storey_elements = [];
        this.serialized_storey_elements.forEach(se => { 
            const e = null;
            switch(se.constructor) {
                case cn_marker: {
                    let marker = cn_marker.unserialize(se,this.storey);
                    marker["element_type"] = se.element_type;
                    this.storey.markers.push(marker);
                    break;
                }
                case cn_sampling: {
                    let sampling = cn_sampling.unserialize(se,this.storey);
                    sampling["element_type"] = se.element_type;
                    this.storey.markers.push(sampling);
                    break;
                }
                default:
                    break;
            }
        });

        return this.storey.scene;
    }

    /**
     * Pastre clipboard into storey
     * @param {cn_storey} storey 
     */
    paste(storey) {
        const building = storey.building;
		var scene = storey.scene;

		//*** We add all element types that may have disappear */
		if (this.serialized_data.element_types)
		{
			building.transaction_manager.push_item_set(building,"element_types");
			for (var i in this.serialized_data.element_types)
			{
				var et = this.serialized_data.element_types[i];
				if (building.element_types.indexOf(et) < 0)
					building.element_types.push(et);
			}
		}


		//*** We add all objects that may have disappear */
		if (this.serialized_data.objects)
		{
			building.transaction_manager.push_item_set(building,"objects");
			for (var i in this.serialized_data.objects)
			{
				var obj = this.serialized_data.objects[i];
				if (building.objects.indexOf(obj) < 0)
					building.objects.push(obj);
			}
		}

		//*** we add walls
		function add_or_remove_wall(ww) {
			if (scene.walls.indexOf(ww) >= 0)
				scene.remove_wall(ww);
			else
				scene.insert_wall(ww);
		}

		this.storey.scene.walls.filter(w => w.valid).forEach(w => {
            console.log("added wall",w,w.ID);
			w.ID = cn_uuid(w.ID);
			for (var j in w.openings)
				w.openings[j].ID = cn_uuid(w.openings[j].ID);
			scene.insert_wall(w);
			building.transaction_manager.push_item_set(w,[],add_or_remove_wall);
        });

		//*** we add other elements
		building.transaction_manager.push_item_set(scene,"slab_openings");
		building.transaction_manager.push_item_set(scene,"stairs");
		building.transaction_manager.push_item_set(scene,"object_instances");
		building.transaction_manager.push_item_set(scene,"beams");
		building.transaction_manager.push_item_set(scene,"columns");
		building.transaction_manager.push_item_set(scene,"pipes");
		scene.add_elements(this.storey.scene.slab_openings);
		scene.add_elements(this.storey.scene.stairs);
		scene.add_elements(this.storey.scene.object_instances);
		scene.add_elements(this.storey.scene.beams);
		scene.add_elements(this.storey.scene.columns);
		scene.add_elements(this.storey.scene.pipes);

		scene.update();
		scene.update_deep();
        storey.update_slabs();

		building.transaction_manager.push_item_set(storey,"markers");
        this.storey.markers.forEach(marker => {
            const m = this._check_marker(marker,storey);
            if (m) storey.markers.push(m);
        });
		building.transaction_manager.push_item_set(storey,"samplings");
        this.storey.samplings.forEach(sampling =>  {
            const s = this._check_marker(sampling,storey);
            if (s) storey.samplings.push(s);
        });
    }

    _check_marker(marker, storey)
    {
        marker.ID = cn_uuid(marker.ID);
        if (marker["element_type"] == "cn_slab")
            marker.element = storey.find_slab(marker.position,0);
        if (marker["element_type"] && !marker.element) return null;
        return marker;
    }
}

<app-mpca-form-skeleton>
    <div title>Protection/Exposition</div>
    <div form>
        <div [formGroup]="formProtectionExposition" class="form-protection-exposition">
            <div class="d-flex align-items-start">
                <div class="col-3 form-subtitle align-self-center" style="text-align: center; width: 130px">
                    <b>Protection matériau</b>
                </div>
                <div class="col-3 form-subtitle align-self-center" style="text-align: center; width: 130px">
                    <b>Etanchéité</b>
                </div>
                <div class="col-3 form-subtitle align-self-center" style="text-align: center; width: 130px">
                    <b>Chocs et vibration</b>
                </div>
                <div class="col-3 form-subtitle align-self-center" style="text-align: center; width: 130px">
                    <b>Exposition au circulation d'air</b>
                </div>
            </div>
            <div class="d-flex align-items-start">
                <div class="col-3 d-flex flex-column justify-content-center" data-cy="type-colonne">
                    <input type="hidden" formControlName="type" />
                    <ng-container
                        *ngTemplateOutlet="
                            contentTpl;
                            context: {
                                control: formProtectionExposition.get('type'),
                                options: itemProtectionExposition
                            }
                        "
                    ></ng-container>
                </div>
                <div class="col-3 d-flex flex-column justify-content-center" data-cy="etancheite-colonne">
                    <input type="hidden" formControlName="etancheite" />
                    <ng-container
                        *ngTemplateOutlet="
                            contentTpl;
                            context: {
                                control: formProtectionExposition.get('etancheite'),
                                options: itemEtancheiteConfig
                            }
                        "
                    ></ng-container>
                </div>
                <div class="col-3 d-flex flex-column justify-content-center" data-cy="choc-vibration-colonne">
                    <input type="hidden" formControlName="chocVibration" />
                    <ng-container
                        *ngTemplateOutlet="
                            contentTpl;
                            context: {
                                control: formProtectionExposition.get('chocVibration'),
                                options: itemConditionExpoChocVibration
                            }
                        "
                    ></ng-container>
                </div>
                <div class="col-3 d-flex flex-column justify-content-center" data-cy="circulation-air-colonne">
                    <input type="hidden" formControlName="circulationAir" />
                    <ng-container
                        *ngTemplateOutlet="
                            contentTpl;
                            context: {
                                control: formProtectionExposition.get('circulationAir'),
                                options: itemConditionExpoCirculationAir
                            }
                        "
                    ></ng-container>
                </div>
            </div>
        </div>
    </div>
</app-mpca-form-skeleton>

<ng-template let-options="options" let-control="control" #contentTpl>
    <div class="d-flex flex-column">
        <div
            *ngFor="let item of options"
            [ngClass]="{ disabled: disabled }"
            class="badge-select m-1 p-2 {{ control.value === item.item ? item.color : '' }}"
            (click)="control.setValue(item.item)"
        >
            {{ item.item }}
        </div>
    </div>
</ng-template>

<!--Informations générales-->
<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Informations générales' | uppercase }}
    </div>
    <!--    Block Tableau-->
    <div class="block-content px-5 ml-2 mb-5">
        <table>
            <ng-container *ngFor="let info of infosGeneral">
                <tr>
                    <td>
                        {{ info.libelle }}
                    </td>
                    <td>
                        <b>{{
                            ceeReportData[info.property].valeur ? ceeReportData[info.property].valeur : 'Non renseigné'
                        }}</b>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
<!--PERFORMANCE_THERMIQUE-->
<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Performance thermique' | uppercase }}
    </div>
    <!--    Block Tableau-->
    <div class="block-content px-5 ml-2 mb-5">
        <ng-container *ngFor="let sol of ceeReportData.pointsDeControle.solution">
            <div class="block-subtitle-2 mt-2 mb-2">{{ sol?.infosSolutions?.solutionName }}</div>
            <table>
                <ng-container *ngFor="let info of sol.infosSolutions.informations">
                    <tr *ngIf="info.libelle && info.sectionRapport === PERFORMANCE_THERMIQUE">
                        <td>
                            {{ info.libelle }}
                        </td>
                        <td>
                            <b [innerHTML]="info?.reponse"></b>
                        </td>
                        <td>
                            <button
                                *ngIf="!readonlyMode && info.commentaires?.length"
                                mat-icon-button
                                class="small-button button-group primary"
                                (click)="editDisplayedComments(info.commentaires, info.sectionRapport)"
                            >
                                <mat-icon class="icon-small">edit</mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </ng-container>
    </div>
</div>

<ng-container *ngFor="let data of contentData">
    <ng-container *ngTemplateOutlet="PTCtemplate; context: { title: data.title, section: data.section }"></ng-container>
</ng-container>

<ng-template #PTCtemplate let-title="title" let-section="section">
    <div class="mb-2">
        <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
            {{ title | uppercase }}
        </div>
        <div class="block-content px-5 ml-2 mb-5">
            <ng-container *ngFor="let sol of ceeReportData.pointsDeControle.solution">
                <div class="block-subtitle-2 mt-2 mb-2">{{ sol?.infosSolutions?.solutionName }}</div>
                <table *ngFor="let zone of sol.zones">
                    <tr>
                        <th>{{ zone.zoneName }}</th>
                        <th></th>
                    </tr>
                    <ng-container *ngFor="let info of zone.pointsDeControle">
                        <tr *ngIf="info.libelle && info.sectionRapport === section">
                            <td>
                                {{ info?.libelle }}
                            </td>
                            <td>
                                <b [innerHTML]="info?.reponse"></b>
                            </td>
                            <td>
                                <button
                                    *ngIf="!readonlyMode && info.commentaires?.length"
                                    mat-icon-button
                                    class="small-button button-group primary"
                                    (click)="editDisplayedComments(info.commentaires, info.sectionRapport)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </ng-container>
        </div>
    </div>
</ng-template>

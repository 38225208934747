import { Agence } from './agence.model';
import { Bien } from './bien.model';
import { Contact } from './contact.model';
import { Document } from './document.model';

/**
 * Commande
 */
export class Commande {
    id: string;

    idSalesforce: string;

    contact?: Contact;

    contactString?: string;

    isEntreprise: boolean;

    numeroCommande?: string;

    agence?: Agence;

    bien?: Bien;

    /**
     * Adresse à plat du bien principal : voie; cmpl1; cmpl2; CP; ville utilisée pour filtrer dans les tableaux
     */
    adresseBienPrincipalStringPourFiltre: string;

    urlACBox?: string;

    dateCreation?: string;

    documentBonCommande?: Document;
}

<div class="pre-visite" *ngIf="isLoaded && !erreursBesoin">
    <div class="m-3">
        <verification-card
            *ngIf="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="form"
        >
            Veuillez vérifier la pré-visite
        </verification-card>
    </div>
    <div class="body">
        <div class="column" [formGroup]="form.get('strategie')">
            <div class="header">
                <div>Estimation de la Stratégie</div>
            </div>
            <div>
                <mat-label>Nombre de bâtiment(s)</mat-label>
                <app-increment-input-reactive
                    class="big-increment"
                    formControlName="nombreBatiment"
                    [incrementControl]="form.get('strategie').get('nombreBatiment')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [disabled]="true"
                    [noButtons]="true"
                >
                </app-increment-input-reactive>
            </div>

            <div>
                <mat-label>Nombre de zone(s)</mat-label>
                <app-increment-input-reactive
                    class="big-increment"
                    formControlName="nombreZones"
                    [incrementControl]="form.get('strategie').get('nombreZones')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [disabled]="true"
                    [noButtons]="true"
                >
                </app-increment-input-reactive>
            </div>

            <div>
                <mat-label>Nombre de Meta</mat-label>
                <app-increment-input-reactive
                    class="big-increment"
                    formControlName="nombreMeta"
                    [incrementControl]="form.get('strategie').get('nombreMeta')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [disabled]="true"
                    [noButtons]="true"
                >
                </app-increment-input-reactive>
            </div>

            <div>
                <mat-label>Nombre de Metop</mat-label>
                <app-increment-input-reactive
                    class="big-increment"
                    formControlName="nombreMetop"
                    [incrementControl]="form.get('strategie').get('nombreMetop')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [disabled]="true"
                    [noButtons]="true"
                >
                </app-increment-input-reactive>
            </div>

            <div>
                <mat-label>Nombre de ventilateur</mat-label>
                <app-increment-input-reactive
                    class="big-increment"
                    formControlName="nombreVentilateurs"
                    [incrementControl]="form.get('strategie').get('nombreVentilateurs')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [disabled]="true"
                    [noButtons]="true"
                >
                </app-increment-input-reactive>
            </div>

            <div>
                <mat-label>Commentaire</mat-label>
                <textarea matInput formControlName="commentaire" [readonly]="!isInEditMode"></textarea>
            </div>
        </div>
        <div class="column" [formGroup]="form.get('premiereIntervention')">
            <div class="header">
                <div>Estimation 1ère intervention</div>
            </div>
            <div>
                <mat-label>Nombre de bâtiment(s)</mat-label>
                <app-increment-input-reactive
                    class="big-increment"
                    formControlName="nombreBatiment"
                    [incrementControl]="form.get('premiereIntervention').get('nombreBatiment')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [disabled]="true"
                    [noButtons]="true"
                >
                </app-increment-input-reactive>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Zones</mat-label>
                <mat-select
                    formControlName="zones"
                    multiple
                    [disabled]="!isInEditMode"
                    (selectionChange)="onZoneSelectChange()"
                    [compareWith]="polluantService.compareZones"
                >
                    <mat-option *ngFor="let zone of diagPolluant.zones.data.zonesList" [value]="zone">
                        {{ zone.identifiant }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="form.get('premiereIntervention').get('zones').value.length != 0">
                <mat-label>Nombre de Meta</mat-label>
                <app-increment-input-reactive
                    class="big-increment"
                    formControlName="nombreMeta"
                    [incrementControl]="form.get('premiereIntervention').get('nombreMeta')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [readonly]="!isInEditMode"
                >
                </app-increment-input-reactive>
            </div>

            <div *ngIf="form.get('premiereIntervention').get('zones').value.length != 0">
                <mat-label>Nombre de Metop</mat-label>
                <app-increment-input-reactive
                    class="big-increment"
                    formControlName="nombreMetop"
                    [incrementControl]="form.get('premiereIntervention').get('nombreMetop')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [readonly]="!isInEditMode"
                >
                </app-increment-input-reactive>
            </div>

            <div *ngIf="form.get('premiereIntervention').get('zones').value.length != 0">
                <mat-label>Nombre de ventilateurs</mat-label>
                <app-increment-input-reactive
                    class="big-increment"
                    formControlName="nombreVentilateurs"
                    [incrementControl]="form.get('premiereIntervention').get('nombreVentilateurs')"
                    [min]="0"
                    [step]="1"
                    [numericKeyboard]="true"
                    [readonly]="!isInEditMode"
                >
                </app-increment-input-reactive>
            </div>

            <div *ngIf="form.get('premiereIntervention').get('zones').value.length != 0">
                <mat-label>Durée de la visite (Heures et Minutes)</mat-label>
                <div class="d-flex flex-row">
                    <app-increment-input-reactive
                        class="big-increment small"
                        formControlName="dureeVisiteHeures"
                        [incrementControl]="form.get('premiereIntervention').get('dureeVisiteHeures')"
                        [min]="0"
                        [step]="1"
                        [numericKeyboard]="true"
                        [readonly]="!isInEditMode"
                    >
                    </app-increment-input-reactive>
                    <app-increment-input-reactive
                        class="big-increment small"
                        formControlName="dureeVisiteMinutes"
                        [incrementControl]="form.get('premiereIntervention').get('dureeVisiteMinutes')"
                        [min]="0"
                        [step]="15"
                        [numericKeyboard]="true"
                        [readonly]="!isInEditMode"
                    >
                    </app-increment-input-reactive>
                </div>
            </div>

            <div>
                <mat-label>Commentaire</mat-label>
                <textarea matInput formControlName="commentaire" [readonly]="!isInEditMode"></textarea>
            </div>
        </div>
    </div>
</div>
<div class="pre-visite error-message" *ngIf="isLoaded && erreursBesoin">
    Les données saisies dans l'écran Besoin sont incorrectes ou incomplètes
    <div>
        <button mat-raised-button color="primary" (click)="navigateEcranBesoin()">Retourner à l'écran Besoin</button>
    </div>
</div>

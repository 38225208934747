import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-volume-block',
    templateUrl: './volume-block.component.html',
    styleUrls: ['./volume-block.component.scss'],
})
export class VolumeBlockComponent implements OnInit {
    @Output()
    remove = new EventEmitter<number>();

    @Input()
    disabled: Boolean = true;

    @Input()
    label = '';

    constructor() {}

    ngOnInit(): void {}

    delete() {
        // TODO mettre en place le systeme de supression
        this.remove.emit(0);
    }
}

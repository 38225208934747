import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MpcaItemOld, MpcaProcessus } from '../../../../model/mpca.model.old';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConditionsPrelevement } from '../../../../model/polluant-config.model';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { Polluant } from '../../../../model/polluant.model';
import { MpcaListingComponent } from '../mpca-listing/mpca-listing.component';
import { EtatValidation } from '../../../../../../../model/etat-progression.model';
import { StaticJsonService } from '../../../../../../../services/static-json.service';
import { MatDialog } from '@angular/material/dialog';
import { MpcaService } from '../../../../services/mpca.service';
import { MongoUtils } from 'src/app/commons-lib';
import { TypePrestation } from 'src/app/model/type-prestation.model';
import { IValidatableComponent } from '../../../../services/interfaces/validatable-component.interface';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/commons-lib';
import { InterventionService } from '../../../../../../../services/intervention.service';
import { ZoneComponent } from '../../../shared/zone/zone/zone.component';
import { Zone } from '../../../../model/zone.model';
import { ConfirmDialogComponent } from 'src/app/lib/confirmation/confirm-dialog.component';
import { Besoin } from '../../../../model/besoin.model';

@Component({
    selector: 'app-constatations-diverses',
    templateUrl: './mpca-descriptif.component.html',
    styleUrls: ['./mpca-descriptif.component.scss'],
})
export class MpcaDescriptifComponent extends MpcaListingComponent implements OnInit, IValidatableComponent {
    form: FormGroup;
    typeMesurage: TypePrestation;
    showProcessus: boolean = false;

    // static ecransDependants = [
    //     {
    //         composant: ZoneComponent,
    //         nom: 'zones',
    //     },
    // ];

    constructor(
        private staticJsonService: StaticJsonService,
        public dialog: MatDialog,
        private readonly interventionService: InterventionService,
        private mpcaFormService: MpcaService,
        private route: ActivatedRoute,
        private readonly notificationService: NotificationService,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        combineLatest([
            this.diagnosticService.getCurrentDiagnostic(),
            this.interventionService.getCurrentIntervention(),
            this.route.data,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([diag, intervention, routerData]) => {
                    this.diagnostic = diag;

                    combineLatest([this.polluantService.findOnePolluantConfigIndexedDB(this.diagnostic.idConfig)])
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(([polluantConfig]) => {
                            if (polluantConfig == null) {
                                this.notificationService.error(
                                    'Erreur : Merci de faire une synchro pour récupérer les configs en local.'
                                );
                                return;
                            }
                            this.contenuDiagnostic = diag.contenuDiagnostic as Polluant;
                            this.isValidated = this.contenuDiagnostic.mpcaList.validated;

                            this.polluantConfig = polluantConfig;
                            this.restoreData();
                            this.initMpcaForm();
                            this.evaluateShowProcessus();

                            this.isInEditMode =
                                !(
                                    !!routerData.readonly ||
                                    this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)
                                ) && this.isInEditMode;

                            if (!this.isInEditMode) {
                                this.form.disable();
                            }
                            this.isLoaded = true;
                        });
                },
                () => (this.isLoaded = true)
            );
    }

    private initMpcaForm() {
        this.form = this.formBuilder.group({ descriptifList: new FormArray([]) });

        // Boucle sur la liste de MPCA existante
        this.mpcaList.forEach((item) => {
            const mpcaForm = this.createMpcaForm(item);
            this.mpca.push(mpcaForm);
        });

        this.form.valueChanges.subscribe((value) => {
            this.contenuDiagnostic.mpcaList.data.descriptifList = this.form.value.descriptifList;
            this.majMPCAZones();
        });
    }

    /**
     * @description create mpca form
     * @param item
     * @private
     */
    private createMpcaForm(item: MpcaItemOld): FormGroup {
        const _form = this.formBuilder.group(
            {
                id: [item.id],
                general: this.formBuilder.group({
                    materiaux: [
                        item.general?.materiaux,
                        {
                            validators: Validators.required,
                            updateOn: 'change',
                        },
                    ],
                    materiauxClient: [item.general.materiauxClient],
                    etatDegradation: [
                        item.general.etatDegradation,
                        {
                            validators: Validators.required,
                            updateOn: 'change',
                        },
                    ],
                    typeAmiante: [
                        item.general.typeAmiante,
                        {
                            validators: Validators.required,
                            updateOn: 'change',
                        },
                    ],
                }),
                protectionExposition: this.formBuilder.group({
                    type: [
                        item.protectionExposition.type,
                        {
                            updateOn: 'change',
                        },
                    ],
                    etancheite: [
                        item.protectionExposition.etancheite,
                        {
                            updateOn: 'change',
                        },
                    ],
                    chocVibration: [
                        item.protectionExposition.chocVibration,
                        {
                            updateOn: 'change',
                        },
                    ],
                    circulationAir: [
                        item.protectionExposition.circulationAir,
                        {
                            updateOn: 'change',
                        },
                    ],
                }),
                processus: new FormArray([], {
                    updateOn: 'change',
                }),
            },
            { updateOn: 'blur' }
        );

        item.processus.forEach((process) => {
            (_form.get('processus') as FormArray).push(this.createProcessusForm(process));
        });

        _form.get('protectionExposition').valueChanges.subscribe(() => {
            this.checkValidity(); // Sauvegarde du diagnostic
        });

        _form.get('processus').valueChanges.subscribe(() => {
            this.checkValidity(); // Sauvegarde du diagnostic
        });

        return _form;
    }

    private createProcessusForm(processus: MpcaProcessus): FormGroup {
        if (processus.primary == null || processus.primary == undefined) {
            processus.primary = false;
        }

        const processForm = this.formBuilder.group(
            {
                id: [processus.id],
                nom: [processus.nom, Validators.required],
                primary: [processus.primary],
                taches: new FormArray([]),
                metop: this.formBuilder.group({
                    methode: [processus.metop.methode, Validators.required],
                    technique: [processus.metop.technique],
                    outil: [processus.metop.outil],
                    autresOutils: [processus.metop.autresOutils],
                    aspiration: [processus.metop.aspiration],
                    travailHumide: [processus.metop.travailHumide],
                }),
                pendantTravaux: this.formBuilder.group({
                    niveauEmpoussierement: [processus.pendantTravaux.niveauEmpoussierement, Validators.required],
                    typeMasqueDesamianteur: [processus.pendantTravaux.typeMasqueDesamianteur, Validators.required],
                    concentrationFi: [processus.pendantTravaux.concentrationFi, Validators.required],
                }),
                echantillonnage: this.formBuilder.group({
                    concentrationAttendue: [processus.echantillonnage.concentrationAttendue],
                    dureeRepresentativeProcessus: [processus.echantillonnage.dureeRepresentativeProcessus],
                    dureeSaturationPoussieres: [processus.echantillonnage.dureeSaturationPoussieres],
                    debitPrelevement: [processus.echantillonnage.debitPrelevement],
                    fractionFiltre: [processus.echantillonnage.fractionFiltre],
                }),
            },
            { updateOn: 'blur' }
        );

        processus.taches.forEach((tache) => {
            (processForm.get('taches') as FormArray).push(this.mpcaFormService.getTacheGroup(tache));
        });

        return processForm;
    }

    /**
     * @description ajout d'un nouvelle item MPCA dans la liste MPCA
     *
     * @private
     * @param item
     */
    addNewMpcaItem(item: MpcaItemOld) {
        // On clone le MPCA item
        let mpcaClone: MpcaItemOld = MpcaListingComponent.deepCloneMpca(item);
        mpcaClone.id = MongoUtils.generateObjectId();
        super.addNewMpcaItem(mpcaClone);
        const mpcaForm = this.createMpcaForm(mpcaClone);
        this.mpca.push(mpcaForm);

        //Sélection du dernier mpca ajouté
        this.mpcaIndexSelected = this.mpca.length - 1;

        this.evaluateShowProcessus();
    }

    //Affiche la section Processus Client
    // si le type de l'objectif de mesurage est METOP
    evaluateShowProcessus() {
        if (this.selectedMpca == undefined) return;

        // //Ref I, J ou K
        this.showProcessus = false;
        this.contenuDiagnostic.objectifsMesurage.data.objectifsMesurage.forEach((obj: ConditionsPrelevement) => {
            if (obj.typePrelevement == 'METOP') {
                this.showProcessus = true;
                this.selectedMpca.get('processus').setValidators(Validators.required);
            } else {
                this.selectedMpca.get('processus').removeValidators(Validators.required);
            }
        });
    }

    /**
     * @description supprimer un item mpca
     * @param index
     */
    deleteMpcaItem(index: number) {
        //Avant de supprimer l'item
        //On vérifie si ce mpca est associé à une zone
        const mpcaId = this.mpca.at(index).value.id;
        const zonesAssociees = this.contenuDiagnostic.zones.data.zonesList.filter((zone: Zone) => {
            return zone.listeMpca.reduce((present, mpca: MpcaItemOld) => {
                if (mpca.id == mpcaId) {
                    return true;
                }
                return present;
            }, false);
        });
        //Si oui alors on ouvre la modale de confirmation
        if (zonesAssociees.length > 0) {
            let message = 'Attention, ce MPCA est associé aux zones suivantes:';
            zonesAssociees.forEach((zone: Zone) => {
                message += `<br/>- ${zone.nom}`;
            });
            message += '<br/><br/>Supprimer ce MPCA entrainera son retrait des zones ci-dessus.';
            message += '<br/>Confirmez-vous la suppression ?';

            this.matDialog
                .open(ConfirmDialogComponent, {
                    data: {
                        message,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result && result !== false) {
                        let messageEcranZone = `Le MPCA ${
                            this.mpca.at(index).value.general.materiauxClient
                        } a été retirés des zones `;

                        //Si l'opération est confirmée, la ou les références sont supprimée
                        zonesAssociees.forEach((zone: Zone) => {
                            zone.listeMpca = zone.listeMpca.filter((mpca) => {
                                return mpca.id != mpcaId;
                            });

                            messageEcranZone += zone.nom + ', ';
                        });

                        messageEcranZone += 'veuillez compléter les zones si nécessaire.';

                        this.contenuDiagnostic.zones.message = messageEcranZone;

                        super.deleteMpcaItem(index);
                        this.mpca.removeAt(index);
                    }
                });
        } else {
            super.deleteMpcaItem(index);
            this.mpca.removeAt(index);
        }
    }

    deleteProcessus(index) {
        const processusToDelete = this.mpcaProcessus.at(index).value;

        //Vérification si ce processus est utilisé plus loin dans le diagnostic
        let besoinsAssocies = this.contenuDiagnostic.besoins.data.besoinsList.filter((besoin: Besoin) => {
            return besoin.processusId == processusToDelete.id;
        });

        if (besoinsAssocies.length > 0) {
            let message = 'Attention, ce Processus est associé aux besoins de prélèvement suivants:';
            besoinsAssocies.forEach((besoin: Besoin) => {
                message += `<br/>- B${besoin.numero}`;
            });
            message += '<br/><br/>Supprimer ce Processus entrainera son retrait des besoins de prélèvement ci-dessus.';
            message += '<br/>Confirmez-vous la suppression ?';

            this.matDialog
                .open(ConfirmDialogComponent, {
                    data: {
                        message,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result && result !== false) {
                        let messageEcranBesoins = `Le Processus ${processusToDelete.nom} a été retirés des Besoins `;

                        //Si l'opération est confirmée, la ou les références sont supprimée
                        besoinsAssocies.forEach((besoin: Besoin) => {
                            //Suppression des références
                            besoin.processusId = undefined;

                            //Concaténation du message
                            messageEcranBesoins += 'B' + besoin.numero + ', ';
                        });

                        messageEcranBesoins += 'veuillez compléter les besoins si nécessaire.';

                        this.contenuDiagnostic.besoins.message = messageEcranBesoins;

                        this.mpcaProcessus.removeAt(index);
                    }
                });
        } else {
            this.mpcaProcessus.removeAt(index);
        }
    }

    /**
     * @description check validy
     * @private
     */
    private checkValidity() {
        //Si le formulaire est désactivé, on l'active afin de pouvoir savoir si il est valide
        let isFormEnabled = this.form.enabled;
        if (!isFormEnabled) {
            this.form.enable({
                emitEvent: false,
            });
        }

        let etat: EtatValidation = 'INVALID';
        if (this.form && this.form.valid) {
            etat = 'VALID';
        }

        //Récupération de toutes les erreurs
        const erreursMpcas = [];
        if (this.form.status.toString() == 'INVALID') {
            (this.form.get('descriptifList') as FormArray).controls.forEach((formMpca: FormGroup, index: number) => {
                let erreursMpca = {
                    numero: index + 1,
                    nom: formMpca.get('general').get('materiauxClient').value,
                    erreurs: [],
                };

                //boucle sur les différents groupes du formulaire
                for (const formControlName in formMpca.controls) {
                    if (
                        formMpca.get(formControlName).invalid == true ||
                        formMpca.get(formControlName).status == 'INVALID'
                    ) {
                        //Processus est un cas particulier car c'est un formArray donc il ne contient pas de controles individuels qui peuvent être invalides
                        if (formControlName == 'processus') {
                            erreursMpca.erreurs.push(formControlName);
                            continue;
                        }

                        //Boucle sur les différents champs des groupes du formulaire
                        for (const controlName in (formMpca.get(formControlName) as FormGroup).controls) {
                            if (
                                (formMpca.get(formControlName) as FormGroup).get(controlName).invalid == true ||
                                (formMpca.get(formControlName) as FormGroup).get(controlName).status == 'INVALID'
                            ) {
                                erreursMpca.erreurs.push(controlName);
                            }
                        }
                    }
                }

                //Si il y'a des erreurs
                if (erreursMpca.erreurs.length != 0) {
                    erreursMpcas.push(erreursMpca);
                }
            });
        }

        //Si le formulaire était désactivé avant, on le désactive à nouveau
        if (!isFormEnabled) {
            this.form.disable({
                emitEvent: false,
            });
        }

        this.contenuDiagnostic.mpcaList.errors = erreursMpcas;

        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic, true);
    }

    /**
     * Get le formulaire tableau root mpca
     */
    get mpca(): FormArray {
        return this.form.get('descriptifList') as FormArray;
    }

    /**
     * Get le formulaire tableau root mpca
     */
    get selectedMpca(): FormGroup {
        return (this.form.get('descriptifList') as FormArray).controls[this.mpcaIndexSelected] as FormGroup;
    }

    get mpcaProcessus(): FormArray {
        return this.selectedMpca.get('processus') as FormArray;
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.isInEditMode = false;
        this.form.disable();
        this.form.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.form.disable();
        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuDiagnostic.mpcaList.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.form.enable();
        this.previousFormValue = this.form.getRawValue();
    }

    /**
     * Mise à jour des MPCA dans les zones si chgt
     */
    private majMPCAZones() {
        try {
            const currentMPCA = this.mpcaList[this.mpcaIndexSelected];
            for (const zone of this.contenuDiagnostic.zones.data.zonesList) {
                const index = zone.listeMpca.findIndex((mpca) => mpca.id === currentMPCA.id);
                if (index >= 0) {
                    zone.listeMpca[index] = Object.assign({}, currentMPCA);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }
}

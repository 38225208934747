import { Component, Input } from '@angular/core';
import { DocumentHistory } from '../../../../../model/document.model';

@Component({
    selector: 'app-documents-detail-header-attachment-history',
    templateUrl: './documents-detail-attachment-history.component.html',
    styleUrls: ['./documents-detail-attachment-history.component.scss'],
})
export class DocumentsDetailAttachmentHistoryComponent {
    @Input() documentsHistory: DocumentHistory[] = [];

    trackByDocumentHistory(index: number, documentHistory: DocumentHistory): string {
        return documentHistory.nomFichier;
    }
}

import { Injectable } from '@angular/core';
import { BienService } from './bien.service';
import { DiagnosticHandlerService } from './diagnostic-handler.service';
import { Bien, Niveau, Volume } from '../model/bien.model';
import { Diagnostic, EtatDiagnostic } from '../model/diagnostic.model';
import { InterventionService } from './intervention.service';
import { DiagnosticService } from './diagnostic.service';
import { Intervention } from '../model/intervention.model';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class MergeBienService {
    constructor(
        private readonly bienService: BienService,
        private readonly diagnosticHandlerService: DiagnosticHandlerService,
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService
    ) {}

    transfertVolumeToNiveau(bien: Bien, volume: Volume, niveauCible: Niveau, intervention) {
        return this.updateAllDiagnosticsAndIntervention(intervention, (intervention, diagnostics) => {
            const niveauActuel = this.findNiveauForVolume(bien, volume);
            niveauCible.volumes.push(volume);
            this.removeVolumeFromNiveau(niveauActuel, volume);
            diagnostics.forEach((diag) => {
                this.diagnosticHandlerService
                    .getTypePrestationService(diag.typePrestation)
                    .deplaceVolume(diag, volume, niveauCible, bien);
            });
        });
    }

    mergeVolumes(bien: Bien, volumeActuel: Volume, targetVolume: Volume, intervention: Intervention) {
        return this.updateAllDiagnosticsAndIntervention(intervention, (intervention, diagnostics) => {
            const newBien = intervention.relationInterventionBiens[0].bien;
            targetVolume.nom = volumeActuel.nom;
            targetVolume.elementsAControler = targetVolume.elementsAControler.concat(volumeActuel.elementsAControler);
            targetVolume.commentairesId = targetVolume.commentairesId.concat(volumeActuel.commentairesId);
            targetVolume.valeursParametres = Object.assign(
                targetVolume.valeursParametres,
                volumeActuel.valeursParametres
            );
            targetVolume.usageId = volumeActuel.usageId;
            volumeActuel.spaceId = targetVolume.spaceId;
            const currentNiveau = this.findNiveauForVolume(newBien, volumeActuel);
            this.removeVolumeFromNiveau(currentNiveau, volumeActuel);
            diagnostics.forEach((diag) => {
                this.diagnosticHandlerService
                    .getTypePrestationService(diag.typePrestation)
                    .mergeVolume(diag, volumeActuel, targetVolume, newBien);
            });
        });
    }

    mergeNiveaux(bien: Bien, niveauActuel: Niveau, niveauCible: Niveau, intervention: Intervention) {
        return this.updateAllDiagnosticsAndIntervention(intervention, (intervention, diagnostics) => {
            const volumesFiltered = niveauActuel.volumes.filter((volume) => !volume.volumeCache);
            niveauCible.volumes = niveauCible.volumes.concat(volumesFiltered).flat();

            const exterior = niveauActuel.volumes.find((v) => v.volumeCache);
            niveauCible.volumes.find((v) => v.volumeCache).elementsAControler = niveauCible.volumes
                .find((v) => v.volumeCache)
                .elementsAControler.concat(exterior.elementsAControler);

            this.deleteNiveau(bien.description, niveauActuel);

            diagnostics.forEach((diag) =>
                this.diagnosticHandlerService
                    .getTypePrestationService(diag.typePrestation)
                    .mergeNiveau(diag, niveauActuel, niveauCible, bien)
            );
        });
    }
    private updateAllDiagnosticsAndIntervention(
        intervention: Intervention,
        worker: (intervention: Intervention, diagnostics: Diagnostic[]) => void
    ) {
        return this.diagnosticService.getAllDiagnosticsForCurrentIntervention(intervention).pipe(
            map((diagnostics) => diagnostics.filter((it) => it.etat !== EtatDiagnostic.ANNULE)),
            tap((diagnostics) => worker(intervention, diagnostics)),
            switchMap((diagnostics) =>
                combineLatest([
                    this.interventionService.updateIntervention(intervention),
                    ...diagnostics.map((diag) => this.diagnosticService.upsert(intervention, diag)),
                ])
            ),
            tap(() => this.diagnosticService.reloadCurrentDiagnostic()),
            tap(() => this.interventionService.reloadCurrentIntervention())
        );
    }
    private findNiveauForVolume(bien: Bien, volume: Volume) {
        return bien.description.find((it) => it.volumes.some((vol) => vol.id === volume.id));
    }
    private removeVolumeFromNiveau(niveauActuel: Niveau, volume: Volume) {
        const index = niveauActuel.volumes.indexOf(volume);
        if (index > -1) {
            niveauActuel.volumes.splice(index, 1);
        }
    }

    private deleteNiveau(bienDescription: Niveau[], currentNiveau: Niveau) {
        const index = bienDescription.indexOf(currentNiveau);
        if (index > -1) {
            bienDescription.splice(index, 1);
        }
    }
}

import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { EtapeDiagnosticAbstract, EtapeDiagnosticGenerique } from '../../../../model/diagnostic-etape.model';
import { PrelevementGenerique } from '../../../../model/prelevement-generique.model';
import { ResultatAnalyseGenerique } from '../../../../model/resultatAnalyseGenerique.model';
import { Besoin } from './besoin.model';
import { Sachet } from '../../../../model/sachet.model';
import { MpcaItemOld, MpcaProcessus, MpcaProcessusTaskOld } from './mpca.model.old';

export class PolluantPrelevement implements ContenuDiagnostic {
    // NB : il faut bien donner des valeurs par défaut aux différentes étapes, pour l'intialisation du diagnostic
    id: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    material: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    listeZones: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    prelevement: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    visiteDeChantier: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
}

export class PrelevementAir implements PrelevementGenerique {
    id: string;
    reference: string;
    resultats: ResultatAnalyseMeta[];
    besoinPrelevement?: Besoin;
    numero: number;
    refSachet: string;
    refIntervention: string;
}

export class Temoin implements PrelevementGenerique {
    id: string;
    numero: number;
    reference: string;
    resultats: ResultatAnalyseGenerique[];
    refSachet: string;
    refIntervention: string;
}

export class Material {
    numeroChrono: number;
    numeroDebitmetre: number;
    immatriculationUmd: number;
}

export class ResultatAnalyseMeta implements ResultatAnalyseGenerique {
    id: string;
    reference: string;
}

export class PrelevementMeta implements PrelevementAir {
    besoinPrelevement?: Besoin;
    numero: number;
    id: string;
    reference: string;
    resultats: ResultatAnalyseMeta[];
    refSachet: string;
    refIntervention: string;

    //Récupération
    informationsClientRecuperation: InformationsClient = new InformationsClient();
    informationsPrelevementRecuperation: InformationsPrelevement = new InformationsPrelevement();
    conformiteRecuperation: Conformite = new Conformite();

    //Pose
    informationsClientPose: InformationsClient = new InformationsClient();
    informationsPrelevementPose: InformationsPrelevement = new InformationsPrelevement();
    informationsMeteo: InformationsMeteo = new InformationsMeteo();
    conformitePose: Conformite = new Conformite();
    validationStrategie: ValidationStrategie = new ValidationStrategie();

    //Méthode utilisée pour transformer un objet de type Besoin en PrelevementMeta
    static fromBesoin(besoin: Besoin) {
        let besoinPrelevementMeta = new PrelevementMeta();
        besoinPrelevementMeta.besoinPrelevement = besoin;

        return besoinPrelevementMeta;
    }
}

export class PrelevementMetop implements PrelevementAir {
    besoinPrelevement: Besoin;
    id: string;
    reference: string;
    numero: number;
    resultats: ResultatAnalyseMeta[];
    refSachet: string;
    refIntervention: string;

    //Avant Prélèvement
    informationsClient: InformationsClient = new InformationsClient();
    informationsPrelevementAvant: InformationsPrelevement = new InformationsPrelevement();
    informationsMeteoAvant: InformationsMeteo = new InformationsMeteo();
    listeFiltresAvant: FiltrePrelevementMetop[] = [];

    //Pendant Prélèvement
    processus: MpcaProcessus = undefined;
    listeFiltresPendant: FiltrePrelevementMetop[] = [];
    positionGenerale: string = '';
    postureGenerale: string = '';
    prelevementRealise: boolean = undefined;
    informationsMeteoPendant: InformationsMeteo = new InformationsMeteo();

    //Après Prélèvement
    informationsPrelevementApres: InformationsPrelevement = new InformationsPrelevement();
    informationsMeteoApres: InformationsMeteo = new InformationsMeteo();
    listeFiltresApres: FiltrePrelevementMetop[] = [];
    conformite: Conformite = new Conformite();
    validationStrategie: ValidationStrategie = new ValidationStrategie();

    //Méthode utilisée pour transformer un objet de type Besoin en PrelevementMetop
    static fromBesoin(besoin: Besoin, contenuDiagnostic: PolluantPrelevement) {
        let besoinPrelevementMetop = new PrelevementMetop();
        besoinPrelevementMetop.besoinPrelevement = besoin;

        besoinPrelevementMetop.numero = parseInt(besoin.numero);

        return besoinPrelevementMetop;
    }
}

export class PrelevementMest implements PrelevementAir {
    informationsClient: InformationsClient = new InformationsClient();
    conformite: Conformite = new Conformite();
    informationsPrelevementMest: InformationsPrelevementAutre = new InformationsPrelevementAutre();
    besoinPrelevement: Besoin;
    id: string;
    reference: string;
    numero: number;
    resultats: ResultatAnalyseMeta[];
    refSachet: string;
    refIntervention: string;

    //Méthode utilisée pour transformer un objet de type Besoin en PrelevementMest
    static fromBesoin(besoin: Besoin) {
        let besoinPrelevementMest = new PrelevementMest();
        besoinPrelevementMest.besoinPrelevement = besoin;
        besoinPrelevementMest.numero = parseInt(besoin.numero);

        return besoinPrelevementMest;
    }
}

export class InformationsPrelevementAutre {
    localisationPrelevement: string;
    circonstance: string;
    dateTimePrelevement: string;
    remarques: string;
    metopAssocie: string;
}

export class PrelevementAcr implements PrelevementAir {
    id: string;
    reference: string;
    numero: number;
    resultats: ResultatAnalyseMeta[];
    refSachet: string;
    refIntervention: string;
    besoinPrelevement?: Besoin;
    informationsPrelevement: InformationsPrelevementAutre = new InformationsPrelevementAutre();
    informationsCompresseur: InformationsCompresseur = new InformationsCompresseur();
    conformite: Conformite = new Conformite();
    vapeurHuile: InformationsGaz = new InformationsGaz();
    co: InformationsGaz = new InformationsGaz();
    co2: InformationsGaz = new InformationsGaz();
    vapeurEau: InformationsGaz = new InformationsGaz();

    //Méthode utilisée pour transformer un objet de type Besoin en PrelevementAcr
    static fromBesoin(besoin: Besoin) {
        let besoinPrelevementMest = new PrelevementAcr();
        besoinPrelevementMest.besoinPrelevement = besoin;
        besoinPrelevementMest.numero = parseInt(besoin.numero);

        return besoinPrelevementMest;
    }
}

export class InformationsCompresseur {
    marque: string;
    modele: string;
    numeroSerie: string;
    typeCompresseur: string;
    typeHuile: string;
}

export class InformationsGaz {
    debit: number;
    graduationMaxi: number;
    concentrationMesuree: number;
}

export class PrelevementAutre {
    informationsClient: InformationsClient;
    informationsPrelevement: InformationsPrelevementAutre;
}

export class ImpossibilitePrelevement {
    nonConformite: string;
    description: string;
    analyseCause: string;
    traitementImmediat: string;
}

export class FiltrePrelevementMetop {
    numeroFiltre: string = '';
    numeroPompe: string = '';
    debitInitial: number = 0;
    testEtancheite: string = '';
}

export class InformationsClient {
    faitParLeClient: boolean = true;
}

export class InformationsPrelevement {
    localisationPrelevement: string;
    lotFiltre: string;
    numeroPompe: string;
    numeroTete: string;
    dateTimeDebutPrelevement: string;
    dateTimeFinPrelevement: string;
    dureeTotalePrelevement: string;
    pressionMoyenne: number;
    temperatureMoyenne: number;
    tauxHumiditeInterieur: number;
    debitEssaiTotel: number;
    volumeTotal: number;
    debitInitial: number;
    selon1600746033: boolean;
    selonNFX43269: boolean;
    debitFinal: number;
    volume: number;
    debitEssai: string;
    remarque: string;
}

export class InformationsMeteo {
    vitesseVent: number = 20;
    directionVent: DirectionVent;
    conditionMeteo: ConditionMeteo;
    pressionAtmospherique: number = 1000;
    temperature: number = 15;
    hygrometrie: number = 50;
}

export enum DirectionVent {
    'Nord',
    'Sud',
    'Est',
    'Ouest',
}

export enum ConditionMeteo {
    'SOLEIL',
    'AVERSES',
    'PLUIE',
    'NEIGE',
}

export class Conformite {
    prelevementReglementaire: boolean;
    justificatifNonReglementaire: string;
    accreditationCofrac: boolean;
}

export class ValidationStrategie {
    justificatifChangement: string;
    clientPrevenuPar: ContactClient;
}

export enum ContactClient {
    'SUR_PLACE',
    'TELEPHONE',
    'EMAIL',
}

import { Injectable } from '@angular/core';
import { PolluantConfig } from '../modules/diagnostics/polluant/model/polluant-config.model';
import { ElectriciteConfig } from '../modules/diagnostics/electricite/model/electricite.model';
import { CeeConfig } from '../modules/diagnostics/cee/model/cee.model';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { PolluantApiService } from '../modules/diagnostics/polluant/services/polluant-api.service';
import { ElectriciteApiService } from '../modules/diagnostics/electricite/services/electricite-api.service';
import { CeeApiService } from '../modules/diagnostics/cee/services/cee-api.service';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { getGroupPrestation } from '../model/type-prestation.model';

/**
 * Service d'appel aux APIs pour les agences.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class ConfigApiService {
    constructor(
        private polluantApiService: PolluantApiService,
        private electriciteApiService: ElectriciteApiService,
        private ceeApiService: CeeApiService
    ) {}

    /**
     * Pull les configPolluant dans l'indexDb
     */
    pullConfigPolluant(): Observable<PolluantConfig[]> {
        return this.polluantApiService.findAllConfigPolluant();
    }

    findAllConfig(typePrestation: String): Observable<any> {
        var groupPresta = getGroupPrestation(typePrestation);
        if (groupPresta == 'POLLUANT') {
            return this.polluantApiService.findAllPolluantConfigOffline();
        } else if ((groupPresta = 'ELECTRICITE')) {
            return this.electriciteApiService.findAllElectriciteConfigOffline();
        }
        // function Cee
    }

    needConfig(typePrestation: String): boolean {
        var bool = false;
        var groupPresta = getGroupPrestation(typePrestation);
        if (groupPresta == 'POLLUANT') {
            bool = true;
        } else if (groupPresta == 'ELECTRICITE') {
            bool = true;
        }
        // function Cee
        return bool;
    }

    findLastIdConfig(configs: any[]) {
        let lastIdConfig: string;
        let lastDateConfig: Date;
        configs.forEach((config) => {
            if (lastIdConfig == null && lastDateConfig == null) {
                lastIdConfig = config.id;
                lastDateConfig = config.dateDeCreation;
            } else {
                if (lastDateConfig < config.dateDeCreation) {
                    lastIdConfig = config.id;
                    lastDateConfig = config.dateDeCreation;
                }
            }
        });
        return lastIdConfig;
    }

    findLastConfig(configs: any[]) {
        let lastIdConfig: string;
        let lastDateConfig: Date;
        let conf: any;
        configs.forEach((config) => {
            if (lastIdConfig == null && lastDateConfig == null) {
                lastIdConfig = config.id;
                lastDateConfig = config.dateDeCreation;
                conf = config;
            } else {
                if (lastDateConfig < config.dateDeCreation) {
                    lastIdConfig = config.id;
                    lastDateConfig = config.dateDeCreation;
                    conf = config;
                }
            }
        });
        return conf;
    }

    // TODO : Faire pour les autres config après le merge
    /**
     * Pull les configElec dans l'indexDb
     */
    pullConfigElec(): Observable<ElectriciteConfig[]> {
        return this.electriciteApiService.findAllElectriciteConfig();
    }

    // /**
    // * Pull les configCee dans l'indexDb
    // */
    //  private pullConfigCee(): Observable<CeeConfig[]> {
    //     return this.ceeApiService.pullConfigCee();
    // }
}

"use strict";
import {cn_object_instance} from "../model/cn_object_instance";
import {cn_roof} from "../model/cn_roof";
import {cn_space} from "../model/cn_space";
import {cn_edition_handler} from "./cn_edition_handler";
import {cn_edit_box} from "./cn_edit_box";
import {cn_description_input, cn_number_input, cn_number_list_input, cn_space_equipments_input, cn_space_input} from "./cn_inputs";
import {cn_pastille} from "./cn_pastille";
import {cn_svg_map, SVG_MAP_OPTION_SPACE_ALLOW_CUSTOM_DIMENSIONS} from "./cn_svg_map";
import {extension_instance} from '../extension/cn_extension';

export class cn_space_handler extends cn_edition_handler {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {Array<cn_space>} spaces
	 * @param {cn_svg_map} map
	 */
	constructor(spaces,map) {
		super(spaces,map);

		this._map = map;
		this._scene = spaces[0].scene;
		this._transaction_manager = spaces[0].scene.storey.building.transaction_manager;
		this._roof = (this._scene.constructor == cn_roof);
		this._storey = spaces[0].scene.storey;
		//*** marker
		this._spaces = spaces;

		//*** Edit box for mass selection */
		const edit_box = new cn_edit_box(this,spaces,false,false);
		this._handlers.push(edit_box);

        const space_extension = extension_instance.space;

		const obj = this;

		const inside_spaces = spaces.filter(sp => !sp.outside);

        if (!this._storey.exterior && inside_spaces.length) {
            //*** ceiling */
            this._ceiling_pastille = new cn_pastille([0, 0], "arrow_collapse_up.svg");
            edit_box.add_pastille(this._ceiling_pastille);
            this._ceiling_pastille.svg_class = "pastille_background white";
            this._ceiling_pastille.title = "Hauteur sous plafond";
            this._ceiling_pastille.clicked = function () {
                const first_space_ceiling_height = inside_spaces[0].ceiling_height;
                const check = (first_space_ceiling_height <= 0);
                const storey_height = inside_spaces[0].scene.storey.height;
                const max_slab_offset = Math.max(...inside_spaces.map(it => it.slab_offset));
                const max_hsp_value = storey_height - max_slab_offset;
                const hsp_value = check ? max_hsp_value : first_space_ceiling_height - inside_spaces[0].slab_offset;
                const input = new cn_number_input("Hauteur sous plafond", hsp_value, "m", 2, 1, max_hsp_value, "Automatique", check);
                if (inside_spaces.some(s => s.ceiling_height !== first_space_ceiling_height)) input.label += " (variable)";
                input.callback = function () {
                    obj._transaction_manager.push_transaction("Hauteur sous plafond");
                    inside_spaces.forEach(sp => {
                        obj._transaction_manager.push_item_set(sp, ["ceiling_height"])
                        sp.ceiling_height = input.checkbox_status ? 0 : input.value + sp.slab_offset;
                    });
                    map.refresh();
                }
                map.call("number_input", input);
            }

            //*** Floor */
            this._floor_pastille = new cn_pastille([0, 0], "arrow_collapse_down.svg");
            edit_box.add_pastille(this._floor_pastille);
            this._floor_pastille.svg_class = "pastille_background white";
            this._floor_pastille.title = "Décalage de dalle";
            this._floor_pastille.clicked = function () {
                const initial_value = inside_spaces[0].slab_offset;
                const initial_value_cm = initial_value * 100;
                const input = new cn_number_input("Décalage de dalle", initial_value_cm, "cm", 0, -100, 100);
                if (inside_spaces.some(s => s.slab_offset !== initial_value)) input.label += " (variable)";
                input.callback = function () {
                    const result_meters = input.value / 100;
                    obj._transaction_manager.push_transaction("Décalage de dalle");
                    inside_spaces.forEach(sp => {
                        obj._transaction_manager.push_item_set(sp, ["slab_offset"])
                        sp.slab_offset = result_meters;
                    });
                    map.refresh();
                }
                map.call("number_input", input);
            }
		}

		//*** dimensions */
		const allow_custom_dimensions = map.get_option(SVG_MAP_OPTION_SPACE_ALLOW_CUSTOM_DIMENSIONS);
		if (allow_custom_dimensions || this._spaces.length == 1)
		{
            const dimension_title = allow_custom_dimensions ? "Dimensions déclarées" : "Dimensions";
			this._dimension_pastille = new cn_pastille([0,0],"tape_measure.svg");
			edit_box.add_pastille(this._dimension_pastille);
			this._dimension_pastille.svg_class = "pastille_background white";
			this._dimension_pastille.title = dimension_title;
			this._dimension_pastille.clicked = function() {
				if (allow_custom_dimensions) {
					const input = new cn_number_list_input(dimension_title);
                    input.values.push(new cn_number_input("Surface", (spaces[0].declared_area > 0) ? spaces[0].declared_area : spaces[0].area, "m²", 2, 0, 10000, "Automatique", spaces[0].declared_area <= 0));
                    input.values.push(new cn_number_input("Périmètre", (spaces[0].declared_perimeter > 0) ? spaces[0].declared_perimeter : spaces[0].perimeter, "m", 2, 0, 10000, "Automatique", spaces[0].declared_perimeter <= 0));
					input.callback = function() {
                        const new_area = (input.values[0].checkbox_status) ? 0 : input.values[0].value;
                        const new_perimeter = (input.values[1].checkbox_status) ? 0 : input.values[1].value;
						obj._transaction_manager.push_transaction("Dimensions");
						spaces.forEach(sp => {
							obj._transaction_manager.push_item_set(sp,["declared_area","declared_perimeter"]);
							sp.declared_area = new_area;
							sp.declared_perimeter = new_perimeter;
						});
					};
					map.call("number_list_input",input);
				} else {
					const input = new cn_description_input("Dimensions", [
                        {label: "Surface", value: spaces[0].area, decimals: 2, unit: "m²"},
                        {label: "Périmètre", value: spaces[0].perimeter, decimals: 2, unit: "m"},
                    ]);
					map.call("description_input",input);
				}
			};
		}

		//*** Diagonals */
		if (this._spaces.length == 1 && this._map.show_space_measure)
		{
			var diagonals_mode = false;
			const diagonals_pastille = new cn_pastille([0,0],"set_square.svg");
			edit_box.add_pastille(diagonals_pastille);
			diagonals_pastille.svg_class = "pastille_background white";
			diagonals_pastille.title = "Diagonales";
			diagonals_pastille.clicked = function() {
				diagonals_mode = !diagonals_mode;
				obj.call("diagonals_mode",diagonals_mode);
				if (diagonals_mode)
					diagonals_pastille.svg_class = "pastille_background green";
				else
					diagonals_pastille.svg_class = "pastille_background white";
			};
		}

		//*** details */
		if (inside_spaces.length)
		{
			this._details_pastille = new cn_pastille([0,0],"text.svg");
			edit_box.add_pastille(this._details_pastille);
			this._details_pastille.svg_class = "pastille_background white";
			this._details_pastille.title = "Détails";
            const storey_ID = this._storey.ID;
			this._details_pastille.clicked = function() {
				const input = new cn_space_input();
                input.ID = inside_spaces.some(sp => sp.ID !== inside_spaces[0].ID) ? undefined : inside_spaces[0].ID;
                input.storey_ID = storey_ID;
                input.various_names = inside_spaces.some(sp => sp.name !== inside_spaces[0].name);
                input.various_usages = inside_spaces.some(sp => sp.space_usage !== inside_spaces[0].space_usage);
                input.name = input.various_names ? undefined : inside_spaces[0].name;
                input.usage = input.various_usages ? undefined : inside_spaces[0].space_usage;
                const inner_spaces = inside_spaces.filter(sp => sp.indoor);
                if (inner_spaces.length > 0)
                {
                    input.heated_relevant = true;
                    input.various_heated = inner_spaces.some(sp => sp.heated !== inner_spaces[0].heated);
                    input.heated = input.various_heated ? undefined: inner_spaces[0].heated;
                }
				input.callback = function() {
					const new_name = input.name;
					const new_usage = input.usage;
					obj._transaction_manager.push_transaction("Détails de pièces");
					inside_spaces.forEach(sp => {
						obj._transaction_manager.push_item_set(sp,["usage","name"]);
						if (!input.various_names) sp.name = new_name;
						if (!input.various_usages) sp.space_usage = new_usage;
					});
                    if (input.heated_relevant && !input.various_heated)
                    {
                        inner_spaces.forEach(sp => {
                            obj._transaction_manager.push_item_set(sp,["heated"]);
                            sp.heated = input.heated;
                        });
                    }
					map.refresh();
				}
				map.call("space_input",input);
			}
		}

		//*** Equipments */
        if (!this._storey.exterior && this._spaces.length === 1 && !this._spaces[0].outside) {
            const space = this._spaces[0];
            const equipment_pastille = new cn_pastille([0, 0], "shape.svg");
            edit_box.add_pastille(equipment_pastille);
            equipment_pastille.svg_class = "pastille_background white";
            equipment_pastille.title = "Equipements";
            equipment_pastille.clicked = function () {
                const input = new cn_space_equipments_input(space.name, space.space_usage);
                const instances = {};
                space.scene.object_instances.forEach(instance => {
                    if (instance.space === space) {
                        const oid = instance.object.ID;
                        if (typeof (input.objects[oid]) == 'undefined') {
                            input.objects[oid] = 1;
                            instances[oid] = [instance]
                        } else {
                            input.objects[oid]++;
                            instances[oid].push(instance);
                        }
                    }
                });
                //*** Callback definition */
                input.callback = function () {
                    let first_modification = true;

                    //*** Unreturned objects are considered as zero instance */
                    const input_objects_ids = Object.keys(input.objects);
                    const removed_objects_ids = Object.keys(instances).filter(it => !input_objects_ids.includes(it));
                    const result_objects = {
                        ...input.objects,
                        ...(Object.fromEntries(removed_objects_ids.map(id => [id, 0])))
                    }

                    for (let object_id in result_objects) {
                        const number = result_objects[object_id];
                        //*** For each object, we check existing instances of that object */
                        const existing_instances = (typeof (instances[object_id]) == 'undefined') ? [] : instances[object_id];

                        //*** Create transaction if necessary */
                        if (first_modification) {
                            first_modification = false;
                            obj._transaction_manager.push_transaction("Equipements de pièce");
                            obj._transaction_manager.push_item_set(space.scene, ["object_instances"]);
                        }

                        //*** Maybe we have to add some instances */
                        if (number > existing_instances.length) {
                            if (number === existing_instances.length) return;
                            const object = space.scene.building.get_object(object_id);
                            if (object) {
                                for (let k = 0; k < number - existing_instances.length; k++) {
                                    const new_object_instance = new cn_object_instance(space.scene);
                                    new_object_instance.virtual = true;
                                    new_object_instance.space = space;
                                    new_object_instance.object = object;
                                    space.scene.object_instances.push(new_object_instance);
                                }
                            }
                        }

                        //*** Maybe we have to remove some */
                        else if (number < existing_instances.length) {
                            //*** we remove in priority virtual instances */
                            existing_instances.sort(function (a, b) {
                                const xa = (a.virtual) ? 0 : 1;
                                const xb = (b.virtual) ? 0 : 1;
                                return xa - xb;
                            });
                            console.log("existing instances", existing_instances);
                            for (let k = 0; k < existing_instances.length - number; k++) {
                                const index = space.scene.object_instances.indexOf(existing_instances[k]);
                                if (index >= 0) space.scene.object_instances.splice(index, 1);
                            }
                        }
                    }

                    space.scene.full_update();
                    map.refresh();
                };

                map.call("space_equipments_input", input);
            };
		}

        if (this._spaces.length === 1) {
            const input_extension = space_extension.space_handler_option(this._spaces[0], map);
            if (input_extension && input_extension.length) {
                input_extension.forEach(ie => edit_box.add_pastille(ie));

            }
        }
	}

	//***********************************************************************************
	//**** Draws
	//***********************************************************************************
	draw(camera) {
		return super.draw(camera);
	}
}


"use strict";

import { cn_building } from "../../model/cn_building";
import { cn_storey } from "../../model/cn_storey";
import { ZONE_EXTERIOR_LABEL, cn_zone } from "../../model/cn_zone";
import { cn_nomenclature, find_zone } from "../cn_nomenclature";

//***********************************************************************************
//***********************************************************************************
//**** Build nomenclatures
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
/**
 * Buils facing nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_facings(building) {

    building.update_roofs();
    building.compute_altitudes();

    const output = [];
    let has_usage = false;
    const zones = building.zones.usage;

    [building.exterior, ...building.storeys].forEach((storey) => {
        storey.scene.spaces.length && storey.scene.spaces.forEach(space => {
            if (space.space_usage && space.space_usage.length) {
                has_usage = true;
            }
        })
    });

    output.push(new cn_nomenclature("Etage"));
    output.push(new cn_nomenclature("Zone"));
    output.push(new cn_nomenclature("Pièce"));
    if (has_usage)
        output.push(new cn_nomenclature("Usage"));
    output.push(new cn_nomenclature("Position"));
    output.push(new cn_nomenclature("Revêtement"));
    output.push(new cn_nomenclature("Surface", "m²"));

    [building.exterior, ...building.storeys].forEach((storey) => {
        populate_nomenclature_facings(building, storey, output, zones, has_usage);
    });

    return output;
}
//***********************************************************************************
/**
 * 
 * @param {cn_building} building 
 * @param {cn_storey} storey 
 * @param {Array<cn_nomenclature>} output 
 * @param {Array<cn_zone>} zones 
 * @param {boolean} has_usage 
 */
function populate_nomenclature_facings(building, storey, output, zones, has_usage) {
    storey.scene.storey = storey;
    storey.scene.update_deep();

    //*** We compute the storey volume */
    storey.build_roof_volume(true);

    function _populate_facing_list(facing_list, polygons,default_facing) {
        polygons.forEach(pg => {
            const facing = (pg["facing_trimming"])?pg["facing_trimming"].facing:default_facing;
            const building_facing = building.facing_types.find(f => facing && facing.ID === f.ID);
            const facing_name = building_facing ? building_facing.name : 'Aucun';
            if (typeof(facing_list[facing_name]) != 'number') facing_list[facing_name] = 0;
            facing_list[facing_name] += pg.get_area();
        });
    }

    //*** Loop on storeys */
    storey.scene.spaces.length && storey.scene.spaces.forEach(space => {
        const storey_name = (storey.exterior)?"Extérieur":storey.storey_index;
        const zone_name = (storey.exterior)?"-":(space.outside) ? ZONE_EXTERIOR_LABEL : find_zone(zones, space.ID, storey.ID);
        const space_name = space.get_name(storey);
        const space_usage = space.space_usage ? space.space_usage : "";

        //*** Local method to add a line to the nomenclature */
        function add_line(position, facing, area)
        {
            let k = 0;
            output[k].values.push(storey_name);
            k++
            output[k].values.push(zone_name);
            k++
            output[k].values.push(space_name);
            k++
            if (has_usage)
            {
                output[k].values.push(space_usage);
                k++
            }
            output[k].values.push(position);
            k++
            output[k].values.push(facing);
            k++
            output[k].values.push(Number(area.toFixed(2)));
            k++
        }

        //*** Floor spacing */
        if (!space.outside)
        {
            const area_per_facing = {};

            _populate_facing_list(area_per_facing,space.get_floor_facing_polygons(),space.facings[0]);
            for (var key in area_per_facing)
            {
                add_line("Sol", key, area_per_facing[key]);
            }

            if (space.has_roof)
            {
                const ceilings = building.build_3d_ceiling(storey,space);
                if (ceilings.length > 0)
                {
                    var area = 0;
                    ceilings.forEach(c => area += c.get_area());
                    const facing = building.facing_types.find(f => space.facings[1] && space.facings[1].ID === f.ID);
                    add_line("Plafond", facing ? facing.name : 'Aucun', area);
                }
            }
        }

        //*** wall spacing retrieval */
        var facing_list = {};
        space.contours.forEach(ctr => {
            ctr.walls.forEach(function(wall, index) {
                const side = (ctr.wall_orientations[index])?0:1;
                _populate_facing_list(facing_list,wall.build_facing_polygons(side,storey),wall.facings[side]);
            });
        });

        //*** wall spacing writing */
        for (var facing in facing_list)
        {
            if (!space.has_roof)
                add_line("Facade", facing, facing_list[facing]);
            else
                add_line("Mur", facing, facing_list[facing]);
        }
    });
}


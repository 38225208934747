import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Bien } from '../../../../../model/bien.model';
import {
    SvgFormValuesEquipments,
    SvgFormValuesBien,
    SvgFormValuesPage,
    SvgFormValuesPlan,
    SvgFormValuesEspace,
    SvgFormValuesZones,
    SvgFormValuesHap,
} from '../shared/interfaces/export-svg-form-values.interface';
import { ExportSvgFormConfMapper } from '../shared/mappers/export-svg-form-mapper';
import { BaseComponent } from 'src/app/commons-lib';
import { Espace } from '../../../../../model/espace.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-export-svg-side-nav-form',
    templateUrl: './export-svg-side-nav-form.component.html',
    styleUrls: ['./export-svg-side-nav-form.component.scss'],
})
export class ExportSvgSideNavFormComponent extends BaseComponent implements OnInit {
    // TODO: change type
    private _formConf: any;

    @Input() set formConf(formConf: any) {
        this._formConf = formConf;
        if (formConf) {
            this.populateForm();
        }
    }
    get formConf() {
        return this._formConf;
    }

    @Input() biens: Bien[] = [];
    @Input() espaces: Espace[] = [];

    private _dynamicScale: number;
    @Input() set dynamicScale(dynamicScale: any) {
        this._dynamicScale = dynamicScale;
        this.updateScaleDynamically(dynamicScale);
    }
    get dynamicScale() {
        return this._dynamicScale;
    }

    @Output() bienGroupChanged = new EventEmitter<SvgFormValuesBien>();
    @Output() espaceGroupChanged = new EventEmitter<SvgFormValuesEspace>();
    @Output() pagesGroupChanged = new EventEmitter<SvgFormValuesPage>();
    @Output() planGroupChanged = new EventEmitter<SvgFormValuesPlan>();
    @Output() equipmentGroupChanged = new EventEmitter<SvgFormValuesEquipments>();
    @Output() zoneGroupChanged = new EventEmitter<SvgFormValuesZones>();
    @Output() hapGroupChanged = new EventEmitter<SvgFormValuesHap>();
    @Output() clickCenterView = new EventEmitter<any>();

    form: FormGroup;

    formSubscriptions: Subscription[] = [];

    constructor(private readonly exportSvgFormConfMapper: ExportSvgFormConfMapper, private formBuilder: FormBuilder) {
        super();
        this.createForm();
    }

    ngOnInit(): void {}

    createForm() {
        this.form = this.formBuilder.group({
            bien: this.formBuilder.group({
                bien: [undefined],
                niveau: [undefined],
            }),
            espace: this.formBuilder.group({
                espace: [undefined],
            }),
            page: this.formBuilder.group({
                format: ['paysage'],
            }),
            plan: this.formBuilder.group({
                general: this.formBuilder.group({
                    scale: [200],
                    resolution: [200],
                    fixedScale: [false],
                    fixedPosition: [false],
                }),
                display: this.formBuilder.group({
                    render: [0],
                    showGrid: [true],
                    showScale: [true],
                    showBackground: [true],
                    showCompass: [true],
                }),
                elements: this.formBuilder.group({
                    showSpaces: [true],
                    showOuterWalls: [true],
                    showInnerWalls: [true],
                    showBalconies: [true],
                    showWindows: [true],
                    showDoors: [true],
                    showStairs: [true],
                    showSlabOpenings: [true],
                    showDeams: [true],
                    showColumns: [true],
                    showObjects: [true],
                }),
                text: this.formBuilder.group({
                    showSpaceLabel: [true],
                    showSpaceArea: [true],
                    showMarkers: [true],
                    showNumerotation: [true],
                    showLegend: [false],
                }),
                cotes: this.formBuilder.group({
                    showOuterMeasures: [true],
                    showInnerMeasures: [true],
                    showOpeningMeasures: [true],
                }),
            }),
            equipment: this.formBuilder.group({
                conformite: this.formBuilder.group({
                    showConformeObjects: [true],
                    showNonConformeObjects: [true],
                    showAJustifierObjects: [true],
                    showNonVerifieObjects: [true],
                }),
            }),
            zone: this.formBuilder.group({
                surface: this.formBuilder.group({
                    showSurfacesComptabilisees: [true],
                    showSurfacesDecomptees: [true],
                }),
            }),
            hap: this.formBuilder.group({
                surface: this.formBuilder.group({
                    showPerimetres: [true],
                    showZones: [true],
                    showPrelevements: [true],
                }),
                zone: this.formBuilder.group({
                    showZoneInferieurEgal50: [true],
                    showZoneEntre51Et500: [true],
                    showZoneEntre501Et1000: [true],
                    showZoneSuperieur1000: [true],
                    showZoneNonRealises: [true],
                }),
                prelevement: this.formBuilder.group({
                    showPrelevementInferieurEgal50: [true],
                    showPrelevementEntre51Et500: [true],
                    showPrelevementEntre501Et1000: [true],
                    showPrelevementSuperieur1000: [true],
                    showPrelevementNonRealises: [true],
                }),
            }),
        });

        this.initSubscriptions();
    }

    private initSubscriptions() {
        // Block Biens changed :
        this.formSubscriptions.push(
            this.form.get('bien').valueChanges.subscribe((changedValues: SvgFormValuesBien) => {
                this.bienGroupChanged.emit(changedValues);
            })
        );

        // Block Espaces changed :
        this.formSubscriptions.push(
            this.form.get('espace').valueChanges.subscribe((changedValues: SvgFormValuesEspace) => {
                this.espaceGroupChanged.emit(changedValues);
            })
        );

        // Block Page Changed :
        this.formSubscriptions.push(
            this.form.get('page').valueChanges.subscribe((changedValues: SvgFormValuesPage) => {
                this.pagesGroupChanged.emit(changedValues);
            })
        );

        // Block Plan Changed :
        this.formSubscriptions.push(
            this.form.get('plan').valueChanges.subscribe((changedValues: SvgFormValuesPlan) => {
                this.planGroupChanged.emit(changedValues);
            })
        );

        // Block Equipment Changed :
        this.formSubscriptions.push(
            this.form.get('equipment').valueChanges.subscribe((changedValues: SvgFormValuesEquipments) => {
                this.equipmentGroupChanged.emit(changedValues);
            })
        );

        // Block Zone Changed :
        this.formSubscriptions.push(
            this.form.get('zone').valueChanges.subscribe((changedValues: SvgFormValuesZones) => {
                this.zoneGroupChanged.emit(changedValues);
            })
        );

        // Block Hap Changed :
        this.formSubscriptions.push(
            this.form.get('hap').valueChanges.subscribe((changedValues: SvgFormValuesHap) => {
                this.hapGroupChanged.emit(changedValues);
            })
        );
    }

    private cancelSubscriptions() {
        this.formSubscriptions.forEach((it) => it.unsubscribe());
    }

    populateForm() {
        this.cancelSubscriptions();
        const formValues = this.exportSvgFormConfMapper.toSideNavFormValues(this.formConf);
        this.form.patchValue(formValues);
        this.initSubscriptions();
    }

    updateScaleDynamically(dynamicScale) {
        if (this.form && dynamicScale) {
            this.form.patchValue({ plan: { general: { scale: dynamicScale } } }, { onlySelf: true, emitEvent: false });
        }
    }

    onClickCenterView() {
        this.clickCenterView.emit();
    }
}

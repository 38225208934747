import { Component, Inject, OnInit } from '@angular/core';
import { Niveau, Volume } from '../../../../model/bien.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    CODE_BIM_PARAM_ESPACE_CHAUFFE,
    CODE_BIM_PARAM_ESPACE_HUMIDE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    PARAM_VOLUME_LOT,
} from '../../../../shared/constants/cndiag.constants';
import { TypeVolume } from '../../../../model/type-element-a-controler.model';
import { ListUtils } from '../../../../utils/list.utils';
import { ReferenceService } from 'src/app/services/reference.service';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';

class VolumeModalData {
    constructor(
        public niveau: Niveau,
        public volumeToEdit: Volume,
        public zoneBien: string,
        public readonlyName = false
    ) {}
}

@Component({
    selector: 'app-add-volume-modal',
    templateUrl: './add-volume-modal.component.html',
    styleUrls: ['./add-volume-modal.component.scss'],
})
export class AddVolumeModalComponent implements OnInit {
    volumeToEdit = new Volume();
    newVolume = true;
    form: FormGroup;

    // Liste des pièces génériques
    volumes: TypeVolume[] = [];
    // Liste des noms de pièces génériques
    volumesToDisplay: string[] = [];
    // Liste des noms de pièces déjà présentes dans le niveau actuel
    volumesNamesAlreadyPresents: string[] = [];

    readonlyName = false;
    avecPlan = false;
    // Paramètres
    readonly codeBimParamEspaceHumide = CODE_BIM_PARAM_ESPACE_HUMIDE;
    readonly codeBimParamEspaceChauffe = CODE_BIM_PARAM_ESPACE_CHAUFFE;
    readonly codeBimParamEspaceNonReputeCarrez = CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ;
    readonly codeBimParamEspaceNonReputeHabitable = CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE;
    readonly codeBimParamEspaceNonReputeUtile = CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE;
    readonly codeBimParamNumeroLot = PARAM_VOLUME_LOT;
    private originalVolumeName: string;

    constructor(
        public dialogRef: MatDialogRef<AddVolumeModalComponent>,
        private referenceService: ReferenceService,
        @Inject(MAT_DIALOG_DATA) public data: VolumeModalData,
        private formBuilder: FormBuilder
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        this.form = this.formBuilder.group({
            nom: ['', [Validators.required]],
            numLot: [this.data?.zoneBien ? this.data.zoneBien : ''],
            humide: [false],
            chauffe: [false],
            nonReputeCarrez: [false],
            nonReputeHabitable: [false],
            nonReputeUtile: [false],
        });

        // Liste des pièces génériques
        this.referenceService.findAllTypesVolumes().subscribe((pieces) => {
            this.volumes = pieces.filter(
                (it) => it.piecesPreequipeesConfigs.length === 0 && it.etatVolume === EtatWorkflow.ACTIF
            );
            // En mode édition, on rajoute le volume à éditer dans la liste
            if (this.data.volumeToEdit) {
                this.originalVolumeName = this.data.volumeToEdit.nom;
                this.readonlyName = this.data.readonlyName;
                this.volumeToEdit = this.data.volumeToEdit;
                this.newVolume = false;
                // On ajoute aux choix le nom original du volume
                const typeVolumeToEdit = new TypeVolume();
                typeVolumeToEdit.nom = this.volumeToEdit.nom;
                typeVolumeToEdit.valeursDefautParametres = this.volumeToEdit.valeursParametres;
                this.volumes = [
                    ...new Set(this.volumes.concat(typeVolumeToEdit).sort((c1, c2) => c1.nom.localeCompare(c2.nom))),
                ];
                this.volumesToDisplay = [...new Set(this.volumes.map((v) => v.nom))];
                this.form.patchValue({
                    nom: this.volumeToEdit.nom,
                    numLot: this.volumeToEdit.valeursParametres[this.codeBimParamNumeroLot],
                    humide: this.volumeToEdit.valeursParametres[this.codeBimParamEspaceHumide],
                    chauffe: this.volumeToEdit.valeursParametres[this.codeBimParamEspaceChauffe],
                    nonReputeCarrez: this.volumeToEdit.valeursParametres[this.codeBimParamEspaceNonReputeCarrez],
                    nonReputeHabitable: this.volumeToEdit.valeursParametres[this.codeBimParamEspaceNonReputeHabitable],
                    nonReputeUtile: this.volumeToEdit.valeursParametres[this.codeBimParamEspaceNonReputeUtile],
                });
                this.avecPlan = !!this.data.volumeToEdit.spaceId;
            } else {
                this.volumes = [...new Set(this.volumes.sort((c1, c2) => c1.nom.localeCompare(c2.nom)))];
                this.volumesToDisplay = this.volumes.map((v) => v.nom);
            }

            // Récupération de la liste des volumes du niveau, pour créer un nom unique à la création du volume
            if (this.data.niveau) {
                if (this.data.niveau.volumes.length) {
                    this.volumesNamesAlreadyPresents = this.data.niveau.volumes.map((volumeTemp) => volumeTemp.nom);
                }
            }
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        const name = this.form.get('nom').value;
        this.volumeToEdit.nom = name;
        const selectedVolume = this.volumes.find((vol) => vol.nom === name);
        if (selectedVolume != undefined && selectedVolume.id) {
            this.volumeToEdit.usageId = selectedVolume.id;
        }
        if (name != this.originalVolumeName) {
            this.volumeToEdit.nom = ListUtils.createUniqueName(this.volumesNamesAlreadyPresents, name);
        }
        this.volumeToEdit.valeursParametres[this.codeBimParamNumeroLot] = this.form.get('numLot').value;
        this.volumeToEdit.valeursParametres[this.codeBimParamEspaceHumide] = this.form.get('humide').value;
        this.volumeToEdit.valeursParametres[this.codeBimParamEspaceChauffe] = this.form.get('chauffe').value;
        this.volumeToEdit.valeursParametres[this.codeBimParamEspaceNonReputeCarrez] =
            this.form.get('nonReputeCarrez').value;
        this.volumeToEdit.valeursParametres[this.codeBimParamEspaceNonReputeHabitable] =
            this.form.get('nonReputeHabitable').value;
        this.volumeToEdit.valeursParametres[this.codeBimParamEspaceNonReputeUtile] =
            this.form.get('nonReputeUtile').value;
        this.dialogRef.close({ volume: this.volumeToEdit });
    }

    volumeChanged(name: string | string[]) {
        const completeTypeVolume = this.volumes.find((x) => x.nom === name);
        this.form.patchValue({
            humide: completeTypeVolume.valeursDefautParametres[this.codeBimParamEspaceHumide],
            chauffe: completeTypeVolume.valeursDefautParametres[this.codeBimParamEspaceChauffe],
            nonReputeCarrez: completeTypeVolume.valeursDefautParametres[this.codeBimParamEspaceNonReputeCarrez],
            nonReputeHabitable: completeTypeVolume.valeursDefautParametres[this.codeBimParamEspaceNonReputeHabitable],
            nonReputeUtile: completeTypeVolume.valeursDefautParametres[this.codeBimParamEspaceNonReputeUtile],
        });
    }
}

//***********************************************************************************
//***********************************************************************************
//**** fh_scene : base class for the BIM model
//***********************************************************************************
//***********************************************************************************

import {
    AmbientLight,
    BackSide,
    Box3,
    Color,
    DirectionalLight,
    DoubleSide,
    EdgesGeometry,
    Face3,
    FrontSide,
    Geometry,
    LineBasicMaterial,
    LineSegments,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    PointLight,
    Scene,
    Vector2,
    Vector3,
    ImageUtils,
	RepeatWrapping,
	TextureLoader
} from 'three';
import {FH3D_MAX_LOD} from "./fh_view";
import {fh_add, fh_copy, fh_cross, fh_dist, fh_dot, fh_mul, fh_normalize, fh_size, fh_sub} from './fh_vector';
import {fh_polygon} from "./fh_polygon";
import { fh_ground_controller } from './fh_ground_controller';
import {fh_box} from "./fh_box";

export class fh_object extends Object3D
{
	constructor(json_object = null) {
		super();
		this.name = "";
		this.bim_type = "";
		this.BIMID = "";
		this.json_object = json_object;

		this._meshes =[];
		this.selectable = true;
		this.selected = false;
		this.max_storey = 0;

        this.parent = null;
		this.obsolete = false;
		this.tmp_visible = true;

		if (json_object)
		{
			//*** register object
			this.BIMID = json_object.ID;
			if (typeof(json_object.Name) == 'string')
				this.name = json_object.Name;
			else
				this.name = "";

			//*** type dependant settings
			if ((typeof(json_object.Space) == "boolean" && json_object.Space === true) || json_object.Code_BIM == "SPACE")
				this.bim_type = "space";
			else if (json_object.Code_BIM == "FLAT")
				this.bim_type = "flat";
			else
				this.bim_type = "product";

			//*** visibility
			if (typeof(json_object.VISIBILITY) != 'undefined' && !json_object.VISIBILITY)
			this.visible = false;
		}

	}

	update_visibility() {
		this.visible = false;
		if (this.obsolete) return;
		if (!this.tmp_visible) return;
		//@ts-ignore
		if (this.bim_type == "product" && !this.parent.tmp_visible) return;
		this.visible = true;
	}

	get_parent_flat() {
		if (this.bim_type != "space") return null;
		if (typeof(this.parent) != "object") return null;
		var flat = this.parent;
		if (flat.bim_type != 'flat') return null;
		return flat;
	}
}

const fh_loaded_textures = {};
function fh_load_lazy_texture(texture)
{
	if (typeof(fh_loaded_textures[texture]) != "undefined") return fh_loaded_textures[texture];
	const map = new TextureLoader().load(texture);
	map.wrapS = RepeatWrapping;
	map.wrapT = RepeatWrapping;
	fh_loaded_textures[texture] = map;
	console.log("actually loading texture ",texture,map);
	return map;
}

export class fh_scene {
	constructor() {
		//*** Selection callback : called when selection has been changed
		this._cb_selection_changed = function(ids) {};
		this._cb_mouseover_changed = function(id) {};

		//*** Double click callback : called when an object is double clicked
		this._cb_double_click = function(object) {};

		this._camera_light = null;

		//*** Scenegraph
		this._scene = null;
		this._storeys = [];
		this._flats = [];
		this._spaces = [];
		this._products = [];
		this._product_types = [];
		this._objects_by_id = [];
		this._meshes_by_view = [];
		this._all_meshes = [];
		this._storey_orientation = 0;
		this._render_lods = true;
		this._edge_objects = [];

		//*** construction options
		this._store_json_objects = false;
		this._merge_spaceless_products = false;
		this._merged_meshes = 0;
		this._mergeable_meshes = 0;

		//*** Misc
		this._mouseover_object = null;
		this._selection_mode = 0;
		this._current_flat = null;
		this._current_space = null;
		this._selection = [];
		this._all_flats = false;

		//*** TODO : fill that selection stealer to intercept selection.Return false to proceed, true to intercept.
		this._selection_stealer = function(object_id, add_to_selection) {return false;};

		//*** Scene geometry
		this._bounding_box = null;
		this._scene_center = null;
		this._scene_radius = 0;
		this._build_edges = true;

		this._display_fps = false;

		function new_special_material(color) {
			var mat = new MeshBasicMaterial();
			mat.side = DoubleSide;
			mat.color.r = color[0];
			mat.color.g = color[1];
			mat.color.b = color[2];
			mat.transparent=(color[3] < 0.99);
			mat.opacity=color[3];
			mat.depthTest=false;
			mat.depthWrite=false;
			return mat;
		}

		this._space_highlight_material = new_special_material([1,1,0,0.2]);
		this._space_select_material = new_special_material([1,0,0,0.2]);
		this._space_selectable_material = new_special_material([0,0,1,0.2]);

		this._object_highlight_material = new_special_material([1,1,0,0.5]);
		this._object_select_material = new_special_material([1,0,0,0.5]);

		this._object_white_material = new MeshBasicMaterial();
		this._object_white_material.side = DoubleSide;
		this._object_white_material.color.r = 1;
		this._object_white_material.color.g = 1;
		this._object_white_material.color.b = 1;
		this._object_white_material.transparent=false;
		this._object_white_material.opacity=0;
		this._object_white_material.depthTest=true;
		this._object_white_material.depthWrite=true;
		this._edge_material = new LineBasicMaterial( { color: 0x000000 } );
	}

	//***********************************************************************************
	//**** Scene display methods
	//***********************************************************************************

	//*** sets priority to all products of a given product type
	set_priority(product_type, LOD) {
		if (LOD < 0 || LOD > FH3D_MAX_LOD) return;
		var pt = this._product_types[product_type];
		if (typeof(pt) == 'object')
			pt.tmp_LOD = pt.LOD = LOD;
	}

	//*** sets temporary priority to all products of a given product type
	set_tmp_priority(product_type, LOD) {
		if (LOD < 0 || LOD > FH3D_MAX_LOD) return;
		var pt = this._product_types[product_type];
		if (typeof(pt) == 'object')
			pt.tmp_LOD = LOD;
	}

	//*** Restore priority for all products of a given type. If no type specified, for all products.
	restore_priority(product_type = null) {
		if (product_type === null)
		{
			for (var key in this._product_types)
			{
				var pt = this._product_types[key];
				pt.tmp_LOD = pt.LOD;
			}
			return;
		}

		var pt = this._product_types[product_type];
		if (typeof(pt) == 'object')
			pt.tmp_LOD = pt.LOD;
	}

	//*** sets  visibility to aal products of a given type. If product_type is not defined, applies to all products.
	set_product_type_visibility(visibility, product_type = null) {
		if (product_type === null)
		{
			for (var key in this._product_types)
			{
				var pt = this._product_types[key];
				pt.tmp_visible = visibility;
				for (var key in pt.children)
					pt.children[key].update_visibility();
			}
			return;
		}
		var pt = this._product_types[product_type];
		if (typeof(pt) == 'object')
		{
			pt.tmp_visible = visibility;
			for (var key in pt.children)
				pt.children[key].update_visibility();
		}
	}

	//*** sets  visibility to a product. If product_id is not defined, applies to all products.
	set_product_visibility(visibility, product_id = null) {
		if (product_id === null)
		{
			for (var key in this._products)
			{
				var pt = this._products[key];
				pt.tmp_visible = visibility;
				pt.update_visibility();
			}
			return;
		}
		var pt = this._objects_by_id[product_id];
		if (typeof(pt) != 'object') return;
		pt.tmp_visible = visibility || (this._ground_controller && this._ground_controller.visible && typeof(pt.topography) != 'undefined');
		pt.update_visibility();
	}

	//*** sets  visibility to a space. If space_id is not defined, applies to all spaces.
	set_space_visibility(visibility, space_id = null) {
		if (space_id === null)
		{
			for (var key in this._spaces)
			{
				var pt = this._spaces[key];
				pt.tmp_visible = visibility;
				pt.update_visibility();
			}
			return;
		}
		var pt = this._objects_by_id[space_id];
		if (typeof(pt) != 'object') return;
		pt.tmp_visible = visibility;
		pt.update_visibility();
	}

	/**
	 * Sets the color of a product
	 * @param {any} product_id or the product itself
	 * @param {number[] | null} color r, g, b, a of the color, between 0 and 1.  If null, will restore the object'(s original color)
	 * @returns {boolean} true is set was done
	 */
	set_product_color(product_id, color = null) {
		var pt = (typeof(product_id)=='string')?this._objects_by_id[product_id]:product_id;
		if (typeof(pt) != 'object') return false;
		if (color == null)
		{
			pt._meshes.forEach(mesh => {
				if (typeof(mesh._original_color) != 'undefined')
				{
					mesh._original_material.color = mesh._original_color;
					mesh._original_material.opacity = mesh._original_opacity;
				}
			});
		}
		else
		{
			const col = new Color();
			col.r = color[0];
			col.g = color[1];
			col.b = color[2];
			const opacity = (color.length>3)?color[3]:1;

			pt._meshes.forEach(mesh => {
				if (typeof(mesh._original_color) == 'undefined')
				{
					mesh._original_color = mesh._original_material.color;
					mesh._original_opacity = mesh._original_material.opacity;
				}
				mesh._original_material.color = col;
				mesh._original_material.opacity = opacity;
				mesh._original_material.transparent = (opacity < 0.9);
			});
		}
		this.refresh_object(pt);
		return true;
	}

	/**
	 * sets the color of a material
	 * @param {any} material
	 * @param {number[]} color
	 */
	set_material_color(material, color) {
		if (color && color.length >= 3)
		{
			const col = new Color();
			col.r = color[0];
			col.g = color[1];
			col.b = color[2];
			const opacity = (color.length>3)?color[3]:1;

			material.color = col;
			material.opacity = opacity;
			material.transparent = (opacity < 0.9);
		}
	}

	/**
	 * gets a product's color
	 * @param {any} product_id or the product itself
	 * @return {number[] | null}   r, g, b, a of the color, between 0 and 1.  null, if default color
	 */
	 get_product_color(product_id) {
		var pt = (typeof(product_id)=='string')?this._objects_by_id[product_id]:product_id;
		if (typeof(pt) != 'object') return null;
		var res = null;

		pt._meshes.forEach(mesh => {
			if (typeof(mesh._original_color) != 'undefined' && mesh._original_color != mesh._original_material.color)
			{
				res = [mesh._original_material.color.r,mesh._original_material.color.g,mesh._original_material.color.b,mesh._original_material.opacity];
			}
		});
		return res;
	}

	/**
	 * Sets the material to use for space mouseover
	 * @param {MeshBasicMaterial} material
	 */
	set_space_highlight_material(material) {
		this._space_highlight_material = material;
	}

	/**
	 * Sets the material to use for space selection
	 * @param {MeshBasicMaterial} material
	 */
	set_space_selection_material(material) {
		this._space_select_material = material;
	}

	/**
	 * Sets the material to use for space mouseover
	 * @param {MeshBasicMaterial} material
	 */
	set_product_highlight_material(material) {
		this._object_highlight_material = material;
	}

	/**
	 * Sets the material to use for space selection
	 * @param {MeshBasicMaterial} material
	 */
	set_product_selection_material(material) {
		this._object_select_material = material;
	}

	//***********************************************************************************
	/**
	 * Sets a list of products as obsolete.
	 * All these products will not be rendered.
	 * A call to that method clears a previous call
	 * @param {string[]} product_id_list
	 */
	 set_obsolete_products(product_id_list) {
		for (var k in this._products)
		{
			this._products[k].obsolete = false;
		}

		for (var k in product_id_list)
		{
			var pt = this._objects_by_id[product_id_list[k]];
			if (typeof(pt) != 'object') continue;
			if (pt.bim_type != "product") continue;
			pt.obsolete = true;
		}

		for (var k in this._products)
			this._products[k].update_visibility()
	}

	//*** sets edge visibility. Relevant only if edges were created during loading
	set_edge_visibility(visibility) {
		for (var i in this._edge_objects)
			this._edge_objects[i].visible = visibility;
	}

	//*** sets edge visibility. Relevant only if edges were created during loading
	set_white_rendering(v) {
		for (var i in this._products)
		{
			var object = this._products[i];
			for (var j in object._meshes)
			{
				if (v)
					object._meshes[j].material = this._object_white_material;
				else
					object._meshes[j].material = object._meshes[j]._original_material;
			}
		}
	}

	//*** sets Lighting to ambient only
	set_full_ambient(v) {
		if (typeof(this._sun_light) == 'object')
			this._sun_light.intensity = (v)?0:1;

		if (typeof(this._ambient_light) == 'object')
			this._ambient_light.intensity = (v)?1:0.2;

		if (typeof(this._camera_light) == 'object')
		{
			this._camera_light.color = (v)?new Color(0x000000):new Color(0xcccccc);
		}
	}

	//***********************************************************************************
	//**** Scene initialization from json
	//***********************************************************************************

	load_json(json, build_edges = false) {
		this._build_edges = build_edges;
		var d0 = new Date();

		//*** Clear scene
		this._scene = new Scene();
		this._product_types = [];
		this._building = new Object3D();
		this._scene.add(this._building);

		var light = new DirectionalLight(0x808080);
		light.position.set(-0.2, -0.3, 1);
		this._scene.add(light);
		this._sun_light = light;

		var ambient_light = new AmbientLight(0xffffff);
		ambient_light.intensity=0.2;
		this._scene.add(ambient_light);
		this._ambient_light = ambient_light;

		this._camera_light = new PointLight(0xcccccc,0.5,0);
		this._camera_light.power = 8;
		this._scene.add(this._camera_light);

		//*** read storey data
		this._storeys = [];
		if (typeof json.storey_list != 'undefined')
			this._storeys = json.storey_list;

		this._storey_heights = [];
		if (typeof json.storey_heights != 'undefined')
			this._storey_heights = json.storey_heights;

		this._storey_orientation = 0;
		if (typeof json.storey_orientation == 'number')
			this._storey_orientation = json.storey_orientation;

		var d0= new Date();
		//**********************************************************
		//*** read instances
		this._instances = [];
		if (typeof(json.instances) == 'object')
		{
			console.log("Parsing " + json.instances.length + " instances...");
			for (var ii=0;ii<json.instances.length;ii++)
			{
				var mesh = json.instances[ii];

				//*** Create a new THREE geometry object
				var geometry = new Geometry();

				//*** Read vertices
				for (var i=0;i<mesh.vertices.length;i+=3)
					geometry.vertices.push(new Vector3(mesh.vertices[i],mesh.vertices[i+1],mesh.vertices[i+2]));

				//*** read faces
				for (var i=0;i<mesh.triangles.length-2;i+=3)
					geometry.faces.push( new Face3(mesh.triangles[i], mesh.triangles[i+1], mesh.triangles[i+2]));

				geometry.computeFaceNormals();

				this._instances.push(geometry);
			}
		}

		//*** Ground controller */
		this._ground_controller = null;
		if (typeof(json.topography) == 'object')
		{
			this._ground_controller = new fh_ground_controller(json.topography);
			this._scene.add(this._ground_controller);
		}

		//**********************************************************
		//*** read objects
		this._flats = [];
		this._spaces = [];
		this._products = [];
		this._objects_by_id = []
		this._meshes_by_view = [];
		this._all_meshes = [];
		this._edge_objects = [];

		console.log("Parsing " + json.objects.length + " objects...");
		for (var ii=0;ii<json.objects.length;ii++)
		{
			const obj = this.add_object(json.objects[ii]);
			if (obj && this._ground_controller)
			{
				if (json.objects[ii].topography == "map")
					this._ground_controller.ground_elements.push(obj);
				else if (json.objects[ii].topography == "upon")
					this._ground_controller.upon_ground_elements.push(obj);
				else if (json.objects[ii].topography == "above")
					this._ground_controller.above_ground_elements.push(obj);
			}
		}

		console.log("Flats : " + this._flats.length);
		console.log("Spaces : " + this._spaces.length);
		console.log("Products : " + this._products.length);
		console.log("Product types : ",Object.keys(this._product_types).length);
		console.log("Product types : ",this._product_types);
		console.log("merged meshes : ",this._merged_meshes,this._mergeable_meshes);

		//*** refresh selection
		this.set_selection_mode(this._selection_mode);

		//*** calcul des donnees englobantes
		this._bounding_box = new Box3().setFromObject(this._scene);
		this._bounding_box.min.x -= 0.1;
		this._bounding_box.min.y -= 0.1;
		this._bounding_box.min.z -= 0.1;
		this._bounding_box.max.x += 0.1;
		this._bounding_box.max.y += 0.1;
		this._bounding_box.max.z += 0.1;

		console.log("Bounding Box : ");
		console.log(this._bounding_box);
		this._scene_center = this._bounding_box.min.clone();
		this._scene_center.add(this._bounding_box.max);
		this._scene_center.multiplyScalar(0.5);
		var dd = this._bounding_box.max.clone();
		dd.sub(this._bounding_box.min);
		this._scene_radius = 0.5 * dd.length();

		var d1= new Date();
		console.log("Load scene in " + (0.001 * (d1.getTime() - d0.getTime())).toFixed(3) + " s.");

	};

	//***********************************************************************************
	/**
	 * Update bounding box
	 */
	update_bounding_box() {
		this._bounding_box = new Box3().setFromObject(this._scene);
		this._bounding_box.min.x -= 0.1;
		this._bounding_box.min.y -= 0.1;
		this._bounding_box.min.z -= 0.1;
		this._bounding_box.max.x += 0.1;
		this._bounding_box.max.y += 0.1;
		this._bounding_box.max.z += 0.1;
	}

	//***********************************************************************************
	//**** Scene initialization from bbo
	//***********************************************************************************

	load_bbo(source, size_ratio = [1, 1, 1], draw_contact = false) {
        const json = {};
        json.objects = [source];

        if (draw_contact) {
            const inst = source.instanciations && source.instanciations.length ? source.instanciations[0] : null;
            if (inst && inst.anchor_point && inst.anchor_normal) {
                const upaxis = (inst.up_axis && Math.abs(1 - fh_size(inst.up_axis)) < 0.01) ? inst.up_axis : [0, 0, 1];
                const dx = fh_cross(upaxis, inst.anchor_normal);
                if (fh_normalize(dx) < 0.01) fh_copy(dx, [1, 0, 0]);
                const dy = fh_cross(inst.anchor_normal, dx);
                const anchor_point = [inst.anchor_point[0] * size_ratio[0],inst.anchor_point[1] * size_ratio[1],inst.anchor_point[2] * size_ratio[2]];

                const box = new fh_box();
                for (let i in source.geometries) {
                    const geo = source.geometries[i];
                    for (let k = 0; k < geo.vertices.length; k += 3) {
                        const p = fh_sub([size_ratio[0]*geo.vertices[k], size_ratio[1]*geo.vertices[k + 1], size_ratio[2] * geo.vertices[k + 2]], anchor_point);
                        box.enlarge_point([fh_dot(p, dx), fh_dot(p, dy), 0]);
                    }
                }

                const coutour = [];
                coutour.push(fh_add(anchor_point, fh_add(fh_mul(dx, box.position[0] - 0.1), fh_mul(dy, box.position[1] - 0.1))));
                coutour.push(fh_add(anchor_point, fh_add(fh_mul(dx, box.position[0] + box.size[0] + 0.1), fh_mul(dy, box.position[1] - 0.1))));
                coutour.push(fh_add(anchor_point, fh_add(fh_mul(dx, box.position[0] + box.size[0] + 0.1), fh_mul(dy, box.position[1] + box.size[1] + 0.1))));
                coutour.push(fh_add(anchor_point, fh_add(fh_mul(dx, box.position[0] - 0.1), fh_mul(dy, box.position[1] + box.size[1] + 0.1))));

                const color = (inst.anchor_normal[2] > 0.5) ? [0, 0, 1, 0.2] : (inst.anchor_normal[2] < -0.5) ? [0, 1, 0, 0.2] : [1, 0, 0, 0.2];
                const geometry = {vertices: coutour.flat(), triangles: [0, 1, 2, 0, 2, 3], color: color};
                json.objects.push({ID: "contact", Code_BIM: "misc", geometries: [geometry]});
            }
        }

        this.load_json(json);
	}

	//***********************************************************************************
	//**** Merge to a given json object
	//***********************************************************************************

	merge_to_object(json_mesh, object) {
		if (!object) return false;
		if (typeof(json_mesh.views) == 'object' && json_mesh.views.length != 1) return false;

		var view = "3d";
		if (typeof(json_mesh.views) == 'object') view = json_mesh.views[0];

		var mesh = null;
		for (var i in object._meshes)
		{
			if (object._meshes[i].view != view) continue;
			if (object._meshes[i].material.color.r != json_mesh.color[0]) continue;
			if (object._meshes[i].material.color.g != json_mesh.color[1]) continue;
			if (object._meshes[i].material.color.b != json_mesh.color[2]) continue;
			var opacity = (json_mesh.color.length >= 4)?json_mesh.color[3]:1;
			if (object._meshes[i].material.opacity != opacity) continue;
			mesh = object._meshes[i];
			break;
		}

		if (mesh == null)
		{
			var material = new MeshPhongMaterial();
			material.side = DoubleSide;
			material.color.r = json_mesh.color[0];
			material.color.g = json_mesh.color[1];
			material.color.b = json_mesh.color[2];
			if (json_mesh.color.length >= 4)
			{
				var opacity = json_mesh.color[3];
				if (opacity < 0.95)
				{
					material.transparent=true;
					material.opacity=opacity;
				}
			}
			var geometry = new Geometry();

			mesh = new Mesh( geometry, material );

			mesh._original_material = material.clone();
			mesh.view = view;
			object._meshes.push(mesh);
			object.add(mesh);

			var lst = this._meshes_by_view[view];
			if (typeof lst == 'undefined')
			{
				this._meshes_by_view[view] = [];
				lst = this._meshes_by_view[view];
			}
			lst.push(mesh);
			mesh.visible = true;
			this._all_meshes.push(mesh);
			this._merged_meshes++;
		}

		var geometry = mesh.geometry;
		var offset = geometry.vertices.length;

		//*** Read vertices
		for (var n=0;n<json_mesh.vertices.length;n+=3)
			geometry.vertices.push(new Vector3(json_mesh.vertices[n],json_mesh.vertices[n+1],json_mesh.vertices[n+2]));

		//*** read faces
		for (var n=0;n<json_mesh.triangles.length-2;n+=3)
			geometry.faces.push( new Face3(json_mesh.triangles[n]+offset, json_mesh.triangles[n+1]+offset, json_mesh.triangles[n+2]+offset));

		geometry.computeFaceNormals();
		geometry.verticesNeedUpdate = true;
		geometry.elementsNeedUpdate = true;
		return true;
	}

	//***********************************************************************************
	//**** Add a Json object (product, space or flat)
	//***********************************************************************************
	add_object(json_object) {
		try {
		//*** If object already exists, we just change its name
		var object = this._objects_by_id[json_object.ID];
		if (typeof(object) != 'undefined')
		{
			if (typeof(json_object.Name) == 'string')
				object.name = json_object.Name;
			return object;
		}

		//*** register object
		object = new fh_object(json_object);
		this._objects_by_id[object.BIMID] = object;

		var merged_object = null;

		//*** type dependant settings
		if (object.bim_type == "space")
		{
			//*** register storey
			if (typeof(json_object.ETAGE))
			{
				object.storey = parseInt(json_object.ETAGE);
				var string_storey = "" + object.storey;
				var found = false;
				var index_3d = -1;
				for (var jj=0;jj<json_object.geometries.length;jj++)
				{
					if (typeof(json_object.geometries[jj].views) != 'object') continue;
					if (json_object.geometries[jj].views.indexOf(string_storey) >= 0)
					{
						found = true;
						break;
					}
					if (index_3d < 0 && json_object.geometries[jj].views.indexOf("3d") >= 0)
						index_3d = jj;
				}
				if (!found && index_3d>=0)
					json_object.geometries[index_3d].views.push(string_storey);
			}
			else
				object.storey = 0;

			var id_flat = json_object.LOT;
			if (typeof(id_flat) == 'undefined') id_flat = 'undefined_flat';

			var flat = this.add_object({"ID":id_flat,"Code_BIM":"FLAT"});
			flat.add(object);
			this._spaces.push(object);
		}
		else if (object.bim_type == "flat")
		{
			this._flats.push(object);
			this._building.add(object);
		}
		else
		{
			var product_type_object = this.find_product_type(json_object.Code_BIM, json_object.LOD);

			if (this._merge_spaceless_products)
				merged_object = product_type_object._merged;

			this._products.push(object);
			product_type_object.add(object);
			object.visible = product_type_object.tmp_visible;
		}

		if (typeof(json_object.VISIBILITY) != 'undefined' && !json_object.VISIBILITY)
			object.visible = false;

		if (typeof(json_object.geometries) == 'undefined') return object;
		if (json_object.geometries.length==0) return object;

		for (var jj=0;jj<json_object.geometries.length;jj++)
		{
			var json_mesh = json_object.geometries[jj];
			var geometry;
			var matrix = new Matrix4();
			var use_matrix = false;
			if (typeof json_mesh.vertices !== 'undefined' && json_mesh.vertices.length > 0)
			{
				if (merged_object && this.merge_to_object(json_mesh,merged_object))
				{
					this._mergeable_meshes++;
					continue;
				}

				geometry = new Geometry();

				//*** Read vertices
				for (var i=0;i<json_mesh.vertices.length;i+=3)
					geometry.vertices.push(new Vector3(json_mesh.vertices[i],json_mesh.vertices[i+1],json_mesh.vertices[i+2]));

				//*** read faces
				for (var i=0;i<json_mesh.triangles.length-2;i+=3)
					geometry.faces.push( new Face3(json_mesh.triangles[i], json_mesh.triangles[i+1], json_mesh.triangles[i+2]));

				geometry.computeFaceNormals();
				fh_scene._compute_geometry_uvs(geometry);
			}
			else if (this._instances[json_mesh.instance] !== undefined)
			{
				//console.log("instance geometry " + json_mesh.mesh);
				geometry = this._instances[json_mesh.instance];
				//console.log(geometry);
				matrix.fromArray(json_mesh.matrix);
				use_matrix = true;
			}
			else
			{
				console.log("undefined instance");
				continue;
			}

			//*** read material
			var mesh = null;
			var back_mesh = null;
			if (object.bim_type == "space")
			{
				var material = new MeshBasicMaterial();
				material.side = DoubleSide;
				material.color.r = 0;
				material.color.g = 1;
				material.color.b = 0;
				material.transparent=true;
				material.opacity=0;
				material.depthTest=false;
				material.depthWrite=false;

				mesh = new Mesh( geometry, material );
				mesh['_original_material'] = material.clone();
			}
			else
			{
				var material = new MeshPhongMaterial();
				material.side = DoubleSide;

				if (typeof(json_mesh.texture) == "string" && json_mesh.texture != "")
				{
					var map = fh_load_lazy_texture(json_mesh.texture);
					if (map)
					{
						material["texture_url"] = json_mesh.texture;
						material.map = map;
					}
				}

				material.color.r = json_mesh.color[0];
				material.color.g = json_mesh.color[1];
				material.color.b = json_mesh.color[2];
				if (json_mesh.color.length >= 4)
				{
					var opacity = json_mesh.color[3];
					if (opacity < 0.95)
					{
						material.transparent=true;
						material.opacity=opacity;
					}
				}
				mesh = new Mesh( geometry, material );
				mesh['_original_material'] = material.clone();

				if (typeof json_mesh.back_color !== 'undefined')
				{
					material = new MeshPhongMaterial();
					material.side = FrontSide;
					material = new MeshPhongMaterial();
					material.side = BackSide;
					material.color.r = json_mesh.back_color[0];
					material.color.g = json_mesh.back_color[1];
					material.color.b = json_mesh.back_color[2];
					if (json_mesh.back_color.length >= 4)
					{
						var opacity = json_mesh.back_color[3];
						if (opacity < 0.95)
						{
							material.transparent=true;
							material.opacity=opacity;
						}
					}
					back_mesh = new Mesh( geometry, material );
					back_mesh['_original_material'] = material.clone();
				}
			}

			//*** read layers
			if (typeof json_mesh.views !== 'undefined')
			{
				mesh['views'] = json_mesh.views;
				mesh['_is_3d']=(json_mesh.views.indexOf("3d") >= 0);
				for (var k in json_mesh.views)
				{
					if (json_mesh.views[k] != "3d" && isNaN(parseInt(json_mesh.views[k]))) continue;
					var lst = this._meshes_by_view[json_mesh.views[k]];
					if (typeof lst == 'undefined')
					{
						this._meshes_by_view[json_mesh.views[k]] = [];
						lst = this._meshes_by_view[json_mesh.views[k]];
					}
					lst.push(mesh);
					if (json_mesh.views[k] != "3d")
					{
						var storey = parseInt(json_mesh.views[k]);
						if (storey > object.max_storey)
							object.max_storey = storey;
					}
				}
			}
			else
			{
				var lst = this._meshes_by_view["3d"];
				if (typeof lst == 'undefined')
				{
					this._meshes_by_view["3d"] = [];
					lst = this._meshes_by_view["3d"];
				}
				lst.push(mesh);
				mesh['_is_3d']=true;
			}
			this._all_meshes.push(mesh);

			//*** create mesh
			if (use_matrix)
				mesh.applyMatrix(matrix);
			mesh["castShadow"] = true;
			mesh["receiveShadow"] = true;
			mesh["_use_matrix"] = use_matrix;
			object.add( mesh );
			object._meshes.push(mesh);
			if (back_mesh)
			{
				if (use_matrix)
					back_mesh.applyMatrix(matrix);
				back_mesh._use_matrix = use_matrix;
				object.add( back_mesh );
				object._meshes.push(back_mesh);
			}

			if (this._build_edges && object.bim_type != "space")
			{
				var edge_geometry = new EdgesGeometry(mesh.geometry,1);
				var edges = new LineSegments( edge_geometry,this._edge_material );
				//edges.applyMatrix(matrix);
				mesh.add(edges);
				mesh._edges = edges;
				this._edge_objects.push(edges);
			}
		}
		return object;
		}
		catch(err)
		{
			console.error(err);
			return null;
		}
	}

    /**
     * Clean removal of an object
     * @param {object} object;
     */
	remove_object(object) {
		if (!object) return;

		//*** remove from THREE
		object.parent.remove(object);

		//*** remove form id list
		if (this._objects_by_id[object.BIMID])
			this._objects_by_id[object.BIMID] = undefined;

		//*** remove from typed lists
		var  index = this._spaces.indexOf(object);
		if (index >= 0) this._spaces.splice(index,1);
		index = this._flats.indexOf(object);
		if (index >= 0) this._flats.splice(index,1);
		index = this._products.indexOf(object);
		if (index >= 0) this._products.splice(index,1);

		//*** remove meshes
		object._meshes.forEach(mesh => {
			let index = this._all_meshes.indexOf(mesh);
			if (index >= 0) this._all_meshes.splice(index,1);
			if (mesh["_edges"])
			{
				index = this._edge_objects.indexOf(mesh["_edges"]);
				if (index >= 0) this._edge_objects.splice(index,1);
			}
			let lst = this._meshes_by_view["3d"];
			if (lst)
			{
				index = lst.indexOf(mesh);
				if (index >= 0) lst.splice(index,1);
			}
			if (mesh["views"])
			{
				mesh["views"].forEach(v => {
					var lst = this._meshes_by_view[v];
					if (lst)
					{
						index = lst.indexOf(mesh);
						if (index >= 0) lst.splice(index,1);
					}
				});
			}
		});
	}

	/** Build uvs for the geometry */
	static _compute_geometry_uvs(geometry) {
		geometry.faceVertexUvs[0] = [];
		geometry.faces.forEach( face => {
			const v0 = geometry.vertices[face.a];
			const v1 = geometry.vertices[face.b];
			const v2 = geometry.vertices[face.c];
			var axis = "x";
			if (Math.abs(face.normal.x) > Math.abs(face.normal.y))
			{
				if (Math.abs(face.normal.x) > Math.abs(face.normal.z))
					axis = "x";
				else axis = "z";
			}
			else if (Math.abs(face.normal.y) > Math.abs(face.normal.z))
				axis = "y";
			else
				axis = "z";
			var inext = (axis=="x")?"y":"x";
			var jnext = (axis=="z")?"y":"z";
			geometry.faceVertexUvs[0].push([new Vector2(v0[inext],v0[jnext]), new Vector2(v1[inext],v1[jnext]), new Vector2(v2[inext],v2[jnext])]);
		});
		geometry.uvsNeedUpdate = true;
	}

    /**
     * Useful method to set an object geometry
     * @param {object} mesh;
     * @param {Array<number[]>} vertices
     * @param {Array<number>} triangles
     */
    static update_mesh_geometry(mesh, vertices, triangles) {
        const geometry = new Geometry();

        //*** Read vertices
        vertices.forEach(v =>
            geometry.vertices.push(new Vector3(v[0],v[1],v[2]))
        );

        //*** read faces
        for (var i=0;i<triangles.length-2;i+=3)
            geometry.faces.push( new Face3(triangles[i], triangles[i+1], triangles[i+2]));

        geometry.computeFaceNormals();

		fh_scene._compute_geometry_uvs(geometry);

        mesh.geometry = geometry;

        if (mesh._edges)
        {
            mesh._edges.geometry = new EdgesGeometry(geometry,1);
        }
    }

    /**
     * Useful method to set an object color
     * @param {object} mesh;
     * @param {Array<number>} color
     * @param {string} texture
     */
    static update_mesh_color(mesh, color, texture = "") {
		const col_three = new Color();
		col_three.r = color[0];
		col_three.g = color[1];
		col_three.b = color[2];
        mesh._original_color = col_three;
        mesh._original_opacity = (color.length > 3)?color[3]:1;
        mesh._original_material.color = col_three;
        mesh._original_material.opacity = (color.length > 3)?color[3]:1;
        mesh._original_material.transparent = (mesh._original_opacity < 0.9);
		if (texture != "")
		{
			if (!mesh._original_material["texture_url"] || mesh._original_material["texture_url"] != texture)
			{
				var map = fh_load_lazy_texture(texture);
				if (map)
				{
					mesh._original_material["texture_url"] = texture;
					mesh._original_material.map = map;
				}
			}
		}
		else
		{
			mesh._original_material.map = null;
		}
		mesh.material = mesh._original_material;
    }

    /**
     * Useful method to set an object matrix
     * @param {object} object;
     * @param {Array<number>} matrix
     */
    static update_object_matrix(object, matrix) {
		var threematrix = new Matrix4();
		threematrix.fromArray(matrix);

		object._meshes.forEach(mesh => {
			mesh.matrixAutoUpdate = false;
			mesh.matrix = threematrix.clone();
		});
    }

	find_product_type(bim_type, lod)
	{
		var pto = this._product_types[bim_type];
		if (typeof(pto) == 'object') return pto;

		pto = new Object3D();

		if (typeof(lod) == 'undefined') lod = 0;
		if (lod < 0) lod = 0;
		else if (lod > FH3D_MAX_LOD) lod = FH3D_MAX_LOD;

		pto.LOD = lod;
		pto.tmp_LOD = lod;
		pto.visible=true;
		pto.tmp_visible = true;
		this._product_types[bim_type] = pto;
		this._scene.add(pto);

		if (this._merge_spaceless_products)
		{
			pto._merged = new Object3D();
			pto.add(pto._merged);
			pto._merged._meshes =[];
		}
		return pto;
	}
	//***********************************************************************************
	//**** Load flats
	//***********************************************************************************

	set_flats(flats)
	{
		console.log("settings flats");
		if (this._flats.length > 1) return;
		if (this._flats.length == 1 && this._flats[0].BIMID != "undefined_flat") return;

		for (var i in flats)
		{
			if (typeof(flats[i].id) != "number" && typeof(flats[i].id) != "string")
			{
				console.log("Warning in this.load_flats on 'flat[" + i + "]' !!! id not defined or not a number nor a string !!!");
				console.log(typeof(flats[i].id));
				continue;
			}
			if (typeof(flats[i].spaces) != "object")
			{
				console.log("Warning in this.load_flats on 'flat[" + i + "]' !!! flat.spaces not defined or not an object !!!");
				console.log(typeof(flats[i].spaces));
				continue;
			}

			var flat = this.add_object({"ID":flats[i].id,"Code_BIM":"FLAT"});
			console.log("reading flat " + flats[i].id,flat);

			for (var j in flats[i].spaces)
			{
				var space = this._objects_by_id[flats[i].spaces[j]];
				if (typeof(space) != "object")
				{
					console.log("Warning in fh_scene.set_flats on 'flat[" + i + "]' !!! Space '" + flats[i].spaces[j] + "' unknown");
					continue;
				}
				if (space.bim_type != "space")
				{
					console.log("Warning in fh_scene.set_flats on 'flat[" + i + "]' !!! Item '" + flats[i].spaces[j] + "' is not a space");
					continue;
				}
				space.parent.remove(space);
				flat.add(space);
			}
			console.log("New flat : ",flat);
		}
	}

	//***********************************************************************************
	//**** Set selection mode
	//***********************************************************************************

	set_selection_mode(selection_mode)	{
		this.clear_selection();

		this._selection_mode = selection_mode;
		var flat_selection = (selection_mode == 1);
		for (var i in this._flats)
		{
			this._flats[i].selectable = flat_selection;
			this._flats[i].visible = flat_selection;
		}
		var space_selection = (selection_mode == 2);
		for (var i in this._spaces)
		{
			this._spaces[i].selectable = space_selection;
		}
		var product_selection = (selection_mode == 3);
		for (var i in this._products)
		{
			this._products[i].selectable = product_selection;
		}
		if (this._current_flat)
			this.set_current_flat(this._current_flat.BIMID);
		else if (this._all_flats)
			this.set_current_flat("all");
		else
			this.set_current_flat("");

		if (this._current_space)
			this.set_current_space(this._current_space.BIMID);
		else
			this.set_current_space("");
	}


	get_selection_mode() {
		return this._selection_mode;
	}

	//***********************************************************************************
	//**** Set List of selectable objects
	//***********************************************************************************
	set_selectable_objects(object_id_list)
	{
		this.clear_selection();
		for (var i in this._objects_by_id)
		{
			this._objects_by_id[i].selectable = false;
		}
		for (var i in object_id_list)
		{
			var objectToSelect = this._objects_by_id[object_id_list[i]];
			if (objectToSelect)
				objectToSelect.selectable = true;
		}
	}

	//***********************************************************************************
	/**
	 * Clear selection
	 */
	clear_selection()	{
		for (var i in this._selection)
		{
			this._selection[i].selected = false;
			this.refresh_object(this._selection[i]);
		}
		this._selection = [];
	}

	//***********************************************************************************
	/**
	 * Select an object (flat, space, product)
	 * @param {string} object_id
	 * @param {boolean} add_to_selection
	 * @param {boolean} call_callbacks
	 * @returns
	 */
	select_object(object_id, add_to_selection, call_callbacks=false)	{
		//*** check object
		if (this._selection_stealer && this._selection_stealer(object_id, add_to_selection))
			return;

		var obj = this._objects_by_id[object_id];
		if (typeof(obj) != 'object') return;

		//*** maybe clear selection
		if (!add_to_selection)
			this.clear_selection();

		//*** either add, either remove to selection
		if (obj.selected)
		{
			obj.selected = false;
			var index = this._selection.indexOf(obj);
			if (index >= 0) this._selection.splice(index,1);
		}
		else
		{
			obj.selected = true;
			obj.visible = true;
			this._selection.push(obj);
		}

		//*** refresh object visibility
		this.refresh_object(obj);

		if (call_callbacks && typeof(this._cb_selection_changed) == 'function')
		{
			var ids = [];
			for (var i in this._selection)
				ids.push(this._selection[i].BIMID);
			this._cb_selection_changed(ids);
		}
	}

	//***********************************************************************************
	/**
	 * Select a list of items (flats, spaces, products)
	 * @param {string[]} selection_list
	 */
	set_selection(selection_list) {
		this.clear_selection();
		for(var i in selection_list)
			this.select_object(selection_list[i],true);
	}

	//***********************************************************************************
	/**
	 * Set mouseover
	 * @param {object} object
	 * @returns
	 */
	set_mouseover_object(object) {
		if (this._mouseover_object == object) return;

		var old_mouseover_object = this._mouseover_object;
		this._mouseover_object = object;

		if (old_mouseover_object)
			this.refresh_object(old_mouseover_object);

		if (this._mouseover_object)
		{
			this.refresh_object(this._mouseover_object);
			this._cb_mouseover_changed(this._mouseover_object.BIMID);
		}
		else
			this._cb_mouseover_changed(null);
	}

	//***********************************************************************************
	//**** Set current flat
	//***********************************************************************************
	set_current_flat(flat_id)
	{
		if (flat_id == "all")
		{
			this._all_flats = true;
			this._current_flat = null;
			for (var i in this._flats) {
				this._flats[i].visible = true;
			}
			return;
		}

		this._all_flats = false;
		if (this._current_flat)
			this._current_flat.visible = (this._selection_mode == 1);

		this._current_flat = this._objects_by_id[flat_id];
		if (typeof(this._current_flat) == 'undefined' || this._current_flat.bim_type != "flat")
			this._current_flat = null;
		else
			this._current_flat.visible = true;

		for (var i in this._spaces)
			this.refresh_object(this._spaces[i]);
	}

	//***********************************************************************************
	//**** Set current space
	//***********************************************************************************
	set_current_space(space_id)
	{
		if (this._current_space)
		{
			var flat = this._current_space.get_parent_flat();
			if (flat)
				flat.visible = (this._selection_mode == 1 || flat == this._current_flat || flat.selected || this._all_flats);
		}

		this._current_space = this._objects_by_id[space_id];
		if (typeof(this._current_space) == 'undefined' || this._current_space.bim_type != "space")
			this._current_space = null;
		else
		{
			var flat = this._current_space.get_parent_flat();
			if (flat)
				flat.visible = true;
		}

		for (var i in this._spaces)
			this.refresh_object(this._spaces[i]);
	}

	//********************************************************
	//*** Refresh object material depending on selection status
	//********************************************************
	refresh_object(object)
	{
		if (object == null) return;

		//*** special case for flats
		if (object.bim_type == "flat")
		{
			for (var i in object.children)
				this.refresh_object(object.children[i]);
			return;
		}

		var material = null;

		//*** special case for spaces
		if (object.bim_type == "space")
		{
			var flat = object.get_parent_flat();
			if (object == this._mouseover_object || (flat && flat == this._mouseover_object))
				material = this._space_highlight_material;
			else if (object.selected || (flat && flat.selected))
				material = this._space_select_material;
			else if (object == this._current_space || (flat && flat == this._current_flat))
				material = this._space_selectable_material;

			if (material && flat) flat.visible = true;
		}
		//*** For products
		else
		{
			if (object == this._mouseover_object)
				material = this._object_highlight_material;
			else if (object.selected)
				material = this._object_select_material;
		}

		//*** apply to object meshes
		for (var i in object._meshes)
		{
			object._meshes[i].material=(material)?material:object._meshes[i]._original_material;
		}
	}

	//***********************************************************************************
	//**** Build intersection with plane
	//***********************************************************************************
	build_plane_intersection(normal,plane, material, line_material = null) {

		//*** Check intersection between plane and a bounding box */
		function intersects_bb(bb)
		{
			var p0 = new Vector3();
			p0.x = (normal.x)>0?bb.min.x:bb.max.x;
			p0.y = (normal.y)>0?bb.min.y:bb.max.y;
			p0.z = (normal.z)>0?bb.min.z:bb.max.z;
			var p1 = new Vector3();
			p1.x = (normal.x)>0?bb.max.x:bb.min.x;
			p1.y = (normal.y)>0?bb.max.y:bb.min.y;
			p1.z = (normal.z)>0?bb.max.z:bb.min.z;
			var f0 = normal.dot(p0) - plane;
			var f1 = normal.dot(p1) - plane;
			if (f0 >= 0 && f1>= 0) return false;
			if (f0 <= 0 && f1<= 0) return false;
			return true;
		}

		var fh_normal = [normal.x,normal.y,normal.z];
		var fh_point = fh_mul(fh_normal,plane);
		var dx = fh_cross(fh_normal,[0,0,1]);

		var output_object = new Object3D();;

		//*** Loop on products */
		for (var i in this._products)
		{
			//*** Check product intersection */
			var object =  this._products[i];
			if (typeof(object._bounding_box) == 'undefined')
				object._bounding_box = new Box3().setFromObject(object);
			if (!intersects_bb(object._bounding_box)) continue;

			var polygons = [];
			var pair_list = [];

			//*** Loop on meshes */
			for (var j in object._meshes)
			{
				//*** Check intersection with bounding box */
				var mesh =  object._meshes[j];
				if (!mesh._is_3d) continue;

				if (typeof(mesh._bounding_box) == 'undefined')
					mesh._bounding_box = new Box3().setFromObject(mesh);
				if (!intersects_bb(mesh._bounding_box)) continue;

				//***First compute position of each vertex / plane */
				var geometry = object._meshes[j].geometry;
				var pos_list = [];
				var value_neg = false;
				var value_pos = false;

				var vertices = geometry.vertices;
				if (mesh._use_matrix) {
					vertices = [];
					for (var nv in geometry.vertices)
					{
						var vtx = geometry.vertices[nv].clone();
						vtx.applyMatrix4(mesh.matrixWorld);
						vertices.push(vtx);
					}
				}

				for (var k in vertices)
				{
					var x = normal.dot(vertices[k]) - plane;
					if (x<0) value_neg = true;
					if (x>0) value_pos = true;
					pos_list.push(x);
				}
				//*** End if all vertices are on the same side */
				if (!value_neg || !value_pos) continue;

				//*** Build a list of pairs of points, intersections of plance wioth faces */
				for (var k in geometry.faces)
				{
					//*** Check if all vertices of the face are on the same side of the plane */
					var xx = [pos_list[geometry.faces[k].a], pos_list[geometry.faces[k].b], pos_list[geometry.faces[k].c]];
					if (xx[0] <= 0 && xx[1] <= 0 && xx[2] <= 0) continue;
					if (xx[0] >= 0 && xx[1] >= 0 && xx[2] >= 0) continue;

					//*** Build intersection between plane and face */
					var vts = [vertices[geometry.faces[k].a],vertices[geometry.faces[k].b],vertices[geometry.faces[k].c]];
					var pair = [];
					for (var kk=0;kk<3;kk++)
					{
						var k1 = ((kk+1)%3);
						if (xx[kk] < 0 &&xx[k1] < 0) continue;
						if (xx[kk] > 0 &&xx[k1] > 0) continue;
						var t = -xx[kk]/(xx[k1] - xx[kk]);
						var p = [(1-t) * vts[kk].x + t * vts[k1].x,(1-t) * vts[kk].y + t * vts[k1].y,(1-t) * vts[kk].z + t * vts[k1].z];
						pair.push(p);
					}

					//*** Check that pair has 2 vertces */
					if (pair.length == 2)
						pair_list.push(pair);
				}
			}

			//*** Check that there are at least 3 pairs */
			if (pair_list.length <= 2) continue;

			//*** We are going to build contours out of connected pairs */
			var contour = [];
			while (pair_list.length > 0)
			{
				//*** Start contour with first pair */
				if (contour.length == 0)
				{
					contour.push(pair_list[0][0]);
					contour.push(pair_list[0][1]);
					pair_list.splice(0,1);
					continue;
				}

				//*** Initialize contou continuation with closure */
				var last_vtx = contour[contour.length-1];
				var dst_min = fh_dist(contour[0],last_vtx);

				//*** Find best matchingpair */
				var best_index = -1;
				var best_elt = -1;
				for (var kk=0;kk<pair_list.length;kk++)
				{
					for (var niter=0;niter<2;niter++)
					{
						var dst = fh_dist(last_vtx,pair_list[kk][niter]);
						if (dst >= dst_min) continue;
						best_index = kk;
						best_elt = niter;
						dst_min = dst;
					}
				}

				//*** Maybe we close the contour */
				if (best_index < 0 || pair_list.length == 1)
				{
					if (best_index >= 0)
						pair_list.splice(best_index,1);
					var polygon = new fh_polygon(fh_point,fh_normal);
					polygon.add_contour(contour);
					polygon.compute_tesselation();
					if (polygon.tesselation_triangles.length > 0)
						polygons.push(polygon);
					contour = [];
					continue;
				}

				//*** Or we continue the contour */
				contour.push(pair_list[best_index][1-best_elt]);
				pair_list.splice(best_index,1);
			}

			if (polygons.length == 0) continue;

			//*** Create an object for the product */
			var obj = new Object3D();
			output_object.add(obj);

			//*** Add polygon tesselations */
			for (var j in polygons)
			{
				var polygon = polygons[j];
				var geometry = new Geometry();

				//*** Read vertices
				var uvs = [];
				for (kk=0;kk<polygon.tesselation_vertices.length;kk++)
				{
					var v = polygon.tesselation_vertices[kk];
					geometry.vertices.push(new Vector3(v[0],v[1],v[2]));
					uvs.push(new Vector2(fh_dot(v,dx),v[2]));
				}

				//*** read faces
				for (kk=0;kk<polygon.tesselation_triangles.length-2;kk+=3)
				{
					geometry.faces.push( new Face3(polygon.tesselation_triangles[kk], polygon.tesselation_triangles[kk+1], polygon.tesselation_triangles[kk+2]));
					geometry.faceVertexUvs[0].push( [uvs[polygon.tesselation_triangles[kk]],uvs[polygon.tesselation_triangles[kk+1]],uvs[polygon.tesselation_triangles[kk+2]]]);
				}

				geometry.computeFaceNormals();

				var mesh = new Mesh(geometry,material);
				obj.add(mesh);

				if (line_material)
				{
					var edge_geometry = new EdgesGeometry(mesh.geometry,1);
					var edges = new LineSegments( edge_geometry,line_material);
					obj.add(edges);
				}
			}
		}
		return output_object;
	}
}

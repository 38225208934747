import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateChoiceBoxes } from '../../../../../model/states.model';
import { Icon } from 'src/app/commons-lib';
import { DocumentCheckpoint } from 'src/app/model/type-document.model';
import { TypeCommentaire } from '../../../../../model/type-commentaire.model';

@Component({
    selector: 'app-documents-detail-checkpoint',
    templateUrl: './documents-detail-checkpoint.component.html',
    styleUrls: ['./documents-detail-checkpoint.component.scss'],
})
export class DocumentsDetailCheckpointComponent implements OnInit {
    @Input() checkpoint: DocumentCheckpoint;
    @Input() readonlyMode = false;

    @Output() changedCheckpointValue = new EventEmitter<any>();

    validOption = new StateChoiceBoxes('Valide', true, new Icon('check_circle'), 'ok');
    invalidOption = new StateChoiceBoxes('Invalide', false, new Icon('cancel'), 'ko');

    choices: StateChoiceBoxes[] = [this.validOption, this.invalidOption];
    typeCommentaire: TypeCommentaire[] = [
        'NOTE_PERSONNELLE',
        'REMARQUE',
        'JUSTIFICATION_NON_CONFORMITE',
        'CONSTATATION_DIVERSE',
    ];
    constructor() {}

    ngOnInit(): void {}

    onChangeCheckpointValue() {
        this.changedCheckpointValue.emit();
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenshotSvg } from '../../../model/screenshot-svg.model';
import { PlanRapport } from '../../diagnostics/assainissement/model/assainissement.model';

@Component({
    selector: 'app-plans-list',
    templateUrl: './plans-list.component.html',
    styleUrls: ['./plans-list.component.scss'],
})
export class PlansListComponent implements OnInit {
    selectedScreenshotsIds: string[] = [];

    @Input() enableSelection = false;

    @Input() showActionbutton = false;

    /**
     * Liste des screenshots déjà sélectionnés
     * @param screenshots
     */
    @Input()
    set selectedScreenshots(screenshots: PlanRapport[]) {
        this._selectedScreenshots = screenshots;
        this.selectedScreenshotsIds = this._selectedScreenshots.map((s) => s.id);
    }

    /**
     * Liste des screenshots disponibles
     */
    @Input() screenshotsSvg: ScreenshotSvg[];

    @Output() clickDeleteScreenshot = new EventEmitter<ScreenshotSvg>();

    @Output() clickEditScreenshot = new EventEmitter<ScreenshotSvg>();

    @Output() selectionChanged = new EventEmitter<PlanRapport[]>();

    private _selectedScreenshots: PlanRapport[] = [];

    constructor() {}

    ngOnInit(): void {}

    /**
     * Click sur suppression d'un screenshot
     * @param screenshot
     */
    onClickDeleteScreenshot(screenshot: any) {
        this.clickDeleteScreenshot.emit(screenshot);
    }

    /**
     * Changement de sélection
     * @param checked
     * @param screenshot
     */
    changeSelection(checked: boolean, screenshot: ScreenshotSvg) {
        let screenshots: PlanRapport[] = [];
        if (checked) {
            // Ajout d'un screenshot à la sélection
            if (!this.selectedScreenshotsIds.includes(screenshot.id)) {
                screenshots = this._selectedScreenshots.concat(new PlanRapport(screenshot.id, screenshot.nom));
            }
        } else {
            // Enlève un screenshot à la sélection
            if (this.selectedScreenshotsIds.includes(screenshot.id)) {
                screenshots = this._selectedScreenshots.filter((s) => s.id !== screenshot.id);
            }
        }

        this._selectedScreenshots = screenshots;
        // MAJ de la liste d'id des screenshots sélectionnés
        this.selectedScreenshotsIds = this._selectedScreenshots.map((s) => s.id);

        this.selectionChanged.emit(screenshots);
    }

    onClickEditScreenshot(screenshot: ScreenshotSvg) {
        this.clickEditScreenshot.emit(screenshot);
    }
}

import { MongoUtils } from 'src/app/commons-lib';
import { Sequence } from './sequence.model';

export class Besoin {
    id: string = MongoUtils.generateObjectId();
    objectifMesurage?: any = {};
    numero?: string = '';
    pieceId?: string = '';
    precision?: string = '';
    emplacement?: string = '';
    justificatifLocalisation?: string = '';
    justificatifAutre?: string = '';
    dureeMinPrelevement?: string = '';
    commentaire?: string = '';
    mpcaId?: string = '';
    processusId: string = '';
    contexteMesure: string = '';
    simulation?: boolean = undefined;
    conditionPrelevement?: any;
    listeSequences?: Sequence[] = [];
    zoneId?: string = '';
    frequencePrelevement: string = '';
    frequencePrelevementAutre: string = '';
    interventionId: string;
    todo: boolean;
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** a tool to build stairs
//***********************************************************************************
//***********************************************************************************

import {cn_clone} from "../utils/cn_utilities";
import {cn_stairs} from "../model/cn_stairs";
import {cn_disk_handler} from "./cn_disk_handler";
import {cn_polygon_handler} from "./cn_polygon_handler";
import {cn_svg_tool_creation} from "./cn_svg_tool_creation";
import {cn_stairs_handler} from "./cn_stairs_handler";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_element} from "../model/cn_element";
import {cn_edition_handler} from "./cn_edition_handler";

export class cn_svg_tool_stairs_creation extends cn_svg_tool_creation {
	constructor(map, default_stair_type=0) {
		super(map);

		this.stairs_type = default_stair_type;
		this.stair_width = 1;
		this.stair_parameter = {key:'stair_height',value:0.2};

		this._handler = null;
		this._tmp_stairs = null;
		this.initiate_creation();
		this.element_filter = function(element) {return element.constructor == cn_stairs;};
	}

	/**
	 * Open method
	 */
	 open_tool() {
		super.open_tool();
		this.initiate_creation();
	}

	close_tool() {
		super.close_tool();
		this._terminate_edition();
	}

	//*****************************************************************
	//*** Parameters
	//*****************************************************************
	/**
	 * Returns current stairs type:
	 * - 0 = polyline
	 * - 1 = round
	 * - 2 = single line
	 * - 3 = U shape
	 * @returns {number}
	 */
	get_stairs_type() {
		return this.stairs_type;
	}

	/**
	 * Sets current stair type
	 * @param {number} v
	 */
	set_stairs_type(v) {
		if (this.stairs_type == v) return;
		this.stairs_type = v;
		this.initiate_creation();
	}

	get_stair_width() {
		return this.stair_width;
	}

	set_stair_width(v) {
		this.stair_width = v;
		this._terminate_edition();
	}

	get_stair_parameter() {
		return {key:this.stair_parameter.key,value:this.stair_parameter.value};
	}

	set_stair_parameter(v) {
		this.stair_parameter.key = v.key;
		this.stair_parameter.value = v.value;
		this._terminate_edition();
	}

	//*****************************************************************
	//*** Must return 'true' if wa are in modal creation.
	//*****************************************************************
	is_creating() {
		return this._handler.is_creating();
	}

	//***********************************************************************************
	//**** transaction refresh
	//***********************************************************************************
	transaction_refresh() {
		this._terminate_edition();
	}

	//***********************************************************************************
	//**** Refresh
	//***********************************************************************************
	draw(camera) {
		var html = "";

		if (this._handler) this._handler.visible = (this._focus_handler == this._handler);

		html += super.draw(camera);

		if (this.is_creating() && this._tmp_stairs)
		{
			html += "<g opacity='0.5'>";
			html += this._tmp_stairs.draw(camera);
			html += "</g>";
		}

		return html;
	}


	//***********************************************************************************
	//**** Start creation of stairs
	//***********************************************************************************
	initiate_creation() {
		if (this._handler)
		{
			const index = this._handlers.indexOf(this._handler);
			if (index >= 0) this._handlers.splice(index,1);
			this._handler = null;
		}
		this._terminate_edition();
		if (this.stairs_type == 1)
			this.initiate_creation_round();
		else
			this.initiate_creation_straight();
	}

	//***********************************************************************************
	//**** Start creation of stairs
	//***********************************************************************************
	initiate_creation_straight() {
		var obj = this;
		var scene = obj._scene;

		//*** Create handler */
		this._handler = null;
		if (this.stairs_type == 3)
			this._handler = cn_polygon_handler.create_rectangle(this,obj.stair_width*2,obj.stair_width*1.1);
		else
			this._handler = cn_polygon_handler.create_open_polygon(this,(this.stairs_type == 2)?2:0);

		this._handlers.unshift(this._handler);
		this._handler.snap_elements = scene.spaces;
		const upper_storey = this._map._storey.get_relative_storey(1);
		if (upper_storey) this._handler.snap_elements = this._handler.snap_elements.concat(upper_storey.scene.slab_openings);

		this._tmp_stairs = null;

		var stairs_axis = 0;

		this._handler.allow_creation = function(mouse_event) {
			if (scene.find_stairs(mouse_event.mouse_world) == null) return true;
			obj._tmp_stairs = null;
			return false;
		}

		//*** Event on creation start */
		this._handler.on("start_creation", function() {
			obj._terminate_edition();
		});

		//*** Event on creation start */
		this._handler.on("force_update", function() {
			obj._map.refresh();
		});

		//*** Event on creation end */
		this._handler.on("end_creation",function() {
			if (obj._handler == null) return;

			obj._tmp_stairs = null;

			var stairs = new cn_stairs(obj._scene);
			stairs.axis = stairs_axis;
			stairs.stair_width = obj.stair_width,
			stairs.set_stair_parameter(obj.stair_parameter);
			var handler_vertices = obj._handler.vertices;
			for (var i in handler_vertices)
				stairs.vertices.push(cn_clone(handler_vertices[i]));
			stairs.build_valid_borders();
			stairs.update_space();

			// @ts-ignore
			if (obj._handler._rectangle)
				stairs.set_rectangle_flat_parts();

			obj.push_transaction("Création d'escalier");
			obj.push_item_set(scene,"stairs", function() {scene.update();scene.update_deep();});

			obj._scene.stairs.push(stairs);
			obj._scene.update();
			obj._scene.update_deep();

			obj.call("creation",[stairs]);
			obj.initiate_creation();

			//*** Edit recently created slab opening */
			obj._initiate_edition([stairs]);
		});

		this._handler.check_change = function() {

			var handler_vertices = obj._handler.vertices;
			if (handler_vertices.length <= 1) return true;

			var stairs = new cn_stairs(obj._scene);
			stairs.axis = stairs_axis;
			stairs.stair_width = obj.stair_width;
			stairs.set_stair_parameter(obj.stair_parameter);
			for (var i in handler_vertices)
				stairs.vertices.push(cn_clone(handler_vertices[i]));
			stairs.build_valid_borders();
			if (!stairs.valid)
			{
				return false;
			}
			stairs.update_space();
			if (!stairs.space)
			{
				if (obj.stairs_type == 3) return false;
				stairs.axis = 2-stairs_axis;
				stairs.build_valid_borders();
				if (!stairs.valid) return false;
				stairs.update_space();
				if (!stairs.space) return false;
				stairs_axis = 2 - stairs_axis;
			}
			obj._tmp_stairs = stairs;
			return true;
		};
	}
	//***********************************************************************************
	//**** Start creation of stairs
	//***********************************************************************************
	initiate_creation_round() {
		var obj = this;
		var scene = obj._scene;
		this._handler = new cn_disk_handler(scene, this._controller,null,0,[0,Math.PI],this);
		this._handlers.unshift(this._handler);
		this._handler.snap_elements = scene.spaces;

		this._handler.on_change = function() {

			if (obj._handler == null) return;
			var space = obj._handler.space;
			if (space == null) return;
			if (obj._handler["center"] == null) return;

			obj._tmp_stairs = null;

			var stairs = new cn_stairs(obj._scene);
			stairs.stairs_type = "round";
			stairs.stair_width = obj.stair_width;
			stairs.set_stair_parameter(obj.stair_parameter);
			stairs.space = space;
			stairs.center = cn_clone(obj._handler["center"]);
			stairs.radius = obj._handler["radius"];
			stairs.angles = cn_clone(obj._handler["angles"]);
			stairs.build_borders();

			obj.push_transaction("Création d'escalier");
			obj.push_item_set(scene,"stairs", function() {scene.update();scene.update_deep();});

			obj._scene.stairs.push(stairs);
			obj._scene.update();
			obj._scene.update_deep();

			obj.call("creation",[stairs]);
			obj.initiate_creation();

			//*** Edit recently created slab opening */
			obj._initiate_edition([stairs]);
		};

		this._handler.check_change = function() {

			//*** As soon as creation has started, we stop editing previous marker */
			if (obj._handler && obj._handler.is_creating())
				obj._terminate_edition();

			if (obj._handler["center"] == null) return true;

			var stairs = new cn_stairs(obj._scene);
			stairs.stairs_type = "round";
			stairs.stair_width = obj.stair_width;
			stairs.set_stair_parameter(obj.stair_parameter);
			stairs.center = cn_clone(obj._handler["center"]);
			stairs.radius = obj._handler["radius"];
			stairs.angles = cn_clone(obj._handler["angles"]);
			stairs.build_borders();

			if (!stairs.valid) return false;
			obj._tmp_stairs = stairs;
			return true;
		};
	}

	//***********************************************************************************
	//**** Edition elements
	//***********************************************************************************
	/**
	 * TODO : derivate in order to allow edition of other element in the process of creation
	 * @param {cn_mouse_event} mouse_event
	 * @returns {cn_element}
	 */
	 _find_other_element(mouse_event) {
		return this._scene.find_stairs(mouse_event.mouse_world,mouse_event.camera.snap_world_distance);
	}

	/**
	 * TODO : derivate in order to provide an edition handler
	 * @param {Array<cn_stairs>} elements
	 * @returns {cn_edition_handler}
	 */
	_build_edition_handler(elements) {
		return new cn_stairs_handler(elements,this._map,true);
	}

	/**
	 * TODO : derivate in order to find siblings of an element
	 * @param {cn_stairs} element
	 * @returns {Array<cn_element>}
	 */
	_get_siblings(element) {
		const ot = element.stairs_type;
		return this._scene.stairs.filter(s => s.stairs_type == ot);
	}

}


import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
    BaseComponent,
    ConfirmationService,
    NotificationService,
    TreeInputData,
    TreeUtils,
    TreeUtilsNode,
} from 'src/app/commons-lib';
import { ITreeOptions, TREE_ACTIONS, TreeComponent } from '@circlon/angular-tree-component';

import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { TypeOuvrage } from '../../../../../model/type-ouvrage.model';
import { URL_GESTION_TYPES_OUVRAGE_EDIT } from '../../../../../shared/constants/url.constants';
import { ReferenceService } from '../../../../../services/reference.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-arbre-types-ouvrage',
    templateUrl: './arbre-types-ouvrage.component.html',
    styleUrls: ['./arbre-types-ouvrage.component.scss'],
})
export class ArbreTypesOuvrageComponent extends BaseComponent implements OnInit {
    @ViewChild('productTypesTree', { static: false }) productTypesTree: TreeComponent;

    /** Tous les types d'ouvrage */
    allTypesOuvrage: TypeOuvrage[];

    /** Tous les types d'ouvrage (sous forme de tree nodes) */
    allTypesOuvrageNodes: TreeUtilsNode[];

    /** Type d'ouvrage sélectionné */
    typeOuvrageSelection: TypeOuvrage;

    /** Options de l'arbre des types de produits */
    typesOuvrageTreeOptions: ITreeOptions = {
        allowDrag: false,
        allowDrop: false,
        actionMapping: {
            mouse: {
                click: TREE_ACTIONS.EXPAND,
            },
        },
    };

    constructor(
        private readonly referenceApiService: ReferenceApiService,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly router: Router,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit() {
        this.referenceApiService
            .buildTypesOuvrage$()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((resp) => {
                this.allTypesOuvrage = resp;
                this.allTypesOuvrageNodes = TreeUtils.buildTreeFromData(this.allTypesOuvrage);
            });
    }

    editTypeOuvrage(typeOuvrageNode: TreeInputData) {
        this.router.navigate([URL_GESTION_TYPES_OUVRAGE_EDIT, typeOuvrageNode.id]);
    }
    deleteTypeOuvrage(typeOuvrageNode: TreeInputData) {
        this.confirmationService.confirm(
            `Voulez-vous supprimer définitivement le type d'ouvrage: ${typeOuvrageNode.name} ?`,
            () => {
                this.referenceApiService
                    .deleteTypeOuvrage(typeOuvrageNode.id)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((resp) => {
                        this.allTypesOuvrage = resp;
                        this.allTypesOuvrageNodes = TreeUtils.buildTreeFromData(this.allTypesOuvrage);
                        this.notificationService.success(
                            `Le type d'ouvrage: ${typeOuvrageNode.name} à bien été supprimer`
                        );
                    });
            }
        );
    }
}

<div class="markdown-content pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block">{{ contexte.titre }}</div>

    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">DEFINITION DES ZONES HOMOGENES</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone homogène</th>
                    <th>Matériaux contenant de l'amiante</th>
                    <th>Activité</th>
                    <th>Fréquentation</th>
                    <th>Type</th>
                    <th>Étanchéité</th>
                    <th>Choc et vibration</th>
                    <th>Circulation d'air</th>
                    <th>Surfaces</th>
                    <th>Nombre de pièce unitaire</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr>
                        <th [attr.rowspan]="zone.listeMpca.length + 1">{{ zone.nomZone }}</th>
                    </tr>
                    <tr *ngFor="let mpca of zone.listeMpca; let i = index">
                        <th>
                            MPCA {{ i + 1 }} : <em>({{ mpca.general.materiauxClient }})</em>
                            {{ mpca.general.materiaux }} - {{ mpca.general.etatDegradation }}
                            <span *ngFor="let amiante of mpca.general.typeAmiante"> - {{ amiante }} </span>
                        </th>
                        <td>{{ zone.activite }}</td>
                        <td>{{ zone.frequentation }}</td>
                        <td>{{ mpca.protectionExposition.type }}</td>
                        <td>{{ mpca.protectionExposition.etancheite }}</td>
                        <td>{{ mpca.protectionExposition.chocVibration }}</td>
                        <td>{{ mpca.protectionExposition.circulationAir }}</td>
                        <td>{{ zone.surfaces.superficie }}m²</td>
                        <td>{{ zone.surfaces.pieceUnitaire }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">PIECES INDEPENDANTES</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone homogène</th>
                    <th>Détail des surfaces</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr>
                        <th>{{ zone.nomZone }}</th>
                        <td>
                            <ng-container *ngFor="let surface of zone.surfacesDetaillees; let i = index">
                                Vol {{ i + 1 }} : {{ surface.superficie }}m²
                                <ng-container *ngIf="i != zone.surfacesDetaillees.length - 1" ;>,</ng-container>
                            </ng-container>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">PROTOCOLE D’ECHANTILLONNAGE</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone homogène</th>
                    <th>Type de prélèvement</th>
                    <th>Méthode</th>
                    <th>Quantité</th>
                    <th>Durée</th>
                    <th>SA attendue</th>
                    <th>Prélèvement réglementaire</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr>
                        <th [attr.rowspan]="zone.refs.length + 1">{{ zone.nomZone }}</th>
                    </tr>

                    <tr *ngFor="let prelevement of zone.refs; let i = index">
                        <td>{{ prelevement.ref.reference }} - {{ prelevement.ref.typePrelevement }}</td>
                        <td>{{ prelevement.ref.norme }}</td>
                        <td>{{ prelevement.quantite }}</td>
                        <td>{{ prelevement.ref.dureeMax }}</td>
                        <td>{{ prelevement.ref.sensibiliteAnalytiqueAttendue }}</td>
                        <td *ngIf="prelevement.quantite == 1; else elseBlock">Non réglementaire</td>
                        <ng-template #elseBlock>Réglementaire</ng-template>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="subtittle">LOCALISATION ET PÉRIODE DES PRÉLÈVEMENTS</span>
        <table class="tab">
            <thead>
                <tr>
                    <th>Nom de la zone homogène</th>
                    <th>Prélèvement</th>
                    <th>Localisation</th>
                    <th>Justification</th>
                    <th>Condition du prélèvement</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let zone of contexte.zones">
                    <tr *ngFor="let ref of zone.refs">
                        <th>{{ zone.nomZone }}</th>
                        <td class="miss">Non défini</td>
                        <td class="miss">Non défini</td>
                        <td class="miss">Non défini</td>
                        <td class="miss">Non défini</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="miss">INSERTION DU PLAN / CROQUIS</span>
    </div>
</div>

import { ISpacesHandler, SpaceType } from '@acenv/cnmap-angular-editor-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReferenceService } from '../../../../../services/reference.service';
import { map } from 'rxjs/operators';
import { TypeVolume } from '../../../../../model/type-element-a-controler.model';

@Injectable({
    providedIn: 'root',
})
export class SpacesHandler implements ISpacesHandler {
    constructor(private http: HttpClient, private readonly referenceService: ReferenceService) {}

    getSpaceTypes(): Observable<SpaceType[]> {
        return this.referenceService.findAllTypesVolumes().pipe(
            map((vols: TypeVolume[]) => vols.map((it) => new SpaceType(it.id, it.nom))),
            map((spacesTypes) => spacesTypes.sort((a, b) => a.label.localeCompare(b.label)))
        );
    }
}

export const confHap = {
    id: 'confDefaultHAP',
    name: 'HAP',
    main: {
        controls: {
            nom: { value: '', displayed: true },
            detail: { value: 'confDefaultHAP', displayed: true },
        },
    },
    bien: {
        displayed: false,
        controls: {
            bien: { value: undefined, displayed: true },
            niveau: { value: undefined, displayed: true },
        },
    },
    espace: {
        displayed: true,
        controls: {
            espace: { value: undefined, displayed: true },
        },
    },
    page: {
        displayed: false,
        controls: {
            format: { value: 'a4_paysage', displayed: true },
        },
    },
    plan: {
        displayed: true,
        groups: {
            general: {
                displayed: true,
                controls: {
                    scale: { value: 200, displayed: true },
                    resolution: { value: 200, displayed: true },
                    fixedScale: { value: false, displayed: true },
                    fixedPosition: { value: false, displayed: true },
                },
            },
            display: {
                displayed: true,
                controls: {
                    render: { value: 0, displayed: false },
                    showGrid: { value: false, displayed: true },
                    showScale: { value: true, displayed: true },
                    showBackground: { value: true, displayed: true },
                    showCompass: { value: true, displayed: true },
                },
            },
            elements: {
                displayed: true,
                controls: {
                    showSpaces: { value: true, displayed: false },
                    showOuterWalls: { value: true, displayed: false },
                    showInnerWalls: { value: true, displayed: false },
                    showBalconies: { value: true, displayed: false },
                    showWindows: { value: false, displayed: true },
                    showDoors: { value: false, displayed: true },
                    showStairs: { value: true, displayed: false },
                    showSlabOpenings: { value: false, displayed: true },
                    showDeams: { value: false, displayed: true },
                    showColumns: { value: true, displayed: false },
                    showObjects: { value: false, displayed: true },
                },
            },
            text: {
                displayed: true,
                controls: {
                    showSpaceLabel: { value: true, displayed: true },
                    showSpaceArea: { value: false, displayed: true },
                    showMarkers: { value: true, displayed: true },
                    showNumerotation: { value: false, displayed: true },
                    showLegend: { value: true, displayed: true },
                },
            },
            cotes: {
                displayed: true,
                controls: {
                    showOuterMeasures: { value: false, displayed: true },
                    showInnerMeasures: { value: false, displayed: true },
                    showOpeningMeasures: { value: false, displayed: true },
                },
            },
        },
    },
    equipment: {
        displayed: false,
        groups: {
            conformite: {
                displayed: true,
                controls: {
                    showConformeObjects: { value: true, displayed: true },
                    showNonConformeObjects: { value: true, displayed: true },
                    showAJustifierObjects: { value: true, displayed: true },
                    showNonVerifieObjects: { value: true, displayed: true },
                },
            },
        },
    },
    zone: {
        displayed: false,
        groups: {
            surface: {
                displayed: true,
                controls: {
                    showSurfacesComptabilisees: { value: true, displayed: true },
                    showSurfacesDecomptees: { value: true, displayed: true },
                },
            },
        },
    },
    hap: {
        displayed: true,
        groups: {
            surface: {
                displayed: true,
                controls: {
                    showPerimetres: { value: true, displayed: true },
                    showZones: { value: true, displayed: true },
                    showPrelevements: { value: true, displayed: true },
                },
            },
            zone: {
                displayed: true,
                controls: {
                    showZoneInferieurEgal50: { value: false, displayed: true },
                    showZoneEntre51Et500: { value: false, displayed: true },
                    showZoneEntre501Et1000: { value: false, displayed: true },
                    showZoneSuperieur1000: { value: false, displayed: true },
                    showZoneNonRealises: { value: false, displayed: true },
                },
            },
            prelevement: {
                displayed: true,
                controls: {
                    showPrelevementInferieurEgal50: { value: true, displayed: true },
                    showPrelevementEntre51Et500: { value: true, displayed: true },
                    showPrelevementEntre501Et1000: { value: true, displayed: true },
                    showPrelevementSuperieur1000: { value: true, displayed: true },
                    showPrelevementNonRealises: { value: true, displayed: true },
                },
            },
        },
    },
};

import { Component, Input, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Bien, Niveau, Volume } from 'src/app/model/bien.model';
import { Besoin } from '../../diagnostics/polluant/model/besoin.model';
import { Zone } from '../../diagnostics/polluant/model/zone.model';

@Component({
    selector: 'zone-surface-panel',
    templateUrl: './zone-surface-panel.component.html',
    styleUrls: ['./zone-surface-panel.component.scss'],
})
export class ZoneSurfacePanelComponent implements OnInit {
    selectedBien: Bien;
    selectedNiveaux: Array<Niveau> = [];
    selectedZones: Zone[] = [];

    indexNiveauSelected: number;
    indexBienSelected: number = 0;

    @Input() indexZoneSelected: number;
    @Input() biens: Array<Bien>;
    @Input() zones: Array<any>;
    @Input() besoins: Array<Besoin>;
    @Input() volumes: Array<Volume> = [];

    volumesInNiveau: Array<Volume> = [];

    @Input()
    isInEditMode: Boolean = true;

    @Input()
    indexBesoinSelected: number;

    @Input()
    includeAllNiveaux: Boolean = false;
    @Input()
    includeAllZones: Boolean = false;
    @Input()
    showActions: Boolean = true;

    // volumes = [];
    orderedZonesData = {};

    extraZone = {
        nom: 'Toutes les zones',
    };

    @Output() besoinActionEmitter = new EventEmitter<any>();
    @Output() indexZoneSelectedChange = new EventEmitter<number>();

    ngOnInit(): void {
        if (!this.indexNiveauSelected) {
            this.indexZoneSelected = 0;
            this.indexZoneSelectedChange.emit(this.indexZoneSelected);
            this.indexNiveauSelected = 0;
        }

        this.onNiveauChange('Niveau ' + this.indexNiveauSelected);
    }

    onNiveauChange(data) {
        this.selectedBien = this.biens[this.indexBienSelected];

        if (data.nom == 'Tous les niveaux' || data == 'Tous les niveaux') {
            this.indexNiveauSelected = -1;

            //Flatten
            this.selectedNiveaux = this.selectedBien.description.reduce((acc, curVal) => {
                return acc.concat(curVal);
            }, []);
        } else {
            //Données reçue de "bien-niveau-select" au format 'Niveau X'
            //Ne peut pas être immédiatement changé car dépendance avec autres diags
            //Workaround
            this.indexNiveauSelected = data.split(' ')[1] as number;
            this.selectedNiveaux = [this.selectedBien.description[this.indexNiveauSelected]];
        }

        // let volumes = [];
        //Récupération de la liste des volumes pour tous les niveaux sélectionnés
        this.volumesInNiveau = [];
        this.selectedNiveaux.forEach((niveau: Niveau) => {
            this.volumesInNiveau.push(...niveau.volumes);
        });

        this.zoneChanged();
    }

    zoneChanged(): void {
        //Si l'option "Toutes les zones" est sélectionnée
        // alors on prend en compte l'intégralité des zones
        // sinon le tableau de zones sélectionnées ne contiendra que la valeur de celle sélectionnée
        if (this.indexZoneSelected == this.zones.length) {
            this.selectedZones = this.zones;
        } else {
            this.selectedZones = [this.zones[this.indexZoneSelected]];
        }
        this.indexZoneSelectedChange.emit(this.indexZoneSelected);
    }

    navigationZone(e): void {
        if (e == 'next') this.indexZoneSelected++;
        else if (e == 'previous') this.indexZoneSelected--;
        this.indexZoneSelectedChange.emit(this.indexZoneSelected);

        this.zoneChanged();
    }

    nouveauBesoin(volumeId, selectedZone): void {
        this.besoinAction({ action: 'select', volumeId, besoinId: undefined, zone: selectedZone });
    }

    besoinAction(data) {
        //Selection du volume parent du besoin sélectionné
        //let volume = this.selectedNiveaux.volumes.find((item) => item.id == data.volumeId);

        //let besoinToDisplayInForm = data.besoin;
        let besoinIdToDisplay;
        if (data.action == 'delete') {
            //Suppression du besoin de la liste de besoins de ce volume
            this.besoins.splice(data.besoinIndex, 1);
            //On renvoi undefined pour ne plus afficher le formulaire
            besoinIdToDisplay = undefined;
        } else if (data.action == 'duplicate') {
            //Duplication du besoin sélectionné à la position suivante du tableau de besoin de ce volume
            let newBesoin = { ...this.besoins[data.besoinIndex] };
            //Incrémentation du numéro de besoin
            newBesoin.numero = (parseInt(newBesoin.numero) + 1).toString();
            this.besoins.splice(data.besoinIndex + 1, 0, newBesoin);
            //Récupération du besoin qui vient d'être inséré
            besoinIdToDisplay = data.besoinIndex + 1;
        } else if (data.action == 'new') {
            let _nouveauBesoin = new Besoin();
            _nouveauBesoin.numero = (this.besoins.length + 1).toString();
            _nouveauBesoin.pieceId = data.volumeId;
            _nouveauBesoin.zoneId = data.zone.id;
            // _nouveauBesoin.simulation = false;
            this.besoins.push(_nouveauBesoin);
            besoinIdToDisplay = this.besoins.length - 1;
        } else if (data.action == 'select' || data.action == 'edit') {
            besoinIdToDisplay = data.besoinIndex;
        }

        //L'id du besoin à afficher dans le formualaire est envoyé en @Output au composant parent
        //ainsi que la liste à jour des besoins
        this.besoinActionEmitter.emit({
            besoins: this.besoins,
            besoinIdToDisplay,
            indexZoneSelected: this.indexZoneSelected,
        });
    }
}

<div class="container container-page">
    <div class="preview-title d-flex justify-content-center">
        <span *ngIf="titreReportagePhoto" class="pb-3">{{ titreReportagePhoto }} </span>
    </div>

    <div class="row">
        <div
            *ngFor="let imagesFilePage of imagesFilesPage"
            class="col-6 pb-2 d-flex justify-content-center"
            [ngClass]="!imagesFilePage?.fileContent ? 'container-icon-no-image' : ''"
        >
            <div class="w-100">
                <div class="d-flex flex-grow-1">
                    <img
                        *ngIf="imagesFilePage.fileContent"
                        [src]="imagesFilePage.fileContent"
                        alt="image"
                        class="img-fluid img-size"
                    />
                </div>
                <div class="legend">{{ imagesFilePage.legende }}</div>
                <mat-icon *ngIf="!imagesFilePage.fileContent" class="size-icon-no-filecontent">no_photography</mat-icon>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { EtapeDiagnosticEspacesHAP } from 'src/app/model/diagnostic-etape.model';
import { EtatValidation } from '../../../../model/etat-progression.model';
import { Subject } from 'rxjs';
import { FormArray, FormGroup } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { PRELEVEMENT_ESPACE, ZONE_ESPACE } from 'src/app/shared/constants/names.step.constants';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';
import { HapFormService } from './hap-form.service';
import { TypePrestation } from 'src/app/model/type-prestation.model';
import {
    HapPreparationDICT,
    HapPreparationReconnaissance,
    HapPreparationTerrain,
} from '../model/hap-preparation.model';
import { LevelToDisplay } from '../model/hap.model';
import { Perimetre } from '../../../../model/perimetre.model';
import { Espace } from '../../../../model/espace.model';

/**
 * Service pour le diagnostic hap.
 */
@Injectable({
    providedIn: 'root',
})
export class HapCheckValidityService {
    constructor(
        private readonly hapFormService: HapFormService,
        private readonly etatProgressionService: EtatProgressionService
    ) {}

    /**
     * Vérifie l'état de validité des informations l'onglet "Objectifs"
     * @param objectifs
     */
    checkValidityObjectifs(objectifs: string) {
        let etat: EtatValidation = 'VALID';

        if (!objectifs) {
            etat = 'INVALID';
        }

        return etat;
    }

    /**
     * Vérifie l'état de validité des informations l'onglet "Preparation DICT"
     * @param preparationDict: HapPreparationDICT
     */
    checkValidityPreparationDICT(preparationDict: HapPreparationDICT) {
        let etat: EtatValidation = 'VALID';

        if (
            !preparationDict ||
            (preparationDict.soumisDICT &&
                (!preparationDict.demande.dateDemande ||
                    !preparationDict.reponse.dateReponse ||
                    !preparationDict.reponse.idDocumentDICT))
        ) {
            etat = 'INVALID';
        }

        return etat;
    }

    /**
     * Vérifie l'état de validité des informations l'onglet "Preparation Visite de reconnaissance"
     * @param preparationReconnaisance: HapPreparationReconnaissance
     */
    checkValidityPreparationReconnaissance(preparationReconnaisance: HapPreparationReconnaissance) {
        let etat: EtatValidation = 'VALID';

        if (
            !preparationReconnaisance ||
            (preparationReconnaisance.reconnaissanceNecessaire && !preparationReconnaisance.dateReconnaissance) ||
            preparationReconnaisance.contacts.length === 0
        ) {
            etat = 'INVALID';
        }

        return etat;
    }

    /**
     * Vérifie l'état de validité des informations l'onglet "Modalités d'accès et de circulation"
     * @param modalites: string
     */
    checkValidityPreparationModalites(modalites: string) {
        let etat: EtatValidation = 'VALID';

        if (!modalites) {
            etat = 'INVALID';
        }

        return etat;
    }

    /**
     * Vérifie l'état de validité des informations l'onglet "Visite de terrain"
     * @param terrain: HapPreparationTerrain
     */
    checkValidityPreparationTerrain(terrain: HapPreparationTerrain) {
        let etat: EtatValidation = 'VALID';

        if (!terrain || terrain.contacts.length === 0) {
            etat = 'INVALID';
        }

        return etat;
    }

    /**
     * Vérifie l'état de validité des informations l'onglet "périmètre"
     * @param diagnostic: Diagnostic
     * @param ngUnsubscribe: Subject<void>
     * @param typePrestation
     */
    checkValidityPerimetre(diagnostic: Diagnostic, ngUnsubscribe: Subject<void>, typePrestation: TypePrestation) {
        const isOnePerimetreInvalid = this.checkIsOnePerimetreInvalid(diagnostic);

        let etat: EtatValidation = 'VALID';
        if (diagnostic) {
            // const espaces: EtapeDiagnosticEspacesHAP = (diagnostic.contenuDiagnostic as any).espaces;
            const espaces: Espace[] = (diagnostic.contenuDiagnostic as any).espaces.valeur;
            if (!espaces || espaces.length === 0) {
                etat = 'INVALID';
                return etat;
            }
            espaces.forEach((espace) => {
                if (etat === 'VALID') {
                    const formArayListePerimetre = this.hapFormService.createFormArrayListePerimetres(
                        espace.listePerimetres,
                        diagnostic,
                        LevelToDisplay.PERIMETRE,
                        ngUnsubscribe
                    );
                    if (typePrestation.startsWith('POLLUANT')) {
                        for (const control of formArayListePerimetre.controls) {
                            (control as FormGroup).removeControl('typeOuvrage');
                        }
                    }
                    if (formArayListePerimetre.invalid) {
                        etat = 'INVALID';
                    }
                    if (typePrestation !== 'HAP_ETUDE_SITUATION' && isOnePerimetreInvalid) {
                        etat = 'INVALID';
                    }
                }
            });
        }
        return etat;
    }

    /**
     * Vérifie la validité de l'écran des zones lorsqu'on est dans un autre onglet
     */
    checkValidityZoneFromOutside(
        listePerimetre: Perimetre[],
        idEspace: string,
        ngUnsuscribe: Subject<void>,
        diagnostic: Diagnostic
    ) {
        const perimetreFormArray: FormArray = this.hapFormService.createFormArrayListePerimetres(
            listePerimetre,
            diagnostic,
            LevelToDisplay.ZONE,
            ngUnsuscribe
        );

        const etat = this.checkValidityZone(perimetreFormArray);
        this.etatProgressionService.updateDiagnostic(`${ZONE_ESPACE}_${idEspace}`, etat, diagnostic);

        // On valide automatiquement l'onglet périmètre car directement impacté si les zones sont impactées
        this.checkValidityPrelevementFromOutside(listePerimetre, idEspace, ngUnsuscribe, diagnostic);
    }

    /**
     * Vérifie la validité de l'écran des zones
     * @param listePerimetreFormArray
     */
    checkValidityZone(listePerimetreFormArray: FormArray) {
        let etat: EtatValidation = 'INVALID';
        if (!listePerimetreFormArray || listePerimetreFormArray.controls.length === 0) {
            etat = 'VALID';
        } else {
            let hasZoneInvalid = false;
            listePerimetreFormArray.controls.forEach((perimetreFormGroup: FormGroup) => {
                const listeZones = (perimetreFormGroup.get('formArrayZones') as FormArray).controls;
                listeZones.forEach((zoneFormGroup) => {
                    hasZoneInvalid = hasZoneInvalid || zoneFormGroup.invalid;
                });
            });
            etat = hasZoneInvalid ? 'INVALID' : 'VALID';
        }

        return etat;
    }

    /**
     * Vérifie la validité de l'écran des prélèvements lorsqu'on est dans un autre onglet
     */
    checkValidityPrelevementFromOutside(
        listePerimetre: Perimetre[],
        idEspace: string,
        ngUnsuscribe: Subject<void>,
        diagnostic: Diagnostic
    ) {
        const perimetreFormArray: FormArray = this.hapFormService.createFormArrayListePerimetres(
            listePerimetre,
            diagnostic,
            LevelToDisplay.PRELEVEMENT,
            ngUnsuscribe
        );

        const etat = this.checkValidityPrelevement(perimetreFormArray);
        this.etatProgressionService.updateDiagnostic(`${PRELEVEMENT_ESPACE}_${idEspace}`, etat, diagnostic);
    }

    /**
     * Vérifie la validité de l'écran des prélèvements
     * @param listePerimetreFormArray
     */
    checkValidityPrelevement(listePerimetreFormArray: FormArray) {
        let etat: EtatValidation = 'INVALID';
        if (!listePerimetreFormArray || listePerimetreFormArray.controls.length === 0) {
            etat = 'VALID';
        } else {
            let hasPrelevementInvalid = false;
            listePerimetreFormArray.controls.forEach((perimetreFormGroup: FormGroup) => {
                const listeZones = (perimetreFormGroup.get('formArrayZones') as FormArray).controls;
                listeZones.forEach((zoneFormGroup) => {
                    const listePrelevements = (zoneFormGroup.get('formArrayPrelevement') as FormArray).controls;
                    listePrelevements.forEach((prelevementFormGroup) => {
                        hasPrelevementInvalid = hasPrelevementInvalid || prelevementFormGroup.invalid;
                    });
                });
            });
            etat = hasPrelevementInvalid ? 'INVALID' : 'VALID';
        }

        return etat;
    }

    checkValidityBesoin(listePerimetreFormArray: FormArray, typePrestation: TypePrestation, isBesoinValidate: boolean) {
        let etat: EtatValidation = 'INVALID';
        if (!listePerimetreFormArray || listePerimetreFormArray.controls.length === 0) {
            etat = 'VALID';
        } else {
            // On vérifie que les besoins sont valides
            let hasBesoinInvalid = false;
            listePerimetreFormArray.controls.forEach((perimetreFormGroup: FormGroup) => {
                const listeBesoin = (perimetreFormGroup.get('formArrayBesoins') as FormArray).controls;
                listeBesoin.forEach((besoinFormGroup) => {
                    hasBesoinInvalid = hasBesoinInvalid || besoinFormGroup.invalid;
                });
            });

            // Hormis dans le cas de l'étude de situation, on vérifie que l'opérateur à valider les besoins
            if (typePrestation != 'HAP_ETUDE_SITUATION') {
                hasBesoinInvalid = hasBesoinInvalid || !isBesoinValidate;
            }

            etat = hasBesoinInvalid ? 'INVALID' : 'VALID';
        }

        return etat;
    }

    /**
     * Vérifie si au moins un Périmètre n'a pas été validé parmi les espaces présent dans le diagnostic
     * @param diagnostic
     */
    checkIsOnePerimetreInvalid(diagnostic: Diagnostic) {
        if (
            diagnostic.typePrestation !== 'HAP_ETUDE_SITUATION' &&
            diagnostic.typePrestation !== 'POLLUANT_ETUDE_SITUATION'
        ) {
            return !!(diagnostic.contenuDiagnostic as any).espaces.valeur.find(
                (espace: Espace) => !espace.isPerimetreValid
            );
        }
        return false;
    }

    checkValidityResultatsLaboratoire(espaces: Espace[]): EtatValidation {
        let state: EtatValidation = 'INVALID';
        const flat = espaces.flatMap((es) =>
            es.listePerimetres.flatMap((per) =>
                per.listeZones.flatMap((zo) =>
                    zo.listePrelevements.flatMap((pvt) => pvt.echantillonages.flatMap((ech: any) => ech.validated))
                )
            )
        );
        state = flat.includes(false) ? 'INVALID' : 'VALID';
        return state;
    }
}

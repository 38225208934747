<div class="besoin-prelevement" *ngIf="isLoaded && contenuDiagnosticPolluant.zones.errors.length == 0">
    <zone-surface-panel
        class="zone-surface-panel"
        *ngIf="isLoaded"
        [biens]="biens"
        [zones]="zones"
        [besoins]="besoins"
        [volumes]="volumes"
        [indexBesoinSelected]="indexBesoinSelected"
        [isInEditMode]="isInEditMode"
        [includeAllNiveaux]="true"
        [includeAllZones]="includeAllZones"
        (besoinActionEmitter)="besoinSelected($event)"
    ></zone-surface-panel>
    <div class="main">
        <verification-card
            *ngIf="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="form"
        >
            Veuillez vérifier les besoins
        </verification-card>
        <ng-container *ngIf="indexBesoinSelected != undefined">
            <app-informations-besoin
                [formCurrentBesoin]="formCurrentBesoin"
                [selectedZones]="selectedZones"
                [polluantConfig]="polluantConfig"
                [isInEditMode]="isInEditMode"
                [availableVolumesIdInZone]="availableVolumesIdInZone"
                [volumes]="volumes"
                [mpcaList]="contenuDiagnosticPolluant.mpcaList.data.descriptifList"
            ></app-informations-besoin>
        </ng-container>
    </div>
</div>
<div class="besoin-prelevement error-message" *ngIf="isLoaded && contenuDiagnosticPolluant.zones.errors.length != 0">
    Les données saisies dans l'écran Zone sont incorrectes ou incomplètes
    <div>
        <button mat-raised-button color="primary" (click)="navigateEcranZone()">Retourner à l'écran Zone</button>
    </div>
</div>

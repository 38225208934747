"use strict";
import { cn_roof_opening } from "../model/cn_roof_opening";
import { cn_edition_handler } from "./cn_edition_handler";
import { cn_svg_map } from "./cn_svg_map";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_clone} from "../utils/cn_utilities";
import { cn_type_pastille } from "./cn_type_pastille";
import { cn_edit_box } from "./cn_edit_box";
import { cn_handler_rotation } from "./cn_handler_rotation";

export class cn_roof_opening_handler extends cn_edition_handler {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {Array<cn_roof_opening>} openings
	 * @param {cn_svg_map} map
	 * @param {boolean} creation
	 */
	constructor(openings, map,creation=false) {
		super(openings,map);
		const obj = this;

		this._map = map;
		this._scene = this._map._scene;
		this._transaction_manager = this._map._building.transaction_manager;

		this._openings = openings;
		this._opening = (openings.length ==1)?openings[0]:null;
		this._edited_opening_mouseover = false;

		//*** Rotation handler : only for single selection */
		this._rotation_handler = null;
		if (this._opening)
		{
			this._rotation_handler = new cn_handler_rotation([0,0],0);
			this._rotation_handler.on("change",function() {
				obj._transaction_manager.push_transaction("Rotation d'ouvrant de toiture",obj._opening.ID);
				obj._transaction_manager.push_item_set(obj._opening,"orientation",function(item) {item.update();});
				obj._opening.orientation = obj._rotation_handler.angle;
			});
			this._handlers.push(this._rotation_handler);
		}

		//*** Edit box for mass selection */
		const edit_box = new cn_edit_box(this,openings,creation);
		this._handlers.push(edit_box);

		//*** Type pastille */
		const type_pastille = new cn_type_pastille(openings,"opening_type",function(){return map._building.get_roof_opening_types();},"Type de baie",map);
		type_pastille.title="Modifier le modèle de l'ouvrant";
		edit_box.add_pastille(type_pastille);

		edit_box.add_select_siblings_pastille("opening_type");
		edit_box.add_information_pastille("opening_type");

	}

	//***********************************************************************************
	//**** Draws
	//***********************************************************************************
	draw(camera) {
		if (this._rotation_handler)
		{
			this._rotation_handler.center = cn_clone(this._opening.position);
			this._rotation_handler.angle = this._opening.orientation;
			this._rotation_handler._snap_segments = [];
			if (this._opening.slab)
				this._rotation_handler._snap_segments = this._opening.slab.get_segments();

		}
		var html = super.draw(camera);

		if (this._opening)
		{
			var draw_extras = ["selected"];
			if (this._edited_opening_mouseover) draw_extras.push("mouseover");
			html += this._opening.draw(camera,draw_extras);
		}
		return html;
	}

	//***********************************************************************************
	/**
	 * Clear passive move effects
	 */
	clear_move() {
		this._edited_opening_mouseover = false;
		super.clear_move();
	}

	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	move(mouse_event) {
		if (!this.active) return false;
		this.clear_move();
		if (this._opening)
		{
			this._edited_opening_mouseover = this._opening.contains(mouse_event.mouse_world,mouse_event.camera.snap_world_distance);
			if (this._edited_opening_mouseover)
			{
				this._focus_handler = this;
				return true;
			}
		}
		return super.move(mouse_event);
	}

	/**
	 * Manage a grab. To return 'true' if grab is to be managed.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	grab(mouse_event) {
		if (!this.active) return false;
		if (this._focus_handler == this) return true;
		return super.grab(mouse_event);
	}

	/**
	 * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	drag(mouse_event) {
		if (this._focus_handler == this)
		{
			this._transaction_manager.push_transaction("Déplacement d'ouvrant de toiture",this._opening.ID);
			this._transaction_manager.push_item_set(this._opening,"position",function(item) {item.update();});
			this._opening.position = cn_clone(mouse_event.mouse_world);
			this._opening.update();
			return true;
		}
		return super.drag(mouse_event);
	}

	/**
	 * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	drop(mouse_event) {
		if (this._focus_handler == this) return true;
		return super.drop(mouse_event);
	}

	/**
	 * Manage a click. Only after a grab that returned true, and no drag. To return 'true' if click had an effect.
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	click(mouse_event) {
		if (this._focus_handler == this) return true;
		return super.click(mouse_event);
	}
}


"use strict";

import { cn_zone_extension } from "../cn_zone_extension";

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Default zone extension model
//***********************************************************************************
//***********************************************************************************



export class cn_zone_extension_default extends cn_zone_extension {

    /**
     * Tool zones extension
     * Ajout d'autres types de zones que la zone par défaut (usage) :
     * format { property: <string de la propriété dans le building>, label: <string de label IHM>, icon: <string de ref de l'icone mdi> }
     *
     */
    constructor() {
        super();
        this.tool_zones = [
            { property: 'lot', label: 'Lots', icon: 'land-plots-circle-variant' },
            { property: 'thermal', label: 'Thermiques', icon: 'thermometer' }
        ];
    }

}



import { Component, Injector, OnInit } from '@angular/core';
import { DiagnosticService } from '../../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../../model/diagnostic.model';
import { EtatProgressionService } from '../../../../../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Polluant } from '../../../../model/polluant.model';
import { Estimation } from '../../../../model/estimation.model';
import { PolluantComponent } from '../../../../utils/polluant-component';
import { IValidatableComponent } from '../../../../services/interfaces/validatable-component.interface';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { InterventionService } from '../../../../../../../services/intervention.service';
import { competence } from '../../../../enum/competence';

@Component({
    selector: 'app-constatations-diverses',
    templateUrl: './competence.component.html',
    styleUrls: ['./competence.component.scss'],
})
export class CompetenceComponent extends PolluantComponent implements OnInit, IValidatableComponent {
    readonly COMPETENCES: any = competence;
    readonly COMPETENCES_KEY: string[] = Object.keys(competence);
    currentDiagnostic: Diagnostic;
    formCompetences: FormGroup;
    contenuPolluant: Polluant;
    isReadOnly: any;

    constructor(
        private route: ActivatedRoute,
        private injector: Injector,
        private interventionService: InterventionService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.createForm();
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.route.data,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diag, routerData]) => {
                this.currentDiagnostic = diag;
                this.contenuPolluant = diag.contenuDiagnostic as Polluant;
                this.isValidated = (this.contenuPolluant as Polluant).estimations?.validated;
                this.restoreData();

                //Creates listener
                this.formCompetences.valueChanges.subscribe((selectedValue) => {
                    //Création du tableau de compétences basé sur les choix du formulaire
                    const competences = [];
                    for (const key of this.COMPETENCES_KEY) {
                        if (selectedValue[key]) {
                            competences.push(this.COMPETENCES[key]);
                        }
                    }
                    //Ajout du tableau de compétences au diagnostic courant
                    if (this.contenuPolluant.estimations.data.estimation == undefined)
                        this.contenuPolluant.estimations.data.estimation = new Estimation();

                    this.contenuPolluant.estimations.data.estimation.listeCompetences = competences;
                    this.checkValidity();
                });
                this.isInEditMode = !(
                    !!routerData.readonly || this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)
                );
                if (!this.isInEditMode) {
                    this.formCompetences.disable();
                }
                this.checkValidity();
            });
    }

    /**
     * Crée le formulaire
     * @private
     */
    private createForm(): void {
        const competences = {};
        this.COMPETENCES_KEY.forEach((key) => {
            competences[key] = false;
        });
        this.formCompetences = this.formBuilder.group(competences);
    }

    /**
     * Récupères les données du diagnostic courant et les définis dans le formulaire
     */
    private restoreData() {
        if (this.contenuPolluant.estimations.data.estimation == undefined) return;

        const estimation = this.contenuPolluant.estimations.data.estimation;

        if (!estimation.listeCompetences) estimation.listeCompetences = [];

        const competences = {};

        this.COMPETENCES_KEY.forEach((key) => {
            competences[key] = estimation.listeCompetences.indexOf(this.COMPETENCES[key]) > -1;
        });

        this.formCompetences.setValue(competences);
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.currentDiagnostic);
    }

    //Interface IValidatableComponent implémentation

    cancelModification() {
        this.isInEditMode = false;
        this.formCompetences.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuPolluant.estimations.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.previousFormValue = this.formCompetences.getRawValue();
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { Commentaire } from '../../../../model/commentaire.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

class ReportCommentsModalData {
    constructor(
        public comments: Commentaire[],
        public code: string,
        public hiddenComments: Map<string, string[]> = new Map()
    ) {}
}

@Component({
    selector: 'app-report-comments-list-modal',
    templateUrl: './report-comments-list-modal.component.html',
    styleUrls: ['./report-comments-list-modal.component.scss'],
})
export class ReportCommentsListModalComponent implements OnInit {
    commentsAlreadySelected: string[] = [];

    constructor(
        private dialogRef: MatDialogRef<ReportCommentsListModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ReportCommentsModalData
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        this.data.comments.forEach((it) => {
            if (this.data.hiddenComments[this.data.code] === undefined) {
                it.selected = true;
            } else {
                it.selected = !this.data.hiddenComments[this.data.code].includes(it.id);
            }
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close({ hiddenComments: this.data.hiddenComments });
    }

    changeSelection(checked: boolean, com: Commentaire) {
        if (this.data.hiddenComments[this.data.code] === undefined) {
            this.data.hiddenComments[this.data.code] = [];
        }
        if (checked) {
            this.data.hiddenComments[this.data.code].splice(
                this.data.hiddenComments[this.data.code].indexOf(com.id),
                1
            );
        } else {
            this.data.hiddenComments[this.data.code].push(com.id);
        }
    }
}

<h1 mat-dialog-title class="primary m-0">
    <span>Ajouter un fichier</span>
</h1>

<div class="document" mat-dialog-content>
    <app-edit-fichier
        [formFichier]="formFichier"
        [documentToEdit]="documentToEdit"
        [idIntervention]="intervention?.id"
        [updateMode]="updateMode"
        [contactsIntervention]="intervention?.contacts"
        (fileUploaderOutputChanged)="onFileUploaderOutputChanged($event)"
    ></app-edit-fichier>
</div>

<div mat-dialog-actions class="justify-content-end m-0">
    <button mat-button color="primary" (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" (click)="onClickValidate()" [disabled]="!formFichier.valid">
        <lib-icon>check</lib-icon>
        <span>{{ 'Valider' | uppercase }}</span>
    </button>
</div>

"use strict";

import { cn_camera, cn_contour, cn_element } from '..';
import { cn_space_of_elements_visitor } from '../utils/visitors/impl/cn_space_of_elements_visitor';
import { cn_layer } from './cn_layer';

export class cn_zpso extends cn_layer {
    //*******************************************************
    /**
     * Constructor
     */
    constructor(name = '') {
        super(name);
        this.control_result = '';
        this.type = '';
        this.decision_criteria = '';
    }


    //*******************************************************
    /**
     * serialize
     * @returns {object} json object
     */
    serialize(building) {
        const json = super.serialize(building);
        json.control_result = this.control_result;
        json.type = this.type;
        json.decision_criteria = this.decision_criteria;
        return json;
    }

    //*******************************************************
    /**
     * unserialize
     * @returns {object} zpso object
     */
     static unserialize(json, storeys) {
        const result = new cn_zpso(json.name);
        result.ID = json.ID;
        result.elements = [...(json.elements || [])];
        result.color = json.color || '';
        result.stripes = json.stripes || '';
        result.control_result = json.control_result || '';
        result.type = json.type;
        result.decision_criteria = json.decision_criteria;
        result.sections = [...(json.sections || [])];
        result.description = json.description;
        if (json.sections) {
            result.sections = [...json.sections.map(section => {
                return {
                    shape: cn_contour.unserialize(section.shape, storeys.find(storey => storey.ID === section.storey)),
                    storey: section.storey,
                    support: section.support,
                    obj: section.obj
                }
            })];
        } else {
            result.sections = [];
        }
        if (json.lines) {
            result.lines = [...json.lines.map(line => {
                return {
                    shape: cn_contour.unserialize(line.shape, storeys.find(storey => storey.ID === line.storey)),
                    line: [...line.line],
                    storey: line.storey,
                    support: line.support,
                    obj: line.obj
                }
            })];
        }
        return result;
    }
}

import { Component, HostListener } from '@angular/core';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { BaseComponent } from 'src/app/commons-lib';
import { first } from 'rxjs/operators';
import { ElectriciteService } from '../../../services/electricite.service';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { Chapitre, PointControle, ValeurCheckpoint } from '../../../model/electricite.model';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { EtatValidation } from '../../../../../../model/etat-progression.model';
import { checkpointValid } from '../../../shared/electricite.constants';

@Component({
    selector: 'app-logement',
    templateUrl: './logement.component.html',
    styleUrls: ['../points-de-controle.component.scss'],
})
export class LogementComponent extends BaseComponent {
    readonly navItems: { route: string; name: string; etat: string }[] = [
        { route: 'contacts', name: 'Contacts', etat: 'INVALID' },
        { route: 'inadapte', name: 'Inadapté', etat: 'INVALID' },
        { route: 'salle-d-eau', name: "Salle d'eau", etat: 'INVALID' },
        { route: 'prises', name: 'Prises', etat: 'INVALID' },
        { route: 'huisseries-metalliques', name: 'Huisseries métalliques', etat: 'INVALID' },
        { route: 'conduits-metalliques', name: 'Conduits métalliques', etat: 'INVALID' },
    ];

    diagnostic: Diagnostic;
    private _allCheckpoints: PointControle[] = [];
    private _allChapitres: Chapitre[]; // menu de gauche
    private _allSousChapitres: Chapitre[] = [];

    constructor(
        private router: Router,
        private diagnosticService: DiagnosticService,
        private electriciteService: ElectriciteService,
        private etatProgressionService: EtatProgressionService,
        private route: ActivatedRoute
    ) {
        super();
        this.init();
    }

    private init() {
        combineLatest([this.electriciteService.electriciteConfig$, this.diagnosticService.getCurrentDiagnostic()])
            .pipe(first())
            .subscribe(([config, diagnostic]) => {
                const LEGEMENT = config.chapitres.find((ch) => ch.description == 'Logement');
                this._allChapitres = config.chapitres.filter((ch) => ch.idChapitreParent == LEGEMENT.idInterne);
                for (const leftMenu of this._allChapitres) {
                    for (const chapitre of config.chapitres.filter((ch) => ch.idChapitreParent == leftMenu.idInterne)) {
                        this._allSousChapitres.push(chapitre);
                        const checkpoints = config.pointsControles.filter((pc) => pc.idChapitre == chapitre.idInterne);
                        this._allCheckpoints = [...this._allCheckpoints, ...checkpoints];
                    }
                }
                this.diagnostic = diagnostic;
            });
    }

    private checkValidity() {
        if (this._allCheckpoints && this.diagnostic) {
            let etat: EtatValidation = 'VALID';
            for (const chapitre of this._allChapitres) {
                let checkpoints = [];
                for (const sousChapitre of this._allSousChapitres.filter(
                    (ssCh) => ssCh.idChapitreParent == chapitre.idInterne
                )) {
                    checkpoints = [
                        ...checkpoints,
                        ...this._allCheckpoints.filter((pc) => pc.idChapitre == sousChapitre.idInterne),
                    ];
                }
                const navItem = this.navItems.find((item) => item.name == chapitre.description);
                navItem.etat = this.checkValidityByCheckPoints(checkpoints) ? 'VALID' : 'INVALID';
            }
            for (const item of this.navItems) {
                if (item.etat != 'VALID') {
                    etat = 'INVALID';
                    break;
                }
            }
            const code = this.route.snapshot.data['code'];
            this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic);
        }
    }

    private checkValidityByCheckPoints(pCheckpoints: PointControle[]): boolean {
        if (pCheckpoints && this.diagnostic.pointsDeControleBiens[0]) {
            for (const ch of pCheckpoints) {
                const value: ValeurCheckpoint =
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[ch.idInterne];
                if (!checkpointValid(value)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    @HostListener('document:click', ['$event'])
    onDetectClick($event: any) {
        this.checkValidity();
    }
}

import { Injectable } from '@angular/core';
import { AuthenticationStore, HttpErrorInterceptor, HttpErrorService } from 'src/app/commons-lib';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Diagnostic, EtatDiagnostic } from '../model/diagnostic.model';
import { DiagnosticApiService } from './diagnostic-api.service';
import { OldOfflineStorageService } from './old-offline-storage.service';
import { EtatIntervention, Intervention, PrestationDiagnostic } from '../model/intervention.model';
import { ContenuDiagnostic } from '../model/diagnostic-contenu.model';
import { TypePrestation } from '../model/type-prestation.model';
import { DiagnosticHandlerService } from './diagnostic-handler.service';
import { BaseObject } from '../model/base-object.model';
import { OperateurData } from '../model/rapport.model';

/**
 * Service pour les diagnostics.
 * Pour la gestion du mode hors-ligne :
 * - ce service n'appelle pas directement les APIs, mais utilise {@link diagnosticApiService} pour le faire.
 * - le service d'APIs utilise HttpBackend au lieu de HttpClient, pour ne pas passer par les intercepteurs (donc bypasse l'intercepteur {@link HttpErrorInterceptor}).
 * - Le présent service doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class DiagnosticService {
    private currentDiagnostic$ = new BehaviorSubject<Diagnostic>(undefined);

    // private currentDiagnostics$ = new BehaviorSubject<Diagnostic[]>(undefined);

    constructor(
        private readonly diagnosticApiService: DiagnosticApiService,
        private readonly httpErrorService: HttpErrorService,
        private readonly offlineStorageService: OldOfflineStorageService,
        private readonly diagnosticHandlerService: DiagnosticHandlerService,
        private readonly authenticationStore: AuthenticationStore
    ) {}

    /**
     * Permet de savoir si on accède au diagnostic en readOnly ou en modification.
     * On est en readOnly quand l'intervention est TERMINEE ou ANNULEE ou si la personne qui essaie d'y accéder ne fait pas
     * partie des opérateurs définis sur l'intervention (exemple : chef de projet) ou si le diagnostic en cours est à l'état TERMINE/FINI/EN_ATTENTE
     * @param intervention
     * @param currentDiagnostic
     */
    isReadOnlyMode(intervention: Intervention, currentDiagnostic: Diagnostic): boolean {
        let readonlyMode = false;
        if (!intervention) {
            return readonlyMode;
        }
        const user = this.authenticationStore.getCurrentUserSnapshot();
        readonlyMode =
            (currentDiagnostic &&
                (currentDiagnostic.etat === EtatDiagnostic.TERMINE ||
                    currentDiagnostic.etat === EtatDiagnostic.FINI ||
                    currentDiagnostic.etat === EtatDiagnostic.EN_ATTENTE)) ||
            intervention.etat === EtatIntervention.TERMINEE ||
            intervention.etat === EtatIntervention.ANNULEE ||
            intervention.etat === EtatIntervention.NON_REALISEE ||
            !intervention.prestataires.map((it) => it.id).includes(user.id);
        return readonlyMode;
    }

    getCurrentUser(): OperateurData {
        const currentUser = this.authenticationStore.getCurrentUserSnapshot();
        const operateur = new OperateurData();
        operateur.id = currentUser.id;
        operateur.nom = currentUser.lastName;
        operateur.prenom = currentUser.firstName;
        operateur.email = currentUser.email;

        return operateur;
    }

    /**
     * Charge le diagnostic courant à partir de son id,
     * uniquement s'il est différent de l'ancien diagnostic.
     */
    /**
     * Charge le diagnostic courant à partir de son id,
     * uniquement s'il est différent de l'ancien diagnostic.
     * @param idDiagnostic
     * @param forceLoading Permet de forcer le chargement même si le diagnostic n'existe pas.
     */
    loadCurrentDiagnostic(idDiagnostic: string, forceLoading = false): Observable<Diagnostic> {
        if (
            this.currentDiagnostic$.getValue() === undefined ||
            this.currentDiagnostic$.getValue().id !== idDiagnostic ||
            forceLoading
        ) {
            return this.findOne(idDiagnostic).pipe(
                switchMap((diagnostic) => {
                    this.currentDiagnostic$.next(diagnostic);
                    return of(diagnostic);
                })
            );
        } else {
            if (this.currentDiagnostic$.getValue()) {
                return this.getCurrentDiagnostic();
            } else {
                return of(null);
            }
        }
    }

    /**
     * Recharge le diagnostic courant.
     */
    reloadCurrentDiagnostic(): void {
        const currentDiagnosticValue = this.currentDiagnostic$.getValue();
        // this.currentDiagnostic$.next(undefined);
        if (!currentDiagnosticValue) {
            return;
        }
        this.loadCurrentDiagnostic(currentDiagnosticValue.id, true).pipe(take(1)).subscribe();
    }

    /**
     * Renvoie le diagnostic courant.
     */
    getCurrentDiagnostic(useFilter = true): Observable<Diagnostic> {
        // TODO: Checker partout dans l'application si le retrait du filter ne générerait pas de régression. (checker l'existence du diagnostic au retour du subscribe)
        return this.currentDiagnostic$.asObservable().pipe(filter((it) => !useFilter || it !== undefined));
    }

    getCurrentDiagnosticValue(): Diagnostic {
        return this.currentDiagnostic$.getValue();
    }

    /**
     * Reset current diagnostic to undefined
     */
    resetCurrentDiagnostic() {
        if (this.currentDiagnostic$.getValue()) {
            this.currentDiagnostic$.next(undefined);
        }
    }

    /**
     * Vérifie si on peut passer les diagnostics a FINI, si oui, les met à jour.
     */
    resumeDiagnostics(intervention: Intervention, diagnostics: Diagnostic[]): Observable<Diagnostic[]> {
        return forkJoin(
            diagnostics.map((diagnostic) => {
                return this.resumeDiagnostic(intervention, diagnostic);
            })
        );
    }

    /**
     * Vérifie si on peut passer un diagnostic à EN_COURS, si oui, le met à jour.
     */
    resumeDiagnostic(intervention: Intervention, diagnostic: Diagnostic): Observable<Diagnostic> {
        if (diagnostic.etat === EtatDiagnostic.EN_ATTENTE || diagnostic.etat === EtatDiagnostic.FINI) {
            diagnostic.etat = EtatDiagnostic.EN_COURS;
        }
        return this.upsert(intervention, diagnostic).pipe(
            catchError((err) => {
                return throwError(err);
            }),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Vérifie si on peut passer un diagnostic à FINI, si oui, le met à jour.
     */
    endCurrentDiagnostic(intervention: Intervention, diagnostic: Diagnostic): Observable<Diagnostic> {
        if (diagnostic.etat === EtatDiagnostic.EN_ATTENTE) {
            diagnostic.etat = EtatDiagnostic.FINI;
            return this.upsert(intervention, diagnostic).pipe(
                catchError((err) => {
                    return throwError(err);
                }),
                catchError((err) => this.httpErrorService.handleError(err))
            );
        }
    }

    /**
     * Renvoie un diagnostic.
     */
    findOne(idDiagnostic: string): Observable<Diagnostic> {
        return this.diagnosticApiService.findOne(idDiagnostic).pipe(
            catchError((err) => {
                return throwError(err);
            }),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Crée ou met à jour un diagnostic.
     *
     * Dans le cas d'une mise à jour, seul le contenu est mis à jour.
     */
    upsert(intervention: Intervention, diagnostic: Diagnostic): Observable<Diagnostic> {
        // Nettoie le formulaire avant update
        this.prepareForm(intervention, diagnostic);

        return this.diagnosticApiService.upsert(diagnostic).pipe(
            catchError((err) => {
                return throwError(err);
            }),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Nettoie le diagnostic avant un update.
     */
    prepareForm(intervention: Intervention, diagnostic: Diagnostic) {
        const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(diagnostic.typePrestation);

        if (typePrestationService) {
            typePrestationService.prepareForm(intervention, diagnostic.contenuDiagnostic);
        }
    }

    /**
     * Retourne une instance du ContenuDiagnostic correspondant au type de prestation,
     * null si le type de prestation est inconnu
     */
    getContenuDiagnostic(
        typePrestation: TypePrestation,
        prestation?: PrestationDiagnostic
    ): Observable<ContenuDiagnostic> {
        const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(typePrestation);
        if (typePrestationService) {
            return typePrestationService.getContenuDiagnostic(typePrestation, prestation);
        }
        return null;
    }
    // getContenuDiagnostic(typePrestation: TypePrestation): Promise<ContenuDiagnostic> {
    //     const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(typePrestation);
    //     if (typePrestationService) {
    //         return typePrestationService.getContenuDiagnostic(typePrestation);
    //     }
    //     return null;
    // }

    /**
     * Retourne la liste des code BIM des équipements pour un bien pour un type de prestation,
     * ou un tableau vide si le type de prestation ne correspond à rien
     */
    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(typePrestation);
        if (typePrestationService) {
            return typePrestationService.getCodeBimEquipementBien(typePrestation);
        }
        return [];
    }

    /**
     * Retourne tous les diagnostics d'une intervention
     */
    getAllDiagnosticsForCurrentIntervention(intervention?: Intervention): Observable<Diagnostic[]> {
        const prestationDiagnostics = intervention?.prestationsDiagnostics?.filter((p) => !!p.idDiagnostic);
        if (prestationDiagnostics?.length) {
            const obs$ = prestationDiagnostics.map((p) => this.findOne(p.idDiagnostic));
            return forkJoin(obs$);
        } else {
            return of([]);
        }
    }

    /**
     * Retourne toutes les prestations d'une intervention
     * l'id est l'identifiant de la prestationDiagnostics de l'intervention et le nom est celui de la prestation correspondante
     */
    getAllPrestationsDiagnosticByInterventionId(idIntervention: string): Observable<BaseObject[]> {
        return this.diagnosticApiService.getAllPrestationsDiagnosticByInterventionId(idIntervention);
    }

    /**
     * Retourne le diagnostic suivant une intervention et une prestationDiagnostic
     */
    getDiagnosticByInterventionIdAndPrestationDiagnosticId(
        idIntervention: string,
        idPrestationDiagnostic: string
    ): Observable<Diagnostic> {
        return this.diagnosticApiService.getDiagnosticByInterventionIdAndPrestationDiagnosticId(
            idIntervention,
            idPrestationDiagnostic
        );
    }

    /**
     * demande de génération de tous les rapports du diagnostic
     * @param diagnostic
     * @param idReferencePrestation
     * @param idIntervention
     */
    exportReport(
        diagnostic: Diagnostic,
        idReferencePrestation: string,
        idIntervention: string
    ): Observable<Diagnostic> {
        return this.diagnosticApiService.exportReport(diagnostic, idReferencePrestation, idIntervention);
    }

    /**
     * demande de génération de tous les bons de commande du diagnostic
     * @param diagnostic
     * @param idReferencePrestation
     */
    exportBonCommandeAll(
        diagnostic: Diagnostic,
        idReferencePrestation: string,
        idIntervention: string,
        idsBiens: string[]
    ): Observable<Diagnostic> {
        return this.diagnosticApiService.exportBonCommandeAll(
            diagnostic,
            idReferencePrestation,
            idIntervention,
            idsBiens
        );
    }

    /**
     * Permet de savoir si au moins un diagnostics est EN_ATTENTE ou FINI.
     * @param Diagnostic[]
     */

    isDiagnosticWaitingOrEnding(diagnostics: Diagnostic[]): boolean {
        if (!diagnostics) {
            return false;
        }
        const diagnosticsWaitingOrEnding = diagnostics.filter(
            (diag) =>
                diag.etat === EtatDiagnostic.EN_ATTENTE ||
                diag.etat === EtatDiagnostic.FINI ||
                diag.etat === EtatDiagnostic.TERMINE
        );
        if (diagnosticsWaitingOrEnding.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Indique si l'option plan du diagnostic courant est activé ou non.
     * @param intervention
     * @param diagnostic
     */
    getCurrentOptionPlan(intervention: Intervention, diagnostic: Diagnostic) {
        const currentPrestationDiagnostic = intervention.prestationsDiagnostics.find(
            (prestrationDiagnostic) => prestrationDiagnostic.idDiagnostic === diagnostic.id
        );
        if (
            currentPrestationDiagnostic &&
            currentPrestationDiagnostic.prestation &&
            currentPrestationDiagnostic.prestation.optionPlan
        ) {
            return currentPrestationDiagnostic.prestation.optionPlan;
        } else {
            return false;
        }
    }

    /**
     * Met à jour un diagnostic avec les données du diagnostic parent
     * @param interventionToUpdate
     * @param diagnosticToUpdate
     * @param diagnosticParent
     */
    updateDiagnosticWithDiagnosticParent(
        interventionToUpdate: Intervention,
        diagnosticToUpdate: Diagnostic,
        diagnosticParent: Diagnostic
    ): Observable<Diagnostic> {
        if (diagnosticToUpdate && diagnosticParent) {
            const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(
                diagnosticToUpdate.typePrestation
            );

            diagnosticToUpdate.contenuDiagnostic = typePrestationService.getContenuDiagnosticFromParent(
                diagnosticToUpdate,
                diagnosticParent
            );
            // diagnosticToUpdate.contenuDiagnostic = { ...diagnosticParent.contenuDiagnostic };
            diagnosticToUpdate.reportagesPhoto = [...diagnosticParent.reportagesPhoto];
            diagnosticToUpdate.screenshotsPlan = [...diagnosticParent.screenshotsPlan];
            diagnosticToUpdate.pointsDeControleBiens = [...diagnosticParent.pointsDeControleBiens];
            diagnosticToUpdate.recommandationsFinales = [...diagnosticParent.recommandationsFinales];
            diagnosticToUpdate.constatationsFinales = [...diagnosticParent.constatationsFinales];
            diagnosticToUpdate.hiddenComments = { ...diagnosticParent.hiddenComments };

            return this.upsert(interventionToUpdate, diagnosticToUpdate);
        } else {
            return of(null);
        }
    }
}

"use strict";

import {cn_element} from '..';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************
//**** Zone
//***********************************************************************************
//***********************************************************************************

export const ZONE_EXTERIOR_LABEL = 'Extérieur';

export class cn_zone extends cn_element {
    //*******************************************************
    /**
     * Constructor
     */
    constructor(name = '', main_storey = '', zone_type = '', space_auto_numerotation_idx = 0) {
        super();
        this.name = name;
        this.zone_type = zone_type;
        this.main_storey = main_storey;
        this.color = '';
        this.rooms = [];
    }


    //*******************************************************
    /**
     * serialize
     * @returns {object} json object
     */
    serialize() {
        const json = {};
        json.ID = this.ID;
        json.name = this.name;
        json.main_storey = this.main_storey;
        json.zone_type = this.zone_type;
        json.rooms = [...this.rooms];
        json.color = this.color;
        return json;
    }

    //*******************************************************
    /**
     * unserialize
     * @returns {object} zone object
     */
     static unserialize(json) {
        const result = new cn_zone(json.name);
        result.ID = json.ID;
        result.name = json.name;
        result.main_storey = json.main_storey;
        result.zone_type = json.zone_type || '';
        result.rooms = [...json.rooms];
        result.color = json.color;
        return result;
    }

    /**
     * Add room to zone
     *
     * @param {string} space
     * @param {string} storey
     */
    add_room(space, storey) {
        this.rooms.push({space, storey});
    }

    /**
     * Remove room from zone
     *
     * @param {string} space
     * @param {string} storey
     */
    remove_room(space, storey) {
        this.rooms.splice(this.rooms.findIndex(room => room.space === space && room.storey === storey), 1);
    }
}

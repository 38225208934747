"use strict";
import { cn_pipe } from "../model/cn_pipe";
import { cn_edition_handler } from "./cn_edition_handler";
import { cn_svg_map } from "./cn_svg_map";
import {cn_3d_line_handler} from "./cn_3d_line_handler";
import {fh_clone} from "@acenv/fh-3d-viewer";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_type_pastille} from "./cn_type_pastille";
import { cn_edit_box } from "./cn_edit_box";
import { cn_number_input } from "./cn_inputs";

export class cn_pipe_handler extends cn_edition_handler {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {Array<cn_pipe>} pipes
	 * @param {cn_svg_map} map
	 * @param {boolean} creation
	 */
	constructor(pipes, map, creation = false) {
		super(pipes,map);

		this._map = map;
		this._scene = this._map._scene;
		this._transaction_manager = this._map._building.transaction_manager;

		//*** handler for shape */
		this._handler = null;

		//*** pipe
		this.pipes = pipes;
		this._pipe = (pipes.length>1)?null:pipes[0];
		if (this._pipe) this._initialize_edition();

		this._current_vertex_index = 0;

		//*** Edit box for mass selection */
		const edit_box = new cn_edit_box(this,pipes,creation);
		this._handlers.push(edit_box);

		//*** Type pastille */
		const type_pastille = new cn_type_pastille(pipes,"element_type",function(){return map._building.get_pipe_types();},"Type de canalisation",map);
		type_pastille.title = "Modifier le type de conduit";
		edit_box.add_pastille(type_pastille);

		edit_box.add_lock_pastille(this._transaction_manager);

		edit_box.add_select_siblings_pastille("element_type");
		edit_box.add_information_pastille("element_type");
	}

	//***********************************************************************************
	//**** Draws
	//***********************************************************************************
	draw(camera) {
		var html = "";

		if (this._pipe) html += this._pipe.draw(camera, ["selected"]);

		if (this._handler)
			this._handler.active = this._handler.visible = !this._pipe.locked;

		html += super.draw(camera);

		return html;
	}

	//***********************************************************************************
	//**** clear move effects
	//***********************************************************************************
	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} ev
	 * @returns  {boolean}
	 */
	move (ev) {
		if (super.move(ev)) return true;

		return this._pipe && this._pipe.contains(ev.mouse_world,ev.camera.snap_world_distance);
	}

	_initialize_edition() {
		this._handler = null;

		var obj = this;
		var scene = obj._scene;

		this._handler = new cn_3d_line_handler(this,this._pipe.vertices);
		this._handlers.push(this._handler);
		this._handler.automatic_height = NaN;

		//*** Callback on change */
		this._handler.on("change",function(){
			obj._transaction_manager.push_transaction("Modification de conduit",obj._pipe.ID);
			obj._transaction_manager.push_item_set(obj._pipe,"vertices");
			obj._pipe.vertices[0] = fh_clone(obj._handler["vertices"][0]);
			obj._pipe.vertices[1] = fh_clone(obj._handler["vertices"][1]);
		});

		this._handler.on("height_click",function(index) {
			const input = new cn_number_input("Hauteur",obj._pipe.vertices[index][2],"m",2,-100,100);
			input.callback = function() {
				obj._transaction_manager.push_transaction("Hauteur de conduit");
				obj._transaction_manager.push_item_set(obj._pipe,"vertices");
				obj._pipe.vertices[index][2] = input.value;
				obj._handler["vertices"][index][2] = input.value;
				obj._map.refresh_tool();
			}
			obj.call("number_input",input);
			return true;
		});
	}
}


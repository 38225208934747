import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';

export interface ConfirmationOptions {
    message?: string;
    titleLabel?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    warn?: boolean;
    messageCheckboxConfirm?: string;
}

@Injectable()
export class ConfirmationService {
    constructor(public dialog: MatDialog) {}

    confirm(input: string | ConfirmationOptions, callback: () => any, callbackCancel?: () => any): MatDialogRef<any> {
        if (typeof input === 'string') {
            return this.doConfirm({ message: input }, callback, callbackCancel);
        }
        return this.doConfirm(input, callback, callbackCancel);
    }

    confirmWarn(
        input: string | ConfirmationOptions,
        callback: () => any,
        callbackCancel?: () => any
    ): MatDialogRef<any> {
        if (typeof input === 'string') {
            return this.doConfirm({ message: input, warn: true }, callback, callbackCancel);
        }
        return this.doConfirm({ ...input, warn: true }, callback, callbackCancel);
    }

    private doConfirm(options: ConfirmationOptions, callback: () => any, callbackCancel?: () => any) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: options });
        dialogRef.afterClosed().subscribe((dialogResult) => {
            if (dialogResult === true && callback) {
                callback();
            } else if (dialogResult === false && callbackCancel) {
                callbackCancel();
            }
        });
        return dialogRef;
    }
}

import { cn_marker, cn_mouse_event, cn_svg_map } from '@acenv/cnmap-editor';
import { PerimetreEditionHandler } from './perimetre-edition-handler';

export class MarkerHandler extends PerimetreEditionHandler {
    constructor(marker: cn_marker, map: cn_svg_map) {
        super(new Array<cn_marker>(marker), map);
    }

    drag(mouse_event: cn_mouse_event) {
        const resultat_drag = super.drag(mouse_event);
        // TODO if à remettre quand cnmap-editor aura été mis à jour et rajouter le return true dans le drag du cn_marker
        if (resultat_drag) {
            // Prolonge l'évènement au composant parent
            this.call('change');
        }

        return resultat_drag;
    }
}

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { Diagnostic } from '../../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../../services/diagnostic.service';
import { first } from 'rxjs/operators';
import {
    CeeConfig,
    CeeModel,
    Chapitre,
    ISolution,
    PointControle,
    PointControleReponse,
    TypeCee,
} from '../../model/cee.model';
import {
    EtapeDiagnosticAbstract,
    EtapeDiagnosticGenerique,
    ItemEtapeDiagnosticGenerique,
} from '../../../../../model/diagnostic-etape.model';
import { EtatProgressionService } from '../../../../../services/etat-progression.service';
import { CONTROL_POINT } from '../../../../../shared/constants/names.step.constants';
import { CeeService } from '../../services/cee.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogDuplicationComponent } from './dialog-duplication/dialog-duplication.component';
import { NavigationEnd, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import {
    CODE_ISOLANT_DEROULE,
    CODE_ISOLANT_SOUFFLE,
    CODE_ISOLANT_SOUSRAMPANT,
    CODE_NON,
    CODE_NON_VISIBLE,
    CODE_OUI,
    nonOption,
    TYPE_ISOLANT,
} from '../../shared/cee.constants';
import { ProgressDiagnosticService } from '../../../../../services/progress-diagnostic.service';

@Component({
    selector: 'app-points-de-controles',
    templateUrl: './points-de-controles.component.html',
    styleUrls: ['./points-de-controles.component.scss'],
})
export class PointsDeControlesComponent extends BaseComponent implements OnInit, OnDestroy {
    readonly NON_VISIBLE = CODE_NON_VISIBLE;
    public diagnostic: Diagnostic;
    readonlyMode: boolean = false;
    solutioniSolationValid: boolean = false; // Formulaire DialogSolutionIsolationComponent
    public zones: string[] = [];
    public zoneSelected: string; // 1/ Menu selectionné
    public currentIndexSolution: number; // l'index de l'isolant en cours de consultation
    public contentSelected: {
        chapitre: string;
        content: {
            sousChapitre?: string;
            pointsDeControles: PointControle[];
        }[];
    }; // 2/ ContenuMenu selectionné
    private _ceeModel: CeeModel;
    private _subscription: Subscription;
    private _pointsDeControleGlobal: PointControle[]; // Info isolant
    config: {
        chapitre: string;
        content: {
            sousChapitre?: string;
            pointsDeControles: PointControle[];
        }[];
    }[] = [];

    constructor(
        private etatProgressionService: EtatProgressionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly confirmationService: ConfirmationService,
        private readonly progressDiagnosticService: ProgressDiagnosticService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private readonly notificationService: NotificationService,
        private matDialog: MatDialog,
        private readonly ceeService: CeeService
    ) {
        super();
    }

    ngOnInit(): void {
        combineLatest([this.diagnosticService.getCurrentDiagnostic(), this.ceeService.ceeConfig$])
            .pipe(first())
            .subscribe(([diagnostic, config]) => {
                this.diagnostic = diagnostic;
                this._ceeModel = diagnostic.contenuDiagnostic as CeeModel;
                this.initConfig(config);
                this.navigateToSolutionByUrl();
            });

        this._subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.navigateToSolutionByUrl();
            }
        });
    }

    private navigateToSolutionByUrl() {
        const urls = this.router.url.split('/controles/');
        let obj: any = null;
        if (urls[1]) {
            try {
                obj = JSON.parse(decodeURIComponent(escape(window.atob(urls[1]))));
            } catch (e) {
                urls.length = 1;
            }
        }
        const indexSolution = this.solutions.findIndex(
            (sol) => !!sol.infoIsolant.find((inf) => obj && inf.valeur === obj.solutionName)
        );
        if (indexSolution >= 0) {
            this.currentIndexSolution = indexSolution;
            this.zoneSelected = obj.zoneName;
            this.zones = Object.getOwnPropertyNames(this.pointsDeControles.data);
            if (obj.chapitre) {
                this.sousMenuSelected(obj.chapitre);
            }
            this.goToPointDeControle(obj.idInterne);
        } else if (this.solutions.length) {
            const firstSol = this.solutions[0].infoIsolant.find((val) =>
                this.ceeService.idsNomSolution.includes(val.id)
            );
            const url = urls.length == 2 ? urls[0] + '/controles' : urls[0];
            this.router.navigate([url, this.transformToRouteSolution(firstSol ? firstSol.valeur : '1')]);
        } else if (urls.length == 1) {
            const hash: string = btoa(
                unescape(encodeURIComponent(JSON.stringify({ init: 'Point de contrôle' })))
            ).replace(/=/g, '');
            this.router.navigate([urls[0], hash]);
        }
    }

    public transformToRouteSolution(solutionName: string, zoneName?: string, chapitre?: string) {
        return btoa(unescape(encodeURIComponent(JSON.stringify({ solutionName, zoneName, chapitre })))).replace(
            /=/g,
            ''
        );
    }

    public getPointDeControle(pPointControle: PointControle): EtapeDiagnosticGenerique {
        if (this.pointsDeControles.data[this.zoneSelected]) {
            const lValue: EtapeDiagnosticGenerique = this.pointsDeControles.data[this.zoneSelected].find(
                (value) => value.id == pPointControle.idInterne
            );
            if (lValue) {
                return lValue;
            }
            const newValue = new EtapeDiagnosticGenerique();
            newValue.id = pPointControle.idInterne;
            newValue.disabled = pPointControle.isGrey;
            this.pointsDeControles.data[this.zoneSelected].push(newValue);
            return newValue;
        } else {
            console.error('La clé', this.zoneSelected, "n'existe pas !");
        }
        return null;
    }

    public onChangeValue(value: EtapeDiagnosticGenerique) {
        this.progressDiagnosticService.refresh();
        if (!value) return;
        if (value.valeur && ['PC1', 'PC100', 'PC200', 'PC333', 'PC420'].includes(value.id)) {
            // PC1 / PC100 / ... => NAME ZONE
            if (this.zoneSelected == value.valeur) {
                return;
            }
            if (this.pointsDeControles.data[value.valeur]) {
                value.valeur = this.zoneSelected;
                this._snackBar.open('Une autre zone possède déjà le même nom.', '', { duration: 5000 });
                return;
            }
            this.pointsDeControles.data[value.valeur] = this.pointsDeControles.data[this.zoneSelected];
            const newMap = new Map();
            for (const key of Object.getOwnPropertyNames(this.pointsDeControles.data)) {
                if (key != this.zoneSelected) {
                    newMap[key] = this.pointsDeControles.data[key];
                }
            }
            this.pointsDeControles.data = newMap;
            this.zoneSelected = value.valeur;
            this.zones = Object.getOwnPropertyNames(this.pointsDeControles.data);
        }
        // Auto réponse sur les point de controle cible
        this.ceeService.ceeConfig$.pipe(first()).subscribe((config: CeeConfig) => {
            const ptc: PointControle = config.pointsControles.find((chp) => chp.idInterne == value.id);
            const reponsesCible: PointControleReponse[] = ptc.lstPointsContolesReponsesCibles.filter(
                (val) => val.codeReponseInitiale == value.valeur
            );
            const listCheckPoint: EtapeDiagnosticGenerique[] = this.pointsDeControles.data[this.zoneSelected];
            for (const repCibl of reponsesCible) {
                const checkP = listCheckPoint.find((ckp) => ckp.id == repCibl.idPointControleCible);
                if (!!checkP) {
                    checkP.disabled = repCibl.isGreyCible;
                    checkP.valeur = repCibl.reponseCible.code;
                }
            }
        });
        // Calcule de la réponse zone très froid
        if (value.valeur && ['PC47', 'PC48'].includes(value.id)) {
            // PC49 : "Le bien est-il en zone froide",PC48 : Altitude du bien// PC47: Num de département
            const dep = this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC47').valeur;
            const alt = this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC48').valeur;
            this.ceeService.ceeConfig$
                .subscribe((config) => {
                    let isZoneFroide = null;
                    if (dep && alt) {
                        isZoneFroide = config.tableauZoneTresFroide.lstDepartementAltitude.find(
                            (depAlt) => +depAlt.departement === +dep && +depAlt.altitude < +alt
                        );
                    }
                    this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC49').valeur = isZoneFroide
                        ? CODE_OUI
                        : CODE_NON;
                })
                .unsubscribe();
        }

        // Calcule de la réponse PC53 :Pare-vapeur obligatoire pour Sous rampant
        if (value.valeur && ['PC49', 'PC52'].includes(value.id)) {
            const PC49 = this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC49');
            const PC52 = this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC52');
            if (PC49.valeur === CODE_OUI || (PC49.valeur === CODE_NON && PC52.valeur === CODE_NON)) {
                this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC53').valeur = CODE_OUI;
            } else if (PC52.valeur === CODE_OUI) {
                this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC53').valeur = CODE_NON;
            }
        }
        // Calcule de la réponse PC54 :Pare-vapeur obligatoire Si isolation soufflé ou déroulé
        if (value.valeur && ['PC58', 'PC59', 'PC49'].includes(value.id)) {
            const PC58 = this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC58');
            const PC59 = this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC59');
            let valeur = null;
            if (PC58.valeur === CODE_OUI) {
                valeur = CODE_NON;
            } else if (PC58.valeur === CODE_NON && PC59.valeur === CODE_OUI) {
                valeur = CODE_OUI;
            } else if (PC58.valeur === CODE_NON && PC59.valeur === CODE_NON) {
                const PC49 = this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC49');
                valeur = PC49.valeur;
            }
            if (valeur) {
                const checkpoint = this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC54');
                checkpoint.valeur = valeur;
                checkpoint.disabled = true;
                this.calculerReponsePareVapeurConclusion(checkpoint);
            }
        }

        this.calculerReponsePareVapeurConclusion(value);
        // Vérification de la validité
        this.verifyValidity();
    }

    private calculerReponsePareVapeurConclusion(value: EtapeDiagnosticGenerique) {
        // Calcule de la réponse PC55 "Pare-vapeur Conclusion :"
        // 1 - PC75/PC143/PC68/PC73 Présence d'un pare-vapeur sur la preuve de réalisation
        // 2 - PC53/PC54 obligatoire
        // 3 - PC50 Présence d'un pare-vapeur
        if (value.valeur && ['PC75', 'PC50', 'PC53', 'PC54'].includes(value.id)) {
            const valueONE = this.currentSolution.infoIsolant.find((ckp) =>
                ['PC75', 'PC143', 'PC68', 'PC73'].includes(ckp.id)
            ).valeur;
            const valueTWO = this.pointsDeControles.data[this.zoneSelected].find((ckp) =>
                ['PC53', 'PC54'].includes(ckp.id)
            ).valeur;
            const valueTHREE = this.pointsDeControles.data[this.zoneSelected].find((ckp) => 'PC50' === ckp.id).valeur;
            if (valueTWO === CODE_OUI && valueTHREE === 'absent') {
                // 2 OUI 3 CA absentObligatoire
                this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC55').valeur =
                    'absentObligatoire';
            } else if (valueONE === CODE_OUI && valueTHREE === 'absent') {
                // 1 OUI 3 CA absentPresentFacture
                this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC55').valeur =
                    'absentPresentFacture';
            } else if (valueTWO === CODE_NON) {
                // 2 NON nonobligatoire
                this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC55').valeur =
                    'nonobligatoire';
            } else if (valueONE === CODE_NON_VISIBLE && valueTWO === CODE_OUI && valueTHREE === 'present') {
                // 1 NV 2 OUI 3 CP nonVerifiableObligatoirePresentFacture
                this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC55').valeur =
                    'nonVerifiableObligatoirePresentFacture';
            } else if (valueONE === CODE_NON_VISIBLE && valueTWO === CODE_OUI && valueTHREE === 'absent') {
                // 1 NV 2 OUI 3 CA nonVerifiableObligatoireAbsentFacture
                this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC55').valeur =
                    'nonVerifiableObligatoireAbsentFacture';
            } else if (valueONE === CODE_NON_VISIBLE && valueTWO === nonOption.value && valueTHREE) {
                // 1 NV 2 NON nonVerifiableNonNecessaire
                this.pointsDeControles.data[this.zoneSelected].find((ckp) => ckp.id == 'PC55').valeur =
                    'nonVerifiableNonNecessaire';
            }
        }
    }

    // Vérification de la validité de l'étape
    private verifyValidity() {
        let nbSolutionValid = 0;
        for (const solution of this.solutions) {
            let valid: boolean = Object.getOwnPropertyNames(solution.pointsDeControles.data).length ? true : true;
            for (const key of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                if (
                    this.pointsDeControles.data[key] &&
                    this.pointsDeControles.data[key].find((ptc) =>
                        ptc.checked
                            ? false
                            : ptc.valeur
                            ? ptc.valeur == this.NON_VISIBLE && !ptc.commentairesId.length
                                ? true
                                : !ptc.valeur.length
                            : true
                    )
                ) {
                    // Au moins un point n'est pas valide
                    valid = false;
                    break;
                }
            }
            nbSolutionValid += valid ? 1 : 0;
            solution.valid = valid;
        }
        const status =
            this.solutioniSolationValid && nbSolutionValid && nbSolutionValid == this.solutions.length
                ? 'VALID'
                : 'INVALID';
        this.etatProgressionService.updateDiagnostic(CONTROL_POINT, status, this.diagnostic);
    }

    public sousMenuSelected(pChapitreName: string) {
        this.contentSelected = Object.assign(
            {},
            this.config.find((cr) => cr.chapitre == pChapitreName)
        );
        if (this.contentSelected.chapitre == 'Pare-vapeur' || this.contentSelected.chapitre == 'Mise en oeuvre') {
            const ptc = this._pointsDeControleGlobal.find((conf) => conf.description == TYPE_ISOLANT);
            if (!ptc) return;
            const rep: EtapeDiagnosticGenerique = this.currentSolution.infoIsolant.find(
                (inf) => inf.id == ptc.idInterne
            );
            if (!rep) return;
            const choix = ptc.lstChoixReponse.find((val) => val.code == rep.valeur);
            if (!choix) return;
            if (this.contentSelected.chapitre == 'Mise en oeuvre') {
                const content = this.contentSelected.content.filter((cnt) => !cnt.sousChapitre);
                this.contentSelected.content = [
                    ...content,
                    ...this.contentSelected.content.filter((cnt) =>
                        cnt.sousChapitre
                            ? cnt.sousChapitre.replace(/ /g, '') === choix.description.replace(/ /g, '')
                            : false
                    ),
                ];
            } else {
                // Pare-vapeur
                if (CODE_ISOLANT_SOUFFLE == choix.code || CODE_ISOLANT_DEROULE == choix.code) {
                    this.contentSelected.content = this.contentSelected.content.filter(
                        (cnt) => cnt.sousChapitre !== 'Isolant sous rampant'
                    );
                } else if (CODE_ISOLANT_SOUSRAMPANT == choix.code) {
                    this.contentSelected.content = this.contentSelected.content.filter(
                        (cnt) => cnt.sousChapitre !== 'Isolant soufflé ou déroulé'
                    );
                }
            }
        }
        console.log(this.contentSelected);
    }

    public delSolutionFacturee(index: number) {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer cette solution facturée ?', () => {
            const urls = this.router.url.split('/controles/');
            this.router.navigate([
                urls[0] +
                    '/controles/' +
                    [
                        btoa(unescape(encodeURIComponent(JSON.stringify({ init: 'Point de contrôle' })))).replace(
                            /=/g,
                            ''
                        ),
                    ],
            ]);
            this.currentIndexSolution = -1;
            this._ceeModel.solutions.solutions.splice(index, 1);
            this.notificationService.notify('La solution facturée a bien été supprimée.');
        });
    }

    public addSolutionFacturee() {
        this.currentIndexSolution = this._ceeModel.solutions.solutions.length;
        const nom = new EtapeDiagnosticGenerique();
        const ptcNomSolution = this._pointsDeControleGlobal.find((ptc) =>
            this.ceeService.idsNomSolution.includes(ptc.idInterne)
        );
        if (ptcNomSolution) {
            nom.valeur = 'Solution ' + String.fromCharCode(this.currentIndexSolution + 65);
            nom.id = ptcNomSolution ? ptcNomSolution.idInterne : '1';
        }
        const infoIsolant = [nom];
        const newSolution: ISolution = {
            infoIsolant,
            pointsDeControles: new EtapeDiagnosticAbstract(),
        };
        this._ceeModel.solutions.solutions[this.currentIndexSolution] = newSolution;
        const urls = this.router.url.split('/controles/');
        const url = urls.length == 2 ? urls[0] + '/controles' : this.router.url;
        this.router.navigate([url, this.transformToRouteSolution(this.getNomIsolant(this.currentSolution).valeur)]);
        this.verifyValidity();
    }

    public addZone() {
        for (let i = 0; i < 26; i++) {
            const key: string = 'Zone ' + String.fromCharCode(i + 65);
            if (this.pointsDeControles.data[key] ? false : true) {
                this.pointsDeControles.data[key] = [];
                this.zoneSelected = key;
                this.zones.push(key);
                // Initialisation des réponses
                for (let ii = this.config.length - 1; ii >= 0; ii--) {
                    this.sousMenuSelected(this.config[ii].chapitre); // init pointsDeControles
                    for (const container of this.contentSelected.content) {
                        for (const ptc of container.pointsDeControles) {
                            this.getPointDeControle(ptc); // init PTC
                        }
                    }
                }
                this.progressDiagnosticService.refresh();
                this.verifyValidity();
                const urls = this.router.url.split('/controles/');
                const url = urls.length == 2 ? urls[0] + '/controles' : this.router.url;
                this.router.navigate([
                    url,
                    this.transformToRouteSolution(this.getNomIsolant(this.currentSolution).valeur, key),
                ]);
                return;
            }
        }
    }

    /**
     * Suppression d'une zone
     */
    deleteZone(navItem: string) {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer cette zone ?', () => {
            const newMap = new Map();
            for (const key of Object.getOwnPropertyNames(this.pointsDeControles.data)) {
                if (key != navItem) {
                    newMap[key] = this.pointsDeControles.data[key];
                }
            }

            this.pointsDeControles.data = newMap;
            this.zones = Object.getOwnPropertyNames(this.pointsDeControles.data);
            this.zoneSelected = null;
            this.verifyValidity();
            const urls = this.router.url.split('/controles/');
            const url = urls.length == 2 ? urls[0] + '/controles' : this.router.url;
            this.router.navigate([url, this.transformToRouteSolution(this.getNomIsolant(this.currentSolution).valeur)]);
            this.notificationService.notify('La zone a bien été supprimée.');
        });
    }

    /**
     * Dupliquer les données de toute une solution
     * Sauf le nom de la zone
     * @param {EtapeDiagnosticGenerique} value
     */
    duplicateSolution(value: EtapeDiagnosticGenerique) {
        const dialogRef = this.matDialog.open(DialogDuplicationComponent);
        dialogRef.componentInstance.zones = this.zones.filter((nav) => nav != this.zoneSelected);
        dialogRef.afterClosed().subscribe((zoneSelected: string) => {
            if (zoneSelected) {
                const copyData = [];
                this.pointsDeControles.data[zoneSelected].forEach((v) => {
                    const newValue = Object.assign(new EtapeDiagnosticGenerique(), v);
                    if (newValue.id === value.id) {
                        const newZoneName = new ItemEtapeDiagnosticGenerique();
                        newZoneName.valeur = zoneSelected; // La zone dupliquée est stocké dans elements;
                        newValue.elements = [newZoneName];
                    }
                    copyData.push(newValue);
                });
                this.pointsDeControles.data[this.zoneSelected] = copyData;

                for (const ptc of copyData) {
                    if (ptc.valeur && ['PC1', 'PC100', 'PC200', 'PC333', 'PC420'].includes(ptc.id)) {
                        ptc.valeur = this.zoneSelected;
                        break;
                    }
                }
            }
        });
    }

    get pointsDeControles(): EtapeDiagnosticAbstract {
        return this.currentIndexSolution >= 0
            ? this._ceeModel.solutions.solutions[this.currentIndexSolution].pointsDeControles
            : null;
    }

    public getNomIsolant(solution: ISolution) {
        const nomIsolant = solution
            ? solution.infoIsolant.find((val) => this.ceeService.idsNomSolution.includes(val.id))
            : null;
        return nomIsolant;
    }

    get currentSolution(): ISolution {
        return this.solutions[this.currentIndexSolution];
    }

    get solutions(): ISolution[] {
        return this._ceeModel.solutions.solutions;
    }

    @HostListener('document:click', ['$event'])
    onDetectClick($event: any) {
        // set nom de la zone
        if (this.zoneSelected) {
            for (const ptc of this.pointsDeControles.data[this.zoneSelected]) {
                if (!ptc.valeur && ['PC1', 'PC100', 'PC200', 'PC333', 'PC420'].includes(ptc.id)) {
                    ptc.valeur = this.zoneSelected;
                }
            }
        }
    }

    private initConfig(config: CeeConfig) {
        const typeCee: TypeCee = config.typesCee.find((type) => type.description == this.diagnostic.typePrestation);
        const racine: Chapitre = config.chapitres.find((ch) => !ch.idChapitreParent && !ch.idTypeCee);
        const chapitres: Chapitre[] = config.chapitres.filter(
            (ch) => ch.idTypeCee == typeCee.idInterne && ch.idChapitreParent == racine.idInterne
        );
        const racine0: Chapitre = config.chapitres.find((ch) => ch.idTypeCee == typeCee.idInterne);
        const racine01: Chapitre = config.chapitres.find((ch) => ch.idChapitreParent == racine0.idInterne);
        const idChapitre = racine01 ? racine01.idInterne : racine0.idInterne;
        this._pointsDeControleGlobal = config.pointsControles.filter((p) => p.idChapitre == idChapitre);

        for (const chapitre of chapitres) {
            const pointsDeControles: {
                sousChapitre?: string;
                pointsDeControles: PointControle[];
            }[] = [];
            for (const ssCh of config.chapitres.filter((ch) => ch.idChapitreParent == chapitre.idInterne)) {
                // search points de controle
                pointsDeControles.push({
                    sousChapitre: ssCh.description,
                    pointsDeControles: config.pointsControles.filter((pt) => pt.idChapitre == ssCh.idInterne),
                });
            }
            const contents = [
                ...pointsDeControles,
                {
                    pointsDeControles: config.pointsControles.filter((pt) => pt.idChapitre == chapitre.idInterne),
                },
            ];
            this.config.push({
                chapitre: chapitre.description,
                content: [...contents.filter((pt) => !pt.sousChapitre), ...contents.filter((pt) => !!pt.sousChapitre)],
            });
            // set décalage
            for (const content of contents) {
                for (const ptc of content.pointsDeControles) {
                    ptc.decalage = this.decalage(ptc);
                }
            }
        }
        console.log(this.config);
    }

    /**
     * Retourne margin : 0, 5 ou 10 seleon si le ptc a deux parent ou 1 seul ou 0
     * @returns {string}
     */
    private decalage(ptc: PointControle): number {
        let dec = 0;
        if (ptc.idPointControleParent) {
            this.ceeService.ceeConfig$.pipe(first()).subscribe((config) => {
                let ptcP = config.pointsControles.find((chp) => chp.idInterne == ptc.idPointControleParent);
                while (!!ptcP) {
                    if (ptcP.idInterne === ptcP.idPointControleParent) {
                        this.notificationService.error(
                            `Erreur sur le point de contrôle ${ptcP.idInterne}, idInterne = idPointControleParent`
                        );
                        return 5;
                    }
                    dec += 5;
                    ptcP = ptcP.idPointControleParent
                        ? config.pointsControles.find((chp) => chp.idInterne == ptcP.idPointControleParent)
                        : null;
                }
            });
        }
        return dec;
    }

    ngOnDestroy() {
        if (this.diagnostic) {
            this._subscription.unsubscribe();
            const status =
                this.solutions.length && this.solutions.every((sol) => sol.valid == true) ? 'VALID' : 'INVALID';
            this.etatProgressionService.updateDiagnostic(CONTROL_POINT, status, this.diagnostic, true);
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any): void {
        this.ngOnDestroy();
    }
    goToPointDeControle(idInterne): void {
        if (idInterne) {
            // Recherche sous menu d'une zone avec l'idInterne d'un point de controle
            const ssMenu = this.config.find(
                (cr) => !!cr.content.find((cn) => !!cn.pointsDeControles.find((ptc) => ptc.idInterne == idInterne))
            );
            this.sousMenuSelected(ssMenu.chapitre);
            setTimeout(() => {
                const ele = document.getElementById(idInterne);
                if (ele) {
                    ele.scrollIntoView();
                    ele.classList.add('scale-up-center');
                }
            }, 200);
        }
    }
}

export const exportSvgTypes = [
    {
        id: 'idConfAssainissementConforme',
        name: 'Elements conformes',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfAssainissementConforme', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: true,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: false, displayed: true },
                        showAJustifierObjects: { value: false, displayed: true },
                        showNonVerifieObjects: { value: false, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idConfAssainissementNonConforme',
        name: 'Elements non conformes',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfAssainissementNonConforme', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: true,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: false, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idConfAssainissementBienEtEquipement',
        name: 'Bien et équipements',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idConfAssainissementBienEtEquipement', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: true, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: false,
                    controls: {
                        showConformeObjects: { value: false, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idMesurageTotal',
        name: 'Mesurage',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idMesurageTotal', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: false, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idMesurageComptabilisee',
        name: 'Surfaces comptabilisées',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idMesurageComptabilisee', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: false, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: true, displayed: true },
                        showSurfacesDecomptees: { value: false, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idMesurageDecomptee',
        name: 'Surfaces décomptées',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idMesurageDecomptee', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: false, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: false, displayed: false },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: true },
                        showPrelevementEntre51Et500: { value: true, displayed: true },
                        showPrelevementEntre501Et1000: { value: true, displayed: true },
                        showPrelevementSuperieur1000: { value: true, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idHapPerimetres',
        name: 'HAP - Périmètres',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapPerimetres', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: false, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: false, displayed: true },
                        showPrelevements: { value: false, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: false },
                        showZoneEntre51Et500: { value: false, displayed: false },
                        showZoneEntre501Et1000: { value: false, displayed: false },
                        showZoneSuperieur1000: { value: false, displayed: false },
                        showZoneNonRealises: { value: false, displayed: false },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: false },
                        showPrelevementEntre51Et500: { value: false, displayed: false },
                        showPrelevementEntre501Et1000: { value: false, displayed: false },
                        showPrelevementSuperieur1000: { value: false, displayed: false },
                        showPrelevementNonRealises: { value: false, displayed: false },
                    },
                },
            },
        },
    },
    {
        id: 'idHapZones',
        name: 'HAP - Zones',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapZones', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: false, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: false, displayed: false },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: false },
                        showZoneEntre51Et500: { value: false, displayed: false },
                        showZoneEntre501Et1000: { value: false, displayed: false },
                        showZoneSuperieur1000: { value: false, displayed: false },
                        showZoneNonRealises: { value: false, displayed: false },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: false },
                        showPrelevementEntre51Et500: { value: false, displayed: false },
                        showPrelevementEntre501Et1000: { value: false, displayed: false },
                        showPrelevementSuperieur1000: { value: false, displayed: false },
                        showPrelevementNonRealises: { value: false, displayed: false },
                    },
                },
            },
        },
    },
    {
        id: 'idHapBesoins',
        name: 'HAP - Besoins / Prélèvements',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapBesoins', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: false, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: false, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: false },
                        showZoneEntre51Et500: { value: false, displayed: false },
                        showZoneEntre501Et1000: { value: false, displayed: false },
                        showZoneSuperieur1000: { value: false, displayed: false },
                        showZoneNonRealises: { value: false, displayed: false },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: false },
                        showPrelevementEntre51Et500: { value: false, displayed: false },
                        showPrelevementEntre501Et1000: { value: false, displayed: false },
                        showPrelevementSuperieur1000: { value: false, displayed: false },
                        showPrelevementNonRealises: { value: false, displayed: false },
                    },
                },
            },
        },
    },
    {
        id: 'idHapPrelResultats',
        name: 'HAP - Prélèvements et résultats',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapPrelResultats', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: false, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: false, displayed: true },
                    },
                },
                zone: {
                    displayed: true,
                    controls: {
                        showZoneInferieurEgal50: { value: true, displayed: false },
                        showZoneEntre51Et500: { value: true, displayed: false },
                        showZoneEntre501Et1000: { value: true, displayed: false },
                        showZoneSuperieur1000: { value: true, displayed: false },
                        showZoneNonRealises: { value: true, displayed: true },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: true, displayed: false },
                        showPrelevementEntre51Et500: { value: true, displayed: false },
                        showPrelevementEntre501Et1000: { value: true, displayed: false },
                        showPrelevementSuperieur1000: { value: true, displayed: false },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
    {
        id: 'idHapPrelNonReal',
        name: 'HAP - Prélèvements non réalisés',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapPrelNonReal', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: false, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: false },
                    },
                },
                zone: {
                    displayed: false,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: false },
                        showZoneEntre51Et500: { value: false, displayed: false },
                        showZoneEntre501Et1000: { value: false, displayed: false },
                        showZoneSuperieur1000: { value: false, displayed: false },
                        showZoneNonRealises: { value: false, displayed: false },
                    },
                },
                prelevement: {
                    displayed: false,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: false },
                        showPrelevementEntre51Et500: { value: false, displayed: false },
                        showPrelevementEntre501Et1000: { value: false, displayed: false },
                        showPrelevementSuperieur1000: { value: false, displayed: false },
                        showPrelevementNonRealises: { value: true, displayed: false },
                    },
                },
            },
        },
    },
    {
        id: 'idHapGenerique',
        name: 'HAP',
        main: {
            controls: {
                nom: { value: '', displayed: true },
                detail: { value: 'idHapGenerique', displayed: true },
            },
        },
        bien: {
            displayed: true,
            controls: {
                bien: { value: undefined, displayed: true },
                niveau: { value: undefined, displayed: true },
            },
        },
        espace: {
            displayed: true,
            controls: {
                espace: { value: undefined, displayed: true },
            },
        },
        page: {
            displayed: false,
            controls: {
                format: { value: 'a4_paysage', displayed: true },
            },
        },
        plan: {
            displayed: true,
            groups: {
                general: {
                    displayed: true,
                    controls: {
                        scale: { value: 200, displayed: true },
                        resolution: { value: 200, displayed: true },
                        fixedScale: { value: true, displayed: true },
                        fixedPosition: { value: false, displayed: true },
                    },
                },
                display: {
                    displayed: true,
                    controls: {
                        render: { value: 0, displayed: false },
                        showGrid: { value: false, displayed: true },
                        showScale: { value: true, displayed: true },
                        showBackground: { value: true, displayed: true },
                        showCompass: { value: true, displayed: true },
                    },
                },
                elements: {
                    displayed: true,
                    controls: {
                        showSpaces: { value: true, displayed: false },
                        showOuterWalls: { value: true, displayed: false },
                        showInnerWalls: { value: true, displayed: false },
                        showBalconies: { value: true, displayed: false },
                        showWindows: { value: false, displayed: true },
                        showDoors: { value: false, displayed: true },
                        showStairs: { value: true, displayed: false },
                        showSlabOpenings: { value: false, displayed: true },
                        showDeams: { value: false, displayed: true },
                        showColumns: { value: true, displayed: false },
                        showObjects: { value: false, displayed: true },
                    },
                },
                text: {
                    displayed: true,
                    controls: {
                        showSpaceLabel: { value: true, displayed: true },
                        showSpaceArea: { value: false, displayed: true },
                        showMarkers: { value: true, displayed: true },
                        showNumerotation: { value: false, displayed: true },
                        showLegend: { value: true, displayed: true },
                    },
                },
                cotes: {
                    displayed: true,
                    controls: {
                        showOuterMeasures: { value: false, displayed: true },
                        showInnerMeasures: { value: false, displayed: true },
                        showOpeningMeasures: { value: false, displayed: true },
                    },
                },
            },
        },
        equipment: {
            displayed: false,
            groups: {
                conformite: {
                    displayed: true,
                    controls: {
                        showConformeObjects: { value: true, displayed: true },
                        showNonConformeObjects: { value: true, displayed: true },
                        showAJustifierObjects: { value: true, displayed: true },
                        showNonVerifieObjects: { value: true, displayed: true },
                    },
                },
            },
        },
        zone: {
            displayed: false,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showSurfacesComptabilisees: { value: false, displayed: true },
                        showSurfacesDecomptees: { value: true, displayed: true },
                    },
                },
            },
        },
        hap: {
            displayed: true,
            groups: {
                surface: {
                    displayed: true,
                    controls: {
                        showPerimetres: { value: true, displayed: true },
                        showZones: { value: true, displayed: true },
                        showPrelevements: { value: true, displayed: true },
                    },
                },
                zone: {
                    displayed: true,
                    controls: {
                        showZoneInferieurEgal50: { value: false, displayed: true },
                        showZoneEntre51Et500: { value: false, displayed: true },
                        showZoneEntre501Et1000: { value: false, displayed: true },
                        showZoneSuperieur1000: { value: false, displayed: true },
                        showZoneNonRealises: { value: false, displayed: true },
                    },
                },
                prelevement: {
                    displayed: true,
                    controls: {
                        showPrelevementInferieurEgal50: { value: false, displayed: true },
                        showPrelevementEntre51Et500: { value: false, displayed: true },
                        showPrelevementEntre501Et1000: { value: false, displayed: true },
                        showPrelevementSuperieur1000: { value: false, displayed: true },
                        showPrelevementNonRealises: { value: true, displayed: true },
                    },
                },
            },
        },
    },
];

import { EventEmitter, Injectable } from '@angular/core';
import { cn_building, cn_storey } from '@acenv/cnmap-editor';
import { Intervention } from 'src/app/model/intervention.model';
import { CndiagMarker } from 'src/app/modules/shared/map/model/cndiag-marker.model';
import { NotificationService } from 'src/app/commons-lib';
import { Hap, LevelToDisplay } from '../model/hap.model';
import { LegendScreenshot } from '../../../../model/screenshot-svg.model';
import { HapService } from './hap.service';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { Espace } from '../../../../model/espace.model';
import { Zone } from '../../../../model/zone.model';
import { Prelevement, PrelevementEchantillonageADO, ResultSeuil } from '../../../../model/prelevement-generique.model';
import { Perimetre } from '../../../../model/perimetre.model';
import {
    SvgFormValues,
    SvgFormValuesHapPrelevements,
    SvgFormValuesHapZones,
} from '../../../shared/map/export-svg-dialog/shared/interfaces/export-svg-form-values.interface';
import { buildLegend, filterMarkers, showSeuilsPrelevements, showSeuilsZones } from '../utils/hap-legende-marker.utils';

export type SeuilsToDisplay = SvgFormValuesHapZones & SvgFormValuesHapPrelevements;

/**
 * Service pour le diagnostic hap.
 */
@Injectable({
    providedIn: 'root',
})
export class HapCnMapService {
    constructor(private readonly notificationService: NotificationService, private readonly hapService: HapService) {}

    /**
     * Charge le building avec les markers à afficher
     * @param diagnostic
     * @param espace
     * @param levelOfMarkerToDisplay
     * @param intervention
     * @returns
     */
    loadBuilding(
        diagnostic: Diagnostic,
        espace: Espace,
        levelOfMarkerToDisplay: LevelToDisplay,
        intervention: Intervention
    ): cn_storey {
        if (!espace) {
            return;
        }
        // Récupération du bien sélectionné dans l'intervention
        const relationInterventionBien = intervention.relationInterventionBiens.find((relationInterventionBienTemp) => {
            return relationInterventionBienTemp.bien.id === espace.idBien;
        });

        // Initialisation du building avec le jsonPlan du bien s'il existe sinon un building vide
        // Récupération du storey correspondant au niveau choisi dans l'espace
        let currentStorey: cn_storey;
        let refBuilding: cn_building;
        if (relationInterventionBien?.bien?.jsonPlan) {
            refBuilding = cn_building.unserialize(JSON.parse(relationInterventionBien.bien.jsonPlan));
        } else {
            refBuilding = cn_building.generate_new_building();
        }
        if (
            espace.indexNiveau !== null &&
            espace.indexNiveau !== undefined &&
            refBuilding.storeys.length > espace.indexNiveau
        ) {
            currentStorey = refBuilding.storeys[espace.indexNiveau];
        } else {
            const espaceBuilding = cn_building.generate_new_building();
            currentStorey = espaceBuilding.storeys[0];

            const bgMap = refBuilding.storeys[0].background_maps.find((it) => it.image_id === espace.backgroundFileId);
            if (bgMap) {
                currentStorey.background_maps.push(bgMap);
            }
        }
        this.populateStoreyWithMarkers(diagnostic, currentStorey, espace, levelOfMarkerToDisplay);

        return currentStorey;
    }

    /**
     * Complete le cn_storey avec le backgroundMap et les markers
     * @param diagnostic
     * @param currentStorey
     *  @param espace
     * @param levelOfMarkerToDisplay
     * @param svgHapConf
     * @param showBySeuil
     */
    populateStoreyWithMarkers(
        diagnostic: Diagnostic,
        currentStorey: cn_storey,
        espace: Espace,
        levelOfMarkerToDisplay: LevelToDisplay,
        svgHapConf: LevelToDisplay[] = [],
        showBySeuil: SeuilsToDisplay = {
            showZoneInferieurEgal50: false,
            showZoneEntre51Et500: false,
            showZoneEntre501Et1000: false,
            showZoneSuperieur1000: false,
            showZoneNonRealises: false,
            showPrelevementInferieurEgal50: false,
            showPrelevementEntre51Et500: false,
            showPrelevementEntre501Et1000: false,
            showPrelevementSuperieur1000: false,
            showPrelevementNonRealises: false,
        }
    ) {
        // Si on a des résultats de labo, on maj les résultats interprétés des markers
        if (diagnostic.typePrestation === 'HAP_VALIDATION_RESULTATS') {
            this.updateMarkersResultLevel(diagnostic.contenuDiagnostic as Hap, espace, currentStorey);
        }
        // On affiche dans le storey uniquement les markers correspondant au level que l'on veut afficher
        currentStorey.markers = [];
        // Liste des pvts non réalisés
        const listePrelevementsNonRealises = this.getListePrelevements(espace, false);
        // Configuration à utiliser
        const levelsActif: LevelToDisplay[] = svgHapConf.length
            ? svgHapConf
            : this.getLevelsToDisplayByCurrentLevel(levelOfMarkerToDisplay);
        // Parcours de la liste de markers de l'espace
        const cndiagMarkers = espace.listeMarkersJson.map((json) =>
            CndiagMarker.unserialize(JSON.parse(json), currentStorey)
        );
        cndiagMarkers
            .filter((marker) => marker.level === LevelToDisplay.ZONE)
            .forEach((marker) => {
                // Les filtres de seuils sont activés, on update le resultLevel de la zone avec la pire valeur des prélèvements de cette zone
                if (showSeuilsZones(showBySeuil)) {
                    this.findWorstPvtScoreFromZone(espace, marker, currentStorey);
                } else {
                    // Les filtres de sont pas activés, on reset le resultLevel de la zone pour afficher les couleurs d'origines
                    marker.resultLevel = undefined;
                    this.majMarkerJson(espace, marker);
                }
            });

        currentStorey.markers = cndiagMarkers.filter(
            filterMarkers(levelsActif, showBySeuil, listePrelevementsNonRealises)
        );
        this.sortMarkers(currentStorey.markers);
        currentStorey.markers.forEach((m: CndiagMarker) => (m.selectable = m.level === levelOfMarkerToDisplay));
    }

    /**
     * Determine les levels actifs suivant le level courant
     * Si on est en PERIMETRE, seul les PERIMETRE sont actifs
     * Si on est en ZONE, seul les PERIMETRE, les BESOIN et les ZONE sont actifs
     * Si on est en BESOIN, seul les PERIMETRE et les BESOIN sont actifs
     * Si on est en PRELEVEMENT, tous les levels sont actifs
     * @param currentLevel
     * @returns
     */
    private getLevelsToDisplayByCurrentLevel(currentLevel: LevelToDisplay): LevelToDisplay[] {
        switch (currentLevel) {
            case LevelToDisplay.PERIMETRE:
                return [LevelToDisplay.PERIMETRE];
            case LevelToDisplay.ZONE:
                return [LevelToDisplay.PERIMETRE, LevelToDisplay.ZONE, LevelToDisplay.BESOIN];
            case LevelToDisplay.PRELEVEMENT:
                return [
                    LevelToDisplay.PERIMETRE,
                    LevelToDisplay.ZONE,
                    LevelToDisplay.PRELEVEMENT,
                    LevelToDisplay.BESOIN,
                ];
            case LevelToDisplay.BESOIN:
                return [LevelToDisplay.PERIMETRE, LevelToDisplay.BESOIN, LevelToDisplay.ZONE];
        }
    }

    /**
     * Permet de changer le nom du marker d'une référence (Perimetre | Zone | Prelevement) particuliere
     * @param espace
     * @param storey
     * @param reference Perimetre | Zone | Prelevement
     */
    changeNameMarker(espace: Espace, storey: cn_storey, reference: Perimetre | Zone | Prelevement) {
        const marker = storey.markers.find((markerTemp) => {
            return markerTemp.idReference === reference.id;
        });
        const markerLabel = reference['general'] ? reference['general'].reference : reference['nom'];
        if (marker) {
            marker.label = markerLabel ?? 'Non défini';
            // MAJ le markerJson avec le nouveau nom
            this.majMarkerJson(espace, marker);
        }
    }

    /**
     * Permet de changer la couleur du marker d'une référence (Perimetre | Zone | Prelevement) particuliere
     * @param espace
     * @param storey
     * @param reference Perimetre | Zone | Prelevement
     */
    changeColorMarker(espace: Espace, storey: cn_storey, reference: Perimetre | Zone | Prelevement) {
        const marker = storey.markers.find((markerTemp) => {
            return markerTemp.idReference === reference.id;
        });
        if (marker) {
            marker.shape_color = reference.legende.color;
            // MAJ le markerJson avec la nouvelle couleur
            this.majMarkerJson(espace, marker);
        }
    }

    /**
     * Permet de changer l'épaisseur du trait d'un marker
     * @param espace
     * @param storey
     * @param reference Perimetre | Zone | Prelevement
     */
    changeStrokeMarker(espace: Espace, storey: cn_storey, reference: Perimetre | Zone | Prelevement) {
        const marker = storey.markers.find((markerTemp) => {
            return markerTemp.idReference === reference.id;
        });

        if (marker) {
            marker.stroke_width = reference.legende.epaisseurTrait;
            // MAJ le markerJson avec la nouvelle epaisseur de marker
            this.majMarkerJson(espace, marker);
        }
    }

    /**
     * Met à jour le json du marker dans l'espace
     * @param espace
     * @param marker
     */
    private majMarkerJson(espace: Espace, marker: CndiagMarker) {
        espace.listeMarkersJson = espace.listeMarkersJson.map((markerJsonTemp) => {
            if (JSON.parse(markerJsonTemp).idReference === marker.idReference) {
                return JSON.stringify(marker.serialize());
            } else {
                return markerJsonTemp;
            }
        });
    }

    /**
     * Permet d'enregistrer tous les markers d'un storey en json dans l'espace
     * Rappel : le storey contient tous les markers d'un espace suivant un level
     * @param espace
     * @param storey
     * @param levelOfMarkerToUpdate
     */
    mapAllMarkersOfStoreyToJson(espace: Espace, storey: cn_storey, levelOfMarkerToUpdate: LevelToDisplay) {
        const levelsActif: LevelToDisplay[] = this.getLevelsToDisplayByCurrentLevel(levelOfMarkerToUpdate);
        // On nettoie les markerJson actuellement existant dans l'espace
        espace.listeMarkersJson = espace.listeMarkersJson.filter((markerJsonTemp) => {
            return !levelsActif.includes(JSON.parse(markerJsonTemp).level);
        });

        // Pour chaque marker du storey, on le serialize et on l'enregistre dans l'espace
        if (storey.markers) {
            storey.markers.forEach((markerTemp) => {
                espace.listeMarkersJson.push(JSON.stringify(markerTemp.serialize()));
            });
        }
    }

    /**
     * Supprimer le marker du périmètre plus ceux de ses enfants (zones, besoins et prélèvements)
     * @param espace: Espace
     * @param storey: cn_storey
     * @param perimetreToDelete: Perimetre
     * @param editLocationEvent: EventEmitter<any>
     */
    deleteMarkersPerimetre(
        espace: Espace,
        storey: cn_storey,
        perimetreToDelete: Perimetre,
        editLocationEvent: EventEmitter<any>
    ) {
        this.deleteOneMarker(espace, storey, perimetreToDelete.id);

        // Suppression des markers correspondant aux zones du périmètre
        if (perimetreToDelete.listeZones) {
            perimetreToDelete.listeZones.forEach((zoneTemp) => {
                this.deleteMarkersZone(espace, storey, zoneTemp, null);
            });
        }

        // Suppression des markers correspondant aux besoins du périmètre
        if (perimetreToDelete.listeBesoins) {
            perimetreToDelete.listeBesoins.forEach((besoinTemp) => {
                this.deleteMarkersBesoin(espace, storey, besoinTemp, null);
            });
        }

        // On renvoie un évenement pour raffraichir la map
        editLocationEvent.emit();
    }

    /**
     * Supprimer le marker de la zone plus ceux de ses prélèvements
     * @param espace: Espace
     * @param storey: cn_storey
     * @param zoneToDelete: Zone
     * @param editLocationEvent: EventEmitter<any>
     */
    deleteMarkersZone(espace: Espace, storey: cn_storey, zoneToDelete: Zone, editLocationEvent: EventEmitter<any>) {
        this.deleteOneMarker(espace, storey, zoneToDelete.id);

        // Suppression des markers correspondant aux prélèvements de la zone
        if (zoneToDelete.listePrelevements) {
            zoneToDelete.listePrelevements.forEach((prelevementTemp) => {
                this.deleteMarkersPrelevement(espace, storey, prelevementTemp, null);
            });
        }

        if (editLocationEvent) {
            // On renvoie un évenement pour raffraichir la map
            editLocationEvent.emit();
        }
    }

    /**
     * Supprimer le marker du prélèvement
     * @param espace: Espace
     * @param storey: cn_storey
     * @param prelevementToDelete: Prelevement
     * @param editLocationEvent: EventEmitter<any>
     */
    deleteMarkersPrelevement(
        espace: Espace,
        storey: cn_storey,
        prelevementToDelete: Prelevement,
        editLocationEvent: EventEmitter<any>
    ) {
        this.deleteOneMarker(espace, storey, prelevementToDelete.id);

        if (editLocationEvent) {
            // On renvoie un évenement pour raffraichir la map
            editLocationEvent.emit();
        }
    }

    /**
     * Supprimer le marker du besoin
     * @param espace: Espace
     * @param storey: cn_storey
     * @param besoinToDelete: Prelevement
     * @param editLocationEvent: EventEmitter<any>
     */
    deleteMarkersBesoin(
        espace: Espace,
        storey: cn_storey,
        besoinToDelete: Prelevement,
        editLocationEvent: EventEmitter<any>
    ) {
        this.deleteOneMarker(espace, storey, besoinToDelete.id);

        if (editLocationEvent) {
            // On renvoie un évenement pour raffraichir la map
            editLocationEvent.emit();
        }
    }

    /**
     * Supprimer un marker à la fois dans le storey et dans la liste des markerJson de l'espace
     * @param espace
     * @param storey
     * @param idReference
     */
    deleteOneMarker(espace: Espace, storey: cn_storey, idReference: string) {
        storey.markers = storey.markers.filter((markerTemp) => {
            return markerTemp.idReference !== idReference;
        });
        espace.listeMarkersJson = espace.listeMarkersJson.filter((markerJsonTemp) => {
            const markerParsed = JSON.parse(markerJsonTemp);
            return markerParsed.idReference !== idReference;
        });
    }

    /**
     * Ajout un marker dans la liste des markersJson de l'espace
     * @param espace
     * @param marker
     */
    addMarkerToEspace(espace: Espace, marker: CndiagMarker) {
        // serialize le marker pour le sauvegarder en base de données
        espace.listeMarkersJson.push(JSON.stringify(marker.serialize()));
    }

    /**
     * Trie les markers suivant le type de markers (LevelToDisplay)
     * en 1er les prélèvements
     * ensuite les zones
     * enfin les périmètres
     * @param markers
     * @returns
     */
    sortMarkers(markers: CndiagMarker[] = []): CndiagMarker[] {
        return markers.sort((a: CndiagMarker, b: CndiagMarker) => a.level - b.level);
    }

    generateLegendForScreenshot(diagnostic: Diagnostic, conf: SvgFormValues): LegendScreenshot[] {
        const espaceId = conf.espace?.espace?.id;
        const espace = (diagnostic.contenuDiagnostic as Hap).espaces.valeur.find((esp) => esp.id === espaceId);
        const filters = { levelsToDisplay: this.getHapConf(conf), seuilsToDisplay: this.getFilters(conf) };
        return buildLegend(espace, filters, diagnostic);
    }

    /**
     * Permet de récupérer la liste des pvts réalisés ou non
     * @param espace
     * @param isRealize
     * @private
     */
    private getListePrelevements(espace: Espace, isRealize: boolean) {
        return espace.listePerimetres.flatMap((perimetre) =>
            perimetre.listeZones.flatMap((zone) => zone.listePrelevements.filter((pvt) => pvt.isRealize === isRealize))
        );
    }

    /**
     * Maj des score d'analyse des markerJson d'un espace en fonction des résultats d'analyse des pvts
     * @param contenuDiagnostic
     * @param espace
     * @param storey
     */
    updateMarkersResultLevel(contenuDiagnostic: Hap, espace: Espace, storey: cn_storey) {
        const listeResultatsByPrelevement = this.getListeResultatsByPrelevement(contenuDiagnostic.espaces.valeur);
        const arraysPvtsIds = listeResultatsByPrelevement.map((ech) => (ech as any).prelevementId);
        const uniqPvtsIds = [...new Set(arraysPvtsIds)];
        uniqPvtsIds.length &&
            uniqPvtsIds.forEach((pvtId) => {
                let score = [];
                listeResultatsByPrelevement
                    .filter((echantillon) => (echantillon as any).prelevementId === pvtId)
                    .forEach((echantillon) => {
                        (echantillon as any).resultatsAnalyse &&
                            (echantillon as any).resultatsAnalyse.length &&
                            (echantillon as any).resultatsAnalyse.forEach((res) => {
                                if (res.groupResultat !== undefined) {
                                    score = score.concat(res.groupResultat.seuilScore);
                                }
                            });
                    });
                if (score.length && espace.listeMarkersJson) {
                    const index = espace.listeMarkersJson.findIndex((mrk) => JSON.parse(mrk).idReference === pvtId);
                    if (index > -1) {
                        const markerParsed = JSON.parse(espace.listeMarkersJson[index]);
                        const markerTemp: CndiagMarker = CndiagMarker.unserialize(markerParsed, storey) as CndiagMarker;
                        // On attribue le pire score au prélèvement
                        markerTemp.resultLevel = score.sort((a, b) => b - a)[0];
                        espace.listeMarkersJson[index] = JSON.stringify(markerTemp.serialize());
                    }
                }
            });
    }

    private getListeResultatsByPrelevement(espaces: Espace[]): Prelevement[] {
        const listeResultatsByPrelevement = [];
        const prelevementsEchantillonage = espaces
            .flatMap((e) => e.listePerimetres)
            .flatMap((p) => p.listeZones.flatMap((z) => z.listePrelevements).concat(p.listeBesoins))
            .flatMap((p) => p.echantillonages);
        this.hapService.computeResults(prelevementsEchantillonage as PrelevementEchantillonageADO[]);
        const uniqArraysIds = [...new Set(prelevementsEchantillonage.map((it) => it.prelevementId))];
        uniqArraysIds.forEach((id) => {
            listeResultatsByPrelevement.push(prelevementsEchantillonage.filter((it) => it.prelevementId === id));
        });
        return listeResultatsByPrelevement.flat();
    }

    private findWorstPvtScoreFromZone(espace: Espace, marker: CndiagMarker, currentStorey: cn_storey) {
        const listeMarkers = espace.listeMarkersJson;
        const zoneId = marker.idReference;
        const prelevements = espace.listePerimetres.flatMap((perimetre) =>
            perimetre.listeZones.flatMap((zone) => zone.listePrelevements.flat())
        );
        const pvtsFiltered = prelevements.filter((it) => it.general.zoneId === zoneId).map((it) => it.id);
        let pvtsFilteredByMarkerIdReference =
            listeMarkers.length &&
            listeMarkers.filter((markerJsonTemp) => {
                const jsonParsed = JSON.parse(markerJsonTemp);
                const mk = CndiagMarker.unserialize(jsonParsed, currentStorey) as CndiagMarker;
                return pvtsFiltered.includes(mk.idReference);
            });
        // Récupération des pires scores
        const scores = [...new Set(pvtsFilteredByMarkerIdReference.map((it) => JSON.parse(it).resultLevel))].sort(
            (a, b) => b - a
        );
        // Si un score est trouvé, on update le marker
        if (scores.length && scores[0] !== undefined) {
            marker.resultLevel = scores[0];
            this.majMarkerJson(espace, marker);
        }
    }

    getHapConf(conf: SvgFormValues): LevelToDisplay[] {
        const levels = [];
        if (conf?.hap?.surface?.showPerimetres) {
            levels.push(LevelToDisplay.PERIMETRE);
        }
        if (
            conf?.hap?.surface?.showZones ||
            conf?.hap?.zone?.showZoneNonRealises ||
            conf?.hap?.zone?.showZoneInferieurEgal50 ||
            conf?.hap?.zone?.showZoneEntre51Et500 ||
            conf?.hap?.zone?.showZoneEntre501Et1000 ||
            conf?.hap?.zone?.showZoneSuperieur1000
        ) {
            levels.push(LevelToDisplay.ZONE);
        }
        if (
            conf?.hap?.surface?.showPrelevements ||
            conf?.hap?.prelevement?.showPrelevementNonRealises ||
            conf?.hap?.prelevement?.showPrelevementInferieurEgal50 ||
            conf?.hap?.prelevement?.showPrelevementEntre51Et500 ||
            conf?.hap?.prelevement?.showPrelevementEntre501Et1000 ||
            conf?.hap?.prelevement?.showPrelevementSuperieur1000
        ) {
            levels.push(LevelToDisplay.PRELEVEMENT, LevelToDisplay.BESOIN);
        }
        return levels;
    }

    getFilters(conf: SvgFormValues): SeuilsToDisplay {
        return {
            showZoneInferieurEgal50: !!conf?.hap?.zone?.showZoneInferieurEgal50,
            showZoneEntre51Et500: !!conf?.hap?.zone?.showZoneEntre51Et500,
            showZoneEntre501Et1000: !!conf?.hap?.zone?.showZoneEntre501Et1000,
            showZoneSuperieur1000: !!conf?.hap?.zone?.showZoneSuperieur1000,
            showZoneNonRealises: !!conf?.hap?.zone?.showZoneNonRealises,
            showPrelevementInferieurEgal50: !!conf?.hap?.prelevement?.showPrelevementInferieurEgal50,
            showPrelevementEntre51Et500: !!conf?.hap?.prelevement?.showPrelevementEntre51Et500,
            showPrelevementEntre501Et1000: !!conf?.hap?.prelevement?.showPrelevementEntre501Et1000,
            showPrelevementSuperieur1000: !!conf?.hap?.prelevement?.showPrelevementSuperieur1000,
            showPrelevementNonRealises: !!conf?.hap?.prelevement?.showPrelevementNonRealises,
        };
    }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { HapValidatorUtils } from '../utils/hap-validator.utils';
import { FormService } from 'src/app/services/form.service';
import { FormContext } from 'src/app/model/rule/form-context.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Perimetre } from '../../../../model/perimetre.model';
import { Zone } from '../../../../model/zone.model';
import {
    Prelevement,
    PrelevementEchantillonage,
    TypeCarottage,
    TypePrelevement,
} from '../../../../model/prelevement-generique.model';
import { Hap, LevelToDisplay } from '../model/hap.model';
import { removeItemFromList } from '../../../../utils/array.utils';
import { takeUntil, tap } from 'rxjs/operators';

/**
 * Service pour le diagnostic hap.
 */
@Injectable({
    providedIn: 'root',
})
export class HapFormService {
    // Utilisée pour le controle d'unicité des références de prélèvement
    listePrelevementsEtBesoins: Prelevement[] = [];

    constructor(private readonly formBuilder: FormBuilder, private readonly formService: FormService) {}

    /**
     * Met à jour la liste totale des prélèvements et besoins utilisée pour le controle d'unicité
     * des références de prélèvement ou de besoin
     * @param diagnostic
     */
    refreshListePrelevementsEtBesoins(diagnostic: Diagnostic) {
        this.listePrelevementsEtBesoins.splice(0, this.listePrelevementsEtBesoins.length);
        (diagnostic.contenuDiagnostic as Hap).espaces.valeur.forEach((espace) => {
            espace.listePerimetres.forEach((perimetre) => {
                // parcours des zones pour ajouter les prélèvements de chaque zone à la liste des prélèvements et besoins
                perimetre.listeZones.forEach((zone) => {
                    zone.listePrelevements.forEach((prelevement) => {
                        this.listePrelevementsEtBesoins.push(prelevement);
                    });
                });
                // parcours des besoins pour les ajouter à la liste des prélèvements et besoins
                perimetre.listeBesoins.forEach((besoin) => {
                    this.listePrelevementsEtBesoins.push(besoin);
                });
            });
        });
    }

    /**
     * Recherche le Perimetre correspondant à un formGroup dans la liste des périmètres
     * @param listePerimetres
     * @param perimetreFormGroup
     * @returns
     */
    findPerimetreByFormGroup(listePerimetres: Perimetre[], perimetreFormGroup: FormGroup): Perimetre {
        if (listePerimetres && perimetreFormGroup) {
            return listePerimetres.find((perimetreTemp) => {
                const idPerimetreInFormGroup = perimetreFormGroup.controls.id.value;
                return idPerimetreInFormGroup === perimetreTemp.id;
            });
        } else {
            return null;
        }
    }

    /**
     * Recherche la Zone correspondant à un formGroup dans la liste des périmètres
     * @param listePerimetres
     * @param zoneFormGroup
     * @returns
     */
    findZoneByFormGroup(listePerimetres: Perimetre[], zoneFormGroup: FormGroup): Zone {
        if (listePerimetres && zoneFormGroup) {
            let zone: Zone;
            listePerimetres.forEach((perimetreTemp) => {
                const zoneFound = perimetreTemp.listeZones.find((zoneTemp) => {
                    const idZoneInFormGroup = zoneFormGroup.controls.id.value;
                    return idZoneInFormGroup === zoneTemp.id;
                });
                zone = zoneFound ? zoneFound : zone;
            });

            return zone;
        } else {
            return null;
        }
    }

    /**
     * Recherche le besoin correspondant à un formGroup dans la liste des périmètres
     * @param listePerimetres: Perimetre[]
     * @param besoinFormGroup: FormGroup
     * @returns
     */
    findBesoinByFormGroup(listePerimetres: Perimetre[], besoinFormGroup: FormGroup): Prelevement {
        if (listePerimetres && besoinFormGroup) {
            let besoin: Prelevement;
            listePerimetres.forEach((perimetreTemp) => {
                if (besoin) {
                    return;
                }
                const besoinFound = perimetreTemp.listeBesoins.find((zoneTemp) => {
                    const idZoneInFormGroup = besoinFormGroup.controls.id.value;
                    return idZoneInFormGroup === zoneTemp.id;
                });
                besoin = besoinFound ? besoinFound : besoin;
            });

            return besoin;
        } else {
            return null;
        }
    }

    /**
     * Recherche le Prelevement/Besoin correspondant à un formGroup dans la liste des périmètres
     * @param listePerimetres
     * @param formGroup
     * @returns
     */
    findPrelevementOrBesoinByFormGroup(listePerimetres: Perimetre[], formGroup: FormGroup): Prelevement {
        if (listePerimetres && formGroup) {
            let prelevement: Prelevement;
            listePerimetres.forEach((perimetreTemp) => {
                // Pas besoin de faire le traitement si on a trouvé le prélèvement
                if (!prelevement) {
                    const besoinFound = perimetreTemp.listeBesoins.find((zoneTemp) => {
                        const idZoneInFormGroup = formGroup.controls.id.value;
                        return idZoneInFormGroup === zoneTemp.id;
                    });

                    prelevement = besoinFound ? besoinFound : prelevement;

                    // Pas besoin de faire le traitement si on a trouvé le prélèvement
                    if (!prelevement) {
                        perimetreTemp.listeZones.forEach((zoneTemp) => {
                            const prelevementFound = zoneTemp.listePrelevements.find((prelevementTemp) => {
                                const idPrelevementInFormGroup = formGroup.controls.id.value;
                                return idPrelevementInFormGroup === prelevementTemp?.id;
                            });
                            prelevement = prelevementFound ? prelevementFound : prelevement;
                        });
                    }
                }
            });
            return prelevement;
        } else {
            return null;
        }
    }

    /**
     * Crée le formulaire complet de chaque périmètre
     * @param listePerimetres: Perimetre[]
     * @param diagnostic: Diagnostic
     * @param levelToDisplay: LevelToDisplay
     * @param ngUnsubscribe: Subject<void>
     */
    createFormArrayListePerimetres(
        listePerimetres: Perimetre[],
        diagnostic: Diagnostic,
        levelToDisplay: LevelToDisplay,
        ngUnsubscribe: Subject<void>
    ): FormArray {
        this.refreshListePrelevementsEtBesoins(diagnostic);
        const listePerimetresArray = this.formBuilder.array([]);
        if (listePerimetres && listePerimetres.length > 0) {
            listePerimetres.forEach((perimetre) => {
                const perimetreFormGroup = this.createFormGroupPerimetre(
                    perimetre,
                    listePerimetres,
                    levelToDisplay,
                    ngUnsubscribe
                );

                listePerimetresArray.push(perimetreFormGroup);
            });
        }

        console.log(listePerimetresArray);
        return listePerimetresArray;
    }

    /**
     * Crée le formulaire complet d'un périmètre en complétant avec les données du périmètre
     * @param perimetre: Perimetre
     * @param listePerimetresExistant: Perimetre[]
     * @param levelToDisplay: LevelToDisplay
     * @param ngUnsubscribe: Subject<void>
     * @param isCreation: Subject<void> = false
     */
    createFormGroupPerimetre(
        perimetre: Perimetre,
        listePerimetresExistant: Perimetre[],
        levelToDisplay: LevelToDisplay,
        ngUnsubscribe: Subject<void>,
        isCreation = false
    ): FormGroup {
        return this.formBuilder.group({
            id: perimetre.id,
            nomPerimetre: this.formService.createFormControl(
                'nomPerimetre',
                new FormContext(
                    'nom',
                    perimetre,
                    [
                        Validators.required,
                        (control: AbstractControl) => {
                            if (
                                control.value &&
                                ValidatorUtils.checkAlreadyExist(
                                    control.value,
                                    isCreation,
                                    perimetre ? perimetre.nom : '',
                                    listePerimetresExistant.map((perimetreTemp) => perimetreTemp.nom)
                                )
                            ) {
                                return { erreurNomPerimetreExistant: true };
                            }
                            return null;
                        },
                    ],
                    ngUnsubscribe,
                    true,
                    false
                )
            ),
            typeDelimitation: this.formService.createFormControl(
                'typeDelimitation',
                new FormContext('typeDelimitation', perimetre, [Validators.required], ngUnsubscribe, true, false)
            ),
            description: this.formService.createFormControl(
                'description',
                new FormContext('description', perimetre, [Validators.required], ngUnsubscribe, true, false)
            ),
            typeOuvrage: this.formService.createFormControl(
                'typeOuvrage',
                new FormContext('typeOuvrage', perimetre, [], ngUnsubscribe, true, false)
            ),
            color: this.formService.createFormControl(
                'color',
                new FormContext('color', perimetre.legende, [Validators.required], ngUnsubscribe, true, false)
            ),
            epaisseurTrait: this.formService.createFormControl(
                'epaisseurTrait',
                new FormContext('epaisseurTrait', perimetre.legende, [Validators.required], ngUnsubscribe, true, false)
            ),
            formLocalisation: this.formBuilder.group({}),
            formArrayZones: [LevelToDisplay.ZONE, LevelToDisplay.PRELEVEMENT, LevelToDisplay.BESOIN].includes(
                levelToDisplay
            )
                ? this.createFormArrayListeZones(perimetre.listeZones, levelToDisplay, ngUnsubscribe)
                : this.formBuilder.array([]),
            formArrayBesoins: [LevelToDisplay.BESOIN, LevelToDisplay.ZONE, LevelToDisplay.PRELEVEMENT].includes(
                levelToDisplay
            )
                ? this.createFormArrayListeBesoins(perimetre.listeBesoins, levelToDisplay, ngUnsubscribe)
                : this.formBuilder.array([]),
        });
    }

    /**
     * Crée le formulaire complet de chaque besoin
     * @param listeBesoins: Prelevement[]
     * @param levelToDisplay: LevelToDisplay
     * @param ngUnsubscribe: Subject<void>
     */
    createFormArrayListeBesoins(
        listeBesoins: Prelevement[],
        levelToDisplay: LevelToDisplay,
        ngUnsubscribe: Subject<void>
    ): FormArray {
        const listeBesoinsArray = this.formBuilder.array([]);
        if (listeBesoins && listeBesoins.length > 0) {
            listeBesoins.forEach((besoin) => {
                const besoinFormGroup = this.createFormGroupPrelevement(
                    besoin,
                    ngUnsubscribe,
                    levelToDisplay === LevelToDisplay.PRELEVEMENT
                );

                listeBesoinsArray.push(besoinFormGroup);
            });
        }
        return listeBesoinsArray;
    }

    /**
     * Crée le formulaire complet de chaque zone
     * @param listeZones: Zone[]
     * @param levelToDisplay: LevelToDisplay
     * @param ngUnsubscribe: Subject<void>
     */
    createFormArrayListeZones(
        listeZones: Zone[],
        levelToDisplay: LevelToDisplay,
        ngUnsubscribe: Subject<void>
    ): FormArray {
        const listeZonesArray = this.formBuilder.array([]);
        if (listeZones && listeZones.length > 0) {
            listeZones.forEach((zone) => {
                const zoneFormGroup = this.createFormGroupZone(zone, levelToDisplay, ngUnsubscribe);
                listeZonesArray.push(zoneFormGroup);
            });
        }
        return listeZonesArray;
    }

    /**
     * Crée le formulaire complet d'une zone en complétant avec les données de la zone
     * @param zone: Zone
     * @param levelToDisplay: LevelToDisplay
     * @param ngUnsubscribe: Subject<void>
     */
    createFormGroupZone(zone: Zone, levelToDisplay: LevelToDisplay, ngUnsubscribe: Subject<void>): FormGroup {
        const validatorRequiredOnPrelevementOrEmpty =
            levelToDisplay == LevelToDisplay.PRELEVEMENT ? [Validators.required] : [];
        return this.formBuilder.group({
            id: zone.id,
            nom: this.formService.createFormControl(
                'nom',
                new FormContext('nom', zone, [Validators.required], ngUnsubscribe, true, false)
            ),
            description: this.formService.createFormControl(
                'description',
                new FormContext('description', zone, [Validators.required], ngUnsubscribe, true, false)
            ),
            photoSituation: this.formService.createFormControl(
                'photoSituation',
                new FormContext(
                    'idFichierImageSituation',
                    zone,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                )
            ),
            partieOuvrage: this.formService.createFormControl(
                'partieOuvrage',
                new FormContext(
                    'partieOuvrage',
                    zone,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                )
            ),
            typeMateriaux: this.formService.createFormControl(
                'typeMateriaux',
                new FormContext(
                    'typeMateriaux',
                    zone,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                )
            ),
            color: this.formService.createFormControl(
                'color',
                new FormContext(
                    'color',
                    zone.legende,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                )
            ),
            formArrayPrelevement: [LevelToDisplay.PRELEVEMENT, LevelToDisplay.BESOIN].includes(levelToDisplay)
                ? this.createFormArrayListePrelevements(
                      zone.listePrelevements,
                      ngUnsubscribe,
                      levelToDisplay === LevelToDisplay.PRELEVEMENT
                  )
                : this.formBuilder.array([]),
        });
    }

    /**
     * Crée le formulaire complet de chaque prélèvement
     * @param listePrelevements: Prelevement[]
     * @param ngUnsubscribe: Subject<void>
     * @param isLevelPrelevement: boolean s'il s'agit d'un prélèvement ou pas
     */
    createFormArrayListePrelevements(
        listePrelevements: Prelevement[],
        ngUnsubscribe: Subject<void>,
        isLevelPrelevement: boolean
    ): FormArray {
        const listePrelevementsArray = this.formBuilder.array([]);
        if (listePrelevements && listePrelevements.length > 0) {
            listePrelevements.forEach((prelevement) => {
                if (prelevement) {
                    const prelevementFormGroup = this.createFormGroupPrelevement(
                        prelevement,
                        ngUnsubscribe,
                        isLevelPrelevement
                    );
                    listePrelevementsArray.push(prelevementFormGroup);
                }
            });
        }
        return listePrelevementsArray;
    }

    /**
     * Crée le formulaire complet d'un prélèvement en complétant avec les données du prélèvement
     * @param prelevement: Prelevement
     * @param ngUnsubscribe: Subject<void>
     * @param isLevelPrelevement: si on n'est pas dans l'onglet prélèvement, on doit configurer différemment les controles du formGroup
     */
    createFormGroupPrelevement(
        prelevement: Prelevement,
        ngUnsubscribe: Subject<void>,
        isLevelPrelevement = true
    ): FormGroup {
        const realizeControl = this.formService.createFormControl(
            'isRealize',
            new FormContext('isRealize', prelevement, [], ngUnsubscribe, true, false)
        );
        return this.formBuilder.group({
            id: prelevement.id,
            formGeneral: this.createFormGroupPrelevementGeneral(
                prelevement,
                ngUnsubscribe,
                isLevelPrelevement,
                realizeControl
            ),
            formDetail: this.createFormGroupPrelevementDetails(
                prelevement,
                ngUnsubscribe,
                isLevelPrelevement,
                realizeControl
            ),
            formEchantillonage: this.createFormArrayPrelevementEchantillonage(
                prelevement,
                ngUnsubscribe,
                isLevelPrelevement,
                realizeControl
            ),
            formDonneesTechnique: this.createFormGroupPrelevementDonneesTechnique(
                prelevement,
                ngUnsubscribe,
                isLevelPrelevement
            ),
            commentairesId: this.formService.createFormControl(
                'commentairesId',
                new FormContext('commentairesId', prelevement, [], ngUnsubscribe, true, false)
            ),
            isRealize: realizeControl,
        });
    }

    /**
     * créé le formArray des échantillons du prélèvement
     * @param prelevement
     * @param ngUnsubscribe
     * @param isLevelPrelevement: si on n'est pas dans l'onglet prélèvement, on doit configurer différemment les controles du formGroup
     */
    createFormArrayPrelevementEchantillonage(
        prelevement: Prelevement,
        ngUnsubscribe: Subject<void>,
        isLevelPrelevement = true,
        isRealize: FormControl
    ): FormArray {
        const echantillonagesArray = this.formBuilder.array([], (fa: FormArray) =>
            isLevelPrelevement && fa?.controls?.length === 0 && isRealize.value ? { minlength: 'error' } : undefined
        );
        if (prelevement) {
            prelevement.echantillonages.forEach((echantillonage) => {
                const echantillonageFormGroup = this.createFormGroupPrelevementEchantillonage(
                    echantillonage,
                    ngUnsubscribe
                );
                echantillonagesArray.push(echantillonageFormGroup);
            });
        }
        return echantillonagesArray;
    }

    /**
     * créé le formGroup d'un échantillon du prélèvement
     * @param echantillonage
     * @param ngUnsubscribe
     * @param isLevelPrelevement: si on n'est pas dans l'onglet prélèvement, on doit configurer différemment les controles du formGroup
     */
    createFormGroupPrelevementEchantillonage(
        echantillonage: PrelevementEchantillonage,
        ngUnsubscribe: Subject<void>,
        isLevelPrelevement = true
    ): FormGroup {
        const validatorRequiredOnPrelevementOrEmpty = isLevelPrelevement ? [Validators.required] : [];
        return this.formBuilder.group({
            reference: this.formService.createFormControl(
                'reference',
                new FormContext('reference', echantillonage, [Validators.required], ngUnsubscribe, true, true),
                !isLevelPrelevement
            ),
            type: this.formService.createFormControl(
                'type',
                new FormContext('type', echantillonage, [Validators.required], ngUnsubscribe, true, false),
                !isLevelPrelevement
            ),
            hauteur: this.formService.createFormControl(
                'hauteur',
                new FormContext(
                    'hauteur',
                    echantillonage,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                ),
                !isLevelPrelevement
            ),
            materiaux: this.formService.createFormControl(
                'materiaux',
                new FormContext(
                    'materiaux',
                    echantillonage,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                ),
                !isLevelPrelevement
            ),
            commentairesId: this.formService.createFormControl(
                'commentairesId',
                new FormContext('commentairesId', echantillonage, [], ngUnsubscribe, true, false)
            ),
        });
    }

    /**
     * créé le formGroup des informations générales du prélèvement
     * @param prelevement
     * @param ngUnsubscribe
     * @param isLevelPrelevement: si on n'est pas dans l'onglet prélèvement, on doit configurer différemment les controles du formGroup
     * @private
     */
    private createFormGroupPrelevementGeneral(
        prelevement: Prelevement,
        ngUnsubscribe: Subject<void>,
        isLevelPrelevement = true,
        realizeFormControl: FormControl
    ) {
        const validatorRequiredOnPrelevementOrEmpty = isLevelPrelevement
            ? [(c) => (realizeFormControl.value ? Validators.required(c) : undefined)]
            : [];
        return this.formBuilder.group(
            {
                zone: this.formService.createFormControl(
                    'zone',
                    new FormContext('zoneId', prelevement.general, [Validators.required], ngUnsubscribe, true, false),
                    !isLevelPrelevement
                ),
                reference: this.formService.createFormControl(
                    'reference',
                    new FormContext(
                        'reference',
                        prelevement.general,
                        [
                            Validators.required,
                            (control: AbstractControl) => {
                                if (this.checkIfReferenceAlreadyExist(prelevement, control)) {
                                    control.markAsTouched();
                                    return { referenceExistante: true };
                                }
                                return null;
                            },
                        ],
                        ngUnsubscribe,
                        true,
                        false
                    )
                ),
                longitude: this.formService.createFormControl(
                    'longitude',
                    new FormContext('gpsLongitude', prelevement.general, [], ngUnsubscribe, true, false),
                    !isLevelPrelevement
                ),
                latitude: this.formService.createFormControl(
                    'latitude',
                    new FormContext('gpsLatitude', prelevement.general, [], ngUnsubscribe, true, false),
                    !isLevelPrelevement
                ),
                description: this.formService.createFormControl(
                    'description',
                    new FormContext(
                        'description',
                        prelevement.general,
                        validatorRequiredOnPrelevementOrEmpty,
                        ngUnsubscribe,
                        true,
                        false
                    ),
                    !isLevelPrelevement
                ),
                color: this.formService.createFormControl(
                    'color',
                    new FormContext(
                        'color',
                        prelevement.legende,
                        validatorRequiredOnPrelevementOrEmpty,
                        ngUnsubscribe,
                        true,
                        false
                    )
                ),
            },
            { validator: HapValidatorUtils.validatorIsGpsCoordonnatesEmptyOrBothValid }
        );
    }

    /**
     * fonction qui vérifie que la référence n'existe pas dans un autre prélèvement du diagnostic
     * @param currentPrelevement
     * @param control
     */
    checkIfReferenceAlreadyExist(currentPrelevement: Prelevement, control: AbstractControl) {
        if (!this.listePrelevementsEtBesoins || !currentPrelevement) {
            return false;
        }
        let matchingPrelevements;
        if (control.value) {
            matchingPrelevements = this.listePrelevementsEtBesoins.filter(
                (prelevement) =>
                    prelevement?.general.reference === control.value && prelevement.id !== currentPrelevement.id
            );
        }
        return matchingPrelevements && matchingPrelevements.length > 0;
    }

    /**
     * Crée le formGroup des détails du prélèvement
     * @param prelevement
     * @param ngUnsubscribe
     * @param isLevelPrelevement: si on n'est pas dans l'onglet prélèvement, on doit configurer différemment les controles du formGroup
     * @private
     */
    private createFormGroupPrelevementDetails(
        prelevement: Prelevement,
        ngUnsubscribe: Subject<void>,
        isLevelPrelevement = true,
        realizeControl: FormControl
    ) {
        const validatorRequiredOnPrelevementOrEmpty = isLevelPrelevement
            ? [(c) => (realizeControl.value ? Validators.required(c) : undefined)]
            : [];
        return this.formBuilder.group({
            typePrelevement: this.formService.createFormControl(
                'typePrelevement',
                new FormContext(
                    'typePrelevement',
                    prelevement.details,
                    [Validators.required],
                    ngUnsubscribe,
                    true,
                    false
                )
            ),
            produitLimitationFibres: this.formService.createFormControl(
                'produitLimitationFibres',
                new FormContext(
                    'produitLimitationFibre',
                    prelevement.details,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                ),
                (isLevelPrelevement && prelevement.details.typePrelevement !== TypePrelevement.CAROTTAGE) ||
                    !isLevelPrelevement
            ),
            typeCarottage: this.formService.createFormControl(
                'typeCarottage',
                new FormContext(
                    'typeCarottage',
                    prelevement.details,
                    [Validators.required],
                    ngUnsubscribe,
                    true,
                    false
                ),
                prelevement.details.typePrelevement !== TypePrelevement.CAROTTAGE
            ),
            profondeur: this.formService.createFormControl(
                'profondeur',
                new FormContext(
                    'profondeurCarottage',
                    prelevement.details,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                ),
                prelevement.details.typePrelevement !== TypePrelevement.CAROTTAGE ||
                    (prelevement.details.typePrelevement === TypePrelevement.CAROTTAGE &&
                        prelevement.details.typeCarottage != TypeCarottage.PARTIEL)
            ),
            imageSituation: this.formService.createFormControl(
                'imageSituation',
                new FormContext(
                    'idFichierImageSituation',
                    prelevement.details,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                ),
                !isLevelPrelevement
            ),
            imageEchelleAvecEtiquette: this.formService.createFormControl(
                'imageEchelleAvecEtiquette',
                new FormContext(
                    'idFichierImageEchelle',
                    prelevement.details,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                ),
                !isLevelPrelevement
            ),
            sensCarottage: this.formService.createFormControl(
                'sensCarottage',
                new FormContext(
                    'sensCarottage',
                    prelevement.details,
                    validatorRequiredOnPrelevementOrEmpty,
                    ngUnsubscribe,
                    true,
                    false
                ),
                (isLevelPrelevement && prelevement.details.typePrelevement !== TypePrelevement.CAROTTAGE) ||
                    !isLevelPrelevement
            ),
        });
    }

    /**
     * créé le formGroup des données techniques du prélèvement
     * @param prelevement
     * @param ngUnsubscribe
     * @param isLevelPrelevement: si on n'est pas dans l'onglet prélèvement, on doit configurer différemment les controles du formGroup
     * @private
     */
    private createFormGroupPrelevementDonneesTechnique(
        prelevement: Prelevement,
        ngUnsubscribe: Subject<void>,
        isLevelPrelevement = true
    ) {
        const validatorRequiredOnPrelevementOrEmpty = isLevelPrelevement ? [Validators.required] : [];
        return this.formBuilder.group({
            lave: this.formBuilder.group({
                value: this.formService.createFormControl(
                    'lave',
                    new FormContext('value', prelevement.donneesTechniques.lave, [], ngUnsubscribe, true, false),
                    !isLevelPrelevement
                ),
                commentairesId: this.formService.createFormControl(
                    'commentairesId',
                    new FormContext(
                        'commentairesId',
                        prelevement.donneesTechniques.lave,
                        [],
                        ngUnsubscribe,
                        true,
                        false
                    )
                ),
            }),
            couchesADissocier: this.formBuilder.group({
                value: this.formService.createFormControl(
                    'value',
                    new FormContext(
                        'value',
                        prelevement.donneesTechniques.couchesADissocier,
                        [],
                        ngUnsubscribe,
                        true,
                        false
                    ),
                    !isLevelPrelevement
                ),
                commentairesId: this.formService.createFormControl(
                    'commentairesId',
                    new FormContext(
                        'commentairesId',
                        prelevement.donneesTechniques.couchesADissocier,
                        [],
                        ngUnsubscribe,
                        true,
                        false
                    )
                ),
            }),
            analyseCouches: this.formBuilder.group({
                value: this.formService.createFormControl(
                    'value',
                    new FormContext(
                        'value',
                        prelevement.donneesTechniques.analyseCouches,
                        validatorRequiredOnPrelevementOrEmpty,
                        ngUnsubscribe,
                        true,
                        false
                    ),
                    !isLevelPrelevement
                ),
                commentairesId: this.formService.createFormControl(
                    'commentairesId',
                    new FormContext(
                        'commentairesId',
                        prelevement.donneesTechniques.analyseCouches,
                        [],
                        ngUnsubscribe,
                        true,
                        false
                    )
                ),
            }),
            limitationFibre: this.formBuilder.group({
                value: this.formService.createFormControl(
                    'value',
                    new FormContext(
                        'value',
                        prelevement.donneesTechniques.limitationFibre,
                        validatorRequiredOnPrelevementOrEmpty,
                        ngUnsubscribe,
                        true,
                        false
                    ),
                    !isLevelPrelevement
                ),
                commentairesId: this.formService.createFormControl(
                    'commentairesId',
                    new FormContext(
                        'commentairesId',
                        prelevement.donneesTechniques.limitationFibre,
                        [],
                        ngUnsubscribe,
                        true,
                        false
                    )
                ),
            }),
            pollutionSurfacique: this.formBuilder.group({
                value: this.formService.createFormControl(
                    'value',
                    new FormContext(
                        'value',
                        prelevement.donneesTechniques.pollutionSurfacique,
                        validatorRequiredOnPrelevementOrEmpty,
                        ngUnsubscribe,
                        true,
                        false
                    ),
                    !isLevelPrelevement
                ),
                commentairesId: this.formService.createFormControl(
                    'commentairesId',
                    new FormContext(
                        'commentairesId',
                        prelevement.donneesTechniques.pollutionSurfacique,
                        [],
                        ngUnsubscribe,
                        true,
                        false
                    )
                ),
            }),
            analyseAmiante: this.formBuilder.group({
                value: this.formService.createFormControl(
                    'value',
                    new FormContext(
                        'value',
                        prelevement.donneesTechniques.analyseAmiante,
                        validatorRequiredOnPrelevementOrEmpty,
                        ngUnsubscribe,
                        true,
                        false
                    )
                ),
                commentairesId: this.formService.createFormControl(
                    'commentairesId',
                    new FormContext(
                        'commentairesId',
                        prelevement.donneesTechniques.analyseAmiante,
                        [],
                        ngUnsubscribe,
                        true,
                        false
                    )
                ),
                idDocumentPvAmiante: this.formService.createFormControl(
                    'idDocumentPvAmiante',
                    new FormContext(
                        'idDocumentPvAmiante',
                        prelevement.donneesTechniques.analyseAmiante,
                        validatorRequiredOnPrelevementOrEmpty,
                        ngUnsubscribe,
                        true,
                        false
                    ),
                    prelevement.donneesTechniques.analyseAmiante.value === true
                ),
            }),
        });
    }

    /**
     * Crée le formGroup pour le formulaire d'édition d'un échantillon et remplis avec les valeurs actuelles
     * @param formGroupExistant
     */
    createEditEchantillonnageFormGroup(formGroupExistant: FormGroup): FormGroup {
        return this.formBuilder.group({
            reference: new FormControl(formGroupExistant.get('reference').value, [Validators.required]),
            type: new FormControl(formGroupExistant.get('type').value, [Validators.required]),
            hauteur: new FormControl(formGroupExistant.get('hauteur').value, [Validators.required]),
            materiaux: new FormControl(formGroupExistant.get('materiaux').value, [Validators.required]),
            commentairesId: new FormControl(formGroupExistant.get('commentairesId').value, []),
        });
    }

    /**
     * Changer de zone ou sortir d'une zone un prélèvement / besoin.
     * @param formArrayPerimetre Le tableau {@link FormArray} des {@link FormControl} des périmètres
     * @param formGroupEdited le {@link FormGroup} du besoin/prélèvement
     * @param listePerimetre la liste des {@link Perimetre}
     * @param zoneNew la nouvelle {@link Zone} ou null si on sort d'une zone.
     */
    onChangeZoneOfBesoinOrPrelevement(
        formArrayPerimetre: FormArray,
        formGroupEdited: FormGroup,
        listePerimetre: Perimetre[],
        zoneNew: Zone
    ) {
        const selected = this.getAllPrelevementsWithInfos(formArrayPerimetre).find(
            (it) => it.prelevement.value.id === formGroupEdited.value.id
        );
        if (selected) {
            const currentPerimetre = this.findPerimetreByFormGroup(listePerimetre, selected.perimetre);
            const currentList = selected.zone
                ? this.findZoneByFormGroup(listePerimetre, selected.zone as FormGroup)?.listePrelevements
                : currentPerimetre.listeBesoins;

            let currentFormArray = (
                selected.zone
                    ? (selected.zone as FormGroup).get('formArrayPrelevement')
                    : selected.perimetre.get('formArrayBesoins')
            ) as FormArray;

            const prelevement = this.findPrelevementOrBesoinByFormGroup(
                listePerimetre,
                selected.prelevement as FormGroup
            );

            removeItemFromList(currentList, prelevement, (a, b) => a.id === b.id);
            currentFormArray?.removeAt(selected.prelevementIndex);

            const targetList: Prelevement[] = zoneNew ? zoneNew.listePrelevements : currentPerimetre.listeBesoins;
            const targetFormArray: FormArray = zoneNew
                ? this.findZoneInPerimetreForm(formArrayPerimetre, zoneNew)
                : (selected.perimetre.get('formArrayBesoins') as FormArray);

            targetList.push(prelevement);
            targetFormArray.push(selected.prelevement);
        }
    }

    /**
     * Renvoie le {@link FormGroup} d'une zone correspondant du modèle {@link Zone} passé en paramètre.
     * @param perimetre
     * @param zoneNew
     * @private
     */
    private findZoneInPerimetreForm(perimetresFormArray: FormArray, zoneNew: Zone) {
        const allZones = perimetresFormArray.controls
            .map((it) => (it.get('formArrayZones') as FormArray).controls as FormGroup[])
            .flat();
        return allZones.find((it: FormGroup) => it.value.id === zoneNew.id)?.get('formArrayPrelevement') as FormArray;
    }

    /** Renvoie un flat map de tous les besoins/prélèvements avec les infos de zone / index etc.
     *
     * @param formArrayPerimetre
     * @private
     */
    private getAllPrelevementsWithInfos(formArrayPerimetre: FormArray) {
        return formArrayPerimetre.controls
            .map((perimetre: FormGroup) =>
                (perimetre.get('formArrayZones') as FormArray).controls
                    .map((zone) =>
                        (zone.get('formArrayPrelevement') as FormArray).controls.map(
                            (prelevement: FormGroup, prelevementIndex) => ({
                                prelevement,
                                zone,
                                perimetre,
                                prelevementIndex,
                            })
                        )
                    )
                    .flat(1)
                    .concat(
                        (perimetre.get('formArrayBesoins') as FormArray).controls.map(
                            (prelevement: FormGroup, prelevementIndex) => ({
                                prelevement,
                                zone: null,
                                perimetre,
                                prelevementIndex,
                            })
                        )
                    )
            )
            .flat(1);
    }
}

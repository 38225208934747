import { LegendScreenshot, LegendScreenshotItem, LegendShape } from '../model/screenshot-svg.model';

const lineHeightToFontSizeRatio = 0.714;
export interface LegendOptions {
    /**
     * Largeur minimale d'une colonne.
     */
    minColumnWidth: number;
    /**
     * Nombre maximale de lignes / items par colonne.
     */
    maxItemsPerColumn: number;

    /**
     * Largeur moyenne d'un caractère de nom d'item en pixel. Dépend de la {@link #itemFontSize}
     */
    itemCharacterWidth: number;
    /**
     * Taille de font pour les noms d'item.
     */
    itemFontSize: number;
    /**
     * Font size des titres de groupes.
     */
    titleFontSize: number;
    /**
     * Largeur moyenne d'un caractère d'un titre en pixel. Dépend de la {@link #titleCharacterWidth}
     */
    titleCharacterWidth: number;
    /**
     * Offset général horizontal en pixels.
     */
    yOffset: number;
    /**
     * Offset général vertical en pixels.
     */
    xOffset: number;
    /**
     * Epaisseur du trait de ligne
     */
    lineShapeThickness: number;
    /**
     * Padding entre 2 shapes d'un item.
     */
    paddingBetweenShapes: number;
    /**
     * Padding entre 2 blocs de groupes.
     */
    paddingBetweenBlocks: number;
    /**
     * Padding entre 2 colonnes d'item.
     */
    paddingBetweenColumns: number;
    /**
     * Padding entre 2 lignes d'item
     */
    paddingBetweenItemRows: number;
    /**
     * Padding entre la dernière shape et le texte d'un item.
     */
    paddingBetweenShapeAndText: number;
    /**
     * Largeur de shape d'item.
     */
    shapeWidth: number;
    /**
     * Hauteur de shape d'item.
     */
    shapeHeight: number;
    /**
     * Padding général en pixels.
     */
    padding: {
        top: number;
        left: number;
        right: number;
        bottom: number;
    };
}

/**
 * Générer le svg d'un item.
 * @param item l'item en question.
 * @param xCoord coordonnées X
 * @param yCoord coordonnées Y
 * @param options les options
 */
export function renderItem(item: LegendScreenshotItem, xCoord: number, yCoord: number, options: LegendOptions) {
    const {
        lineShapeThickness,
        itemFontSize,
        paddingBetweenShapes,
        shapeWidth,
        shapeHeight,
        paddingBetweenShapeAndText,
    } = options;
    const shapes = item.shapes.map((shape, indexShape) => {
        // Coordonnées X et Y de la shape.
        const x = xCoord + (shapeWidth + paddingBetweenShapes) * indexShape;
        let y = yCoord;
        let style;
        let height = shapeHeight;
        switch (shape) {
            // Rectangle de légende
            case LegendShape.FILLED_SQUARE:
                style = `fill:${item.couleur}; fill-opacity: ${item.opacity}; stroke:black;stroke-width: 1px`;
                break;

            // Rectangle vide
            case LegendShape.EMPTY_SQUARE:
                style = `fill:none; stroke:${item.couleur};stroke-width: 1px; stroke-dasharray: 4;`;
                break;

            // Ligne
            case LegendShape.LINE:
                style = `fill:${item.couleur}; fill-opacity: ${item.opacity}; stroke:${item.couleur};`;
                y = yCoord + shapeHeight / 2.0 - lineShapeThickness / 2.0;
                height = lineShapeThickness;
                break;
            default:
                break;
        }
        return `<rect x="${x}" y="${y}" width="${shapeWidth}" height="${height}" style="${style}"></rect>`;
    });
    const textX =
        xCoord +
        shapeWidth * item.shapes.length +
        paddingBetweenShapes * Math.max(item.shapes.length - 1, 0) +
        paddingBetweenShapeAndText * Math.min(item.shapes.length, 1);

    const textY = yCoord + shapeHeight / 2.0 + (itemFontSize * lineHeightToFontSizeRatio) / 2.0;

    const renderedItem = `${shapes.join('\n')}
<text font-size="${itemFontSize}" x="${textX}" y="${textY}">${item.nom}</text>`;

    const height = shapeHeight + 1;
    // Largeur du text calculé, pas forcément hyper exact.
    const textWidth = item.nom.length * options.itemCharacterWidth;
    const width = (shapeWidth + paddingBetweenShapes) * item.shapes.length + textWidth;
    return { renderedItem, height, width };
}

/**
 * Grouper des éléments d'un tableau en paquets.
 * @param inputArray
 * @param groupSize taille de paquets.
 */
function groupArrayBySize<T>(inputArray: T[], groupSize: number): Array<T[]> {
    const groupedArray = [];

    for (let i = 0; i < inputArray.length; i += groupSize) {
        groupedArray.push(inputArray.slice(i, i + groupSize));
    }

    return groupedArray;
}
export function generateLegendSVG(groups: LegendScreenshot[], options: LegendOptions): string {
    const {
        minColumnWidth,
        maxItemsPerColumn,
        paddingBetweenColumns,
        paddingBetweenItemRows,
        itemCharacterWidth,
        xOffset,
        yOffset,
        titleFontSize,
        titleCharacterWidth,
    } = options;

    let currentX = xOffset + options.padding.left;
    let currentY = yOffset + options.padding.top;

    let legendWidth = 0; // Dynamically calculate the legend width
    let legendHeight = 0; // Dynamically calculate the legend height

    // group items by group size
    const tGroups = groups.map((group) => ({
        ...group,
        items: groupArrayBySize(group.items, maxItemsPerColumn),
    }));

    const legendContents = tGroups.map((group, groupIndex) => {
        const isLastGroup = tGroups.length - 1 === groupIndex;
        const titleY = currentY + titleFontSize;
        const groupTitle = `<text x="${currentX}" y="${titleY}" font-size="${titleFontSize}" font-weight="bold">${group.nomCategorie}</text>`;
        const titleWidth = group.nomCategorie.length * titleCharacterWidth;

        let currentColumnX = currentX;
        const currentBlockY = currentY + titleFontSize + 10;
        let currentColumnY = currentBlockY;
        let maxColumnWidth = Math.max(minColumnWidth);

        const renderedItemGroups = group.items.map((itemGroup, itemGroupIndex) => {
            const isLastItemGroup = group.items.length - 1 === itemGroupIndex;
            const renderedItemGroup = itemGroup.map((item, index) => {
                const { renderedItem, width, height } = renderItem(item, currentColumnX, currentColumnY, options);
                currentColumnY += height + paddingBetweenItemRows; // Spacing between item rows
                maxColumnWidth = Math.max(width, maxColumnWidth);
                return renderedItem;
            });
            currentColumnX += maxColumnWidth + (isLastItemGroup ? 0 : paddingBetweenColumns);
            legendHeight = Math.max(legendHeight, currentColumnY);
            currentColumnY = currentBlockY;
            maxColumnWidth = minColumnWidth;
            return renderedItemGroup;
        });

        const groupContent = [groupTitle].concat(renderedItemGroups.flat()).join('\n');

        // Update the legend width and height based on the current group
        currentX = Math.max(currentColumnX, currentX + titleWidth) + (isLastGroup ? 0 : options.paddingBetweenBlocks);
        legendWidth = Math.max(legendWidth, currentX - xOffset);
        return groupContent;
    });

    // Create a white background rectangle behind the legends
    const backgroundRect = `<rect x="${xOffset}" y="${yOffset}" width="${
        legendWidth + options.padding.right
    }" height="${legendHeight + options.padding.bottom}" fill="white" />`;

    return `<g>${legendContents.length ? backgroundRect : ''}${legendContents.join('')}</g>`;
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { Intervention } from 'src/app/model/intervention.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    PolluantPrelevement,
    PrelevementAcr,
    PrelevementMest,
    PrelevementMeta,
    PrelevementMetop,
} from '../../../model/polluant-prelevement.model';
import { Besoin } from '../../../model/besoin.model';
import { Zone } from '../../../model/zone.model';
import { PolluantService } from '../../../services/polluant.service';
import { ConditionsPrelevement } from '../../../model/polluant-config.model';
import { Surface } from '../../../model/surface.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Niveau, Volume } from 'src/app/model/bien.model';
import { ZoneProprieteModalComponent } from '../../shared/zone/zone-propriete-modal/zone-propriete-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { PrelevementZoneBesoinModalComponent } from './prelevement-zone-besoin-modal/prelevement-zone-besoin-modal.component';

@Component({
    selector: 'app-prelevement-zone',
    templateUrl: './prelevement-zone.component.html',
    styleUrls: ['./prelevement-zone.component.scss'],
    providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
    ],
})
export class ZonePrelevementComponent extends BaseComponent implements OnInit, OnDestroy {
    @ViewChild('zoneProprieteModalComponent') zoneProprieteModalComponent: ZoneProprieteModalComponent;

    diagnostic: Diagnostic;
    intervention: Intervention;
    contenuDiagnostic: PolluantPrelevement;
    isLoaded: Boolean = false;
    isInEditMode: Boolean = false;
    isValidated: Boolean = false;

    form: FormGroup;
    previousFormValue: any;

    indexBesoinSelected: number;
    besoins: any[] = [];
    allBesoins: any[] = [];
    zones: Zone[] = [];
    selectedZone: Zone;
    volumes: Volume[] = [];

    contientMetop: Boolean = false;
    contientMeta: Boolean = false;

    //Permet d'afficher ou de masquer les actions ajouter, dupliquer et supprimer dans la sidebar
    //Par défaut à false sur cet écran
    //true uniquement lorsque l'utilisateur est sur la zone "Hors stratégie"
    showActions: Boolean = false;

    polluantConfig: any;

    constructor(
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService,
        public interventionService: InterventionService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private polluantService: PolluantService,
        private readonly notificationService: NotificationService,
        private router: Router,
        public dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        protected etatProgressionService: EtatProgressionService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService.getCurrentIntervention(),
                    this.diagnosticService.getCurrentDiagnostic(),
                ])
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diag]) => {
                    this.intervention = intervention;

                    //Récupération des volumes
                    this.volumes = this.intervention.relationInterventionBiens[0].bien.description.reduce(
                        (acc: any, niveau: Niveau) => {
                            return acc.concat(niveau.volumes);
                        },
                        []
                    );

                    this.diagnostic = diag;
                    this.polluantService
                        .findOnePolluantConfigIndexedDB(this.diagnostic.idConfig)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((polluantConfig) => {
                            if (polluantConfig == null) {
                                this.notificationService.error(
                                    'Erreur : Merci de faire une synchro pour récupérer les configs en local.'
                                );
                                this.cnSpinnerService.hide();
                                return;
                            }
                            this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;
                            this.polluantConfig = polluantConfig;

                            this.zones = this.contenuDiagnostic.listeZones.data.listeZones;

                            //Récupération de la zone actuelle par rapport à l'url
                            this.selectedZone = Object.assign(
                                {},
                                this.zones.find((zone: Zone) => this.route.snapshot.params.idZone == zone.id)
                            );
                            //Si la zone sélectionnée est Hors Stratégie
                            // alors les actions sont affichées

                            console.log('zone', this.zones);

                            if (this.selectedZone.nom == 'Hors stratégie') {
                                this.showActions = true;
                            }

                            this.allBesoins = this.contenuDiagnostic.prelevement.data.besoinsList;

                            //this.restoreBesoinsData();

                            //Creation du listener pour détecter le changement d'onglet
                            this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((val) => {
                                if (val instanceof NavigationEnd) {
                                    this.isLoaded = false;
                                    //Sélection de la zone par rapport à l'url
                                    const zoneId = val.urlAfterRedirects.split('/').pop();
                                    this.selectedZone = this.zones.find((zone: Zone) => zone.id == zoneId);

                                    //Modification de l'option d'affichage des actions si on se trouve sur la zone "Hors stratégie"
                                    this.showActions = false;
                                    if (this.selectedZone.nom == 'Hors stratégie') {
                                        this.showActions = true;
                                    }

                                    this.isInEditMode = false;

                                    //Recréation du formulaire
                                    //this.restoreBesoinsData();
                                    this.changeDetectorRef.detectChanges();
                                    this.isLoaded = true;
                                }
                            });

                            this.isLoaded = true;
                            setTimeout(() => this.initZoneProprieteModalComponent());
                            this.cnSpinnerService.hide();
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    /**
     * @description Permet de récupérer les besoins associés à une Zone
     */
    private restoreBesoinsData(): void {
        this.besoins = [];

        if (this.selectedZone != undefined && this.selectedZone.listeSurfaces != undefined) {
            this.selectedZone.listeSurfaces.forEach((surface: Surface) => {
                surface.listeIdVolume.forEach((volumeId: string) => {
                    this.allBesoins.forEach((besoin: any) => {
                        if (besoin.besoinPrelevement.pieceId == volumeId) {
                            this.besoins.push(besoin);
                        }
                    });
                });
            });
        }

        //Permet de définir si un objectif de mesurage de type METOP ou META est présent
        this.contientMeta = false;
        this.contientMetop = false;
        this.besoins.forEach((besoin: any) => {
            let _obj: ConditionsPrelevement = besoin.besoinPrelevement.objectifMesurage;
            if (_obj.typePrelevement == 'METOP') this.contientMetop = true;
            if (_obj.typePrelevement == 'META') this.contientMeta = true;
        });
    }

    public besoinSelected(data: any) {
        if (data.action == 'new' || data.action == 'edit') {
            this.openModal(data.besoin, data.action);
        }
        //On récupère les données et on met à jour la liste des besoins
        this.indexBesoinSelected = data.besoinIdToDisplay;
        if (this.indexBesoinSelected == undefined) {
            return;
        }
    }

    /**
     * @description Méthode appellée pour ouvrir la modale d'édition des besoins
     */
    private openModal(besoin: Besoin | undefined, action: any) {
        const dialogRef = this.dialog.open(PrelevementZoneBesoinModalComponent, {
            width: '250px',
            data: {
                deps: {
                    formBuilder: this.formBuilder,
                    polluantService: this.polluantService,
                },
                zone: this.selectedZone,
                volumes: this.volumes,
                besoin: besoin,
                polluantConfig: this.polluantConfig,
                contenuDiagnostic: this.contenuDiagnostic,
                isInEditMode: this.isInEditMode,
            },
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result: { actionReturn: string; data: any | undefined }) => {
            if (result.actionReturn === 'cancel') {
                return;
            } else {
                this.isLoaded = false;

                if (action == 'new' || action == 'edit') {
                    let besoin = result.data;
                    let prelevement: any;

                    //Création ou recréation du Prélèvement associé au besoin de prélèvement
                    // en fonction de son objectif de mesurage
                    // Important aussi en édition car l'obj de mesurage a peut être modifié
                    let _objMesurage: ConditionsPrelevement = besoin.objectifMesurage;
                    if (_objMesurage.typePrelevement == 'META') {
                        prelevement = PrelevementMeta.fromBesoin(besoin);
                    } else if (_objMesurage.typePrelevement == 'METOP') {
                        prelevement = PrelevementMetop.fromBesoin(besoin, this.contenuDiagnostic);
                    } else if (_objMesurage.typePrelevement == 'MEST') {
                        prelevement = PrelevementMest.fromBesoin(besoin);
                    } else if (_objMesurage.typePrelevement == 'ACR') {
                        prelevement = PrelevementAcr.fromBesoin(besoin);
                    }

                    if (action == 'new') {
                        //Si il s'agit d'une création
                        // le prélèvement est ajouté à la liste des besoins de prélèvements
                        this.besoins.push(prelevement);
                    } else if (action == 'edit') {
                        //Si il s'agit d'une modification
                        // le Prélèvement à modifier est trouvé et remplacé
                        let besoinAModifier = this.besoins.find((_besoin: any) => {
                            return _besoin.besoinPrelevement.id == besoin.id;
                        });
                        let indexBesoin = this.besoins.indexOf(besoinAModifier);
                        if (indexBesoin > 0) {
                            this.besoins[indexBesoin] = prelevement;
                        }
                    }
                }

                //Workaround pour propager les changements aux composants enfants
                this.changeDetectorRef.detectChanges();
                this.isLoaded = true;
            }
            this.checkValidity();
        });
    }
    private checkValidity() {
        //Patch Zones
        const formData = this.form.getRawValue();
        Object.assign(this.selectedZone, formData.information);
        if (this.contientMeta) {
            Object.assign(this.selectedZone, formData.meta);
        }
        if (this.contientMetop) {
            Object.assign(this.selectedZone, formData.metop);
        }

        //Patch Besoins
        this.contenuDiagnostic.prelevement.data.besoinsList = this.besoins;

        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.diagnostic, true);
    }

    cancelModification() {
        this.isInEditMode = false;
        this.zoneProprieteModalComponent.isInEditMode = false;
        this.form.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.zoneProprieteModalComponent.isInEditMode = false;
        const index = this.zones.findIndex((zone: Zone) => this.route.snapshot.params.idZone == zone.id);
        this.zones[index] = this.form.getRawValue();
        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuDiagnostic.listeZones.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.zoneProprieteModalComponent.isInEditMode = true;
        this.previousFormValue = JSON.parse(JSON.stringify(this.form.getRawValue()));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    private initZoneProprieteModalComponent(): void {
        this.form = this.formBuilder.group({
            id: [this.selectedZone.id],
            identifiant: [this.selectedZone.identifiant],
            nom: [this.selectedZone.nom, Validators.required],
            usageLocaux: [this.selectedZone.usageLocaux, Validators.required],
            objectifMesurage: [this.selectedZone.objectifMesurage, Validators.required],
            ventilation: [this.selectedZone.ventilation, Validators.required],
            environnement: [this.selectedZone.environnement, Validators.required],
            frequentation: [this.selectedZone.frequentation, Validators.required],
            personnesPresentes: [this.selectedZone.personnesPresentes, Validators.required],
            activiteAdjacente: [this.selectedZone.activiteAdjacente, Validators.required],
            confinement: [this.selectedZone.confinement, Validators.required],
            nombreExtracteurs: [this.selectedZone.nombreExtracteurs, Validators.required],
            sasMateriel: [this.selectedZone.sasMateriel, Validators.required],
            sasPersonnel: [this.selectedZone.sasPersonnel, Validators.required],
            listeMpca: [this.selectedZone.listeMpca, Validators.required],
            listeSurfaces: this.getSurfaceGroup(this.selectedZone.listeSurfaces),
            zoneContexte: [this.selectedZone.zoneContexte, Validators.required],
            typeZone: [this.selectedZone.typeZone, Validators.required],
            zse: [this.selectedZone.zse, Validators.required],
        });
        this.zoneProprieteModalComponent.mpcaListSelected = this.form?.get('listeMpca')?.value;

        this.zoneProprieteModalComponent.polluantConfig = this.polluantConfig;
        this.zoneProprieteModalComponent.indexZone = 0;
        this.zoneProprieteModalComponent.isInEditMode = this.isInEditMode;
        this.zoneProprieteModalComponent.isModal = false;
        const lienVolumeObj = {};
        this.form.getRawValue().listeSurfaces.forEach((surface: Surface) => {
            surface.listeIdVolume.forEach((idVolume: string) => {
                if (this.form.get('objectifMesurage').value == '') {
                    // this.lienVolumeObj[idVolume] = [];
                    return;
                } else {
                    const tableauRefs = this.form.get('objectifMesurage').value.reduce((acc, obj) => {
                        return acc.concat(obj.parentId);
                    }, []);

                    if (lienVolumeObj[idVolume] == undefined) {
                        lienVolumeObj[idVolume] = [];
                    }

                    lienVolumeObj[idVolume] = lienVolumeObj[idVolume].concat(tableauRefs);
                }
            });
        });
        this.zoneProprieteModalComponent.lienVolumeObj = lienVolumeObj;
        this.zoneProprieteModalComponent.form = this.form;
    }
    private getSurfaceGroup(listeSurfaces: Surface[]) {
        const surfaceFormArray = this.formBuilder.array([]);
        listeSurfaces.forEach((surface) => {
            const surfaceForm = this.formBuilder.group({
                superficie: [surface.superficie, Validators.required],
                longueurMax: [surface.longueurMax, Validators.required],
                inferieurSeuilMin: [surface.inferieurSeuilMin, Validators.required],
                listeIdVolume: [surface.listeIdVolume, Validators.required],
                listeCommentaire: [surface.listeCommentaire],
                nombrePu: '',
            });
            surfaceFormArray.push(surfaceForm);
        });

        return surfaceFormArray;
    }
}

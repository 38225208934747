import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { Injectable, Injector, Type } from '@angular/core';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { Route } from '@angular/router';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Polluant, PolluantReportData } from '../model/polluant.model';
import { Intervention } from '../../../../model/intervention.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { PolluantService } from './polluant.service';
import { polluantEtudeSituationNavbarRoutes } from '../../polluant/routing/polluant-etude-situation/polluant-etude-situation-routing.module';
import { Rule } from '../../../../model/regle.model';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { Observable, of } from 'rxjs';
import { TypeReport } from 'src/app/model/reference-prestation.model';
import { DocumentsService } from 'src/app/services/documents.service';
import { PollHeaderBlockComponent } from '../report/blocks/poll-header-block/poll-header-block.component';
import { PollIntroductionBlockComponent } from '../report/blocks/poll-introduction-block/poll-introduction-block.component';
import { PollPerimetreBlockComponent } from '../report/blocks/poll-perimetre-block/poll-perimetre-block.component';
import { PollContextBlockComponent } from '../report/blocks/poll-context-block/poll-context-block.component';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { LegendScreenshot } from 'src/app/model/screenshot-svg.model';
import { cn_building, cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { PollZonesBlockComponent } from '../report/blocks/poll-zones-block/poll-zones-block.component';
import * as moment from 'moment';
import { MpcaItemOld } from '../model/mpca.model.old';

/**
 * Liste des Type de component (Class) de block de rapport disponible pour la composition
 * du contenu d'un raport
 */
export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    PollHeaderBlockComponent: PollHeaderBlockComponent,
    PollIntroductionBlockComponent: PollIntroductionBlockComponent,
    PollPerimetreBlockComponent: PollPerimetreBlockComponent,
    PollZonesBlockComponent: PollZonesBlockComponent,
    PollContextBlockComponent: PollContextBlockComponent,
};

@Injectable({
    providedIn: 'root',
})
export class TypePolluantEtudeSituationService implements ITypePrestationInterface {
    // PARAM volume
    private typePrestation: TypePrestation = 'POLLUANT_ETUDE_SITUATION';

    constructor(
        private readonly polluantService: PolluantService,
        private readonly documentsService: DocumentsService,
        private readonly pointDeControleService: PointDeControleService
    ) {}

    generateLegendForScreenshot(diagnostic: Diagnostic): LegendScreenshot[] {
        return [];
    }

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    getRoutes(): Route[] {
        return polluantEtudeSituationNavbarRoutes;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new Polluant());
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return [];
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        return 0;
    }

    getDiagnosticReportData(
        intervention: Intervention,
        diagnostic: Diagnostic,
        rules?: Rule[],
        optionPlan?: boolean
    ): IDiagReportData {
        let plan;
        const diagReportData = new PolluantReportData();

        //bypass
        diagReportData.conformiteGlobale = true;
        //bypass
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        const contenuDiagnostic = diagnostic.contenuDiagnostic as Polluant;

        //Perimetre
        diagReportData.perimetre = contenuDiagnostic.perimetreDescriptif.data.description;

        //
        const biens = intervention.relationInterventionBiens.map((relationBienPrincipal) => relationBienPrincipal.bien);

        let currentBien = diagnostic.pointsDeControleBiens[0];
        //Récupération des processus et lien avec mpca
        const processus = contenuDiagnostic.mpcaList.data.descriptifList.reduce((acc, mpca) => {
            if (mpca.processus && mpca.processus.length > 0) {
                acc = acc.concat(
                    mpca.processus.map((pr) => {
                        //Empêche dépendances cycliques
                        let newMpca = { ...mpca };
                        newMpca.processus = undefined;
                        pr.mpca = newMpca;
                        return pr;
                    })
                );
            }
            return acc;
        }, []);

        const bien = biens.find((b) => b.id === currentBien.idBien);

        //Si le plan est utilisé, alors on charge ses données pour récupérer la superficie des pièces
        if (bien && bien.jsonPlan) {
            plan = cn_building.unserialize(JSON.parse(bien.jsonPlan));
        }

        diagReportData.zones = contenuDiagnostic.zones.data.zonesList.map((zone) => {
            zone.hasSurfacesInterface = false;
            zone.isZoneDeTravail = zone.typeZone == 'Zone de travail';
            zone.hasMeta = false;
            zone.hasMetop = false;

            zone.listeMpca = zone.listeMpca.map((mpca: MpcaItemOld) => {
                // Duplication de l'item MPCA
                const nMpca = JSON.parse(JSON.stringify(mpca));
                nMpca.nom = nMpca.general.nom;
                nMpca.typeAmiante = nMpca.general.typeAmiante.join(', ');
                return nMpca;
            });

            zone.listeSurfaces = zone.listeSurfaces.map((surface) => {
                surface.listeVolumes = surface.listeIdVolume.map((idVolume) => {
                    //Récupération des données du volume dans la description du bien
                    const volume = intervention.relationInterventionBiens[0].bien.description
                        .reduce((acc, niveau) => {
                            acc = acc.concat(niveau.volumes);
                            return acc;
                        }, [])
                        .find((volume) => volume.id == idVolume);

                    //Récupération des données du plan de ce volume
                    volume.plan = [
                        plan.storeys
                            .reduce((acc, storey) => {
                                acc = acc.concat(storey.scene.spaces);
                                return acc;
                            }, [])
                            .find((space) => space.ID == volume.spaceId),
                    ].map((space) => {
                        return {
                            ID: space.ID,
                            area: space.area.toFixed(2),
                        };
                    })[0];

                    //Calcul du nombre de PU du volume
                    let puNb;
                    if (surface.longueurInterface > 0 && surface.hauteurInterface > 0) {
                        puNb = Math.ceil(
                            (14 * surface.longueurInterface * surface.hauteurInterface) /
                                (730 + surface.longueurInterface * surface.hauteurInterface)
                        );
                    } else if (surface.superficie <= 100 && surface.longueurMax <= 15) {
                        puNb = 1;
                    } else if (surface.superficie <= 100 && surface.longueurMax > 15) {
                        puNb = Math.ceil(surface.longueurMax / 15);
                    } else if (surface.superficie > 100) {
                        puNb = Math.ceil((14 * surface.superficie) / (730 + surface.superficie));
                    } else {
                        puNb = 1;
                    }
                    volume.puNb = puNb;

                    return volume;
                });

                if (surface.surfaceInterface != 0) {
                    zone.hasSurfacesInterface = true;
                }

                return surface;
            });

            // zone.environnement = zone.environnement;

            zone.besoins = contenuDiagnostic.besoins.data.besoinsList.map((besoin) => {
                if (besoin.objectifMesurage.norme == 'NF X43-050') {
                    zone.hasMeta = true;
                    besoin.isMeta = true;
                    besoin.isMetop = false;
                } else if (besoin.objectifMesurage.norme == 'NF X43-269') {
                    zone.hasMetop = true;
                    besoin.isMeta = false;
                    besoin.isMetop = true;
                }

                //Transformation des formats durée
                besoin.objectifMesurage.dureeMinHum = moment
                    .duration(besoin.objectifMesurage.dureeMin)
                    .locale('fr')
                    .humanize();
                besoin.objectifMesurage.dureeMaxHum = moment
                    .duration(besoin.objectifMesurage.dureeMax)
                    .locale('fr')
                    .humanize();

                //récupération du processus associé au besoin
                if (besoin.processusId) {
                    besoin.processus = processus.find((pr) => pr.id == besoin.processusId);
                }

                return besoin;
            });

            return zone;
        });

        diagReportData.refRapport = diagnostic.reportDatas.find(
            (reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT
        ).refRapport;

        diagReportData.introduction = contenuDiagnostic.introduction.data.introduction;
        diagReportData.mpcaList = contenuDiagnostic.mpcaList.data.descriptifList;

        diagReportData.recommandations = null;
        diagReportData.constatationsDiverses = null;
        diagReportData.annexes = null;
        diagReportData.reportagesPhotos = null;
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);

        return diagReportData;
    }

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {}

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {
        this.polluantService.prepareFormActiviteAmiante(intervention, contenuDiagnostic as Polluant);
    }

    prepareSpecificComments(
        diagnostic: Diagnostic,
        commentairesGeneraux: Commentaire[],
        commentaires: CommentairePredefini[]
    ) {}

    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return undefined;
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {}

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { MatDialog } from '@angular/material/dialog';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';
import { DialogUtils } from '../../../utils/dialog.utils';
import { TypeCommentaire } from '../../../model/type-commentaire.model';
import { PointDeControleElement } from '../../../model/point-de-controle.model';
import { Intervention } from '../../../model/intervention.model';
import { InterventionService } from '../../../services/intervention.service';
import { takeUntil } from 'rxjs/operators';
import { Diagnostic } from '../../../model/diagnostic.model';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';
import { EnvironmentUtils } from 'src/app/utils/environment.utils';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-notes-buttons',
    templateUrl: './notes-buttons.component.html',
    styleUrls: ['./notes-buttons.component.scss'],
})
export class NotesButtonsComponent extends BaseComponent implements OnInit {
    @Input()
    commentsId: string[] = [];

    @Input()
    from: any;

    @Input()
    diagnostic: Diagnostic;

    @Input()
    element: PointDeControleElement;

    // Tous les types de commentaire à proposer
    @Input()
    typesCommentaires: TypeCommentaire[] = [];

    // Force le type du nouveau commentaire
    @Input()
    typeNouveauCommentaire: TypeCommentaire;

    @Input()
    replaceTypesCommentaires = false;

    @Input()
    isDescription = false;

    @Input()
    colorIcon = 'primary';

    @Output()
    commentChanged = new EventEmitter<any>();

    intervention: Intervention;

    afficherBoutonPhoto: boolean = EnvironmentUtils.afficherBoutonPhoto();

    constructor(
        public matDialog: MatDialog,
        private interventionService: InterventionService,
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService
    ) {
        super();
    }

    ngOnInit(): void {
        this.interventionService
            .getCurrentIntervention()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((intervention) => (this.intervention = intervention));
    }

    openPictures() {
        console.log('open picture modal');
    }

    openComments($event) {
        $event.stopPropagation();
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    commentairesId: [...this.getTabCommentairesId()],
                    typesCommentaires: this.typesCommentaires,
                    typeNouveauCommentaire: this.typeNouveauCommentaire,
                    replaceTypesCommentaires: this.replaceTypesCommentaires,
                    elements: [this.element],
                    isDescription: this.isDescription,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.setTabCommentairesId(result.commentairesId);
                    // Nettoyage des commentaires obsolètes
                    this.getTabCommentairesId().forEach((com) => {
                        const comment = this.intervention.commentaires.find((c) => c.id === com);
                        if (comment === undefined) {
                            this.getTabCommentairesId().splice(this.getTabCommentairesId().indexOf(com), 1);
                        }
                    });
                    this.commentChanged.emit(this.getTabCommentairesId());
                    if (this.diagnostic) {
                        this.cnSpinnerService
                            .withSpinner(
                                this.diagnosticService.upsert(this.intervention, this.diagnostic),
                                'Ajout de commentaire en cours...'
                            )
                            .pipe(takeUntil(this.ngUnsubscribe))
                            .subscribe();
                    } else {
                        this.cnSpinnerService
                            .withSpinner(
                                this.interventionService.updateIntervention(this.intervention),
                                'Ajout de commentaire en cours...'
                            )
                            .pipe(takeUntil(this.ngUnsubscribe))
                            .subscribe();
                    }
                }
            });
    }

    getTabCommentairesId(): string[] {
        if (this.from) {
            if (this.from instanceof FormControl) {
                return this.from.value;
            } else {
                return this.from.commentairesId;
            }
        } else {
            return [];
        }
    }

    private setTabCommentairesId(newTabCommentairesId: string[]) {
        if (this.from) {
            if (this.from instanceof FormControl) {
                this.from.patchValue(newTabCommentairesId);
            } else {
                this.from.commentairesId = newTabCommentairesId;
            }
        }
    }
}

<ng-container *ngIf="headerChoices.length">
    <div class="py-2 d-flex justify-content-end">
        <app-state-input
            [reset]="true"
            [labels]="true"
            [choices]="headerChoices"
            [containerClass]="'spaceBetween'"
            (valueChangeFromUser)="onChangeBoutonHeader($event)"
        >
        </app-state-input>
    </div>
    <mat-divider></mat-divider>
</ng-container>
<div *ngIf="aJustifier.length > 1" class="to-justify all mr-2 mb-3">
    Il manque des justifications de {{ aJustifier.length }} éléments
    <button title="ButonCommentaire" mat-icon-button color="justify" class="ml-1" (click)="addJustificationForAll()">
        <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
    </button>
</div>
<ng-template ngFor let-checkpoint [ngForOf]="checkpoints">
    <mat-card>
        <mat-card-title>
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <app-section-title [label]="checkpoint.chapitre.description" [showHelp]="false"></app-section-title>
                </div>
                <app-state-input
                    class="mr-2"
                    [persistentColor]="true"
                    [choices]="choicesCheckPoint"
                    [tooltips]="tooltipsEtatRaccord"
                    (valueChangeFromUser)="onChangeAll(checkpoint.pointsControles, $event)"
                >
                </app-state-input>
            </div>
        </mat-card-title>
        <mat-card-content>
            <ng-template ngFor let-pointControle [ngForOf]="checkpoint.pointsControles">
                <mat-card
                    [id]="pointControle.idInterne"
                    class="p-1 pb-0 mb-3 w-100 d-flex flex-column justify-content-between"
                    [ngClass]="
                        diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]?.valeur == OUI ||
                        diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]?.valeur ==
                            SANS_OBJET
                            ? 'point-de-controle-conforme'
                            : diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]?.valeur ==
                              NON
                            ? 'point-de-controle-non-conforme'
                            : diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]?.valeur ==
                              NON_VERIFIABLE
                            ? 'point-de-controle-justifier'
                            : 'point-de-controle-non-rempli'
                    "
                >
                    <mat-card-content
                        [ngClass]="
                            diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]?.isGrey
                                ? 'disabledContent'
                                : ''
                        "
                    >
                        <ng-container
                            *ngIf="
                                diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                    ?.greyExplication
                            "
                        >
                            <div class="d-flex px-2">
                                <b>{{
                                    diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                        ?.greyExplication
                                }}</b>
                            </div>
                            <mat-divider></mat-divider>
                        </ng-container>
                        <div class="d-flex px-2 align-items-center mt-2">
                            <div class="mr-auto d-flex">
                                <div
                                    *ngIf="pointControle.isFluke"
                                    class="mr-2 item-clickable"
                                    matRipple
                                    (click)="openGuideReference($event)"
                                >
                                    <img alt="Fluke" style="max-height: 45px" src="assets/img/MesureElectrique21.png" />
                                </div>
                                <div>{{ pointControle.numeroArticle }} {{ pointControle.description }}</div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div
                                    *ngIf="
                                        diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                            ?.greyLienPointControleExplication
                                    "
                                >
                                    <lib-icon class="icon-small">link</lib-icon>
                                    <span>{{
                                        diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                            ?.greyLienPointControleExplication
                                    }}</span>
                                </div>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    class="mr-2"
                                    matTooltip="Afficher l'aide"
                                    (click)="openHelp(pointControle)"
                                >
                                    <lib-icon class="icon-small">help</lib-icon>
                                </button>
                                <app-notes-buttons
                                    class="mr-2"
                                    [ngStyle]="{
                                        'pointer-events': diagnostic.pointsDeControleBiens[0].valeursParametres[
                                            pointControle.idInterne
                                        ]
                                            ? ''
                                            : 'none'
                                    }"
                                    [from]="
                                        diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                    "
                                    [diagnostic]="diagnostic"
                                    [typesCommentaires]="COMMENT_TYPES"
                                    [replaceTypesCommentaires]="COMMENT_TYPES"
                                    [typeNouveauCommentaire]="
                                        typeNouveauCommentaireSelect(
                                            diagnostic.pointsDeControleBiens[0].valeursParametres[
                                                pointControle.idInterne
                                            ]?.valeur
                                        )
                                    "
                                    (commentChanged)="onChangeComment()"
                                >
                                </app-notes-buttons>
                                <app-state-input
                                    *ngIf="!isReload"
                                    [choices]="choicesCheckPoint"
                                    [reset]="true"
                                    [tooltips]="tooltipsEtatRaccord"
                                    [disabled]="
                                        diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                            ?.isGrey
                                    "
                                    [choiceValue]="
                                        diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                            ?.valeur
                                    "
                                    (valueChangeFromUser)="onValeurChange($event, pointControle)"
                                >
                                </app-state-input>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                (diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                    ?.valeur == NON_VERIFIABLE ||
                                    diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                        ?.valeur == NON) &&
                                !diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]
                                    ?.commentairesId?.length &&
                                !diagnostic.pointsDeControleBiens[0].valeursParametres[pointControle.idInterne]?.isGrey
                            "
                            class="to-justify"
                        >
                            Il manque une justification
                            <button
                                title="ButonCommentaire"
                                mat-icon-button
                                color="justify"
                                class="ml-1"
                                (click)="addJustification(pointControle.idInterne)"
                            >
                                <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </mat-card-content>
    </mat-card>
</ng-template>

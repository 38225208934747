import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { CeeConfig } from '../model/cee.model';

@Injectable({
    providedIn: 'root',
})
export class CeeApiService {
    public resourceUrl = `${environment.apiUrl}/cee`;
    private http: HttpClient;

    constructor(private httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    getConfig(): Observable<CeeConfig> {
        return this.http.get<CeeConfig>(this.resourceUrl + '/config');
    }
}

"use strict";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_event_handler} from "./cn_event_handler";
//***********************************************************************************
//***********************************************************************************
//**** cn_handlerspace_drag
//***********************************************************************************
//***********************************************************************************

import {cn_handler_object} from "./cn_handler_object";
import {cn_camera} from "./cn_camera";
import {
    cn_clone,
    cn_cos,
    cn_dist, cn_dot,
    cn_mul,
    cn_normalize,
    cn_polar,
    cn_sin,
    cn_size,
    cn_sub,
	cnx_add, cnx_mul, cnx_clone
} from "../utils/cn_utilities";

export class cn_handler_space_drag2 extends cn_event_handler{
	constructor(object, scene, storey = null) {
		super();
		this.object = object;
		this.new_object = new cn_handler_object(0,0);
		this.new_object.copy(object);

		this.scene = scene;
		this.storey = storey;
		this._radius = 20;

		this._mouseover = 0;
		this._grabbed = false;
		this._grab_center = false;
		this._grab_local = [0,0];
		this._grab_mouse = [0,0];
		this.space = null;
		for (var i=0;i<this.scene.spaces.length;i++)
		{
			if (!this.scene.spaces[i].contains(this.object.center)) continue;
			this.space = this.scene.spaces[i];
			break;
		}
		this._collisions = [];
		this._angular_precision = 0.001/(this.object.width + this.object.height);
		this.altitude = 0;

		this.radius = 80;
		this.handle_radius = 10;
		this.handles = [];
		for (var i=0;i<8;i++) this.handles.push([0,0]);

		this.on_change = null;

		this.world_radius = this.new_object.width + this.new_object.height;
	}

	//*****************************************************************
	//*** Draw the handler
	//*****************************************************************
	draw(camera) {
		var html = "";

        const object_center = cnx_clone(this.object.center);
		var z = this.altitude;
		object_center[2] = z;

        //*** Draw main circlecenter
        var c = camera.world_to_screen(this.object.center);
        var add_classes = "";
        if (this._mouseover === 1)
            add_classes = " mouseover";

        if (camera.is_3d()) {
            html += `<path class="handle2_center${add_classes}" d="`;
            for (var alpha = 0; alpha < 360; alpha += 5) {
                var dir = [cn_cos(alpha), cn_sin(alpha), 0];
                const h = camera.world_to_screen(cnx_add(object_center, cnx_mul(dir, this.world_radius)));
                if (alpha == 0) html += `M `;
                else html += `L `;
                html += `${h[0]} ${h[1]} `;
            }
            html += ` Z" />`;
        } else
            html += "<circle class='handle2_center" + add_classes + "' cx='" + c[0] + "' cy='" + c[1] + "' r='" + this.radius + "'/>";


        //*** draw handles
        for (var alpha = 0; alpha < 8; alpha++) {
            var dir = [cn_cos(alpha * 45), cn_sin(alpha * 45), 0];

            if (camera.is_3d())
                dir = cn_mul(dir, this.world_radius);
            else
                dir = cn_mul(dir, camera.screen_to_world_scale * this.radius);

            var h = this.object.local_to_global(dir);
			if (camera.is_3d()) h.push(object_center[2]);
            this.handles[alpha] = h;
            var hh = camera.world_to_screen(h);

            var add_classes = "";
            if (this._mouseover === alpha + 2)
                add_classes = " mouseover";
            html += "<circle class='handle2_handle" + add_classes + "' cx='" + hh[0] + "' cy='" + hh[1] + "' r='" + this.handle_radius + "'/>";
        }

		/** draw collisions */
        for (var i in this._collisions) {
            var p = camera.world_to_screen(cnx_clone(this._collisions[i],z));
            html += "<circle cx='" + p[0] + "' cy='" + p[1] + "' r='5' style='fill:blue'/>";
        }

        return html;
    }

	//*****************************************************************
	//*** Clear move data
	//*****************************************************************
	clear_move() {
		this._mouseover = 0;
	}

    /**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
	 move(mouse_event) {
		this._manage_3d(mouse_event);
		this._mouseover = 0;

		//** is moue over a handle ?
		for (var i=0;i<this.handles.length;i++)
		{
			const d = cn_dist(mouse_event.mouse_screen,mouse_event.camera.world_to_screen(this.handles[i]));
			if (d < this.handle_radius)
			{
				this._mouseover = i+2;
				return true;
			}
		}

		//** is mouse over center ?
		var d = cn_sub(mouse_event.mouse_world,this.object.center);
		const radius = (mouse_event.camera.is_3d())?this.world_radius:this.radius * mouse_event.camera.screen_to_world_scale;
		if (cn_size(d) < radius)
		{
			this._mouseover = 1;
			return true;
		}

		return false;
	}

    /**
     * Manage a grab. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
	 grab(mouse_event) {
		this._manage_3d(mouse_event);

		this._grabbed = false;
		this.move(mouse_event);
		if (this._mouseover === 0) return false;

		this._grab_mouse = cn_clone(mouse_event.mouse_world);
		this._grabbed = true;
		if (this._mouseover === 1)
		{
			this._grab_local = [0,0];
			this._grab_center = true;
			return true;
		}

		var handle = this.handles[this._mouseover - 2];
		this._grab_local = this.object.global_to_local(handle);
		this._grab_center = false;
		this._grabbed_handle = this._mouseover - 2;

		/** In that case, we don't allow drag and drop. */
		mouse_event.drag_and_drop_element = null;
		return true;
	}

    /**
     * During a drag and drop, the owner of the drag and drop may change.
     * Return true to accept owning the drag and drop events.
     * @param {cn_mouse_event} ev
     * @returns  {boolean}
     */
    grab_element(ev) {
		this._grabbed = true;
		this._grab_local = [0,0];
		this._grab_center = true;
		return true;
	}

    /**
     * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
	 drag(mouse_event) {
		this._manage_3d(mouse_event);
		if (!this._check_place(mouse_event)) return false;
		if (typeof(this.on_change) == 'function') this.on_change();
		return true;
	}

	//*****************************************************************
	/**
	 * Check a given place in the scene
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean} true if element can be placed here
	 */
	check_place(mouse_event) {
		this._grab_mouse = cn_clone(mouse_event.mouse_world);
		this._grabbed = true;
		this._grab_center = true;
		this._grab_local = [0,0];
		return this._check_place(mouse_event);
	}

	//*****************************************************************
	/**
	 * Check a given place in the scene
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean} true if element can be placed here
	 */
	_check_place(mouse_event) {
		///*** Compute the new position of the object, not taking into account collisions
		if (this._grab_center)
		{
			this.new_object.translate(cn_sub(mouse_event.mouse_world,this.object.local_to_global(this._grab_local)));
		}
		else
		{
			var a0 = cn_polar(cn_sub(this._grab_mouse,this.object.center))[1];
			var a1 = cn_polar(cn_sub(mouse_event.mouse_world,this.object.center))[1];
			console.log("angles",a0,a1);
			var da = a1-a0;
			while (da > Math.PI) da -= Math.PI*2;
			while (da < -Math.PI) da += Math.PI*2;
			this.new_object.rotate_local(this._grab_local,da);
			this.new_object.translate(cn_sub(mouse_event.mouse_world,this.object.local_to_global(this._grab_local)));
		}

		//*** update mouse position
		this._grab_mouse = cn_clone(mouse_event.mouse_world);

		//*** Compute collisions
		this._collisions = this._find_collisions(this.new_object);

		//*** end if no collisions
		if (this._collisions.length === 0)
		{
			this.object.copy(this.new_object);
			return true;
		}

		//*** Place tmp object at initial position
		var tmp_object = new cn_handler_object(0,0);
		tmp_object.copy(this.object);
		var dst = cn_dist(mouse_event.mouse_world,this.object.local_to_global(this._grab_local));
		if (dst < 0.001) return false;
		var precision = 1/(1000*dst);

		//*** wait for first collision
		this._collisions = this._expect_collisions(tmp_object,precision);

		//*** Maybe one single collision at first
		if (this._collisions.length === 1)
			this._manage_single_collision(this._collisions[0]);

		//*** Maybe several at once
		if (this._collisions.length > 1)
			this._manage_several_collisions();
		this.new_object.copy(this.object);

		//*** Maybe we moved to another space ?
		if (this.space == null || !this.space.contains(this._grab_mouse))
		{
			var previousspace = this.space;
			for (var i in this.scene.spaces)
			{
				if (!this.scene.spaces[i].contains(this._grab_mouse)) continue;
				this.space = this.scene.spaces[i];
				break;
			}
			this.new_object.translate(cn_sub(this._grab_mouse,this.object.local_to_global(this._grab_local)));
			if (this.space && this._find_collisions(this.new_object).length === 0)
			{
				this.object.copy(this.new_object);
			}
			else
			{
				this.new_object.copy(this.object);
				this.space = previousspace;
			}
		}

		return true;
	}

	//*****************************************************************
	//*** Manage one collision
	//*****************************************************************
	_manage_single_collision(rotation_center)
	{
		//*** The object is going to rotate around the first collision point
		var a0 = cn_polar(cn_sub(this.object.local_to_global(this._grab_local),rotation_center))[1];
		var a1 = cn_polar(cn_sub(this._grab_mouse,rotation_center))[1];
		var da = a1-a0;
		while (da > Math.PI) da -= Math.PI*2;
		while (da < -Math.PI) da += Math.PI*2;

		this.new_object.copy(this.object);
		this.new_object.rotate(rotation_center,da);

		//*** If rotation proceeded without collision, end of the job.
		if (this._find_collisions(this.new_object).length === 0)
		{
			this.object.copy(this.new_object);
			return [];
		}

		var da0 = 0;
		var da1 = da;
		var new_collisions = [];

		//*** We had a second collision. We march by dichotomy to find the instant before second collision.
		while (Math.abs(da1-da0) > this._angular_precision)
		{
			da = 0.5*(da0+da1);
			this.new_object.copy(this.object);
			this.new_object.rotate(rotation_center,da);
			var collisions = this._find_collisions(this.new_object);
			var actual_collisions = [];
			for (var n=0;n<collisions.length;n++)
			{
				if (cn_dist(collisions[n],rotation_center) > 0.01)
					actual_collisions.push(collisions[n]);
			}

			if (actual_collisions.length > 0)
			{
				new_collisions = actual_collisions;
				da1 = da;
			}
			else
				da0 = da;
		}

		//*** we stop at the second collision
		this._collisions = this._collisions.concat(new_collisions);
		this.new_object.copy(this.object);
		this.new_object.rotate(rotation_center,da0);
		this.object.copy(this.new_object);
	}

	//*****************************************************************
	//*** Manage several collision
	//*****************************************************************
	_manage_several_collisions()
	{
		var dir = cn_sub(this._collisions[1],this._collisions[0]);
		if (cn_normalize(dir) < 0.01) return;
		var delta = cn_sub(this._grab_mouse,this.object.local_to_global(this._grab_local));
		var x = cn_dot(dir,delta);
		if (Math.abs(x) < 0.001) return;
		this.new_object.copy(this.object);
		this.new_object.translate(cn_mul(dir,x));
		var tmp_object = new cn_handler_object(0,0);
		tmp_object.copy(this.object);
		this._expect_collisions(tmp_object,1/(1000*Math.abs(x)));
	}

    /**
     * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
	 drop(mouse_event) {
		this._grabbed = false;
		return true;
	}

	//*****************************************************************
	//*** Expect collisions : we march alont the object course until we have a collision
	//*****************************************************************
	_expect_collisions(object, precision)
	{
		var t0 = 0;
		var t1 = 1;
		var collisions = this._find_collisions(this.new_object);

		//*** We go by dichotomy
		while (t1-t0 > precision)
		{
			var tt = 0.5*(t0+t1);
			object.interpolate(tt,this.object,this.new_object);
			var cc = this._find_collisions(object);
			if (cc.length > 0)
			{
				collisions=cc;
				t1=tt;
			}
			else
				t0=tt;
		}

		//*** We go back to the first time before collision
		object.interpolate(t0,this.object,this.new_object);
		this.object.copy(object);
		return collisions;
	}

	//*****************************************************************
	//*** Update position
	//*****************************************************************
	_find_collisions(object){
		var collisions = [];
		if (this.space == null) return collisions;

		//*** find collisions where one object vertex gets out of space
		for (var k=0;k<object.vertices.length;k++)
		{
			if (!this.space.contains(object.vertices[k],true))
				collisions.push(cn_clone(object.vertices[k]));
		}

		//*** find collisions where one space vertex gets inside object
		for (var k=0;k<this.space.contours.length;k++)
		{
			var ctr = this.space.contours[k].inner_contour;
			for (var n in ctr)
			{
				if (object.contains(ctr[n]))
					collisions.push(cn_clone(ctr[n]));
			}
		}
		return collisions;
	}

	_manage_3d(mouse_event) {
		if (!mouse_event.camera.is_3d()) return;
		mouse_event.move_to_plane([0,0,this.altitude],[0,0,1]);
	}
}


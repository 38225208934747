"use strict";

export const refferer = {
    // Revêtements de mur
    "interior_wall_facing": {
        "type": {
            "codeBim": "type_revetement_de_mur",
            "type": {
                "concrete": "Brut",
                "brick": "Brut",
                "paint": "Peinture",
                "fibreglass": "Papier peint",
                "wall_tiles": "Carrelage",
                "wood_wall": 'Lambris',
                "carpet": 'Moquette',
            }
        },
    },
    // Revêtements de façade
    "exterior_wall_facing": {
        "type": {
            "codeBim": "type_revetement_de_facade",
            "type": {
                "concrete": "Brut",
                "brick": "Brut",
                "paint": "Enduit",
                "wood_facade": "Bardage bois",
                "alu_facade": "Bardage métal",
            }
        },
    },
    // Revêtements de sol
    "floor_facing": {
        "type": {
            "codeBim": "type_revetement_de_sol",
            "type": {
                "concrete": "Béton",
                "tiles": "Carrelage",
                "wood": "Parquet massif",
                "wood_tiles": "Parquet flottant",
                "carpet": "Moquette",
                "vinyl": "Lino",
            }
        },
    },
    // Revêtements de plafond
    "ceiling_facing": {
        "type": {
            "codeBim": "type_revetement_de_plafond",
            "type": {
                "concrete": "Béton",
                "paint": "Peinture",
                "fibreglass": "Fibre de verre",
                "wood_wall": "Lambris",
            }
        },
    },
    "space": {
        "perimeter": "perimetre",
        "height": "hauteur_sous_plafond"
    },
    "wall": {
        "structure": {
            "codeBim": "materiaux_structure",
            "type": {
                "concrete": "Béton",
                "block": "Parpaing",
                "brick": "Brique",
                "stone": "Pierre",
                "wood": "Bois"
            }
        },
        "u_value": "coefficient_u_parois",
        "insulation_width": "epaisseur_isolant",
        "insulation_width_2": "epaisseur_isolant_2",
        "insulation_material": {
            "codeBim": "materiaux_isolant",
            "type": {
                "insulator": "Isolant minéral",
                "wood_fiber": "Isolant végétal",
                "styrofoam": "Isolant synthétique",
                "air_layer": "Lame d'air",
            }
        },
        "insulation_material_2": "materiaux_isolant_2",
        "facing": {
            "codeBim": "parement_exterieur",
            "type": {
                "cement": "Enduit",
                "tiles": "Bardage",
                "ventilated_tiles": "Bardage ventilé"
            }
        },
        "light_structure": {
            "codeBim": "parement_interieur",
            "type": {
                "gypsum": "Plâtre"
            }
        },
        "thickness": "epaisseur_parois",
        "length": "longueur_parois",
        "height": "hauteur_parois"
    },
    "opening": {
        "thickness": "largeur_menuiseries",
        "height": "hauteur_menuiseries",
        "width": "longueur_menuiseries",
        "shift": "decalage_menuiseries",
        "area": "surface_menuiseries",
        "glazing_area": "surface_vitree_menuiseries",
        "frame": {
            "codeBim": "materiau_menuiseries",
            "type": {
                "alu": "Aluminium",
                "pvc": "PVC",
                "wood": "Bois",
                "steel": "Acier"
            }
        },
        "glazing": {
            "codeBim": "vitrage_menuiseries",
            "type": {
                "single": "Simple",
                "double": "Double standard",
                "triple": "Triple"
            }
        },
        "glazing_gaz": "lame_air_gaz_menuiseries",
        "glazing_thickness": "lame_air_epaisseur_menuiseries",
        "opening": {
            "codeBim": "type_ouverture_menuiseries",
            "type": {
                "sliding": "Coulissante",
                "french": "Battante",
                "vertical": "A guillotine"
            }
        },
        "panels": "nombre_panneaux",
        "transom": {
            "codeBim": "imposte_menuiseries",
            "type": {
                "none": "Aucun",
                "rectangle": "Rectangulaire",
                "round": "Arrondi",
                "triangle": "Triangulaire"
            }
        },
        "sill": {
            "codeBim": "allege_fenetre",
            "type": {
                "none": "Aucun",
                "glazed": "Vitrée",
                "opaque": "Opaque"
            }
        },
        "closing": {
            "codeBim": "volet_fenetre",
            "type": {
                "none": "Aucune",
                "rolling": "Roulant",
                "swing": "Battant",
                "blind": "Store"
            }
        },
        "uw": "uw_menuiseries",
        "sw": "facteur_solaire_menuiseries",
        "tl": "tl_menuiseries",
    },
    "balcony": {
        "thickness": "largeur_menuiseries",
        "width": "largeur_menuiseries",
        "height": "hauteur_menuiseries"
    },
    "beam": {
        "width": "largeur_poutre",
        "thickness": "longueur_poutre",
        "bbwidth": "largeur_bb_structure",
        "bblength": "longueur_bb_structure",
        "bbheight": "hauteur_bb_structure",
        "material": {
            "codeBim": "materiaux_poutre",
            "type": {
                "concrete": "béton",
                "wood": "bois",
                "steel": "métal"
            }
        },
        "shape": {
            "codeBim": "forme_poutre",
            "type": {
                "rectangle": "Rectangulaire",
                "circle": "Circulaire"
            }
        }
    },
    "pipe": {
        "length": "longueur",
        "diameter": "diametre",
        "material": {
            "codeBim": "reseau_materiau",
            "type": {
                "pvc": "PVC",
                "copper": "Cuivre",
                "per": "PER",
                "other": "Autre",
            }
        },
    },
    "stair": {
        "stairHeight": "hauteur_contre_marche_escalier",
        "stairNumber": "nombre_de_marches_escalier",
        "stairDepth": "profondeur_marche"
    },
    "slab": {
        "structure": {
            "codeBim": "materiaux_structure",
            "type": {
                "concrete": "Béton",
                "block": "Parpaing",
                "brick": "Brique",
                "stone": "Pierre",
                "wood": "Bois"
            }
        },
        "thickness": "epaisseur_parois",
        "width": "largeur_parois",
        "length": "longueur_parois"
    },
    "roof": {
        "area": "surface_charpente",
        "thickness": "epaisseur_charpente"
    },
    "object": {
        "bbheight": "hauteur_bb_equipements",
        "bbwidth": "largeur_bb_equipements",
        "bblength": "longueur_bb_equipements",
    }
};

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** A handler to manipulate a rectangle (creation / edition)
//***********************************************************************************
//***********************************************************************************

import {cn_add, cn_box, cn_clone, cn_mul, cn_normalize, cn_project_segment, cn_sub} from "../utils/cn_utilities";
import {cn_snap} from "./cn_snap";
import {cn_pastille} from "./cn_pastille";
import {cn_space} from "../model/cn_space";
import {cn_scene} from "../model/cn_scene";
import {cn_scene_controller} from "./cn_scene_controller";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_event_handler} from "./cn_event_handler";

export class cn_rectangle_handler extends cn_event_handler{
	/**
	 * Constructor
	 * @param {boolean} creation_mode : creation mode or edition mode
	 * @param {cn_scene} scene
	 * @param {cn_scene_controller} controller
	 */
	constructor(creation_mode, scene, controller) {
		super();
		/*** Geometry */
		this.start = [0,0];
		this.size = [1,1];
		this.angle=0;

		/** Creation mode */
		this._creation = (creation_mode)?0:-1;

		this.space = null;
		this._scene = scene;
		this._controller = controller;

		this._mousedown = [0,0];
		this._selected_vertex = -1;
		this._mouseover_vertex = -1;
		this._mouseover_edge = null;

		this._vertex_remove = null;
		this._center = new cn_pastille([0, 0], "arrow_all_white.svg");
		this._check_creation = new cn_pastille([0, 0], "check_white.svg");
		this._check_creation.svg_class='pastille_check';

		this._snap_svg = "";

		this.update();
		this.on_change = null;
		this.on_delete = null;
		this._delete = null;

		this._grab = false;

        this.check_change = null;
        this.closed_contour = false;
        this.accept_outside = false;
	}

	//*****************************************************************
	//*** is polygon currently creating ?
	//*****************************************************************
	is_creating() {
		return (this._creation>=0);
	}

	//*****************************************************************
	//*** Draw the handler
	//*****************************************************************
	draw(camera) {
		var html = "";
		if (this._creation && this.space == null) return html;

		var add_classes = "";

		//*** draw contour
		html += "<path class='handle_outline" + add_classes + "' d='";
		for (var i=0; i<this.vertices.length;i++)
		{
			var p = camera.world_to_screen(this.vertices[i]);
			if (i == 0) html += "M";
			else html += "L";
			html += " " + p[0] + " " + p[1] + " ";
		}
		if (this.closed_contour) html += "Z";
		html += "' />";

		//*** Draw vertices
		for (var i=0; i<this.vertices.length;i++)
		{
			add_classes = "";
			if (this._selected_vertex == i)
				add_classes = " selected";
			else if (this._mouseover_vertex == i && this._mouseover_edge == null)
				add_classes = " mouseover";
			var p = camera.world_to_screen(this.vertices[i]);
			html += "<circle class='handle_vertex" + add_classes + "' cx='" + p[0] + "' cy='" + p[1] + "' r='5'/>";
		}

		//*** draw new vertex pointer
		if (this._mouseover_vertex>= 0 && this._mouseover_edge)
		{
			var p = camera.world_to_screen(this._mouseover_edge);
			html += "<circle class='handle_vertex action' cx='" + p[0] + "' cy='" + p[1] + "' r='10'/>";
			html += "<text class='pastille_text' x='" + p[0] + "' y='" + p[1] + "'>+</text>";
		}

		if (this._vertex_remove)
			html += this._vertex_remove.draw(camera);

		//**** Creation only : draw check pastille_delete
		this._check_creation.visible = false;
		if (this._creation)
		{
			if (this.vertices.length > 3 || (this.vertices.length > 2 && !this.closed_contour))
			{
				this._check_creation.visible = true;
				var pos = [0,0];
				for (var i =0;i<this.vertices.length-1;i++) pos = cn_add(pos,this.vertices[i]);
				pos = cn_mul(pos,1/(this.vertices.length-1));
				this._check_creation.position = pos;
				html += this._check_creation.draw(camera);
			}
		}
		else
		{
			//*** draw remove
			if (this.on_delete)
			{
				if (this._delete == null)
				{
					this._delete = new cn_pastille(this._center.position, "close_white.svg");
					this._delete.svg_class='pastille_delete';
					this._delete.offset = [30,0];
				}
				html += this._delete.draw(camera);
				this._center.offset = [-30,0];
			}
			else
				this._center.offset = [0,0];

			//*** draw center
			html += this._center.draw(camera);
		}

		html += this._snap_svg;
		return html;
	}

	//*****************************************************************
	//*** Clear move data
	//*****************************************************************
	clear_move() {
		this._mouseover_vertex = -1;
		this._mouseover_edge = null;

		this._center.mouseover = false;
		if (this._delete)
			this._delete.mouseover = false;
		this._check_creation.mouseover = false;
		if (this._vertex_remove)
			this._vertex_remove.mouseover = false;

		if (this._creation && this.vertices.length < 2)
			this.space = null;
	}

	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} ev
	 * @returns  {boolean}
	 */
	move(ev) {
		this.clear_move();
		this.find_space(ev.mouse_world);
		if (this._creation && this.space == null) return false;

		//*** Creation mode
		if (this._creation)
		{
			this._mouseover_vertex = this.vertices.length-1;
			this._selected_vertex = this.vertices.length-1;
			if (this._check_creation.visible)
			{
				this._check_creation.mouseover = this._check_creation.contains(ev.mouse_world,ev.camera);
				if (this._check_creation.mouseover) return true;
			}

			return this.drag(ev);
		}


		this._center.mouseover = this._center.contains(ev.mouse_world,ev.camera);
		if (this._center.mouseover) return true;

		if (this._delete)
		{
			this._delete.mouseover = this._delete.contains(ev.mouse_world,ev.camera);
			if (this._delete.mouseover) return true;
		}

		if (this._vertex_remove)
		{
			this._vertex_remove.mouseover = this._vertex_remove.contains(ev.mouse_world,ev.camera);
			console.log("vertex remove:",this._vertex_remove.mouseover)
			if (this._vertex_remove.mouseover) return true;
		}

		//*** Check if mouse is over a vertex
		for (var i=0; i<this.vertices.length;i++)
		{
			if (!ev.camera.on_vertex(ev.mouse_world,this.vertices[i]))
				continue;
			this._mouseover_vertex = i;
			break;
		}

		//*** Check if mouse is over an edge
		if (this._mouseover_vertex < 0)
		{
			for (var i=0; i<this.vertices.length-1;i++)
			{
				if (!ev.camera.on_edge(ev.mouse_world,this.vertices[i],this.vertices[i+1]))
					continue;
				this._mouseover_vertex = i;
				this._mouseover_edge = cn_project_segment(ev.mouse_world,this.vertices[i],this.vertices[i+1]);
				break;
			}
			if (this._mouseover_vertex < 0 && this.closed_contour)
			{
				if (ev.camera.on_edge(ev.mouse_world,this.vertices[this.vertices.length-1],this.vertices[0]))
				{
					this._mouseover_vertex = this.vertices.length-1;
					this._mouseover_edge = cn_project_segment(ev.mouse_world,this.vertices[this.vertices.length-1],this.vertices[0]);
				}
			}
		}


		if (this._mouseover_vertex >= 0 || this._mouseover_edge) return true;
		return false;
	}

	/**
	 * Manage a grab. To return 'true' if grab is to be managed.
	 * @param {cn_mouse_event} ev
	 * @returns  {boolean}
	 */
	grab(ev) {
		this.find_space(ev.mouse_world);
		if (this._creation && this.space == null) return false;

		this._grab = true;

		//*** Maybe end of creation by checking ?
		if (this._creation)
		{
			if (this._check_creation.visible && this._check_creation.mouseover)
			{
				this.vertices.splice(this.vertices.length-1);
				this._creation = 0;
				this.update();
				return true;
			}
			return true;
		}

		this.move(ev);

		this._mousedown = cn_clone(ev.mouse_world);

		//*** maybe we move the shape ?
		if (this._center.mouseover)
		{
			this._vertex_remove = null;
			this._selected_vertex = -1;
			return true;
		}

		//*** maybe we delete the shape ?
		if (this._delete && this._delete.mouseover)
		{
			this.on_delete();
			return true;
		}

		//*** maybe we remove the vertex ?
		if (this._vertex_remove && this._vertex_remove.mouseover)
		{
			this.vertices.splice(this._selected_vertex,1);
			this._mouseover_vertex = -1;
			this._selected_vertex = -1;
			this._vertex_remove = null;
			this.update();
			return true;
		}

		//*** Maybe a new point ?
		if (this._mouseover_vertex >= 0 && this._mouseover_edge)
		{
			this._mouseover_vertex++;
			this.vertices.splice(this._mouseover_vertex,0,this._mouseover_edge);
			this._mouseover_edge = null;
			this.update();
		}

		this._selected_vertex = this._mouseover_vertex;
		if (this._selected_vertex >= 0 && (this.vertices.length > 3 || (this.vertices.length > 2 && !this.closed_contour)))
		{
			this._vertex_remove = new cn_pastille(this.vertices[this._selected_vertex],"-");
			this._vertex_remove.small = true;
			this._vertex_remove.selected = true;
			this._vertex_remove.offset = [40,0];
		}
		else
			this._vertex_remove = null;

		return (this._selected_vertex >= 0);
	}

	/**
	 * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
	 * @param {cn_mouse_event} ev
	 * @returns {boolean}
	 */
	drop(ev) {
		this.find_space(ev.mouse_world);
		if (this._creation && this.space == null) return false;
		this._snap_svg = "";
		if (!this._grab) return false;
		this._grab = false;

		if (!this._creation) return false;

		//*** is last point on a given vertex ?
		var dropped_vertex = -1;
		for (var i=0; i<this.vertices.length -1;i++)
		{
			if (!ev.camera.on_vertex(ev.mouse_world,this.vertices[i]))
				continue;
			dropped_vertex = i;
			break;
		}

		//*** nothing, we continue the contour
		if (dropped_vertex < 0)
		{
			var last_vertex = cn_clone(this.vertices[this.vertices.length-1]);
			this.vertices.push(last_vertex);
			return true;
		}

		//*** End condition
		if (this.closed_contour)
		{
			//*** we must go back to first or last vertex
			if ((dropped_vertex != 0 && dropped_vertex != this.vertices.length -2) || this.vertices.length < 4)
				return false;
		}
		else
		{
			//*** we must reselect last vertex.
			if (dropped_vertex != this.vertices.length -2 || this.vertices.length < 3)
				return false;
		}

		this.vertices.splice(this.vertices.length-1);
		this._creation = 0;
		this.update();
		return true;
	}

	/**
	 * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
	 * @param {cn_mouse_event} ev
	 * @returns  {boolean}
	 */
	 drag(ev) {
		this.find_space(ev.mouse_world);
		if (this._creation && this.space == null) return false;

		//*** Move the shape
		if (this._center.mouseover)
		{
			var offset = cn_sub(ev.mouse_world,this._center.position);
			this._mousedown = ev.mouse_world;
			var old_vertices = this.vertices;
			this.vertices = [];
			for (var i=0;i< old_vertices.length;i++)
				this.vertices.push(cn_add(offset,old_vertices[i]));

			if (this.check_change && !this.check_change())
				this.vertices = old_vertices;
			else
				this.update();

			return true;
		}

		if (this._selected_vertex < 0) return false;

		//*** Compute points around
		var points = [null,null,null,null,null];
		var sz = this.vertices.length;
		for (var k=0;k<5;k++)
		{
			var i = this._selected_vertex - 2 + k;
			points[k] = this.vertices[(i+sz)%sz];
		}

		this._snap_svg = "";
		var snap = new cn_snap(ev.mouse_world,ev.camera.snap_world_distance,null,ev.camera);

		//*** in creation mode, snap on first and last vertices
		if (this._creation)
		{
			if (this.closed_contour)
			{
				if (this.vertices.length > 3)
					snap.check_point(this.vertices[0]);
			}
			else
			{
				if (this.vertices.length >= 3)
					snap.check_point(this.vertices[this.vertices.length-2]);
			}
		}

		//*** try to snap on space bounds
		if (this.space)
			snap.check_space(this.space,true);
		else if (this._scene)
		{
			var space = this._scene.find_space(ev.mouse_world);
			if (space && (!this.accept_outside && !space.outside))
				snap.check_space(space,true);
		}

		if (this.vertices.length < 2)
		{
			points[2][0] = snap.position[0];
			points[2][1] = snap.position[1];
			this._snap_svg = snap.svg;
			this.update();
			if (this.check_change) this.check_change();
			return true;
		}

		//*** try to snap on self
		if (this.vertices.length > 2)
		{
			if (points[0] && points[1] && snap.freedoms > 0)
			{
				snap.previous_point = points[1];
				snap.check_angles(points[0],points[1],45);
			}
			if (points[3] && points[4] && snap.freedoms > 0)
			{
				snap.previous_point = points[3];
				snap.check_angles(points[3],points[4],45);
			}
		}

		if (!this.closed_contour)
		{
			for (var k=0;k<5;k++)
			{
				var i = this._selected_vertex - 2 + k;
				if (i < 0 || i >= sz)
					points[k] = null;
			}
		}
		//*** Check that point remains inside the space
		if (this.space)
		{
			if (points[1])
			{
				var dir = cn_sub(snap.position,points[1]);
				var max_distance = cn_normalize(dir) - 0.001;
				if (max_distance > 0.002 && this.space.raytrace(points[1], dir, max_distance, true))
					return false;
			}
			if (points[3])
			{
				var dir = cn_sub(snap.position,points[3]);
				var max_distance = cn_normalize(dir) - 0.001;
				if (max_distance > 0.002 && this.space.raytrace(points[3], dir, max_distance, true))
					return false;
			}
		}
		var point_before = cn_clone(points[2]);
		points[2][0] = snap.position[0];
		points[2][1] = snap.position[1];
		if (this.check_change)
		{
			if (!this.check_change())
			{
				points[2][0] = point_before[0];
				points[2][1] = point_before[1];
				return false;
			}
		}
		this._snap_svg = snap.svg;
		this.update();
		return true;
	}

	//*****************************************************************
	//*** Update position
	//*****************************************************************
	update() {
		if (this._creation) return;
		var bb = new cn_box(this.vertices);
		this._center.position = cn_add(bb.posmin,cn_mul(bb.size,0.5));
		if (this._delete)
			this._delete.position = this._center.position;
		if (this.on_change) this.on_change();
	}

	//*****************************************************************
	//*** find space
	//*****************************************************************
	find_space(mouse_world_position) {
		if (!this._creation) return;
		if (this.vertices.length > 1) return;

		//** expect to be inside a inner space
		this.space = this._scene.find_space(mouse_world_position);
		if (this.space && (!this.accept_outside && this.space.outside))
			this.space = null;

		//*** expect to have nothing below
		var elt = this._controller.find_element(mouse_world_position);
		if (elt && elt.constructor != cn_space)
			this.space = null;
	}

	//*****************************************************************
	//*** check new position
	//*****************************************************************
	_check() {
		if (this.space == null) return true;

		for (var i=0; i < this.vertices.length;i++)
		{
			if (!this.space.contains(this.vertices[i]))
				return false;
		}

		for (var i=0; i < this.vertices.length-1;i++)
		{
			var dir = cn_sub(this.vertices[i+1],this.vertices[i]);
			var max_distance = cn_normalize(dir);
			if (this.space.raytrace(this.vertices[i], dir, max_distance, true))
				return false;
		}
		if (this.closed_contour)
		{
			var dir = cn_sub(this.vertices[0],this.vertices[this.vertices.length-1]);
			var max_distance = cn_normalize(dir);
			if (this.space.raytrace(this.vertices[i], dir, max_distance, true))
				return false;
		}

		//*** User check
		if (this.check_change && !this.check_change())
			return false;

		return true;
	}
}


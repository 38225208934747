import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BaseComponent,
    ConfirmationService,
    FileUploaderComponent,
    FileUtils,
    NotificationService,
} from 'src/app/commons-lib';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Cofrac } from 'src/app/model/cofrac.model';
import { EditMode } from 'src/app/model/edit-mode.model';
import { typesPrestation } from 'src/app/model/type-prestation.model';
import { UserWizy } from 'src/app/model/user-wizy.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { CofracService } from 'src/app/services/cofrac.service';
import { CofracApiService } from 'src/app/services/cofrac-api.service';
import { URL_GESTION_COFRACS, URL_GESTION_COFRACS_EDIT } from 'src/app/shared/constants/url.constants';

@Component({
    selector: 'app-creation-cofrac',
    templateUrl: './creation-cofrac.component.html',
    styleUrls: ['./creation-cofrac.component.scss'],
})
export class CreationCofracComponent extends BaseComponent implements OnInit {
    private idCofrac: string;

    formCofrac: FormGroup;
    cofrac: Cofrac;
    readonlyMode = false;
    mode: EditMode = 'CREATE';
    editPicture = false;
    typePrestationsPossible = typesPrestation.filter((typePrestationTemp) => typePrestationTemp !== 'COMMUN');

    documentCofracFormData: FormData;
    readonly codeTypeDocument = 'DOCUMENT_COFRAC';
    readonly documentTitle = 'Document cofrac';

    @ViewChild('fileUploaderLogo') fileUploaderLogo: FileUploaderComponent;

    displayUserItems = (item: UserWizy) => {
        return `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`.trim();
    };

    get documentCofracControl() {
        return this.formCofrac.get('documentCofrac') as FormControl;
    }

    constructor(
        private readonly cofracService: CofracService,
        private readonly cofracApiService: CofracApiService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly formBuilder: FormBuilder,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService
    ) {
        super();
        this.createForm();
    }

    ngOnInit(): void {
        // Initialise les données (récupération du cofrac)
        // Puis initialise le formulaire
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),

                    switchMap(([params, data]) => {
                        let observables$: Observable<any>;

                        this.idCofrac = params.get('idCofrac');
                        if (this.idCofrac) {
                            this.mode = 'EDIT';
                            this.readonlyMode = data.consulter;
                            observables$ = this.getCofrac();
                        } else {
                            this.cofrac = new Cofrac();
                            observables$ = of(this.cofrac);
                        }

                        return observables$;
                    }),
                    tap(() => {
                        // Initialise le formulaire avec les données
                        this.populateForm(this.cofrac);
                    })
                )
            )
            .subscribe();
    }

    /**
     * Récupère les données du cofrac courant
     */
    getCofrac(): Observable<Cofrac> {
        return this.cofracService.findOne(this.idCofrac).pipe(
            map((currentCofrac) => {
                if (currentCofrac) {
                    this.cofrac = currentCofrac;
                }
                return currentCofrac;
            })
        );
    }

    /**
     * Crée le formulaire de Cofrac
     */
    createForm(): void {
        this.formCofrac = this.formBuilder.group({
            nom: ['', [Validators.required]],
            numero: ['', [Validators.required]],
            documentCofrac: [null, Validators.required],
            dateDebutValidite: ['', [Validators.required]],
            dateEcheance: [''],
            typePrestations: [[], [Validators.required]],
        });
    }

    /**
     * Rempli le fomulaire avec les données de cofrac courant
     * @param cofrac
     */
    populateForm(cofrac: Cofrac): void {
        if (cofrac) {
            this.formCofrac.patchValue({
                nom: cofrac.nom,
                numero: cofrac.numero,
                documentCofrac: cofrac.documentCofrac,
                dateDebutValidite: cofrac.dateDebutValidite,
                dateEcheance: cofrac.dateEcheance,
                typePrestations: cofrac.typePrestations,
            });
        }
    }

    /**
     * Prépare les données du formulaire pour la sauvegarde
     */
    prepareSaveEntity(): Cofrac {
        this.cofrac.nom = this.formCofrac.value.nom;
        this.cofrac.numero = this.formCofrac.value.numero;
        this.cofrac.dateDebutValidite = this.formCofrac.value.dateDebutValidite;
        this.cofrac.dateEcheance = this.formCofrac.value.dateEcheance;
        this.cofrac.typePrestations = this.formCofrac.value.typePrestations;

        // document
        this.cofrac.documentCofrac = this.formCofrac.value.documentCofrac;

        // Autres :
        return this.cofrac;
    }

    /**
     * Action lorsque l'utilisateur envoie le formulaire
     */
    onSubmit(): void {
        if (this.formCofrac.valid) {
            // Prépare les données à sauvegarder
            this.prepareSaveEntity();
            this.uploadData();
        }
    }

    uploadData() {
        // Mise à jour des données
        if (this.mode === 'EDIT') {
            this.cnSpinnerService
                .withSpinner(this.cofracService.updateCofracOnline(this.cofrac))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((cofracUpdated) => {
                    // Gestion du logo
                    if (this.cofrac.logoImage) {
                        this.cofracApiService.uploadLogo(cofracUpdated.id, this.cofrac.logoImage).subscribe();
                    }
                    this.navigateBack();
                });
        } else {
            // Ou création des données
            this.cnSpinnerService
                .withSpinner(this.cofracService.createCofrac(this.cofrac))
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((cofracCreated) => {
                    // Gestion du logo
                    if (this.cofrac.logoImage) {
                        this.cofracApiService.uploadLogo(cofracCreated.id, this.cofrac.logoImage).subscribe();
                    }
                    this.navigateBack();
                });
        }
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton "editer"
     */
    onClickEdit(): void {
        this.router.navigate([URL_GESTION_COFRACS_EDIT, this.cofrac.id]);
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton "retour"
     */
    onClickBack(): void {
        this.navigateBack();
    }

    /**
     * Renvoie vers la liste des cofracs
     */
    navigateBack(): void {
        this.router.navigate([URL_GESTION_COFRACS]);
    }

    /************************ Gestion du logo ***********************/

    onClickUploadLogo(logo: FormData) {
        const reader = new FileReader();
        reader.readAsDataURL(logo.get('file') as File);
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                this.cofrac.logoImage = logo;
                this.fileUploaderLogo.handleUploadFileServiceCall(this.recupUrlImage());
            };
        };
    }

    recupUrlImage(): Observable<HttpEvent<any>> {
        FileUtils.fileToDataURI(this.cofrac.logoImage.get('file') as File).subscribe((url) => {
            this.cofrac.logoUrl = url;
        });
        return of(new HttpResponse());
    }

    uploadFileFinish(typeDocument: string, success: boolean) {
        if (success) {
            this.notificationService.success('Le ' + typeDocument + ' a bien été chargé.');
            this.editPicture = false;
        }
    }

    onClickDeleteLogo() {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer ce logo ?', () => {
            this.cofrac.logoImage = undefined;
            this.cofrac.logoUrl = undefined;
        });
    }
    /************************ Fin gestion du logo ***********************/
}

import { Component, OnInit } from '@angular/core';
import {
    compareChapitre,
    comparePointControle,
    nonOption,
    nonVerifiabledOption,
} from '../../../shared/electricite.constants';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { ElectriciteService } from '../../../services/electricite.service';
import {
    Chapitre,
    Electricite,
    ICheckpoints,
    Photo,
    PointControle,
    PointControleAide,
    ValeurCheckpoint,
} from '../../../model/electricite.model';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { apartmentState, houseState } from '../../../../../../shared/constants/states.constants';
import { CheckpointHelpModalComponent } from '../../checkpoint-help-modal/checkpoint-help-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { TypeCommentaire } from '../../../../../../model/type-commentaire.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { takeUntil } from 'rxjs/operators';
import { CommentModalComponent } from '../../../../../shared/comment-modal/comment-modal.component';
import { DialogUtils } from '../../../../../../utils/dialog.utils';

@Component({
    selector: 'app-autres-constatations',
    templateUrl: './autres-constatations.component.html',
})
export class AutresConstatationsComponent extends BaseComponent implements OnInit {
    readonly NON = nonOption.value;
    readonly NON_VERIFIABLE = nonVerifiabledOption.value;
    readonly COMMENT_TYPES: TypeCommentaire[] = ['NOTE_PERSONNELLE', 'REMARQUE'];
    readonly navItems: { route: string; name: string }[] = [
        { route: 'installations-particulieres', name: 'Installations particulières' },
        { route: 'informations-complementaires', name: 'Informations complementaires' },
    ];
    public activeItem: string;
    chapitres: ICheckpoints[] = []; // chapitres to display
    diagnostic: Diagnostic;
    private _pointsControlesAides: PointControleAide[];
    private _photos: Photo[];
    private _pointsControles: PointControle[];
    private _chapitres: Chapitre[]; // chapitres config
    private _isMaison: boolean;
    private _isAppartement: boolean;

    constructor(
        private diagnosticService: DiagnosticService,
        private electriciteService: ElectriciteService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        combineLatest([this.electriciteService.electriciteConfig$, this.diagnosticService.getCurrentDiagnostic()])
            .pipe(first())
            .subscribe(([config, diagnostic]) => {
                this.diagnostic = diagnostic;
                const ontenuDiagnostic = diagnostic.contenuDiagnostic as Electricite;
                this._isMaison = ontenuDiagnostic.typeBien.valeur == houseState.value;
                this._isAppartement = ontenuDiagnostic.typeBien.valeur == apartmentState.value;

                this._chapitres = config.chapitres;
                this._pointsControlesAides = config.pointsControlesAides;
                this._photos = config.photos;
                this._pointsControles = config.pointsControles.filter((ch) => this.filtreCheckpoint(ch));
            });
        this.route.url.pipe(takeUntil(this.ngUnsubscribe)).subscribe((params) => {
            this.initChapitreToDisplay(
                !!this.router.url.match(this.navItems[0].route) ? this.navItems[0].route : this.navItems[1].route
            );
        });
    }

    private filtreCheckpoint(checkpoint: PointControle): boolean {
        const valeur: ValeurCheckpoint =
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[checkpoint.idInterne];
        return valeur ? valeur.valeur == this.NON : false;
    }

    public initChapitreToDisplay(activeRoute: string) {
        this.activeItem = activeRoute;
        this.chapitres = [];
        let ptcResteAverify = [];
        if (this.activeItem == this.navItems[0].route) {
            // 1) En Immeuble collectif
            // 2) Calcul correspondance DDR et résistance de terre
            ptcResteAverify = this._pointsControles.filter(
                (chp) => chp.idChapitre == 'CH32' || chp.idChapitre == 'CH44' || chp.idChapitre == 'CH11'
            );
            if (this._isAppartement) {
                ptcResteAverify = [
                    ...ptcResteAverify,
                    ...this._pointsControles.filter((chp) => chp.idChapitre == 'CH3' || chp.idChapitre == 'CH46'),
                ];
            }
        } else {
            ptcResteAverify = this._pointsControles.filter(
                (chp) => chp.idChapitre == 'CH4' || chp.idChapitre == 'CH18' || chp.idChapitre == 'CH1'
            );
        }
        for (const ptc of ptcResteAverify) {
            this.addPointControle(ptc);
        }
        // Sort
        this.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
        for (const tr of this.chapitres) {
            tr.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
            for (const val of tr.chapitres) {
                val.pointsControles.sort(comparePointControle);
            }
        }
    }

    private addPointControle(ckp: PointControle) {
        if (!ckp.idChapitre) return;
        const ssChapitre = this._chapitres.find((chap) => chap.idInterne == ckp.idChapitre);
        const chapitre: Chapitre = this._chapitres.find((chap) => chap.idInterne == ssChapitre.idChapitreParent);
        const indexChapitre = this.chapitres.findIndex((tr) => tr.chapitre.id == chapitre.id);

        if (indexChapitre < 0) {
            this.chapitres.push({
                chapitre: chapitre,
                chapitres: [{ chapitre: ssChapitre, pointsControles: [ckp] }],
            });
        } else {
            // Le chapitre existe
            const indexSsChapitre = this.chapitres[indexChapitre].chapitres.findIndex(
                (val) => val.chapitre.id == ssChapitre.id
            );
            if (indexSsChapitre < 0) {
                this.chapitres[indexChapitre].chapitres.push({ chapitre: ssChapitre, pointsControles: [ckp] });
            } else {
                this.chapitres[indexChapitre].chapitres[indexSsChapitre].pointsControles.push(ckp);
            }
        }
    }

    openHelp(checkpoint: any) {
        const contents = [];
        this._pointsControlesAides
            .filter((pca) => pca.idPointControle == checkpoint.idInterne)
            .sort((a, b) => a.ordre - b.ordre)
            .forEach((pca) => {
                const photo = this._photos.find((ph) => ph.idInterne == pca.idPhoto);
                contents.push({ innerHTML: pca.description, photo: photo });
            });
        if (contents) {
            const dialogRef = this.matDialog.open(CheckpointHelpModalComponent);
            dialogRef.componentInstance.title = checkpoint.description;
            dialogRef.componentInstance.contents = contents;
        } else {
            this._snackBar.open("Aucune aide n'est disponible pour ce point de contrôle", '', { duration: 5000 });
        }
    }

    aJustifier(pointsControles: PointControle[]) {
        if (pointsControles && pointsControles.length) {
            return pointsControles.filter(
                (pc) =>
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[pc.idInterne].valeur === this.NON &&
                    !this.diagnostic.pointsDeControleBiens[0].valeursParametres[pc.idInterne].commentairesId.length
            );
        }
        return [];
    }
    addJustificationForAll(pointsControles: PointControle[]) {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    replaceTypesCommentaires: true,
                    typesCommentaires: this.COMMENT_TYPES,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.aJustifier(pointsControles).forEach((pc) => {
                        this.diagnostic.pointsDeControleBiens[0].valeursParametres[pc.idInterne].commentairesId =
                            result.commentairesId;
                    });
                }
            });
    }
    addJustification(idInternePC: string) {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    replaceTypesCommentaires: true,
                    commentairesId:
                        this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInternePC].commentairesId,
                    typesCommentaires: this.COMMENT_TYPES,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInternePC].commentairesId =
                        result.commentairesId;
                }
            });
    }
}

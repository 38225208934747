import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MongoUtils } from 'src/app/commons-lib';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import { ReferenceService } from 'src/app/services/reference.service';
import {
    PiecePreequipeeConfig,
    TypeElementAControler,
    TypeVolume,
} from '../../../../model/type-element-a-controler.model';

@Component({
    selector: 'app-add-custom-preequipped-room-modal',
    templateUrl: './add-custom-preequipped-room-modal.component.html',
    styleUrls: ['./add-custom-preequipped-room-modal.component.scss'],
})
export class AddCustomPreequippedRoomModalComponent implements OnInit {
    roomToEdit = new TypeVolume();
    // confName: string;
    equipments: TypeElementAControler[] = [];
    equipmentsToDisplay: string[] = [];
    newEquipment = '';
    roomEquipmentsNames: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<AddCustomPreequippedRoomModalComponent>,
        private referenceService: ReferenceService
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.roomToEdit.piecesPreequipeesConfigs.push(new PiecePreequipeeConfig());
        this.dialogRef.addPanelClass('minwidth600-dialog');
        this.referenceService.findAllTypesElementsAControler().subscribe((equip) => {
            this.equipments = equip.filter((elem) => elem.etatEquipement === EtatWorkflow.ACTIF);
            this.equipmentsToDisplay = this.equipments.map((e) => e.nom);
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.roomToEdit.id = MongoUtils.generateObjectId();
        this.dialogRef.close({ room: this.roomToEdit });
    }

    addEquipment(newEquipmentName: string) {
        this.roomEquipmentsNames.push(newEquipmentName);
        const equipmentToAdd = this.equipments.find((e) => e.nom == newEquipmentName);
        this.roomToEdit.piecesPreequipeesConfigs[0].idsTypesElementsAControler.push(equipmentToAdd.id);

        this.newEquipment = '';
    }

    deleteEquipment(equipmentToDelete: string) {
        this.roomEquipmentsNames.splice(this.roomEquipmentsNames.indexOf(equipmentToDelete), 1);

        const indexToDelete = this.roomToEdit.piecesPreequipeesConfigs[0].typesElementsAControlerAssocies.findIndex(
            (it) => it.nom === equipmentToDelete
        );
        this.roomToEdit.piecesPreequipeesConfigs[0].idsTypesElementsAControler.splice(indexToDelete, 1);
    }
}

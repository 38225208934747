"use strict";
import { cn_clone, cn_pastille_facing, cn_polygon_handler, cn_storey, cn_svg_map, cn_view_overlay } from "..";
import { cn_facing_trimming } from "../model/cn_facing_trimming";
import { cn_vertex } from "../model/cn_vertex";
import { cn_event_manager } from "./cn_event_manager";
import { cn_edition_handler } from "./cn_edition_handler";
import { cn_edit_box } from "./cn_edit_box";

export class cn_facing_trimming_handler extends cn_edition_handler {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {Array<cn_facing_trimming>} facing_trimmings
	 * @param {cn_svg_map | cn_view_overlay} parent
	 * @param {cn_storey} storey
	 */
	constructor(facing_trimmings, parent, storey) {
		super(facing_trimmings,parent);

		this._map = (parent.constructor == cn_svg_map)?parent:null;
		this._view_overlay = (parent.constructor == cn_view_overlay)?parent:null;
		var transaction_manager = parent._building.transaction_manager;

		this.facing_trimming = (facing_trimmings.length ==  1)?facing_trimmings[0]:null;
		this._polygon_handler = null;
		this.drawing_storey = (storey)?storey:(this._map)?this._map._storey:null;
		if (this.facing_trimming)
		{
			const polygon_handler = new cn_polygon_handler(this,this.facing_trimming.get_shape_3d(this.drawing_storey),true);
			this._handlers.push(polygon_handler);
			this._polygon_handler = polygon_handler;
			
			const scene = this.drawing_storey.scene;
			
			polygon_handler.snap_elements = scene.spaces;

			var obj = this;
			obj._pending_changes = false;

			polygon_handler.on("change",function(){
				transaction_manager.push_transaction("Modification de découpe de revêtement",obj.facing_trimming.ID,function(){scene.update();scene.update_deep();});
				transaction_manager.push_item_set(obj.facing_trimming,"shape");

				obj.facing_trimming.set_shape_3d(polygon_handler.vertices,obj.drawing_storey);
				obj._pending_changes = true;
				return true;
			});
			polygon_handler.on("force_update",function(){obj._force_update();});
		}

		this._edit_box = new cn_edit_box(this,facing_trimmings,false);
		if (this.facing_trimming)
		{
			const pastille = new cn_pastille_facing(parent, this.facing_trimming);
			this._edit_box.add_pastille(pastille);
			if (this._view_overlay)
			{
				pastille.on("change",() => {
					this._view_overlay.refresh_3d();
					this._view_overlay.refresh();
					this.call("change");
					return true;
				});
			}
		}
		this._edit_box.add_lock_pastille(transaction_manager);
		this._handlers.push(this._edit_box);
	}

	draw(camera) {
		if (this._polygon_handler)
		{
			this._polygon_handler.visible = this._polygon_handler.active = (!this.facing_trimming.locked);
		}
		return super.draw(camera);
	}

	move (mouse_event) {
		if (this._pending_changes) this._force_update();
		return super.move(mouse_event);
	}

	drop (mouse_event) {
		if (this._pending_changes) this._force_update();
		return super.drop(mouse_event);
	}

	_force_update() {
		if (this.facing_trimming)
		{
			if (this.facing_trimming.scene)
			{
				this.facing_trimming.scene.update();
				this.facing_trimming.scene.update_deep();
			}
			else
				this.facing_trimming.update();
			if (this._map)
				this._map.refresh();
			if (this._view_overlay)
			{
				this._view_overlay.refresh();
				this._view_overlay.refresh_3d();
			}
			this.call("change");
			this._pending_changes = false;
		}
	}
}


import { cn_storey, cn_svg_map, cn_svg_tool } from '@acenv/cnmap-editor';
import {
    PointDeControleBien,
    PointDeControleElement,
    PointDeControleVolume,
} from '../../../../model/point-de-controle.model';
import { Conformite } from '../../../../model/conformite.model';
import { ViewerMapConfig } from '../viewer-map/viewer-map-config';
import { ElementAControler, Niveau, Volume } from '../../../../model/bien.model';
import {
    STYLE_ELEMENT_A_JUSTIFIER,
    STYLE_ELEMENT_CONFORME,
    STYLE_ELEMENT_NON_CONFORME,
    STYLE_ELEMENT_NON_VERIFIE,
} from 'src/app/shared/constants/svg.constants';

export class CndiagSelectionTool extends cn_svg_tool {
    formPointDeControle: PointDeControleBien;

    private elementsChecked: (ElementAControler | PointDeControleElement)[];
    private volumesChecked: (Volume | PointDeControleVolume)[] = [];
    private config: ViewerMapConfig;
    private mouseOverElement: any;

    private readonly currentStorey: cn_storey;
    private readonly currentNiveau: Niveau;

    private externalSelectedElement: PointDeControleElement | ElementAControler;
    private externalSelectedVolume: PointDeControleVolume | Volume;

    // Si true, la multi sélection pour les objets est activé
    private isMultipleSelectionObject = false;

    private svgMap: cn_svg_map;

    readonly CLASS_ELEMENT_CONFORME = STYLE_ELEMENT_CONFORME;
    readonly CLASS_ELEMENT_A_JUSTIFIER = STYLE_ELEMENT_A_JUSTIFIER;
    readonly CLASS_ELEMENT_NON_CONFORME = STYLE_ELEMENT_NON_CONFORME;
    readonly CLASS_ELEMENT_NON_VERIFIE = STYLE_ELEMENT_NON_VERIFIE;

    constructor(
        svgMap: cn_svg_map,
        config: ViewerMapConfig,
        currentStorey: cn_storey,
        formPointDeControle?: PointDeControleBien,
        currentNiveau?: Niveau
    ) {
        super(svgMap);
        this.svgMap = svgMap;
        if (formPointDeControle) {
            this.formPointDeControle = formPointDeControle;
        }
        if (currentNiveau) {
            this.currentNiveau = currentNiveau;
        }
        this.currentStorey = currentStorey;
        this.config = Object.assign(this.initConfig(), config);
        this.prepareElementsFromPointDeControle();
        this.prepareVolumeFromPointDeControle();
    }

    click(ev: any): boolean {
        if (!this.isMultipleSelectionObject) {
            this._controller.clear_selection();
        }
        // Sélection courante sans l'élément qui vient d'être cliqué
        const currentSelection: any[] = this._controller.get_selection();
        // Element qui vient d'être cliqué
        this._controller.find_mouseover(ev.mouse_world, ev.camera.snap_world_distance);
        const mouseOver = this._controller.get_mouseover();
        if (mouseOver) {
            // Si l'element cliqué est déjà sélectionné, on le déselectionne.
            // Sinon, on le sélectionne
            if (currentSelection.includes(mouseOver)) {
                this._controller.unselect_element(mouseOver);
            } else {
                // On récupère le type de l'élément sélectionné
                // Si on est en mode selection multiple d'objet, on le déselectionne
                if (this.isMultipleSelectionObject && currentSelection.length === 1) {
                    const elementNameCurrentSelection = this._controller.find_element_name(currentSelection[0]);
                    if (elementNameCurrentSelection === 'spaces') {
                        this._controller.unselect_element(currentSelection[0]);
                    }
                }
                const elName = this._controller.find_element_name(mouseOver);
                if (elName === 'spaces') {
                    this._controller.select_element(mouseOver, true);
                } else if (elName === 'objects') {
                    this._controller.select_element(mouseOver, !this.isMultipleSelectionObject);
                }
            }
        }
        if (this._controller.get_selection().length > 0) {
            this.call('selection_change', this._controller.get_selection());

            // Ré-initialise l'object selectionné depuis l'extérieur
            this.externalSelectedElement = undefined;
            this.externalSelectedVolume = undefined;
        } else {
            this.call('selection_change');
        }
        return true;
    }

    move(ev: any): boolean {
        this._controller.find_mouseover(ev.mouse_world, ev.camera.snap_world_distance);

        // Get svg element (space and object instance)
        const space = this._scene.find_space(ev.mouse_world, ev.camera.world_snap_distance);
        const objectInstance = this._scene.find_object_instance(ev.mouse_world, ev.camera.world_snap_distance);

        // On rajoute !objectInstance pour ne pas colorer le space quand on est sur un object instance :
        if (!objectInstance && space && this.isVolumeSelectable(space)) {
            this.mouseOverElement = space;
        } else if (objectInstance && this.isElementSelectable(objectInstance)) {
            this.mouseOverElement = objectInstance;
        } else {
            this.mouseOverElement = null;
        }

        return true;
    }

    /**
     * Dessine le svg, les classes, ...
     * @param camera
     */
    draw(camera: any): string {
        let html = '';
        // Liste des niveaux
        (this._scene as any).building.storeys.forEach((storey) => {
            // On ne travaille que sur l'étage actuellement affiché
            if (storey === this.currentStorey) {
                // Eléments sélectionnés avec la souris :
                this._controller.get_selection().forEach((it) => {
                    if (!this.config.isSelectableEquipement && it.space) {
                        it = it.space;
                    }
                    if (this.isVolumeSelectable(it) || this.isElementSelectable(it)) {
                        html += it.draw(camera, ['selected']);
                    }
                });

                // Boucle sur chaque pièces (volume) :
                storey.scene.spaces.forEach((space) => {
                    // Pièce/Volume mouseOver css
                    if (space === this.mouseOverElement) {
                        html += space.draw(camera, ['space_hover']);
                    }

                    // Contour sur les volumes sélectionnés depuis l'extérieur de la map
                    if (this.isExternalVolumeSelectable(space)) {
                        html += space.draw(camera, ['selected']);
                    }
                });

                // Boucle sur chaque éléments(objets)
                storey.scene.object_instances.forEach((objectInstance) => {
                    if (
                        !this.config.isSelectableEquipement &&
                        objectInstance.space &&
                        objectInstance === this.mouseOverElement
                    ) {
                        html += objectInstance.space.draw(camera, ['space_hover']);
                    } else {
                        const element = this.elementsChecked
                            ? this.elementsChecked.find((el) => el.objectId === objectInstance.ID)
                            : undefined;

                        // Object : CSS mouseover
                        if (objectInstance === this.mouseOverElement) {
                            html += objectInstance.draw_contour(camera, ['object_hover']);
                        }

                        // Contour sur les objects/éléments sélectionnés depuis l'extérieur de la map
                        if (this.isExternalElementSelectable(objectInstance)) {
                            // this._controller.clear_selection();
                            html += objectInstance.draw_contour(camera, ['selected']);
                        }

                        // Elements/Objects CSS : Conformité
                        if (element && element.hasOwnProperty('conformite')) {
                            const style = this.getStyleConformite(element as PointDeControleElement);
                            html += objectInstance.draw(camera, [], style);
                        }

                        // Elements/Objects CSS : element non défini
                        if (!element) {
                            const classes = this.getClassesNotFiltered(element as PointDeControleElement);
                            html += objectInstance.draw_contour(camera, classes);
                        }
                    }
                });

                const space_labelizer = this._map._space_labelizer;
                if (space_labelizer) {
                    html += '<g>' + space_labelizer.draw(camera) + '</g>';
                }
            }
        });
        return html;
    }

    /**
     * Selectionne un élément depuis l'extérieur
     * @param externalSelectedElement
     */
    selectElement(externalSelectedElement: PointDeControleElement | ElementAControler) {
        this._controller.clear_selection();
        this.externalSelectedVolume = undefined;
        this.externalSelectedElement = externalSelectedElement;
        this.refreshMap();
    }

    /**
     * Selectionne un volume depuis l'exterieur
     * @param externalSelectedVolume
     */
    selectVolume(externalSelectedVolume: PointDeControleVolume | Volume) {
        this._controller.clear_selection();
        this.externalSelectedElement = undefined;
        this.externalSelectedVolume = externalSelectedVolume;
        this.refreshMap();
    }

    /**
     * Active ou désactive la multi sélection des objets
     * @param isMultipleSelectionObject
     */
    multipleSelectionObject(isMultipleSelectionObject: boolean) {
        this._controller.clear_selection();
        this.isMultipleSelectionObject = isMultipleSelectionObject;
        this.refreshMap();
    }

    /**
     * Rafraichit le contenu de la map
     */
    refreshMap() {
        this.svgMap.refresh();
    }

    /**
     * Prépare la liste des éléments / pointDeControleElements qui seront utilisés pour le draw
     * @private
     */
    private prepareElementsFromPointDeControle() {
        if (this.formPointDeControle) {
            // On force l'id du niveau pour remédier à un bug côté CNMAP
            // (la lib dupplique l'object ID des éléments qui ne sont pas sur le même niveau).
            this.elementsChecked = this.formPointDeControle.pointsDeControleNiveaux
                .find((niv) => niv.storeyId === this.currentStorey.ID)
                .pointsDeControleVolumes.flatMap((vol) => vol.pointsDeControleElements);
        }
        if (this.currentNiveau) {
            this.elementsChecked = this.currentNiveau.volumes.flatMap((vol) => vol.elementsAControler);
        }
    }

    /**
     * Prépare la liste des volumes / pointDeControleVolumes qui seront utilisés pour le draw
     * @private
     */
    private prepareVolumeFromPointDeControle() {
        if (this.formPointDeControle) {
            // On force l'id du niveau pour remédier à un bug côté CNMAP
            // (la lib dupplique l'object ID des éléments qui ne sont pas sur le même niveau).
            this.volumesChecked =
                (this.formPointDeControle.pointsDeControleNiveaux || []).find(
                    (niv) => niv.storeyId === this.currentStorey.ID
                ).pointsDeControleVolumes || [];
            // Supprimer les pièces sans équipement :
            if (!this.config.isSelectablePieceSansEquipement) {
                this.volumesChecked = this.volumesChecked.filter(
                    (vol: PointDeControleVolume) => !!vol.pointsDeControleElements.length
                );
            }
        }
        if (this.currentNiveau) {
            this.volumesChecked = this.currentNiveau.volumes || [];
        }
    }

    /**
     * Filtre : détermine si l'élément est sélectionnable
     * @param element
     * @private
     */
    private isElementSelectable(element): boolean {
        return this.elementsChecked.map((el) => el.objectId).indexOf(element.ID) !== -1;
    }

    /**
     * Détermine si l'elément séléctionné à l'extérieur est séléctionnable
     * @param objectInstance
     * @private
     */
    private isExternalElementSelectable(objectInstance: any): boolean {
        return this.externalSelectedElement && this.externalSelectedElement.objectId === objectInstance.ID;
    }

    /**
     * Filtre : permet de savoir si le volume est sélectionnable
     * @param volume
     * @private
     */
    private isVolumeSelectable(volume): boolean {
        return this.volumesChecked.map((vol) => vol.spaceId).indexOf(volume.ID) !== -1;
    }

    /**
     * Détermine si l'élément externe séléctionné est cliquable
     * @param space
     * @private
     */
    private isExternalVolumeSelectable(space: any): boolean {
        return (
            this.externalSelectedVolume &&
            this.externalSelectedVolume.spaceId === space.ID &&
            !this.externalSelectedElement
        );
    }

    /**
     * Initialise l'objet de configuration pour la surcharge de la map
     * @private
     */
    private initConfig(): ViewerMapConfig {
        return {
            isSelectablePieceSansEquipement: false,
            isSelectableEquipement: true,
        };
    }

    /**
     * Get les styles relatifs à la conformité
     * @param element
     * @private
     */
    private getStyleConformite(element: PointDeControleElement): string {
        if (element) {
            switch (element.conformite) {
                case Conformite.CONFORME:
                    return this.CLASS_ELEMENT_CONFORME;
                case Conformite.A_JUSTIFIER:
                    return this.CLASS_ELEMENT_A_JUSTIFIER;
                case Conformite.NON_CONFORME:
                    return this.CLASS_ELEMENT_NON_CONFORME;
                default:
                    return this.CLASS_ELEMENT_NON_VERIFIE;
            }
        }
    }

    /**
     * Get les classes css d'élément non filtrés.
     * @param element
     * @private
     */
    private getClassesNotFiltered(element: PointDeControleElement): string[] {
        return element ? [] : ['not_filtered'];
    }
}

import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { EtapeDiagnosticGenerique } from '../../../../model/diagnostic-etape.model';
import {
    AnnexeData,
    DocumentData,
    IDiagReportData,
    ISubBlock,
    ReportagePhotoData,
} from '../../../../model/rapport.model';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { Conformite } from '../../../../model/conformite.model';
import { PointDeControleElement } from '../../../../model/point-de-controle.model';
import { ConclusionElement } from '../../../../model/conclusion.model';
import { ScreenshotSvg } from 'src/app/model/screenshot-svg.model';

export type ConclusionComponentMode = 'reseauEu' | 'reseauEp' | 'autre';

export const etatsList: { label: string; value: string }[] = [
    { label: 'Incomplet', value: 'incomplet' },
    { label: 'A justifier', value: 'a-justifier' },
];

export class Assainissement implements ContenuDiagnostic {
    // NB : il faut bien donner des valeurs par défaut aux différentes étapes, pour l'intialisation du diagnostic
    ageInstallation: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    assainissementCollectif: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    assainissementPublic: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    alimentationEnEau: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    reseauPublic: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    captageEau: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    anciennesFosses: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    situationDuCollecteur: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    pompesDeRelevage: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    clapetsAntiretour: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    bacsAGraisse: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    recuperateursEauDePluie: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    ventilationsGenerale: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    eauxUsees: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    eauxPluviales: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    eauxIndustrielles: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
}

/**
 * AssainissementReportData
 *
 * @title Diagnostic assainissement
 * @description Contient toutes les informations relatives à un diagnostic assainissement
 */
export class AssainissementReportData implements IDiagReportData {
    /**
     * Id
     *
     * @title Id
     * @description Id du diagnostic assainissement
     */
    id: string;

    /**
     * Type prestation
     *
     * @title Type de la prestation
     * @description Type de prestation du diagnostic assainissement
     */
    typePrestation: TypePrestation;

    /**
     * Référence du rapport
     *
     * @title Référence du rapport
     * @description Référence du rapport
     */
    refRapport: string;

    /**
     * Option plan
     *
     * @title Option plan
     * @description L'option plan est-elle activée pour ce diagnostic assainissement. En fonction, l'opérateur devra réaliser un plan du/des biens
     */
    optionPlan: boolean;

    /**
     * Age Installation
     *
     * @title Age de l'installation
     * @description Age de l'installation assainissement
     */
    ageInstallation: string;

    /**
     * Caracteristiques
     *
     * @title Caracteristiques de l'assainissement
     * @description Contient toutes les caractéristique de l'assainissement
     */
    caracteristiques: CaracteristiquesData;

    /**
     * Type Assainissement Communataire
     *
     * @title Type d'assainissement
     * @description Le type d'assainissement communautaire
     */
    typeAssainissementCommunataire: string;

    /**
     * Regards EU
     *
     * @title Nombre de regards d'eau usée
     * @description Nombre de regards d'eau usée
     */
    regardsEU: string;

    /**
     * Regards EP
     *
     * @title Nombre de regards d'eau pluviale
     * @description Nombre de regards d'eau pluviale
     */
    regardsEP: string;

    /**
     * Non visibles
     *
     * @title Liste des éléments non visibles
     * @description Correspond à la liste des éléments non visibles
     */
    nonVisibles: SubBlockAssainissement;

    /**
     * Volumes visités
     *
     * @title Liste des volumes visités
     * @description Correspond à la liste des volumes visités
     */
    volumesVisites: SubBlockAssainissement;

    /**
     * Volumes non visités
     *
     * @title Liste des volumes non visités
     * @description Correspond à la liste des volumes non visités
     */
    volumesNonVisites: SubBlockAssainissement;

    /**
     * Raccordement EU
     *
     * @title Résultats raccordements aux Eaux Usés
     * @description Résultats des points de contrôles pour les éléments raccordés aux Eaux Usés
     */
    raccordementEU: SubBlockAssainissement;

    /**
     * Équipements EU
     *
     * @title Résultats équipements aux Eaux Usés
     * @description Résultats des points de contrôles pour les équipements Eaux Usés
     */
    equipementsEU: SubBlockAssainissement;

    /**
     * Raccordement EP
     *
     * @title Résultats raccordements aux Eaux Pluviales
     * @description Résultats des points de contrôles pour les éléments raccordés aux Eaux Pluviales
     */
    raccordementEP: SubBlockAssainissement;

    /**
     * Équipements EP
     *
     * @title Résultats équipements aux Eaux Pluviales
     * @description Résultats des points de contrôles pour les équipements Eaux Pluviales
     */
    equipementsEP: SubBlockAssainissement;

    /**
     * Constatations Specifiques
     *
     * @title Constatations Specifiques
     * @description Contient toutes les Constatations Specifiques relatives à un diagnostic assainissement
     */
    constatationsSpecifiques: SubBlockAssainissement;

    /**
     * Conclusion Raccordements EU
     *
     * @title Conclusion Raccordements EU
     * @description Contient toutes les conclusions liées aux équipements raccordés aux Eaux Usées
     */
    conclusionRaccordementsEU: SubBlockAssainissement;

    /**
     * Conclusion Équipements EU
     *
     * @title Conclusion Équipements EU
     * @description Contient toutes les conclusions liées aux équipements Eaux Usées
     */
    conclusionEquipementsEU: SubBlockAssainissement;

    /**
     * Conclusion Raccordements EP
     *
     * @title Conclusion Raccordements EP
     * @description Contient toutes les conclusions liées aux équipements raccordés aux Eaux Pluviales
     */
    conclusionRaccordementsEP: SubBlockAssainissement;

    /**
     * Conclusion Équipements EP
     *
     * @title Conclusion Équipements EP
     * @description Contient toutes les conclusions liées aux équipements Eaux Pluviales
     */
    conclusionEquipementsEP: SubBlockAssainissement;

    /**
     * Recommandations
     *
     * @title Recommandations
     * @description Contient toutes les recommandations relatives à ce diagnostic assainissement
     */
    recommandations: string[] = [];

    /**
     * Constatations diverses
     *
     * @title Constatations diverses
     * @description Contient toutes les constatations diverses relatives à ce diagnostic assainissement
     */
    constatationsDiverses: string[] = [];

    /**
     * Conformite globale
     *
     * @title Conformite globale
     * @description Conformite globale de ce diagnostic assainissement
     */
    conformiteGlobale: boolean;

    /**
     * Documents Data
     *
     * @title Documents Data
     * @description Contient tous les documents liés à ce diagnostic assainissement
     */
    documentsData: DocumentData[] = [];

    /**
     * List Documents
     *
     * @title Liste des documents
     * @description Liste des documents liés à ce diagnostic assainissement
     */
    listDocuments: DocumentData[] = [];

    /**
     * Annexes
     *
     * @title Annexes
     * @description Annexes liées à ce diagnostic assainissement
     */
    annexes: AnnexeData[] = [];

    /**
     * Reportages Photos
     *
     * @title Reportages Photos
     * @description Contient tous les reportages photos de ce diagnostic assainissement
     */
    reportagesPhotos: ReportagePhotoData[];

    /**
     * Screenshots Plan
     *
     * @title Captures de plan
     * @description Contient toutes les captures de plan de ce diagnostic assainissement
     */
    screenshotsPlan: ScreenshotSvg[];
}

export class SubBlockAssainissement implements ISubBlock {
    code: string;
    biens: RowBienAssainissement[] = [];

    constructor(code: string, biens: RowBienAssainissement[]) {
        this.code = code;
        this.biens = biens;
    }
}

export class RowBienAssainissement {
    id: string;
    bien: string;
    niveaux: RowNiveauAssainissement[] = [];

    constructor(id: string, nom: string) {
        this.id = id;
        this.bien = nom;
    }
}

export class RowNiveauAssainissement {
    id: string;
    niveau: string;
    storeyId?: string = null;
    volumes: RowVolumeAssainissement[] = [];

    constructor(id: string, nom: string, storeyId: string) {
        this.id = id;
        this.niveau = nom;
        this.storeyId = storeyId;
    }
}

export class RowVolumeAssainissement {
    volume: string;
    volumeId: string;
    plans: PlanRapport[] = [];
    visite: ItemElementAssainissement;
    equipements: RowElementAssainissement[] = [];
    commentaires: Commentaire[] = [];

    constructor(nom: string, volumeId: string) {
        this.volume = nom;
        this.volumeId = volumeId;
    }
}

export class RowElementAssainissement {
    conformite: ItemElementAssainissement;
    element: string;
    materiaux?: ItemElementAssainissement;
    etat?: ItemElementAssainissement;
    ecoulement?: ItemElementAssainissement;
    controle?: ItemElementAssainissement;
    odeur?: ItemElementAssainissement;
    rejet?: ItemElementAssainissement;
    nonVisibles?: string[] = [];
    commentaires: Commentaire[] = [];
}

export class ItemElementAssainissement {
    valeur: string = null;
    style: string = null;

    constructor(valeur: string, style?: string) {
        this.valeur = valeur;
        this.style = style;
    }
}

export class PlanRapport {
    id: string;
    nom: string;

    constructor(id: string, nom: string) {
        this.id = id;
        this.nom = nom;
    }
}

/**
 * CaracteristiquesData
 *
 * @title Caractéristiques du diagnostic assaisnissement
 * @description Contient toutes les caractéristiques
 */
export class CaracteristiquesData {
    /**
     * Type de bâtiment
     *
     * @title Type de bâtiment
     * @description Type de bâtiment du bien concerné
     */
    typeBatiment: Caracteristique;

    /**
     * Alimentation en eau
     *
     * @title Alimentation en eau
     * @description Présence ou non de l'alimentation en eau
     */
    alimentationEau: Caracteristique;

    /**
     * Type de réseau
     *
     * @title Type de réseau
     * @description Type de réseau du bien concercé
     */
    typeReseau: Caracteristique;

    /**
     * Documents liés au diagnostic assainissement
     *
     * @title Documents liés au diagnostic assainissement
     * @description Documents liés au diagnostic assainissement (ex. réglement d'assainissement)
     */
    documentsCaracteristique: Caracteristique;

    /**
     * Documents d'implantation
     *
     * @title Documents d'implantation
     * @description Documents d'implantation
     */
    documentsImplantation: Caracteristique;

    /**
     * Réseau Public d'Eau Potable
     *
     * @title Réseau public d'eau potable
     * @description Le terrain est-il desservi par un réseau public d'eau potable ?
     */
    reseauPublicEauPotable: Caracteristique;

    /**
     * Captage d'eau
     *
     * @title Captage d'eau
     * @description Y a-t-il présence d'un captage d'eau ?
     */
    captageEau: Caracteristique;

    /**
     * Poste de refoulement
     *
     * @title Poste de refoulement
     * @description Y a-t-il présence d'un poste de refoulement ?
     */
    posteRefoulement: Caracteristique;

    /**
     * Clapet anti-retour en contrebas
     *
     * @title Clapet anti-retour en contrebas
     * @description Y a-t-il présence d'un Clapet anti-retour en contrebas ?
     */
    clapetAntiRetourContrebas: Caracteristique;

    /**
     * Ancienne Fosse
     *
     * @title Présence d'une ancienne fosse septique
     * @description Y a-t-il présence d'une ancienne fosse septique ?
     */
    ancienneFosse: Caracteristique;

    /**
     * Ventilation générale
     *
     * @title Ventilation générale
     * @description Y a-t-il présence d'une ventilation générale ?
     */
    ventilationGenerale: Caracteristique;
}

/**
 * Caracteristique
 *
 * @title Caracteristique
 * @description Contient toutes les informations d'une caractéristique
 */
export class Caracteristique {
    /**
     * Label
     *
     * @title Label
     * @description Label de la caractéristique
     */
    label: string;

    /**
     * État
     *
     * @title État
     * @description État de la caractéristique
     */
    etat: ItemElementAssainissement;

    /**
     * Precisions
     *
     * @title Précisions
     * @description Tableau de précisions sur la caractéristique
     */
    precisions: string[] = [];

    constructor(label: string, etat: ItemElementAssainissement, precisions?: string[]) {
        this.label = label;
        this.etat = etat;
        this.precisions = precisions;
    }
}

export class ConclusionAssainissement extends ConclusionElement {
    conformite: Conformite;
    incomplete = false;
    commentary = false;
    errors: string;
    defauts: string[] = [];
    equipement: PointDeControleElement;
}

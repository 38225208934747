"use strict";
//***********************************************************************************
//***********************************************************************************

import {cn_event_handler} from "./cn_event_handler";
import {cn_camera} from "./cn_camera";
import {cn_scene} from "../model/cn_scene";
import {cn_add, cn_clone, cn_dot, cn_mul, cn_size, cn_sub} from "../utils/cn_utilities";
import {cn_handler_rotation} from "./cn_handler_rotation";
import {cn_number_input} from "./cn_inputs";
import {cn_svg_tool} from "./cn_svg_tool";
import {cn_edit_box} from "./cn_edit_box";
import {cn_pastille} from "./cn_pastille";
import {cn_clipboard} from "./cn_clipboard";

/**
 * @class cn_paste_handler
 * Class to manage pasting
 * Events : "paste_end" is sent when paste is over (either validated, either cancelled)
 */
export class cn_paste_handler extends cn_event_handler {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_scene} scene
	 * @param {cn_clipboard} clipboard
	 * @param {cn_svg_tool} parent
	 */
	constructor(scene, clipboard, parent) {
		super(parent);
		this._scene = scene;
		this._clipboard = clipboard;
		this._parent_tool = parent;

		/**
		 * We add tmp element types, just to be sure to unserialize
		 * properly element types or objects that may have been removed in original scene
		 */

		var building = this._scene.building;
		this._clipboard_scene = this._clipboard.unserialize(this._scene.storey);

		this._angle = 0;
		this._mouseover = false;

		this._box = this._clipboard.storey.scene.get_bounding_box(true,true,0,false);
		this._box.enlarge_distance(1);
		this._initial_center = cn_add(this._box.posmin,cn_mul(this._box.size,0.5));
		this._center = cn_clone(this._initial_center);
		this._update_geometry();

		//*** Rotation handdler */
		const obj = this;
		this._rotation_handler = new cn_handler_rotation(this._center,this._angle,5,cn_size(obj._box.size) * 0.55,true,function(v){
			const input = new cn_number_input("Rotation du presse-papier", obj._angle);
			input.min_value = -180;
			input.max_value = 180;
			while (input.value > 180) input.value -= 360;
			while (input.value < -180) input.value += 360;
			input.callback = function() {
				obj._rotate(input.value);
			};
			obj.call("number_input",input);
		});
		this._rotation_handler.on("change",function(){
			obj._rotate(obj._rotation_handler.angle);
		});
		this._handlers.push(this._rotation_handler);


		this._edit_box = new cn_edit_box(this,[this._clipboard_scene],false,false);
		this._handlers.push(this._edit_box);

		//*** Cancel action */
		const cancell_pastille = new cn_pastille([0, 0], "delete_warn.svg");
		this._edit_box.add_pastille(cancell_pastille);
		cancell_pastille.svg_class = "pastille_background white";
		cancell_pastille.title="Annuler";
		cancell_pastille.clicked = () => {
			obj.call("paste_end");
		};

		//*** Horizontal flip action */
		const flip_h_pastille = new cn_pastille([0,0],"flip_horizontal.svg");
		this._edit_box.add_pastille(flip_h_pastille);
		flip_h_pastille.svg_class = "pastille_background white";
		flip_h_pastille.title="Symétrie horizontale";
		flip_h_pastille.clicked = () => {
			this._flip(true);
		};

		//*** Vertical flip action */
		const flip_v_pastille = new cn_pastille([0,0],"flip_vertical.svg");
		this._edit_box.add_pastille(flip_v_pastille);
		flip_v_pastille.svg_class = "pastille_background white";
		flip_v_pastille.title="Symétrie verticale";
		flip_v_pastille.clicked = () => {
			this._flip(false);
		};

		//*** Check action */
		const check_pastille = new cn_pastille([0, 0], "check_success.svg");
		this._edit_box.add_pastille(check_pastille);
		check_pastille.svg_class = "pastille_background white";
		check_pastille.title="Valider";
		check_pastille.clicked = () => {
			this._finalize();
			obj.call("paste_end");
		};
	}

	//***********************************************************************************
	/**
	 * SVG Drawing
	 * @param {cn_camera} camera
	 * @returns {string} svg code
	 */
	draw(camera) {
		var html = "";

		//*** Draw scene */
		html += "<g opacity='0.5'>";
		html += this._clipboard_scene.draw(camera);
		html += "</g>";

		//*** Build paste area path */
		var dx = cn_mul(this._dx,0.5*this._box.size[0]);
		var dy = cn_mul(this._dy,0.5*this._box.size[1]);
		var points = [];
		points.push(cn_sub(this._center,cn_add(dx,dy)));
		points.push(cn_sub(this._center,cn_sub(dx,dy)));
		points.push(cn_add(this._center,cn_add(dx,dy)));
		points.push(cn_add(this._center,cn_sub(dx,dy)));
		for (var i=0;i<points.length;i++)
			points[i] = camera.world_to_screen(points[i]);

		var path = "";
		for (var i=0;i<points.length;i++)
		{
			if (i == 0) path += "M ";
			if (i == 1) path += "L ";
			path += points[i][0] + " ";
			path += points[i][1] + " ";
		}
		path += "Z";

		/** Draw paste area */
		html += "<path class='paste_area' d='" + path + "' />";

		/** Draw paste outline (juste if mouseover) */
		if (this._mouseover)
			html += "<path class='paste_outline', d='" + path + "' style='fill: none;stroke:rgb(255,0,0);stroke-width: 3px;'/>";

		//*** draw rotation */
		html += super.draw(camera);
		return html;
	}

	/**
	 * Apply rotation
	 * @param {number} value
	 */
	_rotate(value) {
		this._clipboard_scene.perform_rotation(this._center,value - this._angle);
		this._angle = value;
		this._rotation_handler.angle = this._angle;
		this._update_geometry();
		this._parent_tool.get_svg_map().refresh_tool();
	}

	/**
	 * Apply filp
	 * @param {boolean} horizontal
	 */
	_flip(horizontal) {
		this._clipboard_scene.perform_flip(this._center,horizontal);
		this._angle = -this._angle;
		if (horizontal) this._angle += 180;
		this._rotation_handler.angle = this._angle;
		this._update_geometry();
		this._parent_tool.get_svg_map().refresh_tool();
	}

	/**
	 * finalize paste into scene
	 */
	_finalize() {
		var building = this._scene.building;
		var scene = this._scene;
		building.transaction_manager.push_transaction("Collage du presse-papier","",function() {scene.update();scene.update_deep();});

		this._clipboard.paste(scene.storey);
	}

	//***********************************************************************************
	//**** Mouse callbacks
	//***********************************************************************************

	clear_move() {
		this._mouseover = true;
		super.clear_move();
	}

	//***********************************************************************************
	/**
	 * Grab event
	 * @param {object} ev
	 * @returns {boolean} true if event is used
	 */
	grab (ev) {
		if (this._focus_handler == this)
		{
			this._grab_start = cn_clone(ev.mouse_world);
			return true;
		}
		return super.grab(ev);
	}

	//***********************************************************************************
	/**
	 * click event
	 * @param {object} ev
	 * @returns {boolean} true if event is used
	 */
	click(ev) {
		if (this._focus_handler == this)
			return true;
		return super.click(ev);
	}

	//***********************************************************************************
	/**
	 * Drop event
	 * @param {object} ev
	 * @returns {boolean} true if event is used
	 */
	drop(ev) {
		if (this._focus_handler == this)
			return true;
		return super.drop(ev);
	}

	//***********************************************************************************
	/**
	 * Move event
	 * @param {object} ev
	 * @returns {boolean} true if event is used
	 */
	move(ev) {
		this._focus_handler = null;

		/** Are we inside the paste area ? */
		var d = cn_sub(ev.mouse_world,this._center);
		var x = cn_dot(d,this._dx);
		var y = cn_dot(d,this._dy);
		if (Math.abs(x) < this._box.size[0]*0.5 && Math.abs(y) < this._box.size[1]*0.5)
		{
			this._focus_handler = this;
			return true;
		}

		return super.move(ev);
	}

	//***********************************************************************************
	/**
	 * Drag
	 * @param {object} ev
	 * @returns {boolean} true if event is used
	 */
	drag(ev) {
		/** Manage translation of paste area */
		if (this._focus_handler == this)
		{
			var offset = cn_sub(ev.mouse_world,this._grab_start);
			this._clipboard_scene.perform_translation(offset);
			this._center = cn_add(this._center,offset);
			this._rotation_handler.center = this._center;
			this._grab_start = cn_clone(ev.mouse_world);
			this._update_geometry();
			return true;
		}

		return super.drag(ev);
	}

	/**
	 * Manage key events
	 * @param {object} ev
	 * @returns {boolean} true if element was used
	 */
	keydown(ev)
	{
		if (ev.key == 'Enter')
		{
			this._finalize();
			this.call("paste_end");
			return true;
		}
		if (ev.key == 'Escape')
		{
			this.call("paste_end");
			return true;
		}
		return false;
	}

	//***********************************************************************************
	/**
	 * Internal : Update geometry
	 */
	_update_geometry() {
		/** Update paste area info */
		while (this._angle > 180) this._angle -= 360;
		while (this._angle < -180) this._angle += 360;

		var angle = this._angle * Math.PI/180;
		var cosangle = Math.cos(angle);
		var sinangle = Math.sin(angle);
		this._dx = [cosangle,sinangle];
		this._dy = [-sinangle,cosangle];

		/** Check if walls can be pasted onto scene */
		for (var i in this._clipboard_scene.walls)
		{
			this._clipboard_scene.walls[i].valid = !this._clipboard_scene.walls[i].crosses(this._scene.walls);
		}
	}
}


import { EtatWorkflow } from './etat-workflow.model';
import { TypePrestation } from './type-prestation.model';
import { Document } from './document.model';
import { PresetPhoto } from './diagnostic.model';

export class ReferencePrestation {
    id: string;
    pictoUrl: string | ArrayBuffer;
    idFichierPicto: string;
    typePrestation: TypePrestation;
    nom: string;
    idRegles: string[] = [];
    etatPrestation: EtatWorkflow;
    pictoImage: FormData;
    idSalesforce: string;
    templates?: TemplateADO[];
    optionPlanActive: boolean;
    presetsReportagePhotosAuto: PresetPhoto[] = [];
    presetsExportScreenshot: string[] = [];
}

export enum TypeReport {
    REPORT = 'REPORT',
    BON_COMMANDE = 'BON_COMMANDE',
}

export class TemplateADO {
    typeReport: TypeReport = TypeReport.REPORT;
    document?: Document = null;
}

/**
 * TypeReport possible suivant le type de prestation
 */
export const mapTypeReportByTypePrestation: Map<TypePrestation, TypeReport[]> = new Map([
    ['ASSAINISSEMENT', [TypeReport.REPORT]],
    ['MESURAGE', [TypeReport.REPORT]],
    ['MESURAGE_HABITABLE', [TypeReport.REPORT]],
    ['MESURAGE_UTILE', [TypeReport.REPORT]],
    ['HAP_VALIDATION', [TypeReport.BON_COMMANDE, TypeReport.REPORT]],
    ['HAP_ETUDE_SITUATION', [TypeReport.REPORT]],
    ['HAP_VISITE_RECONNAISSANCE', [TypeReport.REPORT]],
    ['HAP_TERRAIN', [TypeReport.BON_COMMANDE, TypeReport.REPORT]],
    ['HAP_VALIDATION_RESULTATS', [TypeReport.REPORT]],
    ['ELECTRICITE', [TypeReport.REPORT]],
    ['BAREN101', [TypeReport.REPORT]],
    ['BAREN102', [TypeReport.REPORT]],
    ['BAREN103', [TypeReport.REPORT]],
    ['BARTH160', [TypeReport.REPORT]],
    ['BARTH161', [TypeReport.REPORT]],
    ['POLLUANT_ETUDE_SITUATION', [TypeReport.REPORT]],
    ['POLLUANT_VISITE_CHANTIER', [TypeReport.REPORT]],
    ['POLLUANT_PRELEVEMENT', [TypeReport.REPORT]],
    ['POLLUANT_REDACTION_STRATEGIE', [TypeReport.REPORT]],
    ['POLLUANT_RESULTATS_ANALYSES', [TypeReport.REPORT]],
    // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRESTATIONS...
]);

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Optional,
    Output,
    Self,
    SimpleChanges,
} from '@angular/core';
import { ArrayUtils, NotificationService } from 'src/app/commons-lib';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { CustomSelectGroup } from './custom-select.model';
import { FindAttributePipe } from 'src/app/pipes/find-attribute.pipe';

@Component({
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent implements OnInit, ControlValueAccessor, OnChanges {
    /**
     * Si on passe par reactive form
     */
    @Input()
    selectControl: FormControl | AbstractControl;

    /**
     * Afficher le mat-error
     * Facultatif
     */
    @Input()
    showMatError = true;

    @Input()
    readonly = false;

    @Input()
    disabled = false;

    @Input()
    required = false;

    /**
     * Défini comment on récupére la valeur de l'objet à afficher dans la comboBox
     * C'est un tableau de string contenant les différents niveaux dans l'ordre.
     * {
     *  id: 5,
     *  isVisible : true,
     *  batiment: {
     *      nom: "batiment4"
     *      escalier : 4,
     *      porte : 9
     *  }
     * }
     *
     * Si on veut afficher le nom du batiment dans la comboBox, pathValueDisplay sera égale à ["batiment", "nom"]
     * Si null, on affiche la valeur direct du tableau choices. Utile si on passe un tableau de type 'primitifs' (string, number, ...)
     * Avec pathValueDisplay, on ne peut pas utiliser le addLine.
     */
    @Input()
    pathValueDisplay: string[] | ((item: any) => string);

    /**
     * Fonctionnalité d'ajout de ligne ?
     */
    @Input()
    addline = true;

    /**
     * Fonctionnalité de filter ?
     */
    @Input()
    filter = true;

    @Input()
    showGroup = false;

    @Input()
    emptyOptionActivate = false;

    /**
     * Trier la liste ?
     */
    private _sorted = false;

    @Input()
    global = false;

    @Input()
    set sorted(value: boolean) {
        this._sorted = value;
        if (this._sorted && this._choices) {
            this._choices.sort((c1, c2) =>
                this.findAttributePipe
                    .transform(c1, this.pathValueDisplay)
                    .localeCompare(this.findAttributePipe.transform(c2, this.pathValueDisplay))
            );
            this.filteredList = this._choices.slice();
        }
    }

    /**
     * Fonctionnalité de multisélection ?
     */
    @Input()
    multiple = false;

    /**
     * Placeholder dans l'input
     */
    @Input()
    placeholder: string;

    /**
     * Label du bouton "ajouter"
     */
    @Input()
    addLineButtonLabel = 'Ajouter';

    /**
     * Classe(s) CSS à ajouter sur <mat-form-field>
     */
    @Input()
    matFormFieldClass = '';

    /**
     * Liste des choix
     */
    @Input()
    set choices(choices: any[]) {
        this._choices = choices.slice();
        if (this._sorted) {
            this._choices.sort((c1, c2) =>
                this.findAttributePipe
                    .transform(c1, this.pathValueDisplay)
                    .localeCompare(this.findAttributePipe.transform(c2, this.pathValueDisplay))
            );
        }
        this.filteredList = this._choices.slice();
    }

    _groupChoices: CustomSelectGroup[] = [];

    @Input()
    set groupChoices(groupChoices: CustomSelectGroup[]) {
        this._groupChoices = groupChoices.slice();
        this.filteredGroupChoices = this._groupChoices.slice();
    }

    /**
     * Valeur sélectionnée
     */
    @Input()
    set selectedValue(selectedValue: any | any[]) {
        this.setSelectedValue(selectedValue, false);
    }

    @Output()
    selectedValueChange = new EventEmitter<any | any[]>();

    @Output()
    valueChangeFromUser = new EventEmitter<any | any[]>();

    filteredList = [];
    addingElement = false;

    _choices: any[];
    _selectedValue: any | any[];
    filteredGroupChoices: CustomSelectGroup[] = [];

    @Input()
    disabledCallback: (item: any) => boolean = () => false;

    constructor(
        private notificationService: NotificationService,
        private findAttributePipe: FindAttributePipe,
        @Self()
        @Optional()
        private ngControl: NgControl
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.selectControl && (changes.readonly || changes.disabled)) {
            if (this.readonly || this.disabled) {
                this.selectControl.disable();
            } else {
                this.selectControl.enable();
            }
        }
    }

    selectionChanged(item: any, event: any) {
        if (!event.isUserInput) {
            return;
        }

        if (this.multiple) {
            if (this._selectedValue && this._selectedValue.includes(item)) {
                this.setSelectedValue(
                    [].concat(this._selectedValue).filter((it) => it != item),
                    true
                );
            } else {
                if (!this._selectedValue) {
                    this.setSelectedValue([item], true);
                } else {
                    this.setSelectedValue([].concat(this._selectedValue).concat(item), true);
                }
            }
        } else {
            this.setSelectedValue(item, true);
        }
    }

    showAddInput() {
        this.addingElement = true;
    }

    hideAddInput() {
        this.addingElement = false;
    }

    addEntry(newElement: string, event: any) {
        if (this._choices.includes(newElement)) {
            this.notificationService.warn(`Le choix ${newElement} existe déjà`);
            return;
        }
        this._choices = this._choices.concat(newElement);
        if (this._sorted) {
            this._choices.sort((c1, c2) => c1.localeCompare(c2));
        }
        this.filteredList = this._choices.slice();
        if (this.multiple) {
            this.setSelectedValue([].concat(this._selectedValue).concat(newElement), true);
        } else {
            this.setSelectedValue(newElement, true);
        }
        this.addingElement = false;
        this.notificationService.success({
            message: `Le choix ${newElement} a été ajouté`,
            showCloseButton: false,
            duration: 2000,
        });
        this.selectionChanged(newElement, event);
    }

    private setSelectedValue(selectedValue: any | any[], fromUser: boolean) {
        const choiceValues = [].concat(selectedValue);
        let changed = false;
        if (!ArrayUtils.arraysContainsSameElements([].concat(this._selectedValue), choiceValues)) {
            changed = true;
        }
        this._selectedValue = selectedValue;
        if (changed) {
            this.onChange(selectedValue);
            this.selectedValueChange.emit(selectedValue);
            if (fromUser) {
                this.onChange(selectedValue);
                this.valueChangeFromUser.emit(selectedValue);
            }
        } else if (this.global && fromUser) {
            this.onChange(selectedValue);
            this.selectedValueChange.emit(selectedValue);
        }
    }

    writeValue(obj: any): void {
        this._selectedValue = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onChange(value) {}

    private onTouched() {}
}

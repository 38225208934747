"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_roof_vertex : a vertex to draw roofs
//***********************************************************************************
//***********************************************************************************

import {cn_element} from "./cn_element";
import {
    cn_add,
    cn_box,
    cn_clone,
    cn_dist,
    cn_intersect_line, cn_middle,
    cn_mul,
    cn_normal,
    cn_normalize,
    cn_polar,
    cn_sub, DATATION
} from "../utils/cn_utilities";

export class cn_roof_sector {
	constructor(l) {
		this.line=l;
		this.angle=0;
	}
}

function roof_sector_by_angle(s0, s1) {
	if (s0.angle < s1.angle) return -1;
	return 1;
}

export class cn_roof_vertex extends cn_element {
	constructor(p) {
		super();
		this.position = [p[0],p[1]];
		this.overhang_position = [0,0];
		this.liberties = 2;
		this.lines = [];
		this.angles = [];
		this.date = DATATION;
		this.draw_priority = 10;
	}

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize() {
		var json = {};
		json.ID = this.ID;
		json.liberties = this.liberties;
		json.position = [this.position[0],this.position[1]];
		return json;
	}

	static unserialize(json,scene) {
		if (typeof(json) != 'object') return false;
		var vertex = new cn_roof_vertex(json.position);
		if (typeof(json.ID) == 'string') vertex.ID = json.ID;
		if (typeof(json.liberties) == 'number') vertex.liberties = json.liberties;
		scene.vertices.push(vertex);
		return vertex;
	}

	//***********************************************************************************
	//**** Draw the vertex in svg
	//***********************************************************************************
	draw(camera, add_classes) {
		if (this.liberties == 0) return "";
		var html = "";
		var p = camera.world_to_screen(this.position);
		var draw_class = "roof_vertex";
		var radius = 3;
		if (add_classes)
		{
			draw_class += " " + add_classes.join(" ");
			radius =  6;
		}

		html += "<circle class='" + draw_class + "' cx='" + p[0] + "' cy='" + p[1] + "'/>";
		return html;
	}

	//******************************************************
	//*** return fixed lines around this
	//******************************************************
	get_fixed_lines() {
		var lns = [null,null];
		for (var i in this.lines)
		{
			if (!this.lines[i].fixed) continue;
			if (this.lines[i].vertices[0] == this)
				lns[1] = this.lines[i];
			else
				lns[0] = this.lines[i];
		}
		return lns;
	}

	//******************************************************
	//*** update sectors
	//******************************************************
	update() {
		var sectors = [];

		//*** compute sector angles
		for (var i in this.lines)
		{
			var w = this.lines[i];
			var sector = new cn_roof_sector(w);
			var direction = cn_sub(w.vertices[1].position,w.vertices[0].position);
			if (w.vertices[0] != this) direction = cn_mul(direction,-1);
			var pol = cn_polar(direction);
			sector.angle = pol[1];
			sectors.push(sector);
		}

		//*** sort sectors by angle
		sectors.sort(roof_sector_by_angle);

		this.lines = [];
		this.angles = [];
		for (var i in sectors)
		{
			this.lines.push(sectors[i].line);
			this.angles.push(sectors[i].angle);
		}

		//*** Compute overhang position
		var lns = this.get_fixed_lines();
		this.overhang_position = cn_clone(this.position);
		if (lns[0] && lns[1] && (lns[0].overhang > 0 || lns[1].overhang > 0))
		{
			var dir0 = cn_sub(lns[0].vertices[1].position,lns[0].vertices[0].position);
			cn_normalize(dir0);
			var dir1 = cn_sub(lns[1].vertices[1].position,lns[1].vertices[0].position);
			cn_normalize(dir1);
			var p0 = cn_add(this.position,cn_mul(cn_normal(dir0),lns[0].overhang));
			var p1 = cn_add(this.position,cn_mul(cn_normal(dir1),lns[1].overhang));
			var res = cn_intersect_line(p0,dir0,p1,dir1);
			if (res && cn_dist(res,this.position) < lns[0].overhang + lns[1].overhang)
				this.overhang_position = cn_clone(res);
			else
				this.overhang_position = cn_middle(p0,p1);
		}

		this.removable = ((this.lines.length == 2) && this.liberties > 0);
	}

	//******************************************************
	//*** get next line
	//******************************************************
	next_line(line) {
		if (this.lines.length == 1) return line;
		var index = this.lines.indexOf(line);
		if (index<0) return null;
		while (true)
		{
			index++;
			if (index >= this.lines.length) index = 0;
			return this.lines[index];
		}
		return line;
	}

	previous_line(line) {
		if (this.lines.length == 1) return line;
		var index = this.lines.indexOf(line);
		if (index<0) return null;
		while (true)
		{
			index--;
			if (index < 0) index = this.lines.length-1;
			return this.lines[index];
		}
		return line;
	}

	//******************************************************
	//*** Compute angle from line to next
	//******************************************************
	angle_between(line0, line1) {
		var i0 = this.lines.indexOf(line0);
		if (i0 < 0) return 0;
		var i1 = this.lines.indexOf(line1);
		if (i1 < 0) return 0;
		var angle = this.angles[i1]-this.angles[i0];
		while (angle < 0) angle += 2 * Math.PI;
		return angle;
	}

	//******************************************************
	//*** Returns true for inner vertices
	//******************************************************
	is_inner() {
		for (var j in this.lines)
		{
			if (this.lines[j].slabs[0] && this.lines[j].slabs[1]) continue;
			return false;
		}
		return true;
	}

	/**
	 * Returns bounding box
	 * @returns {cn_box}
	 */
	get_bounding_box() {
		const box = new cn_box();
		box.enlarge_point(this.position);
		return box;
	}
}


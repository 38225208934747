import { Injectable } from '@angular/core';
import { Intervention, RelationInterventionBien } from '../../../../model/intervention.model';
import { Polluant } from '../model/polluant.model';
import { PolluantConfig } from '../model/polluant-config.model';
import { Observable } from 'rxjs';
import { PolluantApiService } from './polluant-api.service';
import {
    PointDeControleBien,
    PointDeControleNiveau,
    PointDeControleVolume,
} from 'src/app/model/point-de-controle.model';
import {
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    PARAM_VOLUME_LOT,
} from 'src/app/shared/constants/cndiag.constants';

@Injectable({
    providedIn: 'root',
})
export class PolluantService {
    constructor(private polluantApiService: PolluantApiService) {}

    //Fonction permettant de préselectionner un objectif de mesurage dans le formulaire
    compareObjectifs(obj1: any, obj2: any): boolean {
        return obj1.reference == obj2.reference;
    }

    //Fonction permettant de préselectionner un objectif de mesurage dans le formulaire
    compareZones(zone1: any, zone2: any): boolean {
        return zone1.id == zone2.id;
    }

    //Fonction permettant de préselectionner une simulation dans le formulaire
    compareSimulation(obj1: any, obj2: any): boolean {
        return obj1.label == obj2.label;
    }

    /**
     * Nettoie une section du diagnostic polluant, avant enregistrement
     * @param contenuDiagnostic Le diagnostic assainissement
     * @param key La section à nettoyer (string)
     * @param displayElement Boolean, si la section possède des sous-éléments (situation, ...)
     */
    private static cleanSection(contenuDiagnostic: Polluant, key: string, displayElement: boolean) {
        if (contenuDiagnostic[key].valeur && contenuDiagnostic[key].valeur.length === 0)
            contenuDiagnostic[key].valeur = null;
        if (displayElement) {
            contenuDiagnostic[key].elements =
                contenuDiagnostic[key].valeur === 'present' ? contenuDiagnostic[key].elements : [];
        }
    }

    prepareFormActiviteAmiante(intervention: Intervention, contenuDiagnostic: Polluant) {
        PolluantService.cleanSection(contenuDiagnostic, 'activitesAmiante', false);
    }

    /**
     * Renvoi la config polluant
     * @param id L'id de la config recherchée
     */
    findOnePolluantConfig(id: string): Observable<PolluantConfig> {
        return this.polluantApiService.findOnePolluantConfig(id);
    }

    findLastPolluantConfig(): Observable<PolluantConfig> {
        return this.polluantApiService.findLastPolluantConfig();
    }

    //find avec indexedDB
    findOnePolluantConfigIndexedDB(id: string): Observable<PolluantConfig> {
        return this.polluantApiService.findOnePolluantConfigOffline(id);
    }

    /**
     * Initialise les données
     * @parm
     */
    static initPolluant(formPointsDeControle: PointDeControleBien[], biensWithFlag: RelationInterventionBien[]) {
        // BIEN
        biensWithFlag.forEach((relationInterventionBien) => {
            let bienHasChanged = false;
            let newBien = false;
            let formBien = formPointsDeControle.find((f) => f.idBien === relationInterventionBien.bien.id);
            // Si le bien a été ajouté dans la description
            if (formBien === undefined) {
                newBien = true;
                formBien = new PointDeControleBien();
                formBien.idBien = relationInterventionBien.bien.id;
                formBien.valeursParametres[PARAM_VOLUME_LOT] = relationInterventionBien.bien.numeroLot;
            }
            // maj du nom du bien
            formBien.nomBien = relationInterventionBien.bien.nom;
            formBien.valeursParametres[PARAM_VOLUME_LOT] = relationInterventionBien.bien.numeroLot;

            // NIVEAU
            relationInterventionBien.bien.description.forEach((niveau) => {
                let niveauHasChanged = false;
                let newNiveau = false;
                let formNiveau = formBien.pointsDeControleNiveaux.find((f) => f.idNiveau === niveau.id);
                // Si le niveau a été ajouté dans la description
                if (formNiveau === undefined) {
                    newNiveau = true;
                    bienHasChanged = true;
                    formNiveau = new PointDeControleNiveau();
                    formNiveau.idNiveau = niveau.id;
                }
                // maj du nom du niveau
                formNiveau.nom = niveau.nom;
                formNiveau.index = niveau.index;

                // VOLUME
                // les volumes cachés ne sont pas utilisés dans les diagnostics mesurages
                niveau.volumes
                    .filter((v) => !v.volumeCache)
                    .forEach((volume) => {
                        let newVolume = false;
                        let formVolume = formNiveau.pointsDeControleVolumes.find((f) => f.idVolume === volume.id);
                        // Si le volume a été ajouté dans la description
                        if (formVolume === undefined) {
                            newVolume = true;
                            niveauHasChanged = true;
                            bienHasChanged = true;
                            formVolume = new PointDeControleVolume();
                            formVolume.idVolume = volume.id;
                            formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ] =
                                volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ];
                            formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE] =
                                volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE];
                            formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE] =
                                volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE];
                        }
                        // maj du nom du volume
                        formVolume.nom = volume.nom;
                        formVolume.valeursParametres[PARAM_VOLUME_LOT] = relationInterventionBien.bien.numeroLot;

                        // Si un volume a été ajouté
                        if (newVolume) {
                            formNiveau.pointsDeControleVolumes.push(formVolume);
                        }
                    });

                // Si un niveau a été ajouté
                if (niveauHasChanged && newNiveau) {
                    formNiveau.pointsDeControleVolumes.length && formBien.pointsDeControleNiveaux.push(formNiveau);
                }
                formBien.pointsDeControleNiveaux = formBien.pointsDeControleNiveaux.sort((a, b) => a.index - b.index);
            });

            // Si un bien a été ajouté
            if (bienHasChanged && newBien) {
                formBien.pointsDeControleNiveaux.length && formPointsDeControle.push(formBien);
            }
        });

        // Si on supprime des infos dans la description, maj du formulaire
        // BIEN
        formPointsDeControle.forEach((formBien) => {
            const bien = biensWithFlag.find((b) => b.bien.id === formBien.idBien);

            // Si le bien a été supprimé de la description
            if (bien === undefined) {
                formPointsDeControle.splice(formPointsDeControle.indexOf(formBien), 1);
            } else {
                formBien.pointsDeControleNiveaux.forEach((formNiveau) => {
                    const niveau = bien.bien.description.find((niv) => niv.id === formNiveau.idNiveau);

                    // Si le niveau a été supprimé de la description
                    if (niveau === undefined) {
                        formBien.pointsDeControleNiveaux.splice(
                            formBien.pointsDeControleNiveaux.indexOf(formNiveau),
                            1
                        );
                    } else {
                        formNiveau.pointsDeControleVolumes.forEach((formVolume) => {
                            const volume = niveau.volumes.find((vol) => vol.id === formVolume.idVolume);

                            // Si le volume a été supprimé de la description
                            if (volume === undefined) {
                                formNiveau.pointsDeControleVolumes.splice(
                                    formNiveau.pointsDeControleVolumes.indexOf(formVolume),
                                    1
                                );
                            }
                        });
                    }
                });
            }
        });
    }
}

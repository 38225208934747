import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PointDeControleBien } from 'src/app/model/point-de-controle.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { combineLatest } from 'rxjs';
import { InterventionService } from 'src/app/services/intervention.service';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { takeUntil } from 'rxjs/operators';
import { Intervention } from 'src/app/model/intervention.model';
import { Bien, Niveau, Volume } from 'src/app/model/bien.model';
import { Polluant } from '../../../model/polluant.model';
import { Besoin } from '../../../model/besoin.model';
import { Zone } from '../../../model/zone.model';
import { PolluantComponent } from '../../../utils/polluant-component';
import { IValidatableComponent } from '../../../services/interfaces/validatable-component.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { EtatValidation } from 'src/app/model/etat-progression.model';
import { PolluantService } from '../../../services/polluant.service';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { getRefInfo } from '../initialisation/objectifs/objectifs.component';
import { ObjectifsMesurage } from '../../../model/polluant-config.model';
import { ConditionsPrelevement } from '../../../model/polluant-config.model';
import { Surface } from '../../../model/surface.model';
import { PrevisiteComponent } from '../estimation/previsite/previsite.component';

@Component({
    selector: 'app-constatations-diverses',
    templateUrl: './besoins-prelevements.component.html',
    styleUrls: ['./besoins-prelevements.component.scss'],
})
export class BesoinsPrelevementsComponent extends PolluantComponent implements OnInit, IValidatableComponent {
    diagnostic: Diagnostic;

    biens: Bien[] = [];
    besoins: Besoin[] = [];
    volumes: Volume[] = [];
    filteredPointsDeControles: PointDeControleBien[] = [];

    zones: Array<Zone> = [];
    availableVolumesIdInZone: Array<Volume>;

    intervention: Intervention;
    currentDiagnostic: Diagnostic;
    contenuDiagnosticPolluant: Polluant;
    conditionsPrelevement: Array<any> = [];

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    //Utilisé pour définir si le sélécteur de Zones doit proposer l'option "Toutes les zones"
    includeAllZones: Boolean = true;

    indexBesoinSelected: number;

    numeroBesoins: any;

    form: FormArray;

    selectedZones: Zone | Zone[];
    polluantConfig: any;
    showZoneError: Boolean;

    static ecransDependants = [
        {
            composant: PrevisiteComponent,
            nom: 'estimations',
        },
    ];

    constructor(
        public interventionService: InterventionService,
        public dialog: MatDialog,
        private cnSpinnerService: CnSpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        public injector: Injector,
        public PolluantSvc: PolluantService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.cnSpinnerService.show();
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.route.data,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diagnostic, routerData]) => {
                    this.intervention = intervention;

                    this.polluantService
                        .findOnePolluantConfigIndexedDB(diagnostic.idConfig)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((polluantConfig) => {
                            this.polluantConfig = polluantConfig;

                            //Récupération des volumes dans la description du bien dans l'intervention
                            this.volumes = this.intervention.relationInterventionBiens[0].bien.description.reduce(
                                (acc: any, niveau: Niveau) => {
                                    return acc.concat(niveau.volumes);
                                },
                                []
                            );

                            this.currentDiagnostic = diagnostic;
                            this.contenuDiagnosticPolluant = diagnostic.contenuDiagnostic as Polluant;
                            this.isValidated = this.contenuDiagnosticPolluant.besoins.validated;

                            this.showZoneError = this.contenuDiagnosticPolluant.zones.errors.length != 0;

                            this.restoreData();

                            //Build form
                            this.form = BesoinsPrelevementsComponent.createForm(this.besoins, this.polluantConfig);
                            this.form.valueChanges.subscribe((selectedValue) => {
                                this.besoins = this.form.getRawValue();
                                this.checkValidity();
                            });
                            this.form.controls.forEach((formBesoin: FormGroup) => {
                                formBesoin.get('objectifMesurage').valueChanges.subscribe(() => {
                                    //A chaque changement sur l'objectif de mesurage
                                    //on recalcul le numéro de le numéro du besoin de prélèvement
                                    this.calculateObjNumber();
                                });
                            });

                            this.calculateObjNumber();
                            this.isInEditMode =
                                !(
                                    !!routerData.readonly ||
                                    this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)
                                ) && this.isInEditMode;
                            if (!this.isInEditMode) {
                                this.form.disable();
                            }
                            this.isLoaded = true;
                            this.cnSpinnerService.hide();
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    /**
     * Récupération des données du diagnostic
     */
    private restoreData() {
        this.zones = this.contenuDiagnosticPolluant.zones.data.zonesList;
        if (this.zones.length > 0) {
            this.selectedZones = this.zones[0];
        }
        this.besoins = this.contenuDiagnosticPolluant.besoins.data.besoinsList;

        this.besoins = this.besoins || [];

        // On vérifie si le nombre de besoins actuel correspond au nombre de besoin min de chaque zone
        this.zones.forEach((zone: Zone) => {
            const nbPu: number = this.puTotal(zone);

            // Pour chaque objectif de mesurage dans la zone
            zone.objectifMesurage.forEach((obj: any) => {
                // On vérifie si le nombre de besoins avec cet obj correspond au nombre de besoin attendu pour cette ref
                const ref = getRefInfo(this.polluantConfig.objectifsMesurage, obj.reference);
                const nbBesoins = this.getNbBesoins(nbPu, ref.methodeCalculQuantitePompe, zone);

                const f = this.besoins.filter((b) => {
                    return b.zoneId == zone.id && b.objectifMesurage.id == ref.id;
                });

                // Si le nombre de besoin actuel est différent du nombre de besoin attendu
                if (f.length < nbBesoins) {
                    const nbBesoinsACreer = nbBesoins - f.length;

                    for (let i = 0; i < nbBesoinsACreer; i++) {
                        const _nouveauBesoin = new Besoin();
                        _nouveauBesoin.numero = '';
                        _nouveauBesoin.objectifMesurage = obj;
                        _nouveauBesoin.zoneId = zone.id;
                        _nouveauBesoin.pieceId = zone.listeSurfaces[0].listeIdVolume[0];

                        this.besoins.push(_nouveauBesoin);
                    }
                }
            });
        });

        if (this.besoins.length) {
            this.besoinSelected({
                besoins: this.besoins,
                besoinIdToDisplay: 0,
                indexZoneSelected: 0,
            });
        }
        this.biens = this.intervention.relationInterventionBiens.map(
            (relationBienPrincipal) => relationBienPrincipal.bien
        );
    }

    /**
     * Création du formulaire contenant tous les besoins de prélèvements
     */
    public static createForm(besoins, polluantConfig): FormArray {
        // this.form = this.form ? this.form : new FormArray([]);
        let form = new FormArray([]);

        besoins.forEach((besoin: any, index: number) => {
            if (form.controls[index]) {
                return;
            }

            let require = Validators.nullValidator;
            if (besoin.emplacement == '') require = Validators.required;
            const formBesoin = new FormGroup({
                id: new FormControl(besoin.id),
                objectifMesurage: new FormControl(
                    besoin.objectifMesurage?.reference != undefined ? besoin.objectifMesurage : undefined,
                    [Validators.required]
                ),
                numero: new FormControl(besoin.numero),
                zoneId: new FormControl(besoin.zoneId),
                pieceId: new FormControl(besoin.pieceId, Validators.required),
                processusId: new FormControl(besoin.processusId),
                contexteMesure: new FormControl(besoin.contexteMesure),
                conditionPrelevement: new FormControl(besoin.conditionPrelevement),
                emplacement: new FormControl(besoin.emplacement, {
                    updateOn: 'blur',
                }),
                precisionLocalisation: new FormControl(besoin.precisionLocalisation, {
                    updateOn: 'blur',
                }),
                justificatifLocalisation: new FormControl(besoin.justificatifLocalisation, require),
                justificatifAutre: new FormControl(besoin.justificatifAutre),
                dureeMinPrelevement: new FormControl(besoin.dureeMinPrelevement),
                commentaire: new FormControl(besoin.commentaire),
                frequencePrelevement: new FormControl(besoin.frequencePrelevement),
                frequencePrelevementAutre: new FormControl(besoin.frequencePrelevementAutre),
            });

            form.push(formBesoin);
        });

        return form;
    }

    /**
     * @description Méthode appellée lorsqu'une action est effectuée sur la liste des besoins
     * @param data
     * @returns
     */
    besoinSelected(data: { besoins: Array<Besoin>; besoinIdToDisplay: number; indexZoneSelected: number }) {
        //Mise à jour de la liste des besoins
        this.besoins = data.besoins;

        this.indexBesoinSelected = data.besoinIdToDisplay;

        //Si suppression on recréé le formulaire avec les nouvelles valeurs
        this.form = BesoinsPrelevementsComponent.createForm(this.besoins, this.polluantConfig);
        this.form.valueChanges.subscribe((selectedValue) => {
            this.besoins = this.form.getRawValue();
            this.checkValidity();
        });
        this.form.controls.forEach((formBesoin: FormGroup) => {
            formBesoin.get('objectifMesurage').valueChanges.subscribe(() => {
                //A chaque changement sur l'objectif de mesurage
                //on recalcul le numéro de le numéro du besoin de prélèvement
                this.calculateObjNumber();
            });
        });

        this.calculateObjNumber();

        //Si l'option sélectionnée est "Toutes les zones" alors selectedZones est un tableau contenant toutes les zones
        // sinon selectedZones est un tableau ne contennant que la zone sélectionnée
        if (data.indexZoneSelected == this.zones.length) {
            this.selectedZones = this.zones;
        } else {
            this.selectedZones = [this.zones[data.indexZoneSelected] as Zone];
        }
        if (this.selectedZones[0].listeSurfaces == undefined && this.selectedZones[0].nom == 'Toutes les zones') {
            this.selectedZones = this.zones;
        }

        this.availableVolumesIdInZone = [];
        this.selectedZones.forEach((z: Zone) => {
            if (z.listeSurfaces != undefined) {
                z.listeSurfaces.forEach((surface: any) => {
                    if (surface.listeIdVolume != undefined) {
                        this.availableVolumesIdInZone = this.availableVolumesIdInZone.concat(surface.listeIdVolume);
                    }
                });
            }
        });
        this.checkValidity();
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];

        //Récupération de toutes les erreurs
        const erreursBesoins = [];
        // if (this.form.status.toString() == 'INVALID') {
        this.form.controls.forEach((formBesoin: FormGroup, index: number) => {
            let erreursBesoin = {
                numero: (index + 1).toString(),
                reference: formBesoin.get('objectifMesurage').value?.reference + ' ' + formBesoin.get('numero').value,
                erreurs: [],
            };

            //boucle sur les controles du formulaire pour trouver les erreurs
            for (const controlName in formBesoin.controls) {
                if (formBesoin.get(controlName).invalid == true || formBesoin.get(controlName).status == 'INVALID') {
                    erreursBesoin.erreurs.push(controlName);
                }
            }

            //Si il y'a des erreurs
            if (erreursBesoin.erreurs.length != 0) {
                erreursBesoins.push(erreursBesoin);
            }
        });

        this.contenuDiagnosticPolluant.besoins.data.besoinsList = this.form.getRawValue();

        this.contenuDiagnosticPolluant.besoins.errors = erreursBesoins;

        let etat: EtatValidation = 'INVALID';
        if (this.typeMesurage == 'POLLUANT_VISITE_CHANTIER') {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            // et si les données des Zones ont été validées
            if (
                this.form &&
                (this.form.valid || this.form.errors == null) &&
                this.contenuDiagnosticPolluant.besoins.validated
            ) {
                etat = 'VALID';
            }
        } else {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            if (this.form.valid) {
                etat = 'VALID';
            }
        }

        this.etatProgressionService.updateDiagnostic(code, etat, this.currentDiagnostic, true);
    }

    getNbBesoins(nbPu: number, methodeCalculQuantitePompe: string, zone: Zone): number {
        //Non applicable
        if (methodeCalculQuantitePompe == 'NON_APPLICABLE') {
            return 1;
        }

        //Nombre d'extracteurs
        if (methodeCalculQuantitePompe == 'NB_EXTRACTEURS') {
            //Nombre d'extracteurs défini dans la zone
            return zone.nombreExtracteurs;
        }

        //Colonne A et B
        if (nbPu == 1) {
            //Si 1 PU
            // Si la surface totale de la PU est < 10 alors seulement 1 besoin
            let surfaceTotalePu = zone.listeSurfaces.reduce((acc, surface) => {
                acc += surface.superficie;
                return acc;
            }, 0);

            if (surfaceTotalePu < 10) {
                return 1;
            } else {
                return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 2 : 2;
            }
        } else if (nbPu == 2) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 2 : 2;
        } else if (nbPu == 3 || nbPu == 4) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 2 : 3;
        } else if (nbPu == 5 || nbPu == 6) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 3 : 4;
        } else if (nbPu == 7 || nbPu == 8) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 3 : 5;
        } else if (nbPu >= 9 || nbPu <= 11) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 3 : 6;
        } else if (nbPu >= 12 || nbPu <= 14) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 3 : 7;
        } else if (nbPu >= 15 || nbPu <= 17) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 4 : 8;
        } else if (nbPu >= 18 || nbPu <= 20) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 4 : 9;
        } else if (nbPu >= 21 || nbPu <= 25) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 5 : 10;
        } else if (nbPu >= 26 || nbPu <= 31) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 5 : 11;
        } else if (nbPu >= 32 || nbPu <= 38) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 6 : 12;
        } else if (nbPu >= 39 || nbPu <= 46) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 6 : 13;
        } else if (nbPu >= 47 || nbPu <= 55) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? 7 : 14;
        } else if (nbPu > 55) {
            return methodeCalculQuantitePompe == 'POMPE_COLONNE_A' ? Math.round(nbPu / 8) : Math.round(nbPu / 4);
        }
    }

    /**
     * Calcul le nombre de PU total d'une zone
     */
    private puTotal(zone: Zone): number {
        return zone.listeSurfaces.reduce((total: any, surface: Surface) => {
            return total + Math.abs(surface.nombrePu ?? 0);
        }, 0);
    }

    /**
     * Fonction permettant de calculer le numéro de tous les obj de mesurage
     * En fonction des autres obj de mesurage du même type
     */
    calculateObjNumber() {
        let objs = {};

        //Pour chacun des besoins
        this.form.controls.forEach((formB: FormGroup, i: number) => {
            let besoin = formB.getRawValue();

            //Comptage du nombre de besoins de prélèvements ayant la même référence
            if (objs[besoin?.objectifMesurage?.reference] == undefined) {
                objs[besoin?.objectifMesurage?.reference] = [];
            }
            objs[besoin?.objectifMesurage?.reference].push(besoin.id);

            let numero = (i + 1).toString();

            besoin.numero = numero;

            //mise à jour du formulaire
            formB.get('numero').patchValue(numero);
        });
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.isInEditMode = false;
        this.contenuDiagnosticPolluant.besoins.data = this.previousFormValue;
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.contenuDiagnosticPolluant = BesoinsPrelevementsComponent.supprimerDonneesDependantes(
            this.contenuDiagnosticPolluant
        );

        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuDiagnosticPolluant.besoins.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.previousFormValue = this.contenuDiagnosticPolluant.besoins.data;
    }

    static supprimerDonneesDependantes(contenuDiagnostic: Polluant) {
        BesoinsPrelevementsComponent.ecransDependants.forEach(({ nom, composant }: any) => {
            //Suppression en cascade des données des écrans dépendants
            if (composant.supprimerDonneesDependantes) {
                let r = composant.supprimerDonneesDependantes(contenuDiagnostic);
            }

            contenuDiagnostic[nom].data = {};
        });

        return contenuDiagnostic;
    }

    navigateEcranZone() {
        const url = this.router.url.split('/');
        url.pop();
        url.push('zone');
        this.router.navigateByUrl(url.join('/'));
    }

    /**
     * Renvoi le FormGroup du besoin actuellement sélectionné
     */
    get formCurrentBesoin(): FormGroup {
        return this.form.controls[this.indexBesoinSelected] as FormGroup;
    }

    get besoinsList(): Besoin[] {
        return this.contenuDiagnosticPolluant.besoins.data;
    }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Environment } from '../environment';
import { Router } from '@angular/router';
import { ApiVersionService } from './api-version.service';

@Component({
    selector: 'lib-app-info-modal',
    templateUrl: './app-info-modal.component.html',
    styleUrls: ['./app-info-modal.component.scss'],
})
export class AppInfoModalComponent implements OnInit {
    /** Année */
    year: string;

    /** Nom de l'application */
    appName: string;

    /** Numéro de version de l'application (frontend) */
    appVersion: string;

    /** Numéro de version de l'API (backend) */
    apiVersion: string;

    constructor(
        private dialogRef: MatDialogRef<AppInfoModalComponent>,
        private apiVersionService: ApiVersionService,
        private router: Router
    ) {}

    ngOnInit() {
        // Renseigne l'année et le n° de version
        this.year = new Date().getFullYear().toString(10);
        this.appName = Environment.appName;
        this.appVersion = Environment.appVersion;
        this.apiVersionService.getApiVersion().subscribe((apiVersion) => (this.apiVersion = apiVersion));
    }

    showChangelog() {
        this.router.navigate(['/changelog']);
        this.dialogRef.close(false);
    }

    showLegal() {
        this.router.navigate(['/legal']);
        this.dialogRef.close(false);
    }

    close() {
        this.dialogRef.close(false);
    }
}

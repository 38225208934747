import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Document } from '../../../../../model/document.model';
import { Diagnostic } from '../../../../../model/diagnostic.model';
import { Intervention } from '../../../../../model/intervention.model';
import { ReferencePrestation } from 'src/app/model/reference-prestation.model';
import { InterventionService } from 'src/app/services/intervention.service';
import { EditDocumentModalComponent } from '../../edit-document-modal/edit-document-modal.component';
import { CnSpinnerService } from '../../../cn-spinner/service/cn-spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/commons-lib';
import { TypeCommentairePipe } from 'src/app/pipes/type-commentaire.pipe';

@Component({
    selector: 'app-documents-detail',
    templateUrl: './documents-detail.component.html',
    styleUrls: ['./documents-detail.component.scss'],
})
export class DocumentsDetailComponent implements OnInit, OnChanges {
    @Input() document: Document;
    @Input() diagnostic: Diagnostic;
    @Input() intervention: Intervention;
    @Input() readonlyMode: boolean;
    @Input() showCheckpoints = true;

    @Output() closedEditPanel = new EventEmitter<any>();

    // Permet de n'afficher que les checkpoints correspondant à la referencePrestation que l'on a dans l'intervention
    referencePrestationToShow: ReferencePrestation;
    // S'il n'y a pas de checkpoint pour la referencePrestaiton courante, pas besoin d'afficher le bloc point de controle
    hasCheckpoints = true;

    constructor(
        private readonly interventionService: InterventionService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly matDialog: MatDialog,
        private readonly notificationService: NotificationService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.updateCheckpoints();
    }

    ngOnInit(): void {
        // Recherche de la référence prestation correspondante au diagnostic courant
        this.updateCheckpoints();
    }

    private updateCheckpoints() {
        if (this.diagnostic) {
            this.referencePrestationToShow = this.interventionService.findReferencePrestationByIdDiagnostic(
                this.intervention,
                this.diagnostic
            );

            if (this.referencePrestationToShow) {
                const typeDocumentCheckpointCurrent = this.document.typeDocument.typeDocumentCheckpoint.find(
                    (typeDocumentCheckpointValue) => {
                        return this.referencePrestationToShow.id == typeDocumentCheckpointValue.referencePrestation.id;
                    }
                );

                this.hasCheckpoints =
                    typeDocumentCheckpointCurrent && typeDocumentCheckpointCurrent.checkpoints.length > 0;
            }
        }
    }

    /**
     * Edition du document
     */
    onClickEditDocument() {
        const documentToEditCopy = { ...this.document };
        return this.matDialog
            .open(EditDocumentModalComponent, {
                data: {
                    idIntervention: this.intervention.id,
                    document: documentToEditCopy,
                    typePrestation: undefined,
                    documentsAlreadyPresent: this.intervention.documents.map((it) => {
                        return {
                            nom: it.nom,
                            id: it.id,
                        };
                    }),
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.intervention.documents = this.intervention.documents.concat(result.document);
                    this.cnSpinnerService
                        .withSpinner(this.interventionService.updateIntervention(this.intervention))
                        .subscribe(() => {
                            this.notificationService.success('Le document a été modifié');
                            this.interventionService.reloadCurrentIntervention();
                        });
                }
            });
    }

    onCloseEditPanel(): void {
        this.closedEditPanel.emit();
    }
}

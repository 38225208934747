import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { AbstractControl, FormControl } from '@angular/forms';
import { ConfirmationService, FileUploaderComponent, HttpErrorService, NotificationService } from 'src/app/commons-lib';
import { FormService } from '../../../services/form.service';
import { FileApiService } from '../../../services/file-api.service';

@Component({
    selector: 'app-import-file',
    templateUrl: './import-file.component.html',
    styleUrls: ['./import-file.component.scss'],
})
export class ImportFileComponent implements OnInit {
    // Le nom du type de document qui va être enregistré. A ne pas confondre avec le nom du fichier.
    @Input() documentTitle: string;
    // Le code du type de document qui doit être enregistré en base de donnée
    @Input() codeTypeDocument: string;
    // Label du champs affiché si diplayLabel est à true (Optionnel)
    // Lorsque labelDocument n'est pas renseigné, le label affiché est documentTitle
    @Input() labelDocument: string = null;
    // Lien vers le champs dans le formulaire réactif qui gère le document (exemple : this.formParentComponent.get('formAttributeName'))
    @Input() fileFormControl: FormControl | AbstractControl;
    @Input() displayLabel: boolean = true;
    @Input() readOnlyMode: boolean = false;
    @Input() required: boolean = true;
    @ViewChild('fileUploader')
    fileUploader: FileUploaderComponent;

    fileFormData: FormData;

    get fileControl() {
        return this.fileFormControl;
    }

    constructor(
        private readonly formService: FormService,
        private readonly confirmationService: ConfirmationService,
        private readonly fileApiService: FileApiService,
        private readonly notificationService: NotificationService,
        private readonly httpErrorService: HttpErrorService
    ) {}

    ngOnInit(): void {}

    /**
     * Ouvre le fichier
     */
    onClickOpenFile(): void {
        if (this.fileControl.value) {
            if (this.fileControl.value.idFichier) {
                this.fileApiService.getFileToDownload(this.fileControl.value);
            }
        }
    }

    /**
     * Supprime le fichier
     */
    onClickDeleteFile(): void {
        this.fileControl.setValue(null);
    }

    /**
     * Ouvre la fenêtre de selection de fichier
     */
    onSelectFile(): void {
        if (!this.fileControl.value || !this.fileControl.value.id) {
            this.fileUploader.selectFile();
        } else {
            this.confirmationService.confirm('Changer le fichier attaché ?', () => {
                this.fileUploader.selectFile();
            });
        }
    }

    /**
     * Vérifie le format du fichier et upload
     * @param formData
     */
    onStartUploadFile(formData: FormData): void {
        const file = formData.get('file') as File;
        if (file.name.endsWith('.pdf')) {
            this.fileFormData = formData;

            this.fileUploader.handleUploadFileServiceCall(
                this.uploadDoc(this.fileFormData, this.codeTypeDocument, this.documentTitle)
            );
        } else {
            this.notificationService.error('Le fichier doit être au format .pdf');
        }
    }

    /**
     * Upload le document et set la value
     * @param formData
     * @param codeTypeDocument
     * @param documentTitle
     */
    uploadDoc(formData: FormData, codeTypeDocument: string, documentTitle: string): Observable<HttpEvent<any>> {
        return this.fileApiService.uploadDocumentFile(null, formData, codeTypeDocument, documentTitle).pipe(
            tap((res) => this.fileControl.setValue(res.body)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Action lorsque l'upload est terminé
     * @param event
     */
    onFinishUploadFile(event: boolean): void {
        if (event) {
            this.notificationService.success('Le fichier a bien été enregistré');
        } else {
            this.notificationService.error("Erreur lors de l'enregistrement du fichier");
        }
    }
}

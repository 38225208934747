<lib-file-uploader #fileUploader [isImage]="isImage" (startUpload)="onStartUploadFile($event)"></lib-file-uploader>
<mat-card *ngIf="!interventionFile?.fileId" class="d-flex w-100 action-card" [ngClass]="readonlyMode ? 'disabled' : ''">
    <mat-card-content class="w-100">
        <div>
            <button class="w-100" mat-button [disabled]="readonlyMode" (click)="onClickAddFile()">
                <mat-icon>{{ isImage ? 'add_a_photo' : 'file_upload' }}</mat-icon>
                <br />{{ textConfig.mainText }}
            </button>
        </div>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="interventionFile?.fileId" class="d-flex p-0 text-center">
    <mat-card-content class="w-100">
        <div>
            <button
                mat-icon-button
                class="position-absolute align-self-end button-group primary"
                (click)="onClickEdit()"
                [disabled]="readonlyMode"
            >
                <lib-icon class="icon-small">edit</lib-icon>
            </button>
            <div *ngIf="editionOverlay">
                <div class="position-absolute align-self-end button-group">
                    <button mat-icon-button color="warn" (click)="onClickCancel()" matTooltip="Annuler">
                        <lib-icon class="icon-small">cancel</lib-icon>
                    </button>
                    <button
                        mat-icon-button
                        color="warn"
                        (click)="onClickDeleteFile()"
                        [matTooltip]="textConfig.deleteToolTip"
                        [disabled]="readonlyMode"
                    >
                        <lib-icon class="icon-small">delete</lib-icon>
                    </button>
                    <button
                        mat-icon-button
                        color="primary"
                        (click)="onClickChangeFile()"
                        [matTooltip]="textConfig.changeToolTip"
                        [disabled]="readonlyMode"
                    >
                        <lib-icon class="icon-small">{{ isImage ? 'add_a_photo' : 'file_upload' }}</lib-icon>
                    </button>
                </div>
            </div>

            <ng-container *ngIf="isImage">
                <app-picture-file-uploader
                    [fileId]="fileId"
                    [dataUrl]="dataUrl"
                    [useDownloadPipe]="useDownloadPipe"
                ></app-picture-file-uploader>
            </ng-container>
            <ng-container *ngIf="!isImage && fileName">
                <app-document-file-uploader
                    [nomFichier]="fileName"
                    (fileDeleted)="onClickDeleteFile()"
                ></app-document-file-uploader
            ></ng-container>
        </div>
    </mat-card-content>
</mat-card>

"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_storey : class that contains a storey
//***********************************************************************************
//***********************************************************************************

import {fh_polygon, fh_solid} from "@acenv/fh-3d-viewer";
import { cn_background_map, cn_sampling } from '..';
import {cn_element} from "./cn_element";
import {cn_marker} from "./cn_marker";
import {cn_roof} from "./cn_roof";
import {cn_scene} from "./cn_scene";
import {cn_slab} from "./cn_slab";
import {cn_space_area} from "./cn_space_area";
import {cn_object} from "./cn_object";
import {cn_object_instance} from "./cn_object_instance";

export const STOREY_EXTERIOR_LABEL = 'Site';

export class cn_storey extends cn_element {
    constructor(building) {
        super(building);

        this.storey_index = 0;
        this.exterior = false;
        this.height = 2.5;
        this.clone_of = null;
        this.scene = new cn_scene(building);
        this.scene.storey = this;
        this.slabs = [];
        this.markers = [];
        this.samplings = [];
        this.roof = null;
        this.name = "";
        this.short_name = "";
        this.background_maps = [];
        this.space_areas = [];

        //*** Scene volatile data
        this.building = building;
        this.removable = false;
        this.ignored_polygon = null;

        /** Computed by / for the 3D */
        this.altitude = 0;
        this.roof_altitude = 0;

        //*** 3D data */
        //*** Roof volume is the volume that comprises the inner volumes of the spaces. 0 is at the storey level. */
        this.roof_volume = null;
        //*** facing volume is roof volume + lower slabs volume. */
        this.facing_volume = null;
    }

    //*******************************************************
    //**** serialize
    //*******************************************************
    serialize() {
        var json = {};
        json.ID = this.ID;
        json.storey_index = this.storey_index;
        json.height = this.height;
        json.name = this.name;
        if (this.clone_of)
            json.clone_of = this.clone_of.s_index;
        else
            json.clone_of = -1;
        if (this.clone_of == null || this.clone_of == this)
            json.scene = this.scene.serialize();
        else
            json.scene = null;

        json.slabs = [];
        for (var i in this.slabs)
            json.slabs.push(this.slabs[i].serialize());

        json.markers = [];
        for (var i in this.markers)
            json.markers.push(this.markers[i].serialize());

        json.samplings = this.samplings.map(sampling => sampling.serialize());

        if (this.roof)
            json.roof = this.roof.serialize();
        else
            json.roof = null;

        json.background_maps = this.background_maps.map(bm => bm.image_id);

        json.space_areas = this.space_areas.map(sa => sa.serialize());

        return json;
    }

    unserialize(json) {
        if (typeof (json.storey_index) != 'number')
            throw "Error reading storey : 'storey_index' not found";
        if (typeof (json.clone_of) != 'number')
            throw "Error reading storey : 'clone_of' not found";
        if (json.scene != null && typeof (json.scene) != 'object')
            throw "Error reading storey : 'scene' not found";

        if (typeof (json.ID) == 'string')
            this.ID = json.ID;
        this.storey_index = json.storey_index;
        if (typeof (json.height) == 'number')
            this.height = json.height;
        if (typeof (json.name) == 'string') {
            this.name = json.name;
        }
        if (json.clone_of >= 0)
            this.clone_of = this.building.storeys[json.clone_of];

        if (json.scene)
            this.scene = cn_scene.unserialize(json.scene, this.building);
        this.scene.storey = this;

        if (json.slabs) {
            for (var i in json.slabs) {
                var s = cn_slab.unserialize(json.slabs[i], this);
                if (s)
                    this.slabs.push(s);
            }
        }

        if (typeof (json.roof) == 'object' && json.roof)
            this.roof = cn_roof.unserialize(json.roof, this);

        if (json.background_maps && json.background_maps.length) {
            this.background_maps = json.background_maps
                .map(bm => this.building.maps.find(b => b.image_id === bm))
                .filter(it => !!it);
        }

        if (json.samplings && json.samplings.length) {
            this.samplings = json.samplings.map(sampling => cn_sampling.unserialize(sampling, this));
        }

        if (json.space_areas && json.space_areas.length) {
            var obj = this;
            json.space_areas.foreach(jsa => cn_space_area.unserialize(jsa, obj));
        }
    }

    //*******************************************************
    /**
     * Unserialize markers (special, as it refers to elements that might be everywhere in the building)
     */
    unserialize_markers(json) {
        if (json.markers && json.markers.length) {
            this.update_slabs();
            for (var i in json.markers) {
                var marker = cn_marker.unserialize(json.markers[i], this);
                if (marker)
                    this.markers.push(marker);
            }
        }
    }

    //*******************************************************
    //**** Returns previous storey
    //*******************************************************

    get_previous_storey() {
        if (this.exterior) {
            return this.building.storeys[this.building.storey_0_index];
        }
        return this.building.find_storey_by_index(this.storey_index - 1);
    }

    is_underground() {
        if (this.building)
            return (this.storey_index < this.building.storey_0_index);
        return false;
    }

    //*******************************************************
    //**** Build slab polygon
    //*******************************************************
    build_slab_polygon(z, balconies, slab_openings) {
        var polygon = new fh_polygon([0, 0, z], [0, 0, 1]);
        for (var i in this.scene.spaces) {
            var space = this.scene.spaces[i];
            if (space.outside) continue;
            if (!balconies && !space.has_roof) continue;
            polygon.unites(space.build_outer_polygon(0, slab_openings));
        }
        return polygon;
    }

    //*******************************************************
    //**** Update roof
    //*******************************************************
    update_roof(roof_footprint = null, upper_footprint = null) {
        console.log("Update roof for storey " + this.storey_index);
        if (roof_footprint) {
            roof_footprint.offset(-0.1);
            roof_footprint.offset(0.1);
            if (upper_footprint)
                upper_footprint.substracts(roof_footprint);
        }
        if (roof_footprint == null || roof_footprint.get_area() < 1.0 || roof_footprint.compute_inner_diameter() < 0.2) {
            this.roof = null;
            console.log("Storey " + this.storey_index + " has NO roof");
            return;
        }
        if (this.roof == null)
            this.roof = new cn_roof(this);

        this.roof.update_from_footprint(roof_footprint, upper_footprint);
        console.log("Storey " + this.storey_index + " has a roof");
    }

    //*******************************************************
    //**** Is roof up te date ?
    //*******************************************************
    roof_up_to_date() {
        if (this.roof) return this.roof.up_to_date();
        return true;
    }

    //*******************************************************
    /**
     * returns max inner height of the storey, taking roof into account.
     * @returns {number}
     */
    get_max_height() {
        if (this.roof == null)
            return this.height;
        return this.height + this.roof.get_max_height();
    }

    //*******************************************************
    /**
     * Compute ceiling height at given 2D position
     * @param {number[]} position
     * @returns {number}
     */
    compute_height(position, use_storey_height_for_outside = false) {
        if (!this.roof) return this.height;

        var hroof = this.roof.compute_height(position);
        if (hroof === false) return this.height;
        return this.height + hroof;
    }


    //*******************************************************
    /**
     * Returns another storey relative to this
     * @param {number} offset
     * @returns {cn_storey}
     */
    get_relative_storey(offset) {
        const index = this.building.storeys.indexOf(this);
        if (index + offset < 0 || index + offset >= this.building.storeys.length)
            return null;
        return this.building.storeys[index + offset];
    }

    //*******************************************************
    /**
     * Compute floor position at given point, relatively to the storey z position.
     * Basically this function will return 0, except if the slab at given point has an offset.
     * @param {number[]} position
     * @returns {number}
     */
    compute_z_floor(position) {
        var sp = this.scene.find_space(position);
        if (sp) return sp.slab_offset;
        return 0;
    }

    //*******************************************************
    /**
     * Compute ceiling position at given point, relatively to the storey z position.
     * this takes roof into account.
     * @param {number[]} position
     * @param {boolean} use_space_ceiling_height
     * @returns {number}
     */
    compute_z_ceiling(position, use_space_ceiling_height = true) {
        var zroof = this.height;
        var index = this.building.storeys.indexOf(this);
        if (index >= 0 && index + 1 < this.building.storeys.length) {
            var spup = this.building.storeys[index + 1].scene.find_space(position);
            if (spup) zroof += spup.slab_offset;
        }
        if (this.roof) {
            var zr = this.roof.compute_height(position);
            if (typeof (zr) == 'number') zroof = this.height + zr;
        }

        if (use_space_ceiling_height) {
            var z0 = 0;
            var sp = this.scene.find_space(position);
            if (sp) {
                z0 = sp.slab_offset;

                var dz = zroof - z0;
                if (sp.ceiling_height > 0 && dz > sp.ceiling_height) dz = sp.ceiling_height;
                zroof = dz + z0;
            }
        }

        return zroof;
    }

    //*******************************************************
    /**
     * Returns 'true' if storey has at least one space with non zero slab offset.
     * @returns {boolean}
     */
    has_slab_offset() {

        if (this.scene.spaces.some(sp => sp.slab_offset!=0)) return true;

        if (this.slabs.length > 0)
        {
            const thickness = this.slabs[0].slab_type.thickness;
            if (this.slabs.some(slab => slab.slab_type.thickness != thickness)) return true;
        }

        return false;
    }

    //*******************************************************
    /**
     * Builds a solid the forms the roof of the storey.
     * @param {boolean} add_slab_volumes : if true, will unites with slab volumes
     * @returns {fh_solid}
     */
    build_roof_volume(add_slab_volumes = false) {
        const h1 = this.height;
        const trace = true;
        const threshold = 0.01;
        const nbs = this.building.storeys.indexOf(this);
        const upper_storey = (nbs < this.building.storeys.length - 1) ? this.building.storeys[nbs + 1] : null;

        if (add_slab_volumes)
            this.facing_volume = null;
        else
            this.roof_volume = null;
        //*** initialize solid */
        var box = this.scene.get_bounding_box();
        if (!box.posmin) return null;

        box.enlarge_distance(1);
        var p0 = box.posmin;
        p0.push(0);
        const roof_volume = new fh_solid();
        roof_volume.brick(p0,[box.size[0],0,0],[0,box.size[1],0],[0,0,h1]);

        //*** Compute floor extrema */
        var z0_max = -Infinity;
        this.slabs.forEach(slab => {
            if (slab.spaces[1])
            {
                var dz = slab.spaces[1].slab_offset;
                if (add_slab_volumes) dz -= slab.slab_type.thickness;
                if (dz > z0_max) z0_max = dz;
            }
        });
        if (z0_max > h1) z0_max = h1;

        //*** remove  / unites max height of floor */
        if (z0_max > threshold)
        {
            var floor = this.build_slab_polygon(-1, true, false);
            floor.offset(threshold);
            const slab_remove = new fh_solid();
            slab_remove.extrusion(floor,[0,0,1+z0_max]);
            roof_volume.substracts(slab_remove);
            if (trace) console.log("removes max height", z0_max, 0);
        }
        else if (z0_max < -threshold)
        {
            var floor = this.build_slab_polygon(z0_max, true, false);
            floor.offset(threshold);
            const slab_unite = new fh_solid();
            slab_unite.extrusion(floor,[0,0,threshold - z0_max]);
            roof_volume.unites(slab_unite);
            if (trace) console.log("unites max height", z0_max, 0);
        }

        //*** adds slab extrusions */
        this.slabs.forEach(slab => {
            if (slab.spaces[1])
            {
                var z = slab.spaces[1].slab_offset;
                if (add_slab_volumes) z -= slab.slab_type.thickness;
                if (z < z0_max - threshold)
                {
                    var polygon = slab.build_polygon(z);
                    polygon.offset(threshold);
                    var slab_solid = new fh_solid();
                    slab_solid.extrusion(polygon, [0, 0, z0_max + threshold - z]);
                    roof_volume.unites(slab_solid);
                    if (trace) console.log("add slab volume");
                }
            }
        });

        //*** remove balconies */
        if (add_slab_volumes)
        {
            const max_slab_thickness = this.get_max_slab_thickness();
            this.slabs.filter(slab => slab.spaces[1] && !slab.spaces[1].indoor).forEach(slab => {
                var z = slab.spaces[1].slab_offset;
                var polygon = slab.build_polygon(z - max_slab_thickness - threshold);
                polygon.offset(threshold);
                var slab_solid = new fh_solid();
                slab_solid.extrusion(polygon, [0, 0, max_slab_thickness + threshold]);
                roof_volume.substracts(slab_solid);
            });
        }

        //*** Compute upper storey extrema */
        if (upper_storey)
        {
            var z1 = h1 + upper_storey.get_max_slab_thickness();
            var z1u_min = Infinity;
            upper_storey.slabs.forEach(slab => {
                if (slab.spaces[1])
                {
                    const z = z1 + slab.spaces[1].slab_offset - slab.slab_type.thickness;
                    if (z < z1u_min) z1u_min = z;
                }
            });
            if (z1u_min < z0_max) z1u_min = z0_max;

            //*** remove min height hole */
            if (z1u_min < h1 - threshold)
            {
                var upper_floor = upper_storey.build_slab_polygon(z1u_min, true, false);
                upper_floor.offset(threshold);
                const slab_remove = new fh_solid();
                slab_remove.extrusion(upper_floor,[0,0,h1 + threshold - z1u_min]);
                roof_volume.substracts(slab_remove);
                if (trace) console.log("remove upper storey");
            }
            else if (z1u_min > h1 + threshold)
            {
                var upper_floor = upper_storey.build_slab_polygon(h1 - threshold, true, false);
                upper_floor.offset(threshold);
                const slab_unite = new fh_solid();
                slab_unite.extrusion(upper_floor,[0,0,z1u_min - h1 - threshold]);
                roof_volume.substracts(slab_unite);
                if (trace) console.log("unites upper storey");
            }

            //*** adds slab extrusions */
            upper_storey.slabs.forEach(slab => {
                if (slab.spaces[1])
                {
                    const z = z1 + slab.spaces[1].slab_offset - slab.slab_type.thickness;
                    if (z > z1u_min + threshold)
                    {
                        var polygon = slab.build_polygon(z1u_min - threshold);
                        polygon.offset(threshold);
                        var slab_solid = new fh_solid();
                        slab_solid.extrusion(polygon, [0, 0, z - z1u_min + threshold]);
                        roof_volume.unites(slab_solid);
                        if (trace) console.log("unites upper slab");
                    }
                }
            });
        }

        //*** Compute roof extrema */
        if (this.roof)
        {
            var zt_min = Infinity;
            var zt_max = -Infinity;
            this.roof.slabs.forEach(slab => {
                slab.contours.forEach(contour => {
                    contour.vertices.forEach(v => {
                        const z = h1 + slab.compute_height(v.position);
                        if (z > zt_max) zt_max = z;
                        if (z < zt_min) zt_min = z;
                    });
                });
            });
            if (zt_min < z0_max) zt_min = z0_max;

            //*** remove min height hole */
            if (zt_min < h1 - threshold)
            {
                var roof_footprint = this.roof.build_footprint(zt_min);
                roof_footprint.offset(threshold);
                const slab_remove = new fh_solid();
                slab_remove.extrusion(roof_footprint,[0,0,h1 + threshold - zt_min]);
                roof_volume.substracts(slab_remove);
                if (trace) console.log("remove roof footprint");
            }
            else if (zt_min > h1 + threshold)
            {
                var roof_footprint = this.roof.build_footprint(h1 - threshold);
                roof_footprint.offset(threshold);
                const slab_unite = new fh_solid();
                slab_unite.extrusion(roof_footprint,[0,0,zt_min - h1 + threshold]);
                roof_volume.substracts(slab_unite);
                if (trace) console.log("unites roof footprint");
            }

            //*** add roof slabs */
            if (zt_max > zt_min + threshold)
            {
                this.roof.slabs.forEach(slab => {
                    var roof_footprint = slab.build_slab_polygon(zt_min-threshold);
                    roof_footprint.offset(threshold);
                    const slab_unite = new fh_solid();
                    slab_unite.extrusion(roof_footprint, [0, 0, zt_max - zt_min + threshold]);
                    var pz = h1 + slab.compute_height([0, 0]);
                    slab_unite.clip([0, 0, pz], slab.normal);
                    roof_volume.unites(slab_unite);
                    if (trace) console.log("unites roof slab");
                });
            }
        }

        if (add_slab_volumes)
            this.facing_volume = roof_volume;
        else
            this.roof_volume = roof_volume;
        return roof_volume;
    }

    //*******************************************************
    /**
     * Update all slabs of the storey
     */
    update_slabs() {
        //*** Old slabs : are there different slab types ?  */
        var common_slab_type = null;
        for (var ki in this.slabs) {
            if (common_slab_type == null)
                common_slab_type = this.slabs[ki].slab_type;
            else if (this.slabs[ki].slab_type != common_slab_type) {
                common_slab_type = null;
                break;
            }
        }

        //*** No common slab type ? create polygons for each old slab */
        if (common_slab_type == null) {
            for (var ki in this.slabs)
                this.slabs[ki].polygon = this.slabs[ki].build_polygon(0);
        }

        //***loop on new slabs */
        var slabs = this.building.build_slabs(this.storey_index - 1, this.storey_index, true);
        var new_slabs = [];
        for (var i in slabs) {
            //*** Only lower or intermediate */
            var slab = slabs[i];
            if (slab.spaces[1] == null) continue;
            new_slabs.push(slab);

            //*** Maube no problem on slab type */
            if (common_slab_type) {
                slab.slab_type = common_slab_type;
                continue;
            }

            //** otherwise compute best slab type (highest intersection area with old slabs) */
            var pg = slab.build_polygon(0);
            var highest_intersection = 0;
            slab.slab_type = null;
            for (var j in this.slabs) {
                var p = pg.clone();
                p.intersects(this.slabs[j].polygon);
                var area = p.get_area();
                if (area <= highest_intersection) continue;
                highest_intersection = area;
                slab.slab_type = this.slabs[j].slab_type;
            }

            //*** In the end, apply default slab type */
            if (slab.slab_type == null)
                slab.slab_type = this.building.get_floor_types()[0];
        }
        this.slabs = new_slabs;

        this.refresh_slabs_markers();
    }

    //*******************************************************
    /**
     * Update markers
     */
    update_markers() {
        this.markers.forEach(marker => {
            marker.update();
        })
    }

     //*******************************************************
    /**
     * refresh slabs markers
     */
     refresh_slabs_markers() {
        this.markers.filter(marker => marker.element && marker.element.constructor === cn_slab).forEach(marker => {
            marker.refresh_slab_element(this);
        })
    }

    //*******************************************************
    /**
     * Returns max slab thickness
     * @returns {number}
     */
    get_max_slab_thickness() {
        var max_thickness = 0;
        for (var i in this.slabs) {
            if (this.slabs[i].slab_type == null) continue;
            var v = this.slabs[i].slab_type.thickness;
            if (v > max_thickness)
                max_thickness = v;
        }
        return max_thickness;
    }

    /**
     *  Get storey name by level
     *
     *  @returns {string}
     */
    get_storey_name() {
        let result = this.exterior ? STOREY_EXTERIOR_LABEL : this.name;
        if (!result) {
            const storeyLevel = parseInt(this.short_name);
            if (Number.isInteger(storeyLevel)) {
                result = storeyLevel < 0 ? "Sous-sol" : "Niveau";
                result += " " + Math.abs(storeyLevel);
            } else {
                result = this.short_name;
            }
        }
        return result;
    }

    //*******************************************************
    /**
     * Find an element by constructor name and index
     * @param {string} constructor_name
     * @param {number} index
     * @returns {object}
     */
    find_element(constructor_name, index) {
        var obj = null;
        if (constructor_name == "cn_slab")
            obj = this.slabs[index];
        else if (constructor_name == "cn_marker")
            obj = this.markers[index];
        else
            obj = this.scene.find_element(constructor_name, index);

        if (obj) return obj;
        return null;
    }

    //*******************************************************
    /**
     * Find an element by ID
     * @param {string} id
     * @returns {cn_element}
     */
    find_element_by_id(id) {
        const elements = [...this.slabs, ...this.markers, ...this.samplings];
        var obj = elements.find(e => e.ID == id);
        if (obj) return obj;
        if (this.scene)
        {
            obj = this.scene.find_element_by_id(id);
            if (obj) return obj;
        }
        return null;
    }

    //*******************************************************
    /**
     * Find amarker under position
     * @param {number[]} position
     * @param {number} tolerance
     * @returns {object}
     */
    find_marker(position, tolerance = 0) {
        return this.markers.find(marker => marker.contains(position, tolerance));
    }

    //*******************************************************
    /**
     * Find sampling under position
     * @param {number[]} position
     * @param {number} tolerance
     * @returns {object}
     */
    find_sampling(position, tolerance = 0) {
        return this.samplings.find(sampling => sampling.contains(position, tolerance));
    }

    //*******************************************************
    /**
     * Find slab under position
     * @param {number[]} position
     * @param {number} tolerance
     * @returns {object}
     */
    find_slab(position, tolerance = 0) {
        for (var i in this.slabs) {
            if (this.slabs[i].contains(position))
                return this.slabs[i];
        }
        return null;

    }

    //*******************************************************
    /**
     * Clean all area data, depending on a list of labels.
     * @param {string[]} label_list
     */
    clean_areas(label_list) {

    }

    //*******************************************************
    //**** find background map by image id
    //*******************************************************
    find_background_map(image_id) {
        for (var i in this.background_maps) {
            if (this.background_maps[i].image_id == image_id)
                return this.background_maps[i];
        }
        return null;
    }

    //*******************************************************
    //**** new background map
    //*******************************************************
    new_background_map(image_id, width, height) {
        var bg = null;

        //*** Maybe we replace an image ?
        for (var i in this.background_maps) {
            if (this.background_maps[i].image_id != image_id) continue;
            bg = this.background_maps[i];
            break;
        }

        //** maybe we create a new image
        if (bg == null) {
            bg = new cn_background_map();
            this.background_maps.push(bg);
        }
        bg.image_id = image_id;
        bg.image_size[0] = width;
        bg.image_size[1] = height;
        bg.scale = 10 / width;

        return bg;
    }

    //*******************************************************
    //**** new background map
    //*******************************************************
    remove_background_map(image_id) {
        for (let i = 0; i < this.background_maps.length; i++) {
            if (this.background_maps[i].image_id !== image_id) continue;
            this.background_maps.splice(i, 1);
            break;
        }
    }

    //*******************************************************
    /**
     * Returns the list of object instances using given type.
     * @param {cn_object} object
     * @returns {cn_object_instance[]} Number of elements of that type.
     */
    get_object_instances(object) {
        const list = [];
        this.scene.object_instances.forEach(instance => {
            if (instance.object.ID === object.ID)
                list.push(instance);
        });
        return list;
    }

    //*******************************************************
    /**
     * Returns the list of object instances using given type in a space.
     * @param {cn_object} object
     * @param {string} space_id
     * @returns {cn_object_instance[]} Number of elements of that type.
     */
    get_space_object_instances(object, space_id) {
        const list = [];
        this.scene.object_instances.forEach(instance => {
            if (instance.space.ID === space_id && instance.object.ID === object.ID)
                list.push(instance);
        });
        return list;
    }

    /**
     * Builds polygons for each couple slab / space.
     * Each polygon in output has extra parameters :
     * - slab : the related roof slab
     * - space : the space below
     * - openings : the list of polygons matcvhing roof openings.
     * Each polygon of a roof opening hhas as an extra parameter the roof opening.
     * @returns {Array<fh_polygon>}
     */
    build_roof_polygons(z=0){
        if (!this.roof) return [];

        var roof_polygons = [];
        var ceiling_slabs = [];
        for (var i in this.scene.spaces) {
            var space = this.scene.spaces[i];
            if (space.outside) continue;
            if (!space.has_roof) continue;
            var pg = space.build_slab_polygon(0, false, false);
            pg["space"] = space;
            ceiling_slabs.push(pg);
        }

        //*** Build geometry of roof openings */
        this.roof.openings.forEach(op => op.polygon = op.build_3d_opening(z+this.height));

        //*** Then loop on roof slabs */
        this.roof.slabs.forEach(slab => {
            const roof_openings = this.roof.openings.filter(op => op.slab == slab);
            //*** Build roof slab polygon */
            var roof_slab_polygon = slab.build_3d_polygon(z+this.height);

            //*** Check intersection with ceiling slabs */
            for (var j in ceiling_slabs) {
                var roof_polygon = ceiling_slabs[j].clone();
                roof_polygon.project(roof_slab_polygon.get_point(), roof_slab_polygon.get_normal(), [0, 0, 1]);
                roof_polygon.intersects(roof_slab_polygon);
                if (roof_polygon.get_area() < 0.01) continue;

                roof_polygon["space"] = ceiling_slabs[j]["space"];
                roof_polygon["slab"] = slab;
                const roof_openings = [];
                roof_polygon["openings"] = roof_openings;
                roof_openings.forEach(roof_opening => {
                    var pg = roof_opening.polygon.clone();
                    pg.interects(roof_polygon);
                    if (pg.get_area() > 0.1)
                    {
                        roof_openings.push(pg);
                        roof_openings["opening"] = roof_opening;
                    }
                });
                roof_polygons.push(roof_polygon)
            }
        });
        return roof_polygons;
    }
}


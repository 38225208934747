import { Injectable } from '@angular/core';
import {
    DetailPhoto,
    Diagnostic,
    PresetPhoto,
    presetPhotoToLabel,
    ReportagePhoto,
    ReportagePhotoImageFile,
} from '../model/diagnostic.model';
import { ReportagePhotoData, ReportagePhotoDataPage, ReportagePhotoDataPageItem } from '../model/rapport.model';
import { URL_MS_REPORT_FILE } from '../shared/constants/cndiag.constants';
import { FileData } from '../shared/offline/offline-storage.service';
import { InterventionFile, TypeReferenceFichier } from '../model/intervention-file.model';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FileApiService } from './file-api.service';
import { Intervention } from '../model/intervention.model';
import { Hap } from '../modules/diagnostics/hap/model/hap.model';
import { combineLatestOrEmpty } from '../utils/rxjs.utils';
import { MongoUtils } from 'src/app/commons-lib';
import { Prelevement } from '../model/prelevement-generique.model';
import { Espace } from '../model/espace.model';

@Injectable({
    providedIn: 'root',
})
export class ReportagePhotoService {
    constructor(private readonly fileApiService: FileApiService) {}

    buildReportagePhotoData(diagnostic: Diagnostic): ReportagePhotoData[] {
        const reportagesPhotoData: ReportagePhotoData[] = [];

        diagnostic.reportagesPhoto.forEach((reportage) => {
            const reportagePhotoData = new ReportagePhotoData();
            reportagePhotoData.nom = reportage.nom;
            reportagePhotoData.pages = [];
            reportage.photos.forEach((detailPhoto, index) => {
                const reportagePhotoDataPageItem = new ReportagePhotoDataPageItem();
                reportagePhotoDataPageItem.idFichier = detailPhoto.idFichier;
                reportagePhotoDataPageItem.urlFichier = URL_MS_REPORT_FILE + detailPhoto.idFichier;
                reportagePhotoDataPageItem.legende = detailPhoto.legende;
                reportagePhotoDataPageItem.creationDate = detailPhoto.creationDate;
                reportagePhotoDataPageItem.gpsLatitudeRef = detailPhoto.gpsLatitudeRef;
                reportagePhotoDataPageItem.gpsLatitude = detailPhoto.gpsLatitude;
                reportagePhotoDataPageItem.gpsLongitudeRef = detailPhoto.gpsLongitudeRef;
                reportagePhotoDataPageItem.gpsLongitude = detailPhoto.gpsLongitude;
                reportagePhotoDataPageItem.gpsAltitudeRef = detailPhoto.gpsAltitudeRef;
                reportagePhotoDataPageItem.gpsAltitude = detailPhoto.gpsAltitude;

                // On crée une nouvelle ligne toutes les 2 photos
                if (index % 4 === 0) {
                    const reportagePhotoDataPage = new ReportagePhotoDataPage();
                    reportagePhotoDataPage.reportagePhotoDataPageItem.push(reportagePhotoDataPageItem);
                    reportagePhotoData.pages.push(reportagePhotoDataPage);
                } else {
                    const reportagePhotoDataPage = reportagePhotoData.pages[reportagePhotoData.pages.length - 1];
                    reportagePhotoDataPage.reportagePhotoDataPageItem.push(reportagePhotoDataPageItem);
                }
            });

            reportagesPhotoData.push(reportagePhotoData);
        });

        return reportagesPhotoData;
    }

    /**
     * Renvoie un objet contenant les informations utiles à l'affichage de l'écran.
     * @param file
     * @param interventionFile
     */
    mapReportagePhotoImageFile(file: FileData, interventionFile: InterventionFile): ReportagePhotoImageFile {
        const reportageImageFile: ReportagePhotoImageFile = new ReportagePhotoImageFile();
        reportageImageFile.fileId = file.fileId;
        reportageImageFile.fileContent = file.fileContent;
        reportageImageFile.selected = false;
        reportageImageFile.typeReferenceFichier = interventionFile.typeReferenceFichier;
        reportageImageFile.creationDate = new Date(+interventionFile.creationDate).toLocaleDateString();
        reportageImageFile.gpsAltitude = interventionFile.gpsAltitude ?? 'Non renseigné';
        reportageImageFile.gpsAltitudeRef = interventionFile.gpsAltitudeRef ?? 'Non renseigné';
        reportageImageFile.gpsLatitude = interventionFile.gpsLatitude ?? 'Non renseigné';
        reportageImageFile.gpsLatitudeRef = interventionFile.gpsLatitudeRef ?? 'Non renseigné';
        reportageImageFile.gpsLongitude = interventionFile.gpsLongitude ?? 'Non renseigné';
        return reportageImageFile;
    }

    /**
     * Récupère la liste des fichiers assosciés aux interventionFiles fournis en paramètre
     * @param interventionFiles
     * @private
     */
    getFiles(interventionFiles: InterventionFile[]): Observable<ReportagePhotoImageFile>[] {
        const observablesImage$: Observable<FileData>[] = [];

        interventionFiles.forEach((interventionFile) => {
            observablesImage$.push(
                this.fileApiService.downloadFile(interventionFile.fileId).pipe(
                    map((file) => {
                        return this.mapReportagePhotoImageFile(file, interventionFile);
                    })
                )
            );
        });
        return observablesImage$ as Observable<ReportagePhotoImageFile>[];
    }

    getPresetsReportagesPhotosAutoForRefPrestation(intervention: Intervention, diagnostic: Diagnostic): PresetPhoto[] {
        return intervention.prestationsDiagnostics.find((diag) => diag.idDiagnostic === diagnostic.id).prestation
            .presetsReportagePhotosAuto;
    }

    /**
     * Permet de coupler le type de preset et le type de commentaire à filtrer
     * @param preset
     * @private
     */
    getCorrespondanceTypeCommentaire(preset: PresetPhoto): string {
        let filter;
        switch (preset) {
            case 'ANOMALIES':
                filter = 'JUSTIFICATION_NON_CONFORMITE';
                break;
            case 'CONSTATATIONS_DIVERSES':
                filter = 'CONSTATATION_DIVERSE';
                break;
            case 'DESCRIPTIF_DU_BIEN':
                filter = 'CONSTATATION_DIVERSE_GENERALE';
                break;
            case 'PRELEVEMENTS_HAP':
                filter = 'PRELEVEMENTS';
                break;
            default:
                filter = null;
                break;
        }
        return filter;
    }

    /**
     * Met à plat la liste des prélèvements d'un diagnostic HAP afin de récupérer la référence du prélèvement en temps que légende
     * @private
     */
    preparePrelevements(
        diagnostic: Diagnostic,
        reportagePhotoImageFiles: ReportagePhotoImageFile[]
    ): ReportagePhotoImageFile[] {
        const prelevements = this.getPrelevements(diagnostic);
        reportagePhotoImageFiles.forEach((photo) => {
            if (!!!photo.legende) {
                photo.legende = prelevements.find(
                    (pvt) =>
                        pvt.details.idFichierImageEchelle === photo.fileId ||
                        pvt.details.idFichierImageSituation === photo.fileId
                )?.general?.reference;
            }
        });
        return reportagePhotoImageFiles;
    }

    /**
     * Mise à plat des photos liées aux prélèvements
     * @param diagnostic
     */
    getPicturesFromPrelevements(diagnostic: Diagnostic): string[] {
        const prelevements = this.getPrelevements(diagnostic);
        return prelevements
            ? prelevements
                  .map((p) => p.details.idFichierImageEchelle)
                  .concat(prelevements.map((p) => p.details.idFichierImageSituation))
            : [];
    }

    /**
     * Mise à plat des prélèvements d'un diagnostic
     * @param diagnostic
     */
    getPrelevements(diagnostic: Diagnostic): Prelevement[] {
        if (diagnostic.typePrestation.includes('HAP')) {
            const contenu = diagnostic.contenuDiagnostic as Hap;
            return (contenu.espaces.valeur as Espace[])
                .flatMap((v) =>
                    v.listePerimetres.flatMap((perimetre) =>
                        perimetre.listeZones.flatMap((zone) => zone.listePrelevements)
                    )
                )
                .concat(
                    (contenu.espaces.valeur as Espace[]).flatMap((v) =>
                        v.listePerimetres.flatMap((perimetre) => perimetre.listeBesoins)
                    )
                );
        }
    }

    /**
     * Création de reportages photo de manière automatique, selon les presets présents dans la refDiagnostic, si ces reportages ne sont pas déjà présents dans le diagnostic
     * En cas de reportage déjà présent, ils seront mis à jour au cas par cas
     */
    generateReportagesAuto(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interventionFiles: InterventionFile[]
    ): Observable<boolean> {
        return combineLatestOrEmpty(this.getFiles(interventionFiles)).pipe(
            switchMap((files) => {
                const reportagePhotoImageFiles = files;
                let toUpdate = false;

                // Pour les reportages photos générés automatiquement à cause de la configuration du type prestation
                const presetsToGenerate = this.getPresetsReportagesPhotosAutoForRefPrestation(intervention, diagnostic);
                presetsToGenerate.length &&
                    presetsToGenerate.forEach((presetAuto) => {
                        const reportagesPresetExistant = diagnostic.reportagesPhoto.filter(
                            (reportageExistant) => reportageExistant.typePreset === presetAuto
                        );
                        if (reportagesPresetExistant.length) {
                            // Si aucun reportage photo de ce preset n'existe en mode auto, on en crée un
                            if (!reportagesPresetExistant.filter((p) => p.autoGenerated).length) {
                                toUpdate = true;
                                this.createNewReportageFromPreset(
                                    presetAuto,
                                    reportagePhotoImageFiles,
                                    intervention,
                                    diagnostic
                                );
                            }
                            reportagesPresetExistant.forEach((reportage) => {
                                // Si le reportage a déjà été créé automatiquement, on l'update avec les nouveaux contenus et légendes
                                if (reportage.autoGenerated) {
                                    toUpdate = true;
                                    this.updateReportagePhotoAuto(
                                        reportage,
                                        presetAuto,
                                        reportagePhotoImageFiles,
                                        intervention,
                                        diagnostic
                                    );
                                }

                                // Si le reportage photo existe déjà, mais a été créé en manuel, ou a été généré automatiquement, puis modifié manuellement,
                                // on update les légendes non modifiées manuellement uniquement
                                else {
                                    toUpdate = true;
                                    this.updateReportagePhotoManuel(
                                        reportage,
                                        reportagePhotoImageFiles,
                                        intervention,
                                        diagnostic
                                    );
                                }
                            });
                        }

                        // Si le reportage photo n'existe pas encore on en crée un nouveau
                        else {
                            toUpdate = true;
                            this.createNewReportageFromPreset(
                                presetAuto,
                                reportagePhotoImageFiles,
                                intervention,
                                diagnostic
                            );
                        }
                    });

                // Pour les reportages photos abonnés aux maj qui ne sont pas de type presetPhoto auto
                const reportagesToUpdateAutomatically = diagnostic.reportagesPhoto.filter(
                    (r) => r.autoGenerated && !presetsToGenerate.includes(r.typePreset)
                );
                reportagesToUpdateAutomatically &&
                    reportagesToUpdateAutomatically.forEach((r) => {
                        toUpdate = true;
                        this.updateReportagePhotoAuto(
                            r,
                            r.typePreset,
                            reportagePhotoImageFiles,
                            intervention,
                            diagnostic
                        );
                    });
                return of(toUpdate);
            })
        );
    }

    /**
     * Création d'un nouveau reportage photo selon un preset défini
     * @param presetAuto
     * @param reportagePhotoImageFiles
     * @param intervention
     * @param diagnostic
     * @private
     */
    private createNewReportageFromPreset(
        presetAuto: PresetPhoto,
        reportagePhotoImageFiles: ReportagePhotoImageFile[],
        intervention: Intervention,
        diagnostic: Diagnostic
    ) {
        // Nouveau reportage photo
        const newReportagePhotoFromPreset = new ReportagePhoto();
        newReportagePhotoFromPreset.id = MongoUtils.generateObjectId();
        newReportagePhotoFromPreset.typePreset = presetAuto;
        newReportagePhotoFromPreset.autoGenerated = true;
        newReportagePhotoFromPreset.nom = `Reportage photo de type ${presetPhotoToLabel(presetAuto)}`;

        // Ajout des photos triées et préparées
        this.preparePhotosForReportage(
            presetAuto,
            reportagePhotoImageFiles,
            newReportagePhotoFromPreset,
            intervention,
            diagnostic
        );

        // Ajout du nouveau reportage photo dans le diagnostic
        diagnostic.reportagesPhoto.push(newReportagePhotoFromPreset);
    }

    /**
     * Mise à jour d'un reportage photo qui a déjà été généré automatiquement
     * @param reportageToUpdate
     * @param presetAuto
     * @param reportagePhotoImageFiles
     * @param intervention
     * @param diagnostic
     * @private
     */
    private updateReportagePhotoAuto(
        reportageToUpdate: ReportagePhoto,
        presetAuto: PresetPhoto,
        reportagePhotoImageFiles: ReportagePhotoImageFile[],
        intervention: Intervention,
        diagnostic: Diagnostic
    ) {
        // On vide les photos
        reportageToUpdate.photos = [];

        // On récupère toutes les photos filtrées, et dont la légende a été mise à jour
        this.preparePhotosForReportage(
            presetAuto,
            reportagePhotoImageFiles,
            reportageToUpdate,
            intervention,
            diagnostic
        );

        // On update le reportage photo existant avec la nouvelle version
        diagnostic.reportagesPhoto[diagnostic.reportagesPhoto.findIndex((r) => r.id === reportageToUpdate.id)] =
            reportageToUpdate;
    }

    /**
     * Mise à jour des légendes d'un reportage photo qui n'ont pas été préalablement modifiées manuellement
     * @param reportageToUpdate
     * @param reportagePhotoImageFiles
     * @param intervention
     * @param diagnostic
     * @private
     */
    private updateReportagePhotoManuel(
        reportageToUpdate: ReportagePhoto,
        reportagePhotoImageFiles: ReportagePhotoImageFile[],
        intervention: Intervention,
        diagnostic: Diagnostic
    ) {
        // On nettoie les photos qui ont été supprimées dans l'intervention et le diagnostic
        reportageToUpdate.photos = reportageToUpdate.photos.filter((photo) => {
            return reportagePhotoImageFiles.map((r) => r.fileId).includes(photo.idFichier);
        });

        reportageToUpdate.photos.forEach((photo) => {
            // Maj de la légende si elle n'a jamais été modifiée manuellement
            if (!photo.legendEdited) {
                // En cas de prélèvement
                if (diagnostic.typePrestation === 'HAP_VALIDATION_RESULTATS') {
                    const prelevements = this.getPrelevements(diagnostic);
                    photo.legende = prelevements.find(
                        (prelevement) =>
                            prelevement.details.idFichierImageEchelle === photo.idFichier ||
                            prelevement.details.idFichierImageSituation === photo.idFichier
                    )?.general?.reference;
                }
                // En cas de commentaire
                photo.legende = photo.legende
                    ? photo.legende
                    : intervention.commentaires.find((c) => c.imageId === photo.idFichier)?.contenu;
            }
        });

        // On update le reportage photo existant avec la nouvelle version
        diagnostic.reportagesPhoto[diagnostic.reportagesPhoto.findIndex((r) => r.id === reportageToUpdate.id)] =
            reportageToUpdate;
    }

    /**
     * Préparation des nouvelles photos et des légendes associées pour un reportage
     * @param presetAuto
     * @param reportagePhotoImageFiles
     * @param newReportagePhotoFromPreset
     * @param intervention
     * @param diagnostic
     * @private
     */
    private preparePhotosForReportage(
        presetAuto: PresetPhoto,
        reportagePhotoImageFiles: ReportagePhotoImageFile[],
        newReportagePhotoFromPreset: ReportagePhoto,
        intervention: Intervention,
        diagnostic: Diagnostic
    ) {
        const typeCommentaireFilter = this.getCorrespondanceTypeCommentaire(presetAuto);
        // Photos du reportage
        // Cas particulier des prélèvements
        if (typeCommentaireFilter === 'PRELEVEMENTS') {
            const photosFromPrelevementsWithLegends = this.preparePrelevements(diagnostic, reportagePhotoImageFiles);
            const pvtsImgs = this.getPicturesFromPrelevements(diagnostic);
            photosFromPrelevementsWithLegends.forEach((photo) => {
                const prelevement = pvtsImgs.find((pvt) => pvt === photo.fileId);

                // Si le prélèvement est trouvé, on le pousse dans le nouveau reportage photo
                if (prelevement) {
                    newReportagePhotoFromPreset.photos.push(
                        new DetailPhoto(
                            photo.fileId,
                            photo.typeReferenceFichier,
                            photo?.legende,
                            false,
                            photo.creationDate ?? 'Non renseigné',
                            photo.gpsLatitudeRef ?? 'Non renseigné',
                            photo.gpsLatitude ?? 'Non renseigné',
                            photo.gpsLongitudeRef ?? 'Non renseigné',
                            photo.gpsLongitude ?? 'Non renseigné',
                            photo.gpsAltitudeRef ?? 'Non renseigné',
                            photo.gpsAltitude ?? 'Non renseigné'
                        )
                    );
                }
            });
        }
        // Cas des commentaires classiques
        else {
            if (presetAuto === 'DESCRIPTIF_DU_BIEN') {
                const detailPhotos = reportagePhotoImageFiles
                    .filter((it) => it.typeReferenceFichier === TypeReferenceFichier.PHOTO_BIEN)
                    .map(
                        (it) =>
                            new DetailPhoto(
                                it.fileId,
                                it.typeReferenceFichier,
                                'Photo du bien',
                                false,
                                it.creationDate ?? 'Non renseigné',
                                it.gpsLatitudeRef ?? 'Non renseigné',
                                it.gpsLatitude ?? 'Non renseigné',
                                it.gpsLongitudeRef ?? 'Non renseigné',
                                it.gpsLongitude ?? 'Non renseigné',
                                it.gpsAltitudeRef ?? 'Non renseigné',
                                it.gpsAltitude ?? 'Non renseigné'
                            )
                    );
                newReportagePhotoFromPreset.photos.push(...detailPhotos);
            }
            const detailPhotos = reportagePhotoImageFiles
                .map((photo) => {
                    const commentaire = intervention.commentaires?.find(
                        (c) => c.imageId === photo.fileId && c.type === typeCommentaireFilter
                    );
                    return { commentaire, photo };
                })
                .filter((it) => !!it.commentaire)
                .map(
                    (it) =>
                        new DetailPhoto(
                            it.photo.fileId,
                            it.photo.typeReferenceFichier,
                            it.commentaire.contenu,
                            false,
                            it.photo.creationDate ?? 'Non renseigné',
                            it.photo.gpsLatitudeRef ?? 'Non renseigné',
                            it.photo.gpsLatitude ?? 'Non renseigné',
                            it.photo.gpsLongitudeRef ?? 'Non renseigné',
                            it.photo.gpsLongitude ?? 'Non renseigné',
                            it.photo.gpsAltitudeRef ?? 'Non renseigné',
                            it.photo.gpsAltitude ?? 'Non renseigné'
                        )
                );
            newReportagePhotoFromPreset.photos.push(...detailPhotos);
        }
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_background_map : SVG canvas for background maps
//***********************************************************************************
//***********************************************************************************

import {cn_event_manager} from "./cn_event_manager";
import {cn_add, cn_dist, cn_dot, cn_middle, cn_normal, cn_normalize, cn_sub} from "../utils/cn_utilities";
import {cn_camera} from "./cn_camera";
import {cn_image_dir} from "../utils/image_dir";
import * as $ from 'jquery';

export class cn_svg_background_map extends cn_event_manager{
	constructor(svg_container_id) {
		super();
		var obj=this;

		this._current_map = null;

		this._background_maps = [];
        this._back_scene = null;

		this._svg_container_id = svg_container_id;
		this._camera = new cn_camera();

		this._reference_points = [[0,0],[10,0]];

		this._minimum_displacement = false;
		this._pan_mode = false;
		this._mouse_position = [0,0];
		this._mousedown_position = [0,0];
		this._mousedown_buttons = 0;
		this._synchronized_cameras = [];

		this._box_size = [80,20];

		this._selection = -1;
		this._offset_buttons = [];
		this._offset_button_selection = -1;
		this._current_camera_scale = 0;

		this._has_focus = false;
		this._current_tool = null;


		//*** We use a special event for svg event management.
		this._svg_event = {x:0, y:0, ctrlKey: false, buttons: 0, wheel_up: false, key: 0};

		function update_svg_event(ev) {

			//*** Localized client position
			if (typeof(ev.clientX) != 'undefined')
			{
				obj._svg_event.x = ev.clientX;
				obj._svg_event.y = ev.clientY;
				var svg_container = document.getElementById(obj._svg_container_id);
				if (svg_container)
				{
					var svg_rect = svg_container.getBoundingClientRect();
					obj._svg_event.x -= svg_rect.left;
					obj._svg_event.y -= svg_rect.top;
				}
			}

			if (typeof(ev.buttons) != 'undefined')
				obj._svg_event.buttons = ev.buttons;

			if (typeof(ev.ctrlKey) != 'undefined')
				obj._svg_event.ctrlKey = ev.ctrlKey;

			if (typeof(ev.deltaY) != 'undefined')
				obj._svg_event.wheel_up = (ev.deltaY > 0.2);

			if (typeof(ev.key) != 'undefined')
				obj._svg_event.key = ev.key;
		}

		var event_container = document.getElementById(obj._svg_container_id);
		if (event_container)
		{
			event_container.addEventListener('mousemove', function(ev) {
				ev.preventDefault();
				update_svg_event(ev);
				obj._mousemove(obj._svg_event);
				}, false);

			event_container.addEventListener('mousedown', function(ev) {
				ev.preventDefault();
				update_svg_event(ev);
				obj._mousedown(obj._svg_event);
				}, false);

			event_container.addEventListener('mouseup', function(ev) {
				ev.preventDefault();
				update_svg_event(ev);
				obj._mouseup(obj._svg_event);
				}, false);

			event_container.addEventListener('wheel', function(ev) {
				ev.preventDefault();
				update_svg_event(ev);
				obj._mousewheel(obj._svg_event);
				}, false);

			event_container.addEventListener('mouseenter', function(ev) {
				obj._mouseenter();
				}, false);

			event_container.addEventListener('mouseleave', function(ev) {
				obj._mouseleave();
				}, false);

			event_container.addEventListener('contextmenu', function (ev) {
				ev.preventDefault();
				return false;
				}, false);

		}

		document.addEventListener('mousemove', function(ev) {
			//var had_focus = obj._has_focus;
			obj._has_focus = false;
			// TODO MouseEvent.path ne fonctionnera pas avec Firefox
			for (var i in ev["path"])
			{
				if (ev["path"][i].id != obj._svg_container_id) continue;
				obj._has_focus = true;
				break;
			}
			});

		document.addEventListener('keydown', function(ev) {
			if (!obj._has_focus) return false;
			//console.log("keyboard",ev);
			ev.preventDefault();
			update_svg_event(ev);
			obj._keydown(obj._svg_event);
			});


		this.refresh();
	}

	//***********************************************************************************
	//**** Change current_map
	//***********************************************************************************
	set_current_map(current_map)
	{
		this._current_map = current_map;
		this._offset_buttons = [];
		this._current_camera_scale = 0;
		if (this._current_map)
		{
			this._reference_points[0] =  this._current_map.to_world(this._current_map.reference_points[0]);
			this._reference_points[1] =  this._current_map.to_world(this._current_map.reference_points[1]);
		}
	}
	get_current_map() {
		return this._current_map;
	}

	set_background_maps(maps)
	{
		this._background_maps = maps;
	}

	//***********************************************************************************
	//**** Change reference length value
	//***********************************************************************************
	set_reference(value) {
		if (value < 0.01) return;
		if (this._current_map == null) return;

		var ic = this._current_map.to_image(this._camera.get_world_center());
		var ih = this._camera.get_world_height() / this._current_map.scale;

		//*** Update map
		var ratio = value / cn_dist(this._reference_points[0],this._reference_points[1]);
		var p0 = this._current_map.to_image(this._reference_points[0]);
		var p1 = this._current_map.to_image(this._reference_points[1]);
		this._current_map.scale *= ratio;
		this._reference_points[0] = this._current_map.to_world(p0);
		this._reference_points[1] = this._current_map.to_world(p1);

		//*** update camera
		var wc = this._current_map.to_world(ic);
		var wh = ih * this._current_map.scale;
		this._camera.set_world_focus(wc,wh);

		this.update_current_map_reference_points();
        this.refresh()
	}

	//***********************************************************************************
	//**** Returns reference length value
	//***********************************************************************************
	get_reference() {
		if (this._current_map == null) return 0;
		return cn_dist(this._reference_points[0],this._reference_points[1]);
	}

	//***********************************************************************************
	//**** Change orientation
	//***********************************************************************************
	set_orientation(value) {
		if (this._current_map == null) return;
		var p0 = this._current_map.to_image(this._reference_points[0]);
		var p1 = this._current_map.to_image(this._reference_points[1]);
		this._current_map.orientation = value;
		this._reference_points[0] = this._current_map.to_world(p0);
		this._reference_points[1] = this._current_map.to_world(p1);
		this.update_current_map_reference_points();
	}

	//***********************************************************************************
	//**** Refresh
	//***********************************************************************************
	refresh() {
		var svg_container = document.getElementById(this._svg_container_id);
		if (!svg_container) return;
		var svg_rect = svg_container.getBoundingClientRect();
		this._camera.set_size(svg_rect.right-svg_rect.left,svg_rect.bottom-svg_rect.top);

        // if(this._back_scene) {
        //     let back_scene = this._back_scene.draw(this._camera, [], 0.8)
        //     $("#" + this._svg_container_id +'_backscene').html(back_scene);
        // }

		var html = "";

		html += "<defs>";
		html += "<marker id='arrow_next' markerWidth='7' markerHeight='5' refX='6' refY='2' orient='auto'>";
		html += "<path d='M0,0 L0,4 L6,2 z' fill='black' stroke='black' />";
		html += "</marker>";
		html += "<marker id='arrow_prev' markerWidth='7' markerHeight='5' refX='0' refY='2' orient='auto'>";
		html += "<path d='M6,0 L6,4 L0,2 z' fill='black' stroke='black'/>";
		html += "</marker>";
		html += "</defs>";


		for (var i=0;i<this._background_maps.length;i++)
			html += this._background_maps[i].draw(this._camera, [], 0.5);

		html += "<g>" + this._camera.draw_grid() + "</g>";

		this.update_offset_buttons();
		if (this._current_map)
		{
            if(this._back_scene) {
                let back_scene= this._back_scene.draw(this._camera, [], 1)
                $("#" + this._svg_container_id +"_backscene").html(back_scene);
            }

			//*** Draw map
			html += this._current_map.draw(this._camera, [], 0.7);

			//*** Draw reference line
			var p0 = this._camera.world_to_screen(this._reference_points[0]);
			var p1 = this._camera.world_to_screen(this._reference_points[1]);

			var xtraclass = (this._selection == 2)?" line_selected selected":"";
			html += "<line class='map_reference_line" + xtraclass + "' x1='" + p0[0] + "' y1='" + p0[1] + "' x2='" + p1[0] + "' y2='" + p1[1] + "' />";
			var xtraclass = (this._selection == 0)?" line_selected selected":"";
			html += "<circle class='map_reference_point" + xtraclass + "' cx='" + p0[0] + "' cy='" + p0[1] + "' r='5'/>";
			var xtraclass = (this._selection == 1)?" line_selected selected":"";
			html += "<circle class='map_reference_point" + xtraclass + "' cx='" + p1[0] + "' cy='" + p1[1] + "' r='5'/>";

			//*** Draw reference length
			var xtraclass = (this._selection == 3)?" line_selected selected":"";
			var c = this._camera.world_to_screen(cn_middle(this._reference_points[0],this._reference_points[1]));
			html += "<rect class='map_text_box" + xtraclass + "' x='" + (c[0]-this._box_size[0] * 0.5) + "' y='" + (c[1]-this._box_size[1] * 0.5) + "' width='" + this._box_size[0] + "' height='" + this._box_size[1] + "' />";

			var length = cn_dist(this._reference_points[0],this._reference_points[1]);
			html += "<text class='map_text' x='" + c[0] + "' y='" + c[1] + "'>" + length.toFixed(2)+ " m</text>";

			//*** Draw offset buttons
			var screen_size = this._camera.get_screen_size();
			var r = 20;
			for (var i=0;i< this._offset_buttons.length;i++)
			{
				var p = this._camera.world_to_screen(this._current_map.to_world(this._offset_buttons[i]));
				if (p[0] + r < 0 || p[0] - r > screen_size[0]) continue;
				if (p[1] + r < 0 || p[1] - r > screen_size[1]) continue;

				//*** Draw center
				var add_classes = "";
				if (i == this._offset_button_selection)
					add_classes = " selected";

				html += "<circle class='map_text_box" + add_classes + "' cx='" + p[0] + "' cy='" + p[1] + "' r='" + r + "'/>";

				//*** draw arrows
				html += "<image xlink:href='" + cn_image_dir() + "arrow_all_white.svg' x='" + (p[0] - r) + "' y='" + (p[1] - r) + "' width='" + (2 * r) + "' height='" + (2 * r) + "' />";
			}

		}

		html += "<g>" + this._camera.draw_scale() + "</g>";

		$("#" + this._svg_container_id).html(html);

	}

	//***********************************************************************************
	//**** Update offset buttons
	//***********************************************************************************
	update_offset_buttons() {
		if (this._current_map == null)
		{
			this._offset_buttons = [];
			this._current_camera_scale = 0;
			return;
		}

		if (this._offset_buttons.length > 0 && this._current_camera_scale == this._camera.world_to_screen_scale) return;

		this._current_camera_scale = this._camera.world_to_screen_scale;

		var image_size = this._current_map.get_world_size();
		var canvas_size = this._camera.get_world_size();
		var nx = 1+Math.floor(2*image_size[0] / canvas_size[0]);
		if (nx < 1) nx=1;
		var ny = 1+Math.floor(2*image_size[1] / canvas_size[1]);
		if (ny < 1) ny=1;

		var dx = 1 / nx;
		var dy = 1 / ny;

		this._offset_buttons = [];
		for (var i=0;i<nx;i++)
		{
			var x = dx * (0.5+i);
			for (var j=0;j<ny;j++)
			{
				var y = dy * (0.5+j);
				this._offset_buttons.push([x,y]);
			}
		}
	}

	//***********************************************************************************
	//**** Update reference points for map
	//***********************************************************************************
	update_current_map_reference_points() {
		if (this._current_map == null) return;
		this._current_map.reference_points[0] = this._current_map.to_image(this._reference_points[0]);
		this._current_map.reference_points[1] = this._current_map.to_image(this._reference_points[1]);
		this.call("change");
	}

	//***********************************************************************************
	//**** Mouse callbacks
	//***********************************************************************************

	_mousedown (ev) {

		this._minimum_displacement = false;
		this._mousedown_buttons = ev.buttons;
		this._mousedown_position = [ev.x,ev.y];

		//*** are we initiating pan ?
		if ((ev.buttons&1) == 0)
		{
			this._mouse_position = [ev.x,ev.y];
			this._pan_mode = true;
			this.refresh();
			return;
		}

		if (this._selection == 3)
			this.call("reference_clicked",cn_dist(this._reference_points[0],this._reference_points[1]));

		this.refresh();
	}

	_mouseup(ev) {
		this._pan_mode = false;
		this.refresh();
	}

	_mousemove(ev) {

		if (!this._minimum_displacement)
			this._minimum_displacement = (cn_dist(this._mousedown_position,[ev.x,ev.y]) > 10);

		//*** process pan
		if (this._pan_mode)
		{
			var ms = [ev.x,ev.y];
			this._camera.pan(ms[0]-this._mouse_position[0],ms[1]-this._mouse_position[1]);
			this._mouse_position = ms;
			this.refresh();
			return;
		}

		//*** passive move
		if (ev.buttons == 0)
		{
			this._selection = -1;
			this._offset_button_selection = -1;
			var ms = [ev.x,ev.y];
			var p0 = this._camera.world_to_screen(this._reference_points[0]);
			var p1 = this._camera.world_to_screen(this._reference_points[1]);
			var c = cn_middle(p0,p1);
			if (cn_dist(p0,ms) < 10)
				this._selection = 0;
			else if (cn_dist(p1,ms) < 10)
				this._selection = 1;
			else if (Math.abs(ev.x-c[0]) <= this._box_size[0]/2 && Math.abs(ev.y-c[1]) <= this._box_size[1]/2)
				this._selection = 3;
			else
			{
				var dir = cn_sub(p1,p0);
				var length = cn_normalize(dir);
				var d = cn_sub(ms,p0);
				var x = cn_dot(d,dir);
				var y = cn_dot(d,cn_normal(dir));
				if (x > 0 && x < length && Math.abs(y) < 5)
					this._selection = 2;
			}

			if (this._selection < 0)
			{
				for (var i=0;i<this._offset_buttons.length;i++)
				{
					var p = this._camera.world_to_screen(this._current_map.to_world(this._offset_buttons[i]));
					if (cn_dist(p,ms) > 30) continue;
					this._offset_button_selection = i;
					break;
				}
			}
		}

		//*** Move reference line
		if (ev.buttons && this._selection >= 0 && this._selection < 3)
		{
			var world_mouse = this._camera.screen_to_world([ev.x,ev.y]);
			if (this._selection < 2) {
                this._reference_points[this._selection] = world_mouse;
            } else
			{
				var dir = cn_sub(world_mouse,this._camera.screen_to_world(this._mousedown_position));
				this._reference_points[0] = cn_add(dir,this._reference_points[0]);
				this._reference_points[1] = cn_add(dir,this._reference_points[1]);
				this._mousedown_position = [ev.x,ev.y];
			}
			this.update_current_map_reference_points();
		}

		//*** Move offset
		if (ev.buttons && this._offset_button_selection >= 0)
		{
            this.call("Offset");
			var world_mouse = this._camera.screen_to_world([ev.x,ev.y]);
			var dir = cn_sub(world_mouse,this._camera.screen_to_world(this._mousedown_position));
			this._reference_points[0] = cn_add(dir,this._reference_points[0]);
			this._reference_points[1] = cn_add(dir,this._reference_points[1]);
			this._current_map.offset = cn_add(this._current_map.offset,dir);
			this._mousedown_position = [ev.x,ev.y];
			this.update_current_map_reference_points();
		}

		this.refresh();
	}

	_mousewheel(ev) {
		this._camera.wheel([ev.x,ev.y],ev.wheel_up);
		this.refresh();
	}

	_mouseenter() {
	}

	_mouseleave() {
		this._pan_mode = false;
		this._minimum_displacement = false;
		if (this._current_tool && this._current_tool.mouseleave(this._camera))
			this.refresh();
	}

	_keydown(ev) {
		if (this._current_tool && this._current_tool.keydown(ev,this._camera))
			this.refresh();
	}
}


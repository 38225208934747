<div class="mpca-descriptif" *ngIf="isLoaded">
    <div class="panel">
        <mpca-panel-list-card
            [canCreate]="isInEditMode"
            [mpcaList]="mpca.getRawValue()"
            [indexSelected]="mpcaIndexSelected"
            (select)="selectMpcaItem($event)"
            (add)="addNewMpcaItem($event)"
            (copy)="addNewMpcaItem($event)"
            (delete)="deleteMpcaItem($event)"
            [disabled]="!isInEditMode"
        >
        </mpca-panel-list-card>
    </div>
    <div class="content" [formGroup]="form">
        <verification-card
            *ngIf="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            [showValidateButton]="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="form"
        >
            Veuillez vérifier les MPCA
            <!-- Modifier les MPCA entrainera la réinitialisation des données de l'écran Zone -->
        </verification-card>
        <div *ngFor="let item of mpca.controls; let i = index" class="mpcaContainer">
            <ng-container *ngIf="i === mpcaIndexSelected">
                <app-mpca-form-content
                    [diagnostic]="diagnostic"
                    [formMpca]="item"
                    [isInEditMode]="isInEditMode"
                    [polluantConfig]="polluantConfig"
                    [showProcessus]="showProcessus"
                    (deleteProcessus)="deleteProcessus($event)"
                ></app-mpca-form-content>
            </ng-container>
        </div>
    </div>
</div>

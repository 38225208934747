import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact, RoleContact } from '../../../model/contact.model';
import { tap } from 'rxjs/operators';
import { StringUtils } from '../../../utils/string.utils';
import { REGEX_EMAIL, REGEX_TELEPHONE } from '../../../shared/constants/cndiag.constants';
import { ContactService } from '../../../services/contact.service';

@Injectable({
    providedIn: 'root',
})
export class EditContactFormService {
    private typeMap = [
        { typeRole: RoleContact.DONNEUR_ORDRE, type: "Donneur d'ordre" },
        { typeRole: RoleContact.PROPRIETAIRE, type: 'Propriétaire' },
    ];
    constructor(private readonly formBuilder: FormBuilder, private readonly contactService: ContactService) {}

    public saveContactFromForm(formContact: FormGroup, id: string, typeRole: RoleContact, isCreation: boolean) {
        const estPersonneMorale = !!formContact.value.estPersonneMorale;
        const entrepriseParent =
            !estPersonneMorale && formContact.value.entreprise ? formContact.value.entreprise : undefined;
        const nom = formContact.value.nom;
        const prenom = estPersonneMorale ? '' : formContact.value.prenom;
        const siret = estPersonneMorale ? formContact.value.siret : '';
        const type = formContact.get('type').value;
        const telephone = formContact.value.telephone;
        const email = formContact.value.email;
        const adresse = formContact.value.adresse;

        const contactToSave = {
            id,
            nom,
            prenom,
            siret,
            telephone,
            adresse,
            email,
            estPersonneMorale,
            type,
            entrepriseParent,
            typeRole,
        };
        const textSuccess: string = isCreation ? 'Le contact a été crée' : 'Le contact a été modifié';
        const textWarn: string = isCreation
            ? 'Un contact similiaire a été trouvé, souhaitez-vous vraiment dupliquer ce contact ?'
            : "Un doublon a été trouvé, souhaitez-vous vraiment écraser les données de l'ancien contact ?";
        return this.contactService
            .upsertContactWarnDuplicate(
                contactToSave,
                textSuccess,
                textWarn,
                isCreation ? "Le contact n'a pas été créé" : "Le contact n'a pas été modifié"
            )
            .pipe(
                tap((contact) => {
                    contact.type = type;
                    contact.typeRole = typeRole;
                })
            );
    }

    public buildContactForm(
        contact: Contact | { id: string; typeRole: RoleContact },
        isProprietaire: boolean,
        isDonneurOrdre: boolean
    ) {
        return this.formBuilder.group({
            id: [contact.id],
            type: [
                {
                    value: this.getType(contact),
                    disabled: isProprietaire || isDonneurOrdre,
                },
                [
                    (control: AbstractControl) => {
                        if (contact.typeRole == RoleContact.AUTRE) {
                            if (
                                StringUtils.toLowerCase(control.value) == 'proprietaire' ||
                                StringUtils.toLowerCase(control.value) == "donneur d'ordre"
                            ) {
                                return { erreurRoleAutre: true };
                            }
                        }
                        return null;
                    },
                ],
            ],
            prenom: [''],
            nom: ['', { validators: [Validators.required] }],
            adresse: this.formBuilder.group({
                voie: [''],
                complementAdresse1: [''],
                complementAdresse2: [''],
                codePostal: [''],
                ville: [''],
            }),
            telephone: ['', [Validators.required, Validators.pattern(REGEX_TELEPHONE)]],
            email: ['', Validators.pattern(REGEX_EMAIL)],
            siret: [''],
            estPersonneMorale: [!!history?.state?.estPersonneMorale, Validators.required],
            entreprise: [''],
        });
    }

    private getType(contact: Contact | { typeRole: RoleContact }) {
        return (this.typeMap.find((it) => it.typeRole === contact.typeRole) || { type: 'Autre' }).type;
    }

    public patchContactForm(currentContact: Contact, formContact: FormGroup, isModeDuplicate: boolean) {
        const estPersonneMorale = !!(currentContact ? currentContact : formContact.value).estPersonneMorale;
        updateContactFormValidators(estPersonneMorale, formContact);
        if (currentContact) {
            const prenom = estPersonneMorale || !currentContact.prenom ? '' : currentContact.prenom;
            const entreprise =
                estPersonneMorale || !currentContact.entrepriseParent ? '' : currentContact.entrepriseParent;
            const siret = !estPersonneMorale || !currentContact.siret ? '' : currentContact.siret;
            const type = currentContact.type ? currentContact.type : '';
            const id = isModeDuplicate ? null : currentContact.id;
            const nom = currentContact.nom;
            const adresse = {
                voie: currentContact.adresse.voie,
                complementAdresse1: currentContact.adresse.complementAdresse1,
                complementAdresse2: currentContact.adresse.complementAdresse2,
                codePostal: currentContact.adresse.codePostal,
                ville: currentContact.adresse.ville,
            };
            const telephone = currentContact.telephone;
            const email = currentContact.email;
            formContact.patchValue({
                type,
                prenom,
                entreprise,
                siret,
                id,
                nom,
                adresse,
                telephone,
                email,
                estPersonneMorale,
            });
        }
        formContact.updateValueAndValidity();
    }
}

export function updateContactFormValidators(estPersonneMorale: boolean, formContact: FormGroup) {
    const entrepriseValidators = estPersonneMorale ? [] : [entrepriseExistsValidator];
    const siretValidators = estPersonneMorale ? [Validators.required] : [];
    const prenomValidators = estPersonneMorale ? [] : [Validators.required];
    formContact.get('entreprise').setValidators(entrepriseValidators);
    formContact.get('siret').setValidators(siretValidators);
    formContact.get('prenom').setValidators(prenomValidators);
    if (estPersonneMorale) {
        formContact.get('entreprise').disable();
        formContact.get('prenom').disable();
        formContact.get('siret').enable();
    } else {
        formContact.get('entreprise').enable();
        formContact.get('prenom').enable();
        formContact.get('siret').disable();
    }

    formContact.updateValueAndValidity();
}

export const entrepriseExistsValidator = (control) => {
    if (!control.value || (control.value && typeof control.value.id === 'string')) {
        return null;
    } else {
        return { entrepriseDoesNotExist: true };
    }
};

import { Bien, ElementAControler, Niveau, Volume } from '../model/bien.model';
import { TypeElementAControler, TypeVolume } from '../model/type-element-a-controler.model';
import { Intervention } from '../model/intervention.model';
import { cn_building, cn_space, cn_storey } from '@acenv/cnmap-editor';
import {
    CODE_BIM_PARAM_ESPACE_CHAUFFE,
    CODE_BIM_PARAM_ESPACE_HUMIDE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    PARAM_VOLUME_LOT,
} from '../shared/constants/cndiag.constants';
import { ListUtils } from '../utils/list.utils';
import { Injectable } from '@angular/core';
import { Diagnostic } from '../model/diagnostic.model';
import { DiagnosticHandlerService } from './diagnostic-handler.service';

export function getStoreyName(storey: cn_storey) {
    return storey.name ? storey.name : storey.short_name === 'RdC' ? 'Niveau 0' : 'Niveau ' + storey.short_name;
}

@Injectable({
    providedIn: 'root',
})
export class SyncBienPlanService {
    constructor(private readonly diagnosticHandlerService: DiagnosticHandlerService) {}
    /**
     * Mise à jour de la description du bien à partir du plan s'il existe
     */
    updateBienFromMap(
        bien: Bien,
        building: cn_building,
        typeElementAController: TypeElementAControler[],
        intervention: Intervention,
        typesVolumes: TypeVolume[] = [],
        diagnostics: Diagnostic[]
    ) {
        if (bien && building) {
            // ADDITION ET MAJ
            // NIVEAU
            building.storeys.forEach((storey, storeyIndex) => {
                const currentNiveau = this.getNiveauOrCreate(bien, storey);
                // Initialisation des flags
                const usageNames = [];
                // MAJ du nom de niveau dans tous les cas
                currentNiveau.nom = getStoreyName(storey);
                currentNiveau.index = storey.storey_index;

                // VOLUMES
                storey.scene.spaces.forEach((space, spaceIndex) => {
                    const currentVolume = this.getVolumeOrCreate(space, currentNiveau);
                    const usageChanged = space.space_usage !== currentVolume.usageId;
                    currentVolume.usageId = space.space_usage;
                    const defaultValuesForTypeVolumes = typesVolumes.find(
                        (volume) => currentVolume.usageId === volume.id
                    );
                    // MAJ nom de volume dans tous les cas
                    this.updateUsageName(
                        storeyIndex,
                        space,
                        currentVolume,
                        spaceIndex,
                        defaultValuesForTypeVolumes,
                        usageNames
                    );
                    // MAJ des paramètres de la pièce en fonction de l'usage déclaré dans la map
                    if (currentVolume.usageId && usageChanged) {
                        if (defaultValuesForTypeVolumes && defaultValuesForTypeVolumes.valeursDefautParametres) {
                            const keys = [
                                CODE_BIM_PARAM_ESPACE_HUMIDE,
                                CODE_BIM_PARAM_ESPACE_CHAUFFE,
                                CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
                                CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
                                CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
                            ];
                            keys.forEach((key) =>
                                this.populateValeursParametres(currentVolume, defaultValuesForTypeVolumes, key)
                            );
                        }
                    }

                    if (currentVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_CHAUFFE]) {
                        currentVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_CHAUFFE] = space.heated;
                    }

                    const zoneName = building.zones?.usage?.find((z) =>
                        z.rooms.find((r) => r.space === space.ID && r.storey === storey.ID)
                    )?.name;
                    if (zoneName) {
                        currentVolume.valeursParametres[PARAM_VOLUME_LOT] = zoneName;
                    } else {
                        currentVolume.valeursParametres[PARAM_VOLUME_LOT] = bien.numeroLot ? bien.numeroLot : null;
                    }

                    // ELEMENT
                    storey.scene.object_instances
                        .filter((obj) => obj.space.ID === space.ID)
                        .forEach((objInst) => {
                            let element = currentVolume.elementsAControler.find((el) => el.objectId === objInst.ID);
                            // Si l'élément à contrôler a été ajouté dans la map
                            if (!element) {
                                // On vérifie si cet équipement n'est pas dans une autre pièce (possible dans le cas d'un ajout de cloison par exemple)
                                // Si oui, on le récupère
                                const listeElement = currentNiveau.volumes.flatMap((vol) =>
                                    vol.elementsAControler.map((element) => ({ vol, element }))
                                );
                                const elementExisting = listeElement.find((el) => el.element.objectId === objInst.ID);
                                if (elementExisting && elementExisting.vol.spaceId !== currentVolume.spaceId) {
                                    const idx = elementExisting.vol.elementsAControler.findIndex(
                                        (it) => it.objectId === elementExisting.element.objectId
                                    );
                                    elementExisting.vol.elementsAControler.splice(idx, 1);
                                    currentVolume.elementsAControler.push(elementExisting.element);
                                    diagnostics?.forEach((diagnostic) =>
                                        this.diagnosticHandlerService
                                            .getTypePrestationService(diagnostic.typePrestation)
                                            ?.deplaceEquipement(
                                                elementExisting.element.id,
                                                diagnostic,
                                                currentVolume,
                                                bien
                                            )
                                    );
                                } else {
                                    // TODO on ne garde que la première partie du nom pour coller avec le référentiel cnbim
                                    const typeElement = typeElementAController.find(
                                        (te) => te.nom === objInst.object.name.split(' standard')[0]
                                    );
                                    if (typeElement) {
                                        element = new ElementAControler();
                                        element.objectId = objInst.ID;
                                        element.codeBim = typeElement.codeBim;
                                        // Nom unique et autoincrémenté
                                        element.nom = ListUtils.createUniqueNameElementAControler(
                                            currentVolume.elementsAControler,
                                            typeElement
                                        );
                                        element.idTypeElementAControler = typeElement.id;
                                        element.valeursParametres = { ...typeElement.valeursDefautParametres };
                                        element.typesPrestationsAssocie = [
                                            ...(typeElement.typesPrestationsAssocie || []),
                                        ];
                                        currentVolume.elementsAControler.push(element);
                                    }
                                }
                            }
                        });
                });
            });

            // SUPPRESSION
            // Si on supprime des infos dans la map, maj de la liste
            // Par exemple, si on supprime un niveau de la map, et qu'il a déjà été créé dans la liste automatiquement, on passe à null le storeyId du niveau de la liste.
            // De même pour les pièces et équipements (spaceId et objectId respectivement)
            // NIVEAU
            bien.description.forEach((niv) => {
                const storey = building.storeys.find((s) => s.ID === niv.storeyId);
                if (!storey) {
                    niv.storeyId = null;
                }
                niv.volumes
                    .filter((it) => it.spaceId)
                    .forEach((vol) => {
                        const space = storey?.scene.spaces.find((space) => space.ID === vol.spaceId);
                        if (!space) {
                            vol.spaceId = null;
                        }
                        vol.elementsAControler
                            .filter((it) => it.objectId)
                            .forEach((el) => {
                                if (!space || !storey.scene.object_instances.some((ob) => ob.ID === el.objectId)) {
                                    el.objectId = null;
                                }
                            });
                    });
            });
            // On tri les étages en fonction de leurs indexes
            bien.description = bien.description.sort((a, b) => a.index - b.index);
        }
    }

    private getNiveauOrCreate(bien: Bien, storey: cn_storey) {
        let currentNiveau = bien.description.find((niveau) => niveau.storeyId === storey.ID);
        // Si le niveau a été ajouté dans la map
        if (!currentNiveau) {
            currentNiveau = new Niveau();
            currentNiveau.storeyId = storey.ID;
            // On rajoute manuellement le volume "caché"
            const volumeCache = new Volume();

            volumeCache.nom = 'Extérieur';
            volumeCache.volumeCache = true;
            currentNiveau.volumes.push(volumeCache);
            bien.description.push(currentNiveau);
        }
        return currentNiveau;
    }

    private getVolumeOrCreate(space: cn_space, currentNiveau: Niveau) {
        let currentVolume;
        if (!space.outside) {
            currentVolume = currentNiveau.volumes
                .filter((it) => !!it.spaceId)
                .find((volume) => volume.spaceId === space.ID);
        } else {
            currentVolume = currentNiveau.volumes.find((v) => v.volumeCache);
        }
        // Si le volume a été ajouté dans la map
        if (!currentVolume) {
            currentVolume = new Volume();
            currentVolume.spaceId = space.ID;
            // Maj des flags
            currentNiveau.volumes.push(currentVolume);
        }
        return currentVolume;
    }

    /**
     * MAJ nom de volume
     * @param space
     * @param currentVolume
     * @param defaultValuesForTypeVolumes
     * @param usageNames
     * @private
     */
    private updateUsageName(
        niveauIndex: number,
        space: cn_space,
        currentVolume: Volume,
        volumeIndex: number,
        defaultValuesForTypeVolumes: TypeVolume,
        usageNames: string[]
    ) {
        let defaultName = 'Espace ' + niveauIndex + '.' + volumeIndex;
        if (space.name) {
            space.name = ListUtils.createUniqueName(usageNames, space.name);
        } else {
            if (volumeIndex === 0) {
                defaultName = 'Extérieur';
            }
            const name = defaultValuesForTypeVolumes ? defaultValuesForTypeVolumes.nom : defaultName;
            space.name = ListUtils.createUniqueName(usageNames, name);
        }
        if (space.name === defaultName) {
            space.name = '';
        }
        currentVolume.nom = defaultName + (space.name ? ' (' + space.name + ')' : '');
        currentVolume.usageId = space.space_usage;
        usageNames.push(space.name);
    }

    private populateValeursParametres(currentVolume: Volume, defaultValuesForTypeVolumes: TypeVolume, key: string) {
        currentVolume.valeursParametres[key] = defaultValuesForTypeVolumes.valeursDefautParametres[key];
    }

    private getAssociatedZone(zones: any[], roomId: string, storeyId: string) {
        return;
    }
}

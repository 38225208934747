<div>
    <ng-container>
        <div class="d-flex padding-left-22">
            <div class="w-75">
                <span>Questions</span>
            </div>
            <div class="w-25">
                <span>Réponses</span>
            </div>
        </div>
        <mat-divider class="mt-2 mb-3"></mat-divider>
        <ng-container *ngIf="checkpointsList.length > 0">
            <div class="mt-3" *ngFor="let checkpoint of checkpointsList">
                <app-documents-detail-checkpoint
                    [checkpoint]="checkpoint"
                    [readonlyMode]="readonlyMode"
                    (changedCheckpointValue)="onChangeCheckpointValue()"
                ></app-documents-detail-checkpoint>
            </div>
        </ng-container>
        <ng-container *ngIf="checkpointsList.length === 0">
            <div class="d-flex justify-content-center">Aucun point de contrôle défini</div></ng-container
        >
    </ng-container>
</div>

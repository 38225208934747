<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span *ngIf="newVolume">Ajouter une pièce</span>
    <span *ngIf="!newVolume">Éditer une pièce</span>
    <button mat-button (click)="cancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content [formGroup]="form">
    <div class="d-flex w-100 flex-row">
        <app-custom-select
            class="w-100"
            formControlName="nom"
            [readonly]="avecPlan"
            [placeholder]="'Nom de la pièce'"
            [matFormFieldClass]="'w-100'"
            [choices]="volumesToDisplay"
            [addline]="true"
            (valueChangeFromUser)="volumeChanged($event)"
            data-cy="nom-piece-select"
        >
        </app-custom-select>
        <mat-form-field class="w-100 ml-2">
            <input placeholder="Numéro de lot" matInput type="text" formControlName="numLot" [readonly]="avecPlan" />
        </mat-form-field>
    </div>

    <div class="mb-2">Paramètres de la pièce</div>
    <div class="d-flex flex-column parametres">
        <mat-checkbox formControlName="humide">Pièce humide</mat-checkbox>
        <mat-checkbox formControlName="chauffe">Pièce chauffée</mat-checkbox>
        <mat-checkbox formControlName="nonReputeCarrez">Pièce non réputée Carrez</mat-checkbox>
        <mat-checkbox formControlName="nonReputeHabitable">Pièce non réputée habitable</mat-checkbox>
        <mat-checkbox formControlName="nonReputeUtile">Pièce non réputée utile</mat-checkbox>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button
        mat-raised-button
        color="accent"
        [disabled]="!form.valid"
        (click)="confirm()"
        data-cy="valider-ajout-piece-button"
    >
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>

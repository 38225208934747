import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BaseComponent,
    ConfirmationService,
    FileUploaderComponent,
    FileUtils,
    NotificationService,
} from 'src/app/commons-lib';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Document } from 'src/app/model/document.model';
import { EditMode } from 'src/app/model/edit-mode.model';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import {
    mapTypeReportByTypePrestation,
    ReferencePrestation,
    TemplateADO,
    TypeReport,
} from 'src/app/model/reference-prestation.model';
import { TypePrestation, typesPrestation } from 'src/app/model/type-prestation.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { ReportService } from 'src/app/services/report.service';
import { URL_GESTION_PRESTATIONS, URL_GESTION_PRESTATIONS_EDIT } from 'src/app/shared/constants/url.constants';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { presetsPhoto } from '../../../../../model/diagnostic.model';
import { exportSvgTypes } from '../../../../shared/map/export-svg-dialog/shared/conf/export-svg-types.conf';

@Component({
    selector: 'app-creation-prestation-diagnostic',
    templateUrl: './creation-prestation-diagnostic.component.html',
    styleUrls: ['./creation-prestation-diagnostic.component.scss'],
})
export class CreationPrestationDiagnosticComponent extends BaseComponent implements OnInit, OnDestroy {
    idReferencePrestation: string;
    referencePrestationInitial: ReferencePrestation = new ReferencePrestation();
    referencePrestation: ReferencePrestation = new ReferencePrestation();

    listeFullReferencePrestation: ReferencePrestation[] = [];

    formReferencePrestation: FormGroup;

    mode: EditMode = 'CREATE';
    isReadOnly = false;
    listePresetsExportSvg = exportSvgTypes;
    listePresetsExport: {
        id: string;
        label: string;
    }[];
    readonly typeReport = TypeReport;
    typesPrestation = typesPrestation.filter((type) => type !== 'COMMUN');
    currentTypesPrestation: TypePrestation;

    editPicture: boolean;

    readonly enumEtatWorkflow = EtatWorkflow;

    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;
    @ViewChild('fileUploaderTemplate') fileUploaderTemplate: FileUploaderComponent;
    @ViewChild('fileUploaderTemplateBonCommande')
    fileUploaderTemplateBonCommande: FileUploaderComponent;

    listePresets = presetsPhoto;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly referenceApiService: ReferenceApiService,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService,
        private readonly confirmationService: ConfirmationService,
        private readonly reportService: ReportService
    ) {
        super();
    }

    ngOnInit(): void {
        this.listePresetsExport = this.listePresetsExportSvg.map((it) => {
            return {
                label: it.name,
                id: it.id,
            };
        });
        this.formReferencePrestation = this.formBuilder.group({
            nom: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (
                            control.value &&
                            ValidatorUtils.checkAlreadyExist(
                                this.formReferencePrestation.controls.nom.value,
                                this.mode !== 'EDIT',
                                this.referencePrestationInitial.nom,
                                this.listeFullReferencePrestation.map((ref) => ref.nom)
                            )
                        ) {
                            return { erreurNomPrestation: true };
                        }
                        return null;
                    },
                ],
            ],
            regle: 'true',
            etatPrestation: [EtatWorkflow.INACTIF, Validators.required],
            typePrestation: ['', Validators.required],
            presetsReportagePhotosAuto: [],
            presetsExportScreenshot: [],
            idSalesforce: ['', Validators.required],
            optionPlanActive: 'false',
        });

        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        this.idReferencePrestation = params.get('idPrestation');
                        if (this.idReferencePrestation) {
                            this.mode = data.duplicate ? 'DUPLICATE' : 'EDIT';
                            this.isReadOnly = data.consulter;
                            return this.referenceService.findOneReferencePrestation(
                                this.idReferencePrestation,
                                data.duplicate
                            );
                        }
                        return of(null);
                    }),
                    switchMap((currentReferencePrestation) =>
                        forkJoin([of(currentReferencePrestation), this.referenceService.findAllReferencePrestations()])
                    )
                )
            )
            .subscribe(([currentReferencePrestation, listeReferencePrestation]) => {
                if (currentReferencePrestation) {
                    this.referencePrestationInitial = JSON.parse(JSON.stringify(currentReferencePrestation));
                    this.referencePrestation = currentReferencePrestation;
                }

                this.listeFullReferencePrestation = listeReferencePrestation;
                this.currentTypesPrestation = currentReferencePrestation
                    ? currentReferencePrestation.typePrestation
                    : null;

                if (this.mode !== 'CREATE') {
                    let nom = '';
                    const typePrestation = this.referencePrestation.typePrestation;
                    const etatPrestation = EtatWorkflow.INACTIF;
                    let idSalesforce = '';
                    let optionPlanActive = false;
                    let presetsReportagePhotosAuto = [];
                    let presetsExportScreenshot = [];
                    if (this.mode === 'EDIT') {
                        nom = this.referencePrestation.nom;
                        idSalesforce = this.referencePrestation.idSalesforce;
                        optionPlanActive = this.referencePrestation.optionPlanActive;
                        presetsReportagePhotosAuto = this.referencePrestation.presetsReportagePhotosAuto;
                        presetsExportScreenshot = this.referencePrestation.presetsExportScreenshot;
                    } else if (this.mode === 'DUPLICATE') {
                        this.referencePrestation.id = null;
                        this.referencePrestation.templates.forEach((template) => {
                            if (template.document) {
                                template.document = null;
                            }
                        });
                    }

                    this.formReferencePrestation.patchValue({
                        nom: nom,
                        etatPrestation: etatPrestation,
                        typePrestation: typePrestation,
                        idSalesforce: idSalesforce,
                        optionPlanActive: optionPlanActive,
                        presetsReportagePhotosAuto: presetsReportagePhotosAuto,
                        presetsExportScreenshot: presetsExportScreenshot,
                    });
                } else {
                    this.referencePrestation.templates = [];
                    this.formReferencePrestation.controls['regle'].setValidators([Validators.required]);
                }
            });
    }

    /************************ Gestion du pictogramme ***********************/

    uploadPictoReferencePrestation(picture: FormData) {
        const reader = new FileReader();
        reader.readAsDataURL(picture.get('file') as File);
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                const height = img.naturalHeight;
                const width = img.naturalWidth;
                if (height > 57 || width > 57) {
                    this.notificationService.error(
                        'Le pictogramme est trop gros, il doit avoir une taille max de 57 pixels.'
                    );
                    this.editPicture = false;
                } else {
                    this.referencePrestation.pictoImage = picture;
                    this.fileUploader.handleUploadFileServiceCall(this.recupUrlImage());
                }
            };
        };
    }

    recupUrlImage(): Observable<HttpEvent<any>> {
        FileUtils.fileToDataURI(this.referencePrestation.pictoImage.get('file') as File).subscribe((url) => {
            this.referencePrestation.pictoUrl = url;
        });
        return of(new HttpResponse());
    }

    uploadFileFinish(typeDocument: string, success: boolean) {
        if (success) {
            this.notificationService.success('Le ' + typeDocument + ' a bien été chargé.');
            this.editPicture = false;
        }
    }

    deletePictoReferencePrestation() {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer ce pictogramme ?', () => {
            this.referencePrestation.pictoImage = undefined;
            this.referencePrestation.pictoUrl = undefined;
        });
    }

    /************************ Fin gestion du pictogramme ***********************/

    /************************ Gestion du template des rapports ***********************/

    /**
     * Sélection du TemplateReport dans la liste des templateReports en fonction du type de rapport
     */
    getTemplateReport(listeTemplateReport: TemplateADO[], typeReport: TypeReport): TemplateADO {
        if (listeTemplateReport) {
            return listeTemplateReport.find((templateADO) => templateADO.typeReport === typeReport);
        }
        return null;
    }

    /**
     * updatePrestationTemplatesList met a jours la liste des templates en fonction du nouveau type de prestation sélectionné
     */
    updatePrestationTemplatesList() {
        const refTypePRestaion = this.currentTypesPrestation;

        // récupération de la liste des templates qu'il faut pour un type de prestation donné
        const listRaportToSet = mapTypeReportByTypePrestation.get(
            this.formReferencePrestation.get('typePrestation').value
        );

        // on rajoute les templates manquant
        listRaportToSet.forEach((typeRapport) => {
            if (!this.referencePrestation.templates.find((elm) => elm.typeReport === typeRapport)) {
                this.referencePrestation.templates.push({
                    document: null,
                    typeReport: typeRapport,
                });
            }
        });

        // on supprime les templates en trop
        this.referencePrestation.templates.forEach((templateADO) => {
            if (!listRaportToSet.includes(templateADO.typeReport)) {
                // si il y a un document de défini pour le type de template que l'on souhaite retirer de la référence de prestation,
                // il faut vérifier si l'utilisateur veux vraiment le supprimer
                if (templateADO && templateADO.document) {
                    this.confirmationService.confirmWarn(
                        'Êtes-vous sûr de vouloir supprimer ce template: ' +
                            templateADO.document.nomFichier +
                            ', cette action est irréversible ?',
                        () => {
                            // on supprime le document et le fichier en base
                            this.reportService
                                .deleteRapportTemplate(
                                    this.referencePrestation.id,
                                    templateADO.typeReport,
                                    templateADO.document.idFichier
                                )
                                .subscribe(() => {
                                    // on supprime le template de la liste des templates
                                    this.referencePrestation.templates.splice(
                                        this.referencePrestation.templates.indexOf(templateADO),
                                        1
                                    );
                                });
                        },
                        () => {
                            // si on ne veut pas supprimer le document, il faut remettre le type de prestation à l'état d'avant
                            this.formReferencePrestation.get('typePrestation').patchValue(refTypePRestaion);
                            this.currentTypesPrestation = refTypePRestaion;
                        }
                    );
                } else {
                    // si il n'y a pas de doccument, on se contente de retirer le template de la liste des templates
                    this.referencePrestation.templates.splice(
                        this.referencePrestation.templates.indexOf(templateADO),
                        1
                    );
                }
            }
        });
        // on stock la valeur du nouveau type de prestation
        this.currentTypesPrestation = this.formReferencePrestation.get('typePrestation').value;
    }

    /**
     * Sélection de la pièce jointe du document
     */
    onClickSelectFile(typeReport: TypeReport, fileUploaderTemplate: FileUploaderComponent) {
        const currentTemplate = this.referencePrestation.templates
            ? this.referencePrestation.templates.find((templateADO) => templateADO.typeReport === typeReport)
            : null;
        if (currentTemplate && !currentTemplate.document) {
            currentTemplate.document = new Document();
            fileUploaderTemplate.selectFile();
        } else {
            this.confirmationService.confirm('Changer le fichier attaché ?', () => {
                fileUploaderTemplate.selectFile();
            });
        }
    }

    onClickDeleteTemplateRapport(typeReport: TypeReport) {
        this.confirmationService.confirmWarn(
            'Êtes-vous sûr de vouloir supprimer ce template, cette action est irréversible ?',
            () => {
                const templateADO = this.getTemplateReport(this.referencePrestation.templates, typeReport);
                if (templateADO && templateADO.document && templateADO.document.id) {
                    this.reportService
                        .deleteRapportTemplate(this.referencePrestation.id, typeReport, templateADO.document.idFichier)
                        .subscribe(() => {
                            this.referencePrestation.templates.find(
                                (templateADOTemp) => templateADOTemp.typeReport === typeReport
                            ).document = null;
                        });
                }
            }
        );
    }

    /**
     * Upload de la pièce jointe
     */
    uploadTemplateRapport(formData: FormData, typeReport: TypeReport, fileUploaderTemplate: FileUploaderComponent) {
        const file = formData.get('file') as File;
        if (file.name.endsWith('.docx')) {
            const currentTemplateADO = this.getTemplateReport(this.referencePrestation.templates, typeReport);
            const templateDocumentId = currentTemplateADO.document ? currentTemplateADO.document.id : null;
            this.reportService.uploadTemplateRapportDocumentFile(templateDocumentId, formData).subscribe((document) => {
                currentTemplateADO.document = document;
                fileUploaderTemplate.handleUploadFileServiceCall(of());
            });
        } else {
            this.notificationService.error('Le template doit être au format .docx');
        }
    }

    uploadFileTemplateRapportFinish(success: boolean) {
        if (success) {
            this.notificationService.success('Le fichier a bien été ajouté');
        }
    }

    /**
     *  Ouverture de la pièce jointe
     */
    onClickOpenDocument(typeReport: TypeReport) {
        this.reportService.getTemplateReportToDowload(
            this.getTemplateReport(this.referencePrestation.templates, typeReport).document
        );
    }

    /************************ Fin gestion du template de rapport ***********************/

    validerPrestation() {
        // Values
        this.referencePrestation.nom = this.formReferencePrestation.value.nom;
        this.referencePrestation.etatPrestation = this.formReferencePrestation.value.etatPrestation;
        this.referencePrestation.typePrestation = this.formReferencePrestation.value.typePrestation;
        this.referencePrestation.idSalesforce = this.formReferencePrestation.value.idSalesforce;
        this.referencePrestation.presetsReportagePhotosAuto =
            this.formReferencePrestation.value.presetsReportagePhotosAuto;
        this.referencePrestation.presetsExportScreenshot = this.formReferencePrestation.value.presetsExportScreenshot;
        this.referencePrestation.optionPlanActive = this.formReferencePrestation.value.optionPlanActive;

        // ------------- Gestion des règles de la prestation ----------------
        if (!this.formReferencePrestation.value.regle) {
            this.referencePrestation.idRegles = undefined;
        }

        this.cnSpinnerService
            .withSpinner(
                this.upsertReferencePrestation().pipe(
                    switchMap((refSave) => {
                        // Gestion du picto
                        if (this.referencePrestation.pictoImage) {
                            return this.referenceApiService.uploadPictoReferencePrestation(
                                refSave.id,
                                this.referencePrestation.pictoImage
                            );
                        } else {
                            return of(null);
                        }
                    }),
                    switchMap(() => this.referenceService.forceReloadReferencePrestation())
                )
            )
            .subscribe(() => {
                this.notificationService.success(`La prestation a été ${this.mode === 'EDIT' ? 'modifiée' : 'créée'}`);
                this.back();
            });
    }

    private upsertReferencePrestation(): Observable<ReferencePrestation> {
        if (this.mode === 'EDIT') {
            return this.referenceApiService.updateReferencePrestation(this.referencePrestation);
        } else {
            return this.referenceApiService.createReferencePrestation(this.referencePrestation);
        }
    }

    editer() {
        this.router.navigate([URL_GESTION_PRESTATIONS_EDIT, this.referencePrestation.id]);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    back() {
        this.router.navigate([URL_GESTION_PRESTATIONS]);
    }
}

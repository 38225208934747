import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService, isServerUnavailableError, MongoUtils, NotificationService } from 'src/app/commons-lib';
import { environment } from '../../environments/environment';
import { dataUrlToBlob } from '../shared/offline/file.utils';
import { Document } from '../model/document.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FileApiService } from './file-api.service';

class FileDownload {
    constructor(public url: string) {}
}

@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(private http: HttpClient, private notificationService: NotificationService) {}

    downloadDocumentFile(fileDownloadTokenUrl: string, fileName: string): void {
        // Récupère le token
        this.http.get<FileDownload>(fileDownloadTokenUrl).subscribe(
            (f) => {
                this.createElementToDownload(environment.apiUrl.concat(f.url), fileName);
            },
            (err) => {
                if (isServerUnavailableError(err)) {
                    this.notificationService.error(`Le fichier n'est pas disponible hors-connexion.`);
                } else if (err.status === 404) {
                    this.notificationService.error(`Le fichier n'a pas pu être trouvé. Merci de contacter le support.`);
                } else {
                    this.notificationService.error(
                        `Une erreur inconnue s'est produite. Merci de contacter le support.`
                    );
                }
            }
        );
    }

    createElementToDownload(hrefUrl: string, fileName: string) {
        const element = document.createElement('a');
        element.href = hrefUrl;
        element.target = '_blank';
        element.download = fileName;
        element.click();
    }

    /**
     * Crée un élément de téléchargement dans le DOM à partir du fileContent passé en paramètre
     * et execute le téléchargement
     * @param fileName
     * @param fileContent
     */
    createElementToDownloadByFileContent(fileName: string, fileContent: string) {
        const a = document.createElement('a');
        a.href = fileContent;
        a.download = fileName;
        a.click();
    }

    verifySizeDocument(currentDocumentUrl: string): string {
        const imageBlob = dataUrlToBlob(currentDocumentUrl);
        if (imageBlob.size > 10 * 1048576) {
            this.notificationService.error('Le document est trop volumineux. Veuillez en utiliser un autre.');
            return null;
        } else {
            return currentDocumentUrl;
        }
    }
}

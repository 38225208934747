import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import { TypeVolumeAdmin } from 'src/app/model/type-element-a-controler.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { PaginatedDataSource } from 'src/app/shared/paging/page';
import { SessionStoragePaginationUtils } from 'src/app/utils/session-storage-pagination.utils';
import {
    DEBOUNCE_TIME_VALUE,
    INITIAL_PAGE_SIZE,
    SESSION_STORAGE_KEY_PIECE,
} from 'src/app/shared/constants/admin.constants';
import { URL_GESTION_PIECES_CONSULTER, URL_GESTION_PIECES_EDIT } from 'src/app/shared/constants/url.constants';

export const DEFAULT_SORT = { direction: 'asc', active: 'nom' };

@Component({
    selector: 'app-tableau-piece',
    templateUrl: './tableau-piece.component.html',
    styleUrls: ['./tableau-piece.component.scss'],
})
export class TableauPieceComponent extends BaseComponent implements OnInit {
    @Input()
    initialPageSize = INITIAL_PAGE_SIZE;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns: string[] = ['nom', 'typesElementsAControlerToString', 'etatVolume', 'actions'];
    dataSource: PaginatedDataSource<TypeVolumeAdmin>;
    sort: Sort;
    searchValue = SessionStoragePaginationUtils.getInitialReseach(SESSION_STORAGE_KEY_PIECE);
    private search = new BehaviorSubject(this.searchValue);

    constructor(
        private readonly referenceApiService: ReferenceApiService,
        private readonly router: Router,
        private readonly cnSpinnerService: CnSpinnerService
    ) {
        super();
        this.sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_PIECE, DEFAULT_SORT);
    }

    ngOnInit(): void {
        this.search
            .pipe(debounceTime(DEBOUNCE_TIME_VALUE), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.dataSource.fetch(0, this.dataSource.size);
            });
        this.dataSource = new PaginatedDataSource<TypeVolumeAdmin>(
            (pageRequest) => {
                return this.cnSpinnerService.withSpinner(
                    this.referenceApiService.searchTypeVolumePourAdminPage(this.search.getValue(), pageRequest).pipe(
                        map((volumes) => {
                            volumes.content.map((volume) => {
                                return this.prepareVolume(volume);
                            });
                            return volumes;
                        })
                    )
                );
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_PIECE, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_PIECE, this.initialPageSize)
        );
    }

    onClickDupliquerVolume(volume: TypeVolumeAdmin) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_PIECES_EDIT, volume.id, 'duplicate']);
    }

    onClickEditerVolume(volume: TypeVolumeAdmin) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_PIECES_EDIT, volume.id]);
    }

    onClickChangerEtatVolume(volume: TypeVolumeAdmin) {
        volume.etatVolume = volume.etatVolume === EtatWorkflow.ACTIF ? EtatWorkflow.INACTIF : EtatWorkflow.ACTIF;
        this.referenceApiService.upsertTypeVolume(volume).subscribe(() => {
            // On réinitialise la liste des volumes dans le cache de l'appli
            this.referenceApiService.buildTypesVolumes$();
        });
    }

    onClickConsulter(volume: TypeVolumeAdmin) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_PIECES_CONSULTER, volume.id]);
    }

    onKeyupSearchVolumes($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
        this.setSessionStorageItem();
    }

    onChangeSortVolumes($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
        this.setSessionStorageItem();
    }

    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(
            SESSION_STORAGE_KEY_PIECE,
            this.dataSource.size,
            this.search.getValue(),
            this.sort
        );
    }

    private prepareVolume(volume: TypeVolumeAdmin): TypeVolumeAdmin {
        volume.piecesPreequipeesConfigs.forEach((typeElementConfiguration) => {
            if (volume.typesElementsAControlerToString && volume.typesElementsAControlerToString.length > 0) {
                volume.typesElementsAControlerToString += `
${typeElementConfiguration.nom} : ${typeElementConfiguration.typesElementsAControlerAssocies
                    .map((it) => it.nom)
                    .join(', ')}`;
            } else {
                volume.typesElementsAControlerToString = `${
                    typeElementConfiguration.nom
                } : ${typeElementConfiguration.typesElementsAControlerAssocies.map((it) => it.nom).join(', ')}`;
            }
        });

        return volume;
    }
}

import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { TypeBien } from '../model/type-bien.model';
import { RaisonAnnulation } from '../model/raison-annulation';
import { Aide } from '../model/aide.model';
import { TypeElementAControler, TypeVolume } from '../model/type-element-a-controler.model';
import { ReferencePrestation } from '../model/reference-prestation.model';
import { TypeDocument } from '../model/type-document.model';

import { AuthenticationService } from 'src/app/commons-lib';
import { ReferenceApiService } from './reference-api.service';
import { NavigationUtils } from '../utils/navigation.utils';
import { TypeOuvrage } from '../model/type-ouvrage.model';
import { TypeMateriau } from '../model/type-materiau.model';
import { CategorieDocument } from '../model/categorie-document.model';
import { CategorieCommentairePredefiniService } from './categorie-commentaire-predefini.service';
import { CategorieCommentairePredefini } from '../model/categorie-commentaire-predefini.model';
import { CommentairePredefini } from '../model/commentaire-predefini.model';
import { CommentairePredefiniService } from './commentaire-predefini.service';
import { GenericObject } from '@acenv/cnmap-angular-editor-lib';

/**
 * Service pour les données de référence.
 *
 * 2 niveaux de cache :
 * - Cache RxJS, à durée courte : renvoie directement la valeur cachée via un shareReplay, évite d'appeler l'API trop souvent
 * - Cache service worker, à durée longue : lorsque l'API est appelée, permet de renvoyer une valeur en mode hors-ligne
 *
 * NB : les données renvoyées depuis le cache RxJS n'ont pas besoin d'être clonées, car elles sont en lecture seule
 */
@Injectable({
    providedIn: 'root',
})
export class ReferenceService {
    // Cache RxJS
    private typesBien$: Observable<TypeBien[]>;
    private raisonsAnnulation$: Observable<RaisonAnnulation[]>;
    private typesElementsAControler$: Observable<TypeElementAControler[]>;
    private typesVolumes$: Observable<TypeVolume[]>;
    private aides$: Observable<Aide[]>;
    private typesOuvrage$: Observable<TypeOuvrage[]>;
    private typesMateriau$: Observable<TypeMateriau[]>;
    private referencePrestation$: Observable<ReferencePrestation[]>;
    private typesDocument$: Observable<TypeDocument[]>;
    private commentairesPredefinis$: Observable<CommentairePredefini[]>;
    private categorieDocuments$: Observable<CategorieDocument[]>;
    private categoriesCommentairePredefini$: Observable<CategorieCommentairePredefini[]>;

    private wallTypes$: Observable<any>;
    private fencesTypes$: Observable<any>;
    private openingTypes$: Observable<any>;
    private fenceOpeningsTypes$: Observable<any>;
    private balconyTypes$: Observable<any>;
    private beamTypes$: Observable<any>;
    private commentStyles$: Observable<any>;
    private spaceUsages$: Observable<any>;
    private spaceUsagesEquipments$: Observable<any>;
    public objects$: Observable<GenericObject[]>;

    // private jwt: string;

    constructor(
        private referenceApiService: ReferenceApiService,
        private authenticationService: AuthenticationService,
        private categorieCommentairePredefiniService: CategorieCommentairePredefiniService,
        private commentairePredefiniService: CommentairePredefiniService
    ) {
        // this.authenticationService.getAuthenticationToken().subscribe((token) => (this.jwt = token));
        this.buildCacheObservables();
        setInterval(() => this.buildCacheObservables(), 30 * 60 * 1000); // invalide le cache RxJS après 30mn (au cas où le référentiel ait été modifié)
    }

    private buildCacheObservables() {
        this.typesBien$ = this.referenceApiService.buildTypesBien$();
        this.typesOuvrage$ = this.referenceApiService.buildTypesOuvrage$();
        this.typesMateriau$ = this.referenceApiService.buildTypesMateriau$();
        this.raisonsAnnulation$ = this.referenceApiService.buildRaisonsAnnulation$();
        this.typesElementsAControler$ = this.referenceApiService.buildTypesEquipementsAControler$();
        this.typesVolumes$ = this.referenceApiService.buildTypesVolumes$();
        this.aides$ = this.referenceApiService.buildAides$();
        this.referencePrestation$ = this.referenceApiService.buildReferencePrestation$();
        this.typesDocument$ = this.referenceApiService.buildTypesDocument$();
        this.commentairesPredefinis$ = this.commentairePredefiniService.buildCommentairesPredefinis$();
        this.categorieDocuments$ = this.referenceApiService.buildCategorieDocuments$();
        this.categoriesCommentairePredefini$ =
            this.categorieCommentairePredefiniService.buildCategoriesCommentairePredefini$();
        /*
        combineLatest([this.commentairesPredefinis$, this.categoriesCommentairePredefini$]).subscribe(
            ([commentaires, categories]) => {
                commentaires.forEach((commentaire) => {
                    if (commentaire.categoriesName == undefined) commentaire.categoriesName = [];
                    commentaire.categoriesName.push(
                        ...categories.filter((c) => {
                            commentaire.categories.includes(c.id);
                        })
                    );
                });

                return commentaires;
            }
        );
*/
        this.wallTypes$ = this.referenceApiService
            .getWallTypes()
            .pipe(map((res) => (res && res.content.length ? JSON.parse(res.content) : [])));
        this.fencesTypes$ = this.referenceApiService
            .getFenceTypes()
            .pipe(map((res) => (res && res.content.length ? JSON.parse(res.content) : [])));
        this.openingTypes$ = this.referenceApiService
            .getOpeningTypes()
            .pipe(map((res) => (res && res.content.length ? JSON.parse(res.content) : [])));
        this.fenceOpeningsTypes$ = this.referenceApiService
            .getFenceOpeningTypes()
            .pipe(map((res) => (res && res.content.length ? JSON.parse(res.content) : [])));
        this.balconyTypes$ = this.referenceApiService
            .getBalconyTypes()
            .pipe(map((res) => (res && res.content.length ? JSON.parse(res.content) : [])));
        this.beamTypes$ = this.referenceApiService
            .getBeamTypes()
            .pipe(map((res) => (res && res.content.length ? JSON.parse(res.content) : [])));
        this.commentStyles$ = this.referenceApiService
            .getCommentStyles()
            .pipe(map((res) => (res && res.content.length ? JSON.parse(res.content) : [])));
        this.spaceUsages$ = this.referenceApiService
            .getSpaceUsages()
            .pipe(map((res) => (res && res.content.length ? JSON.parse(res.content) : [])));
        this.spaceUsagesEquipments$ = this.referenceApiService
            .getSpaceUsagesEquipments()
            .pipe(map((res) => (res && res.content.length ? JSON.parse(res.content) : [])));
        this.objects$ = this.findAllTypesElementsAControlerGenericObject().pipe(
            map((res) => {
                console.log(res);
                return res && res.length ? res : [];
            })
        );
    }

    /**
     * Charge tout le référentiel.
     * Ceci permet de le rendre accessible dans le cache, pour utilisation en mode hors-ligne.
     */
    loadReferenceData() {
        forkJoin([
            this.findAllTypesBien(),
            this.findAllTypesOuvrage(),
            this.findAllTypesMateriau(),
            this.findAllRaisonsAnnulation(),
            this.findAllTypesElementsAControler(),
            this.findAllTypesVolumes(),
            this.findAllAides(),
            this.findAllReferencePrestations(),
            this.findAllTypesDocument(),
            this.findAllCommentairesPredefinis(),
            this.findAllCategorieDocuments(),
            this.findAllWallTypes(),
            this.findAllFenceTypes(),
            this.findAllOpeningTypes(),
            this.findAllFenceOpeningTypes(),
            this.findAllBalconyTypes(),
            this.findAllBeamTypes(),
            this.findAllCommentStyles(),
            this.findAllSpaceUsages(),
            this.findAllSpaceUsagesEquipments(),
            this.findAllTypesElementsAControler(),
        ]).subscribe();
    }

    /**
     * Renvoie tous les types de bien.
     */
    findAllTypesBien(): Observable<TypeBien[]> {
        return this.typesBien$;
    }

    /**
     * Renvoie tous les types d'ouvrage.
     */
    findAllTypesOuvrage(): Observable<TypeOuvrage[]> {
        return this.typesOuvrage$;
    }

    /**
     * Renvoie tous les types de materiau.
     */
    findAllTypesMateriau(): Observable<TypeMateriau[]> {
        return this.typesMateriau$;
    }

    /**
     * Renvoie toutes les raisons d'annulation d'une intervention.
     */
    findAllRaisonsAnnulation(): Observable<RaisonAnnulation[]> {
        return this.raisonsAnnulation$;
    }

    /**
     * Renvoie toutes les aides de l'application.
     */
    findAllAides(): Observable<Aide[]> {
        return this.aides$;
    }

    /**
     * Renvoie toutes les références de commentaires prédéfinis de l'application
     */
    findAllCommentairesPredefinis(): Observable<CommentairePredefini[]> {
        return this.commentairesPredefinis$;
    }

    /**
     * Force le rechargement des commentaires prédéfinis
     */
    forceReloadCommentairesPredefinis(): Observable<CommentairePredefini[]> {
        this.commentairesPredefinis$ = this.commentairePredefiniService.buildCommentairesPredefinis$();
        return this.commentairesPredefinis$;
    }

    /** --------------------------  Type élément à controler ------------------------- */
    /**
     * Renvoie tous les types éléments à controler.
     */
    findAllTypesElementsAControler(): Observable<TypeElementAControler[]> {
        return this.typesElementsAControler$;
    }

    findAllTypesElementsAControlerGenericObject(): Observable<GenericObject[]> {
        this.objects$ = this.referenceApiService.findAllTypesElementsAControler().pipe(
            map((its) => {
                const objects = [];
                if (its?.length) {
                    for (const it of its) {
                        //Permet de filtrer les équipements abstraits
                        if (!!it.codeBim && !it.abstrait) {
                            const result = new GenericObject();
                            result.id = it.id;
                            result.name = it.nom;
                            result.productTypeCodeBim = it.codeBim;
                            result.iconImageData = it.icone;
                            result.width = it.largeur;
                            result.depth = it.profondeur;
                            result.height = it.hauteur;
                            objects.push(result);
                        }
                    }
                }
                return objects;
            }),
            shareReplay(1)
        );
        return this.objects$;
    }

    forceReloadTypesElementsAControler(): Observable<TypeElementAControler[]> {
        this.typesElementsAControler$ = this.referenceApiService.buildTypesEquipementsAControler$();
        return this.typesElementsAControler$;
    }

    /** --------------------------  Type d'ouvrage ------------------------- */
    forceReloadTypesOuvrage(): Observable<TypeOuvrage[]> {
        this.typesOuvrage$ = this.referenceApiService.buildTypesOuvrage$();
        return this.typesOuvrage$;
    }

    /** --------------------------  Type de matériaux ------------------------- */
    forceReloadMateriaux(): Observable<TypeMateriau[]> {
        this.typesMateriau$ = this.referenceApiService.buildTypesMateriau$();
        return this.typesMateriau$;
    }

    /** --------------------------  Volumes  ------------------------- */
    /**
     * Renvoie tous les types volumes.
     */
    findAllTypesVolumes(): Observable<TypeVolume[]> {
        return this.typesVolumes$;
    }

    forceReloadTypesVolumes(): Observable<TypeVolume[]> {
        this.typesVolumes$ = this.referenceApiService.buildTypesVolumes$();
        return this.typesVolumes$;
    }

    /** --------------------------  Référence Prestation ------------------------- */
    /**
     * Renvoie toutes les références de prestations de l'application
     */
    findAllReferencePrestations(): Observable<ReferencePrestation[]> {
        return this.referencePrestation$;
    }

    forceReloadReferencePrestation(): Observable<ReferencePrestation[]> {
        this.referencePrestation$ = this.referenceApiService.buildReferencePrestation$();
        return this.referencePrestation$;
    }

    /**
     * Si cleanPicto, on ne récupère pas le pictogramme. Utilisé en cas de création ou duplication, l'utilisateur devra redéfinir le picto
     * @param idPrestation
     * @param cleanPicto
     * @returns
     */
    findOneReferencePrestation(idPrestation: string, cleanPicto: boolean): Observable<ReferencePrestation> {
        return this.referenceApiService.findOneReferencePrestation(idPrestation).pipe(
            switchMap((prestation) => {
                if (cleanPicto) {
                    prestation.idFichierPicto = undefined;
                } else if (prestation.idFichierPicto) {
                    // prestation.pictoUrl = NavigationUtils.getPictoReferencePrestationUrl(prestation, this.jwt);
                    prestation.pictoUrl = NavigationUtils.getPictoReferencePrestationUrl(prestation);
                }
                return of(prestation);
            })
        );
    }

    /** ------------------------------------------------------------------------ */
    /** --------------------------  Catégorie document ------------------------- */

    /** ------------------------------------------------------------------------ */
    /**
     * Renvoie toutes les références de catégories de document de l'application
     */
    findAllCategorieDocuments(): Observable<CategorieDocument[]> {
        return this.categorieDocuments$;
    }

    /**
     * Renvoie toutes les références de catégories de document de l'application
     */
    findNameCategorieDocumentFromId(idCategorieRecherche: string, allCategorieDocument: CategorieDocument[]): string {
        const categorieById = allCategorieDocument.find((categorieTemp) => categorieTemp.id == idCategorieRecherche);
        if (categorieById) {
            return categorieById.nom;
        } else {
            return idCategorieRecherche;
        }
    }

    /**
     * Recharge la liste des catégorie de document
     * @returns
     */
    forceReloadCategorieDocument(): Observable<CategorieDocument[]> {
        this.categorieDocuments$ = this.referenceApiService.buildCategorieDocuments$();
        return this.categorieDocuments$;
    }

    /**
     * Crée ou met à jour une catégories de document depuis l'admin
     * @param categorieDocument
     * @returns
     */
    upsertCategorieDocument(categorieDocument: CategorieDocument): Observable<CategorieDocument> {
        return this.referenceApiService.upsertCategorieDocument(categorieDocument);
    }

    /** ------------------------------------------------------------------- */
    /** --------------------------  Type document ------------------------- */

    /** ------------------------------------------------------------------- */
    /**
     * Renvoie toutes les références de types document de l'application
     */
    findAllTypesDocument(): Observable<TypeDocument[]> {
        return this.typesDocument$;
    }

    /**
     * Crée ou met à jour un type de document depuis l'admin
     * @param typeDocument
     * @returns
     */
    upsertTypeDocument(typeDocument: TypeDocument): Observable<TypeDocument> {
        return this.referenceApiService.upsertTypeDocument(typeDocument);
    }

    /**
     * Recharge la liste des types de document
     * @returns
     */
    forceReloadTypeDocument(): Observable<TypeDocument[]> {
        this.typesDocument$ = this.referenceApiService.buildTypesDocument$();
        return this.typesDocument$;
    }

    //
    // Catégories commentaires prédéfinis
    //

    /**
     * Renvoie la liste des catégories de commentaires prédéfinis
     */
    findAllCategoriesCommentairePredefini(): Observable<CategorieCommentairePredefini[]> {
        return this.categoriesCommentairePredefini$;
    }

    /**
     * Recharge la liste des catégories de commentaires prédéfinis
     */
    forceReloadCategoriesCommentairePredefini(): Observable<CategorieCommentairePredefini[]> {
        this.categoriesCommentairePredefini$ =
            this.categorieCommentairePredefiniService.buildCategoriesCommentairePredefini$();
        return this.categoriesCommentairePredefini$;
    }

    findAllWallTypes(): Observable<any> {
        return this.wallTypes$;
    }

    findAllOpeningTypes(): Observable<any> {
        return this.openingTypes$;
    }

    findAllBalconyTypes(): Observable<any> {
        return this.balconyTypes$;
    }

    findAllBeamTypes(): Observable<any> {
        return this.beamTypes$;
    }

    findAllCommentStyles(): Observable<any> {
        return this.commentStyles$;
    }

    findAllSpaceUsages(): Observable<any> {
        return this.spaceUsages$;
    }

    findAllSpaceUsagesEquipments(): Observable<any> {
        return this.spaceUsagesEquipments$;
    }

    findAllFenceTypes() {
        return this.fencesTypes$;
    }

    findAllFenceOpeningTypes() {
        return this.fenceOpeningsTypes$;
    }
}

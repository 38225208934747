"use strict";
//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_space_labelizer
 * A class to manage space labels
 */
import {cn_event_handler} from "./cn_event_handler";
import {cn_object_instance} from "../model/cn_object_instance";
import {cn_camera} from "./cn_camera";
import {cn_scene_controller} from "./cn_scene_controller";
import {cn_storey} from "../model/cn_storey";
import {cn_svg_map} from "./cn_svg_map";
import {cn_space} from "../model/cn_space";
import {cn_add, cn_box, cn_clone, cn_mul, cn_sub} from "../utils/cn_utilities";
import {cn_image_dir} from "../utils/image_dir";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_area_context} from "../model/cn_area_context";

const CN_SPACE_LABEL_WIDTH= [150,235];

//***********************************************************************************
//**** internal : space label class
//***********************************************************************************
class cn_equipment_label {

	constructor(equipment) {
		this.equipment = equipment;
		this.box = new cn_box();
		this.svg = "";
	}
}

class cn_space_label {

	constructor(space) {
		this.space = space;
		this.box = new cn_box();
		this.move_box = new cn_box();
		this.edit_box = new cn_box();
		this.svg = "";
		this.equipment_labels = [];
	}
}

//***********************************************************************************

export class cn_space_labelizer extends cn_event_handler {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_storey} storey
	 * @param {cn_scene_controller} controller
	 * @param {cn_svg_map} svg_map
	 */
	constructor(storey, controller = null, svg_map = null) {
		super();
		this._storey = storey;
		this._controller = controller;
		this._scene = storey.scene;
		this._map = svg_map;
		this._area_context = null;

		//*** Settings */
		//*** Are space labels visible? */
		this.visible = true;

		//*** Are space labels interactive? */
		this.interactive = true;

		this.set_discrete_settings();

		//*** Interactive elements */
		this._mouseover_space = null;
		this._mouseover_equipment = null;
		this._mouseover_move = false;

		this._grabbed = false;
		this._point = [0,0];
		this._expanded_space = null;

		this._previous_zoom = 0;

		this._space_labels = [];
	}

	//***********************************************************************************
	/**
	 * Set default settings
	 */
	set_default_settings() {
		//*** expanding mode: 0 = never, 1=on click, 2 = always */
		this.expanding_mode = 1;

		//*** move button visibility */
		this.move_button = true;

        //*** Area visibility for each mode */
		this.name_visible=[true,true];

		//*** Area visibility for each mode */
		this.type_visible=[false,true];

		//*** Area visibility for each mode */
		this.area_visible=[true,true];

		//*** Perimeter visibility for each mode */
		this.perimeter_visible=[false,true];

		//*** floor offset visibility for each mode */
		this.floor_offset_visible=[false,!this._storey.exterior];

		//*** Ceiling visibility for each mode */
		this.ceiling_visible=[false,!this._storey.exterior];

		//*** Facing visibility for each mode */
		this.facing_visible=[false,true];

		//*** Equipments visibility for each mode */
		this.equipments_visible=[true,true];

		this.parameter_keys = {
			type_visible : "Type",
			area_visible : "Surface",
			perimeter_visible : "Périmètre",
			floor_offset_visible : "Décalage de dalle",
			ceiling_visible : "Plafond",
			facing_visible : "Revêtements",
			equipments_visible : "Equipements"
		};
	}

	//***********************************************************************************
	/**
	 * Set discrete settings : only the name, not reactive
	 */
	set_discrete_settings() {
		this.set_default_settings();
		this.expanding_mode = 0;
		this.area_visible=[false,true];
	}

	/**
	 * Sets current area context. If not null, will always add the areas of that context
	 * @param {cn_area_context} area_context
	 */
	set_area_context(area_context)
	{
		this._area_context = area_context;
	}

	//***********************************************************************************
	/**
	 * Expands a given space.
	 * @param {cn_space} space
	 * @returns {boolean} true if this had an effect.
	 */
	select_space(space) {
		if (this.expanding_mode != 1) return false;
		if (space == this._expanded_space) return false;
		if (this._scene.spaces.indexOf(space) < 0) return false;
		this._expanded_space = space;
		return true;
	}

	//***********************************************************************************
	/**
     * Draws space labels
     * @param {cn_camera} camera
     * @param add_classes
     * @returns {string}
     */
	draw(camera, add_classes = []) {
		var html = "";
		if (!this.visible) return html;

		const box_offset = [CN_SPACE_LABEL_WIDTH[0]/2,20];

		this.update_labels(add_classes);

		if (this._mouseover_space)
		{
			html += this._mouseover_space._draw_path(camera,"space_label_highlight mouseover");
		}

		for (var i=0;i<this._space_labels.length;i++)
		{
			var space_label = this._space_labels[i];
            space_label.box.posmin = cn_sub(camera.world_to_screen(cn_add(space_label.space.center,space_label.space.label_position)),box_offset);

			//*** adjust placement to avoid overlapping */
			var overlap = false;
			for (var j=0;j<i;j++)
			{
				if (space_label.box.intersects(this._space_labels[j].box))
				{
					overlap = true;
					break;
				}
			}
            if (overlap) continue;

			html += `<g transform="translate(${space_label.box.posmin[0]},${space_label.box.posmin[1]})">`;

			var svg = space_label.svg + "";
			if (space_label.space == this._mouseover_space)
			{
				if (this._mouseover_move)
					svg = svg.replace("space_label_move_button","space_label_move_button mouseover");
				else if (this.expanding_mode == 1)
					svg = svg.replace("space_label_background","space_label_background mouseover");
			}
			html += svg;

			html += `</g>`;
		}
		return html;
	}

	//***********************************************************************************
	/**
	 * Update all labels of the scene
	 */
	update_labels(add_classes = []) {
		this._space_labels = [];
		this._scene.spaces.forEach(space => {
			if (!space.outside)
			{
				var space_label = new cn_space_label(space);
				this._space_labels.push(space_label);
				this._update(space,space_label, add_classes);
			}
		});
	}

	//***********************************************************************************
	/**
	 * Update one space label
	 * @param {cn_space} space
	 * @param {cn_space_label} space_label
	 */
	_update(space, space_label, add_classes=[]) {
		space_label.box.posmin = [0,0];
		space_label.box.size = [0,0];

		var expanded = (this.expanding_mode == 0 || (this.expanding_mode == 1 && space != this._expanded_space))?0:1;
		if (this._controller && this._controller.get_selection().indexOf(space)>=0) expanded = 1;

        if ([this.name_visible[expanded], this.area_visible[expanded]].every(isVisible => !isVisible)) {
            return;
        }

		space_label.box.size[0] = CN_SPACE_LABEL_WIDTH[expanded];
        if ( add_classes.indexOf("exp") > -1) {
            space_label.box.size[0] = CN_SPACE_LABEL_WIDTH[1];
        }

		space_label.svg = "";

        const extraList = [...add_classes];
        if (expanded) {
            extraList.push('expanded');
        }
        const extra = extraList.join(' ');


		//*** Draw move button */
		var button_svg = "";
		if (this.interactive && this.move_button)
		{
			space_label.move_box.posmin = [3,3];
			space_label.move_box.size = [20,20];
			var bb = space_label.move_box;
			var p = cn_add(bb.posmin,cn_mul(bb.size,0.5));
			var radius = 0.5*bb.size[0];
			button_svg += "<circle class='space_label_move_button' cx='" + p[0] + "' cy='" + p[1] + "' r='" + radius + "'/>";
			button_svg += "<image xlink:href='" + cn_image_dir() + "arrow_all_white.svg' x='" + (bb.posmin[0]) + "' y='" + (bb.posmin[1]) + "' width='" + bb.size[0] + "' height='" + bb.size[1] + "' preserveAspectRatio='xMidYMid meet'/>";
		}

		var x = 5;
		var x0 = x;
		var ww = space_label.box.size[0];
		if (this.move_button)
		{
			x0 += space_label.move_box.posmin[0] + space_label.move_box.size[0];
			ww -= space_label.move_box.posmin[0] + space_label.move_box.size[0];
		}

		var y = 18;
		var line_height = expanded ? 18 : 20;

		//*** Write name */
        if (this.name_visible[expanded]) {
            space_label.svg += `<text x="${x0}" y="${y}" class="space_label_name ${extra}">${space.get_name(this._storey)}</text>`;
            y += line_height;
        }

		//*** Write buttons (after name to draw above) */
		space_label.svg += button_svg;

		//*** Write perimeter */
		if (this.type_visible[expanded]) {

			var space_type = "";
            if (space.outside)
				space_type = "Extérieur";
			else if (!space.has_roof)
				space_type = "Balcon";
			else if (!space.indoor)
				space_type = "Loggia";
			else if (!space.heated)
				space_type = "Espace non chauffé";
			else
				space_type = "Espace chauffé";

            space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">${space_type}</text>`;
            y += line_height;
		}

		//*** Write area */
        if (this.area_visible[expanded]) {

            if (space.declared_area > 0) {

                const label_declared = expanded ? "S déclarée: " : "Déclaré: ";
                const label_raw = expanded ? "S calculée: " : "Calculé: ";

                space_label.svg += `<text x="${this.name_visible[expanded] ? x : x0}" y="${y}" class="space_label_text ${extra}">${label_declared}${space.declared_area.toFixed(2)} m²</text>`;
                y += line_height;

                space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">${label_raw}${space.area.toFixed(2)} m²</text>`;
                y += line_height;

            } else {

                const label_raw = expanded ? "Surface : " : "";
                const label_plain = expanded ? "Surface nette : " : "Nette: ";

                space_label.svg += `<text x="${this.name_visible[expanded] ? x : x0}" y="${y}" class="space_label_text ${extra}">${label_raw}${space.area.toFixed(2)} m²</text>`;
                y += line_height;

                if (space.plain_area < space.area - 0.01) {
                    space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">${label_plain}${space.plain_area.toFixed(2)} m²</text>`;
                    y += line_height;
                }

            }

        }

		if (this._area_context)
		{
			const space_area = this._area_context.get_space_area(this._storey, space);
			for (let nl = 0; nl === 0 || nl < this._area_context.sub_labels.length; nl++) {
				const label_plain = (this._area_context.sub_labels.length == 0)?this._area_context.label:this._area_context.sub_labels[nl];
				const a = (space_area && nl < space_area.sub_areas.length) ? space_area.sub_areas[nl] : 0;
                space_label.svg += `<text x="${x}" y="${y}" class="space_label_text space_label_area ${extra}">${label_plain} : ${a.toFixed(2)} m²</text>`;
				y += line_height;
			}
		}

		//*** Write perimeter */
		if (this.perimeter_visible[expanded]) {

            if (space.declared_perimeter > 0) {

                space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">P déclaré: ${space.declared_perimeter.toFixed(2)} m</text>`;
                y += line_height;

                space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">P calculé: ${space.perimeter.toFixed(2)} m</text>`;
                y += line_height;

            } else {

                space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">Périmètre: ${space.perimeter.toFixed(2)} m</text>`;
                y += line_height;

            }

		}

		//*** Write floor offset */
		if (space.slab_offset > 0 && this.floor_offset_visible[expanded]) {
            space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">Décalage de dalle: ${space.slab_offset.toFixed(2)} m</text>`;
            y += line_height;
		}

        //*** Write floor height */
        if (((space.slab_offset > 0 || space.ceiling_height > 0) && this.area_visible[expanded]) || this.ceiling_visible[expanded]) {
            const hsp = space.get_real_ceiling_height();
            let hsp_display = `${hsp.toFixed(2)} m`;
            if (expanded && space.ceiling_height <= 0) {
                hsp_display += " (Auto)";
            }
            space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">HSP: ${hsp_display}</text>`;
            y += line_height;
        }

        //*** Write floor facing */
        if (this.facing_visible[expanded]) {
            const facing = space.facings.map(f => f ? f.name : '').filter(f => !!f).join(', ') || 'Aucun';
            space_label.svg += `<text x="${x}" y="${y}" class="space_label_text ${extra}">Revêtement: ${facing}</text>`;
            y += line_height;
        }

		//*** Write space equipments */
		y -= 10;
		x = space_label.box.size[0];
		const eq_size = [30,30];
		y -= eq_size[1]+3;

        space_label.equipment_labels = [];
        if (this.equipments_visible[expanded]) {
            let i = 1;
            const start_pos_y = y + 0;
            let width = 0
            const object_groups = new Map();
            this._scene.object_instances.filter(eq => eq.virtual && eq.space === space && eq.object)
                .forEach(eq => object_groups.set(eq.object.ID, [...(object_groups.get(eq.object.ID)||[]), eq]));
            object_groups.forEach((equipments, object_id) => {
                if (x > space_label.box.size[0] - eq_size[0] - i * 3) {
                    i = 1
                    x = 3;
                    y += eq_size[1] + 3;
                } else {
                    i++;
                    x += eq_size[0] + 3;
                }
                width = Math.max(width, x)
                const eq = equipments[0];
                const eq_label = new cn_equipment_label(eq);
                const nb_equipments = equipments.length;
                space_label.equipment_labels.push(eq_label);
                eq_label.box.posmin = [x, y];
                eq_label.box.size = cn_clone(eq_size);
                space_label.svg += `<g opacity="0.8" transform="translate(${x},${y})">`;
                if (eq == this._mouseover_equipment)
                    space_label.svg += `<rect class="object_in_space_background mouseover" x="0" y="0" width="${eq_size[0]}" height="${eq_size[1]}" />`;
                space_label.svg += eq.object.draw_svg_icon(eq_size[0], eq_size[1]);
                space_label.svg += `</g>`;
                if (nb_equipments > 1) {
                    space_label.svg += `<text class="equipment_label_text" transform="translate(${x + 28},${y + 28})">${nb_equipments}</text>`;
                }
            });
            if (this._scene.object_instances.find(eq => eq.virtual && eq.space === space)) {
                space_label.svg = `<rect class="space_label_equipments_list ${extra}" x="0" y="${start_pos_y + eq_size[1]}" width="${width + eq_size[0] + 5}" height="${y - start_pos_y + 3}"/>` + space_label.svg
            }
        }

		space_label.box.size[1] = y + eq_size[1]+3;
	}

	//***********************************************************************************
	//**** Mouse callbacks
	//***********************************************************************************
	clear_move() {
		this._mouseover_space = null;
		this._mouseover_move = false;
		this._mouseover_equipment = null;
	}

	click(ev) {
		if (!this.interactive) return false;
		this._find_mouseover(ev);
		return false;
	}

	grab (ev) {
		if (!this.interactive) return false;
		this._grabbed=false;
		this._find_mouseover(ev);
		if (this._mouseover_space && this._mouseover_move)
		{
			this._grabbed=true;
			this._mouse = cn_clone(ev.mouse_world);
			return true;
		}

		//*** Initiate drag and drop */
		if (this._drag_and_drop() && this._mouseover_equipment)
		{
			ev.drag_and_drop_element = this._mouseover_equipment;
			ev.drag_and_drop_owner = this;
			return true;
		}
		return false;
	}

	drop(ev) {
		if (!this.interactive) return false;
		var r = this._grabbed;
		this._grabbed=false;
		return r;
	}

	move(ev) {
		if (!this.interactive) return false;
		this._find_mouseover(ev);
		return (this._mouseover_space != null);
	}

	drag(ev) {
		if (!this.interactive) return false;
		if (this._grabbed && this._mouseover_space)
		{
			var offset = cn_sub(ev.mouse_world,this._mouse);
			this._mouseover_space.label_position = cn_add(this._mouseover_space.label_position,offset);
			this._mouse = cn_clone(ev.mouse_world);
			return true;
		}

		return false;
	}

	/**
	 * During a drag and drop, the owner of the drag and drop may change.
	 * Return true to accept owning the drag and drop events.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	 grab_element(mouse_event) {
		this._find_mouseover(mouse_event);

		return this._mouseover_space != null;
	}

	drag_element(ev) {
		if (ev.drag_and_drop_element.constructor == cn_object_instance)
		{
			this._find_mouseover(ev);
			if (this._mouseover_space)
			{
				if (this.expanding_mode > 0) this._expanded_space = this._mouseover_space;
				ev.drag_and_drop_element.space = this._mouseover_space;
				ev.drag_and_drop_element.virtual = true;
				this._mouseover_equipment = ev.drag_and_drop_element;
				return true;
			}
		}
		return false;
	}

	drop_element(ev) {
		return false;
	}

	//***********************************************************************************
	_find_mouseover(ev) {
		this._mouseover_space = null;
		this._mouseover_move = false;
		this._mouseover_equipment = null;
		for (var i=0;i<this._space_labels.length;i++)
		{
			var space_label = this._space_labels[i];
			if (!space_label.box.contains_point(ev.mouse_screen)) continue;
			if (ev.drag_and_drop_element) this._mouseover_space = space_label.space;
			var evm = cn_sub(ev.mouse_screen,space_label.box.posmin);
			if (this.move_button && space_label.move_box.contains_point(evm))
			{
				this._mouseover_space = space_label.space;
				this._mouseover_move = true;
				return;
			}
			else
			{
				for (var k=0;k<space_label.equipment_labels.length;k++)
				{
					if (!space_label.equipment_labels[k].box.contains_point(evm)) continue;
					this._mouseover_equipment = space_label.equipment_labels[k].equipment;
					this._mouseover_space = space_label.space;
					return;
				}
			}
			return;
		}
	}

	/**
	 * Returns true if drag and drop of equipments is relevant
	 * @returns {boolean}
	 */
	_drag_and_drop() {
		return true;
		if (this.equipments_visible[0] && this.expanding_mode < 2) return true;
		if (this.equipments_visible[1] && this.expanding_mode > 0) return true;
		return false;
	}
}


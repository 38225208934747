import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, ConfirmationService } from 'src/app/commons-lib';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Diagnostic, ReportagePhoto } from 'src/app/model/diagnostic.model';
import { Intervention } from 'src/app/model/intervention.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { DialogUtils } from '../../../../utils/dialog.utils';
import { MatDialog } from '@angular/material/dialog';
import { ReportagePhotoEditComponent } from '../reportage-photo-edit/reportage-photo-edit.component';
import { combineLatestOrEmpty } from '../../../../utils/rxjs.utils';
import { InterventionFile } from '../../../../model/intervention-file.model';
import { InterventionFileService } from '../../../../services/intervention-file.service';
import { ReportagePhotoService } from '../../../../services/reportage-photo.service';

@Component({
    selector: 'app-reportage-photo-list',
    templateUrl: './reportage-photo-list.component.html',
    styleUrls: ['./reportage-photo-list.component.scss'],
})
export class ReportagePhotoListComponent extends BaseComponent implements OnInit {
    /** Liste des photos disponible pour le reportage photo */
    interventionFiles: InterventionFile[] = [];
    displayedColumns = ['nom', 'actions'];
    readonlyMode = false;
    currentDiagnostic: Diagnostic;

    private currentIntervention: Intervention;

    constructor(
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly confirmationService: ConfirmationService,
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly matDialog: MatDialog,
        private readonly interventionFileService: InterventionFileService,
        private readonly reportagePhotoService: ReportagePhotoService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService.getCurrentIntervention(),
                    this.diagnosticService.getCurrentDiagnostic(),
                ]).pipe(
                    switchMap(([intervention, diagnostic]) => {
                        return combineLatestOrEmpty([
                            this.interventionFileService.getInterventionFilesOfInterventionForReportagePhoto(
                                intervention
                            ),
                            this.interventionFileService.getInterventionFilesOfDiagnostic(intervention, diagnostic),
                        ]).pipe(
                            map(([interventionFilesOfDiagnostic, interventionFilesOfIntervention]) => {
                                return interventionFilesOfDiagnostic.concat(interventionFilesOfIntervention);
                            }),
                            switchMap((interventionFiles) => {
                                return combineLatest([
                                    of(intervention),
                                    of(diagnostic),
                                    of(interventionFiles),
                                    this.reportagePhotoService.generateReportagesAuto(
                                        intervention,
                                        diagnostic,
                                        interventionFiles
                                    ),
                                ]);
                            })
                        );
                    })
                )
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic, interventionFiles]) => {
                this.currentIntervention = intervention;
                this.currentDiagnostic = diagnostic;
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(
                    this.currentIntervention,
                    this.currentDiagnostic
                );
                this.interventionFiles = interventionFiles;
                this.checkValidity();
            });
    }

    /**
     * Action lors du click pour créer le reportage
     * (ouvre la modale)
     */
    onClickCreerReportage() {
        this.openModalReportagePhoto();
    }

    /**
     * Action lors du click pour éditer le reportage
     * (ouvre la modale)
     * @param reportagePhoto
     */
    onClickEditReportage(reportagePhoto: ReportagePhoto) {
        this.openModalReportagePhoto(reportagePhoto.id);
    }

    /**
     * Action lors du click pour la supression d'un reportage photo
     * @param reportagePhoto
     */
    onClickDeleteReportage(reportagePhoto: ReportagePhoto) {
        let autoToDelete = false;
        let msg = 'Êtes-vous sûr de vouloir supprimer ce reportage photo ?';
        if (reportagePhoto.autoGenerated) {
            const presetsAuto = this.reportagePhotoService.getPresetsReportagesPhotosAutoForRefPrestation(
                this.currentIntervention,
                this.currentDiagnostic
            );
            autoToDelete = presetsAuto && presetsAuto.includes(reportagePhoto.typePreset);
        }
        if (autoToDelete) {
            msg =
                'Êtes-vous sûr de vouloir supprimer ce reportage photo automatique ? Il ne sera plus généré automatiquement.';
        }
        this.confirmationService.confirmWarn(msg, () => {
            this.cnSpinnerService
                .withSpinner(this.deleteReportagePhoto(reportagePhoto, autoToDelete), 'Suppression en cours...')
                .subscribe();
        });
    }

    /**
     * Suppression du reportage photo + maj du diagnostic
     * @param reportagePhoto
     * @param reportageAuto
     * @private
     */
    private deleteReportagePhoto(reportagePhoto: ReportagePhoto, reportageAuto: boolean = false): Observable<any> {
        // Suppression du reportage photo dans le diagnostic courant
        this.currentDiagnostic.reportagesPhoto = this.currentDiagnostic.reportagesPhoto.filter(
            (r) => r.id != reportagePhoto.id
        );
        if (reportageAuto) {
            // Supprimer le type preset dans la refprestation
            this.currentIntervention.prestationsDiagnostics.find(
                (diag) => diag.idDiagnostic === this.currentDiagnostic.id
            ).prestation.presetsReportagePhotosAuto = this.currentIntervention.prestationsDiagnostics
                .find((diag) => diag.idDiagnostic === this.currentDiagnostic.id)
                .prestation.presetsReportagePhotosAuto.filter((p) => p !== reportagePhoto.typePreset);
            return forkJoin([
                this.interventionService.updateIntervention(this.currentIntervention),
                this.diagnosticService.upsert(this.currentIntervention, this.currentDiagnostic),
            ]);
        } else {
            return this.diagnosticService.upsert(this.currentIntervention, this.currentDiagnostic);
        }
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.currentDiagnostic);
    }

    /**
     * Ouvre la modale permettant d'ajouter / editer un reportage photo
     * @param idReportagePhoto
     */
    private openModalReportagePhoto(idReportagePhoto?: string) {
        return this.matDialog
            .open(ReportagePhotoEditComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    idReportagePhoto: idReportagePhoto ? idReportagePhoto : null,
                    readonlyMode: this.readonlyMode,
                },
            })
            .afterClosed()
            .subscribe((diagnostic: Diagnostic) => {
                if (diagnostic) {
                    // Mise à jour du diagnostic
                    this.currentDiagnostic = diagnostic;
                }
            });
    }
}

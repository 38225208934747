import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import {
    Chapitre,
    ICheckpoints,
    Photo,
    PointControle,
    PointControleAide,
    ValeurCheckpoint,
} from '../../../model/electricite.model';
import { ElectriciteService } from '../../../services/electricite.service';
import { MatDialog } from '@angular/material/dialog';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { GuideDeReferenceComponent } from '../../guide-de-reference/guide-de-reference.component';
import { DialogUtils } from '../../../../../../utils/dialog.utils';
import { CheckpointHelpModalComponent } from '../../checkpoint-help-modal/checkpoint-help-modal.component';
import { DiagnosticHandlerService } from '../../../../../../services/diagnostic-handler.service';
import { Router, Routes } from '@angular/router';
import {
    compareChapitre,
    comparePointControle,
    nonOption,
    nonVerifiabledOption,
    routesPointsDeControle,
} from '../../../shared/electricite.constants';
import { TypeCommentaire } from '../../../../../../model/type-commentaire.model';

@Component({
    selector: 'app-reste-afaire',
    templateUrl: './reste-a-faire.component.html',
    styleUrls: ['./reste-a-faire.component.scss'],
})
export class ResteAFaireComponent implements OnInit {
    readonly COMMENT_TYPES: TypeCommentaire[] = ['NOTE_PERSONNELLE', 'REMARQUE'];
    readonly NON_VERIFIABLE = nonVerifiabledOption.value;
    readonly NON = nonOption.value;
    pointsControles: PointControle[];
    diagnostic: Diagnostic;
    chapitres: ICheckpoints[] = []; // chapitres to display
    private _pointsControlesAides: PointControleAide[];
    private _photos: Photo[];
    private _link: Routes = routesPointsDeControle;
    private _chapitres: Chapitre[]; // chapitres config

    constructor(
        private diagnosticService: DiagnosticService,
        private electriciteService: ElectriciteService,
        private readonly matDialog: MatDialog,
        private router: Router,
        private readonly diagnosticHandlerService: DiagnosticHandlerService
    ) {}

    ngOnInit(): void {
        combineLatest([this.electriciteService.electriciteConfig$, this.diagnosticService.getCurrentDiagnostic()])
            .pipe(first())
            .subscribe(([config, diagnostic]) => {
                this.diagnostic = diagnostic;
                this._chapitres = config.chapitres;
                this._pointsControlesAides = config.pointsControlesAides;
                this._photos = config.photos;
                const ptcResteAverify = config.pointsControles.filter((ch) => this.filtreCheckpoint(ch));
                for (const ptc of ptcResteAverify) {
                    this.addPointControle(ptc);
                }
                // Sort
                this.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
                for (const tr of this.chapitres) {
                    tr.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
                    for (const val of tr.chapitres) {
                        val.pointsControles.sort(comparePointControle);
                    }
                }
            });
    }

    private addPointControle(ckp: PointControle) {
        if (!ckp.idChapitre) return;
        const ssChapitre = this._chapitres.find((chap) => chap.idInterne == ckp.idChapitre);
        const chapitre: Chapitre = this._chapitres.find((chap) => chap.idInterne == ssChapitre.idChapitreParent);
        let path = this.getPath(this._link, chapitre.description);
        path = `${this.router.url.split('electricite')[0]}electricite/config/${path}`;
        const indexChapitre = this.chapitres.findIndex((tr) => tr.chapitre.id == chapitre.id);

        if (indexChapitre < 0) {
            this.chapitres.push({
                path: path,
                chapitre: chapitre,
                chapitres: [{ chapitre: ssChapitre, pointsControles: [ckp] }],
            });
        } else {
            // Le chapitre existe
            const indexSsChapitre = this.chapitres[indexChapitre].chapitres.findIndex(
                (val) => val.chapitre.id == ssChapitre.id
            );
            if (indexSsChapitre < 0) {
                this.chapitres[indexChapitre].chapitres.push({ chapitre: ssChapitre, pointsControles: [ckp] });
            } else {
                this.chapitres[indexChapitre].chapitres[indexSsChapitre].pointsControles.push(ckp);
            }
        }
    }
    /**
     * Return true si le point de contrôle
     * *** N'est pas répondu
     * *** est à NON ou NON_VERUFIABLE et (manque une justification)
     * @param {PointControle} checkpoint
     * @returns {boolean}
     */
    private filtreCheckpoint(checkpoint: PointControle): boolean {
        const valeur: ValeurCheckpoint =
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[checkpoint.idInterne];
        if (valeur) {
            if (valeur.isGrey) {
                return false;
            } else if (!valeur.valeur) {
                return true;
            } else if (valeur.valeur == this.NON || valeur.valeur == this.NON_VERIFIABLE) {
                return valeur.commentairesId.length == 0;
            } else {
                return false;
            }
        }
        return true;
    }

    private getPath(routes: Routes, label: string): string {
        let path = '';
        for (const route of routes) {
            path = route.path;
            if (route.children && route.children.length) {
                const tmpPath = this.getPath(route.children, label);
                if (tmpPath) {
                    return (path += `/${tmpPath}`);
                }
            } else {
                if (route.data ? label.startsWith(route.data.label) : false) {
                    return route.path;
                }
            }
        }
        return null;
    }

    openGuideReference($event) {
        $event.stopPropagation();
        this.matDialog.open(GuideDeReferenceComponent, { ...DialogUtils.configFullScreen() });
    }

    openHelp(checkpoint: any) {
        const contents = [];
        this._pointsControlesAides
            .filter((pca) => pca.idPointControle == checkpoint.idInterne)
            .sort((a, b) => a.ordre - b.ordre)
            .forEach((pca) => {
                const photo = this._photos.find((ph) => ph.idInterne == pca.idPhoto);
                contents.push({ innerHTML: pca.description, photo: photo });
            });
        if (contents) {
            const dialogRef = this.matDialog.open(CheckpointHelpModalComponent);
            dialogRef.componentInstance.title = checkpoint.description;
            dialogRef.componentInstance.contents = contents;
        }
    }
}

import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { Injectable, Type } from '@angular/core';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Anomalie, CeeModel, CEEReportData, IAnomalie, IReportPC, ISolution, PointControle } from '../model/cee.model';
import { Route } from '@angular/router';
import { ceeNavBarRoutes } from '../cee-routing.module';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { Intervention } from '../../../../model/intervention.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { Rule } from '../../../../model/regle.model';
import { Observable, of } from 'rxjs';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { TypeReport } from '../../../../model/reference-prestation.model';
import { CeeService } from './cee.service';
import { URL_MS_REPORT_FILE } from '../../../../shared/constants/cndiag.constants';
import {
    EGAL,
    INFERIEUR,
    INFERIEUR_EGAL,
    LONGUEURS_RESEAU_ISOLEES,
    nonOption,
    nonVisibleOption,
    ouiOption,
    PERFORMANCE_THERMIQUE,
    sansObjetOption,
    SUPPERIEUR,
    SUPPERIEUR_EGAL,
    SURFACE_ISOLEE,
} from '../shared/cee.constants';
import { first } from 'rxjs/operators';
import { ReportCeeBlockComponent } from '../modules/report/blocks/report-cee-block/report-cee-block.component';
import { EtapeDiagnosticGenerique } from '../../../../model/diagnostic-etape.model';
import { CommentUtils } from '../../../../utils/comment.utils';
import { ReportagePhotoService } from '../../../../services/reportage-photo.service';
import { LegendScreenshot } from '../../../../model/screenshot-svg.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { ReportBarth160Component } from '../modules/report/blocks/report-barth160-block/report-barth160.component';
import { InterventionFile, TypeReferenceFichier } from 'src/app/model/intervention-file.model';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { PercentPipe } from '@angular/common';

export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    ReportCeeBlockComponent: ReportCeeBlockComponent,
    ReportBarth160Component: ReportBarth160Component,
};

@Injectable({
    providedIn: 'root',
})
export class TypeCEESerice implements ITypePrestationInterface {
    protected typePrestation: TypePrestation = null;

    constructor(
        private readonly ceeService: CeeService,
        private readonly reportagePhotoService: ReportagePhotoService,
        private readonly pointDeControleService: PointDeControleService,
        private readonly interventionFileService: InterventionFileService,
        private readonly documentsService: DocumentsService
    ) {}

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    getRoutes(): Route[] {
        return ceeNavBarRoutes;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new CeeModel());
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return undefined;
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        let total: number = 0;
        let totalValide: number = 0;
        const ceeModel = diagnostic.contenuDiagnostic as CeeModel;
        if (ceeModel) {
            for (const solution of ceeModel.solutions.solutions) {
                for (const zone of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                    total += solution.pointsDeControles.data[zone].length;
                    totalValide += solution.pointsDeControles.data[zone].filter((ptc) => !!ptc.valeur).length;
                }
            }
        }
        return (totalValide * 100) / total;
    }

    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return undefined;
    }

    getDiagnosticReportData(
        intervention: Intervention,
        diagnostic: Diagnostic,
        rules?: Rule[],
        optionPlan?: boolean
    ): IDiagReportData {
        const diagReportData = new CEEReportData();
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        diagReportData.refRapport = diagnostic.reportDatas.find(
            (reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT
        ).refRapport;

        if (diagnostic.recommandationsFinales.length) {
            diagReportData.recommandations = diagnostic.recommandationsFinales.map((it) => it.contenu);
        }
        if (diagnostic.constatationsFinales.length) {
            diagReportData.constatationsDiverses = diagnostic.constatationsFinales.map((it) => it.contenu);
        }
        diagReportData.reportagesPhotos = this.reportagePhotoService.buildReportagePhotoData(diagnostic);
        // La conformité des documents
        let conformiteDocuments = true;
        for (const doc of intervention.documents) {
            for (const typeDocCP of doc.typeDocument.typeDocumentCheckpoint.filter(
                (tdc) => tdc.referencePrestation.typePrestation === diagnostic.typePrestation
            )) {
                for (const checkpoint of typeDocCP.checkpoints) {
                    conformiteDocuments = conformiteDocuments && checkpoint.value;
                }
            }
        }
        diagReportData.conformiteGlobale = conformiteDocuments;

        const ceeModel = diagnostic.contenuDiagnostic as CeeModel;
        // Ecran Général

        ceeModel.nomEntreprise.libelle = "Nom de l'entreprise ayant réalisé les travaux";
        diagReportData.etapesGenerales.push(ceeModel.nomEntreprise);
        ceeModel.numeroSIREN.libelle = "Numéro de SIREN de l'entreprise ayant réalisé les travaux";
        diagReportData.etapesGenerales.push(ceeModel.numeroSIREN);

        if (!['BARTH160', 'BARTH161'].includes(this.typePrestation)) {
            ceeModel.entrepriseRGE.libelle = 'Entreprise RGE';
            let etape: any = ceeModel.entrepriseRGE;
            const commentaires = [];
            for (const commentaireId of ceeModel.entrepriseRGE.commentairesId) {
                commentaires.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
            }
            etape.commentaires = commentaires;
            diagReportData.etapesGenerales.push(etape);
        }
        if (!['BARTH160'].includes(this.typePrestation)) {
            ceeModel.dateEtatRecapitulatif.libelle = "Date de l'état récapitulatif";
            diagReportData.etapesGenerales.push(this.convertDateToString(ceeModel.dateEtatRecapitulatif));
            ceeModel.dateEtatRecapitulatif.libelle = 'Date de la visite préalable';
            diagReportData.etapesGenerales.push(this.convertDateToString(ceeModel.dateVisitePrealable));
        }

        ceeModel.dateAcceptationDevis.libelle = "Date d' acceptation du devis";
        diagReportData.etapesGenerales.push(this.convertDateToString(ceeModel.dateAcceptationDevis));
        ceeModel.dateFacture.libelle = 'Date de la facture';
        diagReportData.etapesGenerales.push(this.convertDateToString(ceeModel.dateFacture));
        ceeModel.refFacture.libelle = 'Référence de la facture';
        diagReportData.etapesGenerales.push(ceeModel.refFacture);

        diagReportData.longuerReseauxIsoles = +ceeModel.longuerReseauxIsoles.valeur;
        // Les Anomalies
        diagReportData.anomalies = this.getAnomaliesReport(ceeModel.solutions.solutions);

        // Les Points de controles
        diagReportData.pointsDeControle = this.getPointsDeControleReport(
            intervention,
            ceeModel.solutions.solutions,
            diagReportData.anomalies
        );

        // Liste des Documents
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);
        diagReportData.listDocuments = diagReportData.documentsData.filter((doc) => doc.afficherDansListeDocuments);
        diagReportData.annexes = diagReportData.documentsData
            .filter((doc) => doc.afficherDansRapport)
            .map((doc) => ({
                id: doc.nom,
                filename: URL_MS_REPORT_FILE + doc.idFichier,
                type: 'pdf',
            }));

        for (const sol of diagReportData.pointsDeControle.solution) {
            diagReportData.conformiteGlobale =
                diagReportData.conformiteGlobale &&
                !sol.infosSolutions.informations.some((inf) => {
                    return inf.libelle == 'Conclusion' && inf.reponse.includes('Non conforme');
                });
        }
        // Écran caractéristique :
        if (this.typePrestation === 'BARTH161') {
            let caracteristiques = [];
            const data: any[] = Object.values(ceeModel.caracteristiques.data);
            for (const value of data) {
                const checkpoint: PointControle = this.getPointDeControle(value.id);
                if (checkpoint?.sectionRapport) {
                    const comm = [];
                    for (const commentaireId of value.commentairesId) {
                        comm.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
                    }
                    let reponse = value.valeur;
                    if (
                        checkpoint.lstChoixReponse &&
                        checkpoint.lstChoixReponse.length &&
                        checkpoint.idInterne !== 'PC408' &&
                        checkpoint.idInterne !== 'PC409'
                    ) {
                        const choice = checkpoint.lstChoixReponse.find((cp) => cp.code === value.valeur);
                        reponse = choice ? value.valeur : choice.description;
                    }
                    let ano = this.getAnomalieFromCheckpoint(checkpoint, reponse);

                    caracteristiques.push({
                        libelle: checkpoint.libelleRapport ? checkpoint.libelleRapport : checkpoint.description,
                        reponse: value.valeur,
                        commentaires: comm,
                        sectionRapport: checkpoint.sectionRapport,
                        ordreSectionRapport: checkpoint.ordreSectionRapport,
                        estAnomalie: !!ano,
                        idChapitre: checkpoint.idChapitre,
                        idPointControle: checkpoint.idInterne,
                    });
                }
            }
            let sommeTotal: number = 0;
            for (const carac of caracteristiques) {
                if (
                    carac.idPointControle === 'PC400' ||
                    carac.idPointControle === 'PC401' ||
                    carac.idPointControle === 'PC402' ||
                    carac.idPointControle === 'PC403' ||
                    carac.idPointControle === 'PC404' ||
                    carac.idPointControle === 'PC405' ||
                    carac.idPointControle === 'PC406' ||
                    carac.idPointControle === 'PC407'
                ) {
                    sommeTotal += +carac.reponse;
                }
            }

            caracteristiques.push({
                libelle: 'Nombre total de points singulier déclarés',
                reponse: sommeTotal,
                commentaires: [],
                sectionRapport: 'Caracteristiques',
                ordreSectionRapport: 1,
                estAnomalie: false,
                idChapitre: 'CH400',
                idPointControle: 'PC429',
            });

            caracteristiques = caracteristiques.sort(this.sortValues);
            diagReportData.pointsDeControle.solution[0].caracteristiques = caracteristiques;
        }
        console.log(diagReportData);
        return diagReportData;
    }
    readonly sortValues = (a: any, b: any): number => {
        // Compare d'abord par sectionRapport
        const sectionComparison = a.sectionRapport.localeCompare(b.sectionRapport);

        // Si les sections sont égales, compare par ordreSectionRapport
        if (sectionComparison === 0) {
            return +a.ordreSectionRapport - +b.ordreSectionRapport;
        }

        // Sinon, retourne le résultat de la comparaison par sectionRapport
        return sectionComparison;
    };

    private convertDateToString(value: EtapeDiagnosticGenerique): EtapeDiagnosticGenerique {
        const newValue = Object.assign({}, value);
        newValue.valeur = value.valeur ? new Date(value.valeur).toLocaleDateString() : '';
        return newValue;
    }
    private getInfoSolution(
        intervention: Intervention,
        solution: ISolution,
        anomalies: IAnomalie[]
    ): {
        solutionName: string;
        conclusionAccessibiliteRealisationNonSatisfaisant: boolean;
        conclusionMiseEnServiceNonSatisfaisant: boolean;
        informations: {
            libelle: string;
            reponse: string;
            commentaires: Commentaire[];
            sectionRapport: string;
            ordreSectionRapport: string;
            estAnomalie: boolean;
            libellesAnomalies: any[];
        }[];
    } {
        let noAnomalies: boolean = true;
        const infoSolution: any = {};
        infoSolution.informations = [];
        infoSolution.solutionName = this.getSolutionName(solution);

        solution.infoIsolant.forEach((repPtc) => {
            const checkpoint = this.getPointDeControle(repPtc.id);
            if (checkpoint && checkpoint.sectionRapport) {
                let reponse = repPtc.valeur;

                if (checkpoint.lstChoixReponse && checkpoint.lstChoixReponse.length) {
                    const choice = checkpoint.lstChoixReponse.find((cp) => cp.code === repPtc.valeur);
                    reponse = choice ? repPtc.valeur : choice.description;
                }
                const commentaires = [];
                for (const commentaireId of repPtc.commentairesId) {
                    commentaires.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
                }
                let ano = this.getAnomalieFromCheckpoint(checkpoint, reponse);
                if (checkpoint.type === 'date') {
                    //reponse = new Date(reponse).toLocaleTimeString();
                    const ReponseDate = new Date(reponse).getTime().toString();
                    ano = this.getAnomalieFromCheckpoint(checkpoint, ReponseDate);
                    reponse = new Date(reponse).toLocaleDateString();
                }

                const libellesAnomalies = ano ? [ano] : [];
                if (checkpoint.lstChoixReponse && checkpoint.lstChoixReponse.length) {
                    const choice = checkpoint.lstChoixReponse.find((cp) => cp.code === repPtc.valeur);
                    reponse = choice ? choice.description : repPtc.valeur;
                }
                infoSolution.informations.push({
                    libelle: checkpoint.libelleRapport ? checkpoint.libelleRapport : checkpoint.description,
                    reponse,
                    commentaires,
                    sectionRapport: checkpoint.sectionRapport,
                    ordreSectionRapport: checkpoint.ordreSectionRapport,
                    estAnomalie: !!ano,
                    libellesAnomalies,
                });

                // Search anomalie
                const iAno = anomalies.find((sol) => sol.solution === infoSolution.solutionName);
                if (iAno && iAno.zones && iAno.zones[0].libelleAnomalies) {
                    noAnomalies = iAno.zones[0].libelleAnomalies.find((libelle) => libelle === checkpoint.description)
                        ? false
                        : noAnomalies;
                }
                infoSolution.conclusionAccessibiliteRealisationNonSatisfaisant = false;
                infoSolution.conclusionMiseEnServiceNonSatisfaisant = false;
                for (const info of infoSolution.informations) {
                    if (info.sectionRapport === 'AccessibiliteEtRealisation') {
                        if (info.estAnomalie === true) {
                            infoSolution.conclusionAccessibiliteRealisationNonSatisfaisant = true;
                        }
                    }
                    if (info.sectionRapport === 'MiseEnServiceInstallation') {
                        if (info.estAnomalie === true) {
                            infoSolution.conclusionMiseEnServiceNonSatisfaisant = true;
                        }
                    }
                }
            }
        });
        if (!['BARTH160', 'BARTH161'].includes(this.typePrestation)) {
            infoSolution.informations.push({
                libelle: 'Conclusion',
                reponse: noAnomalies ? 'Conforme' : 'Non conforme',
                commentaires: [],
                sectionRapport: PERFORMANCE_THERMIQUE,
                ordreSectionRapport: '99',
                estAnomalie: false,
                libellesAnomalies: [],
            });
        }

        infoSolution.informations.sort(this.sortValues);
        return infoSolution;
    }

    private getPointsDeControleReport(
        intervention: Intervention,
        solutions: ISolution[],
        anomalies
    ): { solution: IReportPC[] } {
        const reponses: { solution: IReportPC[] } = { solution: [] };
        for (const solution of solutions) {
            // Informations générale
            const response: IReportPC = {
                infosSolutions: this.getInfoSolution(intervention, solution, anomalies),
                zones: [],
            };
            // PTC zones
            for (const zoneName of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                const zone: {
                    zoneName: string;
                    pointsDeControle: {
                        libelle: string;
                        reponse: string;
                        commentaires: Commentaire[];
                        sectionRapport: string;
                        ordreSectionRapport: string;
                        estAnomalie: boolean;
                        libellesAnomalies: any[];
                        imageZone: {
                            legende: string;
                            url: string;
                            creationDate: string;
                            gpsLatitudeRef: string;
                            gpsLatitude: string;
                            gpsLongitudeRef: string;
                            gpsLongitude: string;
                            gpsAltitudeRef: string;
                            gpsAltitude: string;
                        };
                    }[];
                } = { zoneName, pointsDeControle: [] };

                if (solution.pointsDeControles.data[zoneName]) {
                    let localisationReseau = '';
                    let precisionLocalisationReseau = '';
                    let commentairesLocalisation = [];
                    for (const ptc of solution.pointsDeControles.data[zoneName]) {
                        const checkpoint: PointControle = this.getPointDeControle(ptc.id);
                        let reponse = ptc.valeur;
                        if (checkpoint.lstChoixReponse && checkpoint.lstChoixReponse.length) {
                            const choice = checkpoint.lstChoixReponse.find((cp) => cp.code === reponse);
                            reponse = choice ? choice.description : reponse;
                        } else {
                            const option = [ouiOption, nonOption, sansObjetOption, nonVisibleOption].find(
                                (opt) => opt.value == reponse
                            );
                            reponse = option ? option.label : reponse;
                        }

                        const commentaires = [];
                        for (const commentaireId of ptc.commentairesId) {
                            commentaires.push(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
                        }

                        ///////////
                        const anomalie: Anomalie = this.getAnomalieFromCheckpoint(checkpoint, ptc.valeur);
                        const libellesAnomalies = [];
                        if (anomalie) {
                            libellesAnomalies.push({
                                libelleAnomalie: anomalie.description ? anomalie.description : checkpoint.description,
                            });
                        }
                        let libelleRapport = String();
                        if (checkpoint.libelleRapport) {
                            libelleRapport = checkpoint.libelleRapport;
                        } else {
                            libelleRapport = checkpoint.description;
                        }

                        //BarTH160
                        //concatenation de la localisation et de la précision du réseau
                        if (checkpoint.id == 'PC309') {
                            localisationReseau = reponse;
                            commentairesLocalisation.push(commentaires);
                        } else if (checkpoint.id == 'PC320') {
                            precisionLocalisationReseau = reponse;
                            commentairesLocalisation.push(commentaires);
                        } else {
                            if (checkpoint.type === 'photo') {
                                let interfile = this.getInterventionFile(intervention.id, ptc.idImage);
                                interfile.subscribe((value) => {
                                    zone.pointsDeControle.push({
                                        libelle: libelleRapport,
                                        reponse,
                                        commentaires,
                                        sectionRapport: checkpoint.sectionRapport,
                                        ordreSectionRapport: checkpoint.ordreSectionRapport,
                                        estAnomalie: !!anomalie,
                                        libellesAnomalies,
                                        imageZone: {
                                            url: URL_MS_REPORT_FILE + ptc.idImage,
                                            legende: 'Photo ' + zone.zoneName,
                                            creationDate: new Date(+value.creationDate).toLocaleDateString(),
                                            gpsLatitudeRef: value?.gpsLatitudeRef ?? 'Non renseigné',
                                            gpsLatitude: value?.gpsLatitude ?? 'Non renseigné',
                                            gpsLongitudeRef: value?.gpsLongitudeRef ?? 'Non renseigné',
                                            gpsLongitude: value?.gpsLongitude ?? 'Non renseigné',
                                            gpsAltitudeRef: value?.gpsAltitudeRef ?? 'Non renseigné',
                                            gpsAltitude: value?.gpsAltitude ?? 'Non renseigné',
                                        },
                                    });
                                });
                            } else {
                                zone.pointsDeControle.push({
                                    libelle: libelleRapport,
                                    reponse,
                                    commentaires,
                                    sectionRapport: checkpoint.sectionRapport,
                                    ordreSectionRapport: checkpoint.ordreSectionRapport,
                                    estAnomalie: !!anomalie,
                                    libellesAnomalies,
                                    imageZone: {
                                        url: '',
                                        legende: '',
                                        creationDate: '',
                                        gpsLatitudeRef: '',
                                        gpsLatitude: '',
                                        gpsLongitudeRef: '',
                                        gpsLongitude: '',
                                        gpsAltitudeRef: '',
                                        gpsAltitude: '',
                                    },
                                });
                            }
                        }
                    }

                    //BarTH160
                    //Localisation du réseau + précision de la localisation
                    if (['BARTH160'].includes(this.typePrestation)) {
                        zone.pointsDeControle.push({
                            libelle: 'Localisation du réseau',
                            reponse: localisationReseau + precisionLocalisationReseau,
                            commentaires: commentairesLocalisation,
                            sectionRapport: LONGUEURS_RESEAU_ISOLEES,
                            ordreSectionRapport: '3',
                            estAnomalie: false,
                            libellesAnomalies: [],
                            imageZone: {
                                url: '',
                                legende: '',
                                creationDate: '',
                                gpsLatitudeRef: '',
                                gpsLatitude: '',
                                gpsLongitudeRef: '',
                                gpsLongitude: '',
                                gpsAltitudeRef: '',
                                gpsAltitude: '',
                            },
                        });
                    }
                    if (!['BARTH160', 'BARTH161'].includes(this.typePrestation)) {
                        const surfaceFacture: number = this.getSurfaceFactureeBySolution(solution);
                        const surfaceMesuree: number = this.getSurfaceMesureeByZone(
                            solution.pointsDeControles.data[zoneName]
                        );
                        const ecart = Math.abs(((surfaceFacture - surfaceMesuree) / surfaceMesuree) * 100).toFixed(2);

                        zone.pointsDeControle.push({
                            libelle: 'Surface isolée sur preuve de réalisation (Sent) [m²]',
                            reponse: surfaceFacture + ' m²',
                            commentaires: [],
                            sectionRapport: SURFACE_ISOLEE,
                            ordreSectionRapport: '2',
                            estAnomalie: false,
                            libellesAnomalies: [],
                            imageZone: {
                                url: '',
                                legende: '',
                                creationDate: '',
                                gpsLatitudeRef: '',
                                gpsLatitude: '',
                                gpsLongitudeRef: '',
                                gpsLongitude: '',
                                gpsAltitudeRef: '',
                                gpsAltitude: '',
                            },
                        });
                        zone.pointsDeControle.push({
                            libelle: "Calcul de l'écart = (Sent-Sinsp)/Sinsp*100",
                            reponse: ecart + ' %',
                            commentaires: [],
                            sectionRapport: SURFACE_ISOLEE,
                            ordreSectionRapport: '3',
                            estAnomalie: false,
                            libellesAnomalies: [],
                            imageZone: {
                                url: '',
                                legende: '',
                                creationDate: '',
                                gpsLatitudeRef: '',
                                gpsLatitude: '',
                                gpsLongitudeRef: '',
                                gpsLongitude: '',
                                gpsAltitudeRef: '',
                                gpsAltitude: '',
                            },
                        });
                        zone.pointsDeControle.push({
                            libelle: 'Ecart > 10%',
                            reponse: +ecart > 10 ? 'Oui' : 'Non',
                            commentaires: [],
                            sectionRapport: SURFACE_ISOLEE,
                            ordreSectionRapport: '4',
                            estAnomalie: +ecart > 10,
                            libellesAnomalies: [],
                            imageZone: {
                                url: '',
                                legende: '',
                                creationDate: '',
                                gpsLatitudeRef: '',
                                gpsLatitude: '',
                                gpsLongitudeRef: '',
                                gpsLongitude: '',
                                gpsAltitudeRef: '',
                                gpsAltitude: '',
                            },
                        });
                    }
                    zone.pointsDeControle.sort(this.sortValues);

                    response.zones.push(zone);
                }
            }
            reponses.solution.push(response);
        }
        return reponses;
    }
    private getLocalisationtEtPrecision() {}
    /**
     * récupère l'intervention file lié a un commentaire
     * @idFile : l'id du fichier
     */
    public getInterventionFile(interventionId: string, idFile: string): Observable<InterventionFile> {
        let interFiles: Observable<InterventionFile> = this.interventionFileService.findByIdInterventionFileId(
            interventionId,
            TypeReferenceFichier.PHOTO_PRESTATION,
            idFile
        );
        console.log(interFiles);
        return interFiles;
    }

    private getAnomalieFromCheckpoint(checkpoint, pValue) {
        let anomalie: Anomalie = checkpoint.lstAnomalie.find((an) => an.codeReponse === pValue);
        if (!anomalie) {
            anomalie = checkpoint.lstAnomalie.find((an) => {
                switch (an.type) {
                    case INFERIEUR:
                        return +pValue < +an.valeurReference;
                    case SUPPERIEUR:
                        return +pValue > +an.valeurReference;
                    case EGAL:
                        return +pValue === +an.valeurReference;
                    case INFERIEUR_EGAL:
                        return +pValue <= +an.valeurReference;
                    case SUPPERIEUR_EGAL:
                        return +pValue >= +an.valeurReference;
                }
                return false;
            });
        }
        return anomalie;
    }

    private getPointDeControle(idOrDescription: string): PointControle {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        return config.pointsControles.find(
            (ptc) => ptc.description === idOrDescription || ptc.idInterne === idOrDescription
        );
    }

    private getAnomaliesReport(solutions: ISolution[]): IAnomalie[] {
        const iAnomalies: IAnomalie[] = [];
        for (const solution of solutions) {
            const nomSolution = this.getSolutionName(solution);
            const epaisseurFacture = this.getEpaisseurFactureeBySolution(solution); // Epaisseur sur preuve de réalisation (en cm)
            const surfaceFacture = this.getSurfaceFactureeBySolution(solution); // Surface isolée sur preuve de réalisation (m²)
            const iAnomalie: IAnomalie = {
                solution: nomSolution,
                zones: [],
            };
            const totalEpaisseur = this.getEpaisseurMesureeBySolution(solution);
            const totalSurface = this.getSurfaceMesureeBySolution(solution);

            for (const name of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                const anomalies = { name, libelleAnomalies: [] };
                if (surfaceFacture && Math.abs(((+surfaceFacture - totalSurface) / totalSurface) * 100) > 10) {
                    anomalies.libelleAnomalies.push('Surface isolée mesurée par l’inspecteur (Sinsp) [m²]');
                    anomalies.libelleAnomalies.push('Surface isolée sur preuve de réalisation (m²)');
                }
                if (epaisseurFacture && Math.abs(((+epaisseurFacture - totalEpaisseur) / totalEpaisseur) * 100) > 10) {
                    anomalies.libelleAnomalies.push('Épaisseur mesurée par l’inspecteur (cm)');
                    anomalies.libelleAnomalies.push('Epaisseur sur preuve de réalisation (en cm)');
                }
                for (const ptc of solution.pointsDeControles.data[name]) {
                    const pointcontrole: PointControle = this.getPointDeControle(ptc.id);
                    let anomalie: Anomalie = pointcontrole.lstAnomalie.find((an) => an.codeReponse == ptc.valeur);
                    if (!anomalie) {
                        anomalie = pointcontrole.lstAnomalie.find((an) => {
                            switch (an.type) {
                                case INFERIEUR:
                                    return +ptc.valeur < +an.valeurReference;
                                case SUPPERIEUR:
                                    return +ptc.valeur > +an.valeurReference;
                                case EGAL:
                                    return +ptc.valeur === +an.valeurReference;
                            }
                            return false;
                        });
                    }
                    if (anomalie) {
                        anomalies.libelleAnomalies.push(
                            anomalie.description ? anomalie.description : pointcontrole.description
                        );
                    }
                }
                if (anomalies.libelleAnomalies.length) {
                    iAnomalie.zones.push(anomalies);
                }
            }
            if (iAnomalie.zones.length) {
                iAnomalie.zones = iAnomalie.zones.filter((zone) => zone.libelleAnomalies.length);
                iAnomalies.push(iAnomalie);
            }
        }
        return iAnomalies;
    }

    // Retourne le nom d'une solution
    private getSolutionName(solution: ISolution): string {
        let nomSolution = '';
        if (solution.infoIsolant) {
            const solValue = solution.infoIsolant.find((val) => this.ceeService.idsNomSolution.includes(val.id));
            if (solValue) {
                nomSolution = solValue.valeur;
            }
        }
        return nomSolution;
    }

    // Retourne la surface facture par solution
    private getSurfaceFactureeBySolution(solution: ISolution): number {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        const idsSurfaceFacture = config.pointsControles
            .filter((ptc) => ptc.description === 'Surface isolée sur preuve de réalisation (m²)')
            .map((ptc) => ptc.idInterne);
        let surfaceFacture: EtapeDiagnosticGenerique = null; // Surface isolée sur preuve de réalisation (m²)
        if (solution.infoIsolant) {
            surfaceFacture = solution.infoIsolant.find((val) => idsSurfaceFacture.includes(val.id));
        }
        return surfaceFacture ? +surfaceFacture.valeur : 0;
    }

    // Retourne l'Epaisseur facture par solution
    private getEpaisseurFactureeBySolution(solution: ISolution): number {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        const idsEpaisseurFacture = config.pointsControles
            .filter((ptc) => ptc.description === 'Epaisseur sur preuve de réalisation (en cm)')
            .map((ptc) => ptc.idInterne);
        let epaisseurFacture: EtapeDiagnosticGenerique = null; // Surface isolée sur preuve de réalisation (m²)
        if (solution.infoIsolant) {
            epaisseurFacture = solution.infoIsolant.find((val) => idsEpaisseurFacture.includes(val.id));
        }
        return epaisseurFacture ? +epaisseurFacture.valeur : 0;
    }

    // Retourne la surface mesurée par solution (somme zones)
    private getSurfaceMesureeBySolution(solution: ISolution): number {
        let totalSurface = 0;
        for (const name of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
            totalSurface += this.getSurfaceMesureeByZone(solution.pointsDeControles.data[name]);
        }
        return totalSurface;
    }

    // Retourne la surface mesurée par solution (somme zones)
    private getSurfaceMesureeByZone(pointsDeControle: EtapeDiagnosticGenerique[]): number {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        const idsSurfaceMesure = config.pointsControles
            .filter((ptc) => ptc.description === 'Surface isolée mesurée par l’inspecteur (Sinsp) [m²]')
            .map((ptc) => ptc.idInterne);
        const value = pointsDeControle.find((val) => idsSurfaceMesure.includes(val.id));
        return value ? +value.valeur : 0;
    }

    // Retourne Epaisseur mesurée par solution (somme zones)
    private getEpaisseurMesureeBySolution(solution: ISolution): number {
        let totalEpaisseur = 0;
        for (const name of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
            totalEpaisseur += this.getEpaisseurMesureeByZone(solution.pointsDeControles.data[name]);
        }
        return totalEpaisseur;
    }

    // Retourne Epaisseur mesurée par Zone
    private getEpaisseurMesureeByZone(pointsDeControle: EtapeDiagnosticGenerique[]): number {
        let config = null;
        this.ceeService.ceeConfig$.pipe(first()).subscribe((conf) => (config = conf));
        const idsEpaisseurMesure = config.pointsControles
            .filter((ptc) => ptc.description === 'Épaisseur mesurée par l’inspecteur (cm)')
            .map((ptc) => ptc.idInterne);
        const value = pointsDeControle.find((val) => idsEpaisseurMesure.includes(val.id));
        return value ? +value.valeur : 0;
    }

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {}

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {}

    prepareSpecificComments(
        diagnostic: Diagnostic,
        commentairesGeneraux: Commentaire[],
        commentaires: CommentairePredefini[]
    ): Commentaire[] | any {
        return [];
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    generateLegendForScreenshot(diagnostic: Diagnostic): LegendScreenshot[] {
        return [];
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {}

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBAREN101Service extends TypeCEESerice {
    getTypePrestation(): TypePrestation {
        return 'BAREN101';
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBAREN102Service extends TypeCEESerice {
    constructor(
        private readonly ceeServicee: CeeService,
        private readonly reportagePhotoServicee: ReportagePhotoService,
        pointDeControleService: PointDeControleService,
        interventionFileService: InterventionFileService,
        documentsService: DocumentsService
    ) {
        super(ceeServicee, reportagePhotoServicee, pointDeControleService, interventionFileService, documentsService);
        this.typePrestation = 'BAREN102';
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBAREN103Service extends TypeCEESerice {
    constructor(
        private readonly ceeServicee: CeeService,
        private readonly reportagePhotoServicee: ReportagePhotoService,
        pointDeControleService: PointDeControleService,
        interventionFileService: InterventionFileService,
        documentsService: DocumentsService
    ) {
        super(ceeServicee, reportagePhotoServicee, pointDeControleService, interventionFileService, documentsService);
        this.typePrestation = 'BAREN103';
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBARTH160Service extends TypeCEESerice {
    constructor(
        private readonly ceeServicee: CeeService,
        private readonly reportagePhotoServicee: ReportagePhotoService,
        pointDeControleService: PointDeControleService,
        interventionFileService: InterventionFileService,
        documentsService: DocumentsService
    ) {
        super(ceeServicee, reportagePhotoServicee, pointDeControleService, interventionFileService, documentsService);
        this.typePrestation = 'BARTH160';
    }
}

@Injectable({
    providedIn: 'root',
})
export class TypeBARTH161Service extends TypeCEESerice {
    constructor(
        private readonly ceeServicee: CeeService,
        private readonly reportagePhotoServicee: ReportagePhotoService,
        pointDeControleService: PointDeControleService,
        interventionFileService: InterventionFileService,
        documentsService: DocumentsService
    ) {
        super(ceeServicee, reportagePhotoServicee, pointDeControleService, interventionFileService, documentsService);
        this.typePrestation = 'BARTH161';
    }
}

<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span *ngIf="isCreation">Créer un contact</span>
    <span *ngIf="!isCreation">Modifier un contact</span>
    <span *ngIf="isReadOnly">Consulter un contact</span>
    <button mat-button (click)="cancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content>
    <app-edit-contact
        [formContact]="formContact"
        [isReadOnly]="isReadOnly"
        [isProprietaire]="isProprietaire"
        [isDonneurOrdre]="isDonneurOrdre"
        [isContactRef]="isContactRef"
        (onSelectExistingContact)="onSelectExistingContact($event)"
    ></app-edit-contact>

    <div *ngIf="!deleteContact" mat-dialog-actions class="justify-content-end">
        <button mat-button color="primary" (click)="cancel()">
            <lib-icon>close</lib-icon>
            <span>Annuler</span>
        </button>
        <button
            *ngIf="!isCreation && contact.typeRole == enumRoleContact.AUTRE && canDelete"
            mat-button
            color="warn"
            (click)="delete()"
        >
            <lib-icon>delete</lib-icon>
            <span>Supprimer</span>
        </button>
        <button mat-raised-button color="accent" [disabled]="formContact.invalid" (click)="confirm()">
            <lib-icon>check</lib-icon>
            <span>Confirmer</span>
        </button>
    </div>

    <div *ngIf="deleteContact" mat-dialog-actions class="justify-content-end">
        <button mat-button color="primary" (click)="deleteContact = false">
            <lib-icon>close</lib-icon>
            <span>Annuler la suppression</span>
        </button>
        <button mat-raised-button color="warn" (click)="confirmDeletion()">
            <lib-icon>delete</lib-icon>
            <span>Confirmer la suppression</span>
        </button>
    </div>
</div>

<ng-container *ngIf="documentsHistory.length > 0; else elseBlock"
    ><div
        *ngFor="let documentHistory of documentsHistory; trackBy: trackByDocumentHistory"
        class="d-flex justify-content-between w-100 detail-line pt-3 pb-3"
    >
        <div>
            <div>
                {{ documentHistory.nomFichier }}
            </div>
            <div class="small-text">
                transmis le {{ documentHistory.dateTransmission | date: 'shortDate' }} par
                {{ documentHistory.sourceTransmission }}
            </div>
        </div>
        <div class="d-flex align-items-center">
            <app-notes-buttons
                class="notes-buttons-yellow"
                [from]="documentHistory"
                [typesCommentaires]="['MOTIF_MODIFICATION_FICHIER']"
                [replaceTypesCommentaires]="false"
            >
            </app-notes-buttons>
        </div>
    </div>
</ng-container>
<ng-template #elseBlock>Aucun historique</ng-template>

import { Commentaire } from '../model/commentaire.model';
import { CommentairePredefini } from '../model/commentaire-predefini.model';
import { RelationInterventionBien } from '../model/intervention.model';
import { Bien } from '../model/bien.model';

/**
 * Utilitaire lié au bien
 */
export class BienUtils {
    /**
     *
     * Renvoie le suffix d'un bien (bienSelected)
     */
    static getSuffixBien(bienSelected: Bien, relationInterventionBiens: RelationInterventionBien[]): string {
        return relationInterventionBiens.some(
            (relation) => relation.bien.id === bienSelected.id && !relation.isBienPrincipal
        )
            ? '_' + bienSelected.id
            : '';
    }
}

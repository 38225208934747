"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Tool for 3D views
//***********************************************************************************
//***********************************************************************************

import {cn_to_bbp} from "../utils/cn_bbp";
import {cn_building} from "../model/cn_building";
import {cn_view_base} from "./cn_view_base";
import {cn_view_overlay} from "./cn_view_overlay";
import {fh_view, fh_view_elevation, fh_view_perspective, fh_view_subjective} from "@acenv/fh-3d-viewer";
import { cn_event_manager } from "./cn_event_manager";
import { cn_svg_tool_marker_edition } from "./cn_svg_tool_marker_edition";
import { cn_svg_tool_object_edition } from "./cn_svg_tool_object_edition";
import { cn_3d_building } from "../model/cn_3d_building";

/**
 * @class cn_view - A tool to manipulate 3D renderers
 * This class includes 3 renderers :
 * - A persepctive renderer
 * - An elevation renderer
 * - A subjective renderer
 * Available events are :
 * - "storey_change" : called when the current storey changes
 * - "current_view_not_up_to_date" : called when current view does not match anymore the serialized view
 * - "rendering change" : callede whenever rendering changes
 * - "views_change" : called when the list of view names changes
 * - "element_clicked" : called when an element was clicked. Only for selection mode != 0.
 * 			argument 0 : null, or {storey: cn_storey, element: cn_element}
 * 			return value : returning 'true' inhibits selection.
 * - "selection_changed" : called when selection has changed. Only for selection mode != 0
 * 			argument 0 : {storey: cn_storey, element: cn_element}[]
 * - "mouseover_changed" : called when element under mouse has changed. Only for selection mode != 0
 * 			argument 0 : null, or {storey: cn_storey, element: cn_element}
 */
export class cn_view extends cn_view_base {

	//***********************************************************************************
	//**** Constructor
	//***********************************************************************************
	/**
	 * Constructor
	 * The constructer will append 3 divs in main view div, styled with class 'renderer_container'
	 * The constructer will append 2 divs in map view div, styled with class 'renderer_map_container'
	 * All will be hidden until a call to 'open'.
	 * @param {string | HTMLElement} view : identifier of the div for the main view, or html element reference
	 * @param {string | HTMLElement} map  : identifier of the div for the map view (map for elevation and subjective), or html element reference
	 * @param {number} rendering_mode : default rendering mode (0 : perspective, 1 : elevation, 2 : subjective)
	 * @param {cn_event_manager} parent : parent
	 */
	constructor(view, map, rendering_mode = 0, parent=null) {
		super(view, parent);
		this._div_id = view;
		var obj = this;
        this._build_html(view, map);

        this._view_id = view;
		this._map_id = map;

		this._outside_visible = true;

		const overlay_divs = this._rendererDivs.map(function(div) {
			var nd = document.createElement('div');
			nd.classList.add('renderer_container');
			nd.id = div.id + "_overlay";
			div.appendChild(nd);
			return nd;
		})
		this._renderers = [
			new fh_view_perspective(this._rendererDivs[0],this._scene_3d),
			new fh_view_elevation(this._rendererDivs[1],this._rendererMapDivs[0], this._scene_3d),
			new fh_view_subjective(this._rendererDivs[2],this._rendererMapDivs[1], this._scene_3d)];

		this._overlays = [
			new cn_view_overlay(overlay_divs[0],this),
			new cn_view_overlay(overlay_divs[1],this),
			new cn_view_overlay(overlay_divs[2],this)];

		this._overlays.forEach((o, index) => {
			o.on("tool_change",function(t){obj.call(t,index)});
			o.set_renderer(this._renderers[index]);
		});

		// @ts-ignore
		this._renderers[0].on("render",function() {obj._overlays[0].refresh_rendering()});
		// @ts-ignore
		this._renderers[1].on("render",function() {obj._overlays[1].refresh_rendering()});
		// @ts-ignore
		this._renderers[2].on("render",function() {obj._overlays[2].refresh_rendering()});

		//@ts-ignore
		this._renderers[2].set_door_visibility(false);

		for (var k in this._renderers)
		{
			this._renderers[k].on("view_change",function(){obj.call("current_view_not_up_to_date");});
		}

		this._renderers[1].on("elevation_angle_change",function(){obj.call("elevation_angle_change");});

        var obj = this;
		//@ts-ignore
		this._renderers[2]._cb_current_storey_changed = function() {
			//@ts-ignore
			obj._current_storey = obj._renderers[2].get_current_storey();
			obj.call("storey_change");
		};

		this._rendering_mode = rendering_mode;
		this._current_storey = 0;
		this._current_view = -1;

		this._renderer = this._renderers[this._rendering_mode];

		this._rendererDivs.forEach(function(r,index){if (index<3) obj._hide(r);});
		this._rendererMapDivs.forEach(r => this._hide(r));

		this.add_tool(new cn_svg_tool_marker_edition(null),0);
		this.add_tool(new cn_svg_tool_marker_edition(null),1);
		this.add_tool(new cn_svg_tool_object_edition(null),0);
		this.add_tool(new cn_svg_tool_object_edition(null),1);
        this._hide(this._map_id);
	}

    _build_html(div_id, map_div_id) {
	    this._rendererDivs = [0, 1, 2, 3].map(id => 'cn_view_container_'+ id)
            // @ts-ignore
            .map(function(id,index) {
                let div = document.createElement('div');
                div.classList.add('renderer_container');
                // TODO remove id
                div.id = id;
                return div;
            });
	    if (div_id instanceof Element || div_id instanceof HTMLElement) {
	        this._rendererDivs.forEach(r => div_id.append(r));
        } else {
	        let container = document.getElementById(div_id);
	        this._rendererDivs.forEach(r => container.append(r));
        }
        this._rendererMapDivs = [1, 2].map(id => 'cn_map_container_'+ id)
            .map(id => {
                let div = document.createElement('div')
                div.classList.add('renderer_map_container');
                // TODO remove id
                div.id = id;
                return div;
            });
        if (map_div_id instanceof Element || map_div_id instanceof HTMLElement) {
            this._rendererMapDivs.forEach(r => map_div_id.append(r));
        } else {
            let container = document.getElementById(map_div_id);
            this._rendererMapDivs.forEach(r => container.append(r));
        }
    }

    _hide(s) {
        let el;
        if (s instanceof Element || s instanceof HTMLElement) {
            el = s;
        } else {
            el = document.getElementById(s);
        }
        if (el) {
			//@ts-ignore
            el.style.display = 'none';
        } else {
            document.querySelector(s).style.display = 'none';
        }
    }

    _show(s) {
	    let el;
        if (s instanceof Element || s instanceof HTMLElement) {
            el = s;
        } else {
            el = document.getElementById(s);
        }
        if (el) {
			//@ts-ignore
            el.style.display = 'block';
        } else {
            document.querySelector(s).style.display = 'block';
        }
    }

    /**
     * Sets explicit view dimensions on all view renderers. Overrides default auto size behaviour.
     * To reset call with size 0.
     * @param {number} width
     * @param {number} height
     */
	set_view_dimensions(width, height) {
	    this._renderers.forEach(renderer => {
	        renderer._forced_width  = width;
	        renderer._forced_height = height;
        });
    }

	//*** Called upon transaction refresh */
	transaction_refresh() {
		this._overlays[this._rendering_mode].refresh_3d();
		this._overlays[this._rendering_mode].refresh();
	}

	/****************************************************************************/
	//******  tools
	/****************************************************************************/

	add_tool(tool, rendering_mode = -1) {
		const rm = (rendering_mode>=0)?rendering_mode:this._rendering_mode;
		this._overlays[rm].add_tool(tool);
	}

	get_current_tool(rendering_mode = -1)
	{
		const rm = (rendering_mode>=0)?rendering_mode:this._rendering_mode;
		return this._overlays[rm].get_current_tool();
	}

	set_current_tool(tool, rendering_mode = -1) {
		const rm = (rendering_mode>=0)?rendering_mode:this._rendering_mode;
		this._overlays[rm].select_element(null);
		this._overlays[rm].set_current_tool(tool);
	}

	/**
	 * Sets the edition mode for given rendering.
	 * If rendering is not specifies, apply to the current rendering mode.
	 * @param {boolean} edition_mode 
	 * @param {number} rendering_mode 
	 */
	set_edition_mode(edition_mode, rendering_mode = -1) {
		const rm = (rendering_mode>=0)?rendering_mode:this._rendering_mode;
		this._overlays[rm].set_edition_mode(edition_mode);
	}

	/****************************************************************************/
	//******  View parameters
	/****************************************************************************/
	/**
	 * Activate / desactivate mouse management on renderers. Default is true
	 * @param {boolean} v
	 */
	set_mouse_management(v)
	{
		for (var k in this._renderers)
			this._renderers[k].set_mouse_management(v);
	}

	/**
	 * sets smooth animation between view changes. Default is true.
	 * @param {boolean} v
	 */
	set_animation_mode(v)
	{
		for (var k in this._renderers)
			this._renderers[k].set_animation_mode(v);
	}

	/**
	 * Sets selection mode on scene.
	 * Default is 0 (no selection).
	 * 1 = zone selection
	 * 2 = space selection
	 * 3 = element selection
	 * @param {number} selmode
	 */
	set_selection_mode(selmode) {
		super.set_selection_mode(selmode);
		this.update_visibilities();
	}

	/**
	 *
	 * Show / hide exterior
	 * @param {boolean} value
	 */
	set_exterior_visibility(value) {
		this._outside_visible = value;
		this.update_visibilities();
		this._renderer.refresh_rendering();
	}

	/**
	 * Get current exterior visibility
	 * @returns {boolean}
	 */
	get_exterior_visibility() {
		return this._outside_visible;
	}

	/****************************************************************************/
	//******  Storeys
	/****************************************************************************/
	/**
	 * Returns the list of all storey names
	 * @returns {string[]}
	 */
	get_storeys() {
		return this._storeys;
	}

	/**
	 * returns current storey index
	 * @returns {number}
	 */
	get_current_storey() {
		return this._current_storey;
	}

	/**
	 * sets current storey by its index
	 * @param {number} index
	 */
	set_current_storey(index) {
		this._current_storey = index;

		this.update_visibilities();

		if (this._rendering_mode == 1 || this._rendering_mode == 2)
		{
			if (index >= this._scene_3d._storeys.length)
			{
				this._current_storey = this._scene_3d._storeys.length-1;
				index = this._current_storey;
			}
			//@ts-ignore
			this._renderer.set_current_storey(this._scene_3d._storeys[index]);
		}

		this.refresh_rendering();
		this.call("storey_change");
		this.call("current_view_not_up_to_date");
	}

	update_visibilities() {
		//if (this._rendering_mode == 0)
		{
			const topography =  (this._scene_3d._ground_controller && this._scene_3d._ground_controller.visible)
			//*** Hide objects from above storeys
			//@ts-ignore
			this._scene_3d._spaces.forEach(obj => {
				this._scene_3d.set_space_visibility((this._rendering_mode == 2 && obj.storey == this._current_storey),obj.BIMID);
			});

			//@ts-ignore
			this._scene_3d._products.forEach(prod => {
				const obj = prod.json_object;
				var storey = obj.storey;
				if (typeof(storey) != 'undefined')
				{
					if (!this._outside_visible && (typeof(obj.topography) != 'undefined'))
						this._scene_3d.set_product_visibility(false,obj.ID);
					else if (this._rendering_mode == 0)
					{
						const topo_visibility = topography && (typeof(obj.topography) != 'undefined');
						//@ts-ignore
						this._scene_3d.set_product_visibility(topo_visibility || (storey <= this._current_storey),obj.ID);
					}
					else
					{
						this._scene_3d.set_product_visibility(true,obj.ID);
					}
				}
			});
		}
	}
	/****************************************************************************/
	/**
	 * Returns ground controller
	 */
	get_ground_controller() {
		return this._scene_3d._ground_controller;
	}

	/****************************************************************************/
	//******  Rendering mode
	/****************************************************************************/
	/**
	 * Returns current rendering mode (0 : perspective, 1 : elevation, 2 : subjective)
	 * @return {number}
	 */
	get_rendering_mode() {
		return this._rendering_mode;
	}

	/**
	 * Sets rendering mode (0 : perspective, 1 : elevation, 2 : subjective)
	 * @param {number} rendering_mode
	 */
	set_rendering_mode(rendering_mode) {
		this._close_rendering();
		this._rendering_mode = rendering_mode;
		this._renderer = this._renderers[this._rendering_mode];
		this._open_rendering();
		this.call("rendering_change");
		this.call("current_view_not_up_to_date");
	}

	/**
	 * Returns current renderer (class fh_view)
	 * @return {fh_view}
	 */
	get_renderer() {
		return this._renderer;
	}

	/****************************************************************************/
	//******  Perspective controls
	/****************************************************************************/
	/**
	 * Returns true if current camera manipulation is orbit
	 * @return {boolean}
	 */
	get_default_orbit() {
		if (this._rendering_mode != 0) return false;
		return this._renderer.get_default_orbit();
	}

	/**
	 * sets default camera action : 'true' for orbit, 'false' for pan.
	 * @param {boolean} v
	 */
	set_default_orbit(v) {
		if (this._rendering_mode != 0) return;
		this._renderer.set_default_orbit(v);
		this.call("rendering_change");
	}

	/**
	 * Zoom forward (zoom_in = true) or backward (zoom_in = false)
	 * @param {boolean} zoom_in
	 */
	zoom(zoom_in)
	{
		if (this._rendering_mode != 0 && this._rendering_mode != 1) return;
		this._renderer.zoom(zoom_in);
	}

	/**
	 * Resets camera to center of the view
	 */
	reset_camera() {
		this._renderer["_camera_reset"] = true;
		this._renderer.reset_camera();
		this.call("current_view_not_up_to_date");
	}

	/****************************************************************************/
	//******  Elevation controls
	/****************************************************************************/
	/**
	 * Returns elevation angle (azimut), in degrees.
	 * @returns {number}
	 */
	get_elevation_angle() {
		//@ts-ignore
		return this._renderers[1].get_elevation_angle();
	}

	/**
	 * Sets elevation angle (azimut), in degrees.
	 * @param {number} v
	 */
	set_elevation_angle(v) {
		//@ts-ignore
		this._renderers[1].set_elevation_angle(v);
	}

	/**
	 * Returns height of view, in world reference (meters)
	 * @returns {number}
	 */
	get_vertical_world_height() {
		//@ts-ignore
		return this._renderers[1].get_vertical_world_height();
	}

	/**
	 * sets height of view, in world reference (meters)
	 * @param {number} scale
	 */
	set_vertical_world_height(scale) {
		//@ts-ignore
		return this._renderers[1].set_vertical_world_height(scale);
	}

	/****************************************************************************/
	//******  Subjective controls
	/****************************************************************************/
	/**
	 * returns true if subjective viewer is 'standing', false if 'kneeling' (height of view point above the ground)
	 * @returns {boolean}
	 */
	get_viewer_standing() {
		if (this._rendering_mode != 2) return false;
		return (this._renderer["_viewer_height"] > 1);
	}

	/**
	 * Set viewer in 'standing' mode (true), or 'kneeling' mode (false) (height of view point above the ground)
	 * @param {boolean} v
	 */
	// @ts-ignore
	// @ts-ignore
	// @ts-ignore
	set_viewer_standing(v) {
		if (this._rendering_mode != 2) return;
		if (this._renderer["_viewer_height"]  > 1)
			//@ts-ignore
			this._renderer.set_viewer_height(0.8);
		else
			//@ts-ignore
			this._renderer.set_viewer_height(1.6);
		this.call("rendering_change");
		this.call("current_view_not_up_to_date");
	}

	/**
	 * returns true if doors are visible
	 * @returns {boolean}
	 */
	get_door_visibility() {
		if (this._rendering_mode != 2) return false;
		//@ts-ignore
		return this._renderer.get_door_visibility();
	}

	/**
	 * Sets door visibility
	 * @param {boolean} v
	 */
	set_door_visibility(v) {
		if (this._rendering_mode != 2) return;
		//@ts-ignore
		this._renderer.set_door_visibility(v);
		this.call("rendering_change");
		this.call("current_view_not_up_to_date");
	}

	/****************************************************************************/
	//******  View controls
	/****************************************************************************/
	/**
	 * Returns the list of recorded view names
	 * @returns {string[]}
	 */
	get_views() {
		var vs = [];
		for (var i in this._building.views)
			vs.push(this._building.views[i].name);
		return vs;
	}

	/**
	 * Returns the index of current view
	 * @returns {number}
	 */
	get_current_view() {
		return this._current_view;
	}

	/**
	 * Calls for a given registered view (this may change renderer)
	 * Accept an out of range value. In that case, there is no current view.
	 * @param {number} index
	 */
	set_current_view(index)
	{
		if (index >= 0 && index < this._building.views.length)
			this._current_view = index;
		else
		{
			this._current_view = -1;
			return;
		}

		var cam = this._building.views[this._current_view];

		var mode = -1;
		if (cam.type == 'perspective')
			mode = 0;
		else if (cam.type == 'elevation')
			mode = 1;
		else if (cam.type == 'subjective')
			mode = 2;
		else
			return;

		this._close_rendering();

		if (cam.storey <= this._building.storeys.length)
			this._current_storey = cam.storey;

		this._rendering_mode = mode;
		this._renderer = this._renderers[this._rendering_mode];

		this._open_rendering();
		this._renderer.unserialize(cam);
		this.call("rendering_change");
	}

	/**
	 * Returns the name of current view, or 'undefined' if there is no current selected view
	 * @returns {string | undefined}
	 */
	get_current_view_name() {
		if (this._current_view < 0) return undefined;
		return this._building.views[this._current_view].name;
	}

	/**
	 * Changes the name of current view
	 * @param {string} name
	 */
	set_current_view_name(name) {
		this.set_view_name(name,this._current_view);
	}

	/**
	 * Creates a new default name for a view, taking into account current renderer. Name will be unique.
	 * @returns {string}
	 */
	new_view_default_name() {
		var base_name = "Perspective ";
		if (this._rendering_mode == 1)
			base_name = "Elévation ";
		else if (this._rendering_mode == 2)
			base_name = "Vue ";

		var default_name = "";
		for (var i=1;true;i++)
		{
			default_name = base_name + i;
			var exists = false;
			for (var k in this._building.views)
			{
				if (this._building.views[k].name != default_name) continue;
				exists = true;
				break;
			}
			if (!exists) break;
		}
		return default_name;
	}

	/**
	 * Create a new view
	 * @param {string} view_name : name of the view to be created. No unicity check is done.
	*/
	create_new_view(view_name) {
		var cam = this._renderer.serialize();
		if (typeof(this._building.views) == 'undefined')
			this._building.views = [];
		cam["name"] = view_name;
		cam["storey"] = this._current_storey;
		this._building.views.push(cam);
		this._current_view = this._building.views.length-1;
		this.call("views_change");
		return this._current_view;
	}

	/**
	 * Returns the size (width ann height) of the viewport
	 * @returns {number[]}
	 */
	get_viewport_size() {
		return this._renderer.get_viewport_size();
	}

	/**
	 * save current view
	*/
	save_current_view() {
		this.save_view(this._current_view);
	}

	/**
	 * Deletes current view
	 */
	delete_current_view() {
		this.delete_view(this._current_view);
	}

	/**
	 * saves current view to a given view index
	 * @param {number} view_index
	 */
	save_view(view_index) {
		if (typeof(this._building.views[view_index]) == 'undefined') return;
		var v = this._building.views[view_index];
		var name = v.name;
		v = this._renderer.serialize();
		v.name = name;
		v.storey = this._current_storey;
		this._building.views[view_index] = v;
	}

	/**
	 * Deletes a view
	 * @param {number} view_index
	 */
	delete_view(view_index) {
		if (typeof(this._building.views[view_index]) == 'undefined') return;
		this._building.views.splice(view_index,1);
		this._current_view = -1;
		this.call("views_change");
	}

	/**
	 * Changes the name of a view
	 * @param {string} name
	 * @param {number} view_index
	 */
	set_view_name(name, view_index) {
		if (typeof(this._building.views[view_index]) == 'undefined') return;
		this._building.views[view_index].name = name;
	}

	//***********************************************************************************
	//**** Export view
	//***********************************************************************************
	/**
	 * Save current view in B64, given any rendering size
	 * @param {number} w : rendering width, in pixels
	 * @param {number} h : rendering height, in pixels
	 */
	to_image_url(w, h) {
		return this._renderer.to_image_url(w,h);
	}

	/** Save current map view in B64, given any rendering size
	 * @param {number} w : rendering width, in pixels
	 * @param {number} h : rendering height, in pixels
	 */
	map_to_image_url(w, h) {
		if (this._rendering_mode != 0)
			//@ts-ignore
			return this._renderer.map_to_image_url(w,h);
		return null;
	}

	//***********************************************************************************
	//**** Opener
	//***********************************************************************************
	/**
	 * Opens the viewer for a given building
	 * @param {cn_building} building
	 */
	open(building) {
		this._building = building;
		this._3d_building.load_building(building);

		//*** storeys */
		this._storeys = [];
		for (var i=0;i<this._building.storeys.length;i++)
			this._storeys.push(this._building.storeys[i].get_storey_name());
		this._storeys.push("Toiture");

		for (var ki in this._renderers)
		{
			this._renderers[ki].update_scene();
			this._renderers[ki]["_camera_reset"] = false;
		}

		this._current_view = -1;
		this._previous_camera = null;

		this._overlays.forEach(o => o.set_building(building));

		this._open_rendering();
	}

	//***********************************************************************************
	//**** refresh
	//***********************************************************************************
	/**
	 * Refresh rendering
	 */
	refresh_rendering() {
		this._renderer.refresh_rendering();
		this._overlays[this._rendering_mode].refresh_rendering();
	}

	/****************************************************************************/
	//******  Close Rendering
	/****************************************************************************/
	_close_rendering() {
        this._rendererDivs.forEach(r => this._hide(r));
        this._rendererMapDivs.forEach(r => this._hide(r));
		this._previous_camera = this._renderer._camera;
		if (this._rendering_mode == 0)
		{
			//*** Show all storeys */
			//@ts-ignore
			for (var i in this._scene_3d.json.objects)
				//@ts-ignore
				this._scene_3d.set_product_visibility(true,this._scene_3d.json.objects[i].ID);
		}
		else if (this._rendering_mode == 1)
		{
			this._3d_building.set_edge_visibility(false);
			this._scene_3d.set_full_ambient(false);
		}
		else if (this._rendering_mode == 2)
		{
			this._scene_3d.set_product_type_visibility(true,"DOOR");
		}
	}

	//*******************************************************
	//**** open rendering
	//*******************************************************
	_open_rendering() {
		//*** Perspective mode */
		if (this._rendering_mode == 0)
		{
			this._show(this._rendererDivs[0]);
			this._hide(this._map_id);
			//*** Hide all objects below current storey */
			//@ts-ignore
			for (var i in this._scene_3d.json.objects)
			{
				//@ts-ignore
				var storey = this._scene_3d.json.objects[i].storey;
				if (typeof(storey) == 'undefined') continue;
				//@ts-ignore
				this._scene_3d.set_product_visibility(storey <= this._current_storey,this._scene_3d.json.objects[i].ID);
			}
		}
		//*** Elevation mode */
		if (this._rendering_mode == 1)
		{
            this._show(this._map_id);
            this._show(this._rendererDivs[1]);
            this._show(this._rendererMapDivs[0]);
			this._3d_building.set_edge_visibility(true);
			this._scene_3d.set_full_ambient(true);
			if (this._current_storey == this._storeys.length-1) this._current_storey = 0;
			//@ts-ignore
			this._renderer.set_current_storey(this._current_storey);
		}
		//*** Subjective mode */
		else if (this._rendering_mode == 2)
		{
            this._show(this._map_id);
            this._show(this._rendererDivs[2]);
            this._show(this._rendererMapDivs[1]);
			//@ts-ignore
			this._renderer.set_door_visibility(this._renderer.get_door_visibility());
			if (this._current_storey == this._storeys.length-1) this._current_storey = this._storeys.length-2;
			//@ts-ignore
			this._renderer.set_current_storey(this._current_storey);
		}

		if (!this._renderer["_camera_reset"])
			this.reset_camera();
		this._renderer.animate_camera(this._previous_camera);

		this.update_visibilities();
		this.call("storey_change");
		this.refresh_rendering();
	}
}


"use strict";
//***********************************************************************************
//***********************************************************************************
//**** A general handler for object instances
//***********************************************************************************
//***********************************************************************************

import {cn_clone} from "../utils/cn_utilities";
import {cn_object_instance} from "../model/cn_object_instance";
import {cn_storey} from "../model/cn_storey";
import {cn_svg_map} from "./cn_svg_map";
import {cn_pastille, cn_view_overlay, PARAM_STATUS_CUSTOM_VALUE, PARAM_STATUS_USE_DEFAULT_VALUE, PARAM_STATUS_VARIABLE} from '..';
import {cn_handler_space_drag2} from "./cn_handler_space_drag2";
import {cn_handler_wall_drag} from "./cn_handler_wall_drag";
import {cn_handler_object} from "./cn_handler_object";
import {cn_edition_handler} from "./cn_edition_handler";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_edit_box} from "./cn_edit_box";
import {cn_number_input, cn_object_parameters_input} from "./cn_inputs";

export class cn_object_instance_handler extends cn_edition_handler {
    /**
     * Constructor
	 * @param {Array<cn_object_instance>} instances
     * @param {cn_svg_map | cn_view_overlay} map
	 * @param {boolean} creation
	 * @param {cn_storey} storey
     */
	constructor(instances, map, creation=false, storey = null) {
		super(instances,map);
		this.instance = (instances.length==1)?instances[0]:null;
		this.instances = instances;
		this._storey = storey;
		this._map =(map && map.constructor == cn_svg_map)?map:null;
        this._view_overlay = (map && map.constructor == cn_view_overlay)?map:null;
		this._controller = (this._map)?this._map._controller:null;
		this._transaction_manager = instances[0].scene.building.transaction_manager;
        this._handler = null;
        this._ghost = null;

        //*** Create a handler for that object, depending on the object's type
        this.drawing_storey = (storey)?storey:(this._map)?this._map._storey:null;
        if (this.instance)
		{
			this._scene = this.instance.scene;
			var anchor_position = null;
			var anchor_normal = null;
			if (this.instance.object.place_on_wall()) {
				anchor_position = this.instance.object.get_anchor_position();
				anchor_normal = this.instance.object.get_anchor_normal();
			}
			var handler_object = new cn_handler_object(this.instance.object.size[0], this.instance.object.size[1], this.instance.position, this.instance.orientation * Math.PI / 180, anchor_position, anchor_normal);
            handler_object.element = this.instance;

			if (anchor_position)
				this._handler = new cn_handler_wall_drag(handler_object, this._scene, this.drawing_storey);
			else
                this._handler = new cn_handler_space_drag2(handler_object, this._scene, this.drawing_storey);

			this._handler["instance"] = this.instance;
			this._handlers.push(this._handler);
		}

		//*** Edit box for mass selection */
		const edit_box = new cn_edit_box(this,instances,creation);
		this._handlers.push(edit_box);

		const obj = this;
        this._height_pastille = null;
		//*** Altitude edition */
		if (this.instance) {
            const z0 = this.instance.object.get_default_height();
            let z = z0 + this.instance.height;

            const height_pastille = new cn_pastille([0,0],"h=" + (z*100).toFixed(0));
            this._height_pastille = height_pastille;
            edit_box.add_pastille(height_pastille);
            height_pastille.svg_class = "pastille_background white";
            height_pastille.text_class =  "pastille_text black";
            height_pastille.title="Modifier la hauteur";
            height_pastille.clicked = function() {
                const input = new cn_number_input(`Hauteur de l'équipement`, z*100, "cm", 0, 0);
                input.callback = function() {
                    obj._transaction_manager.push_transaction("Hauteur d'équipement");
                    obj._transaction_manager.push_item_set(obj.instance,["height"]);
                    let v = input.value/100;
                    obj.instance.height = v - z0;
                    z = z0 + obj.instance.height;
                    height_pastille.label = "h=" + (z*100).toFixed(0);
                    
                    //*** 3D change ? */
                    if (obj._view_overlay)
                    {
                        obj._view_overlay.refresh_3d();
                    }

                };
                obj.call("number_input",input);
            }
		}

		//*** parameters */
		if (instances[0].object.source && instances[0].object.source.product_type)
		{
			//*** We check that all instances have the same source */
			const source = instances[0].object.source;
			if (source.product_type != "" && !instances.some(i => i.object.source != source))
			{
				//*** We create the parameters pastille */
				const parameters_pastille = new cn_pastille([0,0],"text.svg");
				edit_box.add_pastille(parameters_pastille);
				parameters_pastille.svg_class = "pastille_background white";
				parameters_pastille.title="Paramètres de l'équipement";

				//*** Pastille click method :  */
				parameters_pastille.clicked = function() {

					//*** Build input */
					const input = new cn_object_parameters_input();
                    const distinctLabels = new Set(instances.map(it => it.object.name));
					input.label = distinctLabels.size === 1 ? distinctLabels.values().next().value : '';
					input.product_type = source.product_type;
                    input.parameters = [];

                    //*** Fill codes & values */
                    const codes = [];
                    const typesValueByCode = new Map();
                    const instanceDistinctValuesByCode = new Map();
					for (let code in source.parameters) {
						codes.push(code);
                        typesValueByCode.set(code, source.parameters[code]);
					}
					instances.forEach(instance => {
						for (let code in instance.parameters) {
							if (!codes.includes(code)) {
								codes.push(code);
							}
                            if (!instanceDistinctValuesByCode.has(code)) {
                                instanceDistinctValuesByCode.set(code, new Set());
                            }
                            instanceDistinctValuesByCode.get(code).add(instance.parameters[code]);
						}
					});

                    //*** Fill input parameters */
                    const inputParams = [];
                    codes.forEach(code => {
                        //*** Get type & instance values */
                        const typeValue = typesValueByCode.get(code);
                        const instanceDistinctValues = instanceDistinctValuesByCode.get(code) || new Set();
                        //*** Get status */
                        let status;
                        const allDistinctValues = new Set();
                        if (typeValue != null) {
                            allDistinctValues.add(typeValue);
                        }
                        instanceDistinctValues.forEach(val => allDistinctValues.add(val));

                        if (instances.length === 1) {
                            switch (allDistinctValues.size) {
                                case 0:
                                    status = PARAM_STATUS_USE_DEFAULT_VALUE;
                                    break;
                                case 1:
                                    if (typeValue != null) {
                                        status = PARAM_STATUS_USE_DEFAULT_VALUE;
                                    } else {
                                        status = PARAM_STATUS_CUSTOM_VALUE;
                                    }
                                    break;
                                default:
                                    status = PARAM_STATUS_CUSTOM_VALUE;
                                    break;
                            }
                        } else {
                            switch (allDistinctValues.size) {
                                case 0:
                                    status = PARAM_STATUS_USE_DEFAULT_VALUE;
                                    break;
                                case 1:
                                    if (typeValue != null) {
                                        status = PARAM_STATUS_USE_DEFAULT_VALUE;
                                    } else if (instances.every(instance => instance.parameters[code] === instanceDistinctValues.values().next().value)) {
                                        status = PARAM_STATUS_CUSTOM_VALUE;
                                    } else {
                                        status = PARAM_STATUS_VARIABLE;
                                    }
                                    break;
                                default:
                                    if (instanceDistinctValues.size === 1 && instances.every(instance => instance.parameters[code] === instanceDistinctValues.values().next().value)) {
                                        status = PARAM_STATUS_CUSTOM_VALUE;
                                    } else {
                                        status = PARAM_STATUS_VARIABLE;
                                    }
                                    break;
                            }
                        }

                        //*** Get effective value */
                        let effectiveValue;
                        switch (status) {
                            case PARAM_STATUS_USE_DEFAULT_VALUE:
                                effectiveValue = typeValue;
                                break
                            case PARAM_STATUS_CUSTOM_VALUE:
                                effectiveValue = instanceDistinctValues.values().next().value;
                                break;
                            case PARAM_STATUS_VARIABLE:
                                effectiveValue = undefined;
                                break;
                        }

                        const inputParam = {
                            code_bim: code,
                            default_value: typeValue,
                            value: effectiveValue,
                            status: status,
                        }
                        inputParams.push(inputParam);
                    })
                    input.parameters = inputParams;

					//*** Input callback */
					input.callback = function() {
						obj._transaction_manager.push_transaction("Modification d'équipements");
						instances.forEach(it => {obj._transaction_manager.push_item_set(it, ["parameters"]);});
						input.parameters.forEach(p => {
							if (p.status === PARAM_STATUS_USE_DEFAULT_VALUE) {
								instances.forEach(i => delete i.parameters[p.code_bim]);
							} else if (p.status === PARAM_STATUS_CUSTOM_VALUE) {
								instances.forEach(i => i.parameters[p.code_bim] = p.value);
							}
						});
					};

					obj.call("object_parameters_input",input);
				};
			}
		}

		if (this.instance)
			edit_box.add_lock_pastille(this._transaction_manager);

		edit_box.add_select_siblings_pastille("object");
	}

	//***********************************************************************************
	//**** Refresh
	//***********************************************************************************
	draw(camera) {
		if (this._handler) this._handler.active = this._handler.visible = !this.instance.virtual && !this.instance.locked;
        if (camera.is_3d() && this._handler)
        {
            this._handler.altitude = this.instance.get_altitude(this.drawing_storey);
        }
		return super.draw(camera);
	}

	//***********************************************************************************
	//**** clear move effects
	//***********************************************************************************
	clear_move() {
        this._ghost = null;
		super.clear_move();
	}

    /**
     * Manage a grab. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
     grab(mouse_event) {
        this._ghost = null;
		if (this.instance && this._controller && this._controller.get_selection().length == 1 && this._controller.get_selection()[0] == this.instance)
            mouse_event.drag_and_drop_element = this.instance;

        if (super.grab(mouse_event))
            return true;

		mouse_event.drag_and_drop_element = null;
        return false;
	}

	/**
	 * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
    drag(mouse_event) {
		const res = super.drag(mouse_event);

		if (this._handler && this._handler == this._focus_handler)
		{
        	this._ghost = null;

			if (res) {
				this._transaction_manager.push_transaction("Déplacement d'objet", this.instance.ID);
				this._transaction_manager.push_item_set(this.instance, ["position", "orientation", "_contact_wall", "space", "height"]);
                this.instance._contact_wall = null;
				this.instance.position = cn_clone(this._handler.object.center);
				this.instance.orientation = this._handler.object.angle * 180 / Math.PI;
				this.instance.space = this._handler["space"];
                this.instance.update_deep();

                //*** 3D change ? */
                if (this._view_overlay)
                {
                    //*** maybe update height ? */
                    if (this.instance.object.get_contact() == "wall")
                    {
                        const dh =this.instance.object.get_default_height();
                        this.instance.height += mouse_event.impact_behind.position[2] - this.instance.get_altitude(this.drawing_storey);
                        if (dh + this.instance.height < 0.05) this.instance.height = -dh;
                        if (this._height_pastille)
                        {
                            var z = dh + this.instance.height;
                            this._height_pastille.label = "h=" + (z*100).toFixed(0);
                        }
                    }

                    this._view_overlay.refresh_3d();
                }

				this.call("change");
				this._pending_changes = true;
			}
			else
				this._ghost = mouse_event.mouse_world;
		}
		return true;
	}

    /**
     * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
     drop(mouse_event) {
		return true;
	}

    /**
     * During a drag and drop, the owner of the drag and drop may change.
     * Return true to accept owning the drag and drop events.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
	 grab_element(mouse_event) {
		if (this.instance)
		{
			this.instance.virtual = false;
			this._handler.grab_element(mouse_event);
			this._focus_handler = this._handler;
		}
		return true;
	}

    /**
     * Manage a drag, during a drag and drop.
     * This may follow:
     * - Either a grab event, where drag and drop has been triggered (by setting drag_and_drop_element on the mouse event)
     * - Either after an accepted grab_element event.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
	drag_element(mouse_event) {
		return this.drag(mouse_event);
	}

    /**
     * Manage a drop, in a drag and drop event.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
	drop_element(mouse_event) {
		return true;
	}

}


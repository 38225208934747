import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncrementInputComponent } from './increment-input.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LibLongPressModule } from '../long-press/long-press.module';

@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        MatInputModule,
        MatTooltipModule,
        LibLongPressModule,
    ],
    declarations: [IncrementInputComponent],
    exports: [IncrementInputComponent],
})
export class LibIncrementInputModule {}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { MongoUtils } from 'src/app/commons-lib';
import { from, Observable, of } from 'rxjs';
import { FileData, OfflineStorageService, ResourceWrapper } from '../shared/offline/offline-storage.service';
import { map, switchMap } from 'rxjs/operators';
import { blobToDataUrl, dataUrlToBlob } from '../shared/offline/file.utils';
import { Competence, UserInformation } from '../model/user-wizy.model';
import { STORAGE_KEY_USER_INFORMATIONS } from '../shared/constants/indexeddb.constants';
import { Document } from '../model/document.model';

export class SignatureImageFileData implements FileData {
    fileId?: string;
    fileContent?: string;
    fileName?: string;
    idUser: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserInformationApiService {
    public resourceUrl = environment.apiUrl + '/informations/users';
    /**
     * user information courant
     */
    private resource: ResourceWrapper<UserInformation, string> = this.offlineStorage.wrapRestResource<
        UserInformation,
        string
    >({
        cacheName: STORAGE_KEY_USER_INFORMATIONS,
        idField: 'idUser',
        resourceUrl: `${this.resourceUrl}`,
    });

    private http: HttpClient;
    constructor(private readonly httpBackend: HttpBackend, private offlineStorage: OfflineStorageService) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Upload une nouvelle image de signature pour le user
     * @param idUser id du user
     * @param fileContent image à uploader en Base64
     */
    uploadImageSignature(idUser: string, fileContent: string): Observable<UserInformation> {
        const fileId = MongoUtils.generateObjectId();
        const formData = new FormData();
        formData.append('file', dataUrlToBlob(fileContent));
        formData.append('fileId', fileId);

        return this.http
            .post<UserInformation>(`${this.resourceUrl}/${idUser}/signature`, formData)
            .pipe(switchMap(() => this.getUserInformationByUserId(idUser)));
    }

    /**
     * Télécharge l'image de la signature du user
     * @param idUser id du user
     */
    downloadSignatureImage(idUser: string): Observable<SignatureImageFileData> {
        /**
         * ce Header sert a ne pas enregistrer la réponse dans le cache du navigateur
         * la date d'expiration de la cache est aussi fixée dans le passé pour s'assurer de la non-pérennité de la
         * réponse dans le cache en cas de non interprétation du Pragma ou du Cache-control par le navigateur
         */
        const noCacheHeaders = new HttpHeaders({
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        });
        return this.http
            .get<Blob>(`${this.resourceUrl}/${idUser}/signature`, {
                observe: 'response',
                headers: noCacheHeaders,
                responseType: 'blob' as 'json',
            })
            .pipe(
                switchMap((response) => {
                    if (response.body) {
                        return from(blobToDataUrl(response.body));
                    } else {
                        return of(null);
                    }
                }),
                map((dataUrl) => {
                    if (dataUrl) {
                        return Object.assign({
                            fileContent: dataUrl,
                            idUser: idUser,
                        });
                    } else {
                        return null;
                    }
                })
            );
    }

    /**
     * Update du materiel de l'user
     * @param idUser id du user
     */
    updateMaterielUser(idUser: string, materiel: object) {
        const formData = new FormData();
        formData.append('numeroChrono', materiel['numeroChrono']);
        formData.append('numeroDebitmetre', materiel['numeroDebitmetre']);

        return this.http.post(`${this.resourceUrl}/${idUser}/materiel`, formData);
    }

    /**
     * Retourne l'utilsateurInformation avec l'user id
     * @param idUser id du user
     */
    getUserInformationByUserId(idUser: string): Observable<UserInformation> {
        return this.resource.getOne(idUser);
    }

    /**
     * Retourne la  liste de compétences de l'utilisateur
     * @param idUser id du user
     */
    findCompetencesByIdUser(idUser: string) {
        return this.http.get<Competence[]>(`${this.resourceUrl}/competencesByIdUser/${idUser}`);
    }

    /**
     * Retourne la liste de documents liés aux compétences de plusieurs operateurs
     * @param idUsers
     */
    findDocumentsCompetencesByIdUsers(idUsers: string[]) {
        return this.http.get<Document[]>(`${this.resourceUrl}/documentsCompetencesByIdUsers/`, { params: { idUsers } });
    }
}

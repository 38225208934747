import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Niveau, Volume } from '../../../../../model/bien.model';

export enum TypeAssociation {
    NIVEAU = 'NIVEAU',
    VOLUME = 'VOLUME',
}

export class TransfertUsageModalData {
    constructor(
        public niveaux: Niveau[],
        public typeAssociation: TypeAssociation,
        public sourceElement: Niveau | Volume
    ) {}
}

export class ModalTransfertUsageSelection {
    nom: string;
    id: string;
    niveau: Niveau;
    volume?: Volume;
}
@Component({
    selector: 'app-modal-transfert-usage',
    templateUrl: './modal-transfert-usage.component.html',
    styleUrls: ['./modal-transfert-usage.component.scss'],
})
export class ModalTransfertUsageComponent implements OnInit {
    selection: ModalTransfertUsageSelection;
    niveauxEtPieces: ModalTransfertUsageSelection[] = [];
    title: string;

    constructor(
        private readonly dialogRef: MatDialogRef<ModalTransfertUsageComponent, ModalTransfertUsageSelection>,
        @Inject(MAT_DIALOG_DATA) public readonly data: TransfertUsageModalData
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth700-dialog');
        if (this.data) {
            this.prepareList();
            this.title =
                this.data.typeAssociation === TypeAssociation.NIVEAU
                    ? 'Associer à un niveau'
                    : 'Associer à un niveau / une pièce';
        }
    }

    private prepareList() {
        this.niveauxEtPieces = this.data.niveaux
            .filter((niv) => niv.storeyId)
            .map((niveau) => [
                { nom: niveau.nom, id: niveau.id, niveau },
                ...(this.data.typeAssociation === TypeAssociation.VOLUME
                    ? niveau.volumes
                          .filter((v) => !v.volumeCache && v.spaceId)
                          .map((volume) => ({ nom: volume.nom, id: volume.id, niveau, volume }))
                    : []),
            ])
            .flat();
    }

    cancel() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close(this.selection);
    }
}

"use strict";
import {cn_element} from "../model/cn_element";
import {cn_box} from "../utils/cn_utilities";
import {cn_camera} from "./cn_camera";
import {cn_event_handler} from "./cn_event_handler";
import {cn_event_manager} from "./cn_event_manager";
import {cn_pastille} from "./cn_pastille";

//***********************************************************************************
//***********************************************************************************
//**** Edit box : a box woth various editing tools on a list of elements.
//**** contains a set of pastilles
//***********************************************************************************
//***********************************************************************************

export class cn_edit_box extends cn_event_handler {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_event_manager} parent
	 * @param {Array<cn_element>} elements
	 * @param {boolean} creation
	 * @param {boolean} allow_delete
	 */
	constructor(parent, elements, creation = false, allow_delete = true) {
		super(parent);
		this.elements = elements.concat([]);

		this.selection_padding = 80;

		this._pastilles = [];

		this._siblings_pastille = null;
		this._lock_pastille = null;
		this._siblings_key = "";
		this._mouseover_pastille = null;
		this._screen_box = new cn_box();

		if (allow_delete && elements.some(elt => elt.removable))
		{
			const delete_pastille = new cn_pastille([0, 0], "delete_warn.svg");
			this._pastilles.push(delete_pastille);
			delete_pastille.svg_class = "pastille_background white";
			delete_pastille.clicked = function() {
				parent.call("delete_elements",elements);
			};
			delete_pastille.title = "Supprimer la sélection";
		}
	}

	add_pastille(pastille) {
		this._pastilles.push(pastille);
	}

	/**
	 * Add a sibling pastille = a pastille to select all elements of the same type.
	 * @param {string} key
	 */
	add_select_siblings_pastille(key) {
		if (key == "") key = "class_name";
		const obj = this;
		const element_type = this.elements[0][key];
		this._siblings_pastille = new cn_pastille([0,0],"cursor_default.svg");
		this._pastilles.push(this._siblings_pastille);
		this._siblings_pastille.svg_class = "pastille_background white";
		this._siblings_pastille.title = "Sélectionner les éléments similaires";
		this._siblings_pastille.clicked = function() {obj._parent.call("select_siblings",{element:obj.elements[0],key:key});};
		this._siblings_pastille.visible = !this.elements.some(e => e[key] != element_type);
		this._siblings_key = key;
	}

    /**
     * Add a information pastille = a pastille to display description of a type.
     * @param {string} key
     */
    add_information_pastille(key) {
        if (key == "") key = "class_name";
        const obj = this;
        const element_type = this.elements[0][key];
        this._siblings_pastille = new cn_pastille([0,0],"information.svg");
        this._pastilles.push(this._siblings_pastille);
        this._siblings_pastille.svg_class = "pastille_background white";
        this._siblings_pastille.title = "Détails du type";
        this._siblings_pastille.clicked = function() {obj._parent.call("show_type_information",element_type);};
        this._siblings_pastille.visible = !this.elements.some(e => e[key] != element_type);
        this._siblings_key = key;
    }

	/**
	 * Add a lock pastille = a pastille to lock / unlock elements
	 */
	add_lock_pastille(transaction_manager) {
		const obj = this;
		this._lock_pastille = new cn_pastille([0,0],"lock.svg");
		this._pastilles.push(this._lock_pastille);
		this._lock_pastille.svg_class = "pastille_background white";
		this._lock_pastille.title = "Verrouiller / déverrouiller";
		this._lock_pastille.clicked = function() {
			const new_lock =  !(obj._lock_pastille.label == "lock.svg");
			transaction_manager.push_transaction("Verrouillage d'éléments","");
			obj.elements.forEach(elt => {
				transaction_manager.push_item_set(elt,["locked"]);
				elt.locked = new_lock;
			});
		};
	}

	//***********************************************************************************
	/**
	 * Draws in svg
	 * @param {cn_camera} camera
	 * @returns
	 */
	draw(camera) {
		var html = "";

		//*** compute bounding box */
		const screen_bb = new cn_box();
		this.elements.forEach(elt => screen_bb.enlarge_box(elt.get_screen_bounding_box(camera)));
		if (!screen_bb.posmin) return html;
		screen_bb.enlarge_distance(this.selection_padding);

		html += `<rect class="edit_box_area" x="${screen_bb.posmin[0]}" y="${screen_bb.posmin[1]}" width="${screen_bb.size[0]}" height="${screen_bb.size[1]}" rx="15"/>`;

		//*** search for pastille list position */
		const margin = 10;

		//*** the siblings pastille is viible only if coherent element types */
		if (this._siblings_pastille)
		{
			const element_type = this.elements[0][this._siblings_key];
			this._siblings_pastille.visible = !this.elements.some(e => e[this._siblings_key] != element_type);
		}

		//*** Update lock pastille */
		if (this._lock_pastille)
		{
			const locked = this.elements[0].locked;
			this._lock_pastille.label = (locked)?"lock.svg":"unlock.svg";
			this._lock_pastille.incoherent = this.elements.some(elt => elt.locked != locked);
		}

		const nb_visible_pastilles = this._pastilles.filter(p => p.visible).length;

		const pastille_pos = [screen_bb.posmin[0],screen_bb.posmin[1] + screen_bb.size[1]];
		const pastille_size = [camera.thumb_size * nb_visible_pastilles + margin * (nb_visible_pastilles+1),camera.thumb_size + 2 * margin];
		if (pastille_pos[0] < camera.padding[3])
			pastille_pos[0] = camera.padding[3];
		if (pastille_pos[0] + pastille_size[0] > camera._width -  camera.padding[1])
			pastille_pos[0] = camera._width -  camera.padding[1] - pastille_size[0];

		if (pastille_pos[1] < camera.padding[0])
			pastille_pos[1] = camera.padding[0];
		if (pastille_pos[1] + pastille_size[1] > camera._height -  camera.padding[2])
			pastille_pos[1] = camera._height -  camera.padding[2] - pastille_size[1];
		const pastille_world = camera.screen_to_world(pastille_pos);

		this._screen_box = new cn_box();
		this._screen_box.posmin = pastille_pos;
		this._screen_box.size = pastille_size;

        if (this._pastilles.length) {
		    html += `<rect class="edit_box_background" x="${pastille_pos[0]}" y="${pastille_pos[1]}" width="${pastille_size[0]}" height="${pastille_size[1]}"/>`;
        }

		//*** Draw pastilles */
		var nb_p=0;
		for (var index=0;index<this._pastilles.length;index++)
		{
			if (!this._pastilles[index].visible) continue;
			this._pastilles[index].rectangle = [camera.thumb_size,camera.thumb_size];
			this._pastilles[index].position = pastille_world;
			this._pastilles[index].offset = [margin + camera.thumb_size/2 + camera.thumb_size * nb_p + margin * nb_p,margin + camera.thumb_size/2];
			html+= this._pastilles[index].draw(camera);
			nb_p++;
		}
		return html;
	}

	clear_move() {
		this._mouseover_pastille = null;
		this._pastilles.forEach(p => p.mouseover = false);
	}

	move(mouse_event) {
		this._mouseover_pastille = null;
		this._pastilles.forEach(p => {
			p.mouseover = p.visible && p.contains(mouse_event.mouse_world,mouse_event.camera);
			if (p.mouseover)
			{
				this._mouseover_pastille = p;
				mouse_event.title = p.title;
			}
		});
		if (this._mouseover_pastille != null) return true;
		return this._screen_box.contains_point(mouse_event.mouse_screen);
	}

	grab(mouse_event) {
		return this.move(mouse_event);
	}

	click(mouse_event) {
		if (!this._mouseover_pastille) return false;
		this._mouseover_pastille.clicked();
		return true;
	}

}


// Paramètre points de controle mesurage

import { StateChoiceBoxes } from '../../../../model/states.model';
import { Icon } from 'src/app/commons-lib';
import { Legend } from '../model/polluant.model';

/** Polluant */
export const PARAM_POLLUANT = 'polluant';

/** zones */
export const PARAM_ZONES = 'zones';

/** Type prestation polluant etude situation */
export const PARAM_TYPE_POLLUANT_ETUDE_SITUATION = [PARAM_POLLUANT, PARAM_ZONES];

// *** Vérification des documents ***
/** Ouverture d'un document */
export const PARAM_DOCUMENT_OUVERTURE = 'ouverture';

/** Exploitabilité d'un document */
export const PARAM_DOCUMENT_EXPLOITABLE = 'exploitable';

/** Couverture d'un document */
export const PARAM_DOCUMENT_COUVERTURE = 'couverture';

export enum DOMAINES_REGLEMENTAIRE {
    //Réglementaire ambiance code de la santé publique (CSP)
    CODE_SANTE_PUBLIQUE = 'CODE_SANTE_PUBLIQUE',

    //Réglementaire ambiance code du travail (SS3)
    CODE_TRAVAIL_AMBIANCE = 'CODE_TRAVAIL_AMBIANCE',

    //Réglementaire opérateur code du travail (SS4)
    CODE_TRAVAIL_OPERATEUR = 'CODE_TRAVAIL_OPERATEUR',

    //Domaine de la norme
    DOMAINE_NORME = 'DOMAINE_NORME',
}

// *** Type d'activités amiante ***
// SS3
export const SS3Option = new StateChoiceBoxes(
    'SS3',
    DOMAINES_REGLEMENTAIRE.CODE_TRAVAIL_AMBIANCE,
    new Icon('invert_colors')
);
// SS4
export const SS4Option = new StateChoiceBoxes(
    'SS4',
    DOMAINES_REGLEMENTAIRE.CODE_TRAVAIL_OPERATEUR,
    new Icon('invert_colors_off')
);
// SS4
export const CSPOption = new StateChoiceBoxes(
    'CSP',
    DOMAINES_REGLEMENTAIRE.CODE_SANTE_PUBLIQUE,
    new Icon('invert_colors_off')
);

export const DOMAINES_REGLEMENTAIRE_LIST = [
    { id: DOMAINES_REGLEMENTAIRE.CODE_SANTE_PUBLIQUE, label: 'Réglementaire ambiance code de la santé publique (CSP)' },
    { id: DOMAINES_REGLEMENTAIRE.CODE_TRAVAIL_AMBIANCE, label: 'Réglementaire ambiance code du travail (CT)' },
    { id: DOMAINES_REGLEMENTAIRE.CODE_TRAVAIL_OPERATEUR, label: 'Réglementaire opérateur code du travail (CT)' },
    { id: DOMAINES_REGLEMENTAIRE.DOMAINE_NORME, label: 'Domaine de la norme' },
];

// *** Mapping noms pour rapport ***
export const NAMES_MAPPER = {
    visite: [
        {
            valeur: 'ko',
            afficher: 'Non visité',
        },
        {
            valeur: 'warning',
            afficher: 'Partiellement visité',
        },
        {
            valeur: 'ok',
            afficher: 'Visité',
        },
    ],
};

// *** Forme pour une légende ***
//Rectangle
export const RECTANGLE = 'Rectangle';
//Cercle
export const CERCLE = 'Cercle';
//Point par point
export const POINT_PAR_POINT = 'Point par point';

// *** Type de bordure pour une légende ***
// Pointillé
export const POINTILLE = { name: 'Pointillé', value: [3] };
// Continu
export const CONTINU = { name: 'Continu', value: [0] };

// *** Légendes par défaut ***
// Périmètre d'intervention
export const PERIMETRE_INTERVENTION = new Legend(
    "Périmètre d'intervention",
    RECTANGLE,
    POINTILLE,
    { r: 255, g: 255, b: 255, a: 0 },
    { r: 240, g: 54, b: 54, a: 1 }
);
// Zone de travaux
export const ZONE_TRAVAUX = new Legend(
    'Zone de travaux',
    CERCLE,
    CONTINU,
    { r: 255, g: 255, b: 255, a: 0 },
    { r: 255, g: 255, b: 0, a: 1 }
);
// UMD
export const UMD = new Legend(
    'UMD',
    RECTANGLE,
    CONTINU,
    { r: 255, g: 255, b: 255, a: 0 },
    { r: 240, g: 54, b: 54, a: 1 }
);

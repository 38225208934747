import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Commande } from '../../../../model/commande.model';
import { CommandeService } from '../../../../services/commande.service';
import { Router } from '@angular/router';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { PageRequest, PaginatedDataSource } from '../../../../shared/paging/page';
import { SessionStoragePaginationUtils } from '../../../../utils/session-storage-pagination.utils';
import { BehaviorSubject } from 'rxjs';
import { CommandeApiService } from '../../../../services/commande-api.service';
import {
    DEBOUNCE_TIME_VALUE,
    INITIAL_PAGE_SIZE,
    SESSION_STORAGE_KEY_COMMANDE,
} from 'src/app/shared/constants/admin.constants';
import { URL_GESTION_COMMANDES_CONSULTER, URL_GESTION_COMMANDES_EDIT } from 'src/app/shared/constants/url.constants';

export const DEFAULT_SORT = { direction: 'asc', active: 'numeroCommande' };

@Component({
    selector: 'app-tableau-commande',
    templateUrl: './tableau-commande.component.html',
    styleUrls: ['./tableau-commande.component.scss'],
})
export class TableauCommandeComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input()
    initialPageSize = INITIAL_PAGE_SIZE;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns: string[] = [
        'numeroCommande',
        'contact.nom',
        'bien.name',
        'adresse.voie',
        'dateCreation',
        'actions',
    ];

    dataSource: PaginatedDataSource<Commande>;
    sort: Sort;
    searchValue = SessionStoragePaginationUtils.getInitialReseach(SESSION_STORAGE_KEY_COMMANDE);
    private search = new BehaviorSubject(this.searchValue);

    constructor(
        private readonly commandeService: CommandeService,
        private readonly router: Router,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly commandeApiService: CommandeApiService
    ) {
        super();
        this.sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_COMMANDE, DEFAULT_SORT);
    }

    ngOnInit(): void {
        this.search
            .pipe(debounceTime(DEBOUNCE_TIME_VALUE), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.dataSource.fetch(0, this.dataSource.size);
            });
        this.dataSource = new PaginatedDataSource<Commande>(
            (pageRequest) => {
                return this.cnSpinnerService.withSpinner(this.getCommandes(this.search.getValue(), pageRequest));
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_COMMANDE, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_COMMANDE, this.initialPageSize)
        );
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    /**
     * Récupère les commandes à afficher dans le tableau
     * @param search
     * @param pageRequest
     */
    getCommandes(search: string, pageRequest: PageRequest<Commande>) {
        return this.commandeApiService
            .searchCommandes(search, pageRequest)
            .pipe(takeUntil(this.ngUnsubscribe))
            .pipe(
                map((response) => {
                    if (response && response.content) {
                        response.content = this.prepareCommandes(response.content);
                    }
                    return response;
                })
            );
    }

    /**
     * Action déclenchée lors du click sur le bouton "Dupliquer"
     * @param commande
     */
    dupliquerCommande(commande: Commande) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_COMMANDES_EDIT, commande.id, 'duplicate']);
    }

    /**
     * Action déclenchée lors du click sur le bouton "Editer"
     * @param commande
     */
    editerCommande(commande: Commande) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_COMMANDES_EDIT, commande.id]);
    }

    /**
     * Action déclenchée lors du click sur le bouton "Consulter"
     * @param commande
     */
    consulter(commande: Commande) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_COMMANDES_CONSULTER, commande.id]);
    }

    /**
     * Action déclenchée lors de la saisie d'un caractère dans la barre de recherche
     * @param $event
     */
    onKeyupSearchCommandes($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
    }

    /**
     * Action déclenchée lors d'un tri dans le tableau
     * @param $event
     */
    sortCommandes($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
        this.setSessionStorageItem();
    }

    /**
     * Set les valeurs du tableau dans le session storage (pagination, recherche, tri ..)
     * @private
     */
    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(
            SESSION_STORAGE_KEY_COMMANDE,
            this.dataSource.size,
            this.search.getValue(),
            this.sort
        );
    }

    /**
     * Prépare les données des commandes pour l'affichage dans le tableau.
     * @param commandes
     * @private
     */
    private prepareCommandes(commandes: Commande[]): Commande[] {
        // Pas affiché mais utilisé pour le filtre uniquement
        return commandes.map((commande) => {
            commande.contactString = commande.contact
                ? commande.contact.nom +
                  ' ' +
                  (commande.contact.prenom
                      ? commande.contact.prenom
                      : commande.contact.siret
                      ? commande.contact.siret
                      : '')
                : '';

            return commande;
        });
    }
}

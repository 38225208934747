"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Transaction data
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Transaction manager
//***********************************************************************************
import {cn_transaction, cn_transaction_step} from "./cn_transactions";
import {cn_event_manager} from "../svg/cn_event_manager";

export var CN_CURRENT_DATE = 0;

export class cn_transaction_manager extends cn_event_manager {

    //*******************************************************************************
    /** Constructor
     */
    constructor() {
        super();
        this._current_transaction = null;
        this._transactions = [];
        this._date = 0;
        this._events = {};
        this._transaction_context = null;
        this._current_transaction_context = null;
    }

    //*******************************************************
    /**
     * Sets transaction context (context for undo redo. Can be anything)
     * @param {any} ctx
     */
    set_transaction_context(ctx) {
        this._transaction_context = ctx;
    }

    //*******************************************************
    /**
     * Returns transaction context (context for undo redo. Can be anything)
     * @returns {any}
     */
    get_transaction_context() {
        return this._current_transaction_context;
    }

    //*******************************************************************************
    /** pushes one transaction.
     * Transaction name can be used to showthistory on GUI.
     * If transaction code is empty, will always actually push a new transaction.
     * Otherwise, a new transaction is actually pushed only if last transaction code was different.
     * @param {string} transaction_name Name displayed on GUI
     * @param {string} transaction_code Used to merge transaction if they have same name & code
     * @param {function} callback
     */
    push_transaction(transaction_name, transaction_code = "", callback = null) {
        this.clear_future_transactions();

        CN_CURRENT_DATE++;
        this._date = CN_CURRENT_DATE;
        
        //*** maybe just the same transaction
        if (this._current_transaction && transaction_code != "" && this._current_transaction.transaction_code == transaction_code)
            return;

        //*** terminate current transaction
        if (this._current_transaction == null || this._current_transaction.steps.length > 0) {
            this._current_transaction = new cn_transaction();
            this._current_transaction._transaction_context = this._transaction_context;
            this._current_transaction_context = this._transaction_context;
            this._transactions.push(this._current_transaction);
        }

        this._current_transaction.transaction_name = transaction_name;
        this._current_transaction.transaction_code = transaction_code;
        if (callback && this._current_transaction.callback == null)
            this._current_transaction.callback = callback;
        this.call("change");
    }

    //*******************************************************************************
    /** pushes undo for an item change of one param. Must be called BEFORE actual change.
     *
     * @param {object} item
     * @param {string | string[]} param_name Name of the updated field, one single param or one list of params
     * @param {function} callback
     */
    push_item_set(item, param_name, callback = null) {
        this.clear_future_transactions();
        if (this._current_transaction == null) return;
        if (typeof (param_name) == 'string')
            this._current_transaction.steps.push(new cn_transaction_step(item, [param_name], callback,this._date));
        else if (typeof (param_name) == 'object')
            this._current_transaction.steps.push(new cn_transaction_step(item, param_name, callback,this._date));
    }

    //*******************************************************************************
    /** remove all upcoming transactions (if there was an undo)
     */
    clear_future_transactions() {
        var index = 1 + this._transactions.indexOf(this._current_transaction);
        if (index == 0) return;
        this._transactions.splice(index);
    }

    //*******************************************************************************
    /** Performs undo.
     * @param {number} nbsteps
     * @returns {number} returns number of steps actually undone
     */
    perform_undo(nbsteps = 1) {
        var done_steps = 0;
        for (var k = 0; k < nbsteps; k++) {
            if (this._current_transaction == null) break;

            this._current_transaction_context = this._current_transaction._transaction_context;
            CN_CURRENT_DATE++;
            this._date = CN_CURRENT_DATE;
            for (var n = this._current_transaction.steps.length - 1; n >= 0; n--)
                this._current_transaction.steps[n].perform(this._date);
            if (this._current_transaction.callback) this._current_transaction.callback();
            var index = this._transactions.indexOf(this._current_transaction);
            if (index <= 0)
                this._current_transaction = null;
            else
                this._current_transaction = this._transactions[index - 1];

            done_steps++;
        }
        if (done_steps > 0) this.call("change");
        return done_steps;
    }

    //*******************************************************************************
    /** Performs redo.
     * @param {number} nbsteps
     * @returns {number} returns number of steps actually redone
     */
    perform_redo(nbsteps = 1) {
        var done_steps = 0;
        for (var k = 0; k < nbsteps; k++) {
            var index = 1 + this._transactions.indexOf(this._current_transaction);
            if (index >= this._transactions.length) break;
            CN_CURRENT_DATE++;
            this._date = CN_CURRENT_DATE;
            this._current_transaction = this._transactions[index];
            this._current_transaction_context = this._current_transaction._transaction_context;

            for (var n = 0; n < this._current_transaction.steps.length; n++)
                this._current_transaction.steps[n].perform(this._date);
            if (this._current_transaction.callback) this._current_transaction.callback();

            done_steps++;
        }
        if (done_steps > 0) this.call("change");
        return done_steps;
    }

    //*******************************************************************************
    /** returns past history transactions : eldest first.
     * Returns the list of past (including current) transaction names.
     * @returns {string[]}
     */
    get_past_history() {
        var past_history = [];
        var index = this._transactions.indexOf(this._current_transaction);
        for (var n = 0; n <= index; n++)
            past_history.push(this._transactions[n].transaction_name);
        return past_history;
    }

    //*******************************************************************************
    /** returns future history transactions : next first.
     * Returns the list of future (NOT including current) transaction names.
     * @returns {string[]}
     */
    get_future_history() {
        var future_history = [];
        var index = this._transactions.indexOf(this._current_transaction);
        for (var n = index + 1; n < this._transactions.length; n++)
            future_history.push(this._transactions[n].transaction_name);
        return future_history;
    }

    //*******************************************************************************
    /** removes all transactions
     */
    clear_transactions() {
        this._transactions = [];
        this._current_transaction = null;
        this.call("change");
    }

    //*******************************************************************************
    /**
     * Returns current transaction name
     * @returns {string}
     */
    get_current_transaction_name() {
        if (this._current_transaction) return this._current_transaction.transaction_name;
        return "";
    }

    //*******************************************************************************
    /**
     * sets current transaction name
     * @param {string} name
     */
    set_current_transaction_name(name) {
        if (this._current_transaction) this._current_transaction.transaction_name = name;
    }

    //*******************************************************************************
    /**
     * Returns current transaction code
     * @returns {string}
     */
    get_current_transaction_code() {
        if (this._current_transaction) return this._current_transaction.transaction_code;
        return "";
    }

    //*******************************************************************************
    /**
     * sets current transaction code
     * @param {string} code
     */
    set_current_transaction_code(code) {
        if (this._current_transaction) this._current_transaction.transaction_code = code;
    }

    //*******************************************************************************
    /**
     * sets current transaction callback
     * @param {function} callback
     */
    set_current_transaction_callback(callback) {
        if (this._current_transaction) this._current_transaction.callback = callback;
    }

    //*******************************************************************************
    /**
     * Merges current transaction with previous one
     * @returns {boolean} returns 'true' if merge was done.
     */
    merge_current_transaction() {
        if (this._transactions.length < 2) return false;
        var t1 = this._transactions[this._transactions.length - 2];
        t1.steps = t1.steps.concat(this._current_transaction.steps);
        this._current_transaction = t1;
        this._transactions.splice(this._transactions.length - 1, 1);
        this.call("change");
        return true;
    }

}

import { Component, Input, OnInit } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { MatDialog } from '@angular/material/dialog';
import { Commentaire } from '../../../../../../model/commentaire.model';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { MesurageReportData, SubBlockMesurage } from '../../../model/mesurage.model';
import { TypePrestation } from '../../../../../../model/type-prestation.model';
import { BaseComponent } from 'src/app/commons-lib';
import { ReportCommentsListModalComponent } from 'src/app/modules/diagnostics-shared/rapport/report-comments-list-modal/report-comments-list-modal.component';
import { Intervention } from 'src/app/model/intervention.model';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-liste-volumes-visites-block',
    templateUrl: './liste-volumes-visites-block.component.html',
    styleUrls: ['./liste-volumes-visites-block.component.scss'],
})
export class ListeVolumesVisitesBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    disclaimer: string;
    optionPlan = false;
    volumesNonVisites: SubBlockMesurage;
    volumesVisites: SubBlockMesurage;
    diagnosticData: MesurageReportData;
    readonlyMode = false;

    exclusionVolumesNonVisites: string[] = [];
    exclusionVolumesVisites: string[] = [];

    private diagnostic: Diagnostic;
    private typePrestation: TypePrestation;

    constructor(private matDialog: MatDialog, private diagnosticService: DiagnosticService) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                this.diagnostic = diag;
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
                this.typePrestation = this.diagnostic.typePrestation;
                if (this.interReportData) {
                    this.diagnosticData = this.interReportData.diagnostics.find(
                        (dia) => dia.typePrestation === this.typePrestation
                    ) as MesurageReportData;
                    if (this.diagnosticData) {
                        this.optionPlan = this.diagnosticData.optionPlan;
                        this.volumesNonVisites = this.diagnosticData.volumesNonVisites;
                        this.volumesVisites = this.diagnosticData.volumesVisites;
                    }
                }
                if (this.data) {
                    if (this.data.values['disclaimer']) {
                        this.disclaimer = this.data.values['disclaimer'];
                    }
                }
                this.prepareExclusionLists();
            });
    }

    editDisplayedComments(commentaires: Commentaire[], code: string) {
        return this.matDialog
            .open(ReportCommentsListModalComponent, {
                data: {
                    comments: commentaires,
                    code: code,
                    hiddenComments: this.diagnostic.hiddenComments,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.diagnostic.hiddenComments = result.hiddenComments;
                    this.prepareExclusionLists();
                }
            });
    }

    private prepareExclusionLists() {
        this.exclusionVolumesNonVisites = this.diagnostic.hiddenComments['VOLUMES_NON_VISITES']
            ? this.diagnostic.hiddenComments['VOLUMES_NON_VISITES']
            : [];
        this.exclusionVolumesVisites = this.diagnostic.hiddenComments['VOLUMES_VISITES']
            ? this.diagnostic.hiddenComments['VOLUMES_VISITES']
            : [];
    }
}

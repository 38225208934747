<h1 mat-dialog-title class="primary m-0">
    <span *ngIf="newDocument">Ajouter un document</span>
    <span *ngIf="!newDocument">Modifier un document</span>
</h1>

<div class="document d-flex row p-0" mat-dialog-content [formGroup]="formDoc">
    <!--------------- Modification du document ----------->
    <div [ngClass]="!editFichier ? 'col-12' : 'col-6 border-left-side-panel'" class="py-4">
        <h2>Document</h2>
        <div class="pl-3">
            <!----- Prestations associées ------>
            <label *ngIf="!typePrestationReadonly" class="primary"
                >Lier à la prestation <span class="required"></span
            ></label>
            <app-custom-select
                *ngIf="!typePrestationReadonly"
                class="w-75"
                [readonly]="documentToEdit.requiredForIntervention"
                formControlName="typePrestation"
                [selectControl]="formDoc.get('typePrestation')"
                [matFormFieldClass]="'w-100'"
                [choices]="typesPrestation"
                [addline]="false"
                multiple="true"
                [pathValueDisplay]="displayTypePrestation"
                (selectedValueChange)="typePrestationChanged()"
            >
            </app-custom-select>
            <!----- Type de document ------>
            <label class="primary">Type de document <span class="required"></span></label>
            <app-custom-select
                class="w-75"
                [readonly]="documentToEdit.requiredForIntervention"
                formControlName="typeDocument"
                [selectControl]="formDoc.get('typeDocument')"
                [matFormFieldClass]="'w-100'"
                [choices]="typesDocFiltered"
                [addline]="false"
                [pathValueDisplay]="['nom']"
            >
            </app-custom-select>
            <!----- Nom du document ------>
            <label class="primary">Nom du document <span class="required"></span></label>
            <mat-form-field class="w-100 form-custom" appearance="outline">
                <input matInput placeholder="Nom du document" formControlName="nom" />
                <mat-error>
                    <app-form-control-error-message
                        [control]="formDoc.get('nom')"
                        [textConfig]="{
                            erreurNomDoc: 'Un autre document possède déjà le même nom dans cette intervention'
                        }"
                    ></app-form-control-error-message>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <!--------------- Modification du fichier (Affiché uniquement si on est en mode ajout de document) ----------->
    <div *ngIf="editFichier" class="col-6 py-4">
        <app-edit-fichier
            [formFichier]="formDoc.get('formFichier')"
            [documentToEdit]="documentToEdit"
            [idIntervention]="intervention?.id"
            [contactsIntervention]="intervention?.contacts"
            (fileUploaderOutputChanged)="onFileUploaderOutputChanged($event)"
        ></app-edit-fichier>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end m-0">
    <button mat-button color="primary" (click)="onClickCancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" (click)="onClickValidate()" [disabled]="!formDoc.valid">
        <lib-icon>check</lib-icon>
        <span>{{ 'Valider' | uppercase }}</span>
    </button>
</div>

"use strict";
//***********************************************************************************
//***********************************************************************************
import {cnx_clone, cn_add, cn_cart, cn_clone, cn_mul, cn_size, cn_sub} from "../utils/cn_utilities";
import {cn_handler_object} from "./cn_handler_object";
import {cn_camera} from "./cn_camera";
import {cn_scene} from "../model/cn_scene";
import {cn_storey} from "../model/cn_storey";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_event_handler} from "./cn_event_handler";
import { cn_wall } from "../model/cn_wall";

/**
 * A handler to drag an object along the walls
 * @class cn_handler_wall_drag
 */

export class cn_handler_wall_drag extends cn_event_handler {

	//*****************************************************************
	/**
	 * Constructor
	 * @param {cn_handler_object} object
	 * @param {cn_scene} scene
	 * @param {cn_storey} storey
	 */
	constructor(object, scene, storey=null, creation_mode = false) {
		super();
		this.object = object;
		this.scene = scene;
		this.storey = storey;

		this._mouseover = false;
		this._space = null;
		this._best_position = null;

		this.radius = 10;
		this.creation_mode = creation_mode;
		this.altitude = 0;
	}

	//*****************************************************************
	/**
	 * Draws the handler specific elements
	 * @param {cn_camera} camera
	 * @returns {string} returns a html string
	 */
	draw(camera) {
		var html = "";

		var p = camera.world_to_screen(cnx_clone(this.object.get_world_anchor(),this.altitude));
		html += "<circle cx='" + p[0] + "' cy='" + p[1] + "' r='5' style='fill:blue'/>";
		
		return html;
	}

	//*****************************************************************
	/**
	 * Clears move data
	 */
	clear_move() {
		this._mouseover = false;
	}

	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} mouse_event 
	 * @returns  {boolean}
	 */
	 move(mouse_event) {
		if (this.creation_mode) return true;

		this._mouseover = false;

		if (mouse_event.camera.is_3d())
		{
			this._mouseover = (mouse_event.impact && mouse_event.impact.storey_element && mouse_event.impact.storey_element.element == this.object.element && mouse_event.impact.storey_element.storey == this.storey);
			return this._mouseover;
		}

		//** is mouse over center ?
		console.log("Mouse ",this.object.center,cn_clone(mouse_event.mouse_world));
		if (this.object.contains(mouse_event.mouse_world))
		{
			this._mouseover = true;
			return true;
		}

		var d = cn_sub(mouse_event.mouse_world,this.object.center);
		if (cn_size(d) < this.radius * mouse_event.camera.screen_to_world_scale)
		{
			this._mouseover = true;
			return true;
		}

		return false;
	}

	/**
	 * Manage a grab. To return 'true' if grab is to be managed.
	 * @param {cn_mouse_event} mouse_event 
	 * @returns  {boolean}
	 */
	 grab(mouse_event) {
		if (this.creation_mode) return true;

		this.move(mouse_event);
		if (!this._mouseover) return false;
		return true;
	}

	/**
	 * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
	 * @param {cn_mouse_event} mouse_event 
	 * @returns  {boolean}
	 */
	 drag(mouse_event) {
		if (this.creation_mode) return true;
		return this.check_place(mouse_event);
	}

	//*****************************************************************
	/**
	 * Check a given place in the scene
	 * @param {cn_mouse_event} mouse_event 
	 * @returns {boolean} true if the event was caught
	 */
	check_place(mouse_event) {
		this._best_position = null;

		//*** Special case for 3D */
		if (mouse_event.camera.is_3d())
		{
			if (!mouse_event.impact_behind || 
				!mouse_event.impact_behind.storey_element || 
				mouse_event.impact_behind.storey_element.storey != this.storey || 
				mouse_event.impact_behind.storey_element.element.constructor != cn_wall || 
				Math.abs(mouse_event.impact_behind.normal[2]) > 0.1)
				return false;
			this.object.place_anchor(mouse_event.impact_behind.position,cn_mul(mouse_event.impact_behind.normal,1));
			return true;
		}

		this._space = this.scene.find_space(mouse_event.mouse_world,false);
		if (!this._space) return false;

		var raytracing_range = 4 * mouse_event.camera.snap_world_distance;
		var origin = cn_add(this.object.get_world_anchor(),cn_sub(mouse_event.mouse_world,this.object.center));

		var best_distance = 100;
		var best_normal = null;
		var best_position = null;
		for (var theta = 0; theta < 360; theta +=15)
		{
			var direction = cn_cart([1,theta * Math.PI / 180]);
			var res = this._space.raytrace(origin, direction,best_distance,true);
			if (!res) continue;
			best_distance = res.distance;
			best_normal = res.normal;
			best_position = res.point;
		}
		if (best_position == null) return false;
		this._best_position = best_position;

		this.object.place_anchor(best_position,cn_mul(best_normal,-1));

		return true;
	}

	/**
	 * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
	 * @param {cn_mouse_event} mouse_event 
	 * @returns {boolean}
	 */
	 drop(mouse_event) {
		this._grabbed = false;
		return true;
	}
}


import { Component, HostListener } from '@angular/core';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { CheckpointHelpModalComponent } from '../../checkpoint-help-modal/checkpoint-help-modal.component';
import { ElectriciteService } from '../../../services/electricite.service';
import { combineLatest } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import {
    ConstatationDiverse,
    Photo,
    PointControle,
    PointControleAide,
    ValeurCheckpoint,
} from '../../../model/electricite.model';
import { EtatValidation } from '../../../../../../model/etat-progression.model';
import { ActivatedRoute } from '@angular/router';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { nonOption, nonVerifiabledOption, ouiOption, sansObjetOption } from '../../../shared/electricite.constants';
import { InterventionService } from '../../../../../../services/intervention.service';
import { Intervention, RelationInterventionBien } from '../../../../../../model/intervention.model';
import { apartmentState, houseState } from '../../../../../../shared/constants/states.constants';
import { TypeCommentaire } from '../../../../../../model/type-commentaire.model';

@Component({
    selector: 'app-installation-electrique-eenvironnement',
    templateUrl: './installation-electrique-environnement.component.html',
    styleUrls: ['./installation-electrique-environnement.component.scss'],
})
export class InstallationElectriqueEnvironnementComponent {
    readonly COMMENT_TYPES: TypeCommentaire[] = ['NOTE_PERSONNELLE', 'REMARQUE'];

    // La constatation diverse n'est pas cochée automatiquement en fonction de l'initialisation
    readonly CACD1: string = 'CACD1';
    // La constatation diverse est cochée et grisée uniquement si le type logement est un appartement
    readonly CACD2: string = 'CACD2';
    // La constatation diverse est cochée et grisée uniquement si le type logement est une maison
    readonly CACD3: string = 'CACD3';

    private _isMaison: boolean;
    private _isAppartement: boolean;
    private _pointsControlesAides: PointControleAide[];
    private _photos: Photo[];

    constatationsDiverses: {
        niveau: number;
        hide: boolean;
        disabled: boolean;
        isLast: boolean;
        constatationDiverse: ConstatationDiverse;
    }[] = [];
    diagnostic: Diagnostic;

    constructor(
        private diagnosticService: DiagnosticService,
        private readonly matDialog: MatDialog,
        private electriciteService: ElectriciteService,
        private etatProgressionService: EtatProgressionService,
        private interventionService: InterventionService,
        private route: ActivatedRoute
    ) {
        combineLatest([
            this.electriciteService.electriciteConfig$,
            this.diagnosticService.getCurrentDiagnostic(),
            this.interventionService.getCurrentIntervention(),
        ])
            .pipe(first())
            .subscribe(([config, diagnostic, intervention]) => {
                const relationInterventionBien: RelationInterventionBien =
                    Intervention.getRelationInterventionBienPrincipal(intervention);
                this._isMaison = relationInterventionBien.bien.idTypeBien == houseState.value;
                this._isAppartement = relationInterventionBien.bien.idTypeBien == apartmentState.value;
                this.diagnostic = diagnostic;
                this._pointsControlesAides = config.pointsControlesAides;
                this._photos = config.photos;
                // ACD2 : Uniquement pour appartement
                // ACD3 : Uniquement pour maison
                const constationsDiverses = config.constationsDiverses
                    .filter((cd) =>
                        this._isMaison
                            ? cd.idAccessibiliteConsatatationDiverse != 'ACD3'
                            : this._isAppartement
                            ? cd.idAccessibiliteConsatatationDiverse != 'ACD2'
                            : true
                    )
                    .sort((a, b) => a.reference.localeCompare(b.reference));
                const titre = constationsDiverses.find(
                    (a) => a.diminutif == 'Installation électrique et/ou son environnement'
                );
                this.constatationsDiverses.push({
                    niveau: 0,
                    hide: true,
                    disabled: false,
                    isLast: false,
                    constatationDiverse: titre,
                });
                this.recusive(constationsDiverses, 1, titre.idInterne);
                for (let i = 0; i < this.constatationsDiverses.length; i++) {
                    if (this.constatationsDiverses[i + 1]) {
                        if (this.constatationsDiverses[i].niveau > this.constatationsDiverses[i + 1].niveau) {
                            this.setLast(i);
                        } else if (this.constatationsDiverses[i].niveau == this.constatationsDiverses[i + 1].niveau) {
                            this.constatationsDiverses[i].isLast = true;
                        }
                    } else {
                        this.setLast(i);
                    }
                }
                const pointsControles = config.pointsControles.filter(
                    (pc) =>
                        (pc.idConstatationDiverseNon ||
                            pc.idConstatationDiverseNonVerifiable ||
                            pc.idConstatationDiverseOui ||
                            pc.idConstatationDiverseSansObjet) &&
                        pc.idChapitre
                );
                this.gererAffichageConstatationsDiverses(pointsControles);
            });
    }

    /**
     * Gestion de l'affichage des constatations diverses en fonction des réponse aux checkpoint
     * @param pointsControles
     */
    private gererAffichageConstatationsDiverses(pointsControles: PointControle[]) {
        for (const cp of pointsControles) {
            if (this.diagnostic.pointsDeControleBiens[0].valeursParametres[cp.idInterne]) {
                const valeur = this.diagnostic.pointsDeControleBiens[0].valeursParametres[cp.idInterne].valeur;
                if (valeur == ouiOption.value) {
                    this.setHide(cp.idConstatationDiverseOui);
                } else if (valeur == nonOption.value) {
                    this.setHide(cp.idConstatationDiverseNon);
                } else if (valeur == nonVerifiabledOption.value) {
                    this.setHide(cp.idConstatationDiverseNonVerifiable);
                } else if (valeur == sansObjetOption.value) {
                    this.setHide(cp.idConstatationDiverseSansObjet);
                }
            }
        }
    }

    private setHide(idInterne: string) {
        if (idInterne && idInterne.length > 0) {
            const constatationDiverse = this.constatationsDiverses.find(
                (cd) => cd.constatationDiverse.idInterne == idInterne
            );
            if (!constatationDiverse) {
                return;
            }
            const index = this.constatationsDiverses.indexOf(constatationDiverse);
            for (let i = index; i >= 0; i--) {
                this.constatationsDiverses[i].hide = false;
                if (this.constatationsDiverses[i].niveau === 0) {
                    return;
                }
            }
        }
    }

    /**
     * isLast permet l'affichage app-state-input
     * @param {number} i
     */
    setLast(i: number) {
        this.constatationsDiverses[i].isLast = true;
        for (let j = i - 1; j > 0; j--) {
            if (this.constatationsDiverses[j].niveau != this.constatationsDiverses[i].niveau) {
                break;
            } else if (this.constatationsDiverses[j].niveau == this.constatationsDiverses[i].niveau) {
                this.constatationsDiverses[j].isLast = true;
            }
        }
    }

    openHelp(checkpoint: PointControle) {
        const contents = [];
        this._pointsControlesAides
            .filter((pca) => pca.idPointControle == checkpoint.idInterne)
            .sort((a, b) => a.ordre - b.ordre)
            .forEach((pca) => {
                const photo = this._photos.find((ph) => ph.idInterne == pca.idPhoto);
                contents.push({ innerHTML: pca.description, photo: photo });
            });
        const dialogRef = this.matDialog.open(CheckpointHelpModalComponent);
        dialogRef.componentInstance.title = checkpoint.description;
        dialogRef.componentInstance.contents = contents;
    }

    private recusive(constationsDiverses, niveau, idInterne) {
        let ssTitre = constationsDiverses.filter((a) => a.idConstatationDiverseParent == idInterne);
        if (ssTitre.length > 0) {
            for (const cd of ssTitre) {
                const disabled = this.setDisabled(cd);
                if (disabled) {
                    this.onValeurChange(true, cd.idInterne);
                }
                this.constatationsDiverses.push({
                    niveau: niveau,
                    hide: true,
                    disabled: disabled,
                    isLast: false,
                    constatationDiverse: cd,
                });
                ssTitre = [...ssTitre, ...this.recusive(constationsDiverses, niveau + 1, cd.idInterne)];
            }
        }
        return ssTitre;
    }

    /**
     * La constatation diverse est cochée et grisée uniquement si le type logement est un appartement
     * La constatation diverse est cochée et grisée uniquement si le type logement est une maison
     * @param {ConstatationDiverse} cd
     * @returns {boolean}
     */
    private setDisabled(cd: ConstatationDiverse): boolean {
        const idInterne = cd.idCochageAutomatiqueConstatationDiverse;
        if (this._isAppartement && idInterne == this.CACD2) {
            return true;
        } else if (this._isMaison && idInterne == this.CACD3) {
            return true;
        }
        return false;
    }

    /**
     * Event au clic sur un point de contrôle
     * @param {string} value
     * @param {string} idInterne
     */
    onValeurChange(value: boolean, idInterne: string) {
        if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInterne]) {
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInterne] = new ValeurCheckpoint();
        }
        this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInterne].valeur = value;
    }
}

import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { Injectable, Type } from '@angular/core';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Mesurage, MesurageReportData, RowBienMesurage } from '../model/mesurage.model';
import { Intervention } from '../../../../model/intervention.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { ListeVolumesVisitesBlockComponent } from '../report/blocks/liste-volumes-visites-block/liste-volumes-visites-block.component';
import { MesurageService } from './mesurage.service';
import { PARAM_VOLUME_VISITE } from '../../../../shared/constants/cndiag.constants';
import { ListeMesuresBlockComponent } from '../report/blocks/liste-mesures-block/liste-mesures-block.component';
import { PARAM_AUTRE, PARAM_UTILE_BRUT, PARAM_UTILE_NET } from '../shared/mesurage.constants';
import { CommentairesBlockComponent } from '../report/blocks/commentaires-block/commentaires-block.component';
import { CommentUtils } from '../../../../utils/comment.utils';
import { BonCommandeAnalyseAdmin } from 'src/app/model/bon-commande.model';
import { Observable, of } from 'rxjs';
import { LegendScreenshot } from 'src/app/model/screenshot-svg.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';

export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    ListeVolumesVisitesBlockComponent: ListeVolumesVisitesBlockComponent,
    ListeMesuresBlockComponent: ListeMesuresBlockComponent,
    CommentairesBlockComponent: CommentairesBlockComponent,
};

@Injectable({
    providedIn: 'root',
})
export class TypeMesurageUtileService implements ITypePrestationInterface {
    // PARAM volume
    private typePrestation: TypePrestation = 'MESURAGE_UTILE';

    constructor(
        private readonly mesurageService: MesurageService,
        private readonly pointDeControleService: PointDeControleService
    ) {}

    generateLegendForScreenshot(diagnostic: Diagnostic): LegendScreenshot[] {
        return [];
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    /**
     * Pas d'implémentation car pas de bon de commande en Assainissement
     * @param intervention
     * @param diagnostic
     */
    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        throw new Error('Method not implemented.');
    }

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new Mesurage());
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return [];
    }

    /**
     * Préparation des commentaires spécifiques au mesurage utile selon le type de réponse des formulaires
     * @param diagnostic
     * @param commentairesGeneraux
     * @param commentaires
     */
    prepareSpecificComments(
        diagnostic: Diagnostic,
        commentairesGeneraux: Commentaire[],
        commentaires: CommentairePredefini[]
    ): Commentaire[] {
        const currentContenuDiagnostic = diagnostic.contenuDiagnostic as Mesurage;
        // TODO si conditions...
        return commentaires.map((it) => CommentUtils.commentairePredefiniToCommentaireMapper(it));
    }

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {}

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    getDiagnosticReportData(
        intervention: Intervention,
        diagnostic: Diagnostic,
        [],
        optionPlan: boolean
    ): IDiagReportData {
        const diagReportData = new MesurageReportData();
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        diagReportData.optionPlan = optionPlan;
        if (diagnostic.recommandationsFinales.length) {
            diagReportData.recommandations = diagnostic.recommandationsFinales.map((it) => it.contenu);
        }
        if (diagnostic.constatationsFinales.length) {
            diagReportData.constatationsDiverses = diagnostic.constatationsFinales.map((it) => it.contenu);
        }
        // Screenshot
        diagReportData.screenshotsPlan = diagnostic.screenshotsPlan;
        this.mesurageService.prepareDiagnosticReportData(diagReportData, diagnostic, intervention);
        diagReportData.presenceVolumesNonVisites = !!diagReportData.volumesNonVisites.biens.flatMap((b) => b.niveaux)
            .length;

        return diagReportData;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {
        const diagReportData = diagnosticData as MesurageReportData;
        if (hiddenComments[diagReportData.volumesNonVisites.code]) {
            this.filterVolumeComments(
                diagReportData.volumesNonVisites.biens,
                hiddenComments[diagReportData.volumesNonVisites.code]
            );
        }
        if (hiddenComments[diagReportData.volumesVisites.code]) {
            this.filterVolumeComments(
                diagReportData.volumesVisites.biens,
                hiddenComments[diagReportData.volumesVisites.code]
            );
        }
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        // On ne tient pas compte des volumes cachés dans le mesurage
        const volumesNonCaches = diagnostic.pointsDeControleBiens
            .flatMap((bien) => bien.pointsDeControleNiveaux)
            .flatMap((niveau) => niveau.pointsDeControleVolumes)
            .filter((it) => !it.volumeCache);

        const totalMesure = volumesNonCaches.filter((volume) =>
            [undefined, 'ok'].includes(volume.valeursParametres[PARAM_VOLUME_VISITE])
        ).length;

        const completeMesure = volumesNonCaches.filter(
            (volume) =>
                MesurageService.isFilledAndPositive(volume.valeursParametres[PARAM_UTILE_BRUT]) &&
                MesurageService.isFilledAndPositive(volume.valeursParametres[PARAM_UTILE_NET]) &&
                MesurageService.isFilledAndPositive(volume.valeursParametres[PARAM_AUTRE]) &&
                volume.valeursParametres[PARAM_VOLUME_VISITE] === 'ok'
        ).length;

        const totalRoom = volumesNonCaches.length;
        const completeRoom = volumesNonCaches.filter(
            (v) =>
                v.valeursParametres[PARAM_VOLUME_VISITE] === 'ok' ||
                (['ko', 'warning'].includes(v.valeursParametres[PARAM_VOLUME_VISITE]) && v.justifie)
        ).length;

        return ((completeMesure + completeRoom) * 100) / (totalMesure + totalRoom);
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        return false;
    }

    private filterVolumeComments(biens: RowBienMesurage[], excluded: string[]) {
        biens.forEach((bien) => {
            bien.niveaux.forEach((niveau) => {
                niveau.volumes.forEach((volume) => {
                    volume.commentaires = volume.commentaires.filter((it) => !excluded.includes(it.id));
                });
            });
        });
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {
        const idBien = conf.bien?.bien?.id;
        const idNiveau = conf.bien?.niveau?.id;
        const showComments = false;
        const showZonePositive = !!conf.zone?.surface?.showSurfacesComptabilisees;
        const showZoneNegative = !!conf.zone?.surface?.showSurfacesDecomptees;

        this.mesurageService.populateStoreyWithMarkers(
            diagnostic.contenuDiagnostic as Mesurage,
            currentStorey,
            idBien,
            idNiveau,
            showComments,
            showZonePositive,
            showZoneNegative
        );
    }

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}

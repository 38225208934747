"use strict";

import {cn_wall} from "../model/cn_wall";
import {cn_pastille} from "./cn_pastille";
import {cn_camera} from "./cn_camera";
import {cn_add, cn_dot, cn_middle, cn_mul, cn_sub} from "../utils/cn_utilities";
import {cn_image_input} from "./cn_inputs";
import {cn_image_dir} from "../utils/image_dir";
import {cn_space} from '../model/cn_space';
import {cn_facade, cn_facing_trimming, CN_FACING_TRIMMING_PLACEMENT_CEILING, CN_FACING_TRIMMING_PLACEMENT_FLOOR, generate_pattern_for_facing} from "..";

//***********************************************************************************
//***********************************************************************************
//**** cn_pastille facing : a pastille to display a wall or space facing
//***********************************************************************************
//***********************************************************************************

export class cn_pastille_facing extends cn_pastille {
	/**
     * Constructor
     * @param { cn_wall | cn_space | cn_facing_trimming} element
     * @param { number } side
     */
	constructor(map, element, side = 0, distance = 30, exterior = false) {
		super()
		this.element = element;
		this.side = side;
		this.rectangle = [30,30];
		this.distance = distance;
		this._map = map;
		this.title = "Modifier le revêtement";
        this.exterior = element.scene && element.scene.storey.exterior;
        this.world_position = null;
	}

    /**
     * Rendering
     * @param {cn_camera} camera
     * @returns {string}
     */
	draw(camera) {
        let html = ''
        let texture = '';
        let color = '#FFFFFF';
        if (this.element.constructor === cn_wall) {
            this.position = cn_add(cn_middle(this.element.bounds.pmin, this.element.bounds.pmax), cn_mul(this.element.bounds.direction, 0.5 * this.element.bounds.length));
            let nor = cn_mul(this.element.bounds.normal, this.distance);
            nor[1] = -nor[1];
            this.offset = this.side === 1 ? nor : cn_mul(nor, -1);
            const facing = this.element.facings[this.side];
            if (facing) {
                texture = facing.texture;
                color = facing.color || '#FFFFFF';
            }
        } else if (this.element.constructor === cn_space) {
            if (!this.world_position) {
                this.position = cn_add(this.element.center, this.element.label_position);
                if (!this.exterior) {
                    this.offset = this.side === 0 ? [-50, 60] : [10, 60];
                    const hint_x_offset = this.offset[0] - 15;
                    const hint_y_offset = this.side === 0 ? this.offset[1] + 25 : this.offset[1] - 30;
                    const hint_pos = cn_add(camera.world_to_screen(this.position), [hint_x_offset, hint_y_offset])
                    html += `<rect x="${hint_pos[0]}" y="${hint_pos[1]}" width="30" height="5" fill="black"></rect>`
                }
            }
            const facing = this.element.facings[this.side];
            if (facing) {
                texture = facing.texture;
                color = facing.color || '#FFFFFF';
            }
        } else if (this.element.constructor === cn_facing_trimming) {
            const facing = this.element.facing;
            if (facing) {
                texture = facing.texture;
                color = facing.color || '#FFFFFF';
            }
        }
        if (this.world_position) {
            this.position = this.world_position;
            this.offset = [0, 0];
        }
        this.label = '';
        const p = cn_add(camera.world_to_screen(this.position),this.offset);
        const texture_pattern_and_style = generate_pattern_for_facing(texture, color,
            this.rectangle[0], this.rectangle[1], p[0] - this.rectangle[0]/2, p[1] - this.rectangle[1]/2, 'pastille');
        html += texture_pattern_and_style.pattern;
        this.add_attr = texture_pattern_and_style.style;
		html += super.draw(camera);
        return html;
	}

	/**
	 * Build callback event
	 */
	clicked() {
        let support = null;
        let input_title = null;
        let current_facing = null;
        let is_facade = false;
        if (this.element.constructor === cn_wall) {
            is_facade = this.element.is_facade(this.side);
            support = (this.element.is_outdoor(this.side)) ? 'facade' : 'wall';
            input_title = (this.element.is_outdoor(this.side)) ? 'extérieur' : 'intérieur'
            current_facing = this.element.facings[this.side];
        } else if (this.element.constructor === cn_space) {
            if (!this.exterior) {
                input_title = this.side === 0 ? 'de sol' : 'de plafond';
                support = this.side === 0 ? 'floor' : 'ceiling';
            } else {
                input_title = 'd\'extérieur';
                support = 'exterior';
            }
            current_facing = this.element.facings[this.side];
        } else if (this.element.constructor === cn_facing_trimming) {
            input_title = 'de découpe';
            current_facing = this.element.facing;
            if (this.element.placement == CN_FACING_TRIMMING_PLACEMENT_FLOOR) {
                if (this.element.scene.storey && this.element.scene.storey.exterior)
                    support = 'exterior';
                else
                    support = 'floor';
            }
            else if (this.element.placement == CN_FACING_TRIMMING_PLACEMENT_CEILING) {
                support = 'ceiling';
            } else if (!this.element.wall.spaces[this.element.wall_side].has_roof)
                support = 'facade';
            else
                support = 'wall';
        }
        const input = new cn_image_input("Veuillez choisir un revêtement " + input_title);
        const facings = this._map._building.facing_types.filter(facing => facing.support === support);
        facings.unshift(null);
        input.helper = `Il n'existe aucun revêtement ${input_title}. Veuillez en créer un depuis le menu Parois.`;
        input.choices = facings.map(f => { return { label: f ? f.name : 'Aucun', url: `${cn_image_dir()}texture_${f ? f.texture : ''}.jpg` , maskColor: f ? f.color : 'transparent' };});
        input.selection = [facings.findIndex(f => (current_facing && f && current_facing.ID === f.ID) || (!current_facing && !f))];
        if (this.element.constructor === cn_wall) {
            const wall = this.element;
            const is_outdoor = wall.is_outdoor(this.side);
            if (this.exterior)
            {
                input.options_labels = ['Uniquement à cette clôture', "Toutes les clôtures de ce type", 'Toutes les clôtures du site']
            }
            else if (is_facade) {
                input.options_labels = ['Uniquement à cette façade', "L'intégralité cette façade", 'Toutes les façades de ce niveau', 'Toutes les façades du bâtiment']
            } else {
                input.options_labels = ['Uniquement à ce mur', 'Toute la pièce']
            }
            input.options_selected = input.options_labels[0];
            input.callback = () => {
                    const value = input.selection[0];
                    const check_all_for_storey_or_room = (!this.exterior && input.options_selected === input.options_labels[(is_facade)?2:1]);
                    const check_all_for_building =  (!this.exterior && input.options_selected === input.options_labels[(is_facade)?3:2]);
                    const check_all_for_facade =  (!this.exterior && (is_facade && input.options_selected === input.options_labels[1]));
                    const check_all_for_fence_type =  (this.exterior && input.options_selected === input.options_labels[1]);
                    const check_all_for_fences =  (this.exterior && input.options_selected === input.options_labels[2]);
                    const new_facing = facings[value];
                    this._map._building.transaction_manager.push_transaction("Changement de revêtement de murs");
                    if (check_all_for_building)
                    {
                        this._map._building.storeys.filter(storey => storey.clone_of == null).forEach(storey => {
                            storey.scene.walls.forEach(wall => {
                                for (var side=0;side<2;side++)
                                {
                                    if (wall.is_facade(side) == is_facade && wall.is_outdoor(side) == is_outdoor) {
                                        this._map._building.transaction_manager.push_item_set(wall, "facings");
                                        wall.facings[side] = new_facing;
                                    }
                                }
                            });
                        });
                    }
                    if (check_all_for_storey_or_room) {
                        const scene = this.element.scene;
                        if (is_facade)
                        {
                            scene.walls.forEach(wall => {
                                for (var side=0;side<2;side++)
                                {
                                    if (wall.is_facade(side) && wall.is_outdoor(side) == is_outdoor) {
                                        this._map._building.transaction_manager.push_item_set(wall, "facings");
                                        wall.facings[side] = new_facing;
                                    }
                                }
                            });
                        }
                        else
                        {
                            const space = this.element['spaces'][this.side];
                            scene.walls.forEach(wall => {
                                for (var side=0;side<2;side++)
                                {
                                    if (wall.spaces[side] == space && wall.is_outdoor(side) == is_outdoor) {
                                        this._map._building.transaction_manager.push_item_set(wall, "facings");
                                        wall.facings[side] = new_facing;
                                    }
                                }
                            });
                        }
                    } else if (check_all_for_facade) {
                        const facades =  this._map._building.get_facades();
                        const facade = cn_facade.find_facade_by_wall(wall,this.side,facades);
                        if (facade)
                        {
                            for (var n=0;n<facade.walls.length;n++)
                            {
                                const lwall = facade.walls[n];
                                this._map._building.transaction_manager.push_item_set(lwall, "facings");
                                lwall.facings[facade.wall_sides[n]] = new_facing;
                            }
                        }
                    }
                    else if (check_all_for_fence_type) {
                        const scene = this.element.scene;
                        wall.scene.walls.filter(w => w.wall_type == wall.wall_type).forEach(w => {
                            this._map._building.transaction_manager.push_item_set(w, "facings");
                            for (var side=0;side<2;side++)
                                w.facings[side] = new_facing;
                        });
                    }
                    else if (check_all_for_fences) {
                        const scene = this.element.scene;
                        wall.scene.walls.filter(w => w.has_facings()).forEach(w => {
                            this._map._building.transaction_manager.push_item_set(w, "facings");
                            for (var side=0;side<2;side++)
                                w.facings[side] = new_facing;
                        });
                    }
                    else {
                        this._map._building.transaction_manager.push_item_set(this.element, "facings");
                        this.element['facings'][this.side] = new_facing;
                    }
                    this.call("change");
                    this._map.refresh();
                };
        } else if (this.element.constructor === cn_space) {
            input.callback = () => {
                const value = input.selection[0];
                const new_facing = facings[value];
                this._map._building.transaction_manager.push_transaction("Changement de revêtement " + input_title);
                this._map._building.transaction_manager.push_item_set(this.element, "facings");
                // @ts-ignore
                this.element.facings[this.side] = new_facing;
                this.call("change");
                this._map.refresh();
            }
        } else if (this.element.constructor === cn_facing_trimming) {
            input.callback = () => {
                const value = input.selection[0];
                const new_facing = facings[value];
                this._map._building.transaction_manager.push_transaction("Changement de revêtement " + input_title);
                this._map._building.transaction_manager.push_item_set(this.element, "facing");
                // @ts-ignore
                this.element.facing = new_facing;
                this.call("change");
                this._map.refresh();
            }
        }
        this._map.call("image_input", input);
	}
}

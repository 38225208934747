'use strict';

//***********************************************************************************
//***********************************************************************************
//**** cn_element_type_visitor
//***********************************************************************************
//***********************************************************************************

export class cn_element_type_visitor {

    visit_wall_type(cn_wall_type) {

    }

    visit_opening_type(cn_opening_type) {

    }

    visit_slab_type(cn_slab_type) {

    }

    visit_beam_column_type(cn_beam_column_type) {

    }

    visit_balcony_type(cn_balcony_type) {

    }

    visit_fence_type(cn_fence_type) {

    }

    visit_object_type(cn_object) {

    }

    visit_roof_opening_type(cn_roof_opening_type) {

    }

    visit_stairs_type(cn_stairs_type) {

    }

    visit_pipe_type(cn_pipe_type) {

    }

    visit_fence_opening_type(cn_fence_opening_type) {

    }

}

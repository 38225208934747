//**************************************************************
//**************************************************************
/*
----- FHTAGN Project ---------------------------------------

Copyright(C) 2015-2020 EnerBIM

------------------------------------------------------------
*/
//**************************************************************
//**************************************************************
//***** polygon operations
//**************************************************************
//**************************************************************

import {fh_box} from "./fh_box";
import {fh_add, fh_build_axis, fh_clone, fh_cross, fh_dist, fh_dot, fh_mul, fh_normalize, fh_size, fh_sub} from "./fh_vector";
import {fh_polygon} from "./fh_polygon";

export class fh_solid
{
	//**************************************************************
	//***** Constructor
	//**************************************************************
	constructor() {
		this.clear();

		this._precision = 0.001;

		this._profiling = false;

		this.color = null;
	}

	//**************************************************************
	//***** Clear
	//**************************************************************
	clear() {
		//*** bounding box
		this._bounding_box = new fh_box();

		//*** faces data
		this._faces = [];

		//*** tesselation
		this.tesselation_vertices = [];
		this.tesselation_triangles = [];

		this._volume = -1;
	}

	//**************************************************************
	//***** Clone
	//**************************************************************

	//**************************************************************
	//***** Direct setting
	//**************************************************************
	add_face(face) {
		var faces = face.split();
		if (faces.length == 0) return;
		for (var i in faces)
		{
			this._faces.push(faces[i]);
		}
		this._bounding_box.clear();
		this._volume = -1;
		this.tesselation_vertices = [];
		this.tesselation_triangles = [];
	}

	//**************************************************************
	//***** get faces
	//**************************************************************
	get_faces() {
		return this._faces;
	}

	//**************************************************************
	//***** get bounding box
	//**************************************************************
	get_bounding_box() {
		if (this._bounding_box.empty)
		{
			for (var i in this._faces)
			{
				this._faces[i].compute_contours();
				var vertices = this._faces[i].contour_vertices;
				for (var k in vertices)
					this._bounding_box.enlarge_point(vertices[k]);
			}
		}
		return this._bounding_box;
	}

	/**
	 * Returns the solid's volume. Lazy calculation.
	 * @returns {number}
	 */
	get_volume() {
		if (this._volume >= 0) return this._volume;
		this._volume = 0;
		var minz = this.get_bounding_box().position[2];
		this._faces.forEach(face => {
			const face_normal = face.get_normal();
			//*** face must not be vertical */
			if (face_normal[2] != 0)
			{
				const upward = (face_normal[2] > 0);
				/** Special case : face is horizontal */
				const fbb = face.get_bounding_box();
				if (fbb.size[2] < 0.01)
				{
					var dv = face.get_area() * (fbb.position[2]-minz);
					if (!upward) dv = -dv;
					this._volume += dv;
				}
				else
				{
					//*** Otherwise we consider each triangle of the face */
					face.compute_tesselation();
					for (var nt=0;nt<face.tesselation_triangles.length; nt+=3)
					{
						const v0 = face.tesselation_vertices[face.tesselation_triangles[nt]];
						const v1 = face.tesselation_vertices[face.tesselation_triangles[nt+1]];
						const v2 = face.tesselation_vertices[face.tesselation_triangles[nt+2]];
						const d0 = fh_sub(v1,v0);
						const d1 = fh_sub(v2,v0);
						d0[2] = 0;
						d1[2] = 0;
						const normal = fh_cross(d0,d1);
						var dv = 0.5 * fh_size(normal) * (v0[2] + v1[2] + v2[2] - 3 * minz) / 3;
						if (!upward) dv = -dv;
						this._volume += dv;
					}
				}
			}
		});
		if (this._volume < 0) this._volume *= -1;
		return this._volume;
	}

	//**************************************************************
	//***** Apply matrix
	//**************************************************************
	apply_matrix(matrix) {
		this._bounding_box.clear();
		for (var i in this.tesselation_vertices)
		{
			this.tesselation_vertices[i] = matrix.transform_point(this.tesselation_vertices[i]);
		}

		for (var i in this._faces)
		{
			this._faces[i].apply_matrix(matrix);
		}
	}
	//**************************************************************
	//***** Unites to another solid
	//**************************************************************
	unites(other) {
		var box = this.get_bounding_box();
		var other_box = other.get_bounding_box();
		if (other_box.empty) return;
		var extended_box = new fh_box();
		extended_box.empty = false;
		extended_box.position = fh_sub(other_box.position,[0.01,0.01,0.01]);
		extended_box.size = fh_add(other_box.size,[0.02,0.02,0.02]);
		if (box.empty || !box.intersects(extended_box))
		{
			var faces = other.get_faces();
			for (var i in faces)
				this.add_face(faces[i]);
			return;
		}

		this._operate(other, 0);
	}

	//**************************************************************
	//***** Substracts another solid
	//**************************************************************
	substracts(other)
	{
		var box = this.get_bounding_box();
		if (box.empty) return;
		var other_box = other.get_bounding_box();
		if (other_box.empty) return;
		if (!box.intersects(other_box)) return;

		this._operate(other, 1);
	}

	//**************************************************************
	//***** replace with intersection of solid with another solid
	//**************************************************************
	intersects(other)
	{
		var box = this.get_bounding_box();
		if (box.empty) return;
		var other_box = other.get_bounding_box();
		if (other_box.empty || !box.intersects(other_box))
		{
			this.clear();
			return;
		}

		this._operate(other, 2);
	}

	//**************************************************************
	//***** clip with given plane. Part opposite to normal will be ke
	//**************************************************************
	clip(point, orgnormal)
	{
		var normal = fh_clone(orgnormal);
		var nb_in = 0;
		var nb_on = 0;
		var nb_out = 0;

		this.compute_tesselation();

		for (var n = 0; n < this.tesselation_vertices.length; n++)
		{
			var z = fh_dot(normal,fh_sub(this.tesselation_vertices[n],point));
			if (z > this._precision) nb_out++;
			else if (z < -this._precision) nb_in++;
			else nb_on++;
		}

		//*** easy situations
		if (nb_out == 0) return;
		if (nb_in == 0)
		{
			this.clear();
			return;
		}

		var dx = [0,0,0];
		var dy = [0,0,0];
		fh_build_axis(normal,dx, dy);
		var xmin = 10000;
		var xmax = -10000;
		var ymin = 10000;
		var ymax = -10000;
		var zmax = 0;
		for (var n = 0; n < this.tesselation_vertices.length; n++)
		{
			var v = fh_sub(this.tesselation_vertices[n],point);
			var x = fh_dot(v,dx);
			if (x > xmax) xmax = x;
			if (x < xmin) xmin = x;
			var y = fh_dot(v,dy);
			if (y > ymax) ymax = y;
			if (y < ymin) ymin = y;
			var z = fh_dot(v,normal);
			if (z > zmax) zmax = z;
		}
		xmin -= 10.0 * this._precision;
		xmax += 20.0 * this._precision;
		ymin -= 10.0 * this._precision;
		ymax += 20.0 * this._precision;
		zmax += 20.0 * this._precision;
		var posmin = fh_add(point,fh_add(fh_mul(dx,xmin),fh_mul(dy,ymin)));
		var s = new fh_solid();
		dx = fh_mul(dx,xmax - xmin);
		dy = fh_mul(dy,ymax - ymin);
		normal = fh_mul(normal,zmax);
		s.brick(posmin, dx, dy, normal);
		this.substracts(s);
	}

	//*********************************************
	/** build tesselation */
	//*********************************************
	compute_tesselation() {
		if (this.tesselation_vertices.length > 0) return;
		this._volume = -1;
		if (this._faces.length <= 0) return;
		var faces = this.get_faces();
		for (var i in faces)
		{
			faces[i].compute_tesselation();
			var offset = this.tesselation_vertices.length;
			this.tesselation_vertices = this.tesselation_vertices.concat(faces[i].tesselation_vertices);
			for (var j in faces[i].tesselation_triangles)
			{
				this.tesselation_triangles.push(faces[i].tesselation_triangles[j]+offset);
			}
		}

	}
	//*********************************************
	/** build solid as a brick with given dimensions */
	//*********************************************
	brick(position, dx, dy, dz)
	{
		this.clear();

		var dd = [ dx, dy, dz ];
		for (var axis = 0; axis < 3; axis++)
		{
			var ii = ((axis + 1) % 3);
			var jj = ((axis + 2) % 3);
			for (var kk = 0; kk < 2; kk++)
			{
				var p = fh_clone(position);
				var normal = fh_cross(dd[ii],dd[jj]);
				fh_normalize(normal);
				if (kk == 0) normal = fh_mul(normal,-1.0);
				if (kk > 0) p = fh_add(p,dd[axis]);

				var polygon = new fh_polygon(p, normal);
				var vertices = [];
				vertices.push(p);
				p = fh_add(p,dd[ii]);
				vertices.push(p);
				p = fh_add(p,dd[jj]);
				vertices.push(p);
				p = fh_sub(p,dd[ii]);
				vertices.push(p);
				if (kk == 0) vertices.reverse();
				polygon.add_contour(vertices);
				this.add_face(polygon);
			}
		}
	}

	//*********************************************
	/** build solid as a cylinder with given dimensions */
	//*********************************************
	cylinder(position, dx, dy, dz)
	{
		var nor = fh_cross(dx,dy);
		fh_normalize(nor);
		var face = new fh_polygon(position,nor);
		var contour = [];
		for (var theta=0;theta <= 360;theta += 15)
		{
			var p = fh_add(position,fh_mul(dx,0.5 + 0.5*Math.cos(theta*Math.PI/180)));
			p = fh_add(p,fh_mul(dy,0.5 + 0.5*Math.sin(theta*Math.PI/180)));
			contour.push(p);
		}
		face.add_contour(contour);
		this.extrusion(face,dz);
	}

	//*********************************************
	/** build solid as extrusion of a polygon */
	//*********************************************
	extrusion(face, direction) {
		this.clear();

		face.compute_contours();
		var contour_vertices = face.contour_vertices;
		var contour_sizes = face.contour_sizes;
		var normal = face.get_normal();

		//*** build borders
		var offset = 0;
		for (var n = 0; n < contour_sizes.length; n++)
		{
			var sz = contour_sizes[n];
			for (var k = 0; k < sz; k++)
			{
				var k1 = (k==sz-1)?0:k + 1;
				var contour = [];
				contour.push(fh_clone(contour_vertices[offset + k]));
				contour.push(fh_clone(contour_vertices[offset + k1]));
				contour.push(fh_add(contour_vertices[offset + k1],direction));
				contour.push(fh_add(contour_vertices[offset + k],direction));
				var p = new fh_polygon();
				p.add_contour(contour);
				this.add_face(p);
			}
			offset += sz;
		}

		//*** build top and bottom
		for (var niter = 0; niter < 2; niter++)
		{
			var p = new fh_polygon();
			offset = 0;
			for (var n = 0; n < contour_sizes.length; n++)
			{
				var sz = contour_sizes[n];
				var vts = [];
				if (niter == 0)
				{
					for (var k = 0; k < sz; k++)
						vts.push(fh_clone(contour_vertices[offset + k]));
					if (fh_dot(normal,direction)>0) vts.reverse();
				}
				else
				{
					for (var k = 0; k < sz; k++)
						vts.push(fh_add(contour_vertices[offset + k],direction));
					if (fh_dot(normal,direction)<0) vts.reverse();
				}
				p.add_contour(vts);
				offset += sz;
			}

			this.add_face(p);
		}
	}

	//*********************************************
	/** returns intersection with given plane */
	//*********************************************
	intersect_by_plane(point, normal, polygons = null)
	{
		var solid = this;

		if (polygons)
		{
			// @ts-ignore
            solid = new fh_solid();
			for (var n = 0; n < polygons.length; n++)
				solid.add_face(polygons[n]);
		}

		var p = solid._build_intersection(point, normal,0.001);
		if (p == null) return [];
		return p.split();
	}

	//*********************************************
	/** returns intersection with given plane */
	//*********************************************
	plane_intersection(point, normal)
	{
		return this._build_intersection(point, normal,0.001);
	}

	//*********************************************
	/** returns projection on given plane */
	//*********************************************
	project_on_plane(point, normal)
	{
		var p = new fh_polygon(point, normal);

		var faces = this.get_faces();
		for (var n = 0; n < faces.length; n++)
		{
			if (Math.abs(fh_dot(faces[n].get_normal(),normal)) < 0.001) continue;
			p.unites(faces[n]);
		}
		return p;
	}

	//**************************************************************
	/** Operate :
		- 0 : union
		- 1 : substraction
		- 2 : intersection
	*/
	//**************************************************************
	_operate(other, operation) {
		var new_faces = [];
		var faces = this.get_faces();
		var other_faces = other.get_faces();
		var has_change = false;
		for (var n = 0; n < faces.length; n++)
		{
			var area = faces[n].get_area();
			var f = faces[n].clone();
			var normal = f.get_normal();
			if (operation == 0) normal = fh_mul(normal,-1);
			var p = other._build_intersection(f.get_point(), normal,this._precision);
			if (p && p.get_area() > this._precision * this._precision)
			{
				if (operation < 2)
					f.substracts(p);
				else
					f.intersects(p);
			}
			else if (operation == 2)
			{
				has_change = true;
				continue;
			}
			has_change = has_change || (Math.abs(area - f.get_area()) > 0.001 * area);
			if (f.get_area() > this._precision * this._precision)
				new_faces.push(f);
			else
				has_change = true;
		}
		for (var n = 0; n < other_faces.length; n++)
		{
			var f = other_faces[n].clone();
			if (operation == 1)
				f.reverse();
			var area = f.get_area();
			var normal = f.get_normal();
			if (operation == 0) normal = fh_mul(normal,-1);
			// @ts-ignore
            var p = this._build_intersection(f.get_point(), normal,this._precision);

			if (p && p.get_area() > this._precision * this._precision)
			{
				if (operation == 0)
					f.substracts(p);
				else
					f.intersects(p);
			}
			else if (operation != 0)
				continue;

			if (f.get_area() > this._precision * this._precision)
			{
				has_change = true;
				new_faces.push(f);
			}
		}

		if (!has_change) return;

		//*** merge parallel new faces
		for (var n = 0; n < new_faces.length; n++)
		{
			var f0 = new_faces[n];
			if (f0 == null) continue;
			var normal0 = f0.get_normal();
			var point0 = f0.get_point();

			for (var n1 = n + 1; n1 < new_faces.length; n1++)
			{
				var f1 = new_faces[n1];
				if (f1 == null) continue;

				var normal1 = f1.get_normal();
				if (fh_dist(normal0,normal1) >= this._precision) continue;
				var dd = fh_sub(point0,f1.get_point());
				if (Math.abs(fh_dot(normal0,dd)) > this._precision) continue;
				if (Math.abs(fh_dot(normal1,dd)) > this._precision) continue;

				f0.unites(f1);
				new_faces[n1] = null;
			}
		}

		this.clear();
		for (var n = 0; n < new_faces.length; n++)
		{
			if (new_faces[n])
				this.add_face(new_faces[n]);
		}
	}

	//*********************************************
	/** build intersection between a plane and a solid */
	//*********************************************
	_build_intersection(point, normal, precision)
	{
		class fh_solid_contour
		{
			constructor() {
				this._closed = false;
				this._vertices = [];
			}
		}

		var trace = false;
		var contours = [];

		//*** compute intersection with each face
		var faces = this.get_faces();
		for (var n = 0; n < faces.length; n++)
		{
			//*** need an orderer list of points taht form segments.
			var need_reverse = false;
			var points = [];
			var positions = [];

			//*** intersection direction
			var direction = fh_cross(normal,faces[n].get_normal());

			//*** loop on face contours
			var offset = 0;
			var vertices = faces[n].contour_vertices;
			var contours_sizes = faces[n].contour_sizes;
			for (var nc = 0; nc < contours_sizes.length; nc++)
			{
				var sz = contours_sizes[nc];
				for (var k = 0; k < sz; k++)
				{
					var k0 = offset + k;
					var k1 = offset + ((k + 1) % sz);
					var k2 = offset + ((k + 2) % sz);
					var z0 = fh_dot(normal,fh_sub(vertices[k0],point));
					var z1 = fh_dot(normal,fh_sub(vertices[k1],point));
					var z2 = fh_dot(normal,fh_sub(vertices[k2],point));
					var i0 = (z0 < -precision) ? -1 : (z0>precision) ? 1 : 0;
					var i1 = (z1 < -precision) ? -1 : (z1>precision) ? 1 : 0;
					var i2 = (z2 < -precision) ? -1 : (z2>precision) ? 1 : 0;
					if (Math.abs(i0 + i1) == 2) continue;
					var p = [0,0,0];
					var reverse = false;
					if (i1 == 0)
					{
						if (i0 == i2) continue;
						if (i0 + i2 == 1) continue;
						p = fh_sub(vertices[k1],fh_mul(normal,z1));
						reverse = (i2 - i0 < 0);
					}
					else if (i0 != 0)
					{
						var z = -z1 / (z0 - z1);
						p = fh_add(fh_mul(vertices[k0],z),fh_mul(vertices[k1],1.0 - z));
						reverse = (z1 < z0);
					}
					else
						continue;

					var x = fh_dot(direction,fh_sub(p,point));
					var index = 0;
					for (index = 0; index < positions.length; index++)
					{
						if (x < positions[index]) break;
					}
					positions.splice(index,0, x);
					points.splice(index, 0, p);
					if (index == 0)
						need_reverse = reverse;
				}
				offset += sz;
			}
			if (points.length == 0) continue;
			if (points.length&1) continue;

			//*** contour merge
			if (need_reverse)
				points.reverse();
			for (var k = 0; k < points.length; k += 2)
			{
				var ct = new fh_solid_contour();
				ct._vertices.push(points[k]);
				ct._vertices.push(points[k+1]);
				contours.push(ct);
			}
		}

		if (contours.length == 0) return null;

		//*** merge segments to contours
		var contour_precision = 0.01;
		while (true)
		{
			var change = false;
			for (var n = 0; n < contours.length; n++)
			{
				if (contours[n]._closed) continue;
				if (contours[n]._vertices.length == 0) continue;
				var v1 = contours[n]._vertices[contours[n]._vertices.length-1];
				var smallest_distance = fh_dist(contours[n]._vertices[0],v1);
				var best_contour = null;
				var best_contour_index = -1;
				for (var n1 = n + 1; n1 < contours.length; n1++)
				{
					if (contours[n1]._closed) continue;
					if (contours[n1]._vertices.length == 0) continue;
					var dst = fh_dist(v1,contours[n1]._vertices[0]);
					if (dst > smallest_distance) continue;
					smallest_distance = dst;
					best_contour = contours[n1];
					best_contour_index = n1;
				}
				if (best_contour == null) continue;
				contours[n]._vertices = contours[n]._vertices.concat(best_contour._vertices);
				contours.splice(best_contour_index,1);
				n--;
				//contours[n]._closed = (fh_manhattan_dist(contours[n]._vertices[contours[n]._vertices.length-1],contours[n]._vertices[0]) <= contour_precision);
				change = true;
			}
			if (!change) break;
		}

		var polygon = new fh_polygon(point, normal);
		for (var n = 0; n < contours.length; n++)
		{
			if (contours[n]._vertices.length == 0) continue;
			polygon.add_contour(contours[n]._vertices);
		}
		return polygon;
	}

	//*********************************************
	/** Unitary test */
	//*********************************************
	static unitary_test() {
		var s0 = new fh_solid();
		var s1 = new fh_solid();
		s0.brick([0,0,0],[1,0,0],[0,1,0],[0,0,1]);
		s1.brick([1,0,0],[1,0,0],[0,1,0],[0,0,1]);
		s0.unites(s1);
		console.log("unitary_test 0 (6 faces expected)",s0.get_faces());

		s0.brick([0,0,0],[2,0,0],[0,2,0],[0,0,2]);
		s1.brick([0,0,0],[1,0,0],[0,1,0],[0,0,1]);
		s0.unites(s1);
		console.log("unitary_test 1(6 faces expected)",s0.get_faces());
	}

}

import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { Anomalie, CeeConfig, CeeModel, IAnomalie, PointControle } from '../../../model/cee.model';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { CeeService } from '../../../services/cee.service';
import { EGAL, INFERIEUR, SUPPERIEUR } from '../../../shared/cee.constants';

@Component({
    selector: 'app-conc-points-de-controle',
    templateUrl: './conc-points-de-controle.component.html',
})
export class ConcPointsDeControleComponent {
    anomalies: IAnomalie[] = [];
    constructor(private readonly diagnosticService: DiagnosticService, private readonly ceeService: CeeService) {
        combineLatest([this.diagnosticService.getCurrentDiagnostic(), this.ceeService.ceeConfig$])
            .pipe(first())
            .subscribe(([diagnostic, config]) => {
                this.initAnomalies(config, diagnostic.contenuDiagnostic as CeeModel);
            });
    }

    private initAnomalies(config: CeeConfig, ceeModel: CeeModel) {
        let idsEpaisseurFacture = [];
        let idsEpaisseurMesure = [];
        let idsSurfaceFacture = [];
        let idsSurfaceMesure = [];
        this.ceeService.ceeConfig$
            .subscribe((conf) => {
                idsEpaisseurFacture = conf.pointsControles
                    .filter((ptc) => ptc.description === 'Epaisseur sur preuve de réalisation (en cm)')
                    .map((ptc) => ptc.idInterne);
                idsSurfaceFacture = conf.pointsControles
                    .filter((ptc) => ptc.description === 'Surface isolée sur preuve de réalisation (m²)')
                    .map((ptc) => ptc.idInterne);
                idsEpaisseurMesure = conf.pointsControles
                    .filter((ptc) => ptc.description === 'Épaisseur mesurée par l’inspecteur (cm)')
                    .map((ptc) => ptc.idInterne);
                idsSurfaceMesure = conf.pointsControles
                    .filter((ptc) => ptc.description === 'Surface isolée mesurée par l’inspecteur (Sinsp) [m²]')
                    .map((ptc) => ptc.idInterne);
            })
            .unsubscribe();

        for (const solution of ceeModel.solutions.solutions) {
            let nomSolution = '';
            let epaisseurFacture = null; // Epaisseur sur preuve de réalisation (en cm)
            let surfaceFacture = null; // Surface isolée sur preuve de réalisation (m²)
            if (solution.infoIsolant) {
                const solValue = solution.infoIsolant.find((val) => this.ceeService.idsNomSolution.includes(val.id));
                if (solValue) {
                    nomSolution = solValue.valeur;
                }

                epaisseurFacture = solution.infoIsolant.find((val) => idsEpaisseurFacture.includes(val.id));
                surfaceFacture = solution.infoIsolant.find((val) => idsSurfaceFacture.includes(val.id));
            }
            const iAnomalie: IAnomalie = {
                solution: nomSolution,
                zones: [],
            };
            let totalEpaisseur = 0;
            let totalSurface = 0;
            for (const name of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                const value1 = solution.pointsDeControles.data[name].find((val) => idsEpaisseurMesure.includes(val.id));
                const value2 = solution.pointsDeControles.data[name].find((val) => idsSurfaceMesure.includes(val.id));
                totalEpaisseur += value1 ? +value1.valeur : 0;
                totalSurface += value2 ? +value2.valeur : 0;
            }
            for (const name of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                const anomalies = { name, libelleAnomalies: [] };
                if (surfaceFacture && Math.abs(((+surfaceFacture.valeur - totalSurface) / totalSurface) * 100) > 10) {
                    anomalies.libelleAnomalies.push('Surface isolée mesurée par l’inspecteur (Sinsp) [m²]');
                }
                if (
                    epaisseurFacture &&
                    Math.abs(((+epaisseurFacture.valeur - totalEpaisseur) / totalEpaisseur) * 100) > 10
                ) {
                    anomalies.libelleAnomalies.push('Épaisseur mesurée par l’inspecteur (cm)');
                }
                for (const ptc of solution.pointsDeControles.data[name]) {
                    const pointcontrole: PointControle = config.pointsControles.find((p) => p.idInterne === ptc.id);
                    let anomalie: Anomalie = pointcontrole.lstAnomalie.find((an) => an.codeReponse == ptc.valeur);
                    if (!anomalie) {
                        anomalie = pointcontrole.lstAnomalie.find((an) => {
                            switch (an.type) {
                                case INFERIEUR:
                                    return +ptc.valeur < +an.valeurReference;
                                case SUPPERIEUR:
                                    return +ptc.valeur > +an.valeurReference;
                                case EGAL:
                                    return +ptc.valeur === +an.valeurReference;
                            }
                            return false;
                        });
                    }
                    if (anomalie) {
                        anomalies.libelleAnomalies.push(
                            anomalie.description ? anomalie.description : pointcontrole.description
                        );
                    }
                }
                if (anomalies.libelleAnomalies.length) {
                    iAnomalie.zones.push(anomalies);
                }
            }
            if (iAnomalie.zones.length) {
                iAnomalie.zones = iAnomalie.zones.filter((zone) => zone.libelleAnomalies.length);
                this.anomalies.push(iAnomalie);
            }
        }
    }
}

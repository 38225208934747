import { AssainissementReportData } from './app/modules/diagnostics/assainissement/model/assainissement.model';
import { InterventionReportData } from './app/model/rapport.model';
import { HapReportData } from './app/modules/diagnostics/hap/model/hapData.model';
import { MesurageReportData } from './app/modules/diagnostics/mesurage/model/mesurage.model';
import { TypePrestation } from './app/model/type-prestation.model';

/**
 * Ce fichier est utilisé pour configurer les différentes classes à parser pour la génération du schéma
 */

export class DiagnosticTree {
    typesPrestation: TypePrestation[];
    reportData: string;
}

// À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRESTATIONS...
export const DIAGNOSTICS_TREE: DiagnosticTree[] = [
    {
        typesPrestation: ['ASSAINISSEMENT'],
        reportData: 'AssainissementReportData',
    },
    {
        typesPrestation: [
            'HAP_VALIDATION',
            'HAP_ETUDE_SITUATION',
            'HAP_VISITE_RECONNAISSANCE',
            'HAP_TERRAIN',
            'HAP_VALIDATION_RESULTATS',
        ],
        reportData: 'HapReportData',
    },
    {
        typesPrestation: ['MESURAGE', 'MESURAGE_HABITABLE', 'MESURAGE_UTILE'],
        reportData: 'MesurageReportData',
    },
];

/**
 * Liste des classes à parser pour la génération de doc
 */
export class ReportDataSchema {
    interventionData: InterventionReportData;
    // À COMPLÉTER AVEC LES REPORTDATACLASS DE CHAQUE TYPE DE DIAGNOSTICS...
    diagnostics: [AssainissementReportData, HapReportData, MesurageReportData];
}

"use strict";

import { cn_camera } from "../svg/cn_camera";
import { CN_CURRENT_DATE } from "../utils/cn_transaction_manager";

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_element : base virtual class for almost everything
//***********************************************************************************
//***********************************************************************************

import {cn_add, cn_box, cn_uuid} from "../utils/cn_utilities";

const STATUS_DISABLED = -1;
const STATUS_NORMAL = 0;
const STATUS_MOUSEOVER = 1;
const STATUS_SELECTED = 2;

var _NB_ELEMENTS = 0;
export class cn_element {
	constructor(parent=null) {
		this.ID = cn_element.generate_ID();
        _NB_ELEMENTS++;
		this.class_name = "cn_element";
        this.draw_priority = 10;
        this.status = 0;
        this.parent = parent;

        this.removable = true;
        this.selectable = true;

        this.parameters = {};
        this.visible = true;
		this.visibility_3d = false;
        this.object = undefined;

		this.locked = false;
		this._date = CN_CURRENT_DATE;
		this._creation_date = CN_CURRENT_DATE;
		this._param_dates = {};

        //*** Data used to link to 3D */
        this._storey_3d = null;
        this._object_3d = null;
    }

    /**
     * Generates an ID for element
     */
    static generate_ID() {
        return cn_uuid("element" + _NB_ELEMENTS);
    }

    //*************************************************
    //*** Compare to another element
    //*************************************************
    equals(other, check_names = true) {
        if (typeof (other) != 'object') return false;
        if (other == null) return false;
        if (other.constructor.name != this.constructor.name) return false;

        var s0 = this.serialize();
		var s1 = other.serialize();
		if (typeof(s0.ID) == 'string') s0.ID = "";
		if (typeof(s1.ID) == 'string') s1.ID = "";
		if (!check_names)
		{
			if (typeof(s0.name) == 'string') s0.name = "";
			if (typeof(s1.name) == 'string') s1.name = "";
		}
		return JSON.stringify(s0) == JSON.stringify(s1);
	}

	//*************************************************
	//*** Parameter management
	//*************************************************
	/**
	 * @returns {object} - Returns the parameters of the element
	 */
	get_parameters() {
		return this.parameters;
	}

	/**
	 * @param {string} param_name : parameter name
	 * @returns {any} - Returns the value of given parameter, or undefined
	 */
	get_parameter(param_name) {
		return this.parameters[param_name];
	}

	/**
	 * Sets a given parameter. If value is 'undefined', removes the parameter
	 * @param {string} param_name
	 * @param {any} value
	 */
	set_parameter(param_name,value) {
		if (value == undefined)
		{
			if (typeof(this.parameters[param_name]) != 'undefined')
				delete this.parameters[param_name];
		}
		else
			this.parameters[param_name] = value;
	}

    serialize() {
        return undefined;
    }

	/**
	 * Returns the bounding box of the element
	 * @returns {cn_box}
	 */
	get_bounding_box() {
		return new cn_box();
	}

	/**
	 * Returns the bounding box of the element
	 * @param {cn_camera} camera
	 * @returns {cn_box}
	 */
	 get_screen_bounding_box(camera) {
		const box = new cn_box();
		const world_box = this.get_bounding_box();
		if (!world_box.posmin) return box;
		box.enlarge_point(camera.world_to_screen(world_box.posmin));
		box.enlarge_point(camera.world_to_screen(cn_add(world_box.posmin,world_box.size)));
		return box;
	}

	/**
	 * SVG drawing
	 * @param {cn_camera} camera
	 * @param {Array<string>} extra
	 * @returns {string}
	 */
	draw(camera,extra) {
		return "";
	}

	/**
	 * Sets the date. Only to be used from transactions !!!!
	 * @param {Array<string>} param_names
	 * @param {number} date
	 */
	set_date(param_names, date) {
		this._date = date;
		param_names.forEach(pn => this._param_dates[pn] = date);
		if (this.parent && this.parent instanceof cn_element)
			this.parent.set_date([],date);
	}

	/**
	 * Returns the lastest date of modification of a param.
	 * If param_name is empty, or does not match a dated param, returns the date of the item
	 * @param {string} param_name
	 * @returns {number}
	 */
	get_date(param_name = "") {
		if (param_name == "") return this._date;
		if (typeof(this._param_dates[param_name]) == 'number') return this._param_dates[param_name];
		return this._creation_date;
	}

	/**
	 * returns true if param hasn't changed since date.
	 * If param is empty, returns true if item is up to date.
	 * @param {number} date
	 * @param {string} param_name
	 * @returns {boolean}
	 */
	up_to_date(date, param_name = "")
	{
		if (param_name == "") return this._date <= date;
		if (typeof(this._param_dates[param_name]) == 'number') return this._param_dates[param_name] <= date;
		return this._creation_date <= date;
	}
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { LibFileUploaderModule, LibIconModule, LibIncrementInputModule, LibLongPressModule } from 'src/app/commons-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MapEditorModule } from '@acenv/cnmap-angular-editor-lib';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { DiagnosticsSharedModule } from '../../diagnostics-shared/diagnostics-shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DescriptionBienModule } from '../../interventions/description/description-bien.module';
import { PipesModule } from '../../../pipes/pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { ColorBlockModule } from 'ngx-color/block';

import { ActivitesComponent } from './modules/etude-situation/initialisation/activites/activites.component';
import { ObjectifsComponent } from './modules/etude-situation/initialisation/objectifs/objectifs.component';
import { ValidationDocumentaireComponent } from './modules/etude-situation/validation-documentaire/validation-documentaire.component';
import { DescriptifComponent } from './modules/etude-situation/perimetre/descriptif/descriptif.component';
import { LocalisationComponent } from './modules/etude-situation/perimetre/localisation/localisation.component';
import { IntroStrategieComponent } from './modules/etude-situation/intro-strategie/intro-strategie.component';
import { MpcaDescriptifComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-descriptif.component';
import { ZoneComponent } from './modules/shared/zone/zone/zone.component';
import { BesoinsPrelevementsComponent } from './modules/etude-situation/besoins-prelevements/besoins-prelevements.component';
import { PrevisiteComponent } from './modules/etude-situation/estimation/previsite/previsite.component';
import { CompetenceComponent } from './modules/etude-situation/estimation/competence/competence.component';
import { AddLegendModalComponent } from './modules/etude-situation/perimetre/localisation/add-legend-modal/add-legend-modal.component';
import { MatTreeModule } from '@angular/material/tree';
import { MpcaProcessusClientComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-processus-client/mpca-processus-client.component';
import { MpcaFormGeneralComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-form-general/mpca-form-general.component';
import { MpcaFormSkeletonComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-form-skeleton/mpca-form-skeleton.component';
import { MpcaFormMetopComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-form-metop/mpca-form-metop.component';
import { MpcaFormPendantTravauxComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-form-pendant-travaux/mpca-form-pendant-travaux.component';
import { MpcaFormProtectionExpositionComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-form-protection-exposition/mpca-form-protection-exposition.component';
import { MpcaListingComponent } from './modules/etude-situation/listeMPCA/mpca-listing/mpca-listing.component';
import { MatSliderModule } from '@angular/material/slider';
import { HeaderZoneComponent } from './modules/shared/zone/header-zone/header-zone.component';
import { PieceUnitaireBlockComponent } from './modules/shared/zone/piece-unitaire-block/piece-unitaire-block.component';
import { MpcaListSelectComponent } from './shared/mpca-list-select/mpca-list-select.component';
import { ZoneProprieteModalComponent } from './modules/shared/zone/zone-propriete-modal/zone-propriete-modal.component';
import { ZoneTravailModalComponent } from './modules/shared/zone/zone-travail-modal/zone-travail-modal.component';
import { SequenceModalComponent } from './modules/shared/sequence-modal/sequence-modal.component';
import { SequenceListComponent } from './modules/shared/sequence-list/sequence-list.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { SequenceCardComponent } from './modules/shared/sequence-card/sequence-card.component';
import { RecapitulatifComponent } from './modules/etude-situation/recapitulatif/recapitulatif.component';
import {
    ObjectifsMesuragePipe,
    EstimationPipe,
    CompetencesPipe,
    InterventionPipe,
    MpcaMateriauxPipe,
    UnCamelCasePipe,
    ZoneFullnamePipe,
    HighlightSearchPipe,
} from './utils/polluant.pipe';
import { VerificationCardComponent } from './modules/shared/verification-card/verification-card.component';
import { MessageCardComponent } from './modules/shared/message-card/message-card.component';
import { MpcaProcessusModalComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-processus-modal/mpca-processus-modal.component';
import { CalculatriceModaleComponent } from '../../diagnostics-shared/calculatrice-modale/calculatrice-modale.component';
import { MpcaFormEchantillonnageComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-form-echantillonnage/mpca-form-echantillonnage.component';
import { InitMaterielPrelevement } from './modules/prelevement/prelevement-init/prelevement-materiel.component';
import { ZonePrelevementComponent } from './modules/prelevement/prelevement-zone/prelevement-zone.component';
import { PrelevementZoneSelectionRedirectionComponent } from './modules/prelevement/prelevement-zone-selection-redirection/prelevement-zone-selection-redirection.component';
import { ZoneFormInformationComponent } from './modules/prelevement/prelevement-zone/form/zone-form-information/zone-form-information.component';
import { ZoneFormMetopComponent } from './modules/prelevement/prelevement-zone/form/zone-form-metop/zone-form-metop.component';
import { ZoneFormMetaComponent } from './modules/prelevement/prelevement-zone/form/zone-form-meta/zone-form-meta.component';
import { PrelevementComponent } from './modules/prelevement/prelevement-prelevement/prelevement-prelevement.component';
import { PrelevementTabDirective } from './modules/prelevement/prelevement-prelevement/prelevement-tab.directive';
import { PrelevementFormMateriauxComponent } from './modules/prelevement/prelevement-prelevement/form/meta/prelevement-form-materiaux/prelevement-form-materiaux.component';
import { PrelevementFormGeneralComponent } from './modules/prelevement/prelevement-prelevement/form/meta/prelevement-form-general/prelevement-form-general.component';
import { PrelevementFormPoseComponent } from './modules/prelevement/prelevement-prelevement/form/meta/prelevement-form-pose/prelevement-form-pose.component';
import { PrelevementFormRecuperationComponent } from './modules/prelevement/prelevement-prelevement/form/meta/prelevement-form-recuperation/prelevement-form-recuperation.component';
import { PrintingComponent } from './modules/shared/test-impression/printing.component';
import { PrelevementFormApresComponent } from './modules/prelevement/prelevement-prelevement/form/metop/prelevement-form-apres/prelevement-form-apres.component';
import { PrelevementFormPendantComponent } from './modules/prelevement/prelevement-prelevement/form/metop/prelevement-form-pendant/prelevement-form-pendant.component';
import { PrelevementFormAvantComponent } from './modules/prelevement/prelevement-prelevement/form/metop/prelevement-form-avant/prelevement-form-avant.component';
import { InformationMeteo } from './shared/information-meteo-form/information-meteo-form.component';
import { CompassForm, MeteoPipe } from './shared/compass-form/compass-form.component';
import { FiltresItemListComponent } from './modules/prelevement/prelevement-prelevement/form/metop/prelevement-form-avant/filtres-item-list/filtres-item-list.component';
import { FiltreModalComponent } from './modules/prelevement/prelevement-prelevement/form/metop/prelevement-form-avant/filtres-item-list/filtres-modal/filtre-modal.component';
import { PrelevementFormMestGeneralComponent } from './modules/prelevement/prelevement-prelevement/form/mest/prelevement-form-mest-general/prelevement-form-mest-general.component';
import { PrelevementFormAcrGeneralComponent } from './modules/prelevement/prelevement-prelevement/form/acr/prelevement-form-mest-general/prelevement-form-acr-general.component';
import { GestionPlanPolluantComponent } from './modules/gestion-plan-polluant/gestion-plan-polluant.component';
import { PollHeaderBlockComponent } from './report/blocks/poll-header-block/poll-header-block.component';
import { PollIntroductionBlockComponent } from './report/blocks/poll-introduction-block/poll-introduction-block.component';
import { PollPerimetreBlockComponent } from './report/blocks/poll-perimetre-block/poll-perimetre-block.component';
import { PollContextBlockComponent } from './report/blocks/poll-context-block/poll-context-block.component';
import { ContextZoneHomogeneTemplateComponent } from './report/blocks/poll-context-block/context-zone-homogene-template/context-zone-homogene-template.component';
import { ContextZoneTravauxTemplateComponent } from './report/blocks/poll-context-block/context-zone-travaux-template/context-zone-travaux-template.component';
import { PrelevementZoneBesoinModalComponent } from './modules/prelevement/prelevement-zone/prelevement-zone-besoin-modal/prelevement-zone-besoin-modal.component';
import { InformationsBesoinComponent } from './modules/etude-situation/besoins-prelevements/information-besoin/informations-besoin.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MpcaFormContentComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-form-content/mpca-form-content.component';
import { PollZonesBlockComponent } from './report/blocks/poll-zones-block/poll-zones-block.component';
import { MpcaMaterialSelectionModalComponent } from './modules/etude-situation/listeMPCA/mpca-descriptif/mpca-material-selection-modal/mpca-material-selection-modal.component';

@NgModule({
    declarations: [
        ActivitesComponent,
        ObjectifsComponent,
        ValidationDocumentaireComponent,
        DescriptifComponent,
        LocalisationComponent,
        IntroStrategieComponent,
        MpcaDescriptifComponent,
        ZoneComponent,
        ZonePrelevementComponent,
        BesoinsPrelevementsComponent,
        InformationsBesoinComponent,
        PrevisiteComponent,
        CompetenceComponent,
        RecapitulatifComponent,
        AddLegendModalComponent,
        MpcaProcessusClientComponent,
        MpcaFormGeneralComponent,
        MpcaFormSkeletonComponent,
        MpcaFormMetopComponent,
        MpcaFormPendantTravauxComponent,
        MpcaFormProtectionExpositionComponent,
        MpcaListingComponent,
        MpcaFormEchantillonnageComponent,
        HeaderZoneComponent,
        PieceUnitaireBlockComponent,
        MpcaListSelectComponent,
        ZoneProprieteModalComponent,
        ZoneTravailModalComponent,
        MpcaProcessusModalComponent,
        MpcaMaterialSelectionModalComponent,
        SequenceModalComponent,
        SequenceListComponent,
        SequenceCardComponent,
        VerificationCardComponent,
        MessageCardComponent,
        InitMaterielPrelevement,
        ZoneFormInformationComponent,
        ZoneFormMetopComponent,
        ZoneFormMetaComponent,
        PrelevementComponent,
        PrelevementZoneSelectionRedirectionComponent,
        PrelevementFormMateriauxComponent,
        PrelevementFormGeneralComponent,
        PrelevementFormRecuperationComponent,
        PrelevementFormPoseComponent,
        PrelevementFormAvantComponent,
        PrelevementFormPendantComponent,
        PrelevementFormApresComponent,
        PrelevementFormAcrGeneralComponent,
        PrelevementFormMestGeneralComponent,
        FiltresItemListComponent,
        FiltreModalComponent,
        InformationMeteo,
        ObjectifsMesuragePipe,
        EstimationPipe,
        HighlightSearchPipe,
        CompetencesPipe,
        InterventionPipe,
        MpcaMateriauxPipe,
        UnCamelCasePipe,
        ZoneFullnamePipe,
        MeteoPipe,
        PrelevementTabDirective,
        PrintingComponent,
        CompassForm,
        GestionPlanPolluantComponent,
        PollHeaderBlockComponent,
        PollIntroductionBlockComponent,
        PollPerimetreBlockComponent,
        PollZonesBlockComponent,
        PollContextBlockComponent,
        ContextZoneHomogeneTemplateComponent,
        ContextZoneTravauxTemplateComponent,
        PrelevementZoneBesoinModalComponent,
        MpcaFormContentComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        LibIncrementInputModule,
        CnDiagSharedModule,
        DiagnosticsSharedModule,
        MatMenuModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        LibFileUploaderModule,
        LibLongPressModule,
        MatProgressBarModule,
        MapEditorModule,
        MatSidenavModule,
        MatButtonToggleModule,
        MatTooltipModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        DescriptionBienModule,
        LibIconModule,
        PipesModule,
        MatDialogModule,
        DragDropModule,
        MatSelectModule,
        MatRadioModule,
        ColorBlockModule,
        MatTreeModule,
        MatSliderModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        FormsModule,
        MatDatepickerModule,
    ],
    entryComponents: [
        ActivitesComponent,
        ObjectifsComponent,
        ValidationDocumentaireComponent,
        IntroStrategieComponent,
        DescriptifComponent,
        LocalisationComponent,
        MpcaDescriptifComponent,
        ZoneComponent,
        BesoinsPrelevementsComponent,
        PrevisiteComponent,
        CompetenceComponent,
        RecapitulatifComponent,
        AddLegendModalComponent,
        SequenceModalComponent,
        ZoneProprieteModalComponent,
        ZoneTravailModalComponent,
        PrelevementZoneBesoinModalComponent,
        MpcaProcessusModalComponent,
        MpcaMaterialSelectionModalComponent,
        CalculatriceModaleComponent,
        InitMaterielPrelevement,
        ZonePrelevementComponent,
        //Sous-onglets de la page Prélèvement
        PrelevementFormMateriauxComponent,
        PrelevementFormGeneralComponent,
        PrelevementFormRecuperationComponent,
        PrelevementFormPoseComponent,
        PrelevementFormAvantComponent,
        PrelevementFormPendantComponent,
        PrelevementFormApresComponent,
        PrelevementFormAcrGeneralComponent,
        PrelevementFormMestGeneralComponent,
        FiltreModalComponent,
        PollHeaderBlockComponent,
        PollIntroductionBlockComponent,
        PollPerimetreBlockComponent,
        PollZonesBlockComponent,
        PollContextBlockComponent,
    ],
})
export class PolluantModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bien } from '../../../model/bien.model';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { InterventionService } from '../../../services/intervention.service';
import { StateChoiceBoxes } from '../../../model/states.model';
import { TypeBien } from '../../../model/type-bien.model';
import { takeUntil } from 'rxjs/operators';
import { Intervention } from '../../../model/intervention.model';
import {
    apartmentState,
    buildingState,
    falseStringOption,
    houseState,
    lotState,
    terrainState,
    trueStringOption,
    undefinedOption,
} from '../../../shared/constants/states.constants';
import { BienService } from '../../../services/bien.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { of } from 'rxjs/internal/observable/of';
import { ContactService } from 'src/app/services/contact.service';
import { Contact, RoleContact } from 'src/app/model/contact.model';
import { EditContactModalComponent } from '../edit-contact/edit-contact-modal/edit-contact-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { StringUtils } from 'src/app/utils/string.utils';
import { MONTHS } from '../../../shared/constants/cndiag.constants';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DISPLAY_FN_CONTACT } from '../../../utils/display-function.utils';
import { FormService } from '../../../services/form.service';
import { each, find } from 'underscore';
import { forEach } from 'cypress/types/lodash';
import { filter } from 'cypress/types/bluebird';

@Component({
    selector: 'app-edit-bien',
    templateUrl: './edit-bien.component.html',
    styleUrls: ['./edit-bien.component.scss'],
})
export class EditBienComponent extends BaseComponent implements OnInit {
    @Input()
    bien: Bien;

    @Input()
    intervention: Intervention;

    @Input()
    formInfo: FormGroup;

    @Input()
    isEditMode: boolean;

    @Input()
    isAdminMode: boolean;

    @Output()
    initializeCompleted = new EventEmitter<boolean>();

    editMode$: Observable<boolean>;

    typesBien: TypeBien[];
    choixTypesBien: StateChoiceBoxes[] = [];
    typeBienSelected: TypeBien;

    filteredSearchContactsProprietaires: Contact[];
    isSearchingContacts = this.formService.isSearchingContacts$;

    /**
     * Il faut donner une string pour le state-input-reactive, car le boolean n'est pas pris en compte
     */
    trueStOption: StateChoiceBoxes = trueStringOption;
    falseStOption: StateChoiceBoxes = falseStringOption;

    subTypeList: string[] = [];
    nbRoomsList: string[] = [];
    months = MONTHS;

    hasProprietaire = false;
    readonly displayFnContact = DISPLAY_FN_CONTACT;

    constructor(
        private interventionService: InterventionService,
        private matDialog: MatDialog,
        private referenceService: ReferenceService,
        private bienService: BienService,
        private contactService: ContactService,
        private notificationService: NotificationService,
        private readonly formService: FormService
    ) {
        super();
    }

    ngOnInit(): void {
        this.editMode$ =
            this.isEditMode !== undefined ? of(this.isEditMode) : this.interventionService.getBienEditMode();

        this.referenceService.findAllTypesBien().subscribe((typesBien) => {
            this.typesBien = typesBien;

            if (!this.bien.idTypeBien) {
                this.bien.idTypeBien = 'Appartement';
            }

            this.hasProprietaire = !!(this.bien.contactProprietaire && this.bien.contactProprietaire.nom);
            this.addCustomValidator();
            this.choixTypesBien = this.prepareChoixTypesBien(this.typesBien.map((x) => x.id));
            this.typeBienSelected = this.typesBien.find((x: TypeBien) => x.id === this.bien.idTypeBien);
            console.log('This is the bien selected ', this.bien);
            this.subTypeList = this.typeBienSelected.sousTypes ? this.typeBienSelected.sousTypes : [];
            if (this.subTypeList.length) {
                if (this.bien.sousType && !this.subTypeList.includes(this.bien.sousType)) {
                    this.subTypeList = this.subTypeList.concat(this.bien.sousType);
                }
            }

            this.nbRoomsList = this.typeBienSelected.nbPiecesPrincipales
                ? this.typeBienSelected.nbPiecesPrincipales
                : [];

            this.prepareFormType();

            this.bienService.updateBienFrom(this.formInfo, this.bien);

            // Mise à jour lors de la sélection d'un type de bien ou lorsque le formulaire est re-remplie avec les
            // données précédentes par une cancel dans un composant parent.
            this.formInfo
                .get('idTypeBien')
                .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(() => {
                    this.typeBienChanged();
                });

            this.initializeCompleted.emit(true);
        });

        this.formService
            .contactSearchValueChange(this.formInfo.get('contactProprietaire'), this.contactService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchContactsProprietaires = result.content;
            });
    }

    typeBienChanged() {
        if (
            (this.isEditMode || this.interventionService.getBienEditModeValue()) &&
            this.typesBien &&
            this.formInfo.get('idTypeBien').value.length
        ) {
            this.typeBienSelected = this.typesBien.find(
                (it: TypeBien) => it.id === this.formInfo.get('idTypeBien').value
            );
            this.subTypeList = this.typeBienSelected.sousTypes ? this.typeBienSelected.sousTypes : [];
            this.nbRoomsList = this.typeBienSelected.nbPiecesPrincipales
                ? this.typeBienSelected.nbPiecesPrincipales
                : [];
            if (this.subTypeList.length) {
                if (this.bien.sousType && !this.subTypeList.includes(this.bien.sousType)) {
                    this.subTypeList = this.subTypeList.concat(this.bien.sousType);
                }
            }
        }
        this.prepareFormType();
    }

    changeProprietaire(event: MatAutocompleteSelectedEvent) {
        if (event.option && event.option.value && event.option.value.id) {
            this.bien.contactProprietaire = event.option.value;
            this.hasProprietaire = !!(this.bien.contactProprietaire && this.bien.contactProprietaire.nom);
            this.notificationService.success('Le contact a été ajouté');
        }
    }

    addProprietaire() {
        const newContact = new Contact();
        newContact.typeRole = RoleContact.PROPRIETAIRE;
        newContact.type = 'Propriétaire';
        newContact.estPersonneMorale = false;
        return this.matDialog
            .open(EditContactModalComponent, {
                data: {
                    contact: newContact,
                    isReadOnly: false,
                    isProprietaire: true,
                    isDonneurOrdre: false,
                    isCreation: true,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.bien.contactProprietaire = result.contact;
                    this.formInfo.patchValue({
                        contactProprietaire: result.contact,
                    });
                    this.formInfo.updateValueAndValidity();
                    this.hasProprietaire = !!(this.bien.contactProprietaire && this.bien.contactProprietaire.nom);
                    this.notificationService.success('Le contact a été ajouté');
                }
            });
    }

    editProprietaire() {
        const contactToEdit = { ...this.bien.contactProprietaire };
        contactToEdit.typeRole = RoleContact.PROPRIETAIRE;
        contactToEdit.type = 'Propriétaire';
        return this.matDialog
            .open(EditContactModalComponent, {
                data: {
                    contact: contactToEdit,
                    isReadOnly: false,
                    isProprietaire: true,
                    isDonneurOrdre: false,
                    isCreation: false,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.bien.contactProprietaire = result.contact;
                    this.formInfo.patchValue({
                        contactProprietaire: result.contact,
                    });
                    this.formInfo.updateValueAndValidity();
                    this.hasProprietaire = !!(this.bien.contactProprietaire && this.bien.contactProprietaire.nom);
                    this.notificationService.success('Le contact a été modifié');
                }
            });
    }

    deleteProprietaire() {
        this.bien.contactProprietaire = undefined;
        this.formInfo.patchValue({
            contactProprietaire: '',
        });
        this.formInfo.updateValueAndValidity();
        this.hasProprietaire = !!(this.bien.contactProprietaire && this.bien.contactProprietaire.nom);
    }

    private addCustomValidator() {
        if (!this.formInfo) {
            this.bienService.initBienForm();
        }
        this.formInfo.get('nom').setValidators([
            Validators.required,
            (control: AbstractControl) => {
                if (!this.intervention) {
                    return;
                }
                if (
                    control.value &&
                    this.intervention.relationInterventionBiens
                        .map((b) => StringUtils.toLowerCase(b.bien.nom))
                        .includes(StringUtils.toLowerCase(control.value)) &&
                    StringUtils.toLowerCase(control.value) !== StringUtils.toLowerCase(this.bien.nom)
                ) {
                    return { erreurNomBien: true };
                }
                return null;
            },
        ]);
        this.formInfo.get('batiment').setValidators([
            (control: AbstractControl) => {
                if (this.typeBienSelected && this.typeBienSelected.batiment) {
                    return Validators.required(control);
                }
                return null;
            },
        ]);
        this.formInfo.get('contactProprietaire').setValidators([
            (control) => {
                if (control.value && typeof control.value.nom === 'string') {
                    return null;
                } else {
                    return { contactDoesNotExist: true };
                }
            },
        ]);
    }

    /**
     * Utilitaire pour la card type de bien
     */
    private prepareChoixTypesBien(typesBien: string[]) {
        const choices: StateChoiceBoxes[] = [];
        for (const bien of typesBien) {
            switch (bien) {
                case 'Maison':
                    choices.push(houseState);
                    break;
                case 'Appartement':
                    choices.push(apartmentState);
                    break;
                case 'Immeuble/PartieCommune':
                    choices.push(buildingState);
                    break;
                case 'ImmeubleBatiNonHabitation':
                    choices.push(lotState);
                    break;
                case 'ImmeubleNonBati':
                    choices.push(terrainState);
                    break;
                default:
                    choices.push(undefinedOption);
                    break;
            }
        }
        return choices;
    }

    private prepareFormType() {
        if (this.typeBienSelected) {
            const keys = [
                {
                    typeBien: 'batiment',
                    form: 'batiment',
                },
                {
                    typeBien: 'escalierEntree',
                    form: 'escalierEntree',
                },
                {
                    typeBien: 'situation',
                    form: 'situation',
                },
                {
                    typeBien: 'sousTypes',
                    form: 'sousType',
                },
                {
                    typeBien: 'nbPiecesPrincipales',
                    form: 'nbPiecesPrincipales',
                },
                {
                    typeBien: 'dateConstruction',
                    form: 'anneeConstruction',
                },
                {
                    typeBien: 'nbNiveaux',
                    form: 'nbNiveauxPositifs',
                },
                {
                    typeBien: 'nbNiveaux',
                    form: 'nbNiveauxNegatifs',
                },
                {
                    typeBien: 'nbCagesEscalier',
                    form: 'nbCagesEscalier',
                },
            ];

            for (const key of keys) {
                if (this.typeBienSelected[key.typeBien]) {
                    this.formInfo.get(key.form).setValidators([Validators.required]);
                } else {
                    this.formInfo.get(key.form).patchValue(null);
                    this.formInfo.get(key.form).clearValidators();
                    this.formInfo.get(key.form).updateValueAndValidity();
                }
            }
            this.formInfo.updateValueAndValidity();
        }
    }

    /**
     *  Fonction pour rediriger vers google maps
     */
    onClickRedirect() {
        let adresse = this.bien.adresse;

        let googleMapsURL = `https://maps.google.com?q=${adresse.ville}+${adresse.voie}`;
        window.open(googleMapsURL, '_blank');
    }
}

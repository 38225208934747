//***********************************************************************************
//***********************************************************************************
//**** fh_ground_controller : manipulation of 3D topography
//***********************************************************************************
//***********************************************************************************

import {
    AmbientLight,
    BackSide,
	BufferGeometry,
    Box3,
    Color,
    DirectionalLight,
    DoubleSide,
    EdgesGeometry,
    Face3,
    FrontSide,
    Geometry,
    LineBasicMaterial,
    LineSegments,
    Matrix4,
	Line,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    PointLight,
    Scene,
    Vector2,
    Vector3,
    ImageUtils,
	RepeatWrapping,
	TextureLoader,
	SphereGeometry,
	BoxGeometry,
	OctahedronGeometry,
	Raycaster
} from 'three';
import {dummy_target, fh_view} from "./fh_view";
import {fh_sub, fh_add, fh_dot, fh_normalize, fh_mul} from "./fh_vector";

//***********************************************************************************
//**** Ground controller class
//***********************************************************************************

export class fh_ground_controller extends Object3D {
	//*****************************************************
	//*** Constructor
	constructor(topography) {
		super();

		this._events = {};
		this.visible = false;

		/** Ground elements */
		this.ground_elements = [];
		this.upon_ground_elements = [];
		this.above_ground_elements = [];

		this._grab_mouse = {left:0, top:0};
		this._dragging = 0;
		this._grabbed_element = null;
		this._grab_ctrl = false;
		this._grab_position = [0,0,0];
		this._grab_direction = [0,0,0];

		this._topography = topography;
		this._range = 0;
		const grid_width = this._topography.size[0];
		const grid_height = this._topography.size[1];

		this._max_range = 1;
		while (this._max_range*2 < grid_width && this._max_range*2 < grid_height) this._max_range*=2;

		this._mouseover_element = null;
		this._selected_elements = [];
		this._selected_vertices = [];
		this._raycaster = new Raycaster();

		var selection_container = new Object3D();

		this._handles = [];
		var obj = this;

		//*** build vertices */
		const vertex_geometry = new SphereGeometry(0.1, 16, 8 );
		const vertex_material = new MeshPhongMaterial( { color: 0x9b3c3c, specular: 0x363636, shininess: 70} );
		const vertex_mouseover_material = new MeshBasicMaterial( { color: 0xffff00, depthTest: false, depthWrite: false, transparent:true, opacity: 0.5 } );
		const vertex_selection_material = new MeshBasicMaterial( { color: 0xff0000, depthTest: false, depthWrite: false, transparent:true, opacity: 0.5 } );
		this._vertices = [];
		for (var j=0;j<grid_height;j++)
		{
			for (var i=0;i<grid_width;i++)
			{
				var pt = this._topography.get_point(i,j);
				const vertex_object = new Mesh( vertex_geometry, vertex_material);
				vertex_object.position.set(pt[0],pt[1],pt[2]);
				vertex_object._grid_position=[i,j];
				vertex_object._topography_type = "vertex";
				vertex_object._handle_object = vertex_object;

				// @ts-ignore
				this.add(vertex_object);
				this._vertices.push(vertex_object);
				vertex_object._vertices = [vertex_object];

				const highlight = new Mesh( vertex_geometry, vertex_mouseover_material);
				highlight.visible = false;
				selection_container.add(highlight);
				vertex_object._highlight = highlight;

				vertex_object.update_status = function() {
					if (obj._mouseover_element == this)
					{
						this._highlight.visible = true;
						this._highlight.material = vertex_mouseover_material;
					}
					else if (obj._selected_elements.indexOf(this) >= 0)
					{
						this._highlight.visible = true;
						this._highlight.material = vertex_selection_material;
					}
					else
						this._highlight.visible = false;
				};
			}
		}

		
		//*** build lines */
		this._x_lines = [];
		this._y_lines = [];
		this._line_base_material = new LineBasicMaterial( { color: 0x808080 } );
		this._line_highlight_material = new LineBasicMaterial( { color: 0xff0000 } );
		for (var j=0;j<grid_height;j++)
		{
			for (var i=0;i<grid_width;i++)
			{
				var pt = this._topography.get_point(i,j);
				const v0 = new Vector3(pt[0],pt[1],pt[2]);
				if (i < grid_width-1)
				{
					pt = this._topography.get_point(i+1,j);
					const v1 = new Vector3(pt[0],pt[1],pt[2]);
					const geometry = new BufferGeometry().setFromPoints([v0,v1]);
					const line = new Line( geometry, this._line_base_material );
					line._grid_position=[i,j];
					line._topography_type = "x_line";
					// @ts-ignore
					this.add(line);
					this._x_lines.push(line);
				}
				
				if (j < grid_height-1)
				{
					pt = this._topography.get_point(i,j+1);
					const v1 = new Vector3(pt[0],pt[1],pt[2]);
					const geometry = new BufferGeometry().setFromPoints([v0,v1]);
					const line = new Line( geometry, this._line_base_material );
					line._grid_position=[i,j];
					line._topography_type = "y_line";
					// @ts-ignore
					this.add(line);
					this._y_lines.push(line);
				}
			}
		}

		//*** build faces */
		this._faces = [];
		this._face_material = new MeshBasicMaterial({color: 0xff0000, side:DoubleSide, transparent:true,opacity: 0});
		const face_invisible_material = new MeshBasicMaterial({side:DoubleSide, depthTest: false, depthWrite: false, transparent:true,opacity: 0});
		const face_mouseover_material = new MeshBasicMaterial({color: 0xffff00, side:DoubleSide, depthTest: false, depthWrite: false, transparent:true,opacity: 0.2});
		const face_selection_material = new MeshBasicMaterial({color: 0xff0000, side:DoubleSide, depthTest: false, depthWrite: false, transparent:true,opacity: 0.2});
		
		this._faces_by_range = {};
		
		function _set_shader_rec(item, shader) {
			if (typeof(item._range) != 'number') return;
			if (item._range == 1) item.material = shader;
			else item.children.forEach( child => _set_shader_rec(child,shader));
		}

		function build_faces_rec(parent, range, i0, i1, j0, j1)
		{
			const cube_geometry = new OctahedronGeometry(range/8);

			for (var j=j0;j<j1;j+=range)
			{
				for (var i=i0;i<i1;i+=range)
				{
					if (range == 1)
					{
						var geometry = new Geometry();
						for (var k=0;k<4;k++)
						{
							var pt = obj._topography.get_point(i+(k&1),j+(k&2)/2);
							geometry.vertices.push(new Vector3(pt[0],pt[1],pt[2]));
						}
						geometry.faces.push( new Face3(0,1,3));
						geometry.faces.push( new Face3(0,3,2));
						var object = new Mesh( geometry, face_invisible_material );
						// @ts-ignore
					}
					else
					{
						var object = new Object3D();
						build_faces_rec(object,range/2,i,i+range,j,j+range);
					}
					object._range = range;
					object._topography_type = "face";
					object._grid_position=[i,j];
					object._vertices = [];
					object._vertices.push(obj._vertices[i+j*grid_width]);
					object._vertices.push(obj._vertices[i+range+j*grid_width]);
					object._vertices.push(obj._vertices[i+range+(j+range)*grid_width]);
					object._vertices.push(obj._vertices[i+(j+range)*grid_width]);
					parent.add(object);
					if (typeof(obj._faces_by_range[range]) != 'object')
						obj._faces_by_range[range] = [];
					obj._faces_by_range[range].push(object);

					//*** build handle */
					var cube_center = obj._topography.get_point(i,j);
					object._handle = new Mesh(cube_geometry,vertex_material);
					object._handle.position.set(cube_center[0] + range/2, cube_center[1] + range/2, 0);
					object._handle.visible = false;
					object._handle._handle_object = object;
					object.add(object._handle);
					
					//*** build highlight handle */
					var highlight = new Mesh( cube_geometry, face_invisible_material);
					highlight.visible = false;
					selection_container.add(highlight);
					object._highlight = highlight;

					object.update_status = function() {
						if (obj._mouseover_element == this)
						{
							this._highlight.visible = true;
							this._highlight.material = face_mouseover_material;
							_set_shader_rec(this,face_mouseover_material);
						}
						else if (obj._selected_elements.indexOf(this) >= 0)
						{
							this._highlight.visible = true;
							this._highlight.material = face_selection_material;
							_set_shader_rec(this,face_selection_material);
						}
						else
						{
							this._highlight.visible = false;
							_set_shader_rec(this,face_invisible_material);
						}
					};
				}
			}
		}

		build_faces_rec(this,this._max_range,0,grid_width-1,0,grid_height-1);

		// @ts-ignore
		this.add(selection_container);
		
		this.set_range((this._max_range>= 16)?16:this._max_range);
	}

	//***********************************************************************************
	/**
	 * Registers an event on a given function
	 * @param {string} ev
	 * @param {function} fun
	 */
	 on(ev, fun) {
		if (typeof(this._events[ev]) == 'undefined')
			this._events[ev] = [fun];
		else
			this._events[ev].push(fun);
	}

	//***********************************************************************************
	/**
	 * Unregisters an event for all functions (fun = null), or for one given function
	 * @param {string} ev
	 * @param {function} fun
	 */
	unbind(ev, fun = null) {
		if (typeof(this._events[ev]) == 'undefined') return;
		if (fun)
		{
			var index = this._events[ev].indexOf(fun);
			if (index >= 0) this._events[ev].splice(index,1);
		}
		else
			this._events[ev] = [];
	}

	//***********************************************************************************
	/**
	 * Calls an event with given arguments
	 * @param {string} ev
	 * @param {any} arg
	 */
	call(ev, arg = null) {
		var funs = this._events[ev];
		if (typeof(funs) != 'object') return;
		for (var j in funs)
			funs[j](arg);
	}
	//*****************************************************
	/**
	 * Update modification / view range
	 * @param {number} r 
	 */
	set_range(r) {
		if (typeof(this._faces_by_range[this._range]) == 'object')
		{
			this._faces_by_range[this._range].forEach(obj => obj._handle.visible = false);
		}
		this._range = r;

		this._mouseover_element = null;
		this._select_element();

		this._handles = [];

		this._vertices.forEach(vertex => {
			const visible_i = ((vertex._grid_position[0]&(this._range-1)) == 0);
			const visible_j = ((vertex._grid_position[1]&(this._range-1)) == 0);
			vertex.visible = visible_j && visible_i;
			if (vertex.visible) this._handles.push(vertex);
		});
		
		this._y_lines.forEach(line => {
			if ((line._grid_position[0]&(this._range-1)) == 0)
				line.material = this._line_highlight_material;
			else
				line.material = this._line_base_material;
		});
		
		this._x_lines.forEach(line => {
			if ((line._grid_position[1]&(this._range-1)) == 0)
				line.material = this._line_highlight_material;
			else
				line.material = this._line_base_material;
		});

		this._faces_by_range[this._range].forEach(obj => {
			obj._handle.visible = true;
			this._handles.push(obj._handle);
		});

		this._update_handles();
	}

	//*****************************************************
	/**
	 * Returns current range
	 * @returns {number} 
	 */
	get_range() {
		return this._range;
	}
	
	//*****************************************************
	/**
	 * Returns the list of available range
	 * @returns {number[]} 
	 */
	get_range_list() {
		var lst = [];
		for (var r = this._max_range;r>=1;r/=2)
			lst.push(r);
		return lst;
	}
	
	//*****************************************************
	/**
	 * Force given height for the selection
	 * @param {number} height 
	 * @param {fh_view} view
	 */
	set_selection_height(height, view) {
		var point_list = [];
		this._selected_elements.forEach(elt => {
			if (elt._topography_type == "vertex")
			{
				this._topography.set_height_smart(elt._grid_position[0], elt._grid_position[1],height,this._range);
			}	
			else if (elt._topography_type == "face")
			{
				for (var j=0;j<=elt._range;j++)
				{
					for (var i=0;i<=elt._range;i++)
					{
						point_list.push([elt._grid_position[0]+i,elt._grid_position[1]+j]);
					}
				}
			}
		});

		if (point_list.length > 0)
			this._topography.flatten(point_list,height,this._range);
		
		this._update_from_topography();
		view._scene.update_bounding_box();
		if (typeof(view.update_camera) == 'function') view.update_camera();
		view.refresh_rendering();
		this.call("selection_height_change");
	}

	/**
	 * Returns altitude range of selection. Empry list if no selection.
	 * 1 value is all vertices in selection are the same.
	 * 2 values otherwise.
	 * @returns {number[]}
	 */
	get_altitude_range() {
		if (this._selected_elements.length == 0) return [];
		
		var min_value = 1000;
		var max_value = -1000;
		this._selected_elements.forEach(elt => {
			if (elt._topography_type == "vertex")
			{
				const z = this._topography.get_height(elt._grid_position[0],elt._grid_position[1]);
				if (z < min_value) min_value = z;
				if (z > max_value) max_value = z;
			}
			else if (elt._topography_type == "face")
			{
				for (var j=0;j<=elt._range;j++)
				{
					for (var i=0;i<=elt._range;i++)
					{
						const z = this._topography.get_height(elt._grid_position[0]+i,elt._grid_position[1]+j);
						if (z < min_value) min_value = z;
						if (z > max_value) max_value = z;
					}
				}
			}
		});
		if (max_value - min_value < 0.01) return [min_value];
		return [min_value, max_value];
	}
	//*****************************************************
	//**** Events
	//*****************************************************

	passive_move(mouse, view) {
		this._dragging = 0;
		var elt = this._find_mouse_object(mouse,view);
		if (elt != this._mouseover_element)
		{
			if (this._mouseover_element)
			{
				var old_mouseover = this._mouseover_element;
				this._mouseover_element = null;
				old_mouseover.update_status();
			}
			this._mouseover_element = elt;
			
			if (elt) 
				elt.update_status();

			view.refresh_rendering();
		}
		return elt != null;
	}
	
	grab(mouse, view,ctrl_key) {
		var elt_pos = [0,0,0];
		const elt = this._find_mouse_object(mouse,view, elt_pos);
		if (elt)
		{
			this._grab_mouse = view.camera_to_screen(mouse);
			this._dragging = 1;
			this._grabbed_element = elt;
			this._grab_ctrl = ctrl_key;
			this._raycaster.setFromCamera(mouse,view.get_camera());
			const ray_dir = [this._raycaster.ray.direction.x,this._raycaster.ray.direction.y,this._raycaster.ray.direction.z];
			fh_normalize(ray_dir);
			const ray_or = [this._raycaster.ray.origin.x,this._raycaster.ray.origin.y,this._raycaster.ray.origin.z];
			const l = fh_dot(ray_dir,fh_sub(elt_pos,ray_or));
			
			this._grab_direction = [ray_dir[0],ray_dir[1],0];
			fh_normalize(this._grab_direction);
			this._grab_position = fh_add(ray_or,fh_mul(ray_dir,l));
			return true;
		}
		else if (!ctrl_key)
		{
			this._select_element(null,false);
		}
		this._dragging = 0;
		return false;
	}
	
	drag(mouse, view) {
		if (this._dragging == 0) return;

		//var tt0 = (new Date()).getTime();
		//*** Maybe we are actually strting to drag ?  */
		if (this._dragging == 1)
		{
			const vp = view.camera_to_screen(mouse);
			const dst = Math.abs(vp.left - this._grab_mouse.left) + Math.abs(vp.top - this._grab_mouse.top);
			if (dst < 20) return;
			this._dragging = 2;

			if (this._selected_elements.indexOf(this._grabbed_element) < 0)
				this._select_element(this._grabbed_element, this._grab_ctrl);
		}

		//*** We start dragging management */
		this._raycaster.setFromCamera(mouse,view.get_camera());
		const ray_dir = [this._raycaster.ray.direction.x,this._raycaster.ray.direction.y,this._raycaster.ray.direction.z];
		fh_normalize(ray_dir);
		const ray_or = [this._raycaster.ray.origin.x,this._raycaster.ray.origin.y,this._raycaster.ray.origin.z];

		const dotprod = fh_dot(ray_dir,this._grab_direction);
		if (Math.abs(dotprod) < 0.01) return;
		const lambda = fh_dot(this._grab_direction,fh_sub(this._grab_position,ray_or)) / dotprod;
		const impact = fh_add(ray_or,fh_mul(ray_dir,lambda));

		this._selected_vertices.forEach(v => {
			this._topography.set_height_smart(v._grid_position[0],v._grid_position[1],v.position.z+impact[2]-this._topography.z-this._grab_position[2], this._range);
		});
		this._grab_position = impact;

		//var t0 = (new Date()).getTime();
		this._update_from_topography();
		//console.log("update from topo : ",(new Date()).getTime()-t0);
		
		view._scene.update_bounding_box();
		if (typeof(view.update_camera) == 'function') view.update_camera();
		view.refresh_rendering();
		//console.log("end drag from topo : ",(new Date()).getTime()-tt0);

		this.call("selection_height_change");
	}
	
	drop(mouse, view) {
		if (this._dragging == 0) return;
		if (this._dragging == 2) 
		{
			this._dragging = 0;
			return;
		}
		this._dragging = 0;
		this._select_element(this._grabbed_element, this._grab_ctrl);
	}
	
	_select_element(elt=null, ctrl_key=false)
	{
		if (ctrl_key)
		{
			if(elt)
			{
				const index = this._selected_elements.indexOf(elt);
				if (index < 0)
				{
					this._selected_elements.push(elt);
				}
				elt.update_status();
			}
		}
		else
		{
			const old_selection = this._selected_elements;
			this._selected_elements = [];
			old_selection.forEach(elt => elt.update_status());
			if (elt) 
			{
				this._selected_elements.push(elt);
				elt.update_status();
			}
		}

		this._selected_vertices = [];
		this._selected_elements.forEach(selt => {
			selt._vertices.forEach(v => {if (this._selected_vertices.indexOf(v)<0) this._selected_vertices.push(v);});
		});
		this.call("selection_height_change");
	}

	//*****************************************************
	_update_from_topography() {
		
		//*** Transform ground elements */
		this.ground_elements.forEach(obj => {
			obj.children.forEach(mesh => {
				if (typeof(mesh.geometry) == 'object' && typeof(mesh.geometry.vertices) == 'object')
				{
					mesh.geometry.vertices.forEach(vtx => {
						const zzz = this._topography.compute_height([vtx.x,vtx.y]);
						vtx.z = zzz;
					});
					mesh.geometry.verticesNeedUpdate = true;
					mesh.geometry.normalsNeedUpdate = true;
					mesh.geometry.colorsNeedUpdate = true;
					mesh.geometry.elementsNeedUpdate = true;
					mesh.geometry.computeFaceNormals();
					mesh.geometry.computeBoundingBox();
					mesh.geometry.computeBoundingSphere();
				}
			});
		});
		
		//*** Transform upon ground elements */
		this.upon_ground_elements.forEach(obj => {
			var best_h = 10000;
			var lower_z = 10000;
			const matvtx = new Vector3();
			obj.children.forEach(mesh => {
				if (mesh._is_3d && typeof(mesh.geometry) == 'object' && typeof(mesh.geometry.vertices) == 'object')
				{
					mesh.geometry.vertices.forEach(vtx => {
						matvtx.copy(vtx);
						matvtx.applyMatrix4(mesh.matrix);
						if (matvtx.z < lower_z) lower_z = matvtx.z;
						const h = this._topography.compute_height([matvtx.x,matvtx.y]);
						if (h < best_h) best_h = h;
					});
				}
			});
			//lower_z += obj.position.z;
			obj.position.z = best_h - lower_z;
			obj.updateMatrix();
		});
		
		//*** Transform above ground elements */
		this.above_ground_elements.forEach(obj => {
			var best_h = -10000;
			var lower_z = 10000;
			const matvtx = new Vector3();
			obj.children.forEach(mesh => {
				if (mesh._is_3d && typeof(mesh.geometry) == 'object' && typeof(mesh.geometry.vertices) == 'object')
				{
					mesh.geometry.vertices.forEach(vtx => {
						matvtx.copy(vtx);
						matvtx.applyMatrix4(mesh.matrix);
						if (matvtx.z < lower_z) lower_z = matvtx.z;
						const h = this._topography.compute_height([matvtx.x,matvtx.y]);
						if (h > best_h) best_h = h;
					});
				}
			});
			//lower_z += obj.position.z;
			obj.position.z = best_h - lower_z;
			obj.updateMatrix();
		});

		this._update_handles();
	}

	_update_handles() {
		//*** Update vertices */
		this._vertices.forEach(vertex => {
			if (vertex.visible)
			{
				vertex.position.set(vertex.position.x,vertex.position.y,this._topography.z + this._topography.get_height(vertex._grid_position[0],vertex._grid_position[1]));
				vertex.scale.set(this._range,this._range,this._range);
				vertex.updateMatrix();
				vertex._highlight.position.copy(vertex.position);
				vertex._highlight.scale.set(this._range,this._range,this._range);
				vertex._highlight.updateMatrix();
			}
		});
		
		//*** Update lines */
		this._x_lines.forEach(line => {
			if (line.visible)
			{
				line.geometry.attributes.position.array[2] = this._topography.z + this._topography.get_height(line._grid_position[0],line._grid_position[1]);
				line.geometry.attributes.position.array[5] = this._topography.z + this._topography.get_height(line._grid_position[0]+1,line._grid_position[1]);
				line.geometry.attributes.position.needsUpdate = true;
			}
		});
		
		this._y_lines.forEach(line => {
			if (line.visible)
			{
				line.geometry.attributes.position.array[2] = this._topography.z + this._topography.get_height(line._grid_position[0],line._grid_position[1]);
				line.geometry.attributes.position.array[5] = this._topography.z + this._topography.get_height(line._grid_position[0],line._grid_position[1]+1);
				line.geometry.attributes.position.needsUpdate = true;
			}
		});
		
		//*** Update face geometry */
		this._faces_by_range[1].forEach(face => {
			var dif = false;
			for (var k=0;k<4;k++)
			{
				var vtx = face.geometry.vertices[k];
				const z = this._topography.z + this._topography.get_height(face._grid_position[0]+(k&1),face._grid_position[1]+(k&2)/2);
				if (z != vtx.z) {vtx.z = z; dif = true;}
			}
			if (dif)
			{
				face.geometry.verticesNeedUpdate=true;
				face.geometry.computeFaceNormals();
				face.geometry.computeBoundingBox();
				face.geometry.computeBoundingSphere();
			}
		});
		
		//*** Update face handles */
		this._faces_by_range[this._range].forEach(obj => {
			obj._handle.visible = true;
			
			var cube_center = [0,0,0];
			cube_center[0] = obj._handle.position.x;
			cube_center[1] = obj._handle.position.y;
			cube_center[2] = this._topography.compute_height(cube_center);
			obj._handle.position.set(cube_center[0], cube_center[1], cube_center[2]);
			obj._handle.updateMatrix();
			
			obj._highlight.position.copy(obj._handle.position);
			obj._highlight.updateMatrix();
		});

	}
	//*****************************************************
	_find_mouse_object(mouse, view, impact)
	{
		//*** mouse over a vertex ? */
		const mouse_proj = view.camera_to_screen(mouse);
		
		//*** Mouse over a face ? */
		this._raycaster.setFromCamera(mouse, view._camera);
		var intersects = this._raycaster.intersectObjects(this._handles, true);
		if (intersects.length > 0)
		{
			if (impact)
			{
				impact[0] = intersects[0].point.x;
				impact[1] = intersects[0].point.y;
				impact[2] = intersects[0].point.z;
			}
			return intersects[0].object._handle_object;
		}

		return null;
	}

	_set_shader(object, shader_name) {
		if (object)
		{
			if (typeof(object[shader_name]) == 'object')
				object.material = object[shader_name];
			object.children.forEach(child => this._set_shader(child,shader_name));
		}
	}
}

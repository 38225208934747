"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Return metrics in the form of a son object converter :
//***********************************************************************************
//***********************************************************************************

import {fh_solid} from "@acenv/fh-3d-viewer";
import {cn_dist} from "./cn_utilities";
import {cn_building} from "../model/cn_building";

export function cn_build_metrics(building)
{
	var m = new cn_metrics(building);
	var mm = {};
	mm.id = building.ID;
	mm.inner_metrics = m.inner_metrics;
	mm.outer_metrics = m.outer_metrics;
	mm.spaces = [];
	for (var i in m.storeys)
		mm.spaces = mm.spaces.concat(m.storeys[i].spaces);
	return mm;
}

//***********************************************************************************
//***********************************************************************************
//**** cn_metrics_data class : contains all metrics at a given level
//***********************************************************************************
//***********************************************************************************

export class cn_metrics_data {
	//********************************************************************************
	//**** Constructor
	//********************************************************************************
	constructor(){
		this.walls = 0;
		this.lower_slabs = 0;
		this.upper_slabs = 0;
		this.doors = 0;
		this.windows = 0;
	}

	add(other)
	{
		this.walls += other.walls;
		this.lower_slabs += other.lower_slabs;
		this.upper_slabs += other.upper_slabs;
		this.doors += other.doors;
		this.windows += other.windows;
	}
}

//***********************************************************************************
//***********************************************************************************
//**** cn_metrics class :
//***********************************************************************************
//***********************************************************************************

export class cn_metrics {
	//********************************************************************************
	//**** Constructor
	//********************************************************************************
	constructor(building) {
		this.inner_metrics = new cn_metrics_data();
		this.outer_metrics = new cn_metrics_data();
		this.storeys = [];

		this._building = building;

		var h = 0;
		var slab_thickness = 0.3;

		for (var nbs = 0; nbs < this._building.storeys.length; nbs++)
		{
			this._slab_thickness = slab_thickness;
			this._h0 = h + slab_thickness;
			this._h1 = this._h0 + this._building.storeys[nbs].height;

			var m_storey = this._build_storey(this._building.storeys[nbs]);
			this.storeys.push(m_storey);
			this.inner_metrics.add(m_storey.inner_metrics);
			this.outer_metrics.add(m_storey.outer_metrics);
		}
	}

	//********************************************************************************
	//**** Build zone
	//********************************************************************************
	_build_storey(storey) {
		this._storey = storey;
		var m_storey = {};
		m_storey.id = this._storey.ID;
		m_storey.inner_metrics = new cn_metrics_data();
		m_storey.outer_metrics = new cn_metrics_data();
		m_storey.spaces = [];

		var scene = storey.scene;

		//***************************************
		//*** create roof
		//***************************************
		this._roof_volume = null;
		this._roof_height = this._h1;
		if (this._storey.roof)
		{
			for (var i in storey.roof.slabs)
			{
				var slab = storey.roof.slabs[i];
				var roof_polygon = slab.build_3d_polygon(this._h1,true);
				m_storey.outer_metrics.upper_slabs += roof_polygon.get_area();
			}

			this._roof_volume = storey.build_roof_volume();
			if (this._roof_volume)
			{
				var box = this._roof_volume.get_bounding_box();
				this._roof_height = box.position[2]+box.size[2];
			}
		}

		//***************************************
		//*** create spaces
		//***************************************
		var m_spaces_by_id = {};
		if (scene.spaces.length > 1)
		{
			//*** Create spaces
			for (var i in scene.spaces)
			{
				if (scene.spaces[i].outside) continue;
				var sp = this._build_space(scene.spaces[i]);
				m_spaces_by_id[scene.spaces[i].ID] = sp;
				m_storey.spaces.push(sp);
			}
		}

		//***************************************
		//*** Add walls
		//***************************************
		for (var i in scene.walls)
		{
			var wall = scene.walls[i];
			if (!wall.valid) continue;
			if (wall.wall_type.free) continue;
			if (wall.balcony && wall.wall_type.height < 0.01) continue;

			for (var niter=0;niter<2;niter++)
			{
				var dist = (niter==0)?cn_dist(wall.shape[0],wall.shape[3]):cn_dist(wall.shape[1],wall.shape[2]);
				var msp = m_spaces_by_id[wall.spaces[niter].ID];
				if (msp)
					msp.metrics.walls += dist * (this._h1 - this._h0);
				else
					m_storey.outer_metrics.walls +=  dist * (this._h1 - this._h0 + this._slab_thickness);
			}

			for (var j in wall.openings)
			{
				var opening = wall.openings[j];
				if (!opening.valid) continue;
				var op_area = opening.opening_type.width * opening.opening_type.height;
				if (opening.opening_type.free)
				{
					for (var niter=0;niter<2;niter++)
					{
						var msp = m_spaces_by_id[wall.spaces[niter].ID];
						if (msp)
							msp.metrics.walls -= op_area;
						else
							m_storey.outer_metrics.walls -= op_area;
					}
					continue;
				}

				var is_window = (opening.opening_type.category == "window");
				for (var niter=0;niter<2;niter++)
				{
					var msp = m_spaces_by_id[wall.spaces[niter].ID];
					var metrics = (msp)?msp.metrics:m_storey.outer_metrics;

					if (is_window)
						metrics.windows += op_area;
					else
						metrics.doors += op_area;
				}
			}
		}

		for (var i in m_spaces_by_id)
		{
			var m_space = m_spaces_by_id[i];
			if (m_space.indoor)
				m_storey.inner_metrics.add(m_space.metrics);
			else
				m_storey.outer_metrics.add(m_space.metrics);
		}
		return m_storey;
	}

	//********************************************************************************
	//**** Build space
	//********************************************************************************
	_build_space(space) {
		var m_space = {};
		m_space.id = cn_building.create_unique_id(this._storey,space);
		m_space.indoor = space.indoor;
		m_space.metrics = new cn_metrics_data();

		var footprint = space.build_inner_polygon(this._h0, false);
		var solid = new fh_solid();
		solid.extrusion(footprint,[0,0,this._roof_height - this._h0])

		if (this._roof_volume && space.has_roof) solid.intersects(this._roof_volume);

		var area = footprint.get_area();
		var faces = solid.get_faces();
		for (var i in faces)
		{
			var normal = faces[i].get_normal();
			if (normal[2] > 0.1)
				m_space.metrics.upper_slabs += faces[i].get_area();
			if (normal[2] < -0.1)
				m_space.metrics.lower_slabs += faces[i].get_area();
		}

		return m_space;
	}

}

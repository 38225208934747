export class ArrayUtils {
    static arraysContainsSameElements(arr1: any[], arr2: any[]) {
        if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
            return false;
        }

        const arr1Copy = arr1.concat().sort();
        const arr2Copy = arr2.concat().sort();

        for (let i = 0; i < arr1Copy.length; i++) {
            if (arr1Copy[i] !== arr2Copy[i]) {
                return false;
            }
        }

        return true;
    }
}

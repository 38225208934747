"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Beam and Column type
//***********************************************************************************
//***********************************************************************************

import {fh_add, fh_mul, fh_polygon, fh_solid} from "@acenv/fh-3d-viewer";
import {cn_element_type} from "./cn_element_type";
import {cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_param_group, cn_configuration_tab} from "./cn_configuration";
import {code_to_label} from "./cn_opening_type";
import {cn_element_type_visitor} from '..';

export const BC_MATERIAL_LIST = [
	{label:'Béton', code:'concrete'},
	{label:'Bois', code:'wood'},
	{label:'Acier', code:'steel'}
];

export const BC_SHAPE_LIST = [
	{label:'Rectangulaire', code:'rectangle'},
	{label:'Circulaire', code:'circle'},
	{label:'IPN', code:'ipn'}
];

export const IPN_SHAPE = [
	[0,0],
	[1,0],
	[1,0.1],
	[0.6,0.1],
	[0.6,0.9],
	[1,0.9],
	[1,1],
	[0,1],
	[0,0.9],
	[0.4,0.9],
	[0.4,0.1],
	[0,0.1]
];

/**
 * @class cn_beam_column_type
 * Common class type for beams and columns
 */
export class cn_beam_column_type extends cn_element_type  {
	//***********************************************************************************
	/**
	 * Constructor
	 */
	constructor() {
		super();
		this.name = "";
		this.width = 0.3;
		this.thickness = 0.3;

		this.material = "concrete";
		this.shape = "rectangle";
	}

	//***********************************************************************************
	/**
	 * Creates a default beam type
	 * @returns {cn_beam_column_type[]}
	 */
	static default_types() {
		const def = [];

        const steel = new cn_beam_column_type();
        steel.width = 0.2;
        steel.thickness = 0.3;
        steel.material = "steel";
        steel.shape = "ipn";
        def.push(steel);

        const wood = new cn_beam_column_type();
        wood.width = 0.2;
        wood.thickness = 0.4;
        wood.material = "wood";
        wood.shape = "rectangle";
        def.push(wood);

        const concrete = new cn_beam_column_type();
		concrete.width = 0.4;
		concrete.thickness = 0.4;
		concrete.material = "concrete";
		concrete.shape = "rectangle";
        def.push(concrete);

        return def;
    }

    //***********************************************************************************
    get_generic_label() {
        return "Type de poutre et poteau";
    }

    //***********************************************************************************
    /**
     * Returns a clone of this
     * @returns {cn_beam_column_type}
     */
    clone() {
        const c = new cn_beam_column_type();
        c.name = this.name;
        c.width = this.width;
        c.thickness = this.thickness;

        c.material = this.material;
        c.shape = this.shape;

        return c;
    }

	//***********************************************************************************
	/**
	 * Returns the list of keys of the element
	 * @returns {string[]}
	 */
	model_keys() {
		return ["name","width","thickness","material","shape"];
	}

	//***********************************************************************************
	/**
	 * Serialize
	 * @returns {object}
	 */
	serialize() {
        const json = {};
		json.class_name = 'cn_beam_column_type';
		json.ID = this.ID;
		json.name = this.name;
		json.width = this.width;
		json.thickness = this.thickness;

		json.material = this.material;
		json.shape = this.shape;

		return json;
	}

	//***********************************************************************************
	/**
	 * Unserialize
	 * @param {object} json
	 * @returns {cn_beam_column_type | false}
	 */
	static unserialize(json) {
		if (typeof(json) != 'object') return false;
		if (typeof(json.ID) != 'string') return false;
		if (json.class_name !== 'cn_beam_column_type') return false;
		if (typeof(json.width) != 'number') return false;
		if (typeof(json.thickness) != 'number') return false;

        const ot = new cn_beam_column_type();

		ot.ID = json.ID;
		if (typeof(json.name) == 'string') ot.name = json.name;
		ot.width = json.width;
		ot.thickness = json.thickness;

		if (typeof(json.material) == 'string' && code_to_label(json.material,BC_MATERIAL_LIST)) ot.material = json.material;
		if (typeof(json.shape) == 'string'  && code_to_label(json.shape,BC_SHAPE_LIST)) ot.shape = json.shape;
		return ot;
	}

	//***********************************************************************************
	/**
	 * Returns a displayble label
	 * @returns {string}
	 */
	get_label() {
		if (this.name !== "") return this.name;
        let html = code_to_label(this.material,BC_MATERIAL_LIST);
		if (this.shape !== "rectangle")
			html += ` ${code_to_label(this.shape, BC_SHAPE_LIST)}`;
		return `${html} ${(this.width * 1000).toFixed(0)} x ${(this.thickness * 1000).toFixed(0)}`;
	}

	//***********************************************************************************
    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
	get_description() {
        const description = [];

        const material_label = code_to_label(this.material,BC_MATERIAL_LIST);
		if (material_label)
			description.push({label:"Matériau",value:material_label});

        const shape_label = code_to_label(this.shape,BC_SHAPE_LIST);
		if (shape_label)
			description.push({label:"Type",value:shape_label});

		description.push({label:"Largeur", value: this.width*1000, decimals:0, unit:"mm"});

		description.push({label:"Epaisseur", value: this.thickness*1000, decimals:0, unit:"mm"});

		return description;
	}

	//***********************************************************************************
	/**
	 * Returns a html string to be put in a <svg>
	 * @param {number} w : width of svg element
	 * @param {number} h : height of svg element
	 * @returns {string}
	 */
	draw_svg_icon(w,h) {
		let html = ``;

        const ow = this.width;
        const oh = this.thickness;
        const scale = (ow / oh > w / h)? w / (1.05 * ow):h/(1.05*oh);

        const x0 = w/2 - ow*scale/2;
        const x1 = w/2 + ow*scale/2;
        const y0 = h/2 - oh*scale/2;
        const y1 = h/2 + oh*scale/2;

		if (this.shape === "rectangle") {
            html += `<rect x="${x0}" y="${y0}" width="${x1 - x0}" height="${y1 - y0}" `;
        } else if (this.shape === "circle") {
            html += `<ellipse cx="${(x0 + x1) * 0.5}" cy="${(y0 + y1) * 0.5}" rx="${0.5 * (x1 - x0)}" ry="${0.5 * (y1 - y0)}" `;
        } else if (this.shape === "ipn") {
            html += `<path d="M `;
            for (let i = 0; i < IPN_SHAPE.length; i++) {
                if (i > 0) html += `L `;
                html += `${x0 + (x1 - x0) * IPN_SHAPE[i][0]} ${y0 + (y1 - y0) * IPN_SHAPE[i][1]} `;
            }
            html += `Z" `;
        }

		html += ` class="beam_${this.material}" style="stroke:black"  />`;
		return html;
	}

	//***********************************************************************************
	//**** Build 3D solid
	//***********************************************************************************
	/**
	 * Builds a 3D solid with origin (0,0,0)
	 * @param {number} height
	 */
	build_solid(height) {
        const dz = [0,0,height];
        const dx = [this.width,0,0];
        const dy = [0,this.thickness,0];
        const ori = [0,0,0];

        const solid = new fh_solid();
		if (this.shape === "circle")
			solid.cylinder(ori,dx,dy,dz);
		else if (this.shape === "ipn") {
            const contour = [];
			for (let i=0; i<IPN_SHAPE.length; i++) {
				contour.push([IPN_SHAPE[i][0] * this.width,IPN_SHAPE[i][1] * this.thickness,0]);
			}
            const polygon = new fh_polygon(ori,[0,0,1]);
			polygon.add_contour(contour);
			solid.extrusion(polygon,dz);
		} else {
            solid.brick(ori, dx, dy, dz);
        }

		solid["color"] = this.get_color();

		return solid;
	}

	//***********************************************************************************
	/**
	 * Build footprint, with left corner at 0,0, as horizontal polygon
	 * @returns {fh_polygon}
	 */
	build_footprint() {
        const dz = [0,0,1];
        const dx = [this.width,0,0];
        const dy = [0,this.thickness,0];
        const ori = [0,0,0];
        const polygon = new fh_polygon(ori,dz);
        const contour = [];
		if (this.shape === "circle") {
            const center =  [this.width/2,this.thickness/2,0];
			for (let theta=0;theta<360;theta+=10) {
                const x = 0.5*Math.cos(theta * Math.PI/180);
                const y = 0.5*Math.sin(theta * Math.PI/180);
				contour.push(fh_add(center,fh_add(fh_mul(dx,x),fh_mul(dy, y))));
			}
		}
		else if (this.shape === "ipn") {
			for (let i=0; i<IPN_SHAPE.length; i++)
				contour.push([IPN_SHAPE[i][0] * this.width,IPN_SHAPE[i][1] * this.thickness,0]);
        } else {
            contour.push(ori);
            contour.push(fh_add(ori, dx));
            contour.push(fh_add(ori, fh_add(dx, dy)));
            contour.push(fh_add(ori, dy));
        }
		polygon.add_contour(contour);
		return polygon;
	}

	//***********************************************************************************
	/*** Returns color */
	get_color()	{
		if (this.material === "steel")
			return [100/255,100/255,100/255];
		if (this.material === "wood")
			return [150/255,125/255,100/255];
		return [200/255,200/255,200/255];
	}
	//***********************************************************************************
	//**** Get configuration
	//***********************************************************************************
	static configuration() {
		const configuration = new cn_configuration();

		//*** Global params
		const param_group = new cn_configuration_param_group("Dimensions","dimensions");
		configuration.add_param_group(param_group);
		param_group.add_param(new cn_configuration_param("Largeur","width",10,10000,"mm"));
		param_group.add_param(new cn_configuration_param("Epaisseur","thickness",10,10000,"mm"));

		const tab = new cn_configuration_tab("Typologie","standard");
		configuration.add_tab(tab);
        this.prepare_configuration_tab(tab);

		return configuration;
	}

    //***********************************************************************************
    //**** Prepare material and shape configuration for tabs
    //***********************************************************************************
    static prepare_configuration_tab(tab) {
        //***********************************************
        //*** Material
        const frame_list = new cn_configuration_line("Matériau","material");
        tab.add_line(frame_list);
        BC_MATERIAL_LIST.forEach((material) => {
            frame_list.add_choice(new cn_configuration_choice(material.label,material.code));
        });

        //***********************************************
        //*** Shape
        const shape_list = new cn_configuration_line("Type","shape");
        tab.add_line(shape_list);
        BC_SHAPE_LIST.forEach((shape) => {
            shape_list.add_choice(new cn_configuration_choice(shape.label,shape.code));
        });
    }

	//***********************************************************************************
	//**** Fill configuration
	//***********************************************************************************
	/**
	 * Returns a new configuration built from the element type, or fills an existing configuration.
	 * @param {cn_configuration} config
	 * @returns {cn_configuration}
	 */
	fill_configuration(config = null) {
		const configuration = (config)?config:cn_beam_column_type.configuration();
        // tab selected
        const selection = configuration.selection;
		const param_group = configuration.get_param_group("dimensions");
		param_group.set_param("width",this.width*1000);
		param_group.set_param("thickness",this.thickness*1000);

        const material_line = configuration.tabs[selection].get_line("material");
        material_line.set_selection(this.material);

        const shape_line = configuration.tabs[selection].get_line("shape");
        if (this.shape === "ipn" && this.material !== "steel") {
            this.shape = "rectangle";
        }
        shape_line.set_selection(this.shape);
        shape_line.get_choice("ipn").visible = (this.material === "steel");
		return configuration;
	}

	//***********************************************************************************
	//**** Load configuration
	//***********************************************************************************
	/**
	 * Fills the element_type with data from the configuration.
	 * @param {cn_configuration} configuration
	 */
	load_configuration(configuration) {
		const param_group = configuration.get_param_group("dimensions");
		this.width = param_group.get_param("width").value / 1000;
		this.thickness = param_group.get_param("thickness").value / 1000;

        // tab selected
        const selection = configuration.selection;
		const configuration_tab = configuration.tabs[selection];
		this.material = configuration_tab.get_line("material").get_selection();
		this.shape = configuration_tab.get_line("shape").get_selection();
		return true;
	}

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
     accept_visitor(element_type_visitor) {
        element_type_visitor.visit_beam_column_type(this);
    }
}


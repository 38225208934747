export const SESSION_STORAGE_KEY_COMMANDE = '/gestion-commandes';
export const SESSION_STORAGE_KEY_INTERVENTION = '/gestion-interventions';
export const SESSION_STORAGE_KEY_BIEN = '/gestion-biens';
export const SESSION_STORAGE_KEY_EMPLOYE = '/gestion-users';
export const SESSION_STORAGE_KEY_CONTACT = '/gestion-contacts';
export const SESSION_STORAGE_KEY_CONTACT_EDIT = '/gestion-contacts/edit-contact';
export const SESSION_STORAGE_KEY_PIECE = '/referentiels/volumes';
export const SESSION_STORAGE_KEY_EQUIPEMENT = '/referentiels/equipements';
export const SESSION_STORAGE_KEY_MATERIAUX = '/referentiels/materiaux';
export const SESSION_STORAGE_KEY_PRESTATION = '/referentiels/prestations';
export const SESSION_STORAGE_KEY_AGENCE = '/gestion-agences';
export const SESSION_STORAGE_KEY_COFRAC = '/gestion-cofracs';
export const SESSION_STORAGE_KEY_DOCUMENT = '/gestion-documents';
export const SESSION_STORAGE_KEY_BON_COMMANDE_ANALYSE = '/gestion-bons-de-commande-analyse';
export const SESSION_STORAGE_KEY_COMMENTAIRE_PREDEFINI = '/gestion-commentaires-predefinis';
export const SESSION_STORAGE_KEY_CATEGORIES_COMMENTAIRE_PREDEFINI = '/gestion-categories-commentaires-predefinis';
export const SESSION_STORAGE_KEY_SYNTHESES = '/gestion-syntheses';
export const SESSION_STORAGE_KEY_LIVRABLES = '/gestion-livrables';

export const DEBOUNCE_TIME_VALUE = 600;
export const INITIAL_PAGE_SIZE = 20;

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_ifc converter :
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** write
//***********************************************************************************

import {CNMAP_VERSION} from "../model/cn_building";
import {fh_build_axis, fh_dot, fh_normalize, fh_polygon, fh_solid, fh_sub, fh_matrix, fh_mul, fh_add} from "@acenv/fh-3d-viewer";
import {cn_add, cn_clone, cn_mul, cn_normal, cn_simplify_contour, cn_sub, cn_uuid} from "./cn_utilities";
import { cn_wall_type } from "../model/cn_wall_type";
import { cn_opening_type } from "../model/cn_opening_type";
import { cn_slab_type } from "../model/cn_slab_type";

const material_type_colors = new Map([
    ['concrete', [0.6,0.635294117647059,0.627450980392157]],
    ['block', [0.0, 0.0, 0.0]],
    ['cement', [0.807843137254902, 0.807843137254902, 0.807843137254902]],
    ['brick', [0.5176470588235295, 0.1803921568627451, 0.10588235294117647]],
    ['stone', [0.7215686274509804, 0.6901960784313725, 0.6078431372549019]],
    ['wood', [0.6078431372549019, 0.44313725490196076, 0.3254901960784314]],
    ['insulated_wood', [0.3568627450980392, 0.23529411764705882, 0.06666666666666667]],
    ['insulated_steel', [0.2, 0.2, 0.2]],
    ['insulated_concrete', [0.5333333333333333, 0.5490196078431373 ,0.5529411764705883]],
    ['insulator', [0.9607843137254902, 0.9607843137254902, 0.8627450980392157]],
    ['air', [1.0, 1.0, 1.0]],
    ['gypsum', [0.9568627450980393, 0.9294117647058824, 0.8705882352941177]]
]);

export function cn_to_ifc(cn_building) {
	var writer = new cn_ifc_writer(cn_building);
	writer.write_to_string();
	return writer._output;
}

function force_float(x) {
	return {forced:"float",value:x};
}

function force_no_string(x) {
	return {forced:"no_string",value:x};
}

let CN_ROOF_MODE = 0;

//***********************************************************************************
//**** Step class
//***********************************************************************************

class step_item
{
	constructor(tp, fields=[]) {
		this._type = tp;
		this._fields = fields;
		this.id="";
	}

	to_string() {
		var str = "";
		str += '#' + this.id + '= ' + this._type + '(';
		for (var k=0; k<this._fields.length;k++)
		{
			if (k > 0) str += ',';
			str += this._serialize(this._fields[k],null);
		}
		str += ');\n'
		return str;
	}

	_serialize(x, forced=null)
	{
		var str = "";
		if (x == null)
			str += '$';
		else if (typeof(x) == 'string')
		{
			if (x[0] == '.' && x[x.length-1] == '.')
				str += x;
			else if (x[0] == '@')
				str += x.slice(1);
			else if (forced == 'no_string')
				str += x;
			else
				str += '\'' + x + '\'';
		}
		else if (typeof(x) == 'number')
		{
			str += x;
			if (forced =="float" && str.indexOf(".") < 0)
				str += '.';
		}
		else if (typeof(x) == 'boolean')
		{
			if (x) str += '.T.';
			else str += '.F.';
		}
		else if (typeof(x) == 'object')
		{
			if (typeof(x.forced) == 'string')
				return this._serialize(x.value,x.forced);

			if (typeof(x.id) == 'number')
				str += '#' + x.id;
			else
			{
				str += '(';
				var first = true;
				for (var k in x)
				{
					if (!first) str += ',';
					first = false;
					str += this._serialize(x[k],forced);
				}
				str += ')';
			}
		}
		return str;
	}

}

//***********************************************************************************
//**** Internal class
//***********************************************************************************

class cn_ifc_writer
{
	constructor(cn_building) {
		this._building = cn_building;
		this._output = "";
		this._current_step_id = 1;
		this._unique_id = 0;
        this.ifc_spaces_by_id = new Map();
        this.ifc_materials = new Map();
        this._h0 = 0;
        this._h1 = 0;
        this._h2 = 0;
	}

	//***********************************************************************************
	//**** Main function
	//***********************************************************************************
	write_to_string() {
		this._output = "";
		this._current_step_id = 1;
		this._layers = [];

		this._building.rename_storeys();
		this._building.update_roofs();

		this._output += "ISO-10303-21;\n";
		this._output += "\n",
		this._write_header();
		this._output += "\n";

		this._output += "DATA;\n";

		//*** useful things
		this._origin = this._write_cartesian_point([0.,0.,0.]);
		this._vertical_direction = this._write_direction([0,0,1]);
		this._x_direction = this._write_direction([1,0,0]);
		this._y_direction = this._write_direction([0,1,0]);
		this._write_OwnerHistory();

		this._representation_context = this._write_direct('IFCGEOMETRICREPRESENTATIONCONTEXT',[null,'Model',3,0.00001,this._write_3d_placement([0,0,0]),this._write_direction([0,1,0])]);
		this._3d_subcontext = this._write_direct('IFCGEOMETRICREPRESENTATIONSUBCONTEXT',['Body','Model',null,null,null,null,this._representation_context,null,'.MODEL_VIEW.',null]);

		//*** Useful shaders
		this._wall_shader = this._write_shader("Base",[1,0.95,0.9]);
		this._terrace_roof_shader = this._write_shader("Base",[0.8,0.8,0.8]);
		this._roof_shader = this._write_shader("Base",[0.8,0.5,0.8]);
		this._stairs_shader = this._write_shader("Base",[0.75,0.75,0.75]);
		this._glass_shader = this._write_shader("Glass",[0.8,0.8,0.8],0.5);
		this._aluminum_shader = this._write_shader("Aluminium",[0.3,0.3,0.3]);
		this._wood_shader = this._write_shader("Wood",[0.8,0.7,0.6]);
		this._pvc_shader = this._write_shader("PVC",[1,1,1]);

		this._color_shaders = [];

		this._write_project();

		for (var i in this._layers)
		{
			this._write_direct("IFCPRESENTATIONLAYERASSIGNMENT",[i,null,this._layers[i],null]);
		}

		this._output += "ENDSEC;\n";
		this._output += "END-ISO-10303-21;\n";
		return this._output;
	}

	_uuid() {
		this._unique_id++;
		var uid = "0D" + this._unique_id + cn_uuid("ifc");
		uid = uid.substr(0,22);
		return uid;
	}

	//***********************************************************************************
	//**** Header
	//***********************************************************************************
	_write_header()
	{
		this._output += "HEADER;\n";
		this._output += "FILE_DESCRIPTION(('ViewDefinition[CoordinationView]'),'2;1');\n";
		this._output += "FILE_NAME('web generated','" + new Date().toISOString() + "',(''),(''),'CN-Map IFC Generator','CN-Map','');\n";
		this._output += "FILE_SCHEMA(('IFC2X3'));\n";
		this._output += "ENDSEC;\n";
	}

	//***********************************************************************************
	//**** Write an item
	//***********************************************************************************

	_write_item(item) {
		item.id = this._current_step_id;
		this._current_step_id++;
		this._output += item.to_string();
	}

	/**
	 * Writes an IFC entity
	 * @param {string} item_type
	 * @param {Array<any>} item_fields
	 * @returns {step_item}
	 */
	_write_direct(item_type, item_fields) {
		var item = new step_item(item_type, item_fields);
		this._write_item(item);
		return item;
	}

	//***********************************************************************************
	//**** Write group of items (IFCRELAGGREGATES....)
	//***********************************************************************************

	_write_group(item_type, container, contained) {
		return this._write_direct(item_type, [this._uuid(), this._owner_history, null, null, container, contained]);
	}

	//***********************************************************************************
	//**** Write Owner History
	//***********************************************************************************
	_write_OwnerHistory() {
		var person = this._write_direct('IFCPERSON',[null,'','',null,null,null,null,null,null]);
		var organisation = this._write_direct('IFCORGANIZATION',[null,'','',null,null]);
		var person_and_organisation = this._write_direct('IFCPERSONANDORGANIZATION',[person,organisation,null]);
		var application_organisation = this._write_direct('IFCORGANIZATION',[null,'EnerBIM',null,null,null]);
		var application = this._write_direct('IFCAPPLICATION',[application_organisation,CNMAP_VERSION,'EnerBIM CN-Map','CN-Map']);
		this._owner_history = this._write_direct('IFCOWNERHISTORY',[person_and_organisation, application, null, '.NOCHANGE.', null,null,null,Math.floor(Date.now()/1000)]);
		return this._owner_history;
	}

	//***********************************************************************************
	//**** Write unit Assignment
	//***********************************************************************************
	_write_UnitAssignment() {
		var unit_list = [];
		unit_list.push(this._write_direct('IFCSIUNIT',[null,'.LENGTHUNIT.',null,'.METRE.']));
		unit_list.push(this._write_direct('IFCSIUNIT',[null,'.AREAUNIT.',null,'.SQUARE_METRE.']));
		unit_list.push(this._write_direct('IFCSIUNIT',[null,'.VOLUMEUNIT.',null,'.CUBIC_METRE.']));
		unit_list.push(this._write_direct('IFCSIUNIT',[null,'.PLANEANGLEUNIT.',null,'.RADIAN.']));
		unit_list.push(this._write_direct('IFCSIUNIT',[null,'.PLANEANGLEUNIT.',null,'.RADIAN.']));

		const sec = [];
		var siunit = this._write_direct('IFCSIUNIT',[null,'.MASSUNIT.','.KILO.','.GRAM.'])
		sec.push(this._write_direct('IFCDERIVEDUNITELEMENT',[siunit,1]))
		siunit = this._write_direct('IFCSIUNIT',[null,'.THERMODYNAMICTEMPERATUREUNIT.',null,'.KELVIN.'])
		sec.push(this._write_direct('IFCDERIVEDUNITELEMENT',[siunit,-1]))
		siunit = this._write_direct('IFCSIUNIT',[null,'.TIMEUNIT.',null,'.SECOND.'])
		sec.push(this._write_direct('IFCDERIVEDUNITELEMENT',[siunit,-3]))
		unit_list.push(this._write_direct('IFCDERIVEDUNIT',[sec,'.THERMALTRANSMITTANCEUNIT.',null]));

		var item = this._write_direct('IFCUNITASSIGNMENT',[unit_list]);
		return item;
	}

	//***********************************************************************************
	//**** Project
	//***********************************************************************************
	_write_project() {

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		fields.push('');
		// Description
		fields.push('');
		// Objet Type
		fields.push('');
		// LongName
		fields.push('');
		// Phase
		fields.push('Avant-projet');
		// RepresentationContexts
		fields.push([this._representation_context]);
		// UnitsInContext
		fields.push(this._write_UnitAssignment());

		var project = this._write_direct('IFCPROJECT',fields);

		var site = this._write_Site();

		this._write_group("IFCRELAGGREGATES",project,[site]);
	}

	//***********************************************************************************
	//**** Write placement
	//***********************************************************************************
	_write_cartesian_point(position) {
		return this._write_direct('IFCCARTESIANPOINT',[force_float(position)]);
	}

	_write_direction(direction) {
		return this._write_direct('IFCDIRECTION',[force_float(direction)]);
	}

	//***********************************************************************************
	//**** Write placement
	//***********************************************************************************
	_write_3d_placement(position, dz=null, dx=null) {
		var origin = this._write_cartesian_point(position);
		var ddx = (dx)?this._write_direction(dx):null;
		var ddz = (dz)?this._write_direction(dz):null;
		return this._write_direct('IFCAXIS2PLACEMENT3D',[origin,ddz,ddx]);
	}

	//***********************************************************************************
	/**
	 * Write placement, using position, dx, dz
	 * @param {step_item} relative
	 * @param {number[]} position
	 * @param {number[]} dz
	 * @param {number[]} dx
	 * @returns {step_item}
	 */
	_write_placement(relative, position, dz=null, dx=null) {
		var rel = this._write_3d_placement(position,dz,dx);
		return this._write_direct('IFCLOCALPLACEMENT',[relative,rel]);
	}

	//***********************************************************************************
	/**
	 * Write placement, using a matrix
	 * @param {step_item} relative
	 * @param {fh_matrix} matrix
	 * @returns {step_item}
	 */
	_write_placement_from_matrix(relative, matrix) {

		var origin = [0,0,0];
		var dx = [0,0,0];
		var dz = [0,0,0];
		for (var k=0;k<3;k++)
		{
			origin[k] = matrix.values[k+12];
			dx[k] = matrix.values[k];
			dz[k] = matrix.values[k+8];
		}
		var rel = this._write_3d_placement(origin,dz,dx);
		return this._write_direct('IFCLOCALPLACEMENT',[relative,rel]);
	}

	//***********************************************************************************
	//**** Site
	//***********************************************************************************
	_write_Site() {

		this._global_placement = this._write_placement(null,[0,0,0]);
		this._site_placement = this._write_placement(this._global_placement,[0,0,0]);

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		fields.push(this._escape_ifc_string(this._building.name));
		// Description
		fields.push('');
		// Objet Type
		fields.push('');
		// Object placement
		fields.push(this._site_placement);
		// Object representation
		fields.push(null);
		// Long name
		fields.push('');
		// Composition type
		fields.push('.ELEMENT.');
		// Latitude
		fields.push(null);
		// Longitude
		fields.push(null);
		// Elevation
		fields.push(null);
		// Landtitlenumber
		fields.push('');
		// Site address
		fields.push(null);

		var site = this._write_direct('IFCSITE',fields);

		var building = this._write_Building();

		this._write_group("IFCRELAGGREGATES",site,[building]);

		return site;
	}

	//***********************************************************************************
	//**** Site
	//***********************************************************************************
	_write_Building() {

		this._building_placement = this._write_placement(this._site_placement,[0,0,0]);

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		fields.push(this._escape_ifc_string(this._building.name));
		// Description
		fields.push('');
		// Objet Type
		fields.push('');
		// Object placement
		fields.push(this._site_placement);
		// Object representation
		fields.push(null);
		// Long name
		fields.push('');
		// Composition type
		fields.push('.ELEMENT.');
		// ElevationOfRefHeight
		fields.push(0);
		// ElevationOfTerrain
		fields.push(0);
		// BuildingAddress
		fields.push(null);

		var building = this._write_direct('IFCBUILDING',fields);

		//*** Write object common data
		for (var i=0; i<this._building.objects.length;i++)
			this._write_object(this._building.objects[i]);

		//*** initialize type data */
		this._type_psets = new Map();

		//*** Write storeys
		var storeys = [];
		var z = 0;
		this._slab_thickness = 0.3;
		this._building.storeys.forEach((storey, i) => {
            const slab_thickness = storey.get_max_slab_thickness();
            this._h0 = z + slab_thickness;
			this._h1 = this._h0 + storey.height;
			this._h2 = this._h1;
            if (i < this._building.storeys.length-1)
				this._h2 += this._building.storeys[i + 1].get_max_slab_thickness();
			storeys.push(this._write_Storey(i));
			z += slab_thickness + storey.height;
        });
		this._write_group("IFCRELAGGREGATES",building,storeys);

        // Write Zones
        this._building.zones['usage'].forEach(zone => {
            const ifc_zone = this._write_zone(zone);
            const ifc_spaces = [];
            zone.rooms.forEach(room => {
                const ifc_associate_space = this.ifc_spaces_by_id.get(room.storey).get(room.space);
                ifc_spaces.push(ifc_associate_space);
            });
            this._write_direct('IFCRELASSIGNSTOGROUP', [this._uuid(), this._owner_history, null, null, ifc_spaces, null, ifc_zone]);
        });

		return building;
	}

	//***********************************************************************************
	//**** objects
	_write_object(object) {

		var source = object.source;
		var shape_representation = null;
		if (source.type != "wikipim")
		{
			//*** Build solid geometry
			var solid = object.build_solid();
			const brep = this._write_faceted_brep(solid);
			shape_representation = this._write_direct("IFCSHAPEREPRESENTATION",[this._3d_subcontext,'Body',"Brep",[brep]]);
		}
		else
		{
			var shapes = this._write_geometries(source.geometries);
			shape_representation = this._write_direct("IFCSHAPEREPRESENTATION",[this._3d_subcontext,'Body','Brep',shapes]);
			object.matrix = object.get_matrix();
		}
		const local_placement = this._write_3d_placement([0,0,0]);
		object.ifc_entity = this._write_direct("IFCREPRESENTATIONMAP",[local_placement,shape_representation]);

	}

    /***********************************************************************************
     * Zones
     ***********************************************************************************/
    _write_zone(zone) {
        const fields = [this._uuid(), this._owner_history, this._escape_ifc_string(zone.name), '', ''];
        const ifc_zone = this._write_direct('IFCZONE', fields);
        return ifc_zone;
    }

	//***********************************************************************************
	//**** Storey
	//***********************************************************************************
    _write_Storey(storey_index) {

		this._storey_placement = this._write_placement(this._building_placement, [0, 0, this._h0]);
		var storey = this._building.storeys[storey_index];

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		fields.push(this._escape_ifc_string(storey.get_storey_name()));
		// Description
		fields.push('');
		// Objet Type
		fields.push('');
		// Object placement
		fields.push(this._storey_placement);
		// Object representation
		fields.push(null);
		// Long name
		fields.push(this._escape_ifc_string(storey.get_storey_name()));
		// Composition type
		fields.push('.ELEMENT.');
		// Elevation
		fields.push(this._h0);

		var ifc_storey = this._write_direct('IFCBUILDINGSTOREY',fields);

		//*** Add slab
		this._structure_elements = [];
		this._write_floor_slabs(storey);
		this._write_roof_slabs(storey);

		//*** Add spaces
		var ifc_spaces = [];
		for (var i in storey.scene.spaces)
		{
			var space = storey.scene.spaces[i];
			if (space.outside) continue;
            const ifc_space = this._write_space(space, storey)
			ifc_spaces.push(ifc_space);
            const storey_ref = this.ifc_spaces_by_id.get(storey.ID) || new Map();
            storey_ref.set(space.ID, ifc_space);
            this.ifc_spaces_by_id.set(storey.ID, storey_ref);
		}
		this._write_group("IFCRELAGGREGATES", ifc_storey, ifc_spaces);

		//** add walls
		for (var i in storey.scene.walls)
		{
			var wall = storey.scene.walls[i];
			if (!wall.valid) continue;
			if (wall.wall_type.free) continue;
			this._structure_elements.push(this._write_wall(storey.scene.walls[i],storey));
		}

		//** add stairs
		for (var i in storey.scene.stairs)
			this._structure_elements.push(this._write_stairs(storey.scene.stairs[i],storey));

		//** add beams
		for (var i in storey.scene.beams)
			this._structure_elements.push(this._write_beam(storey.scene.beams[i],storey));

		//** add columns
		for (var i in storey.scene.columns)
			this._structure_elements.push(this._write_column(storey.scene.columns[i],storey));

		//** add object instances
		for (var i in storey.scene.object_instances)
		{
			var oi = this._write_object_instance(storey.scene.object_instances[i],storey);
			if (oi) this._structure_elements.push(oi);
		}

		this._write_group("IFCRELCONTAINEDINSPATIALSTRUCTURE",this._structure_elements,ifc_storey);

		return ifc_storey;
	}

	//***********************************************************************************
	//**** Storey
	//***********************************************************************************
	_write_space(space, storey) {
		var representation = this._write_representation_solid(space.build_solid(storey),"Spaces");

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
        const space_index = storey.scene.spaces.indexOf(space);
		fields.push(storey.storey_index.toFixed(0));
		// Description
		fields.push('');
		// Objet Type
		fields.push('');
		// Object placement
		fields.push(this._write_placement(this._storey_placement,[0, 0, 0]));
		// Object representation
		fields.push(representation);
		// Long name
		fields.push(this._escape_ifc_string(space.get_name(storey)));
		// Composition type
		fields.push('.ELEMENT.');
		// InteriorOrExteriorSpace
		fields.push(".INTERNAL.");
		// ElevationWithFlooring
		fields.push(null);

		var ifc_space = this._write_direct('IFCSPACE',fields);

		return ifc_space;
	}

	//***********************************************************************************
	//**** Wall
	//***********************************************************************************
	_write_wall(wall,storey) {

		const contour = [];

        let low_offset = wall.spaces[0].slab_offset;
		let high_offset = wall.spaces[0].slab_offset;

        if (!wall.spaces[0].outside && !wall.spaces[1].outside) {
            low_offset = Math.min(wall.spaces[0].slab_offset, wall.spaces[1].slab_offset);
            high_offset = Math.max(wall.spaces[0].slab_offset, wall.spaces[1].slab_offset);
        }
        else if (wall.spaces[0].outside) {
            low_offset = wall.spaces[1].slab_offset;
            high_offset = wall.spaces[1].slab_offset;
        }

        contour.push(wall.shape[0]);
		contour.push(wall.vertices[0].position);
		contour.push(wall.shape[1]);
		contour.push(wall.shape[2]);
		if (wall.delegates[1] == null)
			contour.push(wall.vertices[1].position);
		contour.push(wall.shape[3]);

        contour.forEach(contour => contour.push(low_offset))

		var representation = null;
		if (wall.balcony)
		{
			if (wall.wall_type.category == "railing")
			{
				const extruded_polygons = wall.wall_type.build_extruded_polygons(0, wall);
				representation = this._write_representation_extruded_polygons(extruded_polygons,"Walls");
			}
			else
				representation = this._write_representation([contour], wall.wall_type.height, this._wall_shader, "Walls");
		}
        else
		{
			var solid = new fh_solid();
			var pg = new fh_polygon([0, 0, low_offset], [0, 0, 1]);
			pg.add_contour(contour);
			solid.extrusion(pg, [0, 0, this._roof_height - low_offset]);
			if (this._solid_roof) solid.intersects(this._solid_roof);

			//*** openings */
			if (wall.openings.length > 0) {
				//*** data for hole geometry
				var dx = [wall.bounds.direction[0], wall.bounds.direction[1], 0];
				var dy = [-wall.bounds.direction[1], wall.bounds.direction[0], 0];
				var dz = [0, 0, 1];
				var p0 = [wall.bounds.pmin[0], wall.bounds.pmin[1], high_offset];
				p0 = fh_sub(p0, fh_mul(dy, 0.01));

				//*** loop on openings
				for (var o = 0; o < wall.openings.length; o++) {
					//*** expect valid openings
					var opening = wall.openings[o];
					if (!opening.valid) continue;

					//*** add opening hole
					var opening_solid = opening.opening_type.build_piercing_solid(fh_add(p0, fh_mul(dx, opening.position)), dx, dy, dz, wall.wall_type.thickness + 0.02);
					solid.substracts(opening_solid);
				}
			}

			representation = this._write_representation_solid(solid,"Walls");
		}

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		if (wall.balcony)
		{
			if (wall.wall_type.category == "railing")
				fields.push(this._escape_ifc_string('Balustrade'));
			else
				fields.push(this._escape_ifc_string('Acrotère'));
		}
		else
			fields.push(this._escape_ifc_string('Mur'));
		// Description
		fields.push(null);
		// Objet Type
		fields.push(null);
		// Object placement
		fields.push(this._write_placement(this._storey_placement, [0, 0, 0]));
		// Object representation
		fields.push(representation);
		// Tag
		fields.push(null);

		if (wall.balcony && wall.wall_type.category == "railing")
		{
			fields.push(".BALUSTRADE.");
			var ifc_wall = this._write_direct('IFCRAILING',fields);
		}
		else {
			var ifc_wall = this._write_direct('IFCWALLSTANDARDCASE', fields);
            this._write_element_layer_material(ifc_wall, wall.wall_type, '.AXIS2.');
        }

		for (var i in wall.openings)
		{
			var opening = wall.openings[i];
			if (!opening.valid) continue;

			var ifc_opening_element = this._write_opening_element(opening, wall,storey, high_offset);

			var ifc_opening = this._write_opening(opening, wall, storey, high_offset);

			this._write_direct("IFCRELVOIDSELEMENT",[this._uuid(),this._owner_history,null,null,ifc_wall,ifc_opening_element]);
			this._write_direct("IFCRELFILLSELEMENT",[this._uuid(),this._owner_history,null,null,ifc_opening_element,ifc_opening]);

			this._write_pset_link(ifc_opening,this._write_opening_type(opening.opening_type));
		}

		//*** Write pset */
		if (!wall.balcony)
		{
			this._write_pset_link(ifc_wall,this._write_wall_type(wall.wall_type));
		}

		return ifc_wall;
	}

	/**
	 * Writes wall type as a Pset_WallCommon
	 * @param {cn_wall_type} wall_type
	 * @returns {Array<step_item>}
	 */
	_write_wall_type(wall_type) {
		var pset = this._type_psets.get(wall_type);
		if (typeof(pset) != 'undefined') return pset;
		const values = [];
		values.push(this._write_property_single_value("Reference",wall_type.get_label(),'IFCIDENTIFIER'));
		if (!wall_type.free && wall_type.category != "generic")
			values.push(this._write_property_single_value("ThermalTransmittance",wall_type.get_U(),"IFCTHERMALTRANSMITTANCEMEASURE",3));

		pset = this._write_property_set("Pset_WallCommon",values);
		this._type_psets.set(wall_type,[pset]);
		return [pset];
	}

	/**
	 * Writes wall type as a Pset_WallCommon
	 * @param {cn_slab_type} slab_type
	 * @returns {Array<step_item>}
	 */
	_write_slab_type(slab_type) {
		var pset = this._type_psets.get(slab_type);
		if (typeof(pset) != 'undefined') return pset;
		const values = [];
		values.push(this._write_property_single_value("Reference",slab_type.get_label(),'IFCIDENTIFIER'));
		if (slab_type.category != "generic")
			values.push(this._write_property_single_value("ThermalTransmittance",slab_type.get_U(),"IFCTHERMALTRANSMITTANCEMEASURE",3));

		pset = this._write_property_set("Pset_SlabCommon",values);
		this._type_psets.set(slab_type,[pset]);
		return [pset];
	}

	/**
	 * Writes opening type as a Pset_WallCommon
	 * @param {cn_opening_type} opening_type
	 * @returns {Array<step_item>}
	 */
	_write_opening_type(opening_type) {
		var res = this._type_psets.get(opening_type);
		if (typeof(res) != 'undefined') return res;

		opening_type.compute_physics();
		const psets = [];
		var values = [];
		values.push(this._write_property_single_value("Reference",opening_type.get_label(),'IFCIDENTIFIER'));
		if (!opening_type.free)
			values.push(this._write_property_single_value("ThermalTransmittance",opening_type.Uw,"IFCTHERMALTRANSMITTANCEMEASURE",3));

		values.push(this._write_property_single_value("GlazingAreaFraction",opening_type.get_glazing_area()/opening_type.get_area(),"IFCPOSITIVERATIOMEASURE",2));
		var pset = this._write_property_set((opening_type.category == "window")?"Pset_WindowCommon":"Pset_DoorCommon",values);
		psets.push(pset);

		if (opening_type.glazing != "none" && opening_type.get_glazing_area() > 0)
		{
			values = [];
			const nb_glazings = (opening_type.glazing == "single")?1: (opening_type.glazing == "double")?2:3;
			values.push(this._write_property_single_value("GlassLayers",nb_glazings,'IFCCOUNTMEASURE',0));
			if (nb_glazings >= 2)
				values.push(this._write_property_single_value("FillGas",(opening_type.glazing_gaz == "argon_16")?"argon":"air",'IFCLABEL'));
			const tl = opening_type.Tl * opening_type.get_area() / opening_type.get_glazing_area();
			values.push(this._write_property_single_value("Translucency",tl,'IFCPOSITIVERATIOMEASURE',2));
			const sw = opening_type.Sw * opening_type.get_area() / opening_type.get_glazing_area();
			values.push(this._write_property_single_value("SolarHeatGainTransmittance",sw,'IFCPOSITIVERATIOMEASURE',2));
			pset = this._write_property_set("Pset_DoorWindowGlazingType",values);
			psets.push(pset);
		}

		this._type_psets.set(opening_type,psets);
		return psets;
	}

	/**
	 * Writes link between item and pset
	 * @param {step_item} element
	 * @param {Array<step_item>} psets
	 */
	_write_pset_link(element,psets) {
		psets.forEach(pset => {
			const fields = [];
			fields.push(this._uuid());
			fields.push(this._owner_history);
			fields.push(null);
			fields.push(null);
			fields.push([element]);
			fields.push(pset);
			this._write_direct('IFCRELDEFINESBYPROPERTIES',fields);
		});
	}

    _write_element_layer_material(associate_element, element_type, axis) {
        if (element_type && element_type.layers) {
            const material_set = []
            element_type.layers.forEach((material) => {
                const ifc_material = this._add_or_find_material(material.code, material.name);
                const ifc_material_layer = this._write_direct('IFCMATERIALLAYER', [ifc_material, material.thickness, null]);
                material_set.push(ifc_material_layer);
            });
            const ifc_material_layer_set = this._write_direct('IFCMATERIALLAYERSET', [material_set, element_type.name || '']);
            const ifc_material_layer_set_usage = this._write_direct('IFCMATERIALLAYERSETUSAGE', [ifc_material_layer_set, axis, '.POSITIVE.', 0]);
            this._write_direct('IFCRELASSOCIATESMATERIAL', [this._uuid(), this._owner_history, null, null, [associate_element], ifc_material_layer_set_usage]);
        }
    }

    _add_or_find_material(material_code, material_name) {
        let result = this.ifc_materials.get(material_code);
        if (!result) {
            const ifc_material = this._write_direct('IFCMATERIAL', [this._escape_ifc_string(material_name)]);
            const color_rgb = material_type_colors.get(material_code) || [0.8784313725490196, 1.0, 1.0]
            const presentation_style_assignement = this._write_shader(material_code, color_rgb);
            const styled_item = this._write_direct('IFCSTYLEDITEM', [null, [presentation_style_assignement], null]);
            const styled_representation = this._write_direct('IFCSTYLEDREPRESENTATION',[this._representation_context, 'Style', 'Material', [styled_item]]);
            this._write_direct('IFCMATERIALDEFINITIONREPRESENTATION', [null, null, [styled_representation], ifc_material]);
            this.ifc_materials.set(material_code, ifc_material);
            result = ifc_material;
        }
        return result
    }

	//***********************************************************************************
	//**** IFC opening element
	//***********************************************************************************
	_write_opening_element(opening, wall, storey, high_offset) {

		var direction = cn_clone(wall.bounds.direction);
		var normal = cn_normal(direction);
		var p0 = cn_sub(wall.bounds.pmin,cn_mul(normal,0.01));
		p0 = cn_add(p0,cn_mul(direction,opening.position));
		p0.push(0);
		normal.push(0);
		direction.push(0);
		var extrusion = opening.opening_type.build_piercing_extrusion(p0, direction, normal, [0,0,1], wall.wall_type.thickness + 0.02);
		var representation = null;//this._write_representation_extruded_polygons([extrusion],"Openings");

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		fields.push('Opening');
		// Description
		fields.push(null);
		// Objet Type
		fields.push(null);
		// Object placement
		fields.push(this._write_placement(this._storey_placement, [0, 0, high_offset]));
		// Object representation
		fields.push(representation);
		// Tag
		fields.push(null);

		var ifc_opening_element = this._write_direct('IFCOPENINGELEMENT',fields);

		return ifc_opening_element;
	}

	//***********************************************************************************
	//**** IFC opening element
	//***********************************************************************************
	_write_roof_opening_element(opening,storey) {

		var extrusion = opening.build_local_piercing();
		if (extrusion == null) return null;
		var representation = this._write_representation_extruded_polygons([extrusion],"Openings");

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		fields.push('Opening');
		// Description
		fields.push(null);
		// Objet Type
		fields.push(null);
		// Object placement
		fields.push(this._write_placement_from_matrix(this._storey_placement,opening.build_3d_matrix(storey.height)));
		// Object representation
		fields.push(representation);
		// Tag
		fields.push(null);

		var ifc_opening_element = this._write_direct('IFCOPENINGELEMENT',fields);

		return ifc_opening_element;
	}

	//***********************************************************************************
	//**** IFC opening
	//***********************************************************************************
	_write_opening(opening, wall, storey, high_offset) {

		var extruded_polygons = opening.build_extruded_polygons(0);
		var representation = this._write_representation_extruded_polygons(extruded_polygons,"Windows_Doors");

		var placement = this._write_placement(this._storey_placement,[0, 0, high_offset]);
		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		fields.push(opening.opening_type.category);
		// Description
		fields.push(null);
		// Objet Type
		fields.push(null);
		// Object placement
		fields.push(placement);
		// Object representation
		fields.push(representation);
		// Tag
		fields.push(null);
		// OverallHeight
		fields.push(force_float(opening.opening_type.height));
		// OverallWidth
		fields.push(force_float(opening.opening_type.width));

		var ifc_type = (opening.opening_type.category == "window")?'IFCWINDOW':'IFCDOOR';
		var ifc_opening = this._write_direct(ifc_type,fields);

		this._structure_elements.push(ifc_opening);

		return ifc_opening;
	}

	//***********************************************************************************
	//**** IFC opening
	//***********************************************************************************
	_write_roof_opening(opening,storey) {

		var extruded_polygons = opening.opening_type.build_extruded_polygons(storey.height);
		var representation = this._write_representation_extruded_polygons(extruded_polygons,"Windows_Doors");

		var placement = this._write_placement_from_matrix(this._storey_placement,opening.build_3d_matrix(storey.height));
		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		fields.push(opening.opening_type.category);
		// Description
		fields.push(null);
		// Objet Type
		fields.push(null);
		// Object placement
		fields.push(placement);
		// Object representation
		fields.push(representation);
		// Tag
		fields.push(null);
		// OverallHeight
		fields.push(force_float(opening.opening_type.length));
		// OverallWidth
		fields.push(force_float(opening.opening_type.width));

		var ifc_opening = this._write_direct('IFCWINDOW',fields);

		this._structure_elements.push(ifc_opening);

		return ifc_opening;
	}

	//***********************************************************************************
	//**** Storey
	//***********************************************************************************
	_write_floor_slabs(storey) {
        const slabs = [];
        let polygons_with_slabs = [];
        const max_slab_thickness = storey.get_max_slab_thickness();

        if (storey.slabs && storey.slabs.length) {
            polygons_with_slabs = storey.slabs.filter(slab => !!slab.spaces[1])
                .map(slab => {
                    let slab_z = - slab.slab_type.thickness;
				    if (slab.spaces[1] && !slab.spaces[1].outside) {
                        slab_z += slab.spaces[1].slab_offset;
                    }
                    return { slab: slab, polygon: slab.build_polygon(slab_z) }
                });
        } else {
            polygons_with_slabs = storey.build_slab_polygon(0, true, true).split().map(pol => {  return { slab: null, polygon: pol }});
        }

        polygons_with_slabs.forEach(pol => {
			const solid = new fh_solid();
            const slab_thickness = pol.slab ? pol.slab.slab_type.thickness : this._slab_thickness;
			solid.extrusion(pol.polygon,[0,0,slab_thickness]);
			const representation = this._write_representation_solid(solid,"Slabs");

			var fields = [];
			// Unique id
			fields.push(this._uuid());
			// Owner history
			fields.push(this._owner_history);
			// Name
			if (!this._nb_slabs) this._nb_slabs = 1;
			fields.push("Dalle " + this._nb_slabs);
			this._nb_slabs++;
			// Description
			fields.push(null);
			// Objet Type
			fields.push(null);
			// Object placement
			fields.push(this._write_placement(this._storey_placement, [0, 0, 0]));
			// Object representation
			fields.push(representation);
			// Tag
			fields.push(null);
			// PredefinedType
			fields.push(".FLOOR.");

			const slab = this._write_direct('IFCSLAB',fields);

            if (pol.slab) {
                this._write_element_layer_material(slab, pol.slab.slab_type, '.AXIS1.');
				this._write_pset_link(slab,this._write_slab_type(pol.slab.slab_type));
            }

			this._structure_elements.push(slab);
			slabs.push(slab);
        })

		return slabs;
	}

	//***********************************************************************************
	//**** Storey
	//***********************************************************************************
	_write_roof_slabs(storey) {
		this._solid_roof = null;
		this._roof_height = storey.height;

		storey.roof_volume = storey.build_roof_volume();
		this._solid_roof = storey.roof_volume;
		if (this._solid_roof)
		{
			var box = this._solid_roof.get_bounding_box();
			this._roof_height = box.position[2]+box.size[2];
		}

		if (!storey.roof) return;

		var roofs = [];
		for (var i in storey.roof.slabs)
		{
			var slab = storey.roof.slabs[i];

			var openings = [];
			var opening_positions = [];
			for (var o in storey.roof.openings)
			{
				if (storey.roof.openings[o].slab != slab) continue;
				openings.push(storey.roof.openings[o]);
				var pos = storey.roof.openings[o].position;
				pos.push(storey.height + slab.compute_height(pos));
				opening_positions.push(pos);
			}

			var roof_polygon = slab.build_3d_polygon(storey.height,true);
			var roof_polygons = roof_polygon.split();
			for (var k in roof_polygons)
			{
				var solid = new fh_solid();
				solid.extrusion(roof_polygons[k], [0, 0, slab.slab_type.thickness]);
				solid["shader"] = (Math.abs(slab.slope) < 1)?this._terrace_roof_shader:this._roof_shader;

				var representation = this._write_representation_solid(solid,"roofs");

				var fields = [];
				// Unique id
				fields.push(this._uuid());
				// Owner history
				fields.push(this._owner_history);
				// Name
				if (!this._nb_slabs) this._nb_slabs = 1;
				fields.push("Toiture " + this._nb_slabs);
				this._nb_slabs++;
				// Description
				fields.push(null);
				// Objet Type
				fields.push(null);
				// Object placement
				fields.push(this._write_placement(this._storey_placement,[0, 0, 0]));
				// Object representation
				fields.push(representation);
				// Tag
				fields.push(null);
				// PredefinedType
				fields.push(".ROOF.");

				const roof_type = (CN_ROOF_MODE==0)?'IFCSLAB':'IFCROOF'
				var slab0 = this._write_direct(roof_type,fields);
				//this._structure_elements.push(slab);
                this._write_element_layer_material(slab0, slab.slab_type, '.AXIS1.');

				if (roof_type == 'IFCSLAB')
					this._write_pset_link(slab0,this._write_slab_type(slab.slab_type));

				roofs.push(slab0);
				for (var oi=0;oi< openings.length;oi++)
				{
					if (!roof_polygons[k].contains(opening_positions[oi])) continue;

					var ifc_opening_element = this._write_roof_opening_element(openings[oi],storey);

					var ifc_opening = this._write_roof_opening(openings[oi],storey);

					this._write_direct("IFCRELVOIDSELEMENT",[this._uuid(),this._owner_history,null,null,slab0,ifc_opening_element]);
					this._write_direct("IFCRELFILLSELEMENT",[this._uuid(),this._owner_history,null,null,ifc_opening_element,ifc_opening]);
				}
			}
		}

		if (CN_ROOF_MODE == 0)
		{
			var fields = [];
			// Unique id
			fields.push(this._uuid());
			// Owner history
			fields.push(this._owner_history);
			// Name
			if (!this._nb_roofs) this._nb_roofs = 1;
			fields.push("Toiture " + this._nb_roofs);
			this._nb_roofs++;
			// Description
			fields.push(null);
			// Objet Type
			fields.push(null);
			// Object placement
			fields.push(this._write_placement(this._storey_placement,[0,0, 0]));
			// Object representation
			fields.push(representation);
			// Tag
			fields.push(null);
			// PredefinedType
			fields.push(null);

			var roof = this._write_direct('IFCROOF',fields);
			this._structure_elements.push(roof);
			this._write_group("IFCRELAGGREGATES",roof,roofs);
		}
		else
		{
			roofs.forEach(r => this._structure_elements.push(r));
		}
	}

	//***********************************************************************************
	//**** stairs
	//***********************************************************************************
	_write_stairs(stairs, storey) {

		var representation = this._write_representation_polygons(stairs.build_polygons(0,storey.height),"Stairs",this._stairs_shader);
		if (representation == null) return null;
		var placement = this._write_placement(this._storey_placement,[0,0,this._slab_thickness]);

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		if (!this._nb_stairs) this._nb_stairs = 1;
		fields.push("Escalier " + this._nb_stairs);
		this._nb_stairs++;
		// Description
		fields.push(null);
		// Objet Type
		fields.push(null);
		// Object placement
		fields.push(placement);
		// Object representation
		fields.push(representation);
		// Tag
		fields.push(null);
		// ShapeType
		fields.push(null);

		var ifc_element = this._write_direct("IFCSTAIR",fields);

		return ifc_element;
	}

	//***********************************************************************************
	//**** beam
	//***********************************************************************************
	_write_beam(beam, storey) {

		var representation = this._write_representation_extruded_polygons([beam.build_extruded_polygon(0,storey)],"Beams");

		var placement = this._write_placement(this._storey_placement,[0,0,this._slab_thickness]);

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		if (!this._nb_beams) this._nb_beams = 1;
		fields.push("Poutre " + this._nb_beams);
		this._nb_beams++;
		// Description
		fields.push(null);
		// Objet Type
		fields.push(null);
		// Object placement
		fields.push(placement);
		// Object representation
		fields.push(representation);
		// Tag
		fields.push(null);

		var ifc_beam = this._write_direct("IFCBEAM",fields);

		return ifc_beam;
	}

	//***********************************************************************************
	//**** Column
	//***********************************************************************************
	_write_column(column, storey) {

		var representation = this._write_representation_extruded_polygons([column.build_extruded_polygon(-this._slab_thickness,storey,this._slab_thickness)],"Columns");

		var placement = this._write_placement(this._storey_placement,[0,0,this._slab_thickness]);

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		if (!this._nb_columns) this._nb_columns = 1;
		fields.push("Poteau " + this._nb_columns);
		this._nb_columns++;
		// Description
		fields.push(null);
		// Objet Type
		fields.push(null);
		// Object placement
		fields.push(placement);
		// Object representation
		fields.push(representation);
		// Tag
		fields.push(null);

		var ifc_column = this._write_direct("IFCCOLUMN",fields);

		return ifc_column;
	}

	//***********************************************************************************
	//**** object instance
	//***********************************************************************************
	_write_object_instance(instance, storey) {

		if (!instance.object || !instance.object.ifc_entity || instance.virtual) return null;
		var matrix = instance.build_3d_matrix(0, storey);
		if (instance.object.matrix) matrix.multiplies(instance.object.matrix);
		var shape = this._write_mapped_item(instance.object.ifc_entity);
		var representation = this._write_product_definition_shape([shape],"objects","MappedRepresentation");

		const p0 = [0,0,0];
		const dz = [0,0,0];
		const dx = [0,0,0];
		for (var k = 0; k < 3; k++)
		{
			dx[k] = matrix.values[k];
			dz[k] = matrix.values[k + 8];
			p0[k] = matrix.values[k + 12];
		}
		p0[2] += this._slab_thickness;
		var placement = this._write_placement(this._storey_placement,p0,dz,dx);

		var fields = [];
		// Unique id
		fields.push(this._uuid());
		// Owner history
		fields.push(this._owner_history);
		// Name
		fields.push(instance.object.name);
		// Description
		fields.push(null);
		// Objet Type
		fields.push(null);
		// Object placement
		fields.push(placement);
		// Object representation
        // TODO FVI la representation fait planter les viewers BIM
		fields.push(null);
		fields.push(representation);
		// Tag
		fields.push(null);
		// Composition type
		fields.push(null);

		var ifc_element = this._write_direct("IFCBUILDINGELEMENTPROXY",fields);

		return ifc_element;
	}

	//***********************************************************************************
	//**** Build a shader
	//***********************************************************************************
	_write_shader(name, color, transparency = 0) {
		var color0 = this._write_direct("IFCCOLOURRGB",[null, color[0], color[1], color[2]]);
		var surface_rendering = this._write_direct("IFCSURFACESTYLERENDERING", [color0, transparency, null, null, null, null, "@IFCNORMALISEDRATIOMEASURE(0.5)", "@IFCSPECULAREXPONENT(10.)", ".NOTDEFINED."]);
		var surface_style = this._write_direct("IFCSURFACESTYLE",[name, ".BOTH.", [surface_rendering]]);
		var shader = this._write_direct("IFCPRESENTATIONSTYLEASSIGNMENT", [[surface_style]]);
		return shader;
	}

	_write_color_shader(color) {
		if (!color) return null;
		var name = "";
		for (var i in color)
			name += "_" + color[i].toFixed(2);

		if (typeof(this._color_shaders[name]) == 'undefined')
			this._color_shaders[name] = this._write_shader("",color,(color.length>= 4)?1-color[3]:0);

		return this._color_shaders[name];
	}

	_set_shader(representation_item, shader) {
		if (shader && representation_item) {
		    this._write_direct("IFCSTYLEDITEM", [representation_item, [shader], null]);
        }
	}

	//***********************************************************************************
	//**** Build a solid
	//***********************************************************************************
	_write_solid(contours, extrusion_height, shader, z=0) {

		//*** Build contours
		var ifc_outer_contour;
		var ifc_inner_contours = [];
		for (var i=0;i<contours.length;i++)
		{
			cn_simplify_contour(contours[i]);
			if (contours[i].length<3) continue;
			var points = [];
			for (var j=0;j<contours[i].length;j++)
				points.push(this._write_cartesian_point(contours[i][j]));

			var ctr = this._write_direct("IFCPOLYLINE",[points]);
			if (i == 0)
				ifc_outer_contour = ctr;
			else
				ifc_inner_contours.push(ctr);
		}

		//*** Build profile
		var profile;
		if (ifc_inner_contours.length == 0)
			profile = this._write_direct("IFCARBITRARYCLOSEDPROFILEDEF",[".AREA.",null,ifc_outer_contour]);
		else
			profile = this._write_direct("IFCARBITRARYPROFILEDEFWITHVOIDS",[".AREA.",null,ifc_outer_contour,ifc_inner_contours]);

		//*** Build solid
		var solid = this._write_direct("IFCEXTRUDEDAREASOLID",[profile,this._write_3d_placement([0,0,z],null,null),this._vertical_direction,force_float(extrusion_height)]);

		//*** Build shader
		this._set_shader(solid,shader);

		return solid;
	}

	//***********************************************************************************
	//**** Build an extrusion
	//***********************************************************************************
	_write_extrusion(extruded_polygons) {
		var solids = [];
		for (var i in extruded_polygons)
		{
			var extrusion = extruded_polygons[i];
			extrusion.polygon.compute_contours();

			//*** build matrix
			var dz = extrusion.matrix.transform_vector(extrusion.direction);
			var extrusion_height = fh_normalize(dz);
			var dx = [0,0];
			var dy = [0,0];
			fh_build_axis(dz,dx,dy);
			var p0 = extrusion.matrix.transform_point(extrusion.polygon.get_point());

			//*** Build contours
			var ifc_outer_contour;
			var ifc_inner_contours = [];
			var offset = 0;
			var vertices = extrusion.polygon.contour_vertices;
			for (var nct=0;nct<extrusion.polygon.contour_sizes.length;nct++)
			{
				var sz = extrusion.polygon.contour_sizes[nct];

				var points = [];
				for (var nv=0;nv<sz;nv++)
				{
					var pp = fh_sub(extrusion.matrix.transform_point(vertices[offset+nv]),p0);
					var ppp = [fh_dot(pp,dx),fh_dot(pp,dy),fh_dot(pp,dz)]
					points.push(this._write_cartesian_point(ppp));
				}
				offset += sz;

				var ctr = this._write_direct("IFCPOLYLINE",[points]);
				if (nct == 0)
					ifc_outer_contour = ctr;
				else
					ifc_inner_contours.push(ctr);
			}

			//*** Build profile
			var profile;
			if (ifc_inner_contours.length == 0)
				profile = this._write_direct("IFCARBITRARYCLOSEDPROFILEDEF",[".AREA.",null,ifc_outer_contour]);
			else
				profile = this._write_direct("IFCARBITRARYPROFILEDEFWITHVOIDS",[".AREA.",null,ifc_outer_contour,ifc_inner_contours]);

			//*** Build solid
			var ifcdz = this._write_direction(dz);
			var ifcdx = this._write_direction(dx);

			var solid = this._write_direct("IFCEXTRUDEDAREASOLID",[profile,this._write_3d_placement(p0,dz,dx),this._vertical_direction,force_float(extrusion_height)]);

			//*** Build shader
			this._set_shader(solid,this._write_color_shader(extrusion.color));

			solids.push(solid);
		}
		return solids;
	}

	//***********************************************************************************
	//**** Build a representation with one single extruded contour set
	//***********************************************************************************
	_write_solids_representation(solids, layer_name) {

		//*** build shape
		var shape_representation = this._write_direct("IFCSHAPEREPRESENTATION",[this._3d_subcontext,'Body','SweptSolid',solids]);
		if (typeof(this._layers[layer_name]) == 'undefined')
			this._layers[layer_name] = [shape_representation];
		else
			this._layers[layer_name].push(shape_representation);

		//*** build representation
		var representation = this._write_direct("IFCPRODUCTDEFINITIONSHAPE",[null,null,[shape_representation]]);
		return representation;
	}

	//***********************************************************************************
	//**** Build a representation with one single extruded contour set
	//***********************************************************************************
	_write_representation(contours, extrusion_height, shader, layer_name) {
		const solid = this._write_solid(contours, extrusion_height, shader);
		return this._write_solids_representation([solid], layer_name);
	}

	//***********************************************************************************
	//**** Build a representation with varaious extrusions
	//***********************************************************************************
	_write_representation_extruded_polygons(extruded_polygons, layer_name) {
		var solids = this._write_extrusion(extruded_polygons);
		return this._write_solids_representation(solids,layer_name);
	}

	//***********************************************************************************
	//**** Build a face
	//***********************************************************************************
	_write_face(polygon) {

		polygon.compute_contours();

		//*** Build contours
		var bounds = [];
		var vertices = polygon.contour_vertices;
		var offset = 0;
		for (var nct=0;nct<polygon.contour_sizes.length;nct++)
		{
			var sz = polygon.contour_sizes[nct];

			var pg_vertices = [];
			for (var nv=0;nv<sz;nv++)
				pg_vertices.push(vertices[offset+nv]);

			cn_simplify_contour(pg_vertices,0.01);

			offset += sz;

			if (pg_vertices.length > 2)
			{
				const points = pg_vertices.map(v => this._write_cartesian_point(v));
				var ctr = this._write_direct("IFCPOLYLOOP",[points]);
				bounds.push(this._write_direct("IFCFACEBOUND",[ctr,(nct==0)]));
			}
			else if (nct == 0) return null;
		}

		var face = this._write_direct("IFCFACE",[bounds]);

		return face
	}

	//***********************************************************************************
	//**** Build a face
	//***********************************************************************************
	_write_triangle(v0, v1, v2) {

		//*** Build contours
		var bounds = [];
		var points = [];
		points.push(this._write_cartesian_point(v0));
		points.push(this._write_cartesian_point(v1));
		points.push(this._write_cartesian_point(v2));
		var ctr = this._write_direct("IFCPOLYLOOP",[points]);
		bounds.push(this._write_direct("IFCFACEBOUND",[ctr,true]));
		var face = this._write_direct("IFCFACE",[bounds]);

		return face
	}

	//***********************************************************************************
	//**** Build a BRep
	//***********************************************************************************
	_write_face_based_surface_model(polygons) {

		var faces = [];
		for (var i in polygons)
		{
			const f = this._write_face(polygons[i]);
			if (f) faces.push(f);
		}
		if (faces.length == 0) return null;

		var connected_face_set = this._write_direct("IFCCONNECTEDFACESET",[faces]);

		return this._write_direct("IFCFACEBASEDSURFACEMODEL",[[connected_face_set],3]);
	}

	//***********************************************************************************
	//**** Build representation of a list of geometries
	//***********************************************************************************
	_write_geometries(geometries)
	{
		var items = [];
		for (var i in geometries)
		{
			var geometry = geometries[i];
			var faces = [];

			for (var j=0;j<geometry.triangles.length;j+=3)
			{
				var t = geometry.triangles[j];
				var v0 = [geometry.vertices[3*t],geometry.vertices[3*t+1],geometry.vertices[3*t+2]];
				t = geometry.triangles[j+1];
				var v1 = [geometry.vertices[3*t],geometry.vertices[3*t+1],geometry.vertices[3*t+2]];
				t = geometry.triangles[j+2];
				var v2 = [geometry.vertices[3*t],geometry.vertices[3*t+1],geometry.vertices[3*t+2]];
				faces.push(this._write_triangle(v0,v1,v2));
			}

			var connected_face_set = this._write_direct("IFCCONNECTEDFACESET",[faces]);

			var item = this._write_direct("IFCFACEBASEDSURFACEMODEL",[[connected_face_set],3]);
			items.push(item);

			//*** Build shader
			if (geometry.color)
				this._set_shader(item,this._write_color_shader(geometry.color));
		}
		return items;
	}

	//***********************************************************************************
	//**** Build a BRep
	//***********************************************************************************
	_write_faceted_brep(solid) {

		var faces = [];
		var polygons = solid.get_faces();
		for (var i in polygons)
		{
			const f = this._write_face(polygons[i]);
			if (f) faces.push(f);
		}
		if (faces.length == 0) return null;

		var closed_shell =  this._write_direct("IFCCLOSEDSHELL",[faces]);

		var item = this._write_direct("IFCFACETEDBREP",[closed_shell]);
		this._set_shader(item,solid.shader);
		return item;
	}

	//***********************************************************************************
	//**** Build a mapped item
	_write_mapped_item(ifc_entity)
	{
		var placement = this._write_direct("IFCCARTESIANTRANSFORMATIONOPERATOR3D",[null,null,this._origin,1.0,null]);

		/*const local_placement = this._write_3d_placement([0,0,0]);
		var shape_representation = this._write_direct("IFCSHAPEREPRESENTATION",[this._3d_subcontext,'Body',"BRep",[ifc_entity]]);
		var map = this._write_direct("IFCREPRESENTATIONMAP",[local_placement,shape_representation]);*/
		return this._write_direct("IFCMAPPEDITEM",[ifc_entity,placement]);
	}

	//***********************************************************************************
	//**** Build a representation with a list of polygons
	//***********************************************************************************
	_write_representation_polygons(polygons, layer_name, shader=null) {

		var shape = this._write_face_based_surface_model(polygons);
		if (shape == null) return null;
		this._set_shader(shape,shader);
		return this._write_product_definition_shape([shape],layer_name);
	}

	//***********************************************************************************
	//**** Build a representation with a solid
	//***********************************************************************************
	_write_representation_solid(solid, layer_name) {

		var shape = this._write_faceted_brep(solid);
		if (shape == null) return null;
		return this._write_product_definition_shape([shape],layer_name);
	}

	//***********************************************************************************
	//**** Build a product_definition_shape
	//***********************************************************************************
	_write_product_definition_shape(shapes, layer_name, representation_type = "") {

		//*** build shape
		var shape_representation = this._write_direct("IFCSHAPEREPRESENTATION",[this._3d_subcontext,'Body',representation_type,shapes]);
		if (typeof(this._layers[layer_name]) == 'undefined')
			this._layers[layer_name] = [shape_representation];
		else
			this._layers[layer_name].push(shape_representation);

		//*** build representation
		var representation = this._write_direct("IFCPRODUCTDEFINITIONSHAPE",[null,null,[shape_representation]]);
		return representation;
	}

	_escape_ifc_string(txt)
	{
		var txt2 = "";
		for (var i = 0; i < txt.length; i++)
		{
			if (txt[i] == "'")
				txt2 += "\\X\\27";
			else
			{
				var n = Number(txt.charCodeAt(i));
				if (n < 128)
					txt2 += txt[i];
				else
					txt2 += "\\X\\" + n.toString(16).toUpperCase();
			}
		}
		return txt2;
	}

	/**
	 * Writes a property set
	 * @param {string} name
	 * @param {Array<step_item>} properties
	 * @returns {step_item}
	 */
	_write_property_set(name,  properties)	{
		return this._write_direct("IFCPROPERTYSET",[this._uuid(),this._owner_history,name,null,properties]);
	}

	/**
	 * Writes a single value property
	 * @param {string} name
	 * @param {boolean | number | string} value
	 * @param {string} value_type
	 * @param {number} decimals
	 * @returns  {step_item}
	 */
	_write_property_single_value(name,  value, value_type, decimals=3) {

		var xvalue = "@" + value_type+ "(";
		if (typeof(value) == 'boolean')
			xvalue += (value)?".T.":".F.";
		else if (typeof(value) == 'number')
			xvalue += value.toFixed(decimals);
		else if (typeof(value) == 'string')
			xvalue += "'" + this._escape_ifc_string(value) + "'";
		xvalue += ")";
		return this._write_direct("IFCPROPERTYSINGLEVALUE",[name, null, xvalue, null]);
	}
}



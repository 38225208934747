"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_handlerspace_drag
//***********************************************************************************
//***********************************************************************************

import { cn_clone, cn_dist, cn_dot, cn_mul, cn_normalize, cn_polar, cn_size, cn_sub } from "../utils/cn_utilities";
import { cn_handler_object } from "./cn_handler_object";
import { cn_camera } from "./cn_camera";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_event_handler} from "./cn_event_handler";

export class cn_handler_space_drag extends cn_event_handler{
	constructor(object, scene, storey) {
		super();
		this.object = object;
		this.new_object = new cn_handler_object(0,0);
		this.new_object.copy(object);
		this.storey = storey;

		this.scene = scene;
		this._radius = 20;
		//(this.object.width < this.object.height)?this.object.width/4:this.object.height/4;

		this._mouseover = 0;
		this._grabbed = false;
		this._grab_center = false;
		this._grab_local = [0,0];
		this._grab_mouse = [0,0];
		this.space = null;
		for (var i in this.scene.spaces)
		{
			if (!this.scene.spaces[i].contains(this.object.center)) continue;
			this.space = this.scene.spaces[i];
			break;
		}
		this._collisions = [];
		this._angular_precision = 0.001/(this.object.width + this.object.height);
	}

	//*****************************************************************
	//*** Draw the handler
	//*****************************************************************
	draw(camera) {
		var html = "";

		var add_classes = "";
		if (this._grabbed && !this._grab_center)
			add_classes = " line_selected";
		else if (this._mouseover === 2)
			add_classes = " line_mouseover";

		add_classes = "";
		if (this._grabbed && this._grab_center)
			add_classes = " selected";
		else if (this._mouseover === 1)
			add_classes = " mouseover";

		return html;
	}

	//*****************************************************************
	//*** Clear move data
	//*****************************************************************
	clear_move() {
		this._mouseover = 0;
	}

	/**
     * Manage a passive move. To return 'true' if something of interest under the mouse.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
	 move(mouse_event) {
		this._mouseover = 0;

		//** is moue over center pastille ?
		var d = cn_sub(mouse_event.mouse_world,this.object.center);
		if (cn_size(d) < this._radius * mouse_event.camera.screen_to_world_scale)
		{
			this._mouseover = 1;
			return true;
		}

		//*** is mouse over the object ?
		var x = cn_dot(d,this.object.dx);
		if (Math.abs(x) > this.object.width * 0.5) return false;
		var y= cn_dot(d,this.object.dy);
		if (Math.abs(y) > this.object.height * 0.5) return false;
		this._mouseover = 2;

		return true;
	}

    /**
     * Manage a grab. To return 'true' if grab is to be managed.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
	 grab(mouse_event) {
		this._grabbed = false;
		this._grab_local = this.object.global_to_local(mouse_event.mouse_world);
		this._grab_mouse = [mouse_event.mouse_world[0],mouse_event.mouse_world[1]];
		var d = cn_sub(mouse_event.mouse_world,this.object.center);
		if (cn_size(d) < this._radius * mouse_event.camera.screen_to_world_scale)
		{
			this._grabbed = true;
			this._grab_center = true;
			return true;
		}
		if (Math.abs(this._grab_local[0]) > this.object.width * 0.5) return false;
		if (Math.abs(this._grab_local[1]) > this.object.height * 0.5) return false;
		this._grabbed = true;
		this._grab_center = false;
		return true;
	}

	grab_element(ev) {
		this._grabbed = true;
		this._grab_center = true;
		this._grab_local = [0,0];
		return true;
	}

    //*****************************************************************
    /**
     * Check a given place in the scene
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean} true if element can be placed here
     */
    check_place(mouse_event) {
        this._grab_mouse = cn_clone(mouse_event.mouse_world);
        this._grabbed = true;
        this._grab_center = true;
        this._grab_local = [0,0];
        return this._check_place(mouse_event);
    }

//*****************************************************************
    /**
     * Check a given place in the scene
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean} true if element can be placed here
     */
    _check_place(mouse_event) {
        ///*** Compute the new position of the object, not taking into account collisions
        if (this._grab_center)
        {
            this.new_object.translate(cn_sub(mouse_event.mouse_world,this.object.local_to_global(this._grab_local)));
        }
        else
        {
            var a0 = cn_polar(cn_sub(this._grab_mouse,this.object.center))[1];
            var a1 = cn_polar(cn_sub(mouse_event.mouse_world,this.object.center))[1];
            var da = a1-a0;
            while (da > Math.PI) da -= Math.PI*2;
            while (da < -Math.PI) da += Math.PI*2;
            this.new_object.rotate_local(this._grab_local,da);
            this.new_object.translate(cn_sub(mouse_event.mouse_world,this.object.local_to_global(this._grab_local)));
        }

        //*** update mouse position
        this._grab_mouse = [mouse_event.mouse_world[0],mouse_event.mouse_world[1]];

        //*** Compute collisions
        this._collisions = this._find_collisions(this.new_object);

        //*** end if no collisions
        if (this._collisions.length === 0)
        {
            this.object.copy(this.new_object);
            return true;
        }

        //*** Place tmp object at initial position
        var tmp_object = new cn_handler_object(0,0);
        tmp_object.copy(this.object);
        var dst = cn_dist(mouse_event.mouse_world,this.object.local_to_global(this._grab_local));
        if (dst < 0.001) return false;
        var precision = 1/(1000*dst);

        //*** wait for first collision
        this._collisions = this._expect_collisions(tmp_object,precision);

        //*** Maybe one single collision at first
        if (this._collisions.length === 1)
            this._manage_single_collision(this._collisions[0]);

        //*** Maybe several at once
        if (this._collisions.length > 1)
            this._manage_several_collisions();

        this.new_object.copy(this.object);

        //*** Maybe we moved to another space ?
        if (this.space == null || !this.space.contains(this._grab_mouse))
        {
            var previousspace = this.space;
            for (var i in this.scene.spaces)
            {
                if (!this.scene.spaces[i].contains(this._grab_mouse)) continue;
                this.space = this.scene.spaces[i];
                break;
            }
            this.new_object.translate(cn_sub(this._grab_mouse,this.object.local_to_global(this._grab_local)));
            if (this.space && this._find_collisions(this.new_object).length === 0)
            {
                this.object.copy(this.new_object);
            }
            else
            {
                this.new_object.copy(this.object);
                this.space = previousspace;
            }
        }

        return true;
    }

    /**
     * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns  {boolean}
     */
	 drag(mouse_event) {
		if (!this._grabbed) return false;

		///*** just translate the object
		if (this._grab_center)
		{
			this.new_object.translate(cn_sub(mouse_event.mouse_world,this.object.local_to_global(this._grab_local)));
		}
		else
		{
			//*** Drag the object
			var a0 = cn_polar(cn_sub(this._grab_mouse,this.object.center))[1];
			var a1 = cn_polar(cn_sub(mouse_event.mouse_world,this.object.center))[1];
			var da = a1-a0;
			while (da > Math.PI) da -= Math.PI*2;
			while (da < -Math.PI) da += Math.PI*2;
			this.new_object.rotate_local(this._grab_local,da);
			this.new_object.translate(cn_sub(mouse_event.mouse_world,this.object.local_to_global(this._grab_local)));
		}

		this._grab_mouse = [mouse_event.mouse_world[0],mouse_event.mouse_world[1]];
		this._collisions = this._find_collisions(this.new_object);
		if (this._collisions.length === 0)
		{
			this.object.copy(this.new_object);
			return true;
		}

		var tmp_object = new cn_handler_object(0,0);
		tmp_object.copy(this.object);
		var dst = cn_dist(mouse_event.mouse_world,this.object.local_to_global(this._grab_local));
		if (dst < 0.001) return false;
		var precision = 1/(1000*dst);

		this._collisions = this._expect_collisions(tmp_object,precision);
        if (this._collisions.length === 1)
            this._manage_single_collision(this._collisions[0]);
        if (this._collisions.length > 1)
            this._manage_several_collisions();
		this.new_object.copy(this.object);

		//*** Maybe we moved to another space ?
		if (this.space == null || !this.space.contains(this._grab_mouse))
		{
			var previousspace = this.space;
			for (var i in this.scene.spaces)
			{
				if (!this.scene.spaces[i].contains(this._grab_mouse)) continue;
				this.space = this.scene.spaces[i];
				break;
			}
			this.new_object.translate(cn_sub(this._grab_mouse,this.object.local_to_global(this._grab_local)));
			if (this.space && this._find_collisions(this.new_object).length === 0)
			{
				this.object.copy(this.new_object);
			}
			else
			{
				this.new_object.copy(this.object);
				this.space = previousspace;
			}
		}

		return true;
	}

	//*****************************************************************
	//*** Manage one collision
	//*****************************************************************
	_manage_single_collision(rotation_center)
	{
		var a0 = cn_polar(cn_sub(this.object.local_to_global(this._grab_local),rotation_center))[1];
		var a1 = cn_polar(cn_sub(this._grab_mouse,rotation_center))[1];
		var da = a1-a0;
		while (da > Math.PI) da -= Math.PI*2;
		while (da < -Math.PI) da += Math.PI*2;

		this.new_object.copy(this.object);
		this.new_object.rotate(rotation_center,da);
		if (this._find_collisions(this.new_object).length === 0)
		{
			this.object.copy(this.new_object);
			return [];
		}

		var da0 = 0;
		var da1 = da;
		console.log("manage rotation",this._angular_precision);
		var new_collisions = [];
		while (Math.abs(da1-da0) > this._angular_precision)
		{
			console.log(da0,da1);
			da = 0.5*(da0+da1);
			this.new_object.copy(this.object);
			this.new_object.rotate(rotation_center,da);
			var collisions = this._find_collisions(this.new_object);
			var actual_collisions = [];
			for (var n=0;n<collisions.length;n++)
			{
				if (cn_dist(collisions[n],rotation_center) > 0.01)
					actual_collisions.push(collisions[n]);
			}

			if (actual_collisions.length > 0)
			{
				new_collisions = actual_collisions;
				da1 = da;
			}
			else
				da0 = da;
		}
		this._collisions = this._collisions.concat(new_collisions);
		this.new_object.copy(this.object);
		this.new_object.rotate(rotation_center,da0);
		this.object.copy(this.new_object);
	}

	//*****************************************************************
	//*** Manage one collision
	//*****************************************************************
	_manage_several_collisions()
	{
		var dir = cn_sub(this._collisions[1],this._collisions[0]);
		if (cn_normalize(dir) < 0.01) return;
		var delta = cn_sub(this._grab_mouse,this.object.local_to_global(this._grab_local));
		var x = cn_dot(dir,delta);
		if (Math.abs(x) < 0.001) return;
		this.new_object.copy(this.object);
		this.new_object.translate(cn_mul(dir,x));
		var tmp_object = new cn_handler_object(0,0);
		tmp_object.copy(this.object);
		this._expect_collisions(tmp_object,1/(1000*Math.abs(x)));
	}

    /**
     * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
     * @param {cn_mouse_event} mouse_event
     * @returns {boolean}
     */
	 drop(mouse_event) {
		this._grabbed = false;
		return true;
	}

	//*****************************************************************
	//*** Expect collisions
	//*****************************************************************
	_expect_collisions(object, precision)
	{
		var t0 = 0;
		var t1 = 1;
		var collisions = this._find_collisions(this.new_object);
		while (t1-t0 > precision)
		{
			var tt = 0.5*(t0+t1);
			object.interpolate(tt,this.object,this.new_object);
			var cc = this._find_collisions(object);
			if (cc.length > 0)
			{
				collisions=cc;
				t1=tt;
			}
			else
				t0=tt;
		}
		object.interpolate(t0,this.object,this.new_object);
		this.object.copy(object);
		return collisions;
	}

	//*****************************************************************
	//*** Update position
	//*****************************************************************
	_find_collisions(object){
		var collisions = [];
		if (this.space == null) return collisions;
		for (var k=0;k<4;k++)
		{
			if (!this.space.contains(object.vertices[k],true))
				collisions.push(cn_clone(object.vertices[k]));
		}
		for (var k=0;k<this.space.contours.length;k++)
		{
			var ctr = this.space.contours[k].inner_contour;
			for (var n in ctr)
			{
				if (object.contains(ctr[n]))
					collisions.push(cn_clone(ctr[n]));
			}
		}
		return collisions;
	}
}


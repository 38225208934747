"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Roof controller
//***********************************************************************************
//***********************************************************************************

import {cn_roof_vertex} from "../model/cn_roof_vertex";
import {cn_roof_line} from "../model/cn_roof_line";
import {cn_roof_slab} from "../model/cn_roof_slab";
import {cn_roof_opening} from "../model/cn_roof_opening";
import {cn_roof} from "../model/cn_roof";
import {cn_marker, cn_sampling} from '..';

export class cn_roof_controller {
	constructor(scene) {
		var obj=this;

		//*** Scene data
		this._scene = scene;
		this._storey = scene.storey;

		//*** Selection data
		this._mouseover = null;
		this._selection = [];

		//*** filter data
		this._element_names = ["outer_lines","inner_lines","slabs","openings", "samplings", "markers"];
		this._element_labels = ["Bordures de toit","Lignes de toit","Pans de toiture","Ouvrants",  "Prélèvements", "Annotations"];
		this._element_filter = [];
		for (var i in this._element_names)
			this._element_filter[this._element_names[i]] = true;
		this._element_count = [];
		for (var i in this._element_names)
			this._element_count[this._element_names[i]] = 0;
		this.update_element_count();
	}

	//***********************************************************************************
	//**** Selection methods
	//***********************************************************************************
	get_selection(name_filter = "") {
		if (name_filter == "")
			return this._selection;
		var sel = [];
		for (var i in this._selection)
		{
			if (name_filter == this.find_element_name(this._selection[i]))
				sel.push(this._selection[i]);
		}
		return sel;
	}

	get_selection_delegates() {return []};

	select_element(elt, clear_selection=true) {
		if (clear_selection)
			this.clear_selection();
		if (this._selection.indexOf(elt) < 0)
			this._selection.push(elt);
	}

	select_element_list(elements) {
		this.clear_selection();
		elements.forEach(e => this.select_element(e,false));
	}

	unselect_element(elt) {
		var index = this._selection.indexOf(elt);
		if (index >= 0)
			this._selection.splice(index,1);
	}

	get_mouseover() {
		return this._mouseover;
	}

	get_mouseover_delegate() {
		return null;
	}

	set_mouseover(elt) {
		this._mouseover = elt;
	}

	clear_selection() {
		this._mouseover = null;
		this._selection = [];
	}

	is_selected(elt) {
		return (this._selection.indexOf(elt) >= 0);
	}

	//***********************************************************************************
	//**** element data
	//***********************************************************************************
	get_element_names() {
		return this._element_names;
	}

	get_element_label(name) {
		return this._element_labels[this._element_names.indexOf(name)];
	}

	get_element_count(name) {
		return this._element_count[name];
	}

	get_element_filter(name) {
		if (name != "vertices")
			return this._element_filter[name];
		return (this.get_element_filter("outer_lines") || this.get_element_filter("inner_lines"));
	}

	set_element_filter(name, value) {
		if (name == "vertices") return;
		this._element_filter[name] = value;

		var new_status = (value)?0:-1;
		var elts = this.get_elements(name);
		for (var i in elts)
		{
			elts[i].status = new_status;
		}

		//*** deselected disabled elements
		if (!value)
		{
			var new_selection = [];
			for (var i in this._selection)
			{
				var sname = this.find_element_name(this._selection[i]);
				if (name != sname)
					new_selection.push(this._selection[i]);
			}
			this._selection = new_selection;
		}
	}

	//*** checks if a given element passes the filters
	check_filter(elt) {
		if (elt.constructor != cn_roof_vertex)
			return this.get_element_filter(this.find_element_name(elt));

		//*** specific treatment for vertices
		for (var i in elt.lines)
		{
			if (this.get_element_filter(this.find_element_name(elt.lines[i])))
				return true;
		}
		return false;
	}

	//*** return all elements with a given name
	get_elements(name) {
		var elts = [];

		//*** vertices
		if (name == "vertices")
			elts = elts.concat(this._scene.vertices);

		//*** Lines
		else if (name == "inner_lines" || name == "outer_lines")
		{
			for (var i  in this._scene.lines)
			{
				var line = this._scene.lines[i];
				if (this.find_element_name(line) == name)
					elts.push(line);
			}
		}

		//*** slab
		else if (name == "slabs")
			elts = elts.concat(this._scene.slabs);

		//*** opening
		else if (name == "openings")
			elts = elts.concat(this._scene.openings);
        else if (name == "samplings")
            elts = elts.concat(this._scene.storey.samplings);
        else if (name == "markers")
            elts = elts.concat(this._scene.storey.markers);
		return elts;
	}

	//***********************************************************************************
	//**** Updates mouseover element
	//***********************************************************************************
	find_element(pt, tolerance) {
		//*** Maybe mouse over a vertex ?
		var vertex = this._scene.find_vertex(pt, tolerance);
		if (vertex && this.check_filter(vertex))
			return vertex;

		//*** Maybe mouse over a line ?
		var line = this._scene.find_line(pt, tolerance);
		if (line && this.check_filter(line))
			return line;

		//*** Maybe mouse over a  roof opening ?
		var opening = this._scene.find_opening(pt);
		if (opening && this.check_filter(opening))
			return opening;

        if (this._scene.storey && this._scene.draw_samplings) {
            const sampling = this._scene.storey.find_sampling(pt, tolerance);
                if (sampling && this.check_filter(sampling))
                    return sampling;
        }

        if (this._scene.storey && this._scene.draw_comments) {
            const comment = this._scene.storey.find_marker(pt, tolerance);
            if (comment && this.check_filter(comment))
                return comment;
        }

		//*** Maybe mouse over a  slab  ?
		var slab = this._scene.find_slab(pt, tolerance);
		if (slab && this.check_filter(slab))
			return slab;

		return null;
	}

	//***********************************************************************************
	//**** Updates mouseover element
	//***********************************************************************************
	find_mouseover(pt, tolerance) {
		this.set_mouseover(this.find_element(pt, tolerance));
	}

	//***********************************************************************************
	//**** Area selection
	//***********************************************************************************
	area_select(box, clear_selection)
	{
		if (clear_selection)
			this.clear_selection();

		//*** Check lines
		for (var i in this._scene.lines)
		{
			var line = this._scene.lines[i];

			if (this.get_element_filter(this.find_element_name(line)) &&
				this._selection.indexOf(line) < 0 &&
				box.contains_point(line.vertices[0].position) &&
				box.contains_point(line.vertices[1].position))
				{
					this.select_element(line,false);
				}
		}

		if (this.get_element_filter("slabs"))
		{
			for (var i in this._scene.slabs)
			{
				var slab = this._scene.slabs[i];
				if (slab.contained_by_box(box))
					this.select_element(slab,false);
			}
		}

		if (this.get_element_filter("openings"))
		{
			for (var i in this._scene.openings)
			{
				var opening = this._scene.openings[i];
				if (opening.contained_by_box(box))
					this.select_element(opening,false);
			}
		}
	}

	//***********************************************************************************
	//**** Area selection
	//***********************************************************************************
	select_all()
	{
		this.clear_selection();

		this._scene.lines.forEach(line => {
			if (this.get_element_filter(this.find_element_name(line)))
				this.select_element(line,false);
		});

		this._scene.vertices.forEach(v => this.select_element(v,false));

		if (this.get_element_filter("openings"))
		{
			this._scene.openings.forEach(elt => this.select_element(elt,false));
		}

		if (this.get_element_filter("markers"))
		{
			this._storey.markers.forEach(elt => this.select_element(elt,false));
		}
	}

	//***********************************************************************************
	//*** Select all elements with a given name
	//***********************************************************************************
	select_elements(name) {
		if (!this.get_element_filter(name)) return;
		this.clear_selection();
		var selection = this.get_elements(name);
		for (var i in selection)
			this.select_element(selection[i],false);
	}

	//***********************************************************************************
	//**** Returns element name
	//***********************************************************************************
	find_element_name(elt) {
		var ctr = elt.constructor;
		if (ctr == cn_roof_vertex)
			return "vertices";

		if (ctr == cn_roof_line)
		{
			if (elt.is_border())
				return "outer_lines";
			return "inner_lines";
		}

		if (ctr == cn_roof_slab)
			return "slabs";

		if (ctr == cn_roof_opening)
			return "openings";

        if (ctr == cn_sampling)
            return "samplings";

        if (ctr == cn_marker)
            return "markers";

		return "";
	}

	//***********************************************************************************
	//**** Update element count
	//***********************************************************************************
	update_element_count() {
		for (var i in this._element_count)
			this._element_count[i] = 0;

		for (var i in this._scene.lines)
		{
			this._element_count[this.find_element_name(this._scene.lines[i])]++;
		}

		this._element_count["slabs"] += this._scene.slabs.length;

		this._element_count["openings"] += this._scene.openings.length;

		var elts = [];

		return elts;
	}

	//***********************************************************************************
	/**
	 * Copy selection in a new cn_roof object
	 * @returns {cn_roof}
	 */
	copy_selection() {
		return null;
	}

    //***********************************************************************************
	/**
	 * Cut selection in a new cn_roof object
	 * @returns {cn_roof}
	 */
    cut_selection() {
        return null;
    }

	//*******************************************************
	/**
	 * Returns 'true' if there is something to copy
	 * @returns {boolean}
	 */
	can_copy() {
		return false;
	}


    //*******************************************************
	/**
	 * Returns 'true' if there is something to cut
	 * @returns {boolean}
	 */
    can_cut() {
        return false;
    }

	//*******************************************************
	/**
	 * Returns 'true' if there is something to delete
	 * @returns {boolean}
	 */
	can_delete() {
		for (var i in this._selection)
		{
			if (this._selection[i].removable) return true;
		}
		return false;
	}
}


"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Configuration for element type edition
//***********************************************************************************
//***********************************************************************************
//*** A configuration is a dynamic scheme for element type edition.
//*** It describes all the UI elements connected to an element type.
//*** use
//***    - cn_element_type.get_configuration() to build a specific configuration
//***    - cn_element_type.load_configuration(cf) to set an element type with a configuration
//***    - cn_element_type.fill_configuration(cf) to set configration with a given element type.

//***********************************************************************************
//**** Root = configration
//***********************************************************************************
export class cn_configuration  {
	constructor() {
		this.tabs = [];
		this.selection = 0;
		this.param_groups = [];
	}

	//***************************************
	//*** tab methods
	//***************************************
	add_tab(tab) {
		this.tabs.push(tab);
	}

	get_tab(name) {
		for (var i in this.tabs)
		{
			if (this.tabs[i].name == name) return this.tabs[i];
		}
		return null;
	}

	get_tab_index(name)
	{
		for (var i=0;i<this.tabs.length;i++)
		{
			if (this.tabs[i].name == name) return i;
		}
		return -1;
	}

	//***************************************
	//*** selection methods
	//***************************************
	set_selection(name)
	{
		this.selection = this.get_tab_index(name);
	}
	get_selection() {
		return this.tabs[this.selection].name;
	}

	//***************************************
	//*** Param group methods
	//***************************************
	add_param_group(pg) {
		this.param_groups.push(pg);
	}

	get_param_group(name) {
		for (var i in this.param_groups)
		{
			if (this.param_groups[i].name == name) return this.param_groups[i];
		}
		return null;
	}
}

//***********************************************************************************
//**** Top level param group
//***********************************************************************************

export class cn_configuration_param_group {
	constructor(label = "", name = "", checkable = false) {
		this.label = label;
		this.name = name;
		this.params = [];
		this.checkable = checkable;
		this.checked = false;
		this.visible = true;
	}

	//***********************************************************************************
	//**** Params methods
	//***********************************************************************************
	add_param(param) {
		this.params.push(param);
	}

	get_param(name) {
		for (var i in this.params)
		{
			if (this.params[i].name == name) return this.params[i];
		}
		return null;
	}

	set_param(name, value=0, visible=true) {
		var param = this.get_param(name);
		if (param == null) return false;
		param.visible = visible;
		param.value = value;
		return true;
	}
}

//***********************************************************************************
//**** configration tab
//***********************************************************************************
export class cn_configuration_tab  {
	constructor(label = "", name = "") {
		this.label = label;
		this.name = name;
		this.lines = [];
		this.selection = 0;
	}

	//***********************************************************************************
	//**** default
	//***********************************************************************************
	add_line(line) {
		this.lines.push(line);
	}

	get_line(name) {
		for (var i in this.lines)
		{
			if (this.lines[i].name == name) return this.lines[i];
		}
		return null;
	}
	get_line_index(name) {
		for (var i=0;i<this.lines.length;i++)
		{
			if (this.lines[i].name == name) return i;
		}
		return -1;
	}
	set_selection(name)
	{
		this.selection = this.get_line_index(name);
	}
	get_selection() {
		return this.lines[this.selection].name;
	}
}

//***********************************************************************************
//**** configration line
//***********************************************************************************
export class cn_configuration_line  {
	constructor(label = "", name = "") {
		this.label = label;
		this.name = name;
		this.params = [];
		this.choices = [];
		this.selection = 0;
		this.visible = true;
	}

	//***********************************************************************************
	//**** Adds one choice
	//***********************************************************************************
	add_choice(choice) {
		this.choices.push(choice);
	}

	get_choice(name) {
		for (var i in this.choices)
		{
			if (this.choices[i].name == name) return this.choices[i];
		}
		return null;
	}

	get_choice_index(name) {
		for (var i=0;i<this.choices.length;i++)
		{
			if (this.choices[i].name == name) return i;
		}
		return -1;
	}

	set_selection(name)
	{
		this.selection = this.get_choice_index(name);
	}
	get_selection()
	{
		if (this.selection < 0 || this.selection >= this.choices.length)
			return "";
		return this.choices[this.selection].name;
	}
	//***********************************************************************************
	//**** Params methods
	//***********************************************************************************
	add_param(param) {
		this.params.push(param);
	}

	get_param(name) {
		for (var i in this.params)
		{
			if (this.params[i].name == name) return this.params[i];
		}
		return null;
	}

	set_param(name, value=0, visible=true) {
		var param = this.get_param(name);
		if (param == null) return false;
		param.visible = visible;
		param.value = value;
		return true;
	}
}

//***********************************************************************************
//**** configration choice
//***********************************************************************************
export class cn_configuration_choice  {
	constructor(label = "", name = "") {
		this.label = label;
		this.name = name;
		this.visible = true;
	}
}

//***********************************************************************************
//**** configration choice
//***********************************************************************************
export class cn_configuration_param  {
	constructor(label = "", name = "", min_value = 0, max_value = 100, unit = "", decimals=0, step=1, default_value=null) {
		this.label = label;
		this.name = name;
		this.min_value = min_value;
		this.max_value = max_value;
		this.decimals = decimals;
		this.step = step;
		this.unit = unit;
		this.value = (default_value ==null)?min_value:default_value;
		this.visible = true;
		this.choice_labels = [];
	}

	/**
	 * If the param is to propose a scroll list
	 * @param {string} label 
	 * @param {string} name 
	 * @param {string[]} choice_labels 
	 * @param {number} default_value 
	 * @returns 
	 */
	static choice_list(label, name, choice_labels, default_value=0)
	{
		let p = new cn_configuration_param(label,name,0,choice_labels.length-1,"",0,1,default_value);
		p.choice_labels = choice_labels.concat([]);
		return p;
	}

	get_choice() {
		return this.choice_labels[this.value];
	}
}


import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { combineLatest, forkJoin, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { EditMode } from 'src/app/model/edit-mode.model';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import {
    PiecePreequipeeConfig,
    TypeElementAControler,
    TypeVolume,
    TypeVolumeAdmin,
} from 'src/app/model/type-element-a-controler.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { URL_GESTION_PIECES, URL_GESTION_PIECES_EDIT } from 'src/app/shared/constants/url.constants';
import {
    CODE_BIM_PARAM_ESPACE_CHAUFFE,
    CODE_BIM_PARAM_ESPACE_HUMIDE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
} from 'src/app/shared/constants/cndiag.constants';
import { ValidatorUtils } from '../../../../../utils/validator.utils';

@Component({
    selector: 'app-creation-piece',
    templateUrl: './creation-piece.component.html',
    styleUrls: ['./creation-piece.component.scss'],
})
export class CreationPieceComponent extends BaseComponent implements OnInit, OnDestroy {
    typeVolume: TypeVolumeAdmin = new TypeVolumeAdmin();
    listeNomTypeElementAControler: string[] = [];
    formTypeVolume: FormGroup;
    mode: EditMode = 'CREATE';
    isReadOnly = false;

    private idTypeVolume: string;
    private typeVolumeInitial: TypeVolumeAdmin = new TypeVolumeAdmin();
    private listeFullTypesVolumes: TypeVolume[] = [];
    private listeFullTypeElementAControler: TypeElementAControler[] = [];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly referenceService: ReferenceService,
        private readonly referenceApiService: ReferenceApiService,
        private readonly confirmationService: ConfirmationService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.createForm();

        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    switchMap(([params, data]) => {
                        this.idTypeVolume = params.get('idVolume');
                        if (this.idTypeVolume) {
                            this.mode = data.duplicate ? 'DUPLICATE' : 'EDIT';
                            this.isReadOnly = data.consulter;
                            return this.referenceApiService.findOneTypeVolume(this.idTypeVolume);
                        }
                        return of(null);
                    }),
                    switchMap((currentVolume) =>
                        forkJoin([
                            of(currentVolume),
                            this.referenceService.findAllTypesElementsAControler(),
                            this.referenceService.forceReloadTypesVolumes(),
                        ])
                    ),
                    takeUntil(this.ngUnsubscribe)
                )
            )
            .subscribe(([currentVolume, listeTypeElementAControler, listeTypesVolumes]) => {
                if (currentVolume) {
                    this.typeVolumeInitial = JSON.parse(JSON.stringify(currentVolume));
                    this.typeVolume = currentVolume;
                }

                this.listeFullTypesVolumes = listeTypesVolumes;
                this.listeFullTypeElementAControler = listeTypeElementAControler.filter((element) => !element.abstrait);

                if (this.mode !== 'CREATE') {
                    let nom = '';
                    let etatVolume = this.typeVolume.etatVolume;

                    if (this.mode === 'EDIT') {
                        nom = this.typeVolume.nom;
                        this.typeVolumeInitial = JSON.parse(JSON.stringify(this.typeVolume));
                    } else if (this.mode === 'DUPLICATE') {
                        etatVolume = EtatWorkflow.INACTIF;
                        this.typeVolume.id = null;
                    }

                    this.populateForm(nom, etatVolume);
                }

                // Initialisation de la liste des équipements associés possible pour la liste déroulante
                this.listeNomTypeElementAControler = this.listeFullTypeElementAControler.map((element) => element.nom);
            });
    }

    /**
     * Crée le formulaire
     * @private
     */
    private createForm(): void {
        this.formTypeVolume = this.formBuilder.group({
            nom: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (
                            control.value &&
                            ValidatorUtils.checkAlreadyExist(
                                this.formTypeVolume.controls.nom.value,
                                this.mode !== 'EDIT',
                                this.typeVolumeInitial.nom,
                                this.listeFullTypesVolumes.map((ref) => ref.nom)
                            )
                        ) {
                            return { erreurNomTypeVolume: true };
                        }
                        return null;
                    },
                ],
            ],
            etatVolume: [EtatWorkflow.INACTIF, Validators.required],
            pieceHumide: false,
            pieceChauffee: false,
            pieceNonCarrez: false,
            pieceNonHabitable: false,
            pieceNonUtile: false,
            configurations: this.formBuilder.array([]),
        });
    }

    /**
     * Rempli le formulaire et initialise le formArray
     * @param nom
     * @param etatVolume
     * @private
     */
    private populateForm(nom, etatVolume): void {
        this.formTypeVolume.patchValue({
            nom: nom,
            etatVolume: etatVolume,
            pieceHumide: this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_HUMIDE],
            pieceChauffee: this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_CHAUFFE],
            pieceNonCarrez: this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ],
            pieceNonHabitable: this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE],
            pieceNonUtile: this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE],
        });

        this.initConfigurationsFormArray(this.typeVolume.piecesPreequipeesConfigs);
    }

    /**
     * Initialise le FormArray des configurations
     * @param configurations
     * @private
     */
    private initConfigurationsFormArray(configurations: PiecePreequipeeConfig[]): void {
        configurations.forEach((conf) => {
            const configurationGroup = this.createConfiguration(conf);
            this.initEquipmentFormArray(conf.typesElementsAControlerAssocies, configurationGroup);
        });
    }

    /**
     * Renvoie le FormArray des configurations
     */
    get configurationsFormArray(): FormArray {
        return this.formTypeVolume.get('configurations') as FormArray;
    }

    /**
     * Crée le FormGroup contenu dans les FormArray de la prestation
     * @param configuration
     * @private
     */
    private getConfigurationsFormGroup(configuration: PiecePreequipeeConfig): FormGroup {
        return this.formBuilder.group({
            nom: [configuration ? configuration.nom : '', [Validators.required]],
            selectEquipment: [''],
            typesElementsAControlerAssocies: this.formBuilder.array([], [Validators.required]),
        });
    }

    /**
     * Crée un FormGroup de configuration dans le formArray des configurations
     * @param configuration
     */
    createConfiguration(configuration: PiecePreequipeeConfig): FormGroup {
        const configurationGroup = this.getConfigurationsFormGroup(configuration);
        this.configurationsFormArray.push(configurationGroup);
        return configurationGroup;
    }

    /**
     * Supprime une prestation dans le form array
     * @param index
     * @private
     */
    private deleteConfiguration(index: number): void {
        this.configurationsFormArray.removeAt(index);
    }

    /**
     * Initialise le FormArray des équipements
     * @param typesElementsAControlerAssocies
     * @param configurationGroup
     * @private
     */
    private initEquipmentFormArray(
        typesElementsAControlerAssocies: TypeElementAControler[],
        configurationGroup: FormGroup
    ): void {
        typesElementsAControlerAssocies.forEach((equipment) => {
            this.createEquipment(configurationGroup, equipment.nom);
        });
    }

    /**
     * Renvoie le FormGroup d'équipement
     * @param equipementName
     * @private
     */
    private getEquipmentFormGroup(equipementName: any): FormGroup {
        return this.formBuilder.group({
            name: [equipementName, [Validators.required]],
        });
    }

    /**
     * Crée un équipement dans le FormArray d'équipement
     * @param prestation
     * @param equipementName
     */
    createEquipment(prestation: AbstractControl, equipementName: string) {
        const equipementGroup = this.getEquipmentFormGroup(equipementName);
        const elementsFormArray = prestation.get('typesElementsAControlerAssocies') as FormArray;
        elementsFormArray.push(equipementGroup);
        return equipementGroup;
    }

    /**
     * Supprime un équipement dans le FormArray
     * @param prestation
     * @param index
     */
    deleteEquipment(prestation: AbstractControl, index: number) {
        const elementsFormArray = prestation.get('typesElementsAControlerAssocies') as FormArray;
        elementsFormArray.removeAt(index);
    }

    /**
     * Evenement lors de l'envoi du formulaire
     */
    onSubmit() {
        if (this.formTypeVolume.valid) {
            this.validerVolume();
        }
    }

    /**
     * Affiche une boite de dialogue pour confirme la suppression d'une prestation
     * @param typeElementParPrestation
     * @param index
     */
    confirmDeleteConfiguration(typeElementParPrestation: PiecePreequipeeConfig, index: number) {
        const msg = `Êtes-vous sûr de vouloir supprimer les équipements de ${typeElementParPrestation.nom} ?`;
        this.deleteConfiguration(index);
    }

    /**
     * Renvoie vers l'url précédente
     */
    back() {
        this.router.navigate([URL_GESTION_PIECES]);
    }

    /**
     * Renvoie vers l'url d'édition
     */
    editer() {
        this.router.navigate([URL_GESTION_PIECES_EDIT, this.typeVolume.id]);
    }

    /**
     * Récupère les données du formulaire, les prépare puis les sauvegarde en BD
     * @private
     */
    private validerVolume() {
        // this.cnSpinnerService.show('Sauvegarde en cours...');

        // Etat
        this.typeVolume.etatVolume = this.formTypeVolume.value.etatVolume;

        // Informations générales
        this.typeVolume.nom = this.formTypeVolume.value.nom;

        // Réglages par défaut :
        this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_HUMIDE] = this.formTypeVolume.value.pieceHumide;
        this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_CHAUFFE] =
            this.formTypeVolume.value.pieceChauffee;
        this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ] =
            this.formTypeVolume.value.pieceNonCarrez;
        this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE] =
            this.formTypeVolume.value.pieceNonHabitable;
        this.typeVolume.valeursDefautParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE] =
            this.formTypeVolume.value.pieceNonUtile;

        // Réinitialisation de la liste des élements associés
        // Puis remplir avec les valeurs du formGroup
        this.typeVolume.piecesPreequipeesConfigs = [];

        // Liste configurations :
        const typesElementsParConfiguration = [];
        this.configurationsFormArray.controls.forEach((configurationsFormGroup) => {
            // Création d'une nouvelle prestation :
            const typeElementParConfiguration: PiecePreequipeeConfig = new PiecePreequipeeConfig();

            typeElementParConfiguration.nom = configurationsFormGroup.value.nom;

            // Liste des équipements contenus dans une configuration :
            const equipments = configurationsFormGroup.value.typesElementsAControlerAssocies;
            equipments.forEach((equipment) => {
                // Rechercher l'objet complet equipment grâce à son nom :
                const equipmentFind = this.listeFullTypeElementAControler.find((elem) => elem.nom === equipment.name);
                typeElementParConfiguration.typesElementsAControlerAssocies.push(equipmentFind);
            });

            typesElementsParConfiguration.push(typeElementParConfiguration);
        });

        this.typeVolume.piecesPreequipeesConfigs = typesElementsParConfiguration;
        this.upsertTypeVolume();
    }

    /**
     * Ajoute ou met à jour les données en base
     * @param typeVolume
     * @private
     */
    private upsertTypeVolume(typeVolume: TypeVolumeAdmin = this.typeVolume) {
        this.cnSpinnerService
            .withSpinner(
                this.referenceApiService.upsertTypeVolume(typeVolume).pipe(
                    // On réinitialise la liste des volumes dans le cache de l'appli
                    tap(() => this.referenceService.forceReloadTypesVolumes()),
                    takeUntil(this.ngUnsubscribe)
                )
            )
            .subscribe(() => {
                const message =
                    this.mode === 'CREATE'
                        ? 'La pièce a été créé'
                        : this.mode === 'DUPLICATE'
                        ? 'La pièce a été dupliqué'
                        : 'La pièce a été modifié';
                this.notificationService.success(message);

                this.back();
            });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}

import { User } from 'src/app/commons-lib';
import { TypePrestation } from './type-prestation.model';
import { Document } from './document.model';

export class UserWizy extends User {
    listeRoleString: string;
    companyName: string;
    nbInterventionsEnCours: number;
}

export class UserInformation {
    id: string;
    idUser: string;

    /**
     * Identifiant de la signature dans le storageService
     */
    fichierSignatureId: string;

    /**
     * Liste des préférences de l'utilisateur
     */
    preferences: Preference[];

    /**
     * Liste des compétences de l'utilisateur
     */
    competences: Competence[];

    /**
     * Liste du materiel de l'utilisateur
     */
    materiel: Materiel;
}

export class Preference {
    nom: string;
    valeur: string;
}

// val nom: String,
// val organisme: String? = null,
// val dateObtention: LocalDate? = null,
// val dateFin: LocalDate,
// val typePrestations: List<TypePrestation>? = null,
// val idDocument: String? = null

export class Competence {
    nom: string;
    organisme?: string;
    dateObtention?: string;
    dateFin?: string;
    competenceDocument?: Document;
    numero?: string;
    login?: string;
    password?: string;
}

export class Materiel {
    numeroChrono: string;
    numeroDebitmetre: string;
    immatriculationUmd: string;

    constructor(numeroChrono: string, numeroDebitmetre: string) {
        this.numeroChrono = numeroChrono;
        this.numeroDebitmetre = numeroDebitmetre;
    }
}

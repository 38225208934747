/**
 * Type de commentaire.
 */
import { CategorieCommentairePredefini } from './categorie-commentaire-predefini.model';
import { TypeCommentaire } from './type-commentaire.model';

/**
 * Commentaire
 * @title Commentaire
 * @description Un commentaire peut être de type différent
 */
export class Commentaire {
    /**
     * id
     * @title id du commentaire
     * @description id du commentaire
     */
    id: string;

    /**
     * Type de commentaire
     * @title Type de commentaire
     * @description Type de commentaire
     */
    public type: TypeCommentaire;

    /**
     * @title Date et heure du commentaire
     * @description Date et heure du commentaire
     */
    public date: string;

    /**
     * @title Contenu du commentaire
     * @description Contenu du commentaire
     */
    public contenu?: string = null;

    /**
     * @title id de l'image
     * @description id de l'image rattachée au commentaire
     */
    public imageId?: string;

    /**
     * @title Sélection du commentaire
     * @description Statut de sélection d'un commentaire
     */
    public selected = true;

    /**
     * @title Origine du commentaire
     * @description Le commentaire est-il issu de la description du bien ?
     */
    public fromDescription = false;

    /**
     * @title Catégories du commentaire
     * @description Catégories du commentaire
     */
    public categories?: CategorieCommentairePredefini[] = [];

    /**
     *  EXIF de l'image
     */
    imageDateTimeOriginal?: string;
    imageGpsLatitudeRef?: string;
    imageGpsLatitude?: string;
    imageGpsLongitudeRef?: string;
    imageGpsLongitude?: string;
    imageGpsAltitudeRef?: string;
    imageGpsAltitude?: string;
}

/**
 * Document (fichier)
 */
import { TypePrestation } from './type-prestation.model';
import { TypeDocument } from './type-document.model';
import { Conformite } from './conformite.model';

export class DocumentPreRapport {
    /**
     * Document est affiché dans la liste des documents du rapport
     */
    afficherDansListeDocuments: boolean;

    /**
     * Document est affiché dans le rapport
     */
    afficherDansRapport: boolean;
}

export class Document {
    id?: string;

    /**
     * Nom du document (peut être différent de celui du fichier)
     */
    nom?: string;

    /**
     * Types de prestation auquels le document est rattaché
     */
    typePrestations?: TypePrestation[];

    /**
     * Type de document
     */
    typeDocument: TypeDocument;

    /**
     * Date de création du fichier
     */
    dateCreation: string;

    /**
     * Nom de l'opérateur aillant ajouter la pièce jointe
     */
    creePar: string;

    /**
     * Id du fichier attaché au document
     */
    idFichier?: string = null;

    /**
     * Nom du fichier attaché au document (avec son extension)
     */
    nomFichier?: string = null;

    /**
     * transmis par
     */
    sourceTransmission?: string = null;

    /**
     * Date de transmission de la pièce jointe
     */
    dateTransmission?: string = null;

    /**
     * Historique des pièces jointe du document
     */
    documentsHistory: DocumentHistory[] = [];

    /**
     * Commentaires liés au document
     */
    commentairesId: string[] = [];

    /**
     * Document obligatoire dans l'intervention (contrairement au isRequired de typeDocument qui précise pour une referencePrestation)
     */
    requiredForIntervention = false;

    /**
     * Document editable
     */
    isEditable = false;

    /**
     * Document conforme
     */
    conformite: Conformite;

    /**
     * Document est affiché dans le rapport
     */
    afficherDansRapport: boolean;

    afficherDansListeDocuments: boolean;
}

export class DocumentHistory {
    /**
     * Nom du fichier attaché au document (avec son extension)
     */
    nomFichier?: string = null;

    /**
     * transmis par
     */
    sourceTransmission?: string = null;

    /**
     * Date de transmission de la pièce jointe
     */
    dateTransmission?: string = null;

    /**
     * Commentaires liés au document
     */
    commentairesId: string[] = [];
}

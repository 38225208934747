import { Pipe, PipeTransform } from '@angular/core';
import { TypePrestation, typePrestationToLabel } from '../model/type-prestation.model';

/**
 * Affiche le nom d'un type de prestation
 */
@Pipe({
    name: 'typePrestation',
})
export class TypePrestationPipe implements PipeTransform {
    constructor() {}

    transform(typePrestation?: TypePrestation): string {
        return typePrestationToLabel(typePrestation);
    }
}

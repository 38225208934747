<div *ngIf="isLoaded" class="zone">
    <app-point-controle-bien-panel
        class="panel"
        [disabled]="indexSurface == undefined"
        [biens]="pdcBiens"
        [volumesIdDispo]="listVolumeId"
        [listVolumeDisabled]="listVolumeDisabled"
        [listVolumeChecked]="listVolumeChecked"
        (panelSubmit)="handleVolumePanelSubmit($event)"
        (panelCancel)="handleVolumePanelCancel()"
    >
    </app-point-controle-bien-panel>

    <div class="content">
        <message-card *ngIf="message">
            <p>{{ message }}</p>
        </message-card>

        <verification-card
            *ngIf="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
            [showValidateButton]="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="form"
        >
            Veuillez vérifier et valider les zones
        </verification-card>
        <div class="d-flex align-items-center zone-header">
            <div class="zone-row mr-4">
                <app-widget-select [ngModel]="indexZone" (ngModelChange)="zoneSelected($event)">
                    <option *ngFor="let zone of formZones.value; let index = index" [value]="index">
                        {{ zone | zoneFullnamePipe }}
                    </option>
                </app-widget-select>

                <app-next-previous-button
                    class="d-flex ml-4"
                    [nextDisabled]="indexZone === formZones.length - 1"
                    [previousDisabled]="indexZone === 0"
                    (clicked)="navigationZone($event)"
                ></app-next-previous-button>
            </div>

            <div class="zone-row">
                <app-widget-button class="mr-3" (click)="addZone()" *ngIf="isInEditMode" data-cy="add-zone-button">
                    <mat-icon>add</mat-icon>
                </app-widget-button>
                <app-widget-button class="mr-3" (click)="editZone()" data-cy="edit-zone-button">
                    <mat-icon>edit</mat-icon>
                </app-widget-button>
                <app-widget-button (click)="duplicateZone()" *ngIf="isInEditMode">
                    <mat-icon>content_copy</mat-icon>
                </app-widget-button>
            </div>
        </div>

        <div class="mt-3" *ngIf="formZones.length">
            <app-header-zone [puTotal]="totalPu" [surfaceTotale]="surfaceTotale"> </app-header-zone>
        </div>
        <div *ngIf="formZones.length">
            <div *ngFor="let surface of formArraySurfaces.controls; let indexSurface = index" class="mt-3">
                <app-piece-unitaire-block
                    [nbVolumes]="surface.get('listeIdVolume').value.length"
                    [typeZse]="getTypeZseByIndex(indexZone)"
                    [biens]="pdcBiens"
                    [surface]="surface"
                    [listeIdVolumes]="surface.get('listeIdVolume').value"
                    [volumes]="volumes"
                    [volumesSurface]="volumesSurface"
                    (surfaceDeleted)="removeSurfaceByIndex(indexSurface)"
                    (surfaceSelected)="selectSurfaceByIndex(indexSurface)"
                    (volumeDeleted)="removeVolumeFromSurface($event, indexSurface)"
                    [disabled]="!isInEditMode"
                ></app-piece-unitaire-block>
            </div>
            <button
                mat-stroked-button
                color="primary"
                class="mt-3"
                (click)="addSurface()"
                *ngIf="isInEditMode"
                data-cy="ajouter-surface-button"
            >
                Ajouter une nouvelle Surface
            </button>
        </div>
    </div>
</div>

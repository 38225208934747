import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';

import {
    PolluantPrelevement,
    PrelevementMest,
} from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
    selector: 'app-prelevement-form-mest-general',
    templateUrl: './prelevement-form-mest-general.component.html',
    styleUrls: ['./prelevement-form-mest-general.component.scss'],
})
export class PrelevementFormMestGeneralComponent implements OnInit {
    formGeneral: FormGroup;

    @Input('diagnostic')
    diagnostic: Diagnostic;
    contenuDiagnostic: PolluantPrelevement;

    @Input('selectedBesoinId')
    selectedBesoinId: string;
    selectedPrelevement: PrelevementMest;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Output('formChange')
    formChange: EventEmitter<any> = new EventEmitter<any>();

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(private formBuilder: FormBuilder, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;

        //Récupération du Prélèvement par l'ID de son Besoin
        this.selectedPrelevement = this.contenuDiagnostic.prelevement.data.besoinsList.find(
            (b) => b.besoinPrelevement.id == this.selectedBesoinId
        ) as PrelevementMest;

        this.createForm();
    }

    createForm() {
        this.formGeneral = this.formBuilder.group({
            faitParLeClient: [this.selectedPrelevement.informationsClient.faitParLeClient, Validators.required],

            localisationPrelevement: [this.selectedPrelevement.informationsPrelevementMest.localisationPrelevement],
            circonstance: [this.selectedPrelevement.informationsPrelevementMest.circonstance],
            dateTimePrelevement: [
                moment(this.selectedPrelevement.informationsPrelevementMest.dateTimePrelevement),
                Validators.required,
            ],
            remarques: [this.selectedPrelevement.informationsPrelevementMest.remarques],

            accreditationCofrac: [this.selectedPrelevement.conformite.accreditationCofrac, Validators.required],
        });

        this.formGeneral.valueChanges.subscribe((newValue) => {
            const formValues = this.formGeneral.getRawValue();

            //Patch des valeurs du diagnostic avec les valeurs du formulaire
            this.selectedPrelevement.informationsClient.faitParLeClient = formValues.faitParLeClient;
            this.selectedPrelevement.conformite.accreditationCofrac = formValues.accreditationCofrac;

            //Envoi des changements au composant parent pour sauvegarde
            this.formChange.emit(this.contenuDiagnostic);
        });
    }

    /**
     * @description Méthode appellée pour imprimer l'étiquette liée au prélèvement
     */
    public impressionEtiquette() {
        //A implémenter
    }
}

import { TypePrestation } from './type-prestation.model';
import { TypeReport } from './reference-prestation.model';
import { Intervention } from './intervention.model';
import { Commande } from './commande.model';
import { Agence } from './agence.model';

export class ReportBlockData {
    title?: string;
    values?: Map<string, any>;
    intervention?: InterventionReportData;
}

export class ReportBlock {
    name: string;
    order: number;
    componentName: string;
    data?: ReportBlockData;
}

export class ReportContent {
    typePrestation: TypePrestation;
    blocks: ReportBlock[] = [];

    constructor(typePrestation: TypePrestation, blocks: ReportBlock[]) {
        this.typePrestation = typePrestation;
        this.blocks = blocks;
    }
}

export class ReportReference {
    default: boolean;
    company: string;
    /**
     * Type du rapport, il s'agit d'un énum
     */
    reportType: TypeReport = TypeReport.REPORT;
    reportContent: string;
}

export interface IReportComponent {
    data: ReportBlockData;
    interReportData: InterventionReportData;
    intervention: Intervention;
}

/**
 * AnnexeData
 *
 * @title Annexe
 * @description Contient toutes les informations relatives à un document annexe
 */
export class AnnexeData {
    /**
     * Id
     * @title Id
     * @description id d'une annexe
     */
    id: string;

    /**
     * Filename
     * @title Nom du fichier
     * @description Le nom du fichier d'une annexe
     */
    filename: string;

    /**
     * Type
     * @title Type
     * @description Le type de fichier d'une annexe
     */
    type: 'pdf' | string;
}

/**
 * IDiagReportData
 *
 * @title Diagnostic
 * @description Contient toutes les informations relatives à un diagnostic
 */
export interface IDiagReportData {
    id: string;
    typePrestation: TypePrestation;
    conformiteGlobale: boolean;
    documentsData: DocumentData[];
    listDocuments: DocumentData[];
    annexes: AnnexeData[];
    reportagesPhotos: ReportagePhotoData[];
}

export interface ISubBlock {
    code: string;
}

/**
 * InterventionReportData
 *
 * @title Intervention
 * @description Contient toutes les informations relatives à l'information
 */
export class InterventionReportData {
    id: string;
    affaire = 'n.c';

    dossierNumber = 'n.c';
    datePrevue = 'n.c';
    dateEffective = 'n.c';
    dateCommande = 'n.c';
    emisLe = 'n.c';
    societe: CompanyData;
    operateur: OperateurData;
    donneurOrdre: ContactData;
    proprietaire: ContactData;
    contactsAutres: ContactData[] = [];
    bienPrincipal: BienData;
    diagnostics: IDiagReportData[];
    conformiteGlobale: boolean;
    conformiteCalculee: boolean;
    conformiteDefinie: boolean;
    documentsPresents: string[] = [];
    biens: BienData[] = [];
    auMoinsUnBienMeuble: boolean;
    auMoinsUnBienOccupe: boolean;
    listDocuments: DocumentData[] = [];
    annexes: AnnexeData[];
    documentsQuestions: any[];
    conformiteDocuments: boolean;
    numDossierInterventionParente = 'n.c';
    dateCommandeInterventionParente = 'n.c';
    rapportParentEmisLe = 'n.c';
}

/**
 * ContactData
 *
 * @title Contact
 * @description Contient toutes les informations relatives à un contact
 */
export class ContactData {
    nom: string = 'n.c';
    prenom: string = 'n.c';
    adresse: string = 'n.c';
    codePostal: string = 'n.c';
    ville: string = 'n.c';
    presence: boolean;
    type: string = 'n.c';

    constructor(
        nom: string,
        prenom: string,
        adresse: string,
        cp: string,
        ville: string,
        presence: boolean,
        type: string
    ) {
        this.nom = nom;
        this.prenom = prenom;
        this.adresse = adresse && adresse !== '' ? adresse : 'n.c';
        this.codePostal = cp;
        this.ville = ville;
        this.presence = presence;
        this.type = type;
    }
}

/**
 * CompanyData
 *
 * @title Agence
 * @description Contient toutes les informations relatives à une agence
 */
export class CompanyData {
    /**
     * Nom
     * @title tototo
     * @description tatata
     */
    nom: string;
    adresse = 'n.c';
    codePostal = 'n.c';
    ville = 'n.c';
    siret: string = 'n.c';
    assurance: AssuranceData;
    dateValidite: string = 'n.c';
    codeAPE: string = 'n.c';
    tva: string = 'n.c';
}

/**
 * AssuranceData
 *
 * @title Assurance
 * @description Contient toutes les informations relatives à une assurance
 */
export class AssuranceData {
    /**
     * Nom
     * @title Nom de l'assurance
     * @description Nom de l'assurance
     */
    nom = 'n.c';

    /**
     * Numero Police
     *
     * @title Numero de police
     * @description Numero de police de l'assurance
     */
    numeroPolice = 'n.c';

    /**
     * dateValidite
     *
     * @title Date de validité
     * @description Date de validité de l'assurance
     */
    dateValidite = 'n.c';

    /**
     * dateEcheance
     *
     * @title Date d'écheance
     * @description Date d'écheance de l'assurance
     */
    dateEcheance = 'n.c';
}

/**
 * OperateurData
 *
 * @title Opérateur
 * @description Contient toutes les informations relatives à un opérateur
 */
export class OperateurData {
    nom = 'n.c';
    prenom = 'n.c';
    certifications = 'n.c';
    signatureUrl = 'n.c';
    email = 'n.c';
    id: string;
}

/**
 * BienData
 *
 * @title Bien
 * @description Contient toutes les informations relatives à un bien
 */
export class BienData {
    /**
     * id
     *
     * @title id
     * @description id du bien data
     */
    id: string;

    /**
     * refBien
     *
     * @title Référence du bien
     * @description Référence du bien concerné
     */
    refBien = 'n.c';

    /**
     * Adresse
     *
     * @title Adresse du bien
     * @description Adresse du bien concerné
     */
    adresse = 'n.c';

    /**
     * Code Postal
     *
     * @title Code postal du bien
     * @description Code postal du bien concerné
     */
    codePostal = 'n.c';

    /**
     * Ville
     *
     * @title Ville du bien
     * @description Ville du bien concerné
     */
    ville = 'n.c';

    /**
     * Bâtiment
     *
     * @title Numéro de bâtiment
     * @description Numéro de bâtiment du bien concerné
     */
    batiment = 'n.c';

    /**
     * Étage
     *
     * @title Numéro d'étage
     * @description Numéro d'étage du bien concerné
     */
    etage = 'n.c';

    /**
     * Porte
     *
     * @title Numéro de porte
     * @description Numéro de porte du bien concerné
     */
    porte = 'n.c';

    /**
     * Type de bien
     *
     * @title Type de bien
     * @description Type du bien concerné
     */
    type = 'n.c';

    /**
     * Affectation
     *
     * @title Affectation du bien
     * @description Affectation du bien concerné
     */
    affectation = 'n.c';

    /**
     * Numéro de lot
     *
     * @title Numéro de lot du bien
     * @description Numéro de lot du bien concerné
     */
    numLot = 'n.c';

    /**
     * Nombre de niveaux
     *
     * @title Nombre de niveaux du bien
     * @description Nombre de niveaux du bien concerné
     */
    nbNiveaux = 'n.c';

    /**
     * Surface
     *
     * @title Surface du bien
     * @description Surface du bien concerné
     */
    surface = 'n.c';

    /**
     * Cadastre
     *
     * @title Numéro de cadastre du bien
     * @description Numéro de cadastre du bien concerné
     */
    cadastre = 'n.c';

    /**
     * Année de construction
     *
     * @title Année de construction
     * @description Année de construction du bien concerné
     */
    annee = 'n.c';

    /**
     * Meublé
     *
     * @title Meublé
     * @description Le bien concerné est-il meublé ou non ?
     */
    estMeuble = 'n.c';

    /**
     * Occupation du bien
     *
     * @title Occupation du bien
     * @description Le bien concerné est-il occupé ou non ?
     */
    estOccupe = 'n.c';

    /**
     * Photo du bien
     *
     * @title Photo du bien
     * @description url de la photo du bien concerné
     */
    imageUrl: string;

    /**
     * Photo aérienne du bien
     *
     * @title Photo aérienne du bien
     * @description url de la photo aérienne du bien concerné
     */
    imageUrlSat: string;

    /**données EXIFS des photo et photo aériennes */
    ExifsPhotoBien: {
        creationDate: string;
        gpsLatitudeRef: string;
        gpsLatitude: string;
        gpsLongitudeRef: string;
        gpsLongitude: string;
        gpsAltitudeRef: string;
        gpsAltitude: string;
    };
    ExifsPhotoAerienneBien: {
        creationDate: string;
        gpsLatitudeRef: string;
        gpsLatitude: string;
        gpsLongitudeRef: string;
        gpsLongitude: string;
        gpsAltitudeRef: string;
        gpsAltitude: string;
    };
}

/**
 * DocumentData
 *
 * @title Document
 * @description Contient toutes les informations relatives à un document
 */
export class DocumentData {
    idFichier?: string;
    nom: string;
    date: string;
    type: string;
    afficherDansRapport: boolean;
    afficherDansListeDocuments: boolean;
    commentaires: CommentaireDocData[];
}

/**
 * CommentaireDocData
 *
 * @title Commentaire de document
 * @description Contient toutes les informations relatives à un commentaire lié à un document
 */
export class CommentaireDocData {
    /**
     * @title id du commentaire de document
     * @description id du commentaire de document
     */
    id: string;

    /**
     * @title Contenu du commentaire de document
     * @description Contenu du commentaire de document
     */
    contenu: string;
}

export enum EtatRapport {
    INCONNU = 'INCONNU',
    EN_FILE_D_ATTENTE = 'EN_FILE_D_ATTENTE',
    GENERATION_EN_COURS = 'GENERATION_EN_COURS',
    TERMINE = 'TERMINE',
    EN_ERREUR = 'EN_ERREUR',
    EN_PAUSE = 'EN_PAUSE',
    DIFFERE = 'DIFFERE',
}
/**
 * Correspond à la table rapports dans la base de donnée sauf l'attribut dateRapport.
 * La donnée dateRapport a été rajouté pour l'objet RapportsByDiagnostic.
 */
export class Rapport {
    id: string;
    outputFiles: RapportGenere[] = [];
    dateRapport: string;
    errorMessage?: string;
    typeLivrables: TypeLivrable;
    agence: Agence;
    commande: Commande;
    numCommande: string;
    idIntervention: string;
    idDiagnostic: string;
    typePrestation: TypePrestation;
    idReferencePrestation: string;
    idSynthese: string;
    dateIntervention: string;
    idsPrestataire: string[];
    state: EtatRapport;
}

export class RapportGenere {
    id: string;
    name: string;
    url: string;
    type: TypeRapport;
}

export class RapportDownloadToken {
    id: string;
    description: string;
}

/**
 * Correspond à l'objet rapport dans la table Diagnostics dans la base de donnée
 */
export class RapportDiagnostic {
    token: string;
    dateRapport: string;
    idRapport: string;
    error: string;
}

/**
 * Correspond à RapportsByDiagnosticADO côté back. Il est construit avec les données de la table diagnostics + la table rapports
 */
export class RapportsByDiagnostic {
    idDiagnostic: string;
    typePrestation: TypePrestation;
    rapports: Rapport[];
}

export enum TypeRapport {
    /** Correspond au type de rapport au format docx */
    INTERMEDIATE = 'INTERMEDIATE',
    /** Correspond au type de rapport au format PDF */
    FINAL_PDF = 'FINAL_PDF',
    REPORT_DATA_JSON = 'REPORT_DATA_JSON',
    FINAL_EXCEL = 'FINAL_EXCEL',
    INTERMEDIATE_PDF = 'INTERMEDIATE_PDF',
}

/**
 * ReportagePhotoData
 *
 * @title Reportage photos
 * @description Contient toutes les informations relatives à un reportage photos
 */
export class ReportagePhotoData {
    nom: string;
    pages: ReportagePhotoDataPage[] = [];
}

/**
 * ReportagePhotoDataPageItem
 *
 * @title Photo d'une reportage photos
 * @description Contient toutes les informations d'une seule photo appartenant à un reportage photos
 */
export class ReportagePhotoDataPageItem {
    idFichier: string;
    urlFichier: string;
    legende: string;
    creationDate: string;
    gpsLatitudeRef: string;
    gpsLatitude: string;
    gpsLongitudeRef: string;
    gpsLongitude: string;
    gpsAltitudeRef: string;
    gpsAltitude: string;
}

/**
 * ReportagePhotoDataPage
 *
 * @title Page de reportages photos
 * @description Contient toutes les informations relatives à une page de reportages photos
 */
export class ReportagePhotoDataPage {
    reportagePhotoDataPageItem: ReportagePhotoDataPageItem[] = [];
}

export enum TypeLivrable {
    /** Bon de commande labo */
    BON_DE_COMMANDE_LABO = 'BON_DE_COMMANDE_LABO',

    /** Rapport */
    RAPPORT = 'RAPPORT',

    /** Synthèse */
    SYNTHESE = 'SYNTHESE',
}

<div class="app-prelevement-init" *ngIf="isLoaded">
    <div class="verification-card-container">
        <verification-card
            *ngIf="form != undefined"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="form"
        >
            Veuillez vérifier les compétences
        </verification-card>
    </div>
    <div *ngIf="form != undefined" class="form" [formGroup]="form">
        <div class="section" *ngIf="contientMetop">
            <div class="header">Matériel du technicien</div>
            <mat-form-field appearance="fill">
                <mat-label>Numéro chronomètre</mat-label>
                <input matInput required formControlName="numeroChrono" [readonly]="!isInEditMode" />
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Numéro débimètre</mat-label>
                <input matInput required formControlName="numeroDebitmetre" [readonly]="!isInEditMode" />
            </mat-form-field>
        </div>
        <div class="section" *ngIf="materielChantierNecessaire">
            <div class="header">Matériel sur le chantier</div>
            <mat-form-field appearance="fill">
                <mat-label>Immatriculation UMD</mat-label>
                <input matInput required formControlName="immatriculationUmd" [readonly]="!isInEditMode" />
            </mat-form-field>
        </div>
    </div>
    <p *ngIf="!materielChantierNecessaire && !contientMetop">Aucun matériel requis</p>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, FileUploaderComponent, NotificationService } from 'src/app/commons-lib';
import { takeUntil } from 'rxjs/operators';
import { TypeRegle } from '../../../model/regle.model';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import { ReferencePrestation } from '../../../model/reference-prestation.model';

const typesRegle: TypeRegle[] = ['REGLE_CONTROL', 'REGLE_DOCUMENT'];

@Component({
    selector: 'app-ajout-regle',
    templateUrl: './ajout-regle.component.html',
    styleUrls: ['./ajout-regle.component.scss'],
})
export class AjoutRegleComponent extends BaseComponent implements OnInit {
    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;

    referencePrestations: ReferencePrestation[] = [];
    selectedRefPresta: string;
    typeRegles = typesRegle;
    selectedTypeRegle: string;

    constructor(
        private readonly referenceApiService: ReferenceApiService,
        private readonly referenceService: ReferenceService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.referenceService
            .findAllReferencePrestations()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((refPrestas) => {
                this.referencePrestations = refPrestas.filter(
                    (refPresta) => refPresta.etatPrestation === EtatWorkflow.ACTIF
                );
            });
    }

    uploadRule(rules: FormData) {
        this.fileUploader.handleUploadFileServiceCall(
            this.referenceApiService.uploadRuleFile(this.selectedRefPresta, this.selectedTypeRegle, rules)
        );
    }

    uploadFileFinish(success: boolean) {
        if (success) {
            this.notificationService.success('Le fichier de règle à bien été importé.');
        }
    }
}

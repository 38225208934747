import { Component, ElementRef, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { fabric } from 'fabric';
import { Image } from 'fabric/fabric-impl';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { Diagnostic } from '../../../../../../../model/diagnostic.model';
import { Canvas, Legend, Polluant } from '../../../../model/polluant.model';
import { CERCLE, POINT_PAR_POINT, RECTANGLE } from '../../../../shared/polluant.constants';
import { PolluantComponent } from '../../../../utils/polluant-component';
import { AddLegendModalComponent } from './add-legend-modal/add-legend-modal.component';

@Component({
    selector: 'app-constatations-diverses',
    templateUrl: './localisation.component.html',
    styleUrls: ['./localisation.component.scss'],
})
export class LocalisationComponent extends PolluantComponent implements OnInit {
    canvases: Canvas[] = [];
    // Objet permettant de manipuler la balise html <canvas>
    htmlCanvas: fabric.Canvas;
    // Objet contenant les informations nécessaire pour manipuler faciliement les canvas (différent de la balise html <canvas>)
    selectedCanvas: Canvas;

    @ViewChild('canvasContainer', { static: true })
    canvasContainer: ElementRef;

    diagnostic: Diagnostic;
    diagnosticPolluant: Polluant;

    /**
     * Variables utiles pour dessiner en point par point
     */
    private shapePoints = [];
    private lines = [];
    private lineCounter = 0;
    private x = 0;
    private y = 0;
    private delimiterPoint = 10;

    constructor(private cnSpinnerService: CnSpinnerService, private route: ActivatedRoute, private injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.diagnosticService.getCurrentDiagnostic().subscribe((diag) => {
            this.diagnostic = diag;
            this.diagnosticPolluant = diag.contenuDiagnostic as Polluant;
            this.canvases =
                this.diagnosticPolluant.perimetreDescriptif.data.canvases != undefined
                    ? (this.diagnosticPolluant.perimetreDescriptif.data.canvases as Canvas[])
                    : [];
            if (this.canvases.length > 0) {
                this.selectedCanvas = this.canvases[0];
            }

            console.log(this.canvases);
            this.initCanvas();
            this.checkValidity();
        });
    }

    /**
     * Initialise le canvas en le liant avec la balise HTML puis le paramètre.
     */
    initCanvas() {
        this.htmlCanvas = new fabric.Canvas('canvasLocalisation');
        this.htmlCanvas.setDimensions({
            width: this.canvasContainer.nativeElement.offsetWidth,
            height: this.canvasContainer.nativeElement.offsetHeight,
        });

        // Permet de garder une cohérence des formes si un groupe est sélectionné
        this.htmlCanvas.preserveObjectStacking = true;

        // Empêche le clic droit
        this.htmlCanvas.stopContextMenu = true;
    }

    /**
     * Ajoute un évènement qui s'appelle à chaque fois que la taille de
     * la fenêtre change. Redimensionne le Canvas.
     */
    @HostListener('window:resize', ['$event'])
    onWindowResize(event) {
        if (this.htmlCanvas) {
            let newWidth = this.canvasContainer.nativeElement.offsetWidth;
            let newHeight = this.canvasContainer.nativeElement.offsetHeight;
            this.htmlCanvas.setDimensions({
                width: newWidth,
                height: newHeight,
            });
            this.updateBackgroundImage();
        }
    }

    /**
     * Ouvre une pop-up pour créer une Légende
     */
    addLegend() {
        this.discardActiveObject();
        return this.matDialog
            .open(AddLegendModalComponent, {
                data: {
                    newLegend: true,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    let legend = new Legend(
                        result.name,
                        result.shapeType,
                        result.borderType,
                        result.backgroundColor,
                        result.borderColor
                    );
                    this.createShape(legend);
                    this.checkValidity();
                }
            });
    }

    /**
     * Ouvre une pop-up pour éditer une légende
     */
    editLegend(legend: Legend) {
        this.discardActiveObject();
        return this.matDialog
            .open(AddLegendModalComponent, {
                data: {
                    newLegend: false,
                    legend: legend,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    let legend = this.selectedCanvas.legends.find((l) => l.id === result.id);
                    legend.name = result.name;
                    legend.backgroundColor = result.backgroundColor;
                    legend.borderColor = result.borderColor;
                    legend.shape.set('fill', this.convertRGBAToString(legend.backgroundColor));
                    legend.shape.set('stroke', this.convertRGBAToString(legend.borderColor));
                    legend.shape.set('strokeDashArray', result.borderType.value);

                    this.htmlCanvas.renderAll();
                }
                this.checkValidity();
            });
    }

    /**
     * Créer une forme pour une légende donnée
     */
    createShape(legend: Legend) {
        switch (legend.shapeType) {
            case RECTANGLE:
                this.createRectangle(legend);
                break;
            case CERCLE:
                this.createCircle(legend);
                break;
            case POINT_PAR_POINT:
                this.startDrawing(legend);
                break;
            default:
                this.createRectangle(legend);
        }
    }

    /**
     * Créer un rectangle
     */
    createRectangle(legend: Legend) {
        legend.shape = new fabric.Rect({
            left: this.htmlCanvas.width / 2,
            top: this.htmlCanvas.height / 2,
            fill: this.convertRGBAToString(legend.backgroundColor),
            width: 100,
            height: 100,
            originX: 'center',
            originY: 'center',
            strokeWidth: 3,
            stroke: this.convertRGBAToString(legend.borderColor),
            strokeDashArray: legend.borderType.value,
        });
        this.registerShape(legend);
    }

    /**
     * Créer un cercle
     */
    createCircle(legend: Legend) {
        legend.shape = new fabric.Circle({
            left: this.htmlCanvas.width / 2,
            top: this.htmlCanvas.height / 2,
            fill: this.convertRGBAToString(legend.backgroundColor),
            radius: 50,
            originX: 'center',
            originY: 'center',
            strokeWidth: 3,
            stroke: this.convertRGBAToString(legend.borderColor),
            strokeDashArray: legend.borderType.value,
        });
        this.registerShape(legend);
    }

    /**
     * Enregistre la forme dans la Légende donnée
     */
    registerShape(legend: Legend) {
        this.selectedCanvas.legends.push(legend);
        this.htmlCanvas.add(legend.shape);
        this.htmlCanvas.renderAll();
    }

    /**
     * Déselectionne toutes les Légendes
     */
    discardActiveObject() {
        this.htmlCanvas.discardActiveObject();
        this.htmlCanvas.renderAll();
    }

    /**
     * Permet de charger une image
     */
    selectFile(event: any) {
        if (event.target.files && event.target.files[0]) {
            this.cnSpinnerService.show("Chargement de l'image en cours...");
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.onload = (event2: any) => {
                let backgroundImageUri = event2.target.result as string;
                this.createCanvas(backgroundImageUri, file.name);
                this.cnSpinnerService.hide();
            };
            reader.readAsDataURL(file);
        }
    }

    /**
     * Créer un Canvas (utilisé lors de l'ajout d'une image)
     */
    createCanvas(backgroundImageUri, backgroundImageName) {
        if (this.htmlCanvas) this.htmlCanvas.clear();
        let c = new Canvas(backgroundImageUri, backgroundImageName, []);
        this.selectedCanvas = c;
        this.canvases.push(c);
        this.diagnosticPolluant.perimetreDescriptif.data.canvases = this.canvases;
        this.checkValidity();
        this.createBackgroundImage(backgroundImageUri);
    }

    /**
     * Met une image donnée à l'arrière plan du Canvas
     */
    createBackgroundImage(backgroundImageUri) {
        fabric.Image.fromURL(backgroundImageUri, (img) => {
            let backgroundImage = img;
            backgroundImage.name = backgroundImageUri.match(/\/.*$/).splice(0, 1).toString();
            this.htmlCanvas.setBackgroundImage(backgroundImage, this.htmlCanvas.renderAll.bind(this.htmlCanvas), {
                originX: 'left',
                originY: 'top',
                scaleX: this.htmlCanvas.width / img.width,
                scaleY: this.htmlCanvas.height / img.height,
            });
        });
    }

    /**
     * Met à jour les dimensions de l'arrière plan du Canvas
     */
    updateBackgroundImage() {
        let img = this.htmlCanvas.backgroundImage as Image;
        this.htmlCanvas.setBackgroundImage(img, this.htmlCanvas.renderAll.bind(this.htmlCanvas), {
            originX: 'left',
            originY: 'top',
            scaleX: this.htmlCanvas.width / img.width,
            scaleY: this.htmlCanvas.height / img.height,
        });
    }

    /**
     * Supprime une Légende
     */
    removeLegend(legend: Legend) {
        const index = this.selectedCanvas.legends.indexOf(legend);
        this.selectedCanvas.legends.splice(index, 1);
        this.htmlCanvas.discardActiveObject();
        this.htmlCanvas.remove(legend.shape);
    }

    /**
     * Supprime un canvas (image)
     */
    removeCanvas(canvas) {
        let index = this.canvases.indexOf(canvas);
        if (this.selectedCanvas.id === canvas.id) {
            this.htmlCanvas.clear();
            this.selectedCanvas = null;
        }
        this.canvases.splice(index, 1);
        this.htmlCanvas.renderAll();
    }

    /**
     * Sélectionne un canvas actif afin de voir toutes les données liées à celui-ci
     */
    selectionCanvas(canvas: Canvas) {
        this.selectedCanvas = canvas;
        this.htmlCanvas.clear();
        this.createBackgroundImage(canvas.backgroundImageUri);
        let shapes = canvas.legends.map((l) => l.shape);
        this.htmlCanvas.add(...shapes);
        this.htmlCanvas.renderAll();
    }

    /**
     * Vérifie si le canvas donné est sélectionné
     */
    isSelectedCanvas(canvas: any) {
        return this.selectedCanvas.id === canvas.id;
    }

    /**
     * Vérifie si la Légende donnée est sélectionnée
     */
    isSelectedLegend(legend: Legend) {
        return this.htmlCanvas.getActiveObjects().find((obj) => obj === legend.shape);
    }

    /******** Méthodes permettant de déssiner en point par point ********/

    /**
     * Prépare le canvas pour un dessin à main levée
     */
    startDrawing(legend: Legend) {
        this.htmlCanvas.selection = false;
        this.htmlCanvas.forEachObject((obj) => {
            obj.selectable = false;
            obj.evented = false;
        });

        this.htmlCanvas.on('mouse:down', (options) => {
            this.updatePoint(options);
            if (this.shapePoints && this.shapePoints.length && this.firstAndLastPointJoined()) {
                this.stopDrawing(legend);
            } else {
                this.shapePoints.push(new fabric.Point(this.x, this.y));
                let points = [this.x, this.y, this.x, this.y];
                let line = new fabric.Line(points, {
                    strokeWidth: 3,
                    selectable: false,
                    stroke: 'red',
                    x1: this.x,
                    y1: this.y,
                });
                this.lines.push(line);
                this.htmlCanvas.add(this.lines[this.lineCounter]);
                this.lineCounter++;
            }
        });

        this.htmlCanvas.on('mouse:move', (options) => {
            if (this.lines[0]) {
                this.updatePoint(options);
                this.lines[this.lineCounter - 1].set({
                    x2: this.x,
                    y2: this.y,
                });
                this.htmlCanvas.renderAll();
            }
        });
    }

    /**
     * Arrête le dessin à main levée
     */
    stopDrawing(legend: Legend) {
        this.lines.forEach((value, index, ar) => {
            this.htmlCanvas.remove(value);
        });
        legend.shape = this.makeShape(this.shapePoints, legend);
        this.selectedCanvas.legends.push(legend);
        this.htmlCanvas.add(legend.shape);
        this.htmlCanvas.renderAll();

        this.reset();
    }

    /**
     * Réinitialise les données
     */
    private reset() {
        this.shapePoints = [];
        this.lines = [];
        this.lineCounter = 0;
        this.htmlCanvas.off('mouse:down');
        this.htmlCanvas.off('mouse:move');
        this.htmlCanvas.selection = true;
        this.htmlCanvas.forEachObject((obj) => {
            obj.selectable = true;
            obj.evented = true;
        });
    }

    /**
     * Met à jour les coordonnées d'un point
     */
    updatePoint(options) {
        this.x = options.e.clientX - this.canvasContainer.nativeElement.offsetLeft;
        this.y = options.e.clientY - this.canvasContainer.nativeElement.offsetTop;
    }

    /**
     * Vérifie que le premier point et le dernier sont plus ou moins au même endroit
     * afin de terminer le dessin
     */
    firstAndLastPointJoined() {
        let firstPoint: fabric.Point = this.shapePoints[0];
        return (
            this.x <= firstPoint.x + this.delimiterPoint &&
            this.x >= firstPoint.x - this.delimiterPoint &&
            this.y <= firstPoint.y + this.delimiterPoint &&
            this.y >= firstPoint.y - this.delimiterPoint
        );
    }

    /**
     * Fabrique la Légende dessiné à main levée
     */
    makeShape(shapePoints, legend: Legend) {
        let left = this.findLeftPaddingForShape(shapePoints);
        let top = this.findTopPaddingForShape(shapePoints);
        shapePoints.push(new fabric.Point(shapePoints[0].x, shapePoints[0].y));
        let shape = new fabric.Polyline(shapePoints, {
            fill: this.convertRGBAToString(legend.backgroundColor),
            stroke: this.convertRGBAToString(legend.borderColor),
            strokeWidth: 3,
            left: left,
            top: top,
        });
        return shape;
    }

    /**
     * Cherche le point le plus en haut
     */
    findTopPaddingForShape(shapePoints) {
        let result = 999999;
        for (let i = 0; i < this.lineCounter; i++) {
            if (shapePoints[i].y < result) {
                result = shapePoints[i].y;
            }
        }
        return Math.abs(result);
    }

    /**
     * Cherche le point le plus à gauche
     */
    findLeftPaddingForShape(shapePoints) {
        let result = 999999;
        for (let i = 0; i < this.lineCounter; i++) {
            if (shapePoints[i].x < result) {
                result = shapePoints[i].x;
            }
        }
        return Math.abs(result);
    }

    /******** Méthodes permettant de déssiner en point par point (fin) ********/

    /**
     * Converti un objet RGBA en hexadécimal
     */
    private convertRGBAToString(rgba) {
        return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.diagnostic);
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.isInEditMode = false;
        this.htmlCanvas = this.previousFormValue;
        this.htmlCanvas.renderAll();
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.diagnosticPolluant.perimetreDescriptif.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.previousFormValue = this.htmlCanvas;
    }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { URL_GESTION_SYNTHESES } from '../../../../shared/constants/url.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { DISPLAY_FN_COMMANDE } from '../../../../utils/display-function.utils';
import { FormService } from '../../../../services/form.service';
import { Commande } from '../../../../model/commande.model';
import { StateChoiceBoxes } from '../../../../model/states.model';
import {
    DATE_FORMAT_FRANCAIS_HH_MM,
    PARAM_TOOLTIP_NON,
    PARAM_TOOLTIP_OUI,
} from '../../../../shared/constants/cndiag.constants';
import { falseOption, trueOption } from 'src/app/shared/constants/states.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, of } from 'rxjs';
import { startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { EditMode } from '../../../../model/edit-mode.model';
import { ExportSyntheseService } from '../../../../services/export-synthese.service';
import { Synthese } from '../../../../model/synthese';
import { CommandeApiService } from '../../../../services/commande-api.service';
import { Document } from '../../../../model/document.model';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PaginatedDataSource, PaginatedSelection } from '../../../../shared/paging/page';
import { Sort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { combineLatestOrEmpty } from '../../../../utils/rxjs.utils';
import { typePrestationToLabel, typesPrestation } from '../../../../model/type-prestation.model';
import { Moment } from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';

export class SimplePrestationDiagnostic {
    id: string;
    numero: string;
    prestation: string;
    prestationId: string;
    bien: string;
    bienId: string;
    adresse: string;
    dateHeureFinEffective: string;
    statut: string;
}

@Component({
    selector: 'app-creation-export-synthese',
    templateUrl: './creation-export-synthese.component.html',
    styleUrls: ['./creation-export-synthese.component.scss'],
})
export class CreationExportSyntheseComponent extends BaseComponent implements OnInit, OnDestroy {
    synthese: Synthese;
    isReadOnly: any;
    formSynthese: FormGroup;
    isSearchingCommandes = this.formService.isSearchingCommandes$;
    filteredSearchCommandes: Commande[];
    listTemplates: Document[] = [];
    anyCommandeMere = false;

    trueOption: StateChoiceBoxes = trueOption;
    falseOption: StateChoiceBoxes = falseOption;
    tooltipSyntheseCommande = [PARAM_TOOLTIP_OUI, PARAM_TOOLTIP_NON];
    mode: EditMode = 'CREATE';

    commandes$: BehaviorSubject<Commande[]> = new BehaviorSubject<Commande[]>([]);
    dataSource: PaginatedDataSource<SimplePrestationDiagnostic>;
    sort: any;
    displayedColumns = [
        'check',
        'numCommande',
        'client',
        'nomIntervention',
        'typePrestation',
        'prestataires',
        'adresse',
        'dateIntervention',
        'etatDiagnostic',
    ];
    selection = new PaginatedSelection();

    listTypesPrestation = typesPrestation
        .filter((it) => it !== 'COMMUN')
        .map((it) => {
            return { value: it, label: typePrestationToLabel(it) };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

    @ViewChild(MatPaginator) paginator: MatPaginator;
    readonly DATE_FORMAT_FRANCAIS_HH_MM = DATE_FORMAT_FRANCAIS_HH_MM;
    readonly displayFnCommande = DISPLAY_FN_COMMANDE;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly formService: FormService,
        private readonly formBuilder: FormBuilder,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly exportSyntheseService: ExportSyntheseService,
        private readonly commandeApiService: CommandeApiService,
        private readonly notificationService: NotificationService
    ) {
        super();
        this.createForm();
    }

    ngOnInit() {
        this.cnSpinnerService
            .withSpinner(this.exportSyntheseService.listTemplates())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((templates) => {
                this.listTemplates = templates;
                this.synthese = new Synthese();
            });

        this.dataSource = new PaginatedDataSource<SimplePrestationDiagnostic>(
            (pageRequest) => {
                return combineLatestOrEmpty([
                    this.formSynthese
                        .get('commandes')
                        .valueChanges.pipe(startWith(this.formSynthese.get('commandes').value as [])),
                    this.formSynthese
                        .get('typesPrestations')
                        .valueChanges.pipe(startWith(this.formSynthese.get('typesPrestations').value as [])),
                    this.formSynthese
                        .get('startDate')
                        .valueChanges.pipe(startWith(this.formSynthese.get('startDate').value as string)),
                    this.formSynthese
                        .get('endDate')
                        .valueChanges.pipe(startWith(this.formSynthese.get('endDate').value as string)),
                ]).pipe(
                    switchMap(([commandes, typesPrestations, startDate, endDate]) =>
                        this.exportSyntheseService.getPrestationsSynthese(
                            commandes.map((it) => it.id),
                            null,
                            typesPrestations.map((it) => it.value),
                            this.formatDate(startDate),
                            this.formatDate(endDate),
                            pageRequest
                        )
                    ),
                    tap((p) => (this.selection = this.selection.setTotalElements(p.totalElements)))
                );
            },
            { direction: 'asc', active: 'numCommande' },
            20
        );
        this.searchCommande();
    }

    back() {
        this.router.navigate([URL_GESTION_SYNTHESES]);
    }

    creerExportSynthese(selection: PaginatedSelection) {
        const commandeMere = this.formSynthese.get('commandes').value.find((it) => it['commandeMere'] === true);
        this.cnSpinnerService
            .withSpinner(
                this.exportSyntheseService
                    .creerSynthese(
                        this.formSynthese.get('commandes').value.map((it) => it.id),
                        commandeMere.id,
                        commandeMere.agence.id,
                        selection,
                        this.formSynthese.get('template').value,
                        {
                            search: '',
                            typesPrestation: this.formSynthese.value.typesPrestations.map((it) => it.value),
                            startDate: this.formatDate(this.formSynthese.value.startDate),
                            endDate: this.formatDate(this.formSynthese.value.endDate),
                        }
                    )
                    .pipe(
                        takeUntil(this.ngUnsubscribe),
                        switchMap((syntheses) =>
                            combineLatestOrEmpty(syntheses.map((s) => this.exportSyntheseService.generate(s.id)))
                        )
                    )
            )
            .subscribe(() => {
                this.notificationService.success("Le rapport de synthèse a été mis en fil d'attente pour génération.");
                this.back();
            });
    }

    deleteCommande(index: number) {
        const commandeToRemove = this.formSynthese.get('commandes').value[index];
        this.formSynthese.patchValue({
            commandes: this.formSynthese.get('commandes').value.filter((it) => it !== commandeToRemove),
        });
        this.formSynthese.updateValueAndValidity();
        this.commandes$.next(this.formSynthese.get('commandes').value);
        this.isAnyCommandeMere();
    }

    /**
     * Crée la structure du formulaire
     */
    private createForm() {
        this.formSynthese = this.formBuilder.group({
            commandes: [[], Validators.required],
            commande: [
                '',
                [
                    (control) => {
                        if (control.value && typeof control.value.id === 'string') {
                            return null;
                        } else {
                            return { commandeDoesNotExist: true };
                        }
                    },
                ],
            ],
            template: ['', Validators.required],
            typesPrestations: [[]],
            startDate: [''],
            endDate: [''],
        });
    }

    private searchCommande() {
        this.formService
            .commandeSearchValueChange(this.formSynthese.get('commande'), this.commandeApiService)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap((listeCommandes) => {
                    const result = listeCommandes.content.filter(
                        (it) =>
                            !this.commandes$
                                .getValue()
                                .map((itt) => itt.id)
                                .includes(it.id)
                    );
                    return of(result);
                })
            )
            .subscribe((result) => {
                this.filteredSearchCommandes = result;
            });
    }

    onClickBtnAddCommandFromSelect(event: MatAutocompleteSelectedEvent) {
        if (
            event?.option?.value?.id &&
            !this.formSynthese
                .get('commandes')
                .value.map((it) => it?.id)
                .includes(event?.option?.value?.id)
        ) {
            this.commandeApiService.findOneCommande(event?.option?.value?.id).subscribe((commandeFull) => {
                const commandes = this.formSynthese.value.commandes;
                commandeFull['commandeMere'] = commandes.length === 0;
                commandes.push(commandeFull);
                this.commandes$.next(commandes);
                this.formSynthese.patchValue({
                    commande: { id: '' },
                    commandes: commandes,
                });
                this.filteredSearchCommandes = [];
                this.isAnyCommandeMere();
            });
        }
    }

    sortPrestations($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy(this.sort);
    }

    toggleCheckAll(boolean: boolean) {
        this.selection = this.selection.checkAll(boolean);
        // On retourne à la première page de résultats
        this.dataSource.fetch(0, this.paginator.pageSize);
    }

    toggleCheck($event: MatCheckboxChange, row: SimplePrestationDiagnostic) {
        this.selection = this.selection.checkItem(row.id, $event.checked);
    }

    isAnyCommandeMere() {
        this.anyCommandeMere = this.formSynthese.get('commandes').value.some((it) => !!it['commandeMere']);
    }

    CommandeMereChanged(event: string | boolean | (string | boolean)[], com: Commande) {
        this.isAnyCommandeMere();
        if (this.anyCommandeMere) {
            const commandes = this.formSynthese.get('commandes').value;
            if (commandes.filter((it) => it['commandeMere'] === true).length > 1) {
                commandes.forEach((it) => {
                    if (it !== com) {
                        it['commandeMere'] = false;
                    }
                });
                this.formSynthese.patchValue({
                    commande: { id: '' },
                    commandes: commandes,
                });
                this.formSynthese.updateValueAndValidity();
            }
        }
    }

    private formatDate(d: Moment) {
        return d ? d.format('yyyy-MM-DD HH:mm') : '';
    }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ReferenceService } from 'src/app/services/reference.service';
import { RaisonAnnulation } from '../../../model/raison-annulation';

@Component({
    selector: 'app-cancel-intervention-modal',
    templateUrl: './cancel-intervention-modal.component.html',
    styleUrls: ['./cancel-intervention-modal.component.scss'],
})
export class CancelInterventionModalComponent implements OnInit {
    reasonsList: string[] = [];
    reason = '';
    comment = '';
    title = "Annuler l'intervention";

    constructor(
        public dialogRef: MatDialogRef<CancelInterventionModalComponent>,
        private referenceService: ReferenceService
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        this.referenceService
            .findAllRaisonsAnnulation()
            .subscribe((res) => (this.reasonsList = res.map((x) => x.raison)));
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        const raisonAnnulation = new RaisonAnnulation();
        raisonAnnulation.raison = this.reason;
        raisonAnnulation.commentaire = this.comment;

        this.dialogRef.close({ raisonAnnulation: raisonAnnulation });
    }
}

/**
 * Interface de service pour les différent type de prestation qui défini les méthodes à implémenter
 */
import { TypePrestation } from '../../model/type-prestation.model';
import { InjectionToken, Type } from '@angular/core';
import { Intervention, PrestationDiagnostic } from '../../model/intervention.model';
import { ContenuDiagnostic } from '../../model/diagnostic-contenu.model';
import { IDiagReportData, InterventionReportData } from '../../model/rapport.model';
import { Diagnostic } from '../../model/diagnostic.model';
import { Rule } from '../../model/regle.model';
import { Commentaire } from '../../model/commentaire.model';
import { CommentairePredefini } from '../../model/commentaire-predefini.model';
import { BonCommandeAnalyseAdmin } from '../../model/bon-commande.model';
import { Observable } from 'rxjs';
import { Prestation } from 'src/app/model/prestation.model';
import { LegendScreenshot } from '../../model/screenshot-svg.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../model/bien.model';
import { SvgFormValues } from '../../modules/shared/map/export-svg-dialog/shared/interfaces/export-svg-form-values.interface';

export const TYPE_PRESTATION_SERVICE: InjectionToken<ITypePrestationInterface[]> = new InjectionToken<
    ITypePrestationInterface[]
>('TYPE_PRESTATION_SERVICE');

/**
 * Interface à implémenter par un service d'un module diagnostic pour un type de prestation
 * afin de fournir les données et actions spécifiques à ce type de prestation.
 */
export interface ITypePrestationInterface {
    /**
     * Retourne le type de prestation de l'implementation
     */
    getTypePrestation(): TypePrestation;

    /**
     * Prépare le contenu diagnostic avant l'upsert.
     */
    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic);

    /**
     * Retourne le ContenuDiagnostic du diagnostic parent
     */
    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic;

    /**
     * Retourne une instance de ContenuDiagnostic du type de la prestation
     */
    getContenuDiagnostic(
        typePrestation: TypePrestation,
        prestation?: PrestationDiagnostic
    ): Observable<ContenuDiagnostic>;

    /**
     * Retourne la liste des codes BIM d'équipements spécifiques au type de prestation
     */
    getCodeBimEquipementBien(typePrestation: TypePrestation): string[];

    /**
     * Récupération des commentaires prédéfinis selon les réponses antérieures des formulaires
     * @param diagnostic
     * @param commentairesGeneraux
     * @param commentaires
     */
    prepareSpecificComments(
        diagnostic: Diagnostic,
        commentairesGeneraux: Commentaire[],
        commentaires: CommentairePredefini[]
    ): Commentaire[] | any;

    /**
     * Retourne le Type (Class) du component en fonction du nom
     */
    getReportBlockType(componentName: string): Type<any>;

    /**
     * Retourne les données de rapport pour le type de prestation
     */
    getDiagnosticReportData(
        intervention: Intervention,
        diagnostic: Diagnostic,
        rules?: Rule[],
        optionPlan?: boolean
    ): IDiagReportData;

    /**
     * Retourne les données du bon de commande pour le type de prestation
     */
    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData;

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin;

    /**
     * Retourne le pourcentage d'avancement du diagnostic
     */
    getCompletionPercentage(diagnostic: Diagnostic): number;

    /**
     * Prepare les commentaires, filtrés, pour le rapport
     */
    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>);

    /**
     * Vérifie si un bien, niveau, volume ou élément a déjà été rempli dans les points de contrôle d'un diagnostic
     */
    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean;

    generateLegendForScreenshot(diagnostic: Diagnostic, conf: SvgFormValues): LegendScreenshot[];

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: SvgFormValues);

    /**
     * Déplace une pièce vers un niveau lié à un plan (sans le merger à une pièce)
     * @param diagnostic
     * @param volumeSource
     * @param niveauDestination
     * @param currentBien
     */
    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien);

    /**
     * Merge deux pièces depuis un diagnostic et réassigne les équipements
     * @param diagnostic
     * @param volumeSource
     * @param volumeDestination
     * @param currentBien
     */
    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien);

    /**
     * Merge toutes les pièces d'un niveau dans un autre niveau lié à un plan
     * @param diagnostic
     * @param niveauSource
     * @param niveauDestination
     * @param currentBien
     */
    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien);

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien);
}

"use strict";
//***********************************************************************************
//***********************************************************************************
import {cn_clone, cn_dist} from "../utils/cn_utilities";
import {cn_column} from "../model/cn_column";
import {cn_beam_column_type} from "../model/cn_beam_column_type";
import {cn_3d_object_handler} from "./cn_3d_object_handler";
import {fh_clone} from "@acenv/fh-3d-viewer";
import {cn_beam} from "../model/cn_beam";
import {cn_3d_line_handler} from "./cn_3d_line_handler";
import {cn_svg_tool_creation} from "./cn_svg_tool_creation";
import {cn_svg_map} from "./cn_svg_map";
import { cn_polygon_handler } from "./cn_polygon_handler";
import { cn_beam_handler } from "./cn_beam_handler";
import { cn_mouse_event } from "./cn_mouse_event";
import { cn_element } from "../model/cn_element";
import { cn_edition_handler } from "./cn_edition_handler";

/**
 * @class cn_svg_tool_beams_creation
 * A tool to create beams and columns
 *
 * Available events :
 * - "beam_height_clicked(v)" : called when one beam height is clicked. Argument is height in meters. Expect a call to set_beam_height
 * - "column_height_clicked(v)" : called when column height is clicked. Argument is height in meters. Expect a call to set_column_height
 */
export class cn_svg_tool_beams_creation extends cn_svg_tool_creation {
	//*****************************************************************
	/**
	 * Constructor
	 * @param {cn_svg_map} map
	 */
	constructor(map) {
		super(map);

		this._category = "column";
		this._beam_type = null;
		this._creation_handler = null;
		this._initiate_creation();
		this.element_filter = (element) => {
			if (this._category == "column")
				return element.constructor == cn_column;
			return element.constructor == cn_beam;
		};
	}

	//*****************************************************************
	//*** Parameters
	//*****************************************************************
	/**
	 * @returns {string} returns the category in creation ("beam" or "column")
	 */
	get_category() {
		return this._category;
	}

	/**
	 * Sets the category to create ("beam" or "column")
	 * @param {string} category
	 */
	set_category(category) {
		if (category != "beam" && category != "column") return;
		if (this._category == category) return;
		this._category = category;
		this._terminate_edition();
		this._initiate_creation();
	}

	/**
	 * @returns {cn_beam_column_type} returns current beam type
	 */
	get_current_beam_type() {
		return this._beam_type;
	}

	/**
	 * Sets thecurrent beam type
	 * @param {cn_beam_column_type} beam_type
	 */
	set_current_beam_type(beam_type) {
		this._beam_type = beam_type;
		this._initiate_creation();
		this._map.refresh_main();
	}

	/**
	 * Checks current beam type
	 */
	check_current_element_type()
	{
		var beam_types = this._scene.building.get_beam_types();
		if (beam_types.indexOf(this._beam_type) >= 0) return;
		this._beam_type = (beam_types.length > 0)?beam_types[0]:null;
		this._initiate_creation();
	}

	/**
	 *
	 * @returns
	 */
	get_element_types() {
		return this._building.get_beam_types();
	}

	/**
	 * Selects all elements with given type
	 * @param {cn_beam_column_type} beam_type
	 */
	 select_elements_by_type(beam_type)
	 {
		 this._initiate_edition([...this._scene.beams, ...this._scene.columns].filter(p => p.element_type == beam_type));
	 }

 	//*****************************************************************
	//*** Must return 'true' if wa are in modal creation.
	//*****************************************************************
	is_creating() {
		return this._creation_handler.is_creating();
	}

	open_tool() {
		super.open_tool();
		const element_types = this.get_element_types();
		if (element_types.indexOf(this._beam_type) < 0)
			this._beam_type = element_types[0];
		this._initiate_creation();
	}

	//***********************************************************************************
	//**** Refresh
	//***********************************************************************************
	draw(camera) {
		this._creation_handler.visible = (this._focus_handler == this._creation_handler);
		return super.draw(camera);
	}

	//***********************************************************************************
	//**** Start creation of stairs
	//***********************************************************************************
	_initiate_creation() {
		if (this._creation_handler)
		{
			const index = this._handlers.indexOf(this._creation_handler);
			if (index >= 0) this._handlers.splice(index,1);
		}
		var obj = this;
		var scene = obj._scene;
		const create_beam = (this._category == "beam");
		const handler = cn_polygon_handler.create_open_polygon(this,(create_beam)?2:1);
		handler.snap_elements = scene.spaces.concat(scene.beams).concat(scene.columns);
		handler.display_measures = false;
		this._creation_handler = handler;
		this._handlers.unshift(handler);

		handler.on("start_creation",function() {
			obj._terminate_edition();
		});

		handler.on("end_creation",function() {
			var new_element = null;
			if (create_beam)
			{
				const beam = new cn_beam(obj._beam_type);
				new_element = beam;
				beam.vertices[0] = fh_clone(handler.vertices[0]);
				beam.vertices[1] = fh_clone(handler.vertices[1]);

				obj.push_transaction("Création de poutre");
				obj.push_item_set(scene,"beams");
				scene.beams.push(beam);
			}
			else
			{
				const column = new cn_column(obj._beam_type);
				new_element = column;
				column.position = cn_clone(handler.vertices[0]);

				obj.push_transaction("Création de colonne");
				obj.push_item_set(scene,"columns");
				scene.columns.push(column);
			}
			obj.call("creation",[new_element]);
			obj._initiate_creation();

			//*** Edit recently created slab opening */
			obj._initiate_edition([new_element]);
		});
	}
	//***********************************************************************************
	//**** Edition elements
	//***********************************************************************************
	/**
	 * TODO : derivate in order to allow edition of other element in the process of creation
	 * @param {cn_mouse_event} mouse_event
	 * @returns {cn_element}
	 */
	 _find_other_element(mouse_event) {
        var element = null;
		if (this._category == "beam")
			element = this._scene.find_beam(mouse_event.mouse_world,mouse_event.camera.snap_world_distance);
		else
			element = this._scene.find_column(mouse_event.mouse_world,mouse_event.camera.snap_world_distance);
		return element;
	}

	/**
	 * TODO : derivate in order to provide an edition handler
	 * @param {Array<cn_beam | cn_column>} elements
	 * @returns {cn_edition_handler}
	 */
	_build_edition_handler(elements) {
		return new cn_beam_handler(elements,this._map,true);
	}

	/**
	 * TODO : derivate in order to find siblings of an element
	 * @param {cn_beam | cn_column} element
	 * @returns {Array<cn_element>}
	 */
	_get_siblings(element) {
		const ot = element.element_type;
		if (this._category == "beam")
			return this._scene.beams.filter(s => s.element_type == ot);
		return this._scene.columns.filter(s => s.element_type == ot);
	}

}


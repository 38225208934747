import { Injectable } from '@angular/core';
import { Zone } from '../model/zone.model';
import { Besoin } from '../model/besoin.model';
import { forEach } from 'cypress/types/lodash';

@Injectable({
    providedIn: 'root',
})
export class FrequenceService {
    constructor() {}

    determineFrequenceMinimale(zone: Zone): string {
        return this.determineFrequence(this.calculPoints(zone));
    }

    private calculPoints(zone: Zone): number {
        let points: number = 0;
        points = points + this.activiteAdjacente(zone.activiteAdjacente);
        points = points + this.environnement(zone.environnement);
        points = points + this.concentrationFibre(zone.besoinPrelevements);
        points = points + this.moyenProtection(zone.confinement);

        return points;
    }

    private determineFrequence(points: number): string {
        switch (true) {
            case points <= 5:
                return '1 fois par quinzaine';
                break;
            case points <= 7:
                return '1 fois par semaine';
                break;
            case points <= 9:
                return '2 fois par semaine';
                break;
            case points <= 11:
                return "Cotation incohérente vis-à-vis de l'analyse de risque du commanditaire";
                break;
            default:
                return '';
        }
    }

    private moyenProtection(confinement: string) {
        switch (confinement) {
            case 'Aucun':
                return 3;
                break;

            case 'Isolement simple de la zone':
                return 2;
                break;

            case 'Calfeutrement de la zone':
                return 2;
                break;

            default:
                return 1;
        }
    }

    private concentrationFibre(besoinPrelevements: Besoin[]): number {
        let un: boolean = false;
        let deux: boolean = false;
        let trois: boolean = false;
        if (besoinPrelevements !== undefined) {
            besoinPrelevements.forEach((b) => {
                if (b.conditionPrelevement?.sensibiliteAnalytiqueDefinie) {
                    switch (true) {
                        case b.conditionPrelevement?.sensibiliteAnalytique < 100:
                            un = true;
                            break;
                        case b.conditionPrelevement?.sensibiliteAnalytique < 6000:
                            deux = true;
                            break;
                        case b.conditionPrelevement?.sensibiliteAnalytique >= 6000:
                            trois = true;
                            break;
                        default:
                            return 0;
                    }
                }
            });
        }
        if (trois) return 3;
        else if (deux) return 2;
        else return 1;
    }

    private environnement(environnement: string[]): number {
        if (environnement.includes('Intérieur')) return 2;
        else if (environnement.includes('Extérieur')) return 1;
        else return 0;
    }

    private activiteAdjacente(activiteAdjacente: string): number {
        switch (activiteAdjacente) {
            case 'Public':
                return 3;
                break;

            case 'Entreprise':
                return 2;
                break;

            default:
                return 1;
        }
    }
}

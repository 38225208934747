import { Component, HostListener, OnInit } from '@angular/core';
import {
    aucun,
    demontable,
    enPartie,
    horsTensionOption,
    Non,
    nonDemontable,
    nonOption,
    NonVerifiable,
    nonVerifiabledOption,
    Oui,
    ouiOption,
    sansObjetOption,
    sousTensionOption,
    tous,
} from '../../../shared/electricite.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormContext } from '../../../../../../model/rule/form-context.model';
import { FormService } from '../../../../../../services/form.service';
import {
    Bouton,
    Electricite,
    ElectriciteConfig,
    PointControleReponse,
    ValeurCheckpoint,
} from '../../../model/electricite.model';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { BaseComponent, DateUtils, Icon, MongoUtils } from 'src/app/commons-lib';
import { InterventionService } from '../../../../../../services/intervention.service';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { Intervention } from '../../../../../../model/intervention.model';
import { EtatValidation } from '../../../../../../model/etat-progression.model';
import { ActivatedRoute } from '@angular/router';
import { ElectriciteService } from '../../../services/electricite.service';
import { Niveau } from '../../../../../../model/bien.model';
import { TypeCommentaire } from '../../../../../../model/type-commentaire.model';
import { StateChoiceBoxes } from '../../../../../../model/states.model';
import { apartmentState, houseState } from '../../../../../../shared/constants/states.constants';
import { Commentaire } from '../../../../../../model/commentaire.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CommentModalComponent } from '../../../../../shared/comment-modal/comment-modal.component';
import { DialogUtils } from '../../../../../../utils/dialog.utils';
import { EtapeDiagnosticInitialisationElectricite } from 'src/app/model/diagnostic-etape.model';
import { any, List } from 'underscore';

@Component({
    selector: 'app-installation',
    templateUrl: './installation.component.html',
    styleUrls: ['./installation.component.scss'],
})
export class InstallationComponent extends BaseComponent implements OnInit {
    readonly COMMENT_TYPES: TypeCommentaire[] = ['JUSTIFICATION_NON_CONFORMITE', 'NOTE_PERSONNELLE', 'REMARQUE'];
    readonly COMMENT_TYPES_CAPOTS: TypeCommentaire[] = ['NOTE_PERSONNELLE', 'REMARQUE', 'MOTIF_NON_DEMONTABLE'];
    readonly CHOICES_30MA = [
        new StateChoiceBoxes('Oui (plusieurs seul 30mA)', 'ouip', null),
        new StateChoiceBoxes('Oui (un seul 30mA)', 'oui1', null),
        new StateChoiceBoxes('Non (présence de 30MA mais ne protégeant pas toute l’installation)', 'non1', null),
        new StateChoiceBoxes('Absence', 'absence', null),
    ];

    readonly ouiNonChoices = [ouiOption, nonOption];
    readonly demontableChoices = [demontable, nonDemontable];
    readonly TOUS = new StateChoiceBoxes('Tous (plusieurs capots)', 'tous', new Icon('check'));
    readonly TOUS_1 = new StateChoiceBoxes('Tous (1 seul capot)', 'tous1', new Icon('check'));
    readonly AUCUN_1 = new StateChoiceBoxes('Aucun (1 seul capot)', 'aucun1', new Icon('check'));
    readonly AUCUN_P = new StateChoiceBoxes('Aucun (plusieurs seul capots)', 'aucunP', new Icon('check'));
    readonly choicesTypeBien = [houseState, apartmentState];
    readonly choicesTension = [sousTensionOption, horsTensionOption];

    formInsallation: FormGroup;
    currentContenuDiagnostic: Electricite;
    diagnostic: Diagnostic;
    intervention: Intervention;
    readonlyMode = false;
    choicesPresenceDispositif30mA = [Oui, Non, NonVerifiable];
    choicesCapotsTableaux = [tous, aucun, enPartie];
    showDispositifCapotsTableauxElectrique: boolean = false;
    aJustifier: string[] = []; // liste des idInterne
    public dispositifsValid: boolean;
    public niveaux: Niveau[];
    public currentKey: string;
    public nbElementsTableaux: number = 0;
    public nbElements30mA: number = 0;
    public reloadComposant: string; // reload composant app-state-input pb de maj
    private _boutons: Bouton[];
    private _dispositifCapotsDemontable: any[] = [];

    constructor(
        private interventionService: InterventionService,
        private route: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private diagnosticService: DiagnosticService,
        private etatProgressionService: EtatProgressionService,
        private formService: FormService,
        private formBuilder: FormBuilder,
        private electriciteService: ElectriciteService,
        protected readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.electriciteService.electriciteConfig$,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic, config]) => {
                this._boutons = (config as ElectriciteConfig).boutons;
                this.diagnostic = diagnostic;
                this.intervention = intervention;
                const relationBienPrincipal = Intervention.getRelationInterventionBienPrincipal(this.intervention);
                this.niveaux = relationBienPrincipal.bien.description;
                for (const niv of this.niveaux) {
                    for (const vol of niv.volumes) {
                        if (vol.elementsAControler) {
                            this.nbElements30mA += vol.elementsAControler.filter(
                                (ele) => ele.codeBim == 'trente_Ma'
                            ).length;
                            if (this.nbElements30mA > 0) {
                                for (const el of vol.elementsAControler) {
                                    if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[el.id]) {
                                        this.initValeurCheckpoint(el.id, null);
                                    }
                                }
                            }
                            for (const capot of vol.elementsAControler.filter(
                                (ele) => ele.codeBim == 'tableau-electrique'
                            )) {
                                this._dispositifCapotsDemontable.push(capot);
                            }
                        }
                    }
                }
                this.nbElementsTableaux += this._dispositifCapotsDemontable.length;
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(intervention, diagnostic);
                this.currentContenuDiagnostic = diagnostic.contenuDiagnostic as Electricite;
                if (!this.currentContenuDiagnostic.typeBien.valeur) {
                    this.currentContenuDiagnostic.typeBien.valeur = relationBienPrincipal.bien.idTypeBien;
                    this.onChangeFromUser(this.currentContenuDiagnostic.typeBien.valeur, 'typeBien');
                }
                if (this.nbElementsTableaux == 0) {
                    this.currentContenuDiagnostic.capotsDemontables.valeur = aucun.value;
                }
                if (this.nbElements30mA == 0) {
                    this.currentContenuDiagnostic.presenceDispositif30mA.valeur = Non.value;
                }
                this.formInsallation = this.formBuilder.group({
                    capotsDemontables: this.formService.createFormControl(
                        'capotsDemontables',
                        new FormContext(
                            'capotsDemontables.valeur',
                            this.currentContenuDiagnostic,
                            Validators.required,
                            this.ngUnsubscribe
                        )
                    ),
                    presenceDispositif30mA: this.formService.createFormControl(
                        'presenceDispositif30mA',
                        new FormContext(
                            'presenceDispositif30mA.valeur',
                            this.currentContenuDiagnostic,
                            Validators.required,
                            this.ngUnsubscribe
                        )
                    ),
                    typeBien: this.formService.createFormControl(
                        'typeBien',
                        new FormContext(
                            'typeBien.valeur',
                            this.currentContenuDiagnostic,
                            Validators.required,
                            this.ngUnsubscribe
                        )
                    ),
                    sousTension: this.formService.createFormControl(
                        'sousTension',
                        new FormContext(
                            'sousTension.valeur',
                            this.currentContenuDiagnostic,
                            Validators.required,
                            this.ngUnsubscribe
                        )
                    ),
                });
                this.formInsallation.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                    this.checkValidity();
                });
            });
    }

    /**
     * Affichage de l'écran de droit avec la liste des dispositifs à contrôler
     * @param {string} key (Capot | Dispositif 30mA)
     */
    voirLesDispositifs(key: string) {
        this.currentKey = key;
        this.showDispositifCapotsTableauxElectrique = true;
    }

    /**
     * En fonction de currentKey [Capots ou Dispositif 30mA]
     * Alimentation par oui ou par non tous les élements à contrôler
     * @param {string} value
     */
    public changeAll(value: string) {
        for (const niv of this.niveaux) {
            for (const vol of niv.volumes) {
                for (const element of vol.elementsAControler) {
                    if (
                        this.currentKey == 'Capots'
                            ? element.codeBim == 'tableau-electrique'
                            : element.codeBim == 'trente_Ma'
                    ) {
                        this.setElementAControler(element.id, value);
                    }
                }
            }
        }
        this.setCapotsDemontablesByDispositifs();
    }

    addJustification(etape: EtapeDiagnosticInitialisationElectricite) {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    replaceTypesCommentaires: true,
                    commentairesId: etape.commentairesId,
                    typesCommentaires: this.COMMENT_TYPES_CAPOTS,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.currentContenuDiagnostic.capotsDemontables;
                    etape.commentairesId = result.commentairesId;
                }
                //this.updateAJustifier();
            });
    }

    public onChangeFromUser(value: string | boolean, property: string) {
        if (!value) return;

        let bouton = this.getBouton(value);
        if (property == 'capotsDemontables') {
            if (this.nbElementsTableaux == 0) {
                this.currentContenuDiagnostic.capotsDemontables.valeur = aucun.value;
                this.showErrorMsg(property);
                return;
            }
            this.setDispositifsCapotsByValue(value);
            bouton = this.getBouton(this.getChoiceByValueForCapotsDemontables(value).value);
        } else if (property == 'presenceDispositif30mA') {
            if (this.nbElements30mA == 0) {
                this.currentContenuDiagnostic.presenceDispositif30mA.valeur = Non.value;
                this.showErrorMsg(property);
                return;
            }

            bouton = this.getBouton(this.getChoiceByValueForPresenceDispositif30mA(value).value);
        }
        if (!bouton) {
            console.error('Bouton non trouvé !');
            return;
        }
        // Réinit PCR
        let oldValue = this.currentContenuDiagnostic[property].oldValeur;
        if (oldValue && property == 'capotsDemontables') {
            oldValue = this.getChoiceByValueForCapotsDemontables(oldValue).value;
        } else if (oldValue && property == 'presenceDispositif30mA') {
            oldValue = this.getChoiceByValueForPresenceDispositif30mA(oldValue).value;
        }
        if (oldValue) {
            const oldBouton = this.getBouton(oldValue);
            if (oldBouton) {
                for (const pcr of oldBouton.lstPointsControleReponse) {
                    this.setPointControle(pcr, true);
                }
            }
        }
        // set PCR
        for (const pcr of bouton.lstPointsControleReponse) {
            this.setPointControle(pcr, false);
        }
        this.currentContenuDiagnostic[property].valeur = value;
        this.currentContenuDiagnostic[property].oldValeur = this.currentContenuDiagnostic[property].valeur;
    }

    /**
     * Capots :
     * Réponse Automatique si il n'y a pas de dispositif :  "Aucun"
     * 30Ma :
     * Réponse Automatique si il n'y a pas de dispositif : "Non"
     * => Si l'utilisateur clique sur une autre réponse message d'erreur : "Merci de rajouter un dispositif"
     * @param {string} property
     */
    private showErrorMsg(property: string) {
        this.reloadComposant = property;
        setTimeout(() => (this.reloadComposant = null), 200);
        this._snackBar.open('Merci de rajouter un dispositif', '', { duration: 5000 });
    }

    /**
     * Return le choix en fonction de la valeur et du nombre de dispositif
     * @param {string} value
     * @returns {StateChoiceBoxes}
     */
    private getChoiceByValueForCapotsDemontables(value: any): StateChoiceBoxes {
        switch (value) {
            case tous.value:
                if (this.nbElementsTableaux == 1) {
                    return this.TOUS_1;
                }
                return this.TOUS;
            case aucun.value:
                if (this.nbElementsTableaux == 1) {
                    return this.AUCUN_1;
                }
                return this.AUCUN_P;
        }
        return enPartie;
    }

    private getChoiceByValueForPresenceDispositif30mA(value: any): StateChoiceBoxes {
        switch (value) {
            case this.choicesPresenceDispositif30mA[0].value: // OUI
                if (this.nbElements30mA == 1) {
                    return this.CHOICES_30MA[1]; // 'Oui (un seul 30mA)
                }
                return this.CHOICES_30MA[0]; // Oui (plusieurs seul 30mA)
            case this.choicesPresenceDispositif30mA[1].value: // NON
                if (this.nbElements30mA == 1) {
                    return this.CHOICES_30MA[2]; // Non (présence de 30MA mais ne protégeant pas toute l’installation)
                }
                return this.CHOICES_30MA[3]; // Absence
        }
        return this.choicesPresenceDispositif30mA[2]; // NON_VERIFIABLE
    }

    private getBouton(value: string | boolean): Bouton {
        let choice = this.choicesPresenceDispositif30mA.find((opt) => opt.value == value);
        if (!choice) {
            choice = this.choicesCapotsTableaux.find((opt) => opt.value == value);
        }
        if (!choice) {
            choice = this.choicesTypeBien.find((opt) => opt.value == value);
        }
        if (!choice) {
            choice = this.choicesTension.find((opt) => opt.value == value);
        }
        /*if (!choice) {
            choice = this.choicesSensibiliteGeneral.find((opt) => opt.value == value);
        }*/
        if (!choice) {
            choice = [this.TOUS, this.TOUS_1, this.AUCUN_1, this.AUCUN_P, enPartie].find((opt) => opt.value == value);
        }
        if (!choice) {
            choice = this.CHOICES_30MA.find((opt) => opt.value == value);
        }
        if (!choice) {
            console.error('Choice option Non trouvé : ' + choice);
            return;
        }

        return this._boutons.find((btn) => btn.description == choice.label);
    }

    private setPointControle(pcr: PointControleReponse, isClear: boolean) {
        if (pcr.idPointControle) {
            if (isClear) {
                if (this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle]) {
                    for (const idComm of this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle]
                        .commentairesId) {
                        // Suppression des commentaires
                        this.intervention.commentaires = this.intervention.commentaires.filter(
                            (com) => com.id != idComm
                        );
                    }
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle] = null;
                }
                return;
            }
            this.initValeurCheckpoint(pcr.idPointControle, this.getValueFromLibelle(pcr.reponse));

            this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].isGrey = pcr.isGrey;
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[
                pcr.idPointControle
            ].greyLienPointControleExplication = pcr.greyLienPointControleExplication;
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].greyExplication =
                pcr.greyInitialisationExplication;
            const idCom = this.addCommentaire(
                pcr.greyInitialisationExplication,
                this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].commentairesId
            );
            if (idCom) {
                this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].commentairesId.push(
                    idCom
                );
            }
        }
    }

    // Ajout un commentaire sur le point de contrôle avec l'explication initiale
    // => Vérifie s'il est pas déjà existant
    // option :
    // ** Création d'un seul commentaire qui sera lié à plusieurs commentaire
    // ** Éviter de créer plusieurs commentaires avec le même contenu
    private addCommentaire(expl: string, commentairesId: string[]): string {
        if (!(expl && expl.length)) {
            return null;
        }
        // 1\ Vérification si non existant
        for (const id of commentairesId) {
            const comm: Commentaire = this.intervention.commentaires.find((com) => com.id == id);
            if (comm && comm.contenu == expl) {
                return null;
            }
        }

        // 2\ Création du commentaire
        const nouveauCommentaire = new Commentaire();
        nouveauCommentaire.date = DateUtils.localDateTimeString();
        nouveauCommentaire.id = MongoUtils.generateObjectId();
        nouveauCommentaire.type = 'REMARQUE';
        nouveauCommentaire.contenu = expl;
        this.intervention.commentaires = this.intervention.commentaires.concat(nouveauCommentaire);
        this.interventionService.updateIntervention(this.intervention);
        return nouveauCommentaire.id;
    }

    private initValeurCheckpoint(key: string, valeur: string | boolean) {
        if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[key]) {
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[key] = new ValeurCheckpoint();
        }
        this.diagnostic.pointsDeControleBiens[0].valeursParametres[key].valeur = valeur;
    }

    public setElementAControler(id: string, value: string | boolean) {
        this.initValeurCheckpoint(id, value);
        this.setCapotsDemontablesByDispositifs();
    }

    private getValueFromLibelle(reponse: string): string | boolean {
        const option = [ouiOption, nonOption, nonVerifiabledOption, sansObjetOption].find(
            (opt) => opt.label.toLowerCase() == reponse.toLowerCase()
        );
        return option ? option.value : null;
    }

    private checkValidity() {
        let etat: EtatValidation = 'INVALID';
        if (this.formInsallation && this.formInsallation.valid) {
            this.dispositifsValid = this.verifyReponsesDispositifs();
            etat = this.dispositifsValid ? 'VALID' : 'INVALID';
        }
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic);
    }

    /**
     * Renvoie true si tout les dispositif ont été renseigné
     * @returns {boolean}
     */
    private verifyReponsesDispositifs(): boolean {
        for (const niv of this.niveaux) {
            for (const vol of niv.volumes) {
                for (const ele of vol.elementsAControler) {
                    if (ele.codeBim == 'tableau-electrique') {
                        if (
                            !(
                                this.diagnostic.pointsDeControleBiens[0].valeursParametres[ele.id] &&
                                this.diagnostic.pointsDeControleBiens[0].valeursParametres[ele.id].valeur
                            )
                        ) {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }

    /**
     * Permet de répondre automatiquement à
     * "Capots des tableaux électriqueS démontables"
     * par
     * ** Tous : Si tous les dispositifs sont Démontable
     * ** Aucun : Si tous les dispositifs ne sont pas démontable
     * ** En partie : Si au moins un dispositif est démontable
     */
    private setCapotsDemontablesByDispositifs() {
        let nbTous: number = 0;
        for (const ele of this._dispositifCapotsDemontable) {
            if (this.diagnostic.pointsDeControleBiens[0].valeursParametres[ele.id]) {
                if (this.diagnostic.pointsDeControleBiens[0].valeursParametres[ele.id].valeur == demontable.value) {
                    nbTous += 1;
                }
            }
        }
        let valeur = aucun.value; // aucun
        if (nbTous == this.nbElementsTableaux) {
            valeur = tous.value; // Tous
        } else if (nbTous > 0) {
            valeur = enPartie.value; // En partie
        }

        this.currentContenuDiagnostic.capotsDemontables.valeur = valeur;
    }

    /**
     * En fonction de la valeur (Tous, Aucun, En partie)
     * Alimentation des dispositifs
     * ** Tous par démontable
     * ** Auncun par Non démontable
     * ** En partie par vide
     *
     * @param value
     */
    private setDispositifsCapotsByValue(value: string | boolean) {
        let valeur = null; // représente "En partie" => vide
        if (value == tous.value) {
            valeur = demontable.value;
        } else if (value == aucun.value) {
            valeur = nonDemontable.value;
        }
        for (const ele of this._dispositifCapotsDemontable) {
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[ele.id].valeur = valeur;
        }
        if (value == enPartie.value) {
            this.voirLesDispositifs('Capots');
        }
    }

    public volumeHaseElement(volume): boolean {
        for (const element of volume.elementsAControler) {
            if (
                this.currentKey == 'Capots' ? element.codeBim == 'tableau-electrique' : element.codeBim == 'trente_Ma'
            ) {
                return true;
            }
        }
        return false;
    }

    @HostListener('document:click', ['$event'])
    onDetectClick($event: any) {
        this.checkValidity();
    }
}

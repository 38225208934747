import { BuildingStore, IconData, IEditorHandler } from '@acenv/cnmap-angular-editor-lib';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ParametersGroup,
    ProductType,
    ParameterDefinition,
    ParameterType,
    ParameterNumberDefinition,
} from '@acenv/wikipim-catalog';
import { AuthenticationStore, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { InterventionService } from '../../../../../services/intervention.service';
import { BienService } from '../../../../../services/bien.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { CnSpinnerService } from '../../../cn-spinner/service/cn-spinner.service';
import { Backup } from '@acenv/cnmap-angular-editor-lib/lib/model/backup.model';
import { HttpClient } from '@angular/common/http';
import { ReferenceService } from '../../../../../services/reference.service';
import { DefinitionParametre, TypeParametre } from '../../../../../model/definition-parametre.model';

@Injectable({
    providedIn: 'root',
})
export class EditorHandler implements IEditorHandler {
    constructor(
        private interventionService: InterventionService,
        private buildingStore: BuildingStore,
        private bienService: BienService,
        private notificationService: NotificationService,
        private confirmationService: ConfirmationService,
        private authenticationStore: AuthenticationStore,
        private cnSpinnerService: CnSpinnerService,
        private router: Router,
        private http: HttpClient,
        private referenceService: ReferenceService
    ) {}
    canExportBbp(): Observable<boolean> {
        return of(false);
    }

    displayWikipimCatalog(): boolean {
        return false;
    }
    getObjectsParametersHidden(): Observable<string[]> {
        return of([]);
    }
    setObjectsParameterHidden(codeBim: string, hidden: boolean): Observable<string[]> {
        return of([]);
    }

    getCurrentUserName(): Observable<string> {
        return this.authenticationStore.getCurrentUser().pipe(map((user) => `${user.lastName} ${user.firstName}`));
    }

    getBuildingName(): Observable<string> {
        return this.bienService.getCurrentBien().pipe(map((bien) => bien.bien.nom));
    }

    getName(): Observable<string> {
        return this.bienService.getCurrentBien().pipe(map((bien) => bien.bien.nom));
    }

    getLocalization(): Observable<{ address: string; postCode: string; city: string }> {
        return this.bienService.getCurrentBien().pipe(
            map((currentBien) => ({
                address: currentBien.bien.adresse.voie ? currentBien.bien.adresse.voie : '',
                postCode: currentBien.bien.adresse.codePostal ? currentBien.bien.adresse.codePostal : '',
                city: currentBien.bien.adresse.ville ? currentBien.bien.adresse.ville : '',
            }))
        );
    }

    getNotificationIcon(): Observable<IconData> {
        // const drawing = this.drawingService.getCurrentDrawingValue();
        // return this.offlineStorageService.getUnsyncedDrawingsIds().pipe(
        //     map(unsyncedDrawingsIds => {
        //         if (unsyncedDrawingsIds.includes(drawing.id)) {
        //             return new IconData('sync_disabled', 'Maquette non synchronisée sur le serveur', 'icon-error');
        //         } else {
        //             return null;
        //         }
        //     })
        // );
        return of(null);
    }

    getWikipimProductsTypes(): Observable<ProductType[]> {
        return combineLatest([this.referenceService.findAllTypesElementsAControler()]).pipe(
            switchMap(([objects]) => {
                const productTypes = objects.map((obj) => {
                    const productType = new ProductType();
                    productType.id = obj.id;
                    productType.codeBim = obj.codeBim;
                    productType.name = obj.nom;
                    productType.description = obj.description;
                    productType.imageFileId = obj.imageFileId;
                    productType.imageUrl = obj.icone;
                    productType.parentsIds = obj.idsAncetres?.length ? obj.idsAncetres[0] : [];
                    productType.nbProducts = obj.nbProducts;
                    productType.abstract = obj.abstrait;
                    productType.hasChildren = false;
                    productType.flag = obj.flag;
                    productType.parametersDefinitions = [];
                    for (const dp of obj.definitionsParametres) {
                        const definitionsParametres = new ParameterDefinition();
                        definitionsParametres.groupId = dp.groupId;
                        definitionsParametres.order = dp.order;
                        definitionsParametres.name = dp.nom;
                        definitionsParametres.description = dp.description;
                        definitionsParametres.codeBim = dp.codeBim;
                        definitionsParametres.instance = dp.instance;
                        definitionsParametres.catalog = dp.catalog;
                        definitionsParametres.canHaveDefaultValue = dp.canHaveDefaultValue;
                        definitionsParametres.canBeProportional = dp.canBeProportional;
                        definitionsParametres.readonly = dp.readonly;
                        definitionsParametres.type = ParameterType[TypeParametre[dp.type]];
                        definitionsParametres.choices = dp.parametreListeChoix;
                        const numberDefinitions = new ParameterNumberDefinition();
                        numberDefinitions.min = dp.parametreNombre.min;
                        numberDefinitions.max = dp.parametreNombre.max;
                        numberDefinitions.nbDecimals = dp.parametreNombre.nbDecimales;
                        numberDefinitions.unit = dp.parametreNombre.unite;
                        definitionsParametres.numberDefinitions = numberDefinitions;
                        productType.parametersDefinitions.push(definitionsParametres);
                    }
                    return productType;
                });
                return of(productTypes);
            })
        );
    }

    isReadOnly(): Observable<boolean> {
        // return this.projectService.getCurrentProject().pipe(map(project => project.extendedRights === 'GUEST_RO'));
        return of(false);
    }

    navigateToEditor(toUrl: string, relativeTo: ActivatedRoute) {
        localStorage.setItem('editorBackUrl', this.router.url);

        if (relativeTo) {
            this.router.navigate([toUrl], {
                relativeTo,
            });
        } else {
            this.router.navigate([toUrl]);
        }
    }

    back() {
        const backUrl = localStorage.getItem('editorBackUrl');

        if (backUrl) {
            this.router.navigate([backUrl]);
        } else {
            const back = this.router.url.split('/bien/')[0];
            this.router.navigate([back]);
        }
    }

    save(): Observable<void> {
        const relationInterventionBien = this.bienService.getCurrentBienValue();
        const currentIntervention = this.interventionService.getCurrentInterventionValue();
        return this.cnSpinnerService.withSpinner(
            this.buildingStore.getCurrentBuilding().pipe(
                take(1),
                switchMap((building) => {
                    const json = building.serialize();
                    /*let niveauIndex = 0;
                    for (const storey of json.storeys) {
                        let index = 0;
                        for (const space of storey.scene.spaces) {
                            if (!space.name) {
                                if (index === 0) {
                                    space.name = 'Extérieur';
                                } else {
                                    space.name = 'Espace ' + niveauIndex + '.' + index;
                                }
                            }
                            index++;
                        }
                        niveauIndex++;
                    }*/
                    currentIntervention.relationInterventionBiens.find(
                        (it) => it === relationInterventionBien
                    ).bien.jsonPlan = JSON.stringify(json);
                    return this.interventionService.updateIntervention(currentIntervention).pipe(
                        tap(() => {
                            this.notificationService.success('Maquette enregistrée');
                            // this.interventionService.reloadCurrentIntervention();
                            // Indique que toutes les modifications ont été sauvées
                            this.bienService.setPlanModified(false);
                            this.buildingStore.setCurrentBuildingSaved();
                        }),
                        map(() => {})
                    );
                })
            ),
            'Sauvegarde en cours...'
        );
    }

    saveAs(): Observable<void> {
        return of(void 0);
    }

    navigateToBackgroundMapManager() {
        const backgroundUrl = this.router.url.split('/map/')[0] + '/background-maps';
        this.router.navigate([backgroundUrl]);
    }

    autoSave(backups: Backup[]): Observable<void> {
        return of(null);
    }

    getWikipimParametersGroup(): Observable<ParametersGroup[]> {
        return of([]);
    }

    getWikipimParametersHidden(): Observable<string[]> {
        return of([]);
    }

    setWikipimParameterHidden(codeBim: string, hidden: boolean): Observable<string[]> {
        return of([]);
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** A tool to edit stairs
//***********************************************************************************
//***********************************************************************************

import {cn_add, cn_clone, cn_sub, cn_normal, cn_dot, cn_dist, cn_cart, cn_polar} from "../utils/cn_utilities";
import {cn_stairs} from "../model/cn_stairs";
import {cn_disk_handler} from "./cn_disk_handler";
import {cn_polygon_handler} from "./cn_polygon_handler";
import {cn_edition_handler} from "./cn_edition_handler";
import { cn_edit_box } from "./cn_edit_box";
import { cn_pastille } from "./cn_pastille";
import { cn_number_input, cn_number_select_input } from "./cn_inputs";
import { cn_svg_map } from "./cn_svg_map";

export class cn_stairs_handler extends cn_edition_handler {
	/**
	 * Constructor
	 * @param {Array<cn_stairs>} stairs_list
	 * @param {cn_svg_map} map
	 * @param {boolean} creation : if true, will only alow some modifications
	 */
	constructor(stairs_list, map, creation=false) {
		super(stairs_list,map);
		const obj = this;
		this.stairs_list = stairs_list;
		this.stairs = (stairs_list.length == 1)?stairs_list[0]:null;

		this._map = map;
		this._scene = this._map._scene;
		this._transaction_manager = this._map._building.transaction_manager;

		this._pending_changes = null;
		this._handler = null;
		this._mouseover_flat_part = null;
		this._ghost = null;

		if (this.stairs)
		{
			if (this.stairs.stairs_type == "straight")
				this._edit_stairs_straight(this.stairs);
			else if (this.stairs.stairs_type == "round")
				this._edit_stairs_round(this.stairs);
		}

		//*** Edit box for mass selection */
		const edit_box = new cn_edit_box(this,stairs_list,creation);
		this._handlers.push(edit_box);
		this._edit_box = edit_box;

		//*** stair_width pastille */
		const stair_width_pastille = new cn_pastille([0,0],"arrow_left_right.svg");
		edit_box.add_pastille(stair_width_pastille);
		stair_width_pastille.svg_class = "pastille_background white";
		stair_width_pastille.title="Largeur de marche";
		stair_width_pastille.clicked = function() {
			const input = new cn_number_input("Largeur de marches",stairs_list[0].stair_width,"m",2,0.5,10);
			if (stairs_list.some(s => s.stair_width != input.value))
				input.label = "Largeur de marches (variable)";
			input.callback = function() {
				obj._transaction_manager.push_transaction("Largeur d'escalier");
				stairs_list.forEach(stairs => {
					var previous_value = stairs.stair_width;
					stairs.stair_width = input.value;
					stairs.build_valid_borders();
					stairs.stair_width = previous_value;
					if (stairs.valid)
					{
						obj._transaction_manager.push_item_set(stairs,["stair_width","slab_opening"],function(s){stairs.build_borders();stairs.update_space();});
						stairs.stair_width = input.value;
						stairs.build_slab_opening();
					}
					else
					{
						stairs.build_borders();
					}
					stairs.update_space();
				});
				map.refresh();
			};
			map.call("number_input",input);
		}

		//*** stair_height pastille */
		const stair_height_pastille = new cn_pastille([0,0],"arrow_up_down.svg");
		edit_box.add_pastille(stair_height_pastille);
		stair_height_pastille.svg_class = "pastille_background white";
		stair_height_pastille.title="Hauteur de marche";
		stair_height_pastille.clicked = function() {
			const keys = ["stair_height","stair_depth","stair_number"];
			const input = new cn_number_select_input("Calcul de hauteur de marche");
			input.values.push(new cn_number_input("Hauteur de marche",stairs_list[0].actual_stair_height,"m",2,0,1));
			input.values.push(new cn_number_input("Profondeur de marche",stairs_list[0].actual_stair_depth,"m",2,0,10));
			input.values.push(new cn_number_input("Nombre de marches",stairs_list[0].actual_stair_number,"",0,0,1000));
			const params = stairs_list[0].get_stair_parameter();
			if (stairs_list.some(s => s.get_stair_parameter().key != params.key || s.get_stair_parameter().value != params.value))
				input.label = "Calcul de hauteur de marche (variable)";
			input.choice = keys.indexOf(params.key);
			input.callback = function() {
				obj._transaction_manager.push_transaction("hauteur de marches");
				const new_value = {key:keys[input.choice],value:input.values[input.choice].value};
				stairs_list.forEach(stairs => {
					var previous_value = stairs.get_stair_parameter();
					stairs.set_stair_parameter(new_value);
					stairs.build_borders();
					stairs.set_stair_parameter(previous_value);
					if (stairs.valid)
					{
						obj._transaction_manager.push_item_set(stairs,keys,function(s){stairs.build_borders();stairs.update_space();});
						stairs.set_stair_parameter(new_value);
					}
					else
					{
						stairs.build_borders();
					}
					stairs.update_space();
				});
				map.refresh();
			};
			map.call("number_select_input",input);
		}

		//*** stair_height pastille */
		this._opening_slab_mode = false;
		if (this.stairs)
		{
			this._opening_slab_pastille = new cn_pastille([0,0],"content_cut.svg");
			edit_box.add_pastille(this._opening_slab_pastille);
			this._opening_slab_pastille.svg_class = "pastille_background white";
			this._opening_slab_pastille.title="Modifier la trémie";
			this._opening_slab_pastille.clicked = function() {
				if (obj._opening_handler)
				{
					obj.remove_handler(obj._opening_handler);
					obj._opening_handler = null;
					obj._opening_slab_pastille.svg_class = "pastille_background white";
				}
				else
				{
					obj._edit_slab_opening(obj.stairs);
					if (obj._opening_handler)
						obj._opening_slab_pastille.svg_class = "pastille_background green";
				}
			}

			edit_box.add_lock_pastille(obj._transaction_manager);
		}

		edit_box.add_select_siblings_pastille("stairs_type");
	}

	//***********************************************************************************
	//**** Refresh
	//***********************************************************************************
	draw(camera) {
		if (this.stairs)
		{
			this._handler.visible = this._handler.active = (!this.stairs.locked && this._opening_handler == null);
			this._opening_slab_pastille.visible = !this.stairs.locked;
		}
		var html = super.draw(camera);

		if (this._mouseover_flat_part)
			html += this._mouseover_flat_part.draw_highlight(camera,true);

		if (this._handler && this._ghost)
			html += "<g opacity='0.99'>" + this._ghost.draw(camera) + "</g>";

		return html;
	}

	//***********************************************************************************
	//**** clear move effects
	//***********************************************************************************
	clear_move() {
		super.clear_move();
		this._mouseover_flat_part = null;
		this._ghost = null;
	}

	//***********************************************************************************
	//**** Mouse callbacks
	//***********************************************************************************
	click(mouse_event) {
		if (super.click(mouse_event)) return true;
		if (this._mouseover_flat_part)
		{
			this._transaction_manager.push_transaction("Palier d'escalier");
			var stairs = this.stairs;
			this._transaction_manager.push_item_set(this._mouseover_flat_part,"flat",function(){stairs.build_borders();});

			this._mouseover_flat_part.flat = !this._mouseover_flat_part.flat;
			stairs.build_borders();
			this._flat_part_grabbed = false;
			return true;
		}
		return false;
	}

	grab (mouse_event) {
		if (super.grab(mouse_event)) return true;
		if (this._mouseover_flat_part) return true;
		return false;
	}

	drop (mouse_event) {

		this._ghost = null;
		if (this._pending_changes)
		{
			this._pending_changes.update_deep();
			this._pending_changes = null;
		}

		return super.drop(mouse_event);
	}

	move (mouse_event) {

		if (super.move(mouse_event)) return true;

		if (this.stairs && !this._opening_handler)
		{
			this._mouseover_flat_part = this.stairs.find_flat_part(mouse_event.mouse_world);
			if (this._mouseover_flat_part) return true;
		}

		return false;
	}

	drag (mouse_event) {
		return super.drag(mouse_event);
	}

	//***********************************************************************************
	//**** Start edition of slab opening
	//***********************************************************************************
	edit_stairs(stairs) {
		if (stairs.stairs_type == "straight")
			this._edit_stairs_straight(stairs);
		else if (stairs.stairs_type == "round")
			this._edit_stairs_round(stairs);
	}

	_edit_stairs_straight(stairs){
		var obj = this;
		var scene = obj._scene;

		var vts = stairs.vertices;
		var ctr = [];
		for (var i in vts)
			ctr.push(cn_clone(vts[i]));

		this._handler = new cn_polygon_handler(this,ctr,false);
		this._handlers.push(this._handler);
		this._handler.space = stairs.space;
		this._handler.accept_outside = true;
		this._handler.snap_elements = scene.spaces;
		const upper_storey = this._map._storey.get_relative_storey(1);
		if (upper_storey) this._handler.snap_elements = this._handler.snap_elements.concat(upper_storey.scene.slab_openings);

		//*** Change callback */
		this._handler.on("change", function(transform){

			obj._transaction_manager.push_transaction("Modification d'escalier",stairs.ID);
			obj._transaction_manager.push_item_set(stairs,["vertices","axis","slab_opening"],function() {
				stairs.build_borders();
				});

			var stairs_vertices = stairs.vertices;
			var handler_vertices = obj._handler.vertices;

			while (stairs_vertices.length < handler_vertices.length)
				stairs_vertices.push([0,0]);

			if (stairs_vertices.length > handler_vertices.length)
				stairs_vertices.splice(0,stairs_vertices.length - handler_vertices.length);

			for (var i=0; i < handler_vertices.length;i++)
				stairs_vertices[i] = cn_clone(handler_vertices[i]);

			stairs.build_borders();
			stairs.update_space();

			if (transform)
				stairs.slab_opening = stairs.slab_opening.map(transform);
			else
				stairs.build_slab_opening();
		});

		//*** Change checker */
		this._handler.check_change = function() {
			var handler_vertices = obj._handler.vertices;
			if (handler_vertices.length < 2) return true;

			obj._ghost = new cn_stairs(scene);
			obj._ghost.axis = stairs.axis;
			obj._ghost.stair_width = stairs.stair_width;
			obj._ghost.set_stair_parameter(stairs.get_stair_parameter());
			for (var i in handler_vertices)
				obj._ghost.vertices.push(cn_clone(handler_vertices[i]));

			obj._ghost.build_borders();
			if (!obj._ghost.valid)
			{
				obj._ghost = null;
				return false;
			}
			if (stairs.space == null)
			{
				obj._ghost = null;
				return true;
			}

			obj._ghost.update_space();
			if (obj._ghost.space == null)
			{
				return false;
			}
			obj._handler.space = obj._ghost.space;
			obj._ghost = null;
			return true;
		};

		//*** pass rectangle size request to parent */
		this._handler.on("set_rectangle_size",function(sz){
			obj.call("set_rectangle_size",sz);
		});

		//*** refresh on non mouse driven event */
		this._handler.on("force_update",function(sz){
			obj._map.refresh();
		});
	}

	_edit_slab_opening(stairs)
	{
		if (this._opening_handler)
		{
			this.remove_handler(this._opening_handler);
			this._opening_handler = null;
		}
		if (!stairs.valid || stairs.slab_opening.length == 0) return;
		var obj = this;
		this._opening_handler = new cn_polygon_handler(this,stairs.slab_opening,true);
		this._opening_handler.svg_class = "stairs_slab_opening_active";
		this._opening_handler.snap_elements = this._map._scene.spaces.concat([stairs]);
		this._handlers.push(this._opening_handler);

		//*** Change callback */
		this._opening_handler.on("change", function(){

			obj._transaction_manager.push_transaction("Modification de trémie d'escalier",stairs.ID);
			obj._transaction_manager.push_item_set(stairs,["slab_opening"]);

			stairs.slab_opening = obj._opening_handler.vertices.map(cn_clone);
		});
		this._opening_handler.on("force_update",function() {
			obj._map.refresh();
		});
	}

	//***********************************************************************************
	/**
	 * Sets size of current shape if rectangular
	 * @param {number[]} sz
	 */
	set_rectangle_size(sz) {
		if (this._handler == null) return;
		if (this._handler.constructor == cn_polygon_handler)
		{
			this._handler.set_rectangle_size(sz);

			if (this._pending_changes)
			{
				this._pending_changes.update_deep();
				this._pending_changes = null;
			}
		}
	}

	_edit_stairs_round(stairs){
		var obj = this;
		var scene = obj._scene;

		this._handler = new cn_disk_handler(this._scene, this._map._controller,stairs.center,stairs.radius, stairs.angles, this);
		this._handlers.push(this._handler);
		this._handler["stairs"] = stairs;
		this._handler.space = stairs.space;
		this._handler.snap_elements = scene.spaces;

		this._handler.on_change = function(){

			obj._transaction_manager.push_transaction("Modification d'escalier",stairs.ID);
			obj._transaction_manager.push_item_set(stairs,["center","radius","angles","slab_opening"],function() {
				stairs.build_borders();
				});

			const old_center = stairs.center;
			const old_radius = stairs.radius;
			const old_angles = stairs.angles;
			stairs.center = cn_clone(obj._handler["center"]);
			stairs.radius = obj._handler["radius"];
			stairs.angles = cn_clone(obj._handler["angles"]);

			stairs.build_borders();
			stairs.update_space();
			if (stairs.radius == old_radius)
			{
				const da = stairs.angles[1] - old_angles[1];
				stairs.slab_opening = stairs.slab_opening.map(v => cn_add(stairs.center,cn_cart(cn_add(cn_polar(cn_sub(v,old_center)),[0,da]))));
			}
			else
				stairs.build_slab_opening();

			obj._map.refresh_main_and_tool();
			obj.call("selection_change");
		};

		this._handler.check_change = function() {

			obj._ghost = new cn_stairs(scene);
			obj._ghost.stair_width = stairs.stair_width;
			obj._ghost.set_stair_parameter(stairs.get_stair_parameter());
			obj._ghost.stairs_type = "round";
			obj._ghost.center = cn_clone(obj._handler["center"]);
			obj._ghost.radius = obj._handler["radius"];
			if (obj._ghost.radius < obj._ghost.stair_width) return false;
			obj._ghost.angles = cn_clone(obj._handler["angles"]);

			obj._ghost.build_borders();
			if (!obj._ghost.valid)
			{
				obj._ghost = null;
				return false;
			}
			if (stairs.space == null)
			{
				obj._ghost = null;
				return true;
			}

			obj._ghost.update_space();
			if (obj._ghost.space == null)
			{
				console.log("space null");
				return false;
			}
			obj._handler.space = obj._ghost.space;
			obj._ghost = null;
			return true;
		};
	}
}


import { Injectable } from '@angular/core';
import { ElectriciteConfig } from '../model/electricite.model';
import { environment } from '../../../../../environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { OfflineStorageService, ResourceWrapper } from '../../../../shared/offline/offline-storage.service';
import { FileService } from '../../../../services/file.service';
import { Observable } from 'rxjs';
import { STORAGE_KEY_CONFIGS_ELECTRICITE } from '../../../../shared/constants/indexeddb.constants';

/**
 * Service d'appel aux APIs pour électricité.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur {@link HttpErrorInterceptor}).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class ElectriciteApiService {
    public resourceUrl = `${environment.apiUrl}/electricite`;
    private http: HttpClient;

    private resourceOffline: ResourceWrapper<ElectriciteConfig, string> = this.offlineStorage.wrapRestResource<
        ElectriciteConfig,
        string
    >(
        {
            cacheName: STORAGE_KEY_CONFIGS_ELECTRICITE,
            idField: 'id',
            resourceUrl: this.resourceUrl,
        },
        false
    );

    private resource: ResourceWrapper<ElectriciteConfig, string> = this.offlineStorage.wrapRestResource<
        ElectriciteConfig,
        string
    >(
        {
            cacheName: STORAGE_KEY_CONFIGS_ELECTRICITE,
            idField: 'id',
            resourceUrl: this.resourceUrl,
        },
        true
    );

    constructor(
        private httpBackend: HttpBackend,
        private fileService: FileService,
        private offlineStorage: OfflineStorageService
    ) {
        this.http = new HttpClient(httpBackend);
    }

    getConfig(): Observable<ElectriciteConfig> {
        return this.http.get<ElectriciteConfig>(this.resourceUrl + '/config');
    }

    findAllElectriciteConfig(): Observable<ElectriciteConfig[]> {
        return this.resource.getAll(null, null, `${this.resourceUrl}/config`);
    }

    findAllElectriciteConfigOffline(): Observable<ElectriciteConfig[]> {
        return this.resourceOffline.getAll();
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent, DateUtils, MongoUtils } from 'src/app/commons-lib';
import { TypeCommentaire } from '../../../model/type-commentaire.model';
import { CommentairePredefini } from '../../../model/commentaire-predefini.model';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { combineLatest } from 'rxjs';
import { Diagnostic } from '../../../model/diagnostic.model';
import { Commentaire } from '../../../model/commentaire.model';
import { CommentaireService } from '../../../services/commentaire.service';
import { InterventionService } from '../../../services/intervention.service';
import { Intervention } from '../../../model/intervention.model';
import { CnSpinnerService } from '../../shared/cn-spinner/service/cn-spinner.service';
import { takeUntil } from 'rxjs/operators';
import { ReferenceService } from 'src/app/services/reference.service';
import { StringUtils } from 'src/app/utils/string.utils';
import { MatDialog } from '@angular/material/dialog';
import { InputTextareaModalComponent } from '../../shared/input-textarea-modal/input-textarea-modal.component';
import { TypeCommentairePipe } from 'src/app/pipes/type-commentaire.pipe';
import { ArrayUtils } from 'src/app/utils/array.utils';

@Component({
    selector: 'app-recapitulatif-commentaires',
    templateUrl: './recapitulatif-commentaires.component.html',
    styleUrls: ['./recapitulatif-commentaires.component.scss'],
})
export class RecapitulatifCommentairesComponent extends BaseComponent implements OnInit {
    commentaires: CommentairePredefini[] = [];
    commentairesGeneraux: Commentaire[] = [];
    commentairesGenerauxFiltres: Commentaire[] = [];
    searchword = '';
    freeComment = '';
    diagnostic: Diagnostic;
    intervention: Intervention;
    readonlyMode = false;
    // Liste des catégories possible correspondant aux chips affichés dans l'IHM
    listCategories = [];
    // Liste des catégories (chips) sélectionnées par l'opérateur et qui permettent de filtrer les résultats
    listCategoriesSelected = [];

    /**
     * Le type de commentaire à afficher
     */
    @Input()
    typeCommentaire: TypeCommentaire;

    /**
     * Les commentaires déjà sélectionnés pour le rapport
     */
    @Input()
    commentairesFinaux: Commentaire[] = [];

    @Output()
    commentairesFinauxChanged = new EventEmitter<Commentaire[]>();

    constructor(
        private readonly referenceService: ReferenceService,
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly commentaireService: CommentaireService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly matDialog: MatDialog,
        private readonly typeCommentairePipe: TypeCommentairePipe
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.commentairesFinaux) {
            this.commentairesFinaux = [];
        }
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.referenceService.findAllCommentairesPredefinis(),
                    this.interventionService.getCurrentIntervention(),
                    this.diagnosticService.getCurrentDiagnostic(),
                ])
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([result, intervention, diagnostic]) => {
                this.intervention = intervention;
                this.diagnostic = diagnostic;

                // En mode readonly ?
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.intervention, diagnostic);

                // On filtre les commentaires selon le type de prestation concernée et l'onglet (recommandation ou constatation diverse)
                this.commentaires = result.filter(
                    (r) => r.typeCommentaire === this.typeCommentaire && r.typePrestation === diagnostic.typePrestation
                );

                // Filtre des commentaires prédéfinis selon les réponses antérieures des formulaires
                this.prepareSpecificComments();

                // Ajout des commentaires généraux provenant des biens
                this.prepareCommentsFromBien();

                this.commentairesGenerauxFiltres = [...this.commentairesGeneraux];

                // Récupérer les catégories possibles
                this.listCategories = [
                    ...new Set(
                        this.commentairesGenerauxFiltres
                            .flatMap((commentaireTemp) => commentaireTemp.categories)
                            .filter((categorieTemp) => categorieTemp !== null)
                            .sort((c1, c2) => c1.id.localeCompare(c2.id))
                    ),
                ];

                // on supprime les doublons
                var cache = {};
                this.listCategories = this.listCategories.filter(function (elem, index, array) {
                    return cache[elem.id] ? 0 : (cache[elem.id] = 1);
                });
            });
    }

    /**
     * Filtre dans les commentaires suggérés
     */
    onInputSearch() {
        this.filterComments();
    }

    /**
     * Ajout manuel d'un commentaire de type saisie libre à la sélection finale
     */
    onClickBtnAddFreeTextComment() {
        this.addToFinalComments(MongoUtils.generateObjectId(), this.typeCommentaire, this.freeComment);
        // Emission du changement
        this.commentairesFinauxChanged.emit(this.commentairesFinaux);
        // Réinitialisation du textarea
        this.freeComment = '';
    }

    /**
     * Suppression d'un commentaire de la sélection finale.
     * Le commentaire supprimé est réinséré dans la liste de suggestion
     */
    onClickBtnDeleteComment(commentaire: any) {
        this.commentairesFinaux.splice(this.commentairesFinaux.indexOf(commentaire), 1);
        // Emission du changement
        this.commentairesFinauxChanged.emit(this.commentairesFinaux);
    }

    /**
     * Ajout d'un commentaire pré-défini dans la liste des commentaires finaux
     * @param commentaire
     */
    onClickBtnAddComment(commentaire: Commentaire) {
        this.commentairesFinaux.push(Object.assign(new Commentaire(), commentaire));
        // Emission du changement
        this.commentairesFinauxChanged.emit(this.commentairesFinaux);
    }

    /**
     * Permet de modifier le texte d'un commentaire
     * @param commentaire
     */
    onClickBtnEditComment(commentaire: Commentaire) {
        this.matDialog
            .open(InputTextareaModalComponent, {
                data: {
                    existingValue: commentaire.contenu,
                    titreModal: 'Modifier le commentaire',
                    labelInput: 'Commentaire',
                    placeholderInput: this.typeCommentairePipe.transform(this.typeCommentaire),
                    texteBoutonValider: 'Valider',
                    texteBoutonAnnuler: 'Annuler',
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    commentaire.contenu = result.valueInput;
                }
            });
    }

    /**
     * Évènement lors du clic sur un chip de filtre de catégorie
     * @param categorie
     */
    onClickChipsCategorie(categorie) {
        const indexCategorie = this.listCategoriesSelected.indexOf(categorie);
        if (indexCategorie == -1) {
            this.listCategoriesSelected.push(categorie);
        } else {
            this.listCategoriesSelected.splice(indexCategorie, 1);
        }

        this.filterComments();
    }

    /**
     * Filtre les commentaires suivant le champs de texte et les catégories sélectionnées
     * Si aucune catégorie n'est sélectionnée, c'est comme si elles étaient toutes sélectionnées
     */
    private filterComments() {
        this.commentairesGenerauxFiltres = this.commentairesGeneraux.filter(
            (commentaireTemp) =>
                StringUtils.toLowerCase(commentaireTemp.contenu).includes(this.searchword) &&
                (this.listCategoriesSelected.length == 0 ||
                    ArrayUtils.hasIntersectionBetweenArrays(this.listCategoriesSelected, commentaireTemp.categories))
        );
    }

    /**
     * Récupération des commentaires prédéfinis selon les réponses antérieures des formulaires
     */
    private prepareSpecificComments() {
        this.commentairesGeneraux = this.commentaireService.prepareSpecificCommentsFromFormResponses(
            this.commentairesGeneraux,
            this.commentaires
        );
    }

    /**
     * Ajout des commentaires généraux provenant des biens
     * @private
     */
    private prepareCommentsFromBien() {
        const commentairesIntervention = this.intervention.commentaires;
        const comsIdsFlattened = this.intervention.relationInterventionBiens.flatMap((b) => b.bien.commentairesId);
        comsIdsFlattened.length &&
            comsIdsFlattened.forEach((id) => {
                const commentaire = commentairesIntervention.find(
                    // On filtre selon le type de commentaire attendu
                    (com) => com.id === id && com.type === this.typeCommentaire
                );
                if (commentaire !== undefined) {
                    this.commentairesGeneraux = this.commentairesGeneraux.concat(commentaire);
                }
            });
    }

    /**
     * Ajout d'un commentaire à la sélection finale
     * @param id
     * @param type
     * @param contenu
     * @private
     */
    private addToFinalComments(id, type, contenu) {
        this.commentairesFinaux = this.commentairesFinaux.concat(this.createCommentaire(id, type, contenu));
    }

    /**
     * Création d'un commentaire
     * @param id
     * @param type
     * @param contenu
     * @private
     */
    private createCommentaire(id, type, contenu): Commentaire {
        const newComment = new Commentaire();
        newComment.id = id;
        newComment.type = type;
        newComment.contenu = contenu;
        newComment.date = DateUtils.localDateTimeString();
        return newComment;
    }

    /**
     * Faire descendre un commentaire
     * @param recom
     */
    onClickBtnMoveCommentDown(recom: Commentaire) {
        this.onClickBtnMoveComment(recom, (i) => ++i);
    }

    /**
     * Faire monter un commentaire
     * @param recom
     */
    onClickBtnMoveCommentUp(recom: Commentaire) {
        this.onClickBtnMoveComment(recom, (i) => --i);
    }

    /**
     * Déplacer un commentaire
     * @param recom
     * @param funct
     * @private
     */
    private onClickBtnMoveComment(recom: Commentaire, funct: (i: number) => number) {
        const oldIndex = this.commentairesFinaux.indexOf(recom);
        const newIndex = funct(oldIndex);
        const commentairesFinauxCopy = [...this.commentairesFinaux];
        commentairesFinauxCopy.splice(newIndex, 0, commentairesFinauxCopy.splice(oldIndex, 1)[0]);
        this.commentairesFinaux = [...commentairesFinauxCopy];
        // Emission du changement
        this.commentairesFinauxChanged.emit(this.commentairesFinaux);
    }
}

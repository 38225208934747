import { Pipe, PipeTransform } from '@angular/core';
import { TypeElementAControler, typeElementAControllerIdToLabel } from '../model/type-element-a-controler.model';

/**
 * Affiche le label du typeElement a controller en fonction de son id
 */
@Pipe({
    name: 'typeElementAController',
})
export class TypeElementAControllerPipe implements PipeTransform {
    constructor() {}

    transform(id: string, typesElementsAController: TypeElementAControler[]): string {
        return typeElementAControllerIdToLabel(id, typesElementsAController);
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MongoUtils } from 'src/app/commons-lib';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypeElementAControler } from '../../../../model/type-element-a-controler.model';
import { Bien } from '../../../../model/bien.model';

/**
 * Classe contenant les data de la modal
 */
class EquipmentModalData {
    constructor(
        public typesElementsAControlerFromReferentiel: TypeElementAControler[],
        public totalTypesElementsAControler: TypeElementAControler[],
        public typeElementAControlerToEdit: TypeElementAControler,
        public bien: Bien
    ) {}
}

@Component({
    selector: 'copy-equipment-modal',
    templateUrl: './copy-equipment-modal.component.html',
    styleUrls: ['./copy-equipment-modal.component.scss'],
})
export class CopyEquipmentModalComponent implements OnInit {
    createMode = false;
    typeElementAControler: TypeElementAControler = new TypeElementAControler();
    nomsTypeElementAControler: string[] = [];
    form: FormGroup;
    deleteEquipment = false;
    alreadyPresent = false;

    constructor(
        public dialogRef: MatDialogRef<CopyEquipmentModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EquipmentModalData,
        private formBuilder: FormBuilder
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        // Liste des noms d'équipements présents dans le référentiel
        this.nomsTypeElementAControler = this.data.typesElementsAControlerFromReferentiel
            .filter((it) => !it.abstrait)
            .map((e) => e.nom);
        // Création du formulaire + validators
        this.form = this.formBuilder.group({
            from: ['', [Validators.required]],
            nom: [
                '',
                [
                    Validators.required,
                    // Validateur d'unicité de nom d'équipement
                    (control: AbstractControl) => {
                        if (
                            this.data.totalTypesElementsAControler
                                .map((b) => b.nom.toLowerCase().trim())
                                .includes(control.value.toLowerCase().trim())
                        ) {
                            return { erreurNomEquipement: true };
                        }
                        return null;
                    },
                ],
            ],
        });
        // Mode édition
        if (this.data.typeElementAControlerToEdit) {
            this.createMode = false;
            // Le validateur doit être différent du mode création : On autorise de garder le même nom qu'avant édition
            this.form.removeControl('from');
            this.form.patchValue({
                nom: this.data.typeElementAControlerToEdit.nom,
            });
            this.form.get('nom').clearValidators();
            this.form.get('nom').setValidators([
                Validators.required,
                (control: AbstractControl) => {
                    if (
                        this.data.totalTypesElementsAControler
                            .map((b) => b.nom.toLowerCase().trim())
                            .includes(control.value.toLowerCase().trim()) &&
                        this.data.typeElementAControlerToEdit.nom.toLowerCase().trim() !==
                            control.value.toLowerCase().trim()
                    ) {
                        return { erreurNomEquipement: true };
                    }
                    return null;
                },
            ]);
            this.form.updateValueAndValidity();
        } else {
            this.createMode = true;
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        // En mode création/duplication, on copie l'équipement de référence, en ne changeant que le nom et son id
        let result: TypeElementAControler;
        if (this.createMode) {
            const baseEquipment = this.data.typesElementsAControlerFromReferentiel.find(
                (e) => e.nom === this.form.get('from').value
            );
            result = {
                ...baseEquipment,
                id: MongoUtils.generateObjectId(),
            };
        } else {
            result = this.data.typeElementAControlerToEdit;
        }
        result.nom = this.form.get('nom').value;
        this.dialogRef.close(result);
    }

    deleteCustomEquipment() {
        this.deleteEquipment = true;
        this.alreadyPresent =
            this.data.bien.description.filter(
                (niveau) =>
                    niveau.volumes.filter((volume) => {
                        return (
                            volume.elementsAControler
                                .map((el) => el.idTypeElementAControler)
                                .filter((it) => it === this.data.typeElementAControlerToEdit.id).length > 0
                        );
                    }).length > 0
            ).length > 0;
    }

    confirmDeletion() {
        this.data.bien.typesElementsAControler.splice(
            this.data.bien.typesElementsAControler.indexOf(this.data.typeElementAControlerToEdit),
            1
        );
        this.dialogRef.close(false);
    }
}

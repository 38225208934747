<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">receipt_long</lib-icon>
            <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'une commande</span>
            <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition d'une commande</span>
            <span *ngIf="mode === 'DUPLICATE' && !isReadOnly">Duplication de la commande</span>
            <span *ngIf="isReadOnly">Consultation de la commande</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button
                *ngIf="!isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="formCommande?.invalid || !commande.bien || !commande.contact"
                (click)="validerCommande()"
            >
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
            <button *ngIf="isReadOnly" mat-raised-button color="primary" (click)="editer()">
                <mat-icon>edit</mat-icon>
                <span>{{ 'Éditer' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>

<div class="equipement-container">
    <div *ngIf="formCommande" class="h-100 row no-gutters p-4 scrollable-content" [formGroup]="formCommande">
        <!-- Colonne gauche-->
        <div class="col-xl-6 pr-2">
            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 m-0 my-3 primary">Informations générales</div>
                    <!-- Type de client -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Type de client</div>
                        <div class="col-xl-8 pr-2">
                            <mat-button-toggle-group
                                *ngIf="!isReadOnly"
                                formControlName="isEntreprise"
                                appearance="legacy"
                            >
                                <mat-button-toggle class="button-toggle-double ACTIF left-toggle" value="true">
                                    Entreprise
                                </mat-button-toggle>
                                <mat-button-toggle class="button-toggle-double INACTIF right-toggle" value="false">
                                    Particulier
                                </mat-button-toggle>
                            </mat-button-toggle-group>

                            <div
                                *ngIf="isReadOnly"
                                class="statut-workflow consultation mb-3"
                                [ngClass]="commande.isEntreprise ? 'ACTIF' : 'INACTIF'"
                            >
                                {{ commande.isEntreprise ? 'Entreprise' : 'Particulier' }}
                            </div>
                        </div>
                    </div>
                    <!-- Numéro de la commande -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Numéro de la commande</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="text" [readonly]="isReadOnly" formControlName="numeroCommande" />
                                <mat-error
                                    *ngIf="formCommande?.get('numeroCommande').hasError('numeroCommandeAlreadyExists')"
                                >
                                    Une autre commande possède déjà le même numéro.
                                </mat-error>
                                <app-mat-error [control]="formCommande?.get('numeroCommande')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Date de signature de la commande -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">
                            Date de signature de la commande
                        </div>
                        <mat-form-field
                            class="col-xl-4 pr-2"
                            (click)="!isReadOnly ? datepickerCommande.open() : ''"
                            appearance="fill"
                        >
                            <input
                                matInput
                                [readonly]="isReadOnly"
                                formControlName="dateCommande"
                                [matDatepicker]="datepickerCommande"
                            />
                            <mat-datepicker-toggle *ngIf="!isReadOnly" matSuffix [for]="datepickerCommande">
                            </mat-datepicker-toggle>
                            <mat-datepicker #datepickerCommande></mat-datepicker>
                            <app-mat-error [control]="formCommande?.get('dateCommande')"></app-mat-error>
                        </mat-form-field>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <!--Colonne droite-->
        <div class="col-xl-6 pl-2">
            <!--Bien principal-->
            <mat-card class="p-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Bien principal</div>
                        <div class="col-xl-8 pr-2">
                            <div *ngIf="!commande.bien" class="col-xl-7 buttons d-flex align-items-center flex-column">
                                <mat-form-field class="flex-grow-1">
                                    <input
                                        formControlName="bien"
                                        [matAutocomplete]="autoBien"
                                        [required]="true"
                                        matInput
                                        [readonly]="isReadOnly"
                                        placeholder="Bien"
                                        type="text"
                                    />
                                    <mat-error *ngIf="formCommande?.get('bien').hasError('bienDoesNotExist')">
                                        Aucun bien n'existe avec ce nom.
                                    </mat-error>
                                    <mat-autocomplete
                                        #autoBien="matAutocomplete"
                                        [displayWith]="displayFnNom"
                                        (optionSelected)="onClickChangeBienFromSelect($event)"
                                    >
                                        <mat-option *ngIf="isSearchingBiens | async" class="is-loading">
                                            <mat-spinner diameter="25"></mat-spinner>
                                        </mat-option>
                                        <ng-container *ngIf="!(isSearchingBiens | async)">
                                            <mat-option *ngFor="let option of filteredSearchBiens" [value]="option">
                                                {{ option.nom }}
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                </mat-form-field>

                                <div>ou</div>
                                <div>
                                    <button
                                        *ngIf="!isReadOnly"
                                        color="accent"
                                        mat-raised-button
                                        class="bt-valid"
                                        (click)="addEditBien(true)"
                                    >
                                        <lib-icon>add_business</lib-icon>
                                        Ajouter un bien principal
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="commande.bien">
                                <div class="d-flex p-1 justify-content-between align-items-center">
                                    <span class="mat-subheading-2 m-0 primary">Nom : {{ commande.bien.nom }}</span>
                                    <div>
                                        <button
                                            *ngIf="!isReadOnly && commande.bien"
                                            mat-icon-button
                                            class="button-group primary"
                                            (click)="addEditBien(false)"
                                        >
                                            <lib-icon class="icon-small">edit</lib-icon>
                                        </button>
                                        <button
                                            *ngIf="!isReadOnly && commande.bien"
                                            mat-icon-button
                                            class="button-group warn"
                                            (click)="cleanBien()"
                                        >
                                            <lib-icon class="icon-small">delete</lib-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <!-- Contacts -->
            <mat-card class="p-2 mt-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Contact client</div>
                        <div class="col-xl-8 d-flex">
                            <div class="col-xl-6 pr-2">
                                <div
                                    *ngIf="!isReadOnly && !commande.contact"
                                    class="col-xl-11 d-flex align-items-center flex-column"
                                >
                                    <mat-form-field class="flex-grow-1">
                                        <input
                                            formControlName="contact"
                                            [matAutocomplete]="autoContact"
                                            matInput
                                            [readonly]="isReadOnly"
                                            placeholder="Contact"
                                            type="text"
                                        />
                                        <mat-error *ngIf="formCommande?.get('contact').hasError('contactDoesNotExist')">
                                            Aucun contact n'existe avec ce nom.
                                        </mat-error>
                                        <mat-autocomplete
                                            #autoContact="matAutocomplete"
                                            [displayWith]="displayFnContact"
                                            (optionSelected)="onClickChangeContactFromSelect($event)"
                                        >
                                            <mat-option *ngIf="isSearchingContacts | async" class="is-loading">
                                                <mat-spinner diameter="25"></mat-spinner>
                                            </mat-option>
                                            <ng-container *ngIf="!(isSearchingContacts | async)">
                                                <mat-option
                                                    *ngFor="let option of filteredSearchContacts"
                                                    [value]="option"
                                                >
                                                    {{
                                                        option.nom
                                                            ? option.estPersonneMorale
                                                                ? option.nom + ' - ' + option.siret
                                                                : option.prenom
                                                                ? option.nom + ' - ' + option.prenom
                                                                : option.nom
                                                            : null
                                                    }}
                                                </mat-option>
                                            </ng-container>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <div>ou</div>
                                    <div class="buttons d-flex align-items-start flex-column">
                                        <button
                                            color="accent"
                                            mat-raised-button
                                            class="bt-valid mb-1"
                                            (click)="addContact()"
                                        >
                                            <lib-icon>person_add</lib-icon>
                                            Ajouter le client
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <div class="d-flex flex-wrap justify-content-between align-items-start" *ngIf="commande.contact">
                <mat-card class="mt-1 d-flex flex-column justify-content-between position-relative small-card">
                    <div>
                        <div class="position-absolute align-self-end button-group">
                            <button
                                *ngIf="!isReadOnly"
                                mat-icon-button
                                class="primary"
                                [disabled]="!(isOnline$ | async)"
                                (click)="editContact()"
                            >
                                <lib-icon class="icon-small">edit</lib-icon>
                            </button>
                            <button *ngIf="!isReadOnly" mat-icon-button class="warn" (click)="deleteContact()">
                                <lib-icon class="icon-small">delete</lib-icon>
                            </button>
                        </div>
                        <div class="mat-h2 primary mb-0 ml-2">{{ commande.contact.type }}</div>
                        <div class="mat-h3 bold ml-2 mb-2">
                            {{ commande.contact.nom | uppercase }}
                            {{ commande.contact.estPersonneMorale ? commande.contact.siret : commande.contact.prenom }}
                        </div>
                        <div class="mb-2 d-flex" *ngIf="commande.contact.adresse && commande.contact.adresse.voie">
                            <div class="mr-2">
                                <lib-icon [class]="'primary'" class="icon-small align-bottom">place</lib-icon>
                            </div>
                            <div>
                                <span class="ws-preline"
                                    >{{ commande.contact.adresse.voie }}
                                    <span *ngIf="commande.contact.adresse.complementAdresse1"
                                        ><br />{{ commande.contact.adresse.complementAdresse1 }}</span
                                    >
                                    <span *ngIf="commande.contact.adresse.complementAdresse2"
                                        ><br />{{ commande.contact.adresse.complementAdresse2 }}</span
                                    >
                                    <br />{{ commande.contact.adresse.codePostal }}
                                    {{ commande.contact.adresse.ville }}</span
                                >
                            </div>
                        </div>
                        <div class="mb-2 d-flex">
                            <div class="mr-2">
                                <lib-icon [class]="'primary'" class="icon-small align-bottom">local_phone</lib-icon>
                            </div>
                            <div>
                                {{ commande.contact.telephone }}
                            </div>
                        </div>
                        <div class="d-flex" *ngIf="commande.contact.email">
                            <div class="mr-2">
                                <lib-icon [class]="'primary'" class="icon-small align-bottom">email</lib-icon>
                            </div>
                            <div>{{ commande.contact.email }}</div>
                        </div>
                    </div>
                </mat-card>
            </div>

            <!-- Agence -->
            <mat-card class="p-2 mt-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Agence</div>
                        <div class="col-xl-8">
                            <mat-form-field class="flex-grow-1 w-100">
                                <input
                                    formControlName="agence"
                                    [matAutocomplete]="autoAgence"
                                    matInput
                                    [readonly]="isReadOnly"
                                    placeholder="Agence"
                                    type="text"
                                />
                                <mat-error *ngIf="formCommande?.get('agence').hasError('agenceDoesNotExist')">
                                    Aucune agence n'existe avec ce nom.
                                </mat-error>
                                <mat-autocomplete #autoAgence="matAutocomplete" [displayWith]="displayFnNom">
                                    <mat-option *ngIf="isSearchingAgences | async" class="is-loading">
                                        <mat-spinner diameter="25"></mat-spinner>
                                    </mat-option>
                                    <ng-container *ngIf="!(isSearchingAgences | async)">
                                        <mat-option *ngFor="let option of filteredSearchAgences" [value]="option">
                                            {{ option.nom }}
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="p-2 mt-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 m-0 my-3 primary">Ajouter un document</div>
                    <app-import-file
                        [documentTitle]="documentTitle"
                        [codeTypeDocument]="codeTypeDocument"
                        [fileFormControl]="documentBonCommandeControl"
                        [readOnlyMode]="isReadOnly"
                    ></app-import-file>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

<div class="markdown-content pb-5 pt-2">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">Périmètre d'investigation</div>

    <div class="px-5 mt-2 block-markdown text-justify">
        <span>{{ values.intro }}</span>
        <br />
        <span style="white-space: pre-line">{{ reportData.perimetre }}</span>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="miss">INSERTION DU PLAN / CROQUIS</span>
    </div>
    <br />
    <div class="px-5 mt-2 block-markdown text-justify">
        <span class="miss">Gestion des exclusions du périmètre</span>
    </div>
</div>

import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Diagnostic } from '../../../../../../../model/diagnostic.model';
import { Polluant } from '../../../../model/polluant.model';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { SectionHelp } from '../../../../../../../model/section-help.model';
import { FormContext } from '../../../../../../../model/rule/form-context.model';
import { FormService } from '../../../../../../../services/form.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { EtatValidation } from '../../../../../../../model/etat-progression.model';
import { CnSpinnerService } from '../../../../../../shared/cn-spinner/service/cn-spinner.service';
import { combineLatest } from 'rxjs';
import { DOMAINES_REGLEMENTAIRE, DOMAINES_REGLEMENTAIRE_LIST } from '../../../../shared/polluant.constants';
import { PolluantComponent } from '../../../../utils/polluant-component';
import { NotificationService } from 'src/app/commons-lib';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FormGroup, Validators } from '@angular/forms';
import { ConditionsPrelevement, ObjectifsMesurage, PolluantConfig } from '../../../../model/polluant-config.model';
import { InterventionService } from '../../../../../../../services/intervention.service';

export class TodoItemNode {
    children: TodoItemNode[];
    parentId: string;
    item: string;
    label: string;
    domaineReglementaire: string[];
    typePrelevement: string;
}

export class TodoItemFlatNode {
    item: string;
    level: number;
    label: string;
    parentId: string;
    domaineReglementaire: string[];
    expandable: boolean;
    typePrelevement: string;
}

@Component({
    selector: 'app-objectifs-mesurages',
    templateUrl: './objectifs.component.html',
    styleUrls: ['./objectifs.component.scss'],
})
export class ObjectifsComponent extends PolluantComponent implements OnInit {
    @ViewChild('tree') tree;

    polluantConfig: PolluantConfig;
    currentContenuDiagnostic: Polluant;
    currentDiagnostic: Diagnostic;
    activityTypesSelected: string[] = [];
    helpObjectifsMesurage = new SectionHelp('Objectif de mesurage', 'POLLUANT_ETUDE_SITUATION', 'objectif-de-mesurage');
    nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
    selectedParent: TodoItemFlatNode | null = null;
    newItemName = '';
    // sources
    treeControl: FlatTreeControl<TodoItemFlatNode>;
    treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
    dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
    // checked
    checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
    conditionPrelevementNode: TodoItemFlatNode[] = [];
    form: FormGroup;
    isLoading = true;
    domainesReglementaireList = DOMAINES_REGLEMENTAIRE_LIST;

    currentParentIdLoop: string = '';

    constructor(
        private interventionService: InterventionService,
        private formService: FormService,
        private route: ActivatedRoute,
        private cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService,
        private injector: Injector
    ) {
        super(injector);
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);

        this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        // this.dataSource.data = this.buildFileTree(TREE_DATA, 0);
        this.dataSource.data = [];
    }

    ngOnInit(): void {
        this.cnSpinnerService.show();
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.route.data,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diag, routerData]) => {
                this.currentDiagnostic = diag;
                this.polluantService
                    .findOnePolluantConfigIndexedDB(this.currentDiagnostic.idConfig)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((res: PolluantConfig) => {
                        if (res == null) {
                            this.notificationService.error(
                                'Erreur : Merci de faire une synchro pour récupérer les configs en local.'
                            );
                            this.cnSpinnerService.hide();
                            return;
                        }
                        this.polluantConfig = res;
                        this.currentContenuDiagnostic = diag.contenuDiagnostic as Polluant;
                        this.isValidated = (this.diagnostic.contenuDiagnostic as Polluant).objectifsMesurage.validated;
                        this.createForm();
                        this.createTree(res);
                        this.isLoading = false;
                        this.expendAllTree();
                        this.isInEditMode =
                            !(
                                !!routerData.readonly ||
                                this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)
                            ) && this.isInEditMode;
                        if (!this.isInEditMode) {
                            this.form.disable();
                        }
                    });
            });
    }

    /**
     * Création du formulaire de checkbox avec un champ de type array et obligatoire
     * @private
     */
    private createForm() {
        this.activityTypesSelected = this.currentContenuDiagnostic.activitesAmiante.valeurs;
        if (!this.activityTypesSelected.includes(DOMAINES_REGLEMENTAIRE.DOMAINE_NORME)) {
            this.activityTypesSelected.push(DOMAINES_REGLEMENTAIRE.DOMAINE_NORME);
        }

        this.form = this.formBuilder.group({
            objectifsMesurage: this.formService.createFormControl(
                'objectifsMesurage',
                new FormContext(
                    'objectifsMesurage.data.objectifsMesurage',
                    this.currentContenuDiagnostic,
                    Validators.required,
                    this.ngUnsubscribe
                )
            ),
        });

        this.form.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.checkValidity();
        });
    }

    /**
     * @description création de l'arbre de checbox avec 2 niveaux
     * Le niveau 0 correspond à l'objectif mesurage
     * Le niveau 1 correspond aux conditions de prelevements
     * @param res
     * @private
     */
    private createTree(res: PolluantConfig) {
        // Notify the change.
        this.dataSource.data = this.buildFileTree(res.objectifsMesurage, 0);
        this.restoreData(this.currentDiagnostic);
    }

    /**
     * Methode temporaire permettant d'ouvrir tous les nodes du premier niveau du Tree
     * @private
     */
    private expendAllTree() {
        setTimeout(() => {
            this.tree.treeControl.expandAll();
            this.cnSpinnerService.hide();
        }, 300);
    }

    /**
     * @description permet de restaurer les données et de cocher les checkboxs
     * @param data
     * @private
     */
    private restoreData(data) {
        if (data) {
            if (data.contenuDiagnostic.objectifsMesurage.data.objectifsMesurage == undefined) {
                data.contenuDiagnostic.objectifsMesurage.data.objectifsMesurage = [];
                return;
            }

            data.contenuDiagnostic.objectifsMesurage.data.objectifsMesurage.forEach((objectif, index, array) => {
                const elem = this.conditionPrelevementNode.find((obj) => {
                    return obj.item === objectif.reference;
                });
                if (elem) {
                    const intersection = this.activityTypesSelected.filter((x) =>
                        elem.domaineReglementaire.includes(x)
                    );
                    if (intersection.length > 0) {
                        this.todoItemSelectionToggle(elem);
                    }
                } else {
                    array.splice(index, 1);
                }
            });
        }
    }

    buildFileTree(configs: any[], level: number): TodoItemNode[] {
        const tree: TodoItemNode[] = [];
        configs.forEach((config) => {
            const node = new TodoItemNode();
            if (config.conditionsPrelevement != undefined) {
                this.currentParentIdLoop = config.id;
                // si c'est un polluant config (premier niveau)
                node.children = this.buildFileTree(config.conditionsPrelevement, level + 1);
                // Si l'arbre des enfants est vide nous n'affichons pas le titre principal et ignorons ce config
                if (node.children.length == 0) {
                    return;
                }
                node.label = config.description;
                node.item = config.id;
            } else {
                if (this.activityTypesSelected.filter((x) => config.typeActiviteAmiante.includes(x)).length > 0) {
                    Object.assign(node, config);
                    node.item = config.reference;
                    node.label = config.referenceLongue;
                    node.parentId = this.currentParentIdLoop;
                    node.domaineReglementaire = config.typeActiviteAmiante;
                    node.typePrelevement = config.typePrelevement;
                }
            }

            if (Object.keys(node).length > 0) tree.push(node);
        });
        return tree;
    }

    getLevel = (node: TodoItemFlatNode) => node.level;

    isExpandable = (node: TodoItemFlatNode) => node.expandable;

    getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

    hasChild = (_: number, nodeData: TodoItemFlatNode) => nodeData.expandable;

    hasNoContent = (_: number, nodeData: TodoItemFlatNode) => nodeData.item === '';

    transformer = (node: TodoItemNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
        Object.assign(flatNode, node);
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        flatNode.label = node.label;
        flatNode.domaineReglementaire = node.domaineReglementaire;
        flatNode.typePrelevement = node.typePrelevement;
        if (level === 1) {
            flatNode.parentId = this.currentParentIdLoop;
            this.conditionPrelevementNode.push(flatNode);
        } else {
            this.currentParentIdLoop = node.item;
        }
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    checkAll() {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
            if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[i])) {
                this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
            }
            this.treeControl.expand(this.treeControl.dataNodes[i]);
        }
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return descendants.every((child) => this.checklistSelection.isSelected(child));
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((child) => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: TodoItemFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.every((child) => this.checklistSelection.isSelected(child));
        this.checkAllParentsSelection(node);

        const objectifMesurage = [];
        // @ts-ignore
        this.checklistSelection._selection.forEach((item: any) => {
            if (item.level === 1) {
                objectifMesurage.push({
                    ...item,
                    reference: item.item,
                });
            }
        });
        this.form.patchValue({ objectifsMesurage: objectifMesurage });
        this.checkValidity();
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
        const objectifMesurage = [];

        // @ts-ignore
        this.checklistSelection._selection.forEach((item) => {
            if (item.level === 1) {
                objectifMesurage.push({
                    ...item,
                    reference: item.item,
                });
            }
        });

        this.form.patchValue({ objectifsMesurage: objectifMesurage });
        this.checkValidity();
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: TodoItemFlatNode): void {
        let parent: TodoItemFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: TodoItemFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
        const currentLevel = this.getLevel(node);
        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    private checkValidity() {
        //Si le formulaire est désactivé, on l'active afin de pouvoir savoir si il est valide
        let isFormEnabled = this.form.enabled;
        if (!isFormEnabled) {
            this.form.enable({
                emitEvent: false,
            });
        }

        let etat: EtatValidation = 'INVALID';
        if (this.form && this.form.valid) {
            etat = 'VALID';
        }

        //Si le formulaire était désactivé avant, on le désactive à nouveau
        if (!isFormEnabled) {
            this.form.disable({
                emitEvent: false,
            });
        }

        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, etat, this.currentDiagnostic);
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.form.disable();
        this.isInEditMode = false;
        //patch new values
        this.form.patchValue({ objectifsMesurage: this.previousFormValue.objectifsMesurage });

        //Uncheck all
        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
            if (this.checklistSelection.isSelected(this.treeControl.dataNodes[i])) {
                this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
            }
            this.treeControl.expand(this.treeControl.dataNodes[i]);
        }

        //Recheck all nodes
        this.previousFormValue.objectifsMesurage.forEach((objectif: any) => {
            const elem = this.conditionPrelevementNode.find((obj) => {
                return obj.item === objectif.reference;
            });
            if (elem) {
                this.checklistSelection.toggle(elem);
            }
        });
    }

    saveModification() {
        this.form.disable();
        this.isInEditMode = false;
        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.currentContenuDiagnostic.objectifsMesurage.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.form.enable();
        this.isInEditMode = true;
        this.currentContenuDiagnostic.objectifsMesurage.validated = false;
        this.previousFormValue = this.form.getRawValue();
    }
}
export function getInfoObjectifsSelectionnes(
    objectifs: ObjectifsMesurage[],
    objectifsSelectionnes: any[]
): [string[], string[]] {
    var desc = [];
    var normes = [];
    objectifs.forEach((objectif) => {
        objectif.conditionsPrelevement.forEach((cond) => {
            objectifsSelectionnes.forEach((objectifSelectionne) => {
                if (cond.reference == objectifSelectionne.reference) {
                    if (!desc.includes(objectif.description)) {
                        desc.push(objectif.description);
                    }
                    if (!normes.includes(cond.norme + ' ' + cond.normeApplication)) {
                        normes.push(cond.norme + ' ' + cond.normeApplication);
                    }
                    return;
                }
            });
        });
    });
    return [desc, normes];
}

export function getConditionPrelevement(
    objectifs: ObjectifsMesurage[],
    objectifsSelectionnes: any[]
): ConditionsPrelevement[] {
    var condition = [];
    objectifs.forEach((objectif) => {
        objectif.conditionsPrelevement.forEach((cond) => {
            objectifsSelectionnes.forEach((objectifSelectionne) => {
                if (cond.reference == objectifSelectionne.reference) {
                    condition.push(cond);
                    return;
                }
            });
        });
    });
    return condition;
}

export function getRefInfo(objectifs: ObjectifsMesurage[], ref: string): ConditionsPrelevement {
    var conditionPrelo;
    objectifs.forEach((objectif) => {
        objectif.conditionsPrelevement.forEach((cond) => {
            if (cond.reference == ref) {
                conditionPrelo = cond;
                return;
            }
        });
    });
    return conditionPrelo;
}

export function getRefsInfoById(objectifs: ObjectifsMesurage[], refsId: string[]): ConditionsPrelevement[] {
    var conditionsPrelo = [];
    objectifs.forEach((objectif) => {
        objectif.conditionsPrelevement.forEach((cond) => {
            if (refsId.indexOf(cond.id) != -1) {
                conditionsPrelo.push(cond);
            }
        });
    });
    return conditionsPrelo;
}

import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { Icon } from 'src/app/commons-lib';
import {
    COMMENT,
    COMMENT_DIVERS,
    COMMENT_RECO,
    CONCLUSION,
    CONTROL_POINT,
    DESC,
    DESC_BIEN,
    DESC_BIEN_ANNEXE,
    DOCUMENTS_RAPPORT,
    GESTION_PLAN,
    INIT,
    PREVI_RAPPORT,
    RAPPORT,
    RECAP,
    REPORTAGE_PHOTO,
    RESTE_A_FAIRE,
    SAVE_RAPPORT,
} from '../../../shared/constants/names.step.constants';
import { QuitDiagnosticGuard } from '../../../guards/quit-diagnostic.guard';
import { RapportComponent } from '../../diagnostics-shared/rapport/rapport.component';
import { DiagnosticConfigComponent } from '../../diagnostics-shared/diagnostic-config/diagnostic-config.component';
import { DescriptionBienPageComponent } from '../../interventions/description/description-bien-page/description-bien-page.component';
import {
    interventionRouteDescriptionIterateFunction,
    interventionRouteDescriptionIterateLabel,
    interventionRouteDescriptionIteratePath,
    interventionRouteDescriptionIterateSuffix,
} from '../../interventions/description/description-bien.module';
import { EditorGuard } from '../../../guards/editor.guard';
import { QuitDescriptionBienGuard } from '../../../guards/quit-description-bien.guard';
import { ConstatationsDiversesComponent } from '../../diagnostics-shared/comments/constatations-diverses/constatations-diverses.component';
import { RecommandationsComponent } from '../../diagnostics-shared/comments/recommandations/recommandations.component';
import { ReportagePhotoListComponent } from '../../diagnostics-shared/reportage-photo/reportage-photo-list/reportage-photo-list.component';
import { DocumentsRapportComponent } from '../../diagnostics-shared/documents-rapport/documents-rapport.component';
import { ValidationDocsComponent } from './modules/validation-docs/validation-docs.component';
import { GeneralComponent } from './modules/initialisation/general/general.component';
import { PointsDeControlesComponent } from './modules/points-de-controles/points-de-controles.component';
import { InitConfigResolver } from './services/initConfig.resolver';
import { Intervention } from '../../../model/intervention.model';
import { Diagnostic } from '../../../model/diagnostic.model';
import { CeeModel, Chapitre, TypeCee } from './model/cee.model';
import { ControleAdministratifComponent } from './modules/conclusion/controle-administratif/controle-administratif.component';
import { ConcPointsDeControleComponent } from './modules/conclusion/conc-points-de-controle/conc-points-de-controle.component';
import { AnoIsolantComponent } from './modules/conclusion/isolants/ano-isolant.component';
import { ResteAFaireComponent } from './modules/recapitulatif/reste-a-faire/reste-a-faire.component';
import { CeeModule } from './cee.module';

const DEFAULT_PAGE = 'validation-docs';

// Le fait d'utiliser "replace" pour les + et = permet l'affichage de la navigation car n'arrive pas à faire la comparaison
export function checkPointRouteIterateFunctionSolution(context: {
    currentIntervention: Intervention;
    currentDiagnostic: Diagnostic;
    idsNomSolution: string[];
    typesCee: TypeCee[];
    chapitres: Chapitre[];
}): string[] {
    const routess = [];
    let chapitres: Chapitre[] = [];
    if (context.typesCee && context.chapitres) {
        const typeCee: TypeCee = context.typesCee.find(
            (type) => type.description == context.currentDiagnostic.typePrestation
        );
        const racine: Chapitre = context.chapitres.find((ch) => !ch.idChapitreParent && !ch.idTypeCee);
        chapitres = context.chapitres.filter(
            (ch) => ch.idTypeCee == typeCee.idInterne && ch.idChapitreParent == racine.idInterne
        );
    }
    (context.currentDiagnostic.contenuDiagnostic as CeeModel).solutions.solutions.forEach((solution) => {
        if (solution.infoIsolant && context.idsNomSolution) {
            const solValue = solution.infoIsolant.find((value) => context.idsNomSolution.includes(value.id));
            if (solValue && solValue.valeur) {
                const solutionName = solValue.valeur;
                const hash: string = btoa(unescape(encodeURIComponent(JSON.stringify({ solutionName })))).replace(
                    /=/g,
                    ''
                );
                routess.push(hash);
                for (const zone of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                    const zoneName = zone;
                    const hashWithZone: string = btoa(
                        unescape(encodeURIComponent(JSON.stringify({ solutionName, zoneName })))
                    ).replace(/=/g, '');
                    routess.push(hashWithZone);
                    for (const chapitre of chapitres.map((ch) => ch.description)) {
                        const hashWithChapitre: string = btoa(
                            unescape(encodeURIComponent(JSON.stringify({ solutionName, zoneName, chapitre: chapitre })))
                        ).replace(/=/g, '');
                        routess.push(hashWithChapitre);
                    }
                }
            }
        }
    });
    return routess.length
        ? routess
        : [btoa(unescape(encodeURIComponent(JSON.stringify({ init: 'Point de contrôle' })))).replace(/=/g, '')];
}

export function checkPointRouteIteratePath(value: string): string {
    return value;
}

export function checkPointRouteIterateLabel(value: string): string {
    const obj: any = JSON.parse(decodeURIComponent(escape(window.atob(value))));
    return obj.init ? obj.init : obj.chapitre ? obj.chapitre : obj.zoneName ? obj.zoneName : obj.solutionName;
}

export function checkPointRouteIterateSuffix(value: string): string {
    return '_' + value.replace(/ /g, '_');
}

/**
 * Routes du module à utiliser pour les barres de navigation.
 * L'attribut "data" doit implémenter l'interface {@link NavigationLinkRouteData}
 */
export const ceeNavBarRoutes: Routes = [
    {
        path: 'description',
        data: {
            label: 'Description',
            icon: new Icon('floor-plan', 'mdi'),
            code: DESC,
        },
        children: [
            {
                path: '',
                redirectTo: 'bien-principal',
                pathMatch: 'full',
            },
            {
                path: 'bien-principal',
                data: {
                    label: 'Bien principal',
                    code: DESC_BIEN,
                },
                canDeactivate: [QuitDescriptionBienGuard],
                component: DescriptionBienPageComponent,
            },
            {
                path: ':idBien',
                data: {
                    iterate: {
                        function: interventionRouteDescriptionIterateFunction,
                        path: interventionRouteDescriptionIteratePath,
                        label: interventionRouteDescriptionIterateLabel,
                        suffix: interventionRouteDescriptionIterateSuffix,
                    },
                    code: DESC_BIEN_ANNEXE,
                },
                canDeactivate: [QuitDescriptionBienGuard],
                component: DescriptionBienPageComponent,
            },
            {
                path: 'bien-principal/editor',
                canActivate: [EditorGuard],
                loadChildren: () =>
                    import('../../shared/map/editor-map/editor-map.module').then((m) => m.EditorMapModule),
            },
            {
                path: ':idBien/editor',
                canActivate: [EditorGuard],
                loadChildren: () =>
                    import('../../shared/map/editor-map/editor-map.module').then((m) => m.EditorMapModule),
            },
        ],
    },
    {
        path: 'validation-docs',
        data: {
            label: 'Validation Docs',
            icon: new Icon('list-status', 'mdi'),
            code: 'VALIDE_DOCS',
        },
        canDeactivate: [QuitDiagnosticGuard],
        component: ValidationDocsComponent,
    },
    {
        path: 'initialisation',
        data: {
            label: 'Initialisation',
            icon: new Icon('settings'),
            code: INIT,
        },
        children: [
            {
                path: '',
                redirectTo: 'general',
                pathMatch: 'full',
            },
            {
                path: 'general',
                data: {
                    label: 'GENERAL',
                    code: 'GENERAL',
                    status: 'INVALID',
                },
                component: GeneralComponent,
            },
        ],
    },
    {
        path: 'points-de-controles',
        data: {
            label: 'Points de contrôles',
            icon: new Icon('list-status', 'mdi'),
            code: CONTROL_POINT,
        },
        children: [
            {
                path: '',
                redirectTo: 'controles',
            },
            {
                path: 'controles',
                data: {
                    label: 'Solution',
                },
                component: PointsDeControlesComponent,
                children: [
                    {
                        path: ':hash',
                        data: {
                            iterate: {
                                function: checkPointRouteIterateFunctionSolution,
                                path: checkPointRouteIteratePath,
                                label: checkPointRouteIterateLabel,
                                suffix: checkPointRouteIterateSuffix,
                            },
                        },
                    },
                ],
            },
        ],
    },
    {
        path: 'commentaires',
        data: {
            label: 'Commentaires',
            icon: new Icon('playlist-edit', 'mdi'),
            code: COMMENT,
            status: 'VOID',
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'recommandations',
                pathMatch: 'full',
            },
            {
                path: 'recommandations',
                data: {
                    label: 'Recommandations',
                    code: COMMENT_RECO,
                    status: 'VOID',
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: RecommandationsComponent,
            },
            {
                path: 'constatations-diverses',
                data: {
                    label: 'Constatations diverses',
                    code: COMMENT_DIVERS,
                    status: 'VOID',
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: ConstatationsDiversesComponent,
            },
        ],
    },
    {
        path: 'recapitulatif',
        data: {
            label: 'Récapitulatif',
            icon: new Icon('eye', 'fa'),
            code: RECAP,
            status: 'VOID',
        },
        children: [
            {
                path: '',
                redirectTo: 'reste-a-faire',
                pathMatch: 'full',
            },
            {
                path: 'reste-a-faire',
                data: {
                    label: 'RESTE À FAIRE',
                    code: RESTE_A_FAIRE,
                    status: 'VOID',
                },
                component: ResteAFaireComponent,
            },
        ],
    },
    {
        path: 'conclusion',
        data: {
            label: 'Conclusion',
            icon: new Icon('clipboard-list', 'fa'),
            code: CONCLUSION,
            status: 'VOID',
        },
        children: [
            {
                path: '',
                redirectTo: 'controle-administratif',
                pathMatch: 'full',
            },
            {
                path: 'controle-administratif',
                data: {
                    label: 'Contrôle administratif',
                    status: 'VOID',
                },
                component: ControleAdministratifComponent,
            },
            {
                path: 'isolants',
                data: {
                    label: 'Isolants',
                    status: 'VOID',
                },
                component: AnoIsolantComponent,
            },
            {
                path: 'points-de-controle',
                data: {
                    label: 'Points de contrôle',
                    status: 'VOID',
                },
                component: ConcPointsDeControleComponent,
            },
        ],
    },
    {
        path: 'rapport',
        data: {
            label: 'Rapport',
            icon: new Icon('book'),
            code: RAPPORT,
            status: 'VOID',
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'gestion-plan',
                pathMatch: 'full',
            },
            {
                path: 'gestion-plan',
                data: {
                    label: 'Gestion plan',
                    code: GESTION_PLAN,
                    status: 'VOID',
                },
                canDeactivate: [QuitDiagnosticGuard],
            },
            {
                path: 'reportage-photo',
                data: {
                    label: 'Reportage photo',
                    code: REPORTAGE_PHOTO,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: ReportagePhotoListComponent,
            },
            {
                path: 'documents-rapport',
                data: {
                    label: 'Documents',
                    code: DOCUMENTS_RAPPORT,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: DocumentsRapportComponent,
            },
            {
                path: 'apercu-rapport',
                data: {
                    label: 'Aperçu rapport',
                    code: PREVI_RAPPORT,
                    actions: [
                        {
                            label: 'Valider',
                            icon: new Icon('signature-freehand', 'mdi'),
                            code: SAVE_RAPPORT,
                            textColor: '#FFFFFF',
                            backgroundColor: '#6DC911',
                        },
                    ],
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: RapportComponent,
            },
        ],
    },
];

const routes: Routes = [
    {
        path: '',
        redirectTo: 'config',
        pathMatch: 'full',
    },
    {
        path: 'config',
        component: DiagnosticConfigComponent,
        children: [
            {
                path: '',
                redirectTo: DEFAULT_PAGE,
                pathMatch: 'full',
            },
            ...ceeNavBarRoutes,
        ],
        resolve: { cee: InitConfigResolver },
    },
];

@NgModule({
    imports: [CeeModule, RouterModule.forChild(routes)],
})
export class CeeRoutingModule {}

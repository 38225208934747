"use strict";

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Abstract class for element types.
//***********************************************************************************
//***********************************************************************************

import {cn_element} from "./cn_element";
import {cn_configuration} from "./cn_configuration";

export class cn_element_type extends cn_element  {

	constructor() {
		super();
        /**
         * @type {string}
         */
		this.name;

		/**
		 * @type {string}
		 */
		this.class_name="";
	}

    //***********************************************************************************
    /**
     * Clone
     * @returns {cn_element_type}
     */
    clone() {
        return new cn_element_type();
    }

    //***********************************************************************************
    /**
     * returns displayable label (e.g. "mur générique 30cm")
     * @returns {string}
     */
    get_label() {
        return "";
    }

    //***********************************************************************************
    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
    get_description() {
        return [];
    }

    //***********************************************************************************
    /**
     * returns displayable label for type (e.g. "type de mur")
     * @returns {string}
     */
    get_generic_label() {
        return "";
    }

    //***********************************************************************************
    /**
     * draw svg icon
     * @param {number} width
     * @param {number} height
     * @returns {string}
     */
    draw_svg_icon(width, height) {
        return "";
    }

    //***********************************************************************************
    //**** Fill configuration. If input config is null, must return the configuration
    //***********************************************************************************
    fill_configuration(config = null) {
        return new cn_configuration();
    }

    //***********************************************************************************
    //**** Load configuration
    //***********************************************************************************
    load_configuration(configuration) {
        return false;
    }

    model_keys() {
        return [];
    }
}


"use strict";
import {cn_polygon_handler, cn_storey_element, cn_sub, cnx_compute_normal} from "..";
import {cn_marker} from "../model/cn_marker";
import {cn_roof} from "../model/cn_roof";
import {cn_svg_map} from "./cn_svg_map";
import {cn_edition_handler} from "./cn_edition_handler";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_edit_box} from "./cn_edit_box";
import {cn_pastille} from "./cn_pastille";
import {cn_view_overlay} from "./cn_view_overlay";
import {cn_color_input, cn_marker_input} from "./cn_inputs";

export class cn_marker_handler extends cn_edition_handler {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {Array<cn_marker>} markers
	 * @param {cn_svg_map | cn_view_overlay} map
	 * @param {boolean} creation
	 */
	constructor(markers, map, creation=false) {
		super(markers,map);

		this._map = map;
		this._scene = markers[0].storey.scene;
		this._transaction_manager = markers[0].storey.building.transaction_manager;
		this._roof = (this._scene.constructor == cn_roof);

		//*** marker
		this._marker = (markers.length==1)?markers[0]:null;
		this._markers = markers;

		//*** transaction names */
		this.transaction_name_shape_change = "Modification de la zone d'annotation";
		this.transaction_name_arrow_change = "Déplacement d'annotation";
		this.transaction_name_tail_change = "Déplacement de l'étiquette d'une annotation";
		this.transaction_name_label_change = "Modification du texte d'une annotation";
        this.transaction_color_change = "Modification de la couleur d'une annotation";
        this.transaction_shape_color_change = "Modification de la couleur de la zone d'annotation";
		this.transaction_name_content_change = "Modification du contenu d'une annotation";

		//** mouse flags */
		this._mouseover_box = false;
        this._tail_over = false;
        this._arrow_over = false;
        this._drag_index = 0;

		//*** handler for shape */
		this._handler = null;
		this._grabbed_handler = false;

		this._initialize_shape_edition();

		//*** Edit box for mass selection */
		const edit_box = new cn_edit_box(this,markers,creation);
		this._handlers.push(edit_box);

		const obj = this;

        // Color pastilles => disabled since included in cn_marker_input
        const enableColorPastilles = false;
        if (enableColorPastilles) {
            //*** shape Color pastille */
            this._shape_color_pastille = new cn_pastille([0,0],"");
            edit_box.add_pastille(this._shape_color_pastille);
            this._shape_color_pastille.svg_class = "pastille_background white";
            this._shape_color_pastille.title = "Couleur de la zone";
            this._shape_color_pastille.clicked = function() {
                var colors = obj._get_shape_colors();
                if (colors.length>0)
                {
                    const input = new cn_color_input("Couleur de la zone",colors[0]);
                    if (colors.length > 1) input.label = "Couleur de la zone (variable)";
                    input.callback = function() {
                        obj.set_marker_shape_color(input.color);
                        map.refresh();
                    }
                    map.call("color_input",input);
                }
            }

            //*** Application Color pastille */
            this._color_pastille = new cn_pastille([0,0],"");
            edit_box.add_pastille(this._color_pastille);
            this._color_pastille.svg_class = "pastille_background white";
            this._color_pastille.title = "Couleur d'application";
            this._color_pastille.clicked = function() {
                var colors = obj._get_colors();
                if (colors.length>0)
                {
                    const input = new cn_color_input("Couleur d'application",(colors[0]=="")?"#ffffff":colors[0]);
                    if (colors.length > 1) input.label = "Couleur d'application (variable)";
                    input.checkbox_label = "Aucune couleur";
                    input.checkbox_status = colors[0]=="";
                    input.callback = function() {
                        obj.set_marker_color((input.checkbox_status)?"":input.color);
                        map.refresh();
                    }
                    map.call("color_input",input);
                }
            }
        }

		edit_box.add_lock_pastille(this._transaction_manager);

		//*** Contents pastille */
		if (this._marker)
		{
			//*** We create the parameters pastille */
			const contents_pastille = new cn_pastille([0,0],"text.svg");
			edit_box.add_pastille(contents_pastille);
			contents_pastille.svg_class = "pastille_background white";
			contents_pastille.title="Contenu";

			//*** callback */
			contents_pastille.clicked = () => {
                map.call("marker_input", this.buildMarkerInput(this._marker));
			};
		}
	}

    _get_colors() {
		var colors = [];
		this._markers.forEach(m => {
			if (m.shape == null && m.element)
			{
				if (colors.indexOf(m.color) < 0) colors.push(m.color)
			}
		});
		return colors;
	}

	_get_shape_colors() {
		var colors = [];
		this._markers.forEach(m => {
			if (m.shape)
			{
				if (colors.indexOf(m.shape_color) < 0) colors.push(m.shape_color)
			}
		});
		return colors;
	}

	//***********************************************************************************
	//**** Draws
	//***********************************************************************************
	draw(camera) {
		var html = "";
		if (this._marker)
		{
			const is_3d = camera.is_3d();

			//*** We don't manipulate a 3D shape in 2D. */
			if(this._handler && !is_3d && this._marker.is_shape_3d())
			{
				this.remove_handler(this._handler);
				this._handler = null;
			}

			if (this._handler) this._handler.active = this._handler.visible = !this._marker.locked;

			var opacity = (this._marker.element || this._marker.shape)?0.7:0.3;
			var html = "";

			html +="<g opacity='" + opacity + "'>" + this._marker.draw(camera, ["selected"]) + "</g>";

			//*** Highlight reference element */
			if (!is_3d && this._scene.check_element(this._marker.element))
				html += this._marker.element.draw(camera, ["mouseover"]);

			//*** Draw marker */
			const extra = ['selected'];
			if (this._arrow_over) {
				extra.push('arrow_over')
			} else if (this._tail_over) {
				extra.push('tail_over');
			}
			if (this._mouseover_box) {
				extra.push('mouseover_box')
			} else {
				extra.push('active_box')
			}

			if (!this._marker.locked)
			{
				//*** Draw arraw move symbol around arrow */
				if (this._marker.config.head || this._marker.config.line)
					html += camera.draw_move_arrow_screen(this._marker.get_arrow_screen(camera), (this._arrow_over) ? "selected" : "");

				//*** Draw arraw move symbol around tail */
				if (this._marker.config.tail || this._marker.config.line)
					html += camera.draw_move_arrow_screen(this._marker.get_tail_screen(camera),(this._tail_over)?"selected":"");
			}

			let pic = !!this._marker["pictures"] && !!this._marker["pictures"].length;
			html += this._marker.draw(camera, extra, false, pic);
		}

        const size = camera.thumb_size;

		//*** Update shape color pastille */
        if (this._shape_color_pastille) {
            const shape_colors = this._get_shape_colors();
            if (shape_colors.length == 0)
                this._shape_color_pastille.visible = false;
            else {
                const margin = size/5;
                this._shape_color_pastille.label = `<rect fill="${shape_colors[0]}" stroke="black" stroke-width="1" x="${margin}" y="${margin}" width="${size-2*margin}" height="${size-2*margin}" />`;
                this._shape_color_pastille.incoherent = (shape_colors.length > 1);
            }
        }

		//*** Update color pastille */
        if (this._color_pastille) {
            const colors = this._get_colors();
            if (colors.length == 0)
                this._color_pastille.visible = false;
            else {
                this._color_pastille.label = `<path fill="${(colors[0]=="")?"white":colors[0]}" stroke="black" stroke-width="1" d="M ${0} ${size} L ${size} ${size} 0 0 Z" />`;
                const arrow = size/3;
                const line_width=size/15;
                const line_0 = size/2 + line_width;
                const line_1 = size/2 + size*0.4;
                const line_width_45 = line_width * Math.sqrt(2)/2;
                this._color_pastille.label += `<path fill="black" d="M ${size/2} ${size/2} L ${size/2+arrow} ${size/2} ${size/2} ${size/2-arrow} Z" />`;
                this._color_pastille.label += `<path fill="black" d="M ${line_0 + line_width_45} ${size - (line_0 - line_width_45)} L ${line_0 - line_width_45} ${size - (line_0 + line_width_45)} ${line_1 - line_width_45} ${size - (line_1 + line_width_45)} ${line_1 + line_width_45} ${size - (line_1 - line_width_45)}Z" />`;
                this._color_pastille.incoherent = (colors.length > 1);
            }
        }

		//*** Draw handler */
		html += super.draw(camera);

		return html;
	}

	//***********************************************************************************
	//**** clear move effects
	//***********************************************************************************
	clear_move() {
		this._mouseover_box = false;
        this._arrow_over = false;
        this._tail_over = false;
		super.clear_move();
	}

	//***********************************************************************************
	//**** Mouse callbacks
	//***********************************************************************************

	click(mouse_event) {
		if (this._focus_handler != this)
			return super.click(mouse_event);

		this._check_mouse_over_marker(mouse_event);
		if (this._mouseover_box) {
            this.call("marker_input", this.buildMarkerInput(this._marker));
			return true;
		}
		return this._tail_over || this._arrow_over;
	}

	/**
	 * Drop (or click) event
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	drop(mouse_event) {
		if (this._focus_handler != this)
			return super.drop(mouse_event);

		this._check_mouse_over_marker(mouse_event);
		return this._mouseover_box || this._tail_over || this._arrow_over;
	}

	/**
	 * Grab event
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	grab (mouse_event) {
		if (this._focus_handler != this)
			return super.grab(mouse_event);

        if (this._check_mouse_over_marker(mouse_event))
		{
			this._drag_index = (this._drag_index + 1) % 30;
			return true;
		}
		return false;
	}

	/**
	 * Passive move event
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	move (mouse_event) {

		this._focus_handler = this;
        if (this._check_mouse_over_marker(mouse_event))
			return true;

		//if (this._marker && this._marker.contains(mouse_event.mouse_world,mouse_event.camera.snap_world_distance))
		//	return true;

		this._focus_handler = null;

		if (super.move(mouse_event)) return true;
		return false;
	}

	/**
	 * Drag event
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
    drag(mouse_event, allow_relocate = true) {
		if (this._focus_handler != this)
			return super.drag(mouse_event);

		if (!this._marker) return false;

		if (this._arrow_over && allow_relocate) {
			if (this._marker.shape)
			{
				//*** in case of 3D, we move the mouse world point to the shape plane */
				if (mouse_event.camera.is_3d())
				{
					const shape_vertices = this._marker.get_shape_3d();
					if (shape_vertices.length < 3) return false;
					if (!mouse_event.move_to_plane(shape_vertices[0],cnx_compute_normal(shape_vertices)))
						return false;
				}
				this._transaction_manager.push_transaction(this.transaction_name_arrow_change, this._marker.ID + this._drag_index);
				this._transaction_manager.push_item_set(this._marker, ["element_position","position", "normal"]);
				this._marker.place_from_mouse(mouse_event);
				return true;
			}
			else
			{
				var check_marker = new cn_marker(this._marker.storey, this._marker.type)
				check_marker.place_from_mouse(mouse_event);
				if (check_marker.element) {
					this._transaction_manager.push_transaction(this.transaction_name_arrow_change, this._marker.ID + this._drag_index);
					this._transaction_manager.push_item_set(this._marker, ["element", "element_position", "element_side", "position", "normal"]);
					this._marker.place_from_mouse(mouse_event);

					//*** manage storey change, only in 3D */
					if (mouse_event.camera.is_3d() && mouse_event.impact.storey_element && mouse_event.impact.storey_element.storey != this._marker.storey)
					{
						if (this._marker.change_storey(mouse_event.impact.storey_element.storey))
							console.log("marker changed storey");
					}
					return true;
				}
			}
		} else if (this._tail_over) {
			this._transaction_manager.push_transaction(this.transaction_name_tail_change, this._marker.ID + this._drag_index);

			if (mouse_event.camera.is_3d())
			{
				this._transaction_manager.push_item_set(this._marker, ["tail_position_3d"]);
				this._marker.tail_position_3d = cn_sub(mouse_event.mouse_screen,this._marker.get_arrow_screen(mouse_event.camera));
			}
			else
			{
				this._transaction_manager.push_item_set(this._marker, ["tail_position"]);
				this._marker.set_tail_position(mouse_event.mouse_world);
			}
			return true;
		}
		return false;
	}

	/**
	 * Wheck what's under the mouse
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	_check_mouse_over_marker(mouse_event) {
		this.clear_move();

		if (this._marker == null) return false;

		if (mouse_event.camera.is_3d() && !this._marker.visibility_3d) return false;

		if (!this._marker.locked)
		{
			this._tail_over = mouse_event.camera.move_arrow_selected_screen(mouse_event.mouse_screen,this._marker.get_tail_screen(mouse_event.camera));
			if (this._tail_over) return true;

			this._arrow_over = mouse_event.camera.move_arrow_selected_screen(mouse_event.mouse_screen,this._marker.get_arrow_screen(mouse_event.camera));
			if (this._arrow_over) return true;
		}

        this._mouseover_box = this._marker.text_box.contains_point(mouse_event.mouse_screen);
		if (this._mouseover_box) return true;

		return false;
	}

	/**
	 *
	 * @param {string} new_text
	 * @returns {boolean}
	 */
	set_marker_text(new_text) {
		if (!this._marker) return false;

		this._transaction_manager.push_transaction(this.transaction_name_label_change,this._marker.ID);
		this._transaction_manager.push_item_set(this._marker,"label");
		this._marker.label = new_text;
		this._map.refresh();
		return true;
	}

    /**
	 *
	 * @param {string} new_color
	 * @param {boolean} build_transaction
	 * @returns {boolean}
	 */
	set_marker_color(new_color, build_transaction=true) {
		if (build_transaction) this._transaction_manager.push_transaction(this.transaction_color_change);
		this._markers.forEach(m => {
			this._transaction_manager.push_item_set(m,["color"]);
			m.color = new_color;
		});
		this._map.refresh();
		return true;
	}

    /**
	 *
	 * @param {string} new_color
	 * @param {boolean} build_transaction
	 * @returns {boolean}
	 */
	 set_marker_shape_color(new_color, build_transaction=true) {
		if (build_transaction) this._transaction_manager.push_transaction(this.transaction_shape_color_change);
		this._markers.forEach(m => {
			this._transaction_manager.push_item_set(m,["shape_color"]);
			m.shape_color = new_color;
		});

		this._map.refresh();
		return true;
	}

    /**
     *
     * @param {cn_marker | cn_marker_input} new_marker
	 * @param {boolean} build_transaction
     * @returns {boolean}
     */
    set_marker_content(new_marker, build_transaction=true) {
        if (!this._marker) return false;
        if (build_transaction) this._transaction_manager.push_transaction(this.transaction_name_content_change, this._marker.ID);
        this._transaction_manager.push_item_set(this._marker, ["label", "content", "pictures"]);
		if (typeof(new_marker.label) == "string")
        	this._marker.label = new_marker.label;
		if (typeof(new_marker.content) == "string")
        	this._marker.content = new_marker.content;
		if (typeof(new_marker.pictures) == "object")
        	this._marker.pictures = new_marker.pictures;
		this._map.refresh();
        return true;
    }

	_initialize_shape_edition() {
		this._handler = null;
		if (this._marker == null || this._marker.shape == null) return;

		var obj = this;
		var scene = obj._scene;
		var shape = obj._marker.shape;

		const shape_3d = obj._marker.is_shape_3d();
		this._handler = new cn_polygon_handler(this,obj._marker.get_shape_3d(),true);
		this._handlers.push(this._handler);
		this._handler.allow_3d_change = shape_3d;
		this._handler.creation_storey = this._marker.storey;
		this._handler.snap_elements = scene.spaces;
		this._handler.display_measures = false;
		if (shape_3d) this._handler.behind_storey_element = new cn_storey_element(this._marker,this._marker.storey);

		//*** Callback on change */
		this._handler.on("change",function(){
			obj._transaction_manager.push_transaction(obj.transaction_name_shape_change,shape.ID,function(){scene.update();scene.update_deep();});
			obj._transaction_manager.push_item_set(shape,"vertices");

			obj._marker.set_shape_3d(obj._handler.vertices,shape_3d);

			if (obj._marker.storey != obj._handler.creation_storey)
			{
				if (obj._marker.change_storey(obj._handler.creation_storey, obj._transaction_manager))
					console.log("storey change");
			}

			obj.call("shape_changed");
		});
	}

    /**
     * Builds cn_marker_input from a marker
     * @param {cn_marker} marker
	 * @param {boolean} build_transaction
     * @return {cn_marker_input}
     */
    buildMarkerInput(marker, build_transaction=true) {
        const isShape = marker.shape != null;
        const input = new cn_marker_input(marker.constructor, marker.label, marker.content);
        if (isShape) {
            input.color = marker.shape_color;
        } else {
            input.color = (marker.color === "") ? "#ffffff" : marker.color;
            input.color_checkbox_label = "Aucune couleur";
            input.color_checkbox_status = marker.color === "";
        }
        input.pictures = marker.pictures;
        input.label_required = !marker.type || marker.type === 'label';
        input.callback = () => {
            this.set_marker_content(input,build_transaction);
            if (isShape) {
                this.set_marker_shape_color(input.color,false);
            } else {
                this.set_marker_color((input.color_checkbox_status) ? "" : input.color,false);
            }
        }
        return input;
    }
}


"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Xml utilities
//***********************************************************************************
//***********************************************************************************

export class fh_xml
{
	//*********************************************************
	//*** Constructor
	//*********************************************************
	constructor(element_name, text_data="") {
		this._name=element_name;
		this._attributes = [];
		this._text = text_data;
		this._children = [];
	}

	//*********************************************************
	//*** Attribute setting
	//*********************************************************
	set_attribute(name,value)
	{
		this._attributes.push({'_name':name,'_value':value});
	}

	//*********************************************************
	//*** Append one child
	//*********************************************************
	append_child(v)
	{
		this._children.push(v);
	}

	//*********************************************************
	//*** Serialize
	//*********************************************************
	serialize(tab="") {
		var sz = tab + "<" + this._name;
		for (var i in this._attributes)
		{
			sz += ` ${this._attributes[i]._name}="${this._attributes[i]._value}"`;
		}
		sz += ">";
		sz += this._text;
		for (var i in this._children)
		{
			sz += "\n" + this._children[i].serialize(tab + "\t");
		}
		if (this._children.length > 0)
			sz += "\n" + tab;
		sz += "</" + this._name + ">";
		return sz;
	}

	//*********************************************************
	//*** Short circuit to add ID as attribute and name as child
	//*********************************************************
	add_name_and_id(obj)
	{
		if (typeof(obj.ID) != 'undefined')
			this.set_attribute("id", 'C'+ obj.ID);
		if (typeof(obj.name) != 'undefined')
			this.append_child(new fh_xml("Name",obj.name));
	}

}

import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { Intervention } from '../../../../../model/intervention.model';
import { Bien } from '../../../../../model/bien.model';
import { BackgroundMapApiService, BackgroundMapFileData } from '../../../../../services/background-map-api.service';
import { PerimetreInvestigation, PlanLocalisation } from 'src/app/model/perimetre-investigation.model';
import { Espace } from 'src/app/model/espace.model';

class PlanLocalisationModalData {
    constructor(
        public planLocalisation: Espace,
        public listePlanLocalisationExistant: Espace[],
        public listePerimetresInvestigation: PerimetreInvestigation[],
        public isCreation: boolean,
        public readonlyMode: boolean,
        public intervention: Intervention
    ) {}
}

@Component({
    selector: 'app-plan-localisation-modal',
    templateUrl: './plan-localisation-modal.component.html',
    styleUrls: ['./plan-localisation-modal.component.scss'],
})
export class PlanLocalisationModal extends BaseComponent implements OnInit {
    planLocalisation: Espace;
    listeBiens: Bien[] = [];
    listePlanLocalisationExistant: Espace[];
    listeBackgroundBien: BackgroundMapFileData[] = [];
    listePerimetresInvestigation: PerimetreInvestigation[] = [];
    isCreation: boolean;

    formPlan: FormGroup;
    readonlyMode = false;

    constructor(
        private readonly dialogRef: MatDialogRef<PlanLocalisationModal>,
        private readonly formBuilder: FormBuilder,
        private readonly backgroundMapApiService: BackgroundMapApiService,
        private readonly notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: PlanLocalisationModalData
    ) {
        super();
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth1000-dialog');
        this.prepareComponent();
        this.preparePlanForm();

        if (!this.isCreation && this.planLocalisation) {
            this.populatePlanForm();
        }
        this.genererNomPlan();
    }

    /**
     * Initialisation des différentes variables du composant en fonction des datas passés en paramètre de la modale
     * @private
     */
    private prepareComponent() {
        // selection du plan de localisation
        this.planLocalisation = this.data.planLocalisation;

        // récupération de la liste des biens
        this.listeBiens = this.data.intervention.relationInterventionBiens.map((relationBien) => relationBien.bien);

        this.listePlanLocalisationExistant = this.data.listePlanLocalisationExistant;
        this.listePerimetresInvestigation = this.data.listePerimetresInvestigation;
        this.isCreation = this.data.isCreation;

        this.readonlyMode = this.data.readonlyMode;
    }

    /**
     * Génère le nom du plan en fonction du bien, niveau et périmètre
     * Bien XXX Niveau XXX Périmètre XXX
     */
    genererNomPlan() {
        let nom = [];

        if (this.formPlan.get('bien').value) {
            nom.push(`Bien ${this.formPlan.get('bien').value.nom} `);
        }

        if (this.formPlan.get('niveau').value) {
            nom.push(`Niveau ${this.formPlan.get('niveau').value.index}`);
        }

        if (this.formPlan.get('perimetre').value) {
            nom.push(`Périmètre ${this.formPlan.get('perimetre').value.nom}`);
        }
        console.log(nom);
        this.formPlan.get('nomPlan').setValue(nom.join(' - '));
    }

    /**
     * Peuplement de la liste des backgrounds et selection du background
     */
    prepareBackgroundList(selectedBien: Bien) {
        this.listeBackgroundBien = [];
        const selectedRelationInterventionBien = this.data.intervention.relationInterventionBiens.find(
            (relationBien) => relationBien.bien.id === selectedBien.id
        );
        selectedBien.backgroundMaps.forEach((background) => {
            this.backgroundMapApiService
                .downloadBackgroundImage(
                    this.data.intervention.id,
                    selectedRelationInterventionBien.id,
                    background.fileId
                )
                .subscribe((backgroundFile) => {
                    if (backgroundFile) {
                        if (backgroundFile.fileId === this.planLocalisation.backgroundFileId) {
                            this.formPlan.get('background').setValue(backgroundFile);
                        }
                        this.listeBackgroundBien.push(backgroundFile);
                    }
                });
        });
    }

    /**
     * Crée la base du formulaire pour la creation/edition d'un plan
     */
    preparePlanForm() {
        this.formPlan = this.formBuilder.group({
            nomPlan: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (
                            control.value &&
                            ValidatorUtils.checkAlreadyExist(
                                this.formPlan.controls.nomPlan.value,
                                this.isCreation,
                                this.planLocalisation.nom,
                                this.listePlanLocalisationExistant.map((plan) => plan.nom)
                            )
                        ) {
                            return { erreurNomPlanExistant: true };
                        }
                        return null;
                    },
                ],
            ],
            perimetre: ['', [Validators.required]],
            bien: ['', [Validators.required]],
            niveau: [''],
            background: ['', []],
            typePlan: [''],
        });

        this.formPlan.get('perimetre').valueChanges.subscribe(() => {
            this.genererNomPlan();
        });

        this.formPlan.get('bien').valueChanges.subscribe(() => {
            this.genererNomPlan();
        });

        this.formPlan.get('niveau').valueChanges.subscribe(() => {
            this.genererNomPlan();
        });

        if (this.listePerimetresInvestigation.length == 1) {
            this.formPlan.get('perimetre').setValue(this.listePerimetresInvestigation[0]);
        }
    }

    /**
     * Initialise les données du formulaire pour l'edition d'un plan
     */
    populatePlanForm() {
        // selection de la relation intervention bien
        const selectedRelationInterventionBien = this.data.intervention.relationInterventionBiens.find(
            (relationBien) => relationBien.bien.id === this.planLocalisation.idBien
        );
        let selectedBien = null;
        let selectedNiveau = null;
        let selectedPerimetre = null;

        if (selectedRelationInterventionBien) {
            // selection du bien
            selectedBien = selectedRelationInterventionBien.bien;

            // selection du niveau
            selectedNiveau = selectedBien.description.find(
                (niveau) => niveau.index === this.planLocalisation.indexNiveau
            );

            // Selection du périmètre
            selectedPerimetre = this.listePerimetresInvestigation.find(
                (p) => p.id == this.planLocalisation.idPerimetre
            );

            this.prepareBackgroundList(selectedBien);
        } else {
            // Console à garder pour retrouver plus facilement le message d'erreur qui apparait plusieurs fois dans l'appli
            this.notificationService.warn(`Les informations du bien ont changé, des erreurs pourraient survenir.`);
        }

        this.formPlan.patchValue({
            nomPlan: this.planLocalisation.nom,
            perimetre: selectedPerimetre,
            bien: selectedBien,
            niveau: selectedNiveau,
            background: null,
        });
    }

    /**
     * Compléter l'entité avant de l'envoyer au back
     */
    prepareDataToSave() {
        this.planLocalisation.nom = this.formPlan.controls.nomPlan.value;
        const selectedBien = this.formPlan.controls['bien'].value;
        this.planLocalisation.idBien = selectedBien.id;
        this.planLocalisation.idPerimetre = this.formPlan.get('perimetre').value.id;
        this.planLocalisation.indexNiveau = this.formPlan.controls['niveau'].value?.index;
        this.planLocalisation.backgroundFileId = this.formPlan.controls['background'].value
            ? this.formPlan.controls['background'].value.fileId
            : null;
    }

    /**
     * Evenement click sur le bouton valider
     */
    onClickValider() {
        if (this.formPlan.valid) {
            this.prepareDataToSave();
            this.dialogRef.close({ plan: this.planLocalisation });
        }
    }

    /**
     * Evenement click sur le bouton Annuler ou la fermeture de la modale
     */
    onClickCancel() {
        this.dialogRef.close();
    }

    onChangeBien(bien: Bien) {
        this.prepareBackgroundList(bien);
    }

    onToggleBackground(background: BackgroundMapFileData) {
        const selectedBackgroundBien = this.formPlan.controls['background'].value;
        if (selectedBackgroundBien && selectedBackgroundBien.fileId === background.fileId) {
            this.formPlan.controls['background'].setValue(null);
        } else {
            this.formPlan.controls['background'].setValue(background);
        }
    }
}

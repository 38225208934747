"use strict";
import { cn_clone, cn_contour, cn_dist, cn_dot, cn_facade_lineics, cn_facing, cn_mouse_event, cn_pastille_facing, cn_slab, cn_space, cn_vertex, cn_wall, cn_zpso, cnx_add, cnx_dot, cnx_mul, cnx_normalize, cnx_sub } from '..';
import { cn_facing_trimming, CN_FACING_TRIMMING_PLACEMENT_CEILING, CN_FACING_TRIMMING_PLACEMENT_WALL } from '../model/cn_facing_trimming';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_facing  : Facing Manager
//***********************************************************************************
//***********************************************************************************

import { cn_camera } from "./cn_camera";
import { cn_svg_map } from "./cn_svg_map";
import { cn_svg_tool_creation } from "./cn_svg_tool_creation";
import { cn_edition_handler } from "./cn_edition_handler";
import { cn_element } from "../model/cn_element";
import { cn_facing_trimming_handler } from './cn_facing_trimming_handler';

const CN_TOPO_VERTEX = 0;

class cn_topo_handle {
    constructor() {
        this.indices = [];
        this.position = [0,0];
        this.screen_position = [0,0];
    }

    draw(camera, extra="") {
        if (this.screen_position.length == 0) return "";
        const r = 10;
        const x = this.screen_position[0];
        const y = this.screen_position[1];
        if (this.indices.length == 1)
            return `<circle class="topography_vertex ${extra}" cx="${x}" cy="${y}" r="${r}" />`; 
        return `<path class="topography_vertex ${extra}" d="M ${x-r} ${y} L ${x} ${y+r*2} ${x+r} ${y} ${x} ${y-r*2} Z"  />`;
    }
}

/**
 */
export class cn_svg_tool_topography extends cn_svg_tool_creation {
	//***********************************************************************************
	/**
	 * Constructor
	 */
	constructor() {
		super(null);
        this.mouseover_vertex = -1;
        this.mouseover_face = -1;
        this._selection = [];
        this._selected_vertices = [];
        this._topography = null;
        this._sampling = 8;
        this._handles = [];
        this._dragging = 0;
	}

	//***********************************************************************************
	//*** settings
	//***********************************************************************************

    /**
     * Returns the list of possible precision ranges
     * @returns {Array<number>}
     */
    get_range_list() {
        const range_list = [1];
        for (var r=2;;r*=2)
        {
            if ((this._topography.size[0]-1)%r) break;
            if ((this._topography.size[1]-1)%r) break;
            range_list.push(r);
        }
        return range_list;
    }
    
    get_range() {
        return this._sampling;
    }

    set_range(v) {
        this.mouseover_vertex = -1;
        this.mouseover_face = -1;
        this._selection = [];
        this._selected_vertices = [];
        this._sampling = v;
        this.call("selection_height_change");
    }

    get_altitude_range() {
        var altitude_range = [];
        if (this._selected_vertices.length == 0) return altitude_range;
        this._selected_vertices.forEach(v => {
            const i = (v%this._topography.size[0]);
            const j = Math.floor(v/this._topography.size[0]);
            const z = this._topography.get_height(i,j);
            if (altitude_range.length == 0)
                altitude_range.push(z);
            else if (altitude_range.length == 1 && altitude_range[0] != z)
            {
                if (z < altitude_range[0])
                    altitude_range = [z,altitude_range[0]];
                else
                    altitude_range.push(z);
            }
            else if (altitude_range.length == 2)
            {
                if (z < altitude_range[0]) altitude_range[0] = z;
                if (z > altitude_range[1]) altitude_range[1] = z;
            }
        });
        return altitude_range;
    }

    set_selection_height(height) {
        if (this._selection.length == 0) return;

        this.push_transaction("Applatissement de la topographie");
        this.push_item_set(this._topography,"heights");
		var point_list = [];
		this._selection.forEach(elt => {
			if (elt.indices.length == 1)
			{
				this._topography.set_height_smart(elt.position[0], elt.position[1],height,this._sampling);
			}	
			else 
			{
                const i0 = (elt.indices[0]%this._topography.size[0]);
                const j0 = Math.floor(elt.indices[0]/this._topography.size[0]);
				for (var j=0;j<=this._sampling;j++)
				{
					for (var i=0;i<=this._sampling;i++)
					{
						point_list.push([i0-i,j0-j]);
					}
				}
			}
		});

		if (point_list.length > 0)
			this._topography.flatten(point_list,height,this._sampling);
		
        this._view_overlay.refresh_3d();
        this._view_overlay.refresh();
        this.call("selection_height_change");
    }

    /**
     * Reset all topography
     */
    reset_topography() {
        this.push_transaction("Applatissement de la topographie");
        this.push_item_set(this._topography,"heights");
        this._topography.reset_heights();
        this._view_overlay.refresh_3d();
        this._view_overlay.refresh();
    }

	//***********************************************************************************
	/**
	 * Open tool
	 */
	open_tool() {
		super.open_tool();
        if (this._view_overlay)
            this._topography = this._view_overlay._building.topography;
        this.mouseover_vertex = -1;
        this.mouseover_face = -1;
        this._selection = [];
	}

    //***********************************************************************************
	/**
	 * Close tool
	 */
    close_tool() {
        super.close_tool();
    }

	// @ts-ignore
	draw(camera) {
		let html = "";
        if (!this._topography) return html;

        const sizex = this._topography.size[0];
        const sizey = this._topography.size[1];
        const z0 = this._topography.z;

        this.samples_x = 1 + Math.floor((sizex-1)/this._sampling);
        this.samples_y = 1 + Math.floor((sizey-1)/this._sampling);
        const sz = this.samples_x * this.samples_y + (this.samples_x-1) * (this.samples_y-1);
        if (this._handles.length != sz)
        {
            this._handles = new Array(sz);
            for (var k=0;k<sz;k++) this._handles[k] = new cn_topo_handle();
        }
        
        //*** draw y lines */
        var nh=0;
        for (var j=0;j<sizey;j++)
        {
            const y = this._topography.origin[1] + j;

            const sample_line = ((j%this._sampling)==0);

            //*** draw line */
            html += `<path class="${(sample_line)?"topography_line_main":"topography_line_secondary"}" d="M `;
            
            var nb = 0;
            for (var i=0;i<sizex;i++)
            {
                const pt = [this._topography.origin[0] + i,y,z0+this._topography.heights[i+j*sizex]];
                const v = camera.world_to_screen(pt);
                if (v.length)
                {
                    if (nb==1)
                        html += "L ";
                    html += `${v[0]} ${v[1]} `;
                    nb++;
                }
                if (sample_line && ((i%this._sampling)==0))
                {
                    this._handles[nh].position = [i,j];
                    this._handles[nh].indices = [i+j*sizex];
                    this._handles[nh].screen_position = v;
                    nh++;
                    if (i>0 && j>0)
                    {
                        this._handles[nh].position = [i-this._sampling*0.5,j-this._sampling*0.5];
                        this._handles[nh].indices = [i+j*sizex,(i-this._sampling)+j*sizex,i+(j-this._sampling)*sizex,(i-this._sampling)+(j-this._sampling)*sizex];
                        this._handles[nh].screen_position = camera.world_to_screen(this._topography.get_point(i-this._sampling*0.5,j-this._sampling*0.5));
                        nh++;
                    }
                }
            }
            html += `" />`;
        }
        
        //*** Draw x lines */
        for (var i=0;i<sizex;i++)
        {
            const x = this._topography.origin[0] + i;
            const sample_line = ((i%this._sampling)==0);

            html += `<path class="${(sample_line)?"topography_line_main":"topography_line_secondary"}" d="M `;
            
            var nb = 0;
            for (var j=0;j<sizey;j++)
            {
                const pt = [x,this._topography.origin[1] + j,z0+this._topography.heights[i+j*sizex]];
                const v = camera.world_to_screen(pt);
                if (v.length)
                {
                    if (nb==1)
                        html += "L ";
                    html += `${v[0]} ${v[1]} `;
                    nb++;
                }
            }
            html += `" />`;
        }

        this._handles.forEach(h => html += h.draw(camera));
        
        if (this.mouseover_element)
        {
            html += this.mouseover_element.draw(camera,"mouseover");
        }

        this._selection.forEach(h => {
            html += h.draw(camera,"selected");
        });

		return html;
	}

    //***********************************************************************************
    /**
     * click
     * @returns {boolean}
     */
    click(ev) {
        const element = this._find_mouse_handle(ev);
        this._select_handle(element, ev.ctrlKey);
        return this._selection.length>0;
    }
    /**
	 * move
     *
     * @param {object} ev
	 * @returns {boolean}
	 */
    move(ev) {
        this.mouseover_element = this._find_mouse_handle(ev);
        return (this.mouseover_element != null)
    }

    /**
     * grab
     *
     * @param {object} ev
     * @returns {boolean}
     */
    grab(ev) {
        const element = this._find_mouse_handle(ev);
        if (!element) return false;
        
        this._grab_mouse = ev.mouse_screen;
        this._dragging = 1;
        this._grabbed_element = element;
        this._grab_ctrl = ev.ctrlKey;
        this._grab_direction = [ev.ray.direction[0],ev.ray.direction[1],0];
        cnx_normalize(this._grab_direction);
        this._grab_position = this._topography.get_point(element.position[0],element.position[1]);
        return true;
    }

    drag(ev) {
		if (this._dragging == 0) return false;
        
		if (this._dragging == 1)
        {
            this.push_transaction("Modification de la topographie");
            this.push_item_set(this._topography,"heights");
			this._dragging = 2;
			this._select_handle(this._grabbed_element, this._grab_ctrl);
        }
         
		const dotprod = cnx_dot(ev.ray.direction,this._grab_direction);
		if (Math.abs(dotprod) < 0.01) return false;
		const lambda = cnx_dot(this._grab_direction,cnx_sub(this._grab_position,ev.ray.origin)) / dotprod;
		const impact = cnx_add(ev.ray.origin,cnx_mul(ev.ray.direction,lambda));

		this._selected_vertices.forEach(v => {
            const i = (v%this._topography.size[0]);
            const j = Math.floor(v/this._topography.size[0]);
			this._topography.set_height_smart(i,j,this._topography.get_height(i,j)+impact[2]-this._grab_position[2], this._sampling);
		});
		this._grab_position = impact;
        this.call("selection_height_change");
        return true;
    }

    drop(ev) {
		if (this._dragging == 0) return false;
		if (this._dragging == 2) 
		{
			this._dragging = 0;
            this._view_overlay.refresh_3d();
			return true;
		}
		this._dragging = 0;
        return true;
    }

	//***********************************************************************************
	//**** internal methods
	//***********************************************************************************
    _find_mouse_handle(ev) {
        
        const handle = this._handles.find(h => (h.screen_position.length && cn_dist(h.screen_position,ev.mouse_screen) < 20));
        if (handle) return handle;
        return null;
    }

    /**
     * Internal : update selection
     * @param {cn_topo_handle} handle 
     * @param {boolean} ctrl_key 
     */
    _select_handle(handle, ctrl_key) {
		if (ctrl_key)
		{
			if(handle && this._selection.indexOf(handle)<0)
			{
                this._selection.push(handle);
                handle.indices.forEach(i => {
                    if (this._selected_vertices.indexOf(i)<0) this._selected_vertices.push(i);
                });
			}
		}
		else if (handle)
		{
            this._selection = [handle];
			this._selected_vertices = handle.indices.concat([]);
		}
        else
		{
            this._selection = [];
			this._selected_vertices = [];
		}

		this.call("selection_height_change");
    }
}


"use strict";
//***********************************************************************************
//***********************************************************************************
//**** A SVG overlay over 3D view
//***********************************************************************************
//***********************************************************************************

import { fh_add, fh_dot, fh_view } from "@acenv/fh-3d-viewer";
import { cn_building } from "../model/cn_building";
import { cn_element } from "../model/cn_element";
import { cn_marker } from "../model/cn_marker";
import { cn_storey } from "../model/cn_storey";
import { cn_clone, cn_dist } from "../utils/cn_utilities";
import { cn_event_manager } from "./cn_event_manager";
import { cn_mouse_event } from "./cn_mouse_event";
import { cn_overlay_camera } from "./cn_overlay_camera";
import { cn_view_base } from "./cn_view_base";

import { cn_3d_building } from "../model/cn_3d_building";
import { cn_facing_trimming } from "../model/cn_facing_trimming";
import { cn_object_instance } from "../model/cn_object_instance";
import { cn_storey_element } from "../model/cn_storey_element";
import { SEVERITY_QUESTION_WARNING, cn_question_input } from "./cn_inputs";
import { cn_svg_tool } from "./cn_svg_tool";
import { cn_svg_tool_creation } from "./cn_svg_tool_creation";

/**
 * @class cn_view_overlay - A SVG overlay over 3D view
 */
export class cn_view_overlay extends cn_event_manager {

	//***********************************************************************************
	//**** Constructor
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {HTMLElement} div  : html div dom element
	 * @param {cn_view_base} cnview  : parent
	 */
	constructor(div, cnview) {
		super(cnview);
		this._cnview = cnview;

		this._svg_id = div.id + "_svg";
		div.innerHTML += `<svg id="${this._svg_id}" style="position: absolute; top:0; left: 0; width: 100%; height:100%; pointer-events: none; z-index: 1;"></svg><svg id="${div.id}_action" style="position: absolute; top:0; left: 0; width: 100%; height:100%; z-index: 1"></svg>`;
		this._svg = null;

		this._scene = null;
		this._renderer = null;
		this._camera = null;

		this._edition_mode = true;

		var obj = this;

		this._mouseover_element = null;
		this._selection = [];

		this._svg_event = new cn_mouse_event();

		this._mouse_down = false;
		this._mouse_dragging=-1;

		const main_event_container = document.getElementById(div.id + '_action');
		main_event_container.addEventListener("mousemove",function(event){obj.mouse_move(event)},false);
		main_event_container.addEventListener("mousedown",function(event){obj.mouse_down(event)},false);
		main_event_container.addEventListener("mouseup",function(event){obj.mouse_up(event)},false);
		main_event_container.addEventListener("mouseleave",function(event){obj.mouse_leave(event)},false);
		main_event_container.addEventListener("touchstart",function(event){obj.touch_start(event)},true);
		main_event_container.addEventListener("touchend",function(event){obj.touch_end(event)},true);
		main_event_container.addEventListener("touchmove",function(event){obj.touch_move(event)},true);

		this._tools = [];
		this._current_tool = null;

		this._dragging_tool = null;

		this._highlighted_marker_element = null;

		this._building = null;
		this._show_markers = true;

		this.visible = true;

		this.on("delete_elements",function(elements) {
			obj.ask_delete_elements(elements);
			return true;
		})

		this._unselectable_elements = [];
	}

	/**
	 * Sets the current scene
	 * @param {cn_building} building
	 */
	set_building(building) {
		this._building = building;
		this._svg_event.building = building;
		this.refresh_rendering();
		this._tools.forEach(tool => tool._building = building);
		this._current_tool = null;
		this._selection = [];
	}

	/**
	 * Sets the current renderer
	 * @param {fh_view} renderer
	 */
	 set_renderer(renderer) {
		this._renderer = renderer;
		this._camera = this._svg_event.camera = new cn_overlay_camera(this._renderer,this._cnview._3d_building);
		this.refresh_rendering();
	}

	/**
	 * Returns the 3D building
	 * @returns {cn_3d_building}
	 */

    get_3d_building() {
		return this._cnview._3d_building;
	}

	/**
	 * Sets edition mode, true or false
	 * @param {boolean} v
	 * @returns
	 */
	set_edition_mode(v) {
		if (this._edition_mode == v) return;
		this._selection = [];
		this._edition_mode = v;
	}

	//***********************************************************************************
	//**** selection
	//***********************************************************************************
	/**
	 * Returns the list of selected items
	 * @returns {{element:cn_element,storey:cn_storey}[]}
	 */
	get_selection() {
		return this._selection;
	}

	/**
	 * Add an element to the selection
	 * @param {cn_storey_element} storey_element
	 * @param {boolean} clear_selection
	 */
	select_element(storey_element = null, clear_selection = true) {
		if (clear_selection)
			this._selection = [];
		else if (this._selection.some(elt => {elt.element.ID == storey_element.element.ID && elt.storey.ID == storey_element.storey.ID}))
			return;

		if (storey_element)
		{
			this._selection.push(storey_element);
		}

		if (this._current_tool && !(this._current_tool instanceof cn_svg_tool_creation))
			this.set_current_tool(null);
		for (var i in this._tools)
		{
			if (this._tools[i].on_selection_change())
			{
				this.set_current_tool(this._tools[i]);
				this.call("tool_change",this._current_tool);
				break;
			}
		}
	}

	/**
	 * Sets mousover
	 * @param {cn_storey_element} mouseover
	 */
	set_mouseover(mouseover) {

		//*** End if no change */
		if (this._mouseover_element == mouseover)
			return;
		if (this._mouseover_element && mouseover && this._mouseover_element.storey == mouseover.storey && this._mouseover_element.element == mouseover.element)
			return;

		this.highlight_marker_element(null);

		this._mouseover_element = mouseover;

		if (mouseover)
		{
			if (mouseover.element instanceof cn_marker)
				this.highlight_marker_element(mouseover.element);
		}
		if(this._renderer)
			this._renderer.refresh_rendering();
	}

	/**
	 * Adds a tool
	 * @param {cn_svg_tool} tool
	 */
	add_tool(tool) {
		this._tools.push(tool);
		tool._building = this._building;
		tool._view_overlay = this;
		tool._parent = this;
	}

	/**
	 * Sets the current tool
	 * @param {cn_svg_tool} tool
	 * @returns
	 */
	set_current_tool(tool) {
		if (tool == this._current_tool) return;
		if (this._current_tool)
		{
			this._current_tool.close_tool();
		}
		this._current_tool = tool;
		if (this._current_tool)
		{
			this._current_tool.open_tool();
		}
	}

	/**
	 * Returnes the current tool
	 * @returns {cn_svg_tool}
	 */
	get_current_tool() {
		return this._current_tool;
	}

	//***********************************************************************************
	//**** Event management
	//***********************************************************************************

	/**
	 * Mouse move event
	 * @param {any} ev
	 */
	mouse_move(ev) {
		if (ev.buttons != 0 && ev.buttons != 1) return;
		if (this._mouse_move_cb(ev))
		{
			ev.preventDefault();
			ev.stopPropagation();
		}
	}

	_mouse_move_cb(ev) {
		var prevent_default = false;
		this._cnview.call("get_focus");
		if (!this.visible) return prevent_default;
		this._update_event(ev);

		//*** Manage drag */
		if (this._mouse_down)
		{
			//*** not concerned by dragging */
			if (this._mouse_dragging < 0) return prevent_default;

			if (this._mouse_dragging == 0)
			{
				if (cn_dist(this._svg_event.mouse_screen,this._svg_event.mouse_down) < 20) return prevent_default;
				this._mouse_dragging = 1;
			}

			//*** manage dragging */
			if (this._dragging_tool && this._dragging_tool.drag(this._svg_event))
			{
				prevent_default = true;
				this.refresh_rendering();
				console.log("dragging");
			}
			return prevent_default;
		}

		//*** Manage passive move */
		if (this._svg_event.shiftKey) return prevent_default;

		var new_mouseover = null;

		//*** Maybe tool uses passive move ? */
		if (this._current_tool && this._current_tool.move(this._svg_event))
		{
			prevent_default = true;
			this.set_mouseover(new_mouseover);
			this.refresh_rendering();
			return prevent_default;
		}

		//*** Maybe mouse over an element ? */
		if (this._tools.length && this._edition_mode)
		{
			new_mouseover = this.find_mouse_element(this._svg_event);
			if (new_mouseover && !this._tools.some(t => t.can_edit(new_mouseover)))
				new_mouseover = null;
			if (new_mouseover)
			{
				prevent_default = true;
			}
		}
		else
			new_mouseover = null;

		this.set_mouseover(new_mouseover);

		this.refresh_rendering();
		return prevent_default;
	}

	/**
	 * Mouse move event
	 * @param {any} ev
	 */
	mouse_down(ev) {
		if (ev.which != 1) return;
		if (this._mouse_down_cb(ev))
		{
			ev.preventDefault();
			ev.stopPropagation();
		}
	}

	_mouse_down_cb(ev) {
		var prevent_default = false;
		this._cnview.call("get_focus");
		if (!this.visible) return prevent_default;
		this._update_event(ev);
		if (this._svg_event.shiftKey) return prevent_default;
		this._mouse_down = true;
		this._svg_event.mouse_down = cn_clone(this._svg_event.mouse_screen);

		//*** Maybe tool uses event ? */
		if (this._current_tool && this._current_tool.grab(this._svg_event))
		{
			prevent_default = true;
			this._dragging_tool = this._current_tool;
			this._mouse_dragging = 0;
		}
		else
		{
			this._dragging_tool = null;
			this._mouse_dragging = 0;
		}

		this.refresh_rendering();
		return prevent_default;
	}

	/**
	 * Mouse up event
	 * @param {any} ev
	 */
	mouse_up(ev) {
		if (ev.which != 1) return;

		this._mouse_up_cb(ev);
	}

	_mouse_up_cb(ev) {
		if (!this.visible) return;
		this._update_event(ev);

		if (this._svg_event.shiftKey) return;

		if (this._mouse_dragging == 0)
		{
			if (this._current_tool== null || !this._current_tool.click(this._svg_event))
				this.select_element(this._mouseover_element);
		}
		else if (this._dragging_tool)
			this._dragging_tool.drop(this._svg_event);

		this._mouse_down = false;
		this._dragging_tool = null;
		this._mouse_dragging = -1;
		this.refresh_rendering();
	}

	/**
	 * Mouse leave event
	 * @param {any} ev
	 */
	 mouse_leave(ev) {
		if (!this.visible) return;
		this._mouse_down = false;
		this._dragging_tool = null;
		this._mouse_dragging = -1;
		this.refresh_rendering();
	 }

	touch_start(ev) {
		this._use_touch_move = false;
		ev.preventDefault();
		if (ev.targetTouches.length != 1) return;
		this._mouse_down = false;
		this._mouse_move_cb(ev);
		this._use_touch_move = this._mouse_down_cb(ev);
		if (this._use_touch_move) ev.stopPropagation();
	}

	touch_end(ev) {
		ev.preventDefault();
		if (!this._use_touch_move) return;
		if (ev.touches.length != 0) return;
		this._mouse_up_cb(ev);
		ev.stopPropagation();
	}

	touch_move(ev) {
		ev.preventDefault();
		if (!this._use_touch_move) return;
		if (ev.touches.length != 1) return;
		this._mouse_move_cb(ev);
		ev.stopPropagation();
	}

	_update_event(ev) {
		var updated = false;
		if (ev.touches)
		{
			if (ev.touches.length)
			{
				this._svg_event.mouse_screen[0] = ev.touches[0].clientX;
				this._svg_event.mouse_screen[1] = ev.touches[0].clientY;
				updated = true;
			}
		}
		else if (typeof(ev.clientX) != 'undefined')
		{
			this._svg_event.mouse_screen[0] = ev.clientX;
			this._svg_event.mouse_screen[1] = ev.clientY;
			updated = true;
		}

		if (this._svg && updated)
		{
			var svg_rect = this._svg.getBoundingClientRect();
			this._svg_event.mouse_screen[0] -= svg_rect.left;
			this._svg_event.mouse_screen[1] -= svg_rect.top;
			this._camera.set_size(svg_rect.width,svg_rect.height);
		}

		this._svg_event.camera = this._camera;
		this._svg_event.camera._workplane = null;
		this._svg_event.mouse_world = [0,0,0];
		this._svg_event.impact = this._cnview.get_screen_element(this._svg_event.mouse_screen);
		this._svg_event.impacts = this._cnview.get_screen_elements(this._svg_event.mouse_screen);
		this._svg_event.ray = this._cnview.get_screen_ray(this._svg_event.mouse_screen);
		if (this._svg_event.impact)
			this._svg_event.mouse_world = this._svg_event.impact.position;
		else
			this._svg_event.mouse_world = fh_add(this._svg_event.ray.origin,this._svg_event.ray.direction);

		//*** Impact behind curretn selection */
		this._svg_event.impact_behind = this._svg_event.impacts.find(impact => !this._selection.some(se => se.equals(impact.storey_element)));

		if (ev.touches && ev.touches.length > 0)
			this._svg_event.buttons = 1;
		if (typeof(ev.buttons) != 'undefined')
			this._svg_event.buttons = ev.buttons;

		this._svg_event.ctrlKey = false;
		if (typeof(ev.ctrlKey) != 'undefined')
			this._svg_event.ctrlKey = ev.ctrlKey;

		this._svg_event.shiftKey = false;
		if (typeof(ev.shiftKey) != 'undefined')
			this._svg_event.shiftKey = ev.shiftKey;

		if (typeof(ev.deltaY) != 'undefined')
			this._svg_event.wheel_up = (ev.deltaY > 0.2);

		this._svg_event.key = 0;
		if (typeof(ev.key) != 'undefined')
			this._svg_event.key = ev.key;
	}

	//***********************************************************************************
	//**** Rendering
	//***********************************************************************************
	/**
	 * rendering
	 */
	refresh_rendering() {
		this._svg = document.getElementById(this._svg_id);
        if (!this._svg) return;
		if (this._building == null || this._renderer == null) {
			this._svg.innerHTML = "<rect x='100' y='100' width='100' height='100' fill='red' />";
			return;
		}

		var html = "";

		if (this.visible)
		{
			//*** Special case for markers */
			this._building.storeys.forEach(storey => {
				storey.markers.forEach(marker => {

					if (marker.shape)
					{
						marker.visibility_3d = true;
						var classes = [];
						if (this._mouseover_element && marker == this._mouseover_element.element) classes.push("mouseover");
						html += marker.draw(this._camera,classes);
						marker.visibility_3d = true;
					}
					else
					{
						marker.visibility_3d = false;
						const screen = marker.get_arrow_screen(this._camera);
						if (screen)
						{
							const impact = this._cnview.get_screen_element(screen);
							if (impact && impact.storey_element && impact.storey_element.storey == storey && marker.element && impact.storey_element.element.ID == marker.element.ID && fh_dot(marker.get_3d_normal(),impact.normal) > 0)
							{
								var classes = [];
								if (this._mouseover_element && marker == this._mouseover_element.element) classes.push("mouseover");
								html += marker.draw(this._camera,classes);
								marker.visibility_3d = true;
							}
						}
					}
				});
			});

			//*** Draw mouseover */
			if (this._mouseover_element && !(this._mouseover_element.element instanceof cn_marker ))
			{
				this._camera.storey = this._mouseover_element.storey;
				html += this._mouseover_element.element.draw(this._camera,["mouseover"]);
			}

			//*** Draw selection */
			this._selection.forEach(sel => {
				if (!(sel.element instanceof cn_marker))
				{
					this._camera.storey = sel.storey;
					html += sel.element.draw(this._camera,["selected"]);
				}
			});

			//*** Draw tool */
			if (this._current_tool) html += this._current_tool.draw(this._camera);
		}

		this._svg.innerHTML = html;
	}

	refresh() {
		this.refresh_rendering();
	}

	refresh_3d() {
		if (this.get_3d_building().update_3d())
			this._cnview.update_visibilities();
		if (this._renderer) this._renderer.refresh_rendering();
	}

	/**
	 * Returns the element at given position of the screen, after a rendering
	 * @param {cn_mouse_event} mouse_event
	 * @returns {cn_storey_element}
	 */
	find_mouse_element(mouse_event) {
		var element = null;

		//*** search in 2d markers */
		for (var s in this._building.storeys)
		{
			const storey = this._building.storeys[s];
			for (var m in storey.markers)
			{
				const marker = storey.markers[m];
				if (marker.visibility_3d && marker.contains_3d(mouse_event.mouse_screen,10))
				{
					return new cn_storey_element(marker,storey);
				}
			}
		}

		//*** seearch in 3D */
		if (mouse_event.impact && mouse_event.impact.storey_element)// && mouse_event.impact.storey_element.element.constructor == cn_marker)
		{
			return mouse_event.impact.storey_element;
		}

		return null;
	}

	highlight_marker_element(marker)
	{
	}

	ask_delete_elements(elements)
	{
		const nb = elements.length;
		if (nb == 0) return;
		const input = new cn_question_input((nb==1)?"Voulez-vous supprimer cet élément ?":"Voulez-vous supprimer ces " + nb + " éléments ?");
		input.severity = SEVERITY_QUESTION_WARNING;
		const obj = this;
		input.callback = function() {
			obj.delete_elements(elements);
		}
		obj.call("question_input",input);
	}

	delete_selection() {
		this.delete_elements(this._selection);
		this.select_element(null);
		this.refresh();
	}

	/**
	 *
	 * @param {Array<object>} elements
	 * @returns
	 */
	delete_elements(elements) {
		const obj = this;
		if (elements.length <= 0) return;
		this._building.transaction_manager.push_transaction("Supression d'éléments", "",function() {obj.refresh();if (obj._renderer) obj._renderer.refresh_rendering()});

		const scene_list = [];
		elements.forEach(element => {

			if (element.constructor == cn_marker)
			{
				const index = element.storey.markers.indexOf(element);
				if (index >= 0)
				{
					this._building.transaction_manager.push_item_set(element.storey,"markers");
					element.storey.markers.splice(index,1);
				}
			}
			else if (element.constructor == cn_object_instance)
			{
				const index = element.scene.object_instances.indexOf(element);
				if (index >= 0)
				{
					this._building.transaction_manager.push_item_set(element.scene,"object_instances");
					element.scene.object_instances.splice(index,1);
				}
			}
			else if (element.constructor == cn_facing_trimming)
			{
				if (element.scene)
				{
					const index = element.scene.facing_trimmings.indexOf(element);
					if (index >= 0)
					{
						this._building.transaction_manager.push_item_set(element.scene,"facing_trimmings");
						element.scene.facing_trimmings.splice(index,1);
						if (scene_list.indexOf(element.scene) < 0)
							scene_list.push(element.scene);
					}
				}
				else if (element.building)
				{
					const index = element.building.facing_trimmings.indexOf(element);
					if (index >= 0)
					{
						this._building.transaction_manager.push_item_set(element.building,"facing_trimmings");
						element.building.facing_trimmings.splice(index,1);
					}
				}
			}

			//*** Remove matching 3D objects */
			this._cnview._3d_building.remove_elements(element);
		});

		if (this._current_tool && this._current_tool._terminate_edition)
			this._current_tool._terminate_edition();

		scene_list.forEach(scene => {
			scene.update();
			scene.update_deep();
		})

		this.select_element(null);
		obj.refresh();
		obj.refresh_3d();
	}
}


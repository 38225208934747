"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_bbp converter :
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Global functions :
//***********************************************************************************

import {fh_add, fh_clone, fh_extruded_polygon, fh_matrix, fh_mul, fh_polygon, fh_solid} from "@acenv/fh-3d-viewer";
import {cn_building} from "../model/cn_building";
import {cn_roof_opening} from "../model/cn_roof_opening";
import {cn_clone, cn_dist, cn_mul} from "./cn_utilities";
import {BbpBimTranscoder} from "./transcoder/cn_bbp_bim_transcoder";
import {cn_image_dir} from "./image_dir";
import {
    CODE_BIM_CHEMIN_PIETON,
    CODE_BIM_CLOTURES,
    CODE_BIM_COURS,
    CODE_BIM_DALLE,
    CODE_BIM_ELEMENT_VEGETAL,
    CODE_BIM_ESCALIER,
    CODE_BIM_FENETRE,
    CODE_BIM_FENETRE_DE_TOIT,
    CODE_BIM_FLAT,
    CODE_BIM_GARDE_CORPS,
    CODE_BIM_INCONNU,
    CODE_BIM_LANTERNEAU,
    CODE_BIM_MUR,
    CODE_BIM_PELOUSE,
    CODE_BIM_PORTE,
    CODE_BIM_POTEAU,
    CODE_BIM_POUTRES,
    CODE_BIM_RESEAUX_INTERIEURS,
    CODE_BIM_SPACE,
    CODE_BIM_VOIRIE,
    CODES_BIM,
    PARAM_CODE_BIM_SURFACE_REVETEMENT
} from "./cn_bbp_constants";

export function cn_to_bbp(building, toExport = false, cnmap_pointers = false) {
    var bbp = new cn_bbp(building);
    bbp.cnmap_pointers = cnmap_pointers;
    return bbp.to_bbp(toExport);
}

//***********************************************************************************
//***********************************************************************************
//**** cn_bbp class :
//***********************************************************************************
//***********************************************************************************

//*** For debugging, we may export roof volumes
const EXPORT_ROOF_VOLUMES = false;

const LOG_TIME = true;

export class cn_bbp {


    //********************************************************************************
    //**** Constructor
    //********************************************************************************
    constructor(building) {
        this.bbpBimTranscoder = new BbpBimTranscoder();
        this._building = building;
        this._dummy_solid_id = 0;
        this.cnmap_pointers = false;
        this._timer_0 = 0;
        this._timer = 0;
        this.steps = [];

        this._uids = [];
    }

    log_time(label) {
        if (!LOG_TIME) return;
        var t = new Date().getTime();
        console.log(`#### ${label} at ${t - this._timer_0} (${t - this._timer})`);
        const step = this.steps.find(s => s.label == label);
        if (step) step.duration += t - this._timer;
        else this.steps.push({duration: t - this._timer, label: label});
        this._timer = t;
    }

    log_final() {
        if (!LOG_TIME) return;

        const full_duration = this._timer - this._timer_0;
        this.steps.sort(function (a, b) {
            return b.duration - a.duration
        });
        this.steps.forEach(step => console.log(`---- ${step.label} : ${step.duration} (${(100 * step.duration / full_duration).toFixed(2)}%)`))
    }

    //********************************************************************************
    //**** Main conversion function
    //********************************************************************************
    to_bbp(to_export = false) {
        if (LOG_TIME) {
            this._timer_0 = this._timer = new Date().getTime();
            this.steps = [];
        }

        this._building.rename_storeys();
        this._building.update_roofs();
        this._building.compute_altitudes();
        const space_zone_map = new Map();

        //*** Clear object instances */
        for (var i in this._building.objects)
            this._building.objects[i].instances = [];

        //*** Clear opening type instances */
        for (var i in this._building.element_types)
            this._building.element_types[i].instances = [];

        var json = {};
        var slab_thickness = 0.3;
        var nb_walls = 0;

        this._json = json;

        //fh_solid.unitary_test();
        json.storey_list = [];
        json.storey_heights = [];
        json.instances = [];
        json.objects = [];
        var concrete_color = [1, 0.95, 0.9, 1];
        var stairs_color = [0.75, 0.75, 0.75, 1];
        var terrace_roof_color = [0.8, 0.8, 0.8, 1];
        var roof_color = [0.8, 0.5, 0.5, 1];

        this._building.zones['usage'].forEach(zone => {
            const bbp_zone = this._build_zone(zone);
            json.objects.push(bbp_zone);
            zone.rooms.forEach(room => {
                if (!space_zone_map.get(room.storey)) {
                    space_zone_map.set(room.storey, new Map());
                }
                const storey_map = space_zone_map.get(room.storey);
                storey_map.set(room.space, zone.ID);
                space_zone_map.set(room.storey, storey_map);
            });
        });

        for (var nbs = 0; nbs < this._building.storeys.length; nbs++) {
            this.log_time("Start storey");

            //*** write storeys
            var storey = this._building.storeys[nbs];
            slab_thickness = storey.get_max_slab_thickness();
            json.storey_list.push(storey.get_storey_name());
            json.storey_heights.push(storey.altitude + 1.2);

            var storey_index = "" + storey.storey_index;
            this._storey_index = storey_index;
            var scene = storey.scene;

            this._storey = storey;
            this._h0 = storey.altitude;
            this._h1 = storey.altitude + storey.height;
            this._h2 = storey.roof_altitude;

            storey.scene.storey = storey;

            //***************************************
            //*** create roof
            //***************************************
            this._roof_volume = null;
            this._roof_height = this._h2;
            if (storey.roof) {
                this._storey_index = "" + (storey.storey_index + 1);
                for (var i in storey.roof.slabs) {
                    var slab = storey.roof.slabs[i];
                    var roof_polygon = slab.build_3d_polygon(this._h1, true);
                    var roof_polygons = roof_polygon.split();
                    var openings = [];
                    for (var op in storey.roof.openings) {
                        if (storey.roof.openings[op].slab == slab) {
                            var opening = storey.roof.openings[op];
                            openings.push(opening);
                            var bbp_opening = this._build_opening(slab, opening, this._h0);
                            if (bbp_opening)
                                json.objects.push(bbp_opening);
                        }
                    }
                    for (var k in roof_polygons) {
                        var bbp_roof = {};
                        json.objects.push(bbp_roof);
                        bbp_roof.ID = cn_building.create_unique_id(this._storey, i, k, "roof");
                        bbp_roof.Name = "Toiture";
                        bbp_roof.Code_BIM = CODES_BIM.roof;
                        bbp_roof.IfcType = "IfcSlab";
                        bbp_roof.geometries = [];
                        bbp_roof.storey = this._storey_index;
                        if (this.cnmap_pointers) {
                            bbp_roof.cnmap_storey = storey;
                            bbp_roof.cnmap_element = slab;
                        }
                        Object.assign(bbp_roof, this.bbpBimTranscoder.getRoofParameters(slab));

                        var solid = new fh_solid();
                        solid.extrusion(roof_polygons[k], [0, 0, slab.slab_type.thickness]);
                        for (var op in openings) {
                            var sld = openings[op].build_piercing(this._h1);
                            if (sld) solid.substracts(sld);
                        }
                        var geometry_3d = this._solid_to_geometry(solid);

                        bbp_roof.geometries.push(geometry_3d);
                        geometry_3d.color = (Math.abs(slab.slope) < 1) ? terrace_roof_color : roof_color;
                        geometry_3d.views = ["3d"];
                    }
                }
            }
            this.log_time("Computed roof");

            this._roof_volume = storey.build_roof_volume();
            if (this._roof_volume) {
                var box = this._roof_volume.get_bounding_box();
                this._roof_height = box.position[2] + box.size[2];
                console.log("modified roof height : ", this._roof_height, this._roof_volume);

                if (EXPORT_ROOF_VOLUMES) {
                    for (var i in this._roof_volume.slab_volumes) {
                        json.objects.push(this._dummy_bbp_solid(this._roof_volume.slab_volumes[i], "Slab volume " + i));
                    }
                }
            }

            console.log(`storey ${nbs} : h2 = ${this._h2} roof height = ${this._roof_height}`, this._roof_volume);

            this.log_time("Computed roof volume");
            //***************************************
            //*** create walls for roof line disconinuities
            //***************************************
            if (storey.roof) {
                var wall_thickness = 0.3;
                for (var i in storey.roof.lines) {
                    var line = storey.roof.lines[i];
                    if (line.is_border()) continue;
                    var discontinuity = false;
                    for (var nv = 0; nv < 2; nv++) {
                        var h0 = line.slabs[0].compute_height(line.vertices[nv].position);
                        var h1 = line.slabs[1].compute_height(line.vertices[nv].position);
                        if (Math.abs(h0 - h1) < 0.01) continue;
                        discontinuity = true;
                    }
                    if (!discontinuity) continue;

                    var low_h = [0, 0];
                    var high_h = [0, 0];
                    var high_slab = [-1, -1];
                    for (var nv = 0; nv < 2; nv++) {
                        var h0 = this._h1 + line.slabs[0].compute_height(line.vertices[nv].position);
                        var h1 = this._h1 + line.slabs[1].compute_height(line.vertices[nv].position);
                        low_h[nv] = (h0 > h1) ? h1 : h0;
                        high_h[nv] = (h0 > h1) ? h0 : h1;
                        if (high_h[nv] - low_h[nv] > 0.01)
                            high_slab[nv] = (h0 > h1) ? 0 : 1;
                    }

                    if (high_slab[0] < 0 && high_slab[1] < 0) continue;

                    var p0 = cn_clone(line.vertices[0].position);
                    p0.push(low_h[0]);
                    var p0h = fh_clone(p0);
                    p0h[2] = high_h[0];
                    var p1 = cn_clone(line.vertices[1].position);
                    p1.push(low_h[1]);
                    var p1h = fh_clone(p1);
                    p1h[2] = high_h[1];

                    //*** maybe intermediate point ? */
                    if (high_slab[0] >= 0 && high_slab[1] >= 0 && high_slab[0] != high_slab[1]) {
                        var dh0 = high_h[0] - low_h[0];
                        var dh1 = high_h[1] - low_h[1];
                        var x = dh0 / dh1 / (1 + dh0 / dh1);
                        var pp = fh_add(fh_mul(p0, 1 - x), fh_mul(p1h, x));

                        var offset = (high_slab[0] == 0) ? -1 : 1;
                        var offset_dir = cn_mul(line.bounds.normal, offset * wall_thickness);
                        offset_dir.push(0);
                        var wall = this._build_roof_wall([p0, pp, p0h], offset_dir, concrete_color);
                        wall.ID = cn_building.create_unique_id(line, "0");
                        if (wall) this._add_object(wall);

                        offset = (high_slab[1] == 0) ? -1 : 1;
                        offset_dir = cn_mul(line.bounds.normal, offset * wall_thickness);
                        offset_dir.push(0);
                        wall = this._build_roof_wall([pp, p1, p1h], offset_dir, concrete_color);
                        wall.ID = cn_building.create_unique_id(line, "1");
                        if (wall) this._add_object(wall);
                    }
                    //*** regular case */
                    else {
                        var offset = (high_slab[0] == 0 || high_slab[1] == 0) ? -1 : 1;
                        var offset_dir = cn_mul(line.bounds.normal, offset * wall_thickness);
                        offset_dir.push(0);
                        var contour = [p0, p1, p1h, p0h];
                        var wall = this._build_roof_wall(contour, offset_dir, concrete_color);
                        wall.ID = cn_building.create_unique_id(line);
                        if (wall) this._add_object(wall);
                    }
                }
            }

            this._storey_index = "" + storey.storey_index;

            this.log_time("Computed roof walls");
            //***************************************
            //*** create spaces and flats
            //***************************************
            const created_storeys_default_zones = []; // keep track of storeys indexes with default zone

            if (scene.spaces.length > 1) {
                const zones_storey = space_zone_map.get(storey.ID) || new Map();
                // Creates default zone, it will be pushed in building only if needed
                const default_storey_zone = this._build_default_zone(storey);
                //*** Create spaces
                scene.spaces.filter(space => !space.outside).forEach(space => {
                    const declared_zone_id = zones_storey.get(space.ID);
                    if (declared_zone_id == null) {
                        // No zone declared for this space => use default zone (push in into building if not already done)
                        if (!created_storeys_default_zones.includes(storey.storey_index)) {
                            json.objects.push(default_storey_zone);
                            created_storeys_default_zones.push(storey.storey_index);
                        }
                    }
                    const zone_id = declared_zone_id || default_storey_zone.ID;
                    const bbp_space = this._build_space(space, zone_id);
                    if (bbp_space) {
                        json.objects.push(bbp_space);
                        if (this.cnmap_pointers) {
                            bbp_space.cnmap_storey = storey;
                            bbp_space.cnmap_element = space;
                        }
                    }
                    json.objects = json.objects.concat(this._build_ceilings(space));
                });
            }

            this.log_time("Computed spaces");
            //***************************************
            //*** create floor slabs
            //***************************************

            var slab_openings = [];
            for (var so in storey.scene.slab_openings) {
                slab_openings.push(storey.scene.slab_openings[so].build_3d_polygon(0));
            }

            for (var s in storey.slabs) {
                var slab = storey.slabs[s];
                var slab_z = this._h0 - slab.slab_type.thickness;
                if (slab.spaces[1] && !slab.spaces[1].outside) slab_z += slab.spaces[1].slab_offset;
                var polygon = slab.build_polygon(slab_z);
                for (var so in slab_openings)
                    polygon.substracts(slab_openings[so]);

                var floor_polygons = polygon.split();
                for (var k in floor_polygons) {
                    var bbp_slab = {};
                    json.objects.push(bbp_slab);
                    bbp_slab.ID = cn_building.create_unique_id(slab, k, "slab");
                    bbp_slab.Name = "Dalle";
                    bbp_slab.Code_BIM = CODE_BIM_DALLE;
                    bbp_slab.IfcType = "IfcSlab";
                    if (slab.spaces) {
                        slab.spaces.filter(space => space != null).forEach((space, index) => {
                            if (index === 0) {
                                bbp_slab.SPACE = cn_building.create_unique_id(space.scene.storey, space);
                            } else if (index === 1) {
                                bbp_slab.SPACE2 = cn_building.create_unique_id(space.scene.storey, space);
                            }
                        });
                    }
                    Object.assign(bbp_slab, this.bbpBimTranscoder.getSlabParameters(slab));
                    bbp_slab.geometries = [];
                    bbp_slab.storey = this._storey_index;
                    if (this.cnmap_pointers) {
                        bbp_slab.cnmap_storey = storey;
                        bbp_slab.cnmap_element = slab;
                    }

                    var solid = new fh_solid();
                    solid.extrusion(floor_polygons[k], [0, 0, slab.slab_type.thickness])
                    var geometry_3d = this._solid_to_geometry(solid);
                    bbp_slab.geometries.push(geometry_3d);
                    geometry_3d.color = concrete_color;
                    geometry_3d.views = ["3d"];

                    var geometry2d = this._polygon_to_geometry(floor_polygons[k]);
                    bbp_slab.geometries.push(geometry2d);
                    geometry2d.color = concrete_color;
                    geometry2d.views = [this._storey_index];
                }
            }

            this.log_time("Computed slabs");
            //***************************************
            //*** create floor facings
            //***************************************

            for (let sp in storey.scene.spaces) {
                const space = storey.scene.spaces[sp];
                if (space.outside) continue;

                const floor_facings = space.build_3d_floor_facings(storey, this.cnmap_pointers);
                floor_facings.forEach(floor_facing => {
                    Object.assign(floor_facing, this.bbpBimTranscoder.getFloorFacingParameters(space));
                });
                json.objects = json.objects.concat(floor_facings);
            }
            this.log_time("Computed floor facings");

            //*** build storey volume : roof volume + storey slabs */
            this._storey_volume = storey.build_roof_volume(true);

            this.log_time("Computed roof volume for facings");
            //***************************************
            //*** create walls
            //***************************************
            for (var i in scene.walls) {
                var wall = scene.walls[i];
                if (!wall.valid) continue;
                if (wall.wall_type.free) continue;
                if (wall.balcony && wall.wall_type.height < 0.01) continue;

                var low_offset = wall.get_low_offset();
                var high_offset = wall.get_high_offset()
                var bbp_wall = {};
                json.objects.push(bbp_wall);

                bbp_wall.ID = cn_building.create_unique_id(this._storey, wall);
                bbp_wall.Name = wall.wall_type.get_label();
                nb_walls++;
                if (wall.balcony) {
                    bbp_wall.Code_BIM = CODE_BIM_GARDE_CORPS;
                    bbp_wall.IfcType = "IfcRailing";
                } else {
                    bbp_wall.Code_BIM = CODE_BIM_MUR;
                    bbp_wall.IfcType = "IfcWallStandardCase";
                    bbp_wall.WALL_LENGTH = wall.bounds.length;
                    bbp_wall.WALL_HEIGHT = storey.height;
                    if (wall.spaces) {
                        wall.spaces.filter(space => space != null && space.indoor).forEach((space, index) => {
                            if (index === 0) {
                                bbp_wall.SPACE = cn_building.create_unique_id(this._storey, space);
                            } else if (index === 1) {
                                bbp_wall.SPACE2 = cn_building.create_unique_id(this._storey, space);
                            }
                        });
                    }
                }
                Object.assign(bbp_wall, this.bbpBimTranscoder.getWallParameters(wall, storey));
                bbp_wall.geometries = [];
                bbp_wall.storey = this._storey_index;
                if (this.cnmap_pointers) {
                    bbp_wall.cnmap_storey = storey;
                    bbp_wall.cnmap_element = wall;
                }

                //*** 2D geometry
                var geometry_2d = this._polygon_to_geometry(wall.build_footprint(this._storey.altitude));
                bbp_wall.geometries.push(geometry_2d);
                geometry_2d.color = [0, 0, 0, 1];
                geometry_2d.views = [this._storey_index];

                //*** special case for balconies
                if (wall.balcony) {
                    var extruded_polygons = wall.wall_type.build_extruded_polygons(this._h0 + low_offset, wall);
                    for (var i in extruded_polygons) {
                        var geometry_3d_1 = extruded_polygons[i].tesselate();
                        bbp_wall.geometries.push(geometry_3d_1);
                        geometry_3d_1.views = ["3d"];
                    }
                } else {
                    //*** Build 3D wall
                    var solid_wall = wall.build_3d_solid(this._storey);
                    var geometry_3d = this._solid_to_geometry(solid_wall, this._storey.altitude);
                    bbp_wall.geometries.push(geometry_3d);
                    geometry_3d.color = concrete_color;
                    geometry_3d.views = ["3d"];

                    //*** Add openings
                    wall.openings.forEach(opening => {
                        //*** add opening itself
                        var obj = this._build_opening(wall, opening, this._h0 + high_offset);
                        if (obj)
                            json.objects.push(obj);
                    });
                }


                //*** Build wall facings */
                const facing_0 = wall.build_bbp_facings(0, this._storey, this.cnmap_pointers, this.bbpBimTranscoder);
                const facing_1 = wall.build_bbp_facings(1, this._storey, this.cnmap_pointers, this.bbpBimTranscoder);
                var facings = facing_0
                    .concat(facing_1);
                facings.forEach(f => json.objects.push(f));
            }

            this.log_time("Computed walls");
            //***************************************
            //*** create stairs
            //***************************************
            const upper_storey = (nbs + 1 < this._building.storeys.length) ? this._building.storeys[nbs + 1] : null;
            for (var i in scene.stairs) {
                var stairs = scene.stairs[i];
                if (!stairs.valid) continue;

                var bbp_stairs = {};
                json.objects.push(bbp_stairs);

                bbp_stairs.ID = cn_building.create_unique_id(this._storey, stairs);
                bbp_stairs.Name = "Escalier";
                bbp_stairs.Code_BIM = CODE_BIM_ESCALIER;
                bbp_stairs.IfcType = "IfcStair";
                Object.assign(bbp_stairs, this.bbpBimTranscoder.getStairParameters(stairs));
                bbp_stairs.geometries = [];
                bbp_stairs.storey = this._storey_index;
                bbp_stairs.SPACE = cn_building.create_unique_id(this._storey, stairs.space);
                if (this.cnmap_pointers) {
                    bbp_stairs.cnmap_storey = storey;
                    bbp_stairs.cnmap_element = stairs;
                }
                bbp_stairs.SPACE = cn_building.create_unique_id(this._storey, stairs.space);
                if (upper_storey) {
                    const upper_space = stairs.get_upper_space(upper_storey);
                    if (upper_space) {
                        bbp_stairs.SPACE2 = cn_building.create_unique_id(upper_storey, upper_space);
                    }
                }
                var polygons = stairs.build_polygons(this._h0, this._h2);
                var geo = this._polygons_to_geometry(polygons);

                bbp_stairs.geometries.push(geo);
                geo.color = stairs_color;
                geo.views = ["3d", this._storey_index];
            }

            this.log_time("Computed stairs");
            //***************************************
            //*** create objects
            //***************************************
            for (let i in scene.object_instances) {
                const instance = scene.object_instances[i];
                const h0 = this._h0;
                const bbp_object = this._build_object(instance, h0, false);
                this._json.objects.push(bbp_object);
            }

            this.log_time("Computed objects");
            //***************************************
            //*** create columns
            //***************************************
            for (var i in scene.columns) {
                var column = scene.columns[i];

                var solid0 = column.build_solid(this._h0 + storey.compute_z_floor(column.position), this._h0 + storey.compute_z_ceiling(column.position, false));

                var bbp_column = {};
                json.objects.push(bbp_column);

                bbp_column.ID = cn_building.create_unique_id(this._storey, column);
                bbp_column.Name = "Colonne";
                bbp_column.Code_BIM = CODE_BIM_POTEAU;
                bbp_column.IfcType = "IfcColumn";
                Object.assign(bbp_column, this.bbpBimTranscoder.getBeamParameters(column, storey));
                bbp_column.geometries = [];
                bbp_column.storey = this._storey_index;
                const spaces = column.get_spaces(this._storey.scene);
                if (spaces.length >= 1 && spaces.length <= 2) {
                    bbp_column.SPACE = cn_building.create_unique_id(storey, spaces[0]);
                    if (spaces.length === 2) {
                        bbp_column.SPACE2 = cn_building.create_unique_id(storey, spaces[1]);
                    }
                }
                if (this.cnmap_pointers) {
                    bbp_column.cnmap_storey = storey;
                    bbp_column.cnmap_element = column;
                }

                var geo = this._solid_to_geometry(solid0);

                bbp_column.geometries.push(geo);
                geo.color = (solid0["color"]) ? solid0["color"] : concrete_color;
                geo.views = ["3d"];

                var bbpgeo = this._polygon_to_geometry(column.build_footprint(this._h0));
                bbp_column.geometries.push(bbpgeo);
                bbpgeo.color = [0, 0, 0, 1];
                bbpgeo.views = [this._storey_index];
            }

            //***************************************
            //*** create beams
            //***************************************
            for (var i in scene.beams) {
                var beam = scene.beams[i];

                var bbp_beam = {};
                json.objects.push(bbp_beam);

                bbp_beam.ID = cn_building.create_unique_id(this._storey, beam);
                bbp_beam.Name = "Poutre";
                bbp_beam.Code_BIM = CODE_BIM_POUTRES;
                bbp_beam.IfcType = "IfcBeam";
                Object.assign(bbp_beam, this.bbpBimTranscoder.getBeamParameters(beam, storey));
                bbp_beam.geometries = [];
                bbp_beam.storey = this._storey_index;
                const spaces = beam.get_spaces(this._storey.scene);
                if (spaces.length >= 1 && spaces.length <= 2) {
                    bbp_beam.SPACE = cn_building.create_unique_id(storey, spaces[0]);
                    if (spaces.length === 2) {
                        bbp_beam.SPACE2 = cn_building.create_unique_id(storey, spaces[1]);
                    }
                }
                if (this.cnmap_pointers) {
                    bbp_beam.cnmap_storey = storey;
                    bbp_beam.cnmap_element = beam;
                }

                var hh = [0, 0];
                for (var nv = 0; nv < 2; nv++) {
                    hh[nv] = this._h0 + storey.compute_z_ceiling(beam.vertices[nv], false);
                }

                var solid1 = beam.build_solid(this._h0, hh[0], hh[1]);
                var geo = this._solid_to_geometry(solid1);

                bbp_beam.geometries.push(geo);
                geo.color = (solid1.color) ? solid1.color : concrete_color;
                geo.views = ["3d"];
            }

            this.log_time("Computed beams & columns");
            //***************************************
            //*** create pipes
            //***************************************
            for (var i in scene.pipes) {
                var pipe = scene.pipes[i];

                var bbp_pipe = {};
                json.objects.push(bbp_pipe);

                bbp_pipe.ID = cn_building.create_unique_id(this._storey, pipe);
                bbp_pipe.Name = "Conduit";
                bbp_pipe.Code_BIM = CODE_BIM_RESEAUX_INTERIEURS;
                bbp_pipe.IfcType = "IfcFlowSegment";
                Object.assign(bbp_pipe, this.bbpBimTranscoder.getPipeParameters(pipe));
                bbp_pipe.geometries = [];
                bbp_pipe.storey = this._storey_index;
                if (this.cnmap_pointers) {
                    bbp_pipe.cnmap_storey = storey;
                    bbp_pipe.cnmap_element = pipe;
                }

                var solid1 = pipe.build_solid(this._h0);
                var geo = this._solid_to_geometry(solid1);

                bbp_pipe.geometries.push(geo);
                geo.color = (solid1.color) ? solid1.color : concrete_color;
                geo.views = ["3d"];
            }
            this.log_time("Computed pipes");

            //***************************************
            //*** create comments
            //***************************************
            if (this.cnmap_pointers) {
                this._storey.markers.forEach(marker => {
                    const bbp = marker.build_bbp();
                    if (bbp)
                        json.objects.push(bbp);
                });
            }
        }


        var t0 = (new Date()).getTime();
        this._export_exterior(to_export);
        console.log("Export exterior : ", (new Date()).getTime() - t0);

        this.log_time("END");
        this.log_final();
        return json;
    }

    _add_object(object) {
        if (this._uids.includes(object.ID))
            console.log("WARNING!!! duplicate UID for object ", object);
        else
            this._uids.push(object.ID);
        this._json.objects.push(object);
    }

    //********************************************************************************
    /**
     * exports exterior
     */
    _export_exterior(to_export = false) {
        //*** Compute bounding box */
        var bb = this._building.exterior.scene.get_bounding_box();
        this._building.storeys.forEach(st => {
            bb.enlarge_box(st.scene.get_bounding_box());
        });
        bb.enlarge_distance(5);

        var topography = this._building.topography;
        var topography = this._building.topography;
        if (topography && bb.posmin && bb.posmin.length && bb.size && bb.size.length) {
            topography.resize(Math.floor(bb.posmin[0]), Math.floor(bb.posmin[1]), 1 + Math.ceil(bb.size[0]), 1 + Math.ceil(bb.size[1]))
            if (to_export)
                this._json.topography = topography;
        }

        var z = topography.z;

        //*** base ground polygon */
        var base_ground = new fh_polygon([0, 0, z], [0, 0, 1]);
        var contour = [];
        if (topography) {
            contour.push([topography.origin[0], topography.origin[1], 0]);
            contour.push([topography.origin[0] + topography.size[0] - 1, topography.origin[1], 0]);
            contour.push([topography.origin[0] + topography.size[0] - 1, topography.origin[1] + topography.size[1] - 1, 0]);
            contour.push([topography.origin[0], topography.origin[1] + topography.size[1] - 1, 0]);
        } else {
            bb.posmin.push(0);
            bb.size.push(0);
            contour.push(bb.posmin);
            contour.push(fh_add(bb.posmin, [bb.size[0], 0, 0]));
            contour.push(fh_add(bb.posmin, bb.size));
            contour.push(fh_add(bb.posmin, [0, bb.size[1], 0]));
        }
        base_ground.add_contour(contour);

        //*** remove storey 0 */
        var storey0 = this._building.storeys[this._building.storey_0_index];
        if (storey0 && storey0.scene.spaces.length > 1) {
            var exterior_space = storey0.scene.spaces.find(sp => sp.outside);
            var polygon_exterior_space = exterior_space.build_inner_polygon(0, false);
            base_ground.substracts(polygon_exterior_space);
        }

        this._storey = this._building.exterior;
        this._storey_index = this._building.storeys[this._building.storey_0_index].storey_index;
        //*** exterior spaces */
        var t = (new Date()).getTime();
        this._storey.scene.spaces.forEach(space => {
            if (!space.outside) {
                var space_polygon = space.build_slab_polygon(z);
                space_polygon.intersects(base_ground);
                if (space_polygon.get_area() < 0.01) return;
                base_ground.substracts(space_polygon);

                var bbp_element = {};
                this._json.objects.push(bbp_element);

                bbp_element.ID = cn_building.create_unique_id(this._storey, space);
                bbp_element.Name = "Voirie";
                bbp_element.Code_BIM = CODE_BIM_VOIRIE;
                bbp_element.storey = this._storey_index;
                bbp_element.topography = "map";
                bbp_element.geometries = [];
                if (this.cnmap_pointers) {
                    bbp_element.cnmap_storey = this._storey;
                    bbp_element.cnmap_element = space;
                }

                var geo = (topography) ? topography.polygon_to_tesselation(space_polygon) : this._polygon_to_geometry(space_polygon);
                bbp_element.geometries.push(geo);
                geo.color = [0.9, 0.9, 0.9, 1];
                geo.views = ["3d"];

                if (space.facing == "asphalt") {
                    bbp_element.Name = "Voirie";
                    bbp_element.Code_BIM = CODE_BIM_VOIRIE;
                    geo.color = [0.3, 0.3, 0.3, 1];
                } else if (space.facing == "concrete") {
                    bbp_element.Name = "Béton";
                    bbp_element.Code_BIM = CODE_BIM_COURS;
                    geo.color = [0.7, 0.7, 0.7, 1];
                } else if (space.facing == "gravel") {
                    bbp_element.Name = "Gravier";
                    bbp_element.Code_BIM = CODE_BIM_CHEMIN_PIETON;
                    geo.color = [0.5, 0.5, 0.5, 1];
                } else if (space.facing == "lawn") {
                    bbp_element.Name = "Pelouse";
                    bbp_element.Code_BIM = CODE_BIM_PELOUSE;
                    geo.color = [85 / 255, 181 / 255, 23 / 255, 1];
                } else if (space.facing == "marble") {
                    bbp_element.Name = "Dallage";
                    bbp_element.Code_BIM = CODE_BIM_CHEMIN_PIETON;
                    geo.color = [0.8, 0.9, 0.5, 1];
                } else if (space.facing == "pavement") {
                    bbp_element.Name = "Pavement";
                    bbp_element.Code_BIM = CODE_BIM_VOIRIE;
                    geo.color = [0.6, 0.6, 0.65, 1];
                }

                if (space.facings[0])
                    geo.texture = cn_image_dir() + "texture_" + space.facings[0].texture + ".jpg";
            }
        });
        console.log("ground tesselation : ", (new Date()).getTime() - t);

        //*** remaining ground */
        var bbp_element = {};
        this._json.objects.push(bbp_element);

        bbp_element.ID = cn_building.create_unique_id(this._storey);
        bbp_element.Name = "Voirie";
        bbp_element.Code_BIM = CODE_BIM_VOIRIE;
        bbp_element.storey = this._storey_index;
        bbp_element.topography = "map";

        bbp_element.geometries = [];

        var geo = (topography) ? topography.polygon_to_tesselation(base_ground) : this._polygon_to_geometry(base_ground);
        bbp_element.geometries.push(geo);
        geo.color = [0.9, 0.9, 0.9, 1];
        geo.views = ["3d"];


        //*** exterior walls */
        this._storey.scene.walls.forEach(wall => {
            if (wall.free) return;

            var extruded_polygon_groups = wall.wall_type.build_extruded_polygons(z, wall);
            if (extruded_polygon_groups.length == 0) return;

            for (var nepg = 0; nepg < extruded_polygon_groups.length; nepg++) {
                const extruded_polygons = extruded_polygon_groups[nepg];
                var bbp_wall = {};
                this._json.objects.push(bbp_wall);
                bbp_wall.ID = cn_building.create_unique_id(this._storey, wall, nepg);
                bbp_wall.Name = wall.wall_type.get_label();
                bbp_wall.Code_BIM = CODE_BIM_CLOTURES;
                bbp_wall.IfcType = "IfcRailing";
                bbp_wall.topography = "upon";
                bbp_wall.storey = this._storey_index;
                if (this.cnmap_pointers) {
                    bbp_wall.cnmap_storey = this._storey;
                    bbp_wall.cnmap_element = wall;
                }
                if (wall.wall_type.category == "hedge") {
                    bbp_wall.Code_BIM = CODE_BIM_ELEMENT_VEGETAL;
                }

                //*** compute ground offset */
                bbp_wall.geometries = [];
                for (var i in extruded_polygons) {
                    // console.log("tessalate", i, extruded_polygons[i], extruded_polygons[i].constructor);
                    var geometry_3d_1 = extruded_polygons[i].tesselate();
                    bbp_wall.geometries.push(geometry_3d_1);
                    geometry_3d_1.views = ["3d"];
                }
                this._place_on_ground(bbp_wall);
            }

            wall.openings.forEach(opening => {
                if (opening.valid) {
                    //*** add opening itself
                    var obj = this._build_opening(wall, opening, z);
                    if (obj) {
                        obj.topography = "above";
                        this._json.objects.push(obj);
                        this._place_on_ground(obj);
                    }
                }
            });

            //*** Build wall facings */
            const facing_0 = wall.build_bbp_facings(0, this._storey, this.cnmap_pointers, this.bbpBimTranscoder);
            const facing_1 = wall.build_bbp_facings(1, this._storey, this.cnmap_pointers, this.bbpBimTranscoder);
            var facings = facing_0
                .concat(facing_1);
            facings.forEach(f => {
                f.storey = this._storey_index;
                this._json.objects.push(f);
            });
        });

        //***************************************
        //*** create pipes
        //***************************************
        var concrete_color = [1, 0.95, 0.9, 1];
        for (var i in this._storey.scene.pipes) {
            var pipe = this._storey.scene.pipes[i];

            var bbp_pipe = {};
            this._json.objects.push(bbp_pipe);

            bbp_pipe.ID = cn_building.create_unique_id(this._storey, pipe);
            bbp_pipe.Name = "Conduit";
            bbp_pipe.Code_BIM = CODE_BIM_RESEAUX_INTERIEURS;
            bbp_pipe.IfcType = "IfcFlowSegment";
            bbp_pipe.topography = "upon";
            bbp_pipe.storey = this._storey_index;
            Object.assign(bbp_pipe, this.bbpBimTranscoder.getPipeParameters(pipe));
            bbp_pipe.geometries = [];
            if (this.cnmap_pointers) {
                bbp_pipe.cnmap_storey = this._storey;
                bbp_pipe.cnmap_element = pipe;
            }

            var solid1 = pipe.build_solid(z);
            let geo = this._solid_to_geometry(solid1);

            bbp_pipe.geometries.push(geo);
            geo.color = (solid1.color) ? solid1.color : concrete_color;
            geo.views = ["3d"];
        }

        //***************************************
        //*** create objects
        //***************************************
        for (let i in this._storey.scene.object_instances) {
            const instance = this._storey.scene.object_instances[i];
            const h0 = z;
            const bbp_object = this._build_object(instance, h0, true);
            this._json.objects.push(bbp_object);
        }
    }

    //********************************************************************************
    /**
     * Build roof wall
     * @param {number[][]} contour
     * @param {number[]} thickness
     * @param {number[]} color
     * @returns {object}
     */
    _build_roof_wall(contour, thickness, color) {
        var bbp_wall = {};
        bbp_wall.Name = "Pignon";
        bbp_wall.Code_BIM = CODE_BIM_MUR;
        bbp_wall.IfcType = "IfcWallStandardCase";
        bbp_wall.WALL_LENGTH = cn_dist(contour[0], contour[1]);
        var hmin = contour[0][2];
        var hmax = contour[0][2];
        for (var k in contour) {
            if (hmin > contour[k][2]) hmin = contour[k][2];
            if (hmax < contour[k][2]) hmax = contour[k][2];
        }
        bbp_wall.WALL_HEIGHT = hmax - hmin;
        bbp_wall.storey = this._storey.storey_index;

        var pg = new fh_polygon();
        pg.add_contour(contour);
        pg.compute_contours();
        var epg = fh_extruded_polygon.build_extrusion(pg, thickness, color);
        const geo = epg.tesselate();
        geo["views"] = ["3d"];
        bbp_wall.geometries = [geo];

        return bbp_wall;
    }

    //********************************************************************************
    //**** place an object on the ground
    //********************************************************************************
    _place_on_ground(object, above) {
        if (!this._building.topography) return;

        var all_vertices = [];
        var matrix = null;

        object.geometries.forEach(geo => {
            if (geo.views.indexOf("3d") >= 0) {
                if (typeof (geo.vertices) == 'object')
                    all_vertices = all_vertices.concat(geo.vertices);
                else if (typeof (geo.matrix) == 'object') {
                    var instance = this._json.instances.find(ins => ins.id == geo.instance);
                    if (instance) all_vertices = all_vertices.concat(instance.vertices);
                    if (matrix == null) {
                        matrix = new fh_matrix();
                        matrix.values = geo.matrix.concat([]);
                    }
                }
            }
        });

        const h_offset = this._building.topography.compute_offset(all_vertices, matrix, object.topography == "above");

        // @ts-ignore
        if (matrix) matrix.values[14] += h_offset;

        object.geometries.forEach(geo => {
            if (geo.views.indexOf("3d") >= 0) {
                if (typeof (geo.vertices) == 'object') {
                    for (var nv = 0; nv < geo.vertices.length; nv += 3) geo.vertices[nv + 2] += h_offset;
                } else if (typeof (geo.matrix) == 'object') {
                    geo.matrix = matrix.values;
                }
            }
        });
    }

    /**
     * @param instance
     * @param {number} h0
     * @param {boolean} isExterior
     * @returns {object}
     */
    _build_object(instance, h0, isExterior) {
        const object = instance.object;
        if (instance.object == null) return;
        this._build_object_instances(instance);
        if (object.instances && object.instances.length == 0) return;

        const matrix = instance.build_3d_matrix(h0, this._storey);
        if (object.matrix) matrix.multiplies(object.matrix);

        const bbp_object = {};

        bbp_object.ID = cn_building.create_unique_id(this._storey, instance);
        bbp_object.Name = object.get_label();
        bbp_object.Code_BIM = object.source.product_type;
        bbp_object.storey = this._storey_index;

        if (isExterior) {
            bbp_object.topography = "upon";
        } else {
            if (instance.space) {
                bbp_object.SPACE = cn_building.create_unique_id(this._storey, instance.space);
            }
        }

        // Parameters
        const type_parameters = instance.object.source.parameters;
        const instance_parameters = instance.parameters;
        Object.assign(bbp_object, type_parameters);
        Object.assign(bbp_object, instance_parameters);

        Object.assign(bbp_object, this.bbpBimTranscoder.getObjectParameters(object));

        if (this.cnmap_pointers) {
            bbp_object.cnmap_storey = this._storey;
            bbp_object.cnmap_element = instance;
        }

        if (!instance.virtual) {

            bbp_object.geometries = [];

            for (let i in object.instances) {
                const inst = object.instances[i];
                const geometry = {};
                geometry.instance = inst.id;
                geometry.color = inst.color;
                if (isExterior) {
                    geometry.views = ["3d"];
                } else {
                    geometry.views = ["3d", this._storey_index];
                }
                geometry.matrix = matrix.values;
                bbp_object.geometries.push(geometry)
            }

            if (isExterior) {
                this._place_on_ground(bbp_object, false);
            }
        }

        return bbp_object;
    }

    //********************************************************************************
    //**** Build object instances
    //********************************************************************************
    _build_object_instances(originalInstance) {
        const object = originalInstance.object;
        if (object.instances && object.instances.length > 0) return;
        object.instances = [];
        const geometies = object.get_geometries();
        geometies.forEach(geo => {
            //*** Build bbp instance

            const bbp_instance = {};
            bbp_instance.id = this._json.instances.length;
            bbp_instance.Code_BIM = object.source.product_type;
            bbp_instance.vertices = geo.vertices;
            bbp_instance.triangles = geo.triangles;
            if (this.cnmap_pointers)
                bbp_instance.cnmap_object = object;

            this._json.instances.push(bbp_instance);
            const instance = {};
            instance.id = bbp_instance.id;
            instance.color = [0.8, 0.7, 0.6]; //geo.color;
            if (typeof (geo.color) == 'object')
                instance.color = geo.color;
            object.instances.push(instance);
        });
        object.matrix = object.get_matrix();
    }

    //********************************************************************************
    //**** Build bbp default flat
    //********************************************************************************
    _build_default_zone(storey) {
        try {
            const flat = {};
            flat.ID = cn_building.create_unique_id(this._storey, "flat");
            flat.Name = "Lot du " + storey.get_storey_name();
            flat.Code_BIM = CODE_BIM_FLAT;
            flat.IfcType = 'IfcZone';
            flat.TYPE = CODE_BIM_INCONNU;
            flat.ESCALIER = "";
            flat.ETAGE = storey.storey_index;
            return flat;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    //********************************************************************************
    //**** Build bbp zone
    //********************************************************************************
    _build_zone(zone) {
        const storey = this._building.storeys.find(storey => storey.ID === zone.main_storey);
        const bbp_zone = {
            escalier: '',
            extension_data: {
                ifc_properties: {}
            },
            IfcType: "IfcZone",
            Code_BIM: CODE_BIM_FLAT
        };
        bbp_zone.ID = zone.ID;
        bbp_zone.Name = zone.name;
        bbp_zone.ETAGE = storey.storey_index;
        bbp_zone.TYPE = zone.zone_type || CODE_BIM_INCONNU;
        if (this.cnmap_pointers) {
            bbp_zone.cnmap_storey = storey;
            bbp_zone.cnmap_element = zone;
        }
        return bbp_zone;
    }

    //********************************************************************************
    //**** Build bbp space
    //********************************************************************************
    _build_space(space, zone_id) {
        try {
            if (space.outside) return null;
            //if (!space.has_roof) return null;
            var bbp_space = {};
            bbp_space.ID = cn_building.create_unique_id(this._storey, space);
            bbp_space.Name = space.get_name(this._storey);
            bbp_space.Code_BIM = CODE_BIM_SPACE;
            bbp_space.AREA = space.area;
            bbp_space.ETAGE = this._storey.storey_index;
            bbp_space.NB_ETAGES = this._building.NB_ETAGES;
            bbp_space.TYPE_PIECE = space.space_usage || CODE_BIM_INCONNU;
            bbp_space.LOT = zone_id;
            Object.assign(bbp_space, this.bbpBimTranscoder.getSpaceParameters(space, this._storey));
            bbp_space.geometries = [];

            var footprint = space.build_inner_polygon(this._h0, false);
            var solid = space.build_solid(this._storey);

            var geometry_3d = this._solid_to_geometry(solid, this._storey.altitude);
            bbp_space.geometries.push(geometry_3d);
            geometry_3d.color = [0.5, 1, 0.5, 0.3];
            geometry_3d.views = ["3d"];

            var geometry_3d0 = this._polygon_to_geometry(footprint);
            bbp_space.geometries.push(geometry_3d0);
            geometry_3d0.color = [0.5, 1, 0.5, 0.3];
            geometry_3d0.views = [this._storey_index];

            return bbp_space;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    //********************************************************************************
    /**
     * Build bbp ceilings
     * @param {object} space
     * @returns {object[]}
     */
    _build_ceilings(space) {
        const output = [];
        if (!space.has_roof) return output;

        const ceiling_polygons = this._building.build_3d_ceiling(this._storey, space);
        if (ceiling_polygons.length == 0) return output;
        let area = 0;
        ceiling_polygons.forEach(c => area += c.get_area());

        const bbp_facing_ceiling = {};
        bbp_facing_ceiling.ID = cn_building.create_unique_id(this._storey, space, "ceiling");
        bbp_facing_ceiling.Name = "Plafond " + space.get_name(this._storey);
        bbp_facing_ceiling.Code_BIM = CODES_BIM.facing_ceiling;
        bbp_facing_ceiling[PARAM_CODE_BIM_SURFACE_REVETEMENT] = area;
        bbp_facing_ceiling.SPACE = cn_building.create_unique_id(this._storey, space);
        bbp_facing_ceiling.storey = "" + (this._storey.storey_index + 1);
        Object.assign(bbp_facing_ceiling, this.bbpBimTranscoder.getCeilingFacingParameters(space));
        bbp_facing_ceiling.geometries = [];

        ceiling_polygons.forEach(c => {
            const geometry_3d = this._polygon_to_geometry(c, this._storey.altitude - 0.01);
            geometry_3d.color = [1, 1, 1, 1];
            geometry_3d.views = ["3d"];
            if (space.facings[1])
                geometry_3d.texture = cn_image_dir() + "texture_" + space.facings[1].texture + ".jpg";
            bbp_facing_ceiling.geometries.push(geometry_3d);
        });
        output.push(bbp_facing_ceiling);

        return output;
    }

    //***********************************************************************************
    //**** create bbp object for openings
    //***********************************************************************************
    _build_opening(wall, opening, z0) {
        if (opening.opening_type == null) return null;

        if (opening.opening_type.compute_physics) {
            opening.opening_type.compute_physics();
        }

        var is_roof_opening = (opening.constructor == cn_roof_opening);
        if (!is_roof_opening) {
            if (!opening.valid) return null;
            if (opening.opening_type.free) return null;
        }

        var object = {};

        //*** Build object */
        object.ID = cn_building.create_unique_id(this._storey, opening);
        object.Name = opening.opening_type.get_label();
        Object.assign(object, this.bbpBimTranscoder.getOpeningParameters(opening));
        object.storey = this._storey_index;
        if (this.cnmap_pointers) {
            object.cnmap_storey = this._storey;
            object.cnmap_element = opening;
        }

        if (opening.opening_type.category == "window") {
            if (!is_roof_opening) {
                object.Code_BIM = CODE_BIM_FENETRE;
            } else {
                object.Code_BIM = CODE_BIM_FENETRE_DE_TOIT;
            }
            object.IfcType = "IfcWindow";
        } else if (opening.opening_type.category == "door") {
            object.Code_BIM = CODE_BIM_PORTE;
            object.IfcType = "IfcDoor";
        } else if (opening.opening_type.category == "skylight") {
            object.Code_BIM = CODE_BIM_LANTERNEAU;
            object.IfcType = "IfcWindow";
        } else if (opening.opening_type.category == "vent") {
            object.Code_BIM = CODE_BIM_FENETRE_DE_TOIT;
            object.IfcType = "IfcWindow";
        }

        if (!is_roof_opening) {
            var space0 = wall.spaces[0];
            var space1 = wall.spaces[1];
            if (space0.outside) {
                space0 = space1;
                space1 = null;
            } else if (space1.outside)
                space1 = null;

            object.SPACE = cn_building.create_unique_id(this._storey, space0);
            if (space1)
                object.SPACE2 = cn_building.create_unique_id(this._storey, space1);
        }

        object.geometries = [];

        //*** Build instances if not already done */
        if (opening.opening_type.instances.length == 0) {
            var extruded_polygons = opening.opening_type.build_extruded_polygons();
            for (var i in extruded_polygons) {
                var id = this._json.instances.length;
                var inst = {id: id, color: extruded_polygons[i].color};
                opening.opening_type.instances.push(inst);
                var geometry_3d = extruded_polygons[i].tesselate();
                geometry_3d.id = id;
                this._json.instances.push(geometry_3d);
            }
        }

        //*** Build opening geometry, unsing instances */
        var matrix = opening.build_3d_matrix((is_roof_opening) ? this._h1 : z0);
        for (var i in opening.opening_type.instances) {
            var inst0 = opening.opening_type.instances[i];

            var geometry = {};
            geometry.instance = inst0.id;
            geometry.color = inst0.color;
            geometry.views = ["3d", this._storey_index];
            geometry.matrix = matrix.values.concat([]);
            object.geometries.push(geometry)
        }

        //*** Build footprint for 2D */
        if (!is_roof_opening) {
            var footprint = opening.build_footprint(this._h0 + 0.01);
            var geometry_2d = this._polygon_to_geometry(footprint);
            object.geometries.push(geometry_2d);
            if (opening.opening_type.category == "window")

                geometry_2d.color = [0, 0, 1, 1];
            else
                geometry_2d.color = [0.5, 0.3, 0.1, 1];
            geometry_2d.views = [this._storey_index];
        }

        return object;
    }

    //***********************************************************************************
    //**** Turns fh_polygon to geometry
    //***********************************************************************************

    _polygon_to_geometry(polygon, zoffset = 0) {
        polygon.compute_tesselation();

        var geometry = {};
        geometry.vertices = polygon.tesselation_vertices.flat();
        if (zoffset != 0) {
            for (var k = 2; k < geometry.vertices.length; k += 3)
                geometry.vertices[k] += zoffset;
        }
        geometry.triangles = polygon.tesselation_triangles.concat([]);

        geometry.extension_data = {};
        geometry.extension_data.type = "polygon";
        geometry.extension_data.point = polygon.get_point();
        geometry.extension_data.normal = polygon.get_normal();
        geometry.extension_data.contour_vertices = polygon.contour_vertices.flat();
        geometry.extension_data.contour_sizes = polygon.contour_sizes.concat([]);
        geometry.extension_data.contour_orientations = polygon.contour_orientations.concat([]);
        geometry.extension_data.contour_parents = polygon.contour_parents.concat([]);
        if (zoffset != 0) {
            geometry.extension_data.point[2] += zoffset;
            for (var k = 2; k < geometry.extension_data.contour_vertices.length; k += 3)
                geometry.extension_data.contour_vertices[k] += zoffset;
        }

        return geometry;
    }

    //***********************************************************************************
    //**** Turns fh_polygons to geometry
    //***********************************************************************************

    _polygons_to_geometry(polygons) {
        var solid = new fh_solid();
        for (var i in polygons)
            solid.add_face(polygons[i]);

        return this._solid_to_geometry(solid);
    }

    //***********************************************************************************
    //**** Turns fh_solid to geometry
    //***********************************************************************************

    _solid_to_geometry(solid, deltaz = 0) {
        var geometry = {};
        solid.compute_tesselation();
        geometry.vertices = solid.tesselation_vertices.flat();
        geometry.triangles = solid.tesselation_triangles.concat([]);

        geometry.extension_data = {};
        geometry.extension_data.type = "solid";
        geometry.extension_data.solid_vertices = [];
        geometry.extension_data.contours = [];
        geometry.extension_data.contour_sizes = [];
        var faces = solid.get_faces();
        var offset = 0;
        for (var i in faces) {
            var vertices = faces[i].contour_vertices;
            geometry.extension_data.solid_vertices = geometry.extension_data.solid_vertices.concat(vertices.flat());

            var contour_sizes = faces[i].contour_sizes;
            var contour_orientations = faces[i].contour_orientations;

            //*** we first add positive contours, then negative ones */
            for (var niter = 0; niter < 2; niter++) {
                var off = offset;
                for (var k = 0; k < contour_sizes.length; k++) {
                    var sz = contour_sizes[k];
                    if ((niter == 0) != contour_orientations[k]) {
                        off += sz;
                        continue;
                    }
                    for (var n = 0; n < sz; n++)
                        geometry.extension_data.contours.push(off + n);
                    off += sz;

                    if (contour_orientations[k])
                        geometry.extension_data.contour_sizes.push(sz);
                    else
                        geometry.extension_data.contour_sizes.push(-sz);
                }
            }
            offset += vertices.length;
        }

        if (deltaz != 0) {
            for (var k = 2; k < geometry.vertices.length; k += 3)
                geometry.vertices[k] += deltaz;
            for (var k = 2; k < geometry.extension_data.solid_vertices.length; k += 3)
                geometry.extension_data.solid_vertices[k] += deltaz;
        }

        return geometry;

    }

    _dummy_bbp_solid(solid, label) {
        var bbp_dummy = {};
        bbp_dummy.ID = "summy_dolid_" + this._dummy_solid_id;
        bbp_dummy.Name = label + " " + this._dummy_solid_id;
        bbp_dummy.Code_BIM = CODES_BIM.roof;
        bbp_dummy.geometries = [];

        this._dummy_solid_id++;

        var geometry_3d = this._solid_to_geometry(solid);

        bbp_dummy.geometries.push(geometry_3d);
        geometry_3d.color = [0, 1, 0];
        geometry_3d.views = ["3d"];

        return bbp_dummy;
    }
}

//***********************************************************************************
//**** create bbp roof
//***********************************************************************************

export function cn_roof_to_bbp(storey) {
    var json = {};
    json.storey_list = ["0"];
    json.storey_heights = [0];
    json.instances = [];
    json.objects = [];
    if (storey.roof == null) return json;
    for (var i in storey.roof.slabs) {
        var slab = storey.roof.slabs[i];
        var polygon = slab.build_3d_polygon(0);
        if (polygon == null || polygon.get_area() < 0.1) continue;

        var bbp_roof = {};
        json.objects.push(bbp_roof);
        bbp_roof.ID = "roof_" + storey.ID + i;
        bbp_roof.Name = "Toiture";
        bbp_roof.Code_BIM = CODES_BIM.roof;
        bbp_roof.geometries = [];
        bbp_roof.storey = "0";

        var geometry_3d = {};
        geometry_3d.vertices = [];
        geometry_3d.triangles = [];
        geometry_3d.color = (Math.abs(slab.slope) < 0.5) ? [0.8, 0.8, 0.8, 1] : [0.8, 0.5, 0.5, 1];
        geometry_3d.views = ["3d"];
        polygon.compute_tesselation();
        for (var i in polygon.tesselation_vertices) {
            geometry_3d.vertices.push(polygon.tesselation_vertices[i][0]);
            geometry_3d.vertices.push(polygon.tesselation_vertices[i][1]);
            geometry_3d.vertices.push(polygon.tesselation_vertices[i][2]);
        }
        for (var i in polygon.tesselation_triangles)
            geometry_3d.triangles.push(polygon.tesselation_triangles[i]);

        bbp_roof.geometries.push(geometry_3d);
    }
    return json;
}


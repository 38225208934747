import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Competence } from '../../../../../model/user-wizy.model';
import { TypeDocument } from '../../../../../model/type-document.model';
import { ReferenceService } from '../../../../../services/reference.service';
import { map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/commons-lib';
import { combineLatest } from 'rxjs';

export interface DataCompetenceModal {
    competence: Competence;
    nom: string;
    prenom: string;
}

@Component({
    selector: 'app-creation-competence',
    templateUrl: './creation-competence-modal.component.html',
    styleUrls: ['./creation-competence-modal.component.scss'],
})
export class CreationCompetenceModalComponent extends BaseComponent implements OnInit {
    competenceForm: FormGroup;
    typeDocumentControl: FormControl;
    justificatifControl: FormControl;
    typesDocFiltered: TypeDocument[] = [];
    codeTypeDocument: string;
    documentTitle: string;

    competence: Competence;
    hide = true;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly dialogRef: MatDialogRef<CreationCompetenceModalComponent>,
        private readonly referenceService: ReferenceService,
        @Inject(MAT_DIALOG_DATA) public data: DataCompetenceModal
    ) {
        super();
        this.dialogRef.addPanelClass('minwidth700-dialog');
        this.initData();
        this.createForm();
    }

    ngOnInit(): void {
        combineLatest([
            this.referenceService
                .findAllCategorieDocuments()
                .pipe(map((categories) => categories.find((it) => it.nom === 'Certification'))),
            this.referenceService.findAllTypesDocument(),
        ])
            .pipe(
                takeUntil(this.ngUnsubscribe),
                map(([categorie, typesDoc]) => typesDoc.filter((it) => it.idCategorie === categorie.id))
            )
            .subscribe((types) => (this.typesDocFiltered = types));
        this.populateForm(this.competence);
    }

    /**
     * Initialise les données
     */
    initData(): void {
        if (this.data.competence) {
            this.competence = this.data.competence;
        } else {
            this.competence = new Competence();
        }
    }

    /**
     * Crée le formulaire
     */
    createForm(): void {
        this.competenceForm = this.formBuilder.group({
            nom: ['', [Validators.required]],
            organisme: [''],
            dateObtention: [''],
            dateFin: [''],
            numero: [''],
            login: [''],
            password: [''],
            justificatif: [null],
            typeDocument: [null],
        });
        this.typeDocumentControl = this.competenceForm.get('typeDocument') as FormControl;
        this.justificatifControl = this.competenceForm.get('justificatif') as FormControl;
    }

    /**
     * Rempli le formulaire avec les données de la compétence
     * @param competence
     */
    populateForm(competence: Competence): void {
        this.competenceForm.patchValue({
            nom: competence.nom ? competence.nom : '',
            organisme: competence.organisme ? competence.organisme : '',
            numero: competence.numero ? competence.numero : '',
            login: competence.login ? competence.login : '',
            password: competence.password ? competence.password : '',
            dateObtention: competence.dateObtention ? competence.dateObtention : '',
            dateFin: competence.dateFin ? competence.dateFin : '',
            justificatif: competence.competenceDocument ? competence.competenceDocument : null,
            typeDocument: competence.competenceDocument ? competence.competenceDocument.typeDocument : null,
        });

        if (competence.competenceDocument) {
            this.updateDocumentTitle(competence.competenceDocument?.typeDocument);
        }
        this.typeDocumentControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((typeDoc: TypeDocument) => {
            const oldDocumentTitle = this.documentTitle;
            this.updateDocumentTitle(typeDoc);
            if (typeDoc) {
                if (!this.competenceForm.value.nom || this.competenceForm.value.nom == oldDocumentTitle) {
                    this.competenceForm.get('nom').patchValue(this.documentTitle);
                }
            }
        });
        this.justificatifControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((justificatif) => {
            if (!justificatif) {
                this.documentTitle = '';
                this.codeTypeDocument = '';
            }
        });
    }

    private updateDocumentTitle(typeDoc: TypeDocument) {
        if (typeDoc) {
            this.codeTypeDocument = typeDoc.code;
            let name = [`${typeDoc.nom}`];
            if (this.data.nom || this.data.prenom) {
                name.push(`(${[this.data.nom, this.data.prenom].filter((it) => !!it).join(' ')})`);
            }
            this.documentTitle = name.join(' ');
        } else {
            this.codeTypeDocument = null;
            this.documentTitle = '';
        }
    }

    /**
     * Prépare les données à sauvegarder
     */
    prepareSaveEntity(): Competence {
        const competence = new Competence();
        const formValues = this.competenceForm.value;
        competence.nom = formValues.nom;
        competence.organisme = formValues.organisme;
        competence.dateObtention = formValues.dateObtention;
        competence.dateFin = formValues.dateFin;
        competence.competenceDocument = formValues.justificatif;
        competence.numero = formValues.numero;
        competence.login = formValues.login;
        competence.password = formValues.password;
        return competence;
    }

    /**
     * Action quand l'utilisateur clique sur le bouton Annuler
     */
    onClickBtnCancel(): void {
        this.dialogRef.close();
    }

    /**
     * Action pour préparer les données et envoyer le formulaire
     */
    onSubmitForm(): void {
        if (this.competenceForm.valid) {
            this.dialogRef.close(this.prepareSaveEntity());
        }
    }
}

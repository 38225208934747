"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2023 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************
import {code_to_label} from "./cn_opening_type";

export const BUILDING_TYPE_LIST = [
	{label:'Inconnu', code:'unknown'},
	{label:'Maison individuelle', code:'lodging_house'},
	{label:'Appartement', code:'lodging_flat'},
	{label:'Immeuble de logement', code:'lodging_building'},
	{label:'Immeuble de bureaux', code:'office_building'},
	{label:'Immeuble mixte', code:'mixt_building'},
	{label:'Autre', code:'other'}
];

//***********************************************************************************
//***********************************************************************************
//**** cn_building_data : All administrative data for a cn_building
//***********************************************************************************
//***********************************************************************************

export class cn_building_data {
	//***********************************************************************************
	/**
	 * Constructor
	 *
	 */ 
	constructor() {

		/** Address */
		this.address = "";
		this.city = "";
		this.zip = "";
		this.country = "";

		//*** localization */
		this.longitude=0;
		this.latitude=0;
		this.altitude=0;

		//*** typology */
		this.construction_date = 0;
		this.typology = "unknown";
		this.building_area = 0;
	}

	//***********************************************************************************
	/**
	 * Serialize method
	 * @returns {object}
	 */
	serialize() {
		var json = {};

		json.address = this.address;
		json.city = this.city;
		json.zip = this.zip;
		json.country = this.country;

		json.longitude = this.longitude;
		json.latitude = this.latitude;
		json.altitude = this.altitude;

		json.construction_date = this.construction_date;
		json.typology = this.typology;

		return json;
	}

	//***********************************************************************************
	/**
	 * Unserialize method
	 * @param {object} json
	 * @returns {cn_building_data}
	 */
	static unserialize(json) {
		var data = new cn_building_data();
		if (typeof(json.address) == 'string') data.address = json.address;
		if (typeof(json.city) == 'string') data.city = json.city;
		if (typeof(json.zip) == 'string') data.zip = json.zip;
		if (typeof(json.country) == 'string') data.country = json.country;

		if (typeof(json.longitude) == 'number') data.longitude = json.longitude;
		if (typeof(json.latitude) == 'number') data.latitude = json.latitude;
		if (typeof(json.altitude) == 'number') data.altitude = json.altitude;
		
		if (typeof(json.construction_date) == 'number') data.construction_date = json.construction_date;
		if (typeof(json.typology) == 'string' && code_to_label(json.typology,BUILDING_TYPE_LIST)) data.typology = json.typology;
		
		return data;
	}

}

<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="DATE">
        <div [id]="name" class="w-100 d-flex flex-column">
            <div class="p-2 d-flex justify-content-between">
                <div [ngClass]="required ? 'required' : ''">{{ libelle }}</div>
                <app-notes-buttons
                    *ngIf="showNotesButtons"
                    class="mr-2"
                    [from]="value"
                    [diagnostic]="diagnostic"
                    [typesCommentaires]="COMMENT_TYPES"
                >
                </app-notes-buttons>
            </div>
            <div
                [style.border-radius]="'10px'"
                [ngClass]="
                    value.valeur
                        ? isAnomalie
                            ? 'point-de-controle-non-conforme'
                            : 'point-de-controle-conforme'
                        : 'point-de-controle-non-rempli'
                "
                class="ml-5 d-flex flex-row"
            >
                <mat-form-field (click)="datepicker.open()" class="ml-5">
                    <mat-label>{{ libelle }}</mat-label>
                    <input
                        matInput
                        readonly
                        [name]="name"
                        [matDatepicker]="datepicker"
                        [disabled]="disabled"
                        [required]="required"
                        [(ngModel)]="value.valeur"
                        [ngModelOptions]="{ updateOn: 'blur' }"
                        (ngModelChange)="valueChange.emit(value)"
                    />
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker startView="multi-year"></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="PHOTO">
        <div [id]="name" [style.width]="'100%'" class="d-flex flex-column">
            <div class="p-2 d-flex justify-content-between">
                <div [ngClass]="required ? 'required' : ''">{{ libelle }}</div>
                <app-notes-buttons
                    *ngIf="showNotesButtons"
                    class="mr-2"
                    [from]="value"
                    [diagnostic]="diagnostic"
                    [typesCommentaires]="COMMENT_TYPES"
                >
                </app-notes-buttons>
            </div>
            <div id="idImageUpload" class="col-6">
                <app-wizy-file-uploader
                    #wizyFile
                    [isImage]="true"
                    [readonlyMode]="false"
                    [interventionId]="diagnostic?.idIntervention"
                    [diagnosticId]="diagnostic?.id"
                    [referenceId]="value?.idUnique"
                    [fileId]="value?.idImage"
                    [typeReferenceFichier]="typeReferenceFichierPicture"
                    [textConfig]="fileUploaderTextConfig"
                    [autoUpload]="true"
                    (fileUploaded)="onFileUploaded($event)"
                    (deleted)="onFileDeleted($event)"
                ></app-wizy-file-uploader>
                <div class="p-2 comments">
                    GPS Latitude : {{ wizyFile?.interventionFile?.gpsLatitude }} GPS Longitude :
                    {{ wizyFile?.interventionFile?.gpsLongitude }} Date :
                    {{ gateDate(wizyFile?.interventionFile?.creationDate) }}
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="LISTE_PLUS">
        <div [id]="name" [style.width]="'100%'" class="d-flex flex-column">
            <div class="p-2 d-flex justify-content-between">
                <div [ngClass]="required ? 'required' : ''">{{ libelle }}</div>
                <app-notes-buttons
                    *ngIf="showNotesButtons"
                    class="mr-2"
                    [from]="value"
                    [diagnostic]="diagnostic"
                    [typesCommentaires]="COMMENT_TYPES"
                >
                </app-notes-buttons>
            </div>
            <div class="ml-5 d-flex flex-row">
                <app-custom-select
                    [required]="required"
                    [choices]="choicesListString"
                    [(selectedValue)]="value.valeur"
                    [addLineButtonLabel]="'Ajouter'"
                    [placeholder]="libelle"
                    [matFormFieldClass]="'w-100'"
                    (valueChangeFromUser)="valueChange.emit(value)"
                >
                </app-custom-select>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="LISTE_CHECKBOX_PLUS">
        <div [id]="name" [style.width]="'100%'" class="d-flex flex-column">
            <div class="p-2 d-flex justify-content-between">
                <div [ngClass]="required ? 'required' : ''">{{ libelle }}</div>
                <app-notes-buttons
                    *ngIf="showNotesButtons"
                    class="mr-2"
                    [from]="value"
                    [diagnostic]="diagnostic"
                    [typesCommentaires]="COMMENT_TYPES"
                >
                </app-notes-buttons>
            </div>
            <div class="ml-5 d-flex flex-row">
                <app-custom-select
                    [required]="required"
                    [choices]="choicesListString"
                    [(selectedValue)]="value.valeur"
                    [addLineButtonLabel]="'Ajouter'"
                    [placeholder]="libelle"
                    [multiple]="true"
                    [matFormFieldClass]="'w-100'"
                    (valueChangeFromUser)="valueChange.emit(value)"
                >
                </app-custom-select>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="RATIO_PRECISION">
        <div [id]="name" class="d-flex flex-column w-100">
            <div class="p-2 d-flex justify-content-between">
                <div [ngClass]="required ? 'required' : ''">{{ libelle }}</div>
                <app-notes-buttons
                    *ngIf="showNotesButtons"
                    class="mr-2"
                    [from]="value"
                    [diagnostic]="diagnostic"
                    [typesCommentaires]="COMMENT_TYPES"
                >
                </app-notes-buttons>
            </div>
            <div class="ml-5 d-flex flex-row">
                <app-state-input
                    [labels]="true"
                    [(choiceValue)]="value.valeur"
                    [choices]="choices"
                    (valueChangeFromUser)="valueChange.emit(value)"
                >
                </app-state-input>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="RATIO">
        <div [id]="name" [style.width]="'100%'" class="d-flex flex-column">
            <div class="p-2 d-flex justify-content-between">
                <div [ngClass]="required ? 'required' : ''">{{ libelle }}</div>
                <app-notes-buttons
                    *ngIf="showNotesButtons"
                    class="mr-2"
                    [from]="value"
                    [diagnostic]="diagnostic"
                    [typesCommentaires]="COMMENT_TYPES"
                >
                </app-notes-buttons>
            </div>
            <div class="ml-5 d-flex flex-row">
                <mat-radio-group
                    [style.background-color]="'#f7f7f7'"
                    [style.border-radius]="'10px'"
                    [style.min-width]="'50%'"
                    [(ngModel)]="value.valeur"
                    [required]="required"
                    (ngModelChange)="valueChange.emit(value)"
                    [ngClass]="
                        value.valeur
                            ? isAnomalie
                                ? 'point-de-controle-non-conforme'
                                : 'point-de-controle-conforme'
                            : 'point-de-controle-non-rempli'
                    "
                    class="mb-2 p-2 ml-1 d-flex flex-column"
                >
                    <mat-radio-button
                        class="example-radio-button"
                        *ngFor="let choice of choices; let index = index"
                        [value]="choice.value"
                    >
                        {{ choice.label }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="CLASSIQUE">
        <mat-card
            [id]="name"
            [style.width]="100 - decalage + '%'"
            [style.margin-left]="decalage + '%'"
            class="p-1 pb-0 mb-3 d-flex flex-column justify-content-between"
            [ngClass]="conformeClass"
        >
            <mat-card-content>
                <div class="d-flex flex-row align-items-center justify-content-between mb-2">
                    <div [ngClass]="isCalculette ? 'col-7' : 'col-8'" [innerHTML]="libelle"></div>
                    <div *ngIf="isCalculette" class="col-3">
                        <div class="row align-items-center">
                            <mat-form-field appearance="outline" class="mesure">
                                <input
                                    matInput
                                    type="number"
                                    [placeholder]="MESURE_NON_COMMUNIQUE"
                                    [disabled]="disabled"
                                    [(ngModel)]="value.valeur"
                                    (click)="openNumKeyboardOrCalculator('saisie')"
                                    readonly
                                />
                            </mat-form-field>
                            <div class="button-group-mesure">
                                <button
                                    mat-icon-button
                                    color="primary"
                                    [matTooltip]="'Calculer'"
                                    [disabled]="disabled"
                                    (click)="setZero()"
                                >
                                    <mat-icon>exposure_zero</mat-icon>
                                </button>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    [matTooltip]="'Calculatrice'"
                                    [disabled]="disabled"
                                    (click)="openNumKeyboardOrCalculator('calculatrice')"
                                >
                                    <mat-icon>calculate</mat-icon>
                                </button>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    class="zone"
                                    [disabled]="disabled"
                                    (click)="openModeC()"
                                >
                                    <mat-icon>multiple_stop</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        [ngClass]="isCalculette ? 'col-2' : 'col-4'"
                        class="d-flex align-items-center justify-content-between"
                    >
                        <app-state-input
                            [choices]="choices"
                            [tooltips]="tooltips"
                            [reset]="true"
                            [disabled]="disabled || value.disabled"
                            [(choiceValue)]="value.valeur"
                            (valueChangeFromUser)="valueChange.emit(value)"
                        >
                        </app-state-input>
                        <app-notes-buttons
                            *ngIf="showNotesButtons"
                            #appNotesButton
                            class="mr-2"
                            [from]="value"
                            [diagnostic]="diagnostic"
                            [typesCommentaires]="COMMENT_TYPES"
                        >
                        </app-notes-buttons>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </ng-container>
    <ng-container *ngSwitchCase="TEXT_LIBRE">
        <div [id]="name" [style.width]="'100%'" class="d-flex flex-column">
            <div [ngClass]="required ? 'p-2 required' : 'p-2'">{{ libelle }}</div>
            <div class="p-2 flex-row">
                <mat-form-field class="ml-5">
                    <mat-label>{{ libelle }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [name]="name"
                        [(ngModel)]="value.valeur"
                        (ngModelChange)="valueChange.emit(value)"
                        [ngModelOptions]="{ updateOn: 'blur' }"
                        [disabled]="disabled"
                        [required]="required"
                    />
                </mat-form-field>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="TEXT_NUMERIQUE">
        <div [id]="name" [style.width]="'100%'" class="p-2 d-flex flex-column">
            <div class="row">
                <div [ngClass]="required ? 'col-auto mr-auto required' : 'col-auto mr-auto'">{{ libelle }}</div>
                <div *ngIf="showNonCommunique" class="col-auto">
                    <mat-checkbox
                        color="primary"
                        [disabled]="disabled"
                        [(ngModel)]="value.checked"
                        (click)="value.valeur = null"
                        >Non communiqué
                    </mat-checkbox>
                </div>
                <app-notes-buttons
                    #noteBtnd
                    *ngIf="showNotesButtons"
                    class="mr-2"
                    [from]="value"
                    [diagnostic]="diagnostic"
                    [typesCommentaires]="COMMENT_TYPES"
                >
                </app-notes-buttons>
            </div>
            <div class="ml-5 row align-items-center mesure">
                <mat-form-field appearance="outline">
                    <input
                        matInput
                        class="ml-2"
                        type="number"
                        [disabled]="disabled"
                        [(ngModel)]="value.valeur"
                        [required]="required && !value.checked"
                        (ngModelChange)="valueChange.emit(value)"
                        (click)="openNumKeyboardOrCalculator('saisie'); value.checked = false"
                        readonly
                    />
                </mat-form-field>
                <div class="button-group-mesure">
                    <button mat-icon-button color="primary" (click)="openNumKeyboardOrCalculator('calculatrice')">
                        <mat-icon>calculate</mat-icon>
                    </button>
                    <button
                        *ngIf="isCalculZone"
                        mat-icon-button
                        color="primary"
                        (click)="openDialogCalculPartieComponent()"
                    >
                        <mat-icon>list</mat-icon>
                    </button>
                </div>
                <!-- BARTH161-->
                <ng-container *ngIf="'PC430' === value.id">
                    <div class="mx-5">À contrôler</div>
                    <div style="padding: 10px 30px; background-color: #80808033; border-radius: 20px">
                        {{ valeurAControler }}
                    </div>
                </ng-container>
            </div>
            <!-- BARTH161 À contrôler -->
            <ng-container *ngIf="'PC430' === value.id">
                <div class="ml-5 my-3 row">
                    <div [ngClass]="+valeurAControlerReel !== +value.valeur ? 'error' : 'success'">
                        <lib-icon>{{ +valeurAControlerReel !== +value.valeur ? 'block' : 'check' }}</lib-icon>
                    </div>
                    <div class="ml-2">Absence d’écart avec l'état récapitulatif</div>
                </div>
                <div
                    *ngIf="value.valeur && +valeurAControlerReel !== +value.valeur && !value.commentairesId?.length"
                    class="to-justify all mr-2"
                >
                    Il manque une justification
                    <button mat-icon-button color="justify" class="ml-1">
                        <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="NUMERIQUE_ZERO">
        <div [id]="name" [style.width]="'100%'" class="p-2 d-flex flex-column">
            <div class="row">
                <div [ngClass]="required ? 'col-auto mr-auto required' : 'col-auto mr-auto'">{{ libelle }}</div>
            </div>
            <div class="ml-5 row align-items-center mesure">
                <mat-form-field appearance="outline">
                    <input
                        matInput
                        class="ml-2"
                        type="number"
                        [disabled]="disabled"
                        [(ngModel)]="value.valeur"
                        [required]="required && !value.checked"
                        (ngModelChange)="valueChange.emit(value)"
                        (click)="openNumKeyboardOrCalculator('saisie'); value.checked = false"
                        readonly
                    />
                </mat-form-field>
                <div class="button-group-mesure">
                    <button mat-icon-button color="primary" (click)="openNumKeyboardOrCalculator('calculatrice')">
                        <mat-icon>calculate</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="value.valeur = '0'">
                        <mat-icon>exposure_zero</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="TEXT_NUMERIQUE_AUTO">
        <div [id]="name" [style.width]="'100%'" class="p-2 d-flex flex-column">
            <div class="p-2 d-flex justify-content-between">
                <div [ngClass]="required ? 'required' : ''">{{ libelle }}</div>
                <app-notes-buttons
                    #noteBtn
                    class="mr-2"
                    [from]="value"
                    [diagnostic]="diagnostic"
                    [typesCommentaires]="COMMENT_TYPES"
                >
                </app-notes-buttons>
            </div>
            <div class="ml-5 row align-items-center mesure">
                <div class="d-flex mr-2" [ngClass]="valeurCible !== value.valeur ? 'error' : 'success'">
                    <lib-icon>{{ valeurCible !== value.valeur ? 'block' : 'check' }}</lib-icon>
                </div>

                <mat-form-field appearance="outline">
                    <input
                        matInput
                        class="ml-2"
                        type="number"
                        [disabled]="disabled"
                        [(ngModel)]="value.valeur"
                        [required]="required && !value.checked"
                        (ngModelChange)="valueChange.emit(value)"
                        (click)="openNumKeyboardOrCalculator('saisie'); value.checked = false"
                        readonly
                    />
                </mat-form-field>
                <div class="button-group-mesure">
                    <button mat-icon-button color="primary" (click)="openNumKeyboardOrCalculator('calculatrice')">
                        <mat-icon>calculate</mat-icon>
                    </button>
                </div>
                <button mat-raised-button color="primary" class="ml-2" (click)="value.valeur = valeurCible">
                    <mat-icon>scatter_plot</mat-icon>
                    {{ valeurCible }}
                </button>
            </div>
            <div
                *ngIf="value.valeur && valeurCible !== value.valeur && !value.commentairesId?.length"
                class="to-justify all mt-2 mr-2"
            >
                Il manque une justification
                <button mat-icon-button color="justify" class="ml-1" (click)="noteBtn.openComments($event)">
                    <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="BOUTON_DUPLIQUER">
        <div [id]="name" [style.width]="'100%'" class="d-flex flex-column">
            <div *ngIf="value.elements.length" class="col-6 processus-list">
                <app-item-card class="processus-card">
                    <div title class="title">Données dupliquées de {{ value.elements[0].valeur }}</div>
                    <div action>
                        <button mat-icon-button aria-label="modifier" (click)="onDuplicate.emit(value)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </app-item-card>
            </div>
            <button
                *ngIf="!value.elements.length"
                class="m-4 ml-5 col-4"
                mat-stroked-button
                color="accent"
                (click)="onDuplicate.emit(value)"
            >
                <lib-icon>file_copy</lib-icon>
                Dupliquer les données
            </button>
        </div>
    </ng-container>
</ng-container>

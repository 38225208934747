"use strict";
//***********************************************************************************
//***********************************************************************************
import {cn_pastille} from "./cn_pastille";
import {cn_add, cn_cart, cn_clone, cn_dist, cn_middle, cn_polar, cn_sub} from "../utils/cn_utilities";
import {cn_space} from "../model/cn_space";
import {cn_scene} from "../model/cn_scene";
import {cn_scene_controller} from "./cn_scene_controller";
import {cn_roof_controller} from "./cn_roof_controller";
import {cn_event_handler} from "./cn_event_handler";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_camera} from "./cn_camera";
import {cn_number_input} from "./cn_inputs";
import {cn_snap} from "./cn_snap";

/**
 * A handler to manipulate a round element with 2 angles (used for round stairs)
 * @class cn_disk_handler
 */
export class cn_disk_handler extends cn_event_handler {
	//*****************************************************************
	/**
	 * Constructor
	 * @param {cn_scene} scene
	 * @param {cn_scene_controller | cn_roof_controller} controller
	 * @param {number[] | null} center - center of the round shape. If null, we are in creation.
	 * @param {number} radius - radius of the object. If creation, ignored, will be set by second creating step.
	 * @param {number[]} angles - List of 2 angles, in radians. On creation, ignored.
	 * @param {cn_event_handler} parent .
	 */
	constructor(scene, controller, center=null, radius=0, angles=[0,Math.PI], parent=null) {
		super(parent);
		this.center = center;
		this.radius = radius;
		this.angles = angles;

		this.snap_elements = [];

		this.accept_outside = true;
		this.space = null;
		this._scene = scene;
		this._controller = controller;

		this._creation = (this.center == null);
		if (this._creation)
		{
			this.vertices = [[0,0]];
		}

		this._mousedown = [0,0];
		this._selected_vertex = -1;
		this._mouseover_vertex = -1;

		this._center = new cn_pastille([0, 0], "arrow_all_white.svg");

		this._snap_svg = "";

		this._diameter = [0,0];
		this._diameter_mouseover = false;

		this.update();
		this.on_change = null;
		this.on_delete = null;
		this._delete = null;

		this._grab = false;

        this.check_change = null;
		this._orthogonal_direction = null;
	}

	//*****************************************************************
	/**
	 * @returns {boolean} true if the handler is in the creation process
	 */
	is_creating() {
		if (!this._creation) return false;
		return (this.vertices.length >= 2);
	}

	/**
	 * Draws in svg
	 * @param {cn_camera} camera
	 * @returns
	 */
	draw(camera) {
		var html = "";
		if (this._creation && this.space == null) return html;

		var add_classes = "";


		if (this._creation && this.vertices.length == 1)
		{
			//*** Draw vertices
			for (var i=0; i<this.vertices.length;i++)
			{
				add_classes = "";
				if (this._selected_vertex == i)
					add_classes = " selected";
				else if (this._mouseover_vertex == i)
					add_classes = " mouseover";
				var p = camera.world_to_screen(this.vertices[i]);
				html += "<circle class='handle_vertex" + add_classes + "' cx='" + p[0] + "' cy='" + p[1] + "' r='5'/>";
			}
		}
		else
		{
			//*** Draw diameter */
			const d0 = cn_add(this.center,cn_add(cn_cart([this.radius,this.angles[0]]),cn_cart([this.radius,this.angles[0] + Math.PI/2])));
			const d1 = cn_add(this.center,cn_add(cn_cart([this.radius,this.angles[0] + Math.PI]),cn_cart([this.radius,this.angles[0] + Math.PI/2])));
			html += camera.draw_measure(d0,d1,this._diameter,this._diameter_mouseover,true,[],cn_dist(d0,d1).toFixed(2));

			//*** Draw move pastille */
			this._center.position = cn_clone(this.center);
			html += this._center.draw(camera);

			//*** draw disk circle */
			add_classes = "";
			if (this._selected_vertex == 2)
				add_classes = " selected";
			else if (this._mouseover_vertex == 2)
				add_classes = " mouseover";
			var p = camera.world_to_screen(this.center);
			html += "<circle class='disk_handle_circle" + add_classes + "' cx='" + p[0] + "' cy='" + p[1] + "' r='" + (this.radius * camera.world_to_screen_scale) + "'/>";

			//*** draw angles */
			for (var i=0;i<2;i++)
			{
				var a = cn_add(this.center,cn_cart([this.radius,this.angles[i]]));
				var pa = camera.world_to_screen(a);
				add_classes = "";
				if (this._selected_vertex == i)
					add_classes = " selected";
				else if (this._mouseover_vertex == i)
					add_classes = " mouseover";

				html += "<line class='disk_handle_line" + add_classes + "' x1='" + p[0] + "' y1='" + p[1] + "' x2='" + pa[0] + "' y2='" + pa[1] + "' />";

				html += "<circle class='handle_vertex" + add_classes + "' cx='" + pa[0] + "' cy='" + pa[1] + "' r='5'/>";
			}

		}

		html += this._snap_svg;
		return html;
	}

	/**
	 * Clear move effects
	 */
	clear_move() {
		this._mouseover_vertex = -1;

		this._center.mouseover = false;

		this._diameter_mouseover = false;

		if (this._creation && this.vertices.length < 2)
			this.space = null;
	}

	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	move(mouse_event) {
		this.clear_move();
		this.find_space(mouse_event.mouse_world);

		//*** Creation mode
		if (this._creation)
		{
			if (this.space == null) return false;

			this._mouseover_vertex = this.vertices.length-1;
			this._selected_vertex = this.vertices.length-1;
			return this.drag(mouse_event);
		}

		this._center.mouseover = this._center.contains(mouse_event.mouse_world,mouse_event.camera);
		if (this._center.mouseover) return true;

		//*** Check if mouse is over a vertex
		for (var i=0; i<2;i++)
		{
			var a = cn_add(this.center,cn_cart([this.radius,this.angles[i]]));
			if (!mouse_event.camera.on_vertex(mouse_event.mouse_world,a))
				continue;
			this._mouseover_vertex = i;
			return true;
		}

		//*** Check if mouse is over the circle */
		var dst = cn_dist(this.center, mouse_event.mouse_world)
		if (Math.abs(dst - this.radius) < mouse_event.camera.screen_to_world_scale * 10)
		{
			this._mouseover_vertex = 2;
		}
		if (this._mouseover_vertex >= 0) return true;

		//*** Check if mouse is over the measure */
		if (Math.abs(this._diameter[0] - mouse_event.mouse_screen[0]) <= 30 && Math.abs(this._diameter[1] - mouse_event.mouse_screen[1]) <= 10)
		{
			this._diameter_mouseover = true;
			return true;
		}

		return false;
	}

	/**
	 * Manage a grab. To return 'true' if grab is to be managed.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	grab(mouse_event) {
		this.find_space(mouse_event.mouse_world);
		if (this._creation && this.space == null) return false;

		this._grab = true;

		//*** Nothing to do in creation
		if (this._creation)
		{
			return true;
		}

		this.move(mouse_event);

		this._mousedown = cn_clone(mouse_event.mouse_world);

		//*** maybe we move the shape ?
		if (this._center.mouseover)
		{
			this._selected_vertex = -1;
			return true;
		}

		this._selected_vertex = this._mouseover_vertex;

		return (this._selected_vertex >= 0);
	}

	/**
	 * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	drop(mouse_event) {
		this.find_space(mouse_event.mouse_world);
		if (this._creation && this.space == null) return false;
		this._snap_svg = "";
		if (!this._grab) return false;
		this._grab = false;

		if (!this._creation) return false;

		if (this.vertices.length == 2 && cn_dist(mouse_event.mouse_world,this.vertices[0]) < 0.5)
			return false;

		this.update();

		//*** continue creation ?  */
		if (this.vertices.length < 3)
		{
			this.vertices.push(cn_clone(mouse_event.mouse_world));
			this.update();
			return true;
		}

		//*** is last point on a given vertex ?
		this._creation = false;
		this.update();
		return true;
	}

	/**
	 * Manage a click.
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	click(mouse_event) {
		if (this._diameter_mouseover)
		{
			const obj = this;
			const input = new cn_number_input("Nouveau diamètre",this.radius * 2,"m",2, 0.1);
			input.callback = function() {
				const old_radius = obj.radius;
				obj.radius = Math.abs(input.value/2);
				if (obj.check_change && !obj.check_change())
					obj.radius = old_radius;
				else
					obj.update();
			}
			this.call("number_input",input);
		}
		return this.drop(mouse_event);
	}

	//*****************************************************************
	//*** Update creation
	//*****************************************************************
	_update_creation() {
	}

	/**
	 * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	drag(mouse_event) {
		this.find_space(mouse_event.mouse_world);
		if (this._creation && this.space == null) return false;

		var obj = this;
		function snap_angles(index)
		{
			var delta = obj.angles[index] - obj.angles[1-index];
			var n = Math.round(delta / (0.5 * Math.PI));
			if (n == 0) return;
			var target = obj.angles[1-index] + n*0.5 * Math.PI;
			if (Math.abs(target - obj.angles[index]) < Math.PI * 5 / 180)
				obj.angles[index] = target;
		}

		//*** in creation mode, just move vertices
		if (this._creation)
		{
			if (this.vertices.length == 2 && cn_dist(mouse_event.mouse_world,this.vertices[0]) < 0.5)
				return false;
			if (this.vertices.length < 3)
			{
				var old_pos = cn_clone(this.vertices[this.vertices.length-1] );

				const snap = new cn_snap(mouse_event.mouse_world,mouse_event.camera.snap_world_distance,(this.vertices.length>1)?this.vertices[0]:null,mouse_event.camera);
				snap.snap_elements = this.snap_elements;
				if (this.vertices.length >1 && this._orthogonal_direction)
				{
					snap.check_orthogonal(this.vertices[0],cn_add(this.vertices[0],this._orthogonal_direction));
				}
				else
				{
					snap.snap_point();
					if (this.vertices.length == 1)
						this._orthogonal_direction = (snap.freedoms == 1)?snap.direction:null;
				}

				this.vertices[this.vertices.length-1] = snap.position;
				this.update();
				if (this.check_change && !this.check_change())
				{
					this.vertices[this.vertices.length-1] = old_pos;
					this.update();
				}
			}
			else
			{
				var old_angle = this.angles[1];
				var ppol = cn_polar(cn_sub(mouse_event.mouse_world, this.center));
				var alpha = ppol[1];
				while (alpha - old_angle > Math.PI) alpha -= 2 * Math.PI;
				while (alpha - old_angle < -Math.PI) alpha += 2 * Math.PI;
				this.angles[1] = alpha;
				snap_angles(1);
				if (this.check_change && !this.check_change())
				{
					this.angles[1] = old_angle;
				}
			}
			return true;
		}

		//*** Move the shape
		if (this._center.mouseover)
		{
			this._mousedown = mouse_event.mouse_world;

			const snap = new cn_snap(mouse_event.mouse_world,mouse_event.camera.snap_world_distance,null,mouse_event.camera);
			snap.snap_elements = this.snap_elements;
			for (var niter=0;niter<2;niter++)
			{
				const d = cn_cart([this.radius,this.angles[niter]]);
				var p1 = cn_add(snap.position,d);
				snap.position = p1;
				snap.start_position = cn_clone(p1);
				snap.snap_point();
				p1 = cn_sub(snap.position,d);
				snap.position = p1;
				snap.start_position = cn_clone(p1);
			}

			var old_center = cn_clone(this.center);
			this.center = snap.position;
			if (this.check_change && !this.check_change())
				this.center = old_center;
			else
				this.update();

			return true;
		}

		if (this._selected_vertex < 0) return false;

		//*** change radius
		if (this._selected_vertex == 2)
		{
			var old_radius = this.radius;
			this.radius = cn_dist(this.center, mouse_event.mouse_world);
			if (this.check_change && !this.check_change())
				this.radius = old_radius;
			else
				this.update();

			return true;

		}

		var old_angle = this.angles[this._selected_vertex];
		var alpha = cn_polar(cn_sub(mouse_event.mouse_world, this.center))[1];
		while (alpha - old_angle > Math.PI) alpha -= 2 * Math.PI;
		while (alpha - old_angle < -Math.PI) alpha += 2 * Math.PI;
		this.angles[this._selected_vertex] = alpha;
		snap_angles(this._selected_vertex);

		if (this.check_change && !this.check_change())
			this.angles[this._selected_vertex] = old_angle;
		else
			this.update();

		return true;
	}

	//*****************************************************************
	//*** Update position
	//*****************************************************************
	update() {
		if (this._creation)
		{
			if (this.vertices.length != 2) return;
			this.center = cn_middle(this.vertices[0],this.vertices[1]);
			var pol = cn_polar(cn_sub(this.vertices[0],this.vertices[1]));
			this.radius = pol[0]/2;
			this.angles[0] = pol[1];
			this.angles[1] = pol[1] + Math.PI;
			if (this.vertices.length < 3) return;
			pol = cn_polar(cn_sub(this.vertices[2],this.vertices[1]));
			this.angles[1] = pol[1];
			while (this.angles[1] - this.angles[0] > Math.PI) this.angles[1] -= 2*Math.PI;
			while (this.angles[1] - this.angles[0] < -Math.PI) this.angles[1] += 2*Math.PI;
			return;
		}
		if (this.on_change) this.on_change();
	}

	//*****************************************************************
	//*** find space
	//*****************************************************************
	find_space(mouse_world_position) {
		if (!this._creation) return;
		if (this.vertices.length > 1) return;

		//** expect to be inside a inner space
		this.space = this._scene.find_space(mouse_world_position);
		if (this.space && (!this.accept_outside && this.space.outside))
			this.space = null;

		//*** expect to have nothing below
		var elt = this._controller.find_element(mouse_world_position);
		if (elt && elt.constructor != cn_space)
			this.space = null;
	}
}


import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { HttpErrorService } from 'src/app/commons-lib';
import { CommentairePredefini } from '../model/commentaire-predefini.model';
import { CommentairePredefiniApiService } from './commentaire-predefini-api.service';

@Injectable({
    providedIn: 'root',
})
export class CommentairePredefiniService {
    constructor(
        private readonly commentairePredefiniApiService: CommentairePredefiniApiService,
        private readonly httpErrorService: HttpErrorService
    ) {}

    /**
     * Renvoie une categorie de commentaire prédéfini
     */
    findOne(id: string): Observable<CommentairePredefini> {
        return this.commentairePredefiniApiService.findOne(id).pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Renvoie la liste complète de commentaires prédéfinis
     */
    findAll(): Observable<CommentairePredefini[]> {
        return this.commentairePredefiniApiService.findAll().pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    /**
     * Crée ou modifie un commentaire prédéfini
     * @param categorie
     */
    upsert(categorie: CommentairePredefini): Observable<CommentairePredefini> {
        return this.commentairePredefiniApiService.upsert(categorie).pipe(
            catchError((err) => throwError(err)),
            catchError((err) => this.httpErrorService.handleError(err))
        );
    }

    buildCommentairesPredefinis$() {
        return this.findAll().pipe(shareReplay(1));
    }
}

import { Injectable } from '@angular/core';
import { Diagnostic } from '../model/diagnostic.model';
import { Bien, Niveau, Volume } from '../model/bien.model';
import { PointDeControleNiveau } from '../model/point-de-controle.model';
import { cn_building } from '@acenv/cnmap-editor';
import { PARAM_AU_SOL } from '../modules/diagnostics/mesurage/shared/mesurage.constants';

@Injectable({
    providedIn: 'root',
})
export class PointDeControleService {
    constructor() {}

    /**
     * Fusionne tous les points de controles d'un volume dans le niveau de destination
     * @param diagnostic
     * @param volumeSource
     * @param niveauDestination
     * @param currentBien
     */
    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        const pointDeControleBien = diagnostic.pointsDeControleBiens.find((bien) => bien.idBien === currentBien.id);
        const pointDeControleNiveauDest = pointDeControleBien.pointsDeControleNiveaux.find(
            (niveau) => niveau.idNiveau === niveauDestination.id
        );
        let pointDeControleNiveauSource: PointDeControleNiveau;
        pointDeControleBien.pointsDeControleNiveaux.forEach((niveau) => {
            if (niveau.pointsDeControleVolumes.find((volume) => volume.idVolume === volumeSource.id)) {
                pointDeControleNiveauSource = niveau;
            }
        });
        if (pointDeControleNiveauSource) {
            const pointDeControleVolumeSource = pointDeControleNiveauSource.pointsDeControleVolumes.find(
                (volume) => volume.idVolume === volumeSource.id
            );
            pointDeControleNiveauDest.pointsDeControleVolumes.push(pointDeControleVolumeSource);
            pointDeControleNiveauSource.pointsDeControleVolumes =
                pointDeControleNiveauSource.pointsDeControleVolumes.filter(
                    (volumeToDelete) => !(volumeToDelete.idVolume === volumeSource.id)
                );
        }
    }

    /**
     * Fusionne tous les points de controles d'un niveau dans le niveau de destination
     * @param diagnostic
     * @param niveauSource
     * @param niveauDestination
     * @param currentBien
     */
    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        const pointDeControleBien = diagnostic.pointsDeControleBiens.find((bien) => bien.idBien === currentBien.id);
        const pointDeControleNiveauDest = pointDeControleBien.pointsDeControleNiveaux.find(
            (niveau) => niveau.idNiveau === niveauDestination.id
        );
        const pointDeControleNiveauSource = pointDeControleBien.pointsDeControleNiveaux.find(
            (niveau) => niveau.idNiveau === niveauSource.id
        );
        if (pointDeControleNiveauSource) {
            // On déplace chaque PDCvolume dans le PDCniveau de destination
            pointDeControleNiveauSource.pointsDeControleVolumes.forEach((volume) => {
                if (!volume.volumeCache) {
                    pointDeControleNiveauDest.pointsDeControleVolumes.push(volume);
                }
            });
            niveauSource.volumes = niveauSource.volumes.filter((vol) => vol.volumeCache);
            pointDeControleBien.pointsDeControleNiveaux = pointDeControleBien.pointsDeControleNiveaux.filter(
                (niveau) => niveau.idNiveau !== pointDeControleNiveauSource.idNiveau
            );
        }
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        const allVolumes = diagnostic.pointsDeControleBiens
            .find((it) => it.idBien === bien?.id)
            ?.pointsDeControleNiveaux.flatMap((pdcNiveau) => pdcNiveau.pointsDeControleVolumes);
        const currentVolume = allVolumes?.find((it) =>
            it.pointsDeControleElements.some((el) => el.idElement === idEquipement)
        );
        const newVolume = allVolumes?.find((it) => it.idVolume === volumeDestination.id);
        if (currentVolume && newVolume) {
            const idxElement = currentVolume.pointsDeControleElements.findIndex((it) => it.idElement === idEquipement);
            const elements = currentVolume.pointsDeControleElements.splice(idxElement, 1);
            newVolume.pointsDeControleElements.push(...elements);
        }
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien) {
        const pointDeControleBien = diagnostic.pointsDeControleBiens.find((bien) => bien.idBien === currentBien.id);
        // Récupération du niveau de destination à merger
        const pointDeControleNiveauDest = pointDeControleBien.pointsDeControleNiveaux.find((niveau) =>
            niveau.pointsDeControleVolumes.find((it) => it.idVolume === volumeDestination.id)
        );
        let pointDeControleVolumeDest = null;

        if (pointDeControleNiveauDest) {
            // Récupération du niveau source à merger
            let pointDeControleNiveauSource;
            pointDeControleBien.pointsDeControleNiveaux.forEach((niveau) => {
                if (niveau.pointsDeControleVolumes.filter((volume) => volume.idVolume == volumeSource.id).length > 0) {
                    pointDeControleNiveauSource = niveau;
                }
            });

            // Récupération du volume de destination
            pointDeControleVolumeDest = pointDeControleNiveauDest.pointsDeControleVolumes.find(
                (volume) => volume.idVolume === volumeDestination.id
            );

            pointDeControleVolumeDest.nom = volumeSource.nom;

            if (pointDeControleNiveauSource) {
                // Récupération du volume source
                const pointDeControleVolumeSource = pointDeControleNiveauSource.pointsDeControleVolumes.find(
                    (volume) => volume.idVolume === volumeSource.id
                );

                // Mise à jour du volume : Fusion des pointsDeControleElements des deux volumes
                pointDeControleVolumeDest.pointsDeControleElements =
                    pointDeControleVolumeDest.pointsDeControleElements.concat(
                        pointDeControleVolumeSource.pointsDeControleElements
                    );

                // Mise à jour du volume
                pointDeControleVolumeDest.valeursParametres = Object.assign(
                    pointDeControleVolumeDest.valeursParametres,
                    pointDeControleVolumeSource.valeursParametres
                );

                // Suppression du volume source dans le niveau
                pointDeControleNiveauSource.pointsDeControleVolumes =
                    pointDeControleNiveauSource.pointsDeControleVolumes.filter(
                        (volume) => !(volume.idVolume == volumeSource.id)
                    );
            }
        }

        const building = cn_building.unserialize(JSON.parse(currentBien.jsonPlan));

        const storeyId = pointDeControleNiveauDest?.storeyId
            ? pointDeControleNiveauDest.storeyId
            : currentBien.description.find((stair) => stair.volumes.find((vol) => vol.id === volumeDestination.id))
                  .storeyId;

        const storey = building.find_storey(storeyId);
        const cnVolume = storey.scene.get_space(volumeDestination.spaceId);
        cnVolume.space_usage = volumeDestination.usageId;
        cnVolume.name = volumeDestination.nom;

        if (pointDeControleVolumeDest) {
            cnVolume.declared_area = parseInt(pointDeControleVolumeDest.valeursParametres[PARAM_AU_SOL]);
        }

        currentBien.jsonPlan = JSON.stringify(building.serialize());
    }
}

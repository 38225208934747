import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalStateInputComponent } from './global-state-input/global-state-input.component';
import { StateInputComponent } from './state-input/state-input.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { NotesButtonsComponent } from './notes-buttons/notes-buttons.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { LibFileUploaderModule, LibIconModule, LibIncrementInputModule, LibPipesModule } from 'src/app/commons-lib';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PipesModule } from '../../pipes/pipes.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {
    EnerbimEditorPipesModule,
    MapEditorModule,
    DefaultObjectsHandlerStatic,
    DefaultZonesHandlerStatic,
} from '@acenv/cnmap-angular-editor-lib';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { SectionTitleComponent } from './section-title/section-title.component';
import { EditDocumentModalComponent } from './documents-gestion/edit-document-modal/edit-document-modal.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import { QuitWithoutSavingModalComponent } from './quit-without-saving-dialog/quit-without-saving-modal.component';
import { IncrementInputReactiveComponent } from './increment-input-reactive/increment-input-reactive.component';
import { MatErrorComponent } from './mat-error/mat-error.component';
import { StateInputReactiveComponent } from './state-input-reactive/state-input-reactive.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormErrorComponent } from './form-error/form-error.component';
import { ElementsListModalComponent } from './comment-modal/elements-list-modal/elements-list-modal.component';
import { MatChipsModule } from '@angular/material/chips';
import { ProgressDiagnosticComponent } from './progress-diagnostic/progress-diagnostic.component';
import { ViewerMapComponent } from './map/viewer-map/viewer-map.component';
import { CnSpinnerComponent } from './cn-spinner/cn-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditBienModalComponent } from './edit-bien/edit-bien-modal/edit-bien-modal.component';
import { EditBienComponent } from './edit-bien/edit-bien.component';
import { EditContactComponent } from './edit-contact/edit-contact.component';
import { EditContactModalComponent } from './edit-contact/edit-contact-modal/edit-contact-modal.component';
import { RapportDownloadModalComponent } from './rapport-download-modal/rapport-download-modal.component';
import { CustomSelectIntoModalComponent } from './custom-select-into-modal/custom-select-into-modal.component';
import { MpcaPanelListCardComponent } from './mpca-panel-list-card/mpca-panel-list-card';
import { ItemCardComponent } from './mpca-card/item-card.component';
import { DetailProcessusCardComponent } from './detail-processus-card/detail-processus-card.component';
import { OperateurCheckboxComponent } from './operateur-checkbox/operateur-checkbox.component';
import { ExpensionPanelComponent } from './expension-panel/expension-panel.component';
import { VolumeBlockComponent } from './volume-block/volume-block.component';
import { WidgetButtonComponent } from './widget-button/widget-button.component';
import { PointControleBienPanelComponent } from './point-controle-bien-panel/point-controle-bien-panel.component';
import { WidgetSelectComponent } from './widget-select/widget-select.component';
import { VueSwitchToggleComponent } from './vue-switch-toggle/vue-switch-toggle.component';
import { NextPreviousButtonComponent } from './next-previous-button/next-previous-button.component';
import { PointControleVolumeItemComponent } from './point-controle-volume-item/point-controle-volume-item.component';
import { WidgetBadgeSelectComponent } from './widget-badge-select/widget-badge-select.component';
import { BadgeSelectComponent } from './badge-select/badge-select.component';
import { BienNiveauSelectComponent } from './bien-niveau-select/bien-niveau-select.component';
import { ZoneSurfacePanelComponent } from './zone-surface-panel/zone-surface-panel.component';
import { PieceUnitaireBesoinComponent } from '../diagnostics/polluant/shared/piece-unitaire-besoin/piece-unitaire-besoin.component';
import { EditorHandler } from './map/editor-map/handler/editor-handler';
import { ExportSvgComponent } from './map/export-svg/export-svg.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { ExportPrintParamComponent } from './map/export-print-param/export-print-param.component';
import { ExportPrintSvgParamComponent } from './map/export-print-param/export-print-svg-param/export-print-svg-param.component';
import { NgxResizeObserverModule } from 'ngx-resize-observer';
import { PlansListComponent } from './plans-list/plans-list.component';
import { DisplayElementModalComponent } from './display-element-modal/display-element-modal.component';
import { ExportSvgDialogModule } from './map/export-svg-dialog/export-svg-dialog.module';
import { OngletAdministrationComponent } from './onglet-administration/onglet-administration.component';
import { VignetteProfilComponent } from './vignette-profil/vignette-profil.component';
import { CardClickableComponent } from './card-clickable/card-clickable.component';
import { MateriauModalComponent } from './materiau-modal/materiau-modal.component';
import { OuvrageModalComponent } from './ouvrage-modal/ouvrage-modal.component';
import { PictureFileUploaderComponent } from './file-uploader/picture-file-uploader/picture-file-uploader.component';
import { ViewerMapHapComponent } from './map/viewer-map-hap/viewer-map-hap.component';
import { FormControlErrorMessageComponent } from './form-control-error-message/form-control-error-message.component';
import { BesoinsPuPanelComponent } from './besoins-pu-panel/besoins-pu-panel.component';
import { DocumentsGestionComponent } from './documents-gestion/documents-gestion.component';
import { DocumentsDetailComponent } from './documents-gestion/documents-detail/documents-detail/documents-detail.component';
import { DocumentsListComponent } from './documents-gestion/documents-list/documents-list.component';
import { CardValidationComponent } from './card-validation/card-validation.component';
import { EditFichierComponent } from './documents-gestion/edit-fichier/edit-fichier.component';
import { EditFichierModalComponent } from './documents-gestion/edit-fichier-modal/edit-fichier-modal.component';
import { DocumentsDetailAttachmentComponent } from './documents-gestion/documents-detail/documents-detail-attachment/documents-detail-attachment.component';
import { DocumentsDetailAttachmentHistoryComponent } from './documents-gestion/documents-detail/documents-detail-attachment-history/documents-detail-attachment-history.component';
import { HeaderEditorPanelComponent } from './header-editor-panel/header-editor-panel.component';
import { DocumentsPrestationChipsComponent } from './documents-gestion/shared/documents-prestation-chips/documents-prestation-chips.component';
import { MatListModule } from '@angular/material/list';
import { WizyFileUploaderComponent } from './file-uploader/wizy-file-uploader.component';
import { DocumentFileUploaderComponent } from './file-uploader/document-file-uploader/document-file-uploader.component';
import { DocumentsDetailCheckpointComponent } from './documents-gestion/documents-detail/documents-detail-checkpoint/documents-detail-checkpoint.component';
import { DocumentsDetailCheckpointListComponent } from './documents-gestion/documents-detail/documents-detail-checkpoint-list/documents-detail-checkpoint-list.component';
import { CommentLightModalComponent } from './comment-light-modal/comment-light-modal.component';
import { InputTextareaModalComponent } from './input-textarea-modal/input-textarea-modal.component';
import { ListChipsClickableComponent } from './list-chips-clickable/list-chips-clickable.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DrawingPictureHandler } from './map/editor-map/handler/drawing-picture.handler';
import { WidgetSelectMultiComponent } from './widget-select-multi/widget-select-multi.component';
import { ImportFileComponent } from './import-file/import-file.component';
import { MatTableModule } from '@angular/material/table';
import { SketchPresetHandler } from './map/editor-map/handler/sketch-preset-handler.service';
import { MatSortModule } from '@angular/material/sort';
import { TableauCompetencesComponent } from './tableau-competences/tableau-competences.component';
import { SpacesHandler } from './map/editor-map/handler/spaces-handler';
import { SpacesObjectsHandler } from './map/editor-map/handler/spaces-objects-handler';
import { LibraryHandler } from './map/editor-map/handler/library-handler';
import { ViewerMapMesureComponent } from './map/viewer-map-mesure/viewer-map-mesure.component';
import { PerimetreMapComponent } from './map/perimetre-map/perimetre-map.component';
import { ListePerimetreComponent } from './map/perimetre-map/liste-perimetre/liste-perimetre.component';
import { PerimetreEditPanelComponent } from './map/perimetre-map/perimetre-edit-panel/perimetre-edit-panel.component';
import { LegendePerimetreComponent } from './map/perimetre-map/legende-perimetre/legende-perimetre.component';
import { WidgetHorsLigneComponent } from './widget-hors-ligne/widget-hors-ligne.component';
import { BonCommandeViewEchantillonsTableComponent } from './bon-commande-view-echantillons-table/bon-commande-view-echantillons-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SelectExistingContactModalComponent } from './edit-contact/select-existing-contact-modal/select-existing-contact-modal.component';
import { ObjectsHandler } from './map/editor-map/handler/objects-handler';
import { PerimetreInvestigationModalComponent } from './map/perimetre-map/perimetre-investigation-modal/perimetre-investigation-modal.component';
import { PlanLocalisationModal } from './map/perimetre-map/plan-localisation-modal/plan-localisation-modal.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        RouterModule,
        FormsModule,
        MatSelectFilterModule,
        MatInputModule,
        MatTooltipModule,
        MatDialogModule,
        LibPipesModule,
        MatCardModule,
        LibIncrementInputModule,
        MatCheckboxModule,
        PipesModule,
        DragDropModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatSidenavModule,
        MatButtonToggleModule,
        MapEditorModule,
        LibFileUploaderModule,
        MatProgressBarModule,
        MatBadgeModule,
        MatDatepickerModule,
        MatRadioModule,
        LibIconModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatDividerModule,
        EnerbimEditorPipesModule,
        NgxResizeObserverModule,
        ExportSvgDialogModule,
        MatSidenavModule,
        MatListModule,
        MatAutocompleteModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
    ],
    declarations: [
        CommentModalComponent,
        CustomSelectComponent,
        DocumentsListComponent,
        EditDocumentModalComponent,
        DisplayElementModalComponent,
        GlobalStateInputComponent,
        HelpModalComponent,
        NotesButtonsComponent,
        EditContactModalComponent,
        EditContactComponent,
        QuitWithoutSavingModalComponent,
        SectionTitleComponent,
        StateInputComponent,
        IncrementInputReactiveComponent,
        MatErrorComponent,
        StateInputReactiveComponent,
        FormErrorComponent,
        ElementsListModalComponent,
        ProgressDiagnosticComponent,
        ViewerMapComponent,
        CnSpinnerComponent,
        EditBienModalComponent,
        EditBienComponent,
        CustomSelectIntoModalComponent,
        RapportDownloadModalComponent,
        MpcaPanelListCardComponent,
        ItemCardComponent,
        DetailProcessusCardComponent,
        OperateurCheckboxComponent,
        ExpensionPanelComponent,
        VolumeBlockComponent,
        WidgetButtonComponent,
        PointControleBienPanelComponent,
        WidgetSelectComponent,
        WidgetSelectMultiComponent,
        VueSwitchToggleComponent,
        NextPreviousButtonComponent,
        PointControleVolumeItemComponent,
        WidgetBadgeSelectComponent,
        BadgeSelectComponent,
        ZoneSurfacePanelComponent,
        PieceUnitaireBesoinComponent,
        ExportSvgComponent,
        ExportPrintParamComponent,
        ExportPrintSvgParamComponent,
        BienNiveauSelectComponent,
        PlansListComponent,
        OngletAdministrationComponent,
        VignetteProfilComponent,
        CardClickableComponent,
        MateriauModalComponent,
        OuvrageModalComponent,
        PictureFileUploaderComponent,
        ViewerMapHapComponent,
        FormControlErrorMessageComponent,
        BesoinsPuPanelComponent,
        DocumentsGestionComponent,
        DocumentsDetailComponent,
        CardValidationComponent,
        EditFichierComponent,
        EditFichierModalComponent,
        DocumentsDetailAttachmentComponent,
        DocumentsDetailAttachmentHistoryComponent,
        HeaderEditorPanelComponent,
        DocumentsPrestationChipsComponent,
        WizyFileUploaderComponent,
        DocumentFileUploaderComponent,
        DocumentsDetailCheckpointComponent,
        DocumentsDetailCheckpointListComponent,
        CommentLightModalComponent,
        InputTextareaModalComponent,
        ListChipsClickableComponent,
        ImportFileComponent,
        TableauCompetencesComponent,
        ViewerMapMesureComponent,
        PerimetreMapComponent,
        PlanLocalisationModal,
        PerimetreInvestigationModalComponent,
        ListePerimetreComponent,
        PerimetreEditPanelComponent,
        LegendePerimetreComponent,
        WidgetHorsLigneComponent,
        BonCommandeViewEchantillonsTableComponent,
        SelectExistingContactModalComponent,
    ],
    entryComponents: [
        CommentModalComponent,
        ElementsListModalComponent,
        EditContactModalComponent,
        EditDocumentModalComponent,
        DisplayElementModalComponent,
        HelpModalComponent,
        QuitWithoutSavingModalComponent,
        EditBienModalComponent,
        EditContactModalComponent,
        CustomSelectIntoModalComponent,
        RapportDownloadModalComponent,
        PointControleBienPanelComponent,
        ExportSvgComponent,
        CardValidationComponent,
        HeaderEditorPanelComponent,
        EditFichierModalComponent,
        DocumentsDetailCheckpointComponent,
        CommentLightModalComponent,
        InputTextareaModalComponent,
        ListChipsClickableComponent,
        PlanLocalisationModal,
        PerimetreInvestigationModalComponent,
        SelectExistingContactModalComponent,
    ],
    exports: [
        CommentModalComponent,
        ElementsListModalComponent,
        CustomSelectComponent,
        EditContactComponent,
        EditDocumentModalComponent,
        DisplayElementModalComponent,
        GlobalStateInputComponent,
        HelpModalComponent,
        NotesButtonsComponent,
        SectionTitleComponent,
        StateInputComponent,
        IncrementInputReactiveComponent,
        MatErrorComponent,
        StateInputReactiveComponent,
        FormErrorComponent,
        ProgressDiagnosticComponent,
        ViewerMapComponent,
        CnSpinnerComponent,
        EditBienComponent,
        MpcaPanelListCardComponent,
        DetailProcessusCardComponent,
        ItemCardComponent,
        WidgetSelectComponent,
        WidgetSelectMultiComponent,
        ExpensionPanelComponent,
        VolumeBlockComponent,
        ZoneSurfacePanelComponent,
        BesoinsPuPanelComponent,
        PointControleBienPanelComponent,
        NextPreviousButtonComponent,
        WidgetButtonComponent,
        ExportSvgComponent,
        BienNiveauSelectComponent,
        PlansListComponent,
        OngletAdministrationComponent,
        VignetteProfilComponent,
        CardClickableComponent,
        PictureFileUploaderComponent,
        ViewerMapHapComponent,
        FormControlErrorMessageComponent,
        CardValidationComponent,
        DocumentsGestionComponent,
        HeaderEditorPanelComponent,
        WizyFileUploaderComponent,
        EditFichierModalComponent,
        DocumentsDetailCheckpointComponent,
        CommentLightModalComponent,
        InputTextareaModalComponent,
        ListChipsClickableComponent,
        ImportFileComponent,
        DocumentsListComponent,
        TableauCompetencesComponent,
        ViewerMapMesureComponent,
        WidgetHorsLigneComponent,
        PerimetreMapComponent,
        PlanLocalisationModal,
        PerimetreInvestigationModalComponent,
        ListePerimetreComponent,
        PerimetreEditPanelComponent,
        LegendePerimetreComponent,
        BonCommandeViewEchantillonsTableComponent,
        SelectExistingContactModalComponent,
    ],
    providers: [
        {
            provide: 'IEditorHandler',
            useClass: EditorHandler,
        },
        {
            provide: 'IDrawingPictureHandler',
            useClass: DrawingPictureHandler,
        },
        {
            provide: 'ISketchPresetsHandler',
            useClass: SketchPresetHandler,
        },
        {
            provide: 'ISpacesHandler',
            useClass: SpacesHandler,
        },
        {
            provide: 'ISpacesObjectsSuggestionsHandler',
            useClass: SpacesObjectsHandler,
        },
        {
            provide: 'IObjectsHandler',
            useClass: ObjectsHandler,
        },
        {
            provide: 'ILibraryHandler',
            useClass: LibraryHandler,
        },
        {
            provide: 'IZonesHandler',
            useClass: DefaultZonesHandlerStatic,
        },
        {
            provide: 'IPresetsHandler',
            useClass: SketchPresetHandler,
        },
    ],
})
export class CnDiagSharedModule {}

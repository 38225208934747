import { Component, Input } from '@angular/core';
import { ReportagePhotoImageFile } from '../../../../../../model/diagnostic.model';

@Component({
    selector: 'app-reportage-photo-edit-preview-page',
    templateUrl: './reportage-photo-edit-preview-page.component.html',
    styleUrls: ['./reportage-photo-edit-preview-page.component.scss'],
})
export class ReportagePhotoEditPreviewPageComponent {
    @Input() imagesFilesPage: ReportagePhotoImageFile[] = [];
    @Input() titreReportagePhoto = '';
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** ZPSO fill colors list
//***********************************************************************************
//***********************************************************************************

export const colors = [
    '#f88282',
    '#fabf7c',
    '#faee82',
    '#caff85',
    '#85ffb8',
    '#85ffff',
    '#85acff',
    '#9165f8',
    '#e386ff',
    '#ff85c2'
]


import { FormArray, FormGroup, Validators } from '@angular/forms';
import { Component, Injector, OnInit } from '@angular/core';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { ActivatedRoute } from '@angular/router';
import { FormContext } from '../../../../../../model/rule/form-context.model';
import { FormService } from '../../../../../../services/form.service';
import { Polluant } from '../../../model/polluant.model';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CnSpinnerService } from '../../../../../shared/cn-spinner/service/cn-spinner.service';
import { IValidatableComponent } from '../../../services/interfaces/validatable-component.interface';
import { PolluantComponent } from '../../../utils/polluant-component';
import { IntrocutionOld } from '../../../model/introduction.old.model';
import { EtatValidation } from 'src/app/model/etat-progression.model';
import { NotificationService } from 'src/app/commons-lib';
import { InterventionService } from '../../../../../../services/intervention.service';
import { Intervention } from 'src/app/model/intervention.model';
import { PolluantConfig } from '../../../model/polluant-config.model';
import { ManagementApiService } from 'src/app/services/management-api.service';
import { OperateurData } from 'src/app/model/rapport.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';

@Component({
    selector: 'app-constatations-diverses',
    templateUrl: './intro-strategie.component.html',
    styleUrls: ['./intro-strategie.component.scss'],
})
export class IntroStrategieComponent extends PolluantComponent implements OnInit, IValidatableComponent {
    diagnostic: Diagnostic;
    intervention: Intervention;
    diagnosticPolluant: Polluant;
    polluantConfig: PolluantConfig;
    intros: any[] = [];
    fieldsOptions: any;
    form: FormGroup;
    isLoaded = false;
    prestataire: OperateurData;

    constructor(
        private interventionService: InterventionService,
        private route: ActivatedRoute,
        private formService: FormService,
        private cnSpinnerService: CnSpinnerService,
        private injector: Injector,
        private readonly notificationService: NotificationService
    ) {
        super(injector);
    }

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    ngOnInit(): void {
        this.cnSpinnerService.show();
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.route.data,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diag, routerData]) => {
                    this.intervention = intervention;
                    this.diagnostic = diag;
                    combineLatest([this.polluantService.findOnePolluantConfigIndexedDB(this.diagnostic.idConfig)])
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(([polluantConfig]) => {
                            if (polluantConfig == null) {
                                this.notificationService.error(
                                    'Erreur : Merci de faire une synchro pour récupérer les configs en local.'
                                );
                                this.cnSpinnerService.hide();
                                return;
                            }

                            this.prestataire = this.diagnosticService.getCurrentUser();

                            this.diagnosticPolluant = diag.contenuDiagnostic as Polluant;
                            this.polluantConfig = polluantConfig;

                            this.isValidated = (this.diagnostic.contenuDiagnostic as Polluant).introduction.validated;

                            this.initIntroSelect(polluantConfig); // Initialisation du select "Model d'introduction"

                            // Si on a déjà un model existant, on restaure l'ID du model
                            // On a besoin de cet ID afin de restaurer les fields qui
                            // sont present dans la description du textarea
                            // Si c'est la première fois, on recupere la description du premier element
                            const data = this.diagnosticPolluant.introduction.data;
                            let modelId = null;
                            if (data.model && data.model != 999) {
                                modelId = data.model;
                                this.fieldsOptions = this.intros[modelId].optionsVariables;
                            } else if (data.customModel) {
                                modelId = 999;
                                this.fieldsOptions =
                                    this.diagnosticPolluant.introduction.data.customModel.optionsVariables;
                            } else {
                                modelId = 0;
                                this.fieldsOptions = this.intros[modelId].optionsVariables;
                            }

                            let description = this.getDescriptionIntroFromId(modelId);

                            this.initForm(description); // Initialisation du formulaire

                            this.restoreDataForm(); // restauration des données

                            // const fieldsOptions = this.intros[modelId];

                            this.fillParams();
                            this.isLoaded = true;
                            this.cnSpinnerService.hide();

                            this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                                this.checkValidity();
                            });
                            this.isInEditMode =
                                !(
                                    !!routerData.readonly ||
                                    this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)
                                ) && !this.isValidated;
                            if (!this.isInEditMode) {
                                this.form.disable();
                            }
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    /**
     * @description permet de mettre à jour la description à partir de la valeur des champs configurables
     */
    configurableFieldUpdated() {
        const modelIdSelected = this.form.get('model').value;
        let description = this.getDescriptionIntroFromId(modelIdSelected);

        // Boucle sur les paramètres cachés

        // Boucle sur les paramètres personnalisable
        for (const [key, formControl] of Object.entries(this.form.controls)) {
            if (this.checkFieldDisplay(key)) {
                let formControlValue = formControl.value;
                if (!formControlValue) {
                    formControlValue = key;
                }
                description = description.replace(key, formControlValue);
            }
        }

        this.form.patchValue({ introduction: description });
    }

    /**
     * @description Permet de remplacer les valeurs des variables par leurs clés dans la description
     */
    onModelChange() {
        let originalIntro = this.intros[this.form.get('model').value];
        if (!originalIntro) {
            originalIntro = this.diagnosticPolluant.introduction.data.customModel;
        }
        //lorsqu'une modification est effectuée sur le modèle,
        //le modèle "modèle personnalisé" est automatiquement sélectionne
        this.form.get('model').setValue(999);

        let desc = this.form.get('introduction').value;

        //Toutes les valeurs sont remplacées par les clés
        for (const [key, formControl] of Object.entries(this.form.controls)) {
            if (this.checkFieldDisplay(key)) {
                let formControlValue = formControl.value;
                if (!formControlValue) {
                    formControlValue = key;
                }
                desc = desc.replace(formControlValue, key);
            }
        }

        originalIntro.description = desc;

        console.log(desc);

        //Mise à jour du modèle personnalisé
        this.diagnosticPolluant.introduction.data.customModel = originalIntro;

        this.initForm(desc);
        this.configurableFieldUpdated();

        this.checkValidity();
    }

    /**
     * @description Remplissage du formulaire avec les données du diag et de l'intervention
     */
    fillParams() {
        if (this.form.get('#dateCommande')) {
            const dateCommande = new Date(this.intervention.dateCommande).toLocaleDateString();
            this.form.get('#dateCommande').setValue(dateCommande);
        }

        if (this.form.get('#client')) {
            const client = 'Client';
            this.form.get('#client').setValue(client);
        }

        if (this.form.get('#contextePrelevement')) {
            //récupération des catégories parentes des objectifs de mesurage sélectionnés
            let categories = [];
            this.diagnosticPolluant.objectifsMesurage.data.objectifsMesurage.forEach((obj) => {
                const cat = this.polluantConfig.objectifsMesurage.find((cat) => cat.id == obj.parentId);
                if (cat) {
                    categories.push('• ' + cat.titreRapport);
                }
            });
            //Suppression des doublons
            categories = [...new Set(categories)];
            this.form.get('#contextePrelevement').setValue(categories.join('\n'));
        }

        if (this.form.get('#nomAdresseBien')) {
            const adresse = this.intervention.adresse.voie;
            this.form.get('#nomAdresseBien').setValue(adresse);
        }
        if (this.typeMesurage == 'POLLUANT_VISITE_CHANTIER') {
            const dateVisite = new Date(this.intervention.dateHeureDebutEffective).toLocaleDateString();
            this.form.get('#dateVisite').setValue(dateVisite);

            const intervenant = this.prestataire.prenom + ' ' + this.prestataire.nom;
            this.form.get('#intervenant').setValue(intervenant);
        }

        this.configurableFieldUpdated();
    }

    /**
     * @description on check si le parametre n'est pas model ou introduction
     * @param key
     */
    checkFieldDisplay(key) {
        return !(key === 'model' || key === 'introduction');
    }

    /**
     * @description permet de mettre à jour tous les champs lorsqu'un nouveau model d'intro est selectionne
     */
    selectModelUpdated() {
        const modelIdSelected = this.form.get('model').value;
        let description = this.getDescriptionIntroFromId(modelIdSelected);
        if (modelIdSelected == 999) {
            this.fieldsOptions = this.diagnosticPolluant.introduction.data.customModel.optionsVariables;
        } else {
            this.fieldsOptions = this.intros[modelIdSelected].optionsVariables;
        }
        this.initForm(description);
        this.configurableFieldUpdated();
    }

    getOptionsForField(key: string) {
        const field = this.fieldsOptions.find((fo) => '@' + fo.name == key);
        return field?.options;
    }

    /**
     * @description Initialisation des données du model d'introduction
     * @param polluantConfig
     * @private
     */
    private initIntroSelect(polluantConfig) {
        //Récupération des modèles d'introduction en fonction du type de prestation
        this.intros = polluantConfig.contenuListeDeroulante.modelIntroduction.items.filter(
            (intro) => intro.typePrestation == this.typeMesurage
        );
    }

    /**
     * @description Restauration des données du formulaire
     * Si c'est la premiere fois, on selectionne le premier element
     * @private
     */
    private restoreDataForm() {
        const data = this.diagnosticPolluant.introduction.data;
        if (!data.model && data.model != 0) {
            this.form.patchValue({ model: 0 });
            const description = this.getDescriptionIntroFromId(0);
            this.form.patchValue({ description: description });
            this.configurableFieldUpdated();
        }
    }

    /**
     * retourne la description du modele d'inscription ou un texte vide si ID null
     * @param id du model d'introduction
     * @private
     */
    private getDescriptionIntroFromId(id: number): string {
        if (id === null) {
            return '';
        }

        //Si model custom
        if (id == 999) {
            return this.diagnosticPolluant.introduction.data.customModel.description;
        }

        return this.intros[id].description;
    }

    /**
     * Initialisation du formulaire static et formulaire dynamic
     *
     * @param description
     * @private
     */
    private initForm(description) {
        this.initFormStatic();
        this.initFormDynamic(description);
    }

    /**
     * @description initialisation du formulaire static
     * Le champ model et introduction seront static
     * @private
     */
    private initFormStatic() {
        this.form = this.formBuilder.group({
            model: this.formService.createFormControl(
                'introduction',
                new FormContext(
                    'introduction.data.model',
                    this.diagnosticPolluant,
                    Validators.required,
                    this.ngUnsubscribe
                )
            ),
            introduction: this.formService.createFormControl(
                'introduction',
                new FormContext(
                    'introduction.data.introduction',
                    this.diagnosticPolluant,
                    Validators.required,
                    this.ngUnsubscribe
                )
            ),
        });
    }

    /**
     * @description initialisation du formulaire dynamic avec des champs qui seront ajoutés
     * à partir des balises dans la description
     * @example si la description est "description avec champ #test1", on va créer un champ de type string test1
     * @param description
     * @private
     */
    private initFormDynamic(description: string) {
        const variablesCachees = description.match(/#\w+/g);

        if (variablesCachees) {
            variablesCachees.forEach((variable) => {
                this.form.addControl(
                    variable,
                    this.formService.createFormControl(
                        'introduction',
                        new FormContext(
                            'introduction.data.' + variable,
                            this.diagnosticPolluant,
                            Validators.required,
                            this.ngUnsubscribe
                        )
                    )
                );
            });
        }

        const variablesVisibles = description.match(/@\w+/g);
        if (variablesVisibles) {
            variablesVisibles.forEach((variable) => {
                this.form.addControl(
                    variable,
                    this.formService.createFormControl(
                        'introduction',
                        new FormContext(
                            'introduction.data.' + variable,
                            this.diagnosticPolluant,
                            Validators.required,
                            this.ngUnsubscribe
                        )
                    )
                );
            });
        }
    }

    /**
     * @description Check la validation du formulaire
     * @private
     */
    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        let isFormEnabled = this.form.enabled;

        //Si le formulaire est désactivé, on l'active afin de pouvoir savoir si il est valide
        if (!isFormEnabled) {
            this.form.enable({
                emitEvent: false,
            });
        }

        let etat: EtatValidation = 'INVALID';
        if (this.typeMesurage == 'POLLUANT_VISITE_CHANTIER') {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            // et si les données de l'Introduction ont été validées
            if (this.form && this.form.valid && this.diagnosticPolluant.introduction.validated) {
                etat = 'VALID';
            }
        } else {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            if (this.form && this.form.valid) {
                etat = 'VALID';
            }
        }

        //Si le formulaire était désactivé avant, on le désactive à nouveau
        if (!isFormEnabled) {
            this.form.disable({
                emitEvent: false,
            });
        }

        this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic);
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.form.disable();
        this.isInEditMode = false;
        this.form.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.form.disable();
        this.isInEditMode = false;
        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.diagnosticPolluant.introduction.validated = true;
        this.checkValidity();
    }

    startModification() {
        //Unvalidate screen
        this.isValidated = false;
        this.diagnosticPolluant.introduction.validated = false;
        this.checkValidity();

        //Enable form
        this.form.enable();
        this.isInEditMode = true;

        //Memorize previous form value
        this.previousFormValue = this.form.getRawValue();
    }
}

export const DOCS = 'DOCS';
export const DOCS_DIAG = 'DOCS_DIAG';
export const INIT = 'INIT';
export const DESC = 'DESC';
export const DESC_BIEN = 'DESC_BIEN';
export const COMPO = 'COMPO';
export const INSTALL = 'INSTALL';
export const ELEMENTS = 'ELEMENTS';
export const CONNECT = 'CONNECT';
export const RESEAU = 'RESEAU';
export const EMPTY_VOLUME = 'EMPTY_VOLUME';
export const CONTROL_POINT = 'CONTROL_POINT';
export const EQUIPEMENT = 'EQUIPEMENT';
export const AUTRE = 'AUTRE'; // Toilette sèche
export const RECAP = 'RECAP';
export const RECAP_REU = 'RECAP_REU';
export const RECAP_REP = 'RECAP_REP';
export const RECAP_AUTRE = 'RECAP_AUTRE';
export const COMMENT = 'COMMENT';
export const COMMENT_RECO = 'COMMENT_RECO';
export const COMMENT_DIVERS = 'COMMENT_DIVERS';
export const CONCLUSION = 'CONCLUSION';
export const ZONE_MESURE = 'ZONE_MESURE';
export const CONCLUSION_REU = 'CONCLUSION_REU';
export const CONCLUSION_REP = 'CONCLUSION_REP';
export const CONCLUSION_AUTRE = 'CONCLUSION_AUTRE';
export const CONFORMITE = 'CONFORMITE';
export const BON_COMMANDE_ANALYSE = 'BON_COMMANDE_ANALYSE';
export const RAPPORT = 'RAPPORT';
export const GESTION_PLAN = 'GESTION_PLAN';
export const DOCUMENTS_RAPPORT = 'DOCUMENTS_RAPPORT';
export const REPORTAGE_PHOTO = 'REPORTAGE_PHOTO';
export const PREVI_RAPPORT = 'PREVI_RAPPORT';
export const SAVE_RAPPORT = 'SAVE_RAPPORT';
export const SAVE_BON_COMMANDE = 'SAVE_BON_COMMANDE';

export const FICHE = 'FICHE';
export const INFOS = 'INFOS';
export const INFOS_BIEN = 'INFOS_BIEN';
export const INFOS_BIEN_ANNEXE = 'INFOS_BIEN_ANNEXE';
export const DESC_BIEN_ANNEXE = 'DESC_BIEN_ANNEXE';
export const PREST = 'PREST';

export const MESU = 'MESU';

export const INTERVENTION = 'INTERVENTION';
export const RESUME = 'RESUME';
export const TABLEAU = 'TABLEAU';
export const COUPURE = 'COUPURE';
export const TERRE = 'TERRE';
export const LOGEMENT = 'LOGEMENT';
export const AUTRE_EQUIPEMENT = 'AUTRE_EQUIPEMENT';
export const CONSTATATION = 'CONSTATATION';
export const RESTE_A_FAIRE = 'RESTE_A_FAIRE';
export const INSTALLATIONS_NON_COUVERTES = 'INSTALLATIONS_NON_COUVERTES';
export const INSTALLATION_ELECTRIQUE_ENVIRONNEMENT = 'INSTALLATION_ELECTRIQUE_ENVIRONNEMENT';
export const POINTS_DE_CONTROLES_NON_VERIFIABLE = 'POINTS_DE_CONTROLES_NON_VERIFIABLE';
export const OBJECTIFS = 'OBJECTIFS';
export const PERIMETRE = 'PERIMETRE';
export const ZONE = 'ZONE';
export const ZONE_ESPACE = 'ZONE_ESPACE';
export const BESOINS = 'BESOINS';
export const PRELEVEMENTS = 'PRELEVEMENTS';
export const PRELEVEMENT_ESPACE = 'PRELEVEMENT_ESPACE';
export const RESULTATS_LABORATOIRE = 'RESULTATS_LABORATOIRE';
export const BESOIN_ESPACE = 'BESOIN_ESPACE';
export const PREPARATION = 'PREPARATION';
export const DICT = 'DICT';
export const RECONNAISSANCE = 'RECONNAISSANCE';
export const ACCES_CIRCULATION = 'ACCES_CIRCULATION';
export const TERRAIN = 'TERRAIN';

// Diag Polluant
export const TEST = 'TEST';
export const POLL_INIT_ETUDE = 'POLL_INIT_ETUDE';
export const POLL_INIT_ETUDE_ACTIVITES = 'POLL_INIT_ETUDE_ACTIVITES';
export const POLL_INIT_ETUDE_OBJECTIFS = 'POLL_INIT_ETUDE_OBJECTIFS';
export const POLL_INTRO = 'POLL_INTRO';
export const POLL_PERIMETRE = 'POLL_PERIMETRE';
export const POLL_PERIMETRE_DESCRIPTIF = 'POLL_PERIMETRE_DESCRIPTIF';
export const POLL_PERIMETRE_LOCALISATION = 'POLL_PERIMETRE_LOCALISATION';
export const POLL_MPCA = 'POLL_MPCA';
export const POLL_MPCA_DESC = 'POLL_MPCA_DESC';
export const POLL_ZONE = 'POLL_ZONE';
export const POLL_BESOIN = 'POLL_BESOIN';
export const POLL_RECAP_ETUDE = 'POLL_RECAP_ETUDE';
export const POLL_ESTIMATION = 'POLL_ESTIMATION';
export const POLL_ESTIMATION_PREVISITE = 'POLL_ESTIMATION_PREVISITE';
export const POLL_ESTIMATION_COMPETENCE = 'POLL_ESTIMATION_COMPETENCE';
export const POLL_PRELEVEMENT_INIT = 'POLL_PRELEVEMENT_INIT';
export const POLL_PRELEVEMENT_INIT_MAT = 'POLL_PRELEVEMENT_INIT_MAT';
export const POLL_PRELEVEMENT_ZONE = 'POLL_PRELEVEMENT_ZONE';
export const PRELEVEMENT_ZONE = 'PRELEVEMENT_ZONE';
export const POLL_PRELEVEMENT_META = 'POLL_PRELEVEMENT_META';
export const POLL_RECAP_PRELO = 'POLL_RECAP_PRELO';

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MpcaItemOld, MpcaProcessus } from '../../../../../model/mpca.model.old';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PolluantConfig } from '../../../../../model/polluant-config.model';
import { MpcaProcessusModalComponent } from '../mpca-processus-modal/mpca-processus-modal.component';
import { MongoUtils, NotificationService } from 'src/app/commons-lib';
import { cloneDeep } from 'lodash/cloneDeep';
import { Diagnostic } from '../../../../../../../../model/diagnostic.model';
import { MpcaService } from '../../../../../services/mpca.service';
import { StaticJsonService } from '../../../../../../../../services/static-json.service';
import { MatDialog } from '@angular/material/dialog';
import { Polluant } from 'src/app/modules/diagnostics/polluant/model/polluant.model';
import { ContenuDiagnostic } from 'src/app/model/diagnostic-contenu.model';
import { Besoin } from 'src/app/modules/diagnostics/polluant/model/besoin.model';
import { ConfirmDialogComponent } from 'src/app/lib/confirmation/confirm-dialog.component';

@Component({
    selector: 'app-mpca-form-content',
    templateUrl: './mpca-form-content.component.html',
    styleUrls: ['./mpca-form-content.component.scss'],
})
export class MpcaFormContentComponent {
    @Input() formMpca: FormGroup;
    @Input() isInEditMode: boolean;
    @Input() polluantConfig: PolluantConfig;
    @Input() diagnostic: Diagnostic;
    @Input() mpcaItemSelected: MpcaItemOld;
    @Input() showProcessus: boolean = false;

    @Output() deleteProcessus = new EventEmitter<any>();

    constructor(
        private staticJsonService: StaticJsonService,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private mpcaFormService: MpcaService,
        private readonly notificationService: NotificationService,
        private matDialog: MatDialog
    ) {}

    /**
     * Dans état de dégradation,
     * Quand nous cliquons sur retiré que tous les
     * champs Protection / étanchéité / chocs et vibration / exposition
     * passent automatiquement en Retirée aussi.
     */
    public onGeneralDataChange() {
        const value = this.formMpca.get('general').get('etatDegradation').value;
        if (value === 'Retiré' && this.formMpca.get('protectionExposition')) {
            this.formMpca.get('protectionExposition').get('type').setValue('Matériau retiré');
            this.formMpca.get('protectionExposition').get('etancheite').setValue('Matériau retiré');
            this.formMpca.get('protectionExposition').get('circulationAir').setValue('Matériau retiré');
            this.formMpca.get('protectionExposition').get('chocVibration').setValue('Matériau retiré');
        }
    }
    public addMpcaProcessus(event) {
        this.openModal(-1);
    }

    public editMpcaProcessus(index) {
        this.openModal(index);
    }

    public deleteMpcaProcessus(index) {
        this.deleteProcessus.emit(index);
    }

    public duplicateMpcaProcessus(index) {
        const _procToDuplicate: MpcaProcessus = Object.assign(
            new MpcaProcessus(),
            JSON.parse(JSON.stringify(this.mpcaProcessus.value[index]))
        );
        _procToDuplicate.id = MongoUtils.generateObjectId();
        _procToDuplicate.primary = false;
        this.mpcaProcessus.push(this.createProcessusForm(_procToDuplicate));
    }

    public checkPrimaryProcessus(index) {
        // coche le sélectionné
        // décoche tous les autres
        this.mpcaProcessus.controls.forEach((processusForm: FormGroup, i: number) => {
            if (i == index) {
                if (processusForm.get('primary').value == false) {
                    processusForm.get('primary').patchValue(true);
                    return;
                }
            }
            processusForm.get('primary').patchValue(false);
        });
    }

    private openModal(index) {
        let selectedProcessus;
        if (index != -1) {
            selectedProcessus = this.selectedMpca.get('processus').value[index];
        }

        const dialogRef = this.dialog.open(MpcaProcessusModalComponent, {
            width: '250px',
            data: {
                diagnostic: this.diagnostic,
                polluantConfig: this.polluantConfig,
                selectedMpca: this.formMpca.getRawValue(),
                selectedProcessus,
                indexProcessus: index,
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.action == 'save') {
                if (result.index == '-1') {
                    // Create new processus from result data
                    this.mpcaProcessus.push(result.value);
                } else {
                    const formValue: MpcaProcessus = result.value.getRawValue();

                    // Update exising process
                    this.mpcaProcessus.controls[result.index].patchValue({
                        id: formValue.id,
                        nom: formValue.nom,
                        primary: formValue.primary,
                        metop: formValue.metop,
                        pendantTravaux: formValue.pendantTravaux,
                        echantillonnage: formValue.echantillonnage,
                    });

                    while (
                        (this.mpcaProcessus.controls[result.index].get('taches') as FormArray).controls.length !== 0
                    ) {
                        (this.mpcaProcessus.controls[result.index].get('taches') as FormArray).removeAt(0);
                    }

                    result.value.get('taches').controls.forEach((tache: FormGroup) => {
                        (this.mpcaProcessus.controls[result.index].get('taches') as FormArray).push(tache);
                    });
                }
            } else if (result.action == 'delete') {
                // Supprimer l'index sélectionné
                this.mpcaProcessus.removeAt(result.index);
            }
        });
    }

    private createProcessusForm(processus: MpcaProcessus): FormGroup {
        if (processus.primary == null || processus.primary == undefined) {
            processus.primary = false;
        }

        const processForm = this.formBuilder.group(
            {
                id: [processus.id],
                nom: [processus.nom, Validators.required],
                primary: [processus.primary],
                taches: new FormArray([]),
                metop: this.formBuilder.group({
                    methode: [processus.metop.methode, Validators.required],
                    technique: [processus.metop.technique],
                    outil: [processus.metop.outil],
                    autresOutils: [processus.metop.autresOutils],
                    aspiration: [processus.metop.aspiration],
                    travailHumide: [processus.metop.travailHumide],
                }),
                pendantTravaux: this.formBuilder.group({
                    niveauEmpoussierement: [processus.pendantTravaux.niveauEmpoussierement, Validators.required],
                    typeMasqueDesamianteur: [processus.pendantTravaux.typeMasqueDesamianteur, Validators.required],
                    concentrationFi: [processus.pendantTravaux.concentrationFi, Validators.required],
                }),
                echantillonnage: this.formBuilder.group({
                    concentrationAttendue: [processus.echantillonnage.concentrationAttendue],
                    dureeRepresentativeProcessus: [processus.echantillonnage.dureeRepresentativeProcessus],
                    dureeSaturationPoussieres: [processus.echantillonnage.dureeSaturationPoussieres],
                    debitPrelevement: [processus.echantillonnage.debitPrelevement],
                    fractionFiltre: [processus.echantillonnage.fractionFiltre],
                }),
            },
            { updateOn: 'blur' }
        );

        processus.taches.forEach((tache) => {
            (processForm.get('taches') as FormArray).push(this.mpcaFormService.getTacheGroup(tache));
        });

        return processForm;
    }

    get mpcaProcessus(): FormArray {
        return this.selectedMpca.get('processus') as FormArray;
    }

    /**
     * Get le formulaire tableau root mpca
     */
    get selectedMpca(): FormGroup {
        return this.formMpca;
    }
}

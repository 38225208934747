import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { cn_background_map, cn_building, cn_object_instance, cn_storey, cn_svg_map } from '@acenv/cnmap-editor';
import { CndiagSelectionTool } from '../tools/cndiag-selection-tool';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/commons-lib';
import { PointDeControleBien, PointDeControleElement } from '../../../../model/point-de-controle.model';
import { ElementAControler, Niveau, Volume } from '../../../../model/bien.model';
import { ViewerMapConfig } from './viewer-map-config';
import { BackgroundMapApiService } from '../../../../services/background-map-api.service';
import { RelationInterventionBien } from '../../../../model/intervention.model';
import { forkJoin, Observable, of } from 'rxjs';
import { InterventionService } from '../../../../services/intervention.service';
import { CnStoreyComponent, DrawingPicture, observeCnmapEvent$ } from '@acenv/cnmap-angular-editor-lib';
import { CnStoreyConfig } from '@acenv/cnmap-angular-editor-lib/lib/model/cn-storey-config.model';

const objectImageUrlFromIconId = (iconId: string) => {
    if (iconId.startsWith('uid')) {
        // Pour fonctionner avec les maquettes contenant les anciens objets par défaut dont l'URL comment par "uid"
        return iconId.substring(3);
    }
    return iconId;
};

@Component({
    selector: 'app-viewer-map',
    templateUrl: './viewer-map.component.html',
    styleUrls: ['./viewer-map.component.scss'],
})
export class ViewerMapComponent extends BaseComponent implements OnInit {
    @Input()
    set currentBien(currentBien: RelationInterventionBien) {
        this._currentBien = currentBien;
        if (this._building && this._currentBien) {
            this.loadBM();
            this.cnStoreyComp.refresh();
        }
    }

    @Input()
    set building(building: cn_building) {
        this._building = building;
        if (this._building && this._currentBien) {
            if (this.storeyId) {
                this.changeStorey(this.storeyId);
            } else if (this.currentNiveau) {
                this.changeStorey(this.currentNiveau.storeyId);
            } else {
                this.changeToFirstStorey();
            }
            this.cnStoreyComp.refresh();
        }
    }

    get building(): cn_building {
        return this._building;
    }

    @Input()
    set storeyIdToDisplay(storeyId: string) {
        this._storeyId = storeyId;
        if (storeyId !== undefined && this.building) {
            this.changeStorey(storeyId);
        }
    }

    get storeyId(): string {
        return this._storeyId;
    }

    backgroundMaps: DrawingPicture[] = [];

    @ViewChild('libCnStorey', { static: true }) cnStoreyComp: CnStoreyComponent;

    @Input()
    readOnly = true;

    // Indique que l'on va utiliser des outils spécifique pour gérer les sélections
    @Input()
    useDiagTool = false;

    private _formPointDeControl: PointDeControleBien;
    @Input()
    set formPointDeControl(formPointDeControl: PointDeControleBien) {
        this._formPointDeControl = formPointDeControl;

        if (this._selectionTool) {
            this._selectionTool.refreshMap();
        }
    }

    get formPointDeControl() {
        return this._formPointDeControl;
    }

    // Niveau de la description
    @Input()
    currentNiveau: Niveau;

    @Input()
    viewerMapConfig: ViewerMapConfig = {};

    @Input()
    cnMapConfig: CnStoreyConfig = {
        drawSpaceNames: true,
        drawBackground: true,
        drawGrid: false,
        drawScale: false,
        showCompass: false,
        padding: { left: 30, right: 30, top: 30, bottom: 30 },
    };

    private _externalSelectedElement: PointDeControleElement | ElementAControler;

    @Input()
    set externalSelectedElement(externalSelectedElement) {
        this._externalSelectedElement = externalSelectedElement;

        if (this._selectionTool) {
            this._selectionTool.selectElement(externalSelectedElement);
        }
    }

    get externalSelectedElement() {
        return this._externalSelectedElement;
    }

    private _externalSelectedVolume: Volume;

    @Input()
    set externalSelectedVolume(externalSelectedVolume: Volume) {
        this._externalSelectedVolume = externalSelectedVolume;
        if (this._selectionTool) {
            this._selectionTool.selectVolume(externalSelectedVolume);
        }
    }

    get externalSelectedVolume() {
        return this._externalSelectedVolume;
    }

    @Input()
    set multipleSelectionObject(isMultipleSelectionObject: boolean) {
        this._isMultipleSelectionObject = isMultipleSelectionObject;

        if (this._selectionTool) {
            this._selectionTool.multipleSelectionObject(isMultipleSelectionObject);
        }
    }

    get multipleSelectionObject() {
        return this._isMultipleSelectionObject;
    }

    @Output()
    toolEvent = new EventEmitter<any>();

    currentStorey: cn_storey;
    cnStoreyReadonly: boolean;
    elementId = 'storey_svg_editor';

    private _currentBien: RelationInterventionBien;
    private _building: cn_building;
    private _storeyId: string;
    private _selectionTool: CndiagSelectionTool = null;
    private _isMultipleSelectionObject = false;

    static getObjectImageUrlFromIconId(iconId: string) {
        if (iconId.startsWith('uid')) {
            // Pour fonctionner avec les maquettes contenant les anciens objets par défaut dont l'URL comment par "uid"
            return iconId.substr(3);
        }
        return iconId;
    }

    niveauZoom: number = 0;

    constructor(
        private readonly interventionService: InterventionService,
        private readonly backgroundMapApiService: BackgroundMapApiService
    ) {
        (cn_background_map as any).image_id_to_url = (fileId) => {
            return (this.backgroundMaps.find((bgmu) => bgmu.fileId == fileId) || ({} as DrawingPicture)).imageUrl;
        };
        super();
    }

    ngOnInit(): void {
        if (!this._storeyId) {
            this._storeyId = null;
            this.changeToFirstStorey();
        }
        this.loadBM();
        if (this.building && !this.currentStorey) {
            this.currentStorey = this.building.storeys[this._storeyId];
        }
        // Ouverture de CnStoreyComponent en readonly si readOnly est true ou si useDiagTool est true.
        this.cnStoreyReadonly = this.readOnly || this.useDiagTool;
        // Renseigne la méthode statique pour récupérer l'URL de l'image d'un objet
        (cn_object_instance as any).image_id_to_url = objectImageUrlFromIconId;

        (this.cnStoreyComp as any).svgMap$
            .pipe(
                takeUntil(this.ngUnsubscribe),
                tap((svgMap: cn_svg_map) => {
                    if (this.useDiagTool && svgMap) {
                        //FIXME: En attente consolidation Cnmap
                        svgMap._scene.storey = this.currentStorey;
                        this._selectionTool = new CndiagSelectionTool(
                            svgMap,
                            this.viewerMapConfig,
                            this.currentStorey,
                            this.formPointDeControl,
                            this.currentNiveau
                        );
                        svgMap.set_svg_tool(this._selectionTool);
                        /* 
                        Toutes les classes remontes les évènements qu’elles émettent à leur classe parente, à savoir cn_svg_map. 
                        La map est donc l’event manager qui a une visibilité sur l’ensemble des évènements.

                        this._selectionTool.on('selection_change', (selections: any[]) => {
                            this.toolEvent.emit({
                                event: 'selection_change',
                                mode: 'edition',
                                tool: this._selectionTool,
                                toolName: 'SELECTION',
                                value: selections,
                            });
                        });
                        */
                        observeCnmapEvent$(this._selectionTool, 'selection_change')
                            .pipe(takeUntil(this.ngUnsubscribe))
                            .subscribe((selections) => {
                                this.toolEvent.emit({
                                    event: 'selection_change',
                                    mode: 'edition',
                                    tool: this._selectionTool,
                                    toolName: 'SELECTION',
                                    value: selections,
                                });
                            });
                    }
                })
            )
            .subscribe();
    }

    private loadBM() {
        this.loadBackgroundmaps(this._currentBien)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.cnStoreyComp.refresh());
    }

    zoom(zoomIn: boolean) {
        if (zoomIn) this.niveauZoom++;
        else this.niveauZoom--;
        // this.cnStoreyComp.zoom(this.niveauZoom);
    }

    centerCamera() {
        this.cnStoreyComp.centerCamera();
    }

    refresh() {
        this.cnStoreyComp.refresh();
    }

    private changeStorey(storeyId: string) {
        this.currentStorey = this.building.find_storey(storeyId);
        this.cnStoreyComp.refresh();
    }

    private changeToFirstStorey() {
        this.currentStorey = this.building.storeys[0];
        this.cnStoreyComp.refresh();
    }

    private loadBackgroundmaps(currentBien: RelationInterventionBien): Observable<DrawingPicture[]> {
        if (currentBien.bien.backgroundMaps.length > 0) {
            return forkJoin(
                currentBien.bien.backgroundMaps
                    .slice()
                    .sort((a, b) => a.createdDate.localeCompare(b.createdDate))
                    .map((bg) => {
                        return this.backgroundMapApiService
                            .downloadBackgroundImage(
                                this.interventionService.getCurrentInterventionValue().id,
                                currentBien.id,
                                bg.fileId
                            )
                            .pipe(
                                map((res) => {
                                    const background = { ...bg };
                                    if (res) {
                                        background.imageUrl = res.fileContent;
                                    }
                                    return background;
                                })
                            );
                    })
            ).pipe(
                map((backgrounds) => {
                    this.backgroundMaps = backgrounds;
                    return this.backgroundMaps;
                })
            );
        } else {
            this.backgroundMaps = [];
            return of(this.backgroundMaps);
        }
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bien } from '../../../model/bien.model';
import { BaseObject } from '../../../model/base-object.model';

class NiveauSelection extends BaseObject {
    hasPrevious: boolean;
    hasNext: boolean;
}

@Component({
    selector: 'app-bien-niveau-select',
    templateUrl: './bien-niveau-select.component.html',
    styleUrls: ['./bien-niveau-select.component.scss'],
})
export class BienNiveauSelectComponent implements OnInit {
    @Input()
    set biens(biens: Bien[]) {
        this._biens = biens;
        if (biens) {
            this.bienChoices = this._biens.map((bien) => bien.nom);
            if (this.bienChoices?.length) {
                this.selectedBien = this.bienChoices[0];
            }
        }
    }

    @Input()
    set forPlan(forPlan: boolean) {
        if (forPlan) {
            this._forPlan = forPlan;
            this.updateNiveau();
        }
    }

    get forPlan(): boolean {
        return this._forPlan;
    }

    @Input()
    column = false;

    @Input()
    hideBiensSelection = false;

    @Input()
    displayArrows = true;

    @Input()
    set selectedBien(name: string) {
        if (name) {
            this._selectedBien = name;
            this.updateNiveau();
        }
    }

    get selectedBien(): string {
        return this._selectedBien;
    }

    @Input()
    set selectedNiveau(niveau: any) {
        this._selectedNiveau = niveau
            ? this.niveauChoices.find((n) => n.id === (niveau.id ? niveau.id : niveau.idNiveau))
            : niveau;
    }

    get selectedNiveau(): NiveauSelection {
        return this._selectedNiveau;
    }

    @Input()
    includeAll: Boolean = false;

    @Output()
    bienChanged = new EventEmitter<string>();

    @Output()
    niveauChanged = new EventEmitter<NiveauSelection>();

    bienChoices: string[];

    niveauChoices: NiveauSelection[] = [];

    private _selectedBien: string;

    private _forPlan: boolean;

    private _selectedNiveau: NiveauSelection;

    private _biens: Bien[];

    constructor() {}

    ngOnInit(): void {
        if (!this.bienChoices) {
            this.bienChoices = [];
        }
        if (!this.niveauChoices) {
            this.niveauChoices = [];
        } else {
            if (this.includeAll) {
                const hasPrevious = this.niveauChoices.length > 0;
                const hasNext = false;
                this.niveauChoices.push({ id: 'tous_les_niveaux', nom: 'Tous les niveaux', hasPrevious, hasNext });
            }
        }
        if (!this._selectedNiveau) {
            this._selectedNiveau = this.niveauChoices[0];
        } else {
            // refresh
            this.selectedNiveau = this._selectedNiveau;
        }
    }

    changeBien(event: string | string[]) {
        this.selectedBien = event as string;
        this.bienChanged.emit(this.selectedBien);
    }

    changeNiveau(event: any) {
        this.selectedNiveau = event;
        this.niveauChanged.emit(this.selectedNiveau);
    }

    previousNiveau() {
        const index = this.niveauChoices.findIndex((n) => n.id === this.selectedNiveau.id);
        this.selectedNiveau = this.niveauChoices[index - 1];
        this.niveauChanged.emit(this.selectedNiveau);
    }

    nextNiveau() {
        const index = this.niveauChoices.findIndex((n) => n.id === this.selectedNiveau.id);
        this.selectedNiveau = this.niveauChoices[index + 1];
        this.niveauChanged.emit(this.selectedNiveau);
    }

    private updateNiveau() {
        const bien = this._biens?.find((b) => b.nom === this.selectedBien);
        this.niveauChoices =
            bien?.description
                .filter((n) => !this.forPlan || n.storeyId)
                .map((niveau, index, niveaux) => ({
                    id: niveau.id,
                    nom: niveau.nom,
                    hasPrevious: index > 0,
                    hasNext: index < niveaux.length - 1,
                })) || [];
    }

    attributeDisplay(attribute1, attribute2) {
        if (attribute1.id == attribute2.id) {
            return attribute1.nom;
        } else {
            return '';
        }
    }
}

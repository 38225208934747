<div class="container">
    <div class="py-4 d-flex flex-column">
        <div *ngIf="!hasEspace">Pas d'espace présent dans ce diagnostic</div>
        <ng-container *ngIf="hasEspace">
            <app-plans-list
                class="liste-screenshots mb-3"
                [showActionbutton]="!readonlyMode"
                [screenshotsSvg]="currentDiagnostic.screenshotsPlan"
                (clickDeleteScreenshot)="onClickDeleteScreenshot($event)"
            ></app-plans-list>

            <mat-card *ngIf="!readonlyMode" class="d-flex small-card action-card align-self-center">
                <mat-card-content class="w-100">
                    <button class="w-100" mat-button (click)="onClickAddNewScreenshot()">
                        <lib-icon>add</lib-icon>
                        <br />Créer un nouveau plan de repérage
                    </button>
                </mat-card-content>
            </mat-card>
        </ng-container>
    </div>
</div>

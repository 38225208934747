"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_selection  : A SVG tool to select storey or roof elements
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** internal class : tool couple
//***********************************************************************************

import {cn_clone, cn_middle, cn_sub} from "../utils/cn_utilities";
import {cn_paste_handler} from "./cn_paste_handler";
import {cn_svg_tool} from "./cn_svg_tool";
import {cn_space} from "../model/cn_space";
import {cn_scene} from "../model/cn_scene";
import {cn_roof_vertex} from "../model/cn_roof_vertex";
import {cn_roof_line} from "../model/cn_roof_line";
import {cn_vertex} from "../model/cn_vertex";
import {cn_column} from "../model/cn_column";
import {cn_beam} from "../model/cn_beam";
import {cn_object_instance} from "../model/cn_object_instance";
import {cn_stairs} from "../model/cn_stairs";
import {cn_slab_opening} from "../model/cn_slab_opening";
import {cn_opening} from "../model/cn_opening";
import {cn_wall} from "../model/cn_wall";
import {cn_roof} from "../model/cn_roof";
import {cn_roof_opening} from "../model/cn_roof_opening";
import {cn_area_trimming, cn_background_map, cn_facing_trimming, cn_pipe, cn_roof_height, cn_sampling, cn_snap, SEVERITY_QUESTION_WARNING} from '..';
import {cn_marker} from "../model/cn_marker";
import {cn_element_type} from "../model/cn_element_type";
import {cn_object} from "../model/cn_object";
import {cn_question_input} from "./cn_inputs";
import {cn_edit_box} from "./cn_edit_box";

//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_selection class
//***********************************************************************************
//**** Events :
//**** - 'selection_change' - no arguments. Called when selection changes
//**** - 'main_tool_change' - no arguments. Called when main tool changes
//***********************************************************************************
//***********************************************************************************

export class cn_svg_tool_selection extends cn_svg_tool {
	constructor(svg_map) {
		super(svg_map);
		this._roof = (this._scene.constructor == cn_roof);

		this.area_selection = true;

		this._edition_tools = [];
		this._creation_tools = [];
		this._current_creation_tool = null;
		this._current_edition_tool = null;

		this._edit_box = null;

		this._start_translation = null;
		this._drag_code=0;

		this._paste_handler = null;

		this._hard_focus = false;

		this.on("selection_change",function() {svg_map.call("selection_change");});

		const obj = this;
		svg_map.on("delete_selection",function() {
			var nb = obj.get_removable_selection().length;
			if (nb == 0) return;
			const input = new cn_question_input((nb==1)?"Voulez-vous supprimer cet élément ?":"Voulez-vous supprimer ces " + nb + " éléments ?");
			input.severity = SEVERITY_QUESTION_WARNING;
			input.callback = function() {
				obj.remove_selection();
			}
			obj.call("question_input",input);
			return true;
		})

		svg_map.on("delete_elements",function(elements) {
			const nb = elements.length;
			if (nb == 0) return;
			const input = new cn_question_input((nb==1)?"Voulez-vous supprimer cet élément ?":"Voulez-vous supprimer ces " + nb + " éléments ?");
			input.severity = SEVERITY_QUESTION_WARNING;
			input.callback = function() {
				obj.remove_elements(elements);
			}
			obj.call("question_input",input);
			return true;
		})

		svg_map.on("select_siblings",function(input){
			const element_name = obj._controller.find_element_name(input.element);
			var elements = obj._controller.get_elements(element_name).filter(elt => elt[input.key] == input.element[input.key]);

			//*** Special case for walls : there are 2 element names, we add the other. */
			const wall_names = ["outer_walls","inner_walls"];
			const wall_name_index = wall_names.indexOf(element_name);
			if (wall_name_index >= 0)
				elements = elements.concat(obj._controller.get_elements(wall_names[1-wall_name_index]).filter(elt => elt[input.key] == input.element[input.key]))

			//*** We select the elements */
			elements.forEach(elt => obj._controller.select_element(elt,false));
			obj._selection_change();
			obj._map.refresh_tool();
			return true;
		});

		this._measure_points = [];
	}

	//***********************************************************************************
	//**** Add a pair of tools :
	//**** - name is a label that is returned by 'get_main_tool_name'
	//**** - filter is a list of filter, from
	//***********************************************************************************
	add_tool(name, filters, edition_tool, creation_tool) {
		if (edition_tool)
		{
			this._edition_tools.push(edition_tool);
			edition_tool._svg_parent = this;
		}

		if (creation_tool)
		{
			creation_tool.name = name;
			this._creation_tools.push(creation_tool);
			creation_tool._svg_parent = this;
		}
	}

	//***********************************************************************************
	//**** transaction_refresh
	//***********************************************************************************
	transaction_refresh() {

		if (this._current_creation_tool)
			this._current_creation_tool.transaction_refresh();

		if (this._current_edition_tool)
			this._current_edition_tool.transaction_refresh();

		if (this._controller.get_selection().length == 0) return;
		this.clear_selection();
		this.call("selection_change");
	}

	//***********************************************************************************
	//**** main tool information
	//***********************************************************************************
	get_tool_names() {
		return this._creation_tools.map(t => t.name);
	}

	get_main_tool_name() {
		if (this._current_creation_tool)
			return this._current_creation_tool.name;
		return undefined;
	}

	get_main_tool_creation() {
		return (this._current_creation_tool != null);
	}

	set_main_tool(name,creation=false) {
		this._paste_handler = null;
		if (this._current_creation_tool)
		{
			if (creation && name == this._current_creation_tool.name) return;
			this._current_creation_tool.close_tool();
			this._current_creation_tool = null;
		}
		if (this._current_edition_tool)
		{
			this._current_edition_tool.close_tool();
			this._current_edition_tool = null;
		}

		if (creation && this._controller.get_selection().length > 0) {
			this.clear_selection();
		}

		this.element_filter = null;
		if (creation)
		{
			this._current_creation_tool = this._creation_tools.find(t => t.name == name);
			if (this._current_creation_tool)
			{
				this._current_creation_tool.open_tool();
				this.element_filter = this._current_creation_tool.element_filter;
			}
			else
				this._current_creation_tool = null;
		}

		this.call("main_tool_change");
		this._map.refresh_main_and_tool();
		return true;
	}

	//***********************************************************************************
	//**** Return element data
	//***********************************************************************************
	get_element_names() {
		return this._controller.get_element_names();
	}

	get_element_label(name) {
		return this._controller.get_element_label(name);
	}

	get_element_count(name) {
		return this._controller.get_element_count(name);
	}

	get_element_filter(name) {
		return this._controller.get_element_filter(name);
	}

	get_elements(name) {
		return this._controller.get_elements(name);
	}
	set_element_filter(name, value) {
		//*** chack filter is compatible with main tool
		if (!value && this._current_creation_tool && this._current_creation_tool.filters.indexOf(name) >= 0)
			return false;

		this._controller.set_element_filter(name,value);
		return true;
	}

	check_filter(elt) {
		return this._controller.check_filter(elt);
	}

	//***********************************************************************************
	//**** Clear selection
	//***********************************************************************************
	clear_selection() {
		this._controller.clear_selection();
		this._selection_change();
	}

	//*** Returns selection
	get_selection() {
		return this._controller.get_selection();
	}

	//*** select given elements
	select_elements(name) {
		if (this._current_creation_tool) return;
		this._controller.select_elements(name);
		this._selection_change();
	}

	//*** Returns selection
	get_removable_selection() {
        var selection = [];
        var all_sel = this._controller.get_selection();
        for (var i in all_sel) {
            if (all_sel[i].removable)
                selection.push(all_sel[i]);
        }
        return selection;
    }

    /**
     * Change element type definition in building
     * @param {cn_element_type} old_element_type
     * @param {cn_element_type} new_element_type
     */
    change_element_type(old_element_type, new_element_type) {
        var label = "Modification de " + old_element_type.get_generic_label().toLowerCase();

        var keys = old_element_type.model_keys();
        var scene = this._scene;
        this.push_transaction(label, "", function () {
            scene.update();
            scene.update_deep();
        });
        this.push_item_set(old_element_type, keys);

        for (var k in keys)
            old_element_type[keys[k]] = new_element_type[keys[k]];

        this._scene.update();
        this._scene.update_deep();
    }

    /**
     * Change object definition in building
     * @param {cn_object} old_object
     * @param {cn_object} new_object
     */
    change_object(old_object, new_object) {
        var label = "Modification de " + old_object.get_product_category().toLowerCase();

        var keys = old_object.model_keys();
        var scene = this._scene;
        this.push_transaction(label, "", function () {
            scene.update();
            scene.update_deep();
        });
        this.push_item_set(old_object, keys);

        for (var k in keys)
            old_object[keys[k]] = new_object[keys[k]];

        this._scene.update();
        this._scene.update_deep();
    }

    //***********************************************************************************
    //**** remove selection
    //***********************************************************************************
    remove_selection() {
		this.remove_elements(this._controller.get_selection());
	}

    remove_elements(selection) {
        var obj = this;
        var scene = this._scene;

        //*** start undo redo transaction
		this.push_transaction("Supression d'éléments", "",function() {scene.update(); scene.update_deep(); if (obj._roof) obj.call("roof_change");});

		var update_area_context = false;
		//*** then remove elements
		var vertices = [];
		for (var i in selection)
		{
			var elt = selection[i];
			if (!elt.removable) continue;
			if (elt.constructor == cn_wall)
			{
				this.push_item_set(elt,[],function(w) {
						if (scene.walls.indexOf(w) >= 0)
							scene.remove_wall(w)
						else
							scene.insert_wall(w);
					});
				this._scene.remove_wall(elt);
			}
			else if (elt.constructor == cn_opening)
			{
				var wall = elt.wall;
				var index = wall.openings.indexOf(elt);
				if (index >= 0)
				{
					this.push_item_set(wall,"openings");
					wall.openings.splice(index,1);
				}
			}
			else if (elt.constructor == cn_slab_opening)
			{
				var index = this._scene.slab_openings.indexOf(elt);
				if (index >= 0)
				{
					this.push_item_set(this._scene,"slab_openings");
					this._scene.slab_openings.splice(index,1);
				}
			}
			else if (elt.constructor == cn_area_trimming)
			{
				var index = this._scene.area_trimmings.indexOf(elt);
				if (index >= 0)
				{
					this.push_item_set(this._scene,"area_trimmings");
					this._scene.area_trimmings.splice(index,1);
					update_area_context = true;
				}
			}
			else if (elt.constructor == cn_facing_trimming)
			{
				var index = this._scene.facing_trimmings.indexOf(elt);
				if (index >= 0)
				{
					this.push_item_set(this._scene,"facing_trimmings");
					this._scene.facing_trimmings.splice(index,1);
				}
			}
			else if (elt.constructor == cn_stairs)
			{
				var index = this._scene.stairs.indexOf(elt);
				if (index >= 0)
				{
					this.push_item_set(this._scene,"stairs");
					this._scene.stairs.splice(index,1);
				}
			}
			else if (elt.constructor == cn_object_instance)
			{
				var index = this._scene.object_instances.indexOf(elt);
				if (index >= 0)
				{
                    this.push_item_set(this._scene, "object_instances");
					this._scene.object_instances.splice(index,1);
				}
			}
			else if (elt.constructor == cn_beam)
			{
				var index = this._scene.beams.indexOf(elt);
				if (index >= 0)
				{
                    this.push_item_set(this._scene, "beams");
					this._scene.beams.splice(index,1);
				}
			}
            else if (elt.constructor == cn_pipe)
            {
                var index = this._scene.pipes.indexOf(elt);
                if (index >= 0)
				{
                    this.push_item_set(this._scene, "pipes");
                    this._scene.pipes.splice(index,1);
				}
            }
			else if (elt.constructor == cn_column)
			{
				var index = this._scene.columns.indexOf(elt);
				if (index >= 0)
				{
                    this.push_item_set(this._scene, "columns");
					this._scene.columns.splice(index,1);
				}
			}
			else if (elt.constructor == cn_vertex)
				vertices.push(elt);
			else if (elt.constructor == cn_roof_line)
			{
				if (scene.lines.indexOf(elt) < 0) continue;

				// @ts-ignore
				function undo_lines(lines) {
					obj.push_item_set(lines[0],[],function() {
							if (scene.lines.indexOf(lines[0]) >= 0)
								scene.remove_lines(lines)
							else
								scene.insert_lines(lines);
						});
					scene.remove_lines(lines);
				}

				if (!elt.is_border())
					undo_lines(scene.get_adjacent_lines(elt));
			}
			else if (elt.constructor == cn_roof_vertex)
				vertices.push(elt);
			else if (elt.constructor == cn_roof_opening)
			{
				var index = this._scene.openings.indexOf(elt);
				if (index >= 0)
				{
                    this.push_item_set(this._scene, "openings");
					this._scene.openings.splice(index,1);
				}
			}
			else if (elt.constructor == cn_roof_height)
			{
				var index = this._scene.heights.indexOf(elt);
				if (index >= 0)
				{
                    this.push_item_set(this._scene, "heights");
					this._scene.heights.splice(index,1);
				}
			}
			else if (elt.constructor == cn_sampling) {
                const samplingIndex = this._scene.storey.samplings.findIndex(sampling => sampling.ID === elt.ID);
                if (samplingIndex >= 0) {
                    this.push_item_set(this._scene.storey, "samplings");
                    this._scene.storey.samplings.splice(samplingIndex, 1);
                }
            }
			else if (elt.constructor == cn_marker) {
                const marker_index = this._scene.storey.markers.findIndex(marker => marker.ID === elt.ID);
                if (marker_index >= 0) {
                    this.push_item_set(this._scene.storey, "markers");
                    this._scene.storey.markers.splice(marker_index, 1);
                }
            }
			else if (elt.constructor == cn_background_map) {
                const map_index = this._scene.storey.background_maps.indexOf(elt);
                if (map_index >= 0) {
                    this.push_item_set(this._scene.storey, "background_maps");
                    this._scene.storey.background_maps.splice(map_index, 1);
                }
            }
		}

        const selections_ids = selection.map(sel => sel.ID);
        scene.building.zpsos.forEach(zpso => {
            const impacted_elements = zpso.elements.filter(el => selections_ids.includes(el.obj) && el.storey === scene.storey.ID);
            if (impacted_elements.length) {
                this.push_item_set(zpso, 'elements');
                const impacted_elements_id = impacted_elements.map(el => el.obj);
                zpso.elements = zpso.elements.filter(el => !impacted_elements_id.includes(el.obj));
            }
            const impacted_sections = zpso.sections.filter(el => selections_ids.includes(el.obj) && el.storey === scene.storey.ID);
            if (impacted_sections.length) {
                this.push_item_set(zpso, 'sections');
                const impacted_sections_id = impacted_sections.map(el => el.obj);
                zpso.sections = zpso.sections.filter(el => !impacted_sections_id.includes(el.obj));
            }
        });

		//*** inline function to keep the stack
		function merge_walls(w0,w1, vertex) {
			obj.push_item_set(vertex,[],function() {
					if (scene.walls.indexOf(w1) >= 0)
						scene.merge_wall(w0,w1)
					else
						scene.split_wall(w0,vertex,w1);
				});
			scene.merge_wall(w0,w1);
		}

		//*** inline function to keep the stack
		function merge_lines(l0,l1, vertex) {
			obj.push_item_set(vertex,[],function() {
					if (scene.lines.indexOf(l1) >= 0)
						scene.merge_line(l0,l1)
					else
						scene.split_line(l0,vertex,l1);
				});
			scene.merge_line(l0,l1);
		}

		//*** Special case for vertices
		for (var i in vertices)
		{
			var vertex = vertices[i];

			//*** check that vertex is already here
			if (this._scene.vertices.indexOf(vertex) < 0) continue;

			//@ts-ignore
			var items = (this._roof)?vertex.lines:vertex.walls;
			if (items.length != 2) continue;

			var v0 = items[0].other_vertex(vertex);
			var v1 = items[1].other_vertex(vertex);

			var old_position = cn_clone(vertex.position);
			vertex.position = cn_middle(v0.position,v1.position);
			this._scene.update();

			var check = this._scene.check_changes(items);
			vertex.position = old_position;
			this._scene.update();

			if (!check) continue;

			if (this._roof)
				merge_lines(items[0],items[1],vertex);
			else
				merge_walls(items[0],items[1],vertex);
		}

		this._scene.update();
		this._scene.update_deep();
		if (update_area_context)
		{
			this._building.area_contexts.forEach(a => a.update_deep(this._storey));
		}
		this._controller.update_element_count();
		this.clear_selection();
		this.call("selection_change");
		if (this._roof) this.call("roof_change");
		this._map.refresh();
	}


	//***********************************************************************************
	/**
	 * Copy selection to clipboard
	 */
	copy_selection() {
		if (this._controller.copy_selection())
		{
			console.log("copied to clipboard");
            this.call("element_copied");
			return true;
		}
		console.log("nothing to copy");
		return false;
	}


    //***********************************************************************************
	/**
	 * Cut selection to clipboard
	 */
    cut_selection() {
        if (this._controller.cut_selection()) {
            this.call("element_cut");
			return true;
        }
		return false;
    }

	//***********************************************************************************
	/**
	 * Copy selection to clipboard
	 */
	paste_clipboard() {
		var building = this._scene.building;
		var clipboard = building.clipboard.find(c => c.data_constructor == cn_scene);
		if (!clipboard)
		{
			console.log("nothing to paste");
			return false;
		}
		console.log("paste",clipboard);
		this._paste_handler = new cn_paste_handler(this._scene,clipboard, this);
		this._paste_handler.on("paste_end",() => {
			this._paste_handler = null;
			return false;
		});
		this._map.call("paste_start");
		this._controller.clear_selection();
		this._selection_change();
		return true;
	}

	//*******************************************************
	/**
	 * Current rotation angle in paste process
	 * @returns {number} rotation angle in degrees
	 */
	get_paste_rotation_angle()
	{
		if (!this._paste_handler) return 0;
		return this._paste_handler.get_paste_rotation_angle();
	}

	//***********************************************************************************
	//**** Open / Close tool
	//***********************************************************************************
	open_tool() {
        super.open_tool();
	}

	close_tool() {
		super.close_tool();
		this._paste_handler = null;
		this._scene.update_deep();
	}

	//***********************************************************************************
	//**** Draws  specific svg for the tool. Returns svg string
	//***********************************************************************************
	draw(camera) {

		var html = "";

		/** Draw only measures and  background */
		if (this._measure_points.length)
		{
			html += `<rect class="measure_tool_background" x="0" y="0" width="${camera._width}" height="${camera._height}" />`;
			this._measure_points.forEach(m => html += camera.draw_move_arrow(m, "move_arrow"));
			if (this._measure_points.length >= 2)
				html += camera.draw_measure(this._measure_points[0],this._measure_points[1],null,false,false);
			return html;
		}

		const space_labelizer = this._map._space_labelizer;
		if (space_labelizer)
		{
			space_labelizer.move_button =  (this._paste_handler == null && this._current_creation_tool == null);
			if (!space_labelizer.move_button)
				html += "<g>" + space_labelizer.draw(camera) + "</g>";
		}

		if (this._paste_handler)
			html += this._paste_handler.draw(camera);

		//*** Draw selection
		var selection = this._controller.get_selection();
		const selection_delegates = this._controller.get_selection_delegates();
		var mouseover = this._controller.get_mouseover();
		for (var draw_priority = 0;draw_priority <= 10; draw_priority++)
		{
			const mouseover_extras = ["mouseover"];
			for (var i in selection)
			{
				var op = selection[i];
				if (op.draw_priority != draw_priority) continue;
				if (op.constructor == cn_vertex)
				{
                	html += op.draw(camera, ["selected"], null,selection_delegates[i]);
				}
				else if (op != mouseover)
				{
                	let pic = !!op.pictures && !!op.pictures.length;
					html += op.draw(camera,["selected"], false, pic);
				}
				else mouseover_extras.push("selected");
			}

			if (mouseover && mouseover.draw_priority == draw_priority) {
				if (mouseover.constructor == cn_vertex)
				{
                	html += mouseover.draw(camera, mouseover_extras, this._controller.get_mouseover_delegate());
				}
				else
				{
                	let pic = !!mouseover.pictures && !!mouseover.pictures.length;
                	html += mouseover.draw(camera, mouseover_extras, false, pic);
				}
            }
		}

		//*** draw tool specifics
		if (this._current_creation_tool)
			html += this._current_creation_tool.draw(camera);

		if (space_labelizer && space_labelizer.move_button)
		{
			html += "<g>" + space_labelizer.draw(camera) + "</g>";
		}

		if (this._current_edition_tool)
			html += this._current_edition_tool.draw(camera);

		if (this._edit_box)
			html += this._edit_box.draw(camera);

		return html;
	}

	//***********************************************************************************
	//**** Mouse callbacks
	//***********************************************************************************

	click(ev) {
		if (this._measure_points.length)
		{
			this._new_measure();
			return true;
		}
		const res = (this._focus_handler)?this._focus_handler.click(ev):false;
		if (res || this._hard_focus) return res;

		this._controller.set_mouseover(null);

		//*** selection update
		this._controller.find_mouseover(ev.mouse_world,ev.camera.snap_world_distance);
		var mouseover = this._controller.get_mouseover();
		if (mouseover && mouseover.constructor == cn_space && mouseover.outside)
			mouseover = null;
		if (mouseover) {
            const isMultiSelection = ev.multi_selection || ev.ctrlKey;
			if (isMultiSelection && this._controller.get_selection().indexOf(mouseover) >= 0)
			{
				this._controller.unselect_element(mouseover);
			}
			else
			{
				this._controller.select_element(mouseover, !isMultiSelection,this._controller.get_mouseover_delegate());
			}
			this._selection_change();
			return true;
		}

		//*** No selection: clear it
		if (this._controller.get_selection().length > 0)
		{
			this._controller.clear_selection();
			this._selection_change();
			return true;
		}

		return true;
	}

	grab (ev) {
		if (this._measure_points.length)
		{
			return true;
		}
		this.move(ev);
		ev.drag_and_drop_element = null;

		const res = (this._focus_handler)?this._focus_handler.grab(ev):false;
		if (res || this._hard_focus) return res;

		this._controller.set_mouseover(null);
		this._start_translation = null;
		this._grabbing_tool = null;
		if (ev.ctrlKey) return false;

		//*** Group translation */
		if (this._controller.get_selection().length > 1 && this._current_creation_tool == null)
		{
			var elt = this._controller.find_element(ev.mouse_world,ev.camera.snap_world_distance);
			if (elt && elt.constructor != cn_space && this._controller.get_selection().indexOf(elt) >= 0)
			{
				this._start_translation = cn_clone(ev.mouse_world);
				this._drag_code++;
				this._edition_tools.forEach(t => t.start_translation(ev));
				return true;
			}
		}

		return false;
	}

	drag (ev) {
		if (this._measure_points.length)
		{
			this._update_measure(ev);
			return true;
		}
		if (ev.drag_and_drop_element)
			return this.drag_element(ev);

		const res = (this._focus_handler)?this._focus_handler.drag(ev):false;
		if (res || this._hard_focus) return res;

		//*** Group translation */
		if (this._start_translation)
		{
			var scene = this._scene;
			this.push_transaction("Déplacement conjoint",scene.ID + this._drag_code,function(){scene.update();scene.update_deep();});

			var offset = cn_sub(ev.mouse_world,this._start_translation);
			this._start_translation = cn_clone(ev.mouse_world);

			this._edition_tools.forEach(t => t.translate(ev,offset));
		}
		return true;
	}

	drag_element(ev) {

        if (!this._controller) return false;

		//*** We select the element if not already done */
		if (this._controller.get_selection().length != 1 || this._controller.get_selection()[0] != ev.drag_and_drop_element) {
			this._controller.select_element(ev.drag_and_drop_element, true);
			this._selection_change();
		}

		//*** maybe the space labelizer manages the event ? */
		const space_labelizer = this._map._space_labelizer;
		if (ev.drag_and_drop_owner == space_labelizer && space_labelizer.drag_element(ev))
			return true;

		//*** Maybe the space labelizer can manage drag from now on ? */
		if (space_labelizer && space_labelizer.grab_element(ev))
		{
			//*** we warn the preivous owner to stop */
			ev.drag_and_drop_owner.drag_element_stop(ev);

			//*** new owner is the space labelizer */
			ev.drag_and_drop_owner = space_labelizer;

			//*** we ask the labelizer to perform the drag */
			this._map._space_labelizer.drag_element(ev)
			return true;
		}

		//*** current tool must get the drag and drop */
		const current_tool = (this._current_creation_tool)?this._current_creation_tool:this._current_edition_tool;
		if (current_tool == null) return false;

		if (current_tool != ev.drag_and_drop_owner)
		{
			//*** we warn the preivous owner to stop */
			ev.drag_and_drop_owner.drag_element_stop(ev);

			//*** new owner is the edition tool */
			current_tool.grab_element(ev, true);
			ev.drag_and_drop_owner = current_tool;
		}

		//*** we perform the drag */
		return current_tool.drag_element(ev);
	}

	drop (ev) {
		if (this._measure_points.length)
		{
			this._new_measure();
			return true;
		}
		if (ev.drag_and_drop_element)
		{
			const res = ev.drag_and_drop_owner.drop_element(ev);
			ev.drag_and_drop_element = null;
			return res;
		}

		const res = (this._focus_handler)?this._focus_handler.drop(ev):false;
		if (res || this._hard_focus) return res;

		//*** Group translation */
		if (this._start_translation)
		{
			this._edition_tools.forEach(t => t.finalize_translation(ev));
			this._start_translation = null;
			this._scene.update();
			this._scene.update_deep();
		}
		return true;
	}

	move (ev) {
		if (this._measure_points.length)
		{
			this._update_measure(ev);
			return true;
		}
		this._focus_handler = null;
		this._hard_focus = false;
		this._controller.set_mouseover(null);

		//*** Paste handler always win */
		if (this._paste_handler)
		{
			this._hard_focus = true;
			if (this._paste_handler.move(ev))
			{
				this._focus_handler = this._paste_handler;
				return true;
			}
			return false;
		}

		//*** creation always win */
		if (this._current_creation_tool)
		{
			this._hard_focus = true;
			if (this._current_creation_tool.move(ev))
			{
				this._focus_handler = this._current_creation_tool;
				return true;
			}
			return false;
		}

		//*** Maybe the edit box ? */
		if (this._edit_box && this._edit_box.move(ev))
		{
			this._focus_handler = this._edit_box;
			return true;
		}

		//*** Maybe the edition tool ? */
		if (this._current_edition_tool && this._current_edition_tool.move(ev))
		{
			this._focus_handler = this._current_edition_tool;
			return true;
		}

		//*** Maybe the space labelizer ? */
		if (this._map._space_labelizer && this._map._space_labelizer.move(ev))
		{
			this._focus_handler = this._map._space_labelizer;
			return true;
		}

		//*** let the controller work */
		this._controller.find_mouseover(ev.mouse_world,ev.camera.snap_world_distance);
		return true;
	}

	area_select(area_ev) {

		this._controller.area_select(area_ev.box_world, true);

		this._selection_change();

		return true;
	}

	/**
	 * Manage key events
	 * @param {object} ev
	 * @returns {boolean} true if element was used
	 */
	keydown(ev)
	{
		if (ev.key == 'Escape' && this._measure_points.length)
		{
			this.set_measure_mode(false);
			return true;
		}

		if (this._paste_handler && this._paste_handler.keydown(ev))
			return true;

		if (this._current_creation_tool)
			return this._current_creation_tool.keydown(ev);


        if (ev.ctrlKey) {
            if (ev.key === 'a') {
                this._controller.select_all();
                this._selection_change();
                return true;
            } else if (ev.key === 'c') {
                this.copy_selection();
                return true;
            } else if (ev.key === 'x') {
                this._map.cut_selection();
                return true;
            } else if (ev.key === 'v') {
                this.paste_clipboard();
			return true;
            }
        }

		//*** Manage backspace and delete */
		if (ev.key == 'Backspace' || ev.key == 'Delete')
		{
			var nb = this.get_removable_selection().length;
			if (nb > 0)
			{
				this.call("delete_selection");
				return true;
			}
		}

		//*** Maybe the main tool uses the key event ?  */
		if (this._current_edition_tool && this._current_edition_tool.keydown(ev))
			return true;

	}

	set_measure_mode(active)
	{
		if (active)
		{
			this._measure_points = [[0,0]];
			this.call("measure_mode",true);
		}
		else
		{
			this._measure_points = [];
			this.call("measure_mode",false);
		}
	}

	/**
	 * Update measure points
	 * @param {*} ev
	 * @returns
	 */
	_update_measure(ev)
	{
		if (this._measure_points.length == 0) return;
		const snap = new cn_snap(ev.mouse_world,ev.camera.snap_world_distance,null,ev.camera);
		if (this._scene && this._scene.constructor == cn_scene)
		{
			snap.snap_elements = this._scene.spaces;
			snap.snap_elements = snap.snap_elements.concat(this._scene.stairs);
			snap.snap_elements = snap.snap_elements.concat(this._scene.beams);
			snap.snap_elements = snap.snap_elements.concat(this._scene.columns);
			snap.snap_elements = snap.snap_elements.concat(this._scene.pipes);
			snap.snap_elements = snap.snap_elements.concat(this._scene.slab_openings);
		}
		else if (this._scene && this._scene.constructor == cn_roof)
		{
			snap.snap_elements = this._scene.lines;
			snap.snap_elements = snap.snap_elements.concat(this._scene.heights);
			snap.snap_elements = snap.snap_elements.concat(this._scene.openings);
		}

		snap.snap_point();
		this._measure_points[this._measure_points.length-1] = snap.position;
	}

	_new_measure()
	{
		const p = this._measure_points[this._measure_points.length-1];
		if (this._measure_points.length > 2) this._measure_points = [p];
		this._measure_points.push(cn_clone(p));
	}

	//***********************************************************************************
	//**** Internal : Called when selection changed
	//***********************************************************************************
	_selection_change() {
		//*** Nothing to do if we are in creation */
		const selection = this._controller.get_selection();
		if (this._current_creation_tool)
		{
			if (selection.length==0)
				this._current_creation_tool._terminate_edition();
			return;
		}

		//*** update tools
		this._edit_box = null;
		if (this._current_edition_tool) this._current_edition_tool.close_tool();
		this._current_edition_tool = null;

		this.call("selection_change");
		if (selection.length == 0)  return;

		this._current_edition_tool = this._edition_tools.find(t => t.on_selection_change());
		if (this._current_edition_tool)
		{
			this._current_edition_tool.open_tool();
			return;
		}
		this._edit_box = new cn_edit_box(this,selection,false);
	}

}


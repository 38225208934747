// @ts-check
"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** fence opening type
//***********************************************************************************
//***********************************************************************************

import {cn_element_type} from "./cn_element_type";
import {cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_param_group, cn_configuration_tab} from "./cn_configuration";
import {fh_add, fh_clone, fh_extruded_polygon, fh_mul, fh_polygon} from "@acenv/fh-3d-viewer";
import {code_to_label} from "./cn_opening_type";
import {cn_element_type_visitor} from '..';

export const FENCE_MATERIAL_LIST = [
    {label: 'Acier', code: 'steel'},
    {label: 'Bois', code: 'wood'}
];

export const FENCE_OPENING_LIST = [
    {label: 'Battante', code: 'french'},
    {label: 'Glissante', code: 'sliding'}
];

export const FENCE_LOCK_LIST = [
	{label:'Aucune', code:'none'},
	{label:'A clé', code:'key'},
	{label:'Electronique', code:'electronic'}
];

const FOT_PILAR_WIDTH = 0.1;

export class cn_fence_opening_type extends cn_element_type  {
	//***********************************************************************************
	/**
	 * constructor
	 */
	constructor() {
		super();
		this.class_name="cn_fence_opening_type";
		this.name = "";
		this.width = 0.9;
		this.height = 1;

		this.material = "steel";
		this.opening = "french";
		this.lock = "key";

		//*** Elements to match opening drawing */
		this.category = "door";
		this.panels = 1;
		this.z = 0;
	}

	//***********************************************************************************
	/**
	 * Retrns default fence opening types
	 * @returns {cn_fence_opening_type[]}
	 */
	static default_types() {
		var lst = [];

        fot = new cn_fence_opening_type();
		fot.width=0.9;
		fot.height=1.2;
		fot.material="wood";
		fot.opening="french";
		fot.lock="key";
		lst.push(fot);

		fot = new cn_fence_opening_type();
		fot.width=0.9;
		fot.height=1.2;
		fot.material="steel";
		fot.opening="french";
		fot.lock="electronic";
		lst.push(fot);

        var fot = new cn_fence_opening_type();
		fot.width=3;
		fot.height=2;
		fot.material="steel";
		fot.opening="sliding";
		fot.lock="electronic";
		lst.push(fot);

		return lst;
	}

	//***********************************************************************************
	//**** Clone
	//***********************************************************************************
	clone() {
        var c = new cn_fence_opening_type();
        c.name = this.name;
        c.width = this.width;
        c.height = this.height;
        c.material = this.material;
        c.opening = this.opening;
        c.lock = this.lock;

        return c;
    }

    //***********************************************************************************
    get_generic_label() {
        return "Type de portail";
    }

    //***********************************************************************************
    //**** keys
    //***********************************************************************************
    model_keys() {
        return ["name", "width", "height", "material", "opening", "lock"];
    }

    //***********************************************************************************
    /**
     * serialize
     * @returns {object}
     */
    serialize() {
        var json = {};
        json.class_name = 'cn_fence_opening_type';
        json.ID = this.ID;
        json.name = this.name;
        json.width = this.width;
        json.height = this.height;

		json.material = this.material;
		json.opening = this.opening;
		json.lock = this.lock;

		return json;
	}

	//***********************************************************************************
	/**
	 * Unserialize
	 * @param {object} json
	 * @returns {cn_fence_opening_type}
	 */
	static unserialize(json) {
		if (typeof(json) != 'object') return null;
		if (typeof(json.ID) != 'string') return null;
		if (json.class_name != 'cn_fence_opening_type') return null;
		if (typeof(json.width) != 'number') return null;
		if (typeof(json.height) != 'number') return null;

		var ot = new cn_fence_opening_type();
		ot.ID = json.ID;
		ot.width = json.width;
		ot.height = json.height;
		if (typeof(json.name) == 'string') ot.name = json.name;
		if (typeof(json.material) == 'string') ot.material = json.material;
		if (typeof(json.opening) == 'string') ot.opening = json.opening;
		if (typeof(json.lock) == 'string') ot.lock = json.lock;
		return ot;
	}

	//***********************************************************************************
	//**** Returs visible label
	//***********************************************************************************
	get_label() {
		if (this.name != "") return this.name;
		var html = (this.width < 1.5)?"Portillon":"Portail";
		html += " " + code_to_label(this.material,FENCE_MATERIAL_LIST);
		html += " " + code_to_label(this.lock,FENCE_LOCK_LIST);
		return html +" " +  (this.width * 100).toFixed(0) + " x " + (this.height * 100).toFixed(0);
	}

	/**
	 * Computes symetry left / right
	 * @returns {boolean}
	 */
	 get_x_symetry() {
		return false;
	}

	/**
	 * Computes symetry front / back
	 * @returns {boolean}
	 */
	 get_y_symetry() {
		return this.opening != "french";
	}

	//***********************************************************************************
	//**** Returns description
	//***********************************************************************************
    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
    get_description() {
		var description = [];

		description.push({label:"Largeur", value: this.width*100, decimals:0, unit:"cm"});

		description.push({label:"Hauteur", value: this.height*100, decimals:0, unit:"cm"});

		var material_label = code_to_label(this.material,FENCE_MATERIAL_LIST);
		if (material_label)
			description.push({label:"Matériau",value:material_label});

		var opening_label = code_to_label(this.opening,FENCE_OPENING_LIST);
		if (opening_label)
			description.push({label:"Ouverture",value:opening_label});

		var lock_label = code_to_label(this.lock,FENCE_LOCK_LIST);
		if (lock_label)
			description.push({label:"Serrure",value:lock_label});

		return description;
	}

	//***********************************************************************************
	//**** svg icon
	//***********************************************************************************
	draw_svg_icon(w, h)
	{
		var html = "";

		var ow = this.width;
		var oh = this.height + 0.12;
		var scale = (ow / oh > w / h)? w / (1.05 * ow):h/(1.05*oh);

		this._build_geometry().forEach(brick => {
			var x = brick.position[0] * scale + w/2 - ow * scale /2;
			var y = h - scale * (brick.position[1] + brick.size[1]);
			html += `<rect class="fence_opening_${brick.material}" x="${x}" y="${y}" width="${brick.size[0]*scale}" height="${brick.size[1]*scale}" />`;
		});

		return html;
	}

	get_area()
	{
		return this.width * this.height;
	}

	//***********************************************************************************
	/**
	 * Builds 3D geometry as a list of extrusions
	 * @returns {fh_extruded_polygon[]}
	 */
	build_extruded_polygons() {
		const colors ={
			steel: [19/255, 82/255, 29/255,1],
			wood: [214/255, 160/255, 97/255,1],
			lock_electronic: [255/255, 183/255, 3/255,1],
			black_steel: [46/255,52/255,59/255,1]
		}
		return this._build_geometry().map(brick =>
			fh_extruded_polygon.build_brick(brick.position,brick.size[0],brick.size[1],brick.size[2],colors[brick.material])
		);
	}

	/**
	 * Computed a correct size for each panel
	 * @returns {number[]}
	 */
	compute_panel_widths(available_size = -1)
	{
		return [this.width];
	}

    //***********************************************************************************
    //**** 3D geometry
    //***********************************************************************************
    /**
     * Builds the piercing polygon
     * @param {number[]} origin : 3D origin of the opening (at ground level)
     * @param {number[]} dx : x normalized direction of the opening (along opening width)
     * @param {number[]} dy : y normalized direction of the opening (along thickness)
     * @param {number[]} dz : vertical direction of the opening
     * @returns {fh_polygon} output polygon
     */
    build_piercing_polygon(origin, dx, dy, dz) {
        var z0 = this.z;
        var z2 = this.z + this.height;
        var z1 = z2;

        var p0 = fh_add(origin,fh_mul(dz,z0));
        var p1 = fh_add(origin,fh_mul(dz,z1));
        var p2 = fh_add(origin,fh_mul(dz,z2));
        var contour_vertices = [];
        contour_vertices.push(fh_add(p1,fh_mul(dx,this.width)));
        contour_vertices.push(fh_add(p0,fh_mul(dx,this.width)));
        contour_vertices.push(p0);
        contour_vertices.push(p1);

        var polygon = new fh_polygon(origin,dy);
        polygon.add_contour(contour_vertices);
        return polygon;
    }

	//***********************************************************************************
	/**
	 * Builds 3D geometry as a list of bricks : used both for 3D and 2D
	 * @returns {object[]}
	 */
	_build_geometry() {
		var bricks = [];

		const pilar_width = 0.1;
		bricks.push({position:[0,0,0],size:[pilar_width,this.height,pilar_width],material:this.material});
		bricks.push({position:[this.width - pilar_width,0,0],size:[pilar_width,this.height,pilar_width],material:this.material});

		//*** Build frame */
		const bottom = (this.opening=="sliding")?0.1:0.03;
		const top = (this.opening=="sliding")?0.1:0;
		const frame_width= 0.05;
		const frame_thickness= 0.04;
		const filling_height = this.height - top - bottom - 2*frame_width;
		var filling_width = this.width - 2*pilar_width;

		//** horizontal frame */
		bricks.push({position:[pilar_width,bottom,pilar_width/2 - frame_thickness/2],size:[filling_width,frame_width,frame_thickness],material:this.material});
		bricks.push({position:[pilar_width,this.height - top - frame_width,pilar_width/2 - frame_thickness/2],size:[filling_width,frame_width,frame_thickness],material:this.material});

		//*** vertical frame */
		if (this.opening == "french")
		{
			filling_width -= 2 * frame_width;
			bricks.push({position:[pilar_width,bottom+frame_width,pilar_width/2 - frame_thickness/2],size:[frame_width,filling_height,frame_thickness],material:this.material});
			bricks.push({position:[this.width - pilar_width - frame_width,bottom+frame_width,pilar_width/2 - frame_thickness/2],size:[frame_width,filling_height,frame_thickness],material:this.material});
		}

		//*** vertical bars */
		var bar_size = [0.03,filling_height,0.03];
		bar_size[0] =  (this.material=="wood")?0.1:0.03;
		var bar_position = [pilar_width,bottom + frame_width,pilar_width/2 - bar_size[2]/2];
		var bar_spacing = 0.11;

		const nb_bars = 1+Math.ceil((filling_width - bar_spacing) / (bar_size[0] + bar_spacing));
		bar_spacing = (filling_width - (nb_bars-1) * bar_size[0]) / nb_bars;
		bar_position[0] += bar_spacing;
		if (this.opening == "french") bar_position[0] += frame_width;
		for (var n=0;n<nb_bars-1;n++)
		{
			bricks.push({position:fh_clone(bar_position),size:fh_clone(bar_size),material:this.material});
			bar_position[0] += bar_size[0] +  bar_spacing;
		}

		//*** lock */
		if (this.lock == "electronic")
		{
			bricks.push({position:[this.width-pilar_width,this.height + 0.02,0],size:[pilar_width,pilar_width,pilar_width],material:"lock_electronic"});
		}
		else if (this.lock == "key")
		{
			const lock_thickness = 0.06;
			var y = 1;
			if (y > this.height - top - frame_width) y = this.height - top - frame_width;
			var x = this.width-pilar_width - 0.1;
			bricks.push({position:[x,y,pilar_width/2 - lock_thickness/2],size:[pilar_width,pilar_width,lock_thickness],material:"black_steel"});
		}
		return bricks;
	}

	//***********************************************************************************
	//**** Get configuration
	//***********************************************************************************
	static configuration() {
		var configuration = new cn_configuration();

		//*** Global params
		var param_group = new cn_configuration_param_group("Dimensions","dimensions");
		configuration.add_param_group(param_group);
		param_group.add_param(new cn_configuration_param("Largeur","width",1,1000,"cm"));
		param_group.add_param(new cn_configuration_param("Hauteur","height",1,1000,"cm"));

		//***********************************************
		//*** Window tab
		var tab = new cn_configuration_tab("Ouverture","opening");
		configuration.add_tab(tab);

		//*** Material
		var material_list = new cn_configuration_line("Matériau","material");
		tab.add_line(material_list);
		FENCE_MATERIAL_LIST.forEach(elt => material_list.add_choice(new cn_configuration_choice(elt.label,elt.code)));

		//*** Opening
		var opening_list = new cn_configuration_line("Ouverture","opening");
		tab.add_line(opening_list);
		FENCE_OPENING_LIST.forEach(elt => opening_list.add_choice(new cn_configuration_choice(elt.label,elt.code)));

		//*** Lock
		var lock_list = new cn_configuration_line("Serrure","lock");
		tab.add_line(lock_list);
		FENCE_LOCK_LIST.forEach(elt => lock_list.add_choice(new cn_configuration_choice(elt.label,elt.code)));

		return configuration
	}

	//***********************************************************************************
	/**
	 * Builds or fill a configuration with data from thie element type
	 * @param {object} config : input config, or null if config to be created
	 * @returns {object} configuration filled
	 */
	fill_configuration(config = null)
	{
		var configuration = (config)?config:cn_fence_opening_type.configuration();

		var param_group = configuration.get_param_group("dimensions");
		param_group.set_param("width",this.width*100);
		param_group.set_param("height",this.height*100);

		//*** default to generic
		configuration.selection = 0;

		//*** default data */
		var tab = configuration.tabs[0];
		tab.get_line("material").set_selection(this.material);
		tab.get_line("opening").set_selection(this.opening);
		tab.get_line("lock").set_selection(this.lock);

		return configuration;
	}

	//***********************************************************************************
	/**
	 * Loads data from configuration and fills thie element type
	 * @param {object} configuration
	 * @returns {boolean} true on success
	 */
	load_configuration(configuration)
	{
		var param_group = configuration.get_param_group("dimensions");
		this.width = param_group.get_param("width").value / 100;
		this.height = param_group.get_param("height").value / 100;

		var configuration_tab = configuration.tabs[0];

		this.material = configuration_tab.get_line("material").get_selection();
		this.opening = configuration_tab.get_line("opening").get_selection();
		this.lock = configuration_tab.get_line("lock").get_selection();

		return true;
	}

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
     accept_visitor(element_type_visitor) {
        element_type_visitor.visit_fence_opening_type(this);
    }
}


import { DrawingPicture } from '@acenv/cnmap-angular-editor-lib';
import { Component, OnInit } from '@angular/core';
import { Bien } from 'src/app/model/bien.model';
import { Espace } from 'src/app/model/espace.model';
import { Intervention } from 'src/app/model/intervention.model';
import { BaseComponent, ConfirmationService, MongoUtils } from 'src/app/commons-lib';
import { MatDialog } from '@angular/material/dialog';
import { InterventionService } from '../../../../../services/intervention.service';
import { CnSpinnerService } from '../../../../shared/cn-spinner/service/cn-spinner.service';
import { ScreenshotSvg } from '../../../../../model/screenshot-svg.model';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap, map } from 'rxjs/operators';
import { EtatProgressionService } from '../../../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { DiagnosticService } from '../../../../../services/diagnostic.service';
import { ExportSvgDialogComponent } from '../../../../shared/map/export-svg-dialog/export-svg-dialog.component';
import { DialogUtils } from '../../../../../utils/dialog.utils';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { combineLatestOrEmpty } from 'src/app/utils/rxjs.utils';
import { cn_background_map } from '@acenv/cnmap-editor';
import { BackgroundMapApiService } from 'src/app/services/background-map-api.service';

@Component({
    selector: 'app-gestion-plan-polluant',
    templateUrl: './gestion-plan-polluant.component.html',
    styleUrls: ['./gestion-plan-polluant.component.scss'],
})
export class GestionPlanPolluantComponent extends BaseComponent implements OnInit {
    readonlyMode = false;
    hasEspace: boolean;

    private currentIntervention: Intervention;
    private espaces: Espace[];
    private biensWithPlan: Bien[];
    private backgroundMaps: DrawingPicture[] = [];

    currentDiagnostic: Diagnostic;

    constructor(
        private readonly matDialog: MatDialog,
        private readonly confirmationService: ConfirmationService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionService: InterventionService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly route: ActivatedRoute,
        private readonly diagnosticService: DiagnosticService,
        private readonly backgroundMapApiService: BackgroundMapApiService
    ) {
        super();
        // Définition de la méthode pour récupérer le contenu du backgroundMap
        (cn_background_map as any).image_id_to_url = (fileId) => {
            return (this.backgroundMaps.find((bgmu) => bgmu.fileId == fileId) || ({} as DrawingPicture)).imageUrl;
        };
    }

    ngOnInit(): void {
        // Récupération de l'intervention courante et de la liste des biens possédant des plans + screenshots
        combineLatest([this.getCurrentIntervention(), this.getCurrentDiagnostic()])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic]) => {
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(intervention, diagnostic);

                this.checkValidity();
            });
    }

    /**
     * Création d'un nouveau plan de repérage.
     */
    onClickAddNewScreenshot() {
        return this.matDialog
            .open(ExportSvgDialogComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    biens: this.biensWithPlan ? this.biensWithPlan : [],
                    espaces: this.espaces,
                    typePrestation: this.currentDiagnostic.typePrestation,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false && result.screenshotSvg) {
                    this.cnSpinnerService
                        .withSpinner(this.addScreenshot(result.screenshotSvg))
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe();
                }
            });
    }

    /**
     * Suppression d'un plan de repérage
     * @param screenshot
     */
    onClickDeleteScreenshot(screenshot: ScreenshotSvg) {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer ce plan de repérage ?', () => {
            this.cnSpinnerService
                .withSpinner(this.deleteScreenshot(screenshot.id), 'Suppression en cours...')
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe();
        });
    }

    /**
     * Ajout d'un plan de repérage au diagnostic
     * @param screenshotSvg
     * @private
     */
    private addScreenshot(screenshotSvg: ScreenshotSvg) {
        screenshotSvg.id = MongoUtils.generateObjectId();
        this.currentDiagnostic.screenshotsPlan.push(screenshotSvg);

        return this.diagnosticService.upsert(this.currentIntervention, this.currentDiagnostic);
    }

    /**
     * Suppression du screenshot + maj du diagnostic
     * @param screenshotId
     * @private
     */
    private deleteScreenshot(screenshotId: string): Observable<any> {
        if (this.currentDiagnostic.screenshotsPlan.length) {
            // Suppression du screenshot dans la ref de bien :
            this.currentDiagnostic.screenshotsPlan = this.currentDiagnostic.screenshotsPlan.filter(
                (it) => it.id != screenshotId
            );
            return this.diagnosticService.upsert(this.currentIntervention, this.currentDiagnostic);
        } else {
            return of();
        }
    }

    /**
     * Récupération de l'intervention courante, des backgroundMaps et de la liste des biens possédant des plans
     * @private
     */
    private getCurrentIntervention(): Observable<Intervention> {
        return this.interventionService.getCurrentIntervention().pipe(
            takeUntil(this.ngUnsubscribe),
            switchMap((intervention) => {
                return combineLatestOrEmpty([of(intervention), ...this.requestAllBackgroundImage(intervention)]);
            }),
            switchMap(([intervention]) => {
                this.currentIntervention = intervention;

                // Sauvegarde de l'ensemble des backgroundMap des biens
                intervention.relationInterventionBiens.forEach((relationInterventionBienTemp) => {
                    relationInterventionBienTemp.bien.backgroundMaps.forEach((backgroundMapTemp) => {
                        if (backgroundMapTemp.imageUrl) {
                            this.backgroundMaps.push(backgroundMapTemp);
                        }
                    });
                });

                // Présence de plans dans les biens de l'intervention ?
                this.biensWithPlan = this.currentIntervention.relationInterventionBiens
                    .filter((relation) => !!relation.bien.jsonPlan)
                    .flatMap((relation) => relation.bien);

                return of(intervention);
            })
        );
    }
    /**
     * renvoie la requette pour récupérer l'ensemble des backgroundImage d'une intervention :
     * lors d'un subscribe au résultat de cette fonction, on récupérera la liste des backgroundImage de toute l'intervention
     * @param intervention
     */
    requestAllBackgroundImage(intervention: Intervention): Observable<void>[] {
        const backgroundMapsObs$: Observable<void>[] = [];
        intervention.relationInterventionBiens.forEach((relationInterventionBienTemp) => {
            relationInterventionBienTemp.bien.backgroundMaps.forEach((backgroundMapTemp) => {
                backgroundMapsObs$.push(
                    this.backgroundMapApiService
                        .downloadBackgroundImage(
                            intervention.id,
                            relationInterventionBienTemp.id,
                            backgroundMapTemp.fileId
                        )
                        .pipe(
                            map((res) => {
                                if (res) {
                                    backgroundMapTemp.imageUrl = res.fileContent;
                                }
                            })
                        )
                );
            });
        });
        return backgroundMapsObs$;
    }
    /**
     * Récupération du diagnostic courant et de la liste des screenshots
     * @private
     */
    private getCurrentDiagnostic(): Observable<Diagnostic> {
        return this.diagnosticService.getCurrentDiagnostic().pipe(
            takeUntil(this.ngUnsubscribe),
            tap((diagnostic) => {
                this.currentDiagnostic = diagnostic;

                const contenuDiag = this.currentDiagnostic.contenuDiagnostic as any;
                this.espaces = contenuDiag.espaces ? contenuDiag.espaces.valeur : [];
                this.hasEspace = this.espaces.length > 0;
            })
        );
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.diagnosticService.getCurrentDiagnosticValue());
    }

    // TODO Gérer l'édition d'un screenshot
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationStore, BaseComponent, DateUtils, User } from 'src/app/commons-lib';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Document } from 'src/app/model/document.model';
import { InterventionFile, TypeReferenceFichier } from 'src/app/model/intervention-file.model';
import { Intervention } from 'src/app/model/intervention.model';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { CnSpinnerService } from '../../cn-spinner/service/cn-spinner.service';
import { FileUploaderOutput } from '../../file-uploader/wizy-file-uploader.component';
import { DocumentsService } from '../../../../services/documents.service';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentaireService } from '../../../../services/commentaire.service';

class FichierModalData {
    constructor(
        public idIntervention: string,
        public document: Document,
        public diagnosticId: string,
        public updateMode: boolean
    ) {}
}

@Component({
    selector: 'app-edit-fichier-modal',
    templateUrl: './edit-fichier-modal.component.html',
    styleUrls: ['./edit-fichier-modal.component.scss'],
})
export class EditFichierModalComponent extends BaseComponent implements OnInit {
    documentToEdit: Document;
    intervention: Intervention;
    formFichier: FormGroup;
    diagnosticId: string;

    fileUploaderOutput: FileUploaderOutput;

    updateMode = false;

    commentaireToSave: Commentaire;
    user: User;

    constructor(
        private readonly interventionService: InterventionService,
        private readonly formBuilder: FormBuilder,
        private readonly dialogRef: MatDialogRef<EditFichierModalComponent>,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly interventionFileService: InterventionFileService,
        private readonly documentsService: DocumentsService,
        private readonly commentaireService: CommentaireService,
        @Inject(MAT_DIALOG_DATA) public data: FichierModalData,
        private readonly authenticationStore: AuthenticationStore
    ) {
        super();
        dialogRef.disableClose = true;
        this.updateMode = this.data.updateMode;

        this.createFormFichier();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService.getCurrentIntervention(),
                    this.authenticationStore.getCurrentUser(),
                ]).pipe(takeUntil(this.ngUnsubscribe))
            )
            .subscribe(([intervention, user]) => {
                this.user = user;
                this.intervention = intervention;
                this.documentToEdit = this.data.document;
                this.diagnosticId = this.data.diagnosticId;
            });
    }

    /**
     * Création du formulaire pour éditer le fichier
     */
    createFormFichier() {
        this.formFichier = this.formBuilder.group({
            sourceTransmission: ['', [Validators.required]],
            idFichier: ['', [Validators.required]],
            nomFichier: ['', [Validators.required]],
            commentaireContent: ['', this.updateMode ? [Validators.required] : []],
        });
    }

    /**
     * Action lorsque le fichier a été modifié
     * @param fileUploaderOutput
     */
    onFileUploaderOutputChanged(fileUploaderOutput: FileUploaderOutput): void {
        if (fileUploaderOutput) {
            this.fileUploaderOutput = fileUploaderOutput;
        }
    }

    /**
     * Action lorsque l'utiliateur clique sur le bouton "annuler"
     */
    onClickCancel() {
        this.dialogRef.close(false);
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton pour valider
     * - Suppression du fichier (si on est en mode mise à jour)
     * - Upload du fichier
     * - Mise à jour du document et de l'intervention
     */
    onClickValidate() {
        // Si on est en mode mise à jour, on utilise au préalable la mécanique de suppression du fichier
        const obs$ = this.updateMode ? this.deleteFile() : of(undefined);

        this.cnSpinnerService
            .withSpinner(
                combineLatest([obs$]).pipe(
                    switchMap(() => {
                        // Mise à jour de l'intervention
                        return this.saveDocument();
                    })
                )
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.dialogRef.close({ document: this.documentToEdit });
            });
    }

    /**
     * Supprime le fichier courant et l'intervention file du document actuel
     * @private
     */
    private deleteFile(): any {
        if (this.documentToEdit.idFichier) {
            return this.interventionFileService
                .deleteInterventionFileAndFile(
                    this.intervention.id,
                    this.diagnosticId,
                    this.documentToEdit.id,
                    TypeReferenceFichier.FICHIER_DOCUMENT,
                    this.documentToEdit.idFichier
                )
                .pipe(
                    switchMap(() => {
                        // On enrichie l'historique des documents avec les infos de l'ancien doc + le commentaire.
                        const newComment = this.commentaireService.prepareLightCommentaireToSave(
                            'MOTIF_MODIFICATION_FICHIER',
                            this.formFichier.get('commentaireContent').value
                        );
                        this.documentToEdit.documentsHistory.push(
                            this.documentsService.prepareDataHistory(this.documentToEdit, newComment)
                        );

                        // On rajoute également le commentaire entier dans l'intervention
                        this.intervention.commentaires.push(newComment);

                        // On prépare l'objet document en supprimant les informations relatives au fichier.
                        this.documentsService.prepareDeleteFile(this.documentToEdit);

                        // Mise à jour de l'intervention ( le document est mis à jour par référence)
                        return this.interventionService.updateIntervention(this.intervention);
                    })
                );
        } else {
            return of(undefined);
        }
    }

    /**
     * On met à jour le document avec les données du formulaire
     * On enregistre l'interventionFile contenant le fichier pièce jointe s'il existe
     * @returns
     */
    private saveDocument(): Observable<InterventionFile> {
        // Prepare les données
        this.prepareDataDocumentToSave();

        // Si il y a un fichier, on crée l'interventionFile correspondant
        if (this.fileUploaderOutput) {
            // Upload du fichier et ajout d'une occurence InterventionFile
            return this.interventionFileService.uploadInterventionFile(
                this.fileUploaderOutput.interventionFile,
                this.fileUploaderOutput.dataUrl,
                this.fileUploaderOutput.fileName
            );
        } else {
            return of(null);
        }
    }

    /**
     * Prepare les données pour sauvegarder le document
     * @private
     */
    private prepareDataDocumentToSave() {
        if (this.fileUploaderOutput) {
            this.documentToEdit.dateCreation = DateUtils.localDateTimeString();
            this.documentToEdit.dateTransmission = DateUtils.localDateTimeString();
            this.documentToEdit.sourceTransmission = this.formFichier.get('sourceTransmission').value;
            this.documentToEdit.creePar = this.user.firstName + ' - ' + this.user.lastName;
            this.documentToEdit.idFichier = this.formFichier.get('idFichier').value;
            this.documentToEdit.nomFichier = this.formFichier.get('nomFichier').value;
        }
    }
}

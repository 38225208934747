<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <button mat-button class="px-0" (click)="back()">
            <lib-icon>arrow_back</lib-icon>
        </button>
        <div>
            <lib-icon [class]="'icon-before-text'">settings</lib-icon>
            <span *ngIf="mode === 'CREATE' && !isReadOnly">Création d'une prestation</span>
            <span *ngIf="mode === 'EDIT' && !isReadOnly">Edition d'une prestation</span>
            <span *ngIf="mode === 'DUPLICATE' && !isReadOnly">Duplication de la prestation</span>
            <span *ngIf="isReadOnly">Consultation de la prestation</span>
        </div>

        <div class="d-flex justify-content-end w-100">
            <button mat-button color="primary" class="mx-1" (click)="back()">
                <mat-icon>close</mat-icon>
                <span>{{ 'Annuler' | uppercase }}</span>
            </button>
            <button
                *ngIf="!isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="formReferencePrestation.invalid"
                (click)="validerPrestation()"
            >
                <lib-icon>check</lib-icon>
                <span>{{ 'Valider' | uppercase }}</span>
            </button>
            <button
                *ngIf="isReadOnly"
                mat-raised-button
                color="primary"
                [disabled]="referencePrestationInitial.etatPrestation === enumEtatWorkflow.ACTIF"
                (click)="editer()"
            >
                <mat-icon>edit</mat-icon>
                <span>{{ 'Éditer' | uppercase }}</span>
            </button>
        </div>
    </mat-toolbar>
</div>

<div class="equipement-container">
    <div class="h-100 row no-gutters p-4 scrollable-content" [formGroup]="formReferencePrestation">
        <!-- Colonne gauche-->
        <div class="col-xl-6 pr-2">
            <!-- Etat de l'équipement -->
            <mat-button-toggle-group
                appearance="legacy"
                *ngIf="!isReadOnly"
                formControlName="etatPrestation"
                class="mb-3"
            >
                <mat-button-toggle class="button-toggle-double INACTIF left-toggle" value="INACTIF"
                    >Inactif
                </mat-button-toggle>
                <mat-button-toggle class="button-toggle-double ACTIF right-toggle" value="ACTIF"
                    >Actif
                </mat-button-toggle>
            </mat-button-toggle-group>

            <div
                *ngIf="isReadOnly"
                class="statut-workflow consultation mb-3"
                [ngClass]="referencePrestationInitial.etatPrestation"
            >
                {{ referencePrestationInitial.etatPrestation | etatWorkflow }}
            </div>

            <mat-card class="p-2 mb-2 d-flex flex-column justify-content-between">
                <mat-card-content>
                    <div class="col-xl-12 m-0 mb-3 primary">Informations générales</div>
                    <!-- Nom de l'equipement -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom de la prestation</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="text" [readonly]="isReadOnly" formControlName="nom" />
                                <mat-error *ngIf="formReferencePrestation.get('nom').hasError('erreurNomPrestation')">
                                    Une autre prestation possède déjà le même nom.
                                </mat-error>
                                <app-mat-error [control]="formReferencePrestation.get('nom')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Type de prestation -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Type de prestation</div>
                        <div class="col-xl-8">
                            <app-custom-select
                                class="w-100"
                                formControlName="typePrestation"
                                [selectControl]="formReferencePrestation.get('typePrestation')"
                                [matFormFieldClass]="'w-100'"
                                [choices]="typesPrestation"
                                [addline]="false"
                                [readonly]="isReadOnly"
                                (selectedValueChange)="updatePrestationTemplatesList()"
                            >
                            </app-custom-select>
                            <app-mat-error [control]="formReferencePrestation.get('typePrestation')"></app-mat-error>
                        </div>
                    </div>

                    <!-- identifiant salesforce -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary required">Identifiant Salesforce</div>
                        <div class="col-xl-8 pr-2">
                            <mat-form-field class="w-100">
                                <input matInput type="text" [readonly]="isReadOnly" formControlName="idSalesforce" />
                                <app-mat-error [control]="formReferencePrestation.get('idSalesforce')"></app-mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Règles -->
                    <div class="d-flex p-2 flex-column align-items-center">
                        <div class="col-xl-12 mat-subheading-2 m-0 primary required">Règles de conformité</div>
                        <div *ngIf="mode === 'DUPLICATE'" class="col-xl-11 d-flex p-2 align-items-center">
                            <div class="col-xl-4 mat-subheading-2 m-0 primary">
                                Garder les règles de {{ referencePrestationInitial.nom }}?
                            </div>
                            <div class="col-xl-8 pl-0">
                                <div class="col-xl-4">
                                    <mat-button-toggle-group formControlName="regle" class="mb-3" appearance="legacy">
                                        <mat-button-toggle
                                            class="button-toggle-double INACTIF left-toggle"
                                            value="false"
                                            >Non
                                        </mat-button-toggle>
                                        <mat-button-toggle class="button-toggle-double ACTIF right-toggle" value="true">
                                            Oui
                                        </mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                mode === 'CREATE' ||
                                (mode === 'DUPLICATE' && formReferencePrestation.controls['regle'].value === 'false')
                            "
                            class="col-xl-12 mat-subheading-2 m-0 mat-error"
                        >
                            Vous ne pouvez pas définir de nouvelles règles pour le moment
                        </div>
                        <div *ngIf="mode === 'EDIT'" class="col-xl-12 mat-subheading-2 m-0 mat-error">
                            Vous ne pouvez pas modifier les règles pour le moment
                        </div>
                    </div>

                    <!-- Option plan -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-xl-4 mat-subheading-2 m-0 primary">Activer l'option plan</div>
                        <div class="col-xl-8">
                            <mat-button-toggle-group
                                appearance="legacy"
                                *ngIf="!isReadOnly"
                                formControlName="optionPlanActive"
                                class="mb-3"
                            >
                                <mat-button-toggle class="button-toggle-double ACTIF left-toggle" [value]="true"
                                    >Oui
                                </mat-button-toggle>
                                <mat-button-toggle class="button-toggle-double INACTIF right-toggle" [value]="false"
                                    >Non
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                            <div
                                *ngIf="isReadOnly && referencePrestationInitial.optionPlanActive"
                                class="statut-workflow consultation mb-3 ACTIF"
                            >
                                Oui
                            </div>
                            <div
                                *ngIf="isReadOnly && !referencePrestationInitial.optionPlanActive"
                                class="statut-workflow consultation mb-3 INACTIF"
                            >
                                Non
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <!--Colonne droite-->
        <div class="col-xl-6 pl-2">
            <!------------ Pictogramme ------------->
            <mat-card class="d-flex w-100 p-2 mb-2">
                <mat-card-content class="w-100">
                    <div class="col-xl-12 m-0 mb-3 primary">Pictogramme (57 x 57 pixels max)</div>
                    <mat-card *ngIf="!referencePrestation.pictoUrl && !isReadOnly" class="d-flex w-50 action-card">
                        <mat-card-content class="w-100">
                            <div>
                                <lib-file-uploader
                                    #fileUploader
                                    [isImage]="true"
                                    (startUpload)="uploadPictoReferencePrestation($event)"
                                    (uploadFinished)="uploadFileFinish('pictogramme', $event)"
                                ></lib-file-uploader>
                                <mat-progress-bar
                                    [hidden]="!(fileUploader.uploadRunning | async)"
                                    [value]="fileUploader.uploadProgress | async"
                                ></mat-progress-bar>
                                <button class="w-100" mat-button (click)="fileUploader.selectFile()">
                                    <mat-icon>add_a_photo</mat-icon>
                                    <br />{{ 'Ajouter le pictogramme de la prestation' }}
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <div *ngIf="referencePrestation.pictoUrl" class="pl-5">
                        <button
                            *ngIf="!isReadOnly"
                            mat-icon-button
                            class="position-absolute align-self-end button-group primary"
                            (click)="editPicture = true"
                        >
                            <lib-icon class="icon-small">edit</lib-icon>
                        </button>
                        <div *ngIf="editPicture && !isReadOnly">
                            <div class="position-absolute align-self-end button-group">
                                <button mat-icon-button color="warn" (click)="editPicture = false" matTooltip="Annuler">
                                    <lib-icon class="icon-small">cancel</lib-icon>
                                </button>
                                <button
                                    mat-icon-button
                                    color="warn"
                                    (click)="deletePictoReferencePrestation()"
                                    matTooltip="Supprimer le pictogramme de la prestation"
                                >
                                    <lib-icon class="icon-small">delete</lib-icon>
                                </button>
                                <lib-file-uploader
                                    #fileUploader
                                    [isImage]="true"
                                    (startUpload)="uploadPictoReferencePrestation($event)"
                                    (uploadFinished)="uploadFileFinish('pictogramme', $event)"
                                ></lib-file-uploader>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    (click)="fileUploader.selectFile()"
                                    matTooltip="Changer le pictogramme de la prestation"
                                >
                                    <lib-icon class="icon-small">add_a_photo</lib-icon>
                                </button>
                            </div>
                        </div>
                        <img
                            [src]="referencePrestation.pictoUrl"
                            alt="Pictogramme de la prestation"
                            class="img-fluid"
                            style="max-width: 100%; max-height: 300px"
                        />
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="d-flex w-100 p-2 mb-2">
                <mat-card-content class="w-100">
                    <!-- Presets de reportage photos -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-4 mat-subheading-2 m-0 primary">Reportages photos</div>
                        <div class="col-8 pr-2">
                            <mat-form-field class="w-100" [class.mat-input-readonly]="isReadOnly">
                                <mat-select
                                    [disabled]="isReadOnly"
                                    [class.readonly]="isReadOnly"
                                    formControlName="presetsReportagePhotosAuto"
                                    multiple
                                >
                                    <mat-option *ngFor="let preset of listePresets" [value]="preset">
                                        {{ preset | presetPhoto }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="d-flex w-100 p-2 mb-2">
                <mat-card-content class="w-100">
                    <!-- Presets d'exports SVG' -->
                    <div class="d-flex p-2 align-items-center">
                        <div class="col-4 mat-subheading-2 m-0 primary">Types d'export de plan</div>
                        <div class="col-8 pr-2">
                            <mat-form-field class="w-100" [class.mat-input-readonly]="isReadOnly">
                                <mat-select
                                    [disabled]="isReadOnly"
                                    [class.readonly]="isReadOnly"
                                    formControlName="presetsExportScreenshot"
                                    multiple
                                >
                                    <mat-option *ngFor="let presetSvg of listePresetsExport" [value]="presetSvg.id">
                                        {{ presetSvg.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <!---------- Templates ---------->
            <!---------- Template pour le Rapport ---------->
            <ng-container *ngIf="currentTypesPrestation">
                <lib-file-uploader
                    #fileUploaderTemplate
                    (startUpload)="uploadTemplateRapport($event, typeReport.REPORT, fileUploaderTemplate)"
                    (uploadFinished)="uploadFileTemplateRapportFinish($event)"
                >
                </lib-file-uploader>

                <mat-card class="w-100 position-relative p-2 mb-2">
                    <mat-card-content>
                        <div class="align-items-center">
                            <div class="col-xl-12 m-0 mb-3 primary">Template de rapport (Format .docx obligatoire)</div>

                            <div class="p-2 d-flex align-items-center no-gutters justify-content-between">
                                <div class="w-30 d-flex flex-column">
                                    <div class="d-flex align-items-baseline">
                                        <div
                                            class="mat-subheading-2 m-0 primary pl-3"
                                            *ngIf="
                                                (referencePrestation.templates | prestation_rapport: typeReport.REPORT)
                                                    ?.document?.idFichier
                                            "
                                        >
                                            Date :
                                        </div>
                                        <div class="card-text pl-2">
                                            {{
                                                (referencePrestation.templates | prestation_rapport: typeReport.REPORT)
                                                    ?.document?.dateTransmission | date: 'longDate'
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="w-70">
                                    <div class="d-flex flex-wrap align-items-center justify-content-between">
                                        <div>
                                            <div
                                                *ngIf="
                                                    (
                                                        referencePrestation.templates
                                                        | prestation_rapport: typeReport.REPORT
                                                    )?.document?.idFichier
                                                "
                                                class="ml-3 d-flex align-items-center c-pointer"
                                                (click)="onClickOpenDocument(typeReport.REPORT)"
                                            >
                                                <span class="file-icon-container">
                                                    <mat-icon
                                                        [ngClass]="
                                                            (
                                                                referencePrestation.templates
                                                                | prestation_rapport: typeReport.REPORT
                                                            )?.document.nomFichier | fileExtension
                                                        "
                                                    >
                                                        insert_drive_file</mat-icon
                                                    >
                                                    <span class="file-extension">{{
                                                        (
                                                            referencePrestation.templates
                                                            | prestation_rapport: typeReport.REPORT
                                                        )?.document.nomFichier | fileExtension
                                                    }}</span>
                                                </span>
                                                <b>{{
                                                    (
                                                        referencePrestation.templates
                                                        | prestation_rapport: typeReport.REPORT
                                                    )?.document.nomFichier | filenameWithoutExtension
                                                }}</b>
                                            </div>
                                            <div
                                                *ngIf="
                                                    !(
                                                        referencePrestation.templates
                                                        | prestation_rapport: typeReport.REPORT
                                                    )?.document?.idFichier
                                                "
                                                class="ml-3 c-pointer card-subtitle-bold"
                                                (click)="
                                                    !isReadOnly
                                                        ? onClickSelectFile(typeReport.REPORT, fileUploaderTemplate)
                                                        : ''
                                                "
                                            >
                                                Aucun fichier attaché
                                            </div>
                                        </div>
                                        <div>
                                            <ng-container
                                                *ngIf="
                                                    (
                                                        referencePrestation.templates
                                                        | prestation_rapport: typeReport.REPORT
                                                    )?.document?.idFichier && !isReadOnly
                                                "
                                            >
                                                <button
                                                    mat-icon-button
                                                    color="primary"
                                                    (click)="onClickOpenDocument(typeReport.REPORT)"
                                                >
                                                    <mat-icon>remove_red_eye</mat-icon>
                                                </button>
                                                <button
                                                    mat-icon-button
                                                    color="warn"
                                                    (click)="onClickDeleteTemplateRapport(typeReport.REPORT)"
                                                >
                                                    <mat-icon>clear</mat-icon>
                                                </button>
                                            </ng-container>
                                            <button
                                                *ngIf="!isReadOnly"
                                                mat-icon-button
                                                color="primary"
                                                (click)="onClickSelectFile(typeReport.REPORT, fileUploaderTemplate)"
                                            >
                                                <mat-icon>attach_file</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-container>

            <!---------- Template pour le Bon de commande ---------->
            <ng-container *ngIf="['HAP_TERRAIN', 'HAP_VALIDATION'].includes(currentTypesPrestation)">
                <lib-file-uploader
                    #fileUploaderTemplateBonCommande
                    (startUpload)="
                        uploadTemplateRapport($event, typeReport.BON_COMMANDE, fileUploaderTemplateBonCommande)
                    "
                    (uploadFinished)="uploadFileTemplateRapportFinish($event)"
                >
                </lib-file-uploader>

                <mat-card class="w-100 position-relative p-2 mb-2">
                    <mat-card-content>
                        <div class="align-items-center">
                            <div class="col-xl-12 m-0 mb-3 primary">
                                Template du bon de commande (Format .docx obligatoire)
                            </div>

                            <div class="p-2 d-flex align-items-center no-gutters justify-content-between">
                                <div class="w-30 d-flex flex-column">
                                    <div class="d-flex align-items-baseline">
                                        <div
                                            class="mat-subheading-2 m-0 primary pl-3"
                                            *ngIf="
                                                (
                                                    referencePrestation.templates
                                                    | prestation_rapport: typeReport.BON_COMMANDE
                                                )?.document?.idFichier
                                            "
                                        >
                                            Date :
                                        </div>
                                        <div class="card-text pl-2">
                                            {{
                                                (
                                                    referencePrestation.templates
                                                    | prestation_rapport: typeReport.BON_COMMANDE
                                                )?.document?.dateTransmission | date: 'longDate'
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="w-70">
                                    <div class="d-flex flex-wrap align-items-center justify-content-between">
                                        <div>
                                            <div
                                                *ngIf="
                                                    (
                                                        referencePrestation.templates
                                                        | prestation_rapport: typeReport.BON_COMMANDE
                                                    )?.document?.idFichier
                                                "
                                                class="ml-3 d-flex align-items-center c-pointer"
                                                (click)="onClickOpenDocument(typeReport.BON_COMMANDE)"
                                            >
                                                <span class="file-icon-container">
                                                    <mat-icon
                                                        [ngClass]="
                                                            (
                                                                referencePrestation.templates
                                                                | prestation_rapport: typeReport.BON_COMMANDE
                                                            )?.document.nomFichier | fileExtension
                                                        "
                                                    >
                                                        insert_drive_file</mat-icon
                                                    >
                                                    <span class="file-extension">{{
                                                        (
                                                            referencePrestation.templates
                                                            | prestation_rapport: typeReport.BON_COMMANDE
                                                        )?.document.nomFichier | fileExtension
                                                    }}</span>
                                                </span>
                                                <b>{{
                                                    (
                                                        referencePrestation.templates
                                                        | prestation_rapport: typeReport.BON_COMMANDE
                                                    )?.document.nomFichier | filenameWithoutExtension
                                                }}</b>
                                            </div>
                                            <div
                                                *ngIf="
                                                    !(
                                                        referencePrestation.templates
                                                        | prestation_rapport: typeReport.BON_COMMANDE
                                                    )?.document?.idFichier
                                                "
                                                class="ml-3 c-pointer card-subtitle-bold"
                                                (click)="
                                                    !isReadOnly
                                                        ? onClickSelectFile(
                                                              typeReport.BON_COMMANDE,
                                                              fileUploaderTemplateBonCommande
                                                          )
                                                        : ''
                                                "
                                            >
                                                Aucun fichier attaché
                                            </div>
                                        </div>
                                        <div>
                                            <ng-container
                                                *ngIf="
                                                    (
                                                        referencePrestation.templates
                                                        | prestation_rapport: typeReport.BON_COMMANDE
                                                    )?.document?.idFichier && !isReadOnly
                                                "
                                            >
                                                <button
                                                    mat-icon-button
                                                    color="primary"
                                                    (click)="onClickOpenDocument(typeReport.BON_COMMANDE)"
                                                >
                                                    <mat-icon>remove_red_eye</mat-icon>
                                                </button>
                                                <button
                                                    mat-icon-button
                                                    color="warn"
                                                    (click)="onClickDeleteTemplateRapport(typeReport.BON_COMMANDE)"
                                                >
                                                    <mat-icon>clear</mat-icon>
                                                </button>
                                            </ng-container>
                                            <button
                                                *ngIf="!isReadOnly"
                                                mat-icon-button
                                                color="primary"
                                                (click)="
                                                    onClickSelectFile(
                                                        typeReport.BON_COMMANDE,
                                                        fileUploaderTemplateBonCommande
                                                    )
                                                "
                                            >
                                                <mat-icon>attach_file</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </ng-container>
        </div>
    </div>
</div>

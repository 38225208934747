export function formatErrorMessage(error: any) {
    /**
     * on retourne une chaine de caractères composée du message de l'erreur
     * et des 200 premiers caractères de la stack
     */
    if (error && error.message) {
        return `${error.message} ${
            error.stack ? (error.stack > 200 ? error.stack.slice(0, 200) + '...' : error.stack) : ''
        }`;
    } else {
        return `${error}`;
    }
}

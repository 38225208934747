<div class="d-flex flex-column state-input-content" [ngClass]="containerClass">
    <mat-radio-group [formGroup]="radioForm" class="d-flex flex-row">
        <ng-container *ngFor="let choice of _choices; let last = last">
            <label
                class="d-flex flex-column align-items-center"
                [class.mr-0]="!last && spaceBetweenLabels == '0'"
                [class.mr-1]="!last && (spaceBetweenLabels == '1' || (spaceBetweenLabels == undefined && labels))"
                [class.mr-2]="!last && spaceBetweenLabels == '2'"
                [class.mr-3]="!last && spaceBetweenLabels == '3'"
                [class.mr-4]="!last && spaceBetweenLabels == '4'"
                [class.mr-5]="!last && spaceBetweenLabels == '5'"
                [class.button-large-container]="labels"
                [class.c-pointer]="!disabled"
                [class.disabled-choice]="disabled"
                [class.has-error]="showError && radioControl?.invalid"
            >
                <input
                    type="radio"
                    class="opacity"
                    formControlName="radio"
                    value="{{ choice.value }}"
                    (click)="clickRadio($event, choice.value)"
                    [checked]="isRadioSelected(choice)"
                />
                <span
                    *ngIf="!labelsAsIcons"
                    class="state-input-button"
                    [class.selected]="currentChoicesValues | includes: choice.value"
                    [class.color-reactive]="isColorReactive"
                    [ngClass]="choice.cssClass"
                >
                    <lib-icon
                        [ngClass]="choice.cssClass"
                        [class.global]="globalStateInput"
                        [class.selected]="currentChoicesValues | includes: choice.value"
                        [class.icon-large]="labels"
                        [class.disabled]="disabled"
                        [theme]="choice.icon.theme"
                        [iconName]="choice.icon.name"
                    >
                    </lib-icon>
                </span>
                <span
                    *ngIf="!hideLabel"
                    [class.state-input-button]="labelsAsIcons"
                    [class.state-label]="!labelsAsIcons"
                    class="mat-caption text-center"
                    [class.disabled]="disabled"
                    [class.selected]="currentChoicesValues | includes: choice.value"
                >
                    {{ choice.label }}
                </span>
            </label>
        </ng-container>
    </mat-radio-group>
    <app-form-error
        *ngIf="showMessageError"
        class="mt-2"
        [control]="radioControl"
        message="Une des valeurs doit être choisie"
    ></app-form-error>
</div>

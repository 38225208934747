<div>
    <button *ngIf="afficherBoutonPhoto" mat-icon-button [ngClass]="colorIcon" (click)="openPictures()">
        <mat-icon>camera_alt</mat-icon>
    </button>
    <button mat-icon-button [ngClass]="colorIcon" matTooltip="Ajouter un commentaire" (click)="openComments($event)">
        <mat-icon
            [matBadgeHidden]="getTabCommentairesId()?.length == 0"
            matBadge="{{ getTabCommentairesId()?.length }}"
            matBadgeColor="accent"
        >
            speaker_notes
        </mat-icon>
    </button>
</div>

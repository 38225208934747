import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { trigger, transition, style, query, group, animateChild, animate, state, keyframes } from '@angular/animations';

@Component({
    selector: 'app-guide-de-reference',
    templateUrl: './guide-de-reference.component.html',
    styleUrls: ['./guide-de-reference.component.scss'],
    animations: [
        trigger('guideAnimation', [
            transition(
                'RECTO => VERSO',
                animate('0.8s ease-in-out', style({ transform: 'rotate3d(0, 1, 0, -90deg)' }))
            ),
            transition('VERSO => RECTO', animate('0.8s ease-in-out', style({ transform: 'rotate3d(0, 1, 0, 90deg)' }))),
        ]),
    ],
})
export class GuideDeReferenceComponent implements OnInit {
    readonly pathImages = ['./assets/img/GuideFluke1.png', './assets/img/GuideFluke2.png'];
    public index: number = 0;
    public currentImage: string = this.pathImages[0];

    constructor(private dialogRef: MatDialogRef<GuideDeReferenceComponent>) {}

    ngOnInit(): void {}

    /**
     * Evenement de click sur le bouton de fermeture de la modale
     */
    onClickBtnClose() {
        this.dialogRef.close();
    }

    onClickBtnSuivante() {
        this.index = this.index == 0 ? 1 : 0;
        this.currentImage = this.pathImages[this.index];
    }
}

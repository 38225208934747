import { Routes } from '@angular/router';
import { Icon } from 'src/app/commons-lib';
import { EtatValidation } from '../model/etat-progression.model';
import { Bien } from '../model/bien.model';
import { environment } from '../../environments/environment';
import { ReferencePrestation } from '../model/reference-prestation.model';
import { Cofrac } from '../model/cofrac.model';

/**
 * Définition du bouton d'action optionnel pour la deuxième barre d'onglets
 */
export interface ActionLink {
    label?: string;

    code: string;

    icon?: Icon;

    textColor: string;

    backgroundColor: string;
}

/**
 * Données passées à une route en vue de la transformer en lien de navigation
 */
interface NavigationLinkRouteData<BASE_TYPE, ITERATION_RESULT_TYPE> {
    /**
     * Label à afficher (peut être une string ou une fonction appliquée sur le type principal) (optionnel, vide dans le cas d'une itération)
     */
    label?: string | ((input: BASE_TYPE) => string);

    /**
     * Code permettant d'identifier l'onglet assossié à cette route
     */
    code?: string;

    /**
     * Etat de la validation des données d'un onglet
     */
    status?: EtatValidation;

    /**
     * Icône à afficher
     */
    icon?: Icon;

    /**
     * Ordre (optionnel, spécifier si différent de l'ordre de déclaration des routes)
     */
    order?: number;

    /**
     * Méchanisme d'itération (optionnel, pour générer N liens à partir d'une route)
     */
    iterate?: NavigationLinkRouteDataIterate<BASE_TYPE, ITERATION_RESULT_TYPE>;

    /**
     * Liste des boutons customs pouvant être ajoutés sur la deuxième barre d'onglets
     */
    actions?: ActionLink[];

    // ***********************************************************************
    // !!!! FONCTIONS SUIVANTES NON IMPLÉMENTÉES, à implémenter si besoin !!!!
    // ***********************************************************************

    /**
     * Fonction de masquage optionnelle
     *
     * NON IMPLÉMENTÉ !
     */
    hide: (input: BASE_TYPE) => boolean;

    /**
     * Fonction de génération dynamique des enfants.
     *
     * NON IMPLÉMENTÉ !
     */
    generateChildren: (input: BASE_TYPE) => NavigationLink[];
}

/**
 * Éléments permettant de générer des routes en itérant sur un objet
 */
interface NavigationLinkRouteDataIterate<BASE_TYPE, ITERATION_RESULT_TYPE> {
    /**
     * Fonction d'itération
     */
    function: (input: BASE_TYPE) => ITERATION_RESULT_TYPE[];

    /**
     * Label pour chaque élément de l'itération
     */
    label: (input: ITERATION_RESULT_TYPE) => string;

    /**
     * Path pour chaque élément de l'itération
     */
    path: (input: ITERATION_RESULT_TYPE) => string;

    /**
     * Suffixe à ajouter au code pour identidier la route assossié à un lien
     */
    suffix?: (input: ITERATION_RESULT_TYPE) => string;
}

/**
 * Lien à afficher dans la barre de navigation
 */
export interface NavigationLink {
    /**
     * Label à afficher
     */
    label: string;

    /**
     * Code provenant des datas de la route
     */
    code: string;

    /**
     * Icône à afficher
     */
    icon?: Icon;

    /**
     * URL
     */
    path: string;

    /**
     * Etat de la progression de la saisie de données
     */
    etat: EtatValidation;

    /**
     * Enfants (sous-liens)
     */
    children?: NavigationLink[];

    /**
     * Liste des boutons customs pouvant être ajoutés sur la deuxième barre d'onglets
     */
    actions?: ActionLink[];

    active?: boolean;
    inActiveTrail?: boolean;
    fullPath?: string;
    previous?: NavigationLink;
    next?: NavigationLink;
}

const aplat = (link: NavigationLink) => {
    if ((link.children || []).length) {
        return link.children.map(aplat).flat();
    } else {
        return [link];
    }
};

export const all = (link: NavigationLink) => {
    return [link].concat((link.children || []).map(all).flat());
};

export class NavigationUtils {
    static routesToLinks<BASE_TYPE, ITERATION_RESULT_TYPE>(
        routes: Routes,
        baseObject: BASE_TYPE,
        options: {
            activeBasePath: string;
            url: string;
        }
    ): NavigationLink[] {
        const links = this.mapRoutesToLinksRecursive(routes, baseObject, options);
        links
            .map(aplat)
            .flat()
            .forEach((link, index, allLinks) => {
                if (index > 0) {
                    link.previous = allLinks[index - 1];
                }
                if (index + 1 < allLinks.length) {
                    link.next = allLinks[index + 1];
                }
            });
        links
            .map(all)
            .flat()
            .forEach((link) => {
                if (options.url.startsWith(link.fullPath)) {
                    link.inActiveTrail = true;
                }
                if (link.fullPath === options.url) {
                    link.active = true;
                }
            });
        return links;
    }

    private static mapRoutesToLinksRecursive<BASE_TYPE, ITERATION_RESULT_TYPE>(
        routes: Routes,
        baseObject: BASE_TYPE,
        options: {
            activeBasePath: string;
        }
    ) {
        const routesFilteredAndSorted = routes
            // Élimine les routes sans label, les routes de redirection, etc.
            .filter(
                (route) => route.path && route.data && (route.data.label || route.data.iterate) && !route.redirectTo
            )
            // Caste l'attribut "data" en NavigationLinkRouteData
            .map((route) => ({
                ...route,
                data: route.data as NavigationLinkRouteData<BASE_TYPE, ITERATION_RESULT_TYPE>,
            }))
            // Trie les routes par attribut "order", ou par index dans le tableau
            .map((route, index) => ({ ...route, index }))
            .sort((route1, route2) =>
                route1.data.order && route2.data.order
                    ? route1.data.order - route2.data.order
                    : route1.index - route2.index
            );
        const links = routesFilteredAndSorted
            // Transforme les routes en liens de navigation (applique la fonction d'itération si nécessaire)
            .map((route) => {
                const actions = route.data.actions || [];
                let icon = route.data.icon;
                return route.data.iterate
                    ? // Cas où une fonction d'itération est fournie
                      route.data.iterate.function(baseObject).map((resultObject, index) => {
                          const path = route.data.iterate.path(resultObject);
                          const label = route.data.iterate.label(resultObject);
                          const code = route.data.code
                              ? route.data.code +
                                (route.data.iterate.suffix ? route.data.iterate.suffix(resultObject) : '_' + index)
                              : undefined;
                          const etat = route.data.status ? route.data.status : 'UNVERIFIED';
                          const fullPath = `${options.activeBasePath}/${path}`;
                          const children =
                              route.children && route.children.length
                                  ? this.mapRoutesToLinksRecursive(route.children, baseObject, {
                                        activeBasePath: fullPath,
                                    })
                                  : [];
                          return {
                              label,
                              code,
                              path,
                              icon,
                              etat,
                              children,
                              actions,
                              fullPath,
                          };
                      })
                    : // Cas standard
                      [
                          {
                              label:
                                  typeof route.data.label == 'string' ? route.data.label : route.data.label(baseObject),
                              code: route.data.code,
                              path: route.path,
                              icon: icon,
                              etat: route.data.status ? route.data.status : 'UNVERIFIED',
                              children:
                                  route.children && route.children.length
                                      ? this.mapRoutesToLinksRecursive(route.children, baseObject, {
                                            activeBasePath: `${options.activeBasePath}/${route.path}`,
                                        })
                                      : [],
                              actions: actions,
                              fullPath: `${options.activeBasePath}/${route.path}`,
                          },
                      ];
            })
            .flat();
        return links;
    }

    /**
     * Génère l'URL de récupération du pictogramme d'un diagnostic
     */
    // static getPictoReferencePrestationUrl(refPrestation: ReferencePrestation, jwt: string) {
    static getPictoReferencePrestationUrl(refPrestation: ReferencePrestation) {
        // return `${environment.apiUrl}/reference/${refPrestation.id}/picto/${refPrestation.idFichierPicto}?jwt=${jwt}`;
        return `${environment.apiUrl}/reference/${refPrestation.id}/picto/${refPrestation.idFichierPicto}`;
    }

    /**
     * Génère l'URL de récupération du logo d'un cofrac
     */
    // static getLogoUrlCofrac(cofrac: Cofrac, jwt: string) {
    static getLogoUrlCofrac(cofrac: Cofrac) {
        // return `${environment.apiUrl}/cofrac/${cofrac.id}/logo/${cofrac.idFichierLogo}?jwt=${jwt}`;
        return `${environment.apiUrl}/cofrac/${cofrac.id}/logo/${cofrac.idFichierLogo}`;
    }
}

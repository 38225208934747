<div class="h-100 d-flex">
    <div class="row no-gutters flex-grow-1">
        <!-- Colonne gauche-->
        <div class="col-xl-4 px-5 py-5 white-background h-100 scrollable-content">
            <verification-card
                *ngIf="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
                [isInEditMode]="isInEditMode"
                [isValidated]="isValidated"
                (onCancel)="cancelModification()"
                (onSave)="saveModification()"
                (onValidate)="validateTab()"
                (onEdit)="startModification()"
            >
                Veuillez vérifier la localisation
            </verification-card>
            <div class="h-50">
                <div class="image-upload d-flex align-items-center" [class.disabled]="!isInEditMode">
                    <label for="image-input" class="d-flex align-items-center mat-button ml-auto">
                        <lib-icon class="mr-1">add</lib-icon>
                        <span class="mr-1">{{ 'Image' | uppercase }}</span>
                    </label>
                    <input
                        class="image-input"
                        type="file"
                        #fileUpload
                        accept=".jpg,.png,.jpeg"
                        id="image-input"
                        (click)="fileUpload.value = ''"
                        (change)="selectFile($event)"
                        [disabled]="!isInEditMode"
                    />
                </div>

                <div *ngFor="let canvas of canvases">
                    <div
                        class="legend-card p-1 mt-2 d-flex align-items-center"
                        [ngClass]="{ 'selected-card': isSelectedCanvas(canvas) }"
                    >
                        {{ canvas?.backgroundImageName | fileName }}
                        <div class="d-flex c-pointer ml-auto" (click)="selectionCanvas(canvas)">
                            <lib-icon>visibility</lib-icon>
                        </div>
                        <div class="d-flex c-pointer" (click)="removeCanvas(canvas)">
                            <lib-icon>delete</lib-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="underlined my-2"></div>

            <button
                mat-raised-button
                color="accent"
                class="d-flex ml-auto"
                (click)="addLegend()"
                [disabled]="!selectedCanvas || !isInEditMode"
            >
                <lib-icon>add</lib-icon>
                <span class="mr-auto">{{ 'Légende' | uppercase }}</span>
            </button>

            <div *ngFor="let legend of selectedCanvas?.legends">
                <div
                    class="legend-card p-1 mt-2 d-flex align-items-center"
                    [ngClass]="{ 'selected-card': isSelectedLegend(legend), disabled: !isInEditMode }"
                >
                    <div class="mr-auto">{{ legend?.name }}</div>
                    <div class="d-flex c-pointer" (click)="editLegend(legend)">
                        <lib-icon>edit</lib-icon>
                    </div>
                    <div class="d-flex c-pointer" (click)="removeLegend(legend)">
                        <lib-icon>delete</lib-icon>
                    </div>
                </div>
            </div>
        </div>

        <!-- Colonne droite (canvas)-->
        <div #canvasContainer id="canvasContainer" class="col-xl-8 h-100 scrollable-content">
            <canvas id="canvasLocalisation"></canvas>
        </div>
    </div>
</div>

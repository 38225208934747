// noinspection TypeScriptValidateJSTypes

import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { Injectable, Type } from '@angular/core';
import { first } from 'rxjs/operators';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { Route } from '@angular/router';
import { electriciteNavBarRoutes } from '../electricite-routing.module';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Intervention } from '../../../../model/intervention.model';
import { Rule } from '../../../../model/regle.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import {
    ConstatationDiverse,
    Electricite,
    ElectriciteConfig,
    ElectriciteReportData,
    PointControle,
    ValeurCheckpoint,
} from '../model/electricite.model';
import { ElectriciteService } from './electricite.service';
import { CommentUtils } from '../../../../utils/comment.utils';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { Observable, of } from 'rxjs';
import { ReportElecBlockComponent } from '../report/blocks/report-elec-block.component';
import { TypeReport } from '../../../../model/reference-prestation.model';
import {
    PARAM_VOLUME_LOT,
    PARAM_VOLUME_VISITE,
    URL_MS_REPORT_FILE,
} from '../../../../shared/constants/cndiag.constants';
import { DocumentsService } from '../../../../services/documents.service';
import { ReportagePhotoService } from '../../../../services/reportage-photo.service';
import { nonOption, nonVerifiabledOption, ouiOption } from '../shared/electricite.constants';
import { LegendScreenshot } from 'src/app/model/screenshot-svg.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';
import { CommentairesBlockComponent } from '../../hap/modules/report/blocks/commentaires-block/commentaires-block.component';
import {
    ItemElementMesurage,
    MesurageReportData,
    RowBienMesurage,
    RowNiveauMesurage,
    RowVolumeMesurage,
    SubBlockMesurage,
} from '../../mesurage/model/mesurage.model';
import {
    NAMES_MAPPER,
    PARAM_AUTRE,
    PARAM_CARREZ,
    PARAM_HABITABLE,
    PARAM_HORS_CARREZ,
    PARAM_NON_HABITABLE,
    PARAM_TOTAL_AUTRE,
    PARAM_TOTAL_CARREZ,
    PARAM_TOTAL_HABITABLE,
    PARAM_TOTAL_HORS_CARREZ,
    PARAM_TOTAL_NON_HABITABLE,
    PARAM_TOTAL_UTILE_BRUT,
    PARAM_TOTAL_UTILE_NET,
    PARAM_UTILE_BRUT,
    PARAM_UTILE_NET,
} from '../../mesurage/shared/mesurage.constants';
import { PointDeControleVolume } from '../../../../model/point-de-controle.model';
/**
 * Liste des Type de component (Class) de block de rapport disponible pour la composition
 * du contenu d'un raport
 */
export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    ReportElecBlockComponent: ReportElecBlockComponent,
    CommentairesBlockComponent: CommentairesBlockComponent,
};

@Injectable({
    providedIn: 'root',
})
export class TypeElectriciteService implements ITypePrestationInterface {
    private typePrestation: TypePrestation = 'ELECTRICITE';

    constructor(
        private electriciteService: ElectriciteService,
        private readonly documentsService: DocumentsService,
        private readonly reportagePhotoService: ReportagePhotoService,
        private readonly pointDeControleService: PointDeControleService
    ) {}

    generateLegendForScreenshot(diagnostic: Diagnostic): LegendScreenshot[] {
        return [];
    }

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    getRoutes(): Route[] {
        return electriciteNavBarRoutes;
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        console.log('TypeElectriciteService - getCodeBimEquipementBien');

        return undefined;
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        // Points de contrôle
        const valeursParametres = diagnostic.pointsDeControleBiens[0].valeursParametres;
        const pointsControles = this.electriciteService.electriciteConfig$.value.pointsControles.filter(
            (cp) =>
                !!cp.description &&
                !!cp.numeroArticle &&
                !!cp.idChapitre &&
                (valeursParametres[cp.idInterne] ? !valeursParametres[cp.idInterne].isGrey : true)
        );
        const totalCp = pointsControles.length;
        const completeCp = pointsControles.filter(
            (cp) => valeursParametres[cp.idInterne] && valeursParametres[cp.idInterne].valeur
        ).length;
        return (completeCp * 100) / totalCp;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new Electricite());
    }

    getDiagnosticReportData(
        intervention: Intervention,
        diagnostic: Diagnostic,
        rules?: Rule[],
        optionPlan?: boolean
    ): IDiagReportData {
        const diagReportData = new ElectriciteReportData();
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        diagReportData.refRapport = diagnostic.reportDatas.find(
            (reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT
        ).refRapport;

        if (diagnostic.recommandationsFinales.length) {
            diagReportData.recommandations = diagnostic.recommandationsFinales.map((it) => it.contenu);
        }
        if (diagnostic.constatationsFinales.length) {
            diagReportData.constatationsDiverses = diagnostic.constatationsFinales.map((it) => it.contenu);
        }
        // Liste des Documents
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);
        diagReportData.annexes = diagReportData.documentsData
            .filter((doc) => doc.afficherDansRapport)
            .map((doc) => ({
                id: doc.nom,
                filename: URL_MS_REPORT_FILE + doc.idFichier,
                type: 'pdf',
            }));

        // Reportage photos
        diagReportData.reportagesPhotos = this.reportagePhotoService.buildReportagePhotoData(diagnostic);
        // Screenshot
        diagReportData.screenshotsPlan = diagnostic.screenshotsPlan;
        // 'LOCATION'||'VENTE'
        diagReportData.locationVente = 'VENTE'; /// TODO
        let config = null;
        this.electriciteService.electriciteConfig$.pipe(first()).subscribe((conf) => (config = conf));
        const ptcRepNonTmp = this.getCheckpointsByReponse(diagnostic, nonOption.value, config.pointsControles);
        // Filtre sur les points de contrôles qui ne sont pas dans "MesureCompensatoire"
        const idsPCMC = config.mesuresCompensatoires.map((id) => id.pcMesureCompensatoire);
        const ptcRepNon = ptcRepNonTmp.filter((ptc) => {
            return !idsPCMC.includes(ptc.idInterne);
        });
        idsPCMC.forEach((id) => {
            const rep = this.getValeurCheckpoint(diagnostic, id);
            if (rep?.valeur === ouiOption.value) {
                diagReportData.mesureCompensatoireDetectee = true;
                return;
            }
        });
        // ConclusionEvalRisque
        diagReportData.conclusionEvalRisque = [];
        config.conclusionsEvalRisques.forEach((v) => {
            const categorie = config.conclusionsCategoriesEvalRisques.find(
                (c) => c.idInterne === v.idCategorieEvalRisque
            );
            diagReportData.conclusionEvalRisque.push({
                idInterne: v.idInterne,
                reference: v.reference,
                description: v.description,
                ordreCategorie: v.ordre,
                ordre: categorie.ordre,
                conclusionCategorieEvalRisque: categorie.description,
                checked: false,
            });
        });
        diagReportData.conclusionEvalRisque.sort((a, b) => {
            return a.ordreCategorie - b.ordreCategorie === 0 ? a.ordre - b.ordre : a.ordreCategorie - b.ordreCategorie;
        });
        const ptcRepNVTmp = this.getCheckpointsByReponse(
            diagnostic,
            nonVerifiabledOption.value,
            config.pointsControles
        );
        const ptcRepNonVerifiable = ptcRepNVTmp.filter((ptc) => {
            return !idsPCMC.includes(ptc.idInterne);
        });
        // AnoPC : checked = true ; NonVPC : checked = false
        ['anomaliesPointControle', 'nonVerifiablePointDeControle'].forEach((property, index) => {
            diagReportData[property] = [];
            [ptcRepNon, ptcRepNonVerifiable][index].forEach((ptc) => {
                let anoPC = null;
                if (ptc.idRisqueEncouru?.length) {
                    anoPC = this.initAnomaliesPointControle(
                        intervention,
                        diagnostic,
                        diagReportData,
                        ptc,
                        ptc.idRisqueEncouru,
                        index === 0
                    );
                } else if (ptc.idAnomalie?.length) {
                    const anomalie = config.anomalies.find((ano) => ano.idInterne === ptc.idAnomalie);
                    anoPC = this.initAnomaliesPointControle(
                        intervention,
                        diagnostic,
                        diagReportData,
                        ptc,
                        anomalie.idRisqueEncouru,
                        index === 0
                    );
                } else {
                    const ptcParent = config.pointsControles.find((p) => p.idInterne === ptc.idPointControleParent);
                    if (ptcParent) {
                        const anomalie = config.anomalies.find((ano) => ano.idInterne === ptcParent.idAnomalie);
                        const idRisqueEncouru = ptcParent.idRisqueEncouru
                            ? ptcParent.idRisqueEncouru
                            : anomalie?.idRisqueEncouru;
                        if (idRisqueEncouru) {
                            anoPC = this.initAnomaliesPointControle(
                                intervention,
                                diagnostic,
                                diagReportData,
                                ptc,
                                idRisqueEncouru,
                                index === 0
                            );
                        } else {
                            console.error("Rien n'a été trouvé pour ce PTC : " + ptc.idInterne);
                        }
                    }
                }
                diagReportData[property].push(anoPC);
            });
            diagReportData[property].sort((a, b) =>
                a.conclusionEvalRisque?.reference?.localeCompare(b.conclusionEvalRisque?.reference)
            );
        });
        diagReportData.anomalieDetectee = !!diagReportData.anomaliesPointControle.length;
        // Les constations diverses qui sont cochées
        diagReportData.constatationDiverse = [];
        config.constationsDiverses.forEach((cd) => {
            const valeurCheckpoint = this.getValeurCheckpoint(diagnostic, cd.idInterne);
            if (valeurCheckpoint?.valeur) {
                diagReportData.constatationDiverse.push({
                    idInterne: cd.idInterne,
                    description: cd.description,
                    referenceConclusionEvalRisque: cd.referenceConclusionEvalRisque,
                    reference: cd.reference,
                    idTypeConstatationDiverse: cd.idTypeConstatationDiverse,
                    commentaires: this.getCommentaires(intervention, valeurCheckpoint.commentairesId),
                });
            }
        });
        let conclusionText = diagnostic.pointsDeControleBiens[0].valeursParametres['CONCLUSION_REPORT'];
        if (!conclusionText) {
            conclusionText = '';
            if (diagReportData.anomalieDetectee) {
                conclusionText =
                    "L'installation intérieure d'électricité comporte une ou plusieurs anomalies, il est conseillé de faire réaliser, par un installateur électricien qualifié, les travaux permettant de lever au moins les anomalies relevées.\n";
            }
            if (diagReportData.nonVerifiablePointDeControle?.length) {
                conclusionText +=
                    "Certains points de contrôles n'ont pu être effectués. De ce fait la responsabilité du propriétaire reste pleinement engagée en cas d'accident ou d'incident ayant pour origine une défaillance de toute ou partie de l'installation n'ayant pu être contrôlée.";
            }
        }
        diagReportData.conclusionText = conclusionText;
        const commentaires = intervention.commentaires;
        // Bloc visite
        const subBlockVolumesNonVisites = new SubBlockMesurage('VOLUMES_NON_VISITES', []);
        const subBlockVolumesVisites = new SubBlockMesurage('VOLUMES_VISITES', []);

        // BIENS
        const rowBienNonVisites = new RowBienMesurage(intervention.relationInterventionBiens[0].bien.nom);
        const rowBienVisites = new RowBienMesurage(intervention.relationInterventionBiens[0].bien.nom);
        intervention.relationInterventionBiens[0].bien.description.forEach((bien) => {
            // Visitabilité
            const rowNiveauNonVisites = new RowNiveauMesurage(bien.nom);
            const rowNiveauVisites = new RowNiveauMesurage(bien.nom);
            // NIVEAUX
            bien.volumes.forEach((volume) => {
                const rowVolume = this.prepareVolume(volume, diagReportData, commentaires);
                // Visitabilité
                if (
                    volume.valeursParametres[PARAM_VOLUME_VISITE] &&
                    ['ko', 'warning'].includes(volume.valeursParametres[PARAM_VOLUME_VISITE])
                ) {
                    const rowVolumeNonVisite = { ...rowVolume };
                    rowVolumeNonVisite.commentaires = rowVolume.commentaires.filter(
                        (it) => it.type === 'JUSTIFICATION_NON_VISITE'
                    );
                    rowNiveauNonVisites.volumes.push(rowVolumeNonVisite);
                }
                if (volume.valeursParametres['visite'] && volume.valeursParametres[PARAM_VOLUME_VISITE] === 'ok') {
                    const rowVolumeVisite = { ...rowVolume };
                    rowNiveauVisites.volumes.push(rowVolumeVisite);
                }
            });
            // Visitabilité

            const volumes = [];
            // VOLUMES
            volumes.forEach((volume) => {});
            // Visitabilité
            rowNiveauNonVisites.volumes.length && rowBienNonVisites.niveaux.push(rowNiveauNonVisites);
            rowNiveauVisites.volumes.length && rowBienVisites.niveaux.push(rowNiveauVisites);

            // Visitabilité
            subBlockVolumesNonVisites.biens.push(rowBienNonVisites);
            subBlockVolumesVisites.biens.push(rowBienVisites);
        });

        // Visitabilité
        diagReportData.volumesNonVisites = subBlockVolumesNonVisites;
        diagReportData.volumesVisites = subBlockVolumesVisites;
        return diagReportData;
    }

    /**
     * Initialisation d'un rowVolume
     */
    private prepareVolume(volume: Volume, diagReportData: any, commentaires: Commentaire[]) {
        const newRowVolume = new RowVolumeMesurage(volume.nom, volume.id);
        newRowVolume.visite = new ItemElementMesurage(
            volume.valeursParametres[PARAM_VOLUME_VISITE]
                ? this.translateValue(PARAM_VOLUME_VISITE, volume.valeursParametres[PARAM_VOLUME_VISITE])
                : null,
            null
        );

        newRowVolume.commentaires = commentaires
            .filter((c) => {
                return volume.commentairesId.indexOf(c.id) !== -1;
            })
            .filter((it) => it.type !== 'NOTE_PERSONNELLE');

        newRowVolume.commentaires.map((com) => {
            const newCom = Object.assign({}, com);
            if (newCom.imageId) {
                newCom.imageId = URL_MS_REPORT_FILE + com.imageId;
            }
            return newCom;
        });
        return newRowVolume;
    }
    private translateValue(ppty: string, value: string): string {
        let valueToDisplay = value;
        if (NAMES_MAPPER[ppty]) {
            const finded = NAMES_MAPPER[ppty].find((it) => it.valeur === value);
            if (finded) {
                valueToDisplay = finded.afficher;
            }
        }
        return valueToDisplay;
    }

    private initAnomaliesPointControle(
        intervention: Intervention,
        diagnostic: Diagnostic,
        diagReportData: ElectriciteReportData,
        ptc: PointControle,
        pIdRisqueEncouru: string,
        checked?: boolean
    ): any {
        let config = null;
        this.electriciteService.electriciteConfig$.pipe(first()).subscribe((conf) => (config = conf));
        // checked all CERisque qui sont dans la liste "idEvalRisque" du "idRisqueEncouru".
        const risqueEncouru = config.risquesEncourus.find((re) => re.idInterne === pIdRisqueEncouru);
        let conclusionEvalRisque;
        risqueEncouru.idEvalRisques.forEach((id) => {
            conclusionEvalRisque = diagReportData.conclusionEvalRisque.find((c) => c.idInterne === id);
            if (checked) {
                conclusionEvalRisque.checked = true;
            }
        });
        // Regarder dans toutes les "MesureCompensatoire" si le point de contrôle existe
        // Dans "lstPointControlesAssocies" alors on regarde
        // la réponse au point de contrôle (qui est une mesure compensatoire) "pcMesureCompensatoire".                }
        let mesureCompensatoire = {};
        const mesureCompensatoireFound = config.mesuresCompensatoires.find((p) =>
            p.lstPointControlesAssocies.includes(ptc.idInterne)
        );
        if (mesureCompensatoireFound) {
            const chp = config.pointsControles.find(
                (p) => p.idInterne === mesureCompensatoireFound.pcMesureCompensatoire
            );
            const chpReponse1: ValeurCheckpoint = this.getValeurCheckpoint(
                diagnostic,
                mesureCompensatoireFound.pcMesureCompensatoire
            );
            mesureCompensatoire = {
                idInterne: mesureCompensatoireFound.pcMesureCompensatoire,
                description: chp.description,
                libelleRapport: chp.libelleRapport,
                reponse: chpReponse1?.valeur,
                commentaires: this.getCommentaires(intervention, chpReponse1?.commentairesId),
            };
        }
        const chpReponse2 = this.getValeurCheckpoint(diagnostic, ptc.idInterne);
        const cat = config.conclusionsCategoriesEvalRisques.find(
            (c) => c.idInterne === conclusionEvalRisque.idCategorieEvalRisque
        );
        return {
            pointControle: {
                idInterne: ptc.idInterne,
                description: ptc.description,
                libelleRapport: ptc.libelleRapport,
                numeroArticle: ptc.numeroArticle,
                numeroFiche: ptc.numeroFiche,
                commentaires: this.getCommentaires(intervention, chpReponse2.commentairesId),
            },
            mesureCompensatoire,
            conclusionEvalRisque: {
                reference: conclusionEvalRisque.reference,
                categorieConclusionEvalRisque: conclusionEvalRisque.conclusionCategorieEvalRisque,
                description: conclusionEvalRisque.description,
            },
            risqueEncouru: {
                idInterne: risqueEncouru.idInterne,
                description: risqueEncouru.description,
                ordre: risqueEncouru.ordre,
            },
        };
    }
    private getCommentaires(intervention: Intervention, commentairesId: string[]): any {
        return intervention.commentaires
            .filter((c) => {
                return commentairesId?.includes(c.id);
            })
            .map((c) => {
                return { id: c.id, type: c.type, contenu: c.contenu };
            });
    }
    private getValeurCheckpoint(diagnostic: Diagnostic, idInterne: string): ValeurCheckpoint {
        return diagnostic.pointsDeControleBiens[0].valeursParametres[idInterne];
    }
    private getCheckpointsByReponse(
        diagnostic: Diagnostic,
        reponse: any,
        pointsControles: PointControle[]
    ): PointControle[] {
        return pointsControles.filter((ch) => {
            const valeur: ValeurCheckpoint = this.getValeurCheckpoint(diagnostic, ch.idInterne);
            return valeur && valeur.valeur == reponse;
        });
    }

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        console.log('TypeElectriciteService - isItemAlreadyFilled');

        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {
        console.log('TypeElectriciteService - prepareFilteredCommentsForReport');
    }

    prepareForm(intervention: Intervention, contenuDiagnostic: ContenuDiagnostic) {
        console.log('TypeElectriciteService - prepareForm');
        const contentDiagElec = contenuDiagnostic as Electricite;
    }

    prepareSpecificComments(
        diagnostic: Diagnostic,
        commentairesGeneraux: Commentaire[],
        commentaires: CommentairePredefini[]
    ): Commentaire[] {
        console.log('TypeElectriciteService - prepareSpecificComments');
        return commentaires.map((it) => CommentUtils.commentairePredefiniToCommentaireMapper(it));
    }

    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return undefined;
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {}

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}

import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import {
    EtapeDiagnostic,
    EtapeDiagnosticAbstract,
    EtapeDiagnosticGenerique,
    IdEtapeDiagnostic,
    TypeEtapeDiagnostic,
} from '../../../../model/diagnostic-etape.model';
import { AnnexeData, DocumentData, IDiagReportData, ReportagePhotoData } from '../../../../model/rapport.model';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { Commentaire } from '../../../../model/commentaire.model';

export class CeeModel implements ContenuDiagnostic {
    nomEntreprise: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    numeroSIREN: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    entrepriseRGE: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    refFacture: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();

    dateVisitePrealable: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    dateAcceptationDevis: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    //Francy
    dateDebutTravaux: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    dateFacture: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    dateEtatRecapitulatif: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    //Francy
    typeIsolant: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    marqueIsolant: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    epaisseur: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    nombreSacs: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    resistanceThermique: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    surfaceIsolee: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    solutions: EtapeDiagnosticCee = new EtapeDiagnosticCee();
    caracteristiques: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    longuerReseauxIsoles: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
}

export class EtapeDiagnosticCee implements EtapeDiagnostic {
    id: IdEtapeDiagnostic;
    type: TypeEtapeDiagnostic = TypeEtapeDiagnostic.ETAPE_DIAGNOSTIC_CEE;
    solutions: ISolution[] = [];
}

export interface ISolution {
    infoIsolant?: EtapeDiagnosticGenerique[];
    pointsDeControles?: EtapeDiagnosticAbstract;
    valid?: boolean; // Si une solution est vaiide
    // => data = Map<string, EtapeDiagnosticGenerique[]> : key : Nom de la zone
}

export class Anomalie {
    id: string;
    code: string;
    codeReponse: string;
    type: string;
    valeurReference: number;
    description: string;
}

export interface IReportPC {
    caracteristiques?: {
        libelle: string;
        reponse: string;
        commentaires: Commentaire[];
        sectionRapport: string;
        ordreSectionRapport: string;
        estAnomalie: boolean;
        idChapitre: string;
        idPointControle: string;
    }[];
    infosSolutions: {
        conclusionAccessibiliteRealisationNonSatisfaisant: boolean;
        conclusionMiseEnServiceNonSatisfaisant: boolean;
        solutionName: string;
        informations: {
            libelle: string;
            reponse: string;
            commentaires: Commentaire[];
            sectionRapport: string;
            ordreSectionRapport: string;
        }[];
    };
    zones: {
        zoneName: string;
        pointsDeControle: {
            libelle: string;
            reponse: string;
            commentaires: Commentaire[];
            sectionRapport: string;
            ordreSectionRapport: string;
            estAnomalie: boolean;
            libellesAnomalies: any[];
            imageZone: {
                legende: string;
                url: string;
                creationDate: string;
                gpsLatitudeRef: string;
                gpsLatitude: string;
                gpsLongitudeRef: string;
                gpsLongitude: string;
                gpsAltitudeRef: string;
                gpsAltitude: string;
            };
        }[];
    }[];
}

export interface IAnomalie {
    solution: string;
    zones: {
        name: string;
        libelleAnomalies: string[];
        commentairesId?: string[];
    }[];
}

export class CEEReportData implements IDiagReportData {
    id: string;
    nom: string;
    typePrestation: TypePrestation;
    conformiteGlobale: boolean;
    documentsData: DocumentData[];
    annexes: AnnexeData[];
    reportagesPhotos: ReportagePhotoData[];
    recommandations: string[] = [];
    constatationsDiverses: string[] = [];
    refRapport: string;
    anomalies: IAnomalie[] = [];
    pointsDeControle: {
        solution: IReportPC[];
    };
    etapesGenerales: any[] = [];
    listDocuments: DocumentData[];
    longuerReseauxIsoles: number;
    /*nomEntreprise: any;
    numeroSIREN: any;
    entrepriseRGE: any;
    dateVisitePrealable: any;
    dateAcceptationDevis: any;
    dateFacture: any;
    refFacture: any;
    dateEtatRecapitulatif: any;
    */
}

export class TypeCee {
    id: string;
    idInterne: string;
    description: string;
    libelle: string;
}

export class CeeConfig {
    id: string;
    dateDeCreation: Date;
    idInterne: string;
    typesCee: TypeCee[];
    chapitres: Chapitre[];
    pointsControles: PointControle[];
    tableauZoneTresFroide: TableauZoneTresFroide;
}

export class TableauZoneTresFroide {
    id: string;
    lstDepartementAltitude: DepartementAltitude[];
}

export class DepartementAltitude {
    id: string;
    departement: number;
    altitude: number;
}

export class Chapitre {
    id: string;
    idInterne: string;
    idChapitreParent: string;
    idChapitreRacine: string;
    idTypeCee: string;
    description: string;
    chemin: string;
}

export class PointControle {
    id: string;
    idInterne: string;
    idChapitre: string;
    ordreChapitre: number;
    peutEtreRepondu: boolean;
    description: string;
    type: string;
    decalage: number;
    lstChoixReponse: Reponse[];
    libelleRapport: string;
    isGrey: boolean = false;
    reponseDefaut: string = 'Vide';
    isCalculette: boolean = false;
    isCalculZone: boolean = false;
    hide: boolean = false;
    idPointControleParent: string;
    lstPointsContolesReponsesCibles: PointControleReponse[];
    lstAnomalie: Anomalie[];
    sectionRapport: string;
    ordreSectionRapport: string;
    required: boolean;
}

export class PointControleReponse {
    id: string;
    codeReponseInitiale: string;
    idPointControleCible: string;
    reponseCible: Reponse;
    isGreyCible: boolean;
}

export class Reponse {
    id: string;
    code: string;
    description: string;
    idPointControleReponses: string;
    option: any;
    needJustification: boolean;
    phraseJustification: string;
}

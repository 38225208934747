<div class="d-flex flex-grow-1 flex-row h-100">
    <div class="col-4 p-0 hap-left-side-panel">
        <div class="container h-100 white-background">
            <div class="row h-100 white-background">
                <div class="w-100 d-flex flex-column h-100 overflow-auto">
                    <div
                        class="
                            d-flex
                            flex-row
                            align-items-center
                            justify-content-center
                            background-espace
                            border-bottom-grey
                        "
                    >
                        <ng-container *ngIf="listePlansLocalisation && listePlansLocalisation.length > 0">
                            <button
                                class="ml-2"
                                mat-icon-button
                                color="primary"
                                [disabled]="
                                    !currentPlanLocalisation ||
                                    currentPlanLocalisation.id === listePlansLocalisation[0].id
                                "
                                (click)="onClickPreviousPlanLocalisation()"
                            >
                                <lib-icon iconName="arrow-left-bold-circle" [theme]="'mdi'"></lib-icon>
                            </button>
                            <app-custom-select
                                class="espace-select"
                                [matFormFieldClass]="'w-100'"
                                [choices]="listePlansLocalisation"
                                [selectedValue]="currentPlanLocalisation"
                                (valueChangeFromUser)="onChangePlanLocalisation($event)"
                                [pathValueDisplay]="['nom']"
                                [filter]="false"
                                [addline]="false"
                                [sorted]="false"
                            ></app-custom-select>
                            <button
                                mat-icon-button
                                color="primary"
                                [disabled]="
                                    !currentPlanLocalisation ||
                                    currentPlanLocalisation.id ===
                                        listePlansLocalisation[listePlansLocalisation.length - 1].id
                                "
                                (click)="onClickNextPlanLocalisation()"
                            >
                                <lib-icon iconName="arrow-right-bold-circle" [theme]="'mdi'"></lib-icon>
                            </button>
                        </ng-container>

                        <ng-container *ngIf="!listePlansLocalisation || listePlansLocalisation.length === 0">
                            <span>Aucun plan de localisation défini</span>
                        </ng-container>

                        <mat-icon
                            *ngIf="isOnePerimetreInvalid"
                            class="icon-small icon-warning"
                            matTooltip="Au moins un espace contient des périmètres non validés"
                            >warning</mat-icon
                        >

                        <button
                            mat-icon-button
                            class="ml-2"
                            [disabled]="readonlyMode || !listePlansLocalisation || listePlansLocalisation.length === 0"
                            (click)="onClickEditPlanLocalisation()"
                        >
                            <lib-icon class="icon-small">edit</lib-icon>
                        </button>
                        <button
                            mat-icon-button
                            [disabled]="readonlyMode || !listePlansLocalisation || listePlansLocalisation.length === 0"
                            (click)="onClickDeletePlanLocalisation()"
                        >
                            <lib-icon class="icon-small">delete</lib-icon>
                        </button>
                        <button
                            mat-raised-button
                            class="button-create-espace"
                            type="button"
                            color="accent"
                            [disabled]="readonlyMode"
                            (click)="onClickCreatePlanLocalisation()"
                        >
                            <lib-icon>add</lib-icon>
                        </button>
                    </div>

                    <div *ngIf="currentPlanLocalisation" class="d-flex flex-column align-items-center">
                        <app-liste-perimetre
                            class="w-100"
                            [diagnostic]="diagnostic"
                            [espace]="currentPlanLocalisation"
                            [delimitationFormGroupSelected]="editedFormGroup"
                            [listePerimetreArrayForm]="listeDelimitationArrayForm"
                            [readonlyMode]="readonlyMode"
                            [maxLevelToDisplay]="enumLevelToDisplay.PERIMETRE"
                            [referenceToLocate]="elementToLocate"
                            [currentStorey]="currentStorey"
                            (perimetreEvent)="onOpenEditPanel($event)"
                            (editLocationEvent)="onEditLocation($event)"
                        ></app-liste-perimetre>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-8 p-0">
        <mat-drawer-container class="mat-drawer-hap h-100 w-100" [hasBackdrop]="true">
            <mat-drawer #drawer [mode]="'over'">
                <div class="h-100 w-100">
                    <app-perimetre-edit-panel
                        [perimetreFormGroup]="editedFormGroup"
                        [typePrestation]="diagnostic?.typePrestation"
                        [readonlyMode]="readonlyMode"
                        [currentPlanLocalisation]="currentPlanLocalisation"
                        [currentStorey]="currentStorey"
                        (closedEditPanel)="onCloseEditPanel()"
                    >
                    </app-perimetre-edit-panel>
                </div>
            </mat-drawer>
            <mat-drawer-content>
                <div class="w-100 h-100">
                    <!-- L'ensemble des périmètres (formulaires) doivent être valides pour pouvoir les valider -->
                    <app-card-validation
                        *ngIf="checkValidation && !diagReadonly && !isReadonlyForced"
                        class="validate-card"
                        [disabled]="!listeDelimitationArrayForm.valid"
                        [isValid]="currentPlanLocalisation?.isPerimetreValid"
                        (isValidChange)="onChangeIsPerimetreValid($event)"
                    >
                        <ng-container text-not-valid>Veuillez vérifier les périmètres</ng-container>
                        <ng-container text-valid>Les périmètres de l'espace ont été validé</ng-container>
                    </app-card-validation>

                    <ng-container *ngIf="currentStorey">
                        <app-viewer-map-hap
                            #viewMap
                            [currentBien]="currentBien"
                            [currentStorey]="currentStorey"
                            [referenceToMarker]="elementToLocate"
                            [backgroundMap]="backgroundMapSelected"
                            [level]="enumLevelToDisplay.PERIMETRE"
                            [readonlyMode]="readonlyMode"
                            [useDiagTool]="true"
                            (toolEvent)="viewMapHapEvent($event)"
                        >
                        </app-viewer-map-hap>
                    </ng-container>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>

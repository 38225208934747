import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MpcaItemOld } from '../../../../model/mpca.model.old';
import { WidgetSelectModeEnum } from '../../../../../../shared/widget-select/widget-select.component';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DiagnosticService } from '../../../../../../../services/diagnostic.service';
import { ObjectifsTypeZone, PolluantConfig } from '../../../../model/polluant-config.model';
import { Polluant } from '../../../../model/polluant.model';
import { PolluantService } from '../../../../services/polluant.service';
import { PolluantPrelevement } from '../../../../model/polluant-prelevement.model';
import { ZoneTravailModalComponent } from '../zone-travail-modal/zone-travail-modal.component';
import { Zone } from '../../../../model/zone.model';
import { ConditionPrelevementSelectionnee } from '../../../../model/conditionPrelevementSelectionnee.model';
import { Besoin } from '../../../../model/besoin.model';
import { ProcessusMPCA } from '../../../../model/processus-mpca.model';
import { MpcaPanelListCardComponent } from 'src/app/modules/shared/mpca-panel-list-card/mpca-panel-list-card';

@Component({
    selector: 'app-zone-propriete-modal',
    templateUrl: './zone-propriete-modal.component.html',
    styleUrls: ['./zone-propriete-modal.component.scss'],
})
export class ZoneProprieteModalComponent implements OnInit {
    objectifsMultiplePossible: boolean = true;
    isModal: boolean = true;
    mpcaList: MpcaItemOld[] = [];
    mpcaListSelected: MpcaItemOld[] = [];
    polluantConfig: PolluantConfig;
    contenuPolluant: Polluant | PolluantPrelevement;
    zones: Zone[] = [];
    objectifsMesurage: ConditionPrelevementSelectionnee[];
    objectifsMesurageEligible: ConditionPrelevementSelectionnee[];
    indexZone: number;
    isInEditMode: Boolean = true;
    isContextTravaux: Boolean = true;
    lienVolumeObj: any;
    typeDeZone: String[] = [];
    objTypeDeZone: ObjectifsTypeZone[] = [];
    ZoneSimilaireEchantillonnage: String[] = [];

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    private _form: FormGroup;
    set form(form: FormGroup) {
        if (!form) return;
        this._form = form;
        //TODO
        this.mpcaListSelected = this.form?.get('listeMpca')?.value;
        this._isContextTravaux();
    }

    get form() {
        return this._form;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ZoneTravailModalComponent>,
        private diagnosticService: DiagnosticService,
        public polluantService: PolluantService,
        public dialog: MatDialog
    ) {
        this.polluantConfig = data?.polluantConfig;
        this.indexZone = parseInt(data?.indexZone);
        this.isInEditMode = data?.isInEditMode;
        this.lienVolumeObj = data?.lienVolumeObj;
        this.form = data?.form;
    }

    ngOnInit(): void {
        this.diagnosticService.getCurrentDiagnostic().subscribe((diag) => {
            if (diag.contenuDiagnostic instanceof PolluantPrelevement) {
                this.contenuPolluant = diag.contenuDiagnostic as PolluantPrelevement;
                this.mpcaList = this.contenuPolluant.visiteDeChantier.data.mpcaList.data.descriptifList;
                this.objectifsMesurage =
                    this.contenuPolluant.visiteDeChantier.data.objectifsMesurage.data.objectifsMesurage;
                this.contenuPolluant.listeZones.data.zonesList.forEach((z) => this.zones.push(new Zone(z)));
            } else {
                this.contenuPolluant = diag.contenuDiagnostic as Polluant;
                this.mpcaList = this.contenuPolluant.mpcaList.data.descriptifList;
                this.objectifsMesurage = this.contenuPolluant.objectifsMesurage.data.objectifsMesurage;
                this.contenuPolluant.zones.data.zonesList.forEach((z) => this.zones.push(new Zone(z)));
            }
        });
        this.initialiseContexte();
        this.majListeTypeZone(true);
        this.onTypeZoneChange(true);
    }

    initialiseContexte() {
        let presenceContexteTravaux = false;
        let presenceContexteHorsTravaux = false;
        this.polluantConfig.objectifsMesurage.forEach((o) => {
            o.conditionsPrelevement.forEach((c) => {
                const i = this.objectifsMesurage.findIndex((m) => m.reference === c.reference);
                if (i > -1) {
                    if (c.contextTravaux) {
                        presenceContexteTravaux = true;
                    } else {
                        presenceContexteHorsTravaux = true;
                    }
                }
            });
        });

        if (presenceContexteTravaux && !presenceContexteHorsTravaux)
            this.form.get('zoneContexte').setValue('Pendant travaux');
        if (!presenceContexteTravaux && presenceContexteHorsTravaux)
            this.form.get('zoneContexte').setValue('Hors travaux');
    }

    majListeTypeZone(init = false) {
        this.typeDeZone = [];

        this.objTypeDeZone = this.polluantConfig.objectifsEligible.filter((typeZ) => {
            return (
                this.form.get('zoneContexte').value === typeZ.contexte &&
                typeZ.environnement.every((a) => this.form.get('environnement').value.includes(a)) &&
                this.objectifsMesurage.filter((o) => {
                    return typeZ.objectifs.includes(o.reference);
                }).length > 0
            );
        });

        this.objTypeDeZone.forEach((o) => {
            if (!this.typeDeZone.includes(o.typeZone)) this.typeDeZone.push(o.typeZone);
        });

        if (!init) {
            this.form.patchValue({
                typeZone: undefined,
                objectifMesurage: [],
            });
        }

        // this.onTypeZoneChange(init);
    }

    onTypeZoneChange(init: boolean = false) {
        this.ZoneSimilaireEchantillonnage = [];
        this.objectifsMesurageEligible = [];
        if (init == false) this.form.controls.objectifMesurage.setValue([]);

        var zse = this.objTypeDeZone.filter((typeZ) => {
            return typeZ.typeZone == this.form.get('typeZone').value;
        });

        zse.forEach((z) => {
            this.objectifsMultiplePossible = z.objectifsMultiple;
            if (this.objectifsMultiplePossible && !Array.isArray(this.form.value.objectifMesurage))
                this.form.value.objectifMesurage = [];

            z.objectifs.forEach((o) => {
                const i = this.objectifsMesurage.findIndex((m) => m.reference === o);
                if (i > -1 && !this.objectifsMesurageEligible.includes(this.objectifsMesurage[i]))
                    this.objectifsMesurageEligible.push(this.objectifsMesurage[i]);
            });
            if (!this.ZoneSimilaireEchantillonnage.includes(z.zse) && z.zse != null)
                this.ZoneSimilaireEchantillonnage.push(z.zse);
        });

        if (this.ZoneSimilaireEchantillonnage.length > 0) {
            this.form.controls.zse.setValidators([Validators.required]);
        } else {
            this.form.controls.zse.clearValidators();
            this.form.controls.zse.setErrors(null);
            this.form.controls.zse.setValue([]);
        }

        this._isContextTravaux();
        this.genererNomZone();
    }

    onZSEChange() {
        this.objectifsMesurageEligible = [];
        this.form.controls.objectifMesurage.setValue([]);

        const zse = this.objTypeDeZone.find((z) => z.zse == this.form.get('zse').value);
        if (zse != undefined) {
            zse.objectifs.map((ref) => {
                const i = this.objectifsMesurage.findIndex((m) => m.reference === ref);
                if (i > -1) {
                    this.objectifsMesurageEligible.push(this.objectifsMesurage[i]);
                }
            });

            this.objectifsMultiplePossible = zse.objectifsMultiple;
        }
    }

    addMpca(mpcaId: string) {
        const mpca = MpcaPanelListCardComponent.deepCloneMpca(this.mpcaList.find((mpca) => mpca.id == mpcaId));

        this.mpcaListSelected.push(mpca);
        this.form.get('listeMpca').setValue(this.mpcaListSelected);
        this.genererNomZone();
    }

    deleteMpca(mpcaId: string) {
        const index = this.mpcaListSelected.indexOf(this.mpcaListSelected.find((m) => m.id == mpcaId));
        this.mpcaListSelected.splice(index, 1);
        this.form.get('listeMpca').setValue(this.mpcaListSelected);
        this.genererNomZone();

        //Réinitialisation du champ processus pour tous les besoins qui ont un processus associé à ce MPCA
        const mpca = this.mpcaList.find((mpca) => mpcaId == mpca.id);
        mpca.processus.forEach((processus: ProcessusMPCA) => {
            const besoinsList = (this.contenuPolluant as Polluant).besoins?.data?.besoinsList;
            if (besoinsList?.length) {
                besoinsList.forEach((besoin: Besoin) => {
                    if (besoin.processusId == processus.id) {
                        besoin.processusId = '';
                    }
                });
            }
        });
    }

    /**
     * Si on clique sur "Retirée" : il faut renseigner tous les champs suivant en retirée :
     * Etat de dégradation
     * Type de protection
     * Etanchéité protection
     * Choc et vibration
     * Circulation d'air
     */
    retirerMpca(mpcaId: any) {
        const mpca = this.mpcaListSelected.find((mpca) => mpca.id === mpcaId);
        if (!mpca) {
            return console.error('Erreur mpca non existant : ' + mpcaId);
        }
        mpca.general.etatDegradation = 'Retiré';
        mpca.protectionExposition.type = 'Matériau retiré';
        mpca.protectionExposition.etancheite = 'Matériau retiré';
        mpca.protectionExposition.circulationAir = 'Matériau retiré';
        mpca.protectionExposition.chocVibration = 'Matériau retiré';
    }

    /**
     * Si on clique sur "Encapsulée" : il faut renseigner tous les champs suivant:
     * Etat de dégradation
     * Type de protection
     * Etanchéité protection
     * Choc et vibration
     * Circulation d'air
     * @param mpcaId
     */
    encapsulerMpca(mpcaId: any) {
        // const lMpca = this.mpcaList.find((mpca) => mpca.id === mpcaId);
        const mpca = this.mpcaListSelected.find((mpca) => mpca.id === mpcaId);
        const ongletMPCA = (this.contenuPolluant as Polluant).mpcaList.data.descriptifList.find(
            (mpca) => mpca.id === mpcaId
        );

        if (!(mpca || ongletMPCA)) {
            return console.error('Erreur mpca non existant : ' + mpcaId);
        }
        mpca.general.etatDegradation = ongletMPCA.general.etatDegradation;
        mpca.protectionExposition.type = ongletMPCA.protectionExposition.type;
        mpca.protectionExposition.etancheite = ongletMPCA.protectionExposition.etancheite;
        mpca.protectionExposition.circulationAir = ongletMPCA.protectionExposition.circulationAir;
        mpca.protectionExposition.chocVibration = ongletMPCA.protectionExposition.chocVibration;
    }

    change(event) {
        const value = event.source.value;
        const values = this.form.value.objectifMesurage;
    }

    modalAction(action) {
        if (action == 'saveAndClose') {
            if (!this.formValid) {
                return;
            }
            this.dialogRef.close({ action, form: this.form.value, contenuDiagnostic: this.contenuPolluant });
        } else if (action == 'cancel') {
            this.dialogRef.close({ action });
        } else if (action == 'delete') {
            this.dialogRef.close({ action });
        }
    }

    get formValid(): boolean {
        for (const key of Object.keys(this.form.controls)) {
            const controlErrors: ValidationErrors = this.form.get(key).errors;
            if (controlErrors != null) {
                return false;
            }
        }
        return true;
    }
    /**
     * @description Fonction appellée par chaque élément du menu déroulant Objectif de mesurage
     *              afin de définir si il doit être désactivé ou non en fonction des choix précédents
     * @param item
     */
    isDisabled(item) {
        let state = false;
        let formValues = this.form.value.objectifMesurage;
        //Toutes les cases cochées doivent pouvoir être décochées
        formValues.forEach((formValue: any) => {
            if (!this.objectifsMultiplePossible && formValue.reference != item.reference) {
                state = true;
            }
        });

        this._isContextTravaux();

        return state;
    }

    //Fonction permettant de préselectionner un objectif de mesurage dans le formulaire
    protected compareObj(obj1: any, obj2: any): boolean {
        return obj1.reference == obj2.reference;
    }

    onObjectifMesurageChange() {
        this._isContextTravaux();
        this.genererNomZone();
    }

    /**
     * @description Génère le nom de la zone en fonction de l'obj de mesurage sélectionné
     * et du MPCA
     */
    genererNomZone() {
        let nom = [];

        if (this.form.get('typeZone').value != 'zone de travail annexe') {
            nom.push(this.form.get('typeZone').value);
        } else {
            nom.push('zse');
            nom.push(this.form.get('zse').value);
        }

        let mpcaList = [];

        // Concaténation des noms de MPCA
        this.form.value.listeMpca.forEach((mpca: MpcaItemOld, index) => {
            mpcaList.push(mpca.general?.materiaux.nom);
        });
        if (mpcaList.length != 0) {
            nom.push('(' + mpcaList.join(', ') + ')');
        }

        this.form.get('identifiant').setValue(nom.join(' - '));
    }

    /**
     * Algo pour déterminer les objectifs éligible en fonction du type de zone et ZSE sélectionnées
     * Voir Annexe 2.2 du document "Nouvelle norme avec ZSE V4.pdf"
     */
    updateListeObjectifs() {}

    /**
     * @description
     *
     */
    _isContextTravaux() {
        const objectifs = this.form.value.objectifMesurage;
        const objectifsMesurage = this.polluantConfig.objectifsMesurage;
        var state = false;

        if (this.form.get('typeZone').value == 'Zone de travail') {
            state = true;
        }

        if (!state) {
            // vider le from et enlever les required
            this.form.controls.activiteAdjacente.clearValidators();
            this.form.controls.confinement.clearValidators();
            this.form.controls.nombreExtracteurs.clearValidators();
            this.form.controls.sasMateriel.clearValidators();
            this.form.controls.sasPersonnel.clearValidators();

            this.form.controls.activiteAdjacente.setErrors(null);
            this.form.controls.confinement.setErrors(null);
            this.form.controls.nombreExtracteurs.setErrors(null);
            this.form.controls.sasMateriel.setErrors(null);
            this.form.controls.sasPersonnel.setErrors(null);

            this.form.patchValue({
                activiteAdjacente: '',
                confinement: '',
                nombreExtracteurs: 0,
                sasMateriel: '',
                sasPersonnel: '',
            });
        } else {
            this.form.controls.activiteAdjacente.setValidators([Validators.required]);
            this.form.controls.confinement.setValidators([Validators.required]);
            this.form.controls.nombreExtracteurs.setValidators([Validators.required]);
            this.form.controls.sasMateriel.setValidators([Validators.required]);
            this.form.controls.sasPersonnel.setValidators([Validators.required]);
        }

        this.form.updateValueAndValidity();

        this.isContextTravaux = state;
    }

    selectZoneTravail() {
        const dialogRef = this.dialog.open(ZoneTravailModalComponent, {
            width: '65%',
            data: {
                polluantConfig: this.polluantConfig,
                isInEditMode: this.isInEditMode,
                form: this.createFormZoneTravail(),
            },
            disableClose: true,
            autoFocus: true,
        });
        dialogRef.componentInstance.zoneAssociee = this.form.controls.zoneAssociee.value;
        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'cancel') {
                return;
            } else if (result === 'delete') {
                this.form.controls.idZoneAssociee.setValue('');
                this.form.controls.zoneAssociee.setValue('');
            } else {
                //Update values in the form
                this.form.controls.idZoneAssociee.setValue(result?.id);
                this.form.controls.zoneAssociee.setValue(result);
            }
        });
    }

    createFormZoneTravail() {
        return new FormGroup({
            id: new FormControl(this.form.controls.id.value),
            environnement: new FormControl(this.form.controls.environnement.value),
            idZoneAssociee: new FormControl(this.form.controls.idZoneAssociee.value),
            zoneAssociee: new FormControl(this.form.controls.zoneAssociee.value),
        });
    }

    get zoneCurrent(): Zone {
        if (this.form.controls.id.value != undefined) {
            return this.zones.filter((z) => {
                return z.id == this.form.controls.id.value;
            })[0];
        } else {
            return null;
        }
    }
}

import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationStore } from './authentication.store';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authenticationStore: AuthenticationStore) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let requiredAuthorities = route.data.authorities;
        return this.authenticationStore.getCurrentUser().pipe(
            map((currentUser) => {
                if (!currentUser) {
                    this.router.navigate(['/login']);
                    return false;
                }
                if (!requiredAuthorities || requiredAuthorities.length === 0) {
                    return true;
                }
                if (!Array.isArray(requiredAuthorities)) {
                    requiredAuthorities = [requiredAuthorities];
                }
                const hasAnyAuthority = requiredAuthorities.some((authority: string) =>
                    currentUser.authorities.includes(authority)
                );
                if (hasAnyAuthority) {
                    return true;
                }
                if (isDevMode()) {
                    console.error('User has not any of required authorities: ', requiredAuthorities);
                }
                this.router.navigate(['/access-denied']);
                return false;
            })
        );
    }
}

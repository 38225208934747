import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../authentication/user.service';
import { NotificationService } from '../../notification/notification.service';

@Component({
    selector: 'lib-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
    /** Classe CSS sur le conteneur principal */
    @Input() containerClass = '';

    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;

    constructor(
        private userService: UserService,
        private notificationService: NotificationService,
        private router: Router
    ) {}

    ngOnInit() {}

    submit() {
        if (this.newPassword !== this.newPasswordConfirm) {
            this.notificationService.error('Le mot de passe et sa confirmation ne sont pas identiques');
            return;
        }
        this.userService.updatePassword(this.newPassword, this.oldPassword).subscribe(() => {
            this.notificationService.success('Le mot de passe a bien été mis à jour');
            this.back();
        });
    }

    back() {
        this.router.navigate([`/profile`]);
    }
}

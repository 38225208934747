<div class="container-fluid d-flex h-100" *ngIf="!isLoading">
    <div class="flex-grow-1 column-objectif" [formGroup]="form">
        <verification-card
            *ngIf="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="form"
        >
            Veuillez vérifier les objectifs de mesurage
        </verification-card>
        <input [hidden]="true" type="text" formControlName="objectifsMesurage" />

        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" #tree>
            <mat-tree-node
                class="field-checkbox font-weight-normal"
                *matTreeNodeDef="let node"
                matTreeNodeToggle
                matTreeNodePadding
            >
                <button mat-icon-button disabled></button>
                <mat-checkbox
                    class="checklist-leaf-node"
                    [checked]="checklistSelection.isSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node)"
                    [disabled]="!isInEditMode"
                >
                    {{ node.label }}
                    <!-- +node.domaineReglementaire[0].toLowerCase() -->
                    <span [class]="'domaine-reglementaire--'">({{ node.typePrelevement }})</span>
                </mat-checkbox>
            </mat-tree-node>

            <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding> </mat-tree-node>

            <mat-tree-node
                class="field-checkbox font-weight-bold"
                *matTreeNodeDef="let node; when: hasChild"
                matTreeNodePadding
            >
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                    </mat-icon>
                </button>
                <mat-checkbox
                    [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="todoItemSelectionToggle(node)"
                    [disabled]="!isInEditMode"
                >
                    {{ node.label }}
                </mat-checkbox>
            </mat-tree-node>
        </mat-tree>

        <div class="mt-4">
            <app-form-error
                [control]="form.get('objectifsMesurage')"
                message="Une des valeurs doit être choisie"
            ></app-form-error>
        </div>

        <table>
            <tr
                class="{{ domaineReglementaire.id.toLowerCase() }}"
                *ngFor="let domaineReglementaire of domainesReglementaireList"
            >
                <td>{{ domaineReglementaire.label }}</td>
            </tr>
        </table>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AuthenticationStore, BaseComponent, NotificationService } from 'src/app/commons-lib';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { Polluant } from '../../../model/polluant.model';
import { combineLatest } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { Intervention } from 'src/app/model/intervention.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PolluantPrelevement } from '../../../model/polluant-prelevement.model';
import { Materiel, UserWizy } from 'src/app/model/user-wizy.model';
import { ConditionsPrelevement, PolluantConfig } from '../../../model/polluant-config.model';
import { UserInformationApiService } from 'src/app/services/user-information-api.service';
import { EtatValidation } from 'src/app/model/etat-progression.model';
import { getRefInfo } from '../../etude-situation/initialisation/objectifs/objectifs.component';
import { PolluantService } from '../../../services/polluant.service';

@Component({
    selector: 'app-prelevement-materiel',
    templateUrl: './prelevement-materiel.component.html',
    styleUrls: ['./prelevement-materiel.component.scss'],
})
export class InitMaterielPrelevement extends BaseComponent implements OnInit {
    diagnostic: Diagnostic;
    intervention: Intervention;
    contenuDiagnostic: PolluantPrelevement;
    polluantConfig: PolluantConfig;
    isLoaded: Boolean = false;
    isInEditMode: Boolean = false;
    isValidated: Boolean = false;

    material: Materiel;
    form: FormGroup;
    previousFormValue: any;

    materielChantierNecessaire: Boolean = false;
    contientMetop: Boolean = false;

    user: UserWizy;
    userInfo: any;

    constructor(
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService,
        public interventionService: InterventionService,
        private etatProgressionService: EtatProgressionService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationStore,
        private userInformationApiService: UserInformationApiService,
        private polluantService: PolluantService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService.show();
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.authenticationService.getCurrentUser().pipe(
                takeUntil(this.ngUnsubscribe),
                switchMap((user: UserWizy) => {
                    this.user = user;
                    return this.userInformationApiService.getUserInformationByUserId(user.id);
                })
            ),
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diag, userInfo]) => {
                    this.intervention = intervention;
                    this.diagnostic = diag;

                    combineLatest([this.polluantService.findOnePolluantConfigIndexedDB(this.diagnostic.idConfig)])
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(([polluantConfig]) => {
                            if (polluantConfig == null) {
                                this.notificationService.error(
                                    'Erreur : Merci de faire une synchro pour récupérer les configs en local.'
                                );
                                this.cnSpinnerService.hide();
                                return;
                            }
                            this.polluantConfig = polluantConfig;

                            this.userInfo = userInfo;

                            this.initData();
                            this.initForm();

                            this.isValidated = this.contenuDiagnostic.material.validated;
                            this.isLoaded = true;
                            this.cnSpinnerService.hide();
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    private initData() {
        this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;

        //On vérifie si l'écran a déja été validé
        this.isValidated = this.contenuDiagnostic.material.validated;

        //Initialisation de l'objet Materiel avec les données de la fiche Utilisateur
        // ou du contenuDiag si défini

        let numeroChrono = '0',
            numeroDebitmetre = '0';
        const materielRensigne: boolean = !!this.userInfo && !!this.userInfo.materiel;
        if (!!this.contenuDiagnostic.material.data?.numeroChrono) {
            numeroChrono = this.contenuDiagnostic.material.data.numeroChrono;
        } else if (materielRensigne) {
            numeroChrono = this.userInfo?.materiel?.numeroChrono;
        }

        if (this.contenuDiagnostic.material.data?.numeroDebitmetre != undefined) {
            numeroDebitmetre = this.contenuDiagnostic.material.data.numeroDebitmetre;
        } else if (materielRensigne) {
            numeroDebitmetre = this.userInfo.materiel.numeroDebitmetre;
        }

        if (materielRensigne == false) {
            this.notificationService.warn("Vous n'avez pas encore rensigné vos Informations matériel à votre compte.");
        }
        this.material = new Materiel(numeroChrono, numeroDebitmetre);

        //Le champ "immatriculationUmd" n'est pas présent dans le constructeur
        this.material.immatriculationUmd = this.contenuDiagnostic.material.data.immatriculationUmd;

        if (!this.contenuDiagnostic.visiteDeChantier.data.objectifsMesurage) {
            this.contenuDiagnostic.visiteDeChantier.data.objectifsMesurage = {
                data: {
                    objectifsMesurage: [],
                },
            };
        }

        //Permet de définir si un objectif de mesurage nécessite du matériel de chantier
        this.contenuDiagnostic.visiteDeChantier.data.objectifsMesurage.data.objectifsMesurage.forEach((obj: any) => {
            let refObj = getRefInfo(this.polluantConfig.objectifsMesurage, obj.reference);

            if (refObj.materielChantierNecessaire) {
                this.materielChantierNecessaire = true;
            }
        });
        //Permet de définir si un objectif de mesurage de type METOP est présent
        if (
            this.contenuDiagnostic.visiteDeChantier.data.objectifsMesurage.data.objectifsMesurage.find(
                (obj: ConditionsPrelevement) => {
                    return obj.typePrelevement === 'METOP';
                }
            )
        ) {
            this.contientMetop = true;
        }
    }

    private initForm(): void {
        //Création du formulaire en fonction des objectifs de mesurage
        if (this.materielChantierNecessaire && this.contientMetop) {
            this.form = this.formBuilder.group({
                numeroChrono: [this.material.numeroChrono, Validators.required],
                numeroDebitmetre: [this.material.numeroDebitmetre, Validators.required],
                immatriculationUmd: [this.material.immatriculationUmd, Validators.required],
            });
        } else if (this.materielChantierNecessaire && !this.contientMetop) {
            this.form = this.formBuilder.group({
                immatriculationUmd: [this.material.immatriculationUmd, Validators.required],
            });
        } else if (!this.materielChantierNecessaire && this.contientMetop) {
            this.form = this.formBuilder.group({
                numeroChrono: [this.material.numeroChrono, Validators.required],
                numeroDebitmetre: [this.material.numeroDebitmetre, Validators.required],
            });
        } else {
            this.form = undefined;
        }
    }

    private checkValidity() {
        this.contenuDiagnostic.material.data = this.form.getRawValue();
        this.diagnostic.contenuDiagnostic = this.contenuDiagnostic;

        let etat: EtatValidation = 'INVALID';
        if (this.form && this.form.valid && this.contenuDiagnostic.material.validated) {
            etat = 'VALID';
        }

        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic, true);
    }

    cancelModification() {
        this.isInEditMode = false;
        this.form.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.contenuDiagnostic.material.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.previousFormValue = this.form.getRawValue();
    }
}

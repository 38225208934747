import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/commons-lib';
import { takeUntil } from 'rxjs/operators';
import { TypeMateriau } from 'src/app/model/type-materiau.model';
import { ReferenceService } from 'src/app/services/reference.service';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';

class MateriauModalData {
    constructor(
        public listeMateriauExistant: TypeMateriau[] = [],
        public addCustomIsPossible = false,
        public contenuDiagnostic: any
    ) {}
}

class TypeMateriauModal {
    typeMateriau: TypeMateriau;
    isSelected = false;
}

@Component({
    selector: 'app-materiau-modal',
    templateUrl: './materiau-modal.component.html',
    styleUrls: ['./materiau-modal.component.scss'],
})
export class MateriauModalComponent extends BaseComponent implements OnInit {
    addCustomIsPossible = false;
    contenuDiagnostic: any;
    addMateriauIsOpen = false;
    formAddMateriau: FormGroup;
    materiauxModalList: TypeMateriauModal[] = [];

    // Utilisé pour la création des lignes de matériaux
    array = Array;
    math = Math;

    constructor(
        private readonly dialogRef: MatDialogRef<MateriauModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MateriauModalData,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly referenceService: ReferenceService,
        private readonly formBuilder: FormBuilder
    ) {
        super();
        this.prepareMateriauForm();
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass(['minwidth1000-dialog']);

        this.cnSpinnerService
            .withSpinner(this.referenceService.findAllTypesMateriau())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((typesMateriau: TypeMateriau[]) => {
                this.addCustomIsPossible = this.data.addCustomIsPossible;
                this.contenuDiagnostic = this.data.contenuDiagnostic;

                // Ajout des potentiels materiaux ajoutés par l'opérateur
                const listeTypeMateriau = typesMateriau.concat(this.contenuDiagnostic.materiauxCustom.valeur);
                const listeMateriauSelected = this.data.listeMateriauExistant;

                // Création de la liste à afficher dans le template
                this.prepareListTypeMateriauModal(listeTypeMateriau, listeMateriauSelected);
            });
    }

    // @@@@
    // @@@@ Méthodes relatives à la liste principale de TypeMateriaux

    /**
     * Prepare la liste des materiaux à afficher dans la modale
     * (contient un boolean permettant de déterminer si le materiau doit être selected ou non)
     * @param listeTypeMateriau
     * @param listeMateriauSelected
     */
    prepareListTypeMateriauModal(listeTypeMateriau: TypeMateriau[], listeMateriauSelected: TypeMateriau[]) {
        this.materiauxModalList = listeTypeMateriau.map((typeMateriau) => {
            const typeMateriauModal = new TypeMateriauModal();
            typeMateriauModal.typeMateriau = typeMateriau;

            // On détermine si le typeMateriau doit être séléctionné
            typeMateriauModal.isSelected = !!listeMateriauSelected.find(
                (materiauSelected) => materiauSelected.id === typeMateriau.id
            );
            return typeMateriauModal;
        });
    }

    /**
     * Prepare la liste des données sélectionnée à renvoyer au component parent
     */
    prepareListTypeMateriauSelected(): TypeMateriau[] {
        return this.materiauxModalList
            .filter((materiauModal) => materiauModal.isSelected)
            .map((materiauModal) => materiauModal.typeMateriau);
    }

    /**
     * Quand on clique sur la card d'un matériau
     * @param typeMateriauSelected
     */
    onClickCard(typeMateriauSelected: TypeMateriauModal) {
        typeMateriauSelected.isSelected = !typeMateriauSelected.isSelected;
    }

    /**
     * Evenement click sur le bouton valider
     */
    onClickValider() {
        this.dialogRef.close({ materiaux: this.prepareListTypeMateriauSelected() });
    }

    /**
     * Evenement click sur le bouton Annuler ou la fermeture de la modale
     */
    onClickCancel() {
        this.dialogRef.close();
    }

    // @@@@
    // @@@@ Méthodes relatives au formulaire d'ajout de materiaux customs

    /**
     * Prepare le formulaire permettant d'ajouter un materiau custom
     */
    prepareMateriauForm() {
        this.formAddMateriau = this.formBuilder.group({
            nomMateriau: [
                '',
                [
                    Validators.required,
                    (control: AbstractControl) => {
                        if (
                            control.value &&
                            ValidatorUtils.checkAlreadyExist(
                                this.formAddMateriau.controls.nomMateriau.value,
                                true,
                                undefined,
                                this.materiauxModalList.map((materiau) => materiau.typeMateriau.nom)
                            )
                        ) {
                            return { erreurNomMateriauExistant: true };
                        }
                        return null;
                    },
                ],
            ],
        });
    }

    /**
     * Permet d'afficher ou non l'input pour l'ajout d'un matériau non listé
     */
    onClickDisplayAddMateriau() {
        this.addMateriauIsOpen = !this.addMateriauIsOpen;
        if (this.addMateriauIsOpen) {
            this.prepareMateriauForm();
        }
    }

    /**
     * Prépare les données issues du formulaire permettant d'ajouter un materiau custom
     */
    prepareDataToSave(): TypeMateriau {
        if (this.formAddMateriau.valid) {
            const typeMateriauAdded = new TypeMateriau();
            typeMateriauAdded.nom = this.formAddMateriau.controls.nomMateriau.value;
            return typeMateriauAdded;
        }
    }

    /**
     * - Ajoute un materiau custim dans la liste des materiaux à afficher.
     * - Mais également dans la liste des materiaux custums du diagnostic.
     * - Ferme le formulaire
     */
    onClickAddMateriau() {
        const typeMateriauAdded = this.prepareDataToSave();
        // On sauvegarde le nouveau materiau dans la liste des materiaux customs
        this.contenuDiagnostic.materiauxCustom.valeur.push(typeMateriauAdded);

        // Ajout du nouveau materiau dans la liste de la modale
        const typeMateriauModal = new TypeMateriauModal();
        typeMateriauModal.typeMateriau = typeMateriauAdded;
        typeMateriauModal.isSelected = true;

        this.materiauxModalList.push(typeMateriauModal);

        this.addMateriauIsOpen = !this.addMateriauIsOpen;
    }
}

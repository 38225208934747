import { Component } from '@angular/core';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { CeeService } from '../../../services/cee.service';
import { CeeConfig, CeeModel, PointControle } from '../../../model/cee.model';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CODE_VIDE } from '../../../shared/cee.constants';

export interface ISolution {
    name: string;
    zones: {
        name: string;
        pointsDeControle: { libelle: string; path: string }[];
    }[];
}
@Component({
    selector: 'app-reste-afaire',
    templateUrl: './reste-a-faire.component.html',
    styleUrls: ['./reste-a-faire.component.scss'],
})
export class ResteAFaireComponent {
    iSolutions: ISolution[] = [];
    constructor(
        private readonly router: Router,
        private readonly diagnosticService: DiagnosticService,
        private readonly ceeService: CeeService
    ) {
        combineLatest([this.diagnosticService.getCurrentDiagnostic(), this.ceeService.ceeConfig$])
            .pipe(first())
            .subscribe(([diagnostic, config]) => {
                this.initRAF(config, diagnostic.contenuDiagnostic as CeeModel);
            });
    }

    private initRAF(config: CeeConfig, ceeModel: CeeModel) {
        for (const solution of ceeModel.solutions.solutions) {
            const sol = solution.infoIsolant.find((val) => this.ceeService.idsNomSolution.includes(val.id));
            const solutionName = sol ? sol.valeur : 'Isolation des réseaux';
            const iSolution: ISolution = {
                name: solutionName,
                zones: [],
            };
            for (const zoneName of Object.getOwnPropertyNames(solution.pointsDeControles.data)) {
                const zone = { name: zoneName, pointsDeControle: [] };
                if (solution.pointsDeControles.data[zoneName]) {
                    for (const raf of solution.pointsDeControles.data[zoneName].filter(
                        (ptc) =>
                            (!ptc.valeur || ptc.valeur === CODE_VIDE) &&
                            !ptc.checked &&
                            !ptc.disabled &&
                            !ptc.hide &&
                            ptc.required
                    )) {
                        const pointcontrole: PointControle = config.pointsControles.find((p) => p.idInterne === raf.id);
                        const libelle = pointcontrole.description;
                        const path = this.getPath(solutionName, zoneName, raf.id);
                        zone.pointsDeControle.push({ libelle, path });
                    }
                    if (zone.pointsDeControle.length) {
                        iSolution.zones.push(zone);
                    }
                }
            }
            if (iSolution.zones.length) {
                this.iSolutions.push(iSolution);
            }
        }
    }

    private getPath(solutionName: string, zoneName: string, idInterne: string) {
        const url = this.router.url.split('/config/')[0] + '/config/points-de-controles/controles/';
        const hash: string = btoa(
            unescape(encodeURIComponent(JSON.stringify({ solutionName, zoneName, idInterne })))
        ).replace(/=/g, '');
        return url + hash;
    }
}

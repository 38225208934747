<div class="process">
    <div class="field field-label">
        <label for="process" class="font-weight-normal">Processus client<span class="mandatory"> *</span></label>
    </div>
    <div class="field field-button">
        <button
            mat-raised-button
            color="primary"
            (click)="addProcess()"
            [disabled]="disabled"
            data-cy="ajouter-processus-button"
        >
            Ajouter
        </button>
    </div>
</div>
<div class="processus-list">
    <app-item-card *ngFor="let processus of mpca.value.processus; let i = index" class="processus-card">
        <div title class="title">{{ processus.nom }}</div>
        <div action>
            <mat-checkbox class="col-xl-6" color="primary" [checked]="processus.primary" (change)="checkPrimary(i)"
                >Principal
            </mat-checkbox>
            <button mat-icon-button aria-label="copier" (click)="dupliquerProcessus(i)" [disabled]="disabled">
                <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button aria-label="modifier" (click)="modifierProcessus(i)" [disabled]="disabled">
                <mat-icon>edit</mat-icon>
            </button>
            <button
                mat-icon-button
                color="warn"
                aria-label="supprimer"
                (click)="supprimerProcessus(i)"
                [disabled]="disabled"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </app-item-card>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

class InfoDocumentModalData {
    constructor(public valid: boolean) {}
}

@Component({
    selector: 'app-quit-without-saving-modal',
    templateUrl: './quit-without-saving-modal.component.html',
    styleUrls: ['./quit-without-saving-modal.component.scss'],
})
export class QuitWithoutSavingModalComponent implements OnInit {
    valid = true;

    constructor(
        public dialogRef: MatDialogRef<QuitWithoutSavingModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InfoDocumentModalData
    ) {}

    ngOnInit(): void {
        if (this.data) {
            this.valid = this.data.valid;
        }
    }

    quitWithoutSaving() {
        this.dialogRef.close('quit_without_saving');
    }

    quitWithSaving() {
        this.dialogRef.close('quit_with_saving');
    }

    cancel() {
        this.dialogRef.close('cancel');
    }
}

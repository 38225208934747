<div class="volume-block">
    <div class="title">
        <mat-icon class="mr-3">extension</mat-icon>
        {{ label }}
    </div>
    <div class="action" *ngIf="!disabled">
        <button mat-icon-button (click)="delete()">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>

<div *ngIf="form" class="zone-propriete-modal" [ngClass]="{ modal: isModal }" [formGroup]="form">
    <div class="header">{{ zoneCurrent | zoneFullnamePipe }}</div>
    <div class="zone-content" [ngClass]="{ 'content-modal': isModal }">
        <div class="zone-content-column col-md-6">
            <input type="hidden" formControlName="identifiant" />
            <mat-form-field appearance="fill">
                <mat-label>Complément du nom de la zone</mat-label>
                <input matInput formControlName="nom" [readonly]="!isInEditMode" (change)="genererNomZone()" />
            </mat-form-field>
            <div
                class="cndiad-form-field"
                [ngClass]="{ error: !form?.controls?.zoneContexte?.pristine && form?.controls?.zoneContexte?.errors }"
            >
                <mat-label class="required">Contexte</mat-label>
                <app-widget-select
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="zoneContexte"
                    [disabled]="!isInEditMode"
                    (ngModelChange)="majListeTypeZone()"
                >
                    <option
                        [value]="item.label"
                        [attr.data-color]="item.backgroundColor"
                        datatype="html"
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.contexteZone.items"
                    >
                        <lib-icon
                            *ngIf="item.icone != ''"
                            theme="{{ item.icone.theme }}"
                            iconName="{{ item.icone.nom }}"
                            color="{{ item.icone.couleur }}"
                        ></lib-icon>
                        {{ item.label }}
                    </option>
                </app-widget-select>
            </div>

            <div
                class="cndiad-form-field"
                [ngClass]="{ error: !form?.controls?.environnement?.pristine && form?.controls?.environnement?.errors }"
            >
                <mat-label class="required">Environnement</mat-label>
                <app-widget-select-multi
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="environnement"
                    [disabled]="!isInEditMode"
                    (ngModelChange)="majListeTypeZone()"
                    multiple
                >
                    <option
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.typeEnvironnement.items"
                        [value]="item.label"
                        [attr.data-color]="item.backgroundColor"
                        datatype="html"
                    >
                        <lib-icon
                            *ngIf="item.icone != ''"
                            theme="{{ item.icone.theme }}"
                            iconName="{{ item.icone.nom }}"
                            color="{{ item.icone.couleur }}"
                        ></lib-icon>
                        {{ item.label }}
                    </option>
                </app-widget-select-multi>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Type de bâtiment</mat-label>
                <mat-select required formControlName="usageLocaux" [disabled]="!isInEditMode">
                    <mat-option
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.usageLocaux.items"
                        [value]="item"
                    >
                        {{ item }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Type de Zone</mat-label>
                <mat-select
                    required
                    formControlName="typeZone"
                    [disabled]="!isInEditMode"
                    (selectionChange)="onTypeZoneChange()"
                >
                    <mat-option *ngFor="let item of typeDeZone" [value]="item">
                        {{ item }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="ZoneSimilaireEchantillonnage.length > 0">
                <mat-label>Zone Similaire d'Echantillonnage</mat-label>
                <mat-select formControlName="zse" [disabled]="!isInEditMode" (selectionChange)="onZSEChange()">
                    <mat-option *ngFor="let item of ZoneSimilaireEchantillonnage" [value]="item">
                        {{ item }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="cndiad-form-field" *ngIf="ZoneSimilaireEchantillonnage.length > 0">
                <button mat-flat-button color="accent" (click)="selectZoneTravail()">Zone de travail existante</button>
                <mat-label>{{ form.get('zoneAssociee').value | zoneFullnamePipe }}</mat-label>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Objectif de mesurage</mat-label>
                <mat-select
                    formControlName="objectifMesurage"
                    multiple
                    [disabled]="!isInEditMode"
                    (selectionChange)="onObjectifMesurageChange()"
                    [compareWith]="polluantService.compareObjectifs"
                    data-cy="objectifMesurage"
                >
                    <mat-option
                        *ngFor="let item of objectifsMesurageEligible"
                        [value]="item"
                        [disabled]="isDisabled(item)"
                    >
                        {{ item.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div
                class="cndiad-form-field"
                [ngClass]="{ error: !form?.controls?.ventilation?.pristine && form?.controls?.ventilation?.errors }"
            >
                <mat-label class="required">Ventilation</mat-label>
                <app-widget-select
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="ventilation"
                    [disabled]="!isInEditMode"
                    data-cy="ventilation"
                >
                    <option
                        [value]="item.label"
                        [attr.data-color]="item.backgroundColor"
                        datatype="html"
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.ventilationZone.items"
                    >
                        <lib-icon
                            *ngIf="item.icone != ''"
                            theme="{{ item.icone.theme }}"
                            iconName="{{ item.icone.nom }}"
                            color="{{ item.icone.couleur }}"
                        ></lib-icon>
                        {{ item.label }}
                    </option>
                </app-widget-select>
            </div>

            <div
                class="cndiad-form-field"
                [ngClass]="{ error: !form?.controls?.frequentation?.pristine && form?.controls?.frequentation?.errors }"
            >
                <mat-label class="required">Fréquentation</mat-label>
                <app-widget-select
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="frequentation"
                    [disabled]="!isInEditMode"
                    data-cy="frequentation"
                >
                    <option
                        [value]="item.label"
                        [attr.data-color]="item.backgroundColor"
                        datatype="html"
                        *ngFor="let item of polluantConfig.contenuListeDeroulante.frequentation.items"
                    >
                        <lib-icon
                            *ngIf="item.icone"
                            theme="{{ item.icone.theme }}"
                            iconName="{{ item.icone.nom }}"
                            color="{{ item.icone.couleur }}"
                        ></lib-icon>
                        {{ item.label }}
                    </option>
                </app-widget-select>
            </div>

            <div
                class="cndiad-form-field"
                [ngClass]="{
                    error: !form?.controls?.personnesPresentes?.pristine && form?.controls?.personnesPresentes?.errors
                }"
            >
                <mat-label class="required">Personnes présentes</mat-label>
                <app-widget-select
                    [mode]="widgetSelectModeEnum.BADGE"
                    formControlName="personnesPresentes"
                    [disabled]="!isInEditMode"
                    data-cy="personnesPresentes"
                >
                    <option value="air" datatype="icon">check</option>
                    <option value="aero" datatype="icon">close</option>
                </app-widget-select>
            </div>
        </div>

        <div class="zone-content-column col-md-6">
            <div *ngIf="isModal">
                <app-mpca-list-select
                    [ngClass]="{ error: true }"
                    [mpcaList]="mpcaList"
                    (add)="addMpca($event)"
                    (delete)="deleteMpca($event)"
                    (retirer)="retirerMpca($event)"
                    (encapsuler)="encapsulerMpca($event)"
                    [selectedMpcaList]="mpcaListSelected"
                    class="mb-5 app-mpca-list-select-container"
                    [mandatory]="true"
                    [isInEditMode]="isInEditMode"
                ></app-mpca-list-select>
            </div>
            <div class="ContextTravaux" *ngIf="isContextTravaux">
                <div class="cndiad-form-field">
                    <mat-label class="required">Activité adjacente</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="activiteAdjacente"
                        [disabled]="!isInEditMode"
                        data-cy="activiteConnexe"
                    >
                        <option
                            [value]="item.label"
                            [attr.data-color]="item.backgroundColor"
                            datatype="html"
                            *ngFor="let item of polluantConfig.contenuListeDeroulante.activiteAdjacente.items"
                        >
                            <lib-icon
                                *ngIf="item.icone != ''"
                                theme="{{ item.icone.theme }}"
                                iconName="{{ item.icone.nom }}"
                                color="{{ item.icone.couleur }}"
                            ></lib-icon>
                            {{ item.label }}
                        </option>
                    </app-widget-select>
                </div>
                <div class="cndiad-form-field">
                    <mat-label class="required">Confinement</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="confinement"
                        [disabled]="!isInEditMode"
                        data-cy="confinement"
                    >
                        <option
                            *ngFor="let item of polluantConfig.contenuListeDeroulante.typeConfinement.items"
                            [value]="item"
                            datatype="label"
                        >
                            {{ item }}
                        </option>
                    </app-widget-select>
                </div>
                <div class="cndiad-form-field">
                    <mat-label>Nombre d'extracteurs</mat-label>
                    <app-increment-input-reactive
                        class="big-increment"
                        formControlName="nombreExtracteurs"
                        [incrementControl]="form.get('nombreExtracteurs')"
                        [min]="0"
                        [step]="1"
                        [numericKeyboard]="true"
                        [readonly]="!isInEditMode"
                        data-cy="nombreExtracteurs"
                    >
                    </app-increment-input-reactive>
                </div>

                <div class="cndiad-form-field">
                    <mat-label class="required">Sas matériel</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="sasMateriel"
                        [disabled]="!isInEditMode"
                        data-cy="sasMateriel"
                    >
                        <option value="absent" datatype="label">Absent</option>
                        <option value="présent" datatype="label">Présent</option>
                    </app-widget-select>
                </div>
                <div class="cndiad-form-field">
                    <mat-label class="required">SAS personnel</mat-label>
                    <app-widget-select
                        [mode]="widgetSelectModeEnum.BADGE"
                        formControlName="sasPersonnel"
                        [disabled]="!isInEditMode"
                        data-cy="sasPersonnel"
                    >
                        <option
                            *ngFor="let item of polluantConfig.contenuListeDeroulante.sasPersonnel.items"
                            [value]="item"
                            datatype="label"
                        >
                            {{ item }}
                        </option>
                    </app-widget-select>
                </div>
            </div>
        </div>
    </div>

    <div class="zone-action" *ngIf="isModal">
        <div>
            <button
                mat-flat-button
                color="warn"
                mat-dialog-close="delete"
                (click)="modalAction('delete')"
                *ngIf="indexZone !== 0"
            >
                Supprimer
            </button>
        </div>

        <div class="group-button">
            <button mat-flat-button (click)="modalAction('cancel')">Annuler</button>
            <button
                mat-flat-button
                color="primary"
                (click)="modalAction('saveAndClose')"
                [disabled]="!isInEditMode || !formValid"
                data-cy="sauvegarder-zone-button"
            >
                Sauvegarder
            </button>
        </div>
    </div>
</div>

<div class="pb-5">
    <div class="block-title p-2 pl-5 my-3 d-inline-block" *ngIf="data">{{ data.title }}</div>
    <div class="block-subtitle p-2 pl-5 my-3">Liste des prélevements réalisés</div>
    <app-liste-detail-perimetre
        [listeEspaces]="listeEspaces"
        [data]="data"
        [typePrestation]="typePrestation"
        [isPrelevementRealise]="true"
    ></app-liste-detail-perimetre>
    <ng-container *ngIf="typePrestation === 'HAP_TERRAIN'">
        <div class="block-subtitle p-2 pl-5 my-3">Liste des prélevements non réalisés</div>
        <app-liste-detail-perimetre
            [listeEspaces]="listeEspacesNonRealises"
            [data]="data"
            [typePrestation]="typePrestation"
            [isPrelevementRealise]="false"
        ></app-liste-detail-perimetre>
    </ng-container>
</div>

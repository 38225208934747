import {cn_image_dir} from './image_dir';

/**
 *
 * @param {Map} texturesPerElement
 * @returns object
 */
export function generate_textures_per_element(texturesPerElement, width = '100%', height = '100%', prefix = '') {
    const result = {
        html: '',
        textures: new Map()
    };

    texturesPerElement.forEach((textures, el) => {
        let gradient_pattern_id = '';
        const pattern_id = prefix + el + '-pattern';
        const unique_textures = [];
        const stripped_textures_ids = [];
        textures.forEach(texture => {
            if (!unique_textures.find(tex => tex.color === texture.color && tex.texture === texture.texture)) {
                unique_textures.push(texture);
            }
        });
        const full_textures = unique_textures.filter(tex => tex.texture === 'full');
        if (full_textures.length) {
            const gradient_id = prefix + el + '-gradient';
            gradient_pattern_id = prefix + gradient_id + '-pattern';
            let gradient_pattern = `<linearGradient id="${gradient_id}">`;
            const step = Math.floor(100 / full_textures.length);
            let stop = 0;
            full_textures.forEach(tex => {
                gradient_pattern += `<stop offset="${stop}%"  stop-color="${tex.color}"/>`
                stop += step;
                gradient_pattern += `<stop offset="${stop}%"  stop-color="${tex.color}"/>`
            });
            gradient_pattern += '</linearGradient>'
            gradient_pattern += `<pattern id="${gradient_pattern_id}" x="0" y="0" width="40px" height="40px" patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)">
            <rect x="0" y="0" fill="url(#${gradient_id})" width="40px" height="40px"/>
            </pattern>`
            result.html += gradient_pattern;
        }
        unique_textures.filter(tex => tex.texture !== 'full').forEach((texture, index) => {
            const rotation = texture.texture === 'diagonal' ? -45 : texture.texture === 'horizontal' ? 90 : 0;
            const stripped_texture_id = `${el}-${index}-stripped-pattern`;
            result.html += `<pattern id="${stripped_texture_id}"
                width="16" height="16"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(${rotation})">
                    <rect width="2" height="16" fill="${texture.color}"></rect>
                </pattern>`;
            stripped_textures_ids.push(stripped_texture_id);
        });
        let pattern = `<pattern id="${pattern_id}" x="0" y="0" width="${width}" height="${height}" patternUnits="userSpaceOnUse">`
        if (gradient_pattern_id) {
            pattern += `<rect x="0" y="0" width="${width}" height="${height}" fill="url(#${gradient_pattern_id})" />`
        } else if (stripped_textures_ids.length) {
            pattern += `<rect x="0" y="0" width="${width}" height="${height}" fill="#FFFFFF" />`
        }
        stripped_textures_ids.forEach(id => {
            pattern += `<rect x="0" y="0" width="${width}" height="${height}" fill="url(#${id})"/>`;
        });
        pattern += '</pattern>';
        result.html += pattern;
        result.textures.set(el, `fill: url(#${pattern_id}); opacity: 1;`);
    });

    return result;
}

/**
 * Generate SVG pattern and fill attr for rendered path
 *
 * @param {string} texture
 * @param {string} color
 * @param {number} width
 * @param {number} height
 * @param {number} x
 * @param {number} y
 * @param {string} serial
 * @returns
 */
export function generate_pattern_for_facing(texture, color, width, height, x, y, serial = '') {
    const result = { pattern: '', style: '' };
    result.pattern += `<defs><pattern id="pattern_texture_${serial}${texture}_${width}_${x}_${y}" x="${x}" y="${y}" width="${width}" height="${height}" patternUnits="userSpaceOnUse" >`;
    result.pattern += `<filter id="pattern_texture_${serial}${texture}_${width}_${x}_${y}_flood">`;
    result.pattern += `<feFlood result="floodFill" x="0" y="0" width="${width}" height="${height}" flood-color="${color || '#FFFFFF'}" flood-opacity="1"/>`;
    result.pattern += '<feBlend in="SourceGraphic" in2="floodFill" mode="multiply" />';
    result.pattern += '</filter>';
    result.pattern += `<image xlink:href="${cn_image_dir()}texture_${texture}.jpg" x="0" y="0" width="${width}" height="${height}" preserveAspectRatio="xMidYMid meet"
    style="filter:url(#pattern_texture_${serial}${texture}_${width}_${x}_${y}_flood);"/>`;
    result.pattern += `</pattern></defs>`;
    result.style = `style="fill:url(#pattern_texture_${serial}${texture}_${width}_${x}_${y});opacity:${texture ? 0.5 : 1}"`;
    return result;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibIconModule } from 'src/app/commons-lib';
import { GestionPiecesComponent } from './gestion-pieces.component';
import { CreationPieceComponent } from './creation-piece/creation-piece.component';
import { TableauPieceComponent } from './tableau-piece/tableau-piece.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { PipesModule } from '../../../../pipes/pipes.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CnDiagSharedModule } from '../../../shared/shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
    declarations: [GestionPiecesComponent, CreationPieceComponent, TableauPieceComponent],
    imports: [
        CommonModule,
        LibIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        PipesModule,
        MatMenuModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        CnDiagSharedModule,
        MatCheckboxModule,
    ],
})
export class GestionPiecesModule {}

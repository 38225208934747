import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/commons-lib';
import { Espace } from '../../../../../../model/espace.model';

@Component({
    selector: 'app-export-svg-sub-form-espaces',
    templateUrl: './export-svg-sub-form-espaces.component.html',
    styleUrls: ['./export-svg-sub-form-espaces.component.scss'],
})
export class ExportSvgSubFormEspacesComponent extends BaseComponent implements OnInit {
    @Input() group: FormGroup;

    @Input() espaces: Espace[] = [];

    @Input() formConf: any = {};

    get espaceConf() {
        return this.formConf.espace.controls;
    }

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.group
            .get('espace')
            .valueChanges.pipe(
                tap((espace: Espace) => {
                    return espace;
                })
            )
            .subscribe();
    }

    compareWith(object1: any, object2: any) {
        return object1 && object2 && object1.id === object2.id;
    }
}

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jquery"), require("@acenv/fh-3d-viewer"), require("three"));
	else if(typeof define === 'function' && define.amd)
		define(["jquery", "@acenv/fh-3d-viewer", "three"], factory);
	else if(typeof exports === 'object')
		exports["CNMAP"] = factory(require("jquery"), require("@acenv/fh-3d-viewer"), require("three"));
	else
		root["CNMAP"] = factory(root["jQuery"], root["FH3D"], root["THREE"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__47__, __WEBPACK_EXTERNAL_MODULE__568__, __WEBPACK_EXTERNAL_MODULE__381__) => {
return 
"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Editor for markers
//***********************************************************************************
//***********************************************************************************
import { cn_marker } from "../model/cn_marker";
import { cn_element } from "../model/cn_element";
import { cn_storey } from "../model/cn_storey";
import { cn_marker_handler } from "./cn_marker_handler";
import { cn_view_overlay } from "./cn_view_overlay";
import { cn_svg_tool_edition } from "./cn_svg_tool_edition";
import { cn_svg_map } from "./cn_svg_map";
import { cn_roof } from '../model/cn_roof';

export class cn_svg_tool_marker_edition extends cn_svg_tool_edition {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_svg_map} map
	 */
	constructor(map) {
		super(map);

		this._handler = null;

		this._marker_class = cn_marker;
	}

	/**
	 * Intended for 3d compatible tools. Must return true if the tool can edit the element 
	 * @param {{element: cn_element, storey: cn_storey}} storey_element 
	 * @returns {boolean}
	 */
	can_edit(storey_element) {
		return storey_element.element.constructor == this._marker_class;
	}

	//***********************************************************************************
	/**
	 * Builds a new marker handler.
	 * TODO: derivate this in order to manage marker derivates
	 * @param {Array<cn_marker>} markers
	 * @returns {cn_marker_handler}
	 */
	_build_handler(markers) {
		return new cn_marker_handler(markers,(this._map)?this._map:this._view_overlay);
	}

	//***********************************************************************************
	on_selection_change() {

		const obj = this;
		this._handler = null;
		this._handlers = [];

		//*** We expect the selection to contain only openings */
		var sel = (this._view_overlay)?this._view_overlay.get_selection().map(s => s.element):this._controller.get_selection();
		if (sel.length == 0 || sel.some(elt => elt.constructor != this._marker_class)) 
			return false;

		this._handler = this._build_handler(sel);
        this._handlers.push(this._handler);
		if (this._view_overlay)
		{
			this._handler.on("shape_changed",function() {
				obj._view_overlay.refresh_3d();
			});
		}
		return true;
	}
}


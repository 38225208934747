"use strict";

import { fh_matrix, fh_polygon, fh_solid } from "@acenv/fh-3d-viewer";
import { cn_camera } from "../svg/cn_camera";
import { cnx_add, cnx_clone } from "./cn_utilities";

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2023 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************


//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_bbp_geometry
 * Class to describe geometry for bbp
 */
export class cn_bbp_geometry {
    //*******************************************************
    /**
     * Constructor
     */
    constructor(instance=false) {
        if (instance)
        {
            this.instance = -1;
            this.matrix = new fh_matrix().values;
        }
        else
        {
            this.vertices = [];
            this.triangles = [];
            this.extension_data = {};
            this.zoffset = 0;
        }
        this.color = [1,1,1,1];
        this.texture = "";
        this.views = ["3d"];
    }

    /**
     * Build geometry from a polygon
     * @param {fh_polygon} polygon
     * @param {number} zoffset
     * @return {cn_bbp_geometry}
     */
    static from_polygon(polygon, zoffset=0) {

        polygon.compute_tesselation();

        var geometry = new cn_bbp_geometry();
        geometry.vertices = polygon.tesselation_vertices.flat();
        if (zoffset != 0)
        {
            for (var k=2;k<geometry.vertices.length;k+=3)
                geometry.vertices[k] += zoffset;
        }
        geometry.triangles = polygon.tesselation_triangles.concat([]);

        geometry.extension_data = cn_bbp_geometry._polygon_extension_data(polygon,zoffset);

        return geometry;
    }

    static _polygon_extension_data(polygon, zoffset)
    {
        const extension = {};
        extension.type = "polygon";
        extension.point = cnx_clone(polygon.get_point());
        extension.normal = cnx_clone(polygon.get_normal());
        extension.contour_vertices = polygon.contour_vertices.map(v => cnx_clone(v));
        extension.contour_sizes = polygon.contour_sizes.concat([]);
        extension.contour_orientations = polygon.contour_orientations.concat([]);
        extension.contour_parents = polygon.contour_parents.concat([]);
        if (zoffset != 0)
        {
            extension.point[2] += zoffset;
            extension.contour_vertices.forEach(v => v[2] += zoffset);
        }
        return extension;
    }

    /**
     * Build geometry from a list of polygons
     * @param {Array<fh_polygon>} polygons
     * @param {number} zoffset
     * @return {cn_bbp_geometry}
     */
    static from_polygons(polygons, zoffset=0) {
        var solid = new fh_solid();
        for (var i in polygons)
            solid.add_face(polygons[i]);
        return cn_bbp_geometry.from_solid(solid,zoffset);
    }

    /**
     * Build geometry from a solid
     * @param {fh_solid} solid
     * @param {number} zoffset
     * @return {cn_bbp_geometry}
     */
    static from_solid(solid, zoffset=0) {
        var geometry = new cn_bbp_geometry();
        solid.compute_tesselation();
        geometry.vertices = solid.tesselation_vertices.flat();
        geometry.triangles = solid.tesselation_triangles.concat([]);

        if (zoffset != 0)
        {
            for (var k=2;k<geometry.vertices.length;k+=3)
                geometry.vertices[k] += zoffset;
        }

        geometry.extension_data = {};
        geometry.extension_data.type = "solid";
        geometry.extension_data.faces = solid.get_faces().map(face => cn_bbp_geometry._polygon_extension_data(face,zoffset));

        return geometry;
    }

    /**
     * Build geometry from an instance
     * @param {number} instance_id
     * @param {fh_matrix} matrix
     * @param {number} zoffset
     * @return {cn_bbp_geometry}
     */
    static from_instance(instance_id, matrix, zoffset=0) {
        var geometry = new cn_bbp_geometry(true);
        geometry.instance = instance_id;
        geometry.matrix = matrix.values.concat([]);
        if (zoffset > 0) geometry.matrix[14] += zoffset;
        return geometry;
    }

    /**
     * Draw the geometry in svg
     * @param {cn_camera} camera
     * @param {string} svg_class
     * @return {string}
     */
    draw_svg(camera,svg_class) {
        var html = "";
        if (!this.extension_data || typeof(this.extension_data.type) != 'string') return html;

        var faces = [];
        if (this.extension_data.type == "polygon")
            faces = [this.extension_data];
        else if (this.extension_data.type == "solid")
            faces = this.extension_data.faces;

        var impossible = false;
        faces.forEach(face => {
            html += `<path class="${svg_class}" d="`;
            var offset=0;
            for (var nct=0;nct<face.contour_sizes.length;nct++)
            {
                const sz = face.contour_sizes[nct];
                html += `M `;
                for (var k=0;k<sz;k++)
                {
                    if (k == 1) html += `L `;
                    const p = camera.world_to_screen(cnx_add(face.contour_vertices[offset+k],[0,0,this.zoffset]));
                    if (!p.length)
                        impossible = true;
                    else
                        html += `${p[0]} ${p[1]} `;
                }
                html += `Z `;
                offset += sz;
            }
            html += `" />`;
        });
        if (impossible) return "";
        return html;
    }
};

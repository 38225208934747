"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_zone  : Manipulation of zone
//***********************************************************************************
//***********************************************************************************

import {cn_wall} from "../model/cn_wall";
import {cn_svg_tool_creation} from "./cn_svg_tool_creation";
import {cn_svg_map} from "./cn_svg_map";
import {cn_camera} from "./cn_camera";
import {cn_box, cn_space, cn_storey, cn_zone, extension_instance} from '..';
import {zone_colors} from '../utils/cn_zone_color';

export class cn_svg_tool_zone extends cn_svg_tool_creation {
    //***********************************************************************************
    /**
     * Constructor
     * @param {cn_svg_map} svg_map
     */
    constructor(svg_map, type_zone) {
        super(svg_map);
        this.svg_map = svg_map;
        this.current_zone = null;
        this.replicate_on_clone = false;
        this.type_zone = type_zone;
        this._mouseover_space = null;
		this.element_filter = function(element) {return element.constructor == cn_wall};
    }

    //***********************************************************************************
    /**
     * Open tool
     */
    open_tool() {
		super.open_tool();
    }

    set_type_zone(type_zone) {
        this.type_zone = type_zone;
        if (!this._building.zones[this.type_zone]) {
            this._building.zones[this.type_zone] = [];
        }
    }

    //***********************************************************************************
    /**
     * SVG rendering
     * @param {cn_camera} camera
     * @returns {string} svg rendered
     */
    draw(camera) {
        var html = "";
        if (this._mouseover_space)
        {
            html += this._mouseover_space.draw(camera,["mouseover"]);
        }
        return html;
    }

    clear_move(){
        super.clear_move();
        this._mouseover_space = null;
    }
    //***********************************************************************************
    /**
     * click management
     * @param {object} ev
     * @returns {boolean} true if click was used
     */
    click(ev) {
        if (this.current_zone) {
            const position = [...ev.mouse_world, 0];
            const selected_space = this._scene.find_space(position);
            const current_storey = this._scene.storey;
            if (selected_space && !selected_space.outside) {
                this.associate_space_to_zone(selected_space, current_storey);
            }
        }
        return true;
    }

    /**
     * Associate space into current zone
     *
     * @param {cn_space} selected_space
     * @param {cn_storey} current_storey
     */
    associate_space_to_zone(selected_space, current_storey) {
        this.push_transaction(`Ajout de pièces dans une zone`);
        const existing_zone_for_space = this._search_zone_with_space_in_storey(selected_space, current_storey);
        if (existing_zone_for_space) {
            if (existing_zone_for_space.ID === this.current_zone) {
                extension_instance.space.on_change_zone(selected_space, existing_zone_for_space, null);
            }
            this._handle_remove_room_on_clone(current_storey, existing_zone_for_space, selected_space);
            this._push_item_rooms_of_zone(existing_zone_for_space);
            existing_zone_for_space.remove_room(selected_space.ID, current_storey.ID);
        }
        if (!existing_zone_for_space || existing_zone_for_space.ID !== this.current_zone) {
            const current_zone_building = this._scene.building.zones[this.type_zone].find(zone => zone.ID === this.current_zone);
            if (current_zone_building) {
                extension_instance.space.on_change_zone(selected_space, existing_zone_for_space, current_zone_building);
                this._handle_add_room_on_clone(current_storey, current_zone_building, selected_space);
                this._push_item_rooms_of_zone(current_zone_building);
                current_zone_building.rooms.push({ space: selected_space.ID, storey: this._scene.storey.ID });
            }
        }
    }

    /**
     * Push rooms in transaction item
     *
     * @param {cn_zone} current_zone
     */
    _push_item_rooms_of_zone(current_zone) {
        const zone_index = this._scene.building.zones[this.type_zone].findIndex(zone => zone.ID === current_zone.ID);
        this.push_item_set(this._scene.building.zones[this.type_zone][zone_index], 'rooms');
    }

    /**
     * Remove equivalent rooms to cloned storeys' equivalent zones
     *
     * @param {cn_storey} current_storey
     * @param {cn_zone} existing_zone_for_space
     * @param {cn_space} selected_space
     */
    _handle_remove_room_on_clone(current_storey, existing_zone_for_space, selected_space) {
        if (this.replicate_on_clone && current_storey.ID === existing_zone_for_space.main_storey && current_storey.clone_of !== null) {
            this._get_cloned_storeys(current_storey).forEach(storey => {
                const corresponding_zone = this._search_corresponding_zone_on_clone(existing_zone_for_space, storey);
                if (corresponding_zone) {
                    this._push_item_rooms_of_zone(corresponding_zone);
                    corresponding_zone.remove_room(selected_space.ID, storey.ID);
                }
            });
        }
    }

    /**
     * Add equivalent rooms to cloned storeys
     *
     * @param {cn_storey} current_storey
     * @param {cn_zone} current_zone_building
     * @param {cn_space} selected_space
     */
    _handle_add_room_on_clone(current_storey, current_zone_building, selected_space) {
        if (this.replicate_on_clone && current_storey.ID === current_zone_building.main_storey && current_storey.clone_of !== null) {
            this._get_cloned_storeys(current_storey).forEach(storey => {
                const existing_zone_for_space_in_clone_storey = this._search_zone_with_space_in_storey(selected_space, storey);
                if (existing_zone_for_space_in_clone_storey && existing_zone_for_space_in_clone_storey.main_storey === storey.ID) {
                    this._push_item_rooms_of_zone(existing_zone_for_space_in_clone_storey);
                    existing_zone_for_space_in_clone_storey.remove_room(selected_space.ID, storey.ID);
                }
                if (!existing_zone_for_space_in_clone_storey || existing_zone_for_space_in_clone_storey.main_storey === storey.ID) {
                    const corresponding_zone = this._search_corresponding_zone_on_clone(current_zone_building, storey);
                    if (corresponding_zone) {
                        this._push_item_rooms_of_zone(corresponding_zone);
                        corresponding_zone.rooms.push({ space: selected_space.ID, storey: storey.ID });
                    }
                }
            });
        }
    }

    /**
     * Search zone which may refer that room (space and storey)
     *
     * @param {cn_space} selected_space
     * @param {cn_storey} storey
     * @returns
     */
    _search_zone_with_space_in_storey(selected_space, storey) {
        return this._scene.building.zones[this.type_zone].
            find(zone => zone.rooms.find(room => room.space === selected_space.ID && room.storey === storey.ID));
    }

    /**
     * Get list of cloned storeys of current storey
     *
     * @param {cn_storey} current_storey
     * @returns
     */
    _get_cloned_storeys(current_storey) {
        return this._scene.building.storeys.filter(storey => storey.storey_index !== current_storey.storey_index
            && storey.clone_of !== null && storey.clone_of.ID === current_storey.clone_of.ID);
    }

    /**
     * Search equivalent zone of defined zone in targetted storey
     * (clone algorithm)
     *
     * @param {cn_zone} zone
     * @param {cn_storey} storey
     * @returns
     */
    _search_corresponding_zone_on_clone(zone, storey) {
        let result = null;
        const eligibles_zones = this._scene.building.zones[this.type_zone].filter(z => z.main_storey === storey.ID);
        if (zone.rooms.length === 0) {
            const empty_zones = eligibles_zones.filter(z => z.rooms.length === 0);
            if (empty_zones.length === 1) {
                result = empty_zones[0]
            } else {
                result = empty_zones.find(z => z.name === zone.name);
            }
        } else {
            const current_storey_rooms = zone.rooms.filter(room => room.storey === this._scene.storey.ID).map(room => room.space);
            result = eligibles_zones.find(z => z.rooms.filter(room => room.storey === z.main_storey).every(room => current_storey_rooms.includes(room.space)));
        }
        return result;
    }

    //***********************************************************************************
    /**
     * passive move management
     * @param {object} ev
     * @returns {boolean} true if passive move was used
     */
    move(ev) {
        if (this.current_zone)
            this._mouseover_space = this._scene.find_space(ev.mouse_world);
        return true;
    }

    /**
     * Create a new zone
     *
     * @param {string} zone_name
     * @param {string} zone_type
     * @return {cn_zone}
     */
    add_zone(zone_name, zone_type = '') {
        this.push_transaction(`Création d'une nouvelle zone`);
        this.push_item_set(this._scene.building.zones, this.type_zone);
        const new_zone = new cn_zone(zone_name, this._scene.storey.ID, zone_type);
        new_zone.color = zone_colors[this._scene.building.zones[this.type_zone].length % 10];
        this._scene.building.zones[this.type_zone].push(new_zone);
        if (this._scene.storey.clone_of !== null) {
            this._scene.building.storeys.filter(storey => storey.storey_index !== this._scene.storey.storey_index
                && storey.clone_of !== null && storey.clone_of.ID === this._scene.storey.clone_of.ID).forEach(storey => {
                this._scene.building.zones[this.type_zone].push(new cn_zone(zone_name, storey.ID));
            });
        }
        return new_zone;
    }

    /**
     *
     * Modify a zone
     *
     * @param {cn_zone} zone
     * @param {string} new_name
     * @param {string} new_color
     * @param {string} zone_type
     */
    modify_zone(zone, new_name, new_color, zone_type = '', space_auto_numerotation_idx = 0) {
        const old_zone_index = this._scene.building.zones[this.type_zone].findIndex(z => zone.ID === z.ID);
        this.push_transaction(`Modification d'une zone`);
        this.push_item_set(this._scene.building.zones[this.type_zone][old_zone_index], 'name');
        this.push_item_set(this._scene.building.zones[this.type_zone][old_zone_index], 'color');
        this.push_item_set(this._scene.building.zones[this.type_zone][old_zone_index], 'zone_type');
        const old_zone = this._scene.building.zones[this.type_zone][old_zone_index];
        old_zone.name = new_name;
        old_zone.zone_type = zone_type;
        if (new_color) {
            old_zone.color = new_color;
        }
    }

    /**
     * Delete zone by id
     *
     * @param {string} zone_id
     */
    delete_zone(zone_id) {
        this.push_transaction(`Suppression d'une zone`);
        this.push_item_set(this._scene.building.zones, this.type_zone);
        if (this._scene.storey.clone_of !== null) {
            const current_zone = this._scene.building.zones[this.type_zone].find(zone => zone.ID === zone_id);
            this._scene.building.storeys.filter(storey => storey.storey_index !== this._scene.storey.storey_index
                && storey.clone_of !== null && storey.clone_of.ID === this._scene.storey.clone_of.ID).forEach(storey => {
                    const corresponding_zone = this._search_corresponding_zone_on_clone(current_zone, storey);
                    if (corresponding_zone) {
                        this._scene.building.zones[this.type_zone].splice(
                            this._scene.building.zones[this.type_zone].findIndex(zone => zone.ID === corresponding_zone.ID), 1);
                    }
                });
        }

        const zone_index = this._scene.building.zones[this.type_zone].findIndex(zone => zone.ID === zone_id);
        if (zone_index >= 0) {
            extension_instance.space.on_delete_zone(this._scene.building.zones[this.type_zone][zone_index], this._building.transaction_manager);
            this._scene.building.zones[this.type_zone].splice(zone_index, 1);
            this.current_zone = null;
        }
    }

    /**
     * Set current zone id
     *
     * @param {string} current_zone
     */
    set_current_zone(current_zone) {
        this.current_zone = current_zone;
    }

    /**
     * Set replicate clone mode
     *
     * @param {boolean} is_active
     */
    set_replicate_clone_mode(is_active) {
        this.replicate_on_clone = is_active;
    }

    /**
     * Center camera on selected zone
     *
     * @param zone_id
     * @param storey
     */
    center_camera_on_selected_zone(zone_id, storey) {
        const current_zone = this._scene.building.zones[this.type_zone].find(zone => zone.ID === zone_id);
        if (current_zone && current_zone.rooms && current_zone.rooms.length) {
            const rooms = current_zone.rooms.map(r => storey.scene.spaces.find(s => s.ID === r.space));
            if (rooms.length) {
                const bb = this.get_box(rooms);
                this.svg_map.center_camera_on_custom_bounding_box(bb);
            }
        }
    }

    /**
     * get bounding box of an array of spaces
     * @param spaces
     * @returns {cn_box}
     */
    get_box(spaces) {
        let box = new cn_box();
        for (const s in spaces) {
            if (spaces[s]) {
                for (var i in spaces[s].contours) {
                    box.enlarge_box(spaces[s].contours[i].get_bounding_box());
                }
            }
        }
        return box;
    }
}


import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FileService } from './file.service';
import { OfflineStorageService, ResourceWrapper } from '../shared/offline/offline-storage.service';
import { STORAGE_KEY_INTERVENTION_FILES } from '../shared/constants/indexeddb.constants';
import { InterventionFile, TypeReferenceFichier } from '../model/intervention-file.model';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { InterventionApiService } from './intervention-api.service';

@Injectable({
    providedIn: 'root',
})
export class InterventionFileApiService {
    public resourceUrl = `${environment.apiUrl}/intervention-file`;

    private http: HttpClient;

    // Ressource pour le wrapper HL
    private resource: ResourceWrapper<InterventionFile, string> = this.offlineStorage.wrapRestResource<
        InterventionFile,
        string
    >({
        cacheName: STORAGE_KEY_INTERVENTION_FILES,
        idField: 'id',
        resourceUrl: this.resourceUrl,
    });

    constructor(
        private httpBackend: HttpBackend,
        private fileService: FileService,
        private offlineStorage: OfflineStorageService,
        private interventionApiService: InterventionApiService
    ) {
        this.http = new HttpClient(httpBackend);
    }

    /**
     * Get un objet InterventionFile
     * @param interventionFileId
     */
    findOne(interventionFileId: string): Observable<InterventionFile> {
        return this.resource.getOne(interventionFileId);
    }

    /**
     * Renvoie une liste d'InterventionFile
     * à partir d'une liste d'ids envoyés en paramètre
     * @param idsIntervention
     */
    findByIds(idsIntervention: string[]): Observable<InterventionFile[]> {
        const queryParams: any = {};
        if (idsIntervention && idsIntervention.length) {
            queryParams.interventionIds = idsIntervention;
            return this.resource.getAll(queryParams);
        } else {
            return of([]);
        }
    }

    /**
     * Renvoie une liste d'interventionFil
     * à partir d'une intervention, diagnostic et reference
     * @param interventionId
     * @param diagnosticId
     * @param referenceId
     * @param typeReferenceFichier
     * @param fileId
     */
    findByIdInterventionIdDiagnosticIdReference(
        interventionId: string,
        diagnosticId: string,
        referenceId: string,
        typeReferenceFichier: TypeReferenceFichier,
        fileId: string
    ): Observable<InterventionFile[]> {
        const queryParams: any = {};
        if (interventionId && referenceId) {
            queryParams.interventionId = interventionId;
            queryParams.referenceId = referenceId;
            queryParams.typeReferenceFichier = typeReferenceFichier;
            queryParams.fileId = fileId;

            if (diagnosticId) {
                queryParams.diagnosticId = diagnosticId;
            }
            return this.resource.getAll(
                queryParams,
                (interventionFile) => {
                    if (diagnosticId) {
                        return (
                            interventionFile.interventionId === interventionId &&
                            interventionFile.diagnosticId === diagnosticId &&
                            interventionFile.typeReferenceFichier === typeReferenceFichier &&
                            interventionFile.referenceId === referenceId
                        );
                    }
                    return (
                        interventionFile.interventionId === interventionId &&
                        interventionFile.typeReferenceFichier === typeReferenceFichier &&
                        interventionFile.referenceId === referenceId
                    );
                },
                `${this.resourceUrl}/find-by-all-attributs`
            );
        } else {
            return of([]);
        }
    }

    /**
     * Renvoie une liste d'interventionFil
     * à partir d'une intervention, diagnostic et reference
     * @param interventionId
     * @param typeReferenceFichier
     * @param fileId
     */
    findByIdInterventionFileId(
        interventionId: string,
        typeReferenceFichier: TypeReferenceFichier,
        fileId: string
    ): Observable<InterventionFile[]> {
        const queryParams: any = {};
        if (interventionId && fileId) {
            queryParams.interventionId = interventionId;
            queryParams.typeReferenceFichier = typeReferenceFichier;
            queryParams.fileId = fileId;

            return this.resource.getAll(
                queryParams,
                (interventionFile) => {
                    return (
                        interventionFile.interventionId === interventionId &&
                        interventionFile.typeReferenceFichier === typeReferenceFichier &&
                        interventionFile.fileId === fileId
                    );
                },
                `${this.resourceUrl}/find-by-all-attributs`
            );
        } else {
            return of([]);
        }
    }

    findAllByAllAttributs(
        interventionId: string,
        diagnosticId: string,
        typesReferenceFichier: TypeReferenceFichier[]
    ): Observable<InterventionFile[]> {
        const queryParams: any = {};
        if (interventionId && typesReferenceFichier) {
            queryParams.interventionId = interventionId;
            queryParams.typesReferenceFichier = typesReferenceFichier;

            if (diagnosticId) {
                queryParams.diagnosticId = diagnosticId;
            }

            return this.resource.getAll(
                queryParams,
                (interventionFile) => {
                    if (diagnosticId) {
                        return (
                            interventionFile.interventionId === interventionId &&
                            interventionFile.diagnosticId === diagnosticId &&
                            typesReferenceFichier.includes(interventionFile.typeReferenceFichier)
                        );
                    }
                    return (
                        interventionFile.interventionId === interventionId &&
                        typesReferenceFichier.includes(interventionFile.typeReferenceFichier)
                    );
                },
                `${this.resourceUrl}/find-all-by-all-attributs`
            );
        } else {
            return of([]);
        }
    }

    /**
     * Met à jour un InterventionFile
     * @param interventionFile
     */
    upsert(interventionFile: InterventionFile): Observable<InterventionFile> {
        return this.resource.save(interventionFile);
    }

    /**
     * Supprime un InterventionFile
     * @param interventionFile
     */
    delete(interventionFile: InterventionFile): Observable<any> {
        return this.resource.delete(interventionFile);
    }

    /**
     * Récupère la liste d'intervention File à partir des interventions chargées en indexDB
     */
    pullInterventionFiles(): Observable<InterventionFile[]> {
        return this.interventionApiService.findAllForCurrentUserBetweenDates().pipe(
            switchMap((interventions) => {
                const idsInterventions = interventions.flatMap((intervention) => intervention.id);

                return this.findByIds(idsInterventions).pipe(
                    tap((interventionFiles) =>
                        console.log(`Récupération de tous les fichiers d'interventions`, interventionFiles)
                    ),
                    catchError((err) => {
                        console.log(`Interventions non récupérées (erreur)`, err);
                        return of(null);
                    })
                );
            })
        );
    }

    /**
     * Push les interventions présentes dans la base indexDB
     */
    pushInterventionFiles(): Observable<InterventionFile[]> {
        return this.resource.push();
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Build nomenclatures
//***********************************************************************************
//***********************************************************************************

import { cn_azimut, cn_mul} from "../cn_utilities";
import {
    CLOSING_LIST,
    code_to_label,
    FRAME_LIST,
    GLAZING_GAZ_LIST,
    GLAZING_LIST,
    OPENING_LIST,
    SILL_LIST,
    TRANSOM_LIST,
    VERTICAL_OPENING_LIST,
} from "../../model/cn_opening_type";
import { cn_building } from "../../model/cn_building";
import { ROOF_GLAZING_LIST, ROOF_OPENING_CATEGORY_LIST } from "../../model/cn_roof_opening_type";
import { FENCE_LOCK_LIST, FENCE_MATERIAL_LIST, FENCE_OPENING_LIST } from "../../model/cn_fence_opening_type";
import { STOREY_EXTERIOR_LABEL } from "../../model/cn_storey";
import { cn_nomenclature, find_zone } from "../cn_nomenclature";


//***********************************************************************************
//***********************************************************************************
/**
 * Buils opening and roof openings nomenclatures for the building
 * @param {cn_building} building
 * @param is_exterior
 * @returns {cn_nomenclature[]}
 */

export function cn_nomenclature_openings(building, is_exterior = false) {
    const output = [];
    output.push(new cn_nomenclature("Nom"));
    output.push(new cn_nomenclature("Type"));
    output.push(new cn_nomenclature("Etage"));
    output.push(new cn_nomenclature("Zone"));
    output.push(new cn_nomenclature("Espace 1"));
    output.push(new cn_nomenclature("Espace 2"));
    output.push(new cn_nomenclature("Orientation", "°"));
    if (!is_exterior) {
        output.push(new cn_nomenclature("Menuiserie"));
        output.push(new cn_nomenclature("Vitrage"));
    } else {
        output.push(new cn_nomenclature("Matériau"));
        output.push(new cn_nomenclature("Serrure"));
    }
    output.push(new cn_nomenclature("Ouverture"));
    output.push(new cn_nomenclature("Panneaux"));
    if (!is_exterior) {
        output.push(new cn_nomenclature("Imposte"));
        output.push(new cn_nomenclature("Allège"));
        output.push(new cn_nomenclature("Fermeture"));
    }
    output.push(new cn_nomenclature("Largeur", "m"));
    output.push(new cn_nomenclature("Hauteur", "m"));
    output.push(new cn_nomenclature("Décalage", "m"));
    if (!is_exterior) {
        output.push(new cn_nomenclature("Hauteur d'imposte", "m"));
        output.push(new cn_nomenclature("Hauteur d'allège", "m"));
    }
    output.push(new cn_nomenclature("Surface", "m²"));
    output.push(new cn_nomenclature("Surface vitrée", "m²"));
    if (!is_exterior) {
        output.push(new cn_nomenclature("Uw", "W/m²/K"));
        output.push(new cn_nomenclature("Facteur solaire", "%"));
        output.push(new cn_nomenclature("Transmittance", "%"));
    }

    const zones = building.zones.usage;

    building.element_types.forEach(et => {if (et.compute_physics) et.compute_physics();})

    /*** exterior */
    if (is_exterior) {
        populate_nomenclature_openings(output, zones, building.exterior, is_exterior);
    } else {
        /** interior */
        building.storeys.length && building.storeys.forEach((storey) => {
            /*** openings */
            populate_nomenclature_openings(output, zones, storey, is_exterior);
            //*** roof openings */
            populate_nomenclature_roof_openings(output, zones, storey);
        });
    }
    return output;
}

//***********************************************************************************
//***********************************************************************************
/**
 /**
 * Builds openings nomenclatures for the given storey
 * @param output
 * @param zones
 * @param storey
 */
function populate_nomenclature_openings(output, zones, storey, is_exterior = false) {
    storey.scene.walls.length && storey.scene.walls.forEach((wall) => {
        wall.openings.length && wall.openings.forEach((opening) => {
            const type = opening.opening_type;
            let k = 0;

            //*** name */
            output[k].values.push(type.get_label());
            k++

            //*** Type */
            let tp = (type.category === "window") ? "Fenêtre" : "Porte";
            if (type.free) tp += " libre";
            output[k].values.push(tp);
            k++

            //*** storey */
            let storey_name = storey.exterior ? STOREY_EXTERIOR_LABEL : storey.storey_index;
            output[k].values.push(storey_name);
            k++

            //*** Zone */
            let zone_to_display = find_zone(zones, wall.spaces[0].ID, storey.ID);
            if (!zone_to_display) {
                zone_to_display = find_zone(zones, wall.spaces[1].ID, storey.ID);
            }
            output[k].values.push(zone_to_display);
            k++

            //*** Space 1 */
            output[k].values.push(wall.spaces[0].get_name(storey));
            k++

            //*** Space 2 */
            output[k].values.push(wall.spaces[1].get_name(storey));
            k++

            //*** Orientation */
            const flow_normal = cn_mul(wall.bounds.normal, wall.get_flow_direction() ? 1 : -1);
            output[k].values.push(cn_azimut(flow_normal,storey.building.compass_orientation));
            k++

            if (type.free) {
                if (!is_exterior) {
                    //*** Frame  */
                    output[k].values.push("");
                    k++

                    //*** Glazing  */
                    output[k].values.push("");
                    k++

                    //*** Opening  */
                    output[k].values.push("");
                    k++

                    //*** Panels  */
                    output[k].values.push(0);
                    k++

                    //*** Transom  */
                    output[k].values.push("");
                    k++

                    //*** Sill  */
                    output[k].values.push("");
                    k++

                    //*** Closing  */
                    output[k].values.push("");
                    k++
                } else {
                    //*** Materiau  */
                    output[k].values.push("");
                    k++

                    //*** Serrure  */
                    output[k].values.push("");
                    k++

                    //*** Opening  */
                    output[k].values.push("");
                    k++

                    //*** Panels  */
                    output[k].values.push(0);
                    k++
                }
            } else {
                if (!is_exterior) {
                    //*** Frame  */
                    output[k].values.push(code_to_label(type.frame, FRAME_LIST));
                    k++

                    //*** Glazing  */
                    var glazing = code_to_label(type.glazing, GLAZING_LIST);
                    if (type.glazing == "double") glazing += " / " + code_to_label(type.glazing_gaz, GLAZING_GAZ_LIST);
                    output[k].values.push(glazing);
                    k++

                    //*** Opening  */
                    var opening = code_to_label(type.opening, OPENING_LIST);
                    if (type.opening == "vertical") opening += " / " + code_to_label(type.vertical_opening, VERTICAL_OPENING_LIST);
                    output[k].values.push(opening);
                    k++
                } else {
                    //*** Materiau  */
                    output[k].values.push(code_to_label(type.material, FENCE_MATERIAL_LIST));
                    k++

                    //*** Serrure  */
                    output[k].values.push(code_to_label(type.lock, FENCE_LOCK_LIST));
                    k++

                    //*** Opening  */
                    output[k].values.push(code_to_label(type.opening, FENCE_OPENING_LIST));
                    k++
                }

                //*** Panels  */
                output[k].values.push(type.panels);
                k++

                if (!is_exterior) {
                    //*** Transom  */
                    output[k].values.push(code_to_label(type.transom, TRANSOM_LIST));
                    k++

                    //*** Sill  */
                    output[k].values.push(code_to_label(type.sill, SILL_LIST));
                    k++

                    //*** Closing  */
                    output[k].values.push(code_to_label(type.closing, CLOSING_LIST));
                    k++
                }
            }

            //*** Width  */
            output[k].values.push(Number(type.width.toFixed(2)));
            k++

            //*** height  */
            output[k].values.push(Number(type.height.toFixed(2)));
            k++

            //*** z  */
            output[k].values.push(Number(type.z.toFixed(2)));
            k++

            if (!is_exterior) {
                //*** transom_height  */
                output[k].values.push((!type.free && type.transom === "none") ? 0 : Number(type.transom_height));
                k++

                //*** sill height  */
                output[k].values.push((!type.free && type.sill === "none") ? 0 : Number(type.sill_height));
                k++
            }

            //*** Area  */
            output[k].values.push(Number(type.full_area.toFixed(2)));
            k++

            //*** Glazing Area  */
            output[k].values.push(Number(type.glazing_area.toFixed(2)));
            k++

            if (!is_exterior)
            {
                if (type.free)
                {
                    //*** Uw  */
                    output[k].values.push("");
                    k++

                    //*** Sw  */
                    output[k].values.push("");
                    k++

                    //*** Tl  */
                    output[k].values.push("");
                    k++
                }
                else
                {
                    //*** Uw  */
                    output[k].values.push(Number(type.Uw.toFixed(3)));
                    k++

                    //*** Sw  */
                    output[k].values.push(Number(type.Sw.toFixed(3)));
                    k++

                    //*** Tl  */
                    output[k].values.push(Number(type.Tl.toFixed(3)));
                    k++
                }
            }

        });
    });
}

/**
 * Builds roof openings nomenclatures for the given storey
 * @param output
 * @param zones
 * @param storey
 */
function populate_nomenclature_roof_openings(output, zones, storey) {
    if (storey.roof) {
        const roof = storey.roof;
        roof.openings.length && roof.openings.forEach((roof_opening) => {
            const type = roof_opening.opening_type;
            let k = 0;

            //*** Name */
            output[k].values.push(type.get_label());
            k++

            //*** Type */
            output[k].values.push(code_to_label(type.category, ROOF_OPENING_CATEGORY_LIST));
            k++

            //*** Storey */
            output[k].values.push(storey.storey_index);
            k++

            //*** Zone */
            const space = storey.scene.find_space(roof_opening.position)
            const zone_to_display = find_zone(zones, space.ID, storey.ID);
            output[k].values.push(zone_to_display);
            k++

            //*** Space 1 */
            if (space) {
                output[k].values.push(space.name);
            } else {
                output[k].values.push("");
            }
            k++

            //*** Space 2 */
            output[k].values.push("");
            k++

            //*** Orientation */
            output[k].values.push("");
            k++

            //*** Frame */
            output[k].values.push(code_to_label(type.frame, FRAME_LIST));
            k++

            //*** Glazing */
            var glazing = code_to_label(type.glazing, ROOF_GLAZING_LIST);
            if (type.glazing == "double") glazing += " / " + code_to_label(type.glazing_gaz, GLAZING_GAZ_LIST);
            output[k].values.push(glazing);
            k++

            //*** Opening */
            output[k].values.push(code_to_label(type.opening, OPENING_LIST));
            k++

            //*** Panels  */
            output[k].values.push(1);
            k++

            //*** Transom  */
            output[k].values.push("");
            k++

            //*** Sill  */
            output[k].values.push("");
            k++

            //*** Closing */
            output[k].values.push(code_to_label(type.closing, CLOSING_LIST));
            k++

            //*** Width */
            output[k].values.push(type.width);
            k++

            //*** Height */
            output[k].values.push(type.length);
            k++

            //*** z */
            output[k].values.push("");
            k++

            //*** transom_height */
            output[k].values.push("");
            k++

            //*** sill height */
            output[k].values.push("");
            k++

            //*** Area */
            output[k].values.push(type.get_area());
            k++

            //*** Glazing Area */
            output[k].values.push(type.get_glazing_area());
            k++

            //*** Uw  */
            output[k].values.push(Number(type.Uw.toFixed(3)));
            k++

            //*** Sw  */
            output[k].values.push(Number(type.Sw.toFixed(3)));
            k++

            //*** Tl  */
            output[k].values.push(Number(type.Tl.toFixed(3)));
            k++
        });
    }
}

import {
    CnmapObjectUtils,
    GenericObject,
    IObjectsHandler,
    ParamObjectPanelBehavior,
} from '@acenv/cnmap-angular-editor-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { ReferenceService } from '../../../../../services/reference.service';
import {
    ParameterDefinition,
    ParameterNumberDefinition,
    ParametersGroup,
    ParameterType,
    ProductType,
} from '@acenv/wikipim-catalog';
import { shareReplay, switchMap } from 'rxjs/operators';
import { TypeParametre } from '../../../../../model/definition-parametre.model';

@Injectable({
    providedIn: 'root',
})
export class ObjectsHandler implements IObjectsHandler {
    private parametersGroups$: Observable<ParametersGroup[]>;
    private productsTypes$: Observable<ProductType[]>;
    private genericObjects$: Observable<GenericObject[]>;
    constructor(private http: HttpClient, private readonly referenceService: ReferenceService) {}
    getDimensionsLabels(): string[] {
        return [
            'Largeur', // axe X
            'Profondeur', // axe Y
            'Hauteur', // axe Z
        ];
    }
    nameCompletionCallback(): Function {
        return null;
    }
    getParamObjectPanelBehavior(): ParamObjectPanelBehavior {
        return ParamObjectPanelBehavior.COLLAPSE_ALL;
    }

    getObjectsParametersGroups(): Observable<ParametersGroup[]> {
        if (this.parametersGroups$ == null) {
            this.parametersGroups$ = this.http
                .get<ParametersGroup[]>(`assets/objects/parameters-groups.json`)
                .pipe(shareReplay(1));
        }
        return this.parametersGroups$;
    }

    getObjectsProductsTypes(): Observable<ProductType[]> {
        if (this.productsTypes$ == null) {
            this.productsTypes$ = combineLatest([this.referenceService.findAllTypesElementsAControler()]).pipe(
                switchMap(([objects]) => {
                    const productTypes = objects
                        .filter((obj) => !!obj.codeBim)
                        .map((obj) => {
                            const productType = new ProductType();
                            productType.id = obj.id;
                            productType.codeBim = obj.codeBim;
                            productType.name = obj.nom;
                            productType.description = obj.description;
                            productType.imageFileId = obj.imageFileId;
                            productType.imageUrl = obj.icone;
                            productType.parentsIds = obj.idsAncetres?.length ? obj.idsAncetres[0] : [];
                            productType.nbProducts = obj.nbProducts;
                            productType.abstract = obj.abstrait;
                            productType.hasChildren = false;
                            productType.flag = obj.flag;
                            productType.parametersDefinitions = [];
                            for (const dp of obj.definitionsParametres) {
                                const definitionsParametres = new ParameterDefinition();
                                definitionsParametres.groupId = dp.groupId;
                                definitionsParametres.order = dp.order;
                                definitionsParametres.name = dp.nom;
                                definitionsParametres.description = dp.description;
                                definitionsParametres.codeBim = dp.codeBim;
                                definitionsParametres.instance = dp.instance;
                                definitionsParametres.catalog = dp.catalog;
                                definitionsParametres.canHaveDefaultValue = dp.canHaveDefaultValue;
                                definitionsParametres.canBeProportional = dp.canBeProportional;
                                definitionsParametres.readonly = dp.readonly;
                                definitionsParametres.type = ParameterType[TypeParametre[dp.type]];
                                definitionsParametres.choices = dp.parametreListeChoix;
                                const numberDefinitions = new ParameterNumberDefinition();
                                numberDefinitions.min = dp.parametreNombre?.min;
                                numberDefinitions.max = dp.parametreNombre?.max;
                                numberDefinitions.nbDecimals = dp.parametreNombre?.nbDecimales;
                                numberDefinitions.unit = dp.parametreNombre?.unite;
                                definitionsParametres.numberDefinitions = numberDefinitions;
                                productType.parametersDefinitions.push(definitionsParametres);
                            }
                            return productType;
                        });
                    console.log(productTypes);
                    productTypes.push(CnmapObjectUtils.productTypeUnknown);
                    return of(productTypes);
                })
            );
        }
        return this.productsTypes$;
    }

    getGenericObjects(): Observable<GenericObject[]> {
        if (this.genericObjects$ == null) {
            this.genericObjects$ = this.referenceService.findAllTypesElementsAControlerGenericObject();
        }
        return this.genericObjects$;
    }
}

import { Injector, OnInit, Directive, Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';
import { PolluantConfig } from '../model/polluant-config.model';
import { Polluant } from '../model/polluant.model';
import { PolluantService } from '../services/polluant.service';
import { MatDialog } from '@angular/material/dialog';
import { Intervention } from 'src/app/model/intervention.model';

//Composant abstrait utilisé pour fournir une base commune à toutes les pages du diagnostic Composant
@Component({
    template: '',
})
export abstract class PolluantComponent extends BaseComponent implements OnInit {
    isInEditMode: Boolean = true;
    isValidated: Boolean = false;

    typeMesurage: string;

    diagnostic: Diagnostic;
    intervention: Intervention;
    contenuDiagnostic: Polluant;
    polluantConfig: PolluantConfig;
    previousFormValue: any;

    isLoaded: Boolean = false;

    //Dependencies
    protected diagnosticService: DiagnosticService;
    public polluantService: PolluantService;
    protected etatProgressionService: EtatProgressionService;
    protected formBuilder: FormBuilder;
    protected matDialog: MatDialog;

    constructor(injector: Injector) {
        super();

        //Inject dependencies
        this.diagnosticService = injector.get(DiagnosticService);
        this.polluantService = injector.get(PolluantService);
        this.etatProgressionService = injector.get(EtatProgressionService);
        this.formBuilder = injector.get(FormBuilder);
        this.matDialog = injector.get(MatDialog);

        this.diagnosticService.getCurrentDiagnostic().subscribe((diagnostic: Diagnostic) => {
            this.diagnostic = diagnostic;

            //Les valeurs n'est par défaut pas modifiable si il s'agit d'une visite de chantier
            this.typeMesurage = this.diagnostic.typePrestation;
            if (this.typeMesurage == 'POLLUANT_VISITE_CHANTIER') {
                this.isInEditMode = false;
            }
        });
    }

    ngOnInit(): void {}
}

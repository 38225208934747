import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationOptions } from './confirmation.service';

@Component({
    selector: 'lib-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    /**
     * Sécurité pour la confirmation
     */
    enableConfirm: boolean;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationOptions
    ) {}

    ngOnInit() {
        // S'il y a une case à cocher, alors empêche la confirmation par défaut
        this.enableConfirm = !this.data.messageCheckboxConfirm;
    }

    onConfirm(): void {
        // Contrôle de sécurité
        if (!this.enableConfirm) {
            return;
        }
        this.dialogRef.close(true);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }
}

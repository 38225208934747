<!--Informations générales-->
<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Informations générales' | uppercase }}
    </div>
    <!--    Block Tableau-->
    <div class="block-content px-5 ml-2 mb-5">
        <table>
            <ng-container *ngFor="let infoGenerale of ceeReportData?.etapesGenerales">
                <tr>
                    <td>{{ infoGenerale?.libelle }}</td>
                    <td>
                        <b>{{ infoGenerale?.valeur ? infoGenerale.valeur : 'Non renseigné' }}</b>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
<ng-container *ngFor="let content of contentPTC">
    <div>
        <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
            {{ content.section | uppercase }}
        </div>
        <!--    Block Tableau-->
        <div class="block-content px-5 ml-2 mb-5">
            <table>
                <ng-container *ngFor="let inf of content.pointsDeControle">
                    <tr>
                        <td>{{ inf.libelle }}</td>
                        <td>
                            <b>{{ inf.reponse }}</b>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </div>
</ng-container>
<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Longueurs de réseaux isolées hors volumes chauffés' | uppercase }}
    </div>
    <!--    Block Tableau-->
    <div class="block-content px-5 ml-2 mb-5">
        <table *ngIf="ceeReportData.typePrestation === 'BARTH160'">
            <tr>
                <td>Longueur totale isolée (hors volume chauffé) en m :</td>
                <td>
                    <b>{{ ceeReportData.longuerReseauxIsoles }}</b>
                </td>
            </tr>
        </table>

        <table class="mt-5" *ngFor="let zone of ceeReportData?.pointsDeControle?.solution[0]?.zones">
            <ng-container *ngFor="let info of zone.pointsDeControle">
                <tr>
                    <td>
                        {{ info?.libelle }}
                    </td>
                    <td>
                        <b [innerHTML]="info?.reponse"></b>
                    </td>
                    <td>
                        <button
                            *ngIf="!readonlyMode && info.commentaires?.length"
                            mat-icon-button
                            class="small-button button-group primary"
                            (click)="editDisplayedComments(info.commentaires, info.sectionRapport)"
                        >
                            <mat-icon class="icon-small">edit</mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>

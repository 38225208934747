"use strict";
//***********************************************************************************
//***********************************************************************************
//**** measure object
//***********************************************************************************
//***********************************************************************************

import {cn_add, cn_dist, cn_mul, cn_normalize, cn_sub} from "../utils/cn_utilities";
import { cn_camera } from "./cn_camera";
import { cn_event_handler } from "./cn_event_handler";
import { cn_event_manager } from "./cn_event_manager";
import { cn_number_input } from "./cn_inputs";
import { cn_mouse_event } from "./cn_mouse_event";
import { MIN_WALL_WIDTH } from './cn_svg_tool_wall_edition';

export class cn_measure extends cn_event_handler {

	/**
	 * Constructor
	 * @param {cn_event_manager} parent
	 * @param {Array<number>} vertex_0 : not copied, reference is kept
	 * @param {Array<number>} vertex_1 : not copied, reference is kept
	 * @param {number | boolean} selectable
	 * @param {number | boolean} offset
	 */
	constructor(parent, vertex_0 = [0,0], vertex_1 = [0,0], selectable = false, offset=true) {
		super(parent);
		this.vertices = [vertex_0,vertex_1];
		this.offset = offset;
		this.selectable = (typeof(selectable) == 'number')?selectable:(selectable)?0:-1;
		this._position = [0,0,0];
		this._mouseover = -1;
		this._valid = false;
	}

	/**
	 * Selectable enum:
	 * - none : the measure is not selectable
	 * - full : the measure is selectable.
	 * - left : the measure is selectable on the left side (toward first point)
	 * - right : the measure is selectable on the right side (toward second point)
	 * - left_and_right : the measure is selectable on both sides
	 */
	static selectable = {none:-1, full: 0, left: 1, right: 2, left_and_right: 3};

	//*****************************************************************
	//*** Draws the measure
	//*****************************************************************
	/**
	 * Draw in svg
	 * @param {cn_camera} camera
	 * @param {boolean} highlight
	 * @returns
	 */
	draw_with_highlight(camera, highlight = false) {
		
		var html = "";
		html += camera.draw_measure(this.vertices[0],this.vertices[1], (this.selectable>=0)?this._position:null,this._mouseover==0,this.offset, (highlight)?["selected"]:[]);
		if (html == "")
		{
			this._valid = false;
			return html;
		}
		this._valid = true;
		if (this.selectable > 0)
		{
			const  p0 = camera.world_to_screen(this.vertices[0]);
			const  p1 = camera.world_to_screen(this.vertices[1]);
			if (p0.length && p1.length)
			{
				const dir = cn_sub(p1,p0);
				cn_normalize(dir);
				if (this.selectable&1)
					html += camera.draw_arrow_head(cn_sub(this._position,cn_mul(dir,30)),cn_mul(dir,-1),(this._mouseover == 1)?"selected":"");
				if (this.selectable&2)
					html += camera.draw_arrow_head(cn_add(this._position,cn_mul(dir,30)),dir,(this._mouseover == 2)?"selected":"");
			}
		}
		return html;
	}

	/**
	 * Clear move effects
	 */
	clear_move() {
		this._mouseover = -1;
	}

	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	move(mouse_event) {
		this._mouseover = -1;
		if (!this._valid) return false;
		if (this.selectable<0) return false;
		if (this.selectable == 0)
		{
			if ((Math.abs(this._position[0] - mouse_event.mouse_screen[0]) <= 30) && (Math.abs(this._position[1] - mouse_event.mouse_screen[1]) <= 10))
				this._mouseover = 0;
		}
		else
		{
			const  p0 = mouse_event.camera.world_to_screen(this.vertices[0]);
			const  p1 = mouse_event.camera.world_to_screen(this.vertices[1]);
			const dir = cn_sub(p1,p0);
			cn_normalize(dir);
			if (mouse_event.camera.mouse_over_arrow_head(mouse_event.mouse_screen,cn_sub(this._position,cn_mul(dir,30)),cn_mul(dir,-1)))
				this._mouseover = 1;
			else if (mouse_event.camera.mouse_over_arrow_head(mouse_event.mouse_screen,cn_add(this._position,cn_mul(dir,30)),dir))
				this._mouseover = 2;
			return this._mouseover >= 0;
		}
		return this._mouseover >= 0;
	}

	/**
	 * Manage a click. Only after a grab that returned true, and no drag. To return 'true' if click had an effect.
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	click(mouse_event) {
		if (this._mouseover<0) return false;

		const obj = this;
		const input = new cn_number_input("Modifier la distance",this._position[2]);
		input.callback = function() {obj.set_measure(input.value,obj._mouseover);};
		input.decimals = 3;
		input.unit = "m";
		input.min_value = MIN_WALL_WIDTH;
		this.call("number_input",input);
		return true;
	}

	/**
	 * Manage a grab. To return 'true' if grab is to be managed.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	grab(mouse_event) {
		return this._mouseover>=0;
	}

	/**
	 * TODO : derivate or redefine this method
	 * @param {number} value
	 * @param {number} control
	 */
	set_measure(value, control) {

	}
}


import { cnx_size, cnx_sub } from "../cn_utilities";

import { cn_building } from "../../model/cn_building";
import { cn_facade_lineics } from "../../model/cn_facade_lineics";
import { cn_nomenclature } from "../cn_nomenclature";

/**
 * Builds facade nomenclatures for the building
 * @param {cn_building} building
 * @returns {cn_nomenclature[]}
 */
export function cn_nomenclature_facade(building) {
    building.update_roofs();
    building.compute_altitudes();
    populateFacingAreas(building);

    const facadeMainFacings = (building.storeys || [])
        .flatMap(storey => storey.scene.spaces || [])
        .filter(space => !space.has_roof)
        .flatMap(space => space.contours || [])
        .flatMap(contour => contour.walls || [])
        .flatMap(w => w.facings || [])
        .filter(f => f?.support === 'facade');
    const facadeTrimmingFacings = (building.facing_trimmings || [])
        .filter(ft => ft.facing && ft.facing.support === 'facade')
        .map(ft => ft.facing);
    const usedFacingIds = Array.from(new Set([...facadeMainFacings, ...facadeTrimmingFacings].map(f => f.ID)));

    const output = [];

    const facadesLineics = usedFacingIds
        .map(id => cn_facade_lineics.build_for_facing(building, building.facing_types.find(f => f.ID === id))).flat();

    const facadeCol = new cn_nomenclature('Façade');
    output.push(facadeCol);
    facadeCol.values = facadesLineics.map(facadeLineics => facadeLineics.label);

    const facingCol = new cn_nomenclature('Revêtement');
    output.push(facingCol);
    facingCol.values = facadesLineics.map(facadeLineics => facadeLineics.facing.name);

    const areaCol = new cn_nomenclature('Surface nette (hors ouvrant)', 'm²');
    output.push(areaCol);
    areaCol.values = facadesLineics.map(facadeLineics => facadeLineics.area);

    const fullAreaCol = new cn_nomenclature('Surface brute', 'm²');
    output.push(fullAreaCol);
    fullAreaCol.values = facadesLineics.map(facadeLineics => facadeLineics.full_area);

    // calcul des cornières d'angles
    const joiningAngleCol = new cn_nomenclature('Baguette d\'angle', 'm');
    joiningAngleCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.corner));
    output.push(joiningAngleCol);

    // calcul des longueurs d'arrêt latéral
    const borderAngleCol = new cn_nomenclature('Profilé d\'arrêt latéral', 'm');
    borderAngleCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.vertical));
    output.push(borderAngleCol);

    // calcul des longueurs d'arrêt haut
    const highLineCol = new cn_nomenclature('Profilé d\'arrêt haut', 'm');
    highLineCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.upper));
    output.push(highLineCol);

    // calcul ligne basse
    const lowerLineCol = new cn_nomenclature('Rail de départ (hors ouvrant)', 'm');
    lowerLineCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.bearing));
    output.push(lowerLineCol);

    // calcul embrasures (tour d'ouvrant sans le bas)
    const embrasureCol = new cn_nomenclature('Embrasures', 'm');
    embrasureCol.values = facadesLineics.map(facadeLineics =>
        (sumAllVectorLengths(facadeLineics.upper_opening) + sumAllVectorLengths(facadeLineics.vertical_opening)),
    );
    output.push(embrasureCol);

    // calcul embrasures (tour d'ouvrant sans le bas)
    const doorLedgeCol = new cn_nomenclature('Appuis de porte', 'm');
    doorLedgeCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.lower_door));
    output.push(doorLedgeCol);

    // calcul embrasures (tour d'ouvrant sans le bas)
    const windowLedgeCol = new cn_nomenclature('Appuis de fenêtre', 'm');
    windowLedgeCol.values = facadesLineics.map(facadeLineics => sumAllVectorLengths(facadeLineics.lower_opening));
    output.push(windowLedgeCol);

    return output;
}

/**
 * populate facing_areas for each wall
 * @param {cn_building} building
 */
function populateFacingAreas(building) {
    // we compute the facing areas for all walls of all storey
    building.storeys.forEach(storey => {
        storey.scene.storey = storey;
        storey.scene.update_deep();
        // We compute the storey volume
        if (!storey.exterior) storey.build_roof_volume(true);

        return storey.scene.walls.forEach(wall => {
            for (let side = 0; side < 2; side++) {
                wall.facing_areas[side] = 0;
                const facingPolygons = wall.build_facing_polygons(side, storey);
                facingPolygons
                    .filter(p => !p['facing_trimming'])
                    .forEach(p => wall.facing_areas[side] += p.get_area());
            }
        });
    });
}

/**
 * Sum all vectors
 * @param points{number[][]}: pairs of triplets (each pair represents 2 point forming a vector)
 */
function sumAllVectorLengths(points) {
    let length = 0;
    for (let i = 0; i < points.length; i += 2) {
        length += cnx_size(cnx_sub(points[i], points[i + 1]));
    }
    return length;
}

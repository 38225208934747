import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';

/**
 * Module de personnalisation du datepicker Material pour un fonctionnement correct en français
 */
@NgModule({
    declarations: [],
    imports: [CommonModule, MatDatepickerModule, MatMomentDateModule],
    exports: [MatDatepickerModule, MatMomentDateModule],
    providers: [
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'fr-FR',
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {
                useUtc: true,
            },
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['DD/MM/YYYY'],
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'DD/MM/YYYY',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
})
export class WizydiagDatepickerCustomizationModule {}

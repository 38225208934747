import { Component, EventEmitter, Input, Output } from '@angular/core';
import { cn_storey } from '@acenv/cnmap-editor';
import { BaseComponent, ConfirmationService } from 'src/app/commons-lib';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { HapCheckValidityService } from '../../../../diagnostics/hap/services/hap-check-validity.service';
import { HapCnMapService } from '../../../../diagnostics/hap/services/hap-cn-map.service';
import { HapFormService } from '../../../../diagnostics/hap/services/hap-form.service';
import { HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE } from '../../../../diagnostics/hap/constantes/hap.constantes';
import { Espace } from '../../../../../model/espace.model';
import { Perimetre } from '../../../../../model/perimetre.model';
import { LevelToDisplay } from '../../../../diagnostics/hap/model/hap.model';
import { Zone } from '../../../../../model/zone.model';
import { FormUtils } from '../../../../../utils/form.utils';
import { Prelevement } from '../../../../../model/prelevement-generique.model';
import { CndiagMarker } from '../../model/cndiag-marker.model';

/**
 * Permet de récupérer le marker (emplacement dessiné sur la map) correspondant au formGroup s'il en possède un dans la liste des markers de l'espace
 * @param storey
 * @param levelOfMarker
 * @param formGroup
 * @returns
 */
export function getMarkerFromFormGroup(
    storey: cn_storey,
    levelOfMarker: LevelToDisplay,
    formGroup: FormGroup
): CndiagMarker {
    if (storey.markers) {
        return storey.markers
            .filter((markerTemp) => markerTemp.level === levelOfMarker)
            .find((marker) => marker.idReference === formGroup.get('id').value);
    } else {
        return undefined;
    }
}
@Component({
    selector: 'app-liste-perimetre',
    templateUrl: './liste-perimetre.component.html',
    styleUrls: ['./liste-perimetre.component.scss'],
})
export class ListePerimetreComponent extends BaseComponent {
    @Input() diagnostic: Diagnostic;
    @Input() espace: Espace;
    @Input() set prelevementFormGroup(prelevementFormGroup: FormGroup) {
        this.prelevementFormGroupSelected = prelevementFormGroup;
    }
    @Input() set delimitationFormGroupSelected(delimitationFormGroupSelected: FormGroup) {
        this._delimitationFormGroupSelected = delimitationFormGroupSelected;
    }
    get delimitationFormGroupSelected(): FormGroup {
        return this._delimitationFormGroupSelected;
    }
    @Input() listePerimetreArrayForm: FormArray;
    @Input() readonlyMode: boolean;
    @Input() maxLevelToDisplay: LevelToDisplay;
    @Input() set zoneFormGroupSelected(zoneFormGroupSelected: FormGroup) {
        this._zoneFormGroupSelected = zoneFormGroupSelected;
    }
    get zoneFormGroupSelected(): FormGroup {
        return this._zoneFormGroupSelected;
    }

    @Input() set besoinFormGroupSelected(besoinFormGroupSelected: FormGroup) {
        this._besoinFormGroupSelected = besoinFormGroupSelected;
        this.prelevementFormGroupSelected = undefined;
    }
    get besoinFormGroupSelected(): FormGroup {
        return this._besoinFormGroupSelected;
    }
    // Permet de réinitialiser le bouton de modification de la localisation
    // quand l'utilisateur a fini de dessiner son marker
    @Input() set referenceToLocate(referenceToLocate: Perimetre) {
        if (!referenceToLocate) {
            this.editLocation = undefined;
        }
    }
    @Input() currentStorey: cn_storey;

    // On fait un emit dans le cas d'un edit ou d'une création. Pour l'édit, on passe la zone à éditer en paramètre
    @Output() zoneEvent = new EventEmitter<any>();

    // On fait un emit dans le cas d'un edit ou d'une création. Pour l'édit, on passe le besoin à éditer en paramètre
    @Output() besoinEvent = new EventEmitter<any>();

    // On fait un emit dans le cas d'un edit ou d'une création. Pour l'édit, on passe le prelevement à éditer en paramètre
    @Output() prelevementEvent = new EventEmitter<any>();

    // On fait un emit dans le cas d'un edit ou d'une création. Pour l'édit, on passe le périmetre à éditer en paramètre
    @Output() perimetreEvent = new EventEmitter<any>();

    // Evenement permettant de savoir quand on clique sur l'icone de dessin de l'emplacement
    // On passe le formGroup que l'on veut dessiner et un boolean pour recharger le cn_building si nécessaire
    @Output() editLocationEvent = new EventEmitter<any>();

    // Permet de savoir quel besoin est sélectionné et doit être en surbrillance
    private _besoinFormGroupSelected: FormGroup;
    // Permet de savoir quelle zone est sélectionnée et doit être en surbrillance
    private _zoneFormGroupSelected: FormGroup;
    // Permet de savoir quel périmètre est sélectionné et doit être en surbrillance
    private _delimitationFormGroupSelected: FormGroup;

    enumLevelToDisplay = LevelToDisplay;

    // Permet de savoir quel prelevement est sélectionné et doit être en surbrillance
    prelevementFormGroupSelected: FormGroup;

    // Contient le formGroup dont le dessin est en cours de création/édition
    editLocation: FormGroup;

    constructor(
        private readonly hapFormService: HapFormService,
        private readonly hapCnMapService: HapCnMapService,
        private readonly hapCheckValidityService: HapCheckValidityService,
        private readonly confirmationService: ConfirmationService
    ) {
        super();
    }

    /**
     * Modifier la délimitation passée en paramètre
     * @param delimitationFormGroupToEdit
     */
    onClickEditDelimitation(delimitationFormGroupToEdit: FormGroup) {
        const perimetreToEdit = this.hapFormService.findPerimetreByFormGroup(
            this.espace.listePerimetres,
            delimitationFormGroupToEdit
        );
        if (perimetreToEdit) {
            this.delimitationFormGroupSelected = delimitationFormGroupToEdit;
            this.perimetreEvent.emit({ delimitationFormGroupToEdit: delimitationFormGroupToEdit });
        }
    }

    /**
     * On supprime la délimitation sélectionnée, nécessite une confirmation via une modale
     * @param indexPerimetreToDelete
     */
    onClickDeleteDelimitation(indexPerimetreToDelete: number) {
        // on demande une confirmation
        this.confirmationService.confirm(
            HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE['perimetre'](this.espace.listePerimetres[indexPerimetreToDelete]),
            () => {
                // Lors de la suppression d'un périmètre, on supprime dans l'espace les markers correspondant + les markers de zone + les markers de prélèvement
                this.hapCnMapService.deleteMarkersPerimetre(
                    this.espace,
                    this.currentStorey,
                    this.espace.listePerimetres[indexPerimetreToDelete],
                    this.editLocationEvent
                );

                this.zoneFormGroupSelected = undefined;
                this.espace.listePerimetres.splice(indexPerimetreToDelete, 1);
                this.listePerimetreArrayForm.removeAt(indexPerimetreToDelete);

                // Re-Validation des onglets ZONE et PRELEVEMENT
                this.hapCheckValidityService.checkValidityZoneFromOutside(
                    this.espace.listePerimetres,
                    this.espace.id,
                    this.ngUnsubscribe,
                    this.diagnostic
                );

                this.perimetreEvent.emit(null);
            }
        );
    }

    /**
     * Créer une delimitation/périmètre
     */
    onClickCreateDelimitation() {
        const delimitationToCreate = new Perimetre();

        // Creation du  formGroup de la nouvelle délimitation
        const delimitationFormGroup = this.hapFormService.createFormGroupPerimetre(
            delimitationToCreate,
            this.espace.listePerimetres,
            LevelToDisplay.PERIMETRE,
            this.ngUnsubscribe
        );

        this.espace.listePerimetres.push(delimitationToCreate);
        this.listePerimetreArrayForm.push(delimitationFormGroup);
        this.delimitationFormGroupSelected = delimitationFormGroup;
        this.perimetreEvent.emit({ delimitationFormGroupToEdit: delimitationFormGroup });
    }

    /**
     * Action permettant de définir l'objet à dessiner
     * @param evt: any
     * @param formGroup: FormGroup
     * @param levelToDisplay: LevelToDisplay
     */
    onClickEditLocation(evt, formGroup: FormGroup, levelToDisplay: LevelToDisplay) {
        evt.stopPropagation();

        // Lancer l'évènement de fermeture du panel d'édition
        switch (levelToDisplay) {
            case LevelToDisplay.PERIMETRE:
                this.perimetreEvent.emit(null);
                break;
            case LevelToDisplay.ZONE:
                this.zoneEvent.emit({
                    perimetre: null,
                    zoneToEdit: null,
                    delimitationFormGroup: null,
                    zoneFormGroup: null,
                });
                break;
            case LevelToDisplay.PRELEVEMENT:
                this.prelevementEvent.emit({
                    prelevementFormGroup: null,
                });
                break;
            case LevelToDisplay.BESOIN:
                this.besoinEvent.emit({
                    besoinFormGroup: null,
                });
                break;
            default:
                break;
        }

        // Si on est déjà en train de dessiner cet objet, on annule l'édition
        // Sinon, on active son édition
        if (!formGroup || this.editLocation === formGroup) {
            this.editLocation = null;
            this.editLocationEvent.emit({
                formGroupToLocate: this.editLocation,
            });
        } else {
            // On vérifie si cet obet a déjà été dessiné
            const markerExistant = getMarkerFromFormGroup(this.currentStorey, levelToDisplay, formGroup);
            if (markerExistant) {
                this.confirmationService.confirmWarn(
                    `L'emplacement actuel sera supprimé, êtes-vous sûr de vouloir le redéfinir ?`,
                    () => {
                        // Supression du marker dans l'espace
                        this.hapCnMapService.deleteOneMarker(
                            this.espace,
                            this.currentStorey,
                            markerExistant.idReference
                        );

                        // Définition de l'élément à dessiner
                        this.editLocation = formGroup;

                        this.editLocationEvent.emit({
                            formGroupToLocate: this.editLocation,
                        });
                    }
                );
            } else {
                this.editLocation = formGroup;
                this.editLocationEvent.emit({
                    formGroupToLocate: this.editLocation,
                });
            }
        }
    }

    /**
     * Action déclenchée lors du click sur le bouton permettant de créer un besoin
     * @param delimitationFormGroup
     */
    onClickCreateBesoin(delimitationFormGroup: FormGroup) {
        const besoinNew = new Prelevement();

        // Creation du  formGroup du nouveau besoin
        const besoinFormGroup = this.hapFormService.createFormGroupPrelevement(besoinNew, this.ngUnsubscribe, false);
        besoinFormGroup
            .get('formGeneral')
            .get('reference')
            .setValue(FormUtils.formatPrelevementReference(this.diagnostic));

        (delimitationFormGroup.get('formArrayBesoins') as FormArray).push(besoinFormGroup);

        // Ajout du nouveau besoin dans le périmètre associé
        const perimetre = this.hapFormService.findPerimetreByFormGroup(
            this.espace.listePerimetres,
            delimitationFormGroup
        );
        perimetre.listeBesoins.push(besoinNew);

        this.besoinFormGroupSelected = besoinFormGroup;
        this.besoinEvent.emit({
            besoinFormGroup: besoinFormGroup,
            delimitationFormGroup: delimitationFormGroup,
        });
    }

    onClickBesoinLine(delimitationFormGroup: FormGroup, besoinFormGroup: FormGroup, levelToDisplay: LevelToDisplay) {
        switch (levelToDisplay) {
            case LevelToDisplay.BESOIN:
                this.onClickEditBesoin(delimitationFormGroup, besoinFormGroup);
                break;
            case LevelToDisplay.PRELEVEMENT:
                this.onClickEditPrelevement(delimitationFormGroup, besoinFormGroup);
                break;
            default:
                break;
        }
    }

    /**
     * Action déclenchée lors d'une click sur un besoin afin d'éditer ce dernier
     * @param delimitationFormGroup
     * @param besoinFormGroup
     */
    onClickEditBesoin(delimitationFormGroup: AbstractControl, besoinFormGroup: FormGroup) {
        this.besoinFormGroupSelected = besoinFormGroup;

        this.besoinEvent.emit({
            besoinFormGroup: besoinFormGroup,
            delimitationFormGroup: delimitationFormGroup,
        });
    }

    /**
     * Action déclenchée lors du click sur le bouton permettant de supprimer un besoin
     * nécessite une confirmation via une modale
     * @param delimitationFormGroup: FormGroup
     * @param indexBesoinToDelete: number
     */
    onClickDeleteBesoin(delimitationFormGroup: FormGroup, indexBesoinToDelete: number) {
        // récupération du périmètre du besoin à partir du formGroup
        const perimetreToEdit = this.hapFormService.findPerimetreByFormGroup(
            this.espace.listePerimetres,
            delimitationFormGroup
        );
        // on demande une confirmation
        this.confirmationService.confirm(
            HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE['besoin'](perimetreToEdit.listeBesoins[indexBesoinToDelete]),
            () => {
                // Lors de la suppression d'un Besoin, on supprime dans l'espace le marker correspondant
                this.hapCnMapService.deleteMarkersBesoin(
                    this.espace,
                    this.currentStorey,
                    perimetreToEdit.listeBesoins[indexBesoinToDelete],
                    this.editLocationEvent
                );

                this.besoinFormGroupSelected = undefined;
                // Suppression du formGroup dans le formArray
                (delimitationFormGroup.get('formArrayBesoins') as FormArray).removeAt(indexBesoinToDelete);

                // Suppression du besoin correspondante dans la liste des perimetres
                perimetreToEdit.listeBesoins.splice(indexBesoinToDelete, 1);

                this.besoinEvent.emit({
                    besoinFormGroup: null,
                    delimitationFormGroup: delimitationFormGroup,
                });
            }
        );
    }

    /**
     * Action déclenchée lors du click sur le bouton permettant de créer une zone
     * @param delimitationFormGroup
     */
    onClickCreateZone(delimitationFormGroup: FormGroup) {
        const zoneNew = new Zone();

        // Creation du  formGroup de la nouvelle zone
        const zoneFormGroup = this.hapFormService.createFormGroupZone(zoneNew, LevelToDisplay.ZONE, this.ngUnsubscribe);
        (delimitationFormGroup.get('formArrayZones') as FormArray).push(zoneFormGroup);
        this.zoneFormGroupSelected = zoneFormGroup;

        // Ajout de la nouvelle zone dans le périmètre associé
        const perimetre = this.hapFormService.findPerimetreByFormGroup(
            this.espace.listePerimetres,
            delimitationFormGroup
        );
        perimetre.listeZones.push(zoneNew);

        this.zoneEvent.emit({
            perimetre: perimetre,
            zoneToEdit: zoneNew,
            zoneFormGroup: zoneFormGroup,
            delimitationFormGroup: delimitationFormGroup,
        });
    }

    /**
     * Action déclenchée lors d'une click sur une zone afin d'éditer cette dernière
     * @param delimitationFormGroup
     * @param zoneFormGroup
     */
    onClickEditZone(delimitationFormGroup: AbstractControl, zoneFormGroup: FormGroup) {
        const selectedPerimetre = this.hapFormService.findPerimetreByFormGroup(
            this.espace.listePerimetres,
            delimitationFormGroup as FormGroup
        );

        // Zone que l'on souhaite éditer
        const selectedZone = this.hapFormService.findZoneByFormGroup(this.espace.listePerimetres, zoneFormGroup);
        // Zone formGroupe que l'on souhaite éditer
        this.zoneFormGroupSelected = zoneFormGroup;

        this.zoneEvent.emit({
            perimetre: selectedPerimetre,
            zoneToEdit: selectedZone,
            delimitationFormGroup: delimitationFormGroup,
            zoneFormGroup: zoneFormGroup,
        });
    }

    /**
     * Action déclenchée lors du click sur le bouton permettant de supprimer une zone
     * nécessite une confirmation via une modale
     * @param delimitationFormGroup
     * @param indexZoneToDelete
     */
    onClickDeleteZone(delimitationFormGroup: FormGroup, indexZoneToDelete: number) {
        // récupération du périmètre de la zone à partir du formGroup
        const perimetreToEdit = this.hapFormService.findPerimetreByFormGroup(
            this.espace.listePerimetres,
            delimitationFormGroup
        );
        // on demande une confirmation
        this.confirmationService.confirm(
            HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE['zone'](perimetreToEdit.listeZones[indexZoneToDelete]),
            () => {
                // Lors de la suppression d'une zone, on supprime dans l'espace les markers correspondant + les markers de prélèvement
                this.hapCnMapService.deleteMarkersZone(
                    this.espace,
                    this.currentStorey,
                    perimetreToEdit.listeZones[indexZoneToDelete],
                    this.editLocationEvent
                );

                this.zoneFormGroupSelected = undefined;
                // Suppression du formGroup dans le formArray
                (delimitationFormGroup.get('formArrayZones') as FormArray).removeAt(indexZoneToDelete);

                // Suppression de la zone correspondante dans la liste des perimetres
                perimetreToEdit.listeZones.splice(indexZoneToDelete, 1);

                this.zoneEvent.emit({
                    perimetre: null,
                    zoneToEdit: null,
                    delimitationFormGroup: delimitationFormGroup,
                    zoneFormGroup: null,
                });

                // Validation des onglet ZONE et PRELEVEMENT
                this.hapCheckValidityService.checkValidityZoneFromOutside(
                    this.espace.listePerimetres,
                    this.espace.id,
                    this.ngUnsubscribe,
                    this.diagnostic
                );
            }
        );
    }

    /**
     * Evenement de création d'un nouveau prélèvement
     * @param delimitationFormGroup
     * @param indexZoneOfPrelevement
     */
    onClickCreatePrelevement(delimitationFormGroup: FormGroup, indexZoneOfPrelevement: number) {
        const perimetre = this.hapFormService.findPerimetreByFormGroup(
            this.espace.listePerimetres,
            delimitationFormGroup
        );
        const prelevementNew = new Prelevement();
        prelevementNew.general.zoneId = perimetre.listeZones[indexZoneOfPrelevement].id;
        const prelevementFormGroupNew = this.hapFormService.createFormGroupPrelevement(
            prelevementNew,
            this.ngUnsubscribe
        );
        prelevementFormGroupNew
            .get('formGeneral')
            .get('reference')
            .setValue(FormUtils.formatPrelevementReference(this.diagnostic));
        this.pushPrelevement(
            delimitationFormGroup,
            prelevementFormGroupNew,
            perimetre,
            prelevementNew,
            indexZoneOfPrelevement
        );
    }

    /**
     * Duplique un prélèvement, on ne duplique pas les markers du prélèvement initial
     * @param delimitationFormGroup
     * @param prelevementFormGroup
     * @param indexZoneOfPrelevement
     */
    onClickDuplicatePrelevement(
        delimitationFormGroup: FormGroup,
        prelevementFormGroup: FormGroup,
        indexZoneOfPrelevement: number
    ) {
        const perimetre = this.hapFormService.findPerimetreByFormGroup(
            this.espace.listePerimetres,
            delimitationFormGroup
        );
        const prelevement = this.hapFormService.findPrelevementOrBesoinByFormGroup(
            this.espace.listePerimetres,
            prelevementFormGroup
        );
        const prelevementNew = new Prelevement();
        prelevementNew.general = { ...prelevement.general };
        // On réinitialise la reference car elle est unique dans le diagnostic
        prelevementNew.details = { ...prelevement.details };
        prelevementNew.general.reference = FormUtils.formatPrelevementReference(
            this.diagnostic,
            prelevementNew.details?.typePrelevement
        );
        // On réinitialise les échantillons car leur référence sont basées sur la référence du prélèvement que l'on réinitialise également
        prelevementNew.echantillonages = [];
        prelevementNew.donneesTechniques = { ...prelevement.donneesTechniques };
        const prelevementFormGroupNew = this.hapFormService.createFormGroupPrelevement(
            prelevementNew,
            this.ngUnsubscribe
        );
        prelevementFormGroupNew.markAllAsTouched();
        this.pushPrelevement(
            delimitationFormGroup,
            prelevementFormGroupNew,
            perimetre,
            prelevementNew,
            indexZoneOfPrelevement
        );
    }

    /**
     * Rajoute un prélèvement dans le FormArray des prélèvements du formGroup de la zone et dans la liste des prélèvements de la zone
     * @param delimitationFormGroup
     * @param prelevementFormGroupNew
     * @param perimetre
     * @param prelevementNew
     * @param indexZoneOfPrelevement
     */
    private pushPrelevement(
        delimitationFormGroup: FormGroup,
        prelevementFormGroupNew: FormGroup,
        perimetre: Perimetre,
        prelevementNew: Prelevement,
        indexZoneOfPrelevement: number
    ) {
        // On récupère le formArray des prélèvements de la zone et on y rajoute le nouveau
        (
            (delimitationFormGroup.get('formArrayZones') as FormArray).controls[indexZoneOfPrelevement].get(
                'formArrayPrelevement'
            ) as FormArray
        ).push(prelevementFormGroupNew);
        perimetre.listeZones[indexZoneOfPrelevement].listePrelevements.push(prelevementNew);

        this.prelevementEvent.emit({
            delimitationFormGroup: delimitationFormGroup,
            prelevementFormGroup: prelevementFormGroupNew,
            indexZoneOfPrelevement: indexZoneOfPrelevement,
        });
        this.prelevementFormGroupSelected = prelevementFormGroupNew;
        this._besoinFormGroupSelected = undefined;

        // Revalider l'onglet PRELEVEMENT
        this.hapCheckValidityService.checkValidityPrelevementFromOutside(
            this.espace.listePerimetres,
            this.espace.id,
            this.ngUnsubscribe,
            this.diagnostic
        );
    }

    /**
     * Evènement déclenché lorsqu'on clique sur une ligne des prélèvements dans la liste de gauche
     * @param delimitationFormGroup
     * @param prelevementFormGroup
     * @param indexZoneOfPrelevement
     */
    onClickEditPrelevement(
        delimitationFormGroup: FormGroup,
        prelevementFormGroup: FormGroup,
        indexZoneOfPrelevement?: number
    ) {
        this.prelevementFormGroupSelected = prelevementFormGroup;
        this.prelevementEvent.emit({
            delimitationFormGroup: delimitationFormGroup,
            prelevementFormGroup: prelevementFormGroup,
            indexZoneOfPrelevement: indexZoneOfPrelevement,
        });
    }

    /**
     * On supprime le prélèvement sélectionné, nécessite une confirmation via une modale
     * @param zoneFormGroup: FormGroup
     * @param indexPrelevementToDelete: number
     */
    onClickDeletePrelevement(zoneFormGroup: FormGroup, indexPrelevementToDelete: number) {
        // on récupère la zone à modifier
        const zone = this.hapFormService.findZoneByFormGroup(this.espace.listePerimetres, zoneFormGroup);

        // on demande une confirmation
        this.confirmationService.confirm(
            HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE['prelevement'](zone.listePrelevements[indexPrelevementToDelete]),
            () => {
                // Lors de la suppression d'un prélèvement, on supprime dans l'espace le marker correspondant
                this.hapCnMapService.deleteMarkersPrelevement(
                    this.espace,
                    this.currentStorey,
                    zone.listePrelevements[indexPrelevementToDelete],
                    this.editLocationEvent
                );

                // Suppression du prélèvement correspondant dans la liste des périmètres
                const listePrelevements = zone.listePrelevements;
                const selectedID = this.prelevementFormGroupSelected
                    ? this.prelevementFormGroupSelected.get('id').value
                    : null;
                // id du prélèvement que l'on veut supprimer
                const idToTry = listePrelevements[indexPrelevementToDelete].id;

                // Suppression du formGroup dans le formArray
                (zoneFormGroup.get('formArrayPrelevement') as FormArray).removeAt(indexPrelevementToDelete);

                // si le prélèvement était selectionné, on emit pour fermer le panneau d'édition du prélèvement
                if (idToTry === selectedID) {
                    this.prelevementEvent.emit({
                        prelevementFormGroup: null,
                    });
                    this.prelevementFormGroupSelected = undefined;
                }
                listePrelevements.splice(indexPrelevementToDelete, 1);

                // Revalider l'onglet PRELEVEMENT
                this.hapCheckValidityService.checkValidityPrelevementFromOutside(
                    this.espace.listePerimetres,
                    this.espace.id,
                    this.ngUnsubscribe,
                    this.diagnostic
                );
            }
        );
    }
}

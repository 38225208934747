<mat-sidenav-container *ngIf="correctOrientation" [autosize]="true" style="height: 100%">
    <mat-sidenav
        [mode]="'side'"
        [disableClose]="true"
        [opened]="menuVisible"
        fixedInViewport="true"
        class="mat-elevation-z4"
    >
        <div class="h-100 d-flex flex-column">
            <div class="sidenav-toolbar">
                <mat-toolbar class="d-flex justify-content-center">
                    <a
                        [routerLink]="accueilUrl"
                        matTooltip="Accueil"
                        matTooltipPosition="right"
                        style="outline: none !important"
                        title="Accueil"
                    >
                        <img
                            alt="logo"
                            src="../assets/favicon/android-chrome-192x192.png"
                            style="width: 34px; border-radius: 70%"
                        />
                        <span *ngIf="showLabel" class="page-title ml-2"></span>
                    </a>
                </mat-toolbar>
            </div>

            <mat-nav-list class="menu-icons-container pt-3" dense>
                <ng-container *ngFor="let p of appPages">
                    <a
                        #rla1="routerLinkActive"
                        [class.list-item-active]="rla1.isActive"
                        (click)="resetSessionStorageAndRedirect(p.url)"
                        class="primary"
                        mat-list-item
                        matTooltip="{{ p.title }}"
                        matTooltipPosition="right"
                        routerLinkActive
                        [attr.title]="p.title"
                    >
                        <lib-icon matListIcon>{{ p.icon }}</lib-icon>
                        <span *ngIf="showLabel" class="page-title ml-2">{{ p.title }}</span>
                    </a>
                </ng-container>
                <a
                    (click)="logout()"
                    class="primary"
                    mat-list-item
                    matTooltip="Se déconnecter"
                    matTooltipPosition="right"
                    routerLinkActive
                    title="Se déconnecter"
                >
                    <lib-icon matListIcon>exit_to_app</lib-icon>
                    <span *ngIf="showLabel" class="page-title ml-2">Se déconnecter</span>
                </a>
            </mat-nav-list>
            <!-- <div class="flex-grow-1"></div> -->
            <mat-nav-list class="menu-icons-container pt-3" dense>
                <a
                    routerLink="/application-infos/changelog"
                    class="primary"
                    mat-list-item
                    matTooltip="Infos sur l'application"
                    matTooltipPosition="right"
                >
                    <lib-icon matListIcon>info</lib-icon>
                    <span *ngIf="showLabel" class="page-title ml-2">Version</span>
                </a>
            </mat-nav-list>
            <!-- Infos pour le mode développement -->
            <div *ngIf="develMode" class="d-flex flex-column align-items-center">
                <div class="mt-3 font-italic font-weight-bold" style="color: #f5c153" matTooltip="Mode développement">
                    <div>D</div>
                    <div>E</div>
                    <div>V</div>
                </div>
                <div>
                    <!--Switch online/offline mode-->
                    <mat-slide-toggle [checked]="isOnline" (change)="onClickDevBtnOnline($event.checked)"
                        >Online mode</mat-slide-toggle
                    >
                </div>
                <!-- Synchro manuelle + infos de synchro -->
                <div>
                    <a
                        class="mt-1"
                        href="javascript:void(0)"
                        matTooltip="Lancer la synchronisation (synchro auto désactivée en mode développement)"
                        matTooltipPosition="right"
                        (click)="forceSync()"
                        title="Synchronisation"
                        data-cy="synchro-button"
                    >
                        <mat-icon style="color: #f5c153" matBadgeColor="accent" matBadgePosition="below after">
                            sync_all
                        </mat-icon>
                    </a>
                </div>
            </div>

            <div class="p-2">
                <button mat-icon-button (click)="showLabel = !showLabel">
                    <lib-icon matListIcon *ngIf="showLabel">first_page</lib-icon>
                    <lib-icon matListIcon *ngIf="!showLabel">last_page</lib-icon>
                </button>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content style="height: 100%">
        <ng-container *ngIf="syncProgress$ | async as sp">
            <mat-progress-bar id="syncBar" [hidden]="!sp.running" [value]="sp.progress"></mat-progress-bar>
        </ng-container>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<div *ngIf="!correctOrientation" class="change-orientation-message container-fluid theme-alternate">
    <div class="row justify-content-center p-md-5">
        <div class="col-12 mt-5">
            <img src="assets/logo_full.svg" alt="logo" class="mx-auto d-block" />
        </div>
        <h1 class="col-12 text-center">WizyDiag</h1>
    </div>
    <div class="row mt-5 justify-content-center text-center">
        <div class="col-sm-9 col-md-7 col-lg-5">
            <h1>Merci de tourner votre tablette en mode paysage pour pouvoir utiliser l'application.</h1>
        </div>
    </div>
</div>

<app-cn-spinner></app-cn-spinner>

import { Component, OnInit, Input } from '@angular/core';
import { Intervention } from 'src/app/model/intervention.model';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { Polluant, PolluantReportData } from '../../../model/polluant.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { getInfoObjectifsSelectionnes } from '../../../modules/etude-situation/initialisation/objectifs/objectifs.component';
import { PolluantService } from '../../../services/polluant.service';
import { PolluantConfig } from '../../../model/polluant-config.model';

@Component({
    selector: 'app-poll-introduction-block',
    templateUrl: './poll-introduction-block.component.html',
    styleUrls: ['./poll-introduction-block.component.scss'],
})
export class PollIntroductionBlockComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    currentContenuDiagnostic: Polluant;
    polluantConfig: PolluantConfig;
    diagnostic: Diagnostic;
    text = [];
    descriptionObjectifs: string[];
    normesObjectifs: string[];
    strategiePrelevement = [];
    vars: any = {};
    reportData: PolluantReportData;

    constructor(private diagnosticService: DiagnosticService, private pollService: PolluantService) {}

    ngOnInit(): void {
        if (this.data) {
            if (this.data.values['text']) {
                this.text = this.data.values['text'];
            }

            this.reportData = this.interReportData.diagnostics[0] as PolluantReportData;
        }
    }

    etatEtude(typePresta: string): boolean {
        return typePresta == 'POLLUANT_ETUDE_SITUATION';
    }
}

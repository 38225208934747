<div *ngIf="formContact" class="h-100 row no-gutters p-4 scrollable-content" [formGroup]="formContact">
    <div class="w-100" [formGroup]="formContact">
        <!-- Statut Entreprise ou Personne -->
        <div class="d-flex align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Statut du contact</div>
            <div class="col-xl-8 pr-2">
                <!-- Personne morale -->
                <mat-button-toggle-group
                    appearance="legacy"
                    *ngIf="!isReadOnly"
                    formControlName="estPersonneMorale"
                    class="mb-3"
                >
                    <mat-button-toggle class="button-toggle-double ACTIF left-toggle" [value]="true">
                        Entreprise</mat-button-toggle
                    >
                    <mat-button-toggle class="button-toggle-double INACTIF right-toggle" [value]="false">
                        Personne</mat-button-toggle
                    >
                </mat-button-toggle-group>

                <div
                    *ngIf="isReadOnly"
                    class="statut-workflow consultation mb-3"
                    [ngClass]="formContact?.value.estPersonneMorale ? 'ACTIF' : 'INACTIF'"
                >
                    {{ formContact?.value.estPersonneMorale ? 'Entreprise' : 'Personne' }}
                </div>
            </div>
        </div>

        <!-- Type de contact -->
        <div class="d-flex align-items-center" *ngIf="!isContactRef">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Type de contact</div>
            <div class="col-xl-8 pr-2">
                <mat-form-field class="w-100">
                    <input matInput type="text" formControlName="type" required />
                    <mat-error *ngIf="formContact.get('type').hasError('erreurRoleAutre')"
                        >Le contact ne peut pas avoir ce rôle
                    </mat-error>
                    <app-mat-error [control]="formContact.get('type')"> </app-mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- Nom -->
        <div class="d-flex align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Nom</div>
            <div class="col-xl-8 pr-2">
                <mat-form-field class="w-100">
                    <input
                        matInput
                        formControlName="nom"
                        type="text"
                        [matAutocomplete]="autoName"
                        [readonly]="isReadOnly"
                    />

                    <app-mat-error [control]="formContact.get('nom')"> </app-mat-error>
                    <mat-autocomplete
                        #autoName="matAutocomplete"
                        (optionSelected)="onClickChangeContactFromSelect($event, 'nom')"
                    >
                        <mat-option *ngIf="isSearchingNames | async" class="is-loading">
                            <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                        <mat-optgroup
                            *ngIf="!(isSearchingNames | async) && filteredSearchName?.length"
                            label="Contacts similaires"
                        >
                            <mat-option
                                *ngFor="let option of filteredSearchName"
                                (onSelectionChange)="onSelectionChanged($event, 'nom')"
                                [value]="option"
                            >
                                {{ option.nom }} - {{ option.telephone }} - {{ option.email }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <!-- Prenom -->
        <div class="d-flex align-items-center" *ngIf="!formContact.get('estPersonneMorale').value">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Prénom</div>
            <div class="col-xl-8 pr-2">
                <mat-form-field class="w-100">
                    <input [readonly]="isReadOnly" matInput type="text" formControlName="prenom" />
                    <app-mat-error [control]="formContact.get('prenom')"> </app-mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- Entreprise -->
        <div class="d-flex align-items-center" *ngIf="!formContact.get('estPersonneMorale').value">
            <div class="col-xl-4 mat-subheading-2 m-0 primary">Entreprise</div>
            <div class="col-xl-8 pr-2">
                <mat-form-field class="flex-grow-1 w-100">
                    <input
                        formControlName="entreprise"
                        [matAutocomplete]="autoEntreprise"
                        matInput
                        [readonly]="isReadOnly"
                        placeholder="Entreprise"
                        type="text"
                    />
                    <mat-error *ngIf="formContact.get('entreprise').hasError('entrepriseDoesNotExist')">
                        Aucune entreprise n'existe avec ce nom.
                    </mat-error>
                    <mat-autocomplete #autoEntreprise="matAutocomplete" [displayWith]="displayFnName">
                        <mat-option *ngIf="isSearchingEntreprises | async" class="is-loading">
                            <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                        <ng-container *ngFor="let option of filteredSearchEntreprises">
                            <mat-option
                                *ngIf="!(isSearchingEntreprises | async) && formContact.value.id != option.id"
                                [value]="option"
                            >
                                {{ option.nom }} - {{ option.telephone }} - {{ option.email }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </mat-form-field>
                <app-mat-error [control]="formContact.get('entreprise')"> </app-mat-error>
            </div>
        </div>

        <!-- Siret -->
        <div class="d-flex align-items-center" *ngIf="formContact.get('estPersonneMorale').value">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Siret</div>
            <div class="col-xl-8 pr-2">
                <mat-form-field class="w-100">
                    <input
                        matInput
                        type="text"
                        formControlName="siret"
                        [matAutocomplete]="autoSiret"
                        [readonly]="isReadOnly"
                    />
                    <app-mat-error [control]="formContact.get('siret')"> </app-mat-error>
                    <mat-autocomplete
                        #autoSiret="matAutocomplete"
                        (optionSelected)="onClickChangeContactFromSelect($event, 'siret')"
                    >
                        <mat-option *ngIf="isSearchingSirets | async" class="is-loading">
                            <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                        <mat-optgroup
                            *ngIf="!(isSearchingSirets | async) && filteredSearchSirets?.length"
                            label="Contacts similaires"
                        >
                            <mat-option
                                *ngFor="let option of filteredSearchSirets"
                                (onSelectionChange)="onSelectionChanged($event, 'siret')"
                                [value]="option"
                            >
                                {{ option.siret }} - {{ option.nom }} - {{ option.telephone }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <!-- Téléphone -->
        <div class="d-flex align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary required">Téléphone</div>
            <div class="col-xl-8 pr-2">
                <mat-form-field class="w-100">
                    <input
                        matInput
                        type="text"
                        formControlName="telephone"
                        [matAutocomplete]="autoTelepohone"
                        [readonly]="isReadOnly"
                    />
                    <app-mat-error [control]="formContact.get('telephone')"> </app-mat-error>
                    <mat-autocomplete
                        #autoTelepohone="matAutocomplete"
                        (optionSelected)="onClickChangeContactFromSelect($event, 'telephone')"
                    >
                        <mat-option *ngIf="isSearchingTelephones | async" class="is-loading">
                            <mat-spinner diameter="25"></mat-spinner>
                        </mat-option>
                        <mat-optgroup
                            *ngIf="!(isSearchingTelephones | async) && filteredSearchTelephones?.length"
                            label="Contacts similaires"
                        >
                            <mat-option
                                *ngFor="let option of filteredSearchTelephones"
                                (onSelectionChange)="onSelectionChanged($event, 'telephone')"
                                [value]="option"
                            >
                                {{ option.telephone }} - {{ option.nom }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <!-- Adresse-->
        <div class="d-flex align-items-center" formGroupName="adresse">
            <div class="col-xl-4 mat-subheading-2 m-0 primary">Adresse</div>
            <div class="col-xl-8 d-flex flex-column pr-2">
                <mat-form-field class="w-100">
                    <textarea [readonly]="isReadOnly" placeholder="Voie" matInput formControlName="voie"> </textarea>
                </mat-form-field>

                <mat-form-field class="w-100">
                    <input
                        [readonly]="isReadOnly"
                        placeholder="Complément d'adresse 1"
                        matInput
                        type="text"
                        formControlName="complementAdresse1"
                    />
                </mat-form-field>

                <mat-form-field class="w-100">
                    <input
                        [readonly]="isReadOnly"
                        placeholder="Complément d'adresse 2"
                        matInput
                        type="text"
                        formControlName="complementAdresse2"
                    />
                </mat-form-field>

                <mat-form-field class="w-100">
                    <input
                        [readonly]="isReadOnly"
                        placeholder="Code postal"
                        matInput
                        type="text"
                        formControlName="codePostal"
                    />
                </mat-form-field>

                <mat-form-field class="w-100">
                    <input [readonly]="isReadOnly" placeholder="Ville" matInput type="text" formControlName="ville" />
                </mat-form-field>
            </div>
        </div>

        <!-- Email -->
        <div class="d-flex align-items-center">
            <div class="col-xl-4 mat-subheading-2 m-0 primary">Email</div>
            <div class="col-xl-8 pr-2">
                <mat-form-field class="w-100">
                    <input
                        matInput
                        type="text"
                        formControlName="email"
                        [matAutocomplete]="autoEmail"
                        [readonly]="isReadOnly"
                    />
                    <app-mat-error [control]="formContact.get('email')"> </app-mat-error>
                </mat-form-field>
                <mat-autocomplete
                    #autoEmail="matAutocomplete"
                    (optionSelected)="onClickChangeContactFromSelect($event, 'email')"
                >
                    <mat-option *ngIf="isSearchingEmails | async" class="is-loading">
                        <mat-spinner diameter="25"></mat-spinner>
                    </mat-option>
                    <mat-optgroup
                        *ngIf="!(isSearchingEmails | async) && filteredSearchEmails?.length"
                        label="Contacts similaires"
                    >
                        <mat-option
                            *ngFor="let option of filteredSearchEmails"
                            (onSelectionChange)="onSelectionChanged($event, 'email')"
                            [value]="option"
                        >
                            {{ option.email }} - {{ option.nom }} - {{ option.telephone }}
                        </mat-option>
                    </mat-optgroup>
                </mat-autocomplete>
            </div>
        </div>
    </div>
</div>

"use strict";

import { cn_space } from '../model/cn_space';
import { cn_storey } from '../model/cn_storey';
import { cn_zone } from '../model/cn_zone';
import { cn_pastille } from '../svg/cn_pastille';
import { cn_svg_map } from '../svg/cn_svg_map';
import { cn_transaction_manager } from '../utils/cn_transaction_manager';
import { cn_extension_feature } from './cn_extension_feature';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** zone extension model
//***********************************************************************************
//***********************************************************************************



export class cn_zone_extension extends cn_extension_feature {

    /**
     * Tool zones extension
     * Ajout d'autres types de zones que la zone par défaut (usage) :
     * format { property: <string de la propriété dans le building>, label: <string de label IHM>, icon: <string de ref de l'icone mdi> }
     *
     */
    constructor() {
        super(false);
        this.tool_zones = [];
    }

}



import { Observable, of } from 'rxjs';

export interface ImageSize {
    width: number;
    height: number;
}

export class FileUtils {
    /**
     * Sauvegarde un fichier sur le disque du client.
     */
    static saveFile(fileName: string, fileContent: string, mimeType: string) {
        const blob = new Blob([fileContent], { type: mimeType });
        const element = document.createElement('a');
        element.href = URL.createObjectURL(blob);
        element.download = fileName;
        element.click();
    }

    /**
     * Sauvegarde un fichier à partir d'une URI Base64 (l'URI doit contenir le mime type) sur le disque du client.
     */
    static saveBase64File(fileName: string, fileUri: string) {
        const element = document.createElement('a');
        element.href = fileUri;
        element.download = fileName;
        element.click();
    }

    /**
     * Renvoie le contenu d'un fichier sous forme de Data URI.
     */
    static fileToDataURI(file: Blob): Observable<string> {
        if (file == null) return of('');

        function _fileToDataURI(obs) {
            const reader = new FileReader();
            reader.onerror = (err) => obs.error(err);
            reader.onabort = (err) => obs.error(err);
            reader.onload = () => obs.next(reader.result as string);
            reader.onloadend = () => obs.complete();
            return reader.readAsDataURL(file);
        }

        return new Observable(_fileToDataURI);
    }

    /**
     * Renvoie le contenu d'un fichier sous forme de texte.
     */
    static fileToText(file: Blob): Observable<string> {
        if (file == null) return of('');

        function _fileToText(obs) {
            const reader = new FileReader();
            reader.onerror = (err) => obs.error(err);
            reader.onabort = (err) => obs.error(err);
            reader.onload = () => obs.next(reader.result as string);
            reader.onloadend = () => obs.complete();
            return reader.readAsText(file);
        }

        return new Observable(_fileToText);
    }

    /**
     * Convertit une dataURI sous forme de fichier.
     * https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
     */
    static dataURItoFile(dataURI: string, fileName: string): File {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        const byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ab], fileName, { type: mimeString });
    }
}

import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Volume } from 'src/app/model/bien.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { PolluantPrelevement } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';

@Component({
    selector: 'app-prelevement-form-general',
    templateUrl: './prelevement-form-general.component.html',
    styleUrls: ['./prelevement-form-general.component.scss'],
})
export class PrelevementFormGeneralComponent implements OnInit {
    @Input() set diagnostic(diag: Diagnostic) {
        this.contenuDiagnostic = diag.contenuDiagnostic as PolluantPrelevement;
        this.mpcaList = this.contenuDiagnostic?.prelevement?.data?.mpcaList || [];
        //t his.mpcaList = this.contenuDiagnostic.visiteDeChantier.data.mpcaList.data.descriptifList;
    }

    @Input()
    polluantConfig: any;

    @Input() set selectedBesoinId(id: string) {
        const besoins = this.contenuDiagnostic?.prelevement?.data?.besoinsList?.map((v) => v.besoinPrelevement);
        if (besoins) {
            const besoin = besoins.find((z) => z.id === id);
            this.formBesoin = this.createForm(besoin);
            this.formBesoin.valueChanges.subscribe(() => {
                const index = besoins.findIndex((z) => z.id === id);
                this.contenuDiagnostic.prelevement.data.besoinsList[index].besoinPrelevement =
                    this.formBesoin.getRawValue();
                this.formChange.emit(this.contenuDiagnostic);
            });
        }
    }

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Input()
    volumes: Array<Volume>;

    @Input()
    availableVolumesIdInZone: string[];

    @Input()
    selectedZones: Zone[];

    @Output('formChange')
    formChange: EventEmitter<any> = new EventEmitter<any>();

    formGeneral: FormGroup | AbstractControl;

    materiauxConfig: any[];

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    formBesoin: FormGroup; //
    mpcaList: any[];

    private contenuDiagnostic: PolluantPrelevement;
    protected formBuilder: FormBuilder;

    constructor(injector: Injector) {
        this.formBuilder = injector.get(FormBuilder);
    }

    ngOnInit(): void {}
    private createForm(besoin) {
        return this.formBuilder.group({
            id: [besoin.id],
            objectifMesurage: [besoin.objectifMesurage, Validators.required],
            numero: [besoin.numero],
            zoneId: [besoin.zoneId],
            pieceId: [besoin.pieceId, Validators.required],
            processusId: [besoin.processusId, Validators.required],
            simulation: [besoin.simulation, Validators.required],
            conditionPrelevement: [besoin.conditionPrelevement],
            precisionLocalisation: [
                besoin.precisionLocalisation,
                {
                    updateOn: 'blur',
                },
            ],
            justificatifLocalisation: [besoin.justificatifLocalisation, Validators.required],
            justificatifAutre: [besoin.justificatifAutre],
            dureeMinPrelevement: [besoin.dureeMinPrelevement],
            commentaire: [besoin.commentaire],
            frequencePrelevement: [besoin.frequencePrelevement],
            frequencePrelevementAutre: [besoin.frequencePrelevementAutre],
        });
    }
}

import { cn_marker_handler, cn_svg_map, cn_svg_tool_marker_creation } from '@acenv/cnmap-editor';
import { CndiagMarkerZone } from '../model/cndiag-marker-zone.model';

export class CndiagMarkerZoneToolCreation extends cn_svg_tool_marker_creation {
    referenceToMarker: any;
    _edition_handler: cn_marker_handler;
    /**
     * @param svgMap
     */
    constructor(svgMap: cn_svg_map) {
        super(svgMap);
    }

    /**
     * Builds a new marker
     * @returns {cn_marker}
     */
    _build_marker() {
        const marker = new CndiagMarkerZone(this._map._storey);
        marker.idZone = this.referenceToMarker.id;
        marker.typeZone = this.referenceToMarker.type;
        marker.shape_opacity = 0.8;
        marker.set_type('eraser');
        if (this.referenceToMarker.legende) {
            marker.shape_color = this.referenceToMarker.legende.color;
        }
        return marker;
    }

    /**
     * Surcharge de la méthode appellé lorsqu'un nouveau marker est créé
     * @param {cn_marker} marker
     */
    _marker_created(marker) {
        var obj = this;
        obj._insert_marker(marker);
        obj._initiate_edition([marker]);
        this._edition_handler.buildMarkerInput(marker, false);
        obj._map.refresh();
        //call l'event creation. Si on call marker_input alors cela fait apparaitre la pop up
        this.call('creation', marker);
    }

    _initialize_handler() {
        super._initialize_shape_handler();

        // Permet de saisir un marker sur un autre marker en mode 1
        this._handler.allow_creation = (mouseWorld, cam) => {
            return true;
        };
    }

    _initialize_freeform_handler() {
        super._initialize_freeform_handler();

        // Permet de saisir un marker sur un autre marker en mode 0
        this._handler.allow_creation = (mouseWorld, cam) => {
            return true;
        };
    }

    setMarkerModeToZone() {
        this.set_marker_mode(1);
    }
}

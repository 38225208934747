import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SectionHelp } from '../../../model/section-help.model';
import { BaseComponent } from 'src/app/commons-lib';
import { ReferenceService } from 'src/app/services/reference.service';

class HelpModalData {
    constructor(public help: SectionHelp) {}
}

@Component({
    selector: 'app-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent extends BaseComponent implements OnInit {
    contenu: any;

    constructor(
        public dialogRef: MatDialogRef<HelpModalComponent>,
        private referenceService: ReferenceService,
        @Inject(MAT_DIALOG_DATA) public data: HelpModalData
    ) {
        super();
    }

    ngOnInit() {
        this.referenceService.findAllAides().subscribe((res) => {
            if (res) {
                const aide = res.filter((a) => a.typesPrestation == this.data.help.typePrestation)[0];
                const doc = document.implementation.createHTMLDocument();
                doc.body.innerHTML = aide?.contenu;
                const section = doc.getElementById(this.data.help.sectionId);
                if (section) {
                    this.contenu = section.innerHTML;
                } else {
                    this.contenu =
                        "<span class='mat-h2 primary'>Il n'y a pas d'aide disponible pour cette section.</span>";
                }
            }
        });
    }

    close() {
        this.dialogRef.close();
    }
}

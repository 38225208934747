<mat-card
    *ngIf="!this.currentStorey"
    class="w-100 drop-card-placeholder d-flex justify-content-center align-items-center my-3"
>
    Pas de plan disponible pour ce niveau.
</mat-card>
<div class="storey-view-content" [hidden]="!currentStorey">
    <lib-cn-storey
        #libCnStorey
        [storey]="currentStorey"
        [config]="cnMapConfig"
        [readOnly]="cnStoreyReadonly"
        [showControls]="true"
    ></lib-cn-storey>
</div>

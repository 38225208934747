import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { EtapeDiagnosticGenerique, ItemEtapeDiagnosticGenerique } from '../../../model/diagnostic-etape.model';
import { StateChoiceBoxes } from '../../../model/states.model';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SectionHelp } from '../../../model/section-help.model';
import { FormContext } from '../../../model/rule/form-context.model';
import { FormService } from '../../../services/form.service';
import { absentOption, presentOption } from '../../diagnostics/assainissement/shared/assainissement.constants';
import { Diagnostic } from '../../../model/diagnostic.model';
import { CustomSelectGroup } from '../../shared/custom-select/custom-select.model';
import { takeUntil } from 'rxjs/operators';
import { InterventionService } from '../../../services/intervention.service';
import { DiagnosticService } from '../../../services/diagnostic.service';
import { EtatProgressionService } from '../../../services/etat-progression.service';

@Component({
    selector: 'app-diagnostic-equipment-section',
    templateUrl: './diagnostic-equipment-section.component.html',
    styleUrls: ['./diagnostic-equipment-section.component.scss'],
})
export class DiagnosticEquipmentSectionComponent extends BaseComponent implements OnInit {
    // State choices par défaut
    presentChoiceOption = presentOption;
    absentChoiceOption = absentOption;

    elementsFormArray: any;

    /**
     * Si on passe par reactive form
     */
    @Input()
    diagFormGroup: FormGroup | AbstractControl;

    @Input()
    diagnostic: Diagnostic;

    @Input()
    readonlyMode = false;

    /**
     * Section du diagnostic
     */
    @Input()
    section: EtapeDiagnosticGenerique;

    /**
     * Titre de la section
     */
    @Input() titleLabel: string;

    /**
     * Afficher l'aide ?
     */
    @Input() showHelp = true;

    @Input() helpToDisplay: SectionHelp;

    /**
     * Liste des state choices
     */
    @Input()
    set choices(choices: StateChoiceBoxes[]) {
        this._choices = choices;
    }

    _choices: StateChoiceBoxes[] = [this.presentChoiceOption, this.absentChoiceOption];

    /**
     * Afficher le changement d'état ?
     */
    @Input()
    canChangeState = false;

    /**
     * Liste des situations
     */
    @Input()
    situationsChoice: CustomSelectGroup[];

    /**
     * Afficher le nombre d'équipement ?
     */
    @Input()
    canIncrement = false;

    /**
     * Label si on affiche le nombre d'équipement
     */
    @Input()
    incrementLabel = '';

    constructor(
        private confirmationService: ConfirmationService,
        private notificationService: NotificationService,
        private formBuilder: FormBuilder,
        private formService: FormService,
        private interventionService: InterventionService,
        private diagnosticService: DiagnosticService,
        private etatProgressionService: EtatProgressionService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.diagFormGroup) {
            this.initFormArrayElements();
        }
        this.etatProgressionService.etatProgressions$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((diagn) => {
            this.readonlyMode = this.diagnosticService.isReadOnlyMode(
                this.interventionService.getCurrentInterventionValue(),
                this.diagnosticService.getCurrentDiagnosticValue()
            );
        });
    }

    /**
     * Ajouter un nouvel élément à la liste d'éléments
     */
    addEquipment() {
        const element = new ItemEtapeDiagnosticGenerique();
        element.nombre = 1;
        this.section.elements.push(element);
        // ajout + maj du formulaire
        this.elementsFormArray.push(this.createFormElement(element));
        this.elementsFormArray.updateValueAndValidity();
        this.diagFormGroup.updateValueAndValidity();
    }

    /**
     * Supprimer un élément de la liste
     */
    deleteEquipment(equipment: ItemEtapeDiagnosticGenerique) {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer cet élément ?', () => {
            const index = this.section.elements.indexOf(equipment, 0);
            if (index > -1) {
                this.section.elements.splice(index, 1);
                this.elementsFormArray.removeAt(index);
                // MAJ formulaire + validators
                this.displayElements();
            }
            this.notificationService.success("L'élément a bien été supprimé.");
        });
    }

    /**
     * Défini les règles selon la valeur de présence/absence
     */
    displayElements() {
        // Présent
        if (this.diagFormGroup.get('valeur').value == 'present') {
            this.diagFormGroup.get('elementsFormArray').setValidators([Validators.minLength(1)]);
            if (this.section.elements.length == 0) {
                this.addEquipment();
            }
        }

        // Absent
        if (this.diagFormGroup.get('valeur').value == 'absent') {
            if (this.section.elements.length > 0) {
                for (let i = this.section.elements.length - 1; i >= 0; i--) {
                    this.elementsFormArray.removeAt(i);
                }
                this.diagFormGroup.get('elementsFormArray').clearValidators();
                this.section.elements = [];
            }
        }
        // Dans tous les cas, maj du formulaire
        this.diagFormGroup.updateValueAndValidity();
    }

    private initFormArrayElements() {
        this.elementsFormArray = this.diagFormGroup.get('elementsFormArray') as FormArray;
        if (this.section.elements.length) {
            for (const element of this.section.elements) {
                const form = this.createFormElement(element);
                this.elementsFormArray.push(form);
            }
        }
    }

    private createFormElement(element: ItemEtapeDiagnosticGenerique): FormGroup {
        const form = this.formBuilder.group({
            situation: this.formService.createFormControl(
                'situation',
                new FormContext('situation', element, Validators.required, this.ngUnsubscribe)
            ),
        });
        if (this.canChangeState) {
            form.addControl(
                'valeur',
                this.formService.createFormControl(
                    'valeur',
                    new FormContext('valeur', element, Validators.required, this.ngUnsubscribe)
                )
            );
        }
        if (this.canIncrement) {
            form.addControl(
                'nombre',
                this.formService.createFormControl(
                    'nombre',
                    new FormContext('nombre', element, Validators.required, this.ngUnsubscribe)
                )
            );
        }
        return form;
    }
}

<div class="toolbar">
    <mat-toolbar class="border-bottom-1 primary">
        <div>
            <lib-icon [class]="'icon-before-text'">view_comfy</lib-icon>
            <span>Gestion des référentiels</span>
        </div>
        <div class="flex-grow-1"></div>
        <div>
            <span>User</span>
        </div>
    </mat-toolbar>
</div>
<div class="flex-grow-1 main-background overflow-auto">
    <div class="container">
        <div class="row pt-4">
            <div class="col-xl-6 primary">
                {{ 'Gestion des référentiels' | uppercase }}
            </div>
        </div>
        <div class="row py-4">
            <div class="col-xl-8 d-flex flex-column">
                <div class="d-flex mb-4">
                    <mat-card
                        *ngIf="hasAdminAuthority"
                        class="d-flex flex-column justify-content-between mat-card-button"
                    >
                        <mat-card-content>
                            <div class="d-flex align-items-center">
                                <button mat-button (click)="goToEquipements()">
                                    <lib-icon>settings</lib-icon>
                                    <br />Équipements
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card
                        *ngIf="hasAdminAuthority"
                        class="ml-4 d-flex flex-column justify-content-between mat-card-button"
                    >
                        <mat-card-content>
                            <div class="d-flex align-items-center">
                                <button mat-button (click)="goToTypesOuvrage()">
                                    <lib-icon>settings</lib-icon>
                                    <br />Types ouvrage
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card
                        *ngIf="hasAdminAuthority"
                        class="ml-4 d-flex flex-column justify-content-between mat-card-button"
                    >
                        <mat-card-content>
                            <div class="d-flex align-items-center">
                                <button mat-button (click)="goToMateriaux()">
                                    <lib-icon>settings</lib-icon>
                                    <br />Materiaux
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card
                        *ngIf="hasAdminAuthority"
                        class="ml-4 d-flex flex-column justify-content-between mat-card-button"
                    >
                        <mat-card-content>
                            <div class="d-flex align-items-center">
                                <button mat-button (click)="goToVolumes()">
                                    <lib-icon>settings</lib-icon>
                                    <br />Pièces
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="d-flex">
                    <mat-card class="d-flex flex-column justify-content-between mat-card-button">
                        <mat-card-content>
                            <div class="d-flex align-items-center">
                                <button mat-button (click)="goToPrestations()">
                                    <lib-icon>settings</lib-icon>
                                    <br />Prestations diagnostics
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="ml-4 d-flex flex-column justify-content-between mat-card-button">
                        <mat-card-content>
                            <div class="d-flex align-items-center">
                                <button mat-button (click)="goToTemplateRapport()">
                                    <lib-icon>settings</lib-icon>
                                    <br />Aide à la création <br />
                                    de template rapport
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="ml-4 d-flex flex-column justify-content-between mat-card-button">
                        <mat-card-content>
                            <div class="d-flex align-items-center">
                                <button mat-button (click)="goToLibIcones()">
                                    <lib-icon>settings</lib-icon>
                                    <br />Librairie <br />
                                    Icones
                                </button>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>

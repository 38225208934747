"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_tool  : basic class for tool
//***********************************************************************************
//***********************************************************************************

import {cn_svg_tool} from "./cn_svg_tool";

export class cn_svg_tool_edition extends cn_svg_tool {
	constructor(svg_map) {
		super(svg_map);
		this._svg_parent = null;
	}

	//***********************************************************************************
	//**** Selection callback : when selection changes, all edition tools can manage the new selection.
	//***********************************************************************************
	/**
	 * 
	 * @returns {boolean}
	 */
	on_selection_change() {return false;};

	//***********************************************************************************
	/**
	 * Open tool
	 */
	 open_tool() {
		super.open_tool();
   }

	//***********************************************************************************
	//**** Translate of given offset
	//***********************************************************************************
	start_translation(ev) {};
	translate(ev, offset) {};
	finalize_translation(ev) {};
}


import { Pipe, PipeTransform } from '@angular/core';
import { Adresse } from '../model/adresse.model';

@Pipe({
    name: 'adresse',
})
export class AdressePipe implements PipeTransform {
    transform(value: Adresse, ...args: unknown[]): unknown {
        return [value.voie, value.complementAdresse1, value.complementAdresse2, value.codePostal, value.ville]
            .filter((it) => !!it)
            .join(' ');
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** opening type
//***********************************************************************************
//***********************************************************************************

import {cn_element_type} from "./cn_element_type";
import {cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_param_group, cn_configuration_tab} from "./cn_configuration";
import {fh_add, fh_extruded_polygon, fh_mul, fh_polygon, fh_solid} from "@acenv/fh-3d-viewer";
import {cn_element_type_visitor} from '..';
import {
	cn_dist
} from "../utils/cn_utilities";

export const FRAME_LIST = [
	{label:'Aluminium', code:'alu'},
	{label:'PVC', code:'pvc'},
	{label:'Bois', code:'wood'},
	{label:'Acier', code:'steel'}
];

export const GLAZING_LIST = [
	{label:'Aucun', code:'none'},
	{label:'Simple', code:'single'},
	{label:'Double', code:'double'},
	{label:'Triple', code:'triple'}
];

export const GLAZING_GAZ_LIST = [
	{label:'6mm air', code:'air_6'},
	{label:'8mm air', code:'air_8'},
	{label:'12mm air', code:'air_12'},
	{label:'16mm air', code:'air_16'},
	{label:'16mm argon', code:'argon_16'}
];

export const OPENING_LIST = [
	{label:'Aucune', code:'none'},
	{label:'Glissante', code:'sliding'},
	{label:'Battante', code:'french'},
	{label:'Verticale', code:'vertical'}
];

export const VERTICAL_OPENING_LIST = [
	{label:'Vasistas', code:'bottom-hung'},
	{label:'Saillissante', code:'top-hung'},
	{label:'Basculante', code:'tilting'},
	{label:'Guillotine', code:'sash'}
];

export const TRANSOM_LIST = [
	{label:'Aucun', code:'none'},
	{label:'Rectangulaire', code:'rectangle'},
	{label:'Arrondi', code:'round'},
	{label:'Triangulaire', code:'triangle'}
];

export const SILL_LIST = [
	{label:'Aucune', code:'none'},
	{label:'Vitrée', code:'glazed'},
	{label:'Opaque', code:'opaque'},
];

export const CLOSING_LIST = [
	{label:'Aucune', code:'none'},
	{label:'Volet roulant', code:'rolling'},
	{label:'Volet battant', code:'swing'},
	{label:'Store', code:'blind'}
];

var CLIP_ID = 0;

export function code_to_label(c, lst)
{
	for (var i in lst)
	{
		if (lst[i].code == c) return lst[i].label;
	}
	return false;
}

export function label_to_code(l, lst)
{
	for (var i in lst)
	{
		if (lst[i].label == l) return lst[i].code;
	}
	return false;
}

export class cn_opening_type extends cn_element_type  {
	constructor() {
		super();
		this.name = "";
		this.category = "window";
		this.width = 1.2;
		this.height = 1.3;
		this.z = 0.7;
        this.panel_widths = [];

        this.free = false;
        this.frame = "alu";
		this.frame_quality = false;
        this.glazing = "double";
		this.glazing_gaz = "air_16";
        this.opening = "french";
        this.vertical_opening = VERTICAL_OPENING_LIST[0].code;
        this.panels = 2;
        this.transom = "none";
        this.transom_height = 0.5;
        this.sill = "none";
        this.sill_height = 0.5;
        this.closing = "none";

		this.glazing_area = 0;
		this.opaque_area = 0;
		this.frame_area = 0;
		this.full_area = 0;
		this.glazing_frame_linear = 0;
		this.opaque_frame_linear = 0;

		//*** Physics: */
		this.user_physics = false;
		this.Uw = 0;
		this.Sw = 0;
		this.Tl = 0;
    }

    //***********************************************************************************
    //**** default elements
    //***********************************************************************************
    static default_window() {
        return new cn_opening_type();
    }

    static default_door() {
        var ot = new cn_opening_type();
        ot.category = "door";
        ot.width = 0.8;
        ot.height = 2.1;
        ot.z = 0;
        ot.panel_widths = [];
        ot.frame = "wood";
        ot.glazing = "none";
        ot.opening = "french";
        ot.panels = 1;
        ot.transom = "none";
        ot.sill = "none";
        ot.closing = "none";
        return ot;
    }

    static default_door_90() {
        var ot = new cn_opening_type();
        ot.category = "door";
        ot.width = 0.9;
        ot.height = 2.1;
        ot.z = 0;
        ot.panel_widths = [];
        ot.frame = "alu";
        ot.glazing = "none";
        ot.opening = "french";
        ot.panels = 1;
        ot.transom = "none";
        ot.sill = "none";
        ot.closing = "none";
        return ot;
    }

    static default_french_window() {
        var ot = new cn_opening_type();
        ot.name = "PF 140 x 210"
        ot.category = "window";
        ot.width = 1.4;
        ot.height = 2.1;
        ot.z = 0;
        ot.panel_widths = [];
        ot.frame = "alu";
        ot.glazing = "double";
        ot.opening = "french";
        ot.panels = 1;
        ot.transom = "none";
        ot.sill = "none";
        ot.closing = "rolling";
        return ot;
    }

	//***********************************************************************************
	//**** Clone
	//***********************************************************************************
	clone() {
        var c = new cn_opening_type();
        c.name = this.name;
        c.category = this.category;
        c.width = this.width;
        c.height = this.height;
        c.z = this.z;
        c.panel_widths = this.panel_widths.concat([]);
        c.free = this.free;
        c.frame = this.frame;
        c.frame_quality = this.frame_quality;
        c.glazing = this.glazing;
        c.glazing_gaz = this.glazing_gaz;
        c.opening = this.opening;
        c.vertical_opening = this.vertical_opening;
        c.panels = this.panels;
        c.transom = this.transom;
        c.transom_height = this.transom_height;
        c.sill = this.sill;
        c.sill_height = this.sill_height;
        c.closing = this.closing;
		c.user_physics = this.user_physics;
		c.Uw = this.Uw;
		c.Sw = this.Sw;
		c.Tl = this.Tl;
        return c;
    }

    //***********************************************************************************
    get_generic_label() {
        return "Type d'ouvrant";
    }

    //***********************************************************************************
    //**** keys
    //***********************************************************************************
    model_keys() {
        return ["name", "category", "width", "height", "z", "panel_widths", "free", "frame", "frame_quality", "glazing", "glazing_gaz", "opening", "vertical_opening", "panels", "transom", "transom_height", "sill", "sill_height", "closing","user_physics","Uw","Sw","Tl"];
    }

    //***********************************************************************************
    //**** serialize
    //***********************************************************************************
    serialize() {
        var json = {};
        json.class_name = 'cn_opening_type';
        json.ID = this.ID;
        json.name = this.name;
        json.category = this.category;
        json.width = this.width;
        json.height = this.height;
        json.z = this.z;
        json.panel_widths = this.panel_widths.concat([]);

		json.free = this.free;
		json.frame = this.frame;
		json.frame_quality = this.frame_quality;
		json.glazing = this.glazing;
		json.glazing_gaz = this.glazing_gaz;
		json.opening = this.opening;
		json.vertical_opening = this.vertical_opening;
		json.panels = this.panels;
		json.transom = this.transom;
		json.transom_height = this.transom_height;
		json.sill = this.sill;
		json.sill_height = this.sill_height;
		json.closing = this.closing;
		json.user_physics = this.user_physics;
		json.Uw = this.Uw;
		json.Sw = this.Sw;
		json.Tl = this.Tl;

		return json;
	}

	static unserialize(json) {
		if (typeof(json) != 'object') return false;
		if (typeof(json.ID) != 'string') return false;
		if (json.class_name != 'cn_opening_type') return false;
		if (typeof(json.category) != 'string') return false;
		if (typeof(json.width) != 'number') return false;
		if (typeof(json.height) != 'number') return false;
		if (typeof(json.z) != 'number') return false;
		var ot = new cn_opening_type();
		if (typeof(json.name) == 'string') ot.name = json.name;
		if (typeof(json.frame) == 'string') ot.frame = json.frame;
		if (typeof(json.frame_quality) == 'boolean') ot.frame_quality = json.frame_quality;

		ot.category = json.category;
		ot.ID = json.ID;
		ot.width = json.width;
		ot.height = json.height;
		ot.z = json.z;
		ot.free = (typeof(json.free) == 'boolean')?json.free:false;

		if (typeof(json.panel_widths) == 'object')
		{
			ot.panel_widths = json.panel_widths.concat([]);

			//*** check old way : dimensions. */
			if (ot.panel_widths.length > 0)
			{
				var accum = 0;
				ot.panel_widths.forEach(w => accum += w);
				if (accum > 1)
				{
					ot.panel_widths = ot.panel_widths.map(w => w/ot.width);
				}
			}
		}

		if (typeof(json.name) == 'string') ot.name = json.name;
		if (typeof(json.glazing) == 'string') ot.glazing = json.glazing;
		if (typeof(json.glazing_gaz) == 'string') ot.glazing_gaz = json.glazing_gaz;
		if (typeof(json.opening) == 'string') ot.opening = json.opening;
		if (typeof(json.vertical_opening) == 'string')
			ot.vertical_opening = json.vertical_opening;
		if (typeof(json.panels) == 'string')
			ot.panels = parseInt(json.panels);
		else if (typeof(json.panels) == 'number')
			ot.panels = json.panels;

		if (typeof(json.transom) == 'string')
			ot.transom = json.transom;

		if (typeof(json.transom_height) == 'number')
			ot.transom_height = json.transom_height;

		if (typeof(json.sill) == 'string')
			ot.sill = json.sill;

		if (typeof(json.sill_height) == 'number')
			ot.sill_height = json.sill_height;

		if (typeof(json.closing) == 'string')
			ot.closing = json.closing;

		if (typeof(json.user_physics) == 'boolean')
			ot.user_physics = json.user_physics;

		if (typeof(json.Uw) == 'number')
			ot.Uw = json.Uw;

		if (typeof(json.Sw) == 'number')
			ot.Sw = json.Sw;

		if (typeof(json.Tl) == 'number')
			ot.Tl = json.Tl;

		return ot;
	}

	//***********************************************************************************
	//**** Returs visible label
	//***********************************************************************************
	get_label() {
		if (this.name != "") return this.name;
		var html = "";
		if (this.category == "window") html += "Fenêtre ";
		else if (this.category == "door") html += "Porte ";
		return html + (this.width * 100).toFixed(0) + " x " + (this.height * 100).toFixed(0);
	}

	/**
	 * Computes symetry left / right
	 * @returns {boolean}
	 */
	get_x_symetry() {
		if (this.free) return true;
		if (this.panels <= 1)
			return this.opening != "french";

		const widths = this.compute_panel_widths();
		if (Math.abs(widths[0] -  widths[widths.length-1]) > 0.001) return false;
		if (this.opening != "french") return true;
		return widths.length == 2;
	}

	/**
	 * Computes symetry front / back
	 * @returns {boolean}
	 */
	 get_y_symetry() {
		if (this.free) return true;
		if (this.opening == "vertical")
			return (this.vertical_opening == "sash" || this.vertical_opening == "tilting");
		return this.opening != "french";
	}

	//***********************************************************************************
	//**** Returns description
	//***********************************************************************************

    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
	get_description() {
		var description = [];

		var cat = {label:"Catégorie"};
		if (this.free)
			cat.value = "Baie libre";
		else if (this.category == "window")
			cat.value = "Fenêtre";
		else if (this.category == "door")
			cat.value = "Porte";
		description.push(cat);

		description.push({label:"Largeur", value: this.width*100, decimals:0, unit:"cm"});

		description.push({label:"Hauteur", value: this.height*100, decimals:0, unit:"cm"});

		description.push({label:"Décalage", value: this.z*100, decimals:0, unit:"cm"});

		description.push({label:"Surface", value: this.get_area(), decimals:2, unit:"m²"});

		description.push({label:"Surface vitrée", value: this.get_glazing_area(), decimals:2, unit:"m²"});

		if (this.free) return description;

		var frame_label = code_to_label(this.frame,FRAME_LIST);
		if (frame_label)
		{
			if (this.frame_quality) frame_label += " récent";
			else frame_label += " ancien";
			description.push({label:"Menuiserie",value:frame_label});
		}

		var glazing_label = code_to_label(this.glazing,GLAZING_LIST);
		if (glazing_label)
		{
			if (this.glazing == "double") glazing_label += " " + code_to_label(this.glazing_gaz,GLAZING_GAZ_LIST);
			description.push({label:"Vitrage",value:glazing_label});
		}

		var opening_label = code_to_label(this.opening,OPENING_LIST);
		if (opening_label)
		{
			description.push({label:"Ouverture",value:opening_label});
			if (this.opening == "vertical")
			{
				const vertical_opening_label = code_to_label(this.vertical_opening,VERTICAL_OPENING_LIST);
				if (vertical_opening_label)
					description.push({label:"Type d'ouverture",value:vertical_opening_label});
			}
		}

		description.push({label:"Panneaux",value:this.panels});

		var transom_label = code_to_label(this.transom,TRANSOM_LIST);
		if (transom_label)
			description.push({label:"Imposte",value:transom_label});

		if (this.transom != "none")
			description.push({label:"Hauteur d'imposte", value: this.transom_height*100, decimals:0, unit:"cm"});

		var sill_label = code_to_label(this.sill,SILL_LIST);
		if (sill_label)
			description.push({label:"Allège",value:sill_label});

		if (this.sill != "none")
			description.push({label:"Hauteur d'allège", value: this.sill_height*100, decimals:0, unit:"cm"});

		var closing_label = code_to_label(this.closing,CLOSING_LIST);
		if (closing_label)
			description.push({label:"Fermeture",value:closing_label});

		this.compute_physics();
		description.push({label:"Uw",value:this.Uw,decimals:3,unit:"W/m²/K"});
		description.push({label:"Facteur solaire",value:this.Sw*100,decimals:1,unit:"%"});
		description.push({label:"Transmission",value:this.Tl*100,decimals:1,unit:"%"});

		return description;
	}

	//***********************************************************************************
	//**** svg icon
	//***********************************************************************************
	draw_svg_icon(w,h)
	{
		var html = "";

		if (this.free)
		{
			var ow = this.width;
			var oh = this.height + this.z;
			var scale = (ow / oh > w / h)? w / (1.05 * ow):h/(1.05*oh);

			var x0 = w/2 - ow*scale/2;
			var x1 = w/2 + ow*scale/2;
			var y0 = h - oh*scale;
			var y1 = h - this.z*scale;

			html += "<path class='opening_type_free' d='";
			html += "M 0 0 ";
			html += "L " + w + " 0 ";
			html += "" + w + " " + h + " ";
			html += "0 " + h + " ";
			html += "Z ";

			html += "M " + x0 + " " + y0 + " ";
			html += "L " + x1 + " " + y0 + " ";
			html += " " + x1 + " " + y1 + " ";
			html += " " + x0 + " " + y1 + " ";
			html += "Z";

			html += "'  fill-rule='evenodd' />";
			return html;
		}

		var nb_panels = this.panels;

		var ow = this.width;
		if (this.closing == "swing") ow *= 2;
		var oh = this.height;
		var transom_height = (this.transom == "none")?0:this.transom_height;
		var sill_height = (this.sill == "none")?0:this.sill_height;
		var scale = (ow / oh > w / h)? w / (1.05 * ow):h/(1.05*oh);

		var x0 = w/2 - this.width*scale/2;
		var x1 = w/2 + this.width*scale/2;
		var y0 = h/2 - this.height*scale/2 + transom_height*scale;
		var y1 = h/2 + this.height * scale / 2 - sill_height*scale;

		var fill_color = "";
		if (this.frame == "alu") fill_color = "rgb(100,100,100)";
		if (this.frame == "pvc") fill_color = "rgb(200,200,200)";
		if (this.frame == "wood") fill_color = "rgb(150,125,100)";
		if (this.frame == "steel") fill_color = "rgb(70,70,70)";

		html += "<rect x='" + x0 + "' y='" + y0 + "' width='" + (x1-x0) + "' height='" + (y1-y0) + "' style='fill:" + fill_color + ";stroke:black' />";

		var glass_color = "";
		if (this.glazing == "single") glass_color = "rgb(250,250,255)";
		else if (this.glazing == "double") glass_color = "rgb(220,220,255)";
		else if (this.glazing == "triple") glass_color = "rgb(200,200,255)";
		else if (this.glazing == "none") glass_color = fill_color;

		var mullion = (x1 + y1 - y0 - x0)/20;
		var x = x0 + mullion*0.5;
		var y = y0 + mullion*0.5;
		const panel_sizes = this.compute_panel_widths();
		if (this.opening == "vertical")
		{
			var panel_width = (x1-x0)-mullion;
			for (var k=0;k<nb_panels;k++)
			{
				var panel_height = scale * panel_sizes[k] - mullion;
				html += "<rect x='" + x + "' y='" + y + "' width='" + panel_width + "' height='" + panel_height + "' style='fill:" + glass_color + ";stroke:black' />";
				if (k>0)
					html += "<line x1='" + x0 + "' y1='" + (y - mullion*0.5) + "' x2='" + x1 + "' y2='" + (y - mullion*0.5) + "' style='stroke:black' />";
				y += panel_height + mullion;
			}
		}
		else
		{
			var panel_height = (y1-y0)-mullion;
			for (var k=0;k<nb_panels;k++)
			{
				var panel_width = scale * panel_sizes[k] - mullion;
				html += "<rect x='" + x + "' y='" + y + "' width='" + panel_width + "' height='" + panel_height + "' style='fill:" + glass_color + ";stroke:black' />";
				if (k>0)
					html += "<line x1='" + (x-mullion*0.5) + "' y1='" + y0 + "' x2='" + (x-mullion*0.5) + "' y2='" + y1 + "' style='stroke:black' />";
				x += panel_width + mullion;
				var yy = (y0+y1)/2;
				if (this.opening=="french")
				{
					if (k==1)
						html += "<line x1='" + (x - panel_width - mullion - mullion*0.25) + "' y1='" + yy + "' x2='" + (x- panel_width - mullion + mullion*0.75) + "' y2='" + yy + "' style='stroke:black' />";
					else
						html += "<line x1='" + (x-mullion*0.75) + "' y1='" + yy + "' x2='" + (x-mullion*1.75) + "' y2='" + yy + "' style='stroke:black' />";
				}
				else if (this.opening=="sliding")
				{
					html += "<line x1='" + (x-mullion*0.75) + "' y1='" + (yy - mullion) + "' x2='" + (x-mullion*0.75) + "' y2='" + (yy + mullion) + "' style='stroke:black' />";
				}
			}
		}

		//************************************** */
		//** draw sill */
		if (this.sill != "none")
		{
			html += "<rect x='" + x0 + "' y='" + y1 + "' width='" + (x1-x0) + "' height='" + (sill_height*scale) + "' style='fill:" + fill_color + ";stroke:black' />";
			if (this.sill == "glazed" && x1-x0 - mullion > 0 && sill_height*scale - mullion > 0)
				html += "<rect x='" + (x0+mullion*0.5) + "' y='" + (y1+mullion*0.5) + "' width='" + (x1-x0 - mullion) + "' height='" + (sill_height*scale - mullion) + "' style='fill:" + glass_color + ";stroke:black' />";
		}

		//************************************** */
		//** draw transom */

		function draw_path(vertices) {
			var hh = "";
			for (var i=0;i<vertices.length;i++)
			{
				hh += vertices[i][0] + " " + vertices[i][1] + " " + vertices[i][2] + " ";
			}
			hh += "Z";
			return hh;
		}

		if (this.transom != "none")
		{
			var xm = (x0+x1)*0.5;
			var yy0 = y0 - transom_height * scale;
			var yy2 = y0;
			var yy1 = (yy0 + yy2)*0.5;
			if (this.transom == "rectangle")
			{
				html += "<rect x='" + x0 + "' y='" + yy0 + "' width='" + (x1-x0) + "' height='" + (yy2 - yy0) + "' style='fill:" + fill_color + ";stroke:black' />";
				if (yy2 - yy0 - mullion > 0)
					html += "<rect x='" + (x0+mullion*0.5) + "' y='" + (yy0+mullion*0.5) + "' width='" + (x1-x0 - mullion) + "' height='" + (yy2 - yy0 - mullion) + "' style='fill:" + glass_color + ";stroke:black' />";
			}
			else
			{
				if (this.transom == "triangle")
				{
					var path = [];
					path.push(["M",x0,yy2]);
					path.push(["L",x0,yy1]);
					path.push(["",xm,yy0]);
					path.push(["",x1,yy1]);
					path.push(["",x1,yy2]);
					html += "<path d='" + draw_path(path) + "' style='fill:" + fill_color + ";stroke:black' />";
					if (yy2 - yy0 - mullion > 0)
					{
						path = [];
						path.push(["M",x0+0.5*mullion,yy2-0.5*mullion]);
						path.push(["L",x0+0.5*mullion,yy1+0.25*mullion]);
						path.push(["",xm,yy0+0.5*mullion]);
						path.push(["",x1-0.5*mullion,yy1+0.25*mullion]);
						path.push(["",x1-0.5*mullion,yy2-0.5*mullion]);
						html += "<path d='" + draw_path(path) + "' style='fill:" + glass_color + ";stroke:black' />";
					}
				}
				else
				{
					var path = [];
					path.push(["M",x0,yy2]);
					path.push(["Q",x0,yy0]);
					path.push(["",xm,yy0]);
					path.push(["Q",x1,yy0]);
					path.push(["",x1,yy2]);
					html += "<path d='" + draw_path(path) + "' style='fill:" + fill_color + ";stroke:black' />";
					if (yy2 - yy0 - mullion > 0)
					{
						path = [];
						path.push(["M",x0+0.5*mullion,yy2-0.5*mullion]);
						path.push(["Q",x0+0.5*mullion,yy0+0.5*mullion]);
						path.push(["",xm,yy0+0.5*mullion]);
						path.push(["Q",x1-0.5*mullion,yy0+0.5*mullion]);
						path.push(["",x1-0.5*mullion,yy2-0.5*mullion]);
						html += "<path d='" + draw_path(path) + "' style='fill:" + glass_color + ";stroke:black' />";
					}
				}
			}
		}

		//************************************** */
		//** draw shutter */
		if (this.closing != "none")
		{
			var yy0 = y0 - transom_height * scale;
			var yy1 = y0 - transom_height * scale*0.5;
			var yy2 = y1 + sill_height * scale;
			if (this.closing == "swing")
			{
				var xx1 = x1 + (x1-x0)*0.5;
				var swing_color = "rgb(240,240,240)";
				var path = [];
				path.push(["M",xx1,yy2]);
				path.push(["L",x1,yy2]);
				if (this.transom == "round")
				{
					path.push(["",x1,y0]);
					path.push(["Q",x1,yy0]);
				}
				else if (this.transom == "triangle")
				{
					path.push(["",x1,yy1]);
				}
				else
				{
					path.push(["",x1,yy0]);
				}
				path.push(["",xx1,yy0]);

				html += "<path d='" + draw_path(path) + "' style='fill:" + swing_color + ";stroke:black' />";
				for (var i in path)
				{
					// @ts-ignore
					path[i][1] = x0 - path[i][1] + x1;
				}
				html += "<path d='" + draw_path(path) + "' style='fill:" + swing_color + ";stroke:black' />";
			}
			else
			{
				var xm = (x0+x1)*0.5;
				var path = [];
				path.push(["M",x1,yy2]);
				path.push(["L",x0,yy2]);
				if (this.transom == "round")
				{
					path.push(["",x0,y0]);
					path.push(["Q",x0,yy0]);
					path.push(["",xm,yy0]);
					path.push(["Q",x1,yy0]);
					path.push(["",x1,y0]);
				}
				else if (this.transom == "triangle")
				{
					path.push(["",x0,yy1]);
					path.push(["",xm,yy0]);
					path.push(["",x1,yy1]);
				}
				else
				{
					path.push(["",x0,yy0]);
					path.push(["",x1,yy0]);
				}

				html += "<clipPath id='clip_" + CLIP_ID + "'><path d='" +  draw_path(path) + "'></clipPath>";
				var closing_height = (yy2-yy0)/4;
				if (this.closing=="blind")
				{
					html += "<g opacity='0.5'>";
					html += "<rect x='" + x0 + "' y='" + yy0 + "' width='" + (x1-x0) + "' height='" + closing_height + "' clip-path='url(#clip_" + CLIP_ID + ")' style='fill:rgb(200,150,100);stroke:black' />";
					html += "</g>";
				}
				else
				{
					html += "<rect x='" + x0 + "' y='" + yy0 + "' width='" + (x1-x0) + "' height='" + closing_height + "' clip-path='url(#clip_" + CLIP_ID + ")' style='fill:rgb(150,150,150);stroke:black' />";
					for (var y=yy0 + closing_height - 5;y>yy0;y -= 5)
						html += "<line x1='" + x0 + "' y1='" + y + "' x2='" + x1 + "' y2='" + y + "' clip-path='url(#clip_" + CLIP_ID + ")' style='stroke:black' />";
				}
				html += "<path d='" + draw_path(path) + "' style='fill:none;stroke:black' />";
				CLIP_ID++;
			}
		}

		return html;
	}

	/**
	 * Computed a correct size for each panel
	 * @returns {number[]}
	 */
	compute_panel_widths(available_size = -1)
	{
		var full_width = available_size;
		if (full_width<0) full_width = (this.opening == "vertical")?this.height:this.width;
		if (this.panels == 1) return [full_width];

		var panel_widths = [];

		//*** We donn't want panels to be smaller than this */
		var min_width = (full_width < this.panels*0.1)?full_width/this.panels:0.1;

		//*** extra width over 'min_width' to be added to panels */
		var remains = full_width - min_width * this.panels;
		for (var n=0;n<this.panels-1;n++)
		{
			if (n < this.panel_widths.length)
			{
				var x = this.panel_widths[n]*full_width;
				if (x > remains + min_width) x = remains + min_width;
				panel_widths.push(x);
				remains -= (x - min_width);
			}
			else break;
		}

		//*** we add what remains on other panels */
		remains /= (this.panels - panel_widths.length);
		for (var n=panel_widths.length;n<this.panels;n++)
			panel_widths.push(min_width + remains);

		return panel_widths;
	}

	//***********************************************************************************
	//**** 3D geometry
	//***********************************************************************************
	/**
	 * Builds the piercing polygon
	 * @param {number[]} origin : 3D origin of the opening (at ground level)
	 * @param {number[]} dx : x normalized direction of the opening (along opening width)
	 * @param {number[]} dy : y normalized direction of the opening (along thickness)
	 * @param {number[]} dz : vertical direction of the opening
	 * @returns {fh_polygon} output polygon
	 */
	build_piercing_polygon(origin, dx, dy, dz) {

		//*** Slight offset for doors : in order to avoid ambiguities */
		const z = (this.z>0)?this.z:-0.001;
		var z0 = z;
		var z2 = z + this.height;
		var z1 = (this.transom == "none")?z2:z2- this.transom_height;

		var p0 = fh_add(origin,fh_mul(dz,z0));
		var p1 = fh_add(origin,fh_mul(dz,z1));
		var p2 = fh_add(origin,fh_mul(dz,z2));
		var contour_vertices = [];
		contour_vertices.push(fh_add(p1,fh_mul(dx,this.width)));
		contour_vertices.push(fh_add(p0,fh_mul(dx,this.width)));
		contour_vertices.push(p0);
		contour_vertices.push(p1);
		if (this.transom == "rectangle")
		{
			contour_vertices.push(p2);
			contour_vertices.push(fh_add(p2,fh_mul(dx,this.width)));
		}
		else if (this.transom == "round")
		{
			var center = fh_add(p1,fh_mul(dx,this.width*0.5));
			var ddx = fh_mul(dx,-0.5*this.width);
			var ddy = fh_mul(dz,this.transom_height);
			for (var theta = 0; theta < 180; theta +=5)
			{
				var angle = theta * Math.PI/180;
				var cc = Math.cos(angle);
				var ss = Math.sin(angle);
				contour_vertices.push(fh_add(center,fh_add(fh_mul(ddx,cc),fh_mul(ddy,ss))));
			}
		}
		else if (this.transom == "triangle")
		{
			var pp = fh_add(origin,fh_mul(dz,z1 + 0.5 * this.transom_height));
			contour_vertices.push(pp);
			contour_vertices.push(fh_add(p2,fh_mul(dx,this.width*0.5)));
			contour_vertices.push(fh_add(pp,fh_mul(dx,this.width)));
			console.log("triangle piercing",contour_vertices);
		}
		var polygon = new fh_polygon(origin,dy);
		polygon.add_contour(contour_vertices);
		return polygon;
	}

	build_piercing_solid(origin, dx, dy, dz, thickness)
	{
		var pg = this.build_piercing_polygon(origin, dx, dy, dz);
		var solid = new fh_solid();
		solid.extrusion(pg,fh_mul(dy,thickness));
		return solid;
	}

	build_piercing_extrusion(origin, dx, dy, dz, thickness)
	{
		var pg = this.build_piercing_polygon(origin, dx, dy, dz);
		return fh_extruded_polygon.build_extrusion(pg,fh_mul(dy,thickness),[0,0,0]);
	}

	get_area()
	{
		if (this.free || this.transom == "none" || this.transom == "rectangle")
			return this.width * this.height;
		var transom_height = (this.transom_height < this.height)?this.transom_height:this.height;
		if (this.transom == "triangle")
			return this.width * (this.height - 0.25 * transom_height);
		return this.width * (this.height - transom_height * (1 - 0.25 * Math.PI));
	}

	/**
	 * Returns the glazing area of the opening
	 * @returns{number}
	 */
	get_glazing_area() {
		this.build_extruded_polygons();
		return this.glazing_area;
	}

	/**
	 * Compute physics (areas, Uw, Sw, Tl)
	 */
	compute_physics(){
		this.build_extruded_polygons();

		if (this.user_physics) return;
		if (this.category == "door" && this.glazing == "none")
		{
			if (this.frame == "alu" || this.frame == "steel")
				this.Uw = (this.frame_quality)?1.8:5.8;
			else if (this.frame == "pvc")
				this.Uw = (!this.frame_quality)?1.6:3.8;
			else if (this.frame == "wood")
				this.Uw = (!this.frame_quality)?1.5:3.5;
			this.Sw = 0;
			this.Tl = 0;
			return;
		}

		var Ug = 0;
		var Uf = 0;
		var Tl = 0;
		var g = 0;
		var PSIg = 0;
		if (this.glazing == "none")
		{
			Ug = 5.8;
			PSIg = (this.frame == "alu" || this.frame == "steel")?0.08:0.06;
			Tl = 0;
			g = 0;
		}
		else if (this.glazing == "single")
		{
			Ug = 5.8;
			PSIg = (this.frame == "alu" || this.frame == "steel")?0.08:0.06;
			Tl = 0.86;
			g = 0.90;
		}
		else if (this.glazing == "double")
		{
			PSIg = (this.frame == "alu" || this.frame == "steel")?0.08:0.06;
			if (this.glazing_gaz == "air_6")
			{
				Ug = 3.44;
				Tl = 0.76;
				g = 0.81;
			}
			else if (this.glazing_gaz == "air_8")
			{
				Ug = 3.25;
				Tl = 0.76;
				g = 0.81;
			}
			else if (this.glazing_gaz == "air_12")
			{
				Ug = 2.46;
				Tl = 0.72;
				g = 0.75;
			}
			else if (this.glazing_gaz == "air_16")
			{
				Ug = 1.40;
				Tl = 0.62;
				g = 0.65;
			}
			else if (this.glazing_gaz == "argon_16")
			{
				Ug = 1.1;
				Tl = 0.61;
				g = 0.65;
			}
		}
		else if (this.glazing == "triple")
		{
			Ug = 0.7;
			PSIg = (this.frame == "alu" || this.frame == "steel")?0.06:0.05;
			Tl = 0.5;
			g = 0.65;
		}

		if (this.frame == "alu" || this.frame == "steel")
		{
			Uf = (this.frame_quality)?3:5.9;
		}
		else if (this.frame == "pvc")
		{
			Uf = (this.frame_quality)?1.6:2.8;
		}
		else if (this.frame == "wood")
		{
			Uf = (this.frame_quality)?1.2:2.2;
		}

		this.Uw = (this.glazing_area * Ug + this.opaque_area * Ug + this.frame_area * Uf + this.glazing_frame_linear * PSIg + this.opaque_frame_linear * PSIg) / this.full_area;
		this.Sw = g * this.glazing_area / this.full_area;
		this.Tl = Tl * this.glazing_area / this.full_area;
	}

	//***********************************************************************************
	//**** 3D geometry
	//***********************************************************************************
	build_extruded_polygons() {
		this.glazing_area = 0;
		this.opaque_area = 0;
		this.frame_area = 0;
		this.full_area = 0;
		this.glazing_frame_linear = 0;
		this.opaque_frame_linear = 0;
		var extruded_polygons = [];
		if (this.free) return extruded_polygons;

		var obj = this;
		function add_panel(x,y,z,dx,dy,dz,ex,eiz,f_color,i_color) {
			if (i_color == null)
			{
				extruded_polygons.push(fh_extruded_polygon.build_brick([x,y,z],dx,dy,dz,f_color));
				return;
			}

			//*** panel frame
			var pg = new fh_polygon([x,y,z],[0,0,1]);
			var contour = [];
			contour.push([x,y,z]);
			contour.push([x,y+dy,z]);
			contour.push([x+dx,y+dy,z]);
			contour.push([x+dx,y,z]);
			pg.add_contour(contour);
			contour = [];
			contour.push([x+ex,y+ex,z]);
			contour.push([x+dx-ex,y+ex,z]);
			contour.push([x+dx-ex,y+dy-ex,z]);
			contour.push([x+ex,y+dy-ex,z]);
			pg.add_contour(contour);
			pg.compute_contours();
			extruded_polygons.push(fh_extruded_polygon.build_extrusion(pg,[0,0,dz],f_color));
			obj.frame_area += pg.get_area();

			//*** Filling
			if (dx > 2*ex && dy > 2*ex)
			{
				extruded_polygons.push(fh_extruded_polygon.build_brick([x + ex,y + ex,z + 0.5 * (dz - eiz)],
																		dx - 2*ex,
																		dy - 2*ex,
																		eiz,
																		i_color));

				if (i_color[3]<0.5)
				{
					obj.glazing_area += (dx - 2*ex) * (dy - 2*ex);
					obj.glazing_frame_linear += 2 * (dx - 2*ex) + 2 * (dy - 2*ex);
				}
				else
				{
					obj.opaque_area += (dx - 2*ex) * (dy - 2*ex);
					obj.opaque_frame_linear += 2 * (dx - 2*ex) + 2 * (dy - 2*ex);
				}
			}
		}

		var frame_color = [1,1,1,1];
		if (this.frame == "alu") frame_color = [0.4,0.4,0.4,1];
		if (this.frame == "pvc") frame_color = [0.8,0.8,0.8,1];
		if (this.frame == "wood") frame_color = [0.7,0.6,0.5,1];
		if (this.frame == "steel") frame_color = [0.2,0.2,0.2,1];

		var filling_color = [1,1,1,0.2];
		if (this.glazing == "none") filling_color = frame_color;

		var sill_height = (this.sill=="none")?0:this.sill_height;
		var transom_height = (this.transom=="none")?0:this.transom_height;
		var window_height = this.height - sill_height - transom_height


		//*** draw frame
		var frame_width = 0.03;
		var frame_thickness = 0.06;
		var bottom_frame = (this.category == "window" || this.opening == "sliding");
		extruded_polygons.push(fh_extruded_polygon.build_brick([0,sill_height,0],frame_width,window_height,frame_thickness,frame_color));
		extruded_polygons.push(fh_extruded_polygon.build_brick([this.width - frame_width,sill_height,0],frame_width,window_height,frame_thickness,frame_color));
		extruded_polygons.push(fh_extruded_polygon.build_brick([frame_width,sill_height + window_height - frame_width,0],this.width - 2 * frame_width,frame_width,frame_thickness,frame_color));
		if (bottom_frame)
			extruded_polygons.push(fh_extruded_polygon.build_brick([frame_width,sill_height,0],this.width - 2 * frame_width,frame_width,frame_thickness,frame_color));
		const nb_h_frames = (bottom_frame)?2:1;
		this.frame_area += window_height * this.width - (window_height - nb_h_frames * frame_width) * (this.width - 2 * frame_width);

		//*** draw panels
		var available_width = this.width - 2 * frame_width;
		var available_height = window_height - 2 * frame_width;
		if (!bottom_frame) available_height += frame_width;
		var nb_panels = this.panels;
		var panel_frame_width = 0.03;
		var panel_frame_thickness = 0.03;
		var panel_filling_thickness = 0.015;

		var panel_width = (this.opening == "vertical")?available_width:available_width/nb_panels;
		var panel_height = (this.opening == "vertical")?available_height/nb_panels:available_height;
		var y_panel = (bottom_frame)?frame_width:0;
		y_panel += sill_height

		var obj = this;
		if (nb_panels == 1)
		{
			add_panel(frame_width,y_panel,0.5*(frame_thickness - panel_frame_thickness),
					panel_width,panel_height,panel_frame_thickness,
					panel_frame_thickness,panel_filling_thickness,
					frame_color,filling_color);
		}
		else
		{
			if (this.opening == "vertical")
			{
				var panel_sizes = this.compute_panel_widths(available_height).reverse();
				var y = y_panel;
				for (var n=0;n<nb_panels;n++)
				{
					add_panel(frame_width,y,0.5*(frame_thickness - panel_frame_thickness),
							panel_width,panel_sizes[n],panel_frame_thickness,
							panel_frame_thickness,panel_filling_thickness,
							frame_color,filling_color);
					y += panel_sizes[n];
				}
			}
			else
			{
				var panel_sizes = this.compute_panel_widths(available_width);
				var x = frame_width;
				for (var n=0;n<nb_panels;n++)
				{
					add_panel(x,y_panel,0.5*(frame_thickness - panel_frame_thickness),
							panel_sizes[n],panel_height,panel_frame_thickness,
							panel_frame_thickness,panel_filling_thickness,
							frame_color,filling_color);
					x += panel_sizes[n];
				}
			}
		}

		if (this.sill != "none")
		{
			add_panel(0,0,0,
					this.width,sill_height,frame_thickness,
					frame_width,panel_filling_thickness,
					frame_color,(this.sill=="opaque")?frame_color:filling_color);
		}

		if (this.transom != "none")
		{
			var pg_out = new fh_polygon([0,0,0],[0,0,1]);
			var contour = [];
			var x0 = 0;
			var x1 = x0+this.width;
			var y0= window_height + sill_height;
			var y1 = y0 + this.transom_height;

			contour.push([x1,y0,0]);
			contour.push([x0,y0,0]);
			if (this.transom == "rectangle")
			{
				contour.push([x0,y1,0]);
				contour.push([x1,y1,0]);
			}
			else if (this.transom == "triangle")
			{
				contour.push([x0,(y0+y1)*0.5,0]);
				contour.push([(x0+x1)*0.5,y1,0]);
				contour.push([x1,(y0+y1)*0.5,0]);
			}
			else if (this.transom == "round")
			{
				var center = [(x0+x1)*0.5,y0,0];
				var dx = [-this.width*0.5,0,0];
				var dy = [0,this.transom_height,0];
				for (var theta = 0; theta < 180; theta +=5)
				{
					var angle = theta * Math.PI/180;
					var cc = Math.cos(angle);
					var ss = Math.sin(angle);
					contour.push(fh_add(center,fh_add(fh_mul(dx,cc),fh_mul(dy,ss))));
				}
			}
			pg_out.add_contour(contour);
			pg_out.compute_contours();
			var pg_in = pg_out.clone();
			pg_in.offset(-frame_width);
			pg_out.substracts(pg_in);
			extruded_polygons.push(fh_extruded_polygon.build_extrusion(pg_out,[0,0,frame_thickness],frame_color));
			this.frame_area += pg_out.get_area();

			pg_in.project([0,0,0.5*(frame_thickness-panel_filling_thickness)],[0,0,1],[0,0,1]);
			extruded_polygons.push(fh_extruded_polygon.build_extrusion(pg_in,[0,0,panel_filling_thickness],filling_color));

			this.glazing_area += pg_in.get_area();
			this.glazing_frame_linear += pg_in.get_perimeter();
		}

		this.full_area = this.glazing_area + this.frame_area + this.opaque_area;

		return extruded_polygons;
	}

	//***********************************************************************************
	//**** Get configuration
	//***********************************************************************************
	static configuration(category) {
		var configuration = new cn_configuration();

		//*** Global params
		var param_group = new cn_configuration_param_group("Dimensions","dimensions");
		configuration.add_param_group(param_group);
		param_group.add_param(new cn_configuration_param("Largeur","width",1,1000,"cm"));
		param_group.add_param(new cn_configuration_param("Hauteur","height",1,1000,"cm"));
		param_group.add_param(new cn_configuration_param("Décalage","z",0,1000,"cm"));

		param_group = new cn_configuration_param_group("Thermique","thermics",true);
		configuration.add_param_group(param_group);
		param_group.add_param(new cn_configuration_param("Uw","Uw",0,10,"W/m²/K",3,0.1));
		param_group.add_param(new cn_configuration_param("Facteur solaire","Sw",0,100,"%",1,0.1));
		param_group.add_param(new cn_configuration_param("Transmission","Tl",0,100,"%",1,0.1));

		//*** Standard tab
		var tab = new cn_configuration_tab("Baie","standard");
		configuration.add_tab(tab);

		//***********************************************
		//*** Frame
		var frame_list = new cn_configuration_line("Menuiserie","frame");
		tab.add_line(frame_list);

		FRAME_LIST.forEach(fl => {
			frame_list.add_choice(new cn_configuration_choice(fl.label, fl.code));
		})
		frame_list.add_param(cn_configuration_param.choice_list("Type","frame_quality",["ancien","récent"]));

		//***********************************************
		//*** Glazing
		var glazing_list = new cn_configuration_line("Vitrage","glazing");
		tab.add_line(glazing_list);

		if (category == "door")
			glazing_list.add_choice(new cn_configuration_choice("Opaque","none"));
		glazing_list.add_choice(new cn_configuration_choice("Simple","single"));
		glazing_list.add_choice(new cn_configuration_choice("Double","double"));
		glazing_list.add_choice(new cn_configuration_choice("Triple","triple"));
		glazing_list.add_param(cn_configuration_param.choice_list("Lame d'air","glazing_gaz",GLAZING_GAZ_LIST.map(g => g.label),3));

		//***********************************************
		//*** Opening
		var opening_list = new cn_configuration_line("Ouverture","opening");
		tab.add_line(opening_list);

		if (category == "window")
			opening_list.add_choice(new cn_configuration_choice("Aucune","none"));
		opening_list.add_choice(new cn_configuration_choice("Glissante","sliding"));
		opening_list.add_choice(new cn_configuration_choice("Battante","french"));
		if (category == "window")
			opening_list.add_choice(new cn_configuration_choice("Verticale","vertical"));
		if (category == "window")
			opening_list.add_param(cn_configuration_param.choice_list("Type","vertical_opening",VERTICAL_OPENING_LIST.map(g => g.label),0));

		//***********************************************
		//*** panels
		var panel_list = new cn_configuration_line("Panneaux","panels");
		tab.add_line(panel_list);

		panel_list.add_choice(new cn_configuration_choice("1","1"));
		panel_list.add_choice(new cn_configuration_choice("2","2"));
		panel_list.add_choice(new cn_configuration_choice("3","3"));
		panel_list.add_param(new cn_configuration_param("Panneau 1","panel_1",1,1000,"cm",1,0.5));
		panel_list.add_param(new cn_configuration_param("Panneau 2","panel_2",1,1000,"cm",1,0.5));

		//***********************************************
		//*** transom
		var transom_list = new cn_configuration_line("Imposte","transom");
		tab.add_line(transom_list);
		transom_list.add_param(new cn_configuration_param("Hauteur","transom_height",1,200,"cm",1,50));

		for (var i in TRANSOM_LIST)
			transom_list.add_choice(new cn_configuration_choice(TRANSOM_LIST[i].label,TRANSOM_LIST[i].code));

		//***********************************************
		//*** sill
		if (category == "window")
		{
			var sill_list = new cn_configuration_line("Allège","sill");
			tab.add_line(sill_list);
			sill_list.add_param(new cn_configuration_param("Hauteur","sill_height",1,200,"cm",1,50));

			for (var i in SILL_LIST)
				sill_list.add_choice(new cn_configuration_choice(SILL_LIST[i].label,SILL_LIST[i].code));

			//***********************************************
			//*** closing
			var closing_list = new cn_configuration_line("Fermeture","closing");
			tab.add_line(closing_list);

			for (var i in CLOSING_LIST)
				closing_list.add_choice(new cn_configuration_choice(CLOSING_LIST[i].label,CLOSING_LIST[i].code));
		}

		//***********************************************
		//*** Free openings
		//***********************************************
		var free_tab = new cn_configuration_tab("Baie libre","free");
		configuration.add_tab(free_tab);

		//***********************************************
		//*** Frame
		var free_tab_list = new cn_configuration_line("","");
		free_tab.add_line(free_tab_list);

		free_tab_list.add_choice(new cn_configuration_choice("",""));

		return configuration
	}

	//***********************************************************************************
	//**** Fill configuration
	//***********************************************************************************
	fill_configuration(config = null)
	{
		var configuration = (config)?config:cn_opening_type.configuration(this.category);

		var param_group = configuration.get_param_group("dimensions");
		param_group.set_param("width",this.width*100);
		param_group.set_param("height",this.height*100);
		param_group.set_param("z",this.z*100);

		this.compute_physics();
		param_group = configuration.get_param_group("thermics");
		param_group.checked = this.user_physics;
		param_group.set_param("Uw",this.Uw);
		param_group.set_param("Sw",this.Sw*100);
		param_group.set_param("Tl",this.Tl*100);

		if (this.free)
		{
			param_group.visible = false;
			configuration.set_selection("free");
			return configuration;
		}

		param_group.visible = true;

		var frame_line = configuration.tabs[0].get_line("frame");
		frame_line.set_selection(this.frame);
		frame_line.set_param("frame_quality",(this.frame_quality)?1:0);

		var glazing_line = configuration.tabs[0].get_line("glazing");
		glazing_line.set_selection(this.glazing);
		glazing_line.set_param("glazing_gaz",GLAZING_GAZ_LIST.map(g => g.code).indexOf(this.glazing_gaz),this.glazing == "double");

		var opening_line = configuration.tabs[0].get_line("opening");
		opening_line.set_selection(this.opening);
		opening_line.set_param("vertical_opening",VERTICAL_OPENING_LIST.map(g => g.code).indexOf(this.vertical_opening),this.opening == "vertical");

		var panels_line = configuration.tabs[0].get_line("panels");
		panels_line.set_selection("" + this.panels);
		if (this.panels >1)
		{
			const full_width = (this.opening == "vertical")?this.height:this.width;
			panels_line.full_width = full_width;
			panels_line.get_param("panel_1").visible = true;
			var x = (this.panel_widths.length > 0)?this.panel_widths[0]*full_width:full_width/this.panels;
			panels_line.set_param("panel_1",x*100);
			if (this.panels > 2)
			{
				panels_line.get_param("panel_2").visible = true;
				var x = (this.panel_widths.length > 1)?this.panel_widths[1]*full_width:full_width/this.panels;
				panels_line.set_param("panel_2",x*100);
			}
			else
			panels_line.get_param("panel_2").visible = false;
		}
		else
		{
			panels_line.get_param("panel_1").visible = false;
			panels_line.get_param("panel_2").visible = false;
		}

		var transom_line = configuration.tabs[0].get_line("transom");
		transom_line.set_selection(this.transom);
		transom_line.set_param("transom_height",this.transom_height*100,(this.transom != "none"));

		if (this.category == "window")
		{
			var sill_line = configuration.tabs[0].get_line("sill");
			sill_line.set_selection(this.sill);
			sill_line.set_param("sill_height",this.sill_height*100,(this.sill != "none"));

			var closing_line = configuration.tabs[0].get_line("closing");
			closing_line.set_selection(this.closing);
		}

		return configuration;
	}
	//***********************************************************************************
	//**** Load configuration
	//***********************************************************************************
	load_configuration(configuration)
	{
		var param_group = configuration.get_param_group("dimensions");
		this.width = param_group.get_param("width").value / 100;
		this.height = param_group.get_param("height").value / 100;
		this.z = param_group.get_param("z").value / 100;

		if (configuration.get_selection() == "free")
		{
			configuration.get_param_group("thermics").visible = false;
			this.free = true;
			return true;
		}

		param_group = configuration.get_param_group("thermics");
		param_group.visible = true;
		this.user_physics = param_group.checked;
		if (this.user_physics)
		{
			this.Uw = param_group.get_param("Uw").value;
			this.Sw = param_group.get_param("Sw").value / 100;
			this.Tl = param_group.get_param("Tl").value / 100;
		}
		else
			this.compute_physics();

		this.free = false;
		var configuration_tab = configuration.tabs[0];

		//*** read frame */
		this.frame = configuration_tab.get_line("frame").get_selection();
		this.frame_quality = (configuration_tab.get_line("frame").get_param("frame_quality").value == 1);

		//*** read glazing */
		this.glazing = configuration_tab.get_line("glazing").get_selection();
		if (this.glazing == "double")
			this.glazing_gaz = GLAZING_GAZ_LIST.map(g => g.code)[configuration_tab.get_line("glazing").get_param("glazing_gaz").value];

		//*** read opening */
		this.opening = configuration_tab.get_line("opening").get_selection();
		if (this.opening == "vertical")
			this.vertical_opening = VERTICAL_OPENING_LIST[configuration_tab.get_line("opening").get_param("vertical_opening").value].code;

		const line_panels = configuration_tab.get_line("panels");
		this.panels = parseInt(line_panels.get_selection());
		if (this.panels == 1)
		{
			this.panel_widths = [];
		}
		else
		{
			const param_panel_1 = line_panels.get_param("panel_1");
			const param_panel_2 = line_panels.get_param("panel_2");
			if (this.panels == 2)
			{
				if (param_panel_1.visible && !param_panel_2.visible)
					this.panel_widths = [0.01 * param_panel_1.value/line_panels.full_width];
				else
					this.panel_widths = [1/2];
			}
			else if (this.panels == 3)
			{
				if (param_panel_1.visible && param_panel_2.visible)
					this.panel_widths = [0.01 * param_panel_1.value/line_panels.full_width,0.01 * param_panel_2.value/line_panels.full_width];
				else
					this.panel_widths = [1/3,1/3];
			}
		}

		this.transom = configuration_tab.get_line("transom").get_selection();
		if (this.transom != "none")
			this.transom_height = 0.01 * configuration_tab.get_line("transom").get_param("transom_height").value;
		if (this.category == "window")
		{
			this.sill = configuration_tab.get_line("sill").get_selection();
			if (this.sill != "none")
				this.sill_height = 0.01 * configuration_tab.get_line("sill").get_param("sill_height").value;
			this.closing = configuration_tab.get_line("closing").get_selection();
		}

		var transom_height =  (this.transom == "none")?0:this.transom_height;
		var sill_height =  (this.sill == "none")?0:this.sill_height;
		if (transom_height + sill_height > this.height)
		{
			if (this.transom != "none")
				this.transom_height = transom_height * this.height / (transom_height + sill_height);
			if (this.sill != "none")
				this.sill_height = sill_height * this.height / (transom_height + sill_height);
		}

		if (!this.user_physics)
		{
			this.compute_physics();
			param_group = configuration.get_param_group("thermics");
			param_group.checked = this.user_physics;
			param_group.set_param("Uw",this.Uw);
			param_group.set_param("Sw",this.Sw*100);
			param_group.set_param("Tl",this.Tl*100);
		}
		return true;
	}

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
     accept_visitor(element_type_visitor) {
        element_type_visitor.visit_opening_type(this);
    }
}


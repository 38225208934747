import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DiagnosticHandlerService } from './diagnostic-handler.service';
import { DiagnosticService } from './diagnostic.service';
import { debounceTime, filter, map, shareReplay, withLatestFrom } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProgressDiagnosticService {
    private refreshProgress$ = new BehaviorSubject<boolean>(false);

    readonly progress$: Observable<number> = this.refreshProgress$.pipe(
        filter((refresh) => !!refresh),
        debounceTime(100),
        withLatestFrom(this.diagnosticService.getCurrentDiagnostic(false)),
        map(([_, diagnostic]) => {
            let progress = undefined;
            if (diagnostic) {
                const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(
                    diagnostic.typePrestation
                );

                if (typePrestationService) {
                    progress = typePrestationService.getCompletionPercentage(diagnostic);
                }
            }
            return progress;
        }),
        shareReplay()
    );

    constructor(
        private readonly diagnosticService: DiagnosticService,
        private readonly diagnosticHandlerService: DiagnosticHandlerService
    ) {}

    /**
     * Premet de demander un rafraichissement de la barre de progression des diagnostics
     */
    refresh() {
        this.refreshProgress$.next(true);
    }
}

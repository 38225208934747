"use strict";
//***********************************************************************************
//***********************************************************************************
//**** A tool to edit slab openings
//***********************************************************************************
//***********************************************************************************

import {cn_add, cn_clone} from "../utils/cn_utilities";
import {cn_svg_tool_edition} from "./cn_svg_tool_edition";
import {cn_slab_opening} from "../model/cn_slab_opening";
import {cn_slab_opening_handler} from "./cn_slab_opening_handler";

export class cn_svg_tool_slab_opening_edition extends cn_svg_tool_edition {
	constructor(map) {
		super(map);

		this._handler = null;
	}

	//***********************************************************************************
	//**** Selection callback
	//***********************************************************************************
	on_selection_change() {
		const obj = this;
		this._handlers = [];
		
		//*** We expect the selection to contain only openings */
		var sel = this._controller.get_selection();
		if (sel.length != 1 || sel.some(elt => elt.constructor != cn_slab_opening)) 
			return false;

		this._handlers.push(new cn_slab_opening_handler(sel,this._map));
		return true;
		
	}

	//***********************************************************************************
	//**** Group translation
	//***********************************************************************************
	translate(ev, offset) {
		var selection = this._controller.get_selection();
		for (var i in selection)
		{
			var elt = selection[i];
			if (elt.constructor != cn_slab_opening) continue;
			this.push_item_set(elt.contours[0],"vertices");
			var vertices = elt.contours[0].vertices;
			for (var k in vertices)
			{
				vertices[k].position = cn_add(vertices[k].position,offset);
			}
			elt.update();
		}
	}
}


"use strict";
import { cn_zpso } from '..';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_zpso  : ZPSO Manager
//***********************************************************************************
//***********************************************************************************

import { cn_svg_map } from "./cn_svg_map";
import { cn_svg_tool_layer } from './cn_svg_tool_layer';

/**
 */
export class cn_svg_tool_zpso extends cn_svg_tool_layer {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_svg_map} svg_map
	 * @param {boolean} roofs : true if we are dealing with roof slabs
	 */
	constructor(svg_map, roofs) {
		super(svg_map, roofs);
        this._type_wording = 'une ZPSO';
        this.all_support_rendering = true;
	}

    set_all_support_rendering(value) {
        this.all_support_rendering = value;
        if (!this.all_support_rendering) {
            this._scene.draw_zpso_support = this.support;
            this.clear_move();
            this._edition_handler = null;
        } else {
            this._scene.draw_zpso_support = '';
        }
    }

    /**
     * Set tool support : wall, floor or ceil
     *
     * @param {string} support
     */
     set_support(support) {
        this.support = support
        if (!this.all_support_rendering) {
            this._scene.draw_zpso_support = this.support;
            this.clear_move();
            this._edition_handler = null;
        }
    }

    /**
     * Create new zpso
     *
     * @param {string} name
     * @param {string} color
     * @param {string} stripes
     * @returns {cn_zpso}
     */
    create(name, color, stripes) {
        const result = this._scene.building.add_zpso(name, color, stripes);
        if (result && result.ID) {
            this.push_transaction('Modification d\'une zpso');
            this.push_item_set(this._scene.building, 'zpsos');
        }
        return result
    }

    /**
     * Delete list of zpsos
     *
     * @param {cn_zpso[]} zpsos
     */
    delete(zpsos) {
        this.push_transaction('Suppression de zpso');
        this.push_item_set(this._scene.building, 'zpsos');
        zpsos.forEach(zpso => {
            if (this.current_layer && zpso.ID === this.current_layer.ID) {
                this.unset_current_layer();
            }
            const index = this._scene.building.zpsos.findIndex(z => z.ID === zpso.ID);
            if (index >= 0) {
                this._scene.building.zpsos.splice(index, 1);
            }
        });
    }

    _find_line_mouseover(ev) {
        return this._scene.building.zpsos.reduce((a, b) => a.concat(b.lines.map(line => { return { line: line, layer: b }; })), [])
            .filter(agg => agg.line.storey === this._scene.storey.ID)
            .find(agg => agg.line.shape.contains(ev.mouse_world, ev.camera.snap_world_distance));
    }

    _find_section_mouseover(ev) {
        return this._scene.building.zpsos.reduce((a, b) => a.concat(b.sections.map(section => { return { section: section, layer: b }; })), [])
            .filter(agg => agg.section.storey === this._scene.storey.ID)
            .find(agg => agg.section.shape.contains(ev.mouse_world, ev.camera.snap_world_distance));
    }

    _search_element_on_wall_support(element, position, tolerance) {
        [
            this._scene.find_object_instance,
            this._scene.find_beam,
            this._scene.find_opening,
            this._scene.find_column,
            this._scene.find_stairs,
            this._scene.find_pipe,
            this._scene.find_wall
        ].forEach(searchFunction => {
            if (!element) {
                const el = searchFunction.apply(this._scene, [position, tolerance]);
                if (el) {
                    element = { el: el, support: 'wall' };
                }
            }
        });
        return element;
    }


}


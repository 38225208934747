"use strict";

import { cn_space } from '../model/cn_space';
import { cn_storey } from '../model/cn_storey';
import { cn_zone } from '../model/cn_zone';
import { cn_pastille } from '../svg/cn_pastille';
import { cn_svg_map } from '../svg/cn_svg_map';
import { extension_instance } from './cn_extension';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** abstract feature extension (superclass for element's extension)
//***********************************************************************************
//***********************************************************************************

export class cn_extension_feature {

    constructor(is_registered = false) {
        if (is_registered) {
            extension_instance.features.push(this);
        }
    }

    //*****************************************************************
	/**
	 * Trigger when storey is duplicated
     * @param { cn_storey } old_storey
     * @param { cn_storey } new_storey
     * @returns { void }
	 */
    on_duplicate_storey(old_storey, new_storey) {
    }

}



<ng-container *ngFor="let screenshot of screenshotsSvg">
    <mat-card class="w-100 position-relative p-0 mb-2 svg-card">
        <mat-card-content class="w-100 h-100 d-flex align-items-center">
            <mat-checkbox
                *ngIf="enableSelection"
                [checked]="selectedScreenshotsIds | includes: screenshot.id"
                (change)="changeSelection($event.checked, screenshot)"
            ></mat-checkbox>
            <div class="align-items-center w-100 h-100">
                <div *ngIf="showActionbutton" class="position-absolute button-group">
                    <button
                        mat-icon-button
                        color="primary"
                        matTooltip="Modifier le screenshot"
                        (click)="onClickEditScreenshot(screenshot)"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        color="warn"
                        matTooltip="Supprimer le screenshot"
                        (click)="onClickDeleteScreenshot(screenshot)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div class="d-flex flex-row p-3 w-100 h-100">
                    <div class="svg-preview w-50 h-100" [innerHTML]="screenshot.jsonPlan | safeHtml"></div>
                    <div class="card-title w-50 pt-4 pr-4">{{ screenshot.nom }}</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</ng-container>

<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span>{{ title }}</span>
    <button mat-button (click)="cancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content>
    <div class="d-flex">
        <div class="w-100">
            <mat-form-field [ngSwitch]="data?.typeAssociation">
                <mat-label *ngSwitchCase="'VOLUME'">Pièce</mat-label>
                <mat-label *ngSwitchCase="'NIVEAU'">Niveau</mat-label>
                <input [readonly]="true" matInput [value]="data?.sourceElement?.nom" />
            </mat-form-field>
        </div>
    </div>
    <div class="d-flex">
        <div class="w-100">
            <mat-form-field class="w-100">
                <mat-label *ngIf="data?.typeAssociation === 'VOLUME'">Niveau ou pièce cible</mat-label>
                <mat-label *ngIf="data?.typeAssociation === 'NIVEAU'">Niveau cible</mat-label>
                <mat-select [(value)]="selection">
                    <mat-option *ngFor="let item of niveauxEtPieces" [value]="item">
                        <span [class.ml-2]="!!item.volume"
                            ><b>{{ item.nom }}</b></span
                        >
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" [disabled]="!selection" (click)="confirm()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>

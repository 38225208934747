import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Volume } from 'src/app/model/bien.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { PolluantConfig } from '../../../../model/polluant-config.model';
import { PolluantService } from '../../../../services/polluant.service';
import { Zone } from '../../../../model/zone.model';
import * as moment from 'moment';
import { MpcaItemOld, MpcaProcessus } from '../../../../model/mpca.model.old';
import { calculateValues } from '../../listeMPCA/mpca-descriptif/mpca-form-echantillonnage/mpca-form-echantillonnage.component';
import { getRefInfo } from '../../initialisation/objectifs/objectifs.component';
import { FrequenceService } from '../../../../services/frequence.service';

@Component({
    selector: 'app-informations-besoin',
    templateUrl: './informations-besoin.component.html',
    styleUrls: ['./informations-besoin.component.scss'],
})
export class InformationsBesoinComponent implements OnInit, OnChanges {
    constructor(public PolluantSvc: PolluantService, private frequenceService: FrequenceService) {}

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;
    private _formCurrentBesoin: FormGroup;

    @Input() mpcaList: MpcaItemOld[];
    @Input() set formCurrentBesoin(formBesoin: FormGroup) {
        this._formCurrentBesoin = formBesoin;
        this.disableForm();
        formBesoin.get('objectifMesurage').valueChanges.subscribe(() => {
            //A chaque changement sur l'objectif de mesurage
            //on réévalue si certains champs doivent être affichés ou non
            this.checkFieldsVisibility();
        });

        formBesoin.get('processusId').valueChanges.subscribe(() => {
            //Calcul de la durée min de prélèvement
            // en fonction du processus choisi
            this.calculDureeMinPrelevement();
        });
    }
    get formCurrentBesoin() {
        return this._formCurrentBesoin;
    }
    @Input() selectedZones: Zone[];
    @Input() polluantConfig: PolluantConfig;
    @Input() showConditionPrelevement: boolean;
    @Input() set isInEditMode(val: boolean) {
        this._isInEditMode = val;
        this.disableForm();
    }
    get isInEditMode(): boolean {
        return this._isInEditMode;
    }
    @Input() availableVolumesIdInZone: Array<any>; //string
    @Input() volumes: Array<Volume>;

    showProcessus: boolean = false;
    showContexteMesure: boolean = false;
    showDetailsLocalisation: boolean = true;
    showEmplacement: boolean = true;
    contextesMesure: Array<any> = [];

    computedForm: FormGroup;

    private _isInEditMode: boolean = true;
    private disableForm() {
        if (!this.isInEditMode && this.formCurrentBesoin) {
            this.formCurrentBesoin.disable();
        }
    }
    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        this.computedForm = new FormGroup({
            schemaPrelevement: new FormControl({ value: '', disabled: true }),
            debitVolumePreleve: new FormControl({ value: '', disabled: true }),
            schemaAnalyse: new FormControl({ value: '', disabled: true }),
        });
        this.checkFieldsVisibility();
    }

    /**
     * Vérifie la visibilité des champs en fonction du besoin sélectionné
     */
    checkFieldsVisibility() {
        this.evaluateShowProcessusEtContexteMesure();
        this.evaluateShowConditionPrelevement();
        this.evaluateShowEmplacement();
        this.calculDureeMinPrelevement();
        this.evaluateShowDetailsLocalisation();
        this.getFrequenceObj();
    }

    //Affiche, rend obligatoire et peuple le champ Condition du Prlèvement
    // en fonction du type d'obj de mesurage sélectionné
    evaluateShowConditionPrelevement() {
        const refSelectedObj = getRefInfo(
            this.polluantConfig.objectifsMesurage,
            this.formCurrentBesoin.get('objectifMesurage').value?.reference
        );

        if (!refSelectedObj) return;

        //Pour les besoins A, B, C, D, E, F, G, T, U, V, W, X, Y Il faut ajouter un champ "Condition du prélèvement".
        if (!refSelectedObj.contextTravaux) {
            this.formCurrentBesoin.get('conditionPrelevement').setValidators([Validators.required]);
            this.showConditionPrelevement = true;
            return;
        } else {
            this.formCurrentBesoin.get('conditionPrelevement').clearValidators();
            this.formCurrentBesoin.get('conditionPrelevement').setErrors(null);
            this.formCurrentBesoin.get('conditionPrelevement').setValue('');
            this.showConditionPrelevement = false;
        }
    }

    //Affiche, rend obligatoire et peuple le champ Condition du Prlèvement
    // en fonction du type d'obj de mesurage sélectionné
    evaluateShowDetailsLocalisation() {
        const refSelectedObj = getRefInfo(
            this.polluantConfig.objectifsMesurage,
            this.formCurrentBesoin.get('objectifMesurage').value?.reference
        );
        // I J K: POLL-COR-105
        if (!refSelectedObj || refSelectedObj.emplacement != '') {
            this.showDetailsLocalisation = false;
            this.formCurrentBesoin.get('justificatifLocalisation').clearValidators();
            this.formCurrentBesoin.get('precisionLocalisation').clearValidators();
            return;
        } else {
            this.showDetailsLocalisation = true;
            this.formCurrentBesoin.get('justificatifLocalisation').setValidators([Validators.required]);

            if (refSelectedObj.precisionLocalisation != '') {
                this.formCurrentBesoin.get('precisionLocalisation').setValue(refSelectedObj.precisionLocalisation);
            }
        }
    }

    getFrequenceObj() {
        const selectedObj = this.formCurrentBesoin.get('objectifMesurage').value;
        let refInfo = getRefInfo(this.polluantConfig.objectifsMesurage, selectedObj?.reference);
        if (refInfo != undefined) {
            if (refInfo.calculAutoFrequence) {
                let zoneCurrent = this.selectedZones.find((z) => z.id === this.formCurrentBesoin.controls.zoneId.value);
                let zoneDeCalcul;
                if (zoneCurrent.zoneAssociee != null) {
                    zoneDeCalcul = zoneCurrent.zoneAssociee;
                } else {
                    zoneDeCalcul = zoneCurrent;
                }
                this.formCurrentBesoin
                    .get('frequencePrelevement')
                    .setValue(this.frequenceService.determineFrequenceMinimale(zoneDeCalcul));
            } else this.formCurrentBesoin.get('frequencePrelevement').setValue(refInfo.frequence);
        }
    }

    //Affiche et rend obligatoire le champ Processus
    // si le type de l'objectif de mesurage est METOP
    evaluateShowProcessusEtContexteMesure() {
        //Ref I, J ou K
        const refInfo = getRefInfo(
            this.polluantConfig.objectifsMesurage,
            this.formCurrentBesoin.get('objectifMesurage').value?.reference
        );
        if (refInfo != undefined && refInfo.norme == 'NF X43-269') {
            this.formCurrentBesoin.get('processusId').setValidators([Validators.required]);
            this.formCurrentBesoin.get('contexteMesure').setValidators([Validators.required]);
            this.showProcessus = true;
            this.showContexteMesure = true;

            this.contextesMesure = this.polluantConfig.contenuListeDeroulante.contextesMesure.items.filter(
                (item: any) => {
                    return (
                        item.references.indexOf(this.formCurrentBesoin.get('objectifMesurage').value?.reference) != -1
                    );
                }
            );

            return;
        } else {
            this.formCurrentBesoin.get('processusId').clearValidators();
            this.formCurrentBesoin.get('processusId').setErrors(null);
            this.formCurrentBesoin.get('processusId').setValue('');
            this.showProcessus = false;

            this.formCurrentBesoin.get('contexteMesure').clearValidators();
            this.formCurrentBesoin.get('contexteMesure').setErrors(null);
            this.formCurrentBesoin.get('contexteMesure').setValue('');
            this.showContexteMesure = false;
        }
    }

    evaluateShowEmplacement() {
        if (this.formCurrentBesoin.get('objectifMesurage').value?.reference == undefined) {
            return (this.showEmplacement = false);
        }

        //Ref I, J ou K
        const refInfo = getRefInfo(
            this.polluantConfig.objectifsMesurage,
            this.formCurrentBesoin.get('objectifMesurage').value?.reference
        );

        this.showEmplacement = refInfo?.emplacement != '';
        this.formCurrentBesoin.get('emplacement').setValue(refInfo?.emplacement);
    }

    /**
     * @description Calcul et saisi la durée min de prélèvement, si applicable
     */
    calculDureeMinPrelevement() {
        let dureeMin: string;

        if (this.formCurrentBesoin.get('objectifMesurage').value?.reference == undefined) {
            return;
        }

        const refInfo = getRefInfo(
            this.polluantConfig.objectifsMesurage,
            this.formCurrentBesoin.get('objectifMesurage').value?.reference
        );

        if (!refInfo) return;

        if (
            refInfo.norme == 'NF X43-269' &&
            this.formCurrentBesoin.get('processusId').value &&
            this.formCurrentBesoin.get('processusId').value != ''
        ) {
            //Récupération du Processus :MpcaProcessus
            let selectedProcessus: MpcaProcessus;
            (this.mpcaList || []).forEach((mpca: MpcaItemOld) => {
                mpca.processus.forEach((processus: MpcaProcessus) => {
                    if (this.formCurrentBesoin.get('processusId').value == processus.id) {
                        selectedProcessus = processus;
                        return;
                    }
                });
            });

            if (selectedProcessus) {
                const computedValues = calculateValues(selectedProcessus.echantillonnage);
                if (computedValues == undefined) {
                    dureeMin = 'Erreur';
                } else {
                    // dureeMin = Math.round(computedValues.tMin).toString() + ' minutes';
                    this.computedForm.patchValue(computedValues);
                }
            }
        } else {
            let refInfo = getRefInfo(
                this.polluantConfig.objectifsMesurage,
                this.formCurrentBesoin.get('objectifMesurage').value?.reference
            );
            if (refInfo == undefined) {
                console.error("Erreur lors de la récupération des informations de l'objectif de mesurage sélectionné");
                return;
            }

            //récupérer min dans info Obj
            dureeMin = moment.duration(refInfo.dureeMin).locale('fr').humanize();
        }

        this.formCurrentBesoin.get('dureeMinPrelevement').setValue(dureeMin);
    }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BaseComponent, FileUploaderComponent } from 'src/app/commons-lib';
import { Contact } from 'src/app/model/contact.model';
import { Document } from 'src/app/model/document.model';
import { TypeReferenceFichier } from 'src/app/model/intervention-file.model';
import { CONTACT_AC_ENVIRONNEMENT } from 'src/app/shared/constants/cndiag.constants';
import { FileUploaderOutput, FileUploaderTextConfigFile } from '../../file-uploader/wizy-file-uploader.component';

@Component({
    selector: 'app-edit-fichier',
    templateUrl: './edit-fichier.component.html',
    styleUrls: ['./edit-fichier.component.scss'],
})
export class EditFichierComponent extends BaseComponent implements OnInit {
    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;

    // Correspond au formulaire du fichier
    @Input() formFichier: FormGroup;
    // Document au document en cours d'edition
    @Input() documentToEdit: Document;
    // Correspond à l'identifiant de l'intervention en cours
    @Input() idIntervention: string;
    // Correspond aux contacts de l'intervention
    @Input() set contactsIntervention(contactsIntervention: Contact[]) {
        if (contactsIntervention) {
            this._contactsIntervention = contactsIntervention;
            this.buildListeTransmisPar();
        }
    }
    get contactsIntervention() {
        return this._contactsIntervention;
    }
    _contactsIntervention: Contact[] = [];

    @Input() updateMode: boolean;

    @Output() fileUploaderOutputChanged = new EventEmitter<FileUploaderOutput>();

    listContactTransmisPar: string[] = [];
    typeReferenceFichier = TypeReferenceFichier.FICHIER_DOCUMENT;
    fileUploaderTextConfig: FileUploaderTextConfigFile;

    constructor() {
        super();
        this.initFileUploaderTextConfig();
    }

    ngOnInit(): void {
        this.buildListeTransmisPar();
    }

    /**
     * La liste transmis Par contient l'ensemble des contacts présents de l'intervention + AC Environnement
     */
    buildListeTransmisPar() {
        this.listContactTransmisPar = this.contactsIntervention.map((contactPresentTemp) =>
            contactPresentTemp.estPersonneMorale
                ? `${contactPresentTemp.nom} - ${contactPresentTemp.siret}`
                : `${contactPresentTemp.nom}  ${contactPresentTemp.prenom}`
        );
        this.listContactTransmisPar.push(CONTACT_AC_ENVIRONNEMENT);
    }

    /**
     * Evenement déclenché lorsqu'un a été ajouté
     * @param fileUploaderOutput
     */
    onFileUploaded(fileUploaderOutput: FileUploaderOutput): void {
        // On met à jour le document avec l'id et le nom du fichier
        this.formFichier.patchValue({
            idFichier: fileUploaderOutput.interventionFile.fileId,
            nomFichier: fileUploaderOutput.fileName,
        });

        // En mode update de fichier, tout est forcément required
        if (!this.updateMode) {
            // Modifier le caratère required de la partie fichier
            this.formFichier.controls['sourceTransmission'].setValidators([Validators.required]);
            this.formFichier.controls['idFichier'].setValidators([Validators.required]);
            this.formFichier.controls['sourceTransmission'].updateValueAndValidity();
            this.formFichier.controls['idFichier'].updateValueAndValidity();
        }

        // On stocke les informations du fichier pour l'uploader manuellement
        this.fileUploaderOutputChanged.emit(fileUploaderOutput);
    }

    /**
     * Evenement déclenché lorsqu'un fichier a été supprimé
     */
    onFileDeleted(): void {
        // On supprime l'id et le nom du fichier dans le document, puis on envoie un évènement au composant parent pour prévenir que le fichier n'existe plus
        this.documentToEdit.idFichier = undefined;
        this.documentToEdit.nomFichier = undefined;

        // On met à jour le document avec l'id et le nom du fichier
        this.formFichier.patchValue({
            idFichier: '',
            nomFichier: '',
        });

        // En mode update de fichier, tout est forcément required
        if (!this.updateMode) {
            // Modifier le caratère required de la partie fichier
            this.formFichier.controls['sourceTransmission'].setValidators([]);
            this.formFichier.controls['idFichier'].setValidators([]);
            this.formFichier.controls['sourceTransmission'].updateValueAndValidity();
            this.formFichier.controls['idFichier'].updateValueAndValidity();
        }

        this.fileUploaderOutputChanged.emit(null);
    }

    onChangeSourceTransmission(sourceTransmission: string) {
        // En mode update de fichier, tout est forcément required
        if (!this.updateMode) {
            // Modifier le caratère required de la partie fichier
            if (sourceTransmission) {
                this.formFichier.controls['idFichier'].setValidators([Validators.required]);
                this.formFichier.controls['sourceTransmission'].setValidators([Validators.required]);
            } else {
                this.formFichier.controls['idFichier'].setValidators([]);
                this.formFichier.controls['sourceTransmission'].setValidators([]);
            }
            this.formFichier.controls['sourceTransmission'].updateValueAndValidity();
            this.formFichier.controls['idFichier'].updateValueAndValidity();
        }
    }

    /**
     * Initialise l'objet contenant les informations textuelles à afficher dans le composant d'upload
     * @private
     */
    private initFileUploaderTextConfig() {
        this.fileUploaderTextConfig = {
            mainText: `Ajouter le fichier`,
            deleteToolTip: `Supprimer le fichier`,
            changeToolTip: `Changer le fichier`,
            confirmDeleteDialog: `Êtes-vous sûr de vouloir supprimer ce fichier ?`,
            fileDeleted: `Le fichier a bien été supprimée`,
        };
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_scene : class that contains all data to be drawn
//***********************************************************************************
//***********************************************************************************

import { fh_polygon } from '@acenv/fh-3d-viewer';
import { cn_opening, generate_textures_per_element } from '..';
import { CN_CURRENT_DATE, cn_transaction_manager } from "../utils/cn_transaction_manager";
import { cn_add, cn_box, cn_dot, cn_mul, cn_normalize, cn_sub, cn_uuid } from "../utils/cn_utilities";
import { cn_area_trimming } from "./cn_area_trimming";
import { cn_beam } from "./cn_beam";
import { cn_column } from "./cn_column";
import { cn_contour } from "./cn_contour";
import { cn_element } from "./cn_element";
import { cn_facing_trimming } from './cn_facing_trimming';
import { cn_layer } from './cn_layer';
import { cn_object_instance } from "./cn_object_instance";
import { cn_pipe } from "./cn_pipe";
import { cn_slab_opening } from "./cn_slab_opening";
import { cn_space } from "./cn_space";
import { cn_stairs } from "./cn_stairs";
import { cn_vertex } from "./cn_vertex";
import { cn_wall } from "./cn_wall";

export class cn_scene extends cn_element{
	constructor(building) {
		super(building);
		var obj=this;
		this.removable = false;

		//*** Scene data
		this.vertices = [];
		this.walls = [];
		var outside = new cn_space(this);
		outside.outside = true;
		this.spaces = [outside];
		this.slab_openings = [];
		// this.bms = [];
		this.stairs = [];
		this.object_instances = [];
		this.beams = [];
		this.columns = [];
		this.pipes = [];
		this.area_trimmings = [];
		this.facing_trimmings = [];

		this.slabs = [];

		//*** Scene volatile data
		this.building = building;
		this.storey = null;
        this.draw_samplings = false;
        this.draw_comments = true;
        this.draw_comments_application = false;
        this.draw_height_box = true;
        this.draw_layers = [];
        this.draw_zones = [];
        this.draw_numerotation = true;
        this.draw_layer_support = '';

		//** a flag to rebuild spaces */
		this._need_rebuild_spaces = true;
	}

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize(selection = null) {
		var json = {};

		if (selection)
		{
			for (var i in this.walls)
				this.walls[i].selected = false;

			for (var i in this.slab_openings)
				this.slab_openings[i].selected = false;

            // for (var i in this.bms)
            //     this.bms[i].selected = false;

			for (var i in this.stairs)
				this.stairs[i].selected = false;

			for (var i in this.object_instances)
				this.object_instances[i].selected = false;

			for (var i in this.beams)
				this.beams[i].selected = false;

			for (var i in this.columns)
				this.columns[i].selected = false;

			for (var i in this.pipes)
				this.pipes[i].selected = false;

			for (var i in selection)
				selection[i].selected = true;
		}

		//*** In the case of a selection serialization, we store also element types and objects */
		if (selection)
		{
			json.element_types = [];
			json.objects = [];
		}

		json.ID = this.ID;
		json.vertices = [];
		var n=0;
		for (var i in this.vertices)
		{
			if (selection)
			{
				var vertex = this.vertices[i];
				var found = false;
				for (var j in vertex.walls)
				{
					if (!vertex.walls[j].selected)
						continue;
					found = true;
					break;
				}
				if (!found) continue;
			}
			this.vertices[i].s_index = n;
			json.vertices.push(this.vertices[i].serialize());
			n++;
		}
		json.walls = [];
		n=0;
		var check_selection = (selection != null);
		for (var i in this.walls)
		{
			var wall = this.walls[i];
			if (selection && !wall.selected)
				continue;
			wall.s_index = n;
			n++;
		}
		for (var i in this.walls)
		{
			var wall = this.walls[i];
			if (selection && !wall.selected)
				continue;
			json.walls.push(wall.serialize(check_selection));

			//*** Store wall and openings types */
			if (selection)
			{
				if (json.element_types.indexOf(wall.wall_type) < 0)
					json.element_types.push(wall.wall_type);
				for (var j in wall.openings)
				{
					if (!wall.openings[j].selected) continue;
					var ot = wall.openings[j].opening_type
					if (json.element_types.indexOf(ot) < 0)
						json.element_types.push(ot);
				}
			}
		}
		json.spaces = [];

		function fully_selected(space)
		{
			for (var i in space.contours)
			{
				var ctr = space.contours[i];
				for (var j in ctr.walls)
				{
					if (!ctr.walls[j].selected) return false;
				}
			}
			return true;
		}

		n=0;
		for (var i in this.spaces)
		{
			if (selection && !fully_selected(this.spaces[i])) continue;
			this.spaces[i].s_index = n;
			json.spaces.push(this.spaces[i].serialize());
		}

		json.slab_openings = [];
		n=0;
		for (var i in this.slab_openings)
		{
			if (selection && !this.slab_openings[i].selected) continue;
			this.slab_openings[i].s_index = n;
			n++;
			json.slab_openings.push(this.slab_openings[i].serialize());
		}

        // json.bms = [];
        // n=0;
        // for (var i in this.bms)
        // {
        //     if (selection && !this.bms[i].selected) continue;
        //     this.bms[i].s_index = n;
        //     n++;
        //     json.bms.push(this.bms[i].serialize());
        // }

		json.stairs = [];
		n=0;
		for (var i in this.stairs)
		{
			if (selection && !this.stairs[i].selected) continue;
			this.stairs[i].s_index = n;
			n++;
			json.stairs.push(this.stairs[i].serialize());
		}

		json.object_instances = [];
		n=0;
		for (var i in this.object_instances)
		{
			if (selection && !this.object_instances[i].selected) continue;
			this.object_instances[i].s_index = n;
			n++;
			json.object_instances.push(this.object_instances[i].serialize());
			if (selection && json.objects.indexOf(this.object_instances[i].object) < 0)
				json.objects.push(this.object_instances[i].object);
		}

		json.beams = [];
		n=0;
		for (var i in this.beams)
		{
			if (selection && !this.beams[i].selected) continue;
			this.beams[i].s_index = n;
			n++;
			json.beams.push(this.beams[i].serialize());
			if (selection && json.element_types.indexOf(this.beams[i].element_type) < 0)
				json.element_types.push(this.beams[i].element_type);
		}

		json.columns = [];
		n=0;
		for (var i in this.columns)
		{
			if (selection && !this.columns[i].selected) continue;
			this.columns[i].s_index = n;
			n++;
			json.columns.push(this.columns[i].serialize());
			if (selection && json.element_types.indexOf(this.columns[i].element_type) < 0)
				json.element_types.push(this.columns[i].element_type);
		}

		json.pipes = [];
		n=0;
		for (var i in this.pipes)
		{
			if (selection && !this.pipes[i].selected) continue;
			this.pipes[i].s_index = n;
			n++;
			json.pipes.push(this.pipes[i].serialize());
			if (selection && json.element_types.indexOf(this.pipes[i].element_type) < 0)
				json.element_types.push(this.pipes[i].element_type);
		}

		json.area_trimmings = [];
		n=0;
		for (var i in this.area_trimmings)
		{
			if (selection && !this.area_trimmings[i].selected) continue;
			this.area_trimmings[i].s_index = n;
			n++;
			json.area_trimmings.push(this.area_trimmings[i].serialize());
		}

		json.facing_trimmings = [];
		for (var i in this.facing_trimmings)
		{
			if (selection && !this.facing_trimmings[i].selected) continue;
			json.facing_trimmings.push(this.facing_trimmings[i].serialize());
		}

		return json;
	}

	static unserialize(json,building) {
		if (typeof(json.vertices) != 'object')
			throw "Error reading scene : 'vertices' not found";
		if (typeof(json.walls) != 'object')
			throw "Error reading scene : 'walls' not found";
		if (typeof(json.spaces) != 'object')
			throw "Error reading scene : 'spaces' not found";

		var scene = new cn_scene(building);
		if (typeof(json.ID) == 'string') scene.ID = json.ID;
		for (var i in json.vertices)
			cn_vertex.unserialize(json.vertices[i],scene);

		for (var i in json.walls)
			cn_wall.unserialize(json.walls[i],scene);

		scene.update_vertices(false);
		scene.update_walls();

		scene.spaces = [];
		for (var i in json.spaces)
			cn_space.unserialize(json.spaces[i],scene);
		if (scene.spaces.length == 0)
		{
			var outside = new cn_space(this);
			outside.outside = true;
			scene.spaces = [outside];
		}

		scene.slab_openings = [];
		for (var i in json.slab_openings)
			cn_slab_opening.unserialize(json.slab_openings[i],scene);

        // scene.bms = [];
        // for (var i in json.bms)
        //     cn_background_map.unserialize(json.bms[i]);

		scene.stairs = [];
		for (var i in json.stairs)
			cn_stairs.unserialize(json.stairs[i],scene);

		scene.object_instances = [];
		for (var i in json.object_instances)
			cn_object_instance.unserialize(json.object_instances[i],scene);

		scene.beams = [];
		for (var i in json.beams)
			cn_beam.unserialize(json.beams[i],scene);

		scene.columns = [];
		for (var i in json.columns)
			cn_column.unserialize(json.columns[i],scene);

		scene.pipes = [];
		for (var i in json.pipes)
			cn_pipe.unserialize(json.pipes[i],scene);

		scene.area_trimmings = [];
		for (var i in json.area_trimmings)
			cn_area_trimming.unserialize(json.area_trimmings[i],scene);

		scene.facing_trimmings = [];
		for (var i in json.facing_trimmings)
			cn_facing_trimming.unserialize(json.facing_trimmings[i],scene);

		scene.run_diagnostic();

		return scene;
	}


	//***********************************************************************************
	/**
	 * Checks existence of an element in the scene
	 * @param {any} element
	 * @returns {boolean}
	 */
	 check_element(element) {
		if (element == null) return false;
		if (element.constructor == cn_vertex)
			return this.vertices.indexOf(element) >= 0;
		if (element.constructor == cn_wall)
			return this.walls.indexOf(element) >= 0;
		if (element.constructor == cn_space)
			return this.spaces.indexOf(element) >= 0;
		if (element.constructor == cn_slab_opening)
			return this.slab_openings.indexOf(element) >= 0;
		if (element.constructor == cn_stairs)
			return this.stairs.indexOf(element) >= 0;
		if (element.constructor == cn_object_instance)
			return this.object_instances.indexOf(element) >= 0;
		if (element.constructor == cn_beam)
			return this.beams.indexOf(element) >= 0;
		if (element.constructor == cn_column)
			return this.columns.indexOf(element) >= 0;
		if (element.constructor == cn_pipe)
			return this.pipes.indexOf(element) >= 0;
		if (element.constructor == cn_area_trimming)
			return this.area_trimmings.indexOf(element) >= 0;
		if (element.constructor == cn_facing_trimming)
			return this.facing_trimmings.indexOf(element) >= 0;
        // if (element.constructor == cn_background_map)
        //     return this.bms.indexOf(element) >= 0;
		return false;
	}

	//***********************************************************************************
	//**** run scene diagnostic
	//***********************************************************************************
	run_diagnostic() {

		var errors = this.run_wall_diagnostic();
		if (errors == 0)
			console.log("wall diagnostic went fine");
		else
		{
			console.log("wall diagnostic had " + errors + " errors !!!!");
		}

		this.update_vertices();
		this.update_walls();

		//** a flag to rebuild spaces */
		if (this._need_rebuild_spaces)
			this.build_automatic_spaces();

		this.update_spaces();
		this.update_zones();
		this.update_deep()
	}

	run_wall_diagnostic() {
		var errors = 0;
		var diagnostic = {};
		diagnostic.walls = [];
		var walls_with_0_space = 0;
		var walls_with_1_space = 0;
		var walls_with_doubled_vertices = 0;
		var vertex_merge = false;
		while (true)
		{
			var change = false;
			for (var i in this.walls)
			{
				var w = this.walls[i];

				if (w.spaces[0] == null && w.spaces[1] == null)
				{
					walls_with_0_space++;
					diagnostic.walls.push(w);
				}
				else if (w.spaces[0] == null || w.spaces[1] == null)
				{
					walls_with_1_space++;
					diagnostic.walls.push(w);
				}

				if (w.vertices[0] == w.vertices[1])
				{
					walls_with_doubled_vertices++;
					diagnostic.walls.push(w);
				}
				else if (w.bounds.length < 0.001)
				{
					console.log("WARNING : wall with length 0");
					errors++;
					this.merge_vertices(w.vertices[0],w.vertices[1]);
					vertex_merge = true;
					change = true;
					break;
				}
			}
			if (!change) break;
		}

		console.log("Walls with 0 spaces : " + walls_with_0_space);
		console.log("Walls with 1 spaces : " + walls_with_1_space);
		console.log("Walls with doubled vertices : " + walls_with_doubled_vertices);

		return (errors + walls_with_0_space + walls_with_1_space + walls_with_doubled_vertices);
	}

	run_space_diagnostic() {
		var errors = 0;

		//*** Make list of all contours
		var outer_space = null;
		var clockwise_contours = [];
		var c_clockwise_contours = [];
		for (var i=0;i<this.spaces.length;i++)
		{
			var space = this.spaces[i];
			if (space.outside)
				outer_space = space;

			//*** remove empty spaces
			var nv = 0;
			for (var nctr in space.contours)
				nv += space.contours[nctr].vertices.length;
			if (nv == 0)
			{
				if (space.outside) continue;
				console.log("WARNING : empty space");
				errors++;
				this.spaces.splice(i,1);
				i--;
				continue;
			}

			//*** check contours
			var nb_clockwise = 0;
			var extra_contours = (space.outside)?0:1;
			for (var j =0; j<space.contours.length;j++)
			{
				var ctr = space.contours[j];

				//*** No contour with less than 2 vertices
				if (ctr.vertices.length < 2)
				{
					console.log("WARNING : empty contour");
					errors++;
					space.contours.splice(j,1);
					j--;
					continue;
				}

				//*** register short and counterclockwise contours
				if (ctr.vertices.length == 2 || !ctr.clockwise)
				{
					c_clockwise_contours.push(ctr);
					continue;
				}

				nb_clockwise++;
				if (nb_clockwise <= extra_contours)
				{
					clockwise_contours.push(ctr);
					continue;
				}

				//*** One single clockwise contour per inner space.
				if (space.outside)
					console.log("WARNING : found an outer clockwise contour");
				else
					console.log("WARNING : several clockwise contours in the same space");
				errors++;
				var sp = new cn_space(this);
				this.spaces.push(sp);
				sp.add_contour(ctr);
			}

			//*** inner space with no contour : should be banned.
			if (nb_clockwise < extra_contours)
			{
				console.log("WARNING : space without clockwise contour is removed");
				errors++;
				for (var jj in space.contours)
					space.contours[jj].space = null;
				this.spaces.splice(i,1);
				i--;
			}
		}

		//*** check outer space... we never know !
		if (outer_space == null)
		{
			outer_space = new cn_space(this);
			outer_space.outside = true;
			this.spaces.push(outer_space);
		}

		//*** By now, all clockwise contours are ok (one per inner space).
		//*** We now check counter clockwise contours
		for (var i=0;i<c_clockwise_contours.length;i++)
		{
			var ctr = c_clockwise_contours[i];

			//*** in which clockwise contour does it lie ?
			var pt = ctr.vertices[0].position;
			var clockwise_contour = null;

			//*** we search for the smallest contour that contains this contour.
			for (var jj in clockwise_contours)
			{
				if (!clockwise_contours[jj].contains(pt)) continue;
				if (clockwise_contours[jj].area <= ctr.area) continue;

				if (clockwise_contour == null || clockwise_contours[jj].area < clockwise_contour.area)
					clockwise_contour = clockwise_contours[jj];
			}

			//*** not in any contour : this is an outside contour
			if (clockwise_contour == null)
			{
				if (ctr.space == outer_space) continue;
				console.log("WARNING : one outer contour was wrong");
				errors++;
				outer_space.add_contour(ctr);
				continue;
			}

			//*** Check that contour is in proper space
			if (ctr.space == clockwise_contour.space) continue;
			console.log("WARNING : one inner contour was wrong");
			errors++;
			clockwise_contour.space.add_contour(ctr);
		}

		return errors;
	}

	//***********************************************************************************
	//**** merge 2 similar vertices. First one is kept
	//***********************************************************************************
	/*merge_vertices(v0, v1, transaction_manager = null)
	{
		if (transaction_manager)
		{
			var scene = this;
			transaction_manager.push_item_set(v1,["position"],function() {
				if (scene.vertices.indexOf(v1) >= 0)
					scene.merge_vertices(v0,v1);
				else
					scene.unmerge_vertices(v0,v1);
				});
		}

		//*** remove vertex
		var index = this.vertices.indexOf(v1);
		if (index >= 0) this.vertices.splice(index,1);

		//*** check walls
		for (var i=0;i<this.walls.length;i++)
		{
			var w = this.walls[i];
			if (w.vertices[0] == v1)
				w.vertices[0] = v0;
			if (w.vertices[1] == v1)
				w.vertices[1] = v0;
			if (w.vertices[0] == w.vertices[1])
			{
				this.walls.splice(i,1);
				i--;
			}
		}

		this.update_vertices();
	}

	}*/

	//***********************************************************************************
	//**** unmerge 2 similar vertices.
	//***********************************************************************************
	unmerge_vertices(v0, v1) {
		if (this.vertices.indexOf(v1)>=0) return;
		this.vertices.push(v1);

		for (var i in v0.walls)
		{
			var wall = v0.walls[i];
			if (v1.walls.indexOf(wall) < 0) continue;
			if (wall.vertices[0] == v0)
				wall.vertices[0] = v1;
			else if (wall.vertices[1] == v0)
				wall.vertices[1] = v1;
		}

		this.update_vertices();
	}

	//***********************************************************************************
	//**** Refresh
	//***********************************************************************************
	draw(camera) {
		var html = "";
        const splitted_sections = this._extract_splitted_polygons_from_layers_sections();
        const textures_per_element = this._collect_colorised_elements(splitted_sections);
        const textures_generation = generate_textures_per_element(textures_per_element);
        const textures_id_per_element = textures_generation.textures;
        textures_id_per_element.forEach((value, id) => {
            textures_id_per_element.set(id, `style="${value}"`);
        });
        html += textures_generation.html;

        const mandatory_slabs_for_colored_elements = []
        if (this.draw_comments_application && this.storey && this.storey.markers) {
            this.storey.markers.forEach(marker => {
                mandatory_slabs_for_colored_elements.push(...this.storey.slabs.filter(slab => marker.element && marker.element.ID === slab.ID));
            })
        }
        if (this.draw_layers.length) {
            this.draw_layers.forEach(layer => {
                mandatory_slabs_for_colored_elements.push(...this.storey.slabs.filter(slab => layer.elements.find(el => el.obj === slab.ID && el.storey === this.storey.ID)));
            });
        }

        const zones_highlighted_elements = new Map();
        if (this.draw_zones.length) {
            const drawables_zones = [];
            for (const type_zone in this.building.zones) {
                drawables_zones.push(...this.building.zones[type_zone].filter(zone => this.draw_zones.includes(zone.ID)));
            }
            drawables_zones.forEach((zone, i) => {
                zone.rooms.filter(room => room.storey === this.storey.ID).forEach(room => {
                    zones_highlighted_elements.set(room.space, `style="fill:${zone.color};opacity:1"`);
                });
            });
        }

        this.spaces.forEach(space => {
            html += space.draw(camera, [], zones_highlighted_elements.get(space.ID) || '', this.draw_numerotation);
        });

        const openings = this.walls.reduce((agg, wall) => agg.concat(wall.openings.filter(opening => opening.valid)), []);

		if (!this.slabs) this.slabs = [];

        [
            ...mandatory_slabs_for_colored_elements,
            ...this.slabs,
            ...this.columns,
            ...this.slab_openings,
            ...this.stairs,
            ...this.object_instances,
            ...this.beams,
        ].forEach(element => {
			const inactive = (camera.element_filter && !camera.element_filter(element));
			if (inactive) html += "<g opacity='0.3'>";
            html += element.draw(camera, [], textures_id_per_element.get(element.ID) || '');
			if (inactive) html += "</g>";
        });

        this.pipes.forEach(pipe => {
            let pipe_style = textures_id_per_element.get(pipe.ID) || '';
            let section_style = pipe_style;
            if (this.draw_layers && this.draw_layers.length && !pipe_style) {
                pipe_style = 'style="fill:url(#pattern-wave);"';
                section_style = 'style="fill:rgb(150,150,150);opacity:1;"';
            }
			const inactive = (camera.element_filter && !camera.element_filter(pipe));
			if (inactive) html += "<g opacity='0.3'>";
            html+= pipe.draw(camera, [], pipe_style, section_style);
			if (inactive) html += "</g>";
        });

        this.walls.forEach(wall => {
            let wall_style = textures_id_per_element.get(wall.ID) || '';
            if (this.draw_layers && this.draw_layers.length && !wall_style) {
                wall_style = 'style="opacity:1"';
            }
			const inactive = (camera.element_filter && !camera.element_filter(wall));
			if (inactive) html += "<g opacity='0.3'>";
            html += wall.draw(camera, [], wall_style);
			if (inactive) html += "</g>";
        });
        this.vertices.forEach(element => {
			const inactive = (camera.element_filter && !camera.element_filter(element));
			if (inactive) html += "<g opacity='0.3'>";
			html += element.draw(camera, [], textures_id_per_element.get(element.ID) || '');
			if (inactive) html += "</g>";
		});

        openings.forEach(opening => {
			const inactive = (camera.element_filter && !camera.element_filter(opening));
			if (inactive) html += "<g opacity='0.3'>";
            html += opening.draw(camera, [], textures_id_per_element.get(opening.ID) || '');
			if (inactive) html += "</g>";
        });

        html += this._draw_layer_sections(splitted_sections, camera, textures_id_per_element);

        if (this.draw_samplings) {
            this.storey.samplings.filter(sampling => !sampling.roof && (!this.draw_layer_support || this.draw_layer_support === sampling.support))
            .forEach(sampling => {
                sampling.update();
				const inactive = (camera.element_filter && !camera.element_filter(sampling));
				if (inactive) html += "<g opacity='0.3'>";
                html += sampling.draw(camera);
				if (inactive) html += "</g>";
            });
        }

        if (this.draw_comments && this.storey && this.storey.markers) {
            this.storey.markers.filter(marker => !marker.roof).forEach(marker => {
                marker.update();
                let pic = !!marker["pictures"] && !!marker["pictures"].length;
				const inactive = (camera.element_filter && !camera.element_filter(marker));
				if (inactive) html += "<g opacity='0.3'>";
                html += marker.draw(camera, [], false, pic);
				if (inactive) html += "</g>";
            });
        }

		return html;
	}

    _draw_layer_sections(splitted_sections, camera, textures_id_per_element) {
        let html = ''
        if (this.draw_layers) {
            splitted_sections.forEach(section => {
                const shape = section.contour;
                shape.forEach((contour, i) => {
                    if (i === 0) {
                        html += `<path  d="`;
                    }
                    contour.inner_contour.forEach((contour, i) => {
                        if (i === 0) {
                            html += 'M ';
                        } else if (i === 1) {
                            html += 'L ';
                        }
                        const p = camera.world_to_screen(contour);
                        html += `${p[0]} ${p[1]} `;
                    });
                    if (i === shape.length - 1) {
                        html += 'Z "';
                        html += ` ${textures_id_per_element.get(shape[0].ID) || ''} />`;
                    }
                });
            });
        }
        return html;
    }

    /**
     * Split sections in intersects and separates polygons
     *
     * @returns Array
     */
    _extract_splitted_polygons_from_layers_sections() {
        const splitted_polygons = [];
        if (this.draw_layers.length) {
            const sections_polygons = this.draw_layers.reduce((agg, layer) => agg.concat(layer.sections.filter(section => section.storey === this.storey.ID
                && (!this.draw_layer_support || this.draw_layer_support === section.support)).concat(layer.lines.filter(line => line.storey === this.storey.ID
                    && (!this.draw_layer_support || this.draw_layer_support === line.support))).map(section => {
                const polygon = new fh_polygon([0, 0, 0], [0, 0, 1]);
                polygon.add_contour(section.shape.build_3d_contour(0, true));
                polygon.compute_contours();
                return { polygon, layer };
            })), []);
            sections_polygons.forEach((section, index) => {
                const p = { polygon: section.polygon.clone(), layers: [section.layer] };
                const other_polygons = sections_polygons.slice(index + 1);
                splitted_polygons.forEach(pol => {
                    p.polygon.substracts(pol.polygon);
                    other_polygons.forEach(o => {
                        const op = o.polygon.clone()
                        op.substracts(pol.polygon)
                        return { polygon: op, layer: o.layer  }
                    });
                });
                this._calculate_polygons_intersections(p, other_polygons, splitted_polygons, false);
            });
            sections_polygons.forEach((section, index) => {
                const l = section.polygon.clone();
                sections_polygons.forEach((s, i) => {
                    if (index !== i) {
                        l.substracts(s.polygon);
                    }
                });
                if (l.contour_sizes.length > 0) {
                    l.split().forEach(pol => {
                        splitted_polygons.push({ polygon: pol, layers: [section.layer] });
                    });
                }
            });
        }
        return splitted_polygons.map(pol => {
            return { contour: cn_contour.build_from_polygon(pol.polygon), layers: pol.layers }
        });
    }

    /**
     * Reference all colors to applicate per element
     *
     * @returns {Map}
     */
    _collect_colorised_elements(splitted_sections) {
        const colorsPerElement = new Map();
        if (this.draw_layers.length) {
            this.draw_layers.forEach(layer => {
                layer.elements.filter(el => el.storey === this.storey.ID && (!this.draw_layer_support || this.draw_layer_support === el.support)).forEach(element => {
                    colorsPerElement.set(element.obj, [...(colorsPerElement.get(element.obj) || []),
                    { color: layer.color, texture: layer.stripes }]);
                });
                const impacted_elements_types = [...layer.elements_types];
                if (impacted_elements_types) {
                    const elements_by_type = this._collect_elements_by_type(impacted_elements_types);
                    impacted_elements_types.reduce((a,b) => a.concat(elements_by_type.get(b)), []).forEach(element => {
                        colorsPerElement.set(element, [...(colorsPerElement.get(element) || []),
                        { color: layer.color, texture: layer.stripes }]);
                    });
                }
            });
            splitted_sections.forEach(section => {
                colorsPerElement.set(section.contour[0].ID, section.layers.map(layer => {
                    return { color: layer.color, texture: layer.stripes }
                }));
            });
        }

        if (this.draw_comments_application && this.storey && this.storey.markers) {
            this.storey.markers.forEach(marker => {
                if (marker.element && !marker.shape && marker.color) {
                    colorsPerElement.set(marker.element.ID, [...(colorsPerElement.get(marker.element.ID) || []), { color: marker.color, texture: 'full' }]);
                }
            });
        }
        return colorsPerElement;
    }

    /**
     * Collect element by scope of elements types
     *
     * @param {Array<string>} scope
     */
    _collect_elements_by_type(scope) {
        const result = new Map();
        if (!this.draw_layer_support || this.draw_layer_support === 'wall') {
            this.walls.filter(wall => scope.includes(wall.wall_type.ID)).forEach(wall => result.set(wall.wall_type.ID, [...(result.get(wall.wall_type.ID) || []), wall.ID]));
        }
        if (!this.draw_layer_support || this.draw_layer_support === 'work') {
            this.walls.reduce((a,b) => a.concat(b.openings), []).filter(opening => scope.includes(opening.opening_type.ID)).forEach(opening => result.set(opening.opening_type.ID, [...(result.get(opening.opening_type.ID) || []), opening.ID]));
            this.object_instances.filter(obj => scope.includes(obj.object.ID)).forEach(obj => result.set(obj.object.ID, [...(result.get(obj.object.ID) || []), obj.ID]));
            this.beams.filter(beam => scope.includes(beam.element_type.ID)).forEach(beam => result.set(beam.element_type.ID, [...(result.get(beam.element_type.ID) || []), beam.ID]));
            this.columns.filter(column => scope.includes(column.element_type.ID)).forEach(column => result.set(column.element_type.ID, [...(result.get(column.element_type.ID) || []), column.ID]));
            this.pipes.filter(pipe => scope.includes(pipe.element_type.ID)).forEach(pipe => result.set(pipe.element_type.ID, [...(result.get(pipe.element_type.ID) || []), pipe.ID]));
        }
        if (!this.draw_layer_support || this.draw_layer_support === 'floor') {
            this.slabs.filter(slab => scope.includes(slab.slab_type.ID)).forEach(slab => result.set(slab.slab_type.ID, [...(result.get(slab.slab_type.ID) || []), slab.ID]));
        }

        return result;
    }

    _calculate_polygons_intersections(p, others_polygons, result, intersection) {
        if (others_polygons.length > 0) {
            const next = others_polygons[0];
            const intersect = p.polygon.clone();
            intersect.intersects(next.polygon);
            if (intersect && intersect.contour_sizes.length > 0) {
                p.polygon.substracts(intersect);
                const new_p = { polygon: intersect, layers: [...p.layers, next.layer] };
                this._calculate_polygons_intersections(new_p, others_polygons.slice(1), result, true);
            }
            if (p.polygon.contour_sizes.length > 0) {
                this._calculate_polygons_intersections(p, others_polygons.slice(1), result, intersection);
            }
        } else if (intersection) {
            result.push(p);
        }
        return result
    }

    simple_draw(camera, add_classes = ['']) {
        let html = '';
        this.spaces.forEach(space => {
            html += space.draw(camera, add_classes, '', false, true);
        });
        return html;
    }

	//***********************************************************************************
	//**** Set status
	//***********************************************************************************
	set_status(status = 0) {
		for (var i in this.spaces)
		{
			this.spaces[i].status = status;
			for (var j in this.spaces[i].slab_openings)
				this.spaces[i].slab_openings[j].status = status;
		}
		for (var i in this.walls)
		{
			this.walls[i].status = status;
			for (var j in this.walls[i].openings)
				this.walls[i].openings[j].status = status;
		}
		for (var i in this.vertices)
			this.vertices[i].status = status;
		for (var i in this.slab_openings)
			this.slab_openings[i].status = status;
		for (var i in this.stairs)
			this.stairs[i].status = status;
		for (var i in this.object_instances)
			this.object_instances[i].status = status;
		for (var i in this.beams)
			this.beams[i].status = status;
		for (var i in this.columns)
			this.columns[i].status = status;
		for (var i in this.pipes)
			this.pipes[i].status = status;
	}

	//***********************************************************************************
	//**** Get a space by its ID
	//***********************************************************************************
	get_space(id) {
		for (var i in this.spaces)
		{
			if (this.spaces[i].ID == id) return this.spaces[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find a vertex
	//***********************************************************************************
	find_vertex(pt, tolerance=0.01) {
		for (var i in this.vertices)
		{
			if (this.vertices[i].contains(pt,tolerance))
				return this.vertices[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find a wall by a point
	//***********************************************************************************
	find_wall(pt, tolerance=0) {
		for (var i in this.walls)
		{
			if (this.walls[i].contains(pt,tolerance))
				return this.walls[i];
		}
		return null;
	}

	//***********************************************************************************
	/**
	 * find an opening by a point
	 * @param {number[]} pt
	 * @param {number} tolerance
	 * @returns {cn_opening}
	 */
	find_opening(pt, tolerance=0) {
		var wall = this.find_wall(pt,tolerance);
		if (wall)
			return wall.find_opening(pt);
		return null;
	}

	//***********************************************************************************
	/**
	 * find a space by a point
	 * @param {number[]} pt
	 * @param {boolean} inner
	 * @returns {cn_space}
	 */
	find_space(pt, inner=false) {
		if (this.spaces.length == 1)
			return this.spaces[0];
		for (var i=0;i<this.spaces.length;i++)
		{
			if (this.spaces[i].contains(pt, inner))
				return this.spaces[i];
		}
		return null;
	}

    // find_bm(pt, inner=false) {
    //     console.log("find bms");
    //     console.log(this.bms);
	// 	// if (this.bms.length == 1)
	// 	// 	return this.bms[0];
	// 	for (var i=0;i<this.bms.length;i++)
	// 	{
	// 		if (this.bms[i].contains(pt, inner))
	// 			return this.bms[i];
	// 	}
	// 	return null;
	// }

	//***********************************************************************************
	//**** find a slab by a point
	//***********************************************************************************
	find_slab(pt) {
		for (var i=0;i<this.slabs.length;i++)
		{
			if (this.slabs[i].spaces[0] == null && this.slabs[i].spaces[1] == null) continue;
			if (this.slabs[i].contains(pt))
				return this.slabs[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find a slab opening by a point
	//***********************************************************************************
	find_slab_opening(pt) {
		for (var i=0;i<this.slab_openings.length;i++)
		{
			if (this.slab_openings[i].contains(pt))
				return this.slab_openings[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find a slab by a point
	//***********************************************************************************
	find_stairs(pt) {
		for (var i=0;i<this.stairs.length;i++)
		{
			if (this.stairs[i].contains(pt))
				return this.stairs[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find an object instance by a point
	//***********************************************************************************
	find_object_instance(pt, tolerance=0) {
		for (var i=0;i<this.object_instances.length;i++)
		{
			if (this.object_instances[i].contains(pt, tolerance))
				return this.object_instances[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find a beam by a point
	//***********************************************************************************
	find_beam(pt, tolerance=0) {
		for (var i=0;i<this.beams.length;i++)
		{
			if (this.beams[i].contains(pt, tolerance))
				return this.beams[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find a columns by a point
	//***********************************************************************************
	find_column(pt, tolerance=0) {
		for (var i=0;i<this.columns.length;i++)
		{
			if (this.columns[i].contains(pt, tolerance))
				return this.columns[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find a pipe by a point
	//***********************************************************************************
	find_pipe(pt, tolerance=0) {
		for (var i=0;i<this.pipes.length;i++)
		{
			if (this.pipes[i].contains(pt, tolerance))
				return this.pipes[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find a area trimming by a point
	//***********************************************************************************
	find_area_trimming(pt) {
		for (var i=0;i<this.area_trimmings.length;i++)
		{
			if (this.area_trimmings[i].contains(pt))
				return this.area_trimmings[i];
		}
		return null;
	}

	//***********************************************************************************
	//**** find a facing trimming by a point
	//***********************************************************************************
	find_facing_trimming(pt) {
		for (var i=0;i<this.facing_trimmings.length;i++)
		{
			if (this.facing_trimmings[i].contains(pt))
				return this.facing_trimmings[i];
		}
		return null;
	}

	/**
	 * returns all the openings of the scene
	 * @param {boolean} only_valid
	 * @returns
	 */
	get_openings(only_valid = true)
	{
		var openings = [];
		this.walls.forEach(wall => {
			if (!only_valid)
				openings = openings.concat(wall.openings);
			else
				wall.openings.forEach(op => {if (op.valid) openings.push(op);});
		});
		return openings;
	}

	//***********************************************************************************
	//**** update vertices
	//***********************************************************************************
	update_vertices(clear_unused = true) {
		for (var i in this.vertices)
		{
			this.vertices[i].walls = [];
			this.vertices[i].delegates = [];
		}
		for (var i in this.walls)
		{
			var w = this.walls[i];
			w.vertices[0].walls.push(w);
			w.vertices[1].walls.push(w);
			if (w.delegates[0]) w.vertices[0].delegates.push(w.delegates[0].position);
			if (w.delegates[1]) w.vertices[1].delegates.push(w.delegates[1].position);
		}
		if (clear_unused)
		{
			for (var j =0;j<this.vertices.length;j++)
			{
				if (this.vertices[j].walls.length > 0)
					this.vertices[j].update();
				else
				{
					this.vertices.splice(j,1);
					j--;
				}
			}
		}
	}

	//***********************************************************************************
	//**** update walls
	//***********************************************************************************
	update_walls(wall_list = null) {

		var this_wall_list = (wall_list)?wall_list:this.walls;
		this_wall_list.forEach(w => w.build_self());

		//*** Compute bounds for regular vertices */
		this_wall_list.forEach(w => {
			if (w.delegates[0] == null) w.build_bounds(0);
			if (w.delegates[1] == null) w.build_bounds(1);
		});

		//*** compute delegates. Register all vertices that maight be impacted by changes */
		var impacted_vertices = [];
		this_wall_list.forEach(w => {
			for (var k=0;k<2;k++)
			{
				if (w.fix_delegate(k)) continue;
				if (impacted_vertices.indexOf(w.vertices[1-k]) < 0)
					impacted_vertices.push(w.vertices[1-k]);
			}
		});

		//*** recompute regular walls for impacted vertices */
		if (impacted_vertices.length > 0)
		{
			console.log(`#### ${impacted_vertices.length} impacted vertices`);
			impacted_vertices.forEach(v => {
				//*** recompuute regular */
				v.walls.forEach(w => {
					if (w.delegates[0] == null) w.build_bounds(0);
					if (w.delegates[1] == null) w.build_bounds(1);
				});
			});
		}

		//*** Compute bounds for regular vertices */
		this_wall_list.forEach(w => {
			if (w.delegates[0]) w.build_bounds(0);
			if (w.delegates[1]) w.build_bounds(1);
		});

		//*** build data on openings */
		this_wall_list.forEach(w => w.build_dependant());
	}


	//***********************************************************************************
	/** Forces delegates to unlock */
	unlock_delegates() {
		this.walls.forEach(w => {
			w.delegates.forEach(d => {
				if (d) d.locked = false;
			});
		});
	}

	//***********************************************************************************
	//**** update spaces
	//***********************************************************************************
	update_spaces() {
		for (var i in this.spaces)
			this.spaces[i].update();
    }

    /**
     * update zones
     */
    update_zones() {
        if (this.storey) {
            const spaces_id = this.spaces.map(space => space.ID);
            Object.entries(this.building.zones).forEach(([type_zone, zones]) => {
                zones.forEach(zone => {
                    zone.rooms.filter(room => room.storey === this.storey.ID && !spaces_id.includes(room.space)).forEach(room => {
                        zone.remove_room(room.space, room.storey);
                    });
                });
            });
        }
    }

	//***********************************************************************************
	//**** update
	//***********************************************************************************
	update() {
		this.update_vertices();
		this.update_walls();
		if (this._need_rebuild_spaces)
			this.build_automatic_spaces();
		this.update_spaces();
        this.update_zones();
	}

	update_deep() {
		this.unlock_delegates();

		if (this._need_rebuild_spaces)
			this.build_automatic_spaces();

		for (var i in this.spaces)
			this.spaces[i].slab_openings = [];

		for (var i in this.slab_openings)
		{
			this.slab_openings[i].update_space();
		}

		//*** Exterior space */
		if (this.storey && this.storey.exterior)
		{
			this.storey.ignored_polygon = null;
			var floor_storey = this.building.storeys[this.building.storey_0_index];
			if (floor_storey)
			{
				var exterior_space = floor_storey.scene.spaces.find(sp => sp.outside);
				if (exterior_space)
					this.storey.ignored_polygon = exterior_space.build_inner_polygon(0,false);
			}
		}

		this.facing_trimmings.forEach(function(at) {at.update();});
		for (var i in this.spaces)
		{
			this.spaces[i].update_deep();
			this.spaces[i].has_roof = (!this.spaces[i].outside);
			this.spaces[i].indoor = (!this.spaces[i].outside);
		}
		for (var i in this.walls)
		{
			var w = this.walls[i];
			w.indoor = true;
			if (w.balcony || w.wall_type.free)
			{
				w.indoor = false;
				continue;
			}
			for (var i in w.openings)
			{
				if (!w.openings[i].valid) continue;
				if (w.openings[i].opening_type.free)
				{
					w.indoor = false;
					break;
				}
			}
		}
		if (this.storey && this.storey.exterior)
		{
			this.spaces.forEach(sp => {
				sp.indoor = false;
				sp.has_roof = false;
			});
		}
		else
		{
			while (true)
			{
				var change = false;
				for (var i in this.walls)
				{
					var w = this.walls[i];
					if (!w.balcony) continue;
					if (w.spaces[0].has_roof == w.spaces[1].has_roof) continue;
					w.spaces[0].has_roof = false;
					w.spaces[1].has_roof = false;
					w.spaces[0].indoor = false;
					w.spaces[1].indoor = false;
					change = true;
				}
				for (var i in this.walls)
				{
					var w = this.walls[i];
					if (w.indoor) continue;
					if (w.spaces[0].indoor == w.spaces[1].indoor) continue;
					w.spaces[0].indoor = false;
					w.spaces[1].indoor = false;
					change = true;
				}
				if (!change) break;
			}
		}
		for (var i in this.stairs)
		{
			this.stairs[i].update_deep();
		}
		this.area_trimmings.forEach(function(at) {at.update();});

		this.object_instances.forEach(oi => oi.update_deep());

		if (this.storey)
			return this.storey.update_markers();
	}

	/**
	 * Makes all necessary updates for the scene
	 * @param {boolean} force_rebuild if true, forces the spaces to be rebuilt.
	 */
	full_update(force_rebuild = false) {
		if (force_rebuild) this._need_rebuild_spaces = true;
		this.update();
		this.update_deep();
        if (this.storey) {
            const roof_footprint = this.storey.build_slab_polygon(0, false, false);
            const upper_level = this.building.find_storey_by_index(this.storey.storey_index + 1);
            let upper_footprint = null;
            if (upper_level) {
                upper_footprint = upper_level.build_slab_polygon(0, true, false);
                if (upper_footprint.get_area() < 0.1) upper_footprint = null;
                if (upper_footprint) roof_footprint.substracts(upper_footprint);
            }
            this.storey.update_roof(roof_footprint, upper_footprint);
            this.storey.update_slabs();
        }
		if (this.building && this.building.transaction_manager)
		{
			this.building.transaction_manager.call("rebuild_roof");
		}
	}

	//***********************************************************************************
	//**** Check walls and vertices
	//***********************************************************************************
	check_vertices(vertices = [], display_log = false)
	{
		this.update_vertices();

		if (vertices.length == 0) vertices = this.vertices;

		//*** Check vertices ambiguities : 2 walls starting from each vertex must not form an angle < 1°
		var threshold = Math.PI * 1 / 180;
		for (var i in this.vertices)
		{
			var vertex = this.vertices[i];
			for (var j=0;j<vertex.angles.length;j++)
			{
				var a0 = vertex.angles[j];
				var a1 = (j < vertex.angles.length-1)?vertex.angles[j+1]:vertex.angles[0]+Math.PI*2;
				if (Math.abs(a1-a0) < threshold)
				{
					if (display_log) console.log(`cn_scene.check_vertices: close wall angles [${a0}] and [${a1}] on vertex`,vertex);
					return false;
				}
			}
		}
		return true;
	}

	check_walls(walls = [], avoided_walls = [], display_log = false)
	{
		if (walls.length == 0) walls = this.walls;

		//*** Compute bounds for regular vertices */
		walls.forEach(w => {w.build_self();});
		walls.forEach(w => {
			if (w.delegates[0] == null) w.build_bounds(0);
			if (w.delegates[1] == null) w.build_bounds(1);
		});

		//*** check delegates */
		for (var i in walls)
		{
			const w = walls[i];
			for (var k=0;k<2;k++)
			{
				if (!w.compute_delegate(k))
				{
					if (display_log) console.log(`cn_scene.check_walls: wrong delegate`,walls[i]);
					return false;
				}
			}
		}

		this.update_walls();

		//*** Check wall lengths
		for (var i in walls)
		{
			if (walls[i].bounds.length < 0.001)
			{
				if (display_log) console.log(`cn_scene.check_walls: small wall`,walls[i]);
				return false;
			}
		}

		//*** check wall intersections
		for (var i in this.walls)
		{
			var w = this.walls[i];
			if (avoided_walls.indexOf(w) >= 0) continue;
			if (w.crosses(walls))
			{
				if (display_log) console.log(`cn_scene.check_walls: wall intersection`, w);
				return false;
			}
		}
		return true;
	}

	//***********************************************************************************
	//**** Check contour coherence, after change of some lines
	//***********************************************************************************
	check_changes(walls, avoided_walls = [], display_log = false) {
		if (!this.check_vertices([],display_log)) return false;

		if (!this.check_walls(walls,avoided_walls, display_log)) return false;

		//*** check contours
		this.update_spaces();
		var contours = [];
		var spaces = [];
		for (var i in walls)
		{
			for (var s=0;s<2;s++)
			{
				var ctr = walls[i].contours[s];
				if (ctr && contours.indexOf(ctr) < 0)
					contours.push(ctr);

				var sp = walls[i].spaces[s];
				if (sp && spaces.indexOf(ctr) < 0)
					spaces.push(sp);
			}
		}

		//*** check contours
		for (var i in contours)
		{
			if (!contours[i].check(display_log))
				return false;
		}

		//*** check spaces
		for (var i in spaces)
		{
			var nb_cc = 0;
			var nb_ccc = 0;
			var nb_empty_c = 0;
			var space = spaces[i];
			for (var j in space.contours)
			{
				var ctr = space.contours[j];
				if (ctr.vertices.length <= 2) 
				{
					nb_empty_c++;
					continue;
				}
				if (ctr.clockwise)
					nb_cc++;
				else
					nb_ccc++;
			}

			if (space.outside)
			{
				if (nb_cc > 0)
				{
					if (display_log) console.log(`scene.check_changes:  clockwise contour in exterior`);
					return false;
				}
				if (nb_ccc < 1 && nb_empty_c < 1)
				{
					if (display_log) console.log(`scene.check_changes: no counter clockwise contour in exterior`);
					return false;
				}
			}
			else
			{
				if (nb_cc > 1)
				{
					if (display_log) console.log(`scene.check_changes: several clockwise contours`);
					return false;
				}
			}
		}
		return true;
	}

	//***********************************************************************************
	//**** Insert one wall
	//***********************************************************************************
	insert_wall(wall) {
		if (this.walls.indexOf(wall) >= 0) return;
		this.walls.push(wall);

		//*** insert vertices
		for (var s=0;s<2;s++)
		{
			if (this.vertices.indexOf(wall.vertices[s]) < 0)
				this.vertices.push(wall.vertices[s]);
			//wall.contours[s] = null;
			//wall.spaces[s] = null;
		}
		this._need_rebuild_spaces = true;
		return true;

		//*** update wall and vertex data
		this.update_vertices();
		this.update_walls();

		//*** check end of walls
		var isolated = [false,false];
		for (var s=0;s<2;s++)
		{
			if (wall.vertices[s].walls.length == 1)
				isolated[s] = true;
		}

		//*** fully isolated wall
		if (isolated[0] && isolated[1])
		{
			var space0 = this.find_space(wall.vertices[0].position);
			var space1 = this.find_space(wall.vertices[1].position);
			if (space0 != space1 || space0 == null)
			{
				console.log("WARNING : incoherent wall, not inserted",wall);
				this.walls.splice(this.walls.length-1,1);
				this.update_vertices();
				this.update_walls();
				return false;
			}

			var new_contour = new cn_contour();
			new_contour.follow(wall.vertices[0],wall);
			space0.add_contour(new_contour);
			return true;
		}

		//*** single isolated wall
		if (isolated[0] || isolated[1])
		{
			var space = (isolated[0])?this.find_space(wall.vertices[0].position):this.find_space(wall.vertices[1].position);
			if (space == null)
			{
				console.log("WARNING : incoherent wall, not inserted",wall);
				this.walls.splice(this.walls.length-1,1);
				this.update_vertices();
				this.update_walls();
				return false;
			}

			var new_contour = new cn_contour();
			new_contour.follow(wall.vertices[0],wall);
			space.add_contour(new_contour);
			return true;
		}

		//*** not isolated wall
		var new_contour_0 = new cn_contour();
		new_contour_0.follow(wall.vertices[0],wall);

		var space2 = null;
		var count = 0;
		for (var i=0;i<new_contour_0.walls.length;i++)
		{
			var s = (new_contour_0.wall_orientations[i])?0:1;
			if (space2 == null)
				space2 = new_contour_0.walls[i].spaces[s];
			if (new_contour_0.walls[i] == wall) count++;
		}

		if (space2 == null) {
			console.log("WARNING : incoherent wall, not inserted",wall);
			this.walls.splice(this.walls.length-1,1);
			this.update_vertices();
			this.update_walls();
			this.run_diagnostic();
			return false;
		}

		//*** if second contour is not necessary
		if (count == 2)
		{
			space2.add_contour(new_contour_0);
			space2.update();
			space2.update_deep();
			return true;
		}

		var new_contour_1 = new cn_contour();
		new_contour_1.follow(wall.vertices[1],wall);

		//*** create new space
		var new_space = new cn_space(this);
        this._merge_splited_spaces_zones(space2, new_space);
		this.spaces.push(new_space);

		//*** If both coutours have same orientation (space is split between 2)
		var do_switch = false;
		if (new_contour_0.clockwise && new_contour_1.clockwise)
			do_switch = (new_contour_0.perimeter < new_contour_1.perimeter);

		//*** one space inside another
		else
			do_switch = new_contour_0.clockwise;

		if (do_switch)
		{
			var c = new_contour_1;
			new_contour_1 = new_contour_0;
			new_contour_0 = c;
		}

		space2.add_contour(new_contour_0);
		new_space.add_contour(new_contour_1);

		//*** send inner contours to one or another
		new_contour_0.update();
		new_contour_1.update();
		var contours = space2.contours.concat([]);
		for (var nctr in contours)
		{
			var contour = contours[nctr];
			if (contour == new_contour_0) continue;
			if (contour.clockwise) continue;
			var pt = contour.vertices[0].position;
			if (new_contour_1.contains(pt))
				new_space.add_contour(contour);
		}

		this.update();
		this.update_deep();
		return true;
	}

	//***********************************************************************************
	//**** Remove one wall
	//***********************************************************************************
	remove_wall(wall) {
		var index = this.walls.indexOf(wall);
		if (index < 0) return;

		this.set_date(["walls","vertices"],CN_CURRENT_DATE);
		this.walls.splice(index,1);

		//*** remove wall from vertices
		for (var side=0;side<2;side++)
		{
			if (wall.vertices[side].walls.length != 1) continue;
			index = this.vertices.indexOf(wall.vertices[side]);
			if (index >= 0)
			{
				this.vertices.splice(index,1);
			}
		}
		this._need_rebuild_spaces = true;
		return;
	}

	//***********************************************************************************
	/**
	 * Split a wall by inserting a vertex in it.
	 * @param {cn_wall} wall : the wall to split
	 * @param {cn_vertex} new_vertex : the vertex that splits the wall
	 * @param {cn_wall} new_wall : wall instance to be used for the new wall. If null, a new wall will be created. (used for undo/redo)
	 * @returns {cn_wall} new wall created
	 */
	split_wall(wall, new_vertex, new_wall = null)
	{
		this.set_date(["walls","vertices"],CN_CURRENT_DATE);
		const in_scene = (this.walls.indexOf(wall) >= 0);

		if (in_scene && this.vertices.indexOf(new_vertex) < 0)
			this.vertices.push(new_vertex);
		var new_final_wall = wall.split(new_vertex,new_wall);
		if (in_scene && this.walls.indexOf(new_final_wall) < 0)
			this.walls.push(new_final_wall);

		this._need_rebuild_spaces = true;
		return new_final_wall;
	}

	//***********************************************************************************
	/**
	 * Merge two walls into one.
	 * Walls must have one vertex in common, otherwise without effect.
	 * first wall in argumentwill be kept.
	 * @param {cn_wall} wall0
	 * @param {cn_wall} wall1
	 */
	merge_wall(wall0, wall1)
	{
		this.set_date(["walls","vertices"],CN_CURRENT_DATE);
		//*** search for common vertex
		var i0 = -1;
		var i1 = -1;
		for (var i=0;i<2;i++)
		{
			for (var j=0;j<2;j++)
			{
				if (wall0.vertices[i] != wall1.vertices[j]) continue;
				i0 = i;
				i1 = j;
				break;
			}
		}
		if(i0 < 0) return;

		var index = this.walls.indexOf(wall1);
		if (index >= 0)
		{
			this.walls.splice(index,1);
		}

		//*** save openings */
		var openings = [];
		var opening_positions = [];
		[...wall0.openings, ...wall1.openings].forEach(o => {
			openings.push(o);
			opening_positions.push(o.get_position());
		});

		wall0.vertices[i0] = wall1.vertices[1-i1];
		wall0.delegates[i0] = wall1.delegates[1-i1];

		wall0.openings = [];
		const v0 = wall0.vertex_position(0);
		const v1 = wall0.vertex_position(1);
		const dir = cn_sub(v1,v0);
		cn_normalize(dir);
		for (var k=0;k<openings.length;k++)
		{
			wall0.openings.push(openings[k]);
			openings[k].wall = wall0;
			openings[k].position = cn_dot(dir,cn_sub(opening_positions[k],v0));
		}
		this._need_rebuild_spaces = true;
		return;
	}

	//***********************************************************************************
	/**
	 * split a vertex, returns true if this could be done
	 * @param {cn_vertex} vertex : existing vertex to merge
	 * @param {cn_vertex} new_vertex : new vertex, with position and walls
	 * @param {cn_transaction_manager} transaction_manager : transaction_manager, or null
	 */
	split_vertex(vertex, new_vertex, wall_type=null, transaction_manager=null)
	{
		this.set_date(["walls","vertices"],CN_CURRENT_DATE);
		if (transaction_manager)
		{
			var obj = this;
			transaction_manager.push_item_set(obj,[],function() {
				if (obj.vertices.includes(new_vertex))
					obj.merge_vertices(vertex,new_vertex);
				else
					obj.split_vertex(vertex,new_vertex,wall_type);
			})
		}

		this.vertices.push(new_vertex);

		var new_wall = null;
		var new_wall_delegates = [];
		for (var i in new_vertex.walls)
		{
			var w = new_vertex.walls[i];
			var index = vertex.walls.indexOf(w);
			if (index < 0)
			{
				if (w.vertices.includes(vertex) && w.vertices.includes(new_vertex))
					new_wall = w;
				continue;
			}
			vertex.walls.splice(index,1);
			if (!w.vertices.includes(new_vertex))
			{
				const wid = (w.vertices[0] == vertex)?0:1;
				w.vertices[wid] = new_vertex;
				if (w.delegates[wid]) new_wall_delegates.push(w.delegates[wid]);
			}
		}

		if (new_wall == null)
		{
			console.log("build new wall");
			const wt = (wall_type)?wall_type:new_vertex.walls[0].wall_type;
			new_wall = new cn_wall(vertex,new_vertex,wt,new_vertex.walls[0].axis,this);
			new_vertex.walls.push(new_wall);
		}

		new_wall_delegates.forEach(wd => wd.wall = new_wall);

		this.walls.push(new_wall);
		vertex.walls.push(new_wall);
		this._need_rebuild_spaces = true;
	}

	//***********************************************************************************
	/**
	 * merge two vertices, does not check coherence of operation
	 * @param {cn_vertex} v0
	 * @param {cn_vertex} v1
	 */
	merge_vertices(v0, v1, transaction_manager=null)
	{
		this.set_date(["walls","vertices"],CN_CURRENT_DATE);

		if (transaction_manager)
		{
			var obj = this;
			transaction_manager.push_item_set(obj,[],function() {
				if (obj.vertices.includes(v1))
					obj.merge_vertices(v0,v1);
				else
					obj.split_vertex(v0,v1);
			})
		}

		var index = this.vertices.indexOf(v1);
		if (index >= 0) this.vertices.splice(index,1);

		var v0_walls = v0.walls.concat([]);
		var v1_walls = v1.walls.concat([]);
		for (var i in v1.walls)
		{
			var w = v1.walls[i];
			var index = (w.vertices[0] == v1)?0:1;

			//*** maybe remove wall ? */
			if (w.vertices[1-index] == v0)
			{
				var w_index = this.walls.indexOf(w);
				this.walls.splice(w_index,1);
				var ii = v0.walls.indexOf(w);
				if (ii >= 0) v0.walls.splice(ii,1);
			}
			else
			{
				w.vertices[index] = v0;
				v0.walls.push(w);
			}
		}
		console.log("after merge",v0.display_walls(),v1.display_walls());
		this._need_rebuild_spaces = true;
	}

	//***********************************************************************************
	update_vertices_and_walls(walls = null) {
		if (walls == null)
		{
			this.update_vertices();
			this.update_walls();
			return;
		}

		var vertices = [];
		for (var i in walls)
		{
			var w = walls[i];
			if (vertices.indexOf(w.vertices[0])<0) vertices.push(w.vertices[0]);
			if (vertices.indexOf(w.vertices[1])<0) vertices.push(w.vertices[1]);
		}

		for (var i in vertices)
			vertices[i].update();
		for (var i in walls)
			walls[i].build_self();
		for (var i in walls)
			walls[i].build_dependant();
	}

	update_contours(walls)
	{
		for (var i in walls)
		{
			walls[i].contours[0] = null;
			walls[i].contours[1] = null;
		}
		for (var i in walls)
		{
			var w = walls[i];
			if (w.spaces[0] && !w.contours[0])
			{
				var contour = new cn_contour();
				contour.follow(w.vertices[0],w);
				w.spaces[0].add_contour(contour);
			}
			if (w.spaces[1] && !w.contours[1])
			{
				var contour = new cn_contour();
				contour.follow(w.vertices[1],w);
				w.spaces[1].add_contour(contour);
			}
		}
	}

	//***********************************************************************************
	//**** raytrace
	//***********************************************************************************
	raytrace(origin, direction, max_distance, start_vertex = null, start_wall = null) {
		var res = null;
		for (var i in this.walls)
		{
			var w = this.walls[i];
			if (w == start_wall) continue;
			if (w.vertices[0] == start_vertex || w.vertices[1] == start_vertex) continue;
			var rr = w.raytrace(origin, direction, max_distance);
			if (rr == false) continue;
			res = rr;
			max_distance = res.distance;
		}
		return res;
	}

	/**
	 * returns all impacts between p0 and p1.
	 * @param {number[]} p0
	 * @param {number[]} p1
	 * @return {any}
	 */
	pathtrace(p0, p1) {
		var direction = cn_sub(p1,p0);
		const max_distance = cn_normalize(direction);
		var impacts = [];
		for (var i in this.walls)
		{
			var w = this.walls[i];
			var rr = w.raytrace(p0, direction, max_distance);
			if (rr) impacts.push(rr);
		}
		impacts.sort(function (i0,i1)  {if (i0.distance < i1.distance) return 1; return -1;});
		return impacts;
	}

	//***********************************************************************************
	//**** Build automatic spaces from existing vertices and walls
	//***********************************************************************************
	build_automatic_spaces() {
		//*** remember old spaces and their geometry */
		const old_spaces = [];
        const old_spaces_snapshot = [...this.spaces];
        const reusables_spaces = [];
        const new_spaces = [];
		for (var i in this.spaces)
		{
			var space = this.spaces[i];
			if (space.outside) continue;
			space["footprint"] = null;
			for (var i in space.contours)
			{
				if (!space.contours[i].clockwise) continue;
				space["footprint"] = space.contours[i].build_3d_polygon(0);
				break;
			}
			if (space["footprint"] == null) continue;
			old_spaces.push(space);
		}

		//*** clear walls
		for (var i in this.walls)
		{
			var w = this.walls[i];
			for (var side=0;side<2;side++)
			{
				w.contours[side] = null;
				w.spaces[side] = null;
			}
		}

		//*** Find all contours
		var outer_contours = [];
		var inner_contours = [];
		for (var i in this.walls)
		{
			var w = this.walls[i];
			for (var side=0;side<2;side++)
			{
				//** avoid a wall that was already done on another contour
				if (w.contours[side]) continue;

				//*** build a contour from that wall
				var ctr = new cn_contour();
				ctr.follow(w.vertices[side],w);

				//*** record the contour
				if (ctr.clockwise)
					outer_contours.push(ctr);
				else
					inner_contours.push(ctr);

				//*** memorize contour on all its walls
				for (var nw=0;nw<ctr.walls.length;nw++)
				{
					if (ctr.wall_orientations[nw])
						ctr.walls[nw].contours[0] = ctr;
					else
						ctr.walls[nw].contours[1] = ctr;
				}
			}
		}

		//*** Build outside space
		var outside = new cn_space(this);
		outside.outside = true;
		this.spaces = [outside];

		console.log("found " + outer_contours.length + " contours clockwise and " + inner_contours.length + " contours counter clockwise");

		//*** Build one space for each clockwise contour
		var nmatch = 0;
		for (var nct=0;nct<outer_contours.length;nct++)
		{
			//*** find old space to match it */
			var space2 = null;
			var footprint = outer_contours[nct].build_3d_polygon(0);
			var box = footprint.get_bounding_box();
			var area = footprint.get_area();
			var box_area = box.size[0] * box.size[1];

			for (var ki=0;ki< old_spaces.length;ki++)
			{
				if (this.spaces.indexOf(old_spaces[ki])<0)
				//*** Comparea areas */
				var other_area =  old_spaces[ki]["footprint"].get_area();
				if (Math.abs(area - other_area) > area * 0.01) continue;

				//*** Compare bounding boxes */
				var other_box = old_spaces[ki]["footprint"].get_bounding_box();
				var match = true;
				for (var k=0;k<2;k++)
				{
					if (Math.abs(box.position[k] - other_box.position[k]) < 0.01 * box.size[k] &&
						Math.abs(box.size[k] - other_box.size[k]) < 0.01 * box.size[k])  continue;
					match = false;
					break;
				}
				if (!match) continue;

				//*** Compare polygons */
				var pg = footprint.clone();
				pg.intersects(old_spaces[ki]["footprint"]);
				if (pg.get_area() < 0.99 * area) continue;

				//*** Match was found */
				space2 = old_spaces[ki];
				space2.contours = [];
				nmatch++;
				break;
			}
			if (space2 == null)
			{
				const new_space = new cn_space(this);

				//*** maybe contained by a larger older space ? */
				var space2_area = 0;
				var common_area = 0.4 * footprint.get_area();
				for (var ki=0;ki< old_spaces.length;ki++)
				{
					//*** Comparea areas */
					var other_area =  old_spaces[ki]["footprint"].get_area();

					//*** Compare polygons */
					var pg = footprint.clone();
					pg.intersects(old_spaces[ki]["footprint"]);
					if (pg.get_area() < 0.99 * common_area) continue;

					//*** Match was found */
					if (space2 && pg.get_area() < 1.01 * common_area && space2_area < other_area) continue;

					space2 = old_spaces[ki];
					space2_area = other_area;
					common_area = pg.get_area();
				}
				if (space2)
				{
					new_space.copy_parameters(space2);
                    Object.entries(this.building.zones).forEach(([_, zones]) => {
                        if (this.storey) {
                            zones.forEach(zone => {
                                if(zone.rooms.some(room => room.storey === this.storey.ID && room.space == space2.ID)) {
                                    zone.add_room(new_space.ID, this.storey.ID);
                                }
                            });
                        }
                    });
                    if (!reusables_spaces.find(r => r.old_space === space2.ID)) {
                        reusables_spaces.push({ old_space: space2.ID, new_space: new_space.ID });
                    }
				}
				space2 = new_space;
                this.building.area_contexts.forEach(ac => ac.add_space(space2));
			}
			space2.add_contour(outer_contours[nct]);
            const reuse = reusables_spaces.find(r => r.new_space === space2.ID);
            const space_index = old_spaces_snapshot.findIndex(sp => sp && (sp.ID === space2.ID || (!!reuse && reuse.old_space === sp.ID)));
            if (space_index >= 0) {
                this.spaces[space_index] = space2;
                old_spaces_snapshot[space_index] = null;
            } else {
                new_spaces.push(space2);
            }
		}
		console.log("Found " + nmatch + "/" + outer_contours.length + " space matches");
        this.spaces = this.spaces.filter(sp => !!sp);
        this.spaces.push(...new_spaces);

		//*** Loop on inner contours
		for (var nct=0;nct<inner_contours.length;nct++)
		{
			var contour = inner_contours[nct];

			var best_contour = null;
			var pt = contour.vertices[0].position;

			//*** search for the smallest space contour that contains this contour
			for (var i in outer_contours)
			{
				if (!outer_contours[i].contains(pt)) continue;
				if (outer_contours[i].area <= contour.area * 1.01) continue;
				if (best_contour == null || outer_contours[i].area < best_contour.area)
					best_contour = outer_contours[i];
			}

			//*** If a contour was found, add it to the space
			if (best_contour)
				best_contour.space.add_contour(contour);

			//*** Otherwise, this is an outside contour
			else
				outside.add_contour(contour);
		}

		this._need_rebuild_spaces = false;
	}

	//***********************************************************************************
	//**** Returns bounding box
	//***********************************************************************************
	get_bounding_box(use_markers = false, use_samplings = false, scale = 0, use_background_maps = true) {
		var box = new cn_box();
		for (var i in this.vertices)
			box.enlarge_point(this.vertices[i].position);

        [
            ...this.slab_openings,
            ...this.stairs,
            ...this.object_instances,
            ...this.beams,
            ...this.columns,
            ...this.pipes,
        ].forEach(element => {
            box.enlarge_box(element.get_bounding_box());
        });

        if (use_background_maps && this.storey && this.storey.background_maps && this.storey.background_maps.length) {
            this.storey.background_maps.forEach(bm => {
                box.enlarge_box(bm.get_bounding_box());
            });
        }

        if ((use_markers || use_samplings) && this.storey) {
            if (use_markers) {
                this.storey.markers.forEach(element => box.enlarge_box(element.get_bounding_box(scale)));
            }
            if (use_samplings) {
                this.storey.samplings.forEach(element => box.enlarge_box(element.get_bounding_box(scale)));
            }
        }

		return box;
	}
	//***********************************************************************************
	//**** iterate utilities
	//***********************************************************************************
	iterate_vertices(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.vertices)
			callback(this.vertices[i]);
	}

	iterate_walls(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.walls)
			callback(this.walls[i]);
	}

	iterate_openings(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.walls)
		{
			var wall = this.walls[i];
			for (var j in wall.openings)
				callback(wall.openings[j]);
		}
	}

	iterate_spaces(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.spaces)
			callback(this.spaces[i]);
	}

	iterate_slab_openings(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.spaces)
		{
			var space = this.spaces[i];
			for (var j in space.slab_openings)
				callback(space.slab_openings[j]);
		}
	}

	iterate_stairs(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.stairs)
			callback(this.stairs[i]);
	}

	iterate_object_instances(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.object_instances)
			callback(this.object_instances[i]);
	}

	iterate_beams(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.beams)
			callback(this.beams[i]);
	}

	iterate_columns(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.columns)
			callback(this.columns[i]);
	}

	iterate_pipes(callback) {
		if (typeof(callback) != 'function') return;
		for (var i in this.pipes)
			callback(this.pipes[i]);
	}

	//***********************************************************************************
	//**** Update height
	//***********************************************************************************
	update_height() {
		for (var i in this.stairs)
			this.stairs[i].compute_stairs();
	}

	//***********************************************************************************
	//**** Set selectable. Provide element type
	//***********************************************************************************
	set_selectable(value, element_type = "")
	{
		if (typeof(element_type) == 'object')
		{
			// @ts-ignore
			for (var i in element_type)
				// @ts-ignore
				this.set_selectable(value,element_type[i]);
			return;
		}

		if (element_type == "vertex" || element_type == "")
		{
			for (var i in this.vertices) this.vertices[i].selectable = value;
		}
		if (element_type == "wall" || element_type == "")
		{
			for (var i in this.walls)
			{
				if (!this.walls[i].balcony)
					this.walls[i].selectable = value;
			}
		}
		if (element_type == "balcony" || element_type == "")
		{
			for (var i in this.walls)
			{
				if (this.walls[i].balcony)
					this.walls[i].selectable = value;
			}
		}
		if (element_type == "opening" || element_type == "")
		{
			for (var i in this.walls)
			{
				for (var j in this.walls[i].openings)
				{
					this.walls[i].openings[j].selectable = value;
				}
			}
		}
		if (element_type == "window" || element_type == "door")
		{
			for (var i in this.walls)
			{
				for (var j in this.walls[i].openings)
				{
					if (this.walls[i].openings[j].opening_type.category == element_type)
						this.walls[i].openings[j].selectable = value;
				}
			}
		}
		if (element_type == "space" || element_type == "")
		{
			for (var i in this.spaces)
			{
				this.spaces[i].selectable = value;
			}
		}
		if (element_type == "slab_opening" || element_type == "")
		{
			for (var i in this.slab_openings)
			{
				this.slab_openings[i].selectable = value;
			}
		}
		if (element_type == "stairs" || element_type == "")
		{
			for (var i in this.stairs)
			{
				this.stairs[i].selectable = value;
			}
		}
		if (element_type == "object" || element_type == "")
		{
			for (var i in this.object_instances)
			{
				this.object_instances[i].selectable = value;
			}
		}
		if (element_type == "beam" || element_type == "")
		{
			for (var i in this.beams)
			{
				this.beams[i].selectable = value;
			}
		}
		if (element_type == "column" || element_type == "")
		{
			for (var i in this.columns)
			{
				this.columns[i].selectable = value;
			}
		}

		if (element_type == "pipe" || element_type == "")
		{
			for (var i in this.pipes)
			{
				this.pipes[i].selectable = value;
			}
		}

		if (element_type == "area_trimming" || element_type == "")
		{
			for (var i in this.area_trimmings)
			{
				this.area_trimmings[i].selectable = value;
			}
		}
		if (element_type == "facing_trimming" || element_type == "")
		{
			for (var i in this.facing_trimmings)
			{
				this.facing_trimmings[i].selectable = value;
			}
		}
	}

	//***********************************************************************************
	/**
	 * performs a translation
	 * @param {number[]} offset : translation offset
	 */
	perform_translation(offset) {
		function transform_point(p)
		{
			p[0] += offset[0];
			p[1] += offset[1];
		}

		for (var i in this.vertices)
		{
			this.vertices[i].vertex_operation(transform_point);
		}
		for (var i in this.slab_openings)
		{
			this.slab_openings[i].vertex_operation(transform_point);
		}

		for (var i in this.stairs)
		{
			this.stairs[i].vertex_operation(transform_point);
		}

		for (var i in this.object_instances)
		{
			this.object_instances[i].vertex_operation(transform_point);
		}
		for (var i in this.beams)
		{
			this.beams[i].vertex_operation(transform_point);
		}
		for (var i in this.columns)
		{
			this.columns[i].vertex_operation(transform_point);
		}
		for (var i in this.pipes)
		{
			this.pipes[i].vertex_operation(transform_point);
		}

		for (var i in this.area_trimmings)
		{
			this.area_trimmings[i].vertex_operation(transform_point);
		}

		for (var i in this.facing_trimmings)
		{
			this.facing_trimmings[i].vertex_operation(transform_point);
		}

        if (this.storey) {
            this.storey.markers.forEach(marker => marker.vertex_operation(transform_point));
            this.storey.samplings.forEach(sampling => sampling.vertex_operation(transform_point));
        }

		this.full_update();
	}

	//***********************************************************************************
	/**
	 * performs a rotation of the full scene
	 * @param {number[]} center : world position of rotation center
	 * @param {number} angle : rotation angle, in degrees
	 */
	perform_rotation(center, angle) {
		var rad_angle = angle * Math.PI/180;
		var cosangle = Math.cos(rad_angle);
		var sinangle = Math.sin(rad_angle);
		function transform_point(p)
		{
			var d = cn_sub(p,center);
			p[0] = center[0] + d[0]*cosangle - d[1]*sinangle;
			p[1] = center[1] + d[0]*sinangle + d[1]*cosangle;
		}
		for (var i in this.vertices)
		{
			this.vertices[i].vertex_operation(transform_point);
		}

		for (var i in this.slab_openings)
		{
			this.slab_openings[i].vertex_operation(transform_point);
		}

		for (var i in this.stairs)
		{
			this.stairs[i].perform_rotation(center,rad_angle, transform_point);
		}

		for (var i in this.object_instances)
		{
			this.object_instances[i].perform_rotation(center,rad_angle, transform_point);
		}
		for (var i in this.beams)
		{
			this.beams[i].vertex_operation(transform_point);
		}
		for (var i in this.columns)
		{
			this.columns[i].perform_rotation(center,rad_angle, transform_point);
		}
		for (var i in this.pipes)
		{
			this.pipes[i].vertex_operation(transform_point);
		}

		for (var i in this.area_trimmings)
		{
			this.area_trimmings[i].vertex_operation(transform_point);
		}

		for (var i in this.facing_trimmings)
		{
			this.facing_trimmings[i].vertex_operation(transform_point);
		}

        if (this.storey) {
            this.storey.markers.forEach(marker => marker.vertex_operation(transform_point));
            this.storey.samplings.forEach(sampling => sampling.vertex_operation(transform_point));
        }

		this.full_update();
	}

	//***********************************************************************************
	/**
	 * performs a flip of the full scene
	 * @param {number[]} center : world position of rotation center
	 * @param {boolean} horizontal : true for an horizontal flip, false for a vertical
	 */
	perform_flip(center, horizontal) {

		function transform_point(p)
		{
			if (horizontal)
				p[0] = center[0]*2 - p[0];
			else
				p[1] = center[1]*2 - p[1];
		}
		for (var i in this.vertices)
		{
			this.vertices[i].vertex_operation(transform_point);
		}

		for (var i in this.walls)
		{
			this.walls[i].reverse();
		}

		for (var i in this.spaces)
		{
			this.spaces[i].reverse();
		}

		for (var i in this.slab_openings)
		{
			this.slab_openings[i].perform_flip(center,horizontal, transform_point);
		}

		for (var i in this.stairs)
		{
			this.stairs[i].perform_flip(center,horizontal, transform_point);
		}

		for (var i in this.object_instances)
		{
			this.object_instances[i].perform_flip(center,horizontal, transform_point);
		}
		for (var i in this.beams)
		{
			this.beams[i].vertex_operation(transform_point);
		}
		for (var i in this.columns)
		{
			this.columns[i].perform_flip(center,horizontal, transform_point);
		}
		for (var i in this.pipes)
		{
			this.pipes[i].perform_flip(center,horizontal, transform_point);
		}

		for (var i in this.area_trimmings)
		{
			this.area_trimmings[i].perform_flip(center,horizontal, transform_point);
		}

		for (var i in this.facing_trimmings)
		{
			this.facing_trimmings[i].perform_flip(center,horizontal, transform_point);
		}

        if (this.storey) {
            this.storey.markers.forEach(marker => marker.vertex_operation(transform_point));
            this.storey.samplings.forEach(sampling => sampling.vertex_operation(transform_point));
        }

		this.full_update();
	}

	//***********************************************************************************
	/**
	 * Add an element
	 * @param {cn_element} element
	 */
	add_element(element)
	{
		element["scene"] = this;
		element["space"] = null;
		element.ID = cn_uuid(element.ID);
		if (element.constructor == cn_slab_opening)
			this.slab_openings.push(element);
		if (element.constructor == cn_stairs)
			this.stairs.push(element);
		if (element.constructor == cn_object_instance)
			this.object_instances.push(element);
		if (element.constructor == cn_beam)
			this.beams.push(element);
		if (element.constructor == cn_column)
			this.columns.push(element);
		if (element.constructor == cn_pipe)
			this.pipes.push(element);
		if (element.constructor == cn_area_trimming)
			this.area_trimmings.push(element);
		if (element.constructor == cn_facing_trimming)
			this.facing_trimmings.push(element);

		if (element["update_space"]) element["update_space"]();
	}

	//***********************************************************************************
	/**
	 * Add a list of elements
	 * @param {cn_element[]} elements
	 */
	add_elements(elements)
	{
		for (var i in elements)
			this.add_element(elements[i]);
	}

	//***********************************************************************************
	/**
	 * Add layer to draw
	 * @param {Array<cn_layer>} layers
	 */
	add_layer_to_draw(...layers) {
		this.draw_layers.push(...layers);
	}

    //***********************************************************************************
	/**
	 * Remove layer to draw
	 * @param {Array<cn_layer>} layers
	 */
	remove_layer_to_draw(...layers) {
        layers.forEach(layer => {
		    this.draw_layers.splice(this.draw_layers.findIndex(z => z.ID === layer.ID), 1);
        });
	}

    //***********************************************************************************
	/**
	 * Set zone to draw
	 * @param {Array<string>} zones
	 */
	set_zone_to_draw(...zones) {
        this.unset_zone_to_draw();
		this.draw_zones.push(...zones);
	}

    //***********************************************************************************
	/**
	 * Unset zone to draw
	 */
	unset_zone_to_draw() {
        this.draw_zones.length = 0;
	}

    //***********************************************************************************
	/**
	 * Add zone to draw
	 * @param {Array<string>} zones
	 */
	add_zone_to_draw(...zones) {
		this.draw_zones.push(...zones);
	}

    //***********************************************************************************
	/**
	 * Remove zone to draw
	 * @param {Array<string>} zones
	 */
	remove_zone_to_draw(...zones) {
        zones.forEach(zone => {
		    this.draw_zones.splice(this.draw_zones.indexOf(zone), 1);
        });
	}



    //*******************************************************
    /**
     * Find an element by constructor name and index
	 * @param {string} constructor_name
	 * @param {number} index
     * @returns {object}
     */
	 find_element(constructor_name, index) {
        var obj = null;
        if (constructor_name == "cn_vertex")
            obj = this.vertices[index];
        else if (constructor_name == "cn_wall")
            obj = this.walls[index];
		else if (constructor_name == "cn_space")
			obj = this.spaces[index];
		else if (constructor_name == "cn_slab_opening")
			obj = this.slab_openings[index];
		else if (constructor_name == "cn_stairs")
			obj = this.stairs[index];
		else if (constructor_name == "cn_object_instance")
			obj = this.object_instances[index];
		else if (constructor_name == "cn_beam")
			obj = this.beams[index];
		else if (constructor_name == "cn_column")
			obj = this.columns[index];
		else if (constructor_name == "cn_pipe")
			obj = this.pipes[index];
		else if (constructor_name == "cn_area_trimming")
			obj = this.area_trimmings[index];
		else if (constructor_name == "cn_facing_trimming")
			obj = this.facing_trimmings[index];

        if (obj) return obj;
        return null;
    }

    //*******************************************************
    /**
     * returns a kist of all elements in the scene
     * @returns {Array<cn_element>}
     */
	get_elements() {
		return [
			...this.vertices, 
			...this.walls, 
			...this.get_openings(),
			...this.spaces, 
			...this.slab_openings, 
			...this.stairs, 
			...this.beams,
			...this.columns,
			...this.pipes,
			...this.area_trimmings,
			...this.facing_trimmings
		];
	}

    //*******************************************************
    /**
     * Find an element by its id
	 * @param {string} id
     * @returns {object}
     */
	find_element_by_id(id) {
		const obj = this.get_elements().find(e => e.ID == id);
        if (obj) return obj;
        return null;
    }

    _merge_splited_spaces_zones(old_space, new_space) {
        Object.entries(this.building.zones).forEach(([type_zone, zones]) => {
            zones.forEach(zone => {
                const new_rooms = [];
                zone.rooms.filter(room => room.space === old_space.ID && room.storey === this.storey.ID)
                .forEach(room => new_rooms.push({ space: new_space.ID, storey: this.storey.ID }))
                zone.rooms.push(...new_rooms);
            });
        });
    }

	/**
	 * Returns true if the topology date (i.e. creation or removal of walls or vertices) is lower or equal than input date
	 * @param {number} date
	 * @returns {boolean}
	 */
	up_to_date_topology(date) {
		if (this.get_date() <= date) return true;
		return (this.get_date("walls") <= date && this.get_date("vertices") <= date);
	}

	/**
	 * returns true if wall geometry is lower or equal than input date
	 * @param {number} date
	 * @returns {boolean}
	 */
	up_to_date_walls(date) {
		if (this.get_date() <= date) return true;
		if (this.get_date("walls") > date) return false;
		if (this.get_date("vertices") > date) return false;
		if (this.walls.some(w => !w.up_to_date_geometry(date))) return false;
		return true;
	}
}


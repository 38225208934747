"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Rotation handler
//***********************************************************************************
//***********************************************************************************

import {cn_event_handler} from "./cn_event_handler";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_clone, cn_add, cn_normal, cn_dist, cn_dot, cn_mul, cn_normalize, cn_polar, cn_size, cn_sub, cn_cart} from "../utils/cn_utilities";
import {cn_camera} from "./cn_camera";

export class cn_handler_rotation extends cn_event_handler  {
	//*****************************************************************
	/**
	 * Constructor
	 * @param {number[]} center : center of rotation
	 * @param {number} angle : current angle
	 * @param {number} snap_angle : angle snap, in deegrees
	 * @param {number} world_radius : radius, in world metrics. If omitted or <= 0, will used screen radius.
	 * @param {boolean} handle_full_circle : If true, allows the user to handle any point of the circle, and displays an angle pastille.
	 * @param {function} angle_callback : if defined, called when click on the angle pastille
	 */
	constructor(center, angle, snap_angle = 5, world_radius=0, handle_full_circle = false, angle_callback = null) {
		super();
		this.center = cn_clone(center);
		this.angle = angle;
		this.world_radius = world_radius;
		this.handle_full_circle = handle_full_circle;

		this._snap_segments = [];

		this.snap_angle = snap_angle;

		this._mouseover = false;
		this._mouseover_pastille = false;
		this._grabbed = false;
		this._grab_center = false;
		this._grab_local = [0,0];
		this._grab_mouse = [0,0];
		this._radius = 80;

		this._angle_callback = angle_callback;
	}

	//*****************************************************************
	/**
	 * Draw the handler
	 * @param {cn_camera} camera
	 * @returns {string}
	 */
	draw(camera) {
		var html = "";

		//*** Draw rotation handle */
		var p = camera.world_to_screen(this.center);
		var add_classes = (this._mouseover)?" mouseover":" selected";

		//*** Draw rotation circle */
		if (this.world_radius > 0) this._radius = this.world_radius * camera.world_to_screen_scale;
		var circle_class = (this.handle_full_circle)?"paste_circle":"disk_handle_circle";
		if (this.handle_full_circle && this._mouseover) circle_class += " mouseover";
		html += "<circle class='" + circle_class + "' cx='" + p[0] + "' cy='" + p[1] + "' r='" + this._radius + "'/>";

		var pa = cn_mul(cn_cart([1,this.angle*Math.PI/180]),this._radius);
		pa[0] += p[0];
		pa[1] = p[1] - pa[1];
		if (!this.handle_full_circle)
		{
			html += "<line class='disk_handle_line" + add_classes + "' x1='" + p[0] + "' y1='" + p[1] + "' x2='" + pa[0] + "' y2='" + pa[1] + "' />";
			html += "<circle class='handle_vertex" + add_classes + "' cx='" + pa[0] + "' cy='" + pa[1] + "' r='5'/>";
		}
		else
		{
			const mouseover = (this._mouseover_pastille)?" mouseover":"";
			html += "<circle class='angle_label_fill" + mouseover + "' cx='" + pa[0] + "' cy='" + pa[1] + "' r='20' />";
			var aa = this.angle;
			while (aa > 180) aa -= 360;
			while (aa < -180) aa += 360;
			html += "<text class='angle_label_text' x='" + pa[0] + "' y='" + pa[1] + "'>" + aa.toFixed(1) + " °</text>";
		}
		this._angle_position = camera.screen_to_world(pa);
		var best_segment = null;
		var threshold = 0.5;
		for (var ns in this._snap_segments)
		{
			var a = cn_polar(cn_sub(this._snap_segments[ns][0],this._snap_segments[ns][1]))[1]*180/Math.PI;

			for (var niter=0;niter<2;niter++)
			{
				a += 90;
				var da = this.angle - a;
				while (da > 90) da -= 180;
				while (da < -90) da += 180;
				if (Math.abs(da) >= threshold) continue;
				threshold = Math.abs(da);
				best_segment = this._snap_segments[ns];
			}
		}
		if (best_segment)
		{
			var sc0 = camera.world_to_screen(best_segment[0]);
			var sc1 = camera.world_to_screen(best_segment[1]);
			var dir = cn_sub(best_segment[1],best_segment[0]);

			html += "<line class='snap_parallel' x1='" + sc0[0] + "' y1='" + sc0[1] + "' x2='" + sc1[0] + "' y2='" + sc1[1] + "' />";
			var ortho = cn_normal(dir);
			cn_normalize(ortho);
			var x = cn_dot(cn_sub(best_segment[0],this.center),ortho);
			sc0 = camera.world_to_screen(this.center);
			sc1 = camera.world_to_screen(cn_add(this.center,cn_mul(ortho,x)));
			html += "<line class='snap_parallel' x1='" + sc0[0] + "' y1='" + sc0[1] + "' x2='" + sc1[0] + "' y2='" + sc1[1] + "' />";
		}
		return html;
	}

	//*****************************************************************
	/**
	 * Clear move data
	 */
	clear_move() {
		this._mouseover = false;
		this._mouseover_pastille = false;
	}

	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} ev 
	 * @returns  {boolean}
	 */
	click(ev) {
		this.move(ev);
		if (this._mouseover_pastille)
		{
			this._angle_callback();
			return true;
		}
		return false;
	}

	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} ev 
	 * @returns  {boolean}
	 */
	move(ev) {
		this.clear_move();

		const p = ev.camera.world_to_screen(this.center);
		if (this.handle_full_circle)
		{
			if (this._angle_callback)
			{
				var pa = cn_mul(cn_cart([1,this.angle*Math.PI/180]),this._radius);
				pa[0] += p[0];
				pa[1] = p[1] - pa[1];
				this._mouseover_pastille = (cn_dist(pa,ev.mouse_screen) < 20);
			}

			var d = cn_sub(ev.mouse_screen,p);
			this._mouseover = (Math.abs(cn_size(d)-this._radius) < 10);
			return this._mouseover || this._mouseover_pastille;
		}
		else
		{
			const pa = cn_mul(cn_cart([1,this.angle*Math.PI/180]),this._radius);
			pa[0] += p[0];
			pa[1] = p[1] - pa[1];
			this._angle_position = ev.camera.screen_to_world(pa);

			//*** Mouse over the angle ?  */
			if (ev.camera.on_vertex(ev.mouse_world,this._angle_position))
			{
				this._mouseover = true;
				return true;
			}
		}

		return false;
	}

	/**
	 * Manage a grab. To return 'true' if grab is to be managed.
	 * @param {cn_mouse_event} ev 
	 * @returns  {boolean}
	 */
	grab(ev) {
		this._grab_start = cn_clone(ev.mouse_world);
		this._grab_angle = this.angle;
		return this.move(ev);
	}

	/**
	 * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
	 * @param {cn_mouse_event} ev 
	 * @returns  {boolean}
	 */
	drag(ev) {

		//*** Move angle */
		if (!this._mouseover) return false;

		var orientation = 0;
		if (this.handle_full_circle)
		{
			var angle0 = cn_polar(cn_sub(this._grab_start,this.center))[1];
			var angle1 = cn_polar(cn_sub(ev.mouse_world,this.center))[1];
			this._grab_angle += (angle1 - angle0) * 180/Math.PI;
			orientation = this._grab_angle;
			this._grab_start = cn_clone(ev.mouse_world);
		}
		else
		{
			var delta = cn_polar(cn_sub(ev.mouse_world,this.center));
			orientation = delta[1]*180/Math.PI;
		}
		
		this._snap_svg = "";
		if (this._snap_segments.length == 0)
			this.angle = this.snap_angle*Math.round(orientation/this.snap_angle);
		else
		{
			var best_segment = null;
			var threshold = this.snap_angle;
			var snap_angle = 0;
			for (var ns=0;ns<this._snap_segments.length;ns++)
			{
				var a = cn_polar(cn_sub(this._snap_segments[ns][0],this._snap_segments[ns][1]))[1]*180/Math.PI;
				var da = orientation - a;
				while (da > 180) da -= 180;
				while (da < -180) da += 180;
				if (Math.abs(da) < threshold)
				{
					best_segment = this._snap_segments[ns];
					threshold = Math.abs(da);
					snap_angle = orientation - da;
					continue;
				}
				var tt = Math.abs(Math.abs(da) - 90);
				if (tt >= threshold) continue;

				best_segment = this._snap_segments[ns];
				threshold = tt;
				if (da > 0) snap_angle = orientation - da + 90;
				else snap_angle = orientation - da - 90;
			}
			if (best_segment)
				this.angle = snap_angle;
			else
				this.angle = orientation;
		}
		this.call("change");
		return true;
	}
}


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import * as moment from 'moment';

import {
    InformationsPrelevement,
    PolluantPrelevement,
    PrelevementMeta,
} from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';

@Component({
    selector: 'app-prelevement-form-recuperation',
    templateUrl: './prelevement-form-recuperation.component.html',
    styleUrls: ['./prelevement-form-recuperation.component.scss'],
})
export class PrelevementFormRecuperationComponent implements OnInit {
    formRecuperation: FormGroup;

    @Input('diagnostic')
    diagnostic: Diagnostic;
    contenuDiagnostic: PolluantPrelevement;

    @Input('selectedBesoinId')
    selectedBesoinId: string;
    selectedPrelevement: PrelevementMeta;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Output('formChange')
    formChange: EventEmitter<any> = new EventEmitter<any>();

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;

        console.log(this.contenuDiagnostic);

        //Récupération du Prélèvement par l'ID de son Besoin
        this.selectedPrelevement = this.contenuDiagnostic.prelevement.data.besoinsList.find(
            (b) => b.besoinPrelevement.id == this.selectedBesoinId
        ) as PrelevementMeta;

        this.createForm();
    }

    createForm() {
        var infoRecup: InformationsPrelevement;

        if (Object.keys(this.selectedPrelevement.informationsPrelevementRecuperation).length === 0) {
            infoRecup = this.selectedPrelevement.informationsPrelevementPose;
        } else {
            infoRecup = this.selectedPrelevement.informationsPrelevementRecuperation;
        }

        this.formRecuperation = this.formBuilder.group({
            faitParLeClient: [
                this.selectedPrelevement.informationsClientRecuperation.faitParLeClient,
                Validators.required,
            ],
            localisationPrelevement: [
                infoRecup.localisationPrelevement,
                {
                    updateOn: 'blur',
                },
            ],
            lotFiltre: [infoRecup.lotFiltre, Validators.required],
            numeroPompe: [
                infoRecup.numeroPompe,
                {
                    updateOn: 'blur',
                },
            ],
            numeroTete: [
                infoRecup.numeroTete,
                {
                    updateOn: 'blur',
                },
            ],
            dateDebutPrelevement: [moment(infoRecup.dateTimeDebutPrelevement), Validators.required],
            dateFinPrelevement: [moment(infoRecup.dateTimeFinPrelevement), Validators.required],
            debitInitial: [
                infoRecup.debitInitial,
                {
                    updateOn: 'blur',
                },
            ],
            debitFinal: [
                infoRecup.debitFinal,
                {
                    updateOn: 'blur',
                },
            ],
            volume: [
                infoRecup.volume,
                {
                    updateOn: 'blur',
                },
            ],
            remarques: [infoRecup.remarque, Validators.required],

            //Conformité Recuperation
            prelevementReglementaire: [
                this.selectedPrelevement.conformiteRecuperation.prelevementReglementaire,
                Validators.required,
            ],
            justificatifNonReglementaire: [
                this.selectedPrelevement.conformiteRecuperation.justificatifNonReglementaire,
                Validators.required,
            ],
            accreditationCofrac: [
                this.selectedPrelevement.conformiteRecuperation.accreditationCofrac,
                Validators.required,
            ],
        });

        this.formRecuperation.valueChanges.subscribe((newValue) => {
            const formValues = this.formRecuperation.getRawValue();

            //Patch des valeurs du diagnostic avec les valeurs du formulaire
            this.selectedPrelevement.informationsClientRecuperation.faitParLeClient = formValues.faitParLeClient;
            this.selectedPrelevement.informationsPrelevementRecuperation.localisationPrelevement =
                formValues.localisationPrelevement;
            this.selectedPrelevement.informationsPrelevementRecuperation.lotFiltre = formValues.lotFiltre;
            this.selectedPrelevement.informationsPrelevementRecuperation.numeroPompe = formValues.numeroPompe;
            this.selectedPrelevement.informationsPrelevementRecuperation.numeroTete = formValues.numeroTete;
            this.selectedPrelevement.informationsPrelevementRecuperation.dateTimeDebutPrelevement =
                formValues.dateDebutPrelevement;
            this.selectedPrelevement.informationsPrelevementRecuperation.dateTimeFinPrelevement =
                formValues.dateFinPrelevement;
            this.selectedPrelevement.informationsPrelevementRecuperation.debitInitial = formValues.debitInitial;
            this.selectedPrelevement.informationsPrelevementRecuperation.debitFinal = formValues.debitFinal;
            this.selectedPrelevement.informationsPrelevementRecuperation.volume = formValues.volume;
            this.selectedPrelevement.informationsPrelevementRecuperation.remarque = formValues.remarques;

            //Validation stratégie
            this.selectedPrelevement.validationStrategie.justificatifChangement = formValues.justificatifChangement;
            this.selectedPrelevement.validationStrategie.clientPrevenuPar = formValues.clientPrevenuPar;

            this.selectedPrelevement.conformiteRecuperation.prelevementReglementaire =
                formValues.prelevementReglementaire;
            this.selectedPrelevement.conformiteRecuperation.justificatifNonReglementaire =
                formValues.justificatifNonReglementaire;
            this.selectedPrelevement.conformiteRecuperation.accreditationCofrac = formValues.accreditationCofrac;

            this.formChange.emit(this.contenuDiagnostic);
        });
    }

    /**
     * @description Calcul la durée en heures entre le début et la fin du prélèvement
     */
    calculDuree() {
        if (this.formRecuperation == undefined) {
            return '';
        }

        if (
            this.formRecuperation.get('dateDebutPrelevement').value != '' &&
            this.formRecuperation.get('dateFinPrelevement').value != ''
        ) {
            return (
                Math.round(
                    moment
                        .duration(
                            moment(this.formRecuperation.get('dateFinPrelevement').value).diff(
                                moment(this.formRecuperation.get('dateDebutPrelevement').value)
                            )
                        )
                        .asHours()
                ) + 'h'
            );
        }
        return '';
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { InterventionService } from '../../../../services/intervention.service';
import { takeUntil } from 'rxjs/operators';
import { RelationInterventionBien } from '../../../../model/intervention.model';
import { DescriptionBienComponent } from '../description-bien/description-bien.component';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ReferenceApiService } from 'src/app/services/reference-api.service';

@Component({
    selector: 'app-description-bien-page',
    templateUrl: './description-bien-page.component.html',
    styleUrls: ['./description-bien-page.component.scss'],
})
export class DescriptionBienPageComponent extends BaseComponent implements OnInit {
    relationInterventionBien: RelationInterventionBien;

    @ViewChild('descriptionBienComponent') descriptionBienComponent: DescriptionBienComponent;
    currentFilter: any;

    constructor(
        private referenceApiService: ReferenceApiService,
        private interventionService: InterventionService,
        private route: ActivatedRoute,
        private diagnosticService: DiagnosticService
    ) {
        super();
    }

    ngOnInit() {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => (this.currentFilter = diag.typePrestation));
        combineLatest([this.interventionService.getCurrentIntervention(), this.route.paramMap])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, params]) => {
                const idBien = params.get('idBien');
                if (idBien) {
                    this.relationInterventionBien = intervention.relationInterventionBiens.find(
                        (it) => it.bien.id === idBien
                    );
                } else {
                    this.relationInterventionBien = intervention.relationInterventionBiens.find(
                        (it) => it.isBienPrincipal
                    );
                }
            });
    }
}

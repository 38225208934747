"use strict";
//***********************************************************************************
//***********************************************************************************
//**** a tool to build slab openings
//***********************************************************************************
//***********************************************************************************

import {cn_svg_tool_creation} from "./cn_svg_tool_creation";

export class cn_svg_tool_slab_opening_creation extends cn_svg_tool_creation {
	constructor(map) {
		super(map);
	}

}


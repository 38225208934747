"use strict";
//***********************************************************************************
//***********************************************************************************
//**** A handler to manipulate a polygon
//***********************************************************************************
//***********************************************************************************

import {
	cn_add,
	cn_clone,
	cn_dist,
	cn_is_3d_rectangle,
	cn_mul,
	cn_point_on_segment,
	cn_polar,
	cn_project_segment_3d,
	cn_rotate,
	cn_sub,
	cnx_add,
	cnx_build_axis,
	cnx_clone,
	cnx_compute_normal,
	cnx_copy,
	cnx_cross,
	cnx_dist,
	cnx_dot,
	cnx_intersect_line,
	cnx_mul,
	cnx_normalize,
	cnx_sub
} from "../utils/cn_utilities";
import {cn_snap} from "./cn_snap";
import {cn_pastille} from "./cn_pastille";
import {cn_event_handler} from "./cn_event_handler";
import {cn_mouse_event} from "./cn_mouse_event";
import {cn_notification_input, cn_number_input, cn_number_list_input, SEVERITY_NOTIFICATION_ERROR} from "./cn_inputs";
import {cn_measure} from "./cn_measure";

/**
 * Events :
 * - "start_creation" : called on creation handler, when creation has started (becomes modal).
 * - "end_creation" : called when creation is over.
 * - "change" : called whenever the shape changes (creation or edition)
 */
export class cn_polygon_handler extends cn_event_handler  {
	/**
	 * Constructor
	 * @param {cn_event_handler} parent
	 * @param {number[][]} vertices If empty list, this handler will be a construction handler.
	 * @param {boolean} closed_contour
	 */
	constructor(parent=null, vertices = [], closed_contour = true, authorize_new_vertex = true) {
		super(parent);

		this.vertices = vertices.map(vtx => cnx_clone(vtx));

		this.creation_storey = null;
		this.screen_vertices = this.vertices.map(v => [0,0]);
		this.closed_contour = closed_contour;
		this.accept_outside = false;
		this.space = null;
		this.allow_3d_change = false;
		this.behind_storey_element = null;
		this.svg_class = "handle_outline";
		this.display_measures = true;

		this._creation = (this.vertices.length == 0);

		//*** Number of vertices to validate creation. If 0 (default), continue until check pastille is hit. */
		this.valid_creation = 0;
		this._second_vertex = false;
		if (this._creation)
			this.vertices.push([0,0,0]);

		this._rectangle = false;
		this._r_center = [0,0];
		this._r_dx = [1,0];
		this._r_dy = [0,1];
		this._r_width = 1;
		this._r_height = 1;
		this._rectangle_box = null;
		this._min_size = 0;
		this._analyse_rectangle();

		this._mousedown = [0,0];
		this._selected_vertex = -1;
		this._mouseover_vertex = -1;
		this._mouseover_edge = null;

		this._vertex_remove = null;
		this._center = new cn_pastille([0, 0], "arrow_all_white.svg");
		this._check_creation = new cn_pastille([0, 0], "check_white.svg");
		this._check_creation.svg_class='pastille_check';
		this._check_creation.visible = false;

		this._radius = 0;
		this._mouseover_rotation = false;

		this._snap_svg = "";

		this.update();
		this.on_change = null;
		this.on_delete = null;
        this.override_drawing = null;
		this._delete = null;

		this._grab = false;

        this.check_change = null;

		this.snap_elements = [];

		this.allow_creation = null;

		this._do_draw = true;
        this._authorize_new_vertex = authorize_new_vertex;
		this._visible = true;

		this._plane_point = [];
		this._plane_normal = [];

		this._measures = [];
	}

	//*****************************************************************
	/**
	 * Initialize a rectangle creation handler.
	 * @param {cn_event_handler} parent
	 * @param {number} default_size
	 * @returns {cn_polygon_handler}
	 */
	static create_rectangle(parent, default_size=1, min_size = 0.1) {
		var handler = new cn_polygon_handler(parent);
		handler.vertices.push([default_size,0,0]);
		handler.vertices.push([default_size,-default_size,0]);
		handler.vertices.push([0,-default_size,0]);
		handler.closed_contour = true;
		handler._min_size = min_size;
		handler._analyse_rectangle();
		const nor = cnx_cross(handler._r_dx,handler._r_dy);
		if (nor[2] < -0.9)
		{
			handler._r_dy = cnx_mul(handler._r_dy,-1);
			handler._r_height *= -1;
		}
		handler.update();
		return handler;
	}

	//*****************************************************************
	/**
	 * Initialize an open polygon creation handler.
	 * @param {cn_event_handler} parent
	 * @param {number} contour_size : if not 0, defines the number of vertices needed for the shape
	 * @returns {cn_polygon_handler}
	 */
	static create_open_polygon(parent, contour_size=0, authorize_new_vertex = true) {
		var handler = new cn_polygon_handler(parent, [], true, authorize_new_vertex);
		handler.closed_contour = false;
		handler.valid_creation = contour_size;
		handler.update();
		return handler;
	}

	//*****************************************************************
	/**
	 * Initialize a closed polygon creation handler.
	 * @param {cn_event_handler} parent
	 * @returns {cn_polygon_handler}
	 */
	static create_closed_polygon(parent) {
		var handler = new cn_polygon_handler(parent);
		handler.closed_contour = true;
		handler.update();
		return handler;
	}

	//*****************************************************************
	//*** is polygon currently creating ?
	//*****************************************************************
	is_creating() {
        let result = false;
		if (!this._creation) result =  false;
		else if (this._rectangle) result =  (this._second_vertex);
		else result = (this.vertices.length >= 2);
        return result;
	}

	//*****************************************************************
	//*** Is current shape a rectangle ? */
	_analyse_rectangle(){
		this._rectangle = false;
		this._rectangle = cn_is_3d_rectangle(this.vertices);
		if (!this._rectangle) return ;
		this._r_dx = cnx_sub(this.vertices[1],this.vertices[0]);
		this._r_width = cnx_normalize(this._r_dx);
		this._r_dy = cnx_sub(this.vertices[3],this.vertices[0]);
		this._r_height = cnx_normalize(this._r_dy);
	}

	//*****************************************************************
	//*** Draw the handler
	//*****************************************************************
	draw(camera) {
		var html = "";
		if (!this.visible ||!this.active || !this._do_draw) return html;

		var add_classes = "";

		this.screen_vertices = this.vertices.map(v => camera.world_to_screen(v));

		this._visible = !this.screen_vertices.some(v => v.length < 2);
		if (!this._visible) return;

		//*** draw contour
		html += "<path class='" + this.svg_class + "' d='";
		var nb_vertices = this.vertices.length;
		if (this._creation && ((this._check_creation.visible && this._check_creation.mouseover) || this._measures.some(m => m._mouseover>=0)))
			nb_vertices--;
		for (var i=0; i<nb_vertices;i++)
		{
			var p = this.screen_vertices[i];
			if (i == 0) html += "M";
			else html += "L";
			html += " " + p[0] + " " + p[1] + " ";
		}
		if (this.closed_contour || this._rectangle) html += "Z";
		html += "' />";

		//*** Draw vertices
		for (var i=0; i<nb_vertices;i++)
		{
			add_classes = "";
			if (this._selected_vertex == i)
				add_classes = "selected";
			else if (this._mouseover_vertex == i && this._mouseover_edge == null)
				add_classes = "mouseover";
			var p = this.screen_vertices[i];

			if (this._creation && add_classes == "")
				html += "<circle class='handle_vertex " + add_classes + "' cx='" + p[0] + "' cy='" + p[1] + "' r='5'/>";
			else
				html += camera.draw_move_arrow_screen(this.screen_vertices[i],add_classes);
		}

		//*** edge selection
		if (this._mouseover_vertex>= 0 && this._mouseover_edge)
		{
			//*** highlight edge if rectangle */
			if (this._rectangle)
			{
				var p0 = this.screen_vertices[this._mouseover_vertex];
				var p1 = this.screen_vertices[(this._mouseover_vertex+1)%this.vertices.length];
				html += `<line class="handle_line mouseover" x1="${p0[0]}" y1="${p0[1]}" x2="${p1[0]}" y2="${p1[1]}" />`;
				html += camera.draw_line_move_arrow_screen(p0,p1,"mouseover")
			}
			//*** new edge button if exists */
			else if (this._authorize_new_vertex)
			{
				var pp = camera.world_to_screen(this._mouseover_edge);
				html += "<circle class='handle_vertex action' cx='" + pp[0] + "' cy='" + pp[1] + "' r='10'/>";
				html += "<text class='pastille_text' x='" + pp[0] + "' y='" + pp[1] + "'>+</text>";
			}
		}

		//*** Vertex removal button */
		if (this._vertex_remove)
			html += this._vertex_remove.draw(camera);

		//*** Draw measures */
		if (this.display_measures && !this._rectangle)
		{
			const nb_measures = (this.closed_contour)?nb_vertices:nb_vertices-1;
			if (this._measures.length > nb_measures)
				this._measures.splice(nb_measures,this._measures.length - nb_measures);
			while (this._measures.length < nb_measures)
			{
				const m = new cn_measure(this,[0,0,0],[0,0,0]);
				m.set_measure = (value,control) => this._set_measure(m,value,control);
				this._measures.push(m);
			}
			this._measures.forEach((m,i) => {
				m.vertices[0] = this.vertices[(i+1)%nb_vertices];
				m.vertices[1] = this.vertices[i];
				m.offset = ((this.vertices.length==2 && !this._creation) || (i==0 && this._check_creation.visible && this.vertices.length-1==2))?40:true;
				if (this._creation)
					m.selectable = (i == this.vertices.length-2)?-1:cn_measure.selectable.left_and_right;
				else
					m.selectable = cn_measure.selectable.left_and_right;
				html += m.draw_with_highlight(camera);
			});
		}
		else
		{
			this._measures = [];
		}
		if (!this._creation)
		{
			//*** draw delete button
			if (this.on_delete)
			{
				if (this._delete == null)
				{
					this._delete = new cn_pastille(this._center.position, "close_white.svg");
					this._delete.svg_class='pastille_delete';
					this._delete.offset = [30,0];
				}
				html += this._delete.draw(camera);
				this._center.offset = [-30,0];
			}
			else
				this._center.offset = [0,0];

			//*** draw center
			html += this._center.draw(camera);

			//*** draw size box */
			if (this._rectangle)
			{
				if (this._rectangle_box == null)
				{
					this._rectangle_box = new cn_pastille(this._center.position);
					this._rectangle_box.rectangle = [80,30];
					this._rectangle_box.svg_class='pastille_background white';
					this._rectangle_box.text_class='pastille_text black';
					this._rectangle_box.offset = [0,50];
				}
				this._rectangle_box.label = this._r_width.toFixed(2) + " x " + this._r_height.toFixed(2);
				html += this._rectangle_box.draw(camera);
			}

			//*** draw rotation circle */
			if (!camera.is_3d())
			{
				this._radius = 0;
				this.vertices.forEach(v => {
					var d = cn_dist(this._center.position,v);
					if (d > this._radius) this._radius = d;
				});
				this._radius *= 1.3;
				// @ts-ignore
				var p = camera.world_to_screen(this._center.position);
				html += `<circle class="disk_handle_circle ${(this._mouseover_rotation)?"mouseover":""}" cx="${p[0]}" cy="${p[1]}" r="${this._radius * camera.world_to_screen_scale}" />`;
			}
		}
		//*** Creation and not rectangle : add check button */
		else if (!this._rectangle && this.valid_creation == 0)
		{
			if (this._check_creation.visible)
			{
				var pos = [0,0];
				for (var i =0;i<this.vertices.length-1;i++) pos = cn_add(pos,this.vertices[i]);
				pos = cn_mul(pos,1/(this.vertices.length-1));
				this._check_creation.position = pos;
				html += this._check_creation.draw(camera);
			}
		}

		html += this._snap_svg;
        if (this.override_drawing) {
            html = this.override_drawing(camera, html);
        }
		return html;
	}

	_set_measure(measure, value, control) {
		if (value < 0.1) return false;
		const index = this._measures.indexOf(measure);
		const delta = (control == 2)?-1:1;
		var im = (control == 2)?index:index+1;
		var i0 = im-delta;
		var im1 = im+delta;
		var im2 = im1 + delta;
		const nb_vertices = (this.closed_contour)?this._measures.length:this._measures.length+1;
		function force_range(x) {
			if (x < 0) return x + nb_vertices;
			if (x >= nb_vertices) return x - nb_vertices;
			return x;
		}
		if (this.closed_contour)
		{
			im = force_range(im);
			i0 = force_range(i0);
			im1 = force_range(im1);
			im2 = force_range(im2);
		}
		console.log("points",i0,im,im1,im2);
		var dir = cnx_sub(this.vertices[im],this.vertices[i0]);
		const prev_value = cnx_normalize(dir);
		const new_pos = cnx_add(this.vertices[i0],cnx_mul(dir,value));
		if (im1>=0 && im1 < nb_vertices)
		{
			if (im2>=0 && im2 < nb_vertices)
			{
				const d0 = cnx_sub(this.vertices[im],this.vertices[im1]);
				const d1 = cnx_sub(this.vertices[im1],this.vertices[im2]);
				const inters = cnx_intersect_line(new_pos,d0,this.vertices[im2],d1);
				if (inters) this.vertices[im1] = inters;
				else return false;
			}
			else
			{
				this.vertices[im1] = cnx_add(this.vertices[im1],cnx_mul(dir,value-prev_value))
			}
		}
		this.vertices[im] = new_pos;
		this.update();
		this.call("force_update");
		return true;

	}
	//*****************************************************************
	//*** Clear move data
	//*****************************************************************
	clear_move() {
		this._mouseover_vertex = -1;
		this._mouseover_edge = null;

		this._center.mouseover = false;
		if (this._delete)
			this._delete.mouseover = false;
		if (this._rectangle_box)
			this._rectangle_box.mouseover = false;

		this._check_creation.mouseover = false;
		if (this._vertex_remove)
			this._vertex_remove.mouseover = false;

		this._mouseover_rotation = false;

		this._measures.forEach(m => m.clear_move());
	}

	//*****************************************************************
	/**
	 * Passive move event
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	move(mouse_event) {
		this.clear_move();
		if (!this._check(mouse_event)) return true;

		var mouse_world_position = this._compute_mouse_world(mouse_event);

		//*** Creation mode
		if (this._creation)
		{
			this._selected_vertex = this.vertices.length-1;
			if (this._rectangle) this._selected_vertex = (this._second_vertex)?2:0;
			this._mouseover_vertex = -1;
			this._check_creation.visible = false;
			if (!this._rectangle)
			{
				if (this.closed_contour)
					this._check_creation.visible = this.vertices.length > 3;
				else
					this._check_creation.visible = this.vertices.length > 2;
			}
			if (this._check_creation.visible)
			{
				if (this.check_change)
				{
					const last_vertex = this.vertices[this.vertices.length-1];
					this.vertices.splice(this.vertices.length-1);
					this._check_creation.visible = this.check_change();
					this.vertices.push(last_vertex);
				}
				if (this._check_creation.visible)
				{
					this._check_creation.mouseover = this._check_creation.contains_screen(mouse_event.mouse_screen, mouse_event.camera);
					if (this._check_creation.mouseover) return true;
				}
			}
			if (this._measures.some(m => m.move(mouse_event)))
				return true;
			return this.drag(mouse_event);
		}

		//*** Is mouse above the center ? */
		this._center.mouseover = this._center.contains_screen(mouse_event.mouse_screen, mouse_event.camera);
		if (this._center.mouseover) return true;

		//*** Is mouse over the delete button ? */
		if (this._delete)
		{
			this._delete.mouseover = this._delete.contains_screen(mouse_event.mouse_screen, mouse_event.camera);
			if (this._delete.mouseover) return true;
		}

		//*** Is mouse over the rectangle button ? */
		if (this._rectangle_box && this._rectangle_box.visible)
		{
			this._rectangle_box.mouseover = this._rectangle_box.contains_screen(mouse_event.mouse_screen, mouse_event.camera);
			if (this._rectangle_box.mouseover) return true;
		}

		//*** Is mouse over a vertex remove button ? */
		if (this._vertex_remove)
		{
			this._vertex_remove.mouseover = this._vertex_remove.contains_screen(mouse_event.mouse_screen, mouse_event.camera);
			if (this._vertex_remove.mouseover) return true;
		}

		//*** Check if mouse is over a vertex
		for (var i=0; i<this.screen_vertices.length;i++)
		{
			if (mouse_event.under_mouse_screen(this.screen_vertices[i]))
			{
				this._mouseover_vertex = i;
				return true;
			}
		}

		//*** Check if mouse is over an edge

		const vertices_length=(this.closed_contour || this._rectangle)?this.screen_vertices.length:this.screen_vertices.length-1;
		for (var i=0; i<vertices_length;i++)
		{
			var vnext = (i+1<this.screen_vertices.length)?this.screen_vertices[i+1]:this.screen_vertices[0];
			if (!cn_point_on_segment(mouse_event.mouse_screen,this.screen_vertices[i],vnext,mouse_event.camera.snap_screen_distance))
				continue;
			this._mouseover_vertex = i;
			vnext = (i+1<this.screen_vertices.length)?this.vertices[i+1]:this.vertices[0];
            this._mouseover_edge = cn_project_segment_3d(mouse_world_position,this.vertices[i],vnext);
			return true;
		}

		//*** Is mouse over rotation circle ? */
		if (!mouse_event.camera.is_3d() && Math.abs(cn_dist(this._center.position,mouse_event.mouse_world)-this._radius) < mouse_event.camera.snap_world_distance)
		{
			this._mouseover_rotation = true;
			return true;
		}

		if (this._measures.some(m => m.move(mouse_event)))
			return true;
		return false;
	}

	//*****************************************************************
	/**
	 * Grab event
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	grab(mouse_event) {
		if (!this._check(mouse_event)) return true;

		this.move(mouse_event);
        this.screen_vertices = this.vertices.map(v => mouse_event.camera.world_to_screen(v));

		this._grab = true;

		//*** Maybe end of creation by checking ?
		if (this._creation)
		{
			if (this._check_creation.visible && this._check_creation.mouseover)
			{
				this.vertices.splice(this.vertices.length-1);
				this._creation = false;
				this.call("end_creation", mouse_event);
				this.update();
				return true;
			}

			if (this._rectangle && !this._second_vertex)
			{
				this._second_vertex = true;
				this._mouseover_vertex = 2;
				this._selected_vertex = 2;
				if (mouse_event.camera.is_3d())
				{
					this.creation_storey = mouse_event.impact.storey_element.storey;
					this._plane_normal = mouse_event.impact.normal;
					this._plane_point = cnx_add(mouse_event.impact.position, cnx_mul(this._plane_normal,0.01));
				}
				else
				{
					this.creation_storey = mouse_event.storey;
					this._plane_normal = [0,0,1];
					this._plane_point = cnx_clone(mouse_event.mouse_world);
				}
				this.call("start_creation");
				this.update();
			}
			return true;
		}

		//*** maybe we rotate the shape ? */
		if (this._mouseover_rotation)
		{
			this._mousedown = cn_clone(mouse_event.mouse_world);
			return true;
		}

		//*** maybe we move the shape ?
		if (this._center.mouseover)
		{
			this._vertex_remove = null;
			this._selected_vertex = -1;
			return true;
		}

		//*** maybe we delete the shape ?
		if (this._delete && this._delete.mouseover)
		{
			this.on_delete();
			return true;
		}

		//*** maybe we click on rectangle box the shape ?
		if (this._rectangle_box && this._rectangle_box.visible && this._rectangle_box.mouseover)
		{
			const obj = this;
			const input = new cn_number_list_input("Dimensions");
			input.values.push(new cn_number_input("Largeur",this._r_width,"m",2, 0.1,100));
			input.values.push(new cn_number_input("Longueur",this._r_height,"m",2, 0.1,100));
			input.callback = function() {
				if (Math.abs(input.values[0].value) < 0.1 || Math.abs(input.values[1].value)<0.1) return false;

				var dx = cnx_mul(obj._r_dx,0.5 * (Math.abs(input.values[0].value)-obj._r_width));
				var dy = cnx_mul(obj._r_dy,0.5 * (Math.abs(input.values[1].value)-obj._r_height));
				const old_vertices = obj.vertices.map(cnx_clone);
				for (var k=0;k<3;k++)
				{
					obj.vertices[0][k] -= dx[k] + dy[k];
					obj.vertices[1][k] += dx[k] - dy[k];
					obj.vertices[2][k] += dx[k] + dy[k];
					obj.vertices[3][k] += -dx[k] + dy[k];
				}

				if (obj.check_change && !obj.check_change())
				{
					obj.vertices = old_vertices;
					obj.call("notification_input", new cn_notification_input("Dimensions non valides", SEVERITY_NOTIFICATION_ERROR));
					return false;
				}
				obj.update();
				obj.call("force_update");
				return true;
			}
			this.call("number_list_input",input);
			return true;
		}

		//*** maybe we remove the vertex ?
		if (this._vertex_remove && this._vertex_remove.mouseover)
		{
			this.vertices.splice(this._selected_vertex,1);
			this._mouseover_vertex = -1;
			this._selected_vertex = -1;
			this._vertex_remove = null;
			this.update();
			return true;
		}

		//*** Maybe a new point ?
		if (this._mouseover_vertex >= 0 && this._mouseover_edge && this._authorize_new_vertex && !this._rectangle)
		{
			this._mouseover_vertex++;
			this.vertices.splice(this._mouseover_vertex,0,this._mouseover_edge);
			this._mouseover_edge = null;
			this.update();
		}

		//*** remove vertex */
		this._selected_vertex = this._mouseover_vertex;
		if (this._selected_vertex >= 0 && !this._rectangle && (this.vertices.length > 3 || (this.vertices.length > 2 && !this.closed_contour)))
		{
			this._vertex_remove = new cn_pastille(this.vertices[this._selected_vertex],"-");
			this._vertex_remove.small = true;
			this._vertex_remove.selected = true;
			this._vertex_remove.offset = [40,0];
		}
		else
			this._vertex_remove = null;

		return (this._selected_vertex >= 0 || this._measures.some(m => m._mouseover >= 0));
	}

	//*****************************************************************
	/**
	 * Click event
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	click(mouse_event) {
		if (this._measures.some(m => m.click(mouse_event)))
			return true;
		return this.drop(mouse_event);
	}

	//*****************************************************************
	/**
	 * Drop event
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	drop(mouse_event) {
		if (!this._check(mouse_event)) return true;

		this._snap_svg = "";
		if (!this._grab) return false;
		this._grab = false;

		if (!this._creation) return false;

		//*** Special case for rectangle */
		if (this._rectangle)
		{
			if (this._second_vertex && !mouse_event.under_mouse_screen(this.screen_vertices[0], 2))
			{
				this._creation = false;
				this.call("end_creation", mouse_event);
			}
			this.update();
			return true;
		}

		//*** is last point on a given vertex ?
		var dropped_vertex = -1;
		for (var i=0; i<this.vertices.length -1;i++)
		{
			if (!mouse_event.under_mouse_screen(this.screen_vertices[i]))
				continue;
			dropped_vertex = i;
			break;
		}

		//*** nothing, we continue the contour
		if (dropped_vertex < 0)
		{
			var last_vertex = cnx_clone(this.vertices[this.vertices.length-1]);
			this.vertices.push(last_vertex);
			if (this.vertices.length == 2)
			{
				if (mouse_event.camera.is_3d())
				{
					this.creation_storey = mouse_event.impact.storey_element.storey;
					this._plane_normal = mouse_event.impact.normal;
					this._plane_point = cnx_add(mouse_event.impact.position, cnx_mul(this._plane_normal,0.01));
				}
				else
				{
					this.creation_storey = mouse_event.storey;
					this._plane_normal = [0,0,1];
					this._plane_point = cnx_clone(mouse_event.mouse_world);
				}
				this.call("start_creation");
			}

			//*** Maybe we just created the last vertex ? */
			if (this.vertices.length == this.valid_creation +1)
			{
				this.vertices.splice(this.vertices.length-1);
				this._creation = false;
				this.call("end_creation", mouse_event);
				this.update();
			}
			return true;
		}

		//*** End condition
		if (this.closed_contour)
		{
			//*** we must go back to first or last vertex
			if ((dropped_vertex != 0 && dropped_vertex != this.vertices.length -2) || this.vertices.length < 4)
				return false;
		}
		else
		{
			//*** we must reselect last vertex.
			if (dropped_vertex != this.vertices.length -2 || this.vertices.length < 3)
				return false;
		}

		this.vertices.splice(this.vertices.length-1);
		this._creation = false;
		this.call("end_creation", mouse_event);
		this.update();
		return true;
	}

	//*****************************************************************
	/**
	 * Drag event
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	 drag(mouse_event) {

		var mouse_world_position = this._compute_mouse_world(mouse_event);

		var obj = this;
		function not_on_plane(point, normal) {
			if (cnx_dist(normal, obj._plane_normal) > 0.001) return true;
			const pp = cnx_add(point,cnx_mul(normal,0.01));
			if (Math.abs(cnx_dot(obj._plane_normal,cnx_sub(pp,obj._plane_point))) > 0.01) return true;
			return false;
		}

		//*** Move the shape
		if (this._center.mouseover)
		{
			var offset = [0,0,0];

			//*** Special case for 3D view */
			if (mouse_event.camera.is_3d())
			{
				const impact_behind = (this.behind_storey_element)?mouse_event.first_impact_behind(this.behind_storey_element):mouse_event.impact_behind;
				//*** Maybe we are allowed to change the 3d plane ? */
				if (this.allow_3d_change && impact_behind && impact_behind.storey_element &&
					((impact_behind.storey_element.storey != this.creation_storey) || not_on_plane(impact_behind.position,impact_behind.normal)))
				{
					var old_vertices = this.vertices;
					var old_plane_normal = this._plane_normal;
					var old_plane_point = this._plane_point;
					this._change_plane(impact_behind.position,impact_behind.normal);

					if (this.check_change && !this.check_change())
					{
						this.vertices = old_vertices;
						this._plane_normal = old_plane_normal;
						this._plane_point = old_plane_point;
					}
					else
					{
						console.log("current storey",impact_behind.storey_element.storey);
						this.creation_storey = impact_behind.storey_element.storey;
						this.update();
					}

					return true;
				}
				else
					offset = cnx_sub(mouse_world_position,this._center.position);
			}
			else
			{
				var snap = new cn_snap(mouse_event.mouse_world,mouse_event.camera.snap_world_distance,this._center.position,mouse_event.camera);
				snap.snap_elements = this.snap_elements;
				snap.snap_translation(this.vertices);
				offset = cn_sub(snap.position,this._center.position);
				offset.push(0);
			}
			var old_vertices = this.vertices;

			let transform = function (v) {return cnx_add(v,offset);};
			this.vertices = this.vertices.map(v => transform(v));

			if (this.check_change && !this.check_change())
				this.vertices = old_vertices;
			else
				this.update(transform);

			return true;
		}

		//*** Rotate the shape */
		if (this._mouseover_rotation)
		{
			var snap = new cn_snap(mouse_event.mouse_world,mouse_event.camera.snap_world_distance,this._mousedown,mouse_event.camera);
			snap.snap_elements = this.snap_elements;
			snap.snap_rotation(this._center.position,this.vertices);
			this._snap_svg = snap.svg;
			const angle = cn_polar(cn_sub(snap.position,this._center.position))[1] - cn_polar(cn_sub(this._mousedown,this._center.position))[1];

			let transform = function (v) {return cn_rotate(v,obj._center.position,angle);};
			this.vertices = this.vertices.map(v => transform(v));

			this.update(transform);
			this._mousedown = cn_clone(snap.position);
			return true;
		}

		//*** manage rectangle drag */
		if (this._rectangle)
		{
			if (!mouse_event.camera.is_3d())
			{
				var snap = new cn_snap(mouse_event.mouse_world,mouse_event.camera.snap_world_distance,null,mouse_event.camera);
				snap.snap_elements = this.snap_elements;
				snap.snap_point();
				snap.check_grid();
				mouse_world_position = cnx_clone(snap.position);
			}

			if (this._creation)
			{
				//*** in 3D, we initiate only on something valid */
				if (!this._second_vertex && mouse_event.camera.is_3d() && (!mouse_event.impact || mouse_event.impact.storey_element == null))
				{
					this._do_draw = false;
					return false;
				}

				var old_vertices = this.vertices.map(cnx_clone);

				if (this._second_vertex)
				{
					var d = cnx_sub(mouse_world_position,this.vertices[0]);
					var w = cnx_dot(d,this._r_dx);
					var h = cnx_dot(d,this._r_dy);
					if (Math.abs(w)>this._min_size)
						this._r_width = w;
					else if (w > 0)
						this._r_width = this._min_size;
					else
						this._r_width = -this._min_size;
					if (Math.abs(h)>this._min_size)
						this._r_height = h;
					else if (h > 0)
						this._r_height = this._min_size;
					else
						this._r_height = -this._min_size;
				}
				else
				{
					if (mouse_event.camera.is_3d())
					{
						this._plane_point = cnx_add(mouse_event.impact.position,cnx_mul(mouse_event.impact.normal,0.01));
						this._plane_normal = mouse_event.impact.normal;
						mouse_world_position = this._compute_mouse_world(mouse_event);
						if (Math.abs(this._plane_normal[2]) > 0.9)
							this._r_dx = cnx_cross(this._plane_normal,[0,-1,0]);
						else
							this._r_dx = cnx_cross([0,0,1],this._plane_normal);
						cnx_normalize(this._r_dx);
						this._r_dy = cnx_cross(this._plane_normal,this._r_dx);
						this.creation_storey = mouse_event.impact.storey_element.storey;
					}

					this.vertices[0] = cnx_clone(mouse_world_position);
				}

				if ((this._r_width > 0) == (this._r_height > 0))
				{
					this.vertices[1] = cnx_add(this.vertices[0],cnx_mul(this._r_dx,this._r_width));
					this.vertices[2] = cnx_add(this.vertices[1],cnx_mul(this._r_dy,this._r_height));
					this.vertices[3] = cnx_add(this.vertices[0],cnx_mul(this._r_dy,this._r_height));
				}
				else
				{
					this.vertices[1] = cnx_add(this.vertices[0],cnx_mul(this._r_dy,this._r_height));
					this.vertices[2] = cnx_add(this.vertices[1],cnx_mul(this._r_dx,this._r_width));
					this.vertices[3] = cnx_add(this.vertices[0],cnx_mul(this._r_dx,this._r_width));
				}
				if (this.check_change && !this.check_change())
				{
					console.log("wrong change");
					this.vertices = old_vertices;
				}
				else
					this.update();

				return true;
			}

			if (this._selected_vertex >= 0 && this._mouseover_edge)
			{
				var old_vertices = this.vertices.map(cnx_clone);
				const i0 = this._selected_vertex;
				const i1 = (this._selected_vertex+1)%4;
				const i2 = (this._selected_vertex+2)%4;
				const i3 = (this._selected_vertex+3)%4;
				const trans = (i0&1)?this._r_dx:this._r_dy;
				const w = cnx_dot(trans,cnx_sub(mouse_world_position,this.vertices[i3]));
				if (Math.abs(w) < 0.01) return true;

				this.vertices[i0]= cnx_add(this.vertices[i3],cnx_mul(trans,w));
				this.vertices[i1]= cnx_add(this.vertices[i2],cnx_mul(trans,w));

				if (this._r_width < this._min_size || this._r_height < this._min_size || (this.check_change && !this.check_change()))
					this.vertices = old_vertices;
				else
					this.update();
				return true;
			}
		}

		if (this._selected_vertex < 0) return false;

		//*** Special treatment for 3D */
		if (mouse_event.camera.is_3d())
		{
			const old_vertex = this.vertices[this._selected_vertex];
			this.vertices[this._selected_vertex] = mouse_world_position;
			if (this.check_change && !this.check_change())
				this.vertices[this._selected_vertex] = old_vertex;
			else
			{
				this.update();
			}
			return true;
		}

		//*** Compute points around
		var points = [null,null,null,null,null];
		var sz = this.vertices.length;
		for (var k=0;k<5;k++)
		{
			var i = this._selected_vertex - 2 + k;
			points[k] = this.vertices[(i+sz)%sz];
		}

		this._snap_svg = "";
		var snap = new cn_snap(mouse_world_position,mouse_event.camera.snap_world_distance,null,mouse_event.camera);
		snap.snap_elements = this.snap_elements;

		//*** in creation mode, snap on first and last vertices
		if (this._creation)
		{
			if (this.closed_contour)
			{
				if (this.vertices.length > 3)
					snap.check_point(this.vertices[0]);
			}
			else
			{
				if (this.vertices.length >= 3)
					snap.check_point(this.vertices[this.vertices.length-2]);
			}
		}

		//*** try to snap on elements around
		snap.snap_point();

		if (this.vertices.length < 2)
		{
			points[2][0] = snap.position[0];
			points[2][1] = snap.position[1];
			this._snap_svg = snap.svg;
			this.update();
			if (this.check_change) this.check_change();
			return true;
		}

		//*** try to snap on self
		if (this.vertices.length > 2)
		{
			if (points[0] && points[1] && snap.freedoms > 0)
			{
				snap.previous_point = points[1];
				snap.check_angles(points[0],points[1],45);
			}
			if (points[3] && points[4] && snap.freedoms > 0)
			{
				snap.previous_point = points[3];
				snap.check_angles(points[3],points[4],45);
			}
		}

		if (!this.closed_contour)
		{
			for (var k=0;k<5;k++)
			{
				var i = this._selected_vertex - 2 + k;
				if (i < 0 || i >= sz)
					points[k] = null;
			}
		}

		var point_before = cn_clone(points[2]);
		points[2][0] = snap.position[0];
		points[2][1] = snap.position[1];
		if (this.check_change)
		{
			if (!this.check_change())
			{
				points[2][0] = point_before[0];
				points[2][1] = point_before[1];
				return false;
			}
		}
		this._snap_svg = snap.svg;
		this.update();
		return true;
	}

	_compute_mouse_world(mouse_event) {
		if (!mouse_event.camera.is_3d())
		{
			return cnx_clone(mouse_event.mouse_world);
		}

		var mouse_world_position = cnx_clone(mouse_event.mouse_world);
		this._compute_plane();
		const dot = cnx_dot(mouse_event.ray.direction,this._plane_normal);
		if (Math.abs(dot) > 0.001)
		{
			const x = cnx_dot(this._plane_normal,cnx_sub(this._plane_point,mouse_event.ray.origin)) / dot;
			mouse_world_position = cnx_add(mouse_event.ray.origin,cnx_mul(mouse_event.ray.direction,x));
		}
		return mouse_world_position;
	}

	//***********************************************************************************
	/**
	 * Sets size of current shape if rectangular
	 * @param {number[]} sz
	 * @return {boolean}
	 */
	 set_rectangle_size(sz) {
		if (!this._rectangle) return false;
		if (Math.abs(sz[0]) < 0.1 || Math.abs(sz[1])<0.1) return false;

		var dx = cnx_mul(this._r_dx,0.5 * (Math.abs(sz[0])-this._r_width));
		var dy = cnx_mul(this._r_dy,0.5 * (Math.abs(sz[1])-this._r_height));
		for (var k=0;k<3;k++)
		{
			this.vertices[0][k] -= dx[k] + dy[k];
			this.vertices[1][k] += dx[k] - dy[k];
			this.vertices[2][k] += dx[k] + dy[k];
			this.vertices[3][k] += -dx[k] + dy[k];
		}

		console.log("after:",this.vertices.map(v => cnx_clone(v)));
		this.update();
		return true;
	}

	//*****************************************************************
	//*** Update position
	//*****************************************************************
	update(transform = null) {
		if (this._creation) return;
		var cc = [0,0,0];
		this.vertices.forEach(v => cc = cnx_add(cc,v));
		//*** We use copy, as the vector itself is referenced by other pastilles */
		cnx_copy(cnx_mul(cc,1/this.vertices.length), this._center.position);
		this._analyse_rectangle();
		if (this.on_change) this.on_change();
		this.call("change",transform);
	}

	//*****************************************************************
	//*** check new position
	//*****************************************************************
	_check(mouse_event) {
		this._do_draw = true;
		if (!this._visible) return false;
		if (!this._creation) return true;
		if (this.is_creating()) return true;
		if (this._rectangle && !this._second_vertex && mouse_event.camera.is_3d() && (!mouse_event.impact || mouse_event.impact.storey_element == null))
		{
			this._do_draw = false;
			return false;
		}
		if (this.allow_creation)
			if (!this.allow_creation(mouse_event))
			{
				this._do_draw = false;
				return false;
			}
		return true;
	}

	/**
	 * Compute 3D plane of the polygon
	 * @param {boolean} force
	 * @returns
	 */
	_compute_plane(force = false)
	{
		if (this.vertices.length < 3) return;
		if (this._plane_normal.length > 0) return;
		this._plane_point = cnx_clone(this.vertices[0]);
		this._plane_normal = cnx_compute_normal(this.vertices);
	}

	_change_plane(point, normal) {
		const dx = [0,0,0];
		const dy = [0,0,0];
		cnx_build_axis(this._plane_normal,dx,dy);

		var center = [0,0,0];
		this.vertices.forEach(v => center = cnx_add(center,v));
		center = cnx_mul(center,1/this.vertices.length);

		const local_vertices = this.vertices.map(v => [cnx_dot(cnx_sub(v,center),dx), cnx_dot(cnx_sub(v,center),dy)]);

		this._plane_normal = normal;
		this._plane_point = cnx_add(point, cnx_mul(normal,0.01));
		cnx_build_axis(this._plane_normal,dx,dy);

		this.vertices = local_vertices.map(v => cnx_add(this._plane_point,cnx_add(cnx_mul(dx,v[0]),cnx_mul(dy,v[1]))));
	}
}


import { Besoin } from './besoin.model';
import { MpcaItemOld } from './mpca.model.old';
import { Surface } from './surface.model';
import { MongoUtils } from 'src/app/commons-lib';

export class Zone {
    id?: string;
    identifiant?: string = '';
    // lettre?: string = 'A';
    nom?: string = '';
    listeMpca?: MpcaItemOld[] = [];
    listeSurfaces?: Surface[] = [new Surface()];
    usageLocaux?: string = '';
    activite?: string = '';
    typeActivite?: string = '';
    objectifMesurage?: string[] = [];
    ventilation?: string[] = [];
    environnement?: string[] = [];
    frequentation?: string = '';
    simulation?: string = '';
    personnesPresentes?: boolean = false;
    activiteAdjacente?: string = '';
    confinement?: string = '';
    nombreExtracteurs?: number = 0;
    sasMateriel?: string = '';
    sasPersonnel?: string = '';
    activiteAmiante?: string = '';
    besoinPrelevements?: Besoin[] = [];
    zoneContexte?: string = '';
    typeZone?: string = '';
    zse?: string = '';
    idZoneAssociee?: string = '';
    zoneAssociee?: Zone = null;

    public constructor(zone: Zone = {}) {
        if (zone?.id != null && zone?.id != '') {
            this.id = zone.id;
            this.identifiant = zone.identifiant;
            this.nom = zone.nom;
            this.listeMpca = zone.listeMpca;
            this.listeSurfaces = zone.listeSurfaces;
            this.usageLocaux = zone.usageLocaux;
            this.activite = zone.activite;
            this.typeActivite = zone.typeActivite;
            this.objectifMesurage = zone.objectifMesurage;
            this.ventilation = zone.ventilation;
            this.environnement = zone.environnement;
            this.frequentation = zone.frequentation;
            this.simulation = zone.simulation;
            this.personnesPresentes = zone.personnesPresentes;
            this.activiteAdjacente = zone.activiteAdjacente;
            this.confinement = zone.confinement;
            this.nombreExtracteurs = zone.nombreExtracteurs;
            this.sasMateriel = zone.sasMateriel;
            this.sasPersonnel = zone.sasPersonnel;
            this.activiteAmiante = zone.activiteAmiante;
            this.besoinPrelevements = zone.besoinPrelevements;
            this.zoneContexte = zone.zoneContexte;
            this.typeZone = zone.typeZone;
            this.zse = zone.zse;
            this.idZoneAssociee = zone.idZoneAssociee;
            this.zoneAssociee = zone.zoneAssociee;
        } else {
            // Génère un id aléatoire lorsqu'on instancie un niveau
            this.id = MongoUtils.generateObjectId();
        }
    }
}

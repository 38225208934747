"use strict";

import { cn_data_extension } from './cn_data_extension';
import { cn_space_extension } from './cn_space_extension';
import { cn_zone_extension_default } from './impl/cn_zone_extension._default';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** extension singleton instance
//***********************************************************************************
//***********************************************************************************

class cn_extension {

   constructor() {
       this.space = new cn_space_extension();
       this.data = new cn_data_extension();
       this.zone = new cn_zone_extension_default();
       this.features = [];
   }

}

export const extension_instance = new cn_extension();



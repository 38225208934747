import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RelationInterventionBien } from '../model/intervention.model';

@Injectable({
    providedIn: 'root',
})
export class RelationInterventionBienApiService {
    public resourceUrl = `${environment.apiUrl}/relation_intervention_biens`;

    private http: HttpClient;

    constructor(private readonly httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    synchroniserRelation(idRelation: string): Observable<RelationInterventionBien> {
        return this.http.get<RelationInterventionBien>(`${this.resourceUrl}/synchroniser/${idRelation}`);
    }
}

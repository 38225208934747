import { Injectable } from '@angular/core';
import { NotificationService, HttpErrorService } from 'src/app/commons-lib';
import { SynchronizationService } from './synchronization.service';
import { RelationInterventionBienApiService } from './relation-intervention-bien-api.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class RelationInterventionBienService {
    constructor(
        private readonly synchronizationService: SynchronizationService,
        private readonly relationInterventionBienApiService: RelationInterventionBienApiService,
        private readonly notificationService: NotificationService
    ) {}

    synchroniserRelation(idRelation: string, callback?: any) {
        this.synchronizationService
            .getSyncState()
            .pipe(
                switchMap((state) => {
                    if (state) {
                        return this.relationInterventionBienApiService.synchroniserRelation(idRelation);
                    } else {
                        this.notificationService.error(
                            `Vous devez être en ligne pour synchroniser le bien de l'intervention`
                        );
                        return of(null);
                    }
                })
            )
            .subscribe((newRelation) => {
                callback(newRelation);
            });
    }
}

import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/commons-lib';
import { combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Prestation } from 'src/app/model/prestation.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { TypeReport } from 'src/app/model/reference-prestation.model';

@Component({
    selector: 'app-rapport',
    templateUrl: './rapport.component.html',
    styleUrls: ['./rapport.component.scss'],
})
export class RapportComponent extends BaseComponent implements OnInit {
    diagnostic: Diagnostic;
    prestation: Prestation;

    readonly enumTypeReport = TypeReport;

    constructor(
        private interventionService: InterventionService,
        private etatProgressionService: EtatProgressionService,
        private route: ActivatedRoute,
        private diagnosticService: DiagnosticService
    ) {
        super();
    }

    ngOnInit(): void {
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diag]) => {
                this.diagnostic = diag;
                const prestDiag = intervention.prestationsDiagnostics.find(
                    (prest) => prest.idDiagnostic === this.diagnostic.id
                );
                if (prestDiag) {
                    this.prestation = prestDiag.prestation;
                }
                this.checkValidity();
            });
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.diagnosticService.getCurrentDiagnosticValue());
    }
}

// @ts-check
"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Balcony type
//***********************************************************************************
//***********************************************************************************

import {cn_element_type} from "./cn_element_type";
import {cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_tab} from "./cn_configuration";
import {fh_add, fh_clone, fh_cross, fh_extruded_polygon, fh_mul, fh_normalize, fh_polygon, fh_sub} from "@acenv/fh-3d-viewer";
import {cn_element_type_visitor} from '..';

var CN_BT_MIDDLE = 0;
var CN_BT_OUTER = 1;
var CN_BT_INNER = 2;

var CN_BT_CATEGORY_LIST = ["railing", "wall"];

export class cn_balcony_type extends cn_element_type {
    constructor() {
        super();
        this.name = "";
        this.height = 1.1;
        this.thickness = 0.1;
        this.category = "railing";

        this.free = false;
    }

    //***********************************************************************************
    get_generic_label() {
        return "Type de balcon";
    }

    //***********************************************************************************
    /**
     * Clone
     * @returns {cn_element_type}
     */
    clone() {
        var c = new cn_balcony_type();
        c.name = this.name;
        c.height = this.height;
        c.thickness = this.thickness;
        c.category = this.category;
        return c;
    }

    //***********************************************************************************
    //**** keys
    //***********************************************************************************
    model_keys() {
        return ["name", "height", "thickness", "category"];
	}

	//***********************************************************************************
	//**** set category
	//***********************************************************************************
	set_category(category)
	{
		if (this.category == category) return;
		this.category = category;
		if (this.category == "railing")
		{
			this.thickness = 0.1;
			this.height = 1.1;
			return;
		}
		if (this.category == "wall")
		{
			this.thickness = 0.2;
			this.height = 1.1;
			return;
		}
	}
	//***********************************************************************************
	//**** default
	//***********************************************************************************
	/**
	 *
	 * @returns cn_element_type
	 */
	static default_railing() {
		return new cn_balcony_type();
	}
	/**
	 *
	 * @returns cn_element_type
	 */
	static default_wall() {
		var bt = new cn_balcony_type();
		bt.height = 1.1;
		bt.thickness = 0.2;
		bt.category = "wall";
		return bt;
	}

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize() {
		var json = {};
		json.class_name = 'cn_balcony_type';
		json.ID = this.ID;
		json.name = this.name;
		json.height = this.height;
		json.thickness = this.thickness;
		json.category = this.category;
		return json;
	}

	static unserialize(json) {
		if (typeof(json.ID) != 'string') return false;
		if (json.class_name != 'cn_balcony_type') return false;
		if (typeof(json.name) != 'string') return false;
		if (typeof(json.height) != 'number') return false;
		if (typeof(json.thickness) != 'number') return false;
		var wt = new cn_balcony_type();
		if (typeof(json.name) == 'string') wt.name = json.name;
		wt.ID = json.ID;
		wt.name = json.name;
		wt.height = json.height;
		wt.thickness = json.thickness;
		if (typeof(json.category) == 'string' && CN_BT_CATEGORY_LIST.indexOf(json.category) >= 0)
			wt.category = json.category;
		return wt;
	}

	//***********************************************************************************
	//**** Build label
	//***********************************************************************************
	get_label() {
		if (this.name != "") return this.name;
		var element_type_name = "";

		if (this.category == "railing")
			element_type_name += "Balustrade";
		else if (this.category == "wall")
			element_type_name += "Acrotère";
		element_type_name += " " + (this.height*100).toFixed(0) + "cm";
		return element_type_name;
	}

	//***********************************************************************************
	//**** Returns description
	//***********************************************************************************

    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
	get_description() {
		var description = [];

		var cat = {label:"Catégorie"};
		if (this.category == "railing")
			cat.value = "Balustrade";
		else if (this.category == "wall")
			cat.value = "Acrotère";
		description.push(cat);

		var height = {label:"Hauteur"};
		description.push(height);
		height.value= this.height*100;
		height.decimals = 0;
		height.unit = "cm";

		var th = {label:"Epaisseur"};
		description.push(th);
		th.value= this.thickness*100;
		th.decimals = 0;
		th.unit = "cm";

		return description;
	}

	//***********************************************************************************
	/**
	 * draw svg icon
	 * @param {number} width
	 * @param {number} height
	 * @returns {string}
	 */
	draw_svg_icon(width, height)
	{
		var html = "";
		var z = height * 0.2;
		if (this.category == "railing")
		{
			var ramp_width = height * 0.05;
			var rail_size = height * 0.02;
			var rail_height = height * 0.1;
			var nb_rails = 8;
			var rail_spacing = (width - rail_size) / (nb_rails-1);

			html += "<rect x='0' y='" + z + "' width='" + width + "' height='" + ramp_width + "' style='fill:rgb(200,200,200);stroke:black;' />";
			html += "<rect x='0' y='" + (height - rail_height) + "' width='" + width + "' height='" + rail_size + "' style='fill:rgb(200,200,200);stroke:black;' />";
			var x=0;
			for (var n=0;n<nb_rails;n++)
			{
				var h = (n==0 || n==nb_rails-1)?height-z-ramp_width:height - z - ramp_width - rail_height;
				html += "<rect x='" + x + "' y='" + (z + ramp_width) + "' width='" + rail_size + "' height='" + h + "' style='fill:rgb(200,200,200);stroke:black;' />";
				x += rail_spacing;
			}
		}
		else if (this.category == "wall")
		{
			html += "<rect x='0' y='" + z + "' width='" + width + "' height='" + (height - z) + "' style='fill:rgb(150,150,150);stroke:black;' />";
		}
		return html;
	}

	//***********************************************************************************
	//**** Comparison
	//***********************************************************************************
	is_equal_to(other)
	{
		if (this.name != other.name) return false;
		if (this.height != other.height) return false;
		if (this.thickness != other.thickness) return false;
		if (this.category != other.category) return false;

		return true;
	}

	//***********************************************************************************
	//**** 3D geometry
	//***********************************************************************************
	build_extruded_polygons(z, wall) {
		var extruded_polygons = [];

		//*** Wall balcony
		if (this.category == "wall")
		{
			if (this.height <= 0 || this.thickness <= 0) return extruded_polygons;
			var ctr2d = [];
			ctr2d.push(wall.shape[0]);
			if (wall.delegates[0] == null)
				ctr2d.push(wall.vertices[0].position);
			ctr2d.push(wall.shape[1]);
			ctr2d.push(wall.shape[2]);
			if (wall.delegates[1] == null)
				ctr2d.push(wall.vertices[1].position);
			ctr2d.push(wall.shape[3]);
			var ctr3d = [];
			for (var i in ctr2d)
			{
				var v = ctr2d[i];
				ctr3d.push([v[0],v[1],z]);
			}
			var epg = new fh_extruded_polygon();
			extruded_polygons.push(epg);
			epg.polygon = new fh_polygon(ctr3d[0],[0,0,1]);
			epg.polygon.add_contour(ctr3d);
			epg.direction = [0,0,this.height];
			epg.color = [0.8,0.8,0.8,1];
		}
		//*** railing balcony
		else if (this.category == "railing")
		{
			var color = [0.8,1,0.8,1];
			var ramp_thickness = 0.03;
			var bottom_height = 0.1;
			var rail_thickness = 0.015;
			var start_rail_thickness = 0.03;

			var v0 = wall.vertex_position(0);
			v0.push(z);
			var v1 = wall.vertex_position(1);
			v1.push(z);
			var direction = fh_sub(v1,v0);
			var length = fh_normalize(direction);
			var dz = [0,0,1];
			var dx = fh_cross(direction,dz);

			var p0 = fh_clone(v0);

			//*** ramp
			p0[2] = z + this.height - ramp_thickness*0.5;
			var epg = new fh_extruded_polygon();
			extruded_polygons.push(epg);
			epg.tube(p0,fh_mul(direction,length),dx,this.thickness,ramp_thickness);
			epg.color = color;

			//*** Bottom bar
			p0[2] = z + bottom_height;
			p0 = fh_add(v0,fh_mul(dz,bottom_height));
			epg = new fh_extruded_polygon();
			extruded_polygons.push(epg);
			epg.tube(p0,fh_mul(direction,length),dx,rail_thickness,rail_thickness);
			epg.color = color;

			//*** start rails
			epg = new fh_extruded_polygon();
			extruded_polygons.push(epg);
			epg.tube(v0,fh_mul(dz,this.height),dx,start_rail_thickness,start_rail_thickness);
			epg.color = color;

			epg = new fh_extruded_polygon();
			extruded_polygons.push(epg);
			epg.tube(v1,fh_mul(dz,this.height),dx,start_rail_thickness,start_rail_thickness);
			epg.color = color;

			//*** rails
			var nb_rails = 1+Math.floor(length / (0.11 - rail_thickness));
			var rail_spacing = length / nb_rails;
			var ez = fh_mul(dz,this.height - bottom_height - ramp_thickness - rail_thickness * 0.5);
			p0 = fh_add(v0,fh_mul(dz,bottom_height + rail_thickness * 0.5));
			for (var n=1;n<nb_rails;n++)
			{
				epg = new fh_extruded_polygon();
				extruded_polygons.push(epg);
				epg.tube(fh_add(p0,fh_mul(direction,rail_spacing * n)),ez,dx,rail_thickness,rail_thickness);
				epg.color = color;
			}
		}
		return extruded_polygons;
	}

	//***********************************************************************************
	//**** Get configuration
	//***********************************************************************************
	static configuration() {
		var configuration = new cn_configuration();

		//***********************************************
		//*** Railing configuration
		var railing = new cn_configuration_tab("Balustrade","railing");
		configuration.add_tab(railing);

		var railing_list = new cn_configuration_line("Balustrade");
		railing.add_line(railing_list);

		railing_list.add_param(new cn_configuration_param("Epaisseur","thickness",1,100,"cm"));
		railing_list.add_param(new cn_configuration_param("Hauteur","height",30,200,"cm"));

		railing_list.add_choice(new cn_configuration_choice());

		//***********************************************
		//*** Railing configuration
		var wall = new cn_configuration_tab("Acrotère","wall");
		configuration.add_tab(wall);

		var wall_list = new cn_configuration_line("Acrotère");
		wall.add_line(wall_list);

		wall_list.add_param(new cn_configuration_param("Epaisseur","thickness",0,100,"cm"));
		wall_list.add_param(new cn_configuration_param("Hauteur","height",0,200,"cm"));

		wall_list.add_choice(new cn_configuration_choice());

		return configuration
	}

	//***********************************************************************************
	//**** Fill configuration
	//***********************************************************************************
	fill_configuration(config = null)
	{
		var configuration = (config)?config:cn_balcony_type.configuration();

		//*** default to railing
		configuration.selection = 0;
		configuration.tabs[0].lines[0].get_param("thickness").value = this.thickness * 100;
		configuration.tabs[0].lines[0].get_param("height").value = this.height * 100;
		configuration.tabs[1].lines[0].get_param("thickness").value = this.thickness * 100;
		configuration.tabs[1].lines[0].get_param("height").value = this.height * 100;

		//*** find proper tab
		var tab_index = configuration.get_tab_index(this.category);
		if (tab_index < 0) return false;
		var configuration_tab = configuration.tabs[tab_index];

		if (this.category == "railing")
			return configuration;

		if (this.category == "wall")
		{
			configuration.selection = tab_index;
			return configuration;
		}

		return false;
	}
	//***********************************************************************************
	//**** Load configuration
	//***********************************************************************************
	load_configuration(configuration)
	{
		var configuration_tab = configuration.tabs[configuration.selection];
		this.set_category(configuration_tab.name);

		var configuration_line = configuration_tab.lines[configuration_tab.selection];
		this.thickness = 0.01 * configuration_line.get_param("thickness").value;
		this.height = 0.01 * configuration_line.get_param("height").value;
		return true;
	}

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
     accept_visitor(element_type_visitor) {
        element_type_visitor.visit_balcony_type(this);
    }
}


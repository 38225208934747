import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { combineLatest, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Agence } from 'src/app/model/agence.model';
import { Bien } from 'src/app/model/bien.model';
import { Commande } from 'src/app/model/commande.model';
import { Contact } from 'src/app/model/contact.model';
import { EditMode } from 'src/app/model/edit-mode.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import {
    BienModalResult,
    EditBienModalComponent,
} from 'src/app/modules/shared/edit-bien/edit-bien-modal/edit-bien-modal.component';
import { EditContactModalComponent } from 'src/app/modules/shared/edit-contact/edit-contact-modal/edit-contact-modal.component';
import { AgenceService } from 'src/app/services/agence.service';
import { BienService } from 'src/app/services/bien.service';
import { CommandeService } from 'src/app/services/commande.service';
import { ContactService } from 'src/app/services/contact.service';
import { URL_GESTION_COMMANDES, URL_GESTION_COMMANDES_EDIT } from 'src/app/shared/constants/url.constants';
import { DialogUtils } from 'src/app/utils/dialog.utils';
import { AgenceApiService } from '../../../../services/agence-api.service';
import { BienApiService } from '../../../../services/bien-api.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ValidatorUtils } from '../../../../utils/validator.utils';
import { DISPLAY_FN_CONTACT, DISPLAY_FN_NAME } from '../../../../utils/display-function.utils';
import { FormService } from '../../../../services/form.service';
import { SynchronizationService } from '../../../../services/synchronization.service';

@Component({
    selector: 'app-creation-commande',
    templateUrl: './creation-commande.component.html',
    styleUrls: ['./creation-commande.component.scss'],
})
export class CreationCommandeComponent extends BaseComponent implements OnInit, OnDestroy {
    idCommande: string;
    commandeInitial: Commande;
    commande: Commande = new Commande();
    // Dropdown
    filteredSearchAgences: Agence[];
    filteredSearchBiens: Bien[];
    filteredSearchContacts: Contact[];
    isSearchingAgences = this.formService.isSearchingAgences$;
    isSearchingBiens = this.formService.isSearchingBiens$;
    isSearchingContacts = this.formService.isSearchingContacts$;

    bienCourant: Bien = new Bien();

    formCommande: FormGroup;

    mode: EditMode = 'CREATE';
    isReadOnly = false;
    isOnline$ = this.synchronizationService.getSyncState();

    get documentBonCommandeControl() {
        return this.formCommande?.get('documentBonCommande') as FormControl;
    }

    readonly displayFnNom = DISPLAY_FN_NAME;
    readonly displayFnContact = DISPLAY_FN_CONTACT;
    readonly codeTypeDocument = 'COMMANDE';
    readonly documentTitle = 'Commande';

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly commandeService: CommandeService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService,
        private readonly agenceService: AgenceService,
        private readonly agenceApiService: AgenceApiService,
        private readonly bienService: BienService,
        private readonly bienApiService: BienApiService,
        private readonly contactService: ContactService,
        private readonly matDialog: MatDialog,
        private readonly formService: FormService,
        private readonly synchronizationService: SynchronizationService,
        private readonly confirmationService: ConfirmationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        this.idCommande = params.get('idCommande');
                        if (this.idCommande) {
                            this.mode = data.duplicate ? 'DUPLICATE' : 'EDIT';
                            this.isReadOnly = data.consulter;
                            return this.commandeService.findOneCommande(this.idCommande);
                        }
                        return of(null);
                    })
                )
            )
            .subscribe((currentCommande) => {
                if (currentCommande) {
                    this.commandeInitial = JSON.parse(JSON.stringify(currentCommande));
                    this.commande = currentCommande;
                    this.bienCourant = this.commande.bien ? this.commande.bien : new Bien();
                }
                this.createForm();
                if (this.mode !== 'CREATE') {
                    let numeroCommande = this.commande.numeroCommande;
                    if (this.mode === 'DUPLICATE' && !this.isReadOnly) {
                        numeroCommande = '';
                        this.commande.id = null;
                        this.commande.idSalesforce = null;
                    }

                    this.formCommande.patchValue({
                        numeroCommande: numeroCommande,
                        agence: this.commande.agence ? this.commande.agence : '',
                        bien: this.commande.bien ? this.commande.bien : '',
                        contact: this.commande.contact ? this.commande.contact : '',
                        dateCommande: this.commande.dateCreation,
                        isEntreprise: this.commande.isEntreprise.toString(),
                        documentBonCommande: this.commande.documentBonCommande,
                    });
                }
                this.search();
            });
    }

    /**
     * Crée la structure du formulaire
     * @returns
     */
    createForm() {
        this.formCommande = this.formBuilder.group({
            numeroCommande: [
                '',
                [Validators.required],
                [ValidatorUtils.numeroCommandeAlreadyExists(this.commandeService, this.commandeInitial)],
            ],
            agence: [
                '',
                [
                    Validators.required,
                    (control) => {
                        if (control.value && typeof control.value.id === 'string') {
                            return null;
                        } else {
                            return { agenceDoesNotExist: true };
                        }
                    },
                ],
            ],
            dateCommande: ['', Validators.required],
            isEntreprise: ['', Validators.required],
            bien: [
                '',
                [
                    Validators.required,
                    (control) => {
                        if (control.value && typeof control.value.nom === 'string') {
                            return null;
                        } else {
                            return { bienDoesNotExist: true };
                        }
                    },
                ],
            ],
            contact: [
                '',
                [
                    Validators.required,
                    (control) => {
                        if (control.value && typeof control.value.nom === 'string') {
                            return null;
                        } else {
                            return { contactDoesNotExist: true };
                        }
                    },
                ],
            ],
            documentBonCommande: [null, Validators.required],
        });
    }

    onClickChangeBienFromSelect(event: MatAutocompleteSelectedEvent) {
        if (event?.option?.value?.id) {
            this.changeBien(event.option.value.id);
        }
    }

    cleanBien() {
        this.bienCourant = new Bien();
        this.commande.bien = undefined;
        this.formCommande.patchValue({
            bien: '',
        });
        this.formCommande.updateValueAndValidity();
    }

    addEditBien(isCreation: boolean) {
        this.matDialog
            .open(EditBienModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    bien: this.bienCourant,
                    isCreation: isCreation,
                    isPrincipal: true,
                    isAdminMode: true,
                    isCreateOrderMode: true,
                },
            })
            .afterClosed()
            .subscribe((result: BienModalResult | boolean) => {
                if (result) {
                    const bienResult = result as BienModalResult;
                    this.commande.bien = bienResult.bien;
                    this.formCommande.patchValue({
                        bien: bienResult.bien,
                    });
                    this.formCommande.updateValueAndValidity();
                    this.notificationService.success('Le bien principal a été ajouté');
                }
            });
    }

    onClickChangeContactFromSelect(event: MatAutocompleteSelectedEvent) {
        if (event?.option?.value?.id) {
            this.commande.contact = event.option.value;
            this.notificationService.success('Le contact a été ajouté');
        }
    }

    addContact() {
        const newContact = new Contact();
        newContact.estPersonneMorale = false;
        return this.openModalContact(newContact, true);
    }

    editContact() {
        const contactToEdit = { ...this.commande.contact };
        return this.openModalContact(contactToEdit, false);
    }

    private openModalContact(contactToEdit: any, isCreation: boolean) {
        return this.matDialog
            .open(EditContactModalComponent, {
                data: {
                    contact: contactToEdit,
                    isReadOnly: this.isReadOnly,
                    isProprietaire: false,
                    isDonneurOrdre: false,
                    isCreation: isCreation,
                    isContactRef: true,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.commande.contact = result.contact;
                    this.formCommande.patchValue({
                        contact: result.contact,
                    });
                    this.formCommande.updateValueAndValidity();
                    this.notificationService.success('Le contact a été modifié');
                }
            });
    }

    deleteContact() {
        this.commande.contact = null;
        this.formCommande.patchValue({
            contact: '',
        });
        this.formCommande.updateValueAndValidity();
    }

    validerCommande() {
        this.cnSpinnerService.show('Sauvegarde en cours...');

        // Values
        this.commande.numeroCommande = this.formCommande.value.numeroCommande;
        this.commande.agence = this.formCommande.value.agence;
        this.commande.dateCreation = this.formCommande.value.dateCommande;
        this.commande.isEntreprise = this.formCommande.value.isEntreprise;
        this.commande.documentBonCommande = this.documentBonCommandeControl.value;
        this.commandeService.upsertCommande(this.commande).subscribe(
            () => {
                this.cnSpinnerService.hide();
                if (this.mode === 'EDIT') {
                    this.notificationService.success('La commande a été modifié');
                } else {
                    const message = this.mode === 'CREATE' ? 'La commande a été créé' : 'La commande a été dupliqué';
                    this.notificationService.success(message);
                }
                this.back();
            },
            () => this.cnSpinnerService.hide()
        );
    }

    editer() {
        this.router.navigate([URL_GESTION_COMMANDES_EDIT, this.commande.id]);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    back() {
        this.router.navigate([URL_GESTION_COMMANDES]);
    }

    private changeBien(idBien: string) {
        if (idBien) {
            this.bienService
                .findOneBien(idBien)
                .pipe(
                    tap((b) => {
                        this.commande.bien = b;
                        this.formCommande.patchValue({
                            bien: b,
                        });
                        this.formCommande.updateValueAndValidity();
                        this.bienCourant = this.commande.bien;
                    }),
                    takeUntil(this.ngUnsubscribe)
                )
                .subscribe();
        }
    }

    /**
     * Permet de filtrer les agences, biens et contacts
     * @private
     */
    private search() {
        this.formService
            .agenceSearchValueChange(this.formCommande.get('agence'), this.agenceApiService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchAgences = result.content;
            });

        this.formService
            .bienSearchValueChange(this.formCommande.get('bien'), this.bienApiService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchBiens = result.content;
            });

        this.formService
            .contactSearchValueChange(this.formCommande.get('contact'), this.contactService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchContacts = result.content;
            });
    }
}

import { CODE_NON_COMMUNIQUE, EGAL, INFERIEUR, SUPPERIEUR } from '../../../shared/cee.constants';
import { CeeService } from '../../../services/cee.service';
import { Anomalie, CeeConfig, CeeModel, PointControle } from '../../../model/cee.model';
import { first } from 'rxjs/operators';
import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';

@Component({
    selector: 'app-ano-isolant',
    templateUrl: './ano-isolant.component.html',
})
export class AnoIsolantComponent {
    anomalies: {
        solution: string;
        anomalies: string[];
    }[] = [];
    constructor(private readonly diagnosticService: DiagnosticService, private readonly ceeService: CeeService) {
        combineLatest([this.diagnosticService.getCurrentDiagnostic(), this.ceeService.ceeConfig$])
            .pipe(first())
            .subscribe(([diagnostic, config]) => {
                this.initAnomalies(config, diagnostic.contenuDiagnostic as CeeModel);
            });
    }

    private initAnomalies(config: CeeConfig, ceeModel: CeeModel) {
        for (const solution of ceeModel.solutions.solutions) {
            let nomSolution = '';
            if (solution.infoIsolant) {
                const solValue = solution.infoIsolant.find((val) => this.ceeService.idsNomSolution.includes(val.id));
                if (solValue) {
                    nomSolution = solValue.valeur;
                }
                const iAnomalie = {
                    solution: nomSolution,
                    anomalies: [],
                };
                for (const ptc of solution.infoIsolant) {
                    const pointcontrole: PointControle = config.pointsControles.find((p) => p.idInterne === ptc.id);
                    if (!pointcontrole) {
                        continue;
                    }
                    let anomalie: Anomalie = pointcontrole.lstAnomalie.find((an) => {
                        return an.codeReponse == ptc.valeur || (an.codeReponse === CODE_NON_COMMUNIQUE && ptc.checked);
                    });
                    if (!anomalie) {
                        anomalie = pointcontrole.lstAnomalie.find((an) => {
                            switch (an.type) {
                                case INFERIEUR:
                                    return +ptc.valeur < +an.valeurReference;
                                case SUPPERIEUR:
                                    return +ptc.valeur > +an.valeurReference;
                                case EGAL:
                                    return +ptc.valeur === +an.valeurReference;
                            }
                            return false;
                        });
                    }
                    if (anomalie) {
                        iAnomalie.anomalies.push(
                            anomalie.description ? anomalie.description : pointcontrole.description
                        );
                    }
                }
                this.addAnomalie(iAnomalie);
            }
        }
    }

    private addAnomalie(pAnomalie: any) {
        if (pAnomalie.anomalies.length) {
            this.anomalies.push(pAnomalie);
        }
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { Intervention } from 'src/app/model/intervention.model';
import { Polluant } from '../../../model/polluant.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { PolluantService } from '../../../services/polluant.service';
import {
    getConditionPrelevement,
    getRefInfo,
} from '../../../modules/etude-situation/initialisation/objectifs/objectifs.component';
import { ConditionsPrelevement, ObjectifsMesurage } from '../../../model/polluant-config.model';

@Component({
    selector: 'app-poll-context-block',
    templateUrl: './poll-context-block.component.html',
    styleUrls: ['./poll-context-block.component.scss'],
})
export class PollContextBlockComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    currentContenuDiagnostic: Polluant;

    diagnostic: Diagnostic;
    contextes = [];
    normesObjectifs: string[];
    cond: ConditionsPrelevement[];

    constructor(private diagnosticService: DiagnosticService, private pollService: PolluantService) {}

    ngOnInit(): void {
        this.diagnosticService.getCurrentDiagnostic().subscribe((diag) => {
            this.diagnostic = diag;
        });

        this.currentContenuDiagnostic = this.diagnostic.contenuDiagnostic as Polluant;

        this.pollService.findOnePolluantConfigIndexedDB(this.diagnostic.idConfig).subscribe((config) => {
            this.cond = getConditionPrelevement(
                config.objectifsMesurage,
                this.currentContenuDiagnostic.objectifsMesurage.data.objectifsMesurage
            );
            this.contextSorter(config.objectifsMesurage, this.currentContenuDiagnostic);
        });
    }

    contextSorter(objectifsParents: ObjectifsMesurage[], polluant: Polluant) {
        objectifsParents.forEach((objectifParent) => {
            var contexte = {
                titre: objectifParent.description,
                contextTravaux: objectifParent.chapitreTravaux,
                zones: [],
            };

            if (contexte.contextTravaux) {
                polluant.zones.data.zonesList.forEach((zone) => {
                    var pullZone = false;
                    if (zone.objectifMesurage != undefined) {
                        zone.objectifMesurage.forEach((objectifs) => {
                            if (objectifs.parentId == objectifParent.id) {
                                if (!pullZone) {
                                    contexte.zones.push(this.contextTravauxSorter(zone, objectifsParents));
                                    pullZone = true;
                                }
                            }
                        });
                    }
                });
                if (contexte.zones.length > 0) this.contextes.push(contexte);
            } else {
                polluant.zones.data.zonesList.forEach((zone) => {
                    var pullZone = false;
                    if (zone.objectifMesurage != undefined) {
                        zone.objectifMesurage.forEach((objectifs) => {
                            if (objectifs.parentId == objectifParent.id) {
                                if (!pullZone) {
                                    contexte.zones.push(this.contextHomogeneSorter(zone, objectifsParents));
                                    pullZone = true;
                                }
                            }
                        });
                    }
                });
                if (contexte.zones.length > 0) this.contextes.push(contexte);
            }
        });
    }

    contextTravauxSorter(zone: any, objectifs: ObjectifsMesurage[]): any {
        var superficieZone = 0;
        var pieceUnitaireTotal = 0;
        var sortedRefs = [];

        zone.listeSurfaces.forEach((surface) => {
            superficieZone += surface.superficie;
            pieceUnitaireTotal += surface.nombrePu;
        });

        zone.objectifMesurage.forEach((reference) => {
            sortedRefs.push({
                ref: getRefInfo(objectifs, reference.reference),
                quantite: this.getQuantitePrelevement(reference.reference, pieceUnitaireTotal),
            });
        });

        var sortedZone = {
            nomZone: zone.nom,
            listeMpca: zone.listeMpca,
            refs: sortedRefs,
            surfaces: { superficie: superficieZone, pieceUnitaire: pieceUnitaireTotal },
            surfacesDetaillees: zone.listeSurfaces,
            confinement: zone.confinement,
            sasPersonnel: zone.sasPersonnel,
            sasMateriel: zone.sasMateriel,
            zoneRecuperation: zone.zoneRecuperation,
            zoneStockage: zone.zoneStockageDechet,
            activiteAdjacente: zone.activiteAdjacente,
        };

        return sortedZone;
    }

    contextHomogeneSorter(zone: any, objectifs: ObjectifsMesurage[]): any {
        var superficieZone = 0;
        var pieceUnitaireTotal = 0;
        var sortedRefs = [];

        zone.listeSurfaces.forEach((surface) => {
            superficieZone += surface.superficie;
            pieceUnitaireTotal += surface.nombrePu;
        });

        zone.objectifMesurage.forEach((reference) => {
            sortedRefs.push({
                ref: getRefInfo(objectifs, reference.reference),
                quantite: this.getQuantitePrelevement(reference.reference, pieceUnitaireTotal),
            });
        });

        var sortedZone = {
            nomZone: zone.nom,
            listeMpca: zone.listeMpca,
            refs: sortedRefs,
            surfaces: { superficie: superficieZone, pieceUnitaire: pieceUnitaireTotal },
            surfacesDetaillees: zone.listeSurfaces,
            activite: zone.activite,
            frequentation: zone.frequentation,
        };

        return sortedZone;
    }

    // prettier-ignore
    getQuantitePrelevement(reference: any, pu: number){
        if(pu == 0){return NaN;}

        const ABCDG = ['Ref A','Ref B','Ref C (Liste A)','Ref C (Liste B)','Ref D','Ref G']
        const EFTUVWXY = ['Ref E','Ref F','Ref T','Ref U','Ref V','Ref W','Ref X','Ref Y (Liste A et B)','Ref Y (Autres que listes A et B)']

        if(ABCDG.find(ref => ref == reference)){
            if(pu >= 1 && pu <= 2){ return 2}
            if(pu >= 3 && pu <= 4){ return 2}
            if(pu >= 5 && pu <= 6){ return 3}
            if(pu >= 7 && pu <= 8){ return 3}
            if(pu >= 9 && pu <= 11){ return 3}
            if(pu >= 12 && pu <= 14){ return 3}
            if(pu >= 15 && pu <= 17){ return 4}
            if(pu >= 18 && pu <= 20){ return 4}
            if(pu >= 21 && pu <= 25){ return 5}
            if(pu >= 26 && pu <= 31){ return 5}
            if(pu >= 32 && pu <= 38){ return 6}
            if(pu >= 39 && pu <= 46){ return 6}
            if(pu >= 47 && pu <= 55){ return 7}
            if(pu >= 56){ return Math.round(pu/8)}
        }
        else if(EFTUVWXY.find(ref => ref == reference)){
            if(pu >= 1 && pu <= 2){ return 2}
            if(pu >= 3 && pu <= 4){ return 3}
            if(pu >= 5 && pu <= 6){ return 4}
            if(pu >= 7 && pu <= 8){ return 5}
            if(pu >= 9 && pu <= 11){ return 6}
            if(pu >= 12 && pu <= 14){ return 7}
            if(pu >= 15 && pu <= 17){ return 8}
            if(pu >= 18 && pu <= 20){ return 9}
            if(pu >= 21 && pu <= 25){ return 10}
            if(pu >= 26 && pu <= 31){ return 11}
            if(pu >= 32 && pu <= 38){ return 12}
            if(pu >= 39 && pu <= 46){ return 13}
            if(pu >= 47 && pu <= 55){ return 14}
            if(pu >= 56){ return Math.round(pu/4)}
        }
        else{
            return 1;
        }
    }
}

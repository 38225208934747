"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_roof_height : a tool to compute 3D roofs with constraints
//***********************************************************************************
//***********************************************************************************

import {cn_acos, cn_cos, cn_dist, cn_dot, cn_mul, cn_normalize, cn_sin} from "../utils/cn_utilities";
import {fh_cross, fh_dot, fh_mul, fh_normalize, fh_sub} from "@acenv/fh-3d-viewer";
import { cn_roof_slab } from "./cn_roof_slab";

export class cn_roof_engine {
	constructor(scene) {
		this.scene = scene;
		this.slabs = scene.slabs;
		this.heights = scene.heights;

		this._angle_threshold = 80*Math.PI/180;
		this._sin_angle_threshold = Math.sin(this._angle_threshold);

		this._deviation_tolerance = 0.9;

		this._cos_normal_tolerance = cn_cos(1);

		this._sin_max_slope = cn_sin(80);
	}

	//***********************************************************************************
	//**** Main calculation
	//***********************************************************************************
	compute() {
		this._first_path = true;
		this._expect_coherent = true;
		this._initialize();
		this._compute_slabs();
		this._finalize();
	}

	//***********************************************************************************
	//**** Initialization
	//***********************************************************************************
	_initialize() {

		for (var i in this.slabs)
		{
			var slab = this.slabs[i];
			slab.defined = false;
			slab.done = false;
			slab.heights = [];
		}

		//*** For all heights, compute height locked value, if it exists.
		//*** Compute height status : 0 = free, 1 = locked with coherent value, 2 = locked with incoherent values.
		for (var i in this.heights)
		{
			var height = this.heights[i];
			for (var j=0;j<height.slabs.length;j++)
			{
				if (!height.locks[j])
					height.values[j] = false;
				height.slabs[j].heights.push(height);
			}
		}
	}

	//***********************************************************************************
	//**** Finalize
	//***********************************************************************************
	_finalize() {
		for (var i in this.slabs)
		{
			var slab = this.slabs[i];
			if (slab.done) continue;
			if (slab.normal == false)
				slab.normal = [0,0,1];
			slab.plane = 0;
			this._slab_done(slab);
		}
		this.scene.update_heights();
	}

	//***********************************************************************************
	//**** Slab calculation
	//***********************************************************************************
	_compute_slabs() {
		//*** check for 0 liberty slabs
		for (var i in this.slabs)
		{
			var slab = this.slabs[i];
			if (slab.done) continue;
			slab.status = this.get_slab_status(slab);
			if (slab.status==null) continue;
			if (slab.status.liberties == 0)
			{
				this._slab_done(slab);
				this._expect_coherent = true;
			}
		}
		this._first_path = false;

		//*** check for 1 liberty slabs
		for (var i in this.slabs)
		{
			var slab = this.slabs[i];
			if (slab.done) continue;
			if (slab.status==null) continue;
			if (slab.status.liberties != 1) continue;

			var point = slab.status.point;
			var direction = slab.status.direction;
			var zmin = slab.status.zmin;
			if (zmin > 0) zmin = 0;

			//*** we try to put anoher height at zmin */
			var best_normal = null;
			var best_score = -1;
			for (var k in slab.heights)
			{
				//*** expect a height not set yep */
				var height = slab.heights[k];
				if (this._expect_coherent && !height.is_coherent()) continue;
				var index = height.slabs.indexOf(slab);
				if (height.values[index] !== false) continue;

				//*** compute slab normal */
				height.values[index] = zmin;
				var hpos = [height.position[0],height.position[1],zmin];
				var dir = fh_sub(hpos,point);
				var normal = fh_cross(dir,direction);
				if (fh_normalize(normal) < 0.01)
				{
					height.values[index] = false;
					continue;
				}
				if (normal[2] < 0) normal = fh_mul(normal,-1);
				if (normal[2] < 0.1) continue;

				//*** Compute heigths of unset points */
				var other_zmin = this._compute_zmin(slab,hpos,normal);
				height.values[index] = false;
				if (other_zmin !== false && other_zmin < zmin) continue;

				//*** We keep best configuration */
				var score = this._compute_score(slab,point,normal);
				if (score <= best_score) continue;

				best_normal = normal;
				best_score = score;
			}
			if (best_normal == null) continue;
			slab.status.liberties = 0;
			slab.status.normal = best_normal;
			this._slab_done(slab);
			this._expect_coherent = true;
			this._compute_slabs();
			return;
		}

		//*** check for 2 liberty slabs
		for (var i in this.slabs)
		{
			var slab = this.slabs[i];
			if (slab.done) continue;
			if (slab.status==null) continue;
			if (slab.status.liberties != 2) continue;

			var point = slab.status.point;
			var zmin = slab.status.zmin;
			if (zmin > 0) zmin = 0;

			//*** we search for the farthest point */
			var best_height = null;
			var best_distance = 0.01;
			for (var k in slab.heights)
			{
				//*** expect a height not set yet */
				var height = slab.heights[k];
				if (this._expect_coherent && !height.is_coherent()) continue;
				var index = height.slabs.indexOf(slab);
				if (height.values[index] !== false) continue;
				var dist = cn_dist(point,height.position);
				if (dist < best_distance) continue;
				best_height = height;
				best_distance = dist;
			}
			if (best_height == null) continue;

			if (best_height.neighbour_lock)
			{
				for (var k in best_height.values)
					best_height.values[k] = zmin;
			}
			else
			{
				var index = best_height.slabs.indexOf(slab);
				best_height.values[index] = zmin;
			}
			this._expect_coherent = true;
			this._compute_slabs();
			return;
		}

		if (this._expect_coherent)
		{
			this._expect_coherent = false;
			this._compute_slabs();
		}

		//*** check for 3 liberty slabs
		for (var i in this.slabs)
		{
			var slab = this.slabs[i];
			if (slab.done) continue;
			slab.status = {normal:[0,0,1],point:[0,0,0]};
			this._slab_done(slab);
		}
	}

	//***********************************************************************************
	//**** returns slab status. if vertices is not defined, will use all locked heights
	//***********************************************************************************
	get_slab_status(slab, tolerance = 0.01) {

		//*** Build vertices if necessary
		var locked_vertices = [];
		var non_locked_vertices = [];
		for (var i in slab.heights)
		{
			var height = slab.heights[i];
			var index = height.slabs.indexOf(slab);
			if (height.values[index] === false) continue;

			var v = [height.position[0],height.position[1],height.values[index]];
			if (height.locks[index])
				locked_vertices.push(v);
			else
			non_locked_vertices.push(v);
		}
		return this.compute_liberties(locked_vertices.concat(non_locked_vertices),tolerance);
	}

	//***********************************************************************************
	//**** returns slab status. if vertices is not defined, will use all locked heights
	//***********************************************************************************
	compute_liberties(vertices, tolerance = 0.01) {

		var res = {};
		res.liberties = 3;

		//*** Simple cases
		if (vertices.length == 0) return res;

		res.liberties = 2;
		res.point = vertices[0];
		res.zmin = res.zmax = vertices[0][2];
		for (var i=1;i<vertices.length;i++)
		{
			var z = vertices[i][2];
			if (z < res.zmin) res.zmin = z;
			if (z > res.zmax) res.zmax = z;
		}

		if (vertices.length == 1)
			return res;

		//*** Compute valid directions
		var valid_directions = [];
		for (var i=1;i<vertices.length;i++)
		{
			var dir = fh_sub(vertices[i],vertices[0]);
			if (fh_normalize(dir) > tolerance && Math.abs(dir[2]) < 0.9)
				valid_directions.push(dir);
		}
		//*** simple solution : single direction not valid
		if (valid_directions.length == 0) return res;

		//*** Simple solution : only one valid direction
		res.liberties = 1;
		res.point = vertices[0];
		res.direction = valid_directions[0];
		if (valid_directions.length == 1) return res;

		//*** Compute valid normals
		var normal = null;
		for (var i=1;i<valid_directions.length;i++)
		{
			var nor = fh_cross(valid_directions[i],valid_directions[0]);
			if (fh_normalize(nor) <= tolerance) continue;
			if (nor[2] < 0 ) nor = fh_mul(nor,-1);
			if (nor[2] <  0.1) continue;
			normal = nor;
			break;
		}

		//*** simple solution : no normal found
		if (normal == null) return res;

		//*** Return plane
		res.liberties = 0;
		res.normal = normal;
		res.plane = fh_dot(normal,vertices[0]);

		return res;
	}

	//***********************************************************************************
	/**
	 * Compute minimum height of a slab with fixed plane
	 * @param {cn_roof_slab} slab
	 * @param {number[]} point
	 * @param {number[]} normal
	 * @returns {number | boolean}
	 */
	_compute_zmin(slab,point,normal) {

		//*** Compute bounds of remaining points */
		var other_zmin = null;
		var plane = fh_dot(point,normal);
		for (var i in slab.heights)
		{
			var height = slab.heights[i];
			var index = height.slabs.indexOf(slab);
			if (height.values[index] !== false) continue;
			if (cn_dist(point,height.position) < 0.001) continue;

			var z = (plane -cn_dot(normal,height.position)) / normal[2];
			if (other_zmin === null)
				other_zmin = z;
			else if (z < other_zmin)
				other_zmin =  z;
		}

		return other_zmin;
	}

	//***********************************************************************************
	//**** Compute score of a candidate normal
	//***********************************************************************************
	_compute_score(slab,point,normal) {
		var dx = fh_cross(normal,[0,0,1]);
		cn_normalize(dx);
		var xmin = null;
		var xmax = null;
		for (var i in slab.contours)
		{
			var ctr = slab.contours[i];
			for (var j in ctr.vertices)
			{
				var x = cn_dot(dx,ctr.vertices[j].position);
				if (xmin === null)
					xmin = xmax = x;
				else
				{
					if (x < xmin) xmin = x;
					if (x > xmax) xmax = x;
				}
			}
		}

		return xmax - xmin;
	}

	//***********************************************************************************
	//**** Update heigths
	//***********************************************************************************
	_slab_done(slab) {
		slab.done = true;
		slab.defined = this._first_path;
		slab.normal = slab.status.normal;
		slab.plane = fh_dot(slab.normal,slab.status.point);
		slab.slope = Math.abs(cn_acos(slab.normal[2]) * 180 / Math.PI);
		var ss = cn_sin(slab.slope);
		slab.slope_direction = cn_mul(slab.normal,1/ss);
		cn_normalize(slab.slope_direction);

		for (var i in slab.heights)
		{
			var height = slab.heights[i];
			var index = height.slabs.indexOf(slab);
			var h = slab.compute_height(height.position);
			if (height.neighbour_lock)
			{
				for (var k in height.slabs)
				{
					if (height.values[k] === false)
						height.values[k] = h;
				}
			}
			height.values[index] = h;
		}
	}

}


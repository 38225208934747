import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import {
    EtapeDiagnosticAbstract,
    EtapeDiagnosticEspacesHAP,
    EtapeDiagnosticGenerique,
    EtapeDiagnosticPerimetreHAP,
} from '../../../../model/diagnostic-etape.model';
import {
    AnnexeData,
    DocumentData,
    IDiagReportData,
    ISubBlock,
    ReportagePhotoData,
} from '../../../../model/rapport.model';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { ConclusionElement } from '../../../../model/conclusion.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { RGBA } from 'ngx-color';
import { CaracteristiquesData } from '../../assainissement/model/assainissement.model';
import { fabric } from 'fabric';
import { MpcaItemOld } from './mpca.model.old';

export class Polluant implements ContenuDiagnostic {
    // NB : il faut bien donner des valeurs par défaut aux différentes étapes, pour l'intialisation du diagnostic
    activitesAmiante: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    objectifsMesurage: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    introduction: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    perimetreDescriptif: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    validationDocumentaires: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    mpcaList: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    zones: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    besoins: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    estimations: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    ancienneVersion: EtapeDiagnosticAbstract = new EtapeDiagnosticAbstract();
    espaces: EtapeDiagnosticEspacesHAP = new EtapeDiagnosticEspacesHAP();
    perimetresInvestigation: EtapeDiagnosticPerimetreHAP = new EtapeDiagnosticPerimetreHAP();
}

export class PolluantReportData implements IDiagReportData {
    id: string;
    typePrestation: TypePrestation;
    refRapport: string;
    perimetre: string = '';
    introduction: string = '';
    mpcaList: MpcaItemOld[] = [];
    zones: any[] = [];
    recommandations: string[] = [];
    constatationsDiverses: string[] = [];
    conformiteGlobale: boolean;
    documentsData: DocumentData[] = [];
    annexes: AnnexeData[] = [];
    reportagesPhotos: ReportagePhotoData[];
    listDocuments: DocumentData[];
}

export class ConclusionPolluant extends ConclusionElement {}

export class SubBlock implements ISubBlock {
    code: string;
    biens: RowBien[] = [];

    constructor(code: string, biens: RowBien[]) {
        this.code = code;
        this.biens = biens;
    }
}

export class RowBien {
    id: string;
    bien: string;
    niveaux: RowNiveau[] = [];

    constructor(id: string, nom: string) {
        this.id = id;
        this.bien = nom;
    }
}

export class RowNiveau {
    id: string;
    niveau: string;
    storeyId?: string = null;
    volumes: RowVolume[] = [];

    constructor(id: string, nom: string, storeyId: string) {
        this.id = id;
        this.niveau = nom;
        this.storeyId = storeyId;
    }
}

export class RowVolume {
    volume: string;
    volumeId: string;
    plans: PlanRapport[] = [];
    visite: ItemElement;
    equipements: RowElement[] = [];
    commentaires: Commentaire[] = [];

    constructor(nom: string, volumeId: string) {
        this.volume = nom;
        this.volumeId = volumeId;
    }
}

export class RowElement {
    conformite: ItemElement;
    element: string;
    materiaux?: ItemElement;
    etat?: ItemElement;
    ecoulement?: ItemElement;
    controle?: ItemElement;
    odeur?: ItemElement;
    rejet?: ItemElement;
    nonVisibles?: string[] = [];
    commentaires: Commentaire[] = [];
}

export class ItemElement {
    valeur: string = null;
    style: string = null;

    constructor(valeur: string, style?: string) {
        this.valeur = valeur;
        this.style = style;
    }
}

export class PlanRapport {
    id: string;
    nom: string;

    constructor(id: string, nom: string) {
        this.id = id;
        this.nom = nom;
    }
}

export class Canvas {
    id: string;
    backgroundImageUri: string;
    backgroundImageName: string;
    legends?: Legend[];

    constructor(backgroundImageUri: string, backgroundImageName: string, legends?: Legend[]) {
        this.id = Math.random().toString(36).substr(2, 9);
        this.backgroundImageUri = backgroundImageUri;
        this.backgroundImageName = backgroundImageName;
        this.legends = legends;
    }
}

export class Legend {
    id: string;
    name: string;
    shapeType: string;
    borderType: any;
    backgroundColor: RGBA;
    borderColor: RGBA;
    shape?: fabric.Object;

    constructor(name?: string, shapeType?: string, borderType?: any, backgroundColor?: RGBA, borderColor?: RGBA) {
        this.id = Math.random().toString(36).substr(2, 9);
        this.name = name;
        this.shapeType = shapeType;
        this.borderType = borderType;
        this.backgroundColor = backgroundColor;
        this.borderColor = borderColor;
    }
}

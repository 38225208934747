import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { EtapeDiagnosticGenerique, EtapeDiagnosticZoneMesure } from '../../../../model/diagnostic-etape.model';
import {
    AnnexeData,
    DocumentData,
    IDiagReportData,
    ISubBlock,
    ReportagePhotoData,
} from '../../../../model/rapport.model';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { ConclusionElement } from '../../../../model/conclusion.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { MongoUtils } from 'src/app/commons-lib';
import { ScreenshotSvg } from '../../../../model/screenshot-svg.model';

export class Mesurage implements ContenuDiagnostic {
    // NB : il faut bien donner des valeurs par défaut aux différentes étapes, pour l'intialisation du diagnostic
    sectionMesurage: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    zonesMesures: EtapeDiagnosticZoneMesure = new EtapeDiagnosticZoneMesure();
}

/**
 * @title Diagnostic mesurage
 * @description Contient toutes les informations relatives à un diagnostic mesurage
 */
export class MesurageReportData implements IDiagReportData {
    id: string;
    typePrestation: TypePrestation;
    refRapport: string;
    optionPlan: boolean;
    conformiteGlobale: boolean;
    volumesVisites: SubBlockMesurage;
    volumesNonVisites: SubBlockMesurage;
    mesures: SubBlockMesurage;
    recommandations: string[] = [];
    constatationsDiverses: string[] = [];
    presenceVolumesNonVisites: boolean;
    documentsData: DocumentData[];
    annexes: AnnexeData[];
    reportagesPhotos: ReportagePhotoData[];
    listDocuments: DocumentData[];
    screenshotsPlan: ScreenshotSvg[];
}

/**
 * ConclusionMesurage
 *
 * @title Caractéristiques de la conclusion du diagnostique mesurage
 * @description Contient les caractéristiques de la conclusion du diagnostique mesurage
 */
export class ConclusionMesurage extends ConclusionElement {
    carrez: string;
    horsCarrez: string;
    auSol: string;
    habitable: string;
    nonHabitable: string;
    utileBrut: string;
    utileNet: string;
    utileAutre: string;
    visite = false;
    mesuresIncoherentes = false;
    hasNegativeMesure = false;
}

export class SubBlockMesurage implements ISubBlock {
    code: string;
    biens: RowBienMesurage[] = [];

    constructor(code: string, biens: RowBienMesurage[]) {
        this.code = code;
        this.biens = biens;
    }
}

export class RowBienMesurage {
    bien: string;
    niveaux: RowNiveauMesurage[] = [];
    lot: string;
    carrez: string;
    horsCarrez: string;
    habitable: string;
    nonHabitable: string;
    utileBrut: string;
    utileNet: string;
    utileAutre: string;

    constructor(nom: string) {
        this.bien = nom;
    }
}

export class RowNiveauMesurage {
    niveau: string;
    volumes: RowVolumeMesurage[] = [];
    lot: string;
    carrez: string;
    horsCarrez: string;
    habitable: string;
    nonHabitable: string;
    utileBrut: string;
    utileNet: string;
    utileAutre: string;

    constructor(nom: string) {
        this.niveau = nom;
    }
}

export class RowVolumeMesurage {
    volume: string;
    volumeId: string;
    lot: string;
    plans: string;
    carrez: string;
    horsCarrez: string;
    habitable: string;
    nonHabitable: string;
    utileBrut: string;
    utileNet: string;
    utileAutre: string;
    visite: ItemElementMesurage;
    commentaires: Commentaire[] = [];

    constructor(nom: string, volumeId: string) {
        this.volume = nom;
        this.volumeId = volumeId;
    }
}

export class ItemElementMesurage {
    valeur: string = null;
    style: string = null;

    constructor(valeur: string, style?: string) {
        this.valeur = valeur;
        this.style = style;
    }
}

/**
 * PropertiesMesurage
 *
 * @title Propriétés du diagnostique mesurage
 * @description Contient les propriétés du diagnostique mesurage
 */
export class PropertiesMesurage {
    displayedColumns: string[];
    displayedBienColumns: string[];
    listeMesures: string[];
    listeMesuresTotales: string[];
    listeMesuresZones: string[];
    listeMesuresZonesTotales: string[];
    listeMesuresLabels: string[];
    listeMesuresConclusion: string[];
    typeNonRepute: string;
    isMesurageUtile: boolean;
    listeMesuresCss: string[];

    constructor(
        displayedColumns: string[],
        displayedBienColumns: string[],
        listeMesures: string[],
        listeMesuresTotales: string[],
        listeMesuresZones: string[],
        listeMesuresZonesTotales: string[],
        listeMesuresLabels: string[],
        listeMesuresConclusion: string[],
        typeNonRepute: string,
        isMesurageUtile: boolean
    ) {
        this.displayedColumns = displayedColumns;
        this.displayedBienColumns = displayedBienColumns;
        this.listeMesures = listeMesures;
        this.listeMesuresTotales = listeMesuresTotales;
        this.listeMesuresZones = listeMesuresZones;
        this.listeMesuresZonesTotales = listeMesuresZonesTotales;
        this.listeMesuresLabels = listeMesuresLabels;
        this.listeMesuresConclusion = listeMesuresConclusion;
        this.typeNonRepute = typeNonRepute;
        this.isMesurageUtile = isMesurageUtile;
        this.listeMesuresCss = listeMesures.map((it) => it.toLowerCase().replace(/_/g, '-'));
    }
}

/**
 * ZoneMesure
 *
 * @title Propriétés du diagnostique mesurage
 * @description Contient les propriétés du diagnostique mesurage
 */
export class ZoneMesure {
    id: string = MongoUtils.generateObjectId();
    idBien: string;
    idNiveau: string;
    type: TypeZone;
    typeZone: TypeZone;
    markerJson: string;
}

/**
 * ZoneMesure
 *
 * @title Enumération des types de zones
 * @description Contient les différents type de zone
 */
export enum TypeZone {
    SURFACE_COMTABILISEE,
    SURFACE_DECOMPTEE,
}

import { Component, HostListener, OnInit } from '@angular/core';
import { CheckpointBaseComponent } from '../checkpoint-base/checkpoint-base.component';
import { ElectriciteService } from '../../../services/electricite.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { Chapitre, ICheckpoints, PointControle, ValeurCheckpoint } from '../../../model/electricite.model';
import { checkpointValid, compareChapitre, comparePointControle } from '../../../shared/electricite.constants';
import { EtatValidation } from '../../../../../../model/etat-progression.model';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { TypeCommentaire } from '../../../../../../model/type-commentaire.model';

@Component({
    selector: 'app-fluke',
    templateUrl: './fluke.component.html',
    styleUrls: ['./fluke.component.scss'],
})
export class FlukeComponent extends CheckpointBaseComponent implements OnInit {
    // readonly COMMENT_TYPES: TypeCommentaire[] = ['JUSTIFICATION_NON_CONFORMITE', 'NOTE_PERSONNELLE', 'REMARQUE'];
    private _chapitre: Chapitre[];
    private _checkPointsFluke: PointControle[];
    public chapitres: ICheckpoints[] = [];

    constructor(
        private diagnosticServicee: DiagnosticService,
        private electriciteServicee: ElectriciteService,
        private activatedRoutee: ActivatedRoute,
        private etatProgressionService: EtatProgressionService,
        private route: ActivatedRoute,
        private readonly matDialogg: MatDialog
    ) {
        super(diagnosticServicee, electriciteServicee, activatedRoutee, matDialogg);
        combineLatest([this.electriciteService.electriciteConfig$, this.diagnosticService.getCurrentDiagnostic()])
            .pipe(first())
            .subscribe(([config, diagnostic]) => {
                this._pointsControlesAides = config.pointsControlesAides;
                this._checkPointsFluke = config.pointsControles.filter((pc) => pc.isFluke);
                this._chapitre = config.chapitres;
                for (const ckp of this._checkPointsFluke) {
                    this.addPointControle(ckp);
                }
                this.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
                for (const tr of this.chapitres) {
                    tr.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
                    for (const val of tr.chapitres) {
                        val.pointsControles.sort(comparePointControle);
                    }
                }
                // justifications
                for (const chapitre of config.chapitres) {
                    const pointsControles: PointControle[] = config.pointsControles.filter(
                        (pc) => pc.isFluke && pc.idChapitre == chapitre.idInterne
                    );
                    if (pointsControles.length) {
                        this.checkpoints.push({ chapitre: chapitre, pointsControles: pointsControles });
                    }
                }
                this.diagnostic = diagnostic;
                this.updateAJustifier();
            });
    }

    private addPointControle(ckp: PointControle) {
        const ssChapitre = this._chapitre.find((chap) => chap.idInterne == ckp.idChapitre);
        const chapitre = this._chapitre.find((chap) => chap.idInterne == ssChapitre.idChapitreParent);
        const indexChapitre = this.chapitres.findIndex((tr) => tr.chapitre.id == chapitre.id);
        if (indexChapitre < 0) {
            this.chapitres.push({ chapitre: chapitre, chapitres: [{ chapitre: ssChapitre, pointsControles: [ckp] }] });
        } else {
            // Le chapitre existe
            const indexSsChapitre = this.chapitres[indexChapitre].chapitres.findIndex(
                (val) => val.chapitre.id == ssChapitre.id
            );
            if (indexSsChapitre < 0) {
                this.chapitres[indexChapitre].chapitres.push({ chapitre: ssChapitre, pointsControles: [ckp] });
            } else {
                this.chapitres[indexChapitre].chapitres[indexSsChapitre].pointsControles.push(ckp);
            }
        }
    }

    ngOnInit(): void {}

    private checkValidity() {
        if (this.checkpoints && this.diagnostic) {
            let etat: EtatValidation = 'VALID';
            if (!this.checkValidityByCheckPoints(this._checkPointsFluke)) {
                etat = 'INVALID';
            }
            const code = this.route.snapshot.data['code'];
            this.etatProgressionService.updateDiagnostic(code, etat, this.diagnostic);
        }
    }

    private checkValidityByCheckPoints(pCheckpoints: PointControle[]): boolean {
        if (pCheckpoints && this.diagnostic.pointsDeControleBiens[0]) {
            for (const ch of pCheckpoints) {
                const value: ValeurCheckpoint =
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[ch.idInterne];
                if (!checkpointValid(value)) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    @HostListener('document:click', ['$event'])
    onDetectClick($event: any) {
        this.checkValidity();
    }
}

import { Component, OnInit } from '@angular/core';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CnSpinnerService } from '../../../../../shared/cn-spinner/service/cn-spinner.service';
import { BaseComponent } from 'src/app/commons-lib';
import { takeUntil } from 'rxjs/operators';
import { PolluantPrelevement } from '../../../model/polluant-prelevement.model';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';

@Component({
    selector: 'app-besoins-selection-redirection',
    templateUrl: './prelevement-zone-selection-redirection.component.html',
    styleUrls: ['./prelevement-zone-selection-redirection.component.scss'],
})
export class PrelevementZoneSelectionRedirectionComponent extends BaseComponent implements OnInit {
    constructor(
        private readonly diagnosticService: DiagnosticService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly etatProgressionService: EtatProgressionService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(this.diagnosticService.getCurrentDiagnostic())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((currentDiagnostic) => {
                let zones = (currentDiagnostic.contenuDiagnostic as PolluantPrelevement).visiteDeChantier.data?.zones
                    ?.data?.zonesList;

                // Permet de rediriger vers le premier espace
                if (zones && zones.length) {
                    const zoneId = zones[0].id;
                    this.router.navigate([`../${zoneId}`], { relativeTo: this.route });
                } else {
                    const code = this.route.snapshot.data['code'];
                    this.etatProgressionService.updateDiagnostic(code, 'VOID', currentDiagnostic, true);
                }
            });
    }
}

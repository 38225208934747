"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_roof_selection  : A SVG tool to manipulate roof elements
//***********************************************************************************
//***********************************************************************************

import {cn_add, cn_cart, cn_clone, cn_dot, cn_middle, cn_mul, cn_normalize, cn_sub} from "../utils/cn_utilities";
import {cn_snap} from "./cn_snap";
import {cn_svg_tool_edition} from "./cn_svg_tool_edition";
import {cn_roof_line, MAX_OVERHANG_VALUE} from "../model/cn_roof_line";
import {cn_roof_vertex} from "../model/cn_roof_vertex";
import {cn_vertex} from "../model/cn_vertex";
import { cn_number_input } from "./cn_inputs";
import { cn_edit_box } from "./cn_edit_box";
import { cn_pastille } from "./cn_pastille";
import { cn_edition_handler } from "./cn_edition_handler";

export class cn_svg_tool_roof_line_edition extends cn_svg_tool_edition {
	constructor(svg_map) {
		super(svg_map);
		this._need_pending_changes = false;

		this._selected_lines = [];
		this._selected_vertices = [];

		this._drag_code = 0;
		this._mouseover_element = null;
		this._svg = "";
	}

	//***********************************************************************************
	//**** Tool close
	//***********************************************************************************
	reset_geometry(){
		this._controller.clear_selection();
        this.push_transaction('Réinitialisation du toit')
        this.push_item_set(this._scene, 'lines')
        this.push_item_set(this._scene, 'vertices')
        this.push_item_set(this._scene, 'slabs')
        this.push_item_set(this._scene, 'heights')
		this._scene.reset_geometry();
		this._svg_parent.call("roof_change");
	}

	//***********************************************************************************
	//**** Tool close
	//***********************************************************************************
	tool_close() {
		this._pending_changes();
	}

	//***********************************************************************************
	//**** Selection callback
	//***********************************************************************************
	on_selection_change() {
		this._mouseover_measure = null;

		this._selected_lines = [];
		this._selected_vertices = [];
		this._implied_vertices = [];
		this._implied_lines = [];
		this._implied_contours = [];
		this._edit_box = null;

		this._need_update_implied_selection = true;
		var selection = this._controller.get_selection();
		if (selection.length == 0 || selection.some(elt => elt.constructor != cn_roof_line && elt.constructor != cn_roof_vertex))
			return false;

		this._selected_lines = selection.filter(elt => elt.constructor == cn_roof_line);
		this._selected_vertices = selection.filter(elt => elt.constructor == cn_roof_vertex);
		const removable_vertices = this._selected_vertices.filter(v => v.lines.length==2);

		//*** edit box */
		if (this._selected_lines.length > 0)
		{
			const obj = this;
			const map = this.get_svg_map();
			const border_lines = this._controller.get_selection("outer_lines");
			this._edit_box = new cn_edit_box(map,this._selected_lines,false);
			if (border_lines.length > 0)
			{
				const overhang_pastille = new cn_pastille([0,0],"arrow_left_right.svg");
				this._edit_box.add_pastille(overhang_pastille);
				overhang_pastille.svg_class = "pastille_background white";
				overhang_pastille.clicked = function() {
					const input = new cn_number_input("Débord de toiture",border_lines[0].overhang,"m",2,0,MAX_OVERHANG_VALUE);
					if (border_lines.some(l => l.overhang != input.value))
						input.label = "Débord de toiture (variable) :";
					input.callback = function() {
						obj.set_overhang(input.value);
					};
					map.call("number_input",input);
				}
			}
		}
		else if (removable_vertices.length > 0)
		{
			this._edit_box = new cn_edit_box(this,removable_vertices);
		}

		return (this._edit_box != null || this._selected_vertices.some(v => v.liberties>0));
	}

	//***********************************************************************************
	//**** Specific selection methods
	//***********************************************************************************

	//*** Returns all selected border lines
	get_selected_border_lines() {
		return this._controller.get_selection("outer_lines");
	}

	//***********************************************************************************
	//**** Measure click
	//***********************************************************************************
	set_overhang(v)
	{
		this._set_overhang(v);
		this._svg_parent.call("roof_change");
	}

	_set_overhang(v,create_transaction=true)
	{
		var obj = this;
		var scene = this._scene;
		if (create_transaction)
			this.push_transaction("Débord de toiture","" + this._drag_code,function(){scene.update();scene.update_deep();obj._svg_parent.call("roof_change")});
		else
			this._building.transaction_manager.set_current_transaction_callback(function(){scene.update();scene.update_deep();obj._svg_parent.call("roof_change")});
		var lines = this.get_selected_border_lines();
		for (var i in lines)
		{
			this.push_item_set(lines[i],"overhang");
			lines[i].overhang = v;
		}
		this._scene.update();
		this.get_svg_map().refresh();
	}
	//***********************************************************************************
	//**** Draws  specific svg for the tool. Returns svg string
	//***********************************************************************************
	draw(camera) {

		var html = "";

		var selection = this._controller.get_selection();

		var implied_lines = [];
		for (var i in selection)
		{
			if (selection[i].constructor == cn_roof_line)
			{
				if (implied_lines.indexOf(selection[i]) < 0)
					implied_lines.push(selection[i]);
			}
			else if (selection[i].constructor == cn_roof_vertex)
			{
				for (var j in selection[i].lines)
				{
					var line = selection[i].lines[j];
					if (implied_lines.indexOf(line) < 0)
						implied_lines.push(line);
				}
			}
		}

		//*** Draw measures of implied lines
		for (var i in implied_lines)
		{
			var line = implied_lines[i];
			html += camera.draw_measure(line.vertices[0].position,line.vertices[1].position);
		}

		//*** Draw angles of selected vertex
		if (selection.length == 1 && selection[0].constructor == cn_roof_vertex && selection[0].lines.length > 1)
		{
			var vertex = selection[0];
			var l = vertex.angles.length;
			for (var k=0;k<l;k++)
			{
				var a0 = vertex.angles[k];
				var a1 = vertex.angles[(k+1)%l];
				html += camera.draw_svg_angle(vertex.position,cn_cart([1,a0]),cn_cart([1,a1]));
			}
		}

		if (this._mouseover_element)
		{
			html += this._mouseover_element.draw(camera,['selected','mouseover']);
		}

		html += this._svg;

		if (this._edit_box) html += this._edit_box.draw(camera);

		return html;
	}

	//***********************************************************************************
	//**** Mouse callbacks
	//***********************************************************************************

	clear_move() {

		if (this._edit_box) this._edit_box.clear_move();
	}

	click(ev) {

		if (this._edit_box && this._edit_box.click(ev)) return true;

		return false;
	}

	grab (ev) {
		if (this._edit_box && this._edit_box.grab(ev)) return true;

		this._drag_code++;
		return this._find_mouseover(ev);
	}

	drop (ev) {
		if (this._edit_box && this._edit_box.drop(ev)) return true;
		this._pending_changes();
		return false;
	}

	move (ev) {
		if (this._edit_box && this._edit_box.move(ev)) return true;
		return this._find_mouseover(ev);
	}

	drag (ev) {
		this._svg = "";
		if (this._edit_box && this._edit_box.drag(ev)) return true;

		if (this._mouseover_element)
		{
			if (this._mouseover_element.constructor == cn_roof_vertex)
			{
				this._drag_vertex(ev, this._mouseover_element);
				return true;
			}

			if (this._mouseover_element.constructor == cn_roof_line && this._mouseover_element.fixed)
			{
				this._drag_overhang_line(ev, this._mouseover_element);
				return true;
			}
		}

		return false;
	}

	start_translation(ev) {
		this._drag_code++;
		console.log("start_translation");
		return this._find_mouseover(ev);
	}

	translate(ev,offset) {
		if (this._mouseover_element && this._mouseover_element.constructor == cn_roof_line && this._mouseover_element.fixed)
			this._drag_overhang_line(ev, this._mouseover_element,false);
	}

	finalize_translation(ev)
	{
		this._pending_changes();
	}

	_drag_vertex(ev, vertex)
	{
		if (vertex.liberties == 0) return false;

		var snap = new cn_snap(ev.mouse_world, ev.camera.snap_world_distance, null, ev.camera);

		//*** special case if 1 liberty vertex
		var lns = [null,null];
		if (vertex.liberties == 1)
		{
			lns = vertex.get_fixed_lines();
			if (lns[0] && lns[1])
			{
				var v0 = lns[0].vertices[0].position;
				var v1 = lns[1].vertices[1].position;
				var dir = cn_sub(v1,v0);
				var length = cn_normalize(dir);
				var x = cn_dot(dir,cn_sub(ev.mouse_world,v0));
				if (x < ev.camera.snap_world_distance) return false;
				if (x > length - ev.camera.snap_world_distance) return false;

				snap.freedoms = 1;
				snap.position = cn_add(v0,cn_mul(dir,x));
				snap.start_position = cn_clone(snap.position);
				snap.direction = dir;
				snap.check_point(cn_middle(v0,v1));
			}
			else
				return false;
		}

		//*** try to snap
		this._svg = snap.svg;

		if (!this.check_vertex_change(vertex,snap.position))
			return false;

		this._need_pending_changes = true;
		return true;
	}

	_drag_overhang_line(ev, line, create_transaction = true)
	{
		if (!line.fixed) return false;

		var overhang = 0.01*Math.round(100*cn_dot(line.bounds.normal,cn_sub(ev.mouse_world,line.vertices[0].position)));
		if (overhang < 0) overhang = 0;
		else if (overhang > MAX_OVERHANG_VALUE) overhang = MAX_OVERHANG_VALUE;
		this._set_overhang(overhang,create_transaction);
		this._need_pending_changes = true;
		return true;
	}

	_find_mouseover(ev)
	{
		this._mouseover_element = null;

		const mouseover_element = this._controller.find_element(ev.mouse_world,ev.camera.snap_world_distance);

		if (mouseover_element == null) return false;
		if (this._controller.get_selection().indexOf(mouseover_element) < 0) return false;

		if ((mouseover_element.constructor == cn_roof_vertex && mouseover_element.liberties > 0) ||
			(mouseover_element.constructor == cn_roof_line && mouseover_element.fixed))
		{
			this._mouseover_element = mouseover_element;
			return true;
		}
		return false;
	}

	//***********************************************************************************
	//**** Apply pending changes
	//***********************************************************************************
	_pending_changes() {
		if (!this._need_pending_changes) return;
		this._need_pending_changes = false;
		this._scene.update();
		this._scene.update_deep();
		this._svg_parent.call("roof_change");
	}

	//***********************************************************************************
	//**** Apply translation to current selection
	//***********************************************************************************
	check_vertex_change(vertex, new_position) {

		var old_position = cn_clone(vertex.position);

		//*** Apply new element
		vertex.position = cn_clone(new_position);
		this._scene.update();

		//*** check coherence
		var checking = this._scene.check_changes(vertex.lines);

		//*** Restore old position
		vertex.position = cn_clone(old_position);

		//*** Wrong check
		if (!checking)
		{
			this._scene.update();
			return false;
		}

		//*** If new position is ok, push transaction
		var obj = this;
		this.push_transaction("Déplacement de sommet",vertex.ID,function(){obj._scene.update();obj._scene.update_deep();obj._svg_parent.call("roof_change");});
		this.push_item_set(vertex,['position']);
		vertex.position = cn_clone(new_position);
		this._scene.update();
		return true;
	}

}


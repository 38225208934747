import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-mpca-processus-client',
    templateUrl: './mpca-processus-client.component.html',
    styleUrls: ['./mpca-processus-client.component.scss'],
})
export class MpcaProcessusClientComponent implements OnInit {
    @Output()
    add = new EventEmitter<any>();

    @Output()
    edit = new EventEmitter<any>();

    @Output()
    delete = new EventEmitter<any>();

    @Output()
    duplicate = new EventEmitter<any>();

    @Output()
    check = new EventEmitter<any>();

    @Input()
    mpca: FormGroup;

    @Input() // Utilisé pour passer les champs en read-only lors de la visite de chantier
    disabled: Boolean;

    constructor() {}

    ngOnInit(): void {}

    addProcess() {
        this.add.emit('');
    }

    modifierProcessus(i) {
        this.edit.emit(i);
    }

    supprimerProcessus(i) {
        this.delete.emit(i);
    }

    dupliquerProcessus(i) {
        this.duplicate.emit(i);
    }

    checkPrimary(i) {
        this.check.emit(i);
    }
}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../../model/rapport.model';
import { CEEReportData } from '../../../../model/cee.model';
import { Intervention } from '../../../../../../../model/intervention.model';
import { NavigationBarsService } from '../../../../../../../services/navigation-bars.service';
import { Commentaire } from '../../../../../../../model/commentaire.model';
import {
    ACCESSIBILITE_REALISATION,
    ASPECT_RESISTANCE,
    CONCLUSION,
    ETAT_SUPPORT,
    JUSTESSE_ETAT,
    LONGUEURS_RESEAU,
    NON_QUALITE,
    PARE_VAPEUR,
    PERFORMANCE_THERMIQUE,
    QUALITE,
    SURFACE_ISOLEE,
} from '../../../../shared/cee.constants';
import { MatDialog } from '@angular/material/dialog';
import { ReportCommentsListModalComponent } from 'src/app/modules/diagnostics-shared/rapport/report-comments-list-modal/report-comments-list-modal.component';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';

@Component({
    selector: 'app-report-cee-block',
    templateUrl: './report-cee-block.component.html',
    styleUrls: ['./report-cee-block.component.scss'],
})
export class ReportCeeBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    readonly PERFORMANCE_THERMIQUE = PERFORMANCE_THERMIQUE;
    readonly infosGeneral = [
        { libelle: "Nom de l'entreprise ayant réalisé les travaux", property: 'nomEntreprise' },
        { libelle: 'Numéro de SIREN', property: 'numeroSIREN' },
        { libelle: 'Entreprise RGE', property: 'entrepriseRGE' },
        { libelle: 'Date de la visite préalable', property: 'dateVisitePrealable' },
        { libelle: "Date d'acceptation du devis", property: 'dateAcceptationDevis' },
    ];
    data: ReportBlockData;
    interReportData: InterventionReportData;
    intervention: Intervention;
    public ceeReportData: CEEReportData;
    public contentData = [
        { title: 'Accessibilité et réalisation des travaux', section: ACCESSIBILITE_REALISATION },
        { title: 'Etat du support', section: ETAT_SUPPORT },
        { title: 'Surface isolée', section: SURFACE_ISOLEE },
    ];
    public readonlyMode: boolean;
    private diagnostic: Diagnostic;

    constructor(
        private navigationBarsService: NavigationBarsService,
        private diagnosticService: DiagnosticService,
        private matDialog: MatDialog
    ) {
        super();
        this.diagnosticService.getCurrentDiagnostic().subscribe((diag) => {
            this.diagnostic = diag;
            this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
        });
    }

    ngOnInit(): void {
        if (this.interReportData) {
            let typePrestation = null;
            this.navigationBarsService.navigationBarConfiguration$
                .subscribe((value) => (typePrestation = value.typePrestation))
                .unsubscribe();
            this.ceeReportData = this.interReportData.diagnostics.find(
                (dia) => dia.typePrestation === typePrestation
            ) as CEEReportData;
            if (typePrestation === 'BAREN101') this.contentData.push({ title: 'Pare-vapeur', section: PARE_VAPEUR });
            this.contentData.push({ title: 'Qualité de mise en oeuvre des isolants', section: QUALITE });
            if (typePrestation === 'BAREN103') {
                this.contentData.push({ title: 'Aspect résistance au feu de l’isolant', section: ASPECT_RESISTANCE });
            } else if (typePrestation === 'BARTH160') {
                this.contentData.push({
                    title: 'Longueurs de réseaux isolées hors volume chauffés',
                    section: LONGUEURS_RESEAU,
                });
            } else if (typePrestation === 'BARTH161') {
                this.contentData.push({
                    title: 'Justesse de l’état récapitulatif de récolement',
                    section: JUSTESSE_ETAT,
                });
            }
            this.contentData.push({ title: 'Non-qualité manifeste', section: NON_QUALITE });
            this.contentData.push({ title: "Conclusion de l'inspection", section: CONCLUSION });
        }
    }

    editDisplayedComments(commentaires: Commentaire[], code: string) {
        console.log(commentaires);
        console.log(this.diagnostic.hiddenComments);
        return this.matDialog
            .open(ReportCommentsListModalComponent, {
                data: {
                    comments: commentaires,
                    code,
                    hiddenComments: this.diagnostic.hiddenComments,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.diagnostic.hiddenComments = result.hiddenComments;
                }
            });
    }
}

//***********************************************************************************
//***********************************************************************************
//**** fh3d_view_perspective : 3D view
//***********************************************************************************
//***********************************************************************************

import {PerspectiveCamera, Vector3, Plane, Box3} from 'three';
import {polar_to_cartesian, cartesian_to_polar, dummy_target, fh_view} from "./fh_view";
import {fh_add} from "./fh_vector";

//***********************************************************************************
//**** Class view
//***********************************************************************************

export class fh_view_perspective extends fh_view {
	//*****************************************************
	//*** Constructor
	constructor(div_id, scene) {
		super(div_id, scene);

		this._camera = new PerspectiveCamera(30, window.innerWidth / window.innerHeight, 1, 10000);
		this._camera.position.x = 1000;
		this._camera.position.y = 0;
		this._camera.position.z = 0;
		this._camera.up.set(0,0,1);
		this._camera.lookAt(new Vector3(0,0,0));
		this._view = "3d";

		this._clipping_plane_position = 1;
		var globalPlane = new Plane( new Vector3( 0, 0, -1 ), 1 );
		this._renderer.clippingPlanes = [globalPlane];

		//*** Clipping plane callback : called when cliping plane changed from the view
		this._cb_clipping_plane_changed = function(position) {};

		this._start_position = null;
	}

	//*****************************************************
	//**** Serialisation
	//*****************************************************
	serialize() {
		var json = {};
		json.type = "perspective";
		json.position = [this._camera.position.x,this._camera.position.y,this._camera.position.z];
		json.upaxis = [this._camera.up.x,this._camera.up.y,this._camera.up.z];
		var direction = this._camera.getWorldDirection(dummy_target);
		json.direction = [direction.x,direction.y,direction.z];
		json.fov = this._camera.fov;
		return json;
	}

	unserialize(json) {
		if (json.type != "perspective") return;

		if (!this._animation) this.start_animation();

		this._end_position.set(json.position[0],json.position[1],json.position[2]);
		var target = fh_add(json.position,json.direction);
		this._end_target.set(target[0],target[1],target[2]);
		this._end_fov = json.fov;
	}

	//***********************************************************************************
	//**** Set clipping plane position
	//***********************************************************************************
	set_clipping_plane_position(x) {
		if (this._clipping_plane_position == x) return;
		this._clipping_plane_position = x;
		this.refresh_rendering();
	}

	//********************************************************
	//*** Update perspective camera
	update_camera()	{
		if (this._scene._bounding_box)
		{
			var position = this._camera.position;
			var direction = this._camera.getWorldDirection(dummy_target);
			var zmin = 100000.0;
			var zmax = 0.0;
			var p = new Vector3();
			for (var i=0;i<8;i++)
			{
				if (i&1) p.x = this._scene._bounding_box.max.x - position.x;
				else p.x = this._scene._bounding_box.min.x - position.x;
				if (i&2) p.y = this._scene._bounding_box.max.y - position.y;
				else p.y = this._scene._bounding_box.min.y - position.y;
				if (i&4) p.z = this._scene._bounding_box.max.z - position.z;
				else p.z = this._scene._bounding_box.min.z - position.z;
				var z = p.dot(direction);
				if (z < zmin) zmin = z;
				if (z > zmax) zmax = z;
			}
			if (zmin<0) zmin = 0.1;
			if (zmax < zmin) zmax = zmin+1;
			this._camera.near = zmin;
			this._camera.far = zmax;
		}
		this._camera.updateProjectionMatrix();
	}

	//*****************************************************
	//**** Callbacks for navigation
	//*****************************************************

	//*****************************************************
	//*** Window resize callback
	manage_window_resize() {
		super.manage_window_resize();

		if (typeof(this._camera) != 'object') return;
		this._camera.aspect = this._render_width / this._render_height;
		this.update_camera();
	}

	//********************************************************
	//*** Mouse wheel management
	manage_mouse_wheel(ev) {
		if (ev.preventDefault)
			ev.preventDefault();

		let x = ev.clientX;
		let y = ev.clientY;
		let forward = ev.deltaY > 0.2;
		let ratio = 0;
		if (forward) ratio = -0.2;
		else ratio = 0.2;
		this.do_zoom(x, y, ratio);
	}


//*****************************************************
	//*** Orbit operation
	do_orbit(dx, dy) {

		//*****************************************
		//*** vertical rotation
		var position = this._camera.position.clone();
		var world_direction = this._camera.getWorldDirection(dummy_target).clone();

		//*** compute rotation axis : horizontal and perpendiculat to world direction
		var vectorx = new Vector3();
		vectorx.crossVectors(world_direction,this._camera.up);
		vectorx.normalize();

		//*** compute direction from position to rotation center
		var d_rotation_center = this._mouse_impact.clone();
		d_rotation_center.sub(position);
		var x = d_rotation_center.dot(vectorx);
		vectorx.multiplyScalar(x);
		d_rotation_center.sub(vectorx);
		var angular_position = d_rotation_center.clone();

		//*** change world direction. We will apply the same rotation to position
		var polar_direction = cartesian_to_polar(world_direction);

		var delta_y = -polar_direction.y;
		polar_direction.y += dy * 5;
		if (polar_direction.y > 1.57) polar_direction.y = 1.57;
		else if (polar_direction.y < -1.57) polar_direction.y = -1.57;
		delta_y += polar_direction.y;

		//*** turne position around rotation center
		var polar_position = cartesian_to_polar(angular_position);
		polar_position.y += delta_y;
		delta_y = -polar_position.y;
		if (polar_position.y > 1.57) polar_position.y = 1.57;
		else if (polar_position.y < -1.57) polar_position.y = -1.57;
		delta_y += polar_position.y;
		polar_direction.y += delta_y;
		angular_position = polar_to_cartesian(polar_position);

		//*** update camera position
		position.add(d_rotation_center);
		position.sub(angular_position);
		this._camera.position.x = position.x;
		this._camera.position.y = position.y;
		this._camera.position.z = position.z;

		//*** update camera target
		var target = polar_to_cartesian(polar_direction);
		target.add(this._camera.position);
		this._camera.lookAt(target);
		this._camera.updateProjectionMatrix();

		//*****************************************
		//*** horizontal rotation
		position.sub(this._mouse_impact);
		var polar_point = cartesian_to_polar(position);
		polar_point.x -= dx * 5;
		position = polar_to_cartesian(polar_point);
		position.add(this._mouse_impact);
		this._camera.position.x = position.x;
		this._camera.position.y = position.y;
		this._camera.position.z = position.z;

		target.sub(this._mouse_impact);
		var polar_point = cartesian_to_polar(target);
		polar_point.x -= dx * 5;
		target = polar_to_cartesian(polar_point);
		target.add(this._mouse_impact);

		this._camera.lookAt(target);

	}

	//*****************************************************
	//*** pan operation
	do_pan(dx, dy) {

		//*** we must orbit around a point
		if (this._mouse_impact == null) return;

		//***  the distance to the target is constant : defined by clicked point.
		var target = this._camera.getWorldDirection(dummy_target).clone();
		target.normalize();
		var to_clicked = this._mouse_impact.clone();
		to_clicked.sub(this._camera.position);
		var distance = target.dot(to_clicked);
		target.multiplyScalar(distance);
		target.add(this._camera.position);

		//*** compute cartesian displacements
		var vectorx = new Vector3();
		vectorx.crossVectors(this._camera.getWorldDirection(dummy_target),this._camera.up);
		vectorx.normalize();
		var vectory = new Vector3();
		vectory.crossVectors(vectorx,this._camera.getWorldDirection(dummy_target));
		vectory.normalize();

		vectorx.multiplyScalar(- distance * dx);
		vectory.multiplyScalar(- distance * dy);
		vectorx.add(vectory);

		//*** move position and target parallely
		this._camera.position.add(vectorx);
		target.add(vectorx);
		this._camera.lookAt(target);
	}


	do_zoom(x, y, ratio) {
		var mouse = this.read_mouse_position(x, y);
		var impact = this.get_mouse_impact(mouse);
		if (impact == null) return;

		//*** compute direction to target
		var target = this._camera.getWorldDirection(dummy_target).clone();
		target.add(this._camera.position);
		var direction = impact;
		direction.sub(this._camera.position);

		//*** operate soom
		direction.multiplyScalar(ratio);

		//*** move target and position
		this._camera.position.add(direction);
		target.add(direction);
		this._camera.lookAt(target);

		//*** update camera matrix
		this._camera.updateProjectionMatrix();
		this.update_camera();

		this.call("view_change");
		this.refresh_rendering();
	}

	//*****************************************************
	//*** Mouse operations
	manage_mouse_move(ev) {
		super.manage_mouse_move(ev);
		if (this._mouse_operation != 0 && this._mouse_operation != 1) return;

		//*** compute angular displacements
		var scale = this._camera.fov * Math.PI / (180 * this._container.clientHeight);
		var dx = scale * this._dxm * this._container.clientWidth;
		var dy = scale * this._dym * this._container.clientHeight;

		if (this._mouse_operation == 0)
			this.do_orbit(dx,dy);
		else
			this.do_pan(dx,dy);

		//*** update camera matrix
		this._camera.updateProjectionMatrix();

		//*** update camera matrix
		this.update_camera();

		this.call("view_change");
		this.refresh_rendering();
	}

	//*****************************************************
	//*** start animation
	start_animation() {
		this._animation = true;
		this._animation_start = new Date();
		this._animation_duration = 1000;

		if (this._start_position == null)
		{
			this._start_target = this._scene._scene_center.clone();
			this._start_position = this._scene._scene_center.clone();
			this._start_position.x -= this._scene._scene_radius*20;
			this._start_position.y -= this._scene._scene_radius*20;
			this._start_position.z += this._scene._scene_radius*20;
			this._start_clipping_plane = 1;
			this._start_fov = 30;
			return;
		}
		this._start_position.copy(this._camera.position);
		this._start_fov = this._camera.fov;
		this._end_fov = this._camera.fov;

		this._start_clipping_plane = this._clipping_plane_position;
		var ddd = new Vector3();
		this._camera.getWorldDirection(ddd);
		var dir = this._scene._scene_center.clone();
		dir.sub(this._camera.position);
		var xx = dir.dot(ddd);
		if (xx > 1)
			ddd.multiplyScalar(xx);

		this._start_target.copy(this._camera.position);
		this._start_target.add(ddd);
		this._camera.up.set(0, 0, 1);
	}

	//*****************************************************
	//*** start animation
	perform_animation() {
		if (!this._animation) return;

		var d1 = new Date();
		var t = (d1.getTime() - this._animation_start.getTime()) / this._animation_duration;
		if (t > 1 || !this._camera_animation)
		{
			this._animation = false;
			t = 1;
		}
		//console.log("anim",t);
		var position0 = this._start_position.clone();
		var position1 = this._end_position.clone();
		position0.multiplyScalar(1-t);
		position1.multiplyScalar(t);
		position0.add(position1);
		this._camera.position.copy(position0);

		var dir0 = cartesian_to_polar(this._start_target.clone().sub(this._start_position));
		var dir1 = cartesian_to_polar(this._end_target.clone().sub(this._end_position));
		while (dir1.x - dir0.x > Math.PI) dir0.x +=  2 * Math.PI;
		while (dir0.x - dir1.x > Math.PI) dir1.x +=  2 * Math.PI;
		var dd = new Vector3(dir0.x * (1-t)+dir1.x*t,dir0.y * (1-t) +dir1.y * t,1);
		var target = position0.add(polar_to_cartesian(dd));
		this._camera.lookAt(target);
		/*
		var target0 = this._start_target.clone();
		var target1 = this._end_target.clone();
		target0.multiplyScalar(1-t);
		target1.multiplyScalar(t);
		target0.add(target1);
		this._camera.lookAt(target0);*/

		this._camera.fov = this._start_fov * (1-t*t*t*t) + this._end_fov * t*t*t*t;

		this._clipping_plane_position = this._start_clipping_plane * (1-t) + this._end_clipping_plane * t;
		this._cb_clipping_plane_changed(this._clipping_plane_position);

		this.update_camera();
	}

	//*****************************************************
	//*** camera reset
	reset_camera () {
		if (this._scene._scene_center == null) return;

		this.start_animation();

		this._end_target.copy(this._scene._scene_center);
		this._end_position.copy(this._scene._scene_center);
		this._end_position.x -= this._scene._scene_radius*2;
		this._end_position.y -= this._scene._scene_radius*2;
		this._end_position.z += this._scene._scene_radius*2;
		this._end_clipping_plane = 1;
		this._end_fov = 30;

		this.refresh_rendering();
	}

	//*****************************************************
	//*** animate camera from a given position
	animate_camera(start_camera){
		if (this._scene._scene_center == null) return;
		if (start_camera == null) return;
		if (start_camera.constructor.name != this._camera.constructor.name) return;

		this.start_animation();

		this._start_position.copy(start_camera.position);
		var dir = new Vector3();
		start_camera.getWorldDirection(dir);
		this._start_target = this._start_position.clone();
		this._start_target.add(dir);
		this._start_fov = start_camera.fov;

		this.refresh_rendering();
	};


	//***********************************************************************************
	//**** zoom on selection
	zoom_on_selection() {
		if (this._scene._selection.length != 1) return;

		var bb = new Box3().setFromObject(this._scene._selection[0]);
		var center = bb.min.clone();
		center.add(bb.max);
		center.multiplyScalar(0.5);

		var size = bb.min.distanceTo(bb.max);
		if (size < 1) size = 1;

		this.start_animation();

		var z = bb.max.z;
		if (this._scene._selection[0].bim_type == "flat" || this._scene._selection[0].bim_type == "space")
			z -= 0.5;

		this._end_clipping_plane  = (z-this._scene._bounding_box.min.z) / (this._scene._bounding_box.max.z -  this._scene._bounding_box.min.z) - 0.05;

		var dir = center.clone();
		dir.sub(this._camera.position);
		dir.z = 0;
		dir.normalize();
		dir.multiplyScalar(size*2);
		dir.z = -size*2;

		this._end_target.copy(center);
		this._end_position.copy(center);
		this._end_position.sub(dir);

		this.refresh_rendering();
	}
}

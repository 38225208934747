import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { DiagnosticService } from '../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../model/diagnostic.model';

@Component({
    selector: 'app-documents',
    templateUrl: './document.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent extends BaseComponent implements OnInit {
    showFilter = false;
    currentDiagnostic: Diagnostic;

    constructor(private diagnosticService: DiagnosticService) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService.getCurrentDiagnostic().subscribe((diag) => {
            console.log(diag);
            this.currentDiagnostic = diag;
        });
    }
}

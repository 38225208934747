import { Injectable } from '@angular/core';

import { Intervention } from '../../../../model/intervention.model';
import * as moment from 'moment';
import { Electricite, ElectriciteConfig } from '../model/electricite.model';
import { ElectriciteApiService } from './electricite-api.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

/**
 * Service pour le diagnostic électricité.
 */
@Injectable({
    providedIn: 'root',
})
export class ElectriciteService {
    electriciteConfig$ = new BehaviorSubject<ElectriciteConfig>(undefined);

    constructor(private electriciteApiService: ElectriciteApiService) {}

    /**
     * Préparation du formulaire Electricite
     */
    prepareFormElectricite(intervention: Intervention, contenuDiagnostic: Electricite) {
        // la date d'installation doit être convertie en string pour fonctionner avec indexedDB
        // if (contenuDiagnostic.dateInstallation.valeur) {
        //   contenuDiagnostic.dateInstallation.valeur = moment(contenuDiagnostic.dateInstallation.valeur).toISOString();
        //}
    }

    initConfig() {
        if (!this.electriciteConfig$.getValue())
            this.electriciteApiService
                .getConfig()
                .pipe(map((configs) => configs[0]))
                .subscribe((config) => {
                    console.info(config);
                    this.electriciteConfig$.next(config);
                });
    }
}

<div class="container" *ngIf="currentContenuDiagnostic">
    <!--COMPTEUR-->
    <div class="row pt-4">
        <div class="col">
            <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                <mat-card-title>
                    <div class="d-flex justify-content-between align-items-center">
                        <app-section-title label="Compteur" [showHelp]="false"></app-section-title>
                    </div>
                </mat-card-title>

                <mat-card-content [formGroup]="formIntervention">
                    <div class="d-flex justify-content-around align-items-center age-installation">
                        <div class="d-flex col-xl-6 p-2 align-items-center">
                            <div class="col-xl-6 mat-subheading-2 m-0 primary required">Numéros de compteur</div>
                            <div class="col-xl-6 d-flex align-items-center">
                                <div class="w-100">
                                    <mat-form-field class="w-100">
                                        <mat-label>Numéros de compteur</mat-label>
                                        <input
                                            matInput
                                            formControlName="numeroCompteur"
                                            [disabled]="readonlyMode"
                                            [required]="!currentContenuDiagnostic.numeroCompteur.checked"
                                            (focus)="onfocusNumeroCompteur()"
                                        />
                                    </mat-form-field>
                                </div>
                                <button mat-icon-button color="primary" (click)="openNumKeyboard('numeroCompteur')">
                                    <mat-icon>dialpad</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="d-flex col-xl-6 p-2 align-items-center">
                            <mat-checkbox
                                class="col-xl-6"
                                color="primary"
                                formControlName="numeroCompteurNR"
                                [disabled]="readonlyMode"
                                (change)="numeroCompteurNRModified($event.checked)"
                                >Non renseigné
                            </mat-checkbox>
                            <app-notes-buttons
                                [from]="currentContenuDiagnostic.numeroCompteur"
                                [diagnostic]="diagnostic"
                                [typesCommentaires]="COMMENT_TYPES_COMPTEUR"
                                [replaceTypesCommentaires]="true"
                            >
                            </app-notes-buttons>
                        </div>
                    </div>
                    <app-form-error
                        [control]="formIntervention.get('numeroCompteur')"
                        [message]="'Numéros de compteur obligatoire'"
                    ></app-form-error>
                    <input
                        type="hidden"
                        formControlName="justificationCompteur"
                        [required]="
                            currentContenuDiagnostic.numeroCompteur.checked &&
                            currentContenuDiagnostic.numeroCompteur.commentairesId?.length == 0
                        "
                    />
                    <div
                        *ngIf="
                            currentContenuDiagnostic.numeroCompteur.checked &&
                            currentContenuDiagnostic.numeroCompteur.commentairesId?.length == 0
                        "
                        class="to-justify row align-items-center"
                    >
                        <div class="ml-2">Il manque une justification</div>
                        <app-notes-buttons
                            class="ml-1 justify"
                            [from]="currentContenuDiagnostic.numeroCompteur"
                            [diagnostic]="diagnostic"
                            [typesCommentaires]="COMMENT_TYPES_COMPTEUR"
                            [replaceTypesCommentaires]="true"
                        >
                        </app-notes-buttons>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <!--Année Installation-->
    <div class="row pt-4">
        <div class="col">
            <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                <mat-card-title>
                    <div class="d-flex justify-content-between align-items-center">
                        <app-section-title label="Année d'installation" [showHelp]="false"></app-section-title>
                    </div>
                </mat-card-title>

                <mat-card-content [formGroup]="formIntervention">
                    <div class="d-flex justify-content-around align-items-center age-installation">
                        <div class="d-flex col-xl-6 p-2 align-items-center">
                            <div class="col-xl-6 mat-subheading-2 m-0 primary required">Année d'installation</div>
                            <div class="col-xl-6 d-flex align-items-center">
                                <div class="w-100">
                                    <mat-form-field class="w-100">
                                        <mat-label>Année d'installation</mat-label>
                                        <input
                                            matInput
                                            formControlName="dateInstallation"
                                            [disabled]="readonlyMode"
                                            [required]="
                                                formIntervention.get('dateInstallationND')?.value == true ? false : true
                                            "
                                        />
                                    </mat-form-field>
                                </div>
                                <button mat-icon-button color="primary" (click)="openNumKeyboard('dateInstallation')">
                                    <mat-icon>dialpad</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="d-flex col-xl-6 p-2 align-items-center">
                            <div class="col-xl-6">
                                <div class="row">
                                    <mat-checkbox
                                        class="col-xl-6"
                                        color="primary"
                                        formControlName="dateInstallationEstimation"
                                        [disabled]="readonlyMode"
                                        (change)="dateEstimationModified($event.checked)"
                                        >Date estimée
                                    </mat-checkbox>
                                </div>
                                <div class="row">
                                    <mat-checkbox
                                        class="col-xl-6"
                                        color="primary"
                                        formControlName="dateInstallationND"
                                        [disabled]="readonlyMode"
                                        (change)="dateNDModified($event.checked)"
                                        >Non renseigné
                                    </mat-checkbox>
                                </div>
                            </div>
                            <app-notes-buttons
                                [from]="currentContenuDiagnostic.dateInstallation"
                                [diagnostic]="diagnostic"
                                [typesCommentaires]="COMMENT_TYPES"
                                [replaceTypesCommentaires]="true"
                            >
                            </app-notes-buttons>
                        </div>
                    </div>
                    <app-form-error
                        [control]="formIntervention.get('dateInstallation')"
                        message="'Date de l'installation obligatoire'"
                    ></app-form-error>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <!--Distributeur-->
    <div class="row">
        <div class="col">
            <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                <mat-card-title>
                    <app-section-title label="Distributeur" [showHelp]="false"></app-section-title>
                </mat-card-title>
                <mat-card-content [formGroup]="formIntervention">
                    <div class="d-flex justify-content-around align-items-center">
                        <div class="d-flex col-xl-6 p-2 align-items-center">
                            <div
                                class="col-xl-6 mat-subheading-2 m-0 primary"
                                [ngClass]="{
                                    required: formIntervention.get('fournisseurND')?.value == true ? false : true
                                }"
                            >
                                Nom du distributeur
                            </div>
                            <div class="col-xl-6">
                                <app-custom-select
                                    class="w-100"
                                    formControlName="fournisseur"
                                    [required]="formIntervention.get('fournisseurND')?.value == true ? false : true"
                                    [choices]="fournisseursToDisplay"
                                    [addLineButtonLabel]="'Ajouter un distributeur'"
                                    [sorted]="false"
                                    [placeholder]="'Distributeur'"
                                    [matFormFieldClass]="'w-100'"
                                    (valueChangeFromUser)="fournisseurChanged($event)"
                                >
                                </app-custom-select>
                            </div>
                        </div>
                        <div class="d-flex col-xl-6 p-2 align-items-center">
                            <mat-checkbox
                                class="col-xl-6"
                                color="primary"
                                formControlName="fournisseurND"
                                [disabled]="readonlyMode"
                                (change)="fournisseurNDModified($event.checked)"
                                >Non communiquée
                            </mat-checkbox>
                            <app-notes-buttons
                                [from]="currentContenuDiagnostic.fournisseur"
                                [diagnostic]="diagnostic"
                                [typesCommentaires]="COMMENT_TYPES"
                                [replaceTypesCommentaires]="true"
                            ></app-notes-buttons>
                        </div>
                    </div>
                    <app-form-error
                        [control]="formIntervention.get('fournisseur')"
                        message="Nom du distributeur obligatoire"
                    >
                    </app-form-error>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

import { Component, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Diagnostic } from '../../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../../services/diagnostic.service';
import { BaseComponent, DateUtils, MongoUtils } from 'src/app/commons-lib';
import { ProgressDiagnosticService } from '../../../../../services/progress-diagnostic.service';
import { Intervention, RelationInterventionBien } from '../../../../../model/intervention.model';
import { combineLatest } from 'rxjs';
import { InterventionService } from '../../../../../services/intervention.service';
import { Bouton, ElectriciteConfig, ValeurCheckpoint } from '../../model/electricite.model';
import { nonOption, nonVerifiabledOption, ouiOption, sansObjetOption } from '../../shared/electricite.constants';
import { PointDeControleBien } from '../../../../../model/point-de-controle.model';
import { Niveau } from '../../../../../model/bien.model';
import { ElectriciteService } from '../../services/electricite.service';
import { Commentaire } from '../../../../../model/commentaire.model';

@Component({
    selector: 'app-points-de-controle',
    templateUrl: './points-de-controle.component.html',
    styleUrls: ['./points-de-controle.component.scss'],
})
export class PointsDeControleComponent extends BaseComponent {
    /** Diagnostic courant */
    public diagnostic: Diagnostic;
    public routePlan: string = '/interventions/idIntervention/config/description/bien-principal';
    private intervention: Intervention;
    private _niveaux: Niveau[];
    private _boutons: Bouton[];

    constructor(
        private progressDiagnosticService: ProgressDiagnosticService,
        private interventionService: InterventionService,
        private electriciteService: ElectriciteService,
        private readonly diagnosticService: DiagnosticService
    ) {
        super();
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.electriciteService.electriciteConfig$,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic, electriciteConfig]) => {
                if (!electriciteConfig) {
                    return;
                }
                this.diagnostic = diagnostic;
                this.intervention = intervention;
                this._boutons = (electriciteConfig as ElectriciteConfig).boutons;
                const relationBienPrincipal: RelationInterventionBien =
                    Intervention.getRelationInterventionBienPrincipal(intervention);
                this.routePlan = this.routePlan.replace('idIntervention', relationBienPrincipal.idIntervention);
                this._niveaux = relationBienPrincipal.bien.description;
                if (!this.diagnostic.pointsDeControleBiens[0]) {
                    this.diagnostic.pointsDeControleBiens[0] = new PointDeControleBien();
                    this.diagnostic.pointsDeControleBiens[0].idBien = relationBienPrincipal.bien.id;
                    this.diagnostic.pointsDeControleBiens[0].nomBien = relationBienPrincipal.bien.nom;
                }
                this.setAutoResponsePiscineFontaines();
            });
    }

    @HostListener('document:click', ['$event'])
    onDetectClick($event: any) {
        this.progressDiagnosticService.refresh();
    }

    private setAutoResponsePiscineFontaines() {
        const infoPiscine = {
            codeBim: 'piscine',
            descriptionIfExist: 'Présence de piscine',
            descriptionIfNotExist: 'Absence de piscine',
        };
        const infoFontaines = {
            codeBim: 'fontaine',
            descriptionIfExist: 'Présence de fontaine',
            descriptionIfNotExist: 'Absence de fontaine',
        };
        for (const info of [infoPiscine, infoFontaines]) {
            const bouton = this.getButtonPiscineFontaines(info);
            if (!bouton) {
                console.error("Les boutons PISCINE/FONTAINE n'ont pas été trouvés");
                return;
            }
            for (const pcr of bouton.lstPointsControleReponse) {
                if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle]) {
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle] =
                        new ValeurCheckpoint();
                }
                if (pcr.isGrey) {
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].valeur =
                        this.getValueFromLibelle(pcr.reponse);
                }
                this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].isGrey = pcr.isGrey;
                this.diagnostic.pointsDeControleBiens[0].valeursParametres[
                    pcr.idPointControle
                ].greyLienPointControleExplication = pcr.greyLienPointControleExplication;
                this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].greyExplication =
                    pcr.greyInitialisationExplication;
                const idCom = this.addCommentaire(
                    pcr.greyInitialisationExplication,
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].commentairesId
                );
                if (idCom) {
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[pcr.idPointControle].commentairesId.push(
                        idCom
                    );
                }
            }
        }
    }

    /**
     * Ajout un commentaire sur le point de contrôle avec l'explication initiale
     * @param {string} expl
     * @param {string[]} commentairesId
     * @returns {string}
     */
    private addCommentaire(expl: string, commentairesId: string[]): string {
        if (!(expl && expl.length)) {
            return null;
        }
        // 1\ Vérification si non existant
        for (const id of commentairesId) {
            const comm: Commentaire = this.intervention.commentaires.find((com) => com.id == id);
            if (comm && comm.contenu == expl) {
                return null;
            }
        }

        // 2\ Création du commentaire
        const nouveauCommentaire = new Commentaire();
        nouveauCommentaire.date = DateUtils.localDateTimeString();
        nouveauCommentaire.id = MongoUtils.generateObjectId();
        nouveauCommentaire.type = 'REMARQUE';
        nouveauCommentaire.contenu = expl;
        this.intervention.commentaires = this.intervention.commentaires.concat(nouveauCommentaire);
        this.interventionService.updateIntervention(this.intervention);
        return nouveauCommentaire.id;
    }

    /**
     * Permet de setter les réponses automatique
     * Pour //Piscine et //Fontaines
     * @param {{codeBim: string; descriptionIfExist: string; descriptionIfNotExist: string}} data
     * @returns {Bouton}
     */
    private getButtonPiscineFontaines(info: {
        codeBim: string;
        descriptionIfExist: string;
        descriptionIfNotExist: string;
    }): Bouton {
        try {
            for (const niveau of this._niveaux) {
                for (const volume of niveau.volumes) {
                    for (const element of volume.elementsAControler) {
                        if (element.codeBim == info.codeBim) {
                            return this._boutons.find((btn) => btn.description == info.descriptionIfExist);
                        }
                    }
                }
            }
        } catch (e) {}
        return this._boutons.find((btn) => btn.description == info.descriptionIfNotExist);
    }

    private getValueFromLibelle(reponse: string): string | boolean {
        const option = [ouiOption, nonOption, nonVerifiabledOption, sansObjetOption].find(
            (opt) => opt.label.toLowerCase() == reponse.toLowerCase()
        );
        return option ? option.value : null;
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
import {cn_add, cn_clone} from "../utils/cn_utilities";
import {cn_3d_object_handler} from "./cn_3d_object_handler";
import {fh_clone} from "@acenv/fh-3d-viewer";
import {cn_3d_line_handler} from "./cn_3d_line_handler";
import {cn_svg_tool_edition} from "./cn_svg_tool_edition";
import {cn_column} from "../model/cn_column";
import {cn_beam} from "../model/cn_beam";
import {cn_beam_column_type} from "../model/cn_beam_column_type";
import {cn_svg_map} from "./cn_svg_map";
import {cn_camera} from "./cn_camera";
import { cn_beam_handler } from "./cn_beam_handler";

/**
 * @class cn_svg_tool_beams_edition
 * A class to manipulate beams and columns
 *
 * Available events :
 * - "beam_height_clicked(v)" : called when one beam height is clicked. Argument is height in meters. Expect a call to set_beam_height
 * - "column_height_clicked(v)" : called when column height is clicked. Argument is height in meters. Expect a call to set_column_height
 */
export class cn_svg_tool_beams_edition extends cn_svg_tool_edition {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_svg_map} map
	 */
	constructor(map) {
		super(map);
	}

	//***********************************************************************************
	//**** Selection callback
	//***********************************************************************************
	on_selection_change() {
		const obj = this;
		this._handlers = [];

		//*** We expect the selection to contain only openings */
		var sel = this._controller.get_selection();
		if (sel.length == 0 || sel.some(elt => elt.constructor != cn_beam && elt.constructor != cn_column))
			return false;

		this._handlers.push(new cn_beam_handler(sel,this._map));
		return true;
	}

	//***********************************************************************************
	//**** Group translation
	//***********************************************************************************
	translate(ev, offset) {
		var selection = this._controller.get_selection();
		for (var i in selection)
		{
			var elt = selection[i];
			if (elt.constructor == cn_beam)
			{
				for (var k in elt.vertices)
					elt.vertices[k] = cn_add(elt.vertices[k],offset);
			}

			if (elt.constructor == cn_column)
				elt.position = cn_add(elt.position,offset);
		}
	}

}


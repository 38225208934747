<h1 mat-dialog-title class="primary m-0">
    <span>{{ title }}</span>
</h1>

<form [formGroup]="form" (ngSubmit)="onClickValidate()">
    <div mat-dialog-content>
        <div class="mt-3">
            <label class="primary">{{ label }} <span class="required"></span></label> :
            <mat-form-field class="w-100 form-custom" appearance="outline">
                <textarea
                    matInput
                    formControlName="commentaireContent"
                    cdkAutosizeMinRows="5"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="9"
                    cdkAutosizeMaxRows="9"
                ></textarea>
            </mat-form-field>
            <mat-error>
                <app-form-control-error-message
                    [control]="form.get('commentaireContent')"
                ></app-form-control-error-message>
            </mat-error>
        </div>
    </div>

    <div mat-dialog-actions class="justify-content-end m-0">
        <button mat-button color="primary" (click)="onClickCancel()">
            <lib-icon>close</lib-icon>
            <span>{{ 'Annuler' | uppercase }}</span>
        </button>
        <button mat-raised-button type="submit" color="accent" [disabled]="form.invalid">
            <lib-icon>check</lib-icon>
            <span>{{ 'Valider' | uppercase }}</span>
        </button>
    </div>
</form>

import { Component, Input, OnInit } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { MatDialog } from '@angular/material/dialog';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Commentaire } from '../../../../../../model/commentaire.model';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { MesurageReportData, SubBlockMesurage } from '../../../model/mesurage.model';
import { TypePrestation } from '../../../../../../model/type-prestation.model';
import { BaseComponent } from 'src/app/commons-lib';
import { MESURE_NON_COMMUNIQUE } from '../../../shared/mesurage.constants';
import { ReportCommentsListModalComponent } from 'src/app/modules/diagnostics-shared/rapport/report-comments-list-modal/report-comments-list-modal.component';
import { Intervention } from 'src/app/model/intervention.model';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-liste-mesures-block',
    templateUrl: './liste-mesures-block.component.html',
    styleUrls: ['./liste-mesures-block.component.scss'],
})
export class ListeMesuresBlockComponent extends BaseComponent implements OnInit, IReportComponent {
    readonly MESURE_NON_COMMUNIQUE = MESURE_NON_COMMUNIQUE;

    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    mesures: SubBlockMesurage;
    exclusionsMesures: any;
    typePrestation: TypePrestation;
    displayedColumns: string[];
    displayedMesures: string[];
    readonlyMode = false;

    isMesurageUtile = false;

    private diagnostic: Diagnostic;

    constructor(private matDialog: MatDialog, private diagnosticService: DiagnosticService) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                this.diagnostic = diag;
                this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
                this.typePrestation = this.diagnostic.typePrestation;
                switch (this.typePrestation) {
                    case 'MESURAGE':
                        this.displayedColumns = ['surface carrez (m²)', 'autres surfaces (m²)'];
                        this.displayedMesures = ['carrez', 'horsCarrez'];
                        this.isMesurageUtile = false;
                        break;
                    case 'MESURAGE_HABITABLE':
                        this.displayedColumns = ['surface habitable (m²)', 'autres surfaces (m²)'];
                        this.displayedMesures = ['habitable', 'nonHabitable'];
                        this.isMesurageUtile = false;
                        break;
                    case 'MESURAGE_UTILE':
                        this.displayedColumns = [
                            'surface utile brute (m²)',
                            'surface utile nette (m²)',
                            'surface autre (m²)',
                        ];
                        this.displayedMesures = ['utileBrut', 'utileNet', 'utileAutre'];
                        this.isMesurageUtile = true;
                        break;
                }
                if (this.interReportData) {
                    const diagnosticData = this.interReportData.diagnostics.find(
                        (dia) => dia.typePrestation === this.typePrestation
                    ) as MesurageReportData;
                    if (diagnosticData) {
                        this.mesures = diagnosticData.mesures;
                    }
                }
                this.prepareExclusionLists();
            });
    }

    editDisplayedComments(commentaires: Commentaire[], code: string) {
        return this.matDialog
            .open(ReportCommentsListModalComponent, {
                data: {
                    comments: commentaires,
                    code: code,
                    hiddenComments: this.diagnostic.hiddenComments,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.diagnostic.hiddenComments = result.hiddenComments;
                    this.prepareExclusionLists();
                }
            });
    }

    private prepareExclusionLists() {
        this.exclusionsMesures = this.diagnostic.hiddenComments['MESURES']
            ? this.diagnostic.hiddenComments['MESURES']
            : [];
    }
}

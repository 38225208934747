'use strict';

import { cn_building, cn_space, cn_storey } from '../../..';
import { cn_normalize, cn_sub } from '../../cn_utilities';
import { cn_element_visitor } from '../cn_element_visitor';

//***********************************************************************************
//***********************************************************************************
//**** cn_space_of_elements_visitor
//***********************************************************************************
//***********************************************************************************

export class cn_space_of_elements_visitor extends cn_element_visitor {

    /**
     *  Constructor
     *  @param {cn_storey} storey
     */
    constructor(storey) {
        super();
        this.storey = storey;
        this.spaces = [];
        this.spaces_uniq_ref = new Set()
    }

    visit_wall(cn_wall) {
        (cn_wall.spaces || []).forEach(space => this._add_space(space));
    }

    visit_opening(cn_opening) {
        (cn_opening.wall.spaces || []).forEach(space => this._add_space(space));
    }

    visit_slab(cn_slab) {
        if (cn_slab.spaces[1]) {
            this._add_space(cn_slab.spaces[1]);
        }
    }

    visit_beam(cn_beam) {
        const spaceOne = this.storey.scene.find_space(cn_beam.vertices[0]);
        this._add_space(spaceOne);
        const direction = cn_sub(cn_beam.vertices[1], cn_beam.vertices[0]);
        let max_distance = cn_normalize(direction);
            let next_wall = null;
            let start_vertex = cn_beam.vertices[0];
        do {
            const next_wall_projection = this.storey.scene.raytrace(start_vertex, direction, max_distance, null, next_wall);
            if (next_wall_projection) {
                max_distance -= next_wall_projection.distance;
                next_wall = next_wall_projection.wall;
                start_vertex = next_wall_projection.point;
                this._add_space(next_wall.spaces[0]);
                this._add_space(next_wall.spaces[1]);
            } else {
                break;
            }
        } while(next_wall !== null)
    }

    visit_column(cn_column) {
        const space = this.storey.scene.find_space(cn_column.position);
        this._add_space(space);
    }

    visit_object(cn_object_instance) {
        this._add_space(cn_object_instance.space);
    }

    visit_stairs(cn_stairs) {
        this._add_space(cn_stairs.space);
    }

    visit_pipe(cn_pipe) {
        this._add_space(this.storey.scene.find_space(cn_pipe.vertices[0]))
        if (!cn_pipe.is_vertical()) {
            const direction = cn_sub(cn_pipe.vertices[1], cn_pipe.vertices[0]);
            let max_distance = cn_normalize(direction);
                let next_wall = null;
                let start_vertex = cn_pipe.vertices[0];
            do {
                const next_wall_projection = this.storey.scene.raytrace(start_vertex, direction, max_distance, null, next_wall);
                if (next_wall_projection) {
                    max_distance -= next_wall_projection.distance;
                    next_wall = next_wall_projection.wall;
                    start_vertex = next_wall_projection.point;
                    this._add_space(next_wall.spaces[0]);
                    this._add_space(next_wall.spaces[1]);
                } else {
                    break;
                }
            } while(next_wall !== null)
        }
    }

    /**
     *  Add uniq space in list
     *
     * @param {cn_space} space
     */
    _add_space(space) {
        if (space && !space.outside) {
            const key = `space#${space.ID}`;
            if (!this.spaces_uniq_ref.has(key)) {
                this.spaces_uniq_ref.add(key);
                this.spaces.push({
                    space_id: space.ID,
                    space_name: space.get_name(this.storey),
                    storey_id: this.storey.ID,
                });
            }
        }
    }

    visit_marker(cn_marker) {

    }

    visit_sampling(cn_marker) {

    }

}

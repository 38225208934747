import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../authentication/user.model';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { BaseComponent } from '../../utils/base.component';
import { UserManagementService } from '../user-management.service';

export interface UserDeleteDialogData {
    user: User;
    archiveOnly: boolean;
    /** Possibilité de remplacer l'utilisateur par un autre */
    canReplaceUser: boolean;
    /** Fonction de recherche par email (pour remplacer la fonction standard de recherche dans TOUS les emails de l'application */
    searchEmailFunction?: (email: string) => Observable<string[]>;
}

@Component({
    selector: 'lib-user-delete-dialog',
    templateUrl: './user-delete-dialog.component.html',
    styleUrls: ['./user-delete-dialog.component.css'],
})
export class UserDeleteDialogComponent extends BaseComponent implements OnInit {
    /**
     * Message de confirmation
     */
    messageCheckboxConfirm?: string;

    /**
     * Sécurité pour la confirmation
     */
    enableConfirm: boolean;

    message: string;
    confirmLabel: string;

    replacementUserEnabled = false;
    replacementUserEmail: string;

    /** Subjet pour l'autocomplétion de l'email */
    replacementUserEmailChanged$: Subject<void> = new Subject<void>();

    /** Liste des remplaçants potentiels (filtrés par email) */
    replacementUserOptions$: Observable<string[]>;

    constructor(
        private dialogRef: MatDialogRef<UserDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UserDeleteDialogData,
        private userManagementService: UserManagementService
    ) {
        super();
    }

    ngOnInit() {
        this.messageCheckboxConfirm = this.data.archiveOnly
            ? undefined
            : 'Oui, je suis conscient de ce que je fais, et je souhaite supprimer cet utilisateur.';
        this.enableConfirm = !this.messageCheckboxConfirm;
        this.message = this.data.archiveOnly
            ? "Êtes-vous certain de vouloir <b>archiver</b> l'utilisateur suivant :<br/><i>" +
              this.data.user.email +
              '</i> ?<br/><br/>' +
              '<b>Ses données ne seront pas supprimées</b>.'
            : "Êtes-vous certain de vouloir <b>supprimer</b> l'utilisateur suivant :<br/><i>" +
              this.data.user.email +
              '</i> ?<br/><br/>' +
              '<b>Ses données supprimées définitivement</b> !';
        this.confirmLabel = this.data.archiveOnly ? 'Archiver' : 'Supprimer';

        this.replacementUserOptions$ = this.replacementUserEmailChanged$.pipe(
            debounceTime(200),
            switchMap(() => this.searchEmail(this.replacementUserEmail))
        );
    }

    onConfirm(): void {
        // Contrôle de sécurité
        if (!this.enableConfirm) {
            return;
        }
        this.dialogRef.close({ replacementUserEmail: this.replacementUserEmail });
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

    private searchEmail(value: string): Observable<string[]> {
        if (!value) {
            return of([]);
        }
        return this.data.searchEmailFunction
            ? this.data.searchEmailFunction(value)
            : this.userManagementService
                  .searchByEmail(value.toLowerCase())
                  .pipe(
                      map((results) =>
                          results.map((user) => user.email).filter((email) => email != this.data.user.email)
                      )
                  );
    }
}

<div class="d-flex flex-row" *ngIf="currentContenuDiagnostic && formInsallation">
    <div class="container">
        <!--TYPE DE BIEN-->
        <div class="row pt-4">
            <div class="col">
                <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                    <mat-card-title>
                        <div class="d-flex justify-content-between align-items-center">
                            <app-section-title label="Type d'installation" [showHelp]="false"></app-section-title>
                            <app-notes-buttons
                                [from]="currentContenuDiagnostic.typeBien"
                                [diagnostic]="diagnostic"
                                [typesCommentaires]="COMMENT_TYPES"
                                [replaceTypesCommentaires]="COMMENT_TYPES"
                            ></app-notes-buttons>
                        </div>
                    </mat-card-title>
                    <mat-card-content [formGroup]="formInsallation">
                        <div class="d-flex align-items-center">
                            <div class="d-flex col-xl-6 p-2 align-items-center">
                                <app-state-input
                                    formControlName="typeBien"
                                    [labels]="true"
                                    [(choiceValue)]="currentContenuDiagnostic.typeBien.valeur"
                                    [choices]="choicesTypeBien"
                                    (valueChangeFromUser)="onChangeFromUser($event, 'typeBien')"
                                >
                                </app-state-input>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <!--INSTALLATION SOUS TENSION-->
        <div class="row">
            <div class="col">
                <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                    <mat-card-title>
                        <div class="d-flex justify-content-between align-items-center">
                            <app-section-title label="Installation sous tension" [showHelp]="false"></app-section-title>
                            <app-notes-buttons
                                [from]="currentContenuDiagnostic.sousTension"
                                [diagnostic]="diagnostic"
                                [typesCommentaires]="COMMENT_TYPES"
                                [replaceTypesCommentaires]="COMMENT_TYPES"
                            ></app-notes-buttons>
                        </div>
                    </mat-card-title>
                    <mat-card-content [formGroup]="formInsallation">
                        <div class="d-flex align-items-center">
                            <div class="d-flex col-xl-6 p-2 align-items-center">
                                <app-state-input
                                    formControlName="sousTension"
                                    [reset]="true"
                                    [labels]="true"
                                    [(choiceValue)]="currentContenuDiagnostic.sousTension.valeur"
                                    [choices]="choicesTension"
                                    (valueChangeFromUser)="onChangeFromUser($event, 'sousTension')"
                                >
                                </app-state-input>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <!--CAPOTS DES TABLEAUX ÉLECTRIQUES DÉMONTABLES-->
        <div class="row">
            <div class="col">
                <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                    <mat-card-title>
                        <div class="d-flex justify-content-between align-items-center">
                            <app-section-title
                                label="Capots des tableaux électriques démontables"
                                [showHelp]="false"
                            ></app-section-title>
                            <app-notes-buttons
                                [from]="currentContenuDiagnostic.capotsDemontables"
                                [diagnostic]="diagnostic"
                                [typesCommentaires]="COMMENT_TYPES_CAPOTS"
                                [replaceTypesCommentaires]="COMMENT_TYPES_CAPOTS"
                            ></app-notes-buttons>
                        </div>
                    </mat-card-title>

                    <mat-card-content [formGroup]="formInsallation">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex">
                                <app-state-input
                                    *ngIf="reloadComposant != 'capotsDemontables'"
                                    formControlName="capotsDemontables"
                                    [disabled]="readonlyMode"
                                    [reset]="true"
                                    [labels]="true"
                                    [choices]="choicesCapotsTableaux"
                                    [choiceValue]="currentContenuDiagnostic.capotsDemontables.valeur"
                                    (valueChangeFromUser)="onChangeFromUser($event, 'capotsDemontables')"
                                >
                                </app-state-input>
                            </div>
                            <div class="d-flex pr-5">
                                <button
                                    (click)="voirLesDispositifs('Capots')"
                                    color="black"
                                    [disabled]="!(nbElementsTableaux > 0)"
                                    mat-raised-button
                                    [ngClass]="{ colorWarn: dispositifsValid == false }"
                                >
                                    {{ 'VOIR LES DISPOSITIFS (' + nbElementsTableaux + ')' | uppercase }}
                                </button>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                currentContenuDiagnostic.capotsDemontables.valeur != null &&
                                currentContenuDiagnostic.capotsDemontables.valeur == 'aucun' &&
                                nbElementsTableaux >= 1 &&
                                !currentContenuDiagnostic.capotsDemontables.commentairesId?.length
                            "
                            class="to-justify"
                        >
                            Il manque une justification
                            <button
                                title="Ajouter un commentaire"
                                mat-icon-button
                                color="justify"
                                class="ml-1"
                                (click)="addJustification(currentContenuDiagnostic.capotsDemontables)"
                            >
                                <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <!--PRÉSENCE D’UN OU PLUSIEURS 30MA-->
        <div class="row">
            <div class="col">
                <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                    <mat-card-title>
                        <div class="d-flex justify-content-between align-items-center">
                            <app-section-title
                                label="Présence d’un ou plusieurs 30MA protégeant l’ensemble de l’installation "
                                [showHelp]="false"
                            ></app-section-title>
                            <app-notes-buttons
                                [from]="currentContenuDiagnostic.presenceDispositif30mA"
                                [diagnostic]="diagnostic"
                                [typesCommentaires]="COMMENT_TYPES"
                                [replaceTypesCommentaires]="COMMENT_TYPES"
                            ></app-notes-buttons>
                        </div>
                    </mat-card-title>
                    <mat-card-content [formGroup]="formInsallation">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex">
                                <app-state-input
                                    *ngIf="reloadComposant != 'presenceDispositif30mA'"
                                    formControlName="presenceDispositif30mA"
                                    [disabled]="readonlyMode"
                                    [reset]="true"
                                    [labels]="true"
                                    [choices]="choicesPresenceDispositif30mA"
                                    [choiceValue]="currentContenuDiagnostic.presenceDispositif30mA.valeur"
                                    (valueChangeFromUser)="onChangeFromUser($event, 'presenceDispositif30mA')"
                                >
                                </app-state-input>
                            </div>
                            <div class="d-flex pr-5">
                                <button
                                    (click)="voirLesDispositifs('Dispositif_30mA')"
                                    [disabled]="!(nbElements30mA > 0)"
                                    color="black"
                                    mat-raised-button
                                >
                                    {{ 'VOIR LES DISPOSITIFS (' + nbElements30mA + ')' | uppercase }}
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="col-xl-6" *ngIf="showDispositifCapotsTableauxElectrique">
        <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <button
                    class="bt-navigate bt-navigate-before"
                    mat-button
                    (click)="showDispositifCapotsTableauxElectrique = false"
                >
                    <lib-icon>navigate_before</lib-icon>
                </button>
                <div>
                    {{
                        currentKey == 'Capots'
                            ? 'Capots tableaux électrique'
                            : 'Présence dispositif 30mA est en tête de circuit'
                    }}
                </div>
            </div>
            <app-state-input
                *ngIf="currentKey == 'Capots'"
                [choices]="demontableChoices"
                [tooltips]="['Démontables', 'Non démontables']"
                [disabled]="readonlyMode"
                (valueChangeFromUser)="changeAll($event)"
            >
            </app-state-input>
        </div>

        <ng-template ngFor let-niveau [ngForOf]="niveaux">
            <ng-template ngFor let-volume [ngForOf]="niveau.volumes">
                <ng-container *ngIf="volume.elementsAControler?.length">
                    <div *ngIf="volumeHaseElement(volume)" class="bold">{{ volume.nom }}</div>
                    <ng-template ngFor let-element [ngForOf]="volume.elementsAControler">
                        <div
                            *ngIf="
                                currentKey == 'Capots'
                                    ? element.valeursParametres['tableau_electrique']
                                    : element.valeursParametres['differentiel']
                            "
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div>{{ element.nom }}</div>
                            <div class="d-flex align-items-center">
                                <app-notes-buttons
                                    class="mr-2"
                                    [ngStyle]="{
                                        'pointer-events': diagnostic.pointsDeControleBiens[0].valeursParametres[
                                            element.id
                                        ]
                                            ? ''
                                            : 'none'
                                    }"
                                    [from]="diagnostic.pointsDeControleBiens[0].valeursParametres[element.id]"
                                    [diagnostic]="diagnostic"
                                    [typesCommentaires]="COMMENT_TYPES_CAPOTS"
                                    [replaceTypesCommentaires]="COMMENT_TYPES_CAPOTS"
                                ></app-notes-buttons>
                                <app-state-input
                                    *ngIf="currentKey == 'Capots'"
                                    class="right-separation"
                                    [choices]="demontableChoices"
                                    [tooltips]="['Démontable', 'Non démontable']"
                                    [choiceValue]="
                                        diagnostic.pointsDeControleBiens[0].valeursParametres[element.id]?.valeur
                                    "
                                    (valueChangeFromUser)="setElementAControler(element.id, $event)"
                                >
                                </app-state-input>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                currentKey == 'Capots'
                                    ? element.valeursParametres['tableau_electrique']
                                    : element.valeursParametres['differentiel']
                            "
                        >
                            <div
                                *ngIf="
                                    diagnostic.pointsDeControleBiens[0].valeursParametres[element.id] != null &&
                                    diagnostic.pointsDeControleBiens[0].valeursParametres[element.id].valeur ==
                                        'nonDemontable' &&
                                    !diagnostic.pointsDeControleBiens[0].valeursParametres[element.id].commentairesId
                                        ?.length &&
                                    !currentContenuDiagnostic.capotsDemontables.commentairesId?.length
                                "
                                class="to-justify"
                            >
                                Il manque une justification
                                <button
                                    title="Ajouter un commentaire"
                                    mat-icon-button
                                    color="justify"
                                    class="ml-1"
                                    (click)="
                                        addJustification(
                                            diagnostic.pointsDeControleBiens[0].valeursParametres[element.id]
                                        )
                                    "
                                >
                                    <lib-icon theme="mdi" iconName="comment-alert"></lib-icon>
                                </button>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </ng-template>
        </ng-template>
    </div>
</div>

<div class="container">
    <div class="py-4 d-flex flex-column">
        <ng-container *ngIf="optionPlan; else elseOptionPlanNotActivated">
            <div *ngIf="!hasPlan">Pas de plan présent dans cette intervention</div>
            <ng-container *ngIf="hasPlan">
                <app-plans-list
                    class="liste-screenshots mb-3"
                    [showActionbutton]="!readonlyMode"
                    [screenshotsSvg]="currentDiagnostic.screenshotsPlan"
                    (clickDeleteScreenshot)="onClickDeleteScreenshot($event)"
                    (clickEditScreenshot)="onClickEditScreenshot($event)"
                ></app-plans-list>

                <mat-card *ngIf="!readonlyMode" class="d-flex small-card action-card align-self-center">
                    <mat-card-content class="w-100">
                        <button class="w-100" mat-button (click)="onClickAddNewScreenshot()">
                            <lib-icon>add</lib-icon>
                            <br />Créer un nouveau plan de repérage
                        </button>
                    </mat-card-content>
                </mat-card>
            </ng-container>
        </ng-container>
        <ng-template #elseOptionPlanNotActivated> <div>L'option plan n'est pas activée.</div></ng-template>
    </div>
</div>

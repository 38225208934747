import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogDuplicationComponent } from '../dialog-duplication/dialog-duplication.component';
import { CalculatriceModaleComponent } from '../../../../../diagnostics-shared/calculatrice-modale/calculatrice-modale.component';
import { ConfirmationService } from 'src/app/commons-lib';

@Component({
    selector: 'app-dialog-calcul-partie',
    templateUrl: './dialog-calcul-partie.component.html',
    styleUrls: ['./dialog-calcul-partie.component.scss'],
})
export class DialogCalculPartieComponent implements OnInit {
    zones: {
        nom?: string;
        surface?: number;
        quantite?: number;
        style?: string;
        mode?: string;
    }[] = [];
    displayedColumnsZones: string[] = ['mode', 'nom', 'surface', 'quantite', 'edit'];
    editZone: boolean;
    readonlyMode: boolean;
    nameAlreadyPresent: boolean;
    zoneToEdit: {
        nom?: string;
        surface?: number;
        quantite?: number;
        style?: string;
        mode?: string;
    };
    editMode: boolean;
    originalName: string;
    constructor(
        public dialogRef: MatDialogRef<DialogDuplicationComponent>,
        private confirmationService: ConfirmationService,
        private matDialog: MatDialog
    ) {}

    ngOnInit(): void {}

    onValider() {
        this.dialogRef.close(this.zones);
    }
    deleteZone(zone: any) {
        this.confirmationService.confirmWarn('Êtes-vous sûr de vouloir supprimer cette partie ?', () => {
            this.zones = this.zones.filter((z) => z.nom != zone.nom);
        });
    }

    editZoneRow(zone: any) {
        this.zoneToEdit = zone;
        this.editZone = true;
        this.editMode = true;
        this.originalName = zone.nom;
    }

    validateZone() {
        if (this.nameAlreadyPresent || !this.zoneToEdit.nom || !this.zoneToEdit.surface || !this.zoneToEdit.mode) {
            return;
        }
        if (!this.editMode) {
            this.zones.push(this.zoneToEdit);
        }
        this.editZone = false;
        this.editMode = false;
    }
    get total(): number {
        return this.zones
            .map((z) => (z.mode == 'add' ? +z.surface * z.quantite : -1 * +(z.surface * z.quantite)))
            .reduce((a, b) => +a + +b, 0);
    }
    /**
     * Vérification d'unicité de nom de zone
     */
    isUniqueName() {
        this.nameAlreadyPresent =
            this.zones.filter((zone) => zone.nom == this.zoneToEdit.nom).length > (this.editMode ? 1 : 0);
    }

    /**
     * Mode saisie
     */
    openNumKeyboard(property: string) {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'saisie',
                    originalValue: this.zoneToEdit[property],
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.zoneToEdit[property] = result.resultat;
                }
            });
    }

    /**
     * La mesure est égale à zéro
     */
    setZero() {
        this.zoneToEdit.surface = 0;
    }
    /**
     * Mode calculatrice
     */
    openCalculator() {
        return this.matDialog
            .open(CalculatriceModaleComponent, {
                data: {
                    mode: 'calculatrice',
                    originalValue: this.zoneToEdit.surface,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.zoneToEdit.surface = result.resultat;
                }
            });
    }
}

<div class="container">
    <div class="row pt-4">
        <div class="col">
            <mat-card class="px-2 py-2 d-flex flex-column justify-content-between">
                <mat-card-title>
                    <div class="d-flex justify-content-between align-items-center">
                        <app-section-title label="Informations générales" [showHelp]="false"></app-section-title>
                    </div>
                </mat-card-title>

                <mat-card-content [formGroup]="formGeneral">
                    <!--NOM DE L'ENTREPRISE-->
                    <div class="d-flex flex-column">
                        <div class="p-2">Nom de l'entreprise ayant réalisé les travaux</div>
                        <div class="flex-row">
                            <mat-form-field class="ml-2 col-6">
                                <mat-label>Nom de l'entreprise ayant réalisé les travaux</mat-label>
                                <input
                                    title="NomEntreprise"
                                    matInput
                                    [required]=""
                                    (focus)="formGeneral.patchValue({ nomEntrepriseNR: null })"
                                    formControlName="nomEntreprise"
                                    [disabled]="readonlyMode"
                                />
                            </mat-form-field>
                            <mat-checkbox
                                class="ml-2"
                                color="primary"
                                formControlName="nomEntrepriseNR"
                                [disabled]="readonlyMode"
                                (change)="formGeneral.patchValue({ nomEntreprise: null })"
                                >Non renseigné
                            </mat-checkbox>
                        </div>
                    </div>
                    <!--NUMÉRO DE SIREN-->
                    <div class="d-flex flex-column">
                        <div class="p-2">Numéro de SIREN</div>
                        <div class="ml-2 flex-row">
                            <mat-form-field class="col-6">
                                <mat-label>Numéro de SIREN</mat-label>
                                <input
                                    title="numSiren"
                                    matInput
                                    type="number"
                                    [pattern]="'[0-9]{9}'"
                                    oninput="if (value.length > 9) value = value.slice(0, 9);"
                                    formControlName="numeroSIREN"
                                    [required]=""
                                    (focus)="formGeneral.patchValue({ numeroSIRENNR: null })"
                                    [disabled]="readonlyMode"
                                />
                            </mat-form-field>
                            <mat-checkbox
                                class="ml-2"
                                color="primary"
                                formControlName="numeroSIRENNR"
                                [disabled]="readonlyMode"
                                (change)="formGeneral.patchValue({ numeroSIREN: null })"
                                >Non renseigné
                            </mat-checkbox>
                        </div>
                        <div
                            *ngIf="diagnostic?.typePrestation.includes('BAREN10')"
                            class="d-flex flex-row justify-content-between px-0 col-6"
                        >
                            <div class="p-2" matTooltip="Reconnu garant de l'environnement">Entreprise RGE</div>
                            <div class="d-flex flex-row">
                                <mat-button-toggle-group formControlName="entrepriseRGE">
                                    <mat-button-toggle value="OUI"> Oui </mat-button-toggle>
                                    <mat-button-toggle value="NON"> Non </mat-button-toggle>
                                </mat-button-toggle-group>
                                <app-notes-buttons
                                    class="mr-2"
                                    [from]="ceeModel.entrepriseRGE"
                                    [diagnostic]="diagnostic"
                                    [typesCommentaires]="COMMENT_TYPES"
                                >
                                </app-notes-buttons>
                            </div>
                        </div>
                    </div>
                    <!--DATE DE LA VISITE PRÉALABLE-->
                    <div
                        *ngIf="
                            diagnostic &&
                            (diagnostic.typePrestation.includes('BAREN101') ||
                                diagnostic.typePrestation.includes('BAREN102') ||
                                diagnostic.typePrestation.includes('BAREN103'))
                        "
                        class="d-flex flex-column"
                    >
                        <div class="p-2">Date de la visite préalable</div>
                        <div class="flex-row">
                            <mat-form-field class="ml-2 col-6">
                                <mat-label>Date de la visite préalable</mat-label>
                                <input
                                    title="dateVisitePrealable"
                                    matInput
                                    [required]=""
                                    (focus)="formGeneral.patchValue({ dateVisitePrealableNR: null })"
                                    [matDatepicker]="datepicker"
                                    formControlName="dateVisitePrealable"
                                    [disabled]="readonlyMode"
                                />
                                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                                <mat-datepicker #datepicker startView="multi-year"></mat-datepicker>
                            </mat-form-field>
                            <mat-checkbox
                                class="ml-2"
                                color="primary"
                                formControlName="dateVisitePrealableNR"
                                [disabled]="readonlyMode"
                                (change)="formGeneral.patchValue({ dateVisitePrealable: null })"
                                >Non renseignée
                            </mat-checkbox>
                        </div>
                    </div>

                    <!--DATE D'ACCEPTATION DU DEVIS-->
                    <div class="d-flex flex-column">
                        <div class="p-2">Date d'acceptation du devis</div>
                        <div class="flex-row">
                            <mat-form-field class="ml-2 col-6">
                                <mat-label>Date d'acceptation du devis</mat-label>
                                <input
                                    title="dateAcceptationDevis"
                                    matInput
                                    [required]=""
                                    (focus)="formGeneral.patchValue({ dateAcceptationDevisNR: null })"
                                    [matDatepicker]="datepickerAD"
                                    formControlName="dateAcceptationDevis"
                                    [disabled]="readonlyMode"
                                />
                                <mat-datepicker-toggle matSuffix [for]="datepickerAD"></mat-datepicker-toggle>
                                <mat-datepicker #datepickerAD startView="multi-year"></mat-datepicker>
                            </mat-form-field>
                            <mat-checkbox
                                class="ml-2"
                                color="primary"
                                formControlName="dateAcceptationDevisNR"
                                [disabled]="readonlyMode"
                                (change)="formGeneral.patchValue({ dateAcceptationDevis: null })"
                                >Non renseignée
                            </mat-checkbox>
                        </div>
                    </div>

                    <!--DATE DEBUT DES TRAVAUX-->
                    <div
                        *ngIf="
                            diagnostic &&
                            (diagnostic.typePrestation.includes('BAREN101') ||
                                diagnostic.typePrestation.includes('BAREN102') ||
                                diagnostic.typePrestation.includes('BAREN103'))
                        "
                        class="d-flex flex-column"
                    >
                        <div class="p-2">Date de début des travaux</div>
                        <div class="flex-row">
                            <mat-form-field class="ml-2 col-6">
                                <mat-label>Date d'acceptation du devis</mat-label>
                                <input
                                    title="dateDebutTravaux"
                                    matInput
                                    [required]=""
                                    (focus)="formGeneral.patchValue({ dateDebutTravauxNR: null })"
                                    [matDatepicker]="datepickerDT"
                                    formControlName="dateDebutTravaux"
                                    [disabled]="readonlyMode"
                                />
                                <mat-datepicker-toggle matSuffix [for]="datepickerDT"></mat-datepicker-toggle>
                                <mat-datepicker #datepickerDT startView="multi-year"></mat-datepicker>
                            </mat-form-field>
                            <mat-checkbox
                                class="ml-2"
                                color="primary"
                                formControlName="dateDebutTravauxNR"
                                [disabled]="readonlyMode"
                                (change)="formGeneral.patchValue({ dateDebutTravaux: null })"
                                >Non renseignée
                            </mat-checkbox>
                        </div>
                    </div>

                    <!-- DATE DE LA FACTURE -->
                    <div class="d-flex flex-column">
                        <div class="p-2">Date de la facture</div>
                        <div class="flex-row">
                            <mat-form-field class="ml-2 col-6">
                                <mat-label>Date de la facture</mat-label>
                                <input
                                    title="dateFacture"
                                    matInput
                                    [required]=""
                                    (focus)="formGeneral.patchValue({ dateFactureNR: null })"
                                    [matDatepicker]="datepickerFact"
                                    formControlName="dateFacture"
                                    [disabled]="readonlyMode"
                                />
                                <mat-datepicker-toggle matSuffix [for]="datepickerFact"></mat-datepicker-toggle>
                                <mat-datepicker #datepickerFact startView="multi-year"></mat-datepicker>
                            </mat-form-field>
                            <mat-checkbox
                                class="ml-2"
                                color="primary"
                                formControlName="dateFactureNR"
                                [disabled]="readonlyMode"
                                (change)="formGeneral.patchValue({ dateFacture: null })"
                                >Non renseignée
                            </mat-checkbox>
                        </div>
                    </div>
                    <ng-container *ngIf="diagnostic?.typePrestation.includes('BARTH16')">
                        <!--RÉFÉRENCE DE LA FACTURE-->
                        <div class="d-flex flex-column">
                            <div class="p-2">Référence de la facture</div>
                            <div class="flex-row">
                                <mat-form-field class="ml-2 col-6">
                                    <mat-label>Référence de la facture</mat-label>
                                    <input
                                        matInput
                                        [required]=""
                                        (focus)="formGeneral.patchValue({ refFactureNR: null })"
                                        formControlName="refFacture"
                                        [disabled]="readonlyMode"
                                    />
                                </mat-form-field>
                                <mat-checkbox
                                    class="ml-2"
                                    color="primary"
                                    formControlName="refFactureNR"
                                    [disabled]="readonlyMode"
                                    (change)="formGeneral.patchValue({ refFacture: null })"
                                    >Non renseignée
                                </mat-checkbox>
                            </div>
                        </div>
                        <!--DATE DE L'ETAT RECAPITULATIF -->
                        <div *ngIf="diagnostic?.typePrestation.includes('BARTH161')" class="d-flex flex-column">
                            <div class="p-2">Date de l'état récapitulatif</div>
                            <div class="flex-row">
                                <mat-form-field class="ml-2 col-6">
                                    <mat-label>Date de l'état récapitulatif</mat-label>
                                    <input
                                        title="dateEtatRecapitulatif"
                                        matInput
                                        [required]=""
                                        (focus)="formGeneral.patchValue({ dateEtatRecapitulatifNR: null })"
                                        [matDatepicker]="datepickerER"
                                        formControlName="dateEtatRecapitulatif"
                                        [disabled]="readonlyMode"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="datepickerER"></mat-datepicker-toggle>
                                    <mat-datepicker #datepickerER startView="multi-year"></mat-datepicker>
                                </mat-form-field>
                                <mat-checkbox
                                    class="ml-2"
                                    color="primary"
                                    formControlName="dateEtatRecapitulatifNR"
                                    [disabled]="readonlyMode"
                                    (change)="formGeneral.patchValue({ dateEtatRecapitulatif: null })"
                                    >Non renseignée
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

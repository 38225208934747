// Info isolant
import { StateChoiceBoxes } from '../../../../model/states.model';
import { Icon } from 'src/app/commons-lib';

export const NOM_DE_LA_SOLUTION = 'Nom de la solution :';
export const TYPE_ISOLANT = "Type d'isolant";
export const CODE_NON_COMMUNIQUE = 'nonCommunique';
export const CODE_VIDE = 'vide';

// export const IDS_NOM_SOLUTION = ['PC228', 'PC137', 'PC57'];
// export const predicateNomISolution = (value) => IDS_NOM_SOLUTION.includes(value.id);
export const ouiOption = new StateChoiceBoxes('Oui', 'oui', new Icon('check_circle'), 'ok');
export const nonOption = new StateChoiceBoxes('Non', 'non', new Icon('cancel'), 'ko');

export const CODE_ISOLANT_SOUFFLE = 'isolantSouffle';
export const CODE_ISOLANT_DEROULE = 'isolantDeroule';
export const CODE_ISOLANT_SOUSRAMPANT = 'isolantSousRampant';

// Anomalie
export const INFERIEUR = '<';
export const INFERIEUR_EGAL = '<=';
export const SUPPERIEUR = '>';
export const SUPPERIEUR_EGAL = '>=';
export const EGAL = '=';

// Contantes choix réponses
export const CODE_OUI = 'oui';
export const CODE_NON = 'non';
export const CODE_NON_VISIBLE = 'nonVisible';
export const CODE_SANS_OBJET = 'sansObjet';
export const CODE_PARTIELLEMENT_ACCESSIBLE = 'partAccessible';
export const CODE_AUCUNE = 'aucune';
export const CODE_NE_SAIS_PAS = 'neSaisPas';
export const CODE_NON_REALISE = 'nonRealise';
export const CODE_COTE_FROID = 'coteFroid';
export const ABSENT_OBLIGATOIRE = 'absentObligatoire';
export const ABSENT_PRESENT_FACTURE = 'absentPresentFacture';
export const NON_VERIFIABLE_OBLIGATOIRE_ABSENT_FACTURE = 'nonVerifiableObligatoireAbsentFacture';

export const sansObjetOption = new StateChoiceBoxes('Sans objet', CODE_SANS_OBJET, new Icon('error'), 'warning');
export const nonVisibleOption = new StateChoiceBoxes('Non visible', CODE_NON_VISIBLE, new Icon('visibility_off'));
export const partiellementAccessible = new StateChoiceBoxes(
    'Partiellement Accessible',
    CODE_PARTIELLEMENT_ACCESSIBLE,
    new Icon('error'),
    'warning'
);
// Section rapport
export const PERFORMANCE_THERMIQUE = 'PerformanceThermique';
export const ACCESSIBILITE_REALISATION = 'AccessibiliteEtRealisation';
export const ETAT_SUPPORT = 'EtatDuSupport';
export const SURFACE_ISOLEE = 'SurfaceIsolee';
export const PARE_VAPEUR = 'PareVapeur';
export const QUALITE = 'Qualite';
export const ASPECT_RESISTANCE = 'AspectResistance';
export const LONGUEURS_RESEAU = 'LongueursRéseau';
export const LONGUEURS_RESEAU_ISOLEES = 'LongueurDeReseauIsolees';
export const JUSTESSE_ETAT = 'JustesseEtat';
export const NON_QUALITE = 'NonQualite';
export const CONCLUSION = 'Conslusion';

"use strict";
import { cn_element } from "../model/cn_element";
import { cn_camera } from "./cn_camera";
import { cn_svg_map } from "./cn_svg_map";
import {cn_pastille} from "./cn_pastille";
import { cn_element_type_input } from "./cn_inputs";

export class cn_type_pastille extends cn_pastille {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_element[]} elements
	 * @param {string} key
	 * @param {function} get_choices
	 * @param {cn_svg_map} map
	 */
	constructor(elements, key, get_choices, transaction_name, map) {
		super();

		this._elements = elements;
		this._key = key;
		this._map = map;
		this._get_choices = get_choices;
		this._transaction_name= transaction_name;
		this.rectangle = [30,30];
	}

	//***********************************************************************************
	//**** Force one measure
	//***********************************************************************************
	/**
	 *
	 * @param {cn_camera} camera
	 * @returns
	 */
	draw(camera) {

		if (!this.rectangle)
			this.rectangle = [camera.thumb_size,camera.thumb_size];

		const element_type = this._elements[0][this._key];
		this.label = element_type.draw_svg_icon(this.rectangle[0],this.rectangle[1]);
		this.incoherent = this._elements.some(element => element[this._key] != element_type);

		return super.draw(camera);
	}

	//***********************************************************************************
	//**** Called on clicking
	//***********************************************************************************
	clicked() {
		var obj = this;
		const input = new cn_element_type_input("Choix du type");
		input.choices = this._get_choices();
		input.selection = [];
		this._elements.forEach(element => {
			var index = input.choices.indexOf(element[this._key]);
			if (index >= 0 && input.selection.indexOf(index) < 0) input.selection.push(index);
		});

		input.callback = function() {
			const index = input.selection[0];
			if (index < 0 || index > input.choices.length) return;
			const new_type = input.choices[index];
			obj._map._building.transaction_manager.push_transaction(obj._transaction_name);
			obj._elements.forEach(element => {
				obj._map._building.transaction_manager.push_item_set(element,obj._key);
				element[obj._key] = new_type;
			});
			obj._map._scene.update();
			obj._map._scene.update_deep();
			obj._map.refresh();
		}

		this._map.call("element_type_input",input);
	}
}


import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PointDeControleElement } from 'src/app/model/point-de-controle.model';
import { Conformite } from '../../../model/conformite.model';

class ElementModalData {
    constructor(public elementsToDisplay: PointDeControleElement[]) {}
}

/**
 * Cette modale permet d'afficher une liste d'éléments sous forme de card avec la conformité pour chacun.
 */
@Component({
    selector: 'app-display-element-modal',
    templateUrl: './display-element-modal.component.html',
    styleUrls: ['./display-element-modal.component.scss'],
})
export class DisplayElementModalComponent implements OnInit {
    elementsToDisplay: PointDeControleElement[] = [];

    readonly enumConformite = Conformite;

    constructor(
        private dialogRef: MatDialogRef<DisplayElementModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ElementModalData
    ) {}

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth500-dialog');
        this.elementsToDisplay = this.data.elementsToDisplay;
    }

    cancel() {
        this.dialogRef.close(false);
    }
}

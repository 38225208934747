import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Component({
    selector: 'lib-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
    /** Le fichier doit-il être une image ? */
    @Input() isImage = false;

    /** Upload en cours ? */
    @Input() uploadRunning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /** Pourcentage d'upload */
    @Input() uploadProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    @Output() startUpload = new EventEmitter<FormData>();

    @Output() uploadFinished = new EventEmitter<boolean>();

    @ViewChild('fileUpload') fileUpload: ElementRef;

    constructor() {}

    ngOnInit() {}

    selectFile() {
        this.fileUpload.nativeElement.click();
    }

    uploadFile() {
        const formData = new FormData();
        formData.append('file', this.fileUpload.nativeElement.files[0]);
        this.startUpload.emit(formData);
        this.uploadProgress.next(0);
        this.uploadRunning.next(true);
    }

    handleUploadFileServiceCall(callback: Observable<HttpEvent<any>>) {
        callback
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            this.uploadProgress.next(Math.round((event.loaded * 100) / event.total));
                            break;
                        case HttpEventType.Response:
                            this.uploadProgress.next(100);
                            this.uploadRunning.next(false);
                            this.uploadFinished.emit(true);
                            // Il faut réinitialiser la valeur du l'input pour pouvoir uploader 2x le même fichier (sinon l'évènement change n'est pas déclenché)
                            this.fileUpload.nativeElement.value = null;
                            return event;
                    }
                }),
                catchError(() => {
                    this.uploadProgress.next(100);
                    this.uploadRunning.next(false);
                    this.uploadFinished.emit(false);
                    return of('File upload failed !');
                })
            )
            .subscribe(() => {});
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Column
//***********************************************************************************
//***********************************************************************************

import {cn_element} from "./cn_element";
import {cn_space} from "./cn_space";
import {cn_box, cn_cart, cn_clone, cn_dot, cn_normal, cn_normalize, cn_sub} from "../utils/cn_utilities";
import {fh_add, fh_matrix, fh_mul, fh_polygon, fh_sub, fh_extruded_polygon} from "@acenv/fh-3d-viewer";
import {IPN_SHAPE, cn_beam_column_type} from "./cn_beam_column_type";
import { cn_storey } from "./cn_storey";
import { cn_element_visitor } from '../utils/visitors/cn_element_visitor';
import { cn_scene } from "./cn_scene";


export class cn_column extends cn_element {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_beam_column_type} element_type
	 */
	constructor(element_type) {
		super();

		//*** Model data
		this.position = [0,0];
		this.orientation = 0;
		this.height = 0;
		this.element_type = element_type;
	}

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize() {
		var json = {};
		json.ID = this.ID;
		json.position = cn_clone(this.position);
		json.orientation = this.orientation;
		json.height = this.height;
		json.element_type = this.element_type.ID;
		return json;
	}

	static unserialize(json,scene) {
		if (typeof(json.ID) != 'string') return false;
		if (typeof(json.position) != 'object') return false;
		if (typeof(json.element_type) != 'string') return false;

		var et = null;
		if (typeof(json.element_type) == 'string')
			et = scene.building.get_element_type(json.element_type);
		if (et == null)
		{
			console.log("use default beam type");
			et = scene.building.get_beam_types()[0];
		}

		var column = new cn_column(et);
		column.ID = json.ID;
		column.position = cn_clone(json.position);
		if (typeof(json.orientation) == 'number')
			column.orientation = json.orientation;
		if (typeof(json.height) == 'number')
			column.height = json.height;
		scene.columns.push(column);
	}

	//***********************************************************************************
	//**** Draw
	//***********************************************************************************
	draw(camera, add_classes = [], fill = '') {
		var html = "";

		var mouseover = (add_classes.indexOf("mouseover") >= 0);
		var selected = (add_classes.indexOf("selected") >= 0);

		if (this.status < 0)
			html += "<g opacity='0.3'>";

		var p = camera.world_to_screen(this.position);
		var sz= [this.element_type.width * camera.world_to_screen_scale,this.element_type.thickness * camera.world_to_screen_scale];

		html += "<g transform='translate(" + p[0] + "," + p[1] + ") rotate(" + (-this.orientation) + ") translate(" + (-0.5*sz[0]) + "," + (-0.5*sz[1]) + ") '>";

		var path = "";
		if (this.element_type.shape == "ipn")
		{
			path += "<path d='M ";
			for (var i=0;i<IPN_SHAPE.length;i++)
			{
				if (i > 0) path += "L ";
				path += (sz[0]*IPN_SHAPE[i][0]) + " " + (sz[1]*IPN_SHAPE[i][1]) + " ";
			}
			path += "Z'";
		}
		else if (this.element_type.shape == "circle")
			path += "<ellipse cx='" + (sz[0]*0.5) + "' cy='" + (sz[1]*0.5) + "' rx='" + (sz[0]*0.5) + "' ry='" + (sz[1]*0.5) + "' ";
		else
			path += "<rect x='0' y='0' width='" + sz[0] + "' height='" + sz[1] + "' ";

		if (mouseover || selected)
		{
			html += path + ` class="column_highlight ${(selected)?"selected":"mouseover"}" />`;
		}

		html += path + " class='beam_" + this.element_type.material + " column " + add_classes.join(" ") + "' " + fill + " />";

		html += "</g>";

		if (this.status < 0)
			html += "</g>";

		return html;
	}

	//***********************************************************************************
	//**** Contains
	//***********************************************************************************
	contains(point, tolerance=0) {
		var d = cn_sub(point,this.position);
		var sz0 = this.element_type.width + 2 * tolerance;
		var sz1 = this.element_type.thickness + 2 * tolerance;
		if (cn_dot(d,d) > sz0*sz0 + sz1*sz1) return false;
		var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
		if (Math.abs(cn_dot(d,dx)) > sz0 * 0.5) return false;
		var dy = cn_normal(dx);
		if (Math.abs(cn_dot(d,dy)) > sz1 * 0.5) return false;
		return true;
	}

	//***********************************************************************************
	//**** Build 3D solid
	//***********************************************************************************
	/*build_solid(h, hmax) {
		var dx = cn_cart([1,this.orientation * Math.PI / 180]);
		var dy = cn_normal(dx);
		dx.push(0);
		dy.push(0);
		var dz = [0,0,hmax - h];
		if (this.height && this.height <= dz[2])
			dz[2]= this.height;

		var ori = cn_clone(this.position);
		ori.push(h);
		ori = fh_add(ori,fh_mul(dx,-0.5*this.element_type.width));
		ori = fh_add(ori,fh_mul(dy,-0.5*this.element_type.thickness));

		var solid = new fh_solid();
		solid.brick(ori,fh_mul(dx,this.element_type.width),fh_mul(dy,this.element_type.thickness),dz);
		return solid;
	}*/

	//***********************************************************************************
	//**** Build 3D solid
	//***********************************************************************************
	build_solid(h, hmax) {
		var dx = cn_cart([1,this.orientation * Math.PI / 180]);
		var dy = cn_normal(dx);
		dx.push(0);
		dy.push(0);
		var dz = [0,0,1];

		var ori = cn_clone(this.position);
		ori.push(h);
		ori = fh_add(ori,fh_mul(dx,-0.5*this.element_type.width));
		ori = fh_add(ori,fh_mul(dy,-0.5*this.element_type.thickness));

		var solid_height = (this.height)?this.height:hmax - h;
		var solid = this.element_type.build_solid(solid_height);
		var matrix = new fh_matrix();
		matrix.load_axis(ori,dx,dy,dz);
		solid.apply_matrix(matrix);
		return solid;
	}
	//***********************************************************************************
	//**** Build footprint
	//***********************************************************************************
	build_footprint(h) {
		var dx = cn_cart([1,this.orientation * Math.PI / 180]);
		var dy = cn_normal(dx);
		dx.push(0);
		dy.push(0);
		dx = fh_mul(dx,this.element_type.width);
		dy = fh_mul(dy,this.element_type.thickness);

		var ori = cn_clone(this.position);
		ori.push(h);
		ori = fh_add(ori,fh_mul(dx,-0.5));
		ori = fh_add(ori,fh_mul(dy,-0.5));

		var polygon = new fh_polygon(ori,[0,0,1]);
		var contour = [];
		contour.push(ori);
		var p = fh_add(ori,dx);
		contour.push(p);
		p = fh_add(p,dy);
		contour.push(p);
		p = fh_sub(p,dx);
		contour.push(p);
		polygon.add_contour(contour);
		return polygon;
	}

	/**
	 * Builds an extruded poilygon
	 * @param {number} h : height of the floor
	 * @param {cn_storey} storey
	 * @returns {fh_extruded_polygon}
	 */
	build_extruded_polygon(h, storey, delta_h=0)
	{
		var height = 0;
		if (this.height)
			height += this.height;
		else
		{
			height += storey.height
			if (storey.roof)
			{
				var hh = storey.roof.compute_height(this.position);
				if (hh !== false)
					height += hh;
			}
		}

		var dx = cn_cart([1,this.orientation * Math.PI / 180]);
		var dy = cn_normal(dx);
		dx.push(0);
		dy.push(0);
		var dz = [0,0,1];

		var ori = cn_clone(this.position);
		ori.push(h);
		ori = fh_add(ori,fh_mul(dx,-0.5*this.element_type.width));
		ori = fh_add(ori,fh_mul(dy,-0.5*this.element_type.thickness));

		var extruded_polygon = fh_extruded_polygon.build_extrusion(this.element_type.build_footprint(),[0,0,height + delta_h],this.element_type.get_color());

		var matrix = new fh_matrix();
		matrix.load_axis(ori,dx,dy,dz);
		extruded_polygon.matrix = matrix;
		return extruded_polygon;
	}

	//***********************************************************************************
	/**
	 * Vertex operation : transform all vertices
	 * @param {function} operation : vertex operator
	 */
	vertex_operation(operation) {
		operation(this.position);
	}

	//***********************************************************************************
	/**
	 * Performs a rotation operation
	 * @param {number[]} center : center of ritation
	 * @param {number} angle : rotation angle, in radians
	 * @param {function} rotation_function : fnction that transforms a 2D point
	 */
	perform_rotation(center, angle, rotation_function)
	{
		rotation_function(this.position);
		this.orientation += angle * 180 / Math.PI;
	}

	//***********************************************************************************
	/**
	 * flip operation : transform all vertices
	 * @param {number[]} center : center of flip
	 * @param {boolean} horizontal : true for horizontal flip, vertical otherwise
	 * @param {function} operation : vertex operator
	 */
	perform_flip(center,horizontal, operation) {
		operation(this.position);
		if (horizontal)
			this.orientation = 180 - this.orientation;
		else
			this.orientation = - this.orientation;
	}

	//***********************************************************************************
	/**
	 * Returns bounding box
	 * @returns {cn_box}
	 */
	get_bounding_box() {
		var box = new cn_box();
		box.enlarge_point(this.position);
		return box;
	}

    /**
     * Accept element visitor
     *
     * @param {cn_element_visitor} element_visitor
     */
    accept_visitor(element_visitor) {
        element_visitor.visit_column(this);
    }

    /**
     * Returns all spaces linked to the column.
     * @param {cn_scene} scene
     * @returns {cn_space[]}
     */
    get_spaces(scene) {
        return [scene.find_space(this.position)];
    }

}


"use strict";

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Material
//***********************************************************************************
//***********************************************************************************

var CN_MATERIALS = [];

export var CN_MATERIAL_TYPE_HEAVY_STRUCTURE = 0;
export var CN_MATERIAL_TYPE_LIGHT_STRUCTURE = 1;
export var CN_MATERIAL_TYPE_INSULATED_STRUCTURE = 2;
export var CN_MATERIAL_TYPE_INSULATOR = 3;
export var CN_MATERIAL_TYPE_FACING = 4;
export var CN_MATERIAL_TYPE_OTHER = 4;

export class cn_material {
	constructor(name="Béton", code="concrete", thickness=0.1, conductivity=0, density=0, specific_heat=0, type=CN_MATERIAL_TYPE_HEAVY_STRUCTURE) {
		this.name = name;
		this.code = code;
		this.type = type;

		this.thickness = thickness;
		this.conductivity = conductivity;
		this.density = density;
		this.specific_heat = specific_heat;
	}

	//***********************************************************************************
	/**
	 * Build default materials
	 */
	static build_default_materials() {
		if (CN_MATERIALS.length > 0) return;
		CN_MATERIALS.push(new cn_material("Béton","concrete",0.2,2,1250,100,CN_MATERIAL_TYPE_HEAVY_STRUCTURE));
		CN_MATERIALS.push(new cn_material("Parpaing","block",0.2,0.5,500,1900,CN_MATERIAL_TYPE_HEAVY_STRUCTURE));
		CN_MATERIALS.push(new cn_material("Brique","brick",0.2,0.5,900,2000,CN_MATERIAL_TYPE_HEAVY_STRUCTURE));
		CN_MATERIALS.push(new cn_material("Pierre","stone",0.2,2.5,2700,1000,CN_MATERIAL_TYPE_HEAVY_STRUCTURE));
		CN_MATERIALS.push(new cn_material("Bois","wood",0.2,0.14,500,2500,CN_MATERIAL_TYPE_HEAVY_STRUCTURE));
		CN_MATERIALS.push(new cn_material("Acier","steel",0.2,0.14,500,2500,CN_MATERIAL_TYPE_HEAVY_STRUCTURE));
		CN_MATERIALS.push(new cn_material("Bois isolé","insulated_wood",0.2,0.05,520,2500,CN_MATERIAL_TYPE_INSULATED_STRUCTURE));
		CN_MATERIALS.push(new cn_material("Béton isolé","insulated_concrete",0.2,0.05,500,920,CN_MATERIAL_TYPE_INSULATED_STRUCTURE));
		CN_MATERIALS.push(new cn_material("Acier isolé","insulated_steel",0.2,0.05,520,2500,CN_MATERIAL_TYPE_INSULATED_STRUCTURE));

		CN_MATERIALS.push(new cn_material("Isolant minéral","insulator",0.1,0.04,25,1030,CN_MATERIAL_TYPE_INSULATOR));
		CN_MATERIALS.push(new cn_material("Isolant naturel","wood_fiber",0.1,0.038,160,2100,CN_MATERIAL_TYPE_INSULATOR));
		CN_MATERIALS.push(new cn_material("Isolant synthétique","styrofoam",0.1,0.037,25,1700,CN_MATERIAL_TYPE_INSULATOR));
		CN_MATERIALS.push(new cn_material("Lame d'air","air_layer",0.1,0.06,1,1,CN_MATERIAL_TYPE_INSULATOR));
		CN_MATERIALS.push(new cn_material("Air","air",0.1,0.025,1,1,CN_MATERIAL_TYPE_OTHER));

		CN_MATERIALS.push(new cn_material("Enduit","cement",0.1,1.15,800,900,CN_MATERIAL_TYPE_FACING));
		CN_MATERIALS.push(new cn_material("Bardage","tiles",0.1,0.14,500,2500,CN_MATERIAL_TYPE_FACING));
		CN_MATERIALS.push(new cn_material("Tuiles","roof_tiles",0.02,1.15,1900,1634,CN_MATERIAL_TYPE_FACING));

		CN_MATERIALS.push(new cn_material("Plâtre","gypsum",0.1,0.38,900,1008,CN_MATERIAL_TYPE_LIGHT_STRUCTURE));

		CN_MATERIALS.push(new cn_material("Inconnu","unknown",0,0,0,0,CN_MATERIAL_TYPE_HEAVY_STRUCTURE));
	}

	//***********************************************************************************
	/**
	 * Returns a default material by code
	 * @param {string} code
	 */
	static material_by_code(code, thickness = 0.1)
	{
		cn_material.build_default_materials();
		for (var i in CN_MATERIALS)
		{
			if (CN_MATERIALS[i].code != code) continue;
			var mat = CN_MATERIALS[i].clone();
			mat.thickness = thickness;
			return mat;
		}
		console.error("Could not find material '" + code + "'");
		return null;
	}

	//***********************************************************************************
	/**
	 * Returns a default material by name
	 * @param {string} name
	 */
	 static material_by_name(name, thickness = 0.1)
	 {
		 cn_material.build_default_materials();
		 for (var i in CN_MATERIALS)
		 {
			 if (CN_MATERIALS[i].name != name) continue;
			 var mat = CN_MATERIALS[i].clone();
			 mat.thickness = thickness;
			 return mat;
		 }
		 console.error("Could not find material '" + name + "'");
		 return null;
	 }
 
	//***********************************************************************************
	//**** Clone
	//***********************************************************************************
	clone() {
		var c = new cn_material();
		c.name = this.name;
		c.code = this.code;
		c.type = this.type;

		c.thickness = this.thickness;
		c.conductivity = this.conductivity;
		c.density = this.density;
		c.specific_heat = this.specific_heat;
		return c;
	}

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize() {
		var json = {};
		json.name = this.name;
		json.code = this.code;
		json.thickness = this.thickness;
		json.conductivity = this.conductivity;
		json.density = this.density;
		json.specific_heat = this.specific_heat;
		return json;
	}

	static unserialize(json) {
		if (typeof(json.name) != 'string') return false;
		if (typeof(json.code) != 'string') return false;
		if (typeof(json.thickness) != 'number') return false;

		var ref = cn_material.material_by_code(json.code,json.thickness);
		if (ref) return ref;

		var mat = new cn_material();
		mat.name = json.name;
		mat.code = json.code;
		mat.thickness = json.thickness;

		if (json.conductivity)
			mat.conductivity = json.conductivity;
		else if (ref)
			mat.conductivity = ref.conductivity;

		if (json.density)
			mat.density = json.density;
		else if (ref)
			mat.density = ref.density;

		if (json.specific_heat)
			mat.specific_heat = json.specific_heat;
		else if (ref)
			mat.specific_heat = ref.specific_heat;

		return mat;
	}

	//***********************************************************************************
	//**** returns label
	//***********************************************************************************
	get_label() {
		return this.name + " " + (this.thickness*100).toFixed(0) + "cm";
	}

	//***********************************************************************************
	//**** Comparison
	//***********************************************************************************
	is_equal_to(other)
	{
		if (this.name != other.name) return false;
		if (Math.abs(this.thickness - other.thickness) > 0.001) return false;
		if (this.code != other.code) return false;

		return true;
	}
	//***********************************************************************************
	//**** draw
	//***********************************************************************************
	draw(origin, width, height, scale)
	{
		if (width <= 0) return "";
		if (height <= 0) return "";

		var html = "";
		var color = [200,200,200];
		if (this.code == "brick")
			color = [150,20,20];
		else if (this.code == "stone")
			color = [182,171,136];
		else if (this.code == "wood" || this.code == "insulated_wood" || this.code == "tiles")
			color = [165,132,80];
		else if (this.code == "steel" || this.code == "insulated_steel")
			color = [70,70,70];
		else if (this.code == "insulator")
			color = [236,233,183];
		else if (this.code == "wood_fiber")
			color = [158,220,121];
		else if (this.code == "styrofoam")
				color = [255,255,255];
		else if (this.code == "roof_tiles")
			color = [177,62,62];
		else if (this.code == "gypsum")
			color = [230,230,230];
		else if (this.code == "air_layer")
			color = [230,230,255];

		html += "<rect x='" + origin[0] + "' y='" + origin[1] + "' width='" + width + "' height='" + height + "' style='fill:rgb(" + color[0] + "," + color[1] + "," + color[2] + ");stroke:none;' />";

		if (this.code == "block")
		{
			for (var y=0;y<height;y += 0.2*scale)
				html += "<line x1='" + origin[0] + "' y1='" + y + "' x2='" + (origin[0]+width) + "' y2='" + y + "' style='stroke:black;' />";
		}
		else if (this.code == "brick")
		{
			for (var y=0;y<height;y += 0.1*scale)
				html += "<line x1='" + origin[0] + "' y1='" + y + "' x2='" + (origin[0]+width) + "' y2='" + y + "' style='stroke:black;' />";
		}
		else if (this.code == "stone")
		{
			var offset = 5;
			for (var y=0;y<height;y += 0.2*scale)
			{
				html += "<line x1='" + origin[0] + "' y1='" + (y - offset) + "' x2='" + (origin[0]+width) + "' y2='" + (y + offset) + "' style='stroke:black;' />";
				offset = -offset;
			}
		}
		else if (this.code == "wood" || this.code == "insulated_wood" )
		{
			var thk_x = 0.02 * scale;
			var thk_y = 0.04 * scale;
			if (this.code == "insulated_wood")
				color = [236,233,183];
			else
				color = [82,66,40];
			for (var y=0;y<height;y += 0.4*scale)
			{
				html += "<rect x='" + (origin[0] + thk_x) + "' y='" + (y + thk_y) + "' width='" + (width -thk_x) + "' height='" + (0.4*scale - thk_y) + "' style='fill:rgb(" + color[0] + "," + color[1] + "," + color[2] + ");stroke:none;' />";
			}
		}
		else if (this.code == "steel" || this.code == "insulated_steel" )
		{
			var thk_x = 0;
			var thk_y = 0.15 * scale;
			if (this.code == "insulated_steel")
				color = [236,233,183];
			else
				color = [150,150,150];
			for (var y=-0.5*thk_y;y<height;y += 0.4*scale)
			{
				html += "<rect x='" + (origin[0] + thk_x) + "' y='" + (y + thk_y) + "' width='" + (width -thk_x) + "' height='" + (0.4*scale - thk_y) + "' style='fill:rgb(" + color[0] + "," + color[1] + "," + color[2] + ");stroke:none;' />";
			}
			var thk_x = 0.03 * scale;
			var thk_y = 0.04 * scale;
			for (var y=-0.5*thk_y;y<height;y += 0.4*scale)
			{
				html += "<rect x='" + (origin[0] + thk_x) + "' y='" + (y + thk_y) + "' width='" + (width -2*thk_x) + "' height='" + (0.4*scale - thk_y) + "' style='fill:rgb(" + color[0] + "," + color[1] + "," + color[2] + ");stroke:none;' />";
			}
		}
		else if (this.code == "insulated_concrete" )
		{
			var thk_x = scale * 0.25 * this.thickness;
			var thk_dx = scale * 0.5 * this.thickness;
			var thk_y = 0.05 * scale;
			var thk_dy = 0.2 * scale;
			color = [236,233,183];
			for (var y=0;y<height;y += thk_dy + 2 * thk_y)
			{
				html += "<rect x='" + (origin[0] + thk_x) + "' y='" + (y + thk_y) + "' width='" + thk_dx + "' height='" + thk_dy + "' style='fill:rgb(" + color[0] + "," + color[1] + "," + color[2] + ");stroke:none;' />";
			}
		}
		else if (this.type == CN_MATERIAL_TYPE_INSULATOR && this.code != "air_layer")
		{
			html += "<path d='M " + origin[0] + " " + origin[1] + " ";

			var pattern = width / 2;
			for (var y=0; y < height; y+= pattern * 2)
			{
				html += "Q " + origin[0] + " " + (y + pattern) + " ";
				html += " " + (origin[0]+width*0.5) + " " + (y + pattern*0.5) + " ";
				html += "Q " + (origin[0]+width) + " " + y  + " ";
				html += " " + (origin[0]+width) + " " + (y + pattern) + " ";
				html += "Q " + (origin[0]+width) + " " + (y + pattern * 2) + " ";
				html += " " + (origin[0]+width*0.5) + " " + (y + pattern*1.5) + " ";
				html += "Q " + origin[0] + " " + (y + pattern) + " ";
				html += " " + origin[0] + " " + (y + pattern*2) + " ";
			}
			html += "' style='fill: none;stroke:black;' />";
		}
		return html;
	}
}


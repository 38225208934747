import { NgModule } from '@angular/core';
import { DescriptionBienPageComponent } from './description-bien-page/description-bien-page.component';
import { DescriptionBienComponent } from './description-bien/description-bien.component';
import { DescriptionRoomSectionComponent } from './description-room-section/description-room-section.component';
import { CopyEquipmentModalComponent } from './copy-equipment-modal/copy-equipment-modal.component';
import { AddCustomPreequippedRoomModalComponent } from './add-custom-preequipped-room-modal/add-custom-preequipped-room-modal.component';
import { CommonModule } from '@angular/common';
import { CnDiagSharedModule } from '../../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LibIconModule, LibIncrementInputModule, LibLongPressModule, LibPipesModule } from 'src/app/commons-lib';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../../../pipes/pipes.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { Intervention, RelationInterventionBien } from '../../../model/intervention.model';
import { MapEditorModule } from '@acenv/cnmap-angular-editor-lib';
import { DiagnosticsSharedModule } from '../../diagnostics-shared/diagnostics-shared.module';
import { AddNiveauModalComponent } from './add-niveau-modal/add-niveau-modal.component';
import { AddVolumeModalComponent } from './add-volume-modal/add-volume-modal.component';
import { ModalTransfertUsageComponent } from './description-bien/modal-transfert-usage/modal-transfert-usage.component';

// NB : Les fonctions passées dans l'objet "data" de la description du routage doivent être donc fonctions nommés exportées, sinon le code ne compile pas
export function interventionRouteDescriptionIterateFunction(context: {
    currentIntervention: Intervention;
}): RelationInterventionBien[] {
    return context.currentIntervention.relationInterventionBiens.filter((b) => !b.isBienPrincipal);
}

export function interventionRouteDescriptionIteratePath(relationInterventionBien: RelationInterventionBien): string {
    return relationInterventionBien.bien.id;
}

export function interventionRouteDescriptionIterateLabel(relationInterventionBien: RelationInterventionBien): string {
    return relationInterventionBien.bien.nom;
}

export function interventionRouteDescriptionIterateSuffix(relationInterventionBien: RelationInterventionBien): string {
    return '_' + relationInterventionBien.bien.id;
}

@NgModule({
    imports: [
        CommonModule,
        CnDiagSharedModule,
        MatCardModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        LibIncrementInputModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        MatDividerModule,
        MatProgressBarModule,
        MatSidenavModule,
        MatButtonToggleModule,
        MatTooltipModule,
        LibPipesModule,
        PipesModule,
        MatTabsModule,
        MatMenuModule,
        DragDropModule,
        MatSelectModule,
        MatExpansionModule,
        MatDialogModule,
        ReactiveFormsModule,
        LibIconModule,
        LibLongPressModule,
        MapEditorModule,
        DiagnosticsSharedModule,
    ],
    declarations: [
        DescriptionBienPageComponent,
        DescriptionBienComponent,
        DescriptionRoomSectionComponent,
        CopyEquipmentModalComponent,
        AddCustomPreequippedRoomModalComponent,
        AddNiveauModalComponent,
        AddVolumeModalComponent,
        ModalTransfertUsageComponent,
    ],
})
export class DescriptionBienModule {}

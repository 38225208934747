import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibIconModule } from 'src/app/commons-lib';
import { GestionReferentielsComponent } from './gestion-referentiels.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [GestionReferentielsComponent],
    imports: [CommonModule, LibIconModule, MatToolbarModule, MatCardModule, MatButtonModule],
})
export class GestionReferentielsModule {}

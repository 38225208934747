import { Component, Input, OnInit } from '@angular/core';
import { DocumentData, InterventionReportData, ReportBlockData } from '../../../../../model/rapport.model';

@Component({
    selector: 'app-documents-list-block',
    templateUrl: './documents-list-block.component.html',
    styleUrls: ['./documents-list-block.component.scss'],
})
export class DocumentsListBlockComponent implements OnInit {
    @Input() data: ReportBlockData;
    @Input() interReportData: InterventionReportData;

    documentsToDisplay: DocumentData[] = [];

    ngOnInit(): void {
        this.documentsToDisplay = this.interReportData?.diagnostics[0]?.documentsData?.filter(
            (it) => it.afficherDansListeDocuments
        );
    }
}

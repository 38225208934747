"use strict"
import { cn_balcony_type } from '../model/cn_balcony_type';
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************
//**** Tool for generic unserialization of element types
//***********************************************************************************
//***********************************************************************************
//***********************************************************************************

import { cn_beam_column_type } from '../model/cn_beam_column_type';
import { cn_opening_type } from '../model/cn_opening_type';
import { cn_roof_opening_type } from '../model/cn_roof_opening_type';
import { cn_slab_type } from '../model/cn_slab_type';
import { cn_stairs_type } from '../model/cn_stairs_type';
import { cn_wall_type } from '../model/cn_wall_type';
import { cn_pipe_type } from '../model/cn_pipe_type';
import { cn_fence_type } from '../model/cn_fence_type';
import { cn_fence_opening_type } from '../model/cn_fence_opening_type';

const serializedUtils = {
    'cn_wall_type': cn_wall_type,
    'cn_opening_type': cn_opening_type,
    'cn_slab_type': cn_slab_type,
    'cn_roof_opening_type': cn_roof_opening_type,
    'cn_beam_column_type': cn_beam_column_type,
    'cn_stairs_type': cn_stairs_type,
    'cn_balcony_type': cn_balcony_type,
    'cn_fence_type': cn_fence_type,
    'cn_pipe_type': cn_pipe_type,
    'cn_fence_opening_type': cn_fence_opening_type
};

export class cn_unserialize_element_type {
    constructor() {
    }

    static unserialize(json) {
        const unserializer = serializedUtils[json.class_name];
        if (unserializer) {
            return unserializer.unserialize(json);
        } else {
            throw `Unknown element type for unserialization : ${json.class_name}`
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { TypeCommentaire } from '../../../../model/type-commentaire.model';
import { BaseComponent } from 'src/app/commons-lib';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { Commentaire } from '../../../../model/commentaire.model';
import { EtatProgressionService } from '../../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'app-recommandations',
    templateUrl: './recommandations.component.html',
    styleUrls: ['./recommandations.component.scss'],
})
export class RecommandationsComponent extends BaseComponent implements OnInit {
    typeCommentaire: TypeCommentaire = 'RECOMMANDATION';
    diagnostic: Diagnostic;

    constructor(
        private readonly diagnosticService: DiagnosticService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe), withLatestFrom(this.route.data))
            .subscribe(([diag, data]) => {
                this.diagnostic = diag;
                this.etatProgressionService.updateDiagnostic(data['code'], 'VOID', this.diagnostic);
            });
    }

    commentairesChanged(recoms: Commentaire[]) {
        this.diagnostic.recommandationsFinales = recoms;
    }
}

// Codes bim pour les types d'éléments à contrôler

import { environment } from '../../../environments/environment';

/** Code bim du type élément à contrôler "Équipements" */
export const CODE_BIM_TYPE_ELEMENT_EQUIPEMENTS = 'equipements';

/** Code bim du type élément à contrôler "Équipements sanitaires" */
export const CODE_BIM_TYPE_ELEMENT_EQUIPEMENTS_SANITAIRES = 'equipements_sanitaires';

/** Code bim du type élément à contrôler "Élément du réseau d'évacuation" */
export const CODE_BIM_TYPE_ELEMENT_RESEAUX_EVACUATION = 'Réseaux évacuation';

// Codes bim et valeurs pour les paramètres d'espaces

/** Code bim du paramètre d'espace "Humide" */
export const CODE_BIM_PARAM_ESPACE_HUMIDE = 'humide';
/** Code bim du paramètre d'espace "Chauffé" */
export const CODE_BIM_PARAM_ESPACE_CHAUFFE = 'chauffe';
/** Code bim du paramètre d'espace non réputé Carrez */
export const CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ = 'non_repute_carrez';
/** Code bim du paramètre d'espace non réputé Habitable */
export const CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE = 'non_repute_habitable';
/** Code bim du paramètre d'espace non réputé Utile */
export const CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE = 'non_repute_utile';

// Codes bim et valeurs pour les paramètres de types d'élément à contrôler

/** Code bim du paramètre de type d'élement à contrôler "Réseau d'évacuation" */
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION = 'reseau_evacuation';

/** Valeur du paramètre "Réseau d'évacuation" -> Eaux ménagères */
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EM = 'EM';

/** Valeur du paramètre "Réseau d'évacuation" -> Eaux usées */
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EU = 'EU';

/** Valeur du paramètre "Réseau d'évacuation" -> Eaux pluviales */
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EP = 'EP';

/** Valeur du paramètre "Réseau d'évacuation" -> Eaux vannes */
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EV = 'EV';

/** Valeur du paramètre "Réseau d'évacuation" -> Toilettes sèches */
export const CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_SECHES = 'TOILETTES_SECHES';

/** Catégorie de l'élément à contrôler */
export const CODE_BIM_PARAM_CATEGORIE = 'categorie';

/** Valeur équipement de la catégorie de l'élément à contrôler */
export const CODE_BIM_PARAM_CATEGORIE_EQUIPEMENT = 'EQUIPEMENT';

/** Valeur réseau de la catégorie de l'élément à contrôler */
export const CODE_BIM_PARAM_CATEGORIE_RESEAU = 'RESEAU';

/** Valeur autre de la catégorie de l'élément à contrôler */
export const CODE_BIM_PARAM_CATEGORIE_AUTRE = 'AUTRE';

/** Volume visité */
export const PARAM_VOLUME_VISITE = 'visite';

/** Numéro de lot */
export const PARAM_VOLUME_LOT = 'lot';

/**
 * TOOLTIPS
 */
export const PARAM_TOOLTIP_PRESENT = 'Présent';
export const PARAM_TOOLTIP_ABSENT = 'Absent';

export const PARAM_TOOLTIP_OUI = 'Oui';
export const PARAM_TOOLTIP_NON = 'Non';

export const PARAM_TOOLTIP_VISITEE = 'Visitée';
export const PARAM_TOOLTIP_PARTIELLEMENT_VISITEE = 'Partiellement visitée';
export const PARAM_TOOLTIP_NON_VISITEE = 'Non visitée';

export const PARAM_TOOLTIP_PARTIELLEMENT_ACCESSIBLE = 'Partiellement accessible';

export const PARAM_TOOLTIP_ODEUR_PRESENTE = 'Odeur présente';
export const PARAM_TOOLTIP_ODEUR_ABSENTE = 'Odeur absente';

export const PARAM_TOOLTIP_ETAT_BON = 'Bon';
export const PARAM_TOOLTIP_ETAT_MOYEN = 'Moyen';
export const PARAM_TOOLTIP_ETAT_MAUVAIS = 'Mauvais';

export const PARAM_TOOLTIP_NON_VISIBLE = 'Non visible';

export const PARAM_TOOLTIP_CONTROLE_VISUEL = 'Visuel';
export const PARAM_TOOLTIP_CONTROLE_COLORANT = 'Colorant';
export const PARAM_TOOLTIP_CONTROLE_FUMIGENE = 'Fumigène';

export const PARAM_TOOLTIP_ZONE_AJOUT = 'Ajouter la zone';
export const PARAM_TOOLTIP_ZONE_DEDUCTION = 'Déduire la zone';

export const PARAM_TOOLTIP_DEDUIRE = 'Calculer';
export const PARAM_TOOLTIP_CALCULTATRICE = 'Calculatrice';
export const PARAM_TOOLTIP_REINITIALISER = 'Ré-initialiser';

export const PARAM_TOOLTIP_AJOUTE = 'Ajouter';
export const PARAM_TOOLTIP_RETIRE = 'Retirer';

/** FORMAT DE DATE */
export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_FRANCAIS_HH_MM_MOMENT = 'DD/MM/yyyy HH:mm';
export const DATE_FORMAT_FRANCAIS_HH_MM = 'dd/MM/yyyy HH:mm';
export const DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT = 'DD/MM/YYYY';
export const DATE_FORMAT_FRANCAIS_SANS_HEURE = 'dd/MM/yyyy';
export const DATE_FORMAT_INTERNATIONAL_HH_MM = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';

/** MOIS */
export const MONTHS = [
    'Avant',
    'Après',
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
];

/** REGEX */
export const REGEX_EMAIL = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
export const REGEX_TELEPHONE = '^[0-9]{0,14}$';

export const URL_MS_REPORT_FILE = `s3://${environment.defaultBucket}/`;

export const CONTACT_AC_ENVIRONNEMENT = 'AC Environnement';

export const HTTP_STATUS_CONFLICT = 409;

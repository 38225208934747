import { Component, OnInit } from '@angular/core';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import {
    compareChapitre,
    comparePointControle,
    nonVerifiabledOption,
    routesPointsDeControle,
} from '../../../shared/electricite.constants';
import { ElectriciteService } from '../../../services/electricite.service';
import { first } from 'rxjs/operators';
import {
    Chapitre,
    ICheckpoints,
    Photo,
    PointControle,
    PointControleAide,
    ValeurCheckpoint,
} from '../../../model/electricite.model';
import { combineLatest } from 'rxjs';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { GuideDeReferenceComponent } from '../../guide-de-reference/guide-de-reference.component';
import { DialogUtils } from '../../../../../../utils/dialog.utils';
import { CheckpointHelpModalComponent } from '../../checkpoint-help-modal/checkpoint-help-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, Routes } from '@angular/router';
import { TypeCommentaire } from '../../../../../../model/type-commentaire.model';

@Component({
    selector: 'app-pt-controles-non-verifiable',
    templateUrl: './pt-controles-non-verifiable.component.html',
    styleUrls: ['./pt-controles-non-verifiable.component.scss'],
})
export class PtControlesNonVerifiableComponent implements OnInit {
    readonly COMMENT_TYPES: TypeCommentaire[] = ['NOTE_PERSONNELLE', 'REMARQUE'];
    readonly NON_VERIFIABLE = nonVerifiabledOption.value;
    pointsControles: PointControle[];
    diagnostic: Diagnostic;
    checkpoints: { path: string; sousChapitre: Chapitre; pointsControles: PointControle[] }[] = [];
    chapitres: ICheckpoints[] = []; // chapitres to display
    private _pointsControlesAides: PointControleAide[];
    private _photos: Photo[];
    private _link: Routes = routesPointsDeControle;
    private _chapitres: Chapitre[]; // chapitres config

    constructor(
        private diagnosticService: DiagnosticService,
        private electriciteService: ElectriciteService,
        private readonly matDialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit(): void {
        combineLatest([this.electriciteService.electriciteConfig$, this.diagnosticService.getCurrentDiagnostic()])
            .pipe(first())
            .subscribe(([config, diagnostic]) => {
                this.diagnostic = diagnostic;
                this._chapitres = config.chapitres;
                this._pointsControlesAides = config.pointsControlesAides;
                this._photos = config.photos;
                const ptcResteAverify = config.pointsControles.filter((ch) => this.isNonVerifiable(ch));
                for (const ptc of ptcResteAverify) {
                    this.addPointControle(ptc);
                }
                // Sort
                this.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
                for (const tr of this.chapitres) {
                    tr.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
                    for (const val of tr.chapitres) {
                        val.pointsControles.sort(comparePointControle);
                    }
                }
            });
    }

    private addPointControle(ckp: PointControle) {
        if (!ckp.idChapitre) return;
        const ssChapitre = this._chapitres.find((chap) => chap.idInterne == ckp.idChapitre);
        const chapitre: Chapitre = this._chapitres.find((chap) => chap.idInterne == ssChapitre.idChapitreParent);
        let path = this.getPath(this._link, chapitre.description);
        path = `${this.router.url.split('electricite')[0]}electricite/config/${path}`;
        const indexChapitre = this.chapitres.findIndex((tr) => tr.chapitre.id == chapitre.id);

        if (indexChapitre < 0) {
            this.chapitres.push({
                path: path,
                chapitre: chapitre,
                chapitres: [{ chapitre: ssChapitre, pointsControles: [ckp] }],
            });
        } else {
            // Le chapitre existe
            const indexSsChapitre = this.chapitres[indexChapitre].chapitres.findIndex(
                (val) => val.chapitre.id == ssChapitre.id
            );
            if (indexSsChapitre < 0) {
                this.chapitres[indexChapitre].chapitres.push({ chapitre: ssChapitre, pointsControles: [ckp] });
            } else {
                this.chapitres[indexChapitre].chapitres[indexSsChapitre].pointsControles.push(ckp);
            }
        }
    }

    /**
     * Retourne uniquement les point de contrôle répondu "Non vérifiable"
     * @param {PointControle} pc
     * @returns {boolean}
     */
    private isNonVerifiable(pc: PointControle): boolean {
        const valeurCheckpoint: ValeurCheckpoint =
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[pc.idInterne];
        if (valeurCheckpoint) {
            return valeurCheckpoint.valeur == this.NON_VERIFIABLE;
        }
        return false;
    }

    openGuideReference($event) {
        $event.stopPropagation();
        this.matDialog.open(GuideDeReferenceComponent, { ...DialogUtils.configFullScreen() });
    }

    openHelp(checkpoint: any) {
        const contents = [];
        this._pointsControlesAides
            .filter((pca) => pca.idPointControle == checkpoint.idInterne)
            .sort((a, b) => a.ordre - b.ordre)
            .forEach((pca) => {
                const photo = this._photos.find((ph) => ph.idInterne == pca.idPhoto);
                contents.push({ innerHTML: pca.description, photo: photo });
            });
        if (contents) {
            const dialogRef = this.matDialog.open(CheckpointHelpModalComponent);
            dialogRef.componentInstance.title = checkpoint.description;
            dialogRef.componentInstance.contents = contents;
        }
    }

    private getPath(routes: Routes, label: string): string {
        let path = '';
        for (const route of routes) {
            path = route.path;
            if (route.children && route.children.length) {
                const tmpPath = this.getPath(route.children, label);
                if (tmpPath) {
                    return (path += `/${tmpPath}`);
                }
            } else {
                if (route.data ? label.startsWith(route.data.label) : false) {
                    return route.path;
                }
            }
        }
        return null;
    }
}

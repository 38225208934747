import {
    CONTAINS,
    DATEAFTER,
    DATEBEFORE,
    DATEEQUAL,
    EQUAL,
    GREATEREQTHAN,
    GREATERTHAN,
    IN,
    LESSEQTHAN,
    LESSTHAN,
    NOTCONTAINS,
    NOTEQUAL,
    NOTIN,
    Operator,
    REQUIRED,
} from './operator';
import { JSONPath } from 'jsonpath-plus';
import {
    ComputeMode,
    ComputeModeEnum,
    Condition,
    Operation,
    ResultCondition,
    Rule,
    RuleOptions,
    RuleResults,
} from '../../model/regle.model';

export class EngineRule {
    operators: Map<string, Operator>;
    controlData: any = null;
    referenceData: any = null;
    /**
     * Mode de parcourt des conditions de la règle (conditions de de plus haut niveau cf Rule.conditions).<br/>
     * Si <b>ALL</b> toutes les conditions sont parcourues<br/>
     * Si <b>FIRST_VALID</b> parcour jusqu'à la première condition valid hors prerequis<br/>
     * Si <b>FIRST_INVALID</b> parcour jusqu'à la première condition invalid hors prerequis
     */
    computeMode: ComputeMode = ComputeModeEnum.ALL;
    /**
     * Si true évalue toutes les opérations sans s'interrompre au premier résultat du "and" ou du "or".
     * Celà n'a pas d'imparct sur le résultat de l'opération.
     */
    processAll = false;
    /**
     * Si true la liste des opérations valide et des opérations invalide sont ajoutées dans les
     * paramètres du résultat.
     */
    verbose = false;

    /**
     * Valeurs mises en cache pour éviter les multiples appels à JSONPath
     */
    cacheValues: Map<string, any>;

    constructor(computeMode?: ComputeMode) {
        if (computeMode) {
            this.computeMode = computeMode;
        }
        this.initOperator();
    }

    addOperator(operator: Operator) {
        this.operators.set(operator.name, operator);
    }

    addCallbackOperator(name: string, callback: (refValue: any, value: any) => boolean) {
        this.operators.set(name, new Operator(name, callback));
    }

    /**
     * Evaluation d'une liste de règles filtrée par un code pour un ensemble de données à contrôler
     * pouvant être enrichie par des données de références.
     * Retourne une liste de résultats de règles contenant une validité global et des sous ensembles
     * de résultats de conditions avec une validité, une type et des paramètres optioinels.
     * @param regles ensemble de règle à évaluer
     * @param ruleCode code permettant de filtre l'ensemble de règles par leurs références
     * @param controlData ensemble de données à contrôler
     * @param referenceData ensemble de données complémentaire pour l'évaluation des conditions de la règle
     * @param options
     */
    computeRules(
        regles: Rule[],
        ruleCode: string,
        controlData: any,
        referenceData?: any,
        options?: RuleOptions
    ): RuleResults[] {
        this.controlData = controlData;
        this.referenceData = referenceData;
        const ruleResults: RuleResults[] = [];
        this.cacheValues = new Map();
        if (options) {
            this.computeMode = options.computeMode;
            this.processAll = options.processAll;
            this.verbose = options.verbose;
        }

        const ruleset = regles.filter((rule) => rule.references.includes(ruleCode));
        ruleset.forEach((rule) => {
            const prerequiredConditions = rule.conditions
                .filter((condition) => condition.prerequired)
                .sort((cond1, cond2) => cond1.priority - cond2.priority);
            const ruleResult = new RuleResults(rule.name);
            if (prerequiredConditions.length > 0) {
                prerequiredConditions.forEach((condition) => {
                    ruleResult.addResult(this.evaluateCondition(condition));
                });
            }
            if (ruleResult.valid) {
                const conditions = rule.conditions
                    .filter((condition) => !condition.prerequired)
                    .sort((cond1, cond2) => cond1.priority - cond2.priority);
                for (const condition of conditions) {
                    const result = this.evaluateCondition(condition);
                    ruleResult.addResult(result);
                    if (
                        (result.valid && this.computeMode === ComputeModeEnum.FIRST_VALID) ||
                        (!result.valid && this.computeMode === ComputeModeEnum.FIRST_INVALID)
                    ) {
                        break;
                    }
                }
            }
            ruleResults.push(ruleResult);
        });

        return ruleResults;
    }

    /**
     * Evalue le résultat des opérations de la condition et les résultats des sous conditions potentiellement
     * présentes dans les opérations de manière récurcive.
     * Retourne un objet ResultCondition représentant le résultat de la condition
     * @param condition condition à évaluer
     * @param conditionResults résultats de la règle contenant la liste des résultats des conditions
     */
    private evaluateCondition(condition: Condition, conditionResults?: ResultCondition): ResultCondition {
        const operator = condition.hasOwnProperty('or') ? 'or' : condition.hasOwnProperty('and') ? 'and' : null;
        let result;
        if (condition.result) {
            result = new ResultCondition(
                false,
                condition.result.type,
                condition.name,
                operator,
                condition.result.params
            );
        } else {
            result = new ResultCondition(false, condition.name, condition.name, operator);
        }

        let valid;
        const verifiedOperations = [];
        const unverifiedOperations = [];
        for (const operation of condition[operator]) {
            let value;
            if (operation.hasOwnProperty('condition')) {
                value = this.evaluateCondition(operation.condition, result).valid;
            } else {
                value = this.evaluateOperation(operation);
                this.addOperationToResult(condition, operation, result, value);
            }
            if (value) {
                verifiedOperations.push(operation);
            } else {
                unverifiedOperations.push(operation);
            }
            if (operator === 'and') {
                valid = valid === undefined ? value : valid && value;
                if (!condition.processAll && !this.processAll && !value) {
                    break;
                }
            } else if (operator === 'or') {
                valid = valid === undefined ? value : valid || value;
                if (!condition.processAll && !this.processAll && value) {
                    break;
                }
            }
        }
        result.valid = valid;
        if (condition.prerequired) {
            result.prerequired = condition.prerequired;
        }
        if (conditionResults) {
            conditionResults.subResult.push(result);
            conditionResults.valid = conditionResults.valid && result.valid;
        } else {
            if (valid) {
                // Liste des opérations de plus haut niveau qui ont validé la condition de plus haut niveau
                result.params.set('verifiedBy', verifiedOperations);
            } else {
                // Liste des opérations de plus haut niveau qui ont invalidé la condition de plus haut niveau
                result.params.set('unverifiedBy', unverifiedOperations);
            }
        }
        return result;
    }

    /**
     * Evalue le résultat de l'opération en récupérant la valeur à tester selon le JSON path et en
     * évaluant le résultat de l'opérateur avec la valeur de référence et la valeur à tester.
     * @param operation opération à évaluter
     */
    private evaluateOperation(operation: Operation): boolean {
        const property = operation.hasOwnProperty('pathControl')
            ? 'pathControl'
            : operation.hasOwnProperty('pathReference')
            ? 'pathReference'
            : null;
        let value = this.cacheValues.get(operation[property]);
        if (!value && property) {
            value = JSONPath({
                path: operation[property],
                json: property === 'pathControl' ? this.controlData : this.referenceData,
                wrap: false,
            });
            this.cacheValues.set(operation[property], value);
        }
        return this.evaluateOperator(operation.operator, operation.values, value);
    }

    /**
     * Recherche l'opérateur dans la liste des opérateurs de l'instance du moteur de règles,
     * et exécute l'opération avec la valeur de référence et la valeur à tester fournies
     *
     * @param operatorName nom de l'opérateur, servant de clef dans la map du moteur
     * @param refValue valeur de référence pour l'opération
     * @param value valeur à tester par rapport à la valeur de référence si nécessaire
     * @exception erreur renvoyé si l'opérateur ne peut pas exécuter son callback
     */
    private evaluateOperator(operatorName: string, refValue: any, value: any): boolean {
        const operator = this.operators.get(operatorName);
        if (operator) {
            try {
                return operator.evaluate(refValue, value);
            } catch (e) {
                console.error(e);
                throw e;
            }
        }
        throw new Error("L'opérateur n'existe pas");
    }

    /**
     * Ajout de l'opération dans la collection valide ou invalide des paramètres du résultat de la condition
     */
    private addOperationToResult(condition: Condition, operation: Operation, result: ResultCondition, valide: boolean) {
        if (condition.verbose || this.verbose) {
            if (valide) {
                let paramValid = result.params.get('valids');
                if (!paramValid) {
                    paramValid = [];
                    result.params.set('valids', paramValid);
                }
                paramValid.push(operation);
            } else {
                let paramInvalid = result.params.get('invalids');
                if (!paramInvalid) {
                    paramInvalid = [];
                    result.params.set('invalids', paramInvalid);
                }
                paramInvalid.push(operation);
            }
        }
    }

    private initOperator() {
        this.operators = new Map();
        this.operators.set('dateBefore', new Operator('dateBefore', DATEBEFORE));
        this.operators.set('dateAfter', new Operator('dateAfter', DATEAFTER));
        this.operators.set('dateEqual', new Operator('dateEqual', DATEEQUAL));
        this.operators.set('greaterEqThan', new Operator('greaterEqThan', GREATEREQTHAN));
        this.operators.set('greaterThan', new Operator('greaterThan', GREATERTHAN));
        this.operators.set('lessEqThan', new Operator('lessEqThan', LESSEQTHAN));
        this.operators.set('lessThan', new Operator('lessThan', LESSTHAN));
        this.operators.set('notContains', new Operator('notContains', NOTCONTAINS));
        this.operators.set('contains', new Operator('contains', CONTAINS));
        this.operators.set('required', new Operator('required', REQUIRED));
        this.operators.set('notEqual', new Operator('notEqual', NOTEQUAL));
        this.operators.set('equal', new Operator('equal', EQUAL));
        this.operators.set('notIn', new Operator('notIn', NOTIN));
        this.operators.set('in', new Operator('in', IN));
    }
}

<mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header
        [collapsedHeight]="customCollapsedHeight"
        [expandedHeight]="customExpandedHeight"
        class="pt-2"
    >
        <mat-panel-description
            (click)="$event.stopPropagation()"
            (keydown.Space)="$event.stopPropagation()"
            (keydown.enter)="$event.stopPropagation()"
        >
            <div class="d-flex align-items-baseline flex-wrap w-100 room-buttons-placeholder">
                <div class="d-flex flex-grow-1 mat-subheading-2 primary m-0">
                    {{ room?.nom }}
                </div>
                <ng-container *ngIf="!room?.volumeCache">
                    <b>{{ 'Visitée' | uppercase }}</b>
                    <app-state-input
                        class="right-separation"
                        [choices]="[validatedChoiceOption, warningChoiceOption, notValidatedChoiceOption]"
                        [tooltips]="tooltipsVisite"
                        [disabled]="readonlyMode"
                        [(choiceValue)]="room?.valeursParametres[paramVolumeVisite]"
                        (valueChangeFromUser)="currentVisiteChanged($event)"
                    >
                    </app-state-input>
                    <app-notes-buttons [from]="room" [isDescription]="true"></app-notes-buttons>
                    <div class="position-absolute button-group room-buttons">
                        <button
                            *ngIf="!room?.spaceId && bien.jsonPlan"
                            mat-icon-button
                            color="primary"
                            matTooltip="Associer la pièce"
                            (click)="mergeVolume(room)"
                            [disabled]="readonlyMode"
                        >
                            <mat-icon>swap_horiz</mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            color="primary"
                            matTooltip="Éditer le volume"
                            [disabled]="readonlyMode && !readonlyName"
                            (click)="editRoom()"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button
                            *ngIf="!room?.spaceId"
                            mat-icon-button
                            color="primary"
                            matTooltip="Copier le volume"
                            [disabled]="readonlyMode"
                            (click)="copyRoom()"
                        >
                            <mat-icon>content_copy</mat-icon>
                        </button>
                        <button
                            *ngIf="!room?.spaceId"
                            mat-icon-button
                            color="warn"
                            matTooltip="Supprimer le volume"
                            [disabled]="readonlyMode"
                            (click)="deleteRoom()"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </div>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <div
        class="pb-2"
        cdkDropList
        [cdkDropListData]="room?.elementsAControler"
        [id]="roomIndex"
        cdkDropListConnectedTo="equipmentList"
        [cdkDropListEnterPredicate]="onlyElementPredicate"
        [cdkDropListDisabled]="readonlyMode"
        (cdkDropListDropped)="onDrop($event)"
    >
        <div *ngIf="room?.elementsAControler?.length > 0">
            <div *ngFor="let equipment of room?.elementsAControler" cdkDrag cdkDragDisabled="true" class="mx-2">
                <div class="d-flex equipment-card p-1 m-1 align-items-center">
                    <div class="flex-grow-1">
                        <lib-icon *ngIf="equipment?.objectId" [class]="'icon-before-text'">my_location</lib-icon>
                        {{ equipment?.nom }}
                    </div>
                    <button
                        *ngIf="!equipment?.objectId"
                        mat-icon-button
                        color="warn"
                        matTooltip="Supprimer l'équipement"
                        [disabled]="readonlyMode"
                        (click)="deleteEquipment(equipment)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div
            *ngIf="room?.elementsAControler?.length === 0 && !readonlyMode"
            class="drop-card-placeholder mt-1 mx-3 d-flex justify-content-center align-items-center"
        >
            Déposez un équipement
        </div>
    </div>
</mat-expansion-panel>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/commons-lib';
import { Contact } from 'src/app/model/contact.model';
import { ContactService } from 'src/app/services/contact.service';
import { DISPLAY_FN_NAME } from '../../../utils/display-function.utils';
import { FormService } from '../../../services/form.service';
import { takeUntil, tap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { updateContactFormValidators } from './edit-contact-form.service';
import { MatOptionSelectionChange } from '@angular/material/core';

export function filterByPersonneMoralAndNotId(value: { id: string; estPersonneMorale: boolean }) {
    const isSamePersonneMoraleAndIdDifferent = (contact) =>
        contact.estPersonneMorale === value.estPersonneMorale && contact.id !== value.id;
    return isSamePersonneMoraleAndIdDifferent;
}

@Component({
    selector: 'app-edit-contact',
    templateUrl: './edit-contact.component.html',
    styleUrls: ['./edit-contact.component.scss'],
})
export class EditContactComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input()
    formContact: FormGroup;

    @Input()
    isReadOnly: boolean;

    /** Si on veut créer un propriétaire, le type sera à propriétaire et non modifiable */
    @Input()
    isProprietaire = false;

    /** Si on veut créer un donneur d'ordre, le type sera à Donneur d'ordres et non modifiable */
    @Input()
    isDonneurOrdre = false;

    /** Si on veut créer une référence de contact, cad depuis la partie admin des contacts, on ne saisi ni un typeRole ni un type */
    @Input()
    isContactRef = false;

    @Output()
    onSelectExistingContact?: EventEmitter<Contact> = new EventEmitter<Contact>();

    filteredSearchEntreprises: Contact[];
    filteredSearchName: Contact[];
    filteredSearchTelephones: Contact[];
    filteredSearchEmails: Contact[];
    filteredSearchSirets: Contact[];
    isSearchingEntreprises = this.formService.isSearchingEntreprises$;
    isSearchingNames = this.formService.isSearchingContacts$;
    isSearchingTelephones = this.formService.isSearchingTelephones$;
    isSearchingEmails = this.formService.isSearchingEmails$;
    isSearchingSirets = this.formService.isSearchingSirets$;

    currentFieldSearch = {};

    readonly displayFnName = DISPLAY_FN_NAME;

    constructor(private readonly contactService: ContactService, private readonly formService: FormService) {
        super();
    }

    ngOnInit(): void {
        this.formService
            .entrepriseSearchValueChange(this.formContact.get('entreprise'), this.contactService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchEntreprises = result.content;
            });
        this.formService
            .contactSearchValueChange(this.formContact.get('nom'), this.contactService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchName = result.content.filter(filterByPersonneMoralAndNotId(this.formContact.value));
            });
        this.formService
            .telephoneSearchValueChange(this.formContact.get('telephone'), this.contactService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchTelephones = result.content.filter(
                    filterByPersonneMoralAndNotId(this.formContact.value)
                );
            });
        this.formService
            .emailSearchValueChange(this.formContact.get('email'), this.contactService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchEmails = result.content.filter(
                    filterByPersonneMoralAndNotId(this.formContact.value)
                );
            });
        this.formService
            .siretSearchValueChange(this.formContact.get('siret'), this.contactService)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                this.filteredSearchSirets = result.content.filter(
                    filterByPersonneMoralAndNotId(this.formContact.value)
                );
            });

        this.formContact
            .get('estPersonneMorale')
            .valueChanges.pipe(
                takeUntil(this.ngUnsubscribe),
                tap((val) => updateContactFormValidators(val, this.formContact))
            )
            .subscribe();
    }

    onClickChangeContactFromSelect($event: MatAutocompleteSelectedEvent, field: string) {
        this.formContact.get(field).patchValue(this.currentFieldSearch[field]);
    }

    onSelectionChanged($event: MatOptionSelectionChange<Contact>, field: string) {
        if ($event.isUserInput) {
            this.currentFieldSearch[field] = this.formContact.get(field).value;
            const contact = $event.source.value;
            this.onSelectExistingContact.emit(contact);
        }
    }
}

'use strict';

import { cn_building, cn_space, cn_storey, cn_wall } from '../../..';
import { cn_element_type_visitor } from '../cn_element_type_visitor';

//***********************************************************************************
//***********************************************************************************
//**** cn_space_of_elements_types_visitor
//***********************************************************************************
//***********************************************************************************

export class cn_space_of_elements_types_visitor extends cn_element_type_visitor {

    /**
     *  Constructor
     *
     * @param {cn_building} building
     */
    constructor(building) {
        super();
        this.building = building;
        this.spaces = [];
        this.spaces_uniq_ref = new Set()
    }

    visit_wall_type(cn_wall_type) {
        if (this.building.storeys && this.building.storeys.length) {
            this.building.storeys.forEach(storey => {
                if (storey.scene.walls && storey.scene.walls.length) {
                    storey.scene.walls.filter(wall => wall.wall_type.ID === cn_wall_type.ID).forEach(wall => {
                        (wall.spaces || []).filter(space => !!space).forEach(space => this._add_space(space, storey));
                    });
                }
            });
        }
    }

    visit_opening_type(cn_opening_type) {
        if (this.building.storeys && this.building.storeys.length) {
            this.building.storeys.forEach(storey => {
                if (storey.scene.walls && storey.scene.walls.length) {
                    storey.scene.walls
                    .filter(wall => wall.openings.find(opening => opening.opening_type.ID === cn_opening_type.ID))
                    .forEach(wall => {
                        (wall.spaces || []).filter(space => !!space).forEach(space => this._add_space(space, storey));
                    });
                }
            });
        }
    }

    visit_slab_type(cn_slab_type) {
        if (this.building.storeys && this.building.storeys.length) {
            this.building.storeys.forEach(storey => {
                if (storey.slabs && storey.slabs.length) {
                    storey.slabs.filter(slab => slab.slab_type.ID === cn_slab_type.ID).forEach(slab => {
                        if (slab.spaces[1]) {
                            this._add_space(slab.spaces[1], storey);
                        }
                    });
                }
            });
        }
    }

    visit_beam_column_type(cn_beam_column_type) {
        if (this.building.storeys && this.building.storeys.length) {
            this.building.storeys.forEach(storey => {
                storey.scene.beams
                    .filter(beam => beam.element_type.ID === cn_beam_column_type.ID)
                    .forEach(beam => {
                        const spaceOne = storey.scene.find_space(beam.vertices[0]);
                        const spaceTwo = storey.scene.find_space(beam.vertices[1]);
                        this._add_space(spaceOne, storey);
                        this._add_space(spaceTwo, storey);

                    });
                storey.scene.columns
                    .filter(column => column.element_type.ID === cn_beam_column_type.ID)
                    .forEach(column => {
                        const space = storey.scene.find_space(column.position);
                        this._add_space(space, storey);
                    });
            });
        }
    }

    visit_balcony_type(cn_balcony_type) {
        if (this.building.storeys && this.building.storeys.length) {
            this.building.storeys.forEach(storey => {
                if (storey.scene.walls && storey.scene.walls.length) {
                    storey.scene.walls.filter(wall => wall.wall_type.ID === cn_balcony_type.ID).forEach(wall => {
                        (wall.spaces || []).filter(space => !!space).forEach(space => this._add_space(space, storey));
                    });
                }
            });
        }
    }

    /**
     *  Add uniq space in list
     *
     * @param {cn_space} space
     * @param {cn_storey} storey
     */
    _add_space(space, storey) {
        if (!space.outside) {
            const newSpace = {
                space_id: space.ID,
                space_name: space.get_name(storey),
                storey_id: storey.ID,
            };
            const key = `space#${space.ID}#storey#${storey.ID}`;
            if (!this.spaces_uniq_ref.has(key)) {
                this.spaces.push(newSpace);
                this.spaces_uniq_ref.add(key);
            }
        }
    }

}

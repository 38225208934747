"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Pastille
//***********************************************************************************
//***********************************************************************************

import {cn_add, cn_dist, cn_sub} from "../utils/cn_utilities";
import {cn_image_dir} from "../utils/image_dir";
import { cn_camera } from "./cn_camera";
import { cn_event_manager } from "./cn_event_manager";

export class cn_pastille extends cn_event_manager {
	constructor(position = [0,0], label = "", label_class = "") {
		super();
		this.position = position;
		this.offset = [0,0];
		this.label = label;
		this.small = false;

		this.line_to_offset = false;

		this.mouseover = false;
		this.selected = false;
		this.svg_class = 'pastille_background';
		this.text_class = "pastille_text";
		this.label_class = label_class;

		this.ghost = null;

		this.rectangle = null;

		this.visible = true;

		this.title = "";
        this.add_attr = '';

		/** If true, will add a widget to show that data is not coherent */
		this.incoherent = false;
	}

	//*****************************************************************
	//*** does the shape contains the point ?
	//*****************************************************************
	contains(pos,camera) {
		if (!this.visible) return false;
		var p = cn_add(camera.world_to_screen(this.position),this.offset);
		var p1 = camera.world_to_screen(pos);
		if (this.rectangle)
		{
			if (Math.abs(p[0] - p1[0]) > this.rectangle[0]/2) return false;
			if (Math.abs(p[1] - p1[1]) > this.rectangle[1]/2) return false;
			return true;
		}
		var radius = (this.small)?10:20;
		return cn_dist(p,p1) <= radius;
	}
	contains_screen(pos,camera) {
		if (!this.visible) return false;
		var p = cn_add(camera.world_to_screen(this.position),this.offset);

		if (this.rectangle)
		{
			if (Math.abs(p[0] - pos[0]) > this.rectangle[0]/2) return false;
			if (Math.abs(p[1] - pos[1]) > this.rectangle[1]/2) return false;
			return true;
		}
		var radius = (this.small)?10:20;
		return cn_dist(p,pos) <= radius;
	}

	/**
	 * Rendering
	 * @param {cn_camera} camera
	 * @returns {string}
	 */
	draw(camera)
	{
		if (!this.visible) return "";
		var html = "";
		var add_classes = "";

		var p = cn_add(camera.world_to_screen(this.position),this.offset);

		var radius = (this.small)?10:20;

		if (this.line_to_offset)
		{
			const tp = cn_sub(p,this.offset);
			html += "<line class='roof_height_line' x1='" + p[0] + "' y1='" + p[1] + "' x2='" + tp[0] + "' y2='" + tp[1] + "' />";
		}

		//*** Draw center
		var classes = this.svg_class;
		if (this.small) classes += " small";
		if (this.mouseover)
			classes += " mouseover";
		else if (this.selected)
			add_classes += " selected";

		if (this.rectangle)
			html += `<rect ${this.add_attr} class="${classes}" x="${p[0] - this.rectangle[0]/2}" y="${p[1] - this.rectangle[1]/2}" width="${this.rectangle[0]}" height="${this.rectangle[1]}"/>`;
		else
			html += "<circle class='" + classes + "' cx='" + p[0] + "' cy='" + p[1] + "' r='" + radius + "'/>";

		var r0 = radius*2;
		var r1 = radius*2;
		if (this.rectangle)
		{
			r0 =  this.rectangle[0];
			r1 =  this.rectangle[1];
		}

		//*** draw arrows
		if (this.label[0] == '<')
		{
			html += "<rect fill='white'  x='" + (p[0] - r0/2) + "' y='" +  (p[1] - r1/2)+ "' width='" + r0 + "' height='" + r1 + "'/>";
			html += `<g transform="translate(${p[0] - r0/2},${p[1] - r1/2})">`;
			html += this.label;
			html += `</g>`;
		}
		else if (this.label.indexOf(".png") >= 0 || this.label.indexOf(".jpg") >= 0 || this.label.indexOf(".svg") >= 0)
			html += `<image ${this.add_attr} class="${this.label_class}" xlink:href="${cn_image_dir() + this.label}"
             x="${(p[0] - r0/2)}" y="${(p[1] - r1/2)}" width="${r0}" height="${r1}" preserveAspectRatio="xMidYMid meet"/>`;
		else
			html += "<text class='" + this.text_class + "' x='" + p[0] + "' y='" + p[1] + "'>" + this.label + "</text>";

		if (this.ghost)
		{
			p = camera.world_to_screen(this.ghost);
			if (this.rectangle)
				html += "<rect class='" + classes + "' x='" + (p[0] - this.rectangle[0]/2) + "' y='" +  (p[1] - this.rectangle[1]/2)+ "' width='" + this.rectangle[0] + "' height='" + this.rectangle[1] + "'  opacity='0.5' />";
			else
				html += "<circle class='" + classes + "' cx='" + p[0] + "' cy='" + p[1] + "' r='" + radius + "'  opacity='0.5' />";
			if (this.label.indexOf(".png") >= 0 || this.label.indexOf(".svg") >= 0)
				html += "<image class='" + this.label_class + "' xlink:href='" + cn_image_dir()  + this.label + "' x='" + (p[0] - r0/2) + "' y='" + (p[1] - r1/2) + "' width='" + r0 + "' height='" + r1 + "'  opacity='0.5' />";
			else
				html += "<text class='" + this.text_class + "' x='" + p[0] + "' y='" + p[1] + "' opacity='0.5'>" + this.label + "</text>";
		}

		if (this.incoherent)
		{
			if (this.rectangle)
			{
				html += `<path fill="grey" stroke="none" d="M ${p[0] - this.rectangle[0]/2} ${p[1] + this.rectangle[1]/2} L ${p[0] + this.rectangle[0]/2} ${p[1] + this.rectangle[1]/2} ${p[0] + this.rectangle[0]/2} ${p[1] - this.rectangle[1]/2} Z" />`;
			}
		}

		return html;
	}

	clicked(){

	}
}


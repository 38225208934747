<h1 mat-dialog-title class="epi-title">{{ title }}</h1>

<div mat-dialog-content>
    <div *ngIf="isEmpty">Aucune aide disponible</div>
    <ng-template ngFor let-content [ngForOf]="contents">
        <div [innerHTML]="content.innerHTML"></div>
        <div class="d-flex flex-column-reverse">
            <div *ngIf="content.photo && (content.photo.idPhoto | fileDownload | async)" class="p-2">
                <img
                    (click)="fullScreen($event)"
                    [src]="content.photo.idPhoto | fileDownload | async"
                    class="img-fluid"
                    style="cursor: zoom-in; max-width: 100%; max-height: 300px"
                />
            </div>
        </div>
    </ng-template>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-raised-button color="accent" (click)="confirm()">
        <span>{{ 'Ok' | uppercase }}</span>
    </button>
</div>

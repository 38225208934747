import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import {
    Chapitre,
    Electricite,
    ICheckpoints,
    Photo,
    PointControle,
    PointControleAide,
    ValeurCheckpoint,
} from '../../../model/electricite.model';
import { ElectriciteService } from '../../../services/electricite.service';
import { MatDialog } from '@angular/material/dialog';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { GuideDeReferenceComponent } from '../../guide-de-reference/guide-de-reference.component';
import { DialogUtils } from '../../../../../../utils/dialog.utils';
import { CheckpointHelpModalComponent } from '../../checkpoint-help-modal/checkpoint-help-modal.component';
import {
    compareChapitre,
    comparePointControle,
    nonOption,
    nonVerifiabledOption,
} from '../../../shared/electricite.constants';
import { TypeCommentaire } from '../../../../../../model/type-commentaire.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { apartmentState } from '../../../../../../shared/constants/states.constants';
import { CommentModalComponent } from '../../../../../shared/comment-modal/comment-modal.component';

@Component({
    selector: 'app-liste-anomalies',
    templateUrl: './liste-anomalies.component.html',
    styleUrls: ['./liste-anomalies.component.scss'],
})
export class ListeAnomaliesComponent implements OnInit {
    // Liste des points de contrôle qui sont répondues à NON
    readonly COMMENT_TYPES: TypeCommentaire[] = ['NOTE_PERSONNELLE', 'REMARQUE'];
    readonly NON_VERIFIABLE = nonVerifiabledOption.value;
    readonly NON = nonOption.value;
    //pointsControles: PointControle[];
    diagnostic: Diagnostic;
    chapitres: ICheckpoints[] = []; // chapitres to display
    private _pointsControlesAides: PointControleAide[];
    private _photos: Photo[];
    private _chapitres: Chapitre[]; // chapitres config

    constructor(
        private diagnosticService: DiagnosticService,
        private electriciteService: ElectriciteService,
        private readonly matDialog: MatDialog,
        private _snackBar?: MatSnackBar
    ) {}

    ngOnInit(): void {
        combineLatest([this.electriciteService.electriciteConfig$, this.diagnosticService.getCurrentDiagnostic()])
            .pipe(first())
            .subscribe(([config, diagnostic]) => {
                this.diagnostic = diagnostic;
                this._chapitres = config.chapitres;
                this._pointsControlesAides = config.pointsControlesAides;
                this._photos = config.photos;
                const ontenuDiagnostic = diagnostic.contenuDiagnostic as Electricite;
                const isAppartement = ontenuDiagnostic.typeBien.valeur == apartmentState.value;
                let ptcs = config.pointsControles.filter((ch) => this.filtreCheckpoint(ch));
                // Ne pas afficher les ptc des "autres-contatations"
                ptcs = ptcs.filter(
                    (chp) =>
                        chp.idChapitre != 'CH32' &&
                        chp.idChapitre != 'CH44' &&
                        chp.idChapitre != 'CH11' &&
                        chp.idChapitre != 'CH4' &&
                        chp.idChapitre != 'CH18' &&
                        chp.idChapitre != 'CH1'
                );
                if (isAppartement) {
                    ptcs = ptcs.filter((chp) => chp.idChapitre != 'CH3' && chp.idChapitre != 'CH46');
                }

                for (const ptc of ptcs) {
                    this.addPointControle(ptc);
                }
                // Sort
                this.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
                for (const tr of this.chapitres) {
                    tr.chapitres.sort((a, b) => compareChapitre(a.chapitre, b.chapitre));
                    for (const val of tr.chapitres) {
                        val.pointsControles.sort(comparePointControle);
                    }
                }
            });
    }

    private addPointControle(ckp: PointControle) {
        if (!ckp.idChapitre) return;
        const ssChapitre = this._chapitres.find((chap) => chap.idInterne == ckp.idChapitre);
        const chapitre: Chapitre = this._chapitres.find((chap) => chap.idInterne == ssChapitre.idChapitreParent);
        const indexChapitre = this.chapitres.findIndex((tr) => tr.chapitre.id == chapitre.id);

        if (indexChapitre < 0) {
            this.chapitres.push({
                chapitre: chapitre,
                chapitres: [{ chapitre: ssChapitre, pointsControles: [ckp] }],
            });
        } else {
            // Le chapitre existe
            const indexSsChapitre = this.chapitres[indexChapitre].chapitres.findIndex(
                (val) => val.chapitre.id == ssChapitre.id
            );
            if (indexSsChapitre < 0) {
                this.chapitres[indexChapitre].chapitres.push({ chapitre: ssChapitre, pointsControles: [ckp] });
            } else {
                this.chapitres[indexChapitre].chapitres[indexSsChapitre].pointsControles.push(ckp);
            }
        }
    }
    /**
     * Return true si le point de contrôle
     * *** est à NON
     * @param {PointControle} checkpoint
     * @returns {boolean}
     */
    private filtreCheckpoint(checkpoint: PointControle): boolean {
        const valeur: ValeurCheckpoint =
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[checkpoint.idInterne];
        return valeur && valeur.valeur == nonOption.value;
    }

    openGuideReference($event) {
        $event.stopPropagation();
        this.matDialog.open(GuideDeReferenceComponent, { ...DialogUtils.configFullScreen() });
    }

    openHelp(checkpoint: any) {
        const contents = [];
        this._pointsControlesAides
            .filter((pca) => pca.idPointControle == checkpoint.idInterne)
            .sort((a, b) => a.ordre - b.ordre)
            .forEach((pca) => {
                const photo = this._photos.find((ph) => ph.idInterne == pca.idPhoto);
                contents.push({ innerHTML: pca.description, photo: photo });
            });
        if (contents) {
            const dialogRef = this.matDialog.open(CheckpointHelpModalComponent);
            dialogRef.componentInstance.title = checkpoint.description;
            dialogRef.componentInstance.contents = contents;
        } else {
            this._snackBar.open("Aucune aide n'est disponible pour ce point de contrôle", '', { duration: 5000 });
        }
    }
    addJustificationForAll(pointsControles: PointControle[]) {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    replaceTypesCommentaires: true,
                    typesCommentaires: this.COMMENT_TYPES,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    const aJustifier: PointControle[] = pointsControles.filter(
                        (pc) =>
                            this.diagnostic.pointsDeControleBiens[0].valeursParametres[pc.idInterne].valeur ===
                                this.NON &&
                            !this.diagnostic.pointsDeControleBiens[0].valeursParametres[pc.idInterne].commentairesId
                                .length
                    );
                    aJustifier.forEach((pc) => {
                        this.diagnostic.pointsDeControleBiens[0].valeursParametres[pc.idInterne].commentairesId =
                            result.commentairesId;
                    });
                }
            });
    }

    aJustifier(pointsControles: PointControle[]) {
        if (pointsControles && pointsControles.length) {
            return pointsControles.filter(
                (pc) =>
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[pc.idInterne].valeur === this.NON &&
                    !this.diagnostic.pointsDeControleBiens[0].valeursParametres[pc.idInterne].commentairesId.length
            );
        }
        return [];
    }
    addJustification(idInternePC: string) {
        this.matDialog
            .open(CommentModalComponent, {
                ...DialogUtils.configFullScreen(),
                data: {
                    replaceTypesCommentaires: true,
                    commentairesId:
                        this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInternePC].commentairesId,
                    typesCommentaires: this.COMMENT_TYPES,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result && result !== false) {
                    this.diagnostic.pointsDeControleBiens[0].valeursParametres[idInternePC].commentairesId =
                        result.commentairesId;
                }
            });
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Slab type
//***********************************************************************************
//***********************************************************************************

import { cn_element_type_visitor } from '..';
import {cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_param_group, cn_configuration_tab
} from "./cn_configuration";
import { cn_element_type_layer_material} from './cn_element_type_layer_material';
import {cn_material, CN_MATERIAL_TYPE_FACING, CN_MATERIAL_TYPE_HEAVY_STRUCTURE, CN_MATERIAL_TYPE_INSULATED_STRUCTURE, CN_MATERIAL_TYPE_INSULATOR,
    CN_MATERIAL_TYPE_LIGHT_STRUCTURE
} from "./cn_material";

var CN_SLAB_TYPE_CATEGORY_LIST = ["floor", "roof"];

export class cn_slab_type extends cn_element_type_layer_material  {
    //***********************************************************************************
    /**
     * Constructor
     */
    constructor(category = "floor") {
        super();

        //*** Serialized data */
        this.name = "";
        this.thickness = 0.3;

        //*** volatile data */
        this.category = category;

		//*** Physics: */
		this.user_physics = false;
		this.U = 0;
    }

	//***********************************************************************************
	/**
	 * returns default slab types
	 * @returns {cn_slab_type[]}
	 */
	static default_types()
	{
		var slab_types = [];

		var st = new cn_slab_type();
		st.category = "floor";
		slab_types.push(st);

		var st = new cn_slab_type();
		st.category = "roof";
		slab_types.push(st);

		return slab_types;
	}

	//***********************************************************************************
	/**
	 * Resets default values in case of generic switch. (Nothing done if current generic state is similar)
	 * @param {boolean} generic
	 */
	set_generic(generic)
	{
		if (generic == this.is_generic()) return;

		if (generic)
		{
			this.thickness = 0.3;
			this.layers = [new cn_material("Inconnu","unknown",0.3)];
			return;
		}

		if (this.category == "floor")
		{
			this.thickness = 0.35;
			this.layers = [];
			this.layers.push(cn_material.material_by_code("insulator",0.10));
			this.layers.push(cn_material.material_by_code("concrete",0.20));
			return;
		}
		if (this.category == "roof")
		{
			this.thickness = 0.30;
			this.layers = [];
			this.layers.push(cn_material.material_by_code("concrete",0.20));
			this.layers.push(cn_material.material_by_code("gypsum",0.01));
			return;
		}
	}

	//***********************************************************************************
	/**
	 * Returns true if slab type is generic
	 * @returns {boolean}
	 */
	is_generic() {
		if (this.layers.length != 1) return false;
		return this.layers[0].code == "unknown";
	}

	//***********************************************************************************
    /**
     * category label
     * @returns {string} displayable category
     */
    get_category_label() {
        if (this.category == "floor")
            return "Plancher";
        if (this.category == "roof")
            return "Toiture";
        return "";
    }

    //***********************************************************************************
    get_generic_label() {
        return "Type de " + this.get_category_label().toLowerCase();
    }

    //***********************************************************************************
    /**
     * Structure label
     * @returns {string} displayable structure
     */
    get_structure_label() {
        for (var i in this.layers) {
            if (this.layers[i].type == CN_MATERIAL_TYPE_HEAVY_STRUCTURE || this.layers[i].type == CN_MATERIAL_TYPE_INSULATED_STRUCTURE)
                return this.layers[i].name;
        }
        for (var i in this.layers) {
            if (this.layers[i].type == CN_MATERIAL_TYPE_LIGHT_STRUCTURE)
                return this.layers[i].name;
        }
        return "";
    }

	//***********************************************************************************
	/**
	 * Structure label
	 * @returns {string} displayable structure
	*/
	get_insulation_label() {
		var structure = false;
		for (var i in this.layers)
		{
			if (this.layers[i].type == CN_MATERIAL_TYPE_INSULATED_STRUCTURE)
				return "Intégrée";
			if (this.layers[i].type == CN_MATERIAL_TYPE_HEAVY_STRUCTURE)
				structure = true;
			if (this.layers[i].type == CN_MATERIAL_TYPE_INSULATOR)
			{
				if (structure) return "Inférieure";
				return "Supérieure";
			}
		}
		return "";
	}

	//***********************************************************************************
	/**
	 * Upper facing label label
	 * @returns {string} displayable facing label
	*/
	get_upper_facing_label() {
		if (this.layers.length > 0 && this.layers[0].type == CN_MATERIAL_TYPE_FACING)
			return this.layers[0].name;
		return "";
	}

	//***********************************************************************************
	/**
	 * Returns a clone of this
	 * @returns {cn_slab_type}
	 */
	clone() {
		var c = new cn_slab_type();
		c.name = this.name;
		c.thickness = this.thickness;
		c.category = this.category;
		c.layers = [];
		for (var i in this.layers)
			c.layers.push(this.layers[i].clone());

		c.user_physics = this.user_physics;
		c.U = this.U;
		return c;
	}

	/**
	 * Computes and returns U value
	 * @returns {number}
	 */
	 get_U() {
		if (this.user_physics) return this.U;
		if (this.is_generic()) return 0;
		var R = 0;
		this.layers.forEach(l => R += l.thickness / l.conductivity);
		return 1/R;
	}

	//***********************************************************************************
	//**** keys
	//***********************************************************************************
	model_keys() {
		return ["name","thickness","category","layers","user_physics","U"];
	}

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize() {
		var json = {};
		json.class_name = 'cn_slab_type';
		json.name = this.name;
		json.ID = this.ID;
		json.thickness = this.thickness;
		json.category = this.category;
		json.layers = [];
		for (var i in this.layers)
			json.layers.push(this.layers[i].serialize());
		json.user_physics = this.user_physics;
		json.U = this.U;
		return json;
	}

	static unserialize(json) {
		if (typeof(json.ID) != 'string') return false;
		if (json.class_name != 'cn_slab_type') return false;
		if (typeof(json.thickness) != 'number') return false;
		var wt = new cn_slab_type();
		wt.ID = json.ID;
		wt.thickness = json.thickness;
		if (typeof(json.name) == 'string') wt.name = json.name;

		if (typeof(json.category) == 'string' && CN_SLAB_TYPE_CATEGORY_LIST.indexOf(json.category) >= 0)
			wt.category = json.category;

		wt.layers = [];
		if (typeof(json.layers) == 'object' && json.layers.length > 0)
		{
			var thickness = 0;
			for (var i in json.layers)
			{
				var layer = cn_material.unserialize(json.layers[i]);
				if (layer == null) continue;
				wt.layers.push(layer);
				thickness += layer.thickness;
			}
			if (wt.layers.length > 0)
				wt.thickness = thickness;
		}
		if (wt.layers.length == 0)
			wt.layers = [new cn_material("Inconnu","unknown",wt.thickness)];

		if (typeof(json.user_physics) == 'boolean')
			wt.user_physics = json.user_physics;

		if (typeof(json.U) == 'number')
			wt.U = json.U;

		return wt;
	}

	//***********************************************************************************
	//**** return label
	//***********************************************************************************
	get_label() {
		if (this.name != "") return this.name;
		if (this.is_generic())
			return "Générique " + (this.thickness*100).toFixed(0) + "cm";
		return this.get_structure_label() + " " + (this.thickness*100).toFixed(0) + "cm";
	}

	//***********************************************************************************
	//**** Returns description
	//***********************************************************************************

    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
	get_description() {
		var description = [];

		description.push({label:"Catégorie",value:this.get_category_label()});

		var th = {label:"Epaisseur"};
		th.value = this.thickness * 100;
		th.decimals = 1;
		th.unit = "cm";
		description.push(th);

		if (this.is_generic())
		{
			if (this.user_physics)
				description.push({label:"U",value:this.get_U(),decimals:3,unit:"W/m²/K"});
			return description;
		}

		description.push({label:"U",value:this.get_U(),decimals:3,unit:"W/m²/K"});

		var layers = {label:"Matériaux"};
		description.push(layers);
		layers.value = [];

		for (var i in this.layers)
		{
			layers.value.push(this.layers[i].get_label());
		}

		return description;
	}

	//***********************************************************************************
	//**** draw icon
	//***********************************************************************************
	draw_svg_icon(widtho, heighto)
	{
		var html = "";

		html += "<g transform='translate(" + (widtho/2) + "," + (heighto/2) + ") rotate(90) translate(" + (-0.5*widtho) + "," + (-0.5*heighto) + ") '>";

		var width = heighto;
		var height = widtho;

		var sz = [width,height];
		var p0=[0,0];
		var p1=[width,height];
		var scale = width;
		p0[0] = sz[0] * (0.5 - this.thickness*0.5);
		for (var i=0; i < this.layers.length;i++)
		{
			p1[0] = p0[0];
			var vertical_line = "<line x1='" + p0[0] +"' y1='" + p0[1] +"' x2='" + p1[0] +"' y2='" + p1[1] +"' style='stroke:black' />";
			p1[0] += sz[0] * this.layers[i].thickness;
			html += this.layers[i].draw(p0,p1[0]-p0[0],p1[1]-p0[1],scale);
			html += vertical_line;
			p0[0] = p1[0];
			if (i == this.layers.length-1)
				html += "<line x1='" + p0[0] +"' y1='" + p0[1] +"' x2='" + p1[0] +"' y2='" + p1[1] +"' style='stroke:black' />";
		}

		html += "</g>";
		return html;
	}

	//***********************************************************************************
	//**** Comparison
	//***********************************************************************************
	is_equal_to(other)
	{
		if (this.name != other.name) return false;
		if (this.category != other.category) return false;
		if (this.layers.length != other.layers.length) return false;
		if (this.user_physics != other.user_physics) return false;
		if (this.user_physics && this.U != other.U) return false;

		for (var i=0;i<this.layers.length;i++)
		{
			if (!this.layers[i].is_equal_to(other.layers[i])) return false;
		}
		return true;
	}

	//***********************************************************************************
	//**** Get configuration
	//***********************************************************************************
	static configuration() {
		var configuration = new cn_configuration();

		var param_group = new cn_configuration_param_group("Thermique","thermics",true);
		configuration.add_param_group(param_group);
		param_group.add_param(new cn_configuration_param("U","U",0,10,"W/m²/K",3,0.1));

		//***********************************************
		//*** Generic configuration
		var generic = new cn_configuration_tab("Générique","generic");
		configuration.add_tab(generic);

		var generic_list = new cn_configuration_line("Générique");
		generic.add_line(generic_list);

		generic_list.add_param(new cn_configuration_param("Epaisseur","thickness",1,100,"cm",1,0.5));

		generic_list.add_choice(new cn_configuration_choice());

		//***********************************************
		//*** lower configuration
		var slab = new cn_configuration_tab("Dalle","slab");
		configuration.add_tab(slab);

		var slab_structure = new cn_configuration_line("Structure","structure");
		slab.add_line(slab_structure);
		slab_structure.add_param(new cn_configuration_param("Epaisseur","thickness",1,100,"cm",1,0.5));
		slab_structure.add_choice(new cn_configuration_choice("Béton","concrete"));
		slab_structure.add_choice(new cn_configuration_choice("Pierre","stone"));
		slab_structure.add_choice(new cn_configuration_choice("Bois","wood"));
		slab_structure.add_choice(new cn_configuration_choice("Acier","steel"));

		var slab_insulation = new cn_configuration_line("Isolant","insulator");
		slab.add_line(slab_insulation);
		slab_insulation.add_param(new cn_configuration_param("Epaisseur","thickness",1,100,"cm",1,0.5));
		slab_insulation.add_choice(new cn_configuration_choice("Aucun","none"));
		slab_insulation.add_choice(new cn_configuration_choice("Supérieur","upper"));
		slab_insulation.add_choice(new cn_configuration_choice("Inférieur","lower"));
		slab_insulation.add_choice(new cn_configuration_choice("Intégré","integrated"));

		var slab_lower_facing = new cn_configuration_line("Parement supérieur","upper_facing");
		slab.add_line(slab_lower_facing);
		slab_lower_facing.add_param(new cn_configuration_param("Epaisseur","thickness",1,100,"cm",1,0.5));
		slab_lower_facing.add_choice(new cn_configuration_choice("Aucun","none"));
		slab_lower_facing.add_choice(new cn_configuration_choice("Tuiles","roof_tiles"));
		slab_lower_facing.add_choice(new cn_configuration_choice("Bardage","tiles"));

		var slab_lower_facing = new cn_configuration_line("Parement inférieur","lower_facing");
		slab.add_line(slab_lower_facing);
		slab_lower_facing.add_param(new cn_configuration_param("Epaisseur","thickness",1,100,"cm",1,0.5));
		slab_lower_facing.add_choice(new cn_configuration_choice("Aucun","none"));
		slab_lower_facing.add_choice(new cn_configuration_choice("Plâtre","gypsum"));

		//***********************************************
		//*** Default values
		var wt = new cn_slab_type();
		wt.set_generic(true);
		wt.fill_configuration(configuration);
		wt.set_generic(false);
		wt.fill_configuration(configuration);
		return configuration
	}

	//***********************************************************************************
	//**** Fill configuration
	//***********************************************************************************
	fill_configuration(config = null)
	{
		var configuration = (config)?config:cn_slab_type.configuration();

		var param_group = configuration.get_param_group("thermics");
		param_group.checked = this.user_physics;
		param_group.set_param("U",this.get_U());

		//*** default to generic
		configuration.selection = 0;

		//*** find proper tab
		var tab_index = (this.is_generic())?0:1;
		if (tab_index < 0) return false;
		var configuration_tab = configuration.tabs[tab_index];

		//*** upper facings are visible only for roofs */
		configuration.tabs[1].get_line("upper_facing").visible = (this.category == "roof");

		if (this.is_generic())
		{
			configuration.tabs[0].lines[0].get_param("thickness").value = this.thickness * 100;
			return configuration;
		}

		var layers = this.layers;
		if (layers.length == 0) return false;

		//find structure layer

		var structure_line = configuration_tab.get_line("structure");

		var insulator_line = configuration_tab.get_line("insulator");
		insulator_line.set_selection("none");
		insulator_line.set_param("thickness",10,false);

		var upper_facing_line = configuration_tab.get_line("upper_facing");
		upper_facing_line.set_selection("none");
		upper_facing_line.set_param("thickness",1,false);

		var lower_facing_line = configuration_tab.get_line("lower_facing");
		lower_facing_line.set_selection("none");
		lower_facing_line.set_param("thickness",1,false);

		var structure_layer_found = false;
		for (var i=0; i < layers.length;i++)
		{
			var layer = layers[i];
			if (layer.type == CN_MATERIAL_TYPE_FACING)
			{
				if (i == 0)
				{
					upper_facing_line.set_selection(layer.code);
					upper_facing_line.set_param("thickness",layer.thickness * 100);
				}
				else if (i == layers.length-1)
				{
					lower_facing_line.set_selection(layer.code);
					lower_facing_line.set_param("thickness",layer.thickness * 100);
				}
			}
			else if (layer.type == CN_MATERIAL_TYPE_HEAVY_STRUCTURE)
			{
				structure_line.set_selection(layer.code);
				structure_line.set_param("thickness",layer.thickness * 100);
				structure_layer_found = true;
			}
			else if (layer.type == CN_MATERIAL_TYPE_INSULATOR)
			{
				if (structure_layer_found)
					insulator_line.set_selection("lower");
				else
					insulator_line.set_selection("upper");
				insulator_line.set_param("thickness",layer.thickness * 100);
			}
			else if (layer.code == "insulated_wood")
			{
				structure_line.set_selection("wood");
				structure_line.set_param("thickness",layer.thickness * 100);
				insulator_line.set_selection("integrated");
				structure_layer_found = true;
			}
			else if (layer.code == "insulated_steel")
			{
				structure_line.set_selection("steel");
				structure_line.set_param("thickness",layer.thickness * 100);
				insulator_line.set_selection("integrated");
				structure_layer_found = true;
			}
			else if (layer.code == "insulated_concrete")
			{
				structure_line.set_selection("concrete");
				structure_line.set_param("thickness",layer.thickness * 100);
				insulator_line.set_selection("integrated");
				structure_layer_found = true;
			}
			else if (layers[i].code == "gypsum" && i == layers.length-1)
			{
				lower_facing_line.set_selection(layer.code);
				lower_facing_line.set_param("thickness",layer.thickness * 100);
			}
		}
		if (!structure_layer_found) return false;
		configuration.selection = 1;

		insulator_line.get_choice("integrated").visible =  (structure_line.get_selection() != "stone");

		return configuration;
	}

	//***********************************************************************************
	//**** Load configuration
	//***********************************************************************************
	load_configuration(configuration)
	{
		var configuration_tab = configuration.tabs[configuration.selection];
		this.set_generic(configuration.selection == 0);

		var param_group = configuration.get_param_group("thermics");
		param_group.visible = true;
		this.user_physics = param_group.checked;
		if (this.user_physics)
			this.U = param_group.get_param("U").value;

		if (this.is_generic())
		{
			var configuration_line = configuration_tab.lines[0];
			this.thickness = 0.01 * configuration_line.get_param("thickness").value;
			this.layers[0].thickness = this.thickness;
			return true;
		}

		this.layers = [];

		var structure_line = configuration_tab.get_line("structure");
		var structure = structure_line.get_selection();
		var structure_thickness = 0.01 * structure_line.get_param("thickness").value;

		var insulator_line = configuration_tab.get_line("insulator");
		var insulator = insulator_line.get_selection();
		var insulator_thickness = 0.01 * insulator_line.get_param("thickness").value;

		if (structure == "stone" && insulator == "integrated")
			insulator = "lower";

		var upper_facing_line = configuration_tab.get_line("upper_facing");
		var upper_facing = upper_facing_line.get_selection();
		var upper_facing_thickness = 0.01 * upper_facing_line.get_param("thickness").value;
		if (!upper_facing_line.visible) upper_facing = "none";

		var lower_facing_line = configuration_tab.get_line("lower_facing");
		var lower_facing = lower_facing_line.get_selection();
		var lower_facing_thickness = 0.01 * lower_facing_line.get_param("thickness").value;

		if (upper_facing != "none")
			this.layers.push(cn_material.material_by_code(upper_facing,upper_facing_thickness));

		if (insulator == "upper")
			this.layers.push(cn_material.material_by_code("insulator",insulator_thickness));

		if (insulator == "integrated")
		{
			if (structure == "wood")
				this.layers.push(cn_material.material_by_code("insulated_wood",structure_thickness));
			else if (structure == "concrete")
				this.layers.push(cn_material.material_by_code("insulated_concrete",structure_thickness));
			else if (structure == "steel")
				this.layers.push(cn_material.material_by_code("insulated_steel",structure_thickness));
		}
		else
			this.layers.push(cn_material.material_by_code(structure,structure_thickness));

		if (insulator == "lower")
			this.layers.push(cn_material.material_by_code("insulator",insulator_thickness));

		if (lower_facing != "none")
			this.layers.push(cn_material.material_by_code(lower_facing,lower_facing_thickness));

		this.thickness = 0;
		for (var i in this.layers)
			this.thickness += this.layers[i].thickness;

		if (!this.user_physics)
		{
			param_group = configuration.get_param_group("thermics");
			param_group.checked = this.user_physics;
			param_group.set_param("U",this.get_U());
		}

		return true;
	}

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
     accept_visitor(element_type_visitor) {
        element_type_visitor.visit_slab_type(this);
    }
}


import { DBConfig } from 'ngx-indexed-db';

/**
 * Clé pour le stockage du tableau des interventions (avec leurs biens).
 */
export const STORAGE_KEY_INTERVENTIONS = 'interventions';

/**
 * Clé pour le stockage du tableau des diagnostics.
 */
export const STORAGE_KEY_DIAGNOSTICS = 'diagnostics';

/**
 * Clé pour le stockage du tableau des configsPolluant.
 */
export const STORAGE_KEY_CONFIGS_POLLUANT = 'configsPolluant';

/**
 * Clé pour le stockage du tableau des configsElectricite.
 */
export const STORAGE_KEY_CONFIGS_ELECTRICITE = 'configsElectricite';

/**
 * Clé pour le stockage du tableau des configsCee.
 */
export const STORAGE_KEY_CONFIGS_CEE = 'configsCee';

/**
 * Clé pour le stockage du tableau des pictogrammes des références de prestationajoutées/modifiées/supprimées en local ou non synchronisées sur le serveur.
 */
export const STORAGE_KEY_PICTO_PRESTATIONS = 'pictoPrestations';

/**
 * Clé pour le stockage du tableau des logos des cofracs ajoutées/modifiées/supprimées en local ou non synchronisées sur le serveur.
 */
export const STORAGE_KEY_LOGO_COFRAC = 'logoCofrac';

/**
 * Clé pour le stockage des fonds de carte ajoutés ou supprimés en local, et non synchronisés sur le serveur.
 */
export const STORAGE_KEY_BACKGROUND_MAPS = 'backgroundMap';

/**
 * Clé pour le stockage du tableau des fichiers de documents ajoutés/modifiés/supprimés en local, et non synchronisés sur le serveur.
 */
// TODO Impélementer mode HL pour documents
// export const STORAGE_KEY_FICHIERS_DOCUMENTS = 'fichiersDocuments';

/**
 * Clé pour le stockage de la date depuis laquelle l'application est en mode hors-ligne
 */
export const STORAGE_KEY_OFFLINE_DATE = 'offlinedate';

/**
 * Id fixe permettant de stocker une seul fois la date hors-ligne
 */
export const STORAGE_OFFLINE_DATE_ID = 'offlinedateid';

/**
 * Clé pour le stockage du tableau des interventions de l'agenda
 */
export const STORAGE_KEY_AGENDAS = 'agendas';

export const STORAGE_KEY_REPORT_TASKS = 'reports';

export const STORAGE_KEY_BON_COMMANDE_TASKS = 'bonCommande';

/**
 * Clé pour le stockage du tableau des images de commentaires ajoutées/modifiées/supprimées en local ou non synchronisées sur le serveur.
 */
export const STORAGE_KEY_IMAGES_COMMENTAIRES = 'imageCommentaires';

/**
 * Clé permettant le stockage des objets intervention file
 */
export const STORAGE_KEY_INTERVENTION_FILES = 'interventionFiles';

/**
 * Clé permettant le stockage des fichiers
 */
export const STORAGE_KEY_FILES = 'files';

export const STORAGE_KEY_USER_INFORMATIONS = 'userInformations';

/**
 * Schema des données dans la base local indexeddb
 */
export const dbConfig: DBConfig = {
    name: 'WizydiagDb',
    version: 4,
    objectStoresMeta: [
        {
            store: STORAGE_KEY_INTERVENTIONS,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
        },
        {
            store: STORAGE_KEY_DIAGNOSTICS,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
        },
        {
            store: STORAGE_KEY_CONFIGS_POLLUANT,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
        },
        {
            store: STORAGE_KEY_CONFIGS_ELECTRICITE,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
        },
        {
            store: STORAGE_KEY_CONFIGS_CEE,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
        },
        {
            store: STORAGE_KEY_AGENDAS,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
        },
        {
            store: STORAGE_KEY_OFFLINE_DATE,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [
                { name: 'id', keypath: 'id', options: { unique: true } },
                { name: 'date', keypath: 'date', options: { unique: true } },
            ],
        },
        {
            store: STORAGE_KEY_BACKGROUND_MAPS,
            storeConfig: { keyPath: 'fileId', autoIncrement: true },
            storeSchema: [
                { name: 'bienId', keypath: 'bienId', options: { unique: false } },
                { name: 'fileId', keypath: 'fileId', options: { unique: true } },
            ],
        },
        {
            store: STORAGE_KEY_IMAGES_COMMENTAIRES,
            storeConfig: { keyPath: 'fileId', autoIncrement: false },
            storeSchema: [{ name: 'fileId', keypath: 'fileId', options: { unique: true } }],
        },

        {
            store: STORAGE_KEY_PICTO_PRESTATIONS,
            storeConfig: { keyPath: 'fileId', autoIncrement: true },
            storeSchema: [
                { name: 'idReferencePrestation', keypath: 'idReferencePrestation', options: { unique: false } },
                { name: 'fileId', keypath: 'fileId', options: { unique: false } },
            ],
        },
        {
            store: STORAGE_KEY_REPORT_TASKS,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
        },
        {
            store: STORAGE_KEY_BON_COMMANDE_TASKS,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
        },
        {
            store: STORAGE_KEY_INTERVENTION_FILES,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }],
        },

        {
            store: STORAGE_KEY_FILES,
            storeConfig: { keyPath: 'fileId', autoIncrement: true },
            storeSchema: [{ name: 'fileId', keypath: 'fileId', options: { unique: true } }],
        },
        {
            store: STORAGE_KEY_USER_INFORMATIONS,
            storeConfig: { keyPath: 'idUser', autoIncrement: true },
            storeSchema: [{ name: 'idUser', keypath: 'idUser', options: { unique: true } }],
        },
    ],
};

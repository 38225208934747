<form [formGroup]="form" novalidate id="ngForm">
    <mat-accordion>
        <!-- BLOCK BIENS-->
        <mat-expansion-panel [togglePosition]="'before'" *ngIf="formConf?.bien?.displayed" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>Bien</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- FORM BIENS-->
            <app-export-svg-sub-form-biens
                [group]="form.get('bien')"
                [biens]="biens"
                [formConf]="formConf"
            ></app-export-svg-sub-form-biens>
        </mat-expansion-panel>

        <!-- BLOCK ESPACES-->
        <mat-expansion-panel [togglePosition]="'before'" *ngIf="formConf?.espace?.displayed" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>Espace</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- FORM BIENS-->
            <app-export-svg-sub-form-espaces
                [group]="form.get('espace')"
                [espaces]="espaces"
                [formConf]="formConf"
            ></app-export-svg-sub-form-espaces>
        </mat-expansion-panel>

        <!-- BLOCK PAGE-->
        <mat-expansion-panel [togglePosition]="'before'" *ngIf="formConf?.page?.displayed">
            <mat-expansion-panel-header>
                <mat-panel-title>Page</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- FORM PAGE-->
            <app-export-svg-subform-page [group]="form.get('page')" [formConf]="formConf"></app-export-svg-subform-page>
        </mat-expansion-panel>

        <!-- BLOCK PLAN-->
        <mat-expansion-panel [togglePosition]="'before'" *ngIf="formConf?.plan?.displayed">
            <mat-expansion-panel-header>
                <mat-panel-title>Plan</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- FORM PLAN-->
            <app-export-svg-subform-plan
                [group]="form.get('plan')"
                [formConf]="formConf"
                (clickCenterView)="onClickCenterView()"
            ></app-export-svg-subform-plan>
        </mat-expansion-panel>

        <!--BLOCK EQUIPEMENT-->
        <mat-expansion-panel [togglePosition]="'before'" *ngIf="formConf?.equipment?.displayed">
            <mat-expansion-panel-header>
                <mat-panel-title>Equipement</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- FORM EQUIPMENT-->
            <app-export-svg-subform-equipments
                [group]="form.get('equipment')"
                [formConf]="formConf"
            ></app-export-svg-subform-equipments>
        </mat-expansion-panel>

        <!--BLOCK ZONES MESURE-->
        <mat-expansion-panel
            [togglePosition]="'before'"
            *ngIf="form.get('plan').get('text').get('showMarkers').value && formConf?.zone?.displayed"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>Zones</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- FORM ZONES-->
            <app-export-svg-subform-zones
                [group]="form.get('zone')"
                [formConf]="formConf"
            ></app-export-svg-subform-zones>
        </mat-expansion-panel>

        <!--BLOCK HAP-->
        <mat-expansion-panel
            [togglePosition]="'before'"
            *ngIf="form.get('plan').get('text').get('showMarkers').value && formConf?.hap?.displayed"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>HAP</mat-panel-title>
            </mat-expansion-panel-header>
            <!-- FORM HAP-->
            <app-export-svg-subform-hap [group]="form.get('hap')" [formConf]="formConf"></app-export-svg-subform-hap>
        </mat-expansion-panel>
    </mat-accordion>
</form>

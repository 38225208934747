import {
    AbstractControl,
    AsyncValidatorFn,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { Cofrac } from '../model/cofrac.model';
import { EtapeDiagnosticGenerique } from '../model/diagnostic-etape.model';
import { TypePrestation, typesPrestation } from '../model/type-prestation.model';
import { DataUtils } from './data.utils';
import { StringUtils } from './string.utils';
import { CommandeService } from '../services/commande.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Commande } from '../model/commande.model';
import { ReferenceApiService } from '../services/reference-api.service';

export class ValidatorUtils {
    /**
     * Validateur vérifiant la présence d'une valeur dans le champ du formulaire si l'attribut booléen "fieldCheck"
     * de "data" est false
     * @param data modèle de données contenant la valeur du "fieldCheck"
     * @param fieldCheck attribut du modèle de données duquel on récupère le booléen
     */
    static checkedOrValueRequired(data: any, fieldCheck: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const checked = DataUtils.getFieldValue(fieldCheck, data) as boolean;
            if (!checked && (control.value == null || control.value.length === 0)) {
                return { customError: { message: 'La valeur est obligatoire' } };
            }
            return null;
        };
    }

    static installDate(etape: EtapeDiagnosticGenerique): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!etape.checked && (control.value == null || control.value.length === 0)) {
                return { customError: { message: "Date de l'installation est obligatoire" } };
            }
            return null;
        };
    }

    /**
     * Permet de savoir si une valeur existe déjà dans une liste de valeurs pour éviter les doublons
     * @param newValue Valeur à controler
     * @param isCreation Mode création ou non
     * @param currentValue Valeur actuelle en cas d'édition
     * @param existingValueList valeur existant à ne pas doublonner
     * @returns retourne true si la newValue n'est pas un doublons et false sinon
     */
    static checkAlreadyExist(
        newValue: string,
        isCreation: boolean,
        currentValue: string,
        existingValueList: string[]
    ): boolean {
        if (newValue) {
            if (isCreation === false) {
                return (
                    StringUtils.toLowerCase(currentValue ? currentValue : '').trim() !==
                        StringUtils.toLowerCase(newValue ? newValue : '').trim() &&
                    existingValueList
                        .map((ref) => ref && StringUtils.toLowerCase(ref).trim())
                        .includes(StringUtils.toLowerCase(newValue).trim())
                );
            } else {
                return existingValueList
                    .map((ref) => ref && StringUtils.toLowerCase(ref).trim())
                    .includes(StringUtils.toLowerCase(newValue).trim());
            }
        }
    }

    /**
     * Permet de vérifier qu'il n'y a pas de chauvauchement de cofrac pour chaque type de prestation
     * @param control
     * @returns
     */
    static validateCofrac(control: AbstractControl) {
        if (control.value.length > 1) {
            const mapCofracsByPrestation = new Map<TypePrestation, Cofrac[]>();
            // Pour chaque type de prestation, on récupère les cofracs sélectionnés associés
            typesPrestation.forEach((typePrestationTemp) => {
                const listeCofracForTypePrestationTemp = control.value.filter((cofracTemp: Cofrac) =>
                    cofracTemp.typePrestations.includes(typePrestationTemp)
                );
                mapCofracsByPrestation[typePrestationTemp] = listeCofracForTypePrestationTemp
                    ? listeCofracForTypePrestationTemp
                    : [];
            });

            // Pour chaque type de prestation, on vérifie qu'aucun cofrac ne se chevauchent (en terme de date)
            let hasOverlap = false;
            Object.keys(mapCofracsByPrestation).forEach((typePrestationKey) => {
                if (!hasOverlap && mapCofracsByPrestation[typePrestationKey].length > 1) {
                    mapCofracsByPrestation[typePrestationKey].forEach((cofrac1Temp) => {
                        if (!hasOverlap) {
                            // Si un overlap est déjà detecté, pas besoin d'aller plus loin
                            mapCofracsByPrestation[typePrestationKey].forEach((cofrac2Temp) => {
                                // Si un overlap est déjà detecté ou si on teste les mêmes cofrac, pas besoin d'aller plus loin
                                if (!hasOverlap && cofrac1Temp.id !== cofrac2Temp.id) {
                                    hasOverlap = ValidatorUtils.checkHasOverlapBetweenDates(cofrac1Temp, cofrac2Temp);
                                }
                            });
                        }
                    });
                }
            });
            if (hasOverlap) {
                return { hasOverlap: true };
            }
        }
        return null;
    }

    static checkHasOverlapBetweenDates(cofrac1: Cofrac, cofrac2: Cofrac) {
        const dateEcheanceCofrac1 = cofrac1.dateEcheance ? moment(cofrac1.dateEcheance) : moment().set('year', 9999);
        const dateEcheanceCofrac2 = cofrac2.dateEcheance ? moment(cofrac2.dateEcheance) : moment().set('year', 9999);

        // 2 starts in 1
        if (
            moment(cofrac1.dateDebutValidite) <= moment(cofrac2.dateDebutValidite) &&
            moment(cofrac2.dateDebutValidite) <= dateEcheanceCofrac1
        ) {
            return true;
        }

        // 2 ends in 1
        if (moment(cofrac1.dateDebutValidite) <= dateEcheanceCofrac2 && dateEcheanceCofrac2 <= dateEcheanceCofrac1) {
            return true;
        }

        // 1 in 2
        if (
            moment(cofrac2.dateDebutValidite) <= moment(cofrac1.dateDebutValidite) &&
            dateEcheanceCofrac1 <= dateEcheanceCofrac2
        ) {
            return true;
        }

        // 2 in 1
        if (
            moment(cofrac1.dateDebutValidite) <= moment(cofrac2.dateDebutValidite) &&
            dateEcheanceCofrac2 <= dateEcheanceCofrac1
        ) {
            return true;
        }

        return false;
    }

    static checkedOneOperatorChecked(): ValidatorFn {
        return (formGroup: FormGroup): { [key: string]: any } | null => {
            let checked = 0;
            const minRequired = 1;

            Object.keys(formGroup.controls).forEach((key) => {
                const control = formGroup.controls[key];
                if (control.value === true) {
                    checked++;
                }
            });
            if (checked < minRequired) {
                return { customError: { message: 'Vous devez selectionner au minimum 1 opérateur.' } };
            }
            return null;
        };
    }

    static checkFirstDateIsBeforeSecond(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value.dateDebut.isAfter(control.value.dateFin)) {
                return { customError: { message: 'La date de début doit être antérieur à la date de fin' } };
            }
            return null;
        };
    }

    /**
     * Validateur asynchrone vérifiant l'unicité du numéro de commande.
     * @param service
     * @param commandeInitial
     */
    static numeroCommandeAlreadyExists(service: CommandeService, commandeInitial: Commande): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            const numCommandeToCheck =
                control.value && control.value.numeroCommande ? control.value.numeroCommande : control.value;
            if (
                commandeInitial &&
                commandeInitial.numeroCommande &&
                commandeInitial.numeroCommande.trim() === numCommandeToCheck.trim()
            ) {
                return of(null);
            }
            return service.isNumeroCommandeAvailable(numCommandeToCheck).pipe(
                map((result: boolean) => {
                    if (commandeInitial && commandeInitial.numeroCommande) {
                        return commandeInitial.numeroCommande.trim() !== numCommandeToCheck.trim() && result
                            ? { numeroCommandeAlreadyExists: true }
                            : null;
                    } else {
                        return result ? { numeroCommandeAlreadyExists: true } : null;
                    }
                })
            );
        };
    }

    static uniciteTypeOuvrageValidator(
        controlNom: AbstractControl,
        controlParentsIds: AbstractControl,
        service: ReferenceApiService,
        idTypeOuvrageEdit?: string
    ): AsyncValidatorFn {
        return (): Observable<ValidationErrors> => {
            if (controlNom.value) {
                if (controlParentsIds.value) {
                    return service.isTypeOuvrageAvailable(controlNom.value.trim(), controlParentsIds.value.id).pipe(
                        map((result: string) => {
                            return this.setError(idTypeOuvrageEdit, result, controlNom, controlParentsIds);
                        })
                    );
                } else {
                    return service.isTypeOuvrageAvailableParent(controlNom.value.trim()).pipe(
                        map((result: string) => {
                            return this.setError(idTypeOuvrageEdit, result, controlNom, controlParentsIds);
                        })
                    );
                }
            } else {
                return of(null);
            }
        };
    }

    static uniciteMaterielValidator(
        controlNom: AbstractControl,
        service: ReferenceApiService,
        idTypeOuvrageEdit?: string
    ): AsyncValidatorFn {
        return (): Observable<ValidationErrors> => {
            if (controlNom.value) {
                return service.isMaterielAvailable(controlNom.value.trim()).pipe(
                    map((result: string) => {
                        return this.setError(idTypeOuvrageEdit, result, controlNom);
                    })
                );
            } else {
                return of(null);
            }
        };
    }

    private static setError(
        idContactEdit: string,
        result: string,
        controlNom: AbstractControl,
        controlPrenomOrSiret?: AbstractControl
    ) {
        if (idContactEdit) {
            if (result && result !== idContactEdit) {
                return this.setHasError(controlNom, controlPrenomOrSiret);
            } else {
                return this.clearError(controlNom, controlPrenomOrSiret);
            }
        } else {
            if (result) {
                return this.setHasError(controlNom, controlPrenomOrSiret);
            } else {
                return this.clearError(controlNom, controlPrenomOrSiret);
            }
        }
    }

    private static clearError(controlNom: AbstractControl, controlPrenomOrSiret: AbstractControl) {
        if (controlNom.errors) {
            delete controlNom.errors.contactExistant;
        }
        if (controlNom.errors && Object.entries(controlNom.errors).length == 0) {
            controlNom.setErrors(null);
        }

        // Suppression de l'erreurs sur prenom/siret
        if (controlPrenomOrSiret) {
            if (controlPrenomOrSiret.errors) {
                delete controlPrenomOrSiret.errors.contactExistant;
            }
            if (controlPrenomOrSiret.errors && Object.entries(controlPrenomOrSiret.errors).length == 0) {
                controlPrenomOrSiret.setErrors(null);
            }
        }
        return null;
    }

    private static setHasError(controlNom: AbstractControl, controlPrenomOrSiret: AbstractControl) {
        controlNom.errors
            ? (controlNom.errors.contactExistant = true)
            : controlNom.setErrors({ contactExistant: true });
        // Ajout de l'erreurs sur prenom/siret
        if (controlPrenomOrSiret) {
            controlPrenomOrSiret.errors
                ? (controlPrenomOrSiret.errors.contactExistant = true)
                : controlPrenomOrSiret.setErrors({ contactExistant: true });
        }
        return { contactExistant: true };
    }

    static emptyOr(validatorFns: ValidatorFn[]): ValidatorFn {
        const composedValidatorFn = Validators.compose(validatorFns);
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value) {
                return composedValidatorFn(control);
            } else {
                return null;
            }
        };
    }

    static mustStartWith(otherControl: AbstractControl, message = ''): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && control.value.startsWith && otherControl.value) {
                if (!(control.value as string).startsWith(otherControl.value)) {
                    return { muststartwith: { message } };
                }
            }
            return null;
        };
    }

    static entityMustExist(message = ''): ValidatorFn {
        return (control) => {
            if (control.value && typeof control.value.id !== 'string') {
                return { entitymustexist: { message } };
            } else {
                return null;
            }
        };
    }
}

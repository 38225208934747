import { NgModule } from '@angular/core';
import { TypeBienPipe } from './type-bien.pipe';
import { TypePrestationPipe } from './type-prestation.pipe';
import { TypeCommentairePipe } from './type-commentaire.pipe';
import { FileExtensionPipe } from './file-extension.pipe';
import { FilenameWithoutExtensionPipe } from './filename-without-extension.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { EtatDiagnosticPipe } from './etat-diagnostic.pipe';
import { EtatInterventionPipe } from './etat-intervention.pipe';
import { EtatWorkflowPipe } from './etat-workflow.pipe';
import { FindAttributePipe } from './find-attribute.pipe';
import { MesurePipe } from './mesure.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { PictoPrestationPipe } from './picto-prestation.pipe';
import { TypeAgencePipe } from './type-agence.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';
import { FileNamePipe } from './file-name.pipe';
import { TypeCarottagePipe } from './type-carottage.pipe';
import { ProduitLimitationFibresPipe } from './produit-limitation-fibres.pipe';
import { TypePrelevementPipe } from './type-prelevement.pipe';
import { TypeEchantillonagePipe } from './type-echantillonage.pipe';
import { PrestationRapportPipe } from './prestation-rapport.pipe';
import { FileDownloadPipe } from './file-download.pipe';
import { BonCommandeAnalyseStatePipe } from './bon-comande-analyse-state.pipe';
import { TypeAnalysePipe } from './type-analyse.pipe';
import { CategorieDocumentPipe } from './categorie-document.pipe';
import { CheckConformiteDocumentByPrestationPipe } from './check-conformite-document-by-prestation.pipe';
import { TypeElementAControllerPipe } from './type-element-a-controller.pipe';
import { ExigenceDescriptionPipe } from './exigence-description.pipe';
import { IsPrestationCheckedPipe } from './is-prestation-checked.pipe';
import { PresetPhotoPipe } from './preset-photo.pipe';
import { TypeAnalyseSimplePipe } from './type-analyse-simple.pipe';
import { AsTypePipe } from './as-type.pipe';
import { EtatRapportPipe } from './etat-rapport.pipe';
import { TypeLivrablePipe } from './type-livrable.pipe';
import { TypeRapportPipe } from './type-rapport.pipe';
import { AdressePipe } from './adresse.pipe';
import { UserHasRolePipe } from './user-has-role.pipe';
import { RefBesoinAirPipe } from './besoinAir.pipe';

@NgModule({
    imports: [],
    declarations: [
        SafeHtmlPipe,
        TypeBienPipe,
        TypePrestationPipe,
        TypeCommentairePipe,
        FileExtensionPipe,
        FilenameWithoutExtensionPipe,
        IsPrestationCheckedPipe,
        MesurePipe,
        EtatInterventionPipe,
        ExigenceDescriptionPipe,
        EtatDiagnosticPipe,
        PresetPhotoPipe,
        EtatWorkflowPipe,
        FindAttributePipe,
        SafeUrlPipe,
        PictoPrestationPipe,
        TypeAgencePipe,
        EnumToArrayPipe,
        FileNamePipe,
        TypeCarottagePipe,
        ProduitLimitationFibresPipe,
        TypePrelevementPipe,
        TypeEchantillonagePipe,
        PrestationRapportPipe,
        FileDownloadPipe,
        BonCommandeAnalyseStatePipe,
        TypeAnalysePipe,
        TypeAnalyseSimplePipe,
        CategorieDocumentPipe,
        CheckConformiteDocumentByPrestationPipe,
        TypeElementAControllerPipe,
        AsTypePipe,
        EtatRapportPipe,
        TypeLivrablePipe,
        TypeRapportPipe,
        AdressePipe,
        UserHasRolePipe,
        RefBesoinAirPipe,
    ],
    exports: [
        SafeHtmlPipe,
        TypeBienPipe,
        TypePrestationPipe,
        TypeCommentairePipe,
        FileExtensionPipe,
        FilenameWithoutExtensionPipe,
        IsPrestationCheckedPipe,
        MesurePipe,
        EtatInterventionPipe,
        ExigenceDescriptionPipe,
        EtatDiagnosticPipe,
        PresetPhotoPipe,
        EtatWorkflowPipe,
        FindAttributePipe,
        SafeUrlPipe,
        PictoPrestationPipe,
        TypeAgencePipe,
        EnumToArrayPipe,
        FileNamePipe,
        TypeCarottagePipe,
        ProduitLimitationFibresPipe,
        TypePrelevementPipe,
        TypeEchantillonagePipe,
        PrestationRapportPipe,
        FileDownloadPipe,
        BonCommandeAnalyseStatePipe,
        TypeAnalysePipe,
        TypeAnalyseSimplePipe,
        CategorieDocumentPipe,
        CheckConformiteDocumentByPrestationPipe,
        TypeElementAControllerPipe,
        AsTypePipe,
        EtatRapportPipe,
        TypeLivrablePipe,
        TypeRapportPipe,
        AdressePipe,
        UserHasRolePipe,
        RefBesoinAirPipe,
    ],
    providers: [
        SafeHtmlPipe,
        TypeBienPipe,
        TypePrestationPipe,
        TypeCommentairePipe,
        FileExtensionPipe,
        FilenameWithoutExtensionPipe,
        IsPrestationCheckedPipe,
        MesurePipe,
        EtatInterventionPipe,
        ExigenceDescriptionPipe,
        EtatDiagnosticPipe,
        PresetPhotoPipe,
        EtatWorkflowPipe,
        FindAttributePipe,
        SafeUrlPipe,
        PictoPrestationPipe,
        TypeAgencePipe,
        EnumToArrayPipe,
        FileNamePipe,
        TypeCarottagePipe,
        ProduitLimitationFibresPipe,
        TypePrelevementPipe,
        TypeEchantillonagePipe,
        PrestationRapportPipe,
        BonCommandeAnalyseStatePipe,
        TypeAnalysePipe,
        TypeAnalyseSimplePipe,
        CategorieDocumentPipe,
        CheckConformiteDocumentByPrestationPipe,
        TypeElementAControllerPipe,
        AsTypePipe,
        EtatRapportPipe,
        TypeLivrablePipe,
        TypeRapportPipe,
    ],
})
export class PipesModule {}

// @ts-check
"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_comment_picture : a comment picture
//***********************************************************************************
//***********************************************************************************

import {cn_element} from "./cn_element";

export class cn_comment_picture extends cn_element {
	constructor() {
        super();
        this.name = "";
		this.image_id = "";
	}

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize() {
		const json = {};
		json.ID = this.ID;
		json.name = this.name;
		json.image_id = this.image_id;
		return json;
	}

	static unserialize(json) {
		if (typeof(json) != 'object') return false;
		if (typeof(json.name) != 'string') return false;
		if (typeof(json.image_id) != 'string') return false;

		const comment_picture = new cn_comment_picture();
		if (typeof(json.ID) == 'string')
            comment_picture.ID = json.ID;
        comment_picture.name = json.name;
        comment_picture.image_id = json.image_id;

		return comment_picture;
	}

	static image_id_to_url(image_id) {
	    return undefined;
    }
}



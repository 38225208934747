import { Injectable } from '@angular/core';
import { Document, DocumentHistory, DocumentPreRapport } from '../model/document.model';
import { EtatIntervention, Intervention, PrestationDiagnostic } from '../model/intervention.model';
import { Diagnostic, EtatDiagnostic } from '../model/diagnostic.model';
import { Rule } from '../model/regle.model';
import { RulesService } from './rules.service';
import { CommentaireDocData, DocumentData } from '../model/rapport.model';
import { Commentaire } from '../model/commentaire.model';
import { TypePrestationPipe } from '../pipes/type-prestation.pipe';
import { DOCUMENTS_RAPPORT } from '../shared/constants/names.step.constants';
import { CommentUtils } from '../utils/comment.utils';
import { isEmpty, wherePropEquals } from '../utils/list.utils';
import { Conformite } from '../model/conformite.model';
import { DocumentUtils } from '../utils/document.utils';
import { ReferencePrestation } from '../model/reference-prestation.model';
import { DocumentsApiService } from './documents-api.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT } from '../shared/constants/cndiag.constants';

/**
 * Service pour les documents.
 */
// TODO Réimplémenter un mode HL
@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(
        private readonly rulesService: RulesService,
        private readonly typePrestationPipe: TypePrestationPipe,
        private documentsApiService: DocumentsApiService
    ) {}

    /**
     * Obtient une liste de documents agrémenté d'un flag indiquant s'il faut afficher ou pas en tant qu'annexe dans le rapport
     * @param documents
     * @param diagnostic
     * @param canEdit
     */
    public static buildDocumentsAfficheRapport(
        documents: Document[],
        diagnostic: Diagnostic,
        canEdit = false
    ): (Document & DocumentPreRapport)[] {
        if (
            canEdit &&
            !diagnostic.etatProgressions.find((etatProgression) => etatProgression.code == DOCUMENTS_RAPPORT) &&
            isEmpty(diagnostic.documentsRapport)
        ) {
            return documents.map((doc) => ({
                ...doc,
                afficherDansRapport:
                    (doc.requiredForIntervention ||
                        ['ATTESTATION_HONNEUR', 'ASSURANCE'].includes(doc.typeDocument.code)) &&
                    doc.idFichier !== undefined,
                afficherDansListeDocuments:
                    doc.requiredForIntervention || ['ATTESTATION_HONNEUR', 'ASSURANCE'].includes(doc.typeDocument.code),
            }));
        } else {
            return documents.map((doc) => ({
                ...doc,
                afficherDansRapport: diagnostic.documentsRapport.includes(doc.id) && doc.idFichier !== undefined,
                afficherDansListeDocuments: diagnostic.documentsListRapport.includes(doc.id),
            }));
        }
    }

    findOneDocument(idDocument: string): Observable<Document> {
        return this.documentsApiService.findOneDocument(idDocument);
    }

    /**
     * Retourne la liste de tous les documents spécifique au diagnostic si présent
     * Sinon de l'intervention avec un attribut qui indique si le document est obligatoire ou non
     * @param intervention
     * @param diagnostic
     */
    getDocumentsInformation(intervention: Intervention, diagnostic?: Diagnostic): Document[] {
        let documents: Document[] = [];
        let prestationDiagnostics: PrestationDiagnostic[] = [];

        if (diagnostic) {
            prestationDiagnostics = intervention.prestationsDiagnostics.filter(
                (prestaDiag) => prestaDiag.idDiagnostic === diagnostic.id
            );
        } else {
            prestationDiagnostics = intervention.prestationsDiagnostics;
        }

        documents = documents.concat(
            intervention.documents.filter(
                (doc) =>
                    !doc.typePrestations ||
                    prestationDiagnostics.filter((prestationDiagnostic) =>
                        doc.typePrestations.includes(prestationDiagnostic.prestation.typePrestation)
                    ).length > 0
            )
        );

        return documents;
    }

    /**
     * Indique si un document obligatoire est manquant dans le diagnostic si renseigné, sinon l'intervention
     * @param intervention
     * @param diagnostic
     */
    isDocumentsObligatoireManquant(intervention: Intervention, diagnostic?: Diagnostic): boolean {
        const documentsObligatoireManquant = this.getDocumentsInformation(intervention, diagnostic).filter(
            (document) => document.requiredForIntervention && !document.idFichier
        );

        return documentsObligatoireManquant.length > 0;
    }

    /**
     * Determine si l'utilisateur peut ajouter un document
     * @param intervention
     * @param diagnostic
     */
    canAddDocument(intervention: Intervention, userCanEdit: boolean, diagnostic?: Diagnostic): boolean {
        return this.canDoSomeThingOnDocument(intervention, diagnostic, userCanEdit);
    }

    /**
     * Determine si un document peut être modifié
     * @param intervention
     * @param document
     * @param diagnostic
     */
    canEditDocument(
        intervention: Intervention,
        document: Document,
        userCanEdit: boolean,
        diagnostic?: Diagnostic
    ): boolean {
        return this.canDoSomeThingOnDocument(intervention, diagnostic, userCanEdit);
    }

    /**
     * Vérifie la conformité globale du document par rapport à ses checkpoints (quelque soit la prestation)
     * Si au moins un checkpoint est invalid, le document est NON_CONFORME
     * @param document
     * @param referencePrestation Si la reference prestation existe, on vérifie la conformité de cette prestation en plus de la conformité globale du document
     */
    checkConformiteDocument(document: Document, referencePrestation?: ReferencePrestation) {
        // Conformité du document pour la prestation
        if (referencePrestation) {
            let hasCheckpointInvalidForPrestation = false;
            const typeDocumentCheckpoints = document.typeDocument.typeDocumentCheckpoint;
            if (typeDocumentCheckpoints && typeDocumentCheckpoints.length > 0) {
                const typeDocumentCheckpointForPrestation = typeDocumentCheckpoints.find(
                    (typeDocumentCheckpointTemp) =>
                        typeDocumentCheckpointTemp.referencePrestation.id == referencePrestation.id
                );
                const listeCheckpointsInvalid = typeDocumentCheckpointForPrestation.checkpoints.filter(
                    (checkpointTemp) => !checkpointTemp.value
                );
                if (listeCheckpointsInvalid.length > 0) {
                    hasCheckpointInvalidForPrestation = true;
                }

                if (hasCheckpointInvalidForPrestation) {
                    typeDocumentCheckpointForPrestation.conformite = Conformite.NON_CONFORME;
                } else {
                    typeDocumentCheckpointForPrestation.conformite = Conformite.CONFORME;
                }
            }
        }

        // Conformité globale du document
        let hasCheckpointInvalid = false;
        document.typeDocument.typeDocumentCheckpoint.forEach((typeDocumentCheckpointTemp) => {
            const listeCheckpointsInvalid = typeDocumentCheckpointTemp.checkpoints.filter(
                (checkpointTemp) => !checkpointTemp.value
            );
            if (listeCheckpointsInvalid.length > 0) {
                hasCheckpointInvalid = true;
            }
        });

        if (hasCheckpointInvalid) {
            document.conformite = Conformite.NON_CONFORME;
        } else {
            document.conformite = Conformite.CONFORME;
        }
    }

    /**
     *
     * @param intervention
     * @param typePrestation
     * @param referencePrestation
     * @returns
     */
    findDocumentForPrestation(
        intervention: Intervention,
        idDiagnostic: string,
        referencePrestation?: ReferencePrestation
    ): Document[] {
        let documentsFiltered: Document[] = [];

        // On récupère la referencePrestation pour pouvoir filtrer les documents
        if (idDiagnostic && !referencePrestation) {
            const prestationDiagnosticCourant = intervention.prestationsDiagnostics.find(
                (prestationDiagTemp) => prestationDiagTemp.idDiagnostic === idDiagnostic
            );
            referencePrestation = prestationDiagnosticCourant.prestation.referencePrestation;
        }

        // Si une referencePrestation existe, on filtre les documents
        // Sinon on prend tous les documents de l'intervention
        if (referencePrestation) {
            intervention.documents.forEach((documentTemp) => {
                let typeDocumentForPrestation;
                let typePrestation;
                const typeDocumentCheckpoints = documentTemp.typeDocument.typeDocumentCheckpoint;
                // On vérifie soit avec la referencePrestation et les poitns de controle s'il y en a sinon, avec la liste des types de prestation
                if (typeDocumentCheckpoints && typeDocumentCheckpoints.length > 0) {
                    typeDocumentForPrestation = typeDocumentCheckpoints.filter((typeDocumentCheckpointValue) => {
                        return referencePrestation.id == typeDocumentCheckpointValue.referencePrestation.id;
                    });
                } else {
                    typePrestation = documentTemp.typePrestations.filter((typePrestationTemp) => {
                        return referencePrestation.typePrestation == typePrestationTemp;
                    });
                }

                // On test si on affiche le document ou pas
                if (
                    (typeDocumentForPrestation && typeDocumentForPrestation.length > 0) ||
                    (typePrestation && typePrestation.length > 0)
                ) {
                    documentsFiltered.push(documentTemp);
                }
            });
        } else {
            documentsFiltered = intervention.documents;
        }

        // On trie les documents par leur nom pour les avoir toujours dans le même ordre
        DocumentUtils.sortDocuments(documentsFiltered);

        return documentsFiltered;
    }

    /**
     * Construit la liste contenant les documents à afficher dans le pré-rapport
     * @param intervention
     * @param diagnostic
     */
    buildDocumentsData(intervention: Intervention, diagnostic: Diagnostic): DocumentData[] {
        const documentsInformations: Document[] = this.getDocumentsInformation(intervention, diagnostic);

        return documentsInformations.map((document) => ({
            id: document.id,
            nom: document.nom,
            date: moment(document.dateCreation).format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT),
            idFichier: document.idFichier,
            afficherDansRapport: diagnostic.documentsRapport.includes(document.id),
            afficherDansListeDocuments: diagnostic.documentsListRapport.includes(document.id),
            type:
                document.typeDocument && document.typeDocument.typePrestations
                    ? this.typePrestationPipe.transform(diagnostic.typePrestation)
                    : 'Commun',
            commentaires: this.buildCommentairesDoc(document.commentairesId, intervention),
        }));
    }

    /**
     * Construit une liste de commentaire de doc
     * @param commentairesId
     * @param intervention
     */
    buildCommentairesDoc(commentairesId: string[], intervention: Intervention): any[] {
        return commentairesId.map((commentaireId) => {
            return this.buildCommentaireDoc(CommentUtils.getCommentaire(commentaireId, intervention.commentaires));
        });
    }

    /**
     * Construit un objet de commentaire de doc
     * @param commentaire
     */
    buildCommentaireDoc(commentaire: Commentaire): CommentaireDocData {
        const commentaireDocData = new CommentaireDocData();
        commentaireDocData.id = commentaire.id;
        commentaireDocData.contenu = commentaire.contenu;
        return commentaireDocData;
    }

    prepareDeleteFile(document: Document) {
        document.idFichier = null;
        document.nomFichier = null;
        document.dateTransmission = null;
        return document;
    }

    /**
     * Prepare les données pour ajouter un ancien document à l'historique
     * @param document
     * @param commentaire
     */
    prepareDataHistory(document: Document, commentaire: Commentaire) {
        const documentHistory: DocumentHistory = {
            sourceTransmission: document.sourceTransmission,
            dateTransmission: document.dateTransmission,
            nomFichier: document.nomFichier,
            commentairesId: [commentaire.id],
        };
        return documentHistory;
    }

    private canDoSomeThingOnDocument(
        intervention: Intervention,
        diagnostic?: Diagnostic,
        userCanEdit: boolean = false
    ): boolean {
        if (!userCanEdit) {
            // l'utilisateur ne peut pas modiié
            return false;
        } else if (
            [EtatIntervention.TERMINEE, EtatIntervention.ANNULEE, EtatIntervention.NON_REALISEE].includes(
                intervention.etat
            )
        ) {
            // l'intervention n'est plus modifiable
            return false;
        } else if (diagnostic) {
            const currentPrestationsDiagnostics = intervention.prestationsDiagnostics.find(
                wherePropEquals('idDiagnostic', diagnostic.id)
            );
            // on peut éditer si le diag est en cours ou en attente.
            return [EtatDiagnostic.EN_COURS, EtatDiagnostic.EN_ATTENTE].includes(diagnostic.etat);
        } else {
            // l'utilisateur peut modifier et l'intervention est dans un état modifiable.
            return true;
        }
    }

    /**
     * Retourne la liste des codes des documents obligatoires
     * @param documents
     * @param prestationDiagnostics
     */
    private getDocumentRequiredCode(documents: Document[], prestationDiagnostics: PrestationDiagnostic[]) {
        let requiredDocuments: string[] = [];
        prestationDiagnostics.forEach((prestDiag) => {
            const regle = prestDiag.prestation.regles.find((r) => r.type === 'REGLE_DOCUMENT');
            if (regle && regle.jsonData) {
                requiredDocuments = requiredDocuments.concat(
                    this.rulesService
                        .computeRequiredDocument(documents, JSON.parse(regle.jsonData) as Rule[])
                        .map((rec) => {
                            return rec.code;
                        })
                );
            }
        });
        return requiredDocuments;
    }
}

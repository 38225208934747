import { Injectable } from '@angular/core';

import { Intervention, RelationInterventionBien } from '../../../../model/intervention.model';
import {
    Assainissement,
    AssainissementReportData,
    ItemElementAssainissement,
    RowBienAssainissement,
    RowElementAssainissement,
    RowNiveauAssainissement,
    RowVolumeAssainissement,
    SubBlockAssainissement,
} from '../model/assainissement.model';
import * as moment from 'moment';
import { TypeCheckpoint } from '../../../../model/type-element-a-controler.model';
import {
    CODE_BIM_PARAM_CATEGORIE,
    CODE_BIM_PARAM_CATEGORIE_AUTRE,
    CODE_BIM_PARAM_CATEGORIE_EQUIPEMENT,
    CODE_BIM_PARAM_CATEGORIE_RESEAU,
    CODE_BIM_PARAM_ESPACE_HUMIDE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE,
    CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE,
    CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION,
    CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EM,
    CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EP,
    CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EU,
    CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EV,
    PARAM_VOLUME_VISITE,
    URL_MS_REPORT_FILE,
} from '../../../../shared/constants/cndiag.constants';
import { ItemEtapeDiagnosticGenerique } from '../../../../model/diagnostic-etape.model';
import {
    PointDeControleBien,
    PointDeControleElement,
    PointDeControleNiveau,
    PointDeControleVolume,
} from '../../../../model/point-de-controle.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { ComputeModeEnum, Rule, RuleOptions } from '../../../../model/regle.model';
import { RulesService } from '../../../../services/rules.service';
import {
    NAMES_MAPPER,
    PARAM_ELEMENT_CONTROLE,
    PARAM_ELEMENT_ECOULEMENT,
    PARAM_ELEMENT_ETAT,
    PARAM_ELEMENT_MATERIAUX,
    PARAM_ELEMENT_ODEUR,
    PARAM_ELEMENT_REJET,
} from '../shared/assainissement.constants';
import { Commentaire } from '../../../../model/commentaire.model';
import { Volume } from '../../../../model/bien.model';
import { CustomSelectGroup, ItemCustomSelect } from 'src/app/modules/shared/custom-select/custom-select.model';
import { InterventionReportData } from '../../../../model/rapport.model';
import { DocumentsService } from '../../../../services/documents.service';
import { TypeReport } from 'src/app/model/reference-prestation.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { TypeReferenceFichier } from 'src/app/model/intervention-file.model';

/**
 * Service pour le diagnostic assainissement.
 */
@Injectable({
    providedIn: 'root',
})
export class AssainissementService {
    constructor(
        private readonly ruleService: RulesService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly documentsService: DocumentsService,
        private readonly interventionFileService: InterventionFileService
    ) {}

    /**
     * Initialisation / maj des points de controles dans le diagnostic, selon la description des biens.
     * Si la description évolue au fur et à mesure du diagnostic, les points de contrôles sont eux aussi mis à jour
     * (ajout de bien/niveau/volume/élément, maj des noms des bien/niveau/volume/élément, ou suppression d'un bien/niveau/volume/élément)
     * Si un bien, niveau, volume, équipement a été supprimé de la description, les commentaires et photos du point de contrôle associé sont aussi supprimés
     */
    initFormPointsDeControleAssainissementTotal(
        idDiagnostic: string,
        formPointsDeControle: PointDeControleBien[],
        relationInterventionBiens: RelationInterventionBien[],
        intervention: Intervention
    ): boolean {
        // BIEN
        relationInterventionBiens.forEach((relationInterventionBien) => {
            let bienHasChanged = false;
            let newBien = false;
            let formBien = formPointsDeControle.find((f) => f.idBien === relationInterventionBien.bien.id);
            // Si le bien a été ajouté dans la description
            if (formBien === undefined) {
                newBien = true;
                formBien = new PointDeControleBien();
                formBien.idBien = relationInterventionBien.bien.id;
            }
            // maj du nom du bien
            formBien.nomBien = relationInterventionBien.bien.nom;

            // NIVEAU
            relationInterventionBien.bien.description.forEach((niveau) => {
                let niveauHasChanged = false;
                let newNiveau = false;
                let formNiveau = formBien.pointsDeControleNiveaux.find((f) => f.idNiveau === niveau.id);
                // Si le niveau a été ajouté dans la description
                if (formNiveau === undefined) {
                    newNiveau = true;
                    formNiveau = new PointDeControleNiveau();
                    formNiveau.idNiveau = niveau.id;
                }
                // maj du nom du niveau
                formNiveau.nom = niveau.nom;
                formNiveau.index = niveau.index;
                formNiveau.storeyId = niveau.storeyId;

                // VOLUME
                niveau.volumes.forEach((volume) => {
                    let volumeHasChanged = false;
                    let newVolume = false;
                    let formVolume = formNiveau.pointsDeControleVolumes.find((f) => f.idVolume === volume.id);
                    // Si le volume a été ajouté dans la description
                    if (formVolume === undefined) {
                        newVolume = true;
                        formVolume = new PointDeControleVolume();
                        formVolume.idVolume = volume.id;
                        formVolume.volumeCache = volume.volumeCache;
                        // Maj des flags
                        volumeHasChanged = true;
                        niveauHasChanged = true;
                        bienHasChanged = true;
                    }
                    // maj du nom du volume
                    formVolume.nom = volume.nom;
                    formVolume.spaceId = volume.spaceId;
                    formVolume.usageId = volume.usageId;
                    formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_HUMIDE] =
                        volume.valeursParametres[CODE_BIM_PARAM_ESPACE_HUMIDE];
                    formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ] =
                        volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_CARREZ];
                    formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE] =
                        volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_HABITABLE];
                    formVolume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE] =
                        volume.valeursParametres[CODE_BIM_PARAM_ESPACE_NON_REPUTE_UTILE];
                    // Récupération des commentaires liés au volume dans la description
                    volume.commentairesId.forEach((com) => {
                        if (!formVolume.commentairesId.includes(com)) {
                            formVolume.commentairesId = formVolume.commentairesId.concat(com);
                        }
                    });
                    // Nettoyage des commentaires obsolètes
                    formVolume.commentairesId.forEach((com) => {
                        const comment = intervention.commentaires.find((c) => c.id === com);
                        if (comment === undefined) {
                            formVolume.commentairesId.splice(formVolume.commentairesId.indexOf(com), 1);
                        }
                    });

                    // Cas d'un volume vide
                    if (volume.elementsAControler.length === 0) {
                        volumeHasChanged = true;
                        niveauHasChanged = true;
                        bienHasChanged = true;
                    }

                    // Cas d'un volume contenant des éléments à contrôler
                    // ELEMENT
                    volume.elementsAControler.length &&
                        volume.elementsAControler.forEach((it) => {
                            if (it.typesPrestationsAssocie.includes('ASSAINISSEMENT')) {
                                let formElementAControler = formVolume.pointsDeControleElements.find(
                                    (f) => f.idElement === it.id
                                );
                                // Si l'élément à contrôler a été ajouté dans la description
                                if (formElementAControler === undefined) {
                                    // Maj des flags
                                    volumeHasChanged = true;
                                    niveauHasChanged = true;
                                    bienHasChanged = true;

                                    formElementAControler = new PointDeControleElement();
                                    formElementAControler.idElement = it.id;
                                    formElementAControler.nom = it.nom;
                                    formElementAControler.objectId = it.objectId;
                                    formElementAControler.codeRef = it.codeBim;

                                    formElementAControler.valeursParametres[
                                        CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION
                                    ] = it.valeursParametres[CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION];
                                    formElementAControler.valeursParametres[CODE_BIM_PARAM_CATEGORIE] =
                                        it.valeursParametres[CODE_BIM_PARAM_CATEGORIE];
                                    formElementAControler.typesPrestationsAssocie = it.typesPrestationsAssocie;

                                    formVolume.pointsDeControleElements.push(formElementAControler);
                                }
                            }
                        });

                    // Si un volume a été ajouté
                    if (volumeHasChanged && newVolume) {
                        formNiveau.pointsDeControleVolumes.push(formVolume);
                    }
                });

                // Si un niveau a été ajouté
                if (niveauHasChanged && newNiveau) {
                    formNiveau.pointsDeControleVolumes.length && formBien.pointsDeControleNiveaux.push(formNiveau);
                }
                formBien.pointsDeControleNiveaux = formBien.pointsDeControleNiveaux.sort((a, b) => a.index - b.index);
            });

            // Si un bien a été ajouté
            if (bienHasChanged && newBien) {
                formBien.pointsDeControleNiveaux.length && formPointsDeControle.push(formBien);
            }
        });

        // Si on supprime des infos dans la description, maj du formulaire
        // BIEN
        let deletedComments = false;
        formPointsDeControle.forEach((formBien) => {
            const bien = relationInterventionBiens.find((b) => b.bien.id === formBien.idBien);

            // Si le bien a été supprimé de la description
            if (bien === undefined) {
                formPointsDeControle.splice(formPointsDeControle.indexOf(formBien), 1);
                // Suppression des commentaires + photos atachées
                const flattenedComIds = formBien.pointsDeControleNiveaux
                    .flatMap((niv) => niv.pointsDeControleVolumes)
                    .flatMap((v) => v.commentairesId)
                    .concat(
                        formBien.pointsDeControleNiveaux
                            .flatMap((niv) => niv.pointsDeControleVolumes)
                            .flatMap((vol) => vol.pointsDeControleElements)
                            .flatMap((el) => el.commentairesId)
                    );
                if (flattenedComIds.length) {
                    deletedComments = true;
                    this.deleteComments(flattenedComIds, intervention, idDiagnostic);
                }
            } else {
                formBien.pointsDeControleNiveaux.forEach((formNiveau) => {
                    const niveau = bien.bien.description.find((niv) => niv.id === formNiveau.idNiveau);

                    // Si le niveau a été supprimé de la description
                    if (niveau === undefined) {
                        formBien.pointsDeControleNiveaux.splice(
                            formBien.pointsDeControleNiveaux.indexOf(formNiveau),
                            1
                        );
                        // Suppression des commentaires + photos atachées
                        const flattenedComIds = formNiveau.pointsDeControleVolumes
                            .flatMap((v) => v.commentairesId)
                            .concat(
                                formNiveau.pointsDeControleVolumes
                                    .flatMap((vol) => vol.pointsDeControleElements)
                                    .flatMap((el) => el.commentairesId)
                            );
                        if (flattenedComIds.length) {
                            deletedComments = true;
                            this.deleteComments(flattenedComIds, intervention, idDiagnostic);
                        }
                    } else {
                        formNiveau.pointsDeControleVolumes.forEach((formVolume) => {
                            const volume = niveau.volumes.find((vol) => vol.id === formVolume.idVolume);

                            // Si le volume a été supprimé de la description
                            if (volume === undefined) {
                                formNiveau.pointsDeControleVolumes.splice(
                                    formNiveau.pointsDeControleVolumes.indexOf(formVolume),
                                    1
                                );
                                // Suppression des commentaires + photos atachées
                                const flattenedComIds = formVolume.commentairesId.concat(
                                    formVolume.pointsDeControleElements.flatMap((el) => el.commentairesId)
                                );
                                if (flattenedComIds.length) {
                                    deletedComments = true;
                                    this.deleteComments(flattenedComIds, intervention, idDiagnostic);
                                }
                            } else {
                                formVolume.pointsDeControleElements.forEach((formElementAControler) => {
                                    const element = volume.elementsAControler.find(
                                        (el) => el.id === formElementAControler.idElement
                                    );

                                    // Si l'élément à contrôler a été supprimé de la description
                                    if (element === undefined) {
                                        formVolume.pointsDeControleElements.splice(
                                            formVolume.pointsDeControleElements.indexOf(formElementAControler),
                                            1
                                        );
                                        // Suppression des commentaires + photos atachées
                                        if (formElementAControler.commentairesId.length) {
                                            deletedComments = true;
                                            this.deleteComments(
                                                formElementAControler.commentairesId,
                                                intervention,
                                                idDiagnostic
                                            );
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        return deletedComments;
    }

    prepareMenus(
        idDiagnostic: string,
        formPointsDeControle: PointDeControleBien[],
        biensWithFlag: RelationInterventionBien[],
        intervention: Intervention
    ): CustomSelectGroup[] {
        this.initFormPointsDeControleAssainissementTotal(
            idDiagnostic,
            formPointsDeControle,
            biensWithFlag,
            intervention
        );
        let menus: CustomSelectGroup[] = [];
        formPointsDeControle.forEach((b) => {
            b.pointsDeControleNiveaux.forEach((n) => {
                const menu = new CustomSelectGroup();
                menu.label = b.nomBien + ' - ' + n.nom;
                n.pointsDeControleVolumes.forEach((v) => {
                    const m = new ItemCustomSelect();
                    m.id = v.idVolume;
                    m.label = v.nom;
                    menu.items = menu.items.concat(m);
                });
                menus = menus.concat(menu);
            });
        });
        return menus;
    }

    /**
     * Filtre les informations à afficher selon l'onglet de points de controles
     */
    filterTypeCheckpoint(
        filteredPointsDeControles: PointDeControleBien[],
        pointsDeControlesDiag: PointDeControleBien[],
        pieceSansEquipement: boolean,
        typeCheckpoint: TypeCheckpoint,
        intervention: Intervention
    ) {
        if (pieceSansEquipement) {
            this.initFormPiecesSansEquipement(filteredPointsDeControles, pointsDeControlesDiag, intervention);
        } else {
            this.initFormElements(filteredPointsDeControles, pointsDeControlesDiag, typeCheckpoint);
        }
    }

    /**
     * Initialise les formulaires de points de contrôles pour les éléments à contrôler
     */
    private initFormElements(
        formPointsDeControle: PointDeControleBien[],
        pointsDeControlesDiag: PointDeControleBien[],
        typeCheckpoint: TypeCheckpoint,
        allVolume: boolean = true
    ) {
        pointsDeControlesDiag.forEach((bien) => {
            const formBien = new PointDeControleBien();
            formBien.idBien = bien.idBien;
            formBien.nomBien = bien.nomBien;
            bien.pointsDeControleNiveaux.forEach((niveau) => {
                const formEtage = new PointDeControleNiveau();
                formEtage.idNiveau = niveau.idNiveau;
                formEtage.index = niveau.index;
                formEtage.nom = niveau.nom;
                formEtage.storeyId = niveau.storeyId;
                niveau.pointsDeControleVolumes.forEach((volume) => {
                    const formPiece = new PointDeControleVolume();
                    formPiece.idVolume = volume.idVolume;
                    formPiece.nom = volume.nom;
                    formPiece.spaceId = volume.spaceId;
                    formPiece.volumeCache = volume.volumeCache;
                    formPiece.valeursParametres = volume.valeursParametres;
                    formPiece.commentairesId = volume.commentairesId;
                    formPiece.typesCommentaires = volume.typesCommentaires;
                    formPiece.valeursParametres = volume.valeursParametres;
                    volume.pointsDeControleElements.forEach((it) => {
                        if (
                            (typeCheckpoint === TypeCheckpoint.EQUIPEMENT &&
                                (it.valeursParametres[CODE_BIM_PARAM_CATEGORIE] ===
                                    CODE_BIM_PARAM_CATEGORIE_EQUIPEMENT ||
                                    it.valeursParametres[CODE_BIM_PARAM_CATEGORIE] ===
                                        CODE_BIM_PARAM_CATEGORIE_RESEAU)) ||
                            (typeCheckpoint === TypeCheckpoint.AUTRE &&
                                it.valeursParametres[CODE_BIM_PARAM_CATEGORIE] === CODE_BIM_PARAM_CATEGORIE_AUTRE)
                        ) {
                            formPiece.pointsDeControleElements.push(it);
                        }
                    });
                    if (allVolume || formPiece.pointsDeControleElements.length > 0) {
                        formEtage.pointsDeControleVolumes.push(formPiece);
                    }
                });
                formEtage.pointsDeControleVolumes.length && formBien.pointsDeControleNiveaux.push(formEtage);
            });
            formBien.pointsDeControleNiveaux.length && formPointsDeControle.push(formBien);
        });
    }

    /**
     * Initialise les formulaires de points de contrôles pour les pièces sans équipements
     */
    private initFormPiecesSansEquipement(
        formPointsDeControle: PointDeControleBien[],
        formPointsDeControleDiag: PointDeControleBien[],
        intervention: Intervention
    ) {
        formPointsDeControleDiag.forEach((bien) => {
            const formBien = new PointDeControleBien();
            formBien.idBien = bien.idBien;
            formBien.nomBien = bien.nomBien;
            bien.pointsDeControleNiveaux.forEach((etage) => {
                const formEtage = new PointDeControleNiveau();
                formEtage.idNiveau = etage.idNiveau;
                formEtage.index = etage.index;
                formEtage.nom = etage.nom;
                formEtage.storeyId = etage.storeyId;
                etage.pointsDeControleVolumes.forEach((piece) => {
                    this.ruleService.computeJustificationNonVisite(piece, intervention.commentaires);
                    if (
                        (!piece.valeursParametres[PARAM_VOLUME_VISITE] ||
                            (['ko', 'warning'].includes(piece.valeursParametres[PARAM_VOLUME_VISITE]) &&
                                !piece.justifie)) &&
                        !piece.volumeCache
                    ) {
                        formEtage.pointsDeControleVolumes.push(piece);
                    }
                });
                formEtage.pointsDeControleVolumes.length && formBien.pointsDeControleNiveaux.push(formEtage);
            });
            formBien.pointsDeControleNiveaux.length && formPointsDeControle.push(formBien);
        });
    }

    /**
     * Initialise le formulaire d'assainissement en fonction des éléments présents dans la description du bien
     * @param intervention L'intervention courante
     * @param contenuDiagnostic Le diagnostic assainissement courant
     */
    private initAssainissementFormFromDescription(intervention: Intervention, contenuDiagnostic: Assainissement) {
        let recuperateurEauPluie,
            ancienneFossePresent,
            bacAGraissePresent,
            eauPluvialePresent,
            eauUseePresent,
            eauIndustriellePresent = false;
        intervention.relationInterventionBiens.forEach((b) => {
            b.bien.description &&
                b.bien.description.forEach((niveau) => {
                    niveau.volumes.forEach((volume) => {
                        recuperateurEauPluie = this.isElementPresentInDescription(
                            contenuDiagnostic,
                            volume,
                            'recuperateursEauDePluie',
                            ['recuperateur_eau_de_pluie'],
                            false,
                            recuperateurEauPluie
                        );
                        ancienneFossePresent = this.isElementPresentInDescription(
                            contenuDiagnostic,
                            volume,
                            'anciennesFosses',
                            ['ancienne_fosse_septique'],
                            false,
                            ancienneFossePresent
                        );
                        bacAGraissePresent = this.isElementPresentInDescription(
                            contenuDiagnostic,
                            volume,
                            'bacsAGraisse',
                            ['bac_a_graisse'],
                            false,
                            bacAGraissePresent
                        );
                        eauPluvialePresent = this.isElementPresentInDescription(
                            contenuDiagnostic,
                            volume,
                            'eauxPluviales',
                            ['regard_ep'],
                            true,
                            eauPluvialePresent
                        );
                        eauUseePresent = this.isElementPresentInDescription(
                            contenuDiagnostic,
                            volume,
                            'eauxUsees',
                            ['regard_eu'],
                            true,
                            eauUseePresent
                        );
                        eauIndustriellePresent = this.isElementPresentInDescription(
                            contenuDiagnostic,
                            volume,
                            'eauxIndustrielles',
                            ['regard_ei'],
                            true,
                            eauIndustriellePresent
                        );
                    });
                });
        });

        contenuDiagnostic.recuperateursEauDePluie.valeur = recuperateurEauPluie
            ? 'present'
            : contenuDiagnostic.recuperateursEauDePluie.valeur;
        contenuDiagnostic.anciennesFosses.valeur = ancienneFossePresent
            ? 'present'
            : contenuDiagnostic.anciennesFosses.valeur;
        contenuDiagnostic.bacsAGraisse.valeur = bacAGraissePresent ? 'present' : contenuDiagnostic.bacsAGraisse.valeur;
        contenuDiagnostic.eauxPluviales.valeur = eauPluvialePresent
            ? 'present'
            : contenuDiagnostic.eauxPluviales.valeur;
        contenuDiagnostic.eauxUsees.valeur = eauUseePresent ? 'present' : contenuDiagnostic.eauxUsees.valeur;
        contenuDiagnostic.eauxIndustrielles.valeur = eauIndustriellePresent
            ? 'present'
            : contenuDiagnostic.eauxIndustrielles.valeur;

        return contenuDiagnostic;
    }

    /**
     * Préparation du formulaire assainissement + nettoyage
     */
    prepareFormAssainissement(intervention: Intervention, contenuDiagnostic: Assainissement) {
        contenuDiagnostic = this.initAssainissementFormFromDescription(intervention, contenuDiagnostic);
        // la date d'installation doit être convertie en string pour fonctionner avec indexedDB
        if (contenuDiagnostic.ageInstallation.valeur) {
            contenuDiagnostic.ageInstallation.valeur = moment(contenuDiagnostic.ageInstallation.valeur).toISOString();
        }
        // Sections sans sous-éléments
        this.cleanSection(contenuDiagnostic, 'assainissementCollectif', false);
        this.cleanSection(contenuDiagnostic, 'assainissementPublic', false);
        this.cleanSection(contenuDiagnostic, 'alimentationEnEau', false);
        this.cleanSection(contenuDiagnostic, 'reseauPublic', false);
        this.cleanSection(contenuDiagnostic, 'situationDuCollecteur', false);

        // Sections avec sous-éléments
        this.cleanSection(contenuDiagnostic, 'captageEau', true);
        this.cleanSection(contenuDiagnostic, 'anciennesFosses', true);
        this.cleanSection(contenuDiagnostic, 'bacsAGraisse', true);
        this.cleanSection(contenuDiagnostic, 'ventilationsGenerale', true);
        this.cleanSection(contenuDiagnostic, 'recuperateursEauDePluie', true);
        this.cleanSection(contenuDiagnostic, 'eauxUsees', true);
        this.cleanSection(contenuDiagnostic, 'eauxIndustrielles', true);
        this.cleanSection(contenuDiagnostic, 'eauxPluviales', true);

        contenuDiagnostic.pompesDeRelevage.elements =
            contenuDiagnostic.situationDuCollecteur.valeur !== 'contre_bas'
                ? []
                : contenuDiagnostic.pompesDeRelevage.elements;
        contenuDiagnostic.clapetsAntiretour.elements =
            contenuDiagnostic.situationDuCollecteur.valeur !== 'contre_bas'
                ? []
                : contenuDiagnostic.clapetsAntiretour.elements;
    }

    /**
     * Préparation de la prévisualisation du rapport assainissement en fonction des points de contrôles
     */
    prepareDiagnosticReportData(
        diagReportData: AssainissementReportData,
        diagnostic: Diagnostic,
        intervention: Intervention,
        regles: Rule[]
    ) {
        // On récupère les datas du rapport si elles ont déjà été renseignées
        let currentReportData: AssainissementReportData;
        const currentDiagReportData: any = diagnostic.reportDatas.find(
            (reportData) => reportData.typeReport === TypeReport.REPORT
        );
        if (currentDiagReportData && currentDiagReportData.reportData) {
            currentReportData = (
                JSON.parse(currentDiagReportData.reportData) as InterventionReportData
            ).diagnostics.find((d) => d.id === diagnostic.id) as AssainissementReportData;
        }
        const commentaires = intervention.commentaires.map((com) => {
            const newCom = Object.assign({}, com);
            if (newCom.imageId) {
                newCom.imageId = URL_MS_REPORT_FILE + com.imageId;
            }
            return newCom;
        });
        diagReportData.conformiteGlobale =
            diagnostic.conformiteDefinie &&
            diagnostic.conformiteDefinie.value != undefined &&
            diagnostic.conformiteDefinie.value != null
                ? !!diagnostic.conformiteDefinie.value
                : true;
        const contenuDiagnostic = diagnostic.contenuDiagnostic as Assainissement;

        // Bloc visite
        const subBlockVolumesNonVisites = new SubBlockAssainissement('VOLUMES_NON_VISITES', []);
        const subBlockVolumesVisites = new SubBlockAssainissement('VOLUMES_VISITES', []);
        // Bloc visibilité
        const subBlockNonVisibles = new SubBlockAssainissement('NON_VISIBLES', []);
        // Bloc grille de contrôle
        const subBlockRaccordementEU = new SubBlockAssainissement('RACCORDEMENT_EU', []);
        const subBlockEquipementsEU = new SubBlockAssainissement('EQUIPEMENTS_EU', []);
        const subBlockRaccordementEP = new SubBlockAssainissement('RACCORDEMENT_EP', []);
        const subBlockEquipementsEP = new SubBlockAssainissement('EQUIPEMENTS_EP', []);
        // Bloc conclusion
        const subBlockConclusionRaccordementsEU = new SubBlockAssainissement('CONCLUSIONS_RACCORDEMENTS_EU', []);
        const subBlockConclusionEquipementsEU = new SubBlockAssainissement('CONCLUSIONS_EQUIPEMENTS_EU', []);
        const subBlockConclusionRaccordementsEP = new SubBlockAssainissement('CONCLUSIONS_RACCORDEMENTS_EP', []);
        const subBlockConclusionEquipementsEP = new SubBlockAssainissement('CONCLUSIONS_EQUIPEMENTS_EP', []);
        // Bloc constatations diverses
        const subBlockConstatationsSpecifiques = new SubBlockAssainissement('CONSTATATIONS_SPECIFIQUES', []);
        // BIENS

        diagnostic.pointsDeControleBiens.forEach((bien) => {
            // Visitabilité
            const rowBienNonVisites = new RowBienAssainissement(bien.idBien, bien.nomBien);
            const rowBienVisites = new RowBienAssainissement(bien.idBien, bien.nomBien);
            // Visibilité
            const rowBienNonVisibles = new RowBienAssainissement(bien.idBien, bien.nomBien);
            // Grille de contrôle
            const rowBienRaccordementsEU = new RowBienAssainissement(bien.idBien, bien.nomBien);
            const rowBienEquipementsEU = new RowBienAssainissement(bien.idBien, bien.nomBien);
            const rowBienRaccordementsEP = new RowBienAssainissement(bien.idBien, bien.nomBien);
            const rowBienEquipementsEP = new RowBienAssainissement(bien.idBien, bien.nomBien);
            // Conclusion
            const rowBienConclusionRaccordementsEU = new RowBienAssainissement(bien.idBien, bien.nomBien);
            const rowBienConclusionEquipementsEU = new RowBienAssainissement(bien.idBien, bien.nomBien);
            const rowBienConclusionRaccordementsEP = new RowBienAssainissement(bien.idBien, bien.nomBien);
            const rowBienConclusionEquipementsEP = new RowBienAssainissement(bien.idBien, bien.nomBien);
            // Constatations spécifiques
            const rowBienConstatationsSpecifiques = new RowBienAssainissement(bien.idBien, bien.nomBien);

            // NIVEAUX
            bien.pointsDeControleNiveaux.forEach((niveau) => {
                // Visitabilité
                const rowNiveauNonVisites = new RowNiveauAssainissement(niveau.idNiveau, niveau.nom, niveau.storeyId);
                const rowNiveauVisites = new RowNiveauAssainissement(niveau.idNiveau, niveau.nom, niveau.storeyId);
                // Visibilité
                const rowNiveauNonVisibles = new RowNiveauAssainissement(niveau.idNiveau, niveau.nom, niveau.storeyId);
                // Grille de contrôle
                const rowNiveauRaccordementsEU = new RowNiveauAssainissement(
                    niveau.idNiveau,
                    niveau.nom,
                    niveau.storeyId
                );
                const rowNiveauEquipementsEU = new RowNiveauAssainissement(
                    niveau.idNiveau,
                    niveau.nom,
                    niveau.storeyId
                );
                const rowNiveauRaccordementsEP = new RowNiveauAssainissement(
                    niveau.idNiveau,
                    niveau.nom,
                    niveau.storeyId
                );
                const rowNiveauEquipementsEP = new RowNiveauAssainissement(
                    niveau.idNiveau,
                    niveau.nom,
                    niveau.storeyId
                );
                // Conclusion
                const rowNiveauConclusionRaccordementsEU = new RowNiveauAssainissement(
                    niveau.idNiveau,
                    niveau.nom,
                    niveau.storeyId
                );
                const rowNiveauConclusionEquipementsEU = new RowNiveauAssainissement(
                    niveau.idNiveau,
                    niveau.nom,
                    niveau.storeyId
                );
                const rowNiveauConclusionRaccordementsEP = new RowNiveauAssainissement(
                    niveau.idNiveau,
                    niveau.nom,
                    niveau.storeyId
                );
                const rowNiveauConclusionEquipementsEP = new RowNiveauAssainissement(
                    niveau.idNiveau,
                    niveau.nom,
                    niveau.storeyId
                );
                // Constatations spécifiques
                const rowNiveauConstatationsSpecifiques = new RowNiveauAssainissement(
                    niveau.idNiveau,
                    niveau.nom,
                    niveau.storeyId
                );

                // VOLUMES
                niveau.pointsDeControleVolumes.forEach((volume) => {
                    const rowVolumeNonVisite = currentReportData
                        ? this.prepareVolume(volume, currentReportData.volumesNonVisites, commentaires)
                        : this.prepareVolume(volume, null, commentaires);
                    const rowVolumeVisite = currentReportData
                        ? this.prepareVolume(volume, currentReportData.volumesVisites, commentaires)
                        : this.prepareVolume(volume, null, commentaires);
                    const rowVolumeNonVisible = currentReportData
                        ? this.prepareVolume(volume, currentReportData.nonVisibles, commentaires)
                        : this.prepareVolume(volume, null, commentaires);
                    // Grille de contrôle
                    const rowVolumeRaccordementsEU = currentReportData
                        ? this.prepareVolume(volume, currentReportData.raccordementEU, commentaires)
                        : this.prepareVolume(volume, null, commentaires);
                    const rowVolumeEquipementsEU = currentReportData
                        ? this.prepareVolume(volume, currentReportData.equipementsEU, commentaires)
                        : this.prepareVolume(volume, null, commentaires);
                    const rowVolumeRaccordementsEP = currentReportData
                        ? this.prepareVolume(volume, currentReportData.raccordementEP, commentaires)
                        : this.prepareVolume(volume, null, commentaires);
                    const rowVolumeEquipementsEP = currentReportData
                        ? this.prepareVolume(volume, currentReportData.equipementsEP, commentaires)
                        : this.prepareVolume(volume, null, commentaires);
                    // Conclusion
                    const rowVolumeConclusionRaccordementsEU = currentReportData
                        ? this.prepareVolume(
                              volume,

                              currentReportData.conclusionRaccordementsEU,
                              commentaires
                          )
                        : this.prepareVolume(volume, null, commentaires);
                    const rowVolumeConclusionEquipementsEU = currentReportData
                        ? this.prepareVolume(
                              volume,

                              currentReportData.conclusionEquipementsEU,
                              commentaires
                          )
                        : this.prepareVolume(volume, null, commentaires);
                    const rowVolumeConclusionRaccordementsEP = currentReportData
                        ? this.prepareVolume(
                              volume,

                              currentReportData.conclusionRaccordementsEP,
                              commentaires
                          )
                        : this.prepareVolume(volume, null, commentaires);
                    const rowVolumeConclusionEquipementsEP = currentReportData
                        ? this.prepareVolume(
                              volume,

                              currentReportData.conclusionEquipementsEP,
                              commentaires
                          )
                        : this.prepareVolume(volume, null, commentaires);
                    // Constatations spécifiques
                    const rowVolumeConstatationsSpecifiques = currentReportData
                        ? this.prepareVolume(
                              volume,

                              currentReportData.constatationsSpecifiques,
                              commentaires
                          )
                        : this.prepareVolume(volume, null, commentaires);

                    // Visitabilité
                    if (
                        volume.valeursParametres['visite'] &&
                        ['ko', 'warning'].includes(volume.valeursParametres['visite'])
                    ) {
                        // const rowVolumeNonVisite = { ...rowVolume };
                        rowVolumeNonVisite.commentaires = rowVolumeNonVisite.commentaires.filter(
                            (it) => it.type === 'JUSTIFICATION_NON_VISITE'
                        );
                        rowNiveauNonVisites.volumes.push(rowVolumeNonVisite);
                    }
                    if (volume.valeursParametres['visite'] && volume.valeursParametres['visite'] === 'ok') {
                        // const rowVolumeVisite = { ...rowVolume };
                        rowNiveauVisites.volumes.push(rowVolumeVisite);
                    }

                    // EQUIPEMENTS
                    if (volume.pointsDeControleElements.length) {
                        volume.pointsDeControleElements.forEach((el) => {
                            const rowElement = new RowElementAssainissement();
                            // Récupération de la liste des défauts de l'élément à contrôler
                            const defauts = this.populateDefaultsList(
                                rowElement,
                                el,
                                contenuDiagnostic,
                                regles,
                                diagReportData,
                                diagnostic
                            );
                            // Préparation du row element
                            this.prepareRowElement(rowElement, el, commentaires, defauts);

                            // Grille de contrôle + conclusion réseau EU
                            if (
                                [
                                    CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EM,
                                    CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EV,
                                    CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EU,
                                ].includes(el.valeursParametres[CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION])
                            ) {
                                // Raccordement EU
                                if (
                                    el.valeursParametres[CODE_BIM_PARAM_CATEGORIE] === CODE_BIM_PARAM_CATEGORIE_RESEAU
                                ) {
                                    rowVolumeRaccordementsEU.equipements.push(rowElement);
                                    if (rowElement.conformite && rowElement.conformite.valeur === 'Non conforme') {
                                        const rowElementConclusionRaccordementsEU = { ...rowElement };
                                        rowElementConclusionRaccordementsEU.commentaires =
                                            this.getJustificationNonConforme(el, commentaires);
                                        rowVolumeConclusionRaccordementsEU.equipements.push(
                                            rowElementConclusionRaccordementsEU
                                        );
                                    }
                                    // Equipements EU
                                } else if (
                                    el.valeursParametres[CODE_BIM_PARAM_CATEGORIE] ===
                                    CODE_BIM_PARAM_CATEGORIE_EQUIPEMENT
                                ) {
                                    if (rowElement.conformite && rowElement.conformite.valeur === 'Non conforme') {
                                        const rowElementConclusionEquipementsEU = { ...rowElement };
                                        rowElementConclusionEquipementsEU.commentaires =
                                            this.getJustificationNonConforme(el, commentaires);
                                        rowVolumeConclusionEquipementsEU.equipements.push(
                                            rowElementConclusionEquipementsEU
                                        );
                                    }
                                    rowVolumeEquipementsEU.equipements.push(rowElement);
                                }
                            }
                            // Grille de contrôle + conclusion réseau EP
                            if (
                                el.valeursParametres[CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION] ===
                                CODE_BIM_PARAM_TYPE_ELEMENT_RESEAU_EVACUATION_VALEUR_EP
                            ) {
                                // Raccordement EP
                                if (
                                    el.valeursParametres[CODE_BIM_PARAM_CATEGORIE] === CODE_BIM_PARAM_CATEGORIE_RESEAU
                                ) {
                                    if (rowElement.conformite && rowElement.conformite.valeur === 'Non conforme') {
                                        const rowElementConclusionRaccordementsEP = { ...rowElement };
                                        rowElementConclusionRaccordementsEP.commentaires =
                                            this.getJustificationNonConforme(el, commentaires);
                                        rowVolumeConclusionRaccordementsEP.equipements.push(
                                            rowElementConclusionRaccordementsEP
                                        );
                                    }
                                    rowVolumeRaccordementsEP.equipements.push(rowElement);
                                    // Equipements EP
                                } else if (
                                    el.valeursParametres[CODE_BIM_PARAM_CATEGORIE] ===
                                    CODE_BIM_PARAM_CATEGORIE_EQUIPEMENT
                                ) {
                                    if (rowElement.conformite && rowElement.conformite.valeur === 'Non conforme') {
                                        const rowElementConclusionEquipementsEP = { ...rowElement };
                                        rowElementConclusionEquipementsEP.commentaires =
                                            this.getJustificationNonConforme(el, commentaires);
                                        rowVolumeConclusionEquipementsEP.equipements.push(
                                            rowElementConclusionEquipementsEP
                                        );
                                    }
                                    rowVolumeEquipementsEP.equipements.push(rowElement);
                                }
                            }

                            // Constatations spécifiques
                            if (
                                commentaires
                                    .filter((c) => {
                                        return el.commentairesId.indexOf(c.id) !== -1;
                                    })
                                    .filter((it) => it.type === 'CONSTATATION_DIVERSE').length
                            ) {
                                const rowElementConstatationsSpecifiques = { ...rowElement };
                                rowElementConstatationsSpecifiques.commentaires = commentaires
                                    .filter((c) => {
                                        return el.commentairesId.indexOf(c.id) !== -1;
                                    })
                                    .filter((it) => it.type === 'CONSTATATION_DIVERSE');
                                rowVolumeConstatationsSpecifiques.equipements.push(rowElementConstatationsSpecifiques);
                            }

                            // Visibilité
                            if (rowElement.nonVisibles.length) {
                                const rowElementNonVisible = { ...rowElement };
                                rowElementNonVisible.commentaires = commentaires
                                    .filter((c) => {
                                        return el.commentairesId.indexOf(c.id) !== -1;
                                    })
                                    .filter((it) => it.type === 'JUSTIFICATION_RESERVE');
                                rowVolumeNonVisible.equipements.push(rowElementNonVisible);
                            }
                        });
                        // Visibilité
                        rowVolumeNonVisible.equipements.length &&
                            rowNiveauNonVisibles.volumes.push(rowVolumeNonVisible);
                        // Grille de contrôle
                        rowVolumeRaccordementsEU.equipements.length &&
                            rowNiveauRaccordementsEU.volumes.push(rowVolumeRaccordementsEU);
                        rowVolumeEquipementsEU.equipements.length &&
                            rowNiveauEquipementsEU.volumes.push(rowVolumeEquipementsEU);
                        rowVolumeRaccordementsEP.equipements.length &&
                            rowNiveauRaccordementsEP.volumes.push(rowVolumeRaccordementsEP);
                        rowVolumeEquipementsEP.equipements.length &&
                            rowNiveauEquipementsEP.volumes.push(rowVolumeEquipementsEP);
                        // Conclusion
                        rowVolumeConclusionRaccordementsEU.equipements.length &&
                            rowNiveauConclusionRaccordementsEU.volumes.push(rowVolumeConclusionRaccordementsEU);
                        rowVolumeConclusionEquipementsEU.equipements.length &&
                            rowNiveauConclusionEquipementsEU.volumes.push(rowVolumeConclusionEquipementsEU);
                        rowVolumeConclusionRaccordementsEP.equipements.length &&
                            rowNiveauConclusionRaccordementsEP.volumes.push(rowVolumeConclusionRaccordementsEP);
                        rowVolumeConclusionEquipementsEP.equipements.length &&
                            rowNiveauConclusionEquipementsEP.volumes.push(rowVolumeConclusionEquipementsEP);
                        // Constatations spécifiques
                        rowVolumeConstatationsSpecifiques.equipements.length &&
                            rowNiveauConstatationsSpecifiques.volumes.push(rowVolumeConstatationsSpecifiques);
                    }
                });
                // Visitabilité
                rowNiveauNonVisites.volumes.length && rowBienNonVisites.niveaux.push(rowNiveauNonVisites);
                rowNiveauVisites.volumes.length && rowBienVisites.niveaux.push(rowNiveauVisites);
                // Visibilité
                rowNiveauNonVisibles.volumes.length && rowBienNonVisibles.niveaux.push(rowNiveauNonVisibles);
                // Grille de contrôle
                rowNiveauRaccordementsEU.volumes.length &&
                    rowBienRaccordementsEU.niveaux.push(rowNiveauRaccordementsEU);
                rowNiveauEquipementsEU.volumes.length && rowBienEquipementsEU.niveaux.push(rowNiveauEquipementsEU);
                rowNiveauRaccordementsEP.volumes.length &&
                    rowBienRaccordementsEP.niveaux.push(rowNiveauRaccordementsEP);
                rowNiveauEquipementsEP.volumes.length && rowBienEquipementsEP.niveaux.push(rowNiveauEquipementsEP);
                // Conclusion
                rowNiveauConclusionRaccordementsEU.volumes.length &&
                    rowBienConclusionRaccordementsEU.niveaux.push(rowNiveauConclusionRaccordementsEU);
                rowNiveauConclusionEquipementsEU.volumes.length &&
                    rowBienConclusionEquipementsEU.niveaux.push(rowNiveauConclusionEquipementsEU);
                rowNiveauConclusionRaccordementsEP.volumes.length &&
                    rowBienConclusionRaccordementsEP.niveaux.push(rowNiveauConclusionRaccordementsEP);
                rowNiveauConclusionEquipementsEP.volumes.length &&
                    rowBienConclusionEquipementsEP.niveaux.push(rowNiveauConclusionEquipementsEP);
                // Constatations spécifiques
                rowNiveauConstatationsSpecifiques.volumes.length &&
                    rowBienConstatationsSpecifiques.niveaux.push(rowNiveauConstatationsSpecifiques);
            });

            // Visitabilité
            subBlockVolumesNonVisites.biens.push(rowBienNonVisites);
            subBlockVolumesVisites.biens.push(rowBienVisites);
            // Visibilité
            subBlockNonVisibles.biens.push(rowBienNonVisibles);
            // Grille de contrôle
            subBlockRaccordementEU.biens.push(rowBienRaccordementsEU);
            subBlockEquipementsEU.biens.push(rowBienEquipementsEU);
            subBlockRaccordementEP.biens.push(rowBienRaccordementsEP);
            subBlockEquipementsEP.biens.push(rowBienEquipementsEP);
            // Conclusion
            subBlockConclusionRaccordementsEU.biens.push(rowBienConclusionRaccordementsEU);
            subBlockConclusionEquipementsEU.biens.push(rowBienConclusionEquipementsEU);
            subBlockConclusionRaccordementsEP.biens.push(rowBienConclusionRaccordementsEP);
            subBlockConclusionEquipementsEP.biens.push(rowBienConclusionEquipementsEP);
            // Constatations spécifiques
            subBlockConstatationsSpecifiques.biens.push(rowBienConstatationsSpecifiques);
        });

        // Visitabilité
        diagReportData.volumesNonVisites = subBlockVolumesNonVisites;
        diagReportData.volumesVisites = subBlockVolumesVisites;
        // Visibilité
        diagReportData.nonVisibles = subBlockNonVisibles;
        // Grille de contrôle
        diagReportData.raccordementEU = subBlockRaccordementEU;
        diagReportData.equipementsEU = subBlockEquipementsEU;
        diagReportData.raccordementEP = subBlockRaccordementEP;
        diagReportData.equipementsEP = subBlockEquipementsEP;
        // Conclusion
        diagReportData.conclusionRaccordementsEU = subBlockConclusionRaccordementsEU;
        diagReportData.conclusionEquipementsEU = subBlockConclusionEquipementsEU;
        diagReportData.conclusionRaccordementsEP = subBlockConclusionRaccordementsEP;
        diagReportData.conclusionEquipementsEP = subBlockConclusionEquipementsEP;
        // Constatations spécifiques
        diagReportData.constatationsSpecifiques = subBlockConstatationsSpecifiques;

        // Nombres de regards EU et EP
        this.getNombresRegards(diagReportData, contenuDiagnostic);

        // Liste des Documents
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);
        diagReportData.listDocuments = diagReportData.documentsData.filter((doc) => doc.afficherDansListeDocuments);
        diagReportData.annexes = diagReportData.documentsData
            .filter((doc) => doc.afficherDansRapport)
            .map((doc) => ({
                id: doc.nom,
                filename: URL_MS_REPORT_FILE + doc.idFichier,
                type: 'pdf',
            }));
    }

    private getJustificationNonConforme(element: PointDeControleElement, commentaires: Commentaire[]): Commentaire[] {
        return commentaires
            .filter((c) => {
                return element.commentairesId.indexOf(c.id) !== -1;
            })
            .filter((it) => it.type === 'JUSTIFICATION_NON_CONFORMITE');
    }

    /**
     * En fonction de la description, préremplie l'installation du diagnostic
     * @param contenuDiagnostic Le diagnostic en cours
     * @param volume Le volume a vérifier
     * @param key La section du diagnostic à compléter
     * @param codesBim Tableau de codes bim correspondant aux équipements
     * @param displayNombre Faut-il préciser le nombre d'éléments correspondant
     * @param present Indique si au moins un élément a été trouvé
     */
    private isElementPresentInDescription(
        contenuDiagnostic: Assainissement,
        volume: Volume,
        key: string,
        codesBim: string[],
        displayNombre: boolean,
        present: boolean
    ): boolean {
        if (
            contenuDiagnostic[key].valeur === null ||
            (contenuDiagnostic[key].valeur && contenuDiagnostic[key].valeur.length === 0)
        ) {
            const filtered = volume.elementsAControler.filter((el) => codesBim.includes(el.codeBim));
            if (filtered.length) {
                present = true;
                if (!contenuDiagnostic[key].elements.map((e) => e.situation).includes(volume.id)) {
                    const el = new ItemEtapeDiagnosticGenerique();
                    el.situation = volume.id;
                    if (displayNombre) {
                        el.nombre = filtered.length;
                    }
                    contenuDiagnostic[key].elements.push(el);
                }
            }
        }
        return present;
    }

    /**
     * Nettoie une section du diagnostic assainissement, avant enregistrement
     * @param contenuDiagnostic Le diagnostic assainissement
     * @param key La section à nettoyer (string)
     * @param displayElement Boolean, si la section possède des sous-éléments (situation, ...)
     */
    private cleanSection(contenuDiagnostic: Assainissement, key: string, displayElement: boolean) {
        if (contenuDiagnostic[key].valeur && contenuDiagnostic[key].valeur.length === 0) {
            contenuDiagnostic[key].valeur = null;
        }
        if (displayElement) {
            contenuDiagnostic[key].elements =
                contenuDiagnostic[key].valeur === 'present' ? contenuDiagnostic[key].elements : [];
        }
    }

    /**
     * Récupère le nombre de regards Eau Usée et Eau Pluviale
     * @param diagReportData les data du rapport de type assainissement
     * @param contenuDiagnostic le diagnostic assainissement courant
     */
    private getNombresRegards(diagReportData: AssainissementReportData, contenuDiagnostic: Assainissement) {
        let nbRegardsEU = 0;
        let nbRegardsEP = 0;

        nbRegardsEU = this.calculateNombreRegards(contenuDiagnostic, 'eauxUsees', nbRegardsEU);
        diagReportData.regardsEU = nbRegardsEU.toString();

        nbRegardsEP = this.calculateNombreRegards(contenuDiagnostic, 'eauxPluviales', nbRegardsEP);
        diagReportData.regardsEP = nbRegardsEP.toString();
    }

    private calculateNombreRegards(diagnostic: Assainissement, key: string, nombreRegards: number) {
        if (diagnostic[key].valeur && diagnostic[key].valeur === 'present') {
            if (diagnostic[key].elements.length) {
                diagnostic[key].elements.forEach((it) => {
                    nombreRegards = it.nombre ? (nombreRegards += it.nombre) : nombreRegards;
                });
            }
        }
        return nombreRegards;
    }

    /**
     * Initialisation d'un rowVolume
     * Si le volume a déjà été renseigné précedemment, on garde la liste des screenshots associés
     */
    private prepareVolume(volume: PointDeControleVolume, bloc: SubBlockAssainissement, commentaires: Commentaire[]) {
        let volumesAlreadyPresents: RowVolumeAssainissement[] = [];
        // Vérification des volumes déjà présents dans une version de rapport précédente
        if (bloc && bloc.biens.length) {
            volumesAlreadyPresents = bloc.biens.flatMap((b) => b.niveaux.flatMap((n) => n.volumes));
        }
        const newRowVolume = volumesAlreadyPresents.find((v) => v.volumeId === volume.idVolume)
            ? volumesAlreadyPresents.find((v) => v.volumeId === volume.idVolume)
            : new RowVolumeAssainissement(volume.nom, volume.idVolume);
        // Dans tous les cas, on réinit la liste des équipements pour avoir la liste la plus fraiche possible
        newRowVolume.equipements = [];

        newRowVolume.visite = new ItemElementAssainissement(
            volume.valeursParametres['visite']
                ? this.translateValue(PARAM_VOLUME_VISITE, volume.valeursParametres['visite'])
                : null,
            null
        );
        newRowVolume.commentaires = commentaires
            .filter((c) => {
                return volume.commentairesId.indexOf(c.id) !== -1;
            })
            .filter((it) => it.type !== 'NOTE_PERSONNELLE');
        return newRowVolume;
    }

    /**
     * Execution des règles et récupération de la liste de défauts d'un élément à contrôler, pour un rowElement
     */
    private populateDefaultsList(
        rowElement: RowElementAssainissement,
        pdc: PointDeControleElement,
        contenuDiagnostic: Assainissement,
        regles: Rule[],
        diagReportData: AssainissementReportData,
        diagnostic: Diagnostic
    ): string[] {
        // Exécution des règles
        const results = this.ruleService.computeControlElement(
            pdc,
            contenuDiagnostic,
            regles,
            new RuleOptions(ComputeModeEnum.FIRST_VALID, true, true)
        );

        const result = results.find((res) => res.ruleName === 'conformite');
        let defauts = [];
        if (result) {
            if (result.results.some((res) => res.type === 'non-conforme' && res.valid === true)) {
                if (
                    !diagnostic.conformiteDefinie ||
                    diagnostic.conformiteDefinie.value === undefined ||
                    diagnostic.conformiteDefinie.value === null
                ) {
                    diagReportData.conformiteGlobale = false;
                }

                rowElement.conformite = new ItemElementAssainissement('Non conforme', 'non-conforme-global');
                defauts = result.results
                    .find((res) => res.type === 'non-conforme')
                    .params.get('verifiedBy')
                    .map((op) => (op.name ? op.name : 'Inconnu'));
            }
            if (
                result.results.some(
                    (res) =>
                        (res.type === 'conforme' && res.valid === true) ||
                        (res.type === 'a-justifier' && res.valid === true)
                )
            ) {
                rowElement.conformite = new ItemElementAssainissement('Conforme', 'conforme-global');
            }
        }
        return defauts;
    }

    /**
     * Construction d'un rowElement en fonction des réponses d'un pointDeControleElement et de la liste de défauts
     */
    private prepareRowElement(
        rowElement: RowElementAssainissement,
        pdc: PointDeControleElement,
        commentaires: Commentaire[],
        defauts: string[]
    ) {
        rowElement.element = pdc.nom;
        rowElement.commentaires = commentaires
            .filter((c) => {
                return pdc.commentairesId.indexOf(c.id) !== -1;
            })
            .filter((it) => it.type !== 'NOTE_PERSONNELLE');
        rowElement.materiaux = this.prepareItemElement(pdc, PARAM_ELEMENT_MATERIAUX, 'Materiaux', defauts);
        rowElement.odeur = this.prepareItemElement(pdc, PARAM_ELEMENT_ODEUR, 'Odeur', defauts);
        rowElement.rejet = this.prepareItemElement(pdc, PARAM_ELEMENT_REJET, 'Rejet', defauts);
        rowElement.etat = this.prepareItemElement(pdc, PARAM_ELEMENT_ETAT, 'Etat', defauts);
        rowElement.ecoulement = this.prepareItemElement(pdc, PARAM_ELEMENT_ECOULEMENT, 'Ecoulement', defauts);
        rowElement.controle = this.prepareItemElement(pdc, PARAM_ELEMENT_CONTROLE, 'Controle', defauts);

        if (pdc.valeursParametres[PARAM_ELEMENT_ETAT] && pdc.valeursParametres[PARAM_ELEMENT_ETAT] === 'non_visible') {
            rowElement.nonVisibles.push('État');
        }
        if (
            pdc.valeursParametres[PARAM_ELEMENT_ECOULEMENT] &&
            pdc.valeursParametres[PARAM_ELEMENT_ECOULEMENT] === 'non_visible'
        ) {
            rowElement.nonVisibles.push('Écoulement');
        }
    }

    private prepareItemElement(
        pdc: PointDeControleElement,
        ppty1: string,
        ppty2: string,
        defauts: string[]
    ): ItemElementAssainissement {
        return new ItemElementAssainissement(
            pdc.valeursParametres[ppty1] ? this.translateValue(ppty1, pdc.valeursParametres[ppty1]) : null,
            this.isPropertyNotConforme(defauts, ppty2)
        );
    }

    private isPropertyNotConforme(defauts: string[], property: string): string {
        if (defauts.length) {
            return defauts.includes(property) ? 'non-conforme' : null;
        }
        return null;
    }

    private translateValue(ppty: string, value: string): string {
        let valueToDisplay = value;
        if (NAMES_MAPPER[ppty]) {
            const finded = NAMES_MAPPER[ppty].find((it) => it.valeur === value);
            if (finded) {
                valueToDisplay = finded.afficher;
            }
        }
        return valueToDisplay;
    }

    private deleteComments(ids: string[], intervention: Intervention, idDiagnostic: string) {
        ids.forEach((id) => {
            const com = intervention.commentaires.find((c) => c.id === id);
            if (com && com.imageId) {
                // Suppression de l'image
                this.cnSpinnerService
                    .withSpinner(
                        this.interventionFileService.deleteInterventionFileAndFile(
                            intervention.id,
                            idDiagnostic,
                            com.id,
                            TypeReferenceFichier.PHOTO_COMMENTAIRE,
                            com.imageId
                        )
                    )
                    .subscribe();
            }
            intervention.commentaires.splice(intervention.commentaires.indexOf(com), 1);
        });
    }
}

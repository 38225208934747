import { cn_svg_map, cn_svg_tool_marker_edition } from '@acenv/cnmap-editor';
import { MarkerHandler } from '../editor-map/handler/marker-handler';
import { CndiagMarkerZone } from '../model/cndiag-marker-zone.model';

export class CndiagMarkerZoneToolEdition extends cn_svg_tool_marker_edition {
    // Si on est en read only, on ne doit pas pouvoir sélectionner un marker pour l'éditer
    readonlyMode = false;

    /**
     * @param svgMap
     * @param readonlyMode
     */
    constructor(svgMap: cn_svg_map, readonlyMode: boolean) {
        super(svgMap);
        this._marker_class = CndiagMarkerZone;
        this.readonlyMode = readonlyMode;
    }

    // on_selection_change() {
    //     this._handler = null;
    //     // *** We create a handler only if single selection
    //     const sel = this._controller.get_selection();
    //     if (sel.length != 1 || sel[0].constructor != this._marker_class || this.readonlyMode) {
    //         return false;
    //     }
    //     // *** build edition handler */
    //     this._handler = this._build_handler(sel as CndiagMarkerZone[]);
    //     return true;
    // }

    /**
     * Builds a new marker handler.
     * @param {CndiagMarkerZone} marker
     * @returns {MarkerHandler}
     */
    _build_handler(markers: CndiagMarkerZone[]) {
        const markerHandler = new MarkerHandler(markers[0], this._map);
        // Prolonge l'évènement au composant parent
        markerHandler.on('change', () => {
            this.call('change', markers);
        });
        this.call('zone_selected', markers);
        return markerHandler;
    }
}

<div class="container white-background h-100 p-0">
    <div class="d-flex white-background h-100 align-items-center flex-column">
        <div class="w-100 d-flex align-items-center background-filtre-prestation border-bottom-grey">
            <span class="px-3">Prestations</span>
            <app-custom-select
                class="prestation-filtre-select"
                [matFormFieldClass]="'w-100'"
                [choices]="allTypesPrestation"
                [selectedValue]="filterPrestationValue"
                (valueChangeFromUser)="onChangeFilter($event)"
                [pathValueDisplay]="displayTypePrestation"
                [filter]="false"
                [addline]="false"
                [sorted]="false"
                [emptyOptionActivate]="true"
            ></app-custom-select>
            <div class="flex-grow-1"></div>
            <button mat-button *ngIf="isOnline && !readonlyMode" (click)="majDocuments()">
                <mat-icon>sync_all</mat-icon>
                Mettre à jour les documents
            </button>
        </div>

        <ng-container *ngIf="!documentsByCategorie || documentsByCategorie.size === 0">
            <span class="px-3">Aucun document défini</span>
        </ng-container>
        <div class="w-100 d-flex flex-column align-items-center h-100 overflow-auto">
            <ng-container
                *ngFor="
                    let categorieWithDocument of documentsByCategorie | keyvalue;
                    trackBy: trackByCategorieWithDocument
                "
            >
                <div class="w-100 d-flex flex-column pt-3">
                    <mat-accordion class="border-0 w-100" [togglePosition]="'before'">
                        <mat-expansion-panel [expanded]="true">
                            <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                                <span>{{ categorieWithDocument.key }} ({{ categorieWithDocument.value.length }})</span>
                            </mat-expansion-panel-header>
                            <ng-container
                                *ngFor="
                                    let document of categorieWithDocument.value;
                                    trackBy: trackByDocument;
                                    let isLastDocument = last
                                "
                            >
                                <div
                                    class="d-flex align-items-center justify-content-between w-100"
                                    [ngClass]="[document === documentSelected ? 'line-selected' : '']"
                                    (click)="onClickDocument(document)"
                                >
                                    <div class="d-flex padding-left-70">
                                        <!------ Si on est dans un diagnostic, on affiche l'icône de validation du document ------>
                                        <div *ngIf="diagnostic">
                                            <mat-icon
                                                *ngIf="
                                                    checkConformiteDocumentByPrestationPipe.transform(
                                                        document.typeDocument.typeDocumentCheckpoint,
                                                        currentReferencePrestation
                                                    )
                                                "
                                                class="success"
                                                >done</mat-icon
                                            >
                                            <mat-icon
                                                *ngIf="
                                                    !checkConformiteDocumentByPrestationPipe.transform(
                                                        document.typeDocument.typeDocumentCheckpoint,
                                                        currentReferencePrestation
                                                    )
                                                "
                                                class="warn"
                                                >block</mat-icon
                                            >
                                        </div>
                                        <!---- Nom du document ----->
                                        <span class="pl-3"
                                            >{{ document.nom
                                            }}<span *ngIf="document.requiredForIntervention" class="required"></span
                                        ></span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <!------ Si on n'est pas dans un diagnostic, on affiche les diagnostics associé au document ------>
                                        <div *ngIf="!diagnostic" class="pr-2">
                                            <app-documents-prestation-chips
                                                [nbPrestationsDisplayed]="1"
                                                [typesPrestation]="document.typePrestations"
                                            ></app-documents-prestation-chips>
                                        </div>
                                        <button
                                            class="size-div-eye"
                                            mat-icon-button
                                            [ngClass]="document === documentSelected ? 'white' : 'grey-5'"
                                            (click)="document.idFichier ? onClickOpenDocument(document) : ''"
                                        >
                                            <mat-icon *ngIf="document.idFichier">visibility</mat-icon>
                                        </button>
                                        <app-notes-buttons
                                            class="notes-buttons-yellow"
                                            [from]="document"
                                            [colorIcon]="document === documentSelected ? 'white' : 'grey-5'"
                                        >
                                        </app-notes-buttons>
                                        <button
                                            mat-icon-button
                                            class="warn"
                                            [disabled]="readonlyMode || document.requiredForIntervention"
                                            (click)="onClickDeleteDocument(document)"
                                        >
                                            <lib-icon>delete</lib-icon>
                                        </button>
                                    </div>
                                </div>

                                <mat-divider *ngIf="!isLastDocument" class="w-100"></mat-divider>
                            </ng-container>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-container>
            <button *ngIf="!readonlyMode" mat-raised-button class="bg-btn-grey-1 my-3" (click)="onClickAddDocument()">
                <span>{{ 'Nouveau document' | uppercase }}</span>
            </button>
        </div>
    </div>
</div>

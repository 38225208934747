import { Component, OnInit } from '@angular/core';
import { URL_MODE_HORS_LIGNE } from '../../../shared/constants/url.constants';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthenticationStore } from 'src/app/commons-lib';
import { SynchronizationService } from '../../../services/synchronization.service';

@Component({
    selector: 'app-widget-hors-ligne',
    templateUrl: './widget-hors-ligne.component.html',
    styleUrls: ['./widget-hors-ligne.component.scss'],
})
export class WidgetHorsLigneComponent implements OnInit {
    isOperator$ = this.authenticationStore
        .getCurrentUser()
        .pipe(map((user) => !!user && user.authorities.indexOf('ROLE_OPERATOR') > -1));

    syncState$ = this.synchronizationService.getSyncState();

    constructor(
        private readonly router: Router,
        private readonly authenticationStore: AuthenticationStore,
        private readonly synchronizationService: SynchronizationService
    ) {}

    ngOnInit(): void {}

    gererModeHorsLigne() {
        this.router.navigate([URL_MODE_HORS_LIGNE]);
    }
}

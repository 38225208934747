import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/commons-lib';
import { EtatDiagnostic, RaisonAttente } from 'src/app/model/diagnostic.model';
import { Router } from '@angular/router';
import { URL_MON_COMPTE_SIGNATURE } from 'src/app/shared/constants/url.constants';

class ReportValidationModalData {
    constructor(public docsIsInvalid: boolean, public isOnline: boolean, public hasSignature: boolean) {}
}

@Component({
    selector: 'app-report-validation-modal',
    templateUrl: './report-validation-modal.component.html',
    styleUrls: ['./report-validation-modal.component.scss'],
})
export class ReportValidationModalComponent extends BaseComponent implements OnInit {
    readonly enumEtatDiagnostic = EtatDiagnostic;

    hasSignature = true;
    docsIsInvalid: boolean;
    etatDiagnosticSelection: string;
    reasonsList: string[] = Object.keys(RaisonAttente)
        .map((key) => RaisonAttente[key])
        .filter((value) => typeof value === 'string') as string[];
    raisonsAttenteSelection: string[] = [];

    validateIsPossible = false;

    constructor(
        private readonly dialogRef: MatDialogRef<ReportValidationModalComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: ReportValidationModalData,
        private readonly router: Router
    ) {
        super();
        this.docsIsInvalid = data.docsIsInvalid;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        this.hasSignature = this.data.hasSignature;
    }

    onChangeEtatDiagnostic(event) {
        this.etatDiagnosticSelection = event ? event.value : undefined;
        this.checkValidateIsPossible();
    }

    checkValidateIsPossible() {
        if (this.etatDiagnosticSelection === EtatDiagnostic.FINI) {
            this.validateIsPossible = true;
        } else if (this.etatDiagnosticSelection === EtatDiagnostic.EN_ATTENTE) {
            this.validateIsPossible = !(
                this.docsIsInvalid && !this.raisonsAttenteSelection.includes(RaisonAttente.ATTENTE_DOCUMENT)
            );
        } else {
            this.validateIsPossible = false;
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close({
            etatDiagnosticSelection: this.etatDiagnosticSelection,
            raisonsAttenteSelection: this.raisonsAttenteSelection.map((reason) =>
                Object.keys(RaisonAttente).find((key) => RaisonAttente[key] === reason)
            ),
        });
    }

    onClickBtnAddSignature() {
        this.dialogRef.close(false);
        this.router.navigate([URL_MON_COMPTE_SIGNATURE]);
    }
}

import { Injectable, Type } from '@angular/core';
import { ITypePrestationInterface } from '../../../../services/interfaces/type-prestation.interface';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { Intervention } from '../../../../model/intervention.model';
import { Rule } from '../../../../model/regle.model';
import { IDiagReportData, InterventionReportData } from '../../../../model/rapport.model';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { Commentaire } from '../../../../model/commentaire.model';
import { CommentairePredefini } from '../../../../model/commentaire-predefini.model';
import { ObjectifBlockComponent } from '../modules/report/blocks/objectif-block/objectif-block.component';
import { CommentairesBlockComponent } from '../modules/report/blocks/commentaires-block/commentaires-block.component';
import { URL_MS_REPORT_FILE } from 'src/app/shared/constants/cndiag.constants';
import { DocumentsService } from 'src/app/services/documents.service';
import { PerimetreBlockComponent } from '../modules/report/blocks/perimetre-block/perimetre-block.component';
import { TypeReport } from 'src/app/model/reference-prestation.model';
import { EspaceData, HapReportData, PerimetreData, PrelevementData, ZoneData } from '../model/hapData.model';
import { DetailPerimetreBlockComponent } from '../modules/report/blocks/detail-perimetre-block/detail-perimetre-block.component';
import { BonCommandeAnalyseAdmin } from '../../../../model/bon-commande.model';
import { Hap, LevelToDisplay } from '../model/hap.model';
import { Observable, of } from 'rxjs';
import { Espace } from '../../../../model/espace.model';
import { ZoneBlockComponent } from '../modules/report/blocks/zone-block/zone-block.component';
import { ReportagePhotoService } from '../../../../services/reportage-photo.service';
import { LegendScreenshot } from 'src/app/model/screenshot-svg.model';
import { HapCnMapService } from './hap-cn-map.service';
import { typeCarottageToLabel, typePrelevementToLabel } from '../../../../model/prelevement-generique.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { Bien, Niveau, Volume } from '../../../../model/bien.model';
import { PointDeControleService } from '../../../../services/point-de-controle.service';

/**
 * Liste des Type de component (Class) de block de rapport disponible pour la composition
 * du contenu d'un raport
 */
export const REPORT_BLOCK_CATALOG: { [key: string]: Type<any> } = {
    ObjectifBlockComponent: ObjectifBlockComponent,
    PerimetreBlockComponent: PerimetreBlockComponent,
    ZoneBlockComponent: ZoneBlockComponent,
    DetailPerimetreBlockComponent: DetailPerimetreBlockComponent,
    CommentairesBlockComponent: CommentairesBlockComponent,
};

/**
 * Service pour le diagnostic HAP_VISITE_RECONNAISSANCE.
 */
@Injectable({
    providedIn: 'root',
})
export class TypeHapVisiteReconnaissanceService implements ITypePrestationInterface {
    private typePrestation: TypePrestation = 'HAP_VISITE_RECONNAISSANCE';

    constructor(
        private readonly documentsService: DocumentsService,
        private readonly hapCnMapService: HapCnMapService,
        private readonly reportagePhotoService: ReportagePhotoService,
        private readonly pointDeControleService: PointDeControleService
    ) {}

    generateLegendForScreenshot(diagnostic: Diagnostic, conf: any): LegendScreenshot[] {
        return this.hapCnMapService.generateLegendForScreenshot(diagnostic, conf);
    }

    getCodeBimEquipementBien(typePrestation: TypePrestation): string[] {
        return [];
    }

    getCompletionPercentage(diagnostic: Diagnostic): number {
        return 0;
    }

    getContenuDiagnosticFromParent(diagnosticToUpdate: Diagnostic, diagnosticParent: Diagnostic): ContenuDiagnostic {
        return { ...diagnosticParent.contenuDiagnostic };
    }

    getContenuDiagnostic(typePrestation: TypePrestation): Observable<ContenuDiagnostic> {
        return of(new Hap());
    }

    getReportBlockType(componentName: string): Type<any> {
        const blockType = REPORT_BLOCK_CATALOG[componentName];
        if (!blockType) {
            console.log('Block %s not found', componentName);
        }
        return blockType;
    }

    getDiagnosticReportData(
        intervention: Intervention,
        diagnostic: Diagnostic,
        regles: Rule[],
        optionPlan: boolean
    ): IDiagReportData {
        const diagReportData = new HapReportData();
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;
        const contenuDiagnostic = diagnostic.contenuDiagnostic as Hap;
        diagReportData.refRapport = diagnostic.reportDatas.find(
            (reportDataTemp) => reportDataTemp.typeReport === TypeReport.REPORT
        ).refRapport;
        diagReportData.listeEspaces = this.buildListeEspaceReport(contenuDiagnostic.espaces.valeur);
        diagReportData.objectif = contenuDiagnostic.objectifs.valeur;
        if (diagnostic.recommandationsFinales.length) {
            diagReportData.recommandations = diagnostic.recommandationsFinales.map((it) => it.contenu);
        }
        if (diagnostic.constatationsFinales.length) {
            diagReportData.constatationsDiverses = diagnostic.constatationsFinales.map((it) => it.contenu);
        }

        // Liste des Documents
        diagReportData.documentsData = this.documentsService.buildDocumentsData(intervention, diagnostic);
        diagReportData.listDocuments = diagReportData.documentsData.filter((doc) => doc.afficherDansListeDocuments);
        diagReportData.annexes = diagReportData.documentsData
            .filter((doc) => doc.afficherDansRapport)
            .map((doc) => ({
                id: doc.nom,
                filename: URL_MS_REPORT_FILE + doc.idFichier,
                type: 'pdf',
            }));

        // Screenshot
        diagReportData.screenshotsPlan = diagnostic.screenshotsPlan;
        diagReportData.reportagesPhotos = this.reportagePhotoService.buildReportagePhotoData(diagnostic);

        return diagReportData;
    }

    /**
     * Génère le reportData du bon de commande
     * @param intervention
     * @param diagnostic
     */
    getDiagnosticBonCommandeData(intervention: Intervention, diagnostic: Diagnostic): IDiagReportData {
        return null;
    }

    private buildListeEspaceReport(espaces: Espace[]): EspaceData[] {
        const espacesData = [];
        espaces.forEach((espace) => {
            const espaceData: EspaceData = new EspaceData();
            espaceData.nom = espace.nom;
            espaceData.listePerimetres = [];
            espace.listePerimetres.forEach((perimetre) => {
                const perimetreData: PerimetreData = new PerimetreData();
                perimetreData.nom = perimetre.nom;
                perimetreData.typeOuvrage = perimetre.typeOuvrage ? perimetre.typeOuvrage.name : undefined;
                perimetreData.description = perimetre.description;
                perimetreData.listeZones = [];
                perimetre.listeZones.map((zone) => {
                    const zoneData: ZoneData = new ZoneData();
                    zoneData.nom = zone.nom;
                    zoneData.partieOuvrage = zone.partieOuvrage ? zone.partieOuvrage.name : undefined;
                    zoneData.materiaux = zone.typeMateriaux.map((m) => m.nom);
                    zoneData.idFichierImageSituation = zone.idFichierImageSituation;
                    zoneData.fichierImageSituationUrl = URL_MS_REPORT_FILE + zone.idFichierImageSituation;
                    zoneData.description = zone.description;
                    zoneData.listePrelevements = [];
                    zone.listePrelevements.forEach((prelevement) => {
                        const prelevementData: PrelevementData = new PrelevementData();
                        prelevementData.general = {
                            reference: prelevement.general.reference,
                            description: prelevement.general.description,
                        };
                        prelevementData.details = {
                            typeCarottage: typeCarottageToLabel(prelevement.details.typeCarottage),
                            typePrelevement: typePrelevementToLabel(prelevement.details.typePrelevement),
                            profondeurCarottage: prelevement.details.profondeurCarottage,
                        };
                        prelevementData.echantillonages = [];
                        zoneData.listePrelevements.push(prelevementData);
                    });
                    perimetreData.listeZones.push(zoneData);
                });
                perimetre.listeBesoins.length &&
                    perimetre.listeBesoins.forEach((besoin) => {
                        const prelevementData: PrelevementData = new PrelevementData();
                        prelevementData.general = {
                            reference: besoin.general.reference,
                        };
                        prelevementData.details = {
                            typeCarottage: typeCarottageToLabel(besoin.details.typeCarottage),
                            typePrelevement: typePrelevementToLabel(besoin.details.typePrelevement),
                            profondeurCarottage: besoin.details.profondeurCarottage,
                        };
                        prelevementData.echantillonages = [];

                        prelevementData.donneesTechniques = {
                            lave: besoin.donneesTechniques.lave.value,
                            analyseAmiante: besoin.donneesTechniques.analyseAmiante.value,
                            pollutionSurfacique: besoin.donneesTechniques.pollutionSurfacique.value,
                            limitationFibre: besoin.donneesTechniques.limitationFibre.value,
                            analyseCouches: besoin.donneesTechniques.analyseCouches.value,
                            couchesADissocier: besoin.donneesTechniques.couchesADissocier.value,
                        };
                        perimetreData.listeBesoins.push(prelevementData);
                    });
                espaceData.listePerimetres.push(perimetreData);
            });

            espacesData.push(espaceData);
        });
        return espacesData;
    }

    getTypePrestation(): TypePrestation {
        return this.typePrestation;
    }

    isItemAlreadyFilled(diagnostic: Diagnostic, type: string, itemId: string): boolean {
        return false;
    }

    prepareFilteredCommentsForReport(diagnosticData: IDiagReportData, hiddenComments: Map<string, string[]>) {}

    prepareForm(intervention: Intervention, contenuDiagnostic: Hap) {}

    prepareSpecificComments(
        diagnostic: Diagnostic,
        commentairesGeneraux: Commentaire[],
        commentaires: CommentairePredefini[]
    ): Commentaire[] {
        return [];
    }

    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        return undefined;
    }

    prepareStoreyForScreenshot(diagnostic: Diagnostic, currentStorey: cn_storey, conf: any) {
        const espaceId = conf.espace?.espace?.id;
        const espace = ((diagnostic.contenuDiagnostic as Hap).espaces.valeur as Espace[]).find(
            (esp) => esp.id === espaceId
        );
        this.hapCnMapService.populateStoreyWithMarkers(
            diagnostic,
            currentStorey,
            espace,
            LevelToDisplay.PRELEVEMENT,
            this.hapCnMapService.getHapConf(conf),
            this.hapCnMapService.getFilters(conf)
        );
    }

    deplaceVolume(diagnostic: Diagnostic, volumeSource: Volume, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.deplaceVolume(diagnostic, volumeSource, niveauDestination, currentBien);
    }

    mergeNiveau(diagnostic: Diagnostic, niveauSource: Niveau, niveauDestination: Niveau, currentBien: Bien) {
        this.pointDeControleService.mergeNiveau(diagnostic, niveauSource, niveauDestination, currentBien);
    }

    mergeVolume(diagnostic: Diagnostic, volumeSource: Volume, volumeDestination: Volume, currentBien: Bien) {
        this.pointDeControleService.mergeVolume(diagnostic, volumeSource, volumeDestination, currentBien);
    }

    deplaceEquipement(idEquipement: string, diagnostic: Diagnostic, volumeDestination: Volume, bien: Bien) {
        this.pointDeControleService.deplaceEquipement(idEquipement, diagnostic, volumeDestination, bien);
    }
}

import { MongoUtils } from 'src/app/commons-lib';

export class InterventionFile {
    id: string = MongoUtils.generateObjectId();
    fileId: string;
    interventionId: string;
    diagnosticId?: string;
    referenceId: string;
    typeReferenceFichier: TypeReferenceFichier;

    creationDate: number;
    gpsLatitudeRef: string;
    gpsLatitude: string;
    gpsLongitudeRef: string;
    gpsLongitude: string;
    gpsAltitudeRef: string;
    gpsAltitude: string;
}

export enum TypeReferenceFichier {
    PHOTO_COMMENTAIRE = 'PHOTO_COMMENTAIRE',
    PHOTO_BIEN = 'PHOTO_BIEN',
    PHOTO_ZONE = 'PHOTO_ZONE',
    PHOTO_PRELEVEMENT_SITUATION = 'PHOTO_PRELEVEMENT_SITUATION',
    PHOTO_PRELEVEMENT_ECHELLE = 'PHOTO_PRELEVEMENT_ECHELLE',
    FICHIER_DOCUMENT = 'FICHIER_DOCUMENT',
    PHOTO_COMMENTAIRE_PLAN = 'PHOTO_COMMENTAIRE_PLAN',
    PHOTO_BIEN_SAT = 'PHOTO_BIEN_SAT',
    PHOTO_PRESTATION = 'PHOTO_PRESTATION',
}

import { CommentStyle, IPresetsHandler } from '@acenv/cnmap-angular-editor-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    cn_balcony_type,
    cn_beam_column_type,
    cn_fence_opening_type,
    cn_fence_type,
    cn_opening_type,
    cn_wall_type,
} from '@acenv/cnmap-editor';
import { ReferenceService } from '../../../../../services/reference.service';

@Injectable({
    providedIn: 'root',
})
export class SketchPresetHandler implements IPresetsHandler {
    constructor(private http: HttpClient, private readonly referenceService: ReferenceService) {}

    getWallTypes(): Observable<cn_wall_type[]> {
        return this.referenceService.findAllWallTypes();
    }

    getOpeningTypes(): Observable<cn_opening_type[]> {
        return this.referenceService.findAllOpeningTypes();
    }

    getFenceOpeningTypes(): Observable<cn_fence_opening_type[]> {
        return this.referenceService.findAllFenceOpeningTypes();
    }

    getBalconyTypes(): Observable<cn_balcony_type[]> {
        return this.referenceService.findAllBalconyTypes();
    }

    getFenceTypes(): Observable<cn_fence_type[]> {
        return this.referenceService.findAllFenceTypes();
    }

    getBeamTypes(): Observable<cn_beam_column_type[]> {
        return this.referenceService.findAllBeamTypes();
    }

    getCommentStyles(): Observable<CommentStyle[]> {
        return this.referenceService.findAllCommentStyles();
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Document } from '../model/document.model';
import { FileService } from './file.service';
import { Rapport, RapportDownloadToken, RapportsByDiagnostic } from '../model/rapport.model';
import { TypeReport } from '../model/reference-prestation.model';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';
import { LivrableFilters } from '../modules/admin/gestion-livrables/tableau-livrables/tableau-livrables.component';
import { Livrable } from '../model/livrable.model';

/**
 * Service pour les données de référence.
 *
 * 2 niveaux de cache :
 * - Cache RxJS, à durée courte : renvoie directement la valeur cachée via un shareReplay, évite d'appeler l'API trop souvent
 * - Cache service worker, à durée longue : lorsque l'API est appelée, permet de renvoyer une valeur en mode hors-ligne
 *
 * NB : les données renvoyées depuis le cache RxJS n'ont pas besoin d'être clonées, car elles sont en lecture seule
 */
@Injectable({
    providedIn: 'root',
})
export class ReportApiService {
    public resourceUrl = environment.apiUrl + '/report';

    constructor(private http: HttpClient, private fileService: FileService) {}

    getTemplateReportToDowload(document: Document): void {
        const fileDownloadTokenUrl = `${this.resourceUrl}/getTemplateReportToDowload/${document.idFichier}`;
        const fileName = document.nomFichier;
        this.fileService.downloadDocumentFile(fileDownloadTokenUrl, fileName);
    }

    uploadTemplateRapportDocumentFile(
        idDocument: string,
        file: FormData,
        fileId: string,
        dateUpload: string
    ): Observable<HttpEvent<any>> {
        let params = new HttpParams();
        params = params.set('fileId', fileId);
        params = params.set('dateUpload', dateUpload);
        params = params.set('idDocument', idDocument);
        return this.http.post<any>(`${this.resourceUrl}/uploadTemplateReport`, file, {
            params,
        });
    }

    deleteRapportTemplate(idReferencePrestation: string, typeReport: TypeReport, idFichier: string): Observable<any> {
        return this.http.delete<any>(
            `${this.resourceUrl}/deleteRapportTemplate/${idReferencePrestation}/${typeReport}/${idFichier}`
        );
    }

    getReportFromIdsDiagnostics(idsDiagnostics: string[]): Observable<RapportsByDiagnostic[]> {
        const queryParams: any = {};
        queryParams.idsDiagnostics = idsDiagnostics;
        return this.http.get<RapportsByDiagnostic[]>(`${this.resourceUrl}/getReportFromIdsDiagnostics`, {
            params: queryParams,
        });
    }

    getReportFromIdIntervention(idIntervention: string): Observable<RapportsByDiagnostic[]> {
        return this.http.get<RapportsByDiagnostic[]>(
            `${this.resourceUrl}/getReportFromIdIntervention/${idIntervention}`
        );
    }

    getLivrables(filters: LivrableFilters, pageRequest: PageRequest<Livrable>) {
        return extractPage(
            this.http.get<Livrable[]>(this.resourceUrl + '/livrables', {
                observe: 'response',
                params: {
                    ...toHttpParams(pageRequest),
                    ...filters,
                },
            })
        );
    }

    getLivrable(idRapport: string): Observable<Livrable> {
        return this.http.get<Livrable>(`${this.resourceUrl}/livrables/${idRapport}`);
    }

    getDownloadTokens(idRapport: string, idFichier: string): Observable<RapportDownloadToken[]> {
        return this.http.get<RapportDownloadToken[]>(
            `${this.resourceUrl}/livrables/${idRapport}/fichier/${idFichier}/token`
        );
    }

    createDownloadToken(idRapport: string, idFichier: string, description: string): Observable<RapportDownloadToken> {
        return this.http.post<RapportDownloadToken>(
            `${this.resourceUrl}/livrables/${idRapport}/fichier/${idFichier}/token`,
            { description }
        );
    }
    deleteDownloadToken(idRapport: string, idFichier: string, idToken: string): Observable<void> {
        return this.http.delete<void>(
            `${this.resourceUrl}/livrables/${idRapport}/fichier/${idFichier}/token/${idToken}`
        );
    }
}

<h1 *ngIf="createMode" mat-dialog-title class="primary">Dupliquer un équipement</h1>
<h1 *ngIf="!createMode" mat-dialog-title class="primary">Éditer un équipement</h1>

<div mat-dialog-content [formGroup]="form">
    <div class="d-flex flex-column mx-2">
        <div *ngIf="createMode" class="w-100">
            <div class="mat-subheading-2 m-0 primary required">À partir de</div>
            <app-custom-select
                [matFormFieldClass]="'w-100'"
                formControlName="from"
                [selectControl]="form.get('from')"
                [choices]="nomsTypeElementAControler"
                [placeholder]="'Equipement de référence'"
                [addline]="false"
                [filter]="true"
            >
            </app-custom-select>
        </div>

        <div class="w-100">
            <div class="mat-subheading-2 m-0 primary required">Nouveau nom de l'équipement</div>
            <mat-form-field class="w-100 pr-4">
                <input placeholder="Nom" matInput type="text" formControlName="nom" />
                <mat-error *ngIf="form.get('nom').hasError('erreurNomEquipement')"
                    >Un autre équipement possède déjà le même nom.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end" *ngIf="!deleteEquipment">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button *ngIf="!createMode" mat-button color="primary" (click)="deleteCustomEquipment()">
        <lib-icon>delete</lib-icon>
        <span>{{ "Supprimer l'équipement" | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" [disabled]="!form.valid" (click)="confirm()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>

<div *ngIf="deleteEquipment && !alreadyPresent" mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="deleteEquipment = false">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler la suppression' | uppercase }}</span>
    </button>
    <button mat-raised-button color="warn" (click)="confirmDeletion()">
        <lib-icon>delete</lib-icon>
        <span>{{ 'Confirmer la suppression' | uppercase }}</span>
    </button>
</div>

<div *ngIf="deleteEquipment && alreadyPresent" mat-dialog-actions class="align-items-start justify-content-end">
    <div class="warn bold mr-4">
        <lib-icon [class]="'icon-before-text'">warning</lib-icon>
        Cet équipement est déjà utilisé dans la description du bien.
    </div>
    <button mat-button color="primary" (click)="deleteEquipment = false">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
</div>

import { Component, Injector, OnInit } from '@angular/core';
import { Diagnostic } from '../../../../../../../model/diagnostic.model';
import { ActivatedRoute } from '@angular/router';
import { Zone } from '../../../../model/zone.model';
import { MatDialog } from '@angular/material/dialog';
import { ZoneProprieteModalComponent } from '../zone-propriete-modal/zone-propriete-modal.component';
import { FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { Intervention } from 'src/app/model/intervention.model';
import { Bien, Volume } from 'src/app/model/bien.model';
import { PointDeControleBien } from 'src/app/model/point-de-controle.model';
import { Polluant } from '../../../../model/polluant.model';
import { Surface } from '../../../../model/surface.model';
import { PolluantConfig } from '../../../../model/polluant-config.model';
import { IValidatableComponent } from '../../../../services/interfaces/validatable-component.interface';
import { PolluantComponent } from '../../../../utils/polluant-component';
import { EtatValidation } from 'src/app/model/etat-progression.model';
import { MongoUtils, NotificationService } from 'src/app/commons-lib';
import cloneDeep from 'lodash/cloneDeep';
import { cn_building, cn_space, cn_storey } from '@acenv/cnmap-editor';
import { Besoin } from '../../../../model/besoin.model';
import { ConfirmDialogComponent } from 'src/app/lib/confirmation/confirm-dialog.component';
import { PerimetreInvestigation } from 'src/app/model/perimetre-investigation.model';

@Component({
    selector: 'app-constatations-diverses',
    templateUrl: './zone.component.html',
    styleUrls: ['./zone.component.scss'],
})
export class ZoneComponent extends PolluantComponent implements OnInit, IValidatableComponent {
    biens: Bien[] = [];
    pdcBiens: PointDeControleBien[] = [];
    filteredPointsDeControles: PointDeControleBien[] = [];

    perimetreInvestigation: PerimetreInvestigation;

    //Panel data
    listVolumeDisabled: String[] = [];
    listVolumeId: String[] = [];
    listVolumeChecked: String[] = [];

    indexZone: number = 0;
    indexSurface: number = undefined;

    volumes: Volume[] = [];
    form: FormGroup;
    intervention: Intervention;
    currentDiagnostic: Diagnostic;
    diagnosticPolluant: Polluant;

    polluantConfig: PolluantConfig;

    volumesSurface: any = {};

    volumeStoreyLinkObject: any = {};

    previousFormValue: any;

    lienVolumeObj = {};

    message?: string;

    constructor(
        public dialog: MatDialog,
        private cnSpinnerService: CnSpinnerService,
        public interventionService: InterventionService,
        public injector: Injector,
        public route: ActivatedRoute,
        private readonly notificationService: NotificationService
    ) {
        super(injector);
    }

    // static ecransDependants = [
    //     {
    //         composant: BesoinsPrelevementsComponent,
    //         nom: 'besoins',
    //     },
    // ];

    ngOnInit(): void {
        this.cnSpinnerService.show();
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
            this.route.data,
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diagnostic, routerData]) => {
                    this.intervention = intervention;
                    this.currentDiagnostic = diagnostic;
                    combineLatest([this.polluantService.findOnePolluantConfigIndexedDB(this.diagnostic.idConfig)])
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(([polluantConfig]) => {
                            if (polluantConfig == null) {
                                this.notificationService.error(
                                    'Erreur : Merci de faire une synchro pour récupérer les configs en local.'
                                );
                                this.cnSpinnerService.hide();
                                return;
                            }
                            this.polluantConfig = polluantConfig;
                            this.diagnosticPolluant = diagnostic.contenuDiagnostic as Polluant;

                            this.isValidated = this.diagnosticPolluant.zones.validated;
                            this.pdcBiens = this.currentDiagnostic.pointsDeControleBiens;

                            this.biens = this.intervention.relationInterventionBiens.map(
                                (relationBienPrincipal) => relationBienPrincipal.bien
                            );

                            this.restoreData();

                            this.form = this.createForm();

                            this.findDisabledVolumes();
                            this._isContextTravaux();

                            this.getVolumesSurface();
                            this.checkValidity();
                            this.zoneSelected(0);
                            this.cnSpinnerService.hide();
                            this.isInEditMode =
                                !(
                                    !!routerData.readonly ||
                                    this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic)
                                ) && this.isInEditMode;
                            // this.checkEcransDependants(this.diagnosticPolluant);
                            if (!this.isInEditMode) {
                                this.form.disable();
                            }

                            this.message = this.diagnosticPolluant.zones.message;
                            this.diagnosticPolluant.zones.message = '';

                            this.isLoaded = true;
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    /**
     * Charge le bien
     * @private
     */
    private getVolumesSurface() {
        let currentBien = this.diagnostic.pointsDeControleBiens[0];

        let bienBuilding: cn_building;

        if (currentBien) {
            const bien = this.biens.find((b) => b.id === currentBien.idBien);
            //Si le plan est utilisé, alors on charge ses données pour récupérer la superficie des pièces
            if (bien && bien.jsonPlan) {
                bienBuilding = cn_building.unserialize(JSON.parse(bien.jsonPlan));
            } else {
                bienBuilding = undefined;
                this.volumesSurface = {};
                return;
            }

            bienBuilding.storeys.forEach((storey: cn_storey) => {
                storey.scene.spaces.forEach((space: cn_space) => {
                    if (space.name == 'Extérieur') return;

                    let volume = this.volumeStoreyLinkObject[storey.ID].find((volume: Volume) => {
                        return volume.spaceId == space.ID;
                    });

                    if (volume == undefined) return;

                    this.volumesSurface[volume.id] = space.declared_area > -1 ? space.declared_area : space.area;
                });
            });
        }
    }

    createForm(data?: any) {
        //Création du formulaire global
        const form = new FormGroup({ zonesList: new FormArray([]) });

        //Le formulaire est initialisé soit avec les données passées en paramètre,
        // sinon avec les données du diag
        let zones = data ?? this.diagnosticPolluant.zones.data.zonesList;

        // zones = undefined;

        //Si des données de Zones existent, création d'un nouveau formulaire
        //Sinon initialisation d'un nouveau FormGroup de Zone
        if (zones != undefined && zones.length > 0) {
            zones.forEach((zone) => {
                const zoneForm = this.createNewFormGroupZone(zone);
                (form.get('zonesList') as FormArray).push(zoneForm);
            });
        } else {
            const zoneForm = this.createNewFormGroupZone(new Zone());
            (form.get('zonesList') as FormArray).push(zoneForm);
        }

        return form;
    }

    createNewFormGroupZone(zone: Zone) {
        let require = Validators.nullValidator;
        if (zone.typeZone == 'Zone de travail') require = Validators.required;
        return new FormGroup({
            id: new FormControl(zone.id),
            identifiant: new FormControl(zone.identifiant),
            nom: new FormControl(zone.nom),
            usageLocaux: new FormControl(zone.usageLocaux, Validators.required),
            objectifMesurage: new FormControl(zone.objectifMesurage, Validators.required),
            ventilation: new FormControl(zone.ventilation, Validators.required),
            environnement: new FormControl(zone.environnement, Validators.required),
            frequentation: new FormControl(zone.frequentation, Validators.required),
            personnesPresentes: new FormControl(zone.personnesPresentes, Validators.required),
            listeMpca: new FormControl(zone.listeMpca, Validators.required),
            listeSurfaces: this.getSurfaceGroup(zone.listeSurfaces),
            zoneContexte: new FormControl(zone.zoneContexte, Validators.required),
            typeZone: new FormControl(zone.typeZone, Validators.required),
            zse: new FormControl(zone.zse),
            idZoneAssociee: new FormControl(zone.idZoneAssociee),
            zoneAssociee: new FormControl(zone.zoneAssociee),
            activiteAdjacente: new FormControl(zone.activiteAdjacente, require),
            confinement: new FormControl(zone.confinement, require),
            nombreExtracteurs: new FormControl(zone.nombreExtracteurs, require),
            sasMateriel: new FormControl(zone.sasMateriel, require),
            sasPersonnel: new FormControl(zone.sasPersonnel, require),
        });
    }

    getSurfaceGroup(listeSurfaces: Surface[]) {
        var surfaceFormArray = new FormArray([]);
        listeSurfaces.forEach((surface) => {
            const surfaceForm = this.createNewSurfaceForm(surface);
            surfaceFormArray.push(surfaceForm);
        });

        return surfaceFormArray;
    }

    createNewSurfaceForm(surface: Surface) {
        const formSurface = new FormGroup(
            {
                superficie: new FormControl(surface.superficie, Validators.required),
                longueurMax: new FormControl(surface.longueurMax, {
                    validators: surface.inferieurSeuilMin ? undefined : Validators.required,
                    updateOn: 'change',
                }),
                inferieurSeuilMin: new FormControl(surface.inferieurSeuilMin, {
                    validators: [Validators.required],
                    updateOn: 'change',
                }),
                listeIdVolume: new FormControl(surface.listeIdVolume, Validators.required),
                listeCommentaire: new FormControl(surface.listeCommentaire),
                nombrePu: new FormControl(surface.nombrePu),
                longueurInterface: new FormControl(surface.longueurInterface),
                hauteurInterface: new FormControl(surface.hauteurInterface),
                surfaceInterface: new FormControl(surface.surfaceInterface),
                activeSurfaceInterface: new FormControl(surface.activeSurfaceInterface),
            },
            { updateOn: 'blur' }
        );
        //UpdateOn: Blur permet de ne trigger l'event ValueChange que lorsque l'utilisateur
        //          clique en dehors du champ

        formSurface.valueChanges.subscribe((value) => {
            this.formArraySurfaces.controls.forEach((formSurface: FormGroup) => {
                let surface = formSurface.getRawValue();
                let puNb;
                if (surface.longueurInterface > 0 && surface.hauteurInterface > 0) {
                    puNb = Math.ceil(
                        (14 * surface.longueurInterface * surface.hauteurInterface) /
                            (730 + surface.longueurInterface * surface.hauteurInterface)
                    );
                } else if (surface.superficie <= 100 && surface.longueurMax <= 15) {
                    puNb = 1;
                } else if (surface.superficie <= 100 && surface.longueurMax > 15) {
                    puNb = Math.ceil(surface.longueurMax / 15);
                } else if (surface.superficie > 100) {
                    puNb = Math.ceil((14 * surface.superficie) / (730 + surface.superficie));
                } else {
                    puNb = 1;
                }

                formSurface.get('nombrePu').setValue(puNb, { emitEvent: false });
            });

            //Mise à jour du diagnostic
            this.checkValidity();
        });

        return formSurface;
    }

    addZone() {
        //Création de la zone
        const newZone: Zone = new Zone();

        //Création du formulaire de la nouvelle zone et ajout au formulaire global
        this.formZones.push(this.createNewFormGroupZone(newZone));

        //Sélection de l'index de la nouvelle zone
        this.indexZone = this.formZones.length - 1;

        this.zoneSelected(this.indexZone);

        //Ouverture de la modal d'édition
        this.openModal();
    }

    private duplicateFormGroupZone(zone: FormGroup): FormGroup {
        return this.formBuilder.group({
            id: [MongoUtils.generateObjectId()],
            identifiant: [zone.get('identifiant').value, Validators.required],
            nom: [''],
            usageLocaux: [zone.get('usageLocaux').value, Validators.required],
            objectifMesurage: [zone.get('objectifMesurage').value, Validators.required],
            ventilation: [zone.get('ventilation').value, Validators.required],
            environnement: [zone.get('environnement').value, Validators.required],
            frequentation: [zone.get('frequentation').value, Validators.required],
            personnesPresentes: [zone.get('personnesPresentes').value, Validators.required],
            activiteAdjacente: [zone.get('activiteAdjacente').value, Validators.required],
            confinement: [zone.get('confinement').value, Validators.required],
            nombreExtracteurs: [zone.get('nombreExtracteurs').value, Validators.required],
            sasMateriel: [zone.get('sasMateriel').value, Validators.required],
            sasPersonnel: [zone.get('sasPersonnel').value, Validators.required],
            listeMpca: [zone.get('listeMpca').value, Validators.required],
            listeSurfaces: this.getSurfaceGroup(zone.get('listeSurfaces').value),
            zoneContexte: [zone.get('zoneContexte').value, Validators.required],
            typeZone: [zone.get('typeZone').value, Validators.required],
            zse: [zone.get('zse').value],
            idZoneAssociee: [zone.get('idZoneAssociee').value],
        });
    }

    duplicateZone() {
        this.selectSurfaceByIndex(undefined);

        //Création du formulaire de la zone et ajout au formulaire global
        let formZone = cloneDeep(this.formZones.controls[this.indexZone] as FormGroup);
        const newZone = this.duplicateFormGroupZone(formZone);
        newZone.get('objectifMesurage').setValue('');
        this.formZones.push(newZone);

        //Sélection de l'index de la nouvelle zone
        this.indexZone = this.formZones.length - 1;
        this.zoneSelected(this.indexZone);

        //Ouverture de la modal d'édition
        this.openModal();
    }

    editZone() {
        this.selectSurfaceByIndex(undefined);
        this.openModal();
    }

    addSurface() {
        this.selectSurfaceByIndex(undefined);

        const surface = new Surface();
        surface.inferieurSeuilMin = true;
        const newSurface = this.createNewSurfaceForm(surface);
        this.formArraySurfaces.push(newSurface);

        //Mise à jour du diagnostic
        this.checkValidity();
    }

    getTypeZseByIndex(index) {
        return this.formZones.controls[index]?.get('zse').value;
    }

    /**
     * @description Supprime une surface et débloque les volumes qu'elle contenait
     * @param indexSurface l'index de la surface a supprimer dans le liste de surfaces de la zone actuelle
     */
    removeSurfaceByIndex(indexSurface) {
        this.selectSurfaceByIndex(undefined);

        const _volumesASupprimer: Array<String> = [...this.formArraySurfaces.value[indexSurface].listeIdVolume];
        _volumesASupprimer.forEach((idVolume: String) => {
            this.removeVolumeFromSurface(idVolume, indexSurface);
        });
        this.formArraySurfaces.removeAt(indexSurface);

        this.findDisabledVolumes();

        this.checkValidity();
    }

    /**
     * @description Supprime un volume d'une surface et met à jour la liste des volumes desactivés
     * @param idVolume id du volume à supprimer
     * @param indexSurface index de la surface dans laquelle se trouve le volume
     */
    removeVolumeFromSurface(idVolume, indexSurface) {
        //Vérification si ce volume est associé à des besoins de prélèvements
        const besoinsAssocies = this.diagnosticPolluant.besoins.data?.besoinsList?.filter((besoin: Besoin) => {
            return besoin.pieceId == idVolume;
        });

        if (besoinsAssocies?.length > 0) {
            let message = 'Attention, ce volume est associé aux besoins de prélèvement suivants:';
            besoinsAssocies.forEach((besoin: Besoin) => {
                message += `<br/>- B${besoin.numero}`;
            });
            message +=
                '<br/><br/>Retirer ce volume de la zone entrainera la suppression de ces besoins de prélèvement.';
            message += '<br/>Confirmez-vous la suppression ?';

            this.matDialog
                .open(ConfirmDialogComponent, {
                    data: {
                        message,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result && result !== false) {
                        //Si l'opération est confirmée, la ou les références sont supprimée

                        const filteredBesoins = this.diagnosticPolluant.besoins.data.besoinsList.filter(
                            (besoin: Besoin) => {
                                return besoin.pieceId != idVolume;
                            }
                        );

                        this.diagnosticPolluant.besoins.data.besoinsList = filteredBesoins;

                        this._deleteVolume(idVolume, indexSurface);
                    }
                });
        } else {
            this._deleteVolume(idVolume, indexSurface);
        }
    }

    _deleteVolume(idVolume, indexSurface) {
        //Suppression de l'id du volume supprimé de la liste des volumes de la surface
        let _currentSurface = this.formArraySurfaces.at(indexSurface) as FormGroup;
        let _listeVolumes = (_currentSurface.getRawValue() as Surface).listeIdVolume;
        _listeVolumes.splice(_listeVolumes.indexOf(idVolume), 1);

        //Patcher les valeurs pour mettre à jour le formulaire
        _currentSurface.get('listeIdVolume').setValue(_listeVolumes);

        //Si la surface est vide, alors définir la superficie à 0
        if (_listeVolumes.length == 0) {
            _currentSurface.get('superficie').setValue(0);
        }

        //Suppression de l'id du volume supprimé de la liste des volumes désactivés
        this.listVolumeDisabled.splice(this.listVolumeDisabled.indexOf(idVolume), 1);
        this.listVolumeChecked = [...this.listVolumeDisabled];

        this.selectSurfaceByIndex(undefined);
        //Mise à jour du diagnostic
        this.checkValidity();
    }

    openModal() {
        let _index = this.indexZone;
        const form = cloneDeep(this.formZones.controls[this.indexZone] as FormGroup);
        const dialogRef = this.dialog.open(ZoneProprieteModalComponent, {
            width: '250px',
            data: {
                sequence: {
                    action: 'new',
                },
                form,
                polluantConfig: this.polluantConfig,
                indexZone: this.indexZone,
                isInEditMode: this.isInEditMode,
                lienVolumeObj: this.lienVolumeObj,
            },
            disableClose: true,
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(({ action, form, contenuDiagnostic }) => {
            if (action === 'cancel') {
                this.indexZone = _index;
                return;
            } else if (action === 'delete') {
                //Suppression des volumes associés aux surfaces de la zone à supprimer
                this.formArraySurfaces.value.forEach((surface, index) => {
                    this.removeSurfaceByIndex(index);
                });

                this.formZones.removeAt(this.indexZone);
                this.indexZone = this.indexZone - 1;
            } else {
                // Sauvegarder
                //Update values in the form
                if (form != undefined)
                    this.formZones.controls[this.indexZone].setValue(form, {
                        emitEvent: false,
                    });

                this._isContextTravaux();

                this.contenuDiagnostic = contenuDiagnostic;
                this.indexZone = _index;
            }
            this.checkValidity();
        });
    }

    numToSSColumn(num) {
        let s = '',
            t;

        while (num > 0) {
            t = (num - 1) % 26;
            s = String.fromCharCode(65 + t) + s;
            num = ((num - t) / 26) | 0;
        }
        return s || undefined;
    }

    /**
     * Restaures les données sauvegardées dans le Diagnostic courant
     */
    restoreData() {
        // Sélection du premier perimetre d'investigation
        this.perimetreInvestigation =
            this.diagnosticPolluant.perimetresInvestigation.data.listePerimetreInvestigation[0];

        //Restaure la liste des id de volume disponible définis dans le périmètre
        this.listVolumeId = this.perimetreInvestigation.listeVolumeId || [];

        //Récupère tous les volumes
        this.volumes = this.getAllVolumes();
    }

    /**
     * @description Fais le lien entre les volumes et les contextes d'objectifs de mesurage
     */
    findDisabledVolumes() {
        this.lienVolumeObj = {};

        //Création du tableau de lien entre les volumes et les objs de mesurage
        //pour empêcher deux volumes d'être présents dans deux zones possédant un même objectif de mesurage
        this.formZones.controls.forEach((formZone: FormGroup) => {
            formZone.getRawValue().listeSurfaces.forEach((surface: Surface) => {
                surface.listeIdVolume.forEach((idVolume: string) => {
                    if (formZone.get('objectifMesurage').value == '') {
                        // this.lienVolumeObj[idVolume] = [];
                        return;
                    } else {
                        const tableauRefs = formZone.get('objectifMesurage').value.reduce((acc, obj) => {
                            return acc.concat(obj.parentId);
                        }, []);

                        if (this.lienVolumeObj[idVolume] == undefined) {
                            this.lienVolumeObj[idVolume] = [];
                        }

                        this.lienVolumeObj[idVolume] = this.lienVolumeObj[idVolume].concat(tableauRefs);
                    }
                });
            });
        });

        //Boucle sur toutes les surfaces de toutes les zones pour lister les volumes et ajouter leurs id à la
        // liste des volumes desactivés - this.listVolumeDisabled
        this.listVolumeDisabled = [];
        let formArraySurfaces = this.formCurrentZone.get('listeSurfaces') as FormArray;
        formArraySurfaces.value.forEach((surface) => {
            surface.listeIdVolume.forEach((id) => {
                const vol = this.volumes.find((x) => x.id === id);
                if (vol != undefined) this.listVolumeDisabled.push(vol.id);
            });
        });

        //Vérification pour savoir si un volume est présent dans une autre zone
        // avec au moins un objectif de mesurage similaire
        // si oui alors ce volume ne peut pas être ajouté
        this.formCurrentZone.get('objectifMesurage').value.forEach((obj) => {
            this.volumes.forEach((volume) => {
                if (this.lienVolumeObj[volume.id] == undefined) return;

                if (
                    this.lienVolumeObj[volume.id].indexOf(obj.parentId) != -1 &&
                    !this.listVolumeDisabled.includes(volume.id)
                ) {
                    this.listVolumeDisabled.push(volume.id);
                }
            });
        });

        //A l'initialisation, les volumes cochés sont uniquement ceux qui sont désactivés aka déjà assignés
        this.listVolumeChecked = [...this.listVolumeDisabled];
    }

    navigationZone(e) {
        let newZoneIndex;
        if (e == 'next') newZoneIndex = this.indexZone + 1;
        else if (e == 'previous') newZoneIndex = this.indexZone - 1;

        this.zoneSelected(newZoneIndex);
    }

    zoneSelected(index) {
        this.selectSurfaceByIndex(undefined);
        this.indexZone = parseInt(index);

        this.findDisabledVolumes();
    }

    /**
     * @description Fonction appellée lorsque l'utilisateur clique sur "Valider"
     *              dans le panel de sélection des Volumes.
     * @param listVolume La liste des TOUS les index des volumes sélectionnés,
     *                   y compris ceux déjà désactivés
     */
    handleVolumePanelSubmit(listVolume) {
        //Récupère la surface concernée
        let selectedSurface = this.formArraySurfaces.at(this.indexSurface) as FormGroup;

        //Récupère la liste des volumes nouvellement sélectionnés en faisant la différence
        // entre la liste de tous les volumes sélectionnés et la liste des volumes désactivés
        let _volumesToAdd = listVolume.filter((x) => !this.listVolumeDisabled.includes(x));

        //Ajoute les nouveaux volumes à la liste des volumes de la surface sélectionnée.
        let _listeIdVolume = selectedSurface.value.listeIdVolume.concat(_volumesToAdd);
        selectedSurface.get('listeIdVolume').setValue(_listeIdVolume);
        // this.formArraySurfaces.at(this.indexSurface).setValue(selectedSurface.getRawValue());

        //Mise à jour de la liste des volumes désactivés et cochés
        this.listVolumeDisabled = this.listVolumeDisabled.concat(_volumesToAdd);
        this.listVolumeChecked = [...this.listVolumeDisabled];

        //Déselectionne la surface et verrouille le panel en définissant
        // l'index de la surface sélectionnée à undefined
        this.selectSurfaceByIndex(undefined);

        //Mise à jour du diagnostic
        this.checkValidity();
    }

    /**
     * @description Appelé lors de l'annulation du panel
     *              Passe le panel en read-only
     */
    handleVolumePanelCancel() {
        this.selectSurfaceByIndex(undefined);
        this.listVolumeChecked = [...this.listVolumeDisabled];

        //Mise à jour du diagnostic
        this.checkValidity();
    }

    /**
     * @description Définis l'index de la surface actuellement sélectionnée
     *              Si undefined, alors le panel de sélection de volumes est désactivé
     * @param indexSurfaceSelected l'index de la surface sélectionnée
     */
    selectSurfaceByIndex(indexSurfaceSelected) {
        this.indexSurface = indexSurfaceSelected;
    }

    /**
     * @description Retourne la liste de tous les volumes du bien dans un tableau
     * @returns Tableau contenant la liste des volumes
     */
    getAllVolumes(): Volume[] {
        const volumes: Volume[] = [];
        this.biens.forEach((bien) => {
            bien.description.forEach((niveau) => {
                if (this.volumeStoreyLinkObject[niveau.storeyId] == undefined) {
                    this.volumeStoreyLinkObject[niveau.storeyId] = [];
                }

                niveau.volumes.forEach((volume) => {
                    volumes.push(volume);
                    this.volumeStoreyLinkObject[niveau.storeyId].push(volume);
                });
            });
        });
        return volumes;
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];

        //Si le formulaire est désactivé, on l'active afin de pouvoir savoir si il est valide
        let isFormEnabled = this.form.enabled;
        if (!isFormEnabled) {
            this.form.enable({
                emitEvent: false,
            });
        }

        //Récupération de toutes les erreurs
        const erreursZones = [];
        this.formZones.controls.forEach((formZone: FormGroup, index: number) => {
            let erreursZone = {
                numero: index + 1,
                erreurs: [],
            };

            //boucle sur les controles du formulaire pour trouver les erreurs
            for (const controlName in formZone.controls) {
                if (formZone.get(controlName).invalid == true) {
                    erreursZone.erreurs.push(controlName);
                }
            }

            //Si il y'a des erreurs
            if (erreursZone.erreurs.length != 0) {
                erreursZones.push(erreursZone);
            }
        });

        this.diagnosticPolluant.zones.data = this.form.value;
        this.diagnosticPolluant.zones.errors = erreursZones;

        // this.diagnosticPolluant.zones.data = undefined;
        this.currentDiagnostic.contenuDiagnostic = this.diagnosticPolluant;

        let etat: EtatValidation = 'INVALID';
        if (this.typeMesurage == 'POLLUANT_VISITE_CHANTIER') {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            // et si les données des Zones ont été validées
            if (this.diagnosticPolluant.zones.validated && this.form.valid) {
                etat = 'VALID';
            }
        } else {
            //L'écran est valide si le formulaire n'a pas d'erreurs
            if (this.form && this.form.valid) {
                etat = 'VALID';
            }
        }

        this.etatProgressionService.updateDiagnostic(code, etat, this.currentDiagnostic, true);

        //Si le formulaire était désactivé avant, on le désactive à nouveau
        if (!isFormEnabled) {
            this.form.disable({
                emitEvent: false,
            });
        }
    }

    get formZones() {
        return this.form.get('zonesList') as FormArray;
    }

    get formCurrentZone() {
        return this.formZones.at(this.indexZone) as FormGroup;
    }

    get formArraySurfaces() {
        return this.formZones.at(this.indexZone).get('listeSurfaces') as FormArray;
    }

    //Interface IValidatableComponent implémentation
    cancelModification() {
        this.isInEditMode = false;
        this.form.disable();
        this.form.patchValue(this.previousFormValue);
        this.previousFormValue = undefined;
    }

    saveModification() {
        this.isInEditMode = false;
        this.form.disable();
        this.isInEditMode = false;
        // this.diagnosticPolluant = ZoneComponent.supprimerDonneesDependantes(this.diagnosticPolluant);

        this.checkValidity();
    }

    validateTab() {
        this.isValidated = true;
        this.diagnosticPolluant.zones.validated = true;
        this.checkValidity();
    }

    startModification() {
        this.isInEditMode = true;
        this.form.enable();
        this.previousFormValue = this.form.getRawValue();
    }

    /**
     * @description Permet de savoir si les écrans dépendant de celui-ci ont été renseignés
     * Si oui alors un avertissement est affiché pour indiquer qu'en cas de modification, les données des écrans dépendants seront supprimées
     */
    // checkEcransDependants(contenuDiagnostic: Polluant) {
    //     ZoneComponent.ecransDependants.forEach(({ nom }) => {
    //         //Parmi les écrans définis comme dépendants, si les données de l'un d'entre eux sont définies
    //         // alors l'écran est verrouillé
    //         if (contenuDiagnostic[nom] && Object.values(contenuDiagnostic[nom].data).length > 0) {
    //             this.isInEditMode = false;
    //         }
    //     });
    // }

    // static supprimerDonneesDependantes(contenuDiagnostic: Polluant) {
    //     ZoneComponent.ecransDependants.forEach(({ nom, composant }: any) => {
    //         //Suppression en cascade des données des écrans dépendants
    //         if (composant.supprimerDonneesDependantes) {
    //             let r = composant.supprimerDonneesDependantes(contenuDiagnostic);
    //         }

    //         contenuDiagnostic[nom].data = {};
    //         contenuDiagnostic[nom].errors.push({
    //             label: 'Ecran non-visité',
    //         });
    //     });

    //     return contenuDiagnostic;
    // }

    /**
     * @description
     *
     */
    _isContextTravaux() {
        const objectifsMesurage = this.polluantConfig.objectifsMesurage;
        var state = false;

        (this.form.controls.zonesList as FormArray).controls.forEach((formZone: FormGroup) => {
            const objectifs = formZone.value.objectifMesurage;

            if (objectifs != null && objectifs.length > 0) {
                objectifs.forEach((objectif) => {
                    objectifsMesurage.forEach((obm) => {
                        obm.conditionsPrelevement.forEach((cdPrel) => {
                            if (cdPrel.reference == objectif.reference) {
                                if (cdPrel.contextTravaux) {
                                    state = true;
                                }
                            }
                        });
                    });
                });
            }

            if (!state) {
                // vider le from et enlever les required
                formZone.controls.activiteAdjacente.clearValidators();
                formZone.controls.confinement.clearValidators();
                formZone.controls.nombreExtracteurs.clearValidators();
                formZone.controls.sasMateriel.clearValidators();
                formZone.controls.sasPersonnel.clearValidators();

                formZone.controls.activiteAdjacente.setErrors(null);
                formZone.controls.confinement.setErrors(null);
                formZone.controls.nombreExtracteurs.setErrors(null);
                formZone.controls.sasMateriel.setErrors(null);
                formZone.controls.sasPersonnel.setErrors(null);

                formZone.patchValue({
                    activiteAdjacente: '',
                    confinement: '',
                    nombreExtracteurs: 0,
                    sasMateriel: '',
                    sasPersonnel: '',
                });
            } else {
                formZone.controls.activiteAdjacente.setValidators([Validators.required]);
                formZone.controls.confinement.setValidators([Validators.required]);
                formZone.controls.nombreExtracteurs.setValidators([Validators.required]);
                formZone.controls.sasMateriel.setValidators([Validators.required]);
                formZone.controls.sasPersonnel.setValidators([Validators.required]);
            }

            formZone.updateValueAndValidity();
        });
    }

    get surfaceTotale() {
        return this.formArraySurfaces.value.reduce((n, { superficie }) => n + superficie, 0);
    }

    get totalPu() {
        return this.formArraySurfaces.value.reduce((n, { nombrePu }) => n + nombrePu, 0);
    }
}

"use strict";
import { cn_fence_opening_type } from "..";
import { cn_opening } from "../model/cn_opening";
import { cn_add, cn_dot, cn_middle, cn_mul, cn_normalize, cn_size, cn_sub } from "../utils/cn_utilities";
import { cn_edit_box } from "./cn_edit_box";
import { cn_edition_handler } from "./cn_edition_handler";
import { cn_number_input } from "./cn_inputs";
import { cn_mouse_event } from "./cn_mouse_event";
import { cn_pastille } from "./cn_pastille";
import { cn_svg_map } from "./cn_svg_map";
import { cn_type_pastille } from "./cn_type_pastille";

export class cn_opening_handler extends cn_edition_handler {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {Array<cn_opening>} openings
	 * @param {cn_svg_map} map
	 * @param {boolean} creation
	 */
	constructor(openings, map,creation=false) {
		super(openings,map);
		const obj = this;

		this._map = map;
		this._scene = this._map._scene;
		this._transaction_manager = this._map._building.transaction_manager;

		this._openings = openings;
		this._opening = (openings.length ==1)?openings[0]:null;

		//** mouse flags */
        this._mouseover = -1;
		this._mouseover_opening = false;

		this._center = [0,0];
		this._dx = [0,0];
		this._dy = [0,0];

		this._radius = 0;
		this._draw_disk = true;

		this._start = [true,false,false,true];
		this._inside = [false,true,false,true];

		this._measures = [];
		this._mouseover_measure = "";

		this._grabbed = false;

		this._draw_measures = true;

		//*** Edit box for mass selection */
		const edit_box = new cn_edit_box(this,openings,creation);
		this._handlers.push(edit_box);

		//*** axis pastille */
		const axis_pastille = new cn_pastille([0,0],"switch_opening_positions.png");
		edit_box.add_pastille(axis_pastille);
		axis_pastille.svg_class = "pastille_background white";
		axis_pastille.title="Modifier la position de l'ouvrant";
		axis_pastille.clicked = function() {

			obj._transaction_manager.push_transaction("Positionnement d'ouvrant");
			const new_position = (openings[0].opening_position+1)%3;
			openings.forEach(opening => {
				obj._transaction_manager.push_item_set(opening,["opening_position"]);
				opening.opening_position = new_position;
			});
			obj.call("change");
		}

		//*** Type pastille */
        const is_fence = openings[0].opening_type.constructor === cn_fence_opening_type;
		const type_pastille = new cn_type_pastille(openings,"opening_type",function(){ return !is_fence ?
                map._building.get_opening_types() : map._building.get_fence_opening_types(); }, !is_fence ? 'Type de baie' : 'Type de clôture',map);
		type_pastille.title="Modifier le modèle de l'ouvrant";
		edit_box.add_pastille(type_pastille);

		edit_box.add_lock_pastille(obj._transaction_manager);

		edit_box.add_select_siblings_pastille("opening_type");
		edit_box.add_information_pastille("opening_type");
        this.transaction_increment = 0;
	}

	//***********************************************************************************
	//**** Force one measure
	//***********************************************************************************
	set_current_measure(v) {
		if (this._opening == null) return false;

		if (this._mouseover_measure == "") return false;
		if (typeof(this._measures[this._mouseover_measure]) == 'undefined') return false;
		var delta = v - this._measures[this._mouseover_measure][2];
		if (this._mouseover_measure.includes("after")) delta = -delta;
		var prev_pos = this._opening.position;
		this._opening.position += delta;
		if (!this._opening.check_wall())
		{
			this._opening.position = prev_pos;
			return false;
		}

		this._opening.position = prev_pos;
		this._transaction_manager.push_transaction("Positionnement d'ouvrant",this._opening.ID);
		this._transaction_manager.push_item_set(this._opening,"position",function() {this._opening.wall.build_dependant();});
		this._opening.position += delta;-
		this._map.refresh();
		this.call("change");
		return true;
	}

	//***********************************************************************************
	//**** Draws
	//***********************************************************************************
	draw(camera, draw_measures = true) {

		if (this._opening == null) return super.draw(camera);

		var html = "";

		var measure_opening = this._opening;
		var wall = measure_opening.wall;
		this._draw_measures = draw_measures;

        let html_measures = '';

		//*** draw measure before
		var opening_before = wall.opening_before(measure_opening.position);
		if (opening_before)
		{
			var p0 = cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,opening_before.position + opening_before.opening_type.width));
			var p1 = cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,measure_opening.position));
			this._measures["opening_before"] = [0,0,0];
			if (draw_measures)
				html_measures += camera.draw_measure(p0,p1,this._measures["opening_before"],this._mouseover_measure == "opening_before");
		}
		else
		{
			var p0 = cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,measure_opening.position));
			var p1 = cn_add(wall.bounds.pmax,cn_mul(wall.bounds.direction,measure_opening.position));
			this._measures["wall_before_min"] = [0,0,0];
			this._measures["wall_before_max"] = [0,0,0];
			if (draw_measures)
			{
				html_measures += camera.draw_measure(wall.shape[0],p0,this._measures["wall_before_min"],this._mouseover_measure == "wall_before_min");
				html_measures += camera.draw_measure(p1,wall.shape[1],this._measures["wall_before_max"],this._mouseover_measure == "wall_before_max");
			}
		}

		//*** draw measure after
		var opening_after = wall.opening_after(measure_opening.position + measure_opening.opening_type.width);
		if (opening_after)
		{
			var p0 = cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,measure_opening.position + measure_opening.opening_type.width));
			var p1 = cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,opening_after.position));
			this._measures["opening_after"] = [0,0,0];
			if (draw_measures)
                html_measures += camera.draw_measure(p0,p1,this._measures["opening_after"],this._mouseover_measure == "opening_after");
		}
		else
		{
			var p0 = cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,measure_opening.position + measure_opening.opening_type.width));
			var p1 = cn_add(wall.bounds.pmax,cn_mul(wall.bounds.direction,measure_opening.position + measure_opening.opening_type.width));
			this._measures["wall_after_min"] = [0,0,0];
			this._measures["wall_after_max"] = [0,0,0];
			if (draw_measures)
			{
				html_measures += camera.draw_measure(p0,wall.shape[3],this._measures["wall_after_min"],this._mouseover_measure == "wall_after_min");
				html_measures += camera.draw_measure(wall.shape[2],p1,this._measures["wall_after_max"],this._mouseover_measure == "wall_after_max");
			}
		}

		//*** draw oreitnation ad inside disk
		this._radius = this._opening.opening_type.width * camera.world_to_screen_scale;
		if (this._radius < camera.thumb_size) this._radius = camera.thumb_size;

		var c = cn_add(cn_middle(wall.bounds.pmin,wall.bounds.pmax), cn_mul(wall.bounds.direction,this._opening.position + 0.5 * this._opening.opening_type.width));
		this._center = camera.world_to_screen(c);
		this._dx = cn_sub(camera.world_to_screen(cn_add(c,wall.bounds.direction)),this._center);
		cn_normalize(this._dx);
		this._dx = cn_mul(this._dx,this._radius);
		var dy = cn_sub(camera.world_to_screen(cn_add(c,wall.bounds.normal)),this._center);
		cn_normalize(dy);
		this._dy = cn_mul(dy,this._radius);
		this._dx = cn_mul(this._dx,-1);

		const x_symetry = this._opening.opening_type.get_x_symetry();
		const y_symetry = this._opening.opening_type.get_y_symetry();
		if (!x_symetry || !y_symetry)
		{
			this.draw_disk = true;

			var points = [];
			points.push(cn_add(this._center,this._dx));
			points.push(cn_add(this._center,this._dy));
			points.push(cn_sub(this._center,this._dx));
			points.push(cn_sub(this._center,this._dy));

			this._opening_position = -1;
			if (this._opening.opening_start) this._opening_position++;
			if (this._opening.opening_inside) this._opening_position = 3 - this._opening_position;

			const was_opening_start = this._opening.opening_start;
			const was_opening_inside = this._opening.opening_inside;

			for (var n=0;n<4;n++)
			{
				const p0 = points[n];
				const p1 = points[(n+1)%4];

				var mouseover = false;
				var selected = false;

				if (n == this._mouseover) mouseover = true;;
				if (was_opening_start == this._start[n] && was_opening_inside == this._inside[n])
				{
					this._opening_position = n;
					selected = true;
				}

				var n1 = n;
				if (x_symetry || y_symetry)
				{
					n1 = (x_symetry)?(n<2)?1-n:5-n:3-n;
					selected = selected || (was_opening_start == this._start[n1] && was_opening_inside == this._inside[n1]);
					mouseover = mouseover || (n1 == this._mouseover) ;
				}

				var extra = "";
				if (mouseover) extra += " mouseover";
				if (selected) extra += " selected";
				html += `<path class="opening_disk${extra}" d="M ${this._center[0]} ${this._center[1]} L ${p1[0]} ${p1[1]} A ${this._radius} ${this._radius} 0 0 0 ${p0[0]} ${p0[1]} Z" />`;

				if (y_symetry && n1 < n) continue;
				if (x_symetry && n1 > n) continue;
				this._opening.opening_start = this._start[n];
				this._opening.opening_inside = this._inside[n];
				const nx = (x_symetry)?0:(n== 0 || n == 3)?0.4:-0.4;
				const ny = (x_symetry)?(n<2)?0.4:-0.4:(n<2)?0.5:-0.5;
				html += `<g opacity="0.5" transform="translate(${this._center[0] + this._dx[0]*nx+ this._dy[0]*ny},${this._center[1] + this._dx[1]*nx+ this._dy[1]*ny}) scale(0.3,0.3)  translate(${-this._center[0]},${-this._center[1]})">`;
				html += this._opening.draw(camera,[]);
				html += `</g>`;
			}
			this._opening.opening_start = was_opening_start;
			this._opening.opening_inside = was_opening_inside;
		}
		else
			this._draw_disk = false;

		var world_radius = this._radius / camera.world_to_screen_scale;
		var dist = this._opening.wall.wall_type.thickness;
		if (this._draw_disk) dist += world_radius;

		//*** draw opening */
		if (this._mouseover_opening)
			html += this._opening.draw(camera, ["mouseover"]);
		else
			html += this._opening.draw(camera, ["selected"]);

		//*** rec */
		html += super.draw(camera);
        html += html_measures;
		return html;
	}

	//***********************************************************************************
	//**** clear move effects
	//***********************************************************************************
	clear_move() {
		this._mouseover_opening = false;
		this._mouseover = -1;
		this._mouseover_measure = "";
		super.clear_move();
	}

	/**
	 * Manage a click. Only after a grab that returned true, and no drag. To return 'true' if click had an effect.
	 * @param {cn_mouse_event} mouse_event
	 * @returns {boolean}
	 */
	 click(mouse_event) {
		var obj = this;

		//*** Click on edit box */
		if (super.click(mouse_event)) return true;

		//*** click on measures */
		this.move(mouse_event);
		if (this._mouseover_measure != "")
		{
			const input = new cn_number_input("Modifier la distance",this._measures[this._mouseover_measure][2]);
			input.callback = function() {obj.set_current_measure(input.value);};
			input.decimals=3;
			input.unit = "m";
			input.min_value=0;
			this.call("number_input",input);
			return true;
		}

		//*** click on disk */
		if (this._mouseover >= 0)
		{
			if (this._mouseover == this._opening_position) return true;
			this._transaction_manager.push_transaction("Orientation d'ouvrant");
			this._transaction_manager.push_item_set(this._opening,["opening_start","opening_inside"]);

			this._opening.opening_start = this._start[this._mouseover];
			this._opening.opening_inside = this._inside[this._mouseover];
			this.call("change");
			return true;
		}
		return false;
	}

	grab (mouse_event) {
		this._grabbed = false;
		if (super.grab(mouse_event)) return true;

		this._grabbed = (this._scene.find_opening(mouse_event.mouse_world)== this._opening);
        this.transaction_increment = (this.transaction_increment + 1) % 100;
		return this.move(mouse_event);
	}

	drag (ev) {
		if (!this._grabbed)
		{
			if (super.drag(ev)) return true;
			return false;
		}

		var opening = this._opening;
		if (opening.locked) return false;

		var old_position = opening.position;
		var old_wall = opening.wall;
		var new_wall = this._scene.find_wall(ev.mouse_world);
		if (new_wall && new_wall != old_wall)
			opening.wall = new_wall;
		else
			new_wall = old_wall;
		if (!opening.optimize_placement(ev.mouse_world, ev.camera.snap_world_distance))
		{
			opening.position = old_position;
			opening.wall = old_wall;
		}
		else
		{
			var new_position = opening.position;
			opening.position = old_position;
			opening.wall = old_wall;
			this._transaction_manager.push_transaction("Déplacement d'ouvrant",opening.ID + this.transaction_increment,function() {opening.wall.build_dependant();});
			this._transaction_manager.push_item_set(opening,"position");
			opening.position = new_position;
			if (new_wall != old_wall)
			{
				this._transaction_manager.push_item_set(opening,"wall");
				this._transaction_manager.push_item_set(old_wall,"openings");
				this._transaction_manager.push_item_set(new_wall,"openings");
				opening.wall = new_wall;
				var index = old_wall.openings.indexOf(opening);
				if (index >= 0) old_wall.openings.splice(index,1);
				new_wall.openings.push(opening);
			}
			this.call("change");
		}
		return true;
	}

	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} mouse_event
	 * @returns  {boolean}
	 */
	move (mouse_event) {
		if (super.move(mouse_event)) return true;

        //*** check measures */
        if (this._find_measure(mouse_event)) return true;

		//*** check opening for drag */
		if (this._opening && this._scene.find_opening(mouse_event.mouse_world) == this._opening)
		{
			this._mouseover_opening = true;
			return true;
		}

		//*** check position disk */
		if (this._draw_disk)
		{
			var d = cn_sub(mouse_event.mouse_screen,this._center);
			if (cn_size(d) < this._radius)
			{
				const x = cn_dot(d,this._dx);
				const y = cn_dot(d,this._dy);
				this._mouseover = 0;
				if (x < 0) this._mouseover = 1;
				if (y < 0) this._mouseover = 3-this._mouseover;
				return true;
			}
		}

		return false;
	}

	_find_measure(ev) {
		if (!this._draw_measures) return false;
		for (var key in this._measures)
		{
			var mes = this._measures[key];
			if (Math.abs(mes[0] - ev.mouse_screen[0]) > 30) continue;
			if (Math.abs(mes[1] - ev.mouse_screen[1]) > 10) continue;
			this._mouseover_measure = key;
			return true;
		}
		return false;
	}
}


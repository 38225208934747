import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Legend } from '../../../../../model/polluant.model';
import {
    PERIMETRE_INTERVENTION,
    ZONE_TRAVAUX,
    UMD,
    POINTILLE,
    CONTINU,
    POINT_PAR_POINT,
    RECTANGLE,
    CERCLE,
} from '../../../../../shared/polluant.constants';
import { RGBA } from 'ngx-color';

class LegendModalData {
    constructor(public newLegend: boolean, public legend?: Legend) {}
}

@Component({
    selector: 'app-add-legend-modal',
    templateUrl: './add-legend-modal.component.html',
    styleUrls: ['./add-legend-modal.component.scss'],
})
export class AddLegendModalComponent implements OnInit {
    legends = [PERIMETRE_INTERVENTION, ZONE_TRAVAUX, UMD];
    shapetypes = [RECTANGLE, CERCLE, POINT_PAR_POINT];
    bordertypes = [POINTILLE, CONTINU];
    colors = ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#DCE775', '#FF8A65', '#BA68C8'];
    opacity: number = 100;

    legend: Legend = new Legend();
    disabledShapeTypeAndTemplate: boolean = false;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<AddLegendModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LegendModalData,
        private formBuilder: FormBuilder
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth600-dialog');
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            shapeType: ['', Validators.required],
            borderType: ['', Validators.required],
        });

        if (!this.data.newLegend) {
            this.legend = Object.assign({}, this.data.legend);
            this.disabledShapeTypeAndTemplate = this.data.legend.shapeType === POINT_PAR_POINT;
        } else {
            this.legend.backgroundColor = { r: 217, g: 227, b: 240, a: 1 };
            this.legend.borderColor = { r: 217, g: 227, b: 240, a: 1 };
        }
    }

    /**
     * Applique un modèle prédéfini à la légende courante
     */
    changeTemplate(template: Legend) {
        this.legend.name = template.name;
        this.legend.shapeType = template.shapeType;
        this.legend.borderType = template.borderType;
        this.legend.backgroundColor = template.backgroundColor;
        this.legend.borderColor = template.borderColor;
    }

    /**
     * Rend transparente les bordures
     */
    transparent(event) {
        event.checked
            ? (this.legend.borderColor = { r: 255, g: 255, b: 255, a: 0 })
            : (this.legend.borderColor = { r: 217, g: 227, b: 240, a: 1 });
    }

    /**
     * Transforme une couleur hexadécimal en objet RGBA
     */
    hexToRGB(hex: string) {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (result) {
            let r = parseInt(result[1], 16);
            let g = parseInt(result[2], 16);
            let b = parseInt(result[3], 16);
            return { r: r, g: g, b: b, a: 1 };
        }
        return hex;
    }

    /**
     *
     */
    redefineColors() {
        if (typeof this.legend.backgroundColor === 'string') {
            let rgb = this.hexToRGB(this.legend.backgroundColor);
            this.legend.backgroundColor = rgb as RGBA;
            this.legend.backgroundColor.a = this.opacity;
        }
        if (typeof this.legend.borderColor === 'string') {
            let rgb = this.hexToRGB(this.legend.borderColor);
            this.legend.borderColor = rgb as RGBA;
        }
    }

    /**
     * Permet de désactiver le type de trait 'Point par point' si c'est une édition de Légende
     */
    isOnEdit(type) {
        return !this.data.newLegend && type === POINT_PAR_POINT;
    }

    /**
     * Ferme la pop-up sans renvoyer de données intéressantes
     */
    cancel() {
        this.dialogRef.close(false);
    }

    /**
     * Valide la pop-up puis la ferme en renvoyant des données intéressantes
     */
    confirm() {
        this.dialogRef.close(this.legend);
    }
}

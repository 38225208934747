import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Polluant } from '../../../../../model/polluant.model';
import { MpcaService } from '../../../../../services/mpca.service';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-mpca-material-selection-modal',
    templateUrl: './mpca-material-selection-modal.component.html',
    styleUrls: ['./mpca-material-selection-modal.component.scss'],
})
export class MpcaMaterialSelectionModalComponent implements OnInit {
    materiauxConfig: any;
    materiauxTree: any = [];
    searchResult = {
        categorie: [],
        ouvrageOuComposant: [],
        materiaux: [],
    };
    diagnostic: Diagnostic;
    diagnosticPolluant: Polluant;

    selectedCategorie: any;
    selectedOuvrageComposant: any;
    selectedMateriaux: any;

    formModal: FormGroup;

    material: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<MpcaMaterialSelectionModalComponent>,
        private mpcaFormService: MpcaService,
        injector: Injector
    ) {
        this.materiauxConfig = data.materiauxConfig;
        this.diagnostic = data.diagnostic;

        this.material = data.material;
    }

    ngOnInit(): void {
        this.diagnosticPolluant = this.diagnostic.contenuDiagnostic as Polluant;

        this.materiauxTree = this.createMateriauxTree(this.materiauxConfig.items);

        // Si un materiaux est déjà sélectionné, alors on l'affiche dans les colonnes
        if (this.material) {
            console.log(this.materiauxTree);
            console.log(this.material);
            const cat = this.materiauxTree.find((c) => c.nom == this.material.categorie);
            if (!cat) return;

            this.selectCategorie(cat);

            const oc = cat.ouvrageOuComposant.find((o) => o.nom == this.material.ouvrageOuComposant);
            if (!oc) return;

            this.selectOuvrageComposant(oc);

            const m = oc.partieOuvrageOuComposant.find((m) => m.nom == this.material.nom);

            this.selectMateriaux(m);
        }

        this.formModal = new FormGroup({
            searchInput: new FormControl(''),
        });
        this.formModal.valueChanges.pipe(debounceTime(300)).subscribe(({ searchInput }) => {
            searchInput = searchInput.trim().toLowerCase();

            // Réinitialisation des résultats de recherche
            let result = {
                categorie: [],
                ouvrageOuComposant: [],
                materiaux: [],
            };
            this.searchResult = result;

            this.materiauxConfig.items.forEach((item) => {
                const [cat, oc, mat] = item.split(' - ');

                if (
                    cat.toLowerCase().indexOf(searchInput) > -1 &&
                    result.categorie.find((c) => c.toLowerCase() == cat.toLowerCase()) == undefined
                ) {
                    result.categorie.push(cat);
                }

                if (
                    oc.toLowerCase().indexOf(searchInput) > -1 &&
                    result.ouvrageOuComposant.find(
                        (o) => o.toLowerCase() == cat.toLowerCase() + ' - ' + oc.toLowerCase()
                    ) == undefined
                ) {
                    result.ouvrageOuComposant.push(cat + ' - ' + oc);
                }

                if (
                    mat.toLowerCase().indexOf(searchInput) > -1 &&
                    result.materiaux.find(
                        (m) =>
                            m.toLowerCase() == cat.toLowerCase() + ' - ' + oc.toLowerCase() + ' - ' + mat.toLowerCase()
                    ) == undefined
                ) {
                    result.materiaux.push(cat + ' - ' + oc + ' - ' + mat);
                }
            });

            this.searchResult = result;
        });
    }

    clearSearch() {
        // Réinitialise les résultats
        this.searchResult = {
            categorie: [],
            ouvrageOuComposant: [],
            materiaux: [],
        };

        // Réinitialise le champ pour masquer le panneau de recherche
        this.formModal.get('searchInput').setValue('');
    }

    createMateriauxTree(items) {
        let materiauxTree = [];

        items.forEach((mat: string) => {
            let split = mat.split(' - ');

            let categorie = materiauxTree.find((m) => m.nom == split[0]);
            if (categorie == undefined) {
                materiauxTree.push({
                    nom: split[0],
                    ouvrageOuComposant: [],
                });
                categorie = materiauxTree[materiauxTree.length - 1];
            }

            let ouvrageOuComposant = categorie.ouvrageOuComposant.find((m) => m.nom == split[1]);
            if (ouvrageOuComposant == undefined) {
                categorie.ouvrageOuComposant.push({
                    nom: split[1],
                    partieOuvrageOuComposant: [],
                });
                ouvrageOuComposant = categorie.ouvrageOuComposant[categorie.ouvrageOuComposant.length - 1];
            }

            ouvrageOuComposant.partieOuvrageOuComposant.push({
                nom: split[2],
            });
        });

        return materiauxTree;
    }

    selectCategorie(categorie) {
        this.selectedCategorie = categorie;
        this.selectOuvrageComposant(null);
    }

    selectOuvrageComposant(ouvrageOuComposant) {
        this.selectedOuvrageComposant = ouvrageOuComposant;
        this.selectMateriaux(null);
    }

    selectMateriaux(materiaux) {
        this.selectedMateriaux = materiaux;
    }

    clickSearchResult(type: 'materiaux' | 'ouvrageComposant' | 'categorie', value) {
        let cat, oc, mat;
        let categorie, ouvrageComposant;

        if (type == 'categorie') {
            cat = value;
        }

        if (type == 'ouvrageComposant') {
            [cat, oc] = value.split(' - ');
        }

        if (type == 'materiaux') {
            [cat, oc, mat] = value.split(' - ');
        }

        if (cat) {
            categorie = this.materiauxTree.find((c) => c.nom == cat);
            this.selectCategorie(categorie);
        }

        if (cat && oc) {
            ouvrageComposant = categorie.ouvrageOuComposant.find((o) => o.nom == oc);
            this.selectOuvrageComposant(ouvrageComposant);
        }

        if (mat) {
            const materiaux = ouvrageComposant.partieOuvrageOuComposant.find((m) => m.nom == mat);
            this.selectMateriaux(materiaux);
        }

        this.formModal.get('searchInput').setValue('');
    }

    /**
     * @description Sauvegarde des nouvelles données du formulaire dans le diagnostic
     * @private
     */
    public selectAndClose() {
        this.dialogRef.close({
            materiaux: {
                nom: this.selectedMateriaux.nom,
                categorie: this.selectedCategorie.nom,
                ouvrageOuComposant: this.selectedOuvrageComposant.nom,
            },
        });
    }

    public cancel() {
        this.dialogRef.close();
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_gbxml converter :
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Global functions :
//***********************************************************************************

import { fh_add, fh_clone, fh_mul, fh_polygon } from "@acenv/fh-3d-viewer";
import { cn_building } from "../model/cn_building";
import { cn_opening_type, code_to_label } from "../model/cn_opening_type";
import { ROOF_OPENING_CATEGORY_LIST, cn_roof_opening_type } from "../model/cn_roof_opening_type";
import { cn_space } from "../model/cn_space";
import { cn_storey } from "../model/cn_storey";
import { cn_wall_type } from "../model/cn_wall_type";
import { cn_clone, cn_dist, cn_middle, cn_normal, cn_simplify_contour, cn_sub, cn_uuid, cnx_clone } from "./cn_utilities";
import { fh_xml } from "./fh_xml";

export function cn_to_gbxml(building) {
    var gbxml = new cn_gbxml(building);
    return gbxml.to_dom().serialize();
}

//***********************************************************************************
//***********************************************************************************
//**** cn_gbxml class :
//***********************************************************************************
//***********************************************************************************

class cn_gbxml {
    //********************************************************************************
    //**** Constructor
    //********************************************************************************
    constructor(building) {
        this._building = building;
        this._layers = [];
        this._storey = null;
        this.space_zone_map = new Map();
    }

    //********************************************************************************
    //**** Main function
    //********************************************************************************
    to_dom() {
        this._building.rename_storeys();
        this._building.update_roofs();
        this._building.compute_altitudes();

        this._root = new fh_xml("gbXML");
        this._root.set_attribute("xmlns", "http://www.gbxml.org/schema");
        this._root.set_attribute("temperatureUnit", "C");
        this._root.set_attribute("lengthUnit", "Meters");
        this._root.set_attribute("areaUnit", "SquareMeters");
        this._root.set_attribute("volumeUnit", "CubicMeters");
        this._root.set_attribute("useSIUnitsForResults", "true");
        this._root.set_attribute("version", "5.10");

        this._building.zones['usage'].forEach(zone => {
            zone.rooms.forEach(room => {
                if (!this.space_zone_map.has(room.storey)) {
                    this.space_zone_map.set(room.storey, new Map());
                }
                this.space_zone_map.get(room.storey).set(room.space, zone.ID);
            });
            this._build_zone(zone.ID, zone.name);
        });

        var campus = new fh_xml("Campus");
        campus.set_attribute('id', cn_building.create_unique_id(this._building.ID));
        this._gbxml_campus = campus;
        this._root.append_child(campus);

        this._gbxml_building = new fh_xml("Building");
        campus.append_child(this._gbxml_building);
        this._gbxml_building.add_name_and_id(this._building);

        var h = 0;

        this._gbxml_building.set_attribute("buildingType", "Unknown");

        this._ceiling_slabs = [];
        this._full_ceiling_slab = null;

        for (var nbs = 0; nbs < this._building.storeys.length; nbs++) {
            this._current_storey = this._building.storeys[nbs];
            this._h0 = this._current_storey.altitude;
            this._h1 = this._current_storey.roof_altitude;
            const building_storey_id = this._build_building_storey();
            this._build_storey(building_storey_id);

            h = this._h1;
        }

        //*** Build wall types */
        this._layers = [];
        var wall_types = this._building.get_wall_types();
        for (var i in wall_types)
            this._build_element_type(wall_types[i]);

        var opening_types = this._building.get_opening_types();
        for (var i in opening_types)
            this._build_element_type(opening_types[i]);

        this._building.get_roof_opening_types().forEach(ot => this._build_element_type(ot));

        return this._root;
    }

    _build_zone(id, name) {
        const gbxml_zone = new fh_xml("Zone");
        gbxml_zone.set_attribute("id", id);
        gbxml_zone.append_child(new fh_xml("Name", name));
        this._root.append_child(gbxml_zone);
    }

    _build_building_storey() {
        const gbxml_building_storey = new fh_xml('BuildingStorey');
        const id = cn_building.create_unique_id(this._current_storey.ID, 'buildingStorey')
        gbxml_building_storey.set_attribute('id', id);
        const storey_planar_geometry = this._current_storey.build_slab_polygon(this._h0 + 1.5, false, false);
        gbxml_building_storey.append_child(new fh_xml('Level', (this._h0 + 1.5).toFixed(3)));
        const gbxml_planar_geometry = new fh_xml('PlanarGeometry');
        gbxml_planar_geometry.append_child(this._polygon_to_polyloop(storey_planar_geometry));
        gbxml_building_storey.append_child(gbxml_planar_geometry);
        this._gbxml_building.append_child(gbxml_building_storey);
        return id;
    }

    /**
     * build construction type for an opening or roof opening element type
     * @param {*} element_type
     * @returns
     */
    _get_gbxml_construction(element_type)
    {
        if (element_type.gbxml_construction) return element_type.gbxml_construction;
        let gbxml_construction = {};

        // @ts-ignore
        element_type.gbxml_construction = gbxml_construction;
        if (element_type.constructor == cn_opening_type)
        {
            if (element_type.free)
            {
                gbxml_construction.openingType = "Air";
                gbxml_construction.name = "Baie libre";
            }
            else if (element_type.category == "door")
            {
                if (element_type.opening == "sliding")
                    gbxml_construction.openingType = "SlidingDoor";
                else
                    gbxml_construction.openingType = "NonSlidingDoor";

                if (element_type.glazing == "none")
                {
                    gbxml_construction.name = "Porte";
                    gbxml_construction.ref = "constructionIdRef";
                }
                else
                {
                    gbxml_construction.name = "Porte vitrée";
                    gbxml_construction.ref = "windowTypeIdRef";
                }
            }
            else if (element_type.category == "window")
            {
                if (element_type.opening == "none")
                    gbxml_construction.openingType = "FixedWindow";
                else
                gbxml_construction.openingType = "OperableWindow";
                gbxml_construction.name = "Fenêtre";
                gbxml_construction.ref = "windowTypeIdRef";
            }
        }
        else if (element_type.constructor == cn_roof_opening_type)
        {
            if (element_type.category == "window")
            {
                if (element_type.opening == "none")
                    gbxml_construction.openingType = "FixedWindow";
                else
                    gbxml_construction.openingType = "OperableWindow";
                gbxml_construction.name = "Fenêtre de toit";
                gbxml_construction.ref = "windowTypeIdRef";
            }
            else
            {
                if (element_type.opening == "none")
                    gbxml_construction.openingType = "FixedSkylight";
                else
                    gbxml_construction.openingType ="OperableSkylight";
                gbxml_construction.name = code_to_label(element_type.category,ROOF_OPENING_CATEGORY_LIST);
                gbxml_construction.ref = "windowTypeIdRef";
            }
        }
        return element_type.gbxml_construction;
    }

    //********************************************************************************
    //**** Build wall type
    //********************************************************************************
    _build_element_type(element_type) {

        let gbxml_construction = {};
        if (element_type.constructor == cn_opening_type || element_type.constructor == cn_roof_opening_type)
            gbxml_construction = this._get_gbxml_construction(element_type)

        function create_glass_layer(index) {
            let glazing = new fh_xml("Glaze");
            glazing.set_attribute("id", cn_building.create_unique_id(element_type.ID,"Glaze",index));
            let thickness = new fh_xml("Thickness","0.004");
            thickness.set_attribute("unit","Meters");
            glazing.append_child(thickness);
            return glazing;
        }

        function create_gas_layer(index) {
            let gaz_info = element_type.glazing_gaz.split("_");
            let gap = new fh_xml("Gap");
            gap.set_attribute("id", cn_building.create_unique_id(element_type.ID,"Gap",index));
            gap.set_attribute("gas", (gaz_info[0] == "air")?"Air":"Argon");
            let thickness = new fh_xml("Thickness",(0.001 * parseInt(gaz_info[1])).toFixed(3));
            thickness.set_attribute("unit","Meters");
            gap.append_child(thickness);
            return gap;
        }
        
        const cn_frame_list = ["alu","pvc","bois","steel"];
        const gbxml_frame_list = ["Aluminium","Vinyl","Wood","Aluminium"];
        const index = cn_frame_list.indexOf(element_type.frame);
        var gbxml_frame = "";
        if (index >= 0)
        {
            if ((index == 0 || index == 3) && element_type.frame_quality)
                gbxml_frame = "AluminiumWithBreak";
            else
            gbxml_frame = gbxml_frame_list[index];
        }

        var construction;
        if (gbxml_construction.ref == "windowTypeIdRef")
        {
            construction = new fh_xml("WindowType");
            if (element_type.compute_physics)
            {
                element_type.compute_physics();

                let u_value = new fh_xml("U-value", element_type.Uw.toFixed(3));
                u_value.set_attribute("unit", "WPerSquareMeterK");
                construction.append_child(u_value);

                let solar_factor = new fh_xml("SolarHeatGainCoeff", element_type.Sw.toFixed(3));
                solar_factor.set_attribute("unit", "Fraction");
                solar_factor.set_attribute("solarIncidentAngle", "0");
                construction.append_child(solar_factor);

                let transmission = new fh_xml("Transmittance", element_type.Tl.toFixed(3));
                transmission.set_attribute("unit", "Fraction");
                transmission.set_attribute("type", "Visible");
                construction.append_child(transmission);
                
                construction.append_child(create_glass_layer(0));

                if (element_type.glazing == "double" || element_type.glazing == "triple")
                {
                    construction.append_child(create_gas_layer(0));
                    construction.append_child(create_glass_layer(1));
                        
                    if (element_type.glazing == "triple")
                    {
                        construction.append_child(create_gas_layer(1));
                        construction.append_child(create_glass_layer(2));
                    }
                }

                if (gbxml_frame != "")
                {
                    let frame = new fh_xml("Frame");
                    frame.set_attribute("id",cn_building.create_unique_id(element_type.ID,"Frame"));
                    frame.set_attribute("type",gbxml_frame);
                    construction.append_child(frame);
                }
            }
        }
        else
        {
            construction = new fh_xml("Construction");
            
            if (element_type.compute_physics)
            {
                element_type.compute_physics();
                let u_value = new fh_xml("U-value", element_type.Uw.toFixed(3));
                u_value.set_attribute("unit", "WPerSquareMeterK");
                construction.append_child(u_value);
                
                if (element_type.Sw > 0)
                {
                    let solar_factor = new fh_xml("Transmittance", element_type.Sw.toFixed(3));
                    solar_factor.set_attribute("unit", "Fraction");
                    solar_factor.set_attribute("type", "Solar");
                    construction.append_child(solar_factor);
                }

                if (element_type.Tl > 0)
                {
                    let transmission = new fh_xml("Transmittance", element_type.Tl.toFixed(3));
                    transmission.set_attribute("unit", "Fraction");
                    transmission.set_attribute("type", "Visible");
                    construction.append_child(transmission);
                }
            }
            if (gbxml_frame != "")
                construction.append_child(new fh_xml("Description", gbxml_frame));
        }
        this._root.append_child(construction);
        construction.set_attribute("id", element_type.ID);
        construction.append_child(new fh_xml("Name", element_type.get_label()));

        if (element_type.constructor == cn_wall_type) {
            var layers = element_type.layers;

            for (var i in layers) {
                var layer = this._add_layer(layers[i]);
                var layer_id = new fh_xml("LayerId");
                layer_id.set_attribute("layerIdRef", layer.ID);
                construction.append_child(layer_id);
            }
        }
    }

    //********************************************************************************
    //**** Add materials
    //********************************************************************************
    _add_layer(cn_layer) {
        var code = cn_layer.name + cn_layer.code + cn_layer.thickness;
        for (var i in this._layers) {
            if (this._layers[i].code == code) return this._layers[i];
        }

        var layer = {};
        layer.code = code;
        layer.ID = cn_uuid("material" + this._layers.length);
        this._layers.push(layer);

        var mat = new fh_xml("Material");
        this._root.append_child(mat);
        mat.set_attribute("id", layer.ID + "M");

        mat.append_child(new fh_xml("Name", cn_layer.name));

        var thickness = new fh_xml("Thickness", cn_layer.thickness);
        thickness.set_attribute("unit", "Meters");
        mat.append_child(thickness);

        var conductivity = new fh_xml("Conductivity", cn_layer.conductivity);
        conductivity.set_attribute("unit", "WPerMeterK");
        mat.append_child(conductivity);

        var density = new fh_xml("Density", cn_layer.density);
        density.set_attribute("unit", "KgPerCubicM");
        mat.append_child(density);

        var specific_heat = new fh_xml("SpecificHeat", cn_layer.specific_heat);
        specific_heat.set_attribute("unit", "JPerKgK");
        mat.append_child(specific_heat);

        var gb_layer = new fh_xml("Layer");
        this._root.append_child(gb_layer);
        gb_layer.set_attribute("id", layer.ID);
        gb_layer.append_child(new fh_xml("Name", cn_layer.name));
        var gb_material_id = new fh_xml("MaterialId");
        gb_material_id.set_attribute("materialIdRef", layer.ID + "M");
        gb_layer.append_child(gb_material_id);

        return layer;
    }

    //********************************************************************************
    //**** Build zone
    //********************************************************************************
    _build_storey(building_storey_id) {
        console.log("building storey " + this._current_storey.storey_index);
        var scene = this._current_storey.scene;
        const current_zones_space = this.space_zone_map.get(this._current_storey.ID) || new Map();
        if (scene.spaces.some(space => !space.outside && !current_zones_space.get(space.ID))) {
            this._build_zone(this._current_storey.ID, this._current_storey.get_storey_name());
        }

        //***************************************
        //*** Build ceiling slabs
        //***************************************
        var ceiling_slabs = [];
        var full_ceiling_slab = new fh_polygon([0,0,0],[0,0,1]);
        for (var i in scene.spaces) {
            var space = scene.spaces[i];
            if (space.outside) continue;
            if (!space.has_roof) continue;
            var pg = space.build_slab_polygon(0, true, false);
            var pgs = pg.split();
            for (var k in pgs) {
                pgs[k].compute_contours();
                pgs[k].space = space;
                pgs[k].vertical = false;
                ceiling_slabs.push(pgs[k]);
            }
            full_ceiling_slab.unites(space.build_slab_polygon(0, false, false));
        }

        //***************************************
        //*** Build roof
        //***************************************
        var roof = this._current_storey.roof;
        this._roof_height = this._h1;
        if (roof) {
            //*** Build geometry of roof openings */
            roof.openings.forEach(op => op.gbxml_polygon = op.build_3d_opening(this._h1));

            //*** Then loop on roof slabs */
            for (var i in roof.slabs) {
                var slab = roof.slabs[i];
                const slab_openings = roof.openings.filter(op => op.slab == slab);
                //*** Build roof slab polygon */
                var roof_polygon = slab.build_3d_polygon(this._h1);

                //*** Check intersection with ceiling slabs */
                for (var j in ceiling_slabs) {
                    var ceiling_slab = ceiling_slabs[j].clone();
                    ceiling_slab.project(roof_polygon.get_point(), roof_polygon.get_normal(), [0, 0, 1]);
                    ceiling_slab.intersects(roof_polygon);
                    if (ceiling_slab.get_area() < 0.01) continue;

                    //*** remove that surface from roof */
                    roof_polygon.substracts(ceiling_slab);

                    //*** Add roof surface */
                    var split_ceiling_slab = ceiling_slab.split();
                    for (var sc = 0; sc < split_ceiling_slab.length; sc++) {
                        ceiling_slab = split_ceiling_slab[sc];
                        ceiling_slab.spaces = [ceiling_slabs[j].space, null];
                        ceiling_slab.ID = cn_building.create_unique_id(this._storey, slab, ceiling_slabs[j].space, sc);
                        ceiling_slab.CADID = cn_building.create_unique_id(this._storey, ceiling_slabs[j].space, null);
                        const surface = this._build_surface(ceiling_slab);
                        slab_openings.forEach(op => {
                            var opening_pg = op.gbxml_polygon.clone();
                            opening_pg.intersects(ceiling_slab);
                            if (opening_pg.get_area() > 0.1) {
                                const gbxml_opening = this._build_opening(op, opening_pg, sc);
                                surface.append_child(gbxml_opening);
                            }
                        });
                    }
                }
            }

            //*** Build specific facade walls on roof line discontinuities */
            for (var i in this._current_storey.roof.lines) {
                var line = this._current_storey.roof.lines[i];
                if (line.is_border()) continue;
                var discontinuity = false;
                for (var nv = 0; nv < 2; nv++) {
                    var h0 = line.slabs[0].compute_height(line.vertices[nv].position);
                    var h1 = line.slabs[1].compute_height(line.vertices[nv].position);
                    if (Math.abs(h0 - h1) < 0.01) continue;
                    discontinuity = true;
                }
                if (!discontinuity) continue;

                var low_h = [0, 0];
                var high_h = [0, 0];
                var high_slab = [-1, -1];
                for (var nv = 0; nv < 2; nv++) {
                    var h0 = this._h1 + line.slabs[0].compute_height(line.vertices[nv].position);
                    var h1 = this._h1 + line.slabs[1].compute_height(line.vertices[nv].position);
                    low_h[nv] = (h0 > h1) ? h1 : h0;
                    high_h[nv] = (h0 > h1) ? h0 : h1;
                    if (high_h[nv] - low_h[nv] > 0.01)
                        high_slab[nv] = (h0 > h1) ? 0 : 1;
                }

                if (high_slab[0] < 0 && high_slab[1] < 0) continue;

                var p0 = cn_clone(line.vertices[0].position);
                p0.push(low_h[0]);
                p0.push(high_h[0]);
                var p1 = cn_clone(line.vertices[1].position);
                p1.push(low_h[1]);
                p1.push(high_h[1]);

                //*** maybe intermediate point ? */
                if (high_slab[0] >= 0 && high_slab[1] >= 0 && high_slab[0] != high_slab[1]) {
                    var dh0 = high_h[0] - low_h[0];
                    var dh1 = high_h[1] - low_h[1];
                    var x = dh0 / dh1 / (1 + dh0 / dh1);
                    var p1h = fh_clone(p1);
                    p1h[2] = p1[3];
                    var pp = fh_add(fh_mul(p0, 1 - x), fh_mul(p1h, x));
                    pp.push(pp[2]);

                    this._build_extra_wall(p0, pp, null, cn_building.create_unique_id(line, "0"), this._current_storey);
                    this._build_extra_wall(pp, p1, null, cn_building.create_unique_id(line, "1"), this._current_storey);
                }
                //*** regular case */
                else {
                    this._build_extra_wall(p0, p1, null, cn_building.create_unique_id(line), this._current_storey);
                }
            }
        }

        //*** roof volume
        this._current_storey.build_roof_volume();
        if (this._current_storey.roof_volume) {
            var box = this._current_storey.roof_volume.get_bounding_box();
            this._roof_height = box.position[2] + box.size[2];
        }

        //***************************************
        //*** Build spaces
        //***************************************
        for (var i in scene.spaces)
            this._build_space(scene.spaces[i], i, current_zones_space, building_storey_id);

        //***************************************
        //*** Build walls
        //***************************************
        for (var i in scene.walls)
            this._build_wall(scene.walls[i]);

        //***************************************
        //*** Build floor polygons
        //***************************************
        var floor_slabs = [];
        var outside_polygon = null;
        for (var i in scene.spaces) {
            var space = scene.spaces[i];
            if (space.outside) {
                outside_polygon = space.build_slab_polygon(this._h0, true, false);
                continue;
            }
            var pg = space.build_slab_polygon(this._h0 + space.slab_offset, true, false);
            var pgs = pg.split();
            for (var k in pgs) {
                pgs[k].compute_contours();
                pgs[k].space = space;
                floor_slabs.push(pgs[k]);
            }
        }

        //***************************************
        //*** Build floor slabs
        //***************************************
        for (var i in floor_slabs) {
            var floor_slab = floor_slabs[i].clone();
            for (var j in this._ceiling_slabs) {
                var slab = floor_slab.clone();
                slab.intersects(this._ceiling_slabs[j]);
                if (slab.get_area() < 0.01) continue;
                var slabs = slab.split();
                for (var k in slabs) {
                    var sl = slabs[k];
                    sl.spaces = [this._ceiling_slabs[j].space, floor_slabs[i].space];
                    sl.storeys = [this._ceiling_slabs[j].storey, this._current_storey];
                    sl.vertical = false;
                    sl.ID = cn_building.create_unique_id(this._storey, sl, sl.spaces[0], sl.spaces[1], k, i, j);
                    sl.CADID = cn_building.create_unique_id(this._storey, sl.spaces[0], sl.spaces[1]);
                    this._build_surface(sl);
                }
            }
            if (this._full_ceiling_slab)
                floor_slab.substracts(this._full_ceiling_slab);
            if (floor_slab.get_area() > 0.01) {
                var pgs = floor_slab.split();
                for (var k in pgs) {
                    var floor_s = pgs[k];
                    floor_s.spaces = [null, floor_slabs[i].space];
                    floor_s.vertical = false;
                    floor_s.ID = cn_building.create_unique_id(this._storey, floor_slabs[i].space, i, k);
                    floor_s.CADID = cn_building.create_unique_id(this._storey, null, floor_slabs[i].space);
                    this._build_surface(floor_s);
                }
            }
        }
        this._ceiling_slabs = ceiling_slabs;
        for (var i in this._ceiling_slabs)
            this._ceiling_slabs[i].storey = this._current_storey;
        this._full_ceiling_slab = full_ceiling_slab;
    }

    //********************************************************************************
    //**** Build space
    //********************************************************************************
    /**
     *
     * @param {number[]} p0
     * @param {number[]} p1
     * @param {cn_space} space
     * @param {string} id
     * @param {cn_storey} storey
     */
    _build_extra_wall(p0, p1, space, id, storey) {
        const scene_below = storey.scene;
        const impacts = scene_below.pathtrace(p0, p1);
        const length = cn_dist(p0, p1);
        var normal = cn_normal(cn_sub(p1, p0));
        normal.push(0);

        var spaces_under = [null, null]
        if (impacts.length == 0)
            spaces_under[0] = spaces_under[1] = scene_below.find_space(cn_middle(p0, p1));
        else {
            spaces_under[0] = impacts[0].spaces[0];
            spaces_under[1] = impacts[impacts.length - 1].spaces[1];
        }
        impacts.splice(0, 0, { point: cn_clone(p0), distance: 0, spaces: [null, spaces_under[0]] })
        impacts.push({ point: cn_clone(p1), distance: length, spaces: [spaces_under[1], null] });

        for (var n = 0; n < impacts.length; n++) {
            var t = impacts[n].distance / length;
            impacts[n].point.push(p0[2] * (1 - t) + p1[2] * t);
            impacts[n].point.push(p0[3] * (1 - t) + p1[3] * t);
        }

        for (var n = 0; n < impacts.length - 1; n++) {
            var p00 = fh_clone(impacts[n].point);
            var p10 = fh_clone(impacts[n + 1].point);
            var p01 = fh_clone(p00);
            var p11 = fh_clone(p10);
            p01[2] = impacts[n].point[3];
            p11[2] = impacts[n + 1].point[3];
            var wall_polygon = new fh_polygon(p00, normal);
            wall_polygon.add_contour([p00, p10, p11, p01]);
            wall_polygon["storeys"] = [storey, this._current_storey];
            wall_polygon["spaces"] = [impacts[n].spaces[1], space];
            wall_polygon["vertical"] = true;
            wall_polygon["ID"] = cn_building.create_unique_id(id, n);
            wall_polygon["CADID"] = id;
            wall_polygon["construction_id"] = "";
            wall_polygon["underground"] = false;

            this._build_surface(wall_polygon);
        }
    }

    //********************************************************************************
    //**** Build space
    //********************************************************************************
    _build_space(cn_space, index, current_zones_space, building_storey_id) {
        if (!cn_space.indoor) return;

        cn_space.xml_name = cn_space.get_name(this._current_storey);

        var space = new fh_xml("Space");
        this._gbxml_building.append_child(space);

        space.set_attribute("id", cn_building.create_unique_id(this._current_storey, cn_space));
        space.set_attribute("buildingStoreyIdRef", building_storey_id);
        space.set_attribute("bufferSpace", !cn_space.heated);
        space.append_child(new fh_xml("Name", cn_space.xml_name));

        space.set_attribute("zoneIdRef", current_zones_space.get(cn_space.ID) || this._current_storey.ID);

        space.append_child(new fh_xml("Area", cn_space.area));
        space.append_child(new fh_xml("Volume", "" + (cn_space.area * (this._h1 - this._h0))));

        var solid = cn_space.build_solid(this._current_storey);
        space.append_child(this._solid_to_shell_geometry(solid, cn_building.create_unique_id(this._current_storey, cn_space, index, 'shellG'),this._current_storey.altitude));
    }

    //********************************************************************************
    //**** create walls
    //********************************************************************************
    _build_wall(cn_wall) {
        if (!cn_wall.valid) return;
        if (cn_wall.balcony) return;
        if (cn_wall.wall_type.free) return;

        var sp0 = (cn_wall.spaces[0].outside) ? null : cn_wall.spaces[0];
        var sp1 = (cn_wall.spaces[1].outside) ? null : cn_wall.spaces[1];

        //*** Wall heights */
        var zmin = cn_wall.get_lowest_slab_height();
        var zmax = cn_wall.get_highest_slab_height();
        var lower_space = (sp0 && sp1 && sp0.slab_offset < sp1.slab_offset) ? sp0 : sp1;

        var side = (cn_wall.is_lossy())?cn_wall.get_flow_direction()?0:1:-1;

        //*** Wall geometry */
        var normal = [cn_wall.bounds.normal[0], cn_wall.bounds.normal[1], 0];
        var contour = [];
        var direction = cn_clone(cn_wall.bounds.direction);
        direction.push(0);
        if (side >= 0)
        {
            var p00 = cnx_clone(cn_wall.measure_points[side][0],zmax);
            var p10 = cnx_clone(cn_wall.measure_points[side][1],zmax);
        }
        else
        {
            var p00 = cnx_clone(cn_middle(cn_wall.measure_points[0][0],cn_wall.measure_points[1][1]),zmax);
            var p10 = cnx_clone(cn_middle(cn_wall.measure_points[0][1],cn_wall.measure_points[1][0]),zmax);
        }
        var p01 = cnx_clone(p00);
        var p11 = cnx_clone(p10);
        p01[2] = p11[2] = this._roof_height;

        contour.push(p00);
        contour.push(p01);
        contour.push(p11);
        contour.push(p10);
        var wall_polygon = new fh_polygon(contour[0], normal);
        wall_polygon.add_contour(contour);

        if (this._current_storey.roof_volume) {
            var roof_section = this._current_storey.roof_volume.plane_intersection(wall_polygon.get_point(), wall_polygon.get_normal());
            if (roof_section)
                wall_polygon.intersects(roof_section);
        }
        if (wall_polygon.get_area() < 0.01) return;
        var wall_polygons = wall_polygon.split();

        //***************************************
        //*** opening geometry
        var p00 = cnx_clone(cn_wall.vertex_position(0),zmax);
        for (var o = 0; o < cn_wall.openings.length; o++) {
            var cn_opening = cn_wall.openings[o];
            if (!cn_opening.valid) continue;

            contour = [];
            var x = cn_opening.position;
            var pp0 = fh_add(p00, [0, 0, cn_opening.opening_type.z])
            var pp1 = fh_add(p00, [0, 0, cn_opening.opening_type.z + cn_opening.opening_type.height])

            contour.push(fh_add(pp0, fh_mul(direction, x)));
            contour.push(fh_add(pp0, fh_mul(direction, x + cn_opening.opening_type.width)));
            contour.push(fh_add(pp1, fh_mul(direction, x + cn_opening.opening_type.width)));
            contour.push(fh_add(pp1, fh_mul(direction, x)));

            var opening_polygon = new fh_polygon(pp0, normal);
            opening_polygon.add_contour(contour);

            cn_opening.gbxml_polygon = opening_polygon;
        }

        //*** Loop on wall faces */
        for (var k = 0; k < wall_polygons.length; k++) {
            var pg = wall_polygons[k];
            var pg_unpierced = wall_polygons[k].clone();

            pg["spaces"] = cn_wall.spaces;
            pg["vertical"] = true;
            pg["ID"] = cn_building.create_unique_id(this._current_storey, cn_wall, k);
            pg["CADID"] = cn_building.create_unique_id(this._current_storey, cn_wall);
            pg["construction_id"] = cn_wall.wall_type.ID;
            pg["underground"] = this._current_storey.is_underground();

            var gbxml_wall = this._build_surface(pg, this._current_storey.altitude);
            if (gbxml_wall == null) continue;

            //*** Create openings */
            for (var o = 0; o < cn_wall.openings.length; o++) {
                var cn_opening = cn_wall.openings[o];
                if (!cn_opening.valid) continue;

                var pgo = cn_opening.gbxml_polygon.clone();
                pgo.intersects(pg_unpierced);
                if (pgo.get_area() < 0.01) continue;

                var obj = this._build_opening(cn_opening, pgo, k);
                if (obj)
                    gbxml_wall.append_child(obj);
            }
        }

        //*** The wall below */
        if (zmax - zmin > 0.01) {
            //*** if zero storey, this is a wall on ground */
            if (this._current_storey.storey_index == 0) {
                if (lower_space && lower_space.indoor) {
                    p00 = cn_clone(cn_wall.vertices[0].position);
                    p10 = cn_clone(cn_wall.vertices[1].position);
                    p00.push(zmin);
                    p10.push(zmin);
                    p01 = fh_clone(p00);
                    p11 = fh_clone(p10);
                    p01[2] = p11[2] = zmax;

                    wall_polygon = new fh_polygon(p00, normal);
                    wall_polygon.add_contour([p00, p01, p11, p10]);
                    wall_polygon["spaces"] = [lower_space, null];
                    wall_polygon["vertical"] = true;
                    wall_polygon["ID"] = cn_building.create_unique_id(this._current_storey, cn_wall, k);
                    wall_polygon["CADID"] = cn_building.create_unique_id(this._current_storey, cn_wall);
                    wall_polygon["construction_id"] = cn_wall.wall_type.ID;
                    wall_polygon["underground"] = true;

                    this._build_surface(wall_polygon,this._current_storey.altitude);
                }
            }
            else {
                var p0 = cn_clone(cn_wall.vertices[0].position);
                p0.push(zmin + this._current_storey.altitude);
                p0.push(zmax + this._current_storey.altitude);
                var p1 = cn_clone(cn_wall.vertices[1].position);
                p1.push(zmin + this._current_storey.altitude);
                p1.push(zmax + this._current_storey.altitude);
                this._build_extra_wall(p0, p1, lower_space, cn_building.create_unique_id(this._current_storey, cn_wall), this._building.storeys[this._current_storey.storey_index - 1]);
            }
        }
    }

    //********************************************************************************
    //**** create opening
    //********************************************************************************
    _build_opening(cnm_opening, polygon, index = 0) {
        var opening = new fh_xml("Opening");
        opening.set_attribute("id", cn_building.create_unique_id(this._current_storey, cnm_opening, index));

        opening.append_child(new fh_xml("CADObjectId", cn_building.create_unique_id(this._current_storey, cnm_opening)));

        let gbxml_construction = this._get_gbxml_construction(cnm_opening.opening_type)
        if (gbxml_construction.openingType)
            opening.set_attribute("openingType", gbxml_construction.openingType);
        if (gbxml_construction.name)
            opening.append_child(new fh_xml("Name", gbxml_construction.name));
        if (gbxml_construction.ref)
            opening.set_attribute(gbxml_construction.ref, cnm_opening.opening_type.ID);

        opening.append_child(this._polygon_to_planar_geometry(polygon,this._current_storey.altitude));

        return opening;
    }

    //********************************************************************************
    //**** create surface
    //********************************************************************************
    _build_surface(polygon, zoffset=0) {
        var planar_geometry = this._polygon_to_planar_geometry(polygon, zoffset);
        if (planar_geometry == null) return null;

        var surface = new fh_xml("Surface");
        this._gbxml_campus.append_child(surface);

        surface.append_child(planar_geometry);

        var wall_name = "";
        surface.set_attribute("id", polygon.ID);
        if (typeof (polygon.construction_id) == 'string' && !!polygon.construction_id)
            surface.set_attribute("constructionIdRef", polygon.construction_id);

        var nb_adj = 0;

        surface.append_child(new fh_xml("CADObjectId", polygon.CADID));

        for (var k = 0; k < 2; k++) {
            if (polygon.spaces[k] && polygon.spaces[k].indoor) {
                var node = new fh_xml("AdjacentSpaceId");
                var storey = (typeof (polygon.storeys) == 'object') ? polygon.storeys[k] : this._current_storey;
                node.set_attribute("spaceIdRef", cn_building.create_unique_id(storey, polygon.spaces[k]));
                surface.append_child(node);
                nb_adj++;
            }
        }
        if (polygon.spaces[0] == polygon.spaces[1])
            nb_adj = 0;

        if (nb_adj == 2) {
            if (polygon.vertical) {
                surface.set_attribute("surfaceType", "InteriorWall");
                surface.append_child(new fh_xml("Name", "Paroi intérieure"));
            }
            else {
                surface.set_attribute("surfaceType", "InteriorFloor");
                surface.append_child(new fh_xml("Name", "Plancher intermédiaire"));
            }
        }
        else if (nb_adj == 1) {
            if (polygon.vertical) {
                if (polygon.underground) {
                    surface.set_attribute("surfaceType", "UndergroundWall");
                    surface.append_child(new fh_xml("Name", "Mur enterré"));
                }
                else {
                    surface.set_attribute("surfaceType", "ExteriorWall");
                    surface.append_child(new fh_xml("Name", "Façade"));
                }
            }
            else if (polygon.spaces[0]) {
                surface.set_attribute("surfaceType", "Roof");
                surface.append_child(new fh_xml("Name", "Toiture"));
            }
            else {
                surface.set_attribute("surfaceType", "SlabOnGrade");
                surface.append_child(new fh_xml("Name", "Plancher bas"));
            }
        }
        else {
            surface.set_attribute("surfaceType", "Shade");
            surface.append_child(new fh_xml("Name", "Masque"));
        }

        //surface.append_child(new fh_xml("Name",wall_name));

        return surface;
    }

    //********************************************************************************
    //**** Solid to shell geometry
    //********************************************************************************
    _solid_to_shell_geometry(solid, id, zoffset = 0) {
        var shell_geometry = new fh_xml("ShellGeometry");
        shell_geometry.set_attribute('id', id);
        var closed_shell = new fh_xml("ClosedShell");
        shell_geometry.append_child(closed_shell);

        var faces = solid.get_faces();
        for (var i in faces) {
            closed_shell.append_child(this._polygon_to_polyloop(faces[i],zoffset));
        }
        return shell_geometry;
    }

    //********************************************************************************
    //**** polygon to polyloop
    //********************************************************************************
    _polygon_to_polyloop(polygon, zoffset = 0) {
        polygon.compute_contours();
        var polyloop = new fh_xml("PolyLoop");
        var sz = polygon.contour_sizes[0];
        for (var nv = 0; nv < sz; nv++) {
            polyloop.append_child(this._build_cartesian_point(polygon.contour_vertices[nv],zoffset));
        }

        return polyloop;
    }

    //********************************************************************************
    //**** polygon to planar geometry
    //********************************************************************************
    _polygon_to_planar_geometry(polygon, zoffset = 0) {
        var contour = cn_simplify_contour(polygon.to_single_contour().concat([]));
        if (contour == null || contour.length == 0)
            return null;

        var surface = new fh_xml("PlanarGeometry");
        var polyloop = new fh_xml("PolyLoop");
        surface.append_child(polyloop);
        for (var nct = 0; nct < contour.length; nct++) {
            polyloop.append_child(this._build_cartesian_point(contour[nct], zoffset));
        }

        return surface;
    }

    //********************************************************************************
    //**** polygon to planar geometry
    //********************************************************************************
    _build_cartesian_point(pt, zoffset = 0) {
        var cartesian_point = new fh_xml("CartesianPoint");
        cartesian_point.append_child(new fh_xml("Coordinate", pt[0].toFixed(3)));
        cartesian_point.append_child(new fh_xml("Coordinate", pt[1].toFixed(3)));
        cartesian_point.append_child(new fh_xml("Coordinate", (pt[2]+zoffset).toFixed(3)));
        return cartesian_point;
    }


}

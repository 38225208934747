import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PolluantPrelevement } from 'src/app/modules/diagnostics/polluant/model/polluant-prelevement.model';
import { WidgetSelectModeEnum } from 'src/app/modules/shared/widget-select/widget-select.component';
import { PolluantConfig } from '../../../../../model/polluant-config.model';
import { PolluantService } from '../../../../../services/polluant.service';

//ICONES
const WIND_ICON = `
    <?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 74.78" style="enable-background:new 0 0 122.88 74.78" xml:space="preserve"><g><path d="M28.69,53.38c-1.61,0-2.91-1.3-2.91-2.91c0-1.61,1.3-2.91,2.91-2.91h51.37c0.21,0,0.42,0.02,0.62,0.07 c1.84,0.28,3.56,0.8,5.1,1.63c1.7,0.92,3.15,2.19,4.27,3.89c3.85,5.83,3.28,11.24,0.56,15.24c-1.77,2.61-4.47,4.55-7.45,5.57 c-3,1.03-6.32,1.13-9.32,0.03c-4.54-1.66-8.22-5.89-8.76-13.55c-0.11-1.6,1.1-2.98,2.7-3.09c1.6-0.11,2.98,1.1,3.09,2.7 c0.35,4.94,2.41,7.56,4.94,8.48c1.71,0.62,3.67,0.54,5.48-0.08c1.84-0.63,3.48-1.79,4.52-3.32c1.49-2.19,1.71-5.28-0.61-8.79 c-0.57-0.86-1.31-1.51-2.18-1.98c-0.91-0.49-1.97-0.81-3.13-0.99H28.69L28.69,53.38z M15.41,27.21c-1.61,0-2.91-1.3-2.91-2.91 c0-1.61,1.3-2.91,2.91-2.91h51.21c1.17-0.18,2.23-0.5,3.14-0.99c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79 c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7 c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57 c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H15.41 L15.41,27.21z M2.91,40.3C1.3,40.3,0,38.99,0,37.39c0-1.61,1.3-2.91,2.91-2.91h107.07c1.17-0.18,2.23-0.5,3.13-0.99 c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08 c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55 c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89 c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H2.91L2.91,40.3z"/></g></svg>
`;
const VENTILATEUR_ICON = `
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.07"><defs><style>.cls-1{fill-rule:evenodd;}</style></defs><title>fan-blades</title><path class="cls-1" d="M67.29,82.9c-.11,1.3-.26,2.6-.47,3.9-1.43,9-5.79,14.34-8.08,22.17C56,118.45,65.32,122.53,73.27,122A37.63,37.63,0,0,0,85,119a45,45,0,0,0,9.32-5.36c20.11-14.8,16-34.9-6.11-46.36a15,15,0,0,0-4.14-1.4,22,22,0,0,1-6,11.07l0,0A22.09,22.09,0,0,1,67.29,82.9ZM62.4,44.22a17.1,17.1,0,1,1-17.1,17.1,17.1,17.1,0,0,1,17.1-17.1ZM84.06,56.83c1.26.05,2.53.14,3.79.29,9.06,1,14.58,5.16,22.5,7.1,9.6,2.35,13.27-7.17,12.41-15.09a37.37,37.37,0,0,0-3.55-11.57,45.35,45.35,0,0,0-5.76-9.08C97.77,9,77.88,14,67.4,36.63a14.14,14.14,0,0,0-1,2.94A22,22,0,0,1,78,45.68l0,0a22.07,22.07,0,0,1,6,11.13Zm-26.9-17c0-1.6.13-3.21.31-4.81,1-9.07,5.12-14.6,7-22.52C66.86,2.89,57.32-.75,49.41.13A37.4,37.4,0,0,0,37.84,3.7a44.58,44.58,0,0,0-9.06,5.78C9.37,25.2,14.39,45.08,37,55.51a14.63,14.63,0,0,0,3.76,1.14A22.12,22.12,0,0,1,57.16,39.83ZM40.66,65.42a52.11,52.11,0,0,1-5.72-.24c-9.08-.88-14.67-4.92-22.62-6.73C2.68,56.25-.83,65.84.16,73.74A37.45,37.45,0,0,0,3.9,85.25a45.06,45.06,0,0,0,5.91,9c16,19.17,35.8,13.87,45.91-8.91a15.93,15.93,0,0,0,.88-2.66A22.15,22.15,0,0,1,40.66,65.42Z"/></svg>
`;

const RECUPERATION_ICON = `
    <?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"	 width="628.254px" height="613.516px" viewBox="0 0 628.254 613.516" enable-background="new 0 0 628.254 613.516"	 xml:space="preserve"><g>	<g>		<g>			<g>				<path d="M99.777,446.086c-6.699,12.031-12.031,30.133-12.031,41.539c0,2.648,0,6.016,0.656,10.688L4.676,354.32					C1.996,349.648,0,342.945,0,336.906c0-6.047,1.996-13.398,4.676-18.078L44.871,248.5L0,223.07l146.016-2.703L217,348.281					l-45.527-26.117L99.777,446.086z M164.09,40.227c12.715-22.125,33.496-34.18,58.926-34.18c27.48,0,48.918,12.742,64.312,38.828					l22.777,38.172l-79.051,136.664l-127.914-74.352L164.09,40.227z M173.441,561.945c-38.172,0-69.645-31.477-69.645-69.648					c0-10.719,4.703-28.82,11.402-40.195l21.41-38.172h158.758v148.016H173.441z M300.727,36.18					C290.691,18.789,277.293,6.703,261.215,0h164.746c14.738,0,26.113,6.047,32.84,17.445l40.852,69.648l44.191-26.141					l-71.016,127.281l-145.305-2.047l44.871-25.43L300.727,36.18z M554.59,415.273c20.07,0,36.832-5.359,50.887-16.055					l-83.07,144.648c-6.699,11.375-18.73,18.078-32.789,18.078h-78.395v51.57l-75.004-125.234l75.004-125.266v52.258H554.59z					 M618.848,294.711c6.043,10.719,9.406,22.094,9.406,34.156c0,24.117-15.422,49.57-36.832,61.602					c-10.062,5.391-24.145,8.75-38.172,8.75h-44.242l-79-136.664l127.918-73.008L618.848,294.711z"/>			</g>		</g>	</g></g></svg>
`;
@Component({
    selector: 'app-zone-form-information',
    templateUrl: './zone-form-information.component.html',
    styleUrls: ['./zone-form-information.component.scss'],
})
export class ZoneFormInformationComponent implements OnInit {
    @Input()
    formInformation: FormGroup | AbstractControl;

    @Input()
    polluantConfig: PolluantConfig;

    @Input() // Utilisé pour passer les champs en read-only
    disabled: Boolean;

    @Input()
    contenuDiagnostic: PolluantPrelevement;

    widgetSelectModeEnum: typeof WidgetSelectModeEnum = WidgetSelectModeEnum;

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        public PolluantService: PolluantService
    ) {
        iconRegistry.addSvgIconLiteral('wind', sanitizer.bypassSecurityTrustHtml(WIND_ICON));
        iconRegistry.addSvgIconLiteral('ventilateur', sanitizer.bypassSecurityTrustHtml(VENTILATEUR_ICON));
        iconRegistry.addSvgIconLiteral('recuperation', sanitizer.bypassSecurityTrustHtml(RECUPERATION_ICON));
    }

    ngOnInit(): void {}

    /**
     * @description Fonction appellée par chaque élément du menu déroulant Objectif de mesurage
     *              afin de définir si il doit être désactivé ou non en fonction des choix précédents
     * @param item
     */
    isItemDisabled(item) {
        const formValues = this.formInformation.value.objectifsMesurage;

        //Si rien n'est sélectionné, tous les items sont actifs
        if (formValues.length == 0) {
            return false;
        }

        //Tous les items qui sont cochés restent actifs afin de pouvoir être décochés
        if (formValues.indexOf(item) >= 0) {
            return false;
        }

        let selectedCsp = formValues.filter((value) => value.domaineReglementaire == 'CODE_SANTE_PUBLIQUE')[0];
        //Si une valeure CSP est déjà sélectionnée, alors tous les autres éléments sont desactivés
        // sauf celui qui est déjà sélectionné afin de pouvoir le décocher
        if (selectedCsp != undefined) {
            return true;
        }

        //Tous les items CSP sont désactivés si ils ne vérifient aucune des conditions précédentes
        if (item.domaineReglementaire == 'CODE_SANTE_PUBLIQUE') {
            return true;
        }
    }
}

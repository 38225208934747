"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** cn_roof_line :
//***********************************************************************************
//***********************************************************************************

import {cn_element} from "./cn_element";
import {cnx_add, cnx_clone, cn_add, cn_box, cn_clone, cn_dist, cn_dot, cn_middle, cn_mul, cn_normal, cn_normalize, cn_project_segment, cn_sub} from "../utils/cn_utilities";
import {cn_roof_contour} from "./cn_roof_contour";
import { cn_pastille } from "../svg/cn_pastille";
import { fh_polygon } from "@acenv/fh-3d-viewer";

export const MAX_OVERHANG_VALUE = 5;

export class cn_roof_line extends cn_element{
	constructor(v0, v1) {
		super();
		//*** Constant data
		this.draw_priority = 5;

		//*** Serialized data
		this.vertices = [v0,v1];
		this.fixed = false;
		this.border = 2;
		this.overhang = 0;

		//*** volatile data
		this.shape = [];
		this.slabs = [null,null];
		this.contours = [null,null];
		this.homogeneous = true;
	}

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize() {
		var json = {};
		json.ID = this.ID;
		json.border = this.border;
		json.vertices = [this.vertices[0].s_index,this.vertices[1].s_index];
		json.fixed = this.fixed;
		json.overhang = this.overhang;
		return json;
	}

	static unserialize(json,scene) {
		if (typeof(json) != 'object') return false;
		if (typeof(json.vertices) != 'object') return false;
		if (json.vertices[0] == json.vertices[1]) return false;

		var v0 = scene.vertices[json.vertices[0]];
		var v1 = scene.vertices[json.vertices[1]];

		var line = new cn_roof_line(v0,v1);

		if (typeof(json.ID) == 'string')
			line.ID = json.ID;

		if (typeof(json.border) == 'number')
			line.border = json.border;

		if (typeof(json.fixed) == 'boolean')
			line.fixed = json.fixed;

		if (typeof(json.overhang) == 'number')
			line.overhang = json.overhang;

		scene.lines.push(line);
		return line;
	}

	//***********************************************************************************
	//**** Return true for border line
	//***********************************************************************************
	is_border() {
		for (var k=0;k<2;k++)
		{
			if (this.slabs[k] == null || this.slabs[k].outside) return true;
		}
		return false;
	}

	//***********************************************************************************
	//**** Build lines
	//***********************************************************************************
	build_self() {
		this.removable = !this.is_border();
		var bounds = {};
		bounds.direction = cn_sub(this.vertices[1].position, this.vertices[0].position);
		bounds.length = cn_normalize(bounds.direction);
		bounds.normal = cn_normal(bounds.direction);
		this.bounds = bounds;
	}

	//***********************************************************************************
	//**** Draw the line in svg
	//***********************************************************************************
	draw(camera, add_classes) {
		var html = "";

		var p0 = camera.world_to_screen(this.vertices[0].position);
		var p1 = camera.world_to_screen(this.vertices[1].position);

		const add_classes_flat = (add_classes)?" " + add_classes.join(" "):"";

		if (this.fixed)
		{
			const oh = this.get_overhang_2d_vertices();
			const h0 = camera.world_to_screen(oh[0]);
			const h1 = camera.world_to_screen(oh[1]);

			//*** draw overhang area */
			if (this.overhang > 0)
			{
				const col = this.slabs[0].get_color();
				html += `<path fill="rgba(${col[0]},${col[1]},${col[2]},${col[3]})"  d="M ${p0[0]} ${p0[1]} L ${p1[0]} ${p1[1]} ${h1[0]} ${h1[1]} ${h0[0]} ${h0[1]} Z" />`;

				//*** draw slab line */
				html += `<path class="roof_line fixed"  d="M ${h0[0]} ${h0[1]} L ${p0[0]} ${p0[1]} ${p1[0]} ${p1[1]} ${h1[0]} ${h1[1]}" />`;
			}

			//*** draw overhang line */
			html += "<line class='roof_line fixed" + add_classes_flat + "' x1='" + h0[0] + "' y1='" + h0[1] + "' x2='" + h1[0] + "' y2='" + h1[1] + "' />";

			const selected = (add_classes.indexOf("selected")>=0)
			//*** draw move arrows */
			if (selected && add_classes.indexOf("mouseover")>=0)
				html += camera.draw_line_move_arrow_screen(h0,h1,"mouseover")
		}
		else
		{
			html += "<line class='roof_line" + add_classes_flat + "' x1='" + p0[0] + "' y1='" + p0[1] + "' x2='" + p1[0] + "' y2='" + p1[1] + "' />";
		}

		return html;
	}

	//***********************************************************************************
	//**** Project a point on the line.
	//***********************************************************************************
	project(p) {
		return cn_project_segment(p,this.vertices[0].position,this.vertices[1].position);
	}

	//***********************************************************************************
	//**** returns -1 if no intersection with line, 0 if on first vertex, 1 if on second vertex, 2 if on line.
	//***********************************************************************************
	intersects(p, range) {
		if (range < 0.01) range = 0.01;
		var direction = cn_sub(this.vertices[1].position,this.vertices[0].position);
		var length = cn_normalize(direction);
		if (length < range)
		{
			if (cn_dist(this.vertices[0].position,p) < range)
				return 0;
			if (cn_dist(this.vertices[1].position,p) < range)
				return 1;
			return -1;
		}
		var d = cn_sub(p,this.vertices[0].position);
		var x = cn_dot(direction,d);
		if (x < -range) return -1;
		if (x > length + range) return -1;

		var normal = cn_normal(direction);
		var z = cn_dot(normal,d);
		if (z > range + this.overhang) return -1;
		if (z < -range) return -1;
		if (x < range) return 0;
		if (x > length - range) return 1;
		return 2;
	}

	//***********************************************************************************
	//**** Split the line
	//***********************************************************************************
	split(vertex, new_line = null) {
		var v0 = this.vertices[0];
		var v1 = this.vertices[1];

		var line = new_line;
		if (line)
		{
			line.vertices[0] = vertex;
			line.vertices[1] = this.vertices[1];
		}
		else
			line = new cn_roof_line(vertex,this.vertices[1]);

		this.vertices[1] = vertex;
		vertex.lines.push(this);
		vertex.lines.push(line);
		line.slabs[0] = this.slabs[0];
		line.slabs[1] = this.slabs[1];
		line.contours[0] = this.contours[0];
		line.contours[1] = this.contours[1];

		if (this.border == 0) this.border = 1;
		line.border = this.border;
		line.fixed = this.fixed;
		line.overhang = this.overhang;
		vertex.liberties = (this.fixed)?1:2;

		var index = line.vertices[1].lines.indexOf(this);
		if (index >= 0) line.vertices[1].lines.splice(index,1);
		line.vertices[1].lines.push(line);
		vertex.update();
		line.vertices[1].update();

		this.build_self();
		line.build_self();

		//*** rebuild slabs contours
		if (this.slabs[0])
		{
			var contour = new cn_roof_contour();
			contour.follow(this.vertices[0],this);
			this.slabs[0].add_contour(contour);
		}
		if (this.slabs[1])
		{
			var contour = new cn_roof_contour();
			contour.follow(this.vertices[1],this);
			this.slabs[1].add_contour(contour);
		}
		return line;
	}

	//******************************************************
	//*** get other vertex
	//******************************************************
	other_vertex(v) {
		if (this.vertices[0] == v) return this.vertices[1];
		return this.vertices[0];
	}

	//******************************************************
	//*** fix contours
	//******************************************************
	fix_contours(v) {

		for (var s=0;s<2;s++)
		{
			if (this.slabs[s] == null) continue;
			var contour = new cn_roof_contour();
			contour.follow(this.vertices[s],this);
			this.slabs[s].add_contour(contour);
		}
	}

	//******************************************************
	//*** does this line cross other line ?
	//******************************************************
	crosses(lines) {
		var p0 = this.vertices[0].position;
		var d0 = this.bounds.direction;
		for (var i in lines)
		{
			var w = lines[i];
			if (w == this) continue;
			if (w.vertices[0] == this.vertices[0]) continue;
			if (w.vertices[0] == this.vertices[1]) continue;
			if (w.vertices[1] == this.vertices[0]) continue;
			if (w.vertices[1] == this.vertices[1]) continue;

			var dis = cn_dot(d0,w.bounds.normal);
			if (Math.abs(dis) < 0.01) continue;

			var x = cn_dot(w.bounds.normal,cn_sub(w.vertices[0].position,p0)) / dis;
			if (x < -0.1) continue;
			if (x > this.bounds.length + 0.1) continue;
			var pt = cn_add(p0,cn_mul(d0,x));
			var x1 = cn_dot(w.bounds.direction,cn_sub(pt,w.vertices[0].position));
			if (x1 < -0.1) continue;
			if (x1 > w.bounds.length + 0.1) continue;
			return true;
		}
		return false;
	}

	build_overhang_polygon(z)
	{
		if (!this.fixed || this.overhang < 0.001) return null;

		const slab = this.slabs[0];
		if (!slab) return null;

		const contour = this.get_overhang_vertices(z);
		var polygon = new fh_polygon(contour[0],slab.normal);
		polygon.add_contour(contour);
		return polygon;
	}

	/**
	 * Returns the list of vertices with the overhang
	 * @returns {Array<Array<number>>}
	 */
	get_overhang_2d_vertices()
	{
		const contour = [];
		var hw0 = this.vertices[0].position;
		var hw1 = this.vertices[1].position;

		if (this.overhang > 0.001)
		{
			var d0 = cn_sub(this.vertices[0].overhang_position,this.vertices[0].position);
			cn_normalize(d0);
			var ps0 = cn_dot(d0,this.bounds.normal);
			if (ps0 > 0.5)
				ps0 = this.overhang/ps0;
			else
			{
				d0 = this.bounds.normal;
				ps0 = this.overhang;
			}

			var d1 = cn_sub(this.vertices[1].overhang_position,this.vertices[1].position);
			cn_normalize(d1);
			var ps1 = cn_dot(d1,this.bounds.normal);
			if (ps1>0.5)
				ps1 = this.overhang/ps1;
			else
			{
				d1 = this.bounds.normal;
				ps1 = this.overhang;
			}

			hw0 = cn_add(this.vertices[0].position,cn_mul(d0,ps0));
			hw1 = cn_add(this.vertices[1].position,cn_mul(d1,ps1));
		}

		return [hw0,hw1];
	}

	/**
	 *
	 * @param {number} z
	 * @returns
	 */
	get_overhang_vertices(z)
	{
		const oh = this.get_overhang_2d_vertices();
		const contour = [];
		contour.push(cnx_clone(this.vertices[0].position));
		contour.push(cnx_clone(this.vertices[1].position));
		contour.push(cnx_clone(oh[1]));
		contour.push(cnx_clone(oh[0]));

		const slab = this.slabs[0];
		if (slab)
		{
			contour.forEach(v => v[2] = z + slab.compute_height(v));
		}
		else
		{
			contour.forEach(v => v[2] = z);
		}
		return contour;
	}

	get_bounding_box() {
		const box = new cn_box();
		const oh = this.get_overhang_2d_vertices();
		box.enlarge_point(oh[0]);
		box.enlarge_point(oh[1]);
		box.enlarge_point(this.vertices[0].position);
		box.enlarge_point(this.vertices[1].position);
		return box;
	}

	raytrace(origin, direction, max_distance)
	{
		var x = cn_dot(direction,this.bounds.normal);
		if (Math.abs(x) < 0.0001) return null;
		var lambda = cn_dot(this.bounds.normal,cn_sub(this.vertices[0].position,origin)) / x;
		if (lambda <= 0) return null;
		if (max_distance > 0 && lambda >= max_distance) return null;
		var p = cn_add(origin,cn_mul(direction,lambda));
		var e = cn_dot(this.bounds.direction,cn_sub(p,this.vertices[0].position));
		if (e < 0) return null;
		if (e > this.bounds.length) return null;
		return {'distance':lambda,'point':cn_add(this.vertices[0].position,cn_mul(this.bounds.direction,e)), 'line':this, 'line_position': e};
		
	}
}


import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { combineLatest, forkJoin, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { EditMode } from 'src/app/model/edit-mode.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { ReferenceService } from 'src/app/services/reference.service';
import { URL_GESTION_MATERIAUX } from 'src/app/shared/constants/url.constants';
import { ValidatorUtils } from 'src/app/utils/validator.utils';
import { TypeMateriau } from '../../../../../model/type-materiau.model';

@Component({
    selector: 'app-creation-materiaux',
    templateUrl: './creation-materiaux.component.html',
    styleUrls: ['./creation-materiaux.component.scss'],
})
export class CreationMateriauxComponent extends BaseComponent implements OnInit, OnDestroy {
    idTypeMateriau: string;
    materielInitial: TypeMateriau = new TypeMateriau();
    materiel: TypeMateriau = new TypeMateriau();

    formTemplateValeursParametres: any[] = [];

    formTypeMateriau: FormGroup;

    mode: EditMode = 'CREATE';
    isReadOnly = false;

    isTypeMateriauParentInitial = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly referenceApiService: ReferenceApiService,
        private readonly referenceService: ReferenceService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.formTypeMateriau = this.formBuilder.group({
            nom: ['', [Validators.required], { updateOn: 'blur' }],
        });

        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        this.idTypeMateriau = params.get('idMateriel');
                        if (this.idTypeMateriau) {
                            this.mode = data.duplicate ? 'DUPLICATE' : 'EDIT';
                            this.isReadOnly = data.consulter;
                            return this.referenceApiService.findOneMateriel(this.idTypeMateriau);
                        }
                        return of(null);
                    })
                )
            )
            .subscribe((currentTypeElement) => {
                if (currentTypeElement) {
                    this.materielInitial = JSON.parse(JSON.stringify(currentTypeElement));
                    this.materiel = currentTypeElement;
                }

                this.setAsyncValidatorsCustom();

                if (this.mode !== 'CREATE') {
                    this.isTypeMateriauParentInitial = true;
                    if (this.mode === 'DUPLICATE') {
                        this.materiel.id = null;
                    }
                    this.formTypeMateriau.patchValue({
                        nom: this.materiel.nom,
                    });

                    this.formTypeMateriau.updateValueAndValidity();
                }
            });
    }

    setAsyncValidatorsCustom() {
        this.formTypeMateriau
            .get('nom')
            .setAsyncValidators(
                ValidatorUtils.uniciteMaterielValidator(
                    this.formTypeMateriau.get('nom'),
                    this.referenceApiService,
                    this.mode !== 'EDIT' ? null : this.materielInitial.id
                )
            );

        // on marque les contrôles "dirty" pour que les erreurs s'appliquent
        // sur les champs sans avoir à sélectionner les différents champs
        this.formTypeMateriau.get('nom').markAsTouched();

        // on met à jour l'état du formulaire pour appliquer les nouveaux Validators ajoutés au formulaire
        this.formTypeMateriau.updateValueAndValidity();
    }

    validerTypeMateriau() {
        this.cnSpinnerService.show('Sauvegarde en cours...');

        // Values
        this.materiel.nom = this.formTypeMateriau.value.nom;

        if (this.mode === 'EDIT') {
            this.referenceApiService.updateMateriel(this.materiel).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    this.notificationService.success("Le type d'ouvrage a été modifié");
                    // On réinitialise la liste des types d'ouvrage dans le cache de l'appli
                    this.referenceApiService.buildTypesOuvrage$();
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        } else {
            this.referenceApiService.createMateriel(this.materiel).subscribe(
                () => {
                    this.cnSpinnerService.hide();
                    const message =
                        this.mode === 'CREATE' ? "Le type d'ouvrage a été créé" : "Le type d'ouvrage a été dupliqué";
                    this.notificationService.success(message);
                    // On réinitialise la liste des types d'ouvrage dans le cache de l'appli
                    this.referenceApiService.buildTypesOuvrage$();
                    this.back();
                },
                () => this.cnSpinnerService.hide()
            );
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    back() {
        this.router.navigate([URL_GESTION_MATERIAUX]);
    }
}

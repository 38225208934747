import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Data } from '@angular/router';
import { BaseComponent, ConfirmationService } from 'src/app/commons-lib';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { EtatProgressionService } from 'src/app/services/etat-progression.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { PRELEVEMENT_ESPACE, ZONE_ESPACE } from 'src/app/shared/constants/names.step.constants';
import { PlanLocalisationModal } from './plan-localisation-modal/plan-localisation-modal.component';
import { combineLatest, Observable, of } from 'rxjs';
import { HapService } from '../../../diagnostics/hap/services/hap.service';
import { HapCnMapService } from '../../../diagnostics/hap/services/hap-cn-map.service';
import { HapFormService } from '../../../diagnostics/hap/services/hap-form.service';
import { HapCheckValidityService } from '../../../diagnostics/hap/services/hap-check-validity.service';
import { ViewerMapHapComponent } from '../viewer-map-hap/viewer-map-hap.component';
import { Intervention, RelationInterventionBien } from '../../../../model/intervention.model';
import { cn_storey } from '@acenv/cnmap-editor';
import { DrawingPicture } from '@acenv/cnmap-angular-editor-lib';
import { LevelToDisplay } from '../../../diagnostics/hap/model/hap.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { MatDrawer } from '@angular/material/sidenav';
import { combineLatestOrEmpty } from '../../../../utils/rxjs.utils';
import { HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE } from '../../../diagnostics/hap/constantes/hap.constantes';
import { Perimetre } from '../../../../model/perimetre.model';
import { Zone } from '../../../../model/zone.model';
import { Prelevement } from '../../../../model/prelevement-generique.model';
import { PerimetreInvestigation, PlanLocalisation } from 'src/app/model/perimetre-investigation.model';
import { Espace } from 'src/app/model/espace.model';
import { PolluantService } from 'src/app/modules/diagnostics/polluant/services/polluant.service';

@Component({
    selector: 'app-perimetre-map',
    templateUrl: './perimetre-map.component.html',
    styleUrls: ['./perimetre-map.component.scss'],
})
export class PerimetreMapComponent extends BaseComponent implements OnInit, OnDestroy {
    // Utiliser dans l'édition d'un besoin ou d'un prélèvement
    perimetreFormGroup: FormGroup;
    editedFormGroup: FormGroup;
    diagnostic: Diagnostic;
    intervention: Intervention;
    currentPlanLocalisation: Espace;
    readonlyMode = false;
    isReadonlyForced = false;

    enumLevelToDisplay = LevelToDisplay;
    currentLevelToDisplay: LevelToDisplay;
    listeDelimitationArrayForm: FormArray;
    elementToLocate: Prelevement | Zone | Perimetre = null;

    // Correspond au backgroundMap sélectionné pour l'espace
    backgroundMapSelected: DrawingPicture;
    // Correspond aux backgroundMap du bien
    backgroundMaps: DrawingPicture[] = [];
    currentStorey: cn_storey;
    currentBien: RelationInterventionBien;

    checkValidation = false;
    diagReadonly = false;

    @ViewChild('drawer') public drawer: MatDrawer;
    @ViewChild('viewMap') viewMap: ViewerMapHapComponent;
    currentContenuDiagnostic: any;
    isOnePerimetreInvalid = false;

    listePlansLocalisation: Espace[] = [];
    listePerimetresInvestigation: PerimetreInvestigation[] = [];

    isHap: Boolean = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly matDialog: MatDialog,
        private readonly confirmationService: ConfirmationService,
        private readonly route: ActivatedRoute,
        private readonly interventionService: InterventionService,
        private readonly diagnosticService: DiagnosticService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly hapService: HapService,
        private readonly hapFormService: HapFormService,
        private readonly hapCnMapService: HapCnMapService,
        private readonly hapCheckValidityService: HapCheckValidityService,
        private readonly polluantService: PolluantService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService.getCurrentIntervention(),
                    this.hapBaseComponentInitialisation(),
                    this.route.data,
                ])
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, params, routerData]) => {
                this.currentContenuDiagnostic = this.diagnostic.contenuDiagnostic as any;

                console.log(this.currentContenuDiagnostic);

                const idEspace = params.get('idEspace');
                if (idEspace) {
                    this.currentPlanLocalisation = this.currentContenuDiagnostic.espaces.valeur.find(
                        (espace) => espace.id === idEspace
                    );

                    // ------------------------ Initialisation de la MAP ---------------------------------//
                    // Sélection du backgroundMap de l'espace
                    this.backgroundMapSelected = this.backgroundMaps.find((backgroundMapTemp) => {
                        return backgroundMapTemp.fileId === this.currentPlanLocalisation.backgroundFileId;
                    });
                    this.currentBien = this.intervention.relationInterventionBiens.find(
                        (rel) => rel.bien.id === this.currentPlanLocalisation.idBien
                    );
                    // Génération du storey à partir de l'espace
                    this.currentStorey = this.hapCnMapService.loadBuilding(
                        this.diagnostic,
                        this.currentPlanLocalisation as any,
                        this.currentLevelToDisplay,
                        this.intervention
                    );
                    // ------------------------ Fin initialisation de la MAP ---------------------------------//

                    this.listeDelimitationArrayForm = this.hapFormService.createFormArrayListePerimetres(
                        this.currentPlanLocalisation.listePerimetres,
                        this.diagnostic,
                        this.currentLevelToDisplay,
                        this.ngUnsubscribe
                    );

                    this.readonlyMode =
                        !!routerData.readonly || this.diagnosticService.isReadOnlyMode(intervention, this.diagnostic);
                    if (this.readonlyMode) {
                        this.listeDelimitationArrayForm.disable();
                    }
                    this.checkValidity(this.listeDelimitationArrayForm);

                    // Déclenchement de la fonction de checkValidity dès que le status du formulaire est modifié
                    this.listeDelimitationArrayForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                        this.checkValidity(this.listeDelimitationArrayForm);
                    });

                    this.checkRouterData(routerData);
                }

                // Fermeture du pannel (dans le cas d'un changement de sous onglet)
                this.onCloseEditPanel();
            });
        this.currentLevelToDisplay = LevelToDisplay.PERIMETRE;
        this.cnSpinnerService
            .withSpinner(combineLatest([this.hapBaseComponentInitialisation(), this.route.data]))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([params, routerData]) => {
                if (this.currentContenuDiagnostic && this.currentContenuDiagnostic.perimetresInvestigation) {
                    // Récupération des périmètres d'investigation
                    this.listePerimetresInvestigation =
                        this.currentContenuDiagnostic.perimetresInvestigation.data.listePerimetreInvestigation;

                    // Pour HAP
                    // La notion de périmètre d'investigation n'existe pas
                    // Donc on en créé un caché qui va contenir tous les plans de localisation
                    if (this.diagnostic.typePrestation.startsWith('HAP')) {
                        this.isHap = true;
                        if (this.listePerimetresInvestigation.length == 0) {
                            this.listePerimetresInvestigation.push(
                                new PerimetreInvestigation({
                                    nom: 'Périmètre par défaut',
                                })
                            );
                        }
                    }

                    // Récupération des plans de localisation (espace)
                    this.listePlansLocalisation = this.currentContenuDiagnostic.espaces.valeur || [];

                    // this.currentPlanLocalisation = this.listePlansLocalisation[0] ?? new PlanLocalisation();
                    this.onChangePlanLocalisation(this.listePlansLocalisation[0]);
                } else {
                    this.listePlansLocalisation = [];
                }

                this.checkRouterData(routerData);
                this.checkIsOnePerimetreInvalid();
                this.checkValidity();
            });
    }

    onClickEditPlanLocalisation() {
        this.matDialog
            .open(PlanLocalisationModal, {
                data: {
                    planLocalisation: this.currentPlanLocalisation,
                    listePlanLocalisationExistant: this.listePlansLocalisation,
                    isCreation: false,
                    listePerimetresInvestigation: this.listePerimetresInvestigation,
                    readonlyMode: this.readonlyMode,
                    intervention: this.intervention,
                },
            })
            .afterClosed()
            .subscribe((modalResultat) => {
                if (modalResultat && modalResultat.plan) {
                    // Sélection du backgroundMap du plan
                    this.backgroundMapSelected = this.backgroundMaps.find((backgroundMapTemp) => {
                        return backgroundMapTemp.fileId === this.currentPlanLocalisation.backgroundFileId;
                    });
                    this.editPlanDiagnostic(modalResultat.plan);
                }
                this.checkValidity();
            });
    }

    editPlanDiagnostic(planLocalisation: Espace) {
        this.listePlansLocalisation = this.listePlansLocalisation.map((planTemp) => {
            if (planLocalisation.id === planTemp.id) {
                this.currentPlanLocalisation = Object.assign({}, planLocalisation);
                return this.currentPlanLocalisation;
            } else {
                return planTemp;
            }
        });

        this.diagnosticService.upsert(this.intervention, this.diagnostic).subscribe(() => {
            this.checkValidity();
            this.currentStorey = this.hapCnMapService.loadBuilding(
                this.diagnostic,
                this.currentPlanLocalisation as any,
                LevelToDisplay.PERIMETRE,
                this.intervention
            );
        });
    }

    /**
     * On supprime le plan de localisation sélectionné et on sélectionne le 1er plan de la liste
     */
    onClickDeletePlanLocalisation() {
        this.confirmationService.confirm(
            HAP_SUPPRESSION_ELEMENT_ALERT_MESSAGE['planLocalisation'](this.currentPlanLocalisation as any),
            () => {
                this.listePlansLocalisation = this.listePlansLocalisation.filter((plan) => {
                    return plan.id !== this.currentPlanLocalisation.id;
                });

                // Sauvegarde du diagnostic sans le plan sélectionné
                this.currentContenuDiagnostic.espaces.valeur = Object.assign([], this.listePlansLocalisation);
                this.diagnosticService.upsert(this.intervention, this.diagnostic).subscribe(() => {
                    this.checkValidity();

                    // Suppression de l'état progression correspondant à l'espace dans ZONE et PRELEVEMENT
                    const codeZone = `${ZONE_ESPACE}_${this.currentPlanLocalisation.id}`;
                    this.etatProgressionService.deleteCodeProgression(codeZone, this.diagnostic.etatProgressions);
                    const codePrelevement = `${PRELEVEMENT_ESPACE}_${this.currentPlanLocalisation.id}`;
                    this.etatProgressionService.deleteCodeProgression(
                        codePrelevement,
                        this.diagnostic.etatProgressions
                    );

                    // Sélection du 1er plan de localisation de la liste et chargement de son plan
                    this.onChangePlanLocalisation(
                        this.listePlansLocalisation.length > 0 ? this.listePlansLocalisation[0] : null
                    );
                });
            }
        );
    }

    onClickCreatePlanLocalisation() {
        this.matDialog
            .open(PlanLocalisationModal, {
                data: {
                    planLocalisation: new PlanLocalisation(),
                    listePlanLocalisationExistant: this.listePlansLocalisation,
                    isCreation: true,
                    listePerimetresInvestigation: this.listePerimetresInvestigation,
                    diagnostic: this.diagnostic,
                    intervention: this.intervention,
                },
            })
            .afterClosed()
            .subscribe((modalResultat) => {
                if (modalResultat && modalResultat.plan) {
                    this.savePlanDiagnostic(modalResultat.plan);
                }
                this.checkValidity();
            });
    }

    savePlanDiagnostic(plan: Espace) {
        this.listePlansLocalisation = [...this.listePlansLocalisation, plan];
        this.onChangePlanLocalisation(plan);
        this.currentContenuDiagnostic.espaces.valeur = this.listePlansLocalisation;
        this.diagnosticService.upsert(this.intervention, this.diagnostic).subscribe(() => {
            this.checkValidity();

            // Re-Validation des onglets ZONE et PRELEVEMENT
            this.hapCheckValidityService.checkValidityZoneFromOutside(
                this.currentPlanLocalisation.listePerimetres,
                this.currentPlanLocalisation.id,
                this.ngUnsubscribe,
                this.diagnostic
            );
        });
    }

    onChangePlanLocalisation(planL: Espace) {
        this.currentPlanLocalisation = planL;
        this.currentBien = this.intervention.relationInterventionBiens.find(
            (rel) => rel.bien.id === this.currentPlanLocalisation?.idBien
        );
        this.setFormArrayPerimetre();
        if (this.currentPlanLocalisation) {
            // Sélection du backgroundMap du plan de localisation
            this.backgroundMapSelected = this.backgroundMaps.find((backgroundMapTemp) => {
                return backgroundMapTemp.fileId === this.currentPlanLocalisation.backgroundFileId;
            });

            this.currentStorey = this.hapCnMapService.loadBuilding(
                this.diagnostic,
                this.currentPlanLocalisation as any,
                LevelToDisplay.PERIMETRE,
                this.intervention
            );
        } else {
            this.currentStorey = undefined;
        }
    }

    /**
     * Passer au plan précédent (flèche de gauche)
     */
    onClickPreviousPlanLocalisation() {
        this.onChangePlanLocalisation(
            this.listePlansLocalisation[
                this.listePlansLocalisation.findIndex((planL) => this.currentPlanLocalisation.id === planL.id) - 1
            ]
        );
    }

    /**
     * Passer au plan suivant(flèche de droite)
     */
    onClickNextPlanLocalisation() {
        this.onChangePlanLocalisation(
            this.listePlansLocalisation[
                this.listePlansLocalisation.findIndex((planL) => this.currentPlanLocalisation.id === planL.id) + 1
            ]
        );
    }

    onOpenEditPanel(data: any) {
        if (this.drawer) {
            this.drawer.open();
        }
        this.checkValidity();
        // Si data est null ou undefined, c'est que le perimètre a été supprimé
        if (!data) {
            this.onCloseEditPanel();
            return;
        }

        this.editedFormGroup = data.delimitationFormGroupToEdit;
        if (this.editedFormGroup && !this.diagnostic.typePrestation.startsWith('HAP')) {
            this.editedFormGroup.removeControl('typeOuvrage');
        }
        this.drawer.open();
    }

    onCloseEditPanel() {
        if (this.drawer) {
            this.drawer.close();
            if (this.viewMap) {
                this.viewMap.refresh();
            }
        }
        this.editedFormGroup = null;
        this.checkValidity();
        if (this.currentPlanLocalisation) {
            this.hapCheckValidityService.checkValidityZoneFromOutside(
                this.currentPlanLocalisation.listePerimetres,
                this.currentPlanLocalisation.id,
                this.ngUnsubscribe,
                this.diagnostic
            );
        }
    }

    /**
     * Evènement lorsque l'opérateur valide ou invalide les périmètres du plan de localisation
     */
    onChangeIsPerimetreValid(isValid: boolean) {
        if (this.currentPlanLocalisation) {
            this.currentPlanLocalisation.isPerimetreValid = isValid;
        }

        this.checkValidity(this.listeDelimitationArrayForm);
        this.calculateReadonlyMode();
        this.checkIsOnePerimetreInvalid();
    }

    /**
     * Vérifie si au moins un Périmètre n'a pas été validé parmi les espaces présent dans le diagnostic
     */
    checkIsOnePerimetreInvalid() {
        this.isOnePerimetreInvalid = this.hapCheckValidityService.checkIsOnePerimetreInvalid(this.diagnostic);
    }

    private setFormArrayPerimetre() {
        if (this.currentPlanLocalisation) {
            this.listeDelimitationArrayForm = this.hapFormService.createFormArrayListePerimetres(
                this.currentPlanLocalisation.listePerimetres,
                this.diagnostic,
                LevelToDisplay.PERIMETRE,
                this.ngUnsubscribe
            );

            if (this.readonlyMode) {
                this.listeDelimitationArrayForm.disable();
            }
        } else {
            this.listeDelimitationArrayForm = this.formBuilder.array([]);
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.onCloseEditPanel();
        if (this.diagnostic) {
            this.checkValidity();
        }
    }
    calculateReadonlyMode() {
        // Vérification si le module doit être forcé en readonly :
        const readonlyRouter = this.isReadonlyForced || this.checkDataIsValidate();
        this.diagReadonly = this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
        this.readonlyMode = readonlyRouter || this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
    }

    /**
     * initialise le diagnostic, l'intervention et les backgrounds du composant
     * et renvois un observable sur les paramètres de la route
     */
    hapBaseComponentInitialisation(): Observable<any> {
        return combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
        ]).pipe(
            switchMap(([intervention, diag]) => {
                console.log(diag);
                return combineLatestOrEmpty([
                    of(intervention),
                    of(diag),
                    ...this.hapService.requestAllBackgroundImage(intervention),
                ]);
            }),
            switchMap(([intervention, diagnostic]) => {
                this.diagnostic = diagnostic;
                this.intervention = intervention;

                // Sauvegarde de l'ensemble des backgroundMap des biens
                intervention.relationInterventionBiens.forEach((relationInterventionBienTemp) => {
                    relationInterventionBienTemp.bien.backgroundMaps.forEach((backgroundMapTemp) => {
                        if (backgroundMapTemp.imageUrl) {
                            this.backgroundMaps.push(backgroundMapTemp);
                        }
                    });
                });

                return this.route.paramMap;
            })
        );
    }

    /**
     * Vérifie si l'opérateur a valider les données saisies dans la partie Etude de situation.
     * Si c'est le cas, il ne peut pas modifier les données, il est obligé de cliquer sur Modifier avant depouvoir modifier puis de revalider
     */
    private checkDataIsValidate() {
        if (this.diagnostic.typePrestation != 'HAP_ETUDE_SITUATION') {
            switch (this.currentLevelToDisplay) {
                case LevelToDisplay.PERIMETRE:
                    return this.currentPlanLocalisation && this.currentPlanLocalisation.isPerimetreValid;
                case LevelToDisplay.BESOIN:
                    return this.currentPlanLocalisation && this.currentPlanLocalisation.isBesoinValid;
                default:
                    return false;
            }
        } else {
            return false;
        }
    }
    /**
     * Event déclenché lors du click sur le bouton de dessin de l'emplacement dans le composant liste des périmètres
     * @param data Contient le formGroup que l'on veut dessiner
     */
    onEditLocation(data: any) {
        if (data && data.formGroupToLocate) {
            switch (this.currentLevelToDisplay) {
                case LevelToDisplay.PERIMETRE:
                    this.elementToLocate = this.hapFormService.findPerimetreByFormGroup(
                        this.currentPlanLocalisation.listePerimetres,
                        data.formGroupToLocate
                    );
                    break;
                case LevelToDisplay.ZONE:
                    this.elementToLocate = this.hapFormService.findZoneByFormGroup(
                        this.currentPlanLocalisation.listePerimetres,
                        data.formGroupToLocate
                    );
                    break;
                case LevelToDisplay.BESOIN:
                    this.elementToLocate = this.hapFormService.findBesoinByFormGroup(
                        this.currentPlanLocalisation.listePerimetres,
                        data.formGroupToLocate
                    );
                    break;
                case LevelToDisplay.PRELEVEMENT:
                    this.elementToLocate = this.hapFormService.findPrelevementOrBesoinByFormGroup(
                        this.currentPlanLocalisation.listePerimetres,
                        data.formGroupToLocate
                    );
                    break;
            }
        } else {
            this.elementToLocate = undefined;
        }
    }

    /**
     * Event déclenché lors de chaque action sur la map
     * @param event
     */
    viewMapHapEvent(event) {
        // La création se déclenche dès que l'utilisateur relache le click pour finir le dessin
        if (event.event === 'creation' && event.value) {
            const selected = event.value;
            this.hapCnMapService.addMarkerToEspace(this.currentPlanLocalisation as any, selected);

            // On redéfini l'objet en cours de dessin à undefined pour désactiver le dessin
            this.elementToLocate = undefined;

            // Le trie permet de placer le marker prélèvement au dessus des markers périmètre et zone
            this.hapCnMapService.sortMarkers(this.currentStorey.markers);
        }

        // Le change se déclenche dès que l'utilisateur déplace ou modifie les dimensions du dessin
        if (event.event === 'change') {
            // On met à jour les markerJson dans l'espace pour sauvegarder la nouvelle position ou les nouvelles dimensions
            this.hapCnMapService.mapAllMarkersOfStoreyToJson(
                this.currentPlanLocalisation as any,
                this.currentStorey,
                this.currentLevelToDisplay
            );
        }
    }

    /**
     * Vérifie la validité de l'écran
     * @param listeDelimitationArrayForm
     * @private
     */
    protected checkValidity(listeDelimitationArrayForm?: FormArray) {
        if (this.diagnostic) {
            let state;
            switch (this.currentLevelToDisplay) {
                case LevelToDisplay.PERIMETRE:
                    state = this.hapCheckValidityService.checkValidityPerimetre(
                        this.diagnostic,
                        this.ngUnsubscribe,
                        this.diagnostic.typePrestation
                    );
                    break;
                case LevelToDisplay.ZONE:
                    state = this.hapCheckValidityService.checkValidityZone(listeDelimitationArrayForm);
                    break;
                case LevelToDisplay.BESOIN:
                    state = this.hapCheckValidityService.checkValidityBesoin(
                        listeDelimitationArrayForm,
                        this.diagnostic.typePrestation,
                        this.currentPlanLocalisation.isBesoinValid
                    );
                    break;
                case LevelToDisplay.PRELEVEMENT:
                    state = this.hapCheckValidityService.checkValidityPrelevement(listeDelimitationArrayForm);
                    break;
            }

            const code =
                this.currentLevelToDisplay === LevelToDisplay.PERIMETRE || !this.currentPlanLocalisation
                    ? this.route.snapshot.data['code']
                    : `${this.route.snapshot.data['code']}_${this.currentPlanLocalisation.id}`;
            this.etatProgressionService.updateDiagnostic(`${code}`, state, this.diagnostic, true);
        }
    }

    /**
     * Vérifie les valeurs présentes dans les datas du routeur
     * - checkvalidation
     * - readonly
     * @param routerData
     */
    checkRouterData(routerData: Data) {
        this.checkValidation = !!routerData.checkValidation;
        this.isReadonlyForced = !!routerData.readonly;
        this.calculateReadonlyMode();
    }
}

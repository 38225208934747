<mat-sidenav-container class="sidenav-container">
    <!----------------------------------------------------->
    <!-- DEBUT SIDEBAR CHOIX MODE LISTE OU PLAN à GAUCHE -->
    <!----------------------------------------------------->
    <mat-sidenav [mode]="'side'" [opened]="true" class="sidebar-button">
        <div class="d-flex flex-column w-100 justify-content-end">
            <mat-button-toggle-group
                appearance="legacy"
                [vertical]="true"
                (change)="changeViewMode($event)"
                [value]="viewMode"
            >
                <mat-button-toggle matTooltip="Vue liste" value="list">
                    <mat-icon>list</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle matTooltip="Vue plan" value="plan">
                    <mat-icon>layers</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
            <mat-divider></mat-divider>
            <div *ngIf="viewMode === 'list'" class="d-flex flex-column control-group">
                <div
                    class="item-menu"
                    matTooltip="Commun"
                    [matTooltipPosition]="'right'"
                    (click)="filterChanged('COMMUN')"
                    [class.selected]="categoryFilter === 'COMMUN'"
                >
                    <lib-icon [iconName]="'clipboard'" [theme]="'mdi'"></lib-icon>
                </div>
                <div
                    class="item-menu"
                    *ngFor="let prestaDiag of intervention?.prestationsDiagnostics"
                    [class.selected]="categoryFilter === prestaDiag.prestation.typePrestation"
                    [class.disabled]="
                        typeDiagnostic !== 'COMMUN' && typeDiagnostic !== prestaDiag.prestation.typePrestation
                    "
                    (click)="filterChanged(prestaDiag.prestation.typePrestation)"
                    matTooltip="{{ prestaDiag.prestation.typePrestation | typePrestation }}"
                    [matTooltipPosition]="'right'"
                >
                    <ng-container
                        *ngIf="
                            prestaDiag.prestation.referencePrestation | pictoPrestation | async as fileContent;
                            else default_img
                        "
                    >
                        <img *ngIf="fileContent" [src]="fileContent" alt="" />
                    </ng-container>
                    <ng-template #default_img>
                        <lib-icon [iconName]="'file-image'" [theme]="'mdi'"></lib-icon>
                    </ng-template>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <!--------------------------------------------------->
    <!-- FIN SIDEBAR CHOIX MODE LISTE OU PLAN à GAUCHE -->
    <!--------------------------------------------------->
    <mat-sidenav-content class="main" [ngClass]="pagesPanelOpened ? 'main' : 'main-fullscreen'">
        <mat-sidenav-container class="sidenav-container" [autosize]="true" cdkDropListGroup>
            <!-- Partie gauche : liste des éléments à déplacer dans les pièces -->

            <!------------------------------------------------------------------------------------------------------------->
            <!-- DEBUT SIDEBAR LISTE ELEMENTS à GAUCHE EN MODE LISTE OU DETAIL PIECE ET EQUIPEMENT à DROITE EN MODE PLAN -->
            <!------------------------------------------------------------------------------------------------------------->
            <mat-sidenav
                [mode]="pagesPanelToggleable ? 'over' : 'side'"
                [(opened)]="pagesPanelOpened"
                class="mat-elevation-z4 sidebar p-2"
                [position]="viewMode === 'list' ? 'start' : 'end'"
            >
                <!--                Mode list-->
                <ng-container *ngIf="viewMode === 'list'">
                    <div>
                        <!-- Barre de recherche-->
                        <div class="input-with-prefix">
                            <mat-form-field class="w-100">
                                <input
                                    placeholder="Rechercher"
                                    matInput
                                    type="text"
                                    [(ngModel)]="searchword"
                                    (input)="search()"
                                />
                                <lib-icon matPrefix>search</lib-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Pièces vide-->
                    <mat-accordion
                        *ngIf="viewMode === 'list' && emptyRoomsFiltered && emptyRoomsFiltered.length > 0"
                        class="equipment-accordion border-0"
                        [togglePosition]="'before'"
                    >
                        <mat-expansion-panel>
                            <mat-expansion-panel-header
                                [collapsedHeight]="customCollapsedHeight"
                                [expandedHeight]="customExpandedHeight"
                            >
                                <div class="d-flex align-items-center w-100">
                                    <mat-panel-title class="mat-subheading-2 primary m-0">
                                        <div class="flex-grow-1 mat-subheading-2 primary m-0">Pièces</div>
                                    </mat-panel-title>
                                    <mat-panel-description
                                        class="d-flex justify-content-end mr-0"
                                        (click)="$event.stopPropagation()"
                                    >
                                    </mat-panel-description>
                                </div>
                            </mat-expansion-panel-header>
                            <div
                                cdkDropList
                                id="emptyRoomList"
                                [cdkDropListData]="typesVolumes"
                                cdkDropListConnectedTo="[roomList]"
                                (cdkDropListDropped)="dropRoom($event)"
                                [cdkDropListDisabled]="readonlyMode"
                                [cdkDropListEnterPredicate]="noReturnPredicate"
                            >
                                <div *ngFor="let emptyRoom of emptyRoomsFiltered" cdkDrag [cdkDragData]="emptyRoom">
                                    <div class="equipment-card room p-1 m-1 d-flex align-items-center">
                                        <div cdkDragHandle class="c-grabbable d-flex pr-2" *ngIf="!readonlyMode">
                                            <lib-icon>drag_indicator</lib-icon>
                                        </div>
                                        <div class="d-flex w-100 justify-content-between">
                                            {{ emptyRoom.nom }}
                                            <lib-icon
                                                *ngIf="emptyRoom.etatVolume === undefined"
                                                [theme]="'mdi'"
                                                iconName="content-save-off"
                                            >
                                            </lib-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <!-- Pièces prééquipées-->
                    <mat-accordion
                        *ngIf="viewMode === 'list' && preEquippedRoomsFiltered && preEquippedRoomsFiltered.length > 0"
                        class="equipment-accordion border-0"
                        [togglePosition]="'before'"
                    >
                        <mat-expansion-panel>
                            <mat-expansion-panel-header
                                [collapsedHeight]="customCollapsedHeight"
                                [expandedHeight]="customExpandedHeight"
                            >
                                <div class="d-flex align-items-center w-100">
                                    <mat-panel-title class="mat-subheading-2 primary m-0">
                                        <div class="flex-grow-1 mat-subheading-2 primary m-0">Pièces prééquipées</div>
                                    </mat-panel-title>
                                    <mat-panel-description
                                        class="d-flex justify-content-end mr-0"
                                        (click)="$event.stopPropagation()"
                                    >
                                        <button
                                            mat-button
                                            color="accent"
                                            matTooltip="Ajouter une pièce prééquipée"
                                            [disabled]="readonlyMode"
                                            (click)="addPreequippedRoom()"
                                        >
                                            <lib-icon>add</lib-icon>
                                        </button>
                                    </mat-panel-description>
                                </div>
                            </mat-expansion-panel-header>
                            <div
                                cdkDropList
                                id="preEquippedRoomList"
                                [cdkDropListData]="typesVolumes"
                                cdkDropListConnectedTo="[roomList]"
                                (cdkDropListDropped)="dropRoom($event)"
                                [cdkDropListDisabled]="readonlyMode"
                                [cdkDropListEnterPredicate]="noReturnPredicate"
                            >
                                <div
                                    *ngFor="let preEquippedRoom of preEquippedRoomsFiltered"
                                    cdkDrag
                                    [cdkDragData]="preEquippedRoom"
                                >
                                    <div class="equipment-card room p-1 m-1 d-flex align-items-center">
                                        <div cdkDragHandle class="c-grabbable d-flex pr-2" *ngIf="!readonlyMode">
                                            <lib-icon>drag_indicator</lib-icon>
                                        </div>
                                        <div class="d-flex w-100 justify-content-between">
                                            {{ preEquippedRoom.nom }}
                                            {{
                                                preEquippedRoom.piecesPreequipeesConfigs[0].nom
                                                    ? ' - ' + preEquippedRoom.piecesPreequipeesConfigs[0].nom
                                                    : ''
                                            }}
                                            <lib-icon
                                                *ngIf="!preEquippedRoom.etatVolume"
                                                [theme]="'mdi'"
                                                iconName="content-save-off"
                                            >
                                            </lib-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <!-- Equipements-->
                    <mat-accordion
                        *ngIf="typesElementsAControlerFiltered && typesElementsAControlerFiltered.length > 0"
                        class="equipment-accordion border-0"
                        [togglePosition]="'before'"
                    >
                        <mat-expansion-panel>
                            <mat-expansion-panel-header
                                [collapsedHeight]="customCollapsedHeight"
                                [expandedHeight]="customExpandedHeight"
                            >
                                <div class="d-flex align-items-center w-100">
                                    <mat-panel-title class="mat-subheading-2 primary m-0">
                                        <div class="flex-grow-1 mat-subheading-2 primary m-0">Equipements</div>
                                    </mat-panel-title>
                                    <mat-panel-description
                                        class="d-flex justify-content-end mr-0"
                                        (click)="$event.stopPropagation()"
                                    >
                                        <button
                                            mat-button
                                            color="accent"
                                            matTooltip="Ajouter un équipement"
                                            [disabled]="readonlyMode"
                                            (click)="addEquipment()"
                                        >
                                            <lib-icon>add</lib-icon>
                                        </button>
                                    </mat-panel-description>
                                </div>
                            </mat-expansion-panel-header>
                            <div
                                cdkDropList
                                id="equipmentList"
                                [cdkDropListConnectedTo]="roomIndexes"
                                [cdkDropListSortingDisabled]="true"
                                [cdkDropListData]="typesElementsAControler"
                                [cdkDropListDisabled]="readonlyMode"
                                (cdkDropListDropped)="dropEquipment($event)"
                                [cdkDropListEnterPredicate]="noReturnPredicate"
                            >
                                <div *ngFor="let typeElement of typesElementsAControlerFiltered">
                                    <div
                                        class="equipment-card p-1 m-1 d-flex align-items-center"
                                        cdkDrag
                                        [cdkDragData]="typeElement"
                                    >
                                        <div cdkDragHandle class="c-grabbable d-flex pr-2" *ngIf="!readonlyMode">
                                            <lib-icon>drag_indicator</lib-icon>
                                        </div>
                                        <div class="flex-grow-1">{{ typeElement.nom }}</div>
                                        <button
                                            mat-icon-button
                                            class="small-button"
                                            *ngIf="typeElement.custom"
                                            matTooltip="Éditer l'équipement"
                                            (click)="editEquipment(typeElement)"
                                        >
                                            <mat-icon class="icon-small">edit</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>

                <!--                Mode plan-->
                <ng-container *ngIf="viewMode === 'plan'">
                    <ng-container *ngIf="detailNiveauMode && currentStair?.volumes.length > 0">
                        <app-bien-niveau-select
                            *ngIf="bienSelected?.jsonPlan"
                            [hideBiensSelection]="true"
                            [column]="true"
                            [biens]="[bienSelected]"
                            [selectedBien]="bienSelected?.nom"
                            [selectedNiveau]="currentStair"
                            [forPlan]="false"
                            (niveauChanged)="changeCurrentStairMap($event)"
                        >
                        </app-bien-niveau-select>
                        <div
                            *ngFor="let piece of currentStair?.volumes"
                            class="d-flex piece-item justify-content-between align-items-center"
                            (click)="onClickChangeCurrentRoomMap(piece)"
                        >
                            <div class="piece-title">
                                <span>{{ piece.nom }} ({{ piece.elementsAControler.length }})</span>
                            </div>
                            <div class="d-flex align-items-baseline">
                                <ng-container (click)="$event.stopPropagation()">
                                    <app-notes-buttons [from]="piece" [isDescription]="true"></app-notes-buttons>
                                </ng-container>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    matTooltip="Éditer le volume"
                                    [disabled]="readonlyMode"
                                    (click)="editRoomParam($event, piece)"
                                >
                                    <mat-icon class="icon-small">edit</mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>

                    <div *ngIf="detailPieceMode && pieceSelected">
                        <button mat-raised-button color="accent" (click)="backToPieces()" class="mb-4">
                            <lib-icon>arrow_back</lib-icon>
                            <span>Retour</span>
                        </button>
                        <div class="d-flex align-items-baseline flex-wrap w-100 room-buttons-placeholder">
                            <div class="d-flex flex-grow-1 mat-h2 ml-2 align-self-center room-name">
                                {{ pieceSelected.nom }}
                            </div>
                            <ng-container *ngIf="!pieceSelected.volumeCache">
                                <app-notes-buttons [from]="pieceSelected" [isDescription]="true"></app-notes-buttons>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    matTooltip="Éditer le volume"
                                    [disabled]="readonlyMode"
                                    (click)="editRoomParam($event, pieceSelected)"
                                >
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </ng-container>
                        </div>
                        <div *ngIf="pieceSelected.elementsAControler.length === 0">
                            <span>Ne contient pas d'équipement</span>
                        </div>
                        <div *ngIf="pieceSelected.elementsAControler.length > 0">
                            <div
                                *ngFor="let equipment of pieceSelected.elementsAControler"
                                cdkDrag
                                cdkDragDisabled="true"
                                class="item-clickable"
                                (click)="onClickCardEquipment(equipment, $event)"
                            >
                                <div class="d-flex equipment-card p-2 m-1 align-items-center">
                                    <div class="flex-grow-1">{{ equipment.nom }}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="!pieceSelected?.volumeCache"
                            class="d-flex align-items-center justify-content-between"
                        >
                            <b>{{ 'Visitée' | uppercase }}</b>
                            <app-state-input
                                class="right-separation"
                                [choices]="[validatedChoiceOption, warningChoiceOption, notValidatedChoiceOption]"
                                [tooltips]="tooltipsVisite"
                                [disabled]="readonlyMode"
                                [(choiceValue)]="pieceSelected?.valeursParametres[paramVolumeVisite]"
                                (valueChangeFromUser)="currentVisiteChanged($event)"
                            >
                            </app-state-input>
                        </div>
                    </div>
                </ng-container>
            </mat-sidenav>
            <!----------------------------------------------------------------------------------------------------------->
            <!-- FIN SIDEBAR LISTE ELEMENTS à GAUCHE EN MODE LISTE OU DETAIL PIECE ET EQUIPEMENT à DROITE EN MODE PLAN -->
            <!----------------------------------------------------------------------------------------------------------->

            <mat-sidenav-content class="main" [ngClass]="pagesPanelOpened ? 'main' : 'main-fullscreen'">
                <!--------------------------------------------------------------------------------------------------->
                <!-- DEBUT SIDEBAR LISTE PIECES ET EQUIPEMENT à DROITE EN MODE LISTE OU PLAN à GAUCHE EN MODE PLAN -->
                <!--------------------------------------------------------------------------------------------------->
                <div class="row pl-4 py-2 w-100 bien-storey-content">
                    <div
                        class="to-justify d-flex justify-content-center align-items-center"
                        *ngIf="relationBienPrincipal?.bien.hasNewVersionInRelationInterventionBien && !readonlyMode"
                    >
                        Il existe une version plus récente de ce bien, voulez-vous la récupérer?
                        <button class="ml-2" mat-raised-button (click)="onClickBtnReloadBien(false)">
                            <span>Non, garder en l'état</span>
                        </button>
                        <button class="ml-2" mat-raised-button (click)="onClickBtnReloadBien(true)">
                            <span>Oui</span>
                        </button>
                    </div>
                    <div class="d-flex no-gutters w-100 justify-content-end">
                        <!-- Section étages -->
                        <ng-container *ngIf="viewMode === 'list'">
                            <div
                                class="d-flex flex-grow-1 flex-wrap stair-container"
                                cdkDropList
                                cdkDropListOrientation="horizontal"
                                [cdkDropListDisabled]="readonlyMode"
                                (cdkDropListDropped)="dropStair($event)"
                            >
                                <div
                                    *ngFor="let stair of bienSelected?.description"
                                    cdkDrag
                                    [cdkDragData]="stair"
                                    class="d-flex no-gutters align-items-center"
                                >
                                    <button
                                        mat-button
                                        color="primary"
                                        class="mr-1"
                                        [ngClass]="stair === currentStair ? 'current-stair-button' : 'stair'"
                                        (click)="changeStair(stair)"
                                    >
                                        <span class="d-flex align-items-center">
                                            <span cdkDragHandle class="c-grabbable d-flex" *ngIf="!readonlyMode">
                                                <lib-icon>drag_indicator</lib-icon>
                                            </span>
                                            <mat-icon *ngIf="!stair.storeyId">list</mat-icon>
                                            {{ stair.nom }}
                                        </span>
                                    </button>
                                </div>

                                <button
                                    mat-raised-button
                                    color="accent"
                                    [disabled]="readonlyMode"
                                    (click)="addStair()"
                                    data-cy="ajouter-niveau-button"
                                >
                                    <lib-icon>add</lib-icon>
                                    {{ 'Ajouter un niveau' | uppercase }}
                                </button>
                            </div>
                            <div *ngIf="bienSelected?.jsonPlan" class="pr-2">Plan déjà présent</div>
                        </ng-container>
                        <div>
                            <button mat-raised-button color="accent" (click)="openEditor()" [disabled]="readonlyMode">
                                <mat-icon>layers</mat-icon>
                                Editeur
                            </button>
                        </div>
                    </div>
                    <ng-container *ngIf="viewMode === 'plan'">
                        <div class="d-flex flex-grow-1 stair-container">
                            <mat-card
                                *ngIf="!bienSelected?.jsonPlan"
                                class="
                                    w-100
                                    drop-card-placeholder
                                    d-flex
                                    justify-content-center
                                    align-items-center
                                    my-3
                                "
                            >
                                Pas de plan disponible pour ce bien.
                            </mat-card>
                        </div>
                    </ng-container>
                    <div
                        *ngIf="planRequired && !bienSelected?.jsonPlan"
                        class="d-flex mt-4 no-gutters w-100 justify-content-center"
                    >
                        <mat-card class="to-justify">
                            L'option plan a été demandé pour au moins une prestation
                        </mat-card>
                    </div>
                    <div
                        *ngIf="
                            (intervention?.exigenceDescription === exigenceDescriptionCroquis ||
                                intervention?.exigenceDescription === exigenceDescriptionPlan) &&
                            !bienSelected?.jsonPlan
                        "
                        class="d-flex mt-4 no-gutters w-100 justify-content-center"
                    >
                        <mat-card class="to-justify">
                            L'intervention nécessite un
                            {{ intervention?.exigenceDescription === exigenceDescriptionCroquis ? 'croquis' : 'plan' }}
                        </mat-card>
                    </div>

                    <!-- Étage courant-->
                    <div
                        [style.visibility]="currentStair ? undefined : 'hidden'"
                        [ngClass]="viewMode === 'list' ? 'p-4' : 'p-2'"
                        class="d-flex flex-column justify-content-between w-100"
                    >
                        <div
                            *ngIf="viewMode === 'list'"
                            class="d-flex no-gutters w-100 align-items-center current-stair-section mb-2"
                        >
                            <div class="d-flex flex-grow-1 mat-subheading-2 m-0 pl-2">
                                {{ currentStair?.nom }}
                            </div>
                            <div>
                                <button
                                    *ngIf="!currentStair?.storeyId && bienSelected?.jsonPlan"
                                    mat-icon-button
                                    matTooltip="Associer le niveau"
                                    (click)="mergeNiveau(currentStair)"
                                    [disabled]="readonlyMode"
                                >
                                    <mat-icon>swap_horiz</mat-icon>
                                </button>

                                <button
                                    mat-icon-button
                                    matTooltip="Éditer le niveau"
                                    [disabled]="readonlyMode"
                                    (click)="editStair()"
                                >
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button
                                    *ngIf="!currentStair?.storeyId"
                                    mat-icon-button
                                    matTooltip="Copier le niveau"
                                    [disabled]="readonlyMode"
                                    (click)="copyCurrentStair()"
                                >
                                    <mat-icon>content_copy</mat-icon>
                                </button>
                                <button
                                    *ngIf="!currentStair?.storeyId"
                                    mat-icon-button
                                    color="warn"
                                    matTooltip="Supprimer le niveau"
                                    [disabled]="readonlyMode"
                                    (click)="deleteCurrentStair()"
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>

                        <!-- Partie Liste de la description, pièces pré-équipées, équipements -->
                        <ng-container *ngIf="viewMode === 'list'">
                            <!-- Description du bien-->

                            <!--Volume caché-->
                            <mat-card *ngIf="currentStair?.volumes" class="position-relative mb-2 p-0 room-card">
                                <mat-accordion [togglePosition]="'before'" class="description-accordion">
                                    <app-description-room-section
                                        [intervention]="intervention"
                                        [readonlyMode]="readonlyListe"
                                        [readonlyName]="readonlyListe && !readonlyMode"
                                        [niveau]="currentStair"
                                        [room]="volumeCache"
                                        [bien]="bienSelected"
                                    >
                                    </app-description-room-section>
                                </mat-accordion>
                            </mat-card>

                            <!-- Pièces contenues dans l'étage courant -->
                            <div
                                cdkDropList
                                id="roomList"
                                [cdkDropListData]="currentStair?.volumes"
                                cdkDropListConnectedTo="[emptyRoomList, preEquippedRoomList]"
                                (cdkDropListDropped)="dropRoom($event)"
                                [cdkDropListDisabled]="readonlyListe"
                                [cdkDropListEnterPredicate]="onlyPreEquippedRoomPredicate"
                            >
                                <div *ngIf="currentStair?.volumes.length > 1">
                                    <mat-card
                                        *ngFor="let room of currentStair?.volumes; let index = index"
                                        cdkDrag
                                        [cdkDragData]="room"
                                        class="position-relative mb-2 p-0 room-card"
                                    >
                                        <mat-accordion
                                            [togglePosition]="'before'"
                                            class="description-accordion"
                                            *ngIf="!room.volumeCache"
                                        >
                                            <div>
                                                <span
                                                    cdkDragHandle
                                                    class="position-absolute c-grabbable d-flex handle-room ml-2"
                                                    *ngIf="!readonlyMode"
                                                >
                                                    <lib-icon>drag_indicator</lib-icon>
                                                </span>
                                                <app-description-room-section
                                                    [intervention]="intervention"
                                                    [readonlyMode]="readonlyListe"
                                                    [readonlyName]="readonlyListe && !readonlyMode"
                                                    [niveau]="currentStair"
                                                    [room]="room"
                                                    [bien]="bienSelected"
                                                    [roomIndex]="'room_' + index"
                                                    (copyThisRoom)="copyRoom(room)"
                                                    (deleteThisRoom)="deleteRoom(room)"
                                                >
                                                </app-description-room-section>
                                            </div>
                                        </mat-accordion>
                                    </mat-card>
                                </div>
                                <div
                                    cdkDrag
                                    *ngIf="currentStair?.volumes.length === 1"
                                    class="
                                        w-100
                                        drop-card-placeholder
                                        d-flex
                                        justify-content-center
                                        align-items-center
                                        mb-2
                                    "
                                >
                                    Déposez une pièce pré-équipée ou créez une pièce
                                </div>

                                <div class="d-flex justify-content-center">
                                    <mat-card
                                        class="d-flex small-card action-card align-self-center"
                                        [ngClass]="readonlyMode ? 'disabled' : ''"
                                    >
                                        <mat-card-content class="w-100">
                                            <button
                                                class="w-100"
                                                mat-button
                                                [disabled]="readonlyMode"
                                                (click)="addNewRoom()"
                                                data-cy="ajouter-piece-button"
                                            >
                                                <lib-icon>add</lib-icon>
                                                <br />{{ 'Ajouter une pièce' }}
                                            </button>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Partie Plan -->
                        <ng-container *ngIf="viewMode === 'plan'">
                            <app-viewer-map
                                *ngIf="bienBuilding"
                                #viewMap
                                [building]="bienBuilding"
                                [storeyIdToDisplay]="currentStair?.storeyId"
                                [currentBien]="_relationInterventionBien"
                                [currentNiveau]="currentStair"
                                [useDiagTool]="true"
                                [viewerMapConfig]="viewerMapConfig"
                                (toolEvent)="viewMapEvent($event)"
                                [externalSelectedElement]="externalSelectedElement"
                                [externalSelectedVolume]="externalSelectedVolume"
                            >
                            </app-viewer-map>
                        </ng-container>
                    </div>
                </div>
            </mat-sidenav-content>
            <!------------------------------------------------------------------------------------------------->
            <!-- FIN SIDEBAR LISTE PIECES ET EQUIPEMENT à DROITE EN MODE LISTE OU PLAN à GAUCHE EN MODE PLAN -->
            <!------------------------------------------------------------------------------------------------->
        </mat-sidenav-container>
    </mat-sidenav-content>
</mat-sidenav-container>

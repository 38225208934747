"use strict";
//***********************************************************************************
//***********************************************************************************
/**
 * @class cn_event_manager
 * An abstract class to manage events
 * if event is not managed (or if the callback returns something other than 'true'), it is transmitted to the parent.
 */

export class cn_event_manager {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_event_manager} parent
	 */
	constructor(parent = null) {
		this._parent = parent;
		this._events = {};
	}

	//***********************************************************************************
	/**
	 * Registers an event on a given function
	 * @param {string} ev
	 * @param {function} fun
	 */
	on(ev, fun) {
		if (typeof(this._events[ev]) == 'undefined')
			this._events[ev] = [fun];
		else
			this._events[ev].push(fun);
	}

	//***********************************************************************************
	/**
	 * Unregisters an event for all functions (fun = null), or for one given function
	 * @param {string} ev
	 * @param {function} fun
	 */
	unbind(ev, fun = null) {
		if (typeof(this._events[ev]) == 'undefined') return;
		if (fun)
		{
			var index = this._events[ev].indexOf(fun);
			if (index >= 0) this._events[ev].splice(index,1);
		}
		else
			this._events[ev] = [];
	}

	//***********************************************************************************
	/**
	 * Calls an event with given arguments
	 * @param {string} ev
	 * @param {any} arg
	 * @return {boolean} true if at least one callback was called and returned 'true'.
	 */
	call(ev, arg = null) {
		var funs = this._events[ev];
		var res = false;
		if (typeof(funs) == 'object')
		{
			for (var j in funs)
			{
				const r = funs[j](arg);
				if (r === true) res = true;
			}
		}
		if (!res && this._parent) return this._parent.call(ev,arg);
		return res;
	}
}


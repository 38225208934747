import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormContext } from '../../../../../../model/rule/form-context.model';
import { FormService } from '../../../../../../services/form.service';
import { CeeModel } from '../../../model/cee.model';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { first } from 'rxjs/operators';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { TypeCommentaire } from '../../../../../../model/type-commentaire.model';

@Component({
    selector: 'app-generale',
    templateUrl: './general.component.html',
    styleUrls: ['./general.component.scss'],
})
export class GeneralComponent extends BaseComponent implements OnInit, OnDestroy {
    readonly COMMENT_TYPES: TypeCommentaire[] = [
        'NOTE_PERSONNELLE',
        'REMARQUE',
        'JUSTIFICATION_NON_CONFORMITE',
        'CONSTATATION_DIVERSE',
    ];
    readonlyMode: boolean = false;
    public diagnostic: Diagnostic;
    formGeneral: FormGroup;
    ceeModel: CeeModel = new CeeModel();
    constructor(
        private formService: FormService,
        private readonly diagnosticService: DiagnosticService,
        private etatProgressionService: EtatProgressionService,
        private formBuilder: FormBuilder
    ) {
        super();
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(first())
            .subscribe((diagnostic) => {
                this.diagnostic = diagnostic;
                this.ceeModel = diagnostic.contenuDiagnostic as CeeModel;
            });
    }

    ngOnInit(): void {
        this.formGeneral = this.formBuilder.group({
            nomEntreprise: this.formService.createFormControl(
                'nomEntreprise',
                new FormContext('nomEntreprise.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            nomEntrepriseNR: this.formService.createFormControl(
                'nomEntrepriseNR',
                new FormContext('nomEntreprise.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            numeroSIREN: this.formService.createFormControl(
                'numeroSIREN',
                new FormContext('numeroSIREN.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            entrepriseRGE: this.formService.createFormControl(
                'entrepriseRGE',
                new FormContext('entrepriseRGE.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            numeroSIRENNR: this.formService.createFormControl(
                'numeroSIRENNR',
                new FormContext('numeroSIREN.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateVisitePrealable: this.formService.createFormControl(
                'dateVisitePrealable',
                new FormContext('dateVisitePrealable.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateVisitePrealableNR: this.formService.createFormControl(
                'dateVisitePrealableNR',
                new FormContext('dateVisitePrealable.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateAcceptationDevis: this.formService.createFormControl(
                'dateAcceptationDevis',
                new FormContext('dateAcceptationDevis.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateAcceptationDevisNR: this.formService.createFormControl(
                'dateAcceptationDevisNR',
                new FormContext('dateAcceptationDevis.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateDebutTravaux: this.formService.createFormControl(
                'dateDebutTravaux',
                new FormContext('dateDebutTravaux.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateDebutTravauxNR: this.formService.createFormControl(
                'dateDebutTravauxNR',
                new FormContext('dateDebutTravaux.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateFacture: this.formService.createFormControl(
                'dateFacture',
                new FormContext('dateFacture.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateFactureNR: this.formService.createFormControl(
                'dateFactureNR',
                new FormContext('dateFacture.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateEtatRecapitulatif: this.formService.createFormControl(
                'dateEtatRecapitulatif',
                new FormContext('dateEtatRecapitulatif.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            dateEtatRecapitulatifNR: this.formService.createFormControl(
                'dateEtatRecapitulatifNR',
                new FormContext('dateEtatRecapitulatif.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
            refFacture: this.formService.createFormControl(
                'refFacture',
                new FormContext('refFacture.valeur', this.ceeModel, [], this.ngUnsubscribe)
            ),
            refFactureNR: this.formService.createFormControl(
                'refFactureNR',
                new FormContext('refFacture.checked', this.ceeModel, [], this.ngUnsubscribe)
            ),
        });
    }

    ngOnDestroy() {
        this.etatProgressionService.updateDiagnostic(
            'GENERAL',
            this.formGeneral.valid ? 'VALID' : 'INVALID',
            this.diagnostic,
            true
        );
    }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import {
    BaseComponent,
    ConfirmationService,
    FileUploaderComponent,
    MongoUtils,
    NotificationService,
} from 'src/app/commons-lib';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Observable } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { InterventionFile, TypeReferenceFichier } from 'src/app/model/intervention-file.model';
import { FileApiService } from 'src/app/services/file-api.service';
import { FileService } from 'src/app/services/file.service';
import { InterventionFileApiService } from 'src/app/services/intervention-file-api.service';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { blobToArrayBuffer, blobToDataUrl } from 'src/app/shared/offline/file.utils';
import { CnSpinnerService } from '../cn-spinner/service/cn-spinner.service';
import { data } from 'cypress/types/jquery';
import { ExifParserFactory } from 'src/app/shared/ts-exif-parser';
import { ExifData } from 'src/app/shared/ts-exif-parser/ExifData';

export class FileUploaderTextConfigFile {
    mainText? = `Ajouter l'image`;
    deleteToolTip? = `Supprimer l'image`;
    changeToolTip? = `Changer la photo`;
    confirmDeleteDialog? = `Êtes-vous sûr de vouloir supprimer cette image ?`;
    fileDeleted? = `L'image a bien été supprimée`;
}

export class FileUploaderOutput {
    interventionFile: InterventionFile;
    fileName: string;
    dataUrl: string;
    constructor(interventionFile: InterventionFile, fileName: string, dataUrl: string) {
        this.interventionFile = interventionFile;
        this.fileName = fileName;
        this.dataUrl = dataUrl;
    }
}

@Component({
    selector: 'app-wizy-file-uploader',
    templateUrl: './wizy-file-uploader.component.html',
    styleUrls: ['./wizy-file-uploader.component.scss'],
})
export class WizyFileUploaderComponent extends BaseComponent implements OnChanges {
    // Défini si on n'autorise à télécharger que des images ou pas. Cet attribut va définir le composant que l'on va utiliser pour l'IHM
    @Input() isImage: boolean;

    /**
     * Permet de custommiser le contenu textuel du composant
     * @param textConfig
     */
    @Input() set textConfig(textConfig: FileUploaderTextConfigFile) {
        // On merge car les paramètres de personnalisation sont optionnels.
        const originalTextConfig = new FileUploaderTextConfigFile();
        this._textConfig = Object.assign(originalTextConfig, textConfig);
    }
    get textConfig(): FileUploaderTextConfigFile {
        return this._textConfig;
    }
    private _textConfig = new FileUploaderTextConfigFile();

    @Input() readonlyMode = false;
    @Input() interventionId: string;
    @Input() diagnosticId: string;
    @Input() referenceId: string;
    @Input() typeReferenceFichier: TypeReferenceFichier;
    @Input() fileId: string;
    @Input() autoUpload: boolean;

    @Output() fileUploaded = new EventEmitter<FileUploaderOutput>();
    @Output() deleted = new EventEmitter();

    @ViewChild('fileUploader') fileUploader: FileUploaderComponent;

    interventionFile: InterventionFile;
    editionOverlay = false;
    dataUrl: string = null;
    exifData: any = null;
    fileName: string = null;
    useDownloadPipe = false;

    constructor(
        private readonly fileApiService: FileApiService,
        private readonly interventionFileApiService: InterventionFileApiService,
        private readonly interventionFileService: InterventionFileService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService,
        private readonly imageCompress: NgxImageCompressService,
        private readonly fileService: FileService
    ) {
        super();
    }

    ngOnChanges(value) {
        // Création de l'interventionFile
        this.cnSpinnerService
            .withSpinner(this.initInterventionFile())
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
                this.updateUseDownloadPipe();
            });
    }

    /**
     * Initialise l'objet InterventionFile.
     * Récupère l'objet existant ou en crée un nouveau
     */
    private initInterventionFile(): Observable<InterventionFile> {
        return this.interventionFileService
            .findByIdInterventionIdDiagnosticIdReference(
                this.interventionId,
                this.diagnosticId,
                this.referenceId,
                this.typeReferenceFichier,
                this.fileId
            )
            .pipe(
                tap((interventionFile) => {
                    this.interventionFile = interventionFile;
                })
            );
    }

    /**
     * Règles permettant de déterminer si on doit utiliser le pipe permettant de télécharger l'image ou non.
     */
    private updateUseDownloadPipe(): void {
        this.useDownloadPipe = this.autoUpload;
    }

    /**
     * Prépare le fichier à uploader
     *  - Compression
     *  - Verification de la taille
     * @param fileData
     * @private
     */
    private async prepareFile(fileData: File): Promise<string> {
        let dataUrl = await blobToDataUrl(fileData);

        // Compression dans le cas d'une image
        if (this.isImage) {
            dataUrl = await this.imageCompress.compressFile(dataUrl, -1, 100, 60);
            dataUrl = this.fileService.verifySizeDocument(dataUrl);
        }
        return dataUrl;
    }

    private async recupereExif(fileData: File): Promise<ExifData> {
        let ArrayBuffer = await blobToArrayBuffer(fileData);

        let Data = ExifParserFactory.create(ArrayBuffer).parse();
        return Data;
    }

    /**
     * Evenement déclenché lorsqu'un fichier a été séléctionné dans le composant.
     * - Prépare le fichier a injecter directement dans le dom.
     *
     * Dans le cas d'un auto-upload :
     *  - Upload le fichier
     *  - Créer une occurence InterventionFile
     *
     * Renvoie un objet contenant les données du fichier et de l'intervention file à l'élément parent
     *  par le biais d'un event emitter
     * @param fichier
     */
    async onStartUploadFile(fichier: FormData): Promise<void> {
        // Préparation du fichier (compression vérification etc ...)
        // Récupération du fichier au format FormData et convertion en base64.
        const fileData = fichier.get('file') as File;
        this.fileName = fileData.name;
        this.dataUrl = await this.prepareFile(fileData);
        this.exifData = await this.recupereExif(fileData);

        // Si on est en mode autoUpload, on va requêter le serveur pour
        // sauvegarder le fichier et l'instance InterventionFile.
        if (this.autoUpload) {
            this.cnSpinnerService
                .withSpinner(
                    // Upload du fichier
                    this.fileApiService.uploadFile(this.dataUrl, this.fileName).pipe(
                        switchMap((fileDataResponse) => {
                            // On crée un objet temporaire afin de ne pas mettre à jour
                            // l'objet intervention File trop tôt et éviter de déclencher le pipe
                            // permettant de télécharger l'image.
                            // Sinon le download risque de se faire trop tôt, ce qui peut engendrer un problème de droit
                            const interventionFileTemp = Object.assign({}, this.interventionFile);
                            interventionFileTemp.fileId = fileDataResponse.fileId;

                            interventionFileTemp.creationDate =
                                this.exifData?.tags?.DateTimeOriginal?.toString().concat(
                                    this.exifData?.tags?.SubSecTimeOriginal?.slice(-3)
                                );
                            interventionFileTemp.gpsLatitudeRef = this.exifData?.tags?.GPSLatitudeRef ?? 'non défini';
                            interventionFileTemp.gpsLatitude = this.exifData?.tags?.GPSLatitude ?? 'non défini';
                            interventionFileTemp.gpsLongitudeRef = this.exifData?.tags?.GPSLongitudeRef ?? 'non défini';
                            interventionFileTemp.gpsLongitude = this.exifData?.tags?.GPSLongitude ?? 'non défini';
                            interventionFileTemp.gpsAltitudeRef = this.exifData?.tags?.GPSAltitudeRef ?? 'non défini';
                            interventionFileTemp.gpsAltitude = this.exifData?.tags?.GPSAltitude ?? 'non défini';
                            // Post de l'instance InterventionFile

                            return this.interventionFileApiService.upsert(interventionFileTemp);
                        })
                    )
                )
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((interventionFile) => {
                    // On assigne ici le fichier id pour ne pas déclencher le pipe de downlaod trop tôt
                    this.interventionFile.fileId = interventionFile.fileId;

                    this.interventionFile.creationDate = this.exifData?.tags?.DateTimeOriginal?.toString().concat(
                        this.exifData?.tags?.SubSecTimeOriginal?.slice(-3)
                    );
                    this.interventionFile.gpsLatitudeRef = this.exifData?.tags?.GPSLatitudeRef ?? 'non défini';
                    this.interventionFile.gpsLatitude = this.exifData?.tags?.GPSLatitude ?? 'non défini';
                    this.interventionFile.gpsLongitudeRef = this.exifData?.tags?.GPSLongitudeRef ?? 'non défini';
                    this.interventionFile.gpsLongitude = this.exifData?.tags?.GPSLongitude ?? 'non défini';
                    this.interventionFile.gpsAltitudeRef = this.exifData?.tags?.GPSAltitudeRef ?? 'non défini';
                    this.interventionFile.gpsAltitude = this.exifData?.tags?.GPSAltitude ?? 'non défini';

                    // On prépare l'objet contenant les datas et on le renvoie dans l'eventEmitter
                    this.fileUploaded.emit(
                        this.prepareFileUploaderOutput(this.interventionFile, this.fileName, this.dataUrl)
                    );
                });
        } else {
            // Si on n'est pas en mode autoUpload, on va simplement stocker le contenu sans faire de sauvegarder
            // On affiche donc les informations du fichier stockées en cache.
            this.interventionFile.fileId = MongoUtils.generateObjectId();

            this.interventionFile.creationDate = this.exifData?.tags?.DateTimeOriginal?.toString().concat(
                this.exifData?.tags?.SubSecTimeOriginal?.slice(-3)
            );
            this.interventionFile.gpsLatitudeRef = this.exifData?.tags?.GPSLatitudeRef ?? 'non défini';
            this.interventionFile.gpsLatitude = this.exifData?.tags?.GPSLatitude ?? 'non défini';
            this.interventionFile.gpsLongitudeRef = this.exifData?.tags?.GPSLongitudeRef ?? 'non défini';
            this.interventionFile.gpsLongitude = this.exifData?.tags?.GPSLongitude ?? 'non défini';
            this.interventionFile.gpsAltitudeRef = this.exifData?.tags?.GPSAltitudeRef ?? 'non défini';
            this.interventionFile.gpsAltitude = this.exifData?.tags?.GPSAltitude ?? 'non défini';

            // On prépare l'objet contenant les datas et on le renvoie dans l'eventEmitter
            this.fileUploaded.emit(this.prepareFileUploaderOutput(this.interventionFile, this.fileName, this.dataUrl));
        }

        console.log(this.interventionFile);
    }

    /**
     * Supprime le fichier
     * Supprime l'occurence InterventionFile associée à ce fichier
     * Crée une nouvelle instance InterventionFile
     */
    onClickDeleteFile(): void {
        this.confirmationService.confirmWarn(this.textConfig.confirmDeleteDialog, () => {
            if (this.autoUpload) {
                this.cnSpinnerService
                    .withSpinner(
                        this.interventionFileService.deleteInterventionFile(this.interventionFile, true).pipe(
                            switchMap(() => {
                                return this.initInterventionFile();
                            })
                        )
                    )
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(() => {
                        this.notificationService.success(this.textConfig.fileDeleted);
                        this.deleted.emit();
                    });
            } else {
                // Préparation objet à renvoyer en output
                this.dataUrl = null;
                this.cnSpinnerService
                    .withSpinner(this.initInterventionFile())
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe();

                this.notificationService.success(this.textConfig.fileDeleted);
                this.deleted.emit();
            }
        });
    }

    /**
     * Action lors du click permettant d'ajouter un fichier
     */
    onClickAddFile(): void {
        this.fileUploader.selectFile();
    }

    /**
     * Action lors du click pour modifier un fichier
     */
    onClickChangeFile(): void {
        this.fileUploader.selectFile();
    }

    /**
     * Action lors du click pour annuler l'edition (la croix)
     */
    onClickCancel(): void {
        this.showEditionOverlay(false);
    }

    /**
     * Action lors du click pour afficher le panneau d'edition
     */
    onClickEdit(): void {
        this.showEditionOverlay(true);
    }

    /**
     * Renvoie un objet contenant l'image à uploader et l'objet InterventionFile
     * @param interventionFile
     * @param dataUrl
     * @private
     */
    private prepareFileUploaderOutput(
        interventionFile: InterventionFile,
        fileName: string,
        dataUrl: string
    ): FileUploaderOutput {
        return new FileUploaderOutput(interventionFile, fileName, dataUrl);
    }

    /**
     * Affiche ou non l'overlay d'édition
     * @param value
     * @private
     */
    private showEditionOverlay(value: boolean): void {
        this.editionOverlay = value;
    }
}

import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { CeeConfig, Chapitre, TypeCee } from '../model/cee.model';
import { CeeApiService } from './cee-api.service';
import { NOM_DE_LA_SOLUTION } from '../shared/cee.constants';
import { NavigationBarsService } from '../../../../services/navigation-bars.service';

@Injectable({
    providedIn: 'root',
})
export class CeeService {
    ceeConfig$ = new BehaviorSubject<CeeConfig>(undefined);
    idsNomSolution: string[] = [];
    typesCee: TypeCee[];
    chapitres: Chapitre[];
    constructor(private ceeApiService: CeeApiService, private navigationBarsService: NavigationBarsService) {}

    initConfig() {
        if (!this.ceeConfig$.getValue()) {
            this.ceeApiService
                .getConfig()
                .pipe(map((configs) => configs[0]))
                .subscribe((config: CeeConfig) => {
                    console.info(config);
                    this.ceeConfig$.next(config);
                    this.typesCee = config.typesCee;
                    this.chapitres = config.chapitres;
                    this.idsNomSolution = [
                        '160',
                        '161',
                        ...config.pointsControles
                            .filter((ptc) => ptc.description == NOM_DE_LA_SOLUTION)
                            .map((ptc) => ptc.idInterne),
                    ];
                    this.initRoutes();
                });
        }
    }

    private initRoutes() {
        this.navigationBarsService.navigationBarConfiguration$.subscribe((conf) => {
            if (conf) {
                if (['BAREN101', 'BAREN102', 'BAREN103', 'BARTH160', 'BARTH161'].includes(conf.typePrestation)) {
                    if (conf.refObject && !conf.refObject['idsNomSolution']) {
                        conf.refObject['idsNomSolution'] = this.idsNomSolution;
                        conf.refObject['typesCee'] = this.typesCee;
                        conf.refObject['chapitres'] = this.chapitres;
                        this.navigationBarsService.configureNavBar(conf);
                    }
                }
            }
        });
    }
}

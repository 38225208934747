"use strict";

export const CODE_BIM_INCONNU = 'inconnu';

export const CODE_BIM_FLAT = 'FLAT';
export const CODE_BIM_SPACE = 'SPACE';

export const CODE_BIM_DALLE = 'dalle';
export const CODE_BIM_GARDE_CORPS = 'garde_corps';
export const CODE_BIM_MUR = 'mur';
export const CODE_BIM_ESCALIER = 'escalier';
export const CODE_BIM_POTEAU = 'poteau';
export const CODE_BIM_POUTRES = 'poutres';
export const CODE_BIM_RESEAUX_INTERIEURS = 'reseaux_interieurs';
export const CODE_BIM_VOIRIE = 'voirie';
export const CODE_BIM_COURS = 'cours';
export const CODE_BIM_CHEMIN_PIETON = 'chemin_pieton';
export const CODE_BIM_PELOUSE = 'pelouse';
export const CODE_BIM_CLOTURES = 'clotures';
export const CODE_BIM_ELEMENT_VEGETAL = 'element_vegetal';
export const CODE_BIM_FENETRE = 'fenetre';
export const CODE_BIM_FENETRE_DE_TOIT = 'fenetre_de_toit';
export const CODE_BIM_PORTE = 'porte';
export const CODE_BIM_LANTERNEAU = 'lanterneau';

export const CODES_BIM = {
    facing_ceiling: 'revetement_de_plafond',
    facing_floor: 'revetement_de_sol',
    facing_wall: 'revetement_de_mur',
    facing_facade: 'revetement_de_facade',
    facing_roof: 'revetement_de_toiture',

    roof: 'charpente'
};

/**
 * Id BBP du mur sur lequel est posé un revêtement de mur
 */
export const PARAM_CODE_BIM_REVETEMENT_MUR = "mur_revetement_de_mur"

/**
 * Surface d'un revêtement
 */
export const PARAM_CODE_BIM_SURFACE_REVETEMENT = "surface_revetement"

/**
 * Couleur d'un élément du bâti
 */
export const PARAM_CODE_BIM_COULEUR_BATI = "couleur_elements_du_bati"

import { Pipe, PipeTransform } from '@angular/core';
import { ReferencePrestation } from '../model/reference-prestation.model';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ReferenceApiService } from '../services/reference-api.service';

@Pipe({
    name: 'pictoPrestation',
})
export class PictoPrestationPipe implements PipeTransform {
    constructor(private readonly referenceApiService: ReferenceApiService) {}

    transform(referencePrestation: ReferencePrestation, ...args: unknown[]): Observable<string> {
        if (referencePrestation) {
            return this.referenceApiService
                .downloadImage(referencePrestation.id, referencePrestation.idFichierPicto)
                .pipe(
                    map((fileData) => {
                        return fileData && fileData.fileContent ? fileData.fileContent : null;
                    })
                );
        }
        return of(null);
    }
}

import {
    cn_marker,
    cn_marker_handler,
    cn_mouse_event,
    cn_svg_map,
    cn_svg_tool_marker_creation,
} from '@acenv/cnmap-editor';
import { LevelToDisplay } from 'src/app/modules/diagnostics/hap/model/hap.model';
import { CndiagMarker } from '../model/cndiag-marker.model';

export class CndiagMarkerToolCreation extends cn_svg_tool_marker_creation {
    referenceToMarker: any;
    currentLevel: LevelToDisplay;
    _edition_handler: cn_marker_handler;

    /**
     * @param svgMap
     */
    constructor(svgMap: cn_svg_map) {
        super(svgMap);
    }

    /**
     * Builds a new marker
     * @returns {cn_marker}
     */
    _build_marker() {
        const marker = new CndiagMarker(this._map._storey);
        marker.idReference = this.referenceToMarker.id;
        marker.level = this.currentLevel;
        const markerLabel = this.referenceToMarker['general']
            ? this.referenceToMarker['general'].reference
            : this.referenceToMarker['nom'];
        marker.label = markerLabel ?? 'Non défini';
        switch (this.currentLevel) {
            case LevelToDisplay.PERIMETRE:
                marker.shape_opacity = 0;
                marker.stroke_width = this.referenceToMarker.legende.epaisseurTrait;
                marker.set_type('eraser');
                break;
            case LevelToDisplay.ZONE:
                marker.shape_opacity = 0.5;
                marker.set_type('eraser');
                break;
            case LevelToDisplay.PRELEVEMENT:
            case LevelToDisplay.BESOIN:
                marker.shape_opacity = 0.8;
                break;
            default:
                break;
        }
        if (this.referenceToMarker.legende) {
            marker.shape_color = this.referenceToMarker.legende.color;
        }
        return marker;
    }

    /**
     * Overload the called method, which searches for elements under the cursor in creation mode.
     * This deactivates perimeter selection on hover in mode 0 : simple marker
     * @param {cn_marker} marker
     */
    _find_other_element(mouse_event) {
        return null;
    }

    /**
     * Overload the called method, which searches for elements under the cursor in creation mode.
     * This deactivates perimeter selection on hover in mode 1 : area marke
     * @param {cn_marker} marker
     */
    _find_marker(mouse_event: cn_mouse_event) {
        if (this._view_overlay) {
            var elt = this._view_overlay.find_mouse_element(mouse_event);
            if (!elt) return false;
            return elt.element instanceof cn_marker;
        }
        return false;
    }

    /**
     * Overload method called when a new marker is created
     * @param {cn_marker} marker
     */
    _marker_created(marker) {
        var obj = this;
        obj._insert_marker(marker);
        obj._initiate_edition([marker]);
        this._edition_handler.buildMarkerInput(marker, false);
        obj._map.refresh();
        //call l'event creation. Si on call marker_input alors cela fait apparaitre la pop up
        this.call('creation', marker);
    }

    _initialize_handler() {
        super._initialize_shape_handler();

        // Permet de saisir un marker sur un autre marker en mode 1
        this._handler.allow_creation = (mouseWorld, cam) => {
            return true;
        };
    }

    // ***************************************************************
    _initialize_freeform_handler() {
        super._initialize_freeform_handler();

        // Permet de saisir un marker sur un autre marker en mode 0
        this._handler.allow_creation = (mouseWorld, cam) => {
            return true;
        };
    }

    setCurrentLevel(level: LevelToDisplay) {
        this.currentLevel = level;
        if (this.currentLevel === LevelToDisplay.PRELEVEMENT || this.currentLevel === LevelToDisplay.BESOIN) {
            this.set_marker_mode(0);
        } else {
            // area marker
            this.set_marker_mode(1);
        }
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_tool objects : a tool to manipulate objects
//***********************************************************************************
//***********************************************************************************

import {cn_clone} from "../utils/cn_utilities";
import {cn_object_instance} from "../model/cn_object_instance";
import {cn_handler_space_drag2} from "./cn_handler_space_drag2";
import {cn_handler_wall_drag} from "./cn_handler_wall_drag";
import {cn_handler_object} from "./cn_handler_object";
import {cn_svg_tool_creation} from "./cn_svg_tool_creation";
import {cn_space} from "../model/cn_space";
import {cn_handler_space_drag} from "./cn_handler_space_drag";
import {cn_object} from "../model/cn_object";
import { cn_object_instance_handler } from "./cn_object_instance_handler";
import { cn_mouse_event } from "./cn_mouse_event";
import { CN_MATERIAL_TYPE_HEAVY_STRUCTURE } from "../model/cn_material";
import { cn_element } from "../model/cn_element";
import { cn_edition_handler } from "./cn_edition_handler";
import { cn_event_handler } from "./cn_event_handler";


export class cn_svg_tool_object_creation extends cn_svg_tool_creation {
    constructor(map) {
        super(map);

        //*** Scene data
        this._ghost = new cn_object_instance();
        this._ghost["valid"] = -1;

        this._creation_handler = null;
		this.element_filter = function(element) {return element.constructor == cn_object_instance;};
    }

    //***********************************************************************************
    //**** Returns current opening
    //***********************************************************************************
    set_current_object(obj) {
        //if (obj == this._ghost.object) return;

        this._terminate_edition();
        this.remove_handler(this._creation_handler);
        this._creation_handler = null;

        this._ghost.object = obj;
        if (this._ghost.object == null) return;

        //*** Create a handler for that object.
        var anchor_position = null;
        var anchor_normal = null;
        if (this._ghost.object.place_on_wall()) {
            anchor_position = this._ghost.object.get_anchor_position();
            anchor_normal = this._ghost.object.get_anchor_normal();
        }
        var handler_object = new cn_handler_object(this._ghost.object.size[0], this._ghost.object.size[1], this._ghost.position, this._ghost.orientation * Math.PI / 180, anchor_position, anchor_normal);
        if (anchor_position)
            this._creation_handler = new cn_handler_wall_drag(handler_object, this._scene, this._scene.storey,true);
        else
            this._creation_handler = new cn_handler_space_drag2(handler_object, this._scene);

        this._creation_handler["instance"]= this._ghost;

        this._handlers.unshift(this._creation_handler);
    }

    get_current_object() {
        return this._ghost.object;
    }

    /**
     * Select all instacnes of the given object
     * @param {cn_object} object
     */
    select_instances(object) {
        this._initiate_edition(this._scene.object_instances.filter(i => i.object == object));
    }

    /**
     * Force object resize (3 values)
     * @param {cn_object} object 
     * @param {Array<number>} size 
     */
    set_object_size(object, size) {
        if (size.length < 3) return;
        this.push_transaction("Dimensions d'un objet");
        this.push_item_set(object, "size");
        object.size = size;
    }

    /**
     * Restore object size, only for WIKIPIM objects
     * @param {cn_object} object 
     */
    restore_object_size(object) {
        if (object.get_source_type() != "wikipim") return;
        this.push_transaction("Dimensions d'un objet");
        this.push_item_set(object, "size");
        object.size = object.compute_source_bounding_box().size;
    }

    //***********************************************************************************
    //**** Draws  specific svg for the tool. Returns svg string
    //***********************************************************************************
    draw(camera) {
        var html = "";
        if (this._creation_handler) this._creation_handler.visible = (this._creation_handler == this._focus_handler);
        html = super.draw(camera);

        if (this._creation_handler && this._creation_handler.visible && this._ghost["valid"]>=0)
        {
            var opacity = (this._ghost["valid"] > 0) ? 0.7 : 0.3;
            html += "<g opacity='" + opacity + "'>" + this._ghost.draw(camera) + "</g>";
        }

        return html;
    }

    //***********************************************************************************
    //**** clear move effects
    //***********************************************************************************
    clear_move() {
        this._ghost["valid"] = -1;
        super.clear_move();
    }

    //***********************************************************************************
    //**** Mouse callbacks
    //***********************************************************************************
    click(ev) {
        if (this._focus_handler == this._creation_handler)
            this._create_object(ev);
        else 
            super.click(ev);
        return true;
    }

	grab(ev) {
        const res = super.grab(ev);
        if (res && ev.drag_and_drop_element)
            ev.drag_and_drop_owner = this;
		return true;
	}

    drop(ev) {
        if (this._focus_handler == this._creation_handler)
            this._create_object(ev);
        else 
            super.drop(ev);
        return true;
    }

    move(ev) {
        super.move(ev);
        this._update_ghost(ev);
        return true;
    }

    drag(ev) {
        super.drag(ev);
        this._update_ghost(ev);
        return true;
    }

	/**
	 * During a drag and drop, the owner of the drag and drop may change. 
	 * Return true to accept owning the drag and drop events.
	 * @param {cn_mouse_event} mouse_event 
	 * @returns  {boolean}
	 */
    grab_element(mouse_event) {
        const instance = mouse_event.drag_and_drop_element;
        if (instance && instance.constructor == cn_object_instance)
        {
            instance.virtual = false;
            if (this._edition_handler == null || this._edition_handler._elements.length != 1 ||  this._edition_handler._elements[0] != instance)
            {
                this._initiate_edition([instance]);
            }
            this._edition_handler.grab_element(mouse_event);
            this._focus_handler = this._edition_handler;
            return true;
        }
        return false;
    }

	/**
	 * Manage a drag, during a drag and drop. 
	 * This may follow: 
	 * - Either a grab event, where drag and drop has been triggered (by setting drag_and_drop_element on the mouse event)
	 * - Either after an accepted grab_element event.
	 * @param {cn_mouse_event} mouse_event 
	 * @returns  {boolean}
	 */
    drag_element(mouse_event) {
        return (this._edition_handler && this._edition_handler.drag_element(mouse_event));
    }

	/**
	 * If drag and dropped is passed on another event handler, 
	 * this method is called on the previous owner of the drag and drop.
	 * @param {cn_mouse_event} mouse_event 
	 * @returns {boolean}
	 */
     drag_element_stop(mouse_event) {
        this._terminate_edition();
		return true;
	}

	/**
	 * Manage a drop, in a drag and drop event.
	 * @param {cn_mouse_event} mouse_event 
	 * @returns {boolean}
	 */
	drop_element(mouse_event) {
		return true;
	}
	
    /**
     * Creates a new instance
     * @param {cn_mouse_event} ev
     */
    _create_object(ev)
    {
        this._update_ghost(ev);
        if (this._ghost.object == null || this._ghost["valid"] <= 0) 
        {
            return false;
        }

        this.push_transaction("Nouvel objet");
        this.push_item_set(this._scene, "object_instances");

        var new_object_instance = new cn_object_instance(this._scene);
        new_object_instance.object = this._ghost.object;
        new_object_instance.position = cn_clone(this._ghost.position);
        new_object_instance.orientation = this._ghost.orientation;
        new_object_instance.update_deep();
        this._scene.object_instances.push(new_object_instance);

        this.call("creation", [new_object_instance]);

        this._initiate_edition([new_object_instance]);
    }

    /**
     * Update the ghost depending on the validity of the creation handler
     * @param {cn_mouse_event} ev
     */
    _update_ghost(ev) {
        this._ghost["valid"] = -1;
        if (this._creation_handler)
        {
            this._ghost["valid"] = 0;

            if (!this._creation_handler.check_place(ev)) {
                this._ghost.position = ev.mouse_world;
                return;
            }

            this._ghost.position = cn_clone(this._creation_handler.object.center);
            this._ghost.orientation = this._creation_handler.object.angle * 180 / Math.PI;
            this._ghost.space = this._creation_handler["space"];
            this._ghost["valid"] = 1;
        }
    }

    //***************************************************************

    /**
     * Add virtual objects to a space
     * @param {cn_space} space
     * @param {{number: number, object: cn_object}[]} objects
     */
    add_virtual_objects(space, objects) {
        this.push_transaction("Ajout suggestion d'objets");
        this.push_item_set(this._scene, "object_instances");
        this.push_item_set(this._map._building, "objects");

        objects.forEach(object => {
            if (object.number > 0) {
                const new_object = object.object;

                for (let i = 0; i < object.number; i++) {
                    const new_object_instance = new cn_object_instance(space.scene);
                    new_object_instance.virtual = true;
                    new_object_instance.space = space;
                    new_object_instance.object = new_object;
                    new_object_instance.update_deep();
                    space.scene.object_instances.push(new_object_instance);
                    if (this._map._building.objects.find(it => it.ID === new_object.ID) == null) {
                        this._map._building.add_object(new_object);
                    }
                }
            }
        });
    }

    /**
     * Returns true if space has virtual objects
     * @param {cn_space} space
     * @return {boolean}
     */
    has_virtual_objects(space) {
        return space.scene.object_instances.some(it => it.space && it.space.ID === space.ID && it.virtual === true);
    }

    /**
     * Remove all virtual objects from a space
     * @param {cn_space} space
     */
    remove_virtual_objects(space) {
        this.push_transaction("Suppression suggestion d'objets");
        this.push_item_set(this._scene, "object_instances");
        this.push_item_set(this._map._building, "objects");

        // remove virtual objects from space
        const removed_instances = space.scene.object_instances.filter(it => it.space.ID === space.ID && it.virtual === true);
        space.scene.object_instances = space.scene.object_instances.filter(it => !(it.space.ID === space.ID && it.virtual === true))

        // delete obsolete objects from building
        new Set(removed_instances.map(it => it.object.ID)).forEach(removed_object_id => {
            const potential_obsolete_object = this._map._building.objects.find(it => it.ID === removed_object_id);
            if (potential_obsolete_object != null && this._map._building.get_object_count(potential_obsolete_object) === 0) {
                this._map._building.remove_object(potential_obsolete_object);
            }
        })
    }
	/**
	 * TODO : derivate in order to allow edition of other element in the process of creation
	 * @param {cn_mouse_event} mouse_event 
	 * @returns {cn_element}
	 */
    _find_other_element(mouse_event) {
        if (this._scene)
		    return this._scene.find_object_instance(mouse_event.mouse_world,mouse_event.camera.snap_world_distance);
	}

	/**
	 * TODO : derivate in order to provide an edition handler
	 * @param {Array<cn_object_instance>} elements 
	 * @returns {cn_edition_handler}
	 */
	_build_edition_handler(elements) {
		return new cn_object_instance_handler(elements,this._map,true);
	}
	
	/**
	 * TODO : derivate in order to find siblings of an element
	 * @param {cn_object_instance} element 
	 * @returns {Array<cn_object_instance>}
	 */
	_get_siblings(element) {
        const ot = element.object;
        return this._scene.object_instances.filter(op => op.object == ot);
	}
}


"use strict";
//***********************************************************************************
//***********************************************************************************
//**** A handler to create / modify a 3D object
//***********************************************************************************
//***********************************************************************************

import {cn_event_handler} from "./cn_event_handler";
import {cn_add, cn_cart, cn_clone, cn_mul, cn_polar, cn_sub} from "../utils/cn_utilities";
import {cn_snap} from "./cn_snap";
import {cn_image_dir} from "../utils/image_dir";
import {cn_scene} from "../model/cn_scene";
import {cn_scene_controller} from "./cn_scene_controller";
import {cn_camera} from "./cn_camera";
import {cn_mouse_event} from "./cn_mouse_event";

/**
 * @class cn_3d_object_handler
 * A handler to manipulate a simple object with cener, orientation and height. Used for columns.
 *
 * Available events :
 * - "create" : called when shape is created
 * - "change" : called when shape changes
 * - "height_click" : called when height is clicked
 */
export class cn_3d_object_handler extends cn_event_handler {
	//*****************************************************************
	/**
	 * Constructor
	 * @param {number[] | null} position : start position or null if creation
	 * @param {number} orientation : orientation in degrees
	 * @param {number | false} height : height in meters. If false, auto.
	 * @param {cn_scene} scene : If specified, object will be accepted only in inside spaces.
	 * @param {cn_scene_controller} controller
	 */
	constructor(position, orientation, height, scene, controller = null) {
		super();
		this.position = position;
		this.orientation = orientation;
		this.height = height;

		//*** Settings */
		this.force_inside_space = false;

		this._scene = scene;
		this._controller = controller;

		this._creation = (this.position == null);

		this._mouseover_box = false;
		this._mouseover_vertex = false;
		this._mouseover_angle = false;

		this._snap_svg = "";

		this.on_create = null;
		this.on_change = null;
		this.on_height_click = null;
		this.on_start_creation = null;

		this._box_size = [40,20];
		this._box_position = [0,0];
		this._angle_position = [0,0];
		this._radius = 80;

		this.ghost = null;

		this.snap_elements = [];
	}

	//*****************************************************************
	/**
	 * is shape currently creating ?
	 * @returns {boolean}
	 */
	is_creating() {
		return false;
	}

	//*****************************************************************
	/**
	 * Draw the handler
	 * @param {cn_camera} camera
	 * @returns {string} svg string
	 */
	draw(camera) {
		var html = "";

		//*** end if we area in creation process, draw the ghost */
		if (this._creation && this.ghost)
		{
			var p = camera.world_to_screen(this.ghost);
			html += "<circle class='handle_vertex selected' cx='" + p[0] + "' cy='" + p[1] + "' r='5'/>"
		}

		//** end if no element to select */
		if (this.position == null) return html;

		//*** Draw center point */
		var p = camera.world_to_screen(this.position);
		html += "<circle class='handle_vertex selected' cx='" + p[0] + "' cy='" + p[1] + "' r='5'/>"

        if (this._scene.draw_height_box) {
            //*** Compute box position */
            var box_class = "roof_height_box";
            if (this._mouseover_box) box_class += " mouseover";
            this._box_position = cn_add(this.position, cn_mul([40, 0], camera.screen_to_world_scale));
            var tp = camera.world_to_screen(this._box_position);

            //*** Draw line between height and box */
            html += "<line class='roof_height_line' x1='" + p[0] + "' y1='" + p[1] + "' x2='" + tp[0] + "' y2='" + tp[1] + "' />";

            //*** draw box */
            var txt = (!this.height) ? "auto" :this.height.toFixed(2);
            html += camera.draw_text_box(this._box_position, this._box_size[0], this._box_size[1], txt, box_class, "roof_height_text");
        }

		//*** draw height icon */
		var r = 10;
		var classes = "roof_height_disk custom";
		if (this._mouseover_vertex) classes += " mouseover";
		else classes += " selected";
		html += "<circle class='" + classes + "' cx='" + p[0] + "' cy='" + p[1] + "' r='" + r + "'/>";
		html += "<image xlink:href='" + cn_image_dir()  + "roof_height.png' x='" + (p[0] - r) + "' y='" + (p[1] - r) + "' width='" + (r*2) + "' height='" + (r*2) + "' />";

		//*** Draw rotation circle */
		html += "<circle class='disk_handle_circle' cx='" + p[0] + "' cy='" + p[1] + "' r='" + this._radius + "'/>";

		//*** Draw rotation handle */
		var add_classes = (this._mouseover_angle)?" mouseover":" selected";
		var pa = cn_mul(cn_cart([1,this.orientation*Math.PI/180]),this._radius);
		pa[0] += p[0];
		pa[1] = p[1] - pa[1];
		html += "<line class='disk_handle_line" + add_classes + "' x1='" + p[0] + "' y1='" + p[1] + "' x2='" + pa[0] + "' y2='" + pa[1] + "' />";
		html += "<circle class='handle_vertex" + add_classes + "' cx='" + pa[0] + "' cy='" + pa[1] + "' r='5'/>";
		this._angle_position = camera.screen_to_world(pa);
		html += this._snap_svg;
		return html;
	}

	//*****************************************************************
	/**
	 * Clear move data
	 */
	clear_move() {
		this._mouseover_vertex = false;
		this._mouseover_box = false;
		this._mouseover_angle = false;
		this.ghost = null;
	}

	/**
	 * Manage a passive move. To return 'true' if something of interest under the mouse.
	 * @param {cn_mouse_event} ev 
	 * @returns  {boolean}
	 */
	move(ev) {
		this.clear_move();

		if (this.position)
		{
			//*** Mouse over the vertex ? */
			if (ev.camera.on_vertex(ev.mouse_world,this.position))
			{
				this._mouseover_vertex = true;
				return true;
			}

			//*** Mouse over the box ? */
			var xx = cn_sub(ev.camera.world_to_screen(ev.mouse_world),ev.camera.world_to_screen(this._box_position));
			if (Math.abs(xx[0]) < this._box_size[0]/2 && Math.abs(xx[1]) < this._box_size[1]/2)
			{
				this._mouseover_box = true;
				return true;
			}

			//*** Mouse over the angle ?  */
			if (ev.camera.on_vertex(ev.mouse_world,this._angle_position))
			{
				this._mouseover_angle = true;
				return true;
			}
		}

		//*** creation mode : update ghost */
		if (this._creation)
		{
			this.ghost = this.find_valid_position(ev.mouse_world, ev.camera);
			return true;
		}

		return false;
	}

	/**
	 * Manage a grab. To return 'true' if grab is to be managed.
	 * @param {cn_mouse_event} ev 
	 * @returns  {boolean}
	 */
	 grab(ev) {
		return this.move(ev);
	}

	/**
	 * Manage a drop. Only after a grab that returned true, and at least one drag. To return 'true' if drop had an effect.
	 * @param {cn_mouse_event} ev 
	 * @returns {boolean}
	 */
	 drop(ev) {

		if (this._mouseover_box)
		{
			//*** Check that mouse is still over the box */
			var mouse_position = ev.camera.world_to_screen(ev.mouse_world);
			var xx = cn_sub(mouse_position,ev.camera.world_to_screen(this._box_position));
			if (Math.abs(xx[0]) < this._box_size[0]/2 && Math.abs(xx[1]) < this._box_size[1]/2)
			{
				this.call("height_click");
				return true;
			}
			return false;
		}

		//**** Create a new element*/
		if (this._creation && this.ghost)
		{
			this.position = cn_clone(this.ghost);
			this.ghost = null;
			this.call("create");
			return true;
		}

		this.clear_move();
		return true;
	}

	click(ev) {
		return this.drop(ev);
	}
	/**
	 * Manage a drag. Only after a grab that returned true. To return 'true' if drag had an effect.
	 * @param {cn_mouse_event} ev 
	 * @returns  {boolean}
	 */
	 drag(ev) {

		//**** Move one vertex */
		if (this._mouseover_vertex)
		{
			var p = this.find_valid_position(ev.mouse_world, ev.camera);
			if (p == null) return true;
			this.position = p;
			this.call("change");
			return true;
		}

		//*** Move from box */
		if (this._mouseover_box)
		{
			return true;
		}

		//*** Move angle */
		if (this._mouseover_angle)
		{
			var delta = cn_polar(cn_sub(ev.mouse_world,this.position));
			this.orientation = delta[1]*180/Math.PI;
			this.orientation = 5*Math.round(this.orientation/5);
			this.call("change");
			return true;
		}

		if (this._creation)
			return this.move(ev);

		return true;
	}

	//*****************************************************************
	/**
	 * Find valid position
	 * @param {number[]} mouse_world_position
	 * @param {cn_camera} camera
	 * @returns {number[]} Snapped position, or null
	 */
	find_valid_position(mouse_world_position, camera) {
		if (this._scene && this.force_inside_space)
		{
			var space = this._scene.find_space(mouse_world_position);
			if (space == null || space.outside) return null;
		}

		this._snap_svg = "";
		var snap = new cn_snap(mouse_world_position,camera.snap_world_distance, null, camera);
		
		snap.snap_elements = this.snap_elements;
		snap.snap_point();
		snap.check_grid();
		this._snap_svg = snap.svg;
		return cn_clone(snap.position);
	}

}


<div class="w-100 d-flex align-items-center flex-column">
    <ng-container *ngIf="!listePerimetreArrayForm || listePerimetreArrayForm.controls.length === 0">
        <span>Aucune délimitation défini dans ce plan</span>
    </ng-container>
    <ng-container
        *ngFor="
            let delimitationFormGroup of listePerimetreArrayForm?.controls;
            let ip = index;
            last as isLastDelimitation
        "
        class="line-border-bottom pt-3"
    >
        <div
            class="d-flex flex-row w-100 align-items-center justify-content-between line-height-36"
            [ngClass]="[
                delimitationFormGroupSelected === delimitationFormGroup ? 'line-selected' : '',
                delimitationFormGroup?.invalid ? 'line-invalid' : '',
                maxLevelToDisplay === enumLevelToDisplay.PERIMETRE ? 'item-clickable' : ''
            ]"
        >
            <div
                class="d-flex align-items-center pl-3 w-100"
                (click)="
                    maxLevelToDisplay === enumLevelToDisplay.PERIMETRE
                        ? onClickEditDelimitation(delimitationFormGroup)
                        : ''
                "
            >
                <div
                    *ngIf="delimitationFormGroup.get('color')?.value"
                    class="affichage-legende"
                    [ngStyle]="{ 'background-color': delimitationFormGroup.get('color')?.value }"
                ></div>
                <span class="ml-3">{{
                    delimitationFormGroup?.get('nomPerimetre')?.value
                        ? delimitationFormGroup.get('nomPerimetre').value
                        : '*Nom de la délimitation*'
                }}</span>
            </div>
            <div *ngIf="maxLevelToDisplay === enumLevelToDisplay.PERIMETRE" class="container-btn-actions">
                <!-- Si le périmètre est en cours de localisation, on lui assigne la class editLocationActif qui passe l'icone en orange 
                Si le périmètre est sélectionné, on passe l'icone en blanc
                Sinon l'icone est en grey-5 -->
                <button
                    mat-icon-button
                    [ngClass]="
                        editLocation && editLocation === delimitationFormGroup
                            ? 'editLocationActif'
                            : delimitationFormGroupSelected === delimitationFormGroup
                            ? 'white'
                            : ''
                    "
                    [disabled]="readonlyMode"
                    (click)="onClickEditLocation($event, delimitationFormGroup, enumLevelToDisplay.PERIMETRE)"
                >
                    <mat-icon class="icon-small">location_on</mat-icon>
                </button>
                <button
                    mat-icon-button
                    [disabled]="readonlyMode"
                    (click)="onClickDeleteDelimitation(ip)"
                    style="color: red"
                >
                    <lib-icon class="icon-small">delete</lib-icon>
                </button>
            </div>
        </div>
        <ng-container
            *ngFor="
                let zoneFormGroup of delimitationFormGroup.get('formArrayZones').controls;
                let iz = index;
                last as isLastZone
            "
        >
            <div
                class="d-flex flex-row w-100 pl-5 align-items-center justify-content-between line-height-36"
                [ngClass]="[
                    zoneFormGroupSelected === zoneFormGroup ? 'line-selected' : '',
                    zoneFormGroup?.invalid ? 'line-invalid' : '',
                    maxLevelToDisplay === enumLevelToDisplay.ZONE ? 'item-clickable' : ''
                ]"
                (click)="
                    maxLevelToDisplay === enumLevelToDisplay.ZONE
                        ? onClickEditZone(delimitationFormGroup, zoneFormGroup)
                        : ''
                "
            >
                <div class="d-flex align-items-center">
                    <div
                        *ngIf="zoneFormGroup.get('color')?.value"
                        class="affichage-legende"
                        [ngStyle]="{ 'background-color': zoneFormGroup.get('color')?.value }"
                    ></div>
                    <span class="ml-3 line-font">{{
                        zoneFormGroup.get('nom') && zoneFormGroup.get('nom').value
                            ? zoneFormGroup.get('nom').value
                            : '*Nom de la zone*'
                    }}</span>
                </div>

                <div *ngIf="maxLevelToDisplay === enumLevelToDisplay.ZONE">
                    <!-- Si la zone est en cours de localisation, on lui assigne la class editLocationActif qui passe l'icone en orange 
                    Si la zone est sélectionnée, on passe l'icone en blanc
                    Sinon l'icone est en grey-5 -->
                    <button
                        mat-icon-button
                        [ngClass]="
                            editLocation && editLocation === zoneFormGroup
                                ? 'editLocationActif'
                                : zoneFormGroupSelected === zoneFormGroup
                                ? 'white'
                                : 'grey-5'
                        "
                        [disabled]="readonlyMode"
                        (click)="onClickEditLocation($event, zoneFormGroup, enumLevelToDisplay.ZONE)"
                    >
                        <mat-icon class="icon-small">location_on</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        [ngClass]="zoneFormGroupSelected === zoneFormGroup ? 'white' : 'grey-5'"
                        [disabled]="readonlyMode"
                        (click)="onClickDeleteZone(delimitationFormGroup, iz)"
                    >
                        <lib-icon class="icon-small">delete</lib-icon>
                    </button>
                </div>
            </div>
            <div class="w-100 d-flex align-items-center flex-column">
                <ng-container
                    *ngFor="
                        let prelevementFormGroup of zoneFormGroup.get('formArrayPrelevement').controls;
                        let ipp = index;
                        last as isLastPrelevement
                    "
                >
                    <div
                        class="
                            d-flex
                            flex-row
                            justify-content-between
                            w-100
                            align-items-center
                            line-height-36
                            padding-left-80
                        "
                        [ngClass]="[
                            besoinFormGroupSelected === prelevementFormGroup ||
                            prelevementFormGroupSelected === prelevementFormGroup
                                ? 'line-selected'
                                : '',
                            (maxLevelToDisplay == enumLevelToDisplay.BESOIN && prelevementFormGroup?.invalid) ||
                            (maxLevelToDisplay == enumLevelToDisplay.PRELEVEMENT && prelevementFormGroup?.invalid)
                                ? 'line-invalid'
                                : '',
                            maxLevelToDisplay === enumLevelToDisplay.PRELEVEMENT ||
                            maxLevelToDisplay === enumLevelToDisplay.BESOIN
                                ? 'item-clickable'
                                : ''
                        ]"
                        (click)="
                            maxLevelToDisplay === enumLevelToDisplay.PRELEVEMENT
                                ? onClickEditPrelevement(delimitationFormGroup, prelevementFormGroup, iz)
                                : maxLevelToDisplay === enumLevelToDisplay.BESOIN
                                ? onClickEditBesoin(delimitationFormGroup, prelevementFormGroup)
                                : ''
                        "
                    >
                        <div class="d-flex align-items-center">
                            <lib-icon
                                *ngIf="!prelevementFormGroup.get('isRealize').value"
                                class="icon-small"
                                theme="mdi"
                                iconName="eyedropper-off"
                            ></lib-icon>
                            <lib-icon
                                *ngIf="prelevementFormGroup.get('isRealize').value"
                                class="icon-small"
                                theme="mdi"
                                iconName="eyedropper"
                            ></lib-icon>
                            <div
                                *ngIf="prelevementFormGroup.get('formGeneral')?.get('color')?.value"
                                class="affichage-legende ml-3"
                                [ngStyle]="{
                                    'background-color': prelevementFormGroup.get('formGeneral')?.get('color')?.value
                                }"
                            ></div>
                            <span class="ml-3 line-font">{{
                                prelevementFormGroup.get('formGeneral').get('reference') &&
                                prelevementFormGroup.get('formGeneral').get('reference').value
                                    ? prelevementFormGroup.get('formGeneral').get('reference').value
                                    : '*Nom du prélèvement*'
                            }}</span>
                        </div>
                        <div
                            class="d-flex"
                            *ngIf="
                                maxLevelToDisplay === enumLevelToDisplay.PRELEVEMENT ||
                                maxLevelToDisplay === enumLevelToDisplay.BESOIN
                            "
                        >
                            <!-- Si le prélèvement est en cours de localisation, on lui assigne la class editLocationActif qui passe l'icone en orange 
                            Si le prélèvement est sélectionné, on passe l'icone en blanc
                            Sinon l'icone est en grey-5 -->
                            <button
                                mat-icon-button
                                [ngClass]="
                                    editLocation && editLocation === prelevementFormGroup
                                        ? 'editLocationActif'
                                        : prelevementFormGroupSelected === prelevementFormGroup
                                        ? 'white'
                                        : 'grey-5'
                                "
                                [disabled]="readonlyMode"
                                (click)="
                                    onClickEditLocation($event, prelevementFormGroup, enumLevelToDisplay.PRELEVEMENT)
                                "
                            >
                                <mat-icon class="icon-small">location_on</mat-icon>
                            </button>
                            <button
                                mat-icon-button
                                [ngClass]="prelevementFormGroupSelected === prelevementFormGroup ? 'white' : 'grey-5'"
                                [disabled]="readonlyMode"
                                (click)="
                                    $event.stopPropagation();
                                    onClickDuplicatePrelevement(delimitationFormGroup, prelevementFormGroup, iz)
                                "
                            >
                                <lib-icon class="icon-small">content_copy</lib-icon>
                            </button>
                            <button
                                mat-icon-button
                                [ngClass]="prelevementFormGroupSelected === prelevementFormGroup ? 'white' : 'grey-5'"
                                [disabled]="readonlyMode"
                                (click)="$event.stopPropagation(); onClickDeletePrelevement(zoneFormGroup, ipp)"
                            >
                                <lib-icon class="icon-small">delete</lib-icon>
                            </button>
                        </div>
                    </div>
                    <mat-divider
                        *ngIf="!isLastPrelevement || maxLevelToDisplay === enumLevelToDisplay.PRELEVEMENT"
                        class="w-100"
                    ></mat-divider>
                </ng-container>
                <button
                    *ngIf="maxLevelToDisplay === enumLevelToDisplay.PRELEVEMENT"
                    mat-raised-button
                    class="bg-btn-grey-1 mt-3 margin-bottom-32"
                    [disabled]="readonlyMode"
                    (click)="onClickCreatePrelevement(delimitationFormGroup, iz)"
                >
                    <span>{{ 'Ajouter un prélèvement' | uppercase }}</span>
                </button>
            </div>
            <mat-divider
                *ngIf="!isLastZone || maxLevelToDisplay === enumLevelToDisplay.ZONE"
                class="w-100"
            ></mat-divider>
        </ng-container>
        <button
            *ngIf="maxLevelToDisplay === enumLevelToDisplay.ZONE"
            mat-raised-button
            class="bg-btn-grey-1 mt-3 margin-bottom-32"
            [disabled]="readonlyMode"
            (click)="onClickCreateZone(delimitationFormGroup)"
        >
            <span>{{ 'Ajouter une zone' | uppercase }}</span>
        </button>
        <div
            *ngIf="
                (maxLevelToDisplay === enumLevelToDisplay.PRELEVEMENT ||
                    maxLevelToDisplay === enumLevelToDisplay.BESOIN) &&
                delimitationFormGroup.value.formArrayBesoins.length > 0
            "
            class="w-100 pl-3 pt-4"
        >
            <span *ngIf="delimitationFormGroup.value.formArrayZones.length > 0" class="besoin-section-title"
                >Hors zone</span
            >
            <ng-container
                *ngFor="
                    let besoinFormGroup of delimitationFormGroup.get('formArrayBesoins').controls;
                    let ib = index;
                    last as isLastBesoin
                "
            >
                <div
                    class="d-flex flex-row w-100 align-items-center justify-content-between line-height-36"
                    [ngClass]="[
                        besoinFormGroupSelected === besoinFormGroup || prelevementFormGroupSelected === besoinFormGroup
                            ? 'line-selected'
                            : '',
                        besoinFormGroup?.invalid ? 'line-invalid' : '',
                        maxLevelToDisplay === enumLevelToDisplay.PRELEVEMENT ||
                        maxLevelToDisplay === enumLevelToDisplay.BESOIN
                            ? 'item-clickable'
                            : '',
                        isLastBesoin && maxLevelToDisplay !== enumLevelToDisplay.BESOIN ? 'margin-bottom-32' : ''
                    ]"
                >
                    <div
                        *ngIf="besoinFormGroup.get('formGeneral')?.get('color')?.value"
                        class="d-flex align-items-center pl-3 w-100"
                        (click)="onClickBesoinLine(delimitationFormGroup, besoinFormGroup, maxLevelToDisplay)"
                    >
                        <lib-icon
                            *ngIf="!besoinFormGroup.get('isRealize').value"
                            class="icon-small"
                            theme="mdi"
                            iconName="eyedropper-off"
                        ></lib-icon>
                        <lib-icon
                            *ngIf="besoinFormGroup.get('isRealize').value"
                            class="icon-small"
                            theme="mdi"
                            iconName="eyedropper"
                        ></lib-icon>
                        <div
                            class="affichage-legende ml-3"
                            [ngStyle]="{ 'background-color': besoinFormGroup.get('formGeneral')?.get('color')?.value }"
                        ></div>
                        <span class="ml-3 line-font">{{
                            besoinFormGroup.get('formGeneral').get('reference') &&
                            besoinFormGroup.get('formGeneral').get('reference').value
                                ? besoinFormGroup.get('formGeneral').get('reference').value
                                : '*Nom du prélèvement*'
                        }}</span>
                    </div>

                    <div class="container-btn-actions">
                        <!-- Si le besoin est en cours de localisation, on lui assigne la class editLocationActif qui passe l'icone en orange 
                        Si le besoin est sélectionné, on passe l'icone en blanc
                        Sinon l'icone est en grey-5 -->
                        <button
                            *ngIf="
                                maxLevelToDisplay === enumLevelToDisplay.PRELEVEMENT ||
                                maxLevelToDisplay === enumLevelToDisplay.BESOIN
                            "
                            mat-icon-button
                            [ngClass]="
                                editLocation && editLocation === besoinFormGroup
                                    ? 'editLocationActif'
                                    : besoinFormGroupSelected === besoinFormGroup ||
                                      prelevementFormGroupSelected === besoinFormGroup
                                    ? 'white'
                                    : 'grey-5'
                            "
                            [disabled]="readonlyMode"
                            (click)="onClickEditLocation($event, besoinFormGroup, maxLevelToDisplay)"
                        >
                            <mat-icon class="icon-small">location_on</mat-icon>
                        </button>
                        <button
                            *ngIf="maxLevelToDisplay === enumLevelToDisplay.BESOIN"
                            mat-icon-button
                            [ngClass]="besoinFormGroupSelected === besoinFormGroup ? 'white' : 'grey-5'"
                            [disabled]="readonlyMode"
                            (click)="onClickDeleteBesoin(delimitationFormGroup, ib)"
                        >
                            <lib-icon class="icon-small">delete</lib-icon>
                        </button>
                    </div>
                </div>

                <mat-divider
                    *ngIf="!isLastBesoin || maxLevelToDisplay === enumLevelToDisplay.BESOIN"
                    class="w-100"
                ></mat-divider>
            </ng-container>
        </div>
        <button
            *ngIf="maxLevelToDisplay === enumLevelToDisplay.BESOIN"
            mat-raised-button
            class="bg-btn-grey-1 mt-3 margin-bottom-32"
            [disabled]="readonlyMode"
            (click)="onClickCreateBesoin(delimitationFormGroup)"
        >
            <span>{{ 'Ajouter un besoin' | uppercase }}</span>
        </button>

        <mat-divider
            *ngIf="!isLastDelimitation || maxLevelToDisplay === enumLevelToDisplay.PERIMETRE"
            class="w-100"
        ></mat-divider>
    </ng-container>
    <button
        *ngIf="maxLevelToDisplay === enumLevelToDisplay.PERIMETRE"
        mat-stroked-button
        color="accent"
        class="my-3 add-button"
        [disabled]="readonlyMode"
        (click)="onClickCreateDelimitation()"
    >
        <lib-icon>add</lib-icon>
        <span>{{ 'Ajouter une délimitation' | uppercase }}</span>
    </button>
</div>

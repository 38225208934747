// @ts-check
"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Fence type
//***********************************************************************************
//***********************************************************************************

import {cn_element_type} from "./cn_element_type";

import {cn_add, cn_clone, cn_dot, cn_mul, cn_sub, cnx_clone} from "../utils/cn_utilities";
import {cn_configuration, cn_configuration_choice, cn_configuration_line, cn_configuration_param, cn_configuration_tab} from "./cn_configuration";
import {fh_add, fh_clone, fh_cross, fh_extruded_polygon, fh_mul, fh_normalize, fh_polygon, fh_sub} from "@acenv/fh-3d-viewer";
import {cn_element_type_visitor} from '..';
import {cn_image_dir} from '../utils/image_dir.js';

var CN_BT_MIDDLE = 0;
var CN_BT_OUTER = 1;
var CN_BT_INNER = 2;

var CN_FT_CATEGORY_LIST = ["limit", "fence", "hedge"];
var CN_FT_LABEL_CATEGORY_LIST = ["Limite", "Clôture", "Haie"];

export class cn_fence_type extends cn_element_type {
    constructor() {
        super();
        this.name = "";
        this.wall_height = 1;
        this.fence_height = 1;
        this.thickness = 0.1;
        this.category = "fence";
        this.free = false;
        this.step_length = 2;
    }

	//***********************************************************************************
    /**
     * Clone
     * @returns {cn_element_type}
     */
    clone() {
        var c = new cn_fence_type();
        c.name = this.name;
        c.wall_height = this.wall_height;
        c.fence_height = this.fence_height;
        c.thickness = this.thickness;
        c.category = this.category;
        c.step_length = this.step_length;
        return c;
    }

    //***********************************************************************************
    get_generic_label() {
        return "Type de clôture";
    }

    //***********************************************************************************
    //**** keys
    //***********************************************************************************
    model_keys() {
        return ["name", "wall_height", "fence_height", "step_length", "thickness", "category"];
    }

    //***********************************************************************************
    //**** set category
    //***********************************************************************************
    set_category(category) {
        if (this.category == category) return;
        this.category = category;

        if (category == "fence") {
            this.wall_height = 1;
            this.fence_height = 1;
			this.thickness = 0.2;
			this.free = false;
		}
		else if (category == "hedge")
		{
			this.wall_height = 2;
			this.fence_height = 0;
			this.thickness = 0.4;
			this.free = false;
		}
		else if (category == "limit")
		{
			this.wall_height = 0;
			this.fence_height = 0;
			this.thickness = 0;
			this.free = true;
		}
		else this.set_category("fence");
	}

	//***********************************************************************************
	//**** default
	//***********************************************************************************
	/**
	 * Returns a default list of fence types
	 * @returns {Array<cn_fence_type>} cn_element_type
	 */
	static default_types() {
        var ft_list = [];
        var ft = null;

        ft = new cn_fence_type();
        ft.set_category("limit");
        ft_list.push(ft);

        ft = new cn_fence_type();
        ft.set_category("fence");
        ft_list.push(ft);

        ft = new cn_fence_type();
        ft.set_category("fence");
        ft.fence_height = 0;
        ft_list.push(ft);

        ft = new cn_fence_type();
        ft.set_category("hedge");
        ft_list.push(ft);

        return ft_list;
    }

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize() {
		var json = {};
		json.class_name = 'cn_fence_type';
		json.ID = this.ID;
		json.name = this.name;
		json.wall_height = this.wall_height;
		json.fence_height = this.fence_height;
		json.thickness = this.thickness;
		json.category = this.category;
		json.step_length = this.step_length;
		return json;
	}

	static unserialize(json) {
		if (typeof(json.ID) != 'string') return false;
		if (json.class_name != 'cn_fence_type') return false;
		if (typeof(json.category) != 'string') return false;

		var wt = new cn_fence_type();
		wt.ID = json.ID;
		wt.set_category(json.category);
		if (typeof(json.name) == 'string')
			wt.name = json.name;
		if (typeof(json.wall_height) == 'number')
			wt.wall_height = json.wall_height;
		if (typeof(json.fence_height) == 'number')
			wt.fence_height = json.fence_height;
		if (typeof(json.thickness) == 'number')
			wt.thickness = json.thickness;
		if (typeof(json.step_length) == 'number')
			wt.step_length = json.step_length;
		return wt;
	}

	//***********************************************************************************
	//**** Build label
	//***********************************************************************************
	get_label() {
		if (this.name != "") return this.name;
		var element_type_name = "";

		if (this.category == "limit")
			return "Limite";
		if (this.category == "fence")
		{
			if (this.wall_height > 0 && this.fence_height > 0)
			{
				return "Clôture maçonnée " + (this.wall_height*100).toFixed(0) + "cm + " + (this.fence_height*100).toFixed(0) + "cm";
			}
			if (this.wall_height > 0)
				return "Mur " + (this.wall_height*100).toFixed(0) + "cm";
			return "Clôture " + (this.fence_height*100).toFixed(0) + "cm";
		}

		return "Haie "+ (this.wall_height*100).toFixed(0) + "cm";
	}

    //***********************************************************************************
    /**
     * category label
     * @returns {string} displayable category
     */
    get_category_label() {
        if (this.category == "limit")
            return "Limite";
        if (this.category == "fence")
        {
            if (this.wall_height > 0 && this.fence_height > 0)
            {
                return "Clôture maçonnée";
            }
            if (this.wall_height > 0)
                return "Mur";
            return "Clôture";
        }

        return "Haie";
    }

	//***********************************************************************************
	//**** Returns description
	//***********************************************************************************

    /**
     * Returns an array of objects describing the type.
     * @returns {{label: string, value?: any, decimals?: number, unit?: string}[]}
     */
	get_description() {
		var description = [];

		var cat = {label:"Catégorie"};
		var index = CN_FT_CATEGORY_LIST.indexOf(this.category);
		cat.value = CN_FT_LABEL_CATEGORY_LIST[index];
		description.push(cat);

		var height = {label:"Hauteur"};
		description.push(height);
		height.value= (this.wall_height + this.fence_height)*100;
		height.decimals = 0;
		height.unit = "cm";

		if (this.wall_height > 0)
		{
			var th = {label:"Epaisseur"};
			description.push(th);
			th.value= this.thickness*100;
			th.decimals = 0;
			th.unit = "cm";
		}

		return description;
	}

	//***********************************************************************************
	/**
	 * draw svg icon
	 * @param {number} width
	 * @param {number} height
	 * @returns {string}
	 */
	draw_svg_icon(width, height)
	{
		var html = "";
		if (this.category == "limit")
		{
			html += "<line x1='" + (width/2) +"' y1='0' x2='" + (width/2) +"' y2='" + height +"' style='stroke:black; stroke-width: 2px; stroke-dasharray: 5,5;' />";
			return html;
		}

		var zscale = (this.wall_height + this.fence_height > 2.5)?1/(this.wall_height + this.fence_height):1/2.5;
		zscale *= height;
		function to_screen(y) { return height - y * zscale};

		if (this.category == "hedge")
		{
			html += `<image xlink:href="${cn_image_dir()}texture_hedge.jpg" x="0" y="${to_screen(this.wall_height)}" width="${width}" height="${zscale * this.wall_height}" preserveAspectRatio="xMidYMid slice" />`;
			return html;
		}

		if (this.category == "fence")
		{
			if(this.wall_height > 0)
			{
				html += `<rect x="0" y="${to_screen(this.wall_height)}" width="${width}" height="${zscale * this.wall_height}" style="fill:rgb(200,200,200);stroke:black;" />`;
			}

			if (this.fence_height > 0)
			{
				var rail_size = height * 0.02;
				var rail_height = rail_size*2;
				var nb_rails = 8;
				var rail_spacing = (width - rail_size) / (nb_rails-1);

				html += `<rect x="0" y="${to_screen(this.wall_height + this.fence_height)}" width="${width}" height="${rail_height}" style="fill:rgb(50,100,50);stroke:black;" />`;
				var x=0;
				for (var n=0;n<nb_rails;n++)
				{
					html += `<rect x="${x}" y="${to_screen(this.wall_height + this.fence_height - rail_height/zscale)}" width="${rail_size}" height="${this.fence_height*zscale - rail_height}" style="fill:rgb(50,100,50);stroke:black;" />`;
					x += rail_spacing;
				}
			}
		}
		return html;
	}

	//***********************************************************************************
	//**** Comparison
	//***********************************************************************************
	is_equal_to(other)
	{
		if (this.name != other.name) return false;
		if (this.wall_height != other.wall_height) return false;
		if (this.fence_height != other.fence_height) return false;
		if (this.thickness != other.thickness) return false;
		if (this.category != other.category) return false;
		if (this.step_length != other.step_length) return false;

		return true;
	}

	
	//***********************************************************************************
	//**** Slicing
	//***********************************************************************************
	compute_wall_slices(wall) {
		if (this.category == "limit") return [];
		
		var valid_openings = wall.openings.filter(op => op.valid);
		if (this.category == "hedge") max_length = 2;

		var wall_parts = [];
		wall_parts.push(0);
		var max_length = (this.step_length<1)?10000:this.step_length;
		for (var nop=0;nop <= valid_openings.length;nop++)
		{
			var x = (nop < valid_openings.length)?valid_openings[nop].position:wall.bounds.length;
			var deltax = x - wall_parts[wall_parts.length-1];
			if (deltax > max_length)
			{
				const nb_parts = Math.ceil(deltax / max_length);
				const part_size = deltax / nb_parts;
				for (var k=0;k<nb_parts-1;k++) {
                    const x_sup = wall_parts[wall_parts.length - 1] + part_size;
                    wall_parts.push(x_sup);
                    wall_parts.push(x_sup);
                }
            }
            wall_parts.push(x);
            if (nop < valid_openings.length)
                wall_parts.push(x + valid_openings[nop].opening_type.width);
        }
		return wall_parts;
	}

	compute_space_line_slices(wall, side, topography = null)
	{
		const original_space_lines = wall.full_space_lines[side];
		var wall_parts = this.compute_wall_slices(wall);
		var wall_part_heights = [];
		if (topography)
		{
			for (var k=0;k<wall_parts.length;k+=2)
			{
				var v = cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,wall_parts[k]));
				var zmin = topography.compute_height(v);
				v = cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,wall_parts[k+1]));
				var z = topography.compute_height(v);
				if (z < zmin) zmin = z;
				v = cn_add(wall.bounds.pmax,cn_mul(wall.bounds.direction,wall_parts[k]));
				z = topography.compute_height(v);
				if (z < zmin) zmin = z;
				v = cn_add(wall.bounds.pmax,cn_mul(wall.bounds.direction,wall_parts[k+1]));
				z = topography.compute_height(v);
				if (z < zmin) zmin = z;
				zmin -= topography.z;
				wall_part_heights.push(zmin);
				wall_part_heights.push(zmin);
			}
		}

		const space_lines = [];
		const or = (side==0)?wall.bounds.pmin:cn_add(wall.bounds.pmax,cn_mul(wall.bounds.direction,wall.bounds.length));
		const dir = (side==0)?wall.bounds.direction:cn_mul(wall.bounds.direction,-1);
		if (side == 1)
		{
			wall_parts.reverse();
			wall_parts = wall_parts.map(x => wall.bounds.length - x);
			wall_part_heights.reverse();
		}
		for (var n=0;n<original_space_lines.length-1;n++)
		{
			var p0 = original_space_lines[n];
			var x0 = cn_dot(cn_sub(p0,or),dir);
			var p1 = original_space_lines[n+1];
			var x1 = cn_dot(cn_sub(p1,or),dir);
			for (var k=0;k<wall_parts.length;k+=2)
			{
				const zz = (topography)?wall_part_heights[k]:0;
				const x = wall_parts[k+1];
				if (k > wall_parts.length-2 || x0 <= x && x1 <= x || x1 <= x0)
				{
					space_lines.push(cnx_clone(p0,zz));
					space_lines.push(cnx_clone(p1,zz));
					break;
				}
				if (x0 > x) continue;
				const pp = cn_add(p0, cn_mul(cn_sub(p1,p0),(x-x0)/(x1-x0)));
				space_lines.push(cnx_clone(p0,zz));
				space_lines.push(cnx_clone(pp,zz));
				p0 = pp;
				x0 = x;
			}
		}
		return space_lines;
	}

	//***********************************************************************************
	//**** 3D geometry
	//***********************************************************************************
	build_extruded_polygons(z, wall) {
		var extruded_polygons = [];
		if (this.category == "limit") return extruded_polygons;

		var wall_parts = this.compute_wall_slices(wall);
	
        for (var nop = 0; nop < wall_parts.length; nop += 2) extruded_polygons.push([]);

        //*** Wall balcony
        if (this.wall_height > 0 && this.thickness > 0) {
            for (var nop = 0; nop < wall_parts.length; nop += 2) {
                var ctr2d = [];
                if (nop == 0) {
                    ctr2d.push(wall.shape[0]);
					ctr2d.push(wall.vertices[0].position);
					ctr2d.push(wall.shape[1]);
				}
				else
				{
					ctr2d.push(cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,wall_parts[nop])));
					ctr2d.push(cn_add(wall.bounds.pmax,cn_mul(wall.bounds.direction,wall_parts[nop])));
				}

				if (nop == wall_parts.length-2)
				{
					ctr2d.push(wall.shape[2]);
					ctr2d.push(wall.vertices[1].position);
					ctr2d.push(wall.shape[3]);
				}
				else
				{
					ctr2d.push(cn_add(wall.bounds.pmax,cn_mul(wall.bounds.direction,wall_parts[nop+1])));
					ctr2d.push(cn_add(wall.bounds.pmin,cn_mul(wall.bounds.direction,wall_parts[nop+1])));
				}
				var ctr3d = [];
				for (var i in ctr2d)
				{
					var v = ctr2d[i];
					ctr3d.push([v[0],v[1],z]);
				}
				var epg = new fh_extruded_polygon();
				extruded_polygons[nop/2].push(epg);
				epg.polygon = new fh_polygon(ctr3d[0],[0,0,1]);
				epg.polygon.add_contour(ctr3d);
				epg.direction = [0,0,this.wall_height];

				if (this.category == "fence")
					epg.color = [0.8,0.8,0.8,1];
				else
				{
					epg.color = [0.2,0.5,0.3,1];
					epg.texture = cn_image_dir() + "texture_hedge.jpg";
				}
			}
		}
		//*** railing balcony
		if (this.fence_height > 0 )
		{
			var color = [0.8,1,0.8,1];
			var ramp_thickness = 0.03;
			var bottom_height = 0.1;
			var rail_thickness = 0.015;
			var start_rail_thickness = 0.03;

			for (var nop=0;nop < wall_parts.length;nop+=2)
			{
				var v0 = cn_clone(wall.vertices[0].position);
				if (nop > 0)
				{
					v0 = cn_add(v0,cn_mul(wall.bounds.direction,wall_parts[nop]));
				}
				v0.push(z+this.wall_height);

				var v1 = cn_clone(wall.vertices[1].position);
				if (nop < wall_parts.length-2)
				{
					v1 = cn_add(wall.vertices[0].position,cn_mul(wall.bounds.direction,wall_parts[nop+1]));
				}
				v1.push(z+this.wall_height);

				var direction = fh_sub(v1,v0);
				var length = fh_normalize(direction);
				var dz = [0,0,1];
				var dx = fh_cross(direction,dz);

				var p0 = fh_clone(v0);

				//*** ramp
				p0[2] = z + this.wall_height + this.fence_height - ramp_thickness*0.5;
				var epg = new fh_extruded_polygon();
				extruded_polygons[nop/2].push(epg);
				epg.tube(p0,fh_mul(direction,length),dx,ramp_thickness*2,ramp_thickness);
				epg.color = color;

				//*** start rails
				epg = new fh_extruded_polygon();
				extruded_polygons[nop/2].push(epg);
				epg.tube(v0,fh_mul(dz,this.fence_height),dx,start_rail_thickness,start_rail_thickness);
				epg.color = color;

				epg = new fh_extruded_polygon();
				extruded_polygons[nop/2].push(epg);
				epg.tube(v1,fh_mul(dz,this.fence_height),dx,start_rail_thickness,start_rail_thickness);
				epg.color = color;

				//*** rails
				var nb_rails = 1+Math.floor(length / (0.11 - rail_thickness));
				var rail_spacing = length / nb_rails;
				var ez = fh_mul(dz,this.fence_height - ramp_thickness);
				for (var n=1;n<nb_rails;n++)
				{
					epg = new fh_extruded_polygon();
					extruded_polygons[nop/2].push(epg);
					epg.tube(fh_add(v0,fh_mul(direction,rail_spacing * n)),ez,dx,rail_thickness,rail_thickness);
					epg.color = color;
				}
			}
		}
		return extruded_polygons;
	}

	//***********************************************************************************
	//**** Get configuration
	//***********************************************************************************
	static configuration() {
		var configuration = new cn_configuration();

		//***********************************************
		//*** Railing configuration
		var railing = new cn_configuration_tab("Limite","limit");
		configuration.add_tab(railing);

		var railing_list = new cn_configuration_line("Limite");
		railing.add_line(railing_list);

		railing_list.add_choice(new cn_configuration_choice());

		//***********************************************
		//*** fence configuration
		var wall = new cn_configuration_tab("Clôture","fence");
		configuration.add_tab(wall);

		var wall_list = new cn_configuration_line("Clôture");
		wall.add_line(wall_list);

		wall_list.add_param(new cn_configuration_param("Epaisseur","thickness",0,100,"cm"));
		wall_list.add_param(new cn_configuration_param("Hauteur maçonnée","wall_height",0,200,"cm"));
		wall_list.add_param(new cn_configuration_param("Hauteur de grille","fence_height",0,200,"cm"));
		wall_list.add_param(new cn_configuration_param("Longueur des tronçons","step_length",0,100,"m"));

		wall_list.add_choice(new cn_configuration_choice());

		//***********************************************
		//*** hedge configuration
		var hedge = new cn_configuration_tab("Haie","hedge");
		configuration.add_tab(hedge);

		var hedge_list = new cn_configuration_line("Haie");
		hedge.add_line(hedge_list);

		hedge_list.add_param(new cn_configuration_param("Epaisseur","thickness",0,100,"cm"));
		hedge_list.add_param(new cn_configuration_param("Hauteur","wall_height",0,200,"cm"));

		hedge_list.add_choice(new cn_configuration_choice());

		return configuration
	}

	//***********************************************************************************
	//**** Fill configuration
	//***********************************************************************************
	fill_configuration(config = null)
	{
		var configuration = (config)?config:cn_fence_type.configuration();

		//*** default to railing
		configuration.selection = 0;

		//*** find proper tab
		var tab_index = configuration.get_tab_index(this.category);
		if (tab_index < 0) return false;
		configuration.selection = tab_index;

		if (this.category == "fence")
		{
			configuration.tabs[1].lines[0].get_param("thickness").value = this.thickness * 100;
			configuration.tabs[1].lines[0].get_param("wall_height").value = this.wall_height * 100;
			configuration.tabs[1].lines[0].get_param("fence_height").value = this.fence_height * 100;
			configuration.tabs[1].lines[0].get_param("step_length").value = this.step_length;
		}

		if (this.category == "hedge")
		{
			configuration.tabs[2].lines[0].get_param("thickness").value = this.thickness * 100;
			configuration.tabs[2].lines[0].get_param("wall_height").value = this.wall_height * 100;
		}

		return configuration;
	}
	//***********************************************************************************
	//**** Load configuration
	//***********************************************************************************
	load_configuration(configuration)
	{
		var configuration_tab = configuration.tabs[configuration.selection];
		if (this.category != configuration_tab.name)
		{
			this.set_category(configuration_tab.name);
			return true;
		}

		var configuration_line = configuration_tab.lines[configuration_tab.selection];
		if (this.category == "fence")
		{
			this.thickness = 0.01 * configuration_line.get_param("thickness").value;
			this.wall_height = 0.01 * configuration_line.get_param("wall_height").value;
			this.fence_height = 0.01 * configuration_line.get_param("fence_height").value;
			this.step_length = configuration_line.get_param("step_length").value;
		}
		if (this.category == "hedge")
		{
			this.thickness = 0.01 * configuration_line.get_param("thickness").value;
			this.wall_height = 0.01 * configuration_line.get_param("wall_height").value;
		}
		return true;
	}

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
     accept_visitor(element_type_visitor) {
        element_type_visitor.visit_fence_type(this);
    }

	/**
	 * returns true if thickness hasn't changed since date
	 * @param {number} date 
	 * @returns {boolean} 
	 */
	up_to_date_thickness(date) {
		return this.get_date("thickness") <= date;
	}
}


import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-controle-administratif',
    templateUrl: './controle-administratif.component.html',
})
export class ControleAdministratifComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}

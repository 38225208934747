import { Component, OnInit, Input } from '@angular/core';
import { InterventionReportData, IReportComponent, ReportBlockData } from '../../../../../../model/rapport.model';
import { Intervention } from 'src/app/model/intervention.model';
import { Polluant, PolluantReportData } from '../../../model/polluant.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { DiagnosticService } from 'src/app/services/diagnostic.service';
import { PolluantService } from '../../../services/polluant.service';
import {
    getRefInfo,
    getRefsInfoById,
} from '../../../modules/etude-situation/initialisation/objectifs/objectifs.component';
import { Zone } from '../../../model/zone.model';
import { ObjectifsMesurage } from '../../../model/polluant-config.model';
import { cn_building } from '@acenv/cnmap-editor';
import * as moment from 'moment';
import { MpcaItemOld } from '../../../model/mpca.model.old';

@Component({
    selector: 'app-poll-zones-block',
    templateUrl: './poll-zones-block.component.html',
    styleUrls: ['./poll-zones-block.component.scss'],
})
export class PollZonesBlockComponent implements OnInit, IReportComponent {
    @Input() data: ReportBlockData;
    @Input() intervention: Intervention;
    @Input() interReportData: InterventionReportData;

    values: any = {};
    currentContenuDiagnostic: Polluant;
    diagnostic: Diagnostic;
    plan: any;
    reportData: PolluantReportData;

    constructor() {}

    ngOnInit(): void {
        this.reportData = this.interReportData.diagnostics[0] as PolluantReportData;
    }
}

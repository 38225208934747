import { RouterModule, Routes } from '@angular/router';

import { QuitDiagnosticGuard } from '../../../../../guards/quit-diagnostic.guard';
import { QuitDescriptionBienGuard } from '../../../../../guards/quit-description-bien.guard';
import { DescriptionBienPageComponent } from '../../../../interventions/description/description-bien-page/description-bien-page.component';
import {
    interventionRouteDescriptionIterateFunction,
    interventionRouteDescriptionIterateLabel,
    interventionRouteDescriptionIteratePath,
    interventionRouteDescriptionIterateSuffix,
} from '../../../../interventions/description/description-bien.module';
import { Icon } from 'src/app/commons-lib';
import { EditorGuard } from '../../../../../guards/editor.guard';

import { RecapitulatifComponent } from '../../modules/etude-situation/recapitulatif/recapitulatif.component';
import { InitMaterielPrelevement } from '../../modules/prelevement/prelevement-init/prelevement-materiel.component';
import { Intervention } from 'src/app/model/intervention.model';
import { Diagnostic } from 'src/app/model/diagnostic.model';
import { Zone } from '../../model/zone.model';
import { PolluantPrelevement } from '../../model/polluant-prelevement.model';
import { Polluant } from '../../model/polluant.model';
import { ZonePrelevementComponent } from '../../modules/prelevement/prelevement-zone/prelevement-zone.component';
import { NgModule } from '@angular/core';
import { DiagnosticConfigComponent } from 'src/app/modules/diagnostics-shared/diagnostic-config/diagnostic-config.component';
import { PolluantModule } from '../../polluant.module';
import { PrelevementZoneSelectionRedirectionComponent } from '../../modules/prelevement/prelevement-zone-selection-redirection/prelevement-zone-selection-redirection.component';
import { PrelevementComponent } from '../../modules/prelevement/prelevement-prelevement/prelevement-prelevement.component';
import {
    DESC,
    DESC_BIEN,
    DESC_BIEN_ANNEXE,
    POLL_PRELEVEMENT_INIT,
    POLL_PRELEVEMENT_INIT_MAT,
    POLL_PRELEVEMENT_ZONE,
    PRELEVEMENT_ZONE,
    POLL_PRELEVEMENT_META,
    POLL_RECAP_PRELO,
    DOCUMENTS_RAPPORT,
    GESTION_PLAN,
    PREVI_RAPPORT,
    RECAP,
    REPORTAGE_PHOTO,
    SAVE_RAPPORT,
    TEST,
} from 'src/app/shared/constants/names.step.constants';
import { DocumentsRapportComponent } from 'src/app/modules/diagnostics-shared/documents-rapport/documents-rapport.component';
import { RapportComponent } from 'src/app/modules/diagnostics-shared/rapport/rapport.component';
import { ReportagePhotoListComponent } from 'src/app/modules/diagnostics-shared/reportage-photo/reportage-photo-list/reportage-photo-list.component';
import { GestionPlanPolluantComponent } from '../../modules/gestion-plan-polluant/gestion-plan-polluant.component';
import { PrintingComponent } from '../../modules/shared/test-impression/printing.component';

const DEFAULT_PAGE = 'initialisation/materiel';

export function prelevementRoutesByZonesIterateFunction(context: {
    currentIntervention: Intervention;
    currentDiagnostic: Diagnostic;
}) {
    let _zones: Zone[] = (
        (context.currentDiagnostic.contenuDiagnostic as PolluantPrelevement).visiteDeChantier.data as Polluant
    ).zones?.data?.zonesList;

    return (_zones || []).filter((zone) => zone.nom !== 'Hors stratégie');
}

export function prelevementRoutesByZoneIteratePath(zone: Zone): string {
    return zone.id;
}

export function prelevementRoutesByZoneIterateLabel(zone: Zone): string {
    return zone.nom;
}

export function prelevementRoutesByZoneIterateSuffix(zone: Zone): string {
    return `_${zone.id}`;
}

export let polluantRoutingNavbarRoutes: Routes = [
    {
        path: 'description',
        data: {
            label: 'Description',
            icon: new Icon('floor-plan', 'mdi'),
            code: DESC,
        },
        children: [
            {
                path: '',
                redirectTo: 'bien-principal',
                pathMatch: 'full',
            },
            {
                path: 'bien-principal',
                data: {
                    label: 'Bien principal',
                    code: DESC_BIEN,
                },
                canDeactivate: [QuitDescriptionBienGuard],
                component: DescriptionBienPageComponent,
            },
            {
                path: ':idBien',
                data: {
                    iterate: {
                        function: interventionRouteDescriptionIterateFunction,
                        path: interventionRouteDescriptionIteratePath,
                        label: interventionRouteDescriptionIterateLabel,
                        suffix: interventionRouteDescriptionIterateSuffix,
                    },
                    code: DESC_BIEN_ANNEXE,
                },
                canDeactivate: [QuitDescriptionBienGuard],
                component: DescriptionBienPageComponent,
            },
            {
                path: 'bien-principal/editor',
                canActivate: [EditorGuard],
                loadChildren: () =>
                    import('../../../../shared/map/editor-map/editor-map.module').then((m) => m.EditorMapModule),
            },
            {
                path: ':idBien/editor',
                canActivate: [EditorGuard],
                loadChildren: () =>
                    import('../../../../shared/map/editor-map/editor-map.module').then((m) => m.EditorMapModule),
            },
        ],
    },
    {
        path: 'initialisation',
        canDeactivate: [QuitDiagnosticGuard],
        data: {
            label: 'Init',
            icon: new Icon('settings'),
            code: POLL_PRELEVEMENT_INIT,
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'materiel',
                pathMatch: 'full',
            },
            {
                path: 'materiel',
                data: {
                    label: 'Matériel',
                    code: POLL_PRELEVEMENT_INIT_MAT,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: InitMaterielPrelevement,
            },
        ],
    },
    {
        path: 'zone',
        data: {
            label: 'Zone',
            icon: new Icon('map-marker-path', 'mdi'),
            code: POLL_PRELEVEMENT_ZONE,
        },
        children: [
            {
                path: '',
                redirectTo: 'select-redirect',
                pathMatch: 'full',
            },
            {
                path: 'select-redirect',
                component: PrelevementZoneSelectionRedirectionComponent,
            },
            {
                path: ':idZone',
                data: {
                    iterate: {
                        function: prelevementRoutesByZonesIterateFunction,
                        path: prelevementRoutesByZoneIteratePath,
                        label: prelevementRoutesByZoneIterateLabel,
                        suffix: prelevementRoutesByZoneIterateSuffix,
                    },
                    code: PRELEVEMENT_ZONE,
                },
                component: ZonePrelevementComponent,
            },
        ],
        canDeactivate: [QuitDiagnosticGuard],
    },
    {
        path: 'prelevement',
        data: {
            label: 'Prélèvement',
            icon: new Icon('eyedropper', 'mdi'),
            code: POLL_PRELEVEMENT_META,
        },
        canDeactivate: [QuitDiagnosticGuard],
        component: PrelevementComponent,
    },
    {
        path: 'recapitulatif',
        data: {
            label: 'Récapitulatif',
            icon: new Icon('eye', 'fa'),
            code: POLL_RECAP_PRELO,
        },
        canDeactivate: [QuitDiagnosticGuard],
        component: RecapitulatifComponent,
    },
    {
        path: 'printing',
        data: {
            label: 'Test impression',
            icon: new Icon('eye', 'fa'),
            code: TEST,
        },
        canDeactivate: [QuitDiagnosticGuard],
        component: PrintingComponent,
    },
    {
        path: 'rapport',
        data: {
            label: 'Rapport',
            icon: new Icon('book', 'fa'),
            code: RECAP,
        },
        children: [
            {
                path: '',
                canDeactivate: [QuitDiagnosticGuard],
                redirectTo: 'gestion-plan',
                pathMatch: 'full',
            },
            {
                path: 'gestion-plan',
                data: {
                    label: 'Gestion plan',
                    code: GESTION_PLAN,
                    status: 'VOID',
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: GestionPlanPolluantComponent,
            },
            {
                path: 'reportage-photo',
                data: {
                    label: 'Reportage photo',
                    code: REPORTAGE_PHOTO,
                },
                canDeactivate: [QuitDiagnosticGuard],
                children: [
                    {
                        path: '',
                        component: ReportagePhotoListComponent,
                    },
                ],
            },
            {
                path: 'documents-rapport',
                data: {
                    label: 'Documents',
                    code: DOCUMENTS_RAPPORT,
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: DocumentsRapportComponent,
            },
            {
                path: 'apercu-rapport',
                data: {
                    label: 'Aperçu rapport',
                    code: PREVI_RAPPORT,
                    actions: [
                        {
                            label: 'Valider',
                            icon: new Icon('signature-freehand', 'mdi'),
                            code: SAVE_RAPPORT,
                            textColor: '#FFFFFF',
                            backgroundColor: '#6DC911',
                        },
                    ],
                },
                canDeactivate: [QuitDiagnosticGuard],
                component: RapportComponent,
            },
        ],
    },
];

const routes: Routes = [
    {
        path: '',
        redirectTo: 'config',
        pathMatch: 'full',
    },
    {
        path: 'config',
        component: DiagnosticConfigComponent,
        children: [
            {
                path: '',
                redirectTo: DEFAULT_PAGE,
                pathMatch: 'full',
            },
            ...polluantRoutingNavbarRoutes,
        ],
    },
];

/**
 * Routes du module à utiliser pour les barres de navigation.
 * L'attribut "data" doit implémenter l'interface {@link NavigationLinkRouteData}
 */
@NgModule({
    imports: [PolluantModule, RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PolluantPrelevementRoutingModule {}

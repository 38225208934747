"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Rook opening
//***********************************************************************************
//***********************************************************************************

import { fh_add, fh_clone, fh_cross, fh_extruded_polygon, fh_matrix, fh_mul, fh_normalize, fh_polygon, fh_solid, fh_sub } from "@acenv/fh-3d-viewer";
import { cn_camera } from "../svg/cn_camera";
import { cn_add, cn_box, cn_clone, cn_dot, cn_mul, cn_normal, cn_polar, cn_sub } from "../utils/cn_utilities";
import { cn_element } from "./cn_element";
import { cn_roof } from "./cn_roof";
import { cn_roof_opening_type } from "./cn_roof_opening_type";

export class cn_roof_opening extends cn_element {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_roof_opening_type} roof_opening_type
	 * @param {cn_roof} roof
	 */
	constructor(roof_opening_type, roof) {
		super();
		this.roof = roof;

		//*** Model data
		this.opening_type = roof_opening_type
		this.position = [0,0];
		this.orientation = 0;

		//*** volatile data */
		this.slab = null;
		this.orientation_offset = 0;
		this.vertices = [];
	}

	//***********************************************************************************
	/**
	 * serialize
	 * @returns {object}
	 */
	serialize() {
		var json = {};
		json.ID = this.ID;
		json.opening_type = this.opening_type.ID;
		json.position = cn_clone(this.position);
		json.orientation = this.orientation;
		return json;
	}

	//***********************************************************************************
	/**
	 * Unserialize
	 * @param {object} json
	 * @param {cn_roof} roof
	 * @returns {cn_roof_opening}
	 */
	static unserialize(json,roof) {
		if (typeof(json.ID) != 'string') return null;
		if (typeof(json.opening_type) != 'string') return null;
		if (typeof(json.position) != 'object') return null;
		if (typeof(json.orientation) != 'number') return null;

		var rot = roof.building.get_element_type(json.opening_type);
		if (rot == null)
		{
			console.error("Error reading roof opening : roof opening type not found");
			return null;
		}
		var ro = new cn_roof_opening(rot,roof);
		ro.ID = json.ID;
		ro.position = cn_clone(json.position);
		ro.orientation = json.orientation;
		roof.openings.push(ro);
		return ro;
	}

	//***********************************************************************************
	/**
	 * Draw
	 * @param {cn_camera} camera
	 * @param {string[]} add_classes
	 * @returns
	 */
	draw(camera, add_classes = [], fill = '') {
		var html = "";
		if (this.opening_type == null) return html;

		var mouseover = (add_classes.indexOf("mouseover") >= 0);
		var selected = (add_classes.indexOf("selected") >= 0);

		var p = camera.world_to_screen(this.position);
		var sz= [this.opening_type.width * camera.world_to_screen_scale,this.opening_type.length * camera.world_to_screen_scale];

		if (this.slab == null) html += "<g opacity='0.3'>";


		html += "<g transform='translate(" + p[0] + "," + p[1] + ") rotate(" + (-this.orientation) + ")  '>";
		html += "<rect x='" + (-sz[0]*0.5) + "' y='" + (-sz[1]*0.5) + "' width='" + sz[0] + "' height='" + sz[1] + "' ";
		html += " style='stroke:black; stroke-width: 1px; fill:white' ";
		if (mouseover)
        html += "filter='url(#mouseover_shadow)'";
		else if (selected)
        html += "filter='url(#selection_shadow)'";
		else
        html += "filter='url(#object_shadow)'";
		html += "/>";

		html += this.opening_type.draw_svg(sz[0],sz[1], fill);

		if (mouseover || selected)
			html += "<rect class='object_contour " + add_classes.join(" ") + "' x='" + (-sz[0]*0.5) + "' y='" + (-sz[1]*0.5) + "' width='" + sz[0] + "' height='" + sz[1] + "' />";

		html += "</g>";

		if (this.slab == null) html += "</g>";

		return html;
	}

	//***********************************************************************************
	/**
	 * Draw contour
	 * @param {cn_camera} camera
	 * @param {string[]} add_classes
	 * @returns {string}
	 */
	draw_contour(camera, add_classes) {
		var html = "";
		if (this.opening_type == null) return html;

		var p = camera.world_to_screen(this.position);
		var sz= [this.opening_type.width * camera.world_to_screen_scale,this.opening_type.length * camera.world_to_screen_scale];

		html += "<g transform='translate(" + p[0] + "," + p[1] + ") rotate(" + (-this.orientation) + ") translate(" + (-0.5*sz[0]) + "," + (-0.5*sz[1]) + ") '>";
		html += "<rect class='object_contour + " + add_classes.join(" ") + "' x='0' y='0' width='" + sz[0] + "' height='" + sz[1] + "' />";
		html += "</g>";
		return html;
	}

	//***********************************************************************************
	/**
	 * update
	 */
	update() {
		this.slab = this.roof.find_slab(this.position);
		if (this.slab && this.slab.slope > 0)
		{
			var pol = cn_polar(this.slab.slope_direction);
			this.orientation_offset = pol[1];
		}
		else
			this.orientation_offset = 0;

		this.vertices = [];
		var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
		var dy = cn_normal(dx);
		dx = cn_mul(dx,this.opening_type.width*0.5);
		dy = cn_mul(dy,this.opening_type.length*0.5);
		this.vertices.push(cn_sub(this.position,cn_add(dx,dy)));
		this.vertices.push(cn_sub(this.position,cn_sub(dx,dy)));
		this.vertices.push(cn_add(this.position,cn_add(dx,dy)));
		this.vertices.push(cn_add(this.position,cn_sub(dx,dy)));
	}

	//***********************************************************************************
	/**
	 * Contains
	 * @param {number[]} point
	 * @param {number} tolerance
	 * @returns {boolean}
	 */
	contains(point, tolerance=0) {
		var d = cn_sub(point,this.position);
		var sz0 = this.opening_type.width + 2 * tolerance;
		var sz1 = this.opening_type.length + 2 * tolerance;
		if (cn_dot(d,d) > sz0*sz0 + sz1*sz1) return false;
		var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
		if (Math.abs(cn_dot(d,dx)) > sz0 * 0.5) return false;
		var dy = cn_normal(dx);
		if (Math.abs(cn_dot(d,dy)) > sz1 * 0.5) return false;
		return true;
	}

	//***********************************************************************************
	/**
	 * Returns true if completelu contained in box
	 * @param {cn_box} box
	 */
	contained_by_box(box) {
		if (!box.contains_point(this.position)) return false;

		var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
		var dy = cn_normal(dx);
		dx = cn_mul(dx,0.5*this.opening_type.width);
		dy = cn_mul(dy,0.5*this.opening_type.length);
		var p = cn_sub(this.position,cn_add(dx,dy));
		if (!box.contains_point(p)) return false;
		p = cn_sub(this.position,cn_sub(dx,dy));
		if (!box.contains_point(p)) return false;
		p = cn_add(this.position,cn_add(dx,dy));
		if (!box.contains_point(p)) return false;
		p = cn_add(this.position,cn_sub(dx,dy));
		if (!box.contains_point(p)) return false;
		return true;
	}

	//***********************************************************************************
	/**
	 * Builds 3D matrix
	 * @param {number} h : actual height at level 0
	 * @returns {fh_matrix}
	 */
	build_3d_matrix(h) {
		var matrix = new fh_matrix();
		if (this.slab == null) return null;

		var pos = cn_clone(this.position);
		h += this.slab.compute_height(pos);
		pos.push(h);
		pos = fh_add(pos,fh_mul(this.slab.normal,this.slab.slab_type.thickness));

		var dz = fh_clone(this.slab.normal);
		var a = this.orientation * Math.PI / 180;
		var dx = [Math.cos(a),Math.sin(a),0];
		var dy =fh_cross(dz,dx);
		fh_normalize(dy);
		dx = fh_cross(dy,this.slab.normal);
		fh_normalize(dx);
		matrix.load_axis(pos,dx,dy,dz);
		return matrix;
	}

	//***********************************************************************************
	/**
	 * Builds piercing as extruded polyong through local matrix
	 * @returns {fh_extruded_polygon}
	 */
	build_local_piercing() {
		if (this.slab == null) return null;
		if (this.opening_type == null) return null;
		return this.opening_type.build_piercing_extrusion([0,0,-this.slab.slab_type.thickness-0.01],[1,0,0],[0,1,0],[0,0,1],this.slab.slab_type.thickness+0.02);
	}

	//***********************************************************************************
	/**
	 * Builds 3D piercing as a solid.
	 * @param {number} h
	 * @returns {fh_solid}
	 */
	build_piercing(h) {
		if (this.slab == null)	return null;
		var pos = cn_clone(this.position);
		pos.push(this.slab.compute_height(pos) + h);
		var a = this.orientation * Math.PI / 180;
		var dx = [Math.cos(a),Math.sin(a),0];
		var dy =fh_cross(this.slab.normal,dx);
		fh_normalize(dy);
		dx = fh_cross(dy,this.slab.normal);
		fh_normalize(dx);
		return this.opening_type.build_piercing_solid(fh_sub(pos,fh_mul(this.slab.normal,0.01)),dx,dy,this.slab.normal,this.slab.slab_type.thickness+0.02);
	}

	//***********************************************************************************
	/**
	 * Builds 3D opening, as a polygon at the interior roof.
	 * @param {number} h
	 * @returns {fh_polygon}
	 */
	build_3d_opening(h)
	{
		if (this.slab == null)	return null;
		var pos = cn_clone(this.position);
		pos.push(this.slab.compute_height(pos) + h);
		var a = this.orientation * Math.PI / 180;
		var dx = [Math.cos(a),Math.sin(a),0];
		var dy =fh_cross(this.slab.normal,dx);
		fh_normalize(dy);
		dx = fh_cross(dy,this.slab.normal);
		fh_normalize(dx);
		var pg = new fh_polygon(pos,this.slab.normal);
		var contour = [];
		pos = fh_sub(pos,fh_mul(dx,0.5*this.opening_type.width));
		pos = fh_sub(pos,fh_mul(dy,0.5*this.opening_type.length));
		contour.push(pos);
		pos = fh_add(pos,fh_mul(dx,this.opening_type.width));
		contour.push(pos);
		pos = fh_add(pos,fh_mul(dy,this.opening_type.length));
		contour.push(pos);
		pos = fh_sub(pos,fh_mul(dx,this.opening_type.width));
		contour.push(pos);
		pg.add_contour(contour);
		return pg;
	}

	//***********************************************************************************
	/**
	 * Performs a rotation operation
	 * @param {number[]} center : center of ritation
	 * @param {number} angle : rotation angle, in radians
	 * @param {function} rotation_function : fnction that transforms a 2D point
	 */
	perform_rotation(center, angle, rotation_function)
	{
		rotation_function(this.position);
		this.orientation += angle * 180 / Math.PI;
	}

	//***********************************************************************************
	/**
	 * Vertex operation : transform all vertices
	 * @param {function} operation : vertex operator
	 */
	vertex_operation(operation) {
		operation(this.position);
	}

	//***********************************************************************************
	/**
	 * flip operation : transform all vertices
	 * @param {number[]} center : center of flip
	 * @param {boolean} horizontal : true for horizontal flip, vertical otherwise
	 * @param {function} operation : vertex operator
	 */
	perform_flip(center,horizontal, operation) {
		operation(this.position);
		this.flipped = !this.flipped;
		if (horizontal)
			this.orientation = 180 - this.orientation;
		else
			this.orientation = - this.orientation;
	}

	//***********************************************************************************
	/**
	 * Returns bounding box
	 * @returns {cn_box}
	 */
	get_bounding_box() {
		var box = new cn_box();
		var dx = [Math.cos(this.orientation * Math.PI / 180), Math.sin(this.orientation * Math.PI / 180)];
		var dy = cn_normal(dx);
		dx = cn_mul(dx,0.5*this.opening_type.width);
		dy = cn_mul(dy,0.5*this.opening_type.length);
		var p = cn_sub(this.position,cn_add(dx,dy));
		box.enlarge_point(p);
		var p = cn_sub(this.position,cn_sub(dx,dy));
		box.enlarge_point(p);
		var p2 = cn_add(this.position,cn_add(dx,dy));
		box.enlarge_point(p2);
		var p2 = cn_add(this.position,cn_sub(dx,dy));
		box.enlarge_point(p2);
		return box;
	}

    static image_id_to_url(image_id) {
        return undefined;
    }

}


"use strict";
import {
    cn_contour,
    CN_CURRENT_DATE,
    cn_dot,
    cn_facade_lineics,
    cn_facing,
    cn_mouse_event,
    cn_number_input,
    cn_pastille_facing,
    cn_polygon_handler,
    cn_space,
    cn_wall,
    cnx_clone
} from '..';
import {cn_facing_trimming, CN_FACING_TRIMMING_PLACEMENT_CEILING, CN_FACING_TRIMMING_PLACEMENT_WALL} from '../model/cn_facing_trimming';
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_facing  : Facing Manager
//***********************************************************************************
//***********************************************************************************
import {cn_camera} from "./cn_camera";
import {cn_svg_map} from "./cn_svg_map";
import {cn_svg_tool_creation} from "./cn_svg_tool_creation";
import {cn_edition_handler} from "./cn_edition_handler";
import {cn_element} from "../model/cn_element";
import {cn_facing_trimming_handler} from './cn_facing_trimming_handler';
import {cn_bbp_geometry} from '../utils/cn_bbp_geometry';

/**
 */
export class cn_svg_tool_facing extends cn_svg_tool_creation {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_svg_map} svg_map
	 */
	constructor(svg_map) {
		super(svg_map);
        this.selected_element = null;
        this.mouseover_element = null;
        this.mouseover_storey = null;
        this.mouseover_side = null;
        this.mouseover_facing = null;
        this.mouseover_object = null;
        this.facing_pastilles = [];
		this.element_filter = function(element) {return element.constructor == cn_wall};

        this._facing_lineic_facing = null;
        this._facing_lineics = [];
        this._facing_lineics_date = 0;
	}


	//***********************************************************************************
	/**
	 * Open tool
	 */
	open_tool() {
		if (this._facing_trimming_handler)
		{
			const index = this._handlers.indexOf(this._facing_trimming_handler);
			if (index >= 0) this._handlers.splice(index,1);
		}
        super.open_tool();
	}

    //***********************************************************************************
	/**
	 * Close tool
	 */
    close_tool() {
		if (this._facing_trimming_handler)
		{
			const index = this._handlers.indexOf(this._facing_trimming_handler);
			if (index >= 0) this._handlers.splice(index,1);
		}
        super.close_tool();
    }

	/**
	 * Method to call to initiate the creation of a new facing trimming
	 * @param {boolean} on
	 */
    new_facing_trimming(on = true) {
		if (this._facing_trimming_handler)
		{
			const index = this._handlers.indexOf(this._facing_trimming_handler);
			if (index >= 0) this._handlers.splice(index,1);
		}

        this._select_element(null);
		var obj = this;
		var scene = obj._scene;
		this._facing_trimming_handler = null;
		if (!on) return;
		const facing_trimming_handler = cn_polygon_handler.create_rectangle(this,1);
		this._handlers.push(facing_trimming_handler);
        if (scene)
		    facing_trimming_handler.snap_elements = scene.spaces;
        this._facing_trimming_handler = facing_trimming_handler;

		//*** event filter */
        var storey_element_creation = null;
        var initial_point = null;
        var initial_normal = null;
		facing_trimming_handler.allow_creation = function(mouse_event) {
            if (!mouse_event.camera.is_3d()) return true;
            storey_element_creation = null;
            //*** in 3D, we can create a trimmingonly on walls and slabs. */
            if (mouse_event.impact && mouse_event.impact.storey_element)
            {
                if (mouse_event.impact.storey_element.element.constructor == cn_wall)
                {
                    storey_element_creation = mouse_event.impact.storey_element;
                    initial_point = cnx_clone(mouse_event.impact.position);
                    initial_normal = cnx_clone(mouse_event.impact.normal);
                    return true;
                }
                if (mouse_event.impact.storey_element.element.constructor == cn_space) return true;
            }
            console.log(" cannot create on",mouse_event.impact);
            return false;
		}

		//*** Callback upon creation end */
		facing_trimming_handler.on("end_creation",function(mouse_event) {
			obj.push_transaction("Création de découpe de revêtement");

			const is_3d = mouse_event.camera.is_3d();
            var creation_scene = (scene)?scene:facing_trimming_handler.creation_storey.scene;
			var facing_trimming = new cn_facing_trimming(creation_scene);

            if (is_3d && storey_element_creation)
            {
                creation_scene = storey_element_creation.storey.scene;
                facing_trimming = new cn_facing_trimming(creation_scene.building);
                facing_trimming.wall = storey_element_creation.element;
                facing_trimming.wall_storey = storey_element_creation.storey;
                facing_trimming.wall_side = (cn_dot(initial_normal,facing_trimming.wall.bounds.normal)>0)?1:0;
                facing_trimming.wall_point = cnx_clone((facing_trimming.wall_side==0)?storey_element_creation.element.bounds.pmin:storey_element_creation.element.bounds.pmax,storey_element_creation.storey.altitude);

                facing_trimming.wall_normal = initial_normal;
                facing_trimming.placement = CN_FACING_TRIMMING_PLACEMENT_WALL;
                facing_trimming.set_shape_3d(facing_trimming_handler.vertices,storey_element_creation.storey);
                console.log("shape 3d",facing_trimming.get_shape_3d(facing_trimming_handler.creation_storey));
            }
            else
            {
                if (is_3d && mouse_event.impact.normal[2] < 0)
                    facing_trimming.placement = CN_FACING_TRIMMING_PLACEMENT_CEILING;
			    facing_trimming.shape = new cn_contour(facing_trimming_handler.vertices,facing_trimming);
            }

            if (is_3d && storey_element_creation)
            {
                obj.push_item_set(creation_scene.building,"facing_trimmings",function(){creation_scene.update();creation_scene.update_deep();});
                creation_scene.building.facing_trimmings.push(facing_trimming);
            }
            else
            {
                obj.push_item_set(creation_scene,"facing_trimmings",function(){creation_scene.update();creation_scene.update_deep();});
                creation_scene.facing_trimmings.push(facing_trimming);

            }
            facing_trimming.update();
			obj.remove_handler(facing_trimming_handler);
			creation_scene.update();
			creation_scene.update_deep();
			creation_scene.storey.update_slabs();
            if (obj._map)
			    obj._map.refresh();
            if (obj._view_overlay)
            {
                obj._view_overlay.refresh();
                obj._view_overlay.refresh_3d();
            }
			obj.call("creation",[facing_trimming]);

            obj._other_storey = facing_trimming_handler.creation_storey;
			obj._initiate_edition([facing_trimming]);
		});
    }

    /**
     *
     * @param {cn_facing} facing
     */
    build_facing_lineics(facing) {
        this._facing_lineic_facing = facing;
        this._facing_lineics_date = 0;
    }

    /**
     * Choose how facings are trimmed depending on ground
     */
    choose_facings_above_ground() {
        const input = new cn_number_input("Découpe des revêtements le long du sol", this._building.facings_above_ground_height, "m", 2, -100, 100, "Pas de découpe", !this._building.facings_above_ground);

        const obj = this;
        input.callback = function () {
            obj.push_transaction("Découpe des revêtements le long du sol");
            obj.push_item_set(obj._building, ["facings_above_ground_height","facings_above_ground"]);
            obj._building.facings_above_ground = !input.checkbox_status;
            if ( obj._building.facings_above_ground)
                obj._building.facings_above_ground_height = input.value;

            if (obj._map)
			    obj._map.refresh();
            if (obj._view_overlay)
            {
                obj._view_overlay.refresh();
                obj._view_overlay.refresh_3d();
            }
        }
        this.call("number_input", input);
    }
	//***********************************************************************************
	/**
	 * SVG rendering
	 * @param {cn_camera} camera
	 * @returns {string} svg rendered
	 */
	// @ts-ignore
	draw(camera) {
		let html = "";

        //*** Draw facade lineics */
        if (this._view_overlay)
        {
            if (this._facing_lineics_date == 0 || this._facing_lineics_date < this._view_overlay.get_3d_building().get_update_date())
            {
                this._facing_lineics = this._facing_lineic_facing ? cn_facade_lineics.build_for_facing(this._building,this._facing_lineic_facing) : [];
                this._facing_lineics_date = CN_CURRENT_DATE;
            }
            this._facing_lineics.forEach(f => html += f.draw(camera));
        }

        if (this.selected_element) {
            if (this.selected_object && this.selected_object.geometries[0].constructor == cn_bbp_geometry)
                html += this.selected_object.geometries[0].draw_svg(camera,"outline_3d selected");
            else
                html += this.selected_element.draw(camera, ['selected']);
            this.facing_pastilles.forEach(pastille => html += pastille.draw(camera));
        }

        if (this.mouseover_element && (!this.selected_element || this.mouseover_element.ID !== this.selected_element.ID)) {
            if (this.mouseover_object && this.mouseover_object.geometries[0].constructor == cn_bbp_geometry)
                html += this.mouseover_object.geometries[0].draw_svg(camera,"outline_3d mouseover");
            else
                html += this.mouseover_element.draw(camera, ['mouseover']);
        }

        if (this._scene)
            this._scene.facing_trimmings.forEach(ft => {
                html += ft.draw(camera);
            });

		html += super.draw(camera);
		return html;
	}

    _select_element(element) {
        if (this.selected_element == element) return;
        this.selected_element = element;

        this.facing_pastilles = [];
        if (this.selected_element) {
            if (this.selected_element.constructor === cn_wall && this.selected_element.has_facings()) {
                for (let side = 0; side < 2; side++) {
					this.facing_pastilles.push(new cn_pastille_facing(this._map, this.selected_element, side));
                }
            } else if (this.selected_element.constructor === cn_space) {
                this.selected_element.contours.forEach(ctr => {
                    ctr.walls.forEach((wall, index) => {
                        if (wall.has_facings()) {
                            const side = !!ctr.wall_orientations[index] ? 0 : 1;
                            this.facing_pastilles.push(new cn_pastille_facing(this._map, wall, side));
                        }
                    });
                });
                if (!this.selected_element.outside) {
                    this.facing_pastilles.push(new cn_pastille_facing(this._map, this.selected_element, 0, 30, this._storey.exterior));
                    if (!this._storey.exterior && this.selected_element.has_roof) {
                        this.facing_pastilles.push(new cn_pastille_facing(this._map, this.selected_element, 1));
                    }
                }
            }
        }
    }

    _select_3d_element(ev) {
        if (this.selected_element == this.mouseover_element &&
            this.selected_storey == this.mouseover_storey &&
            this.selected_side == this.mouseover_side) return;
        this.selected_element = this.mouseover_element;
        if (this.selected_element)
        {
            this.selected_storey = this.mouseover_storey;
            this.selected_side = this.mouseover_side;
            this.selected_object = this.mouseover_object;
            this.facing_pastilles = [];
            const pastille = new cn_pastille_facing(this._view_overlay, this.selected_element, this.mouseover_side, 30, this.selected_storey.exterior);
            pastille.world_position = ev.impact.position;
            this.facing_pastilles.push(pastille);
            pastille.on("change",() => {
                this._view_overlay.refresh_3d();
            });
        }
    }

    //***********************************************************************************
    /**
     * click
     * @returns {boolean}
     */
    click(ev) {
        if (this.mouseover_facing) {
            this._terminate_edition();
            this.mouseover_facing.clicked();
            return true;
        }
		if (super.click(ev)) return true;
        this._terminate_edition();

        if (this._scene)
        {
            this._select_element(this._scene.find_wall(ev.mouse_world, ev.camera.snap_world_distance)
                || this._scene.find_space(ev.mouse_world, ev.camera.snap_world_distance));
        }
        else
        {
            this._select_3d_element(ev);
        }
        return true
    }
    /**
	 * move
     *
     * @param {object} ev
	 * @returns {boolean}
	 */
     move(ev) {
        this.mouseover_facing = null;
        this.mouseover_element =  null;
        this.clear_move();

        this.facing_pastilles.forEach(pastille => {
            pastille.mouseover = pastille.contains(ev.mouse_world, ev.camera);
            if (pastille.mouseover) this.mouseover_facing = pastille;
        });

        if (this.mouseover_facing)
            return true;

		if (super.move(ev)) return true;

        if (this._scene)
            this.mouseover_element = this._scene.find_wall(ev.mouse_world, ev.camera.snap_world_distance) || this._scene.find_space(ev.mouse_world, ev.camera.snap_world_distance);
        else if (ev.camera.is_3d())
        {
            if (ev.impact &&
                ev.impact.storey_element &&
                ev.impact.storey_element.element &&
                ev.impact.object &&
                ev.impact.object.json_object)
                {
                    if (ev.impact.storey_element.element.constructor == cn_wall && ev.impact.object.json_object.cnmap_wall)
                    {
                        this.mouseover_element = ev.impact.storey_element.element;
                        this.mouseover_side = (cn_dot(ev.impact.normal,this.mouseover_element.bounds.normal)>0)?1:0;
                        this.mouseover_storey = ev.impact.storey_element.storey;
                        this.mouseover_object = ev.impact.object.json_object;
                    }
                    else if (ev.impact.storey_element.element.constructor == cn_space) {
                        this.mouseover_element = ev.impact.storey_element.element;
                        this.mouseover_side = 0;
                        this.mouseover_storey = ev.impact.storey_element.storey;
                        this.mouseover_object = ev.impact.object.json_object;
                    }
                }
        }
        if (this.mouseover_element)
            return true;

        return false
    }

    /**
     * grab
     *
     * @param {object} ev
     * @returns {boolean}
     */
    grab(ev) {
		if (super.grab(ev)) return true;
		if (this.move(ev)) return true;
        this._terminate_edition();
        return false;
    }

    drag(ev) {
		if (super.drag(ev)) return true;
        return false;
    }

    create(facing) {
        this.push_transaction('Création d\'un revêtement');
        this.push_item_set(this._building, 'facing_types');
        this._building.facing_types.push(facing);
    }

    delete(facing) {
        const facing_index = this._building.facing_types.findIndex(f => f.ID === facing.ID);
        if (facing_index >= 0) {
            this.push_transaction('Supression d\'un revêtement');
            this.push_item_set(this._building, 'facing_types');
            if (facing.support === 'wall' || facing.support === 'facade') {
                this._building.storeys.forEach(storey => {
                    storey.scene.walls.filter(wall => wall.facings.some(f => f && f.ID === facing.ID)).forEach(wall => {
                        this.push_item_set(wall, 'facings');
                        if (wall.facings[0] && wall.facings[0].ID === facing.ID) {
                            wall.facings[0] = null;
                        }
                        if (wall.facings[1] && wall.facings[1].ID === facing.ID) {
                            wall.facings[1] = null;
                        }
                    })
                });
            } else if (facing.support === 'ceiling' || facing.support === 'floor') {
                this._building.storeys.forEach(storey => {
                    storey.scene.spaces.filter(space => space.facings.some(f => f && f.ID === facing.ID)).forEach(space => {
                        this.push_item_set(space, 'facings');
                        if (space.facings[0] && space.facings[0].ID === facing.ID) {
                            space.facings[0] = null;
                        } else if (space.facings[1] && space.facings[1].ID === facing.ID) {
                            space.facings[1] = null;
                        }
                    })
                });
            } else {
                if (this._building.exeterior.spaces) {
                    this._building.exterior.spaces.filter(space => space.facings[0] && space.facings[0].ID === facing.ID).forEach(space => {
                        this.push_item_set(space, 'facings');
                        space.facings[0] = null;
                    })
                }
            }
            this._building.facing_types.splice(facing_index, 1);
        }
    }

    modify(facing) {
        const facing_index = this._building.facing_types.findIndex(f => f.ID === facing.ID);
        this.push_transaction('Modification d\'un revêtement');
        this.push_item_set(this._building.facing_types[facing_index], ['color', 'texture', 'layers', 'name']);
        Object.assign(this._building.facing_types[facing_index], facing);
    }

	//***********************************************************************************
	//**** Edition elements
	//***********************************************************************************
	/**
	 * TODO : derivate in order to allow edition of other element in the process of creation
	 * @param {cn_mouse_event} mouse_event
	 * @returns {cn_element}
	 */
	_find_other_element(mouse_event) {
        if (this._scene)
		    return this._scene.find_facing_trimming(mouse_event.mouse_world,mouse_event.camera.snap_world_distance);
        if (mouse_event.camera.is_3d() &&
            mouse_event.impact &&
            mouse_event.impact.storey_element)
        {
            if (mouse_event.impact.storey_element.element.constructor == cn_facing_trimming)
            {
                // @ts-ignore
                this._other_storey = mouse_event.impact.storey_element.storey;
                this._other_object = mouse_event.impact.object.json_object;
                return mouse_event.impact.storey_element.element;
            }
        }
        return null;
	}

	/**
	 * TODO : derivate in order to provide an edition handler
	 * @param {Array<cn_facing_trimming>} elements
	 * @returns {cn_edition_handler}
	 */
	_build_edition_handler(elements) {
        this._select_element(null);
        const handler = new cn_facing_trimming_handler(elements,(this._map)?this._map:this._view_overlay,this._other_storey);
		return handler;
	}


}


<h1 mat-dialog-title class="primary">Ajouter une pièce pré-équipée</h1>

<div mat-dialog-content>
    <div class="d-flex flex-column">
        <div class="w-100">
            <mat-form-field class="w-100">
                <input placeholder="Nom de la pièce" matInput type="text" [(ngModel)]="roomToEdit.nom" />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field class="w-100">
                <input
                    matInput
                    placeholder="Nom de la configuration"
                    type="text"
                    [(ngModel)]="roomToEdit.piecesPreequipeesConfigs[0].nom"
                />
            </mat-form-field>
        </div>
    </div>

    <div *ngFor="let equipment of roomEquipmentsNames">
        <div class="equipment-card p-1 m-1 d-flex align-items-center">
            <div class="flex-grow-1">{{ equipment }}</div>
            <button mat-icon-button color="warn" (click)="deleteEquipment(equipment)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <app-custom-select
            [matFormFieldClass]="'w-75'"
            [choices]="equipmentsToDisplay"
            [placeholder]="'Ajouter un équipement'"
            [(selectedValue)]="newEquipment"
            [addline]="false"
        ></app-custom-select>
        <button mat-button [disabled]="!(newEquipment && newEquipment !== '')" (click)="addEquipment(newEquipment)">
            <lib-icon>add</lib-icon>
        </button>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button mat-raised-button color="accent" [disabled]="!roomToEdit.nom" (click)="confirm()">
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>

import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { cn_building } from '@acenv/cnmap-editor';
import { BaseComponent, ConfirmationService, MongoUtils, NotificationService } from 'src/app/commons-lib';
import { combineLatest, of } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import { ReferenceService } from 'src/app/services/reference.service';
import { StringUtils } from 'src/app/utils/string.utils';
import { Bien, ElementAControler, Niveau, Volume } from '../../../../model/bien.model';
import { Diagnostic, EtatDiagnostic } from '../../../../model/diagnostic.model';
import { ExigenceDescription, Intervention, RelationInterventionBien } from '../../../../model/intervention.model';
import { TypeElementAControler, TypeVolume } from '../../../../model/type-element-a-controler.model';
import { TypePrestation, typesPrestation } from '../../../../model/type-prestation.model';
import { BienService } from '../../../../services/bien.service';
import { DiagnosticHandlerService } from '../../../../services/diagnostic-handler.service';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { EtatProgressionService } from '../../../../services/etat-progression.service';
import { InterventionService } from '../../../../services/intervention.service';
import { StaticJsonService } from '../../../../services/static-json.service';
import { DragndropUtils } from '../../../../utils/dragndrop.utils';
import { ListUtils } from '../../../../utils/list.utils';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { ViewerMapComponent } from '../../../shared/map/viewer-map/viewer-map.component';
import { AddCustomPreequippedRoomModalComponent } from '../add-custom-preequipped-room-modal/add-custom-preequipped-room-modal.component';
import { AddNiveauModalComponent } from '../add-niveau-modal/add-niveau-modal.component';
import { AddVolumeModalComponent } from '../add-volume-modal/add-volume-modal.component';
import { CopyEquipmentModalComponent } from '../copy-equipment-modal/copy-equipment-modal.component';
import { ViewerMapConfig } from '../../../shared/map/viewer-map/viewer-map-config';
import { RelationInterventionBienService } from 'src/app/services/relation-intervention-bien.service';
import * as moment from 'moment';
import {
    DATE_FORMAT,
    PARAM_TOOLTIP_NON_VISITEE,
    PARAM_TOOLTIP_PARTIELLEMENT_VISITEE,
    PARAM_TOOLTIP_VISITEE,
    PARAM_VOLUME_VISITE,
} from 'src/app/shared/constants/cndiag.constants';
import { EditorHandler } from '../../../shared/map/editor-map/handler/editor-handler';
import { BienUtils } from 'src/app/utils/bien.utils';
import { CheckValidityTabService } from 'src/app/services/check-validity-tab.service';
import { TypeReferenceFichier } from 'src/app/model/intervention-file.model';
import { InterventionFileService } from 'src/app/services/intervention-file.service';
import { notValidatedOption, validatedOption, warningOption } from '../../../../shared/constants/states.constants';
import {
    ModalTransfertUsageComponent,
    ModalTransfertUsageSelection,
    TransfertUsageModalData,
    TypeAssociation,
} from './modal-transfert-usage/modal-transfert-usage.component';
import { BaseObject } from '../../../../model/base-object.model';
import { combineLatestOrEmpty } from '../../../../utils/rxjs.utils';
import { SyncBienPlanService } from '../../../../services/sync-bien-plan.service';
import { MergeBienService } from '../../../../services/merge-bien.service';
import { PolluantService } from '../../../diagnostics/polluant/services/polluant.service';

@Component({
    selector: 'app-description-bien',
    templateUrl: './description-bien.component.html',
    styleUrls: ['./description-bien.component.scss'],
})
export class DescriptionBienComponent extends BaseComponent implements OnInit, OnDestroy {
    // Paramètres de visite
    readonly validatedChoiceOption = validatedOption;
    readonly notValidatedChoiceOption = notValidatedOption;
    readonly warningChoiceOption = warningOption;
    readonly paramVolumeVisite = PARAM_VOLUME_VISITE;

    tooltipsVisite = [PARAM_TOOLTIP_VISITEE, PARAM_TOOLTIP_PARTIELLEMENT_VISITEE, PARAM_TOOLTIP_NON_VISITEE];

    @ViewChild('viewMap') viewMap: ViewerMapComponent;
    _relationInterventionBien: RelationInterventionBien;

    @Input() set relationInterventionBien(value: RelationInterventionBien) {
        this._relationInterventionBien = value;
        this.bienSelected = value.bien;
        this.bienService.setCurrentBien(value);
        this.bienChanged();
    }

    get relationInterventionBien() {
        return this._relationInterventionBien;
    }

    @Input() set bien(value: Bien) {
        this.bienSelected = value;
        this.bienChanged();
    }

    @Input() set currentFilter(value: TypePrestation) {
        this.categoryFilter = value;
        this.typeDiagnostic = value;
        if (!this.categoryFilter) {
            this.categoryFilter = 'COMMUN';
            this.typeDiagnostic = 'COMMUN';
        }
        // Le filtre sera changé automatiquement dans le onInit
    }

    // Option plan demandé
    planRequired = false;
    bienSelected: Bien;

    typesVolumes: TypeVolume[] = [];

    volumeCache: Volume;

    intervention: Intervention;
    diagnostic: Diagnostic;

    // Mode readonly de la liste si on possède déjà un plan du bien
    readonlyListe = false;
    // Mode readonly de la page, si on est admin ou si l'intervention est terminée
    readonlyMode = false;

    relationBienPrincipal: RelationInterventionBien;

    typeDiagnostic = 'COMMUN';
    exigenceDescriptionCroquis = ExigenceDescription.CROQUIS;
    exigenceDescriptionPlan = ExigenceDescription.PLAN;

    detailPieceMode = false;
    detailNiveauMode = true;
    pieceSelected: Volume;

    /**
     * Hauteur du header de l'accordeon
     */
    customCollapsedHeight = '40px';
    customExpandedHeight = '40px';

    pagesPanelToggleable = false;
    pagesPanelOpened = true;
    searchword = '';
    typesPrestations: TypePrestation[] = typesPrestation;
    categoryFilter: TypePrestation = 'COMMUN';
    viewMode = 'list';

    typesElementsAControler: TypeElementAControler[] = [];
    typesElementsAControlerFromReferentiel: TypeElementAControler[] = [];

    typesElementsAControlerFiltered: TypeElementAControler[];
    preEquippedRoomsFiltered: TypeVolume[];
    emptyRoomsFiltered: TypeVolume[] = [];

    currentStair: Niveau = new Niveau();

    roomIndexes = [];

    bienBuilding: cn_building;

    viewerMapConfig: ViewerMapConfig = {
        isSelectablePieceSansEquipement: false,
        isSelectableEquipement: true,
    };

    externalSelectedElement = null;
    externalSelectedVolume = null;

    private typesElementsAControlerById = new Map<string, TypeElementAControler>();

    constructor(
        private readonly bienService: BienService,
        private readonly staticJsonService: StaticJsonService,
        private readonly interventionService: InterventionService,
        private readonly confirmationService: ConfirmationService,
        private readonly notificationService: NotificationService,
        private readonly referenceService: ReferenceService,
        private readonly diagnosticService: DiagnosticService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly diagnosticHandlerService: DiagnosticHandlerService,
        private readonly matDialog: MatDialog,
        private readonly route: ActivatedRoute,
        private readonly _sanitizer: DomSanitizer,
        private readonly relationInterventionBienService: RelationInterventionBienService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly editorHandler: EditorHandler,
        private readonly checkValidityTabService: CheckValidityTabService,
        private readonly interventionFileService: InterventionFileService,
        private readonly syncBienPlanService: SyncBienPlanService,
        private readonly mergeBienService: MergeBienService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService.getCurrentIntervention(),
                    this.referenceService.findAllTypesElementsAControler(),
                    this.referenceService.findAllTypesVolumes(),
                    this.categoryFilter && this.categoryFilter !== 'COMMUN'
                        ? this.diagnosticService.getCurrentDiagnostic()
                        : of(undefined),
                    this.etatProgressionService.etatProgressions$,
                ]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([intervention, equipementsFromReferentiel, pieces, diagnostic]) => {
                        return combineLatest([
                            of(intervention),
                            of(equipementsFromReferentiel),
                            of(pieces),
                            of(diagnostic),
                            this.interventionService.isReadOnlyMode(intervention, undefined),
                            combineLatestOrEmpty(
                                intervention.prestationsDiagnostics
                                    .filter(
                                        (presta) =>
                                            !!presta.idDiagnostic && presta.etatDiagnostic === EtatDiagnostic.EN_COURS
                                    )
                                    .map((presta) => this.diagnosticService.findOne(presta.idDiagnostic))
                            ),
                        ]);
                    })
                )
            )
            .subscribe(
                ([intervention, equipementsFromReferentiel, pieces, diagnostic, readonlyMode, diagnostics]) => {
                    this.intervention = intervention;
                    this.relationBienPrincipal = Intervention.getRelationInterventionBienPrincipal(this.intervention);
                    this.diagnostic = diagnostic;
                    this.readonlyMode = readonlyMode;
                    this.readonlyListe = readonlyMode || !!this.bienSelected.jsonPlan || this.planRequired;

                    this.planRequired = this.intervention.prestationsDiagnostics
                        .flatMap((prest) => prest.prestation.optionPlan)
                        .includes(true);

                    this.interventionService
                        .isReadOnlyMode(this.intervention, diagnostic)
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe((rom) => {
                            this.readonlyMode = rom;
                            this.readonlyListe = rom || !!this.bienSelected.jsonPlan || this.planRequired;
                        });

                    this.typesElementsAControlerFromReferentiel = equipementsFromReferentiel
                        .sort((c1, c2) => c1.nom.localeCompare(c2.nom))
                        .filter((elem) => elem.etatEquipement === EtatWorkflow.ACTIF);
                    // En plus des équipements contenus dans le référentiel, on liste également les équipements customs créés par le user et utilisés dans la description de ce bien
                    this.getEquipments();
                    this.typesElementsAControler.forEach((typeElem) => {
                        this.typesElementsAControlerById.set(typeElem.id, typeElem);
                    });

                    this.typesVolumes = pieces
                        .sort((c1, c2) => c1.nom.localeCompare(c2.nom))
                        .filter((piece) => piece.etatVolume === EtatWorkflow.ACTIF);
                    let building: cn_building;
                    this.loadBuilding();
                    this.syncBienPlanService.updateBienFromMap(
                        this.bienSelected,
                        this.bienBuilding,
                        this.typesElementsAControler,
                        this.intervention,
                        this.typesVolumes,
                        diagnostics
                    );
                    this.bienChanged();
                    this.changeFilter();
                    this.checkValidity();

                    if (this.planRequired || this.bienSelected.jsonPlan) {
                        this.changeViewMode({ value: 'plan' });
                    }

                    this.cnSpinnerService.hide();
                },
                () => this.cnSpinnerService.hide()
            );
    }

    bienChanged() {
        if (!this.bienSelected) {
            return;
        }
        if (this.bienSelected.description && this.bienSelected.description.length) {
            this.currentStair = this.bienSelected.description[0];
            this.volumeCache = this.currentStair.volumes.find((it) => it.volumeCache);
            this.createRoomIndexes();
        } else {
            this.currentStair = undefined;
        }

        if (this.planRequired || this.bienSelected.jsonPlan) {
            this.changeViewMode({ value: 'plan' });
        } else {
            this.changeViewMode({ value: 'list' });
        }

        this.readonlyListe = this.readonlyMode || !!this.bienSelected.jsonPlan || this.planRequired;
    }

    /**
     * Vérification des justifications de non visite, si nécessaire
     */
    currentVisiteChanged(visiteValue: string) {
        // mise à jour de la valeur du paramètre visité de la pièce dans la relation intervention/bien de l'intervention
        this.interventionService.updateRelationInterventionBien(
            this.intervention,
            this.bienSelected.id,
            this.currentStair.id,
            this.pieceSelected.id,
            this.paramVolumeVisite,
            visiteValue
        );
    }

    /**
     * Recherche dans la liste des équipements et des pièces pré-équipées.
     */
    search() {
        // Search accent insensitive
        this.filterElementsAControler();
        this.filterPreEquipedRooms();
        this.filterEmptyRooms();
    }

    /**
     * Récupération du filtre / type de prestation sélectionné
     */
    filterChanged(value: TypePrestation) {
        if (this.typeDiagnostic === 'COMMUN' || value === 'COMMUN' || this.typeDiagnostic === value) {
            this.categoryFilter = value;
            this.changeFilter();
        }
    }

    /**
     * Filtrer selon le type de diagnostic dans la liste des équipements et des pièces pré-équipées.
     */
    changeFilter() {
        this.filterEmptyRooms();
        this.filterElementsAControler();
        // Filtre des pièces pré-équipées
        this.filterPreEquipedRooms();
    }

    /**
     * Filtre la liste des pièces pré-équipé suivant la catégorie et le champs de recherche
     */
    filterPreEquipedRooms() {
        this.preEquippedRoomsFiltered = this.typesVolumes
            .filter((x) => x.nom.toLowerCase().includes(this.searchword))
            .map((it) => {
                return it.piecesPreequipeesConfigs.map((conf) => {
                    if (conf.idsTypesElementsAControler.length) {
                        const typeVol = new TypeVolume();
                        typeVol.id = it.id;
                        typeVol.nom = it.nom;
                        typeVol.etatVolume = it.etatVolume;
                        typeVol.piecesPreequipeesConfigs = [conf];
                        typeVol.valeursDefautParametres = it.valeursDefautParametres;
                        return typeVol;
                    }
                });
            })
            .flat()
            .filter((it) => it !== undefined);
    }

    /**
     * Filtre la liste des pièces vide suivant la catégorie et le champs de recherche
     */
    filterEmptyRooms() {
        this.emptyRoomsFiltered = this.typesVolumes.filter((x) => x.nom.toLowerCase().includes(this.searchword));
    }

    /**
     * Filtre la liste des elements suivant la catégorie et le champs de recherche
     */
    filterElementsAControler() {
        this.typesElementsAControlerFiltered = this.typesElementsAControler.filter(
            (x) =>
                StringUtils.toLowerCase(x.nom).includes(StringUtils.toLowerCase(this.searchword)) &&
                x.typesPrestationsAssocie.find((it) => it === this.categoryFilter)
        );
    }

    /**
     * Toggle mode liste ou plan
     */
    changeViewMode(value) {
        this.viewMode = value.value;
        if (this.viewMode === 'plan') {
            this.pagesPanelOpened = !!this.bienSelected.jsonPlan;
            this.loadBuilding();
        } else {
            this.pagesPanelOpened = true;
        }
    }

    /**
     * Ajouter un équipement personnalisé : Ouvre la modale spécifique.
     */
    addEquipment() {
        return this.matDialog
            .open(CopyEquipmentModalComponent, {
                data: {
                    typesElementsAControlerFromReferentiel: this.typesElementsAControlerFromReferentiel,
                    totalTypesElementsAControler: this.typesElementsAControler,
                },
            })
            .afterClosed()
            .subscribe((result: false | TypeElementAControler) => {
                if (result) {
                    this.bienSelected.typesElementsAControler =
                        this.bienSelected.typesElementsAControler.concat(result);
                    this.notificationService.success(`L'équipement ${result.nom} a été ajouté`);
                }
                this.getEquipments();
                this.changeFilter();
            });
    }

    /**
     * Éditer un équipement personnalisé : Ouvre la modale spécifique.
     * Le bouton ne s'affiche que pour équipements personnalisés, et non ceux issus du référentiel.
     */
    editEquipment(typeElementAControlerToEdit: TypeElementAControler) {
        return this.matDialog
            .open(CopyEquipmentModalComponent, {
                data: {
                    typesElementsAControlerFromReferentiel: this.typesElementsAControlerFromReferentiel,
                    totalTypesElementsAControler: this.typesElementsAControler,
                    typeElementAControlerToEdit: typeElementAControlerToEdit,
                    bien: this.bienSelected,
                },
            })
            .afterClosed()
            .subscribe((result: false | TypeElementAControler) => {
                if (result) {
                    // Si l'élément est déjà présent dans la description, il est également mis à jour.
                    this.bienSelected.description.forEach((niveau) => {
                        niveau.volumes.forEach((volume) => {
                            const presents = volume.elementsAControler.filter(
                                (el) => el.idTypeElementAControler === result.id
                            );
                            presents.length && presents.forEach((it) => (it.nom = result.nom));
                        });
                    });
                    this.interventionService.updateIntervention(this.intervention).subscribe(
                        () => {
                            this.notificationService.success(`L'équipement ${result.nom} a été modifié`);
                            this.getEquipments();
                            this.changeFilter();
                            this.cnSpinnerService.hide();
                        },
                        () => this.cnSpinnerService.hide()
                    );
                }
            });
    }

    /**
     * Ajouter une pièce prééquipée personnalisée : Ouvre la modale spécifique.
     */
    addPreequippedRoom() {
        return this.matDialog
            .open(AddCustomPreequippedRoomModalComponent)
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.typesVolumes.push(result.room);
                    this.typesVolumes.sort((c1, c2) => c1.nom.localeCompare(c2.nom));
                    this.changeFilter();
                    this.notificationService.success(`La pièce prééquipée ${result.room.nom} a été ajoutée`);
                }
            });
    }

    /**
     * Change l'étage courant.
     */
    changeStair(stair: Niveau) {
        this.currentStair = stair;
        this.volumeCache = this.currentStair.volumes.find((it) => it.volumeCache);
        if (this.viewMode === 'list') {
            this.createRoomIndexes();
        }
    }

    /**
     * Ajouter un étage.
     */
    addStair() {
        if (this.viewMode === 'list') {
            return this.matDialog
                .open(AddNiveauModalComponent, {
                    data: {
                        bien: this.bienSelected,
                    },
                })
                .afterClosed()
                .subscribe((result: any) => {
                    if (result && result !== false) {
                        const volumeCache = new Volume();
                        volumeCache.nom = 'Extérieur';
                        volumeCache.volumeCache = true;
                        result.niveau.volumes.push(volumeCache);
                        result.niveau.index = this.bienSelected.description.length;
                        this.bienSelected.description.push(result.niveau);
                        this.currentStair = this.bienSelected.description[this.bienSelected.description.length - 1];
                        this.volumeCache = this.currentStair.volumes.find((it) => it.volumeCache);
                        this.interventionService.updateIntervention(this.intervention).subscribe(
                            () => {
                                this.createRoomIndexes();
                                this.checkValidity();
                                this.cnSpinnerService.hide();
                            },
                            () => this.cnSpinnerService.hide()
                        );
                    }
                });
        } else if (this.viewMode === 'plan') {
            const toUrl = `/interventions/${this.intervention.id}/bien/${this.bienSelected.id}/editor`;
            this.editorHandler.navigateToEditor(toUrl, null);
        }
    }

    /**
     * Copier l'étage courant pour le rajouter dans la liste d'étage.
     */
    copyCurrentStair() {
        const newStair = { ...this.currentStair };
        newStair.id = MongoUtils.generateObjectId();
        newStair.nom = 'Niveau ' + this.bienSelected.description.length;
        newStair.index = this.bienSelected.description.length;
        newStair.storeyId = null;
        newStair.volumes = [];
        this.currentStair.volumes.forEach((vol) => {
            const newVolume = { ...vol };
            newVolume.id = MongoUtils.generateObjectId();
            newVolume.spaceId = null;
            newVolume.commentairesId = [];
            newVolume.elementsAControler = [];
            vol.elementsAControler.forEach((el) => {
                const newElement = { ...el };
                newElement.id = MongoUtils.generateObjectId();
                newVolume.elementsAControler.push(newElement);
            });
            newStair.volumes.push(newVolume);
        });

        this.bienSelected.description.push(newStair);
        this.interventionService.updateIntervention(this.intervention).subscribe(
            () => {
                this.checkValidity();
                this.cnSpinnerService.hide();
            },
            () => this.cnSpinnerService.hide()
        );
    }

    /**
     * Modifier le nom de l'étage courant.
     */
    editStair() {
        return this.matDialog
            .open(AddNiveauModalComponent, {
                data: {
                    bien: this.bienSelected,
                    niveauToEdit: this.currentStair,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    const index = this.bienSelected.description.indexOf(this.currentStair);
                    if (index !== -1) {
                        this.bienSelected.description[index].nom = result.niveau.nom;
                    }
                    this.currentStair.nom = result.niveau.nom;
                    this.interventionService.updateIntervention(this.intervention).subscribe(
                        () => {
                            this.checkValidity();
                            this.cnSpinnerService.hide();
                        },
                        () => this.cnSpinnerService.hide()
                    );
                }
            });
    }

    /**
     * Suppression de l'étage courant, ainsi que les pièces et équipements associés.
     * Vérification si l'étage a déjà été complété dans les points de contrôle d'un diagnostic
     */
    deleteCurrentStair() {
        let msg = 'Êtes-vous sûr de vouloir supprimer ce niveau ?';
        let isAlreadyUsed = false;
        this.diagnosticService
            .getAllDiagnosticsForCurrentIntervention(this.intervention)
            .subscribe((diagnostics: Diagnostic[]) => {
                if (diagnostics) {
                    for (const diagnostic of diagnostics) {
                        const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(
                            diagnostic.typePrestation
                        );
                        if (typePrestationService) {
                            isAlreadyUsed = typePrestationService.isItemAlreadyFilled(
                                diagnostic,
                                'niveau',
                                this.currentStair.id
                            );
                        }
                        if (isAlreadyUsed) {
                            break;
                        }
                    }
                    if (isAlreadyUsed) {
                        msg =
                            '<div class="warn">Attention, ce niveau a déjà été renseigné dans un diagnostic.</div>Êtes-vous sûr de vouloir le supprimer ?';
                    }
                }

                this.confirmationService.confirmWarn(msg, () => {
                    const index = this.bienSelected.description.indexOf(this.currentStair);
                    if (index > -1) {
                        this.bienSelected.description.splice(index, 1);

                        // Suppression des commentaires et des images rattachées, si présentes
                        const flattenedComs = this.currentStair.volumes.flatMap((v) => v.commentairesId);
                        flattenedComs.forEach((comId) => {
                            const com = this.intervention.commentaires.find((c) => c.id === comId);
                            if (com && com.imageId) {
                                // Suppression de l'image
                                this.cnSpinnerService
                                    .withSpinner(
                                        this.interventionFileService.deleteInterventionFileAndFile(
                                            this.intervention.id,
                                            this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                                            com.id,
                                            TypeReferenceFichier.PHOTO_COMMENTAIRE,
                                            com.imageId
                                        )
                                    )
                                    .pipe(takeUntil(this.ngUnsubscribe))
                                    .subscribe();
                            }
                            this.intervention.commentaires.splice(this.intervention.commentaires.indexOf(com), 1);
                        });
                        // On remet à jour les indexes de niveaux
                        this.bienSelected.description.forEach(
                            (niv) => (niv.index = this.bienSelected.description.indexOf(niv))
                        );
                        this.currentStair = this.bienSelected.description[0];
                        this.createRoomIndexes();
                        this.cnSpinnerService
                            .withSpinner(
                                this.interventionService.updateIntervention(this.intervention),
                                'Suppression en cours...'
                            )
                            .subscribe(() => {
                                this.notificationService.success('Le niveau a bien été supprimé.');
                                this.checkValidity();
                            });
                    }
                });
            });
    }

    /**
     * Ajouter manuellement une nouvelle pièce dans l'étage courant.
     */
    addNewRoom() {
        return this.matDialog
            .open(AddVolumeModalComponent, {
                data: {
                    niveau: this.currentStair,
                    zoneBien: this.bienSelected.numeroLot,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false) {
                    this.currentStair.volumes.push(result.volume);
                    this.createRoomIndexes();
                    this.checkValidity();
                }
            });
    }

    /**
     * Supprimer la pièce sélectionnée.
     */
    deleteRoom(room: Volume) {
        let msg = 'Êtes-vous sûr de vouloir supprimer cette pièce ?';
        let isAlreadyUsed = false;
        this.diagnosticService
            .getAllDiagnosticsForCurrentIntervention(this.intervention)
            .subscribe((diagnostics: Diagnostic[]) => {
                if (diagnostics) {
                    for (const diagnostic of diagnostics) {
                        const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(
                            diagnostic.typePrestation
                        );
                        if (typePrestationService) {
                            isAlreadyUsed = typePrestationService.isItemAlreadyFilled(diagnostic, 'volume', room.id);
                        }
                        if (isAlreadyUsed) {
                            break;
                        }
                    }
                    if (isAlreadyUsed) {
                        msg =
                            '<div class="warn">Attention, cette pièce a déjà été renseignée dans un diagnostic.</div>Êtes-vous sûr de vouloir la supprimer ?';
                    }
                }

                this.confirmationService.confirmWarn(msg, () => {
                    const index = this.currentStair.volumes.indexOf(room);
                    if (index > -1) {
                        this.currentStair.volumes.splice(index, 1);

                        // Suppression des commentaires et des images rattachées, si présentes
                        room.commentairesId.forEach((comId) => {
                            const com = this.intervention.commentaires.find((c) => c.id === comId);
                            if (com && com.imageId) {
                                // Suppression de l'image
                                this.cnSpinnerService
                                    .withSpinner(
                                        this.interventionFileService.deleteInterventionFileAndFile(
                                            this.intervention.id,
                                            this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                                            com.id,
                                            TypeReferenceFichier.PHOTO_COMMENTAIRE,
                                            com.imageId
                                        )
                                    )
                                    .pipe(takeUntil(this.ngUnsubscribe))
                                    .subscribe();
                            }
                            this.intervention.commentaires.splice(this.intervention.commentaires.indexOf(com), 1);
                        });
                        this.createRoomIndexes();
                        this.cnSpinnerService
                            .withSpinner(
                                this.interventionService.updateIntervention(this.intervention),
                                'Suppression en cours...'
                            )
                            .subscribe(() => {
                                this.notificationService.success('La pièce a bien été supprimée.');
                                this.checkValidity();
                            });
                    }
                });
            });
    }

    /**
     * Copier la pièce sélectionnée dans le même étage.
     */
    copyRoom(room: Volume) {
        const newRoom = { ...room };
        newRoom.id = MongoUtils.generateObjectId();
        newRoom.nom = ListUtils.createUniqueName(
            this.currentStair.volumes.map((volumeTemp) => volumeTemp.nom),
            room.nom
        );
        newRoom.spaceId = null;
        newRoom.usageId = room.usageId;
        newRoom.commentairesId = [];
        newRoom.elementsAControler = [];
        room.elementsAControler.forEach((el) => {
            const newElement = { ...el };
            newElement.id = MongoUtils.generateObjectId();
            newRoom.elementsAControler.push(newElement);
        });
        this.currentStair.volumes.push(newRoom);
        this.createRoomIndexes();
    }

    /**
     * Drag and drop : Déplacement des étages pour les réordonner.
     */
    dropStair(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.bienSelected.description, event.previousIndex, event.currentIndex);
        // On remet à jour les indexes de niveaux
        this.bienSelected.description.forEach((niv) => (niv.index = this.bienSelected.description.indexOf(niv)));
        if (this.viewMode === 'plan') {
            moveItemInArray(this.bienBuilding.storeys, event.previousIndex, event.currentIndex);
        }
    }

    /**
     *  Drag and drop : Ajouter une pièce pré-équippée dans l'étage courant.
     */
    dropRoom(event: CdkDragDrop<Volume[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            // Si undefined, c'est un élément et non un volume donc rien à faire là
            if (event.item.data.piecesPreequipeesConfigs) {
                const nouveauVolume = new Volume();
                nouveauVolume.usageId = event.item.data.id;
                nouveauVolume.nom = ListUtils.createUniqueName(
                    this.currentStair.volumes.map((volumeTemp) => volumeTemp.nom),
                    event.item.data.nom
                );
                nouveauVolume.valeursParametres = { ...event.item.data.valeursDefautParametres };

                // Si la pièce déposée provient de la liste de pièce prééquipée, et qu'elle contient bien des équipements,
                // on les rajoute lors du drop
                if (
                    event.previousContainer.id === 'preEquippedRoomList' &&
                    event.item.data.piecesPreequipeesConfigs.length
                ) {
                    event.item.data.piecesPreequipeesConfigs[0].idsTypesElementsAControler.map((it) => {
                        const typeElementAControler = this.typesElementsAControlerById.get(it);
                        const nouvelElement = new ElementAControler();
                        nouvelElement.nom = typeElementAControler.nom;
                        nouvelElement.codeBim = typeElementAControler.codeBim;
                        nouvelElement.idTypeElementAControler = typeElementAControler.id;
                        nouvelElement.valeursParametres = { ...typeElementAControler.valeursDefautParametres };
                        nouvelElement.typesPrestationsAssocie = [...typeElementAControler.typesPrestationsAssocie];
                        nouveauVolume.elementsAControler.push(nouvelElement);
                    });
                }

                this.currentStair.volumes.push(nouveauVolume);
                this.createRoomIndexes();
                this.checkValidity();
            }
        }
    }

    /**
     *  Drag and drop : Ajouter un équipement dans une pièce de l'étage courant.
     */
    dropEquipment(event: CdkDragDrop<TypeElementAControler[]>) {
        DragndropUtils.drop(event);
    }

    /**
     * Prédicat interdisant de déposer des items dans une liste.
     */
    noReturnPredicate() {
        return false;
    }

    /**
     * Prédicat interdisant de déposer un typeElementÀContrôler dans une liste de volumes.
     */
    onlyPreEquippedRoomPredicate(item: CdkDrag) {
        return !!item.data.nom;
    }

    changeNiveau(targetNiveau: Niveau) {
        const index = this.bienSelected.description.indexOf(targetNiveau);
        if (index > -1) {
            this.currentStair = this.bienSelected.description[index];
            this.volumeCache = this.currentStair.volumes.find((it) => it.volumeCache);
        }
    }

    zoom(zoomIn: boolean) {
        this.viewMap.zoom(zoomIn);
    }

    centerCamera() {
        this.viewMap.centerCamera();
    }

    openEditor() {
        let toUrl = `/interventions/${this.intervention.id}/bien/${this.bienSelected.id}/editor`;
        if (this.intervention.exigenceDescription === ExigenceDescription.CROQUIS) {
            toUrl += '/map/sketch';
        }
        if (this.bienSelected.description.length && this.bienSelected.jsonPlan === undefined) {
            this.editorHandler.navigateToEditor(toUrl, null);
        } else {
            this.editorHandler.navigateToEditor(toUrl, this.activatedRoute);
        }
    }

    viewMapEvent(event: any) {
        if (event.event === 'selection_change' && event.value) {
            const selected = event.value[0];
            if (selected.area) {
                let volumeSelected;
                if (selected.outside) {
                    // On retourne au détail du niveau
                    this.backToPieces();
                } else {
                    volumeSelected = this.currentStair.volumes.find((it) => it.spaceId === event.value[0].ID);
                }
                if (volumeSelected) {
                    this.changeCurrentRoomMap(volumeSelected);
                }
            } else if (selected.object) {
                // recup volume de l'équipement pour currentvolume
                const volumeSelected = this.currentStair.volumes.find(
                    (vol) => !!vol.elementsAControler.find((el) => el.objectId === selected.ID)
                );
                if (volumeSelected) {
                    this.changeCurrentRoomMap(volumeSelected);
                }
            }
        }
    }

    changeCurrentStairMap(niveauSelected: BaseObject) {
        const stairSelected = this.bienSelected.description.find((it) => it.id === niveauSelected.id);
        this.changeStair(stairSelected);
    }

    changeCurrentRoomMap(piece: Volume) {
        this.detailPieceMode = true;
        this.detailNiveauMode = false;
        this.pieceSelected = piece;
    }

    /**
     * Event lors d'un click sur une pièce dans le menu
     * @param piece
     */
    onClickChangeCurrentRoomMap(piece: Volume) {
        this.changeCurrentRoomMap(piece);
        // Nécessaire de bien l'assigner en dehors de la fonction changeCurrentRoomMap,
        // car cette dernière est également utilisée dans viewMapEvent
        // Et il ne faut pas réassigner la variable externalSelectedVolume dans ce cas là.
        this.externalSelectedVolume = piece;
    }

    backToPieces() {
        this.detailPieceMode = false;
        this.detailNiveauMode = true;
        this.pieceSelected = undefined;
        this.externalSelectedElement = undefined;
    }

    editRoomParam(event: Event, piece: Volume) {
        event.stopPropagation();
        return this.matDialog
            .open(AddVolumeModalComponent, {
                data: {
                    niveau: this.currentStair,
                    volumeToEdit: piece,
                    readonlyName: true,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.interventionService.updateIntervention(this.intervention).subscribe(
                        () => {
                            this.checkValidity();
                            this.cnSpinnerService.hide();
                        },
                        () => this.cnSpinnerService.hide()
                    );
                }
            });
    }

    /**
     * Event lors d'un click sur un objet dans le menu de droite
     * @param pointDeControleElement
     * @param $event
     */
    onClickCardEquipment(pointDeControleElement: ElementAControler, $event) {
        this.externalSelectedElement = pointDeControleElement;
    }

    onClickBtnReloadBien(reloadOk: boolean) {
        if (reloadOk) {
            this.relationInterventionBienService.synchroniserRelation(this.relationBienPrincipal.id, (b) =>
                this.majRelationAfterSynchronize(b)
            );
        } else {
            this.relationBienPrincipal.bien.hasNewVersionInRelationInterventionBien = false;
            this.relationBienPrincipal.bien.dateModificationBien = moment().format(DATE_FORMAT);
        }
    }

    private majRelationAfterSynchronize(newRelation: RelationInterventionBien) {
        if (newRelation) {
            this.relationBienPrincipal = newRelation;
            this.intervention.relationInterventionBiens.map((relation) =>
                relation.isBienPrincipal ? (relation.bien = newRelation.bien) : relation
            );
            this.bienSelected = newRelation.bien;
            this.bienChanged();
        }
    }

    /**
     * Permet de faire fonctionner le drag and drop au sein du ngFor de la liste de pièces.
     * L'équipement / pièce préselectionnée pourra alors être dropée dans la bonne pièce.
     */
    private createRoomIndexes() {
        this.roomIndexes = [];
        if (this.currentStair && this.currentStair.volumes.length > 0) {
            for (let i = 0; i < this.currentStair.volumes.length; i++) {
                this.roomIndexes.push(`room_${i}`);
            }
        }
    }

    /**
     * En plus des équipements contenus dans le référentiel, on liste également les équipements customs créés par le user et utilisés dans la description de ce bien.
     * On ne garde que la liste d'équipements distincts, triés.
     * Les équipements customs sont flagués, ce qui permet d'afficher le bouton editEquipement.
     */
    private getEquipments() {
        this.typesElementsAControler = [];
        if (this.bienSelected.typesElementsAControler) {
            this.typesElementsAControler = this.typesElementsAControler.concat(
                this.bienSelected.typesElementsAControler
            );
            this.typesElementsAControler.forEach((e) => (e.custom = true));
        }
        this.typesElementsAControler = this.typesElementsAControler
            .concat(this.typesElementsAControlerFromReferentiel.filter((it) => !it.abstrait))
            .sort((c1, c2) => c1.nom.localeCompare(c2.nom));
    }

    /**
     * Etat VALID s'il y a au moins une pièce (volume) dans la description, INVALID autrement
     */
    private checkValidity() {
        this.checkValidityTabService
            .checkValidityDescription(this.bienSelected)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((etat) => {
                const suffix = BienUtils.getSuffixBien(this.bienSelected, this.intervention.relationInterventionBiens);
                const code = this.route.snapshot.data['code'] + suffix;
                this.etatProgressionService.updateIntervention(code, etat, this.intervention);
            });
    }

    private loadBuilding() {
        if (this.bienSelected && this.bienSelected.jsonPlan) {
            this.bienBuilding = cn_building.unserialize(JSON.parse(this.bienSelected.jsonPlan));
        } else {
            this.bienBuilding = null;
        }
    }

    mergeNiveau(currentNiveau: Niveau) {
        return this.matDialog
            .open<ModalTransfertUsageComponent, TransfertUsageModalData, ModalTransfertUsageSelection>(
                ModalTransfertUsageComponent,
                {
                    data: {
                        typeAssociation: TypeAssociation.NIVEAU,
                        niveaux: this.bienSelected.description,
                        sourceElement: currentNiveau,
                    },
                }
            )
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                switchMap((result) => {
                    return this.mergeBienService
                        .mergeNiveaux(this.bienSelected, currentNiveau, result.niveau, this.intervention)
                        .pipe(map(() => result.niveau));
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe((targetNiveau) => {
                this.changeNiveau(targetNiveau);
            });
    }

    ngOnDestroy() {
        // Mise à jour du diagnostic POLL-COR-104
        if (this.categoryFilter === 'POLLUANT_ETUDE_SITUATION') {
            combineLatest([
                this.interventionService.getCurrentIntervention(),
                this.diagnosticService.getCurrentDiagnostic(),
            ])
                .pipe(first())
                .subscribe(([intervention, diagnostic]) => {
                    if (diagnostic?.etat === 'EN_COURS') {
                        PolluantService.initPolluant(
                            diagnostic.pointsDeControleBiens,
                            intervention.relationInterventionBiens
                        );
                    }
                });
        }
    }
}

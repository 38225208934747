import { Component, EventEmitter, Input, OnInit, Optional, Output, Pipe, PipeTransform, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

const WIND_ICON = `
<?xml version="1.0" encoding="utf-8"?><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 74.78" style="enable-background:new 0 0 122.88 74.78" xml:space="preserve"><g><path d="M28.69,53.38c-1.61,0-2.91-1.3-2.91-2.91c0-1.61,1.3-2.91,2.91-2.91h51.37c0.21,0,0.42,0.02,0.62,0.07 c1.84,0.28,3.56,0.8,5.1,1.63c1.7,0.92,3.15,2.19,4.27,3.89c3.85,5.83,3.28,11.24,0.56,15.24c-1.77,2.61-4.47,4.55-7.45,5.57 c-3,1.03-6.32,1.13-9.32,0.03c-4.54-1.66-8.22-5.89-8.76-13.55c-0.11-1.6,1.1-2.98,2.7-3.09c1.6-0.11,2.98,1.1,3.09,2.7 c0.35,4.94,2.41,7.56,4.94,8.48c1.71,0.62,3.67,0.54,5.48-0.08c1.84-0.63,3.48-1.79,4.52-3.32c1.49-2.19,1.71-5.28-0.61-8.79 c-0.57-0.86-1.31-1.51-2.18-1.98c-0.91-0.49-1.97-0.81-3.13-0.99H28.69L28.69,53.38z M15.41,27.21c-1.61,0-2.91-1.3-2.91-2.91 c0-1.61,1.3-2.91,2.91-2.91h51.21c1.17-0.18,2.23-0.5,3.14-0.99c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79 c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7 c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57 c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H15.41 L15.41,27.21z M2.91,40.3C1.3,40.3,0,38.99,0,37.39c0-1.61,1.3-2.91,2.91-2.91h107.07c1.17-0.18,2.23-0.5,3.13-0.99 c0.87-0.47,1.61-1.12,2.18-1.98c2.32-3.51,2.09-6.6,0.61-8.79c-1.04-1.53-2.68-2.69-4.52-3.32c-1.81-0.62-3.78-0.7-5.48-0.08 c-2.52,0.92-4.59,3.54-4.94,8.48c-0.11,1.6-1.49,2.81-3.09,2.7c-1.6-0.11-2.81-1.49-2.7-3.09c0.54-7.66,4.22-11.89,8.76-13.55 c3-1.09,6.32-0.99,9.32,0.03c2.98,1.02,5.68,2.97,7.45,5.57c2.72,4,3.29,9.41-0.56,15.24c-1.12,1.7-2.57,2.97-4.27,3.89 c-1.54,0.83-3.26,1.35-5.1,1.63c-0.2,0.04-0.41,0.07-0.62,0.07H2.91L2.91,40.3z"/></g></svg>
`;

const WINDSOCK_ICON = `
<?xml version="1.0" encoding="UTF-8"?><svg width="752pt" height="752pt" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg"> <defs><clipPath id="a"> <path d="m168 139.21h120v473.58h-120z"/></clipPath> </defs> <path d="m346.4 326.16 88.797-11.098v-144.5l-88.797-11.098z"/> <path d="m540.63 183.73-46.23-5.7695v129.73l46.234-5.7734c7.3984-0.94531 12.961-7.25 12.961-14.711v-88.793c0-7.4609-5.5625-13.766-12.965-14.684z"/> <g clip-path="url(#a)"><path d="m184.59 139.42c-0.35547-0.03125-0.62109-0.20703-0.97656-0.20703-0.94922 0-1.7773 0.35547-2.6641 0.53125-0.73828 0.14844-1.4805 0.20703-2.1914 0.44531-1.2734 0.44531-2.3672 1.1562-3.4648 1.8945-0.47266 0.32422-1.0352 0.44531-1.4805 0.82812-0.11328 0.089844-0.14453 0.26562-0.26172 0.35547-1.0664 1.0078-1.8672 2.25-2.5781 3.5234-0.26562 0.44141-0.67969 0.79688-0.88672 1.3008-0.79688 1.8047-1.2734 3.7891-1.2734 5.9219v443.98c0 8.1641 6.6328 14.797 14.801 14.797s14.801-6.6328 14.801-14.801v-253.34l88.797-11.098-0.003906-181.5-101.76-12.727c-0.29297-0.03125-0.55859 0.085938-0.85547 0.085938z"/> </g></svg>
`;

@Component({
    selector: 'compass-form',
    templateUrl: './compass-form.component.html',
    styleUrls: ['./compass-form.component.scss'],
})
export class CompassForm implements OnInit, ControlValueAccessor {
    constructor(
        @Self()
        @Optional()
        private ngControl: NgControl
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public _value: string;

    @Input()
    set value(value: string) {
        this.setValue(value);
    }

    get value() {
        return this._value;
    }

    @Output()
    valueChange: EventEmitter<string> = new EventEmitter<string>();

    ngOnInit(): void {
        if (this.value != undefined && this.value != '') {
            document.getElementById(this.value).classList.add('selected-direction');
        }
    }

    select(event) {
        if (document.getElementsByClassName('selected-direction').length != 0) {
            document.getElementsByClassName('selected-direction')[0].classList.remove('selected-direction');
        }

        event.target.parentElement.classList.add('selected-direction');
        let direction = event.target.parentElement.id;
        this.valueChange.emit(direction);
        this.setValue(direction);
    }

    private setValue(value: string) {
        if (value == null) {
            return;
        }
        let changed = false;
        if (this._value != value) {
            changed = true;
        }
        this._value = value;
        if (changed) {
            this.onChange(value);
            this.valueChange.emit(this._value);
        }
    }

    onChange(value) {}

    private onTouched() {}

    writeValue(obj: any): void {
        this._value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}

@Pipe({
    name: 'formatWindDirection',
})
export class MeteoPipe implements PipeTransform {
    transform(value: string): any {
        switch (value) {
            case 'direction-n':
                return 'N';
            case 'direction-no':
                return 'NO';
            case 'direction-ne':
                return 'NE';
            case 'direction-e':
                return 'E';
            case 'direction-o':
                return 'O';
            case 'direction-so':
                return 'SO';
            case 'direction-se':
                return 'SE';
            case 'direction-s':
                return 'S';
            default:
                return '';
        }
    }
}

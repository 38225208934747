/**
 * Encapsulation d'une prestation et du diagnostic associé
 */
import { TypePrestation } from './type-prestation.model';
import { RegleRef } from './regle.model';
import { ReferencePrestation } from './reference-prestation.model';
import { ReportReference } from './rapport.model';
import { PresetPhoto } from './diagnostic.model';

export class Prestation {
    id: string;

    /**
     * Type de prestation
     */
    typePrestation: TypePrestation;

    /**
     * Référence de prestation
     */
    referencePrestation: ReferencePrestation;

    /**
     * Option plan ?
     */
    optionPlan = false;

    /**
     * Liste des règles de références liées
     * au type de prestation
     */
    regles: RegleRef[] = [];

    /**
     * Liste des données des bloque de rapport
     */
    reportReferences: ReportReference[] = [];

    presetsReportagePhotosAuto: PresetPhoto[] = [];

    presetsExportScreenshot: string[] = [];
}

//***********************************************************************************
//***********************************************************************************
//**** fh_view : Abstract base class for a 3D view
//***********************************************************************************
//***********************************************************************************

import {Color, CullFaceNone, Raycaster, Vector2, Vector3, WebGLRenderer} from 'three';
import { fh_clone, fh_dot } from './fh_vector';
import {TouchController} from "./touch_controller";

export var FH3D_MAX_LOD = 3;
export var dummy_target = new Vector3();

//***********************************************************************************
//**** Class view
//***********************************************************************************
export class fh_view {
	//*****************************************************
	//*** Constructor
	constructor(div_id_or_container, scene, svg=false) {

		this._touchController = new TouchController(this);
		if (div_id_or_container instanceof Element) {
		    this._container = div_id_or_container;
        } else {
            this._container = document.getElementById(div_id_or_container);
        }
        if (!this._container) {
            throw Error("must provide valid html container or id");
        }
		this._container_id = this._container["id"];
		this._scene = scene;

		this._events = {};

		var obj = this;
		this._container.addEventListener('mousemove', function(ev) {
			if (!obj._mouse_management) return;
			// NB : ici pas de preventDefault ou stopPropagation, sinon ça fait planter le resize de la zone 2D/3D dans CNBIM
            // à priori, le preventDefault ne sert pas à grand chose ici puisque CNMAP n'a pas de raison d'empêcher cet évènement d'être propagé
			obj.manage_mouse_move(ev);
		}, false);

		this._container.addEventListener('mousedown', function(ev) {
			if (!obj._mouse_management) return;
			ev.preventDefault();
			ev.stopPropagation();
			obj.manage_mouse_down(ev);
		}, false);

		this._container.addEventListener('dblclick', function(ev) {
			if (!obj._mouse_management) return;
			ev.preventDefault();
			ev.stopPropagation();
			obj.manage_double_click(ev);
		}, false);

		this._container.addEventListener('mouseup', function(ev) {
			if (!obj._mouse_management) return;
			ev.preventDefault();
			ev.stopPropagation();
			obj.manage_mouse_up(ev);
		}, false);

		this._container.addEventListener('mouseleave', function(ev) {
			if (!obj._mouse_management) return;
			ev.preventDefault();
			ev.stopPropagation();
			obj.manage_mouse_leave(ev);
		}, false);

		this._container.addEventListener('wheel', function(ev) {
			if (!obj._mouse_management) return;
			ev.preventDefault();
			ev.stopPropagation();
			obj.manage_mouse_wheel(ev);
		}, false);

		this._container.addEventListener('resize', function(ev) {
			if (!obj._mouse_management) return;
			ev.preventDefault();
			ev.stopPropagation();
			obj.manage_window_resize();
		}, false);

		this._container.addEventListener('touchstart', function(ev) {
			if (!obj._mouse_management) return;
			if (ev.cancelable) {
			    ev.preventDefault();
            }
			ev.stopPropagation();
			obj.manage_touch_start(ev);
		}, false);

		this._container.addEventListener('touchmove', function(ev) {
			if (!obj._mouse_management) return;
            if (ev.cancelable) {
                ev.preventDefault();
            }
			ev.stopPropagation();
			obj.manage_touch_move(ev);
		}, false);

		this._container.addEventListener('touchend', function(ev) {
			if (!obj._mouse_management) return;
            if (ev.cancelable) {
                ev.preventDefault();
            }
			ev.stopPropagation();
			obj.manage_touch_end(ev);
		}, false);

		this._container.addEventListener('touchleave', function(ev) {
			if (!obj._mouse_management) return;
            if (ev.cancelable) {
                ev.preventDefault();
            }
			ev.stopPropagation();
			obj.manage_touch_end(ev);
		}, false);

		this._container.addEventListener('touchcancel', function(ev) {
			if (!obj._mouse_management) return;
			obj.manage_touch_end(ev);
            if (ev.cancelable) {
                ev.preventDefault();
            }
			ev.stopPropagation();
		}, false);

		this._container.addEventListener('contextmenu', function (ev) {
			if (!obj._mouse_management) return;
			ev.preventDefault();
			ev.stopPropagation();
			return false;
		}, false);

		this._renderer = new WebGLRenderer({ antialias: true });
		this._renderer.autoClear = false;
		this._renderer.autoClearColor = false;
		this._renderer.autoClearDepth = false;

		this._renderer.setClearColor(new Color(1,1,1),1);
		this._render_width = 0;
		this._render_height = 0;
		this._renderer.setPixelRatio(window.devicePixelRatio);
		this._renderer.domElement.style.width = '100%';
		this._renderer.domElement.style.height = '100%';

		this._renderer.setFaceCulling( CullFaceNone );

		this._container.appendChild(this._renderer.domElement);
		this._raycaster = new Raycaster();

		this._default_orbit = true;
		this._mouse_operation = -1;
		this._mouse_position = new Vector2(0,0);
		this._mouse_impact = null;
		this._last_mouse_down = [0,0];

		this._animation = false;
		this._start_position = new Vector3();
		this._end_position = new Vector3();
		this._start_target = new Vector3();
		this._end_target = new Vector3();
		this._start_scale = 1;
		this._end_scale = 1;
		this._animation_start = null;
		this._animation_duration = 0;
		this._start_clipping_plane = 1;
		this._end_clipping_plane = 1;
		this._rendering_timeout = 500;
		this._render_lods = true;
		this._need_refresh = false;
		this._need_render = false;

		this._mouse_management = true;

		this._date = new Date();
		this._average_render = [];
		while (this._average_render.length <= FH3D_MAX_LOD)
			this._average_render.push(0);
		this._rendered_level = -1;
		this._camera_animation = true;
		this._animate();

		this._monitor_perf = false;
		this._last_frame_chrono = new Date();
		this._average_durations = [0,0,0,0,0];
		this._average_date = [new Date(),new Date()];

		this._forced_width = 0;
		this._forced_height = 0;
        this._camera = null;
        this._clipping_plane_position = null;
        this._view = null;

	}

	//***********************************************************************************
	//**** Events
	//***********************************************************************************

	on(ev, fun) {
		if (typeof(this._events[ev]) == 'undefined')
			this._events[ev] = [fun];
		else
			this._events[ev].push(fun);
	}

	unbind(ev) {
		if (typeof(this._events[ev]) != 'undefined')
			this._events[ev] = [];
	}

	call(ev, arg=null) {
		var funs = this._events[ev];
		if (typeof(funs) != 'object') return;
		for (var j in funs)
			funs[j](arg);
	}

	//*****************************************************
	//**** Serialisation
	//*****************************************************
	serialize() {
		return {};
	}

	unserialize(json) {

	}

	//*****************************************************
	//**** Animation mode. Default is true
	//*****************************************************
	get_animation_mode() {
		return this._camera_animation;
	}

	set_animation_mode(v) {
		this._camera_animation = v;
	}

	get_camera() {
		return this._camera;
	}

	update_camera() {
		
	}

	//*****************************************************
	//**** mouse event management. Default is true;
	//*****************************************************
	get_mouse_management() {
		return this._mouse_management;
	}

	set_mouse_management(v) {
		this._mouse_management = v;
	}

	//*****************************************************
	//**** Sets default navigation to orbit
	//*****************************************************
	set_default_orbit(v) {
		this._default_orbit = v;
	}
	get_default_orbit() {
		return this._default_orbit;
	}

	//*****************************************************
	//**** Called when scene changes
	//*****************************************************
	update_scene() {

	}

	//*****************************************************
	//**** zoom
	//*****************************************************

	zoom(zoom_in) {
		var ev = {};
		var container_rect = this._container.getBoundingClientRect();
		ev.clientX = this._container.clientWidth/2 + container_rect.left;
		ev.clientY = this._container.clientHeight/2 + container_rect.top;
		ev.deltaY = (zoom_in)?-1:1;
		this.manage_mouse_wheel(ev);
	}

	//*****************************************************
	//**** Sets perf feedback on / off
	//*****************************************************

	set_perf_feedback(on) {
		this._monitor_perf = on;
		this._last_frame_chrono = new Date();

		//*** already exists ?
		if (typeof(this._perf_feedback_id) != 'undefined')
		{
			if (on)
			    document.querySelector("#" + this._perf_feedback_id)["style"].display = "block";
			else
                document.querySelector("#" + this._perf_feedback_id)["style"].display = "none";
			return;
		}

		//*** create div
		if (!on) return;

		this._perf_feedback_id = this._container_id + "_perf_feedback";



		var html = "<table><tr><th>Value</th><th>last</th><th>average</th>";
		var labels = ["refresh lapse","animate lapse","render lapse","render duration","geometry duration","move duration"];
		this._average_durations = [];
		this._last_date = [];
		for (var i in labels)
		{
			this._average_durations.push(0);
			this._last_date.push(new Date());
			html += "<tr>";
			html += "<td>" + labels[i] + ":</td>";
			html += "<td id='" + this._perf_feedback_id + "_" + i + "'></td>";
			html += "<td id='" + this._perf_feedback_id + "_average_" + i + "'></td>";
			html += "</tr>";
		}
		html += "</table>";
        var div = document.createElement('div');
        div.innerHTML = html
        div.style.position = 'absolute';
        div.style.left = '10px';
        div.style.bottom = '10px';
        this._container.append(div);
	}

	_update_duration(index, previous=null) {
		if (!this._monitor_perf) return;
		var ch = new Date();
		var duration = 0;
		if (previous)
			duration = ch.getTime() - previous.getTime();
		else
		{
			duration = ch.getTime() - this._last_date[index].getTime();
			this._last_date[index] = ch;
		}

		this._average_durations[index]= this._average_durations[index]*0.9 + 0.1 * duration;
        document.querySelector("#" + this._perf_feedback_id + "_" + index).innerHTML = duration.toString();
        document.querySelector("#" + this._perf_feedback_id + "_average_" + index).innerHTML = this._average_durations[index].toFixed(0);
	}

	//*****************************************************
	//**** Capture
	//*****************************************************
	to_image_url(width=this._render_width, height=this._render_height)
	{
		this._forced_width = width;
		this._forced_height = height;
		this.manage_window_resize();

		this.perform_animation();

		this._renderer.preserveDrawingBuffer = true;

		this._scene._camera_light.position.copy(this._camera.position);
		if (this._renderer.clippingPlanes.length > 0)
		{
			var z = this._scene._bounding_box.min.z + (0.05 + this._clipping_plane_position) * ( this._scene._bounding_box.max.z -  this._scene._bounding_box.min.z);
			this._renderer.clippingPlanes[0].setComponents(0,0,-1,z);
		}
		this.update_visibility_list();

		for(var key in this._scene._product_types)
		{
			var pt = this._scene._product_types[key];
			pt.visible = pt.tmp_visible;
		}

		this._renderer.clearColor();
		this._renderer.clearDepth();
		this.render_geometry();
		this._renderer.preserveDrawingBuffer = false;

		this._forced_width = 0;
		this._forced_height = 0;

		this.refresh_rendering();

		this._ground_event = false;

		return this._renderer.domElement.toDataURL("image/png");
	}

	//*****************************************************
	//**** Callbacks for navigation
	//*****************************************************

	//*****************************************************
	//*** returns viewport size
	get_viewport_size() {
		return [this._container.clientWidth,this._container.clientHeight];
	}
	
	
	//*****************************************************
	//*** returns ground controller
	get_ground_controller() {
		return this._scene._ground_controller;
	}
	
	//*****************************************************
	//*** Window resize
	manage_window_resize() {
		this._render_width = (this._forced_width)?this._forced_width:this._container.clientWidth;
		this._render_height = (this._forced_height)?this._forced_height:this._container.clientHeight;

		this._renderer.setSize(this._render_width, this._render_height, false);
	}

	//*****************************************************
	//*** Mouse down
	manage_mouse_down(ev) {
		this._touchController.mouseDown(ev);
		this._last_mouse_down[0]=ev.clientX;
		this._last_mouse_down[1]=ev.clientY;
		this._mouse_position = this.read_mouse_position(ev.clientX,ev.clientY);
		this._mouse_impact = this.get_mouse_impact(this._mouse_position);
		
		if (ev.buttons&1)
		{
			const ctrl_key = (typeof(ev.ctrlKey) != 'undefined')?ev.ctrlKey:false;

			if (this._scene._ground_controller && this._scene._ground_controller.visible && this._scene._ground_controller.grab(this._mouse_position,this,ctrl_key))
				this._mouse_operation = 2;
			else if (this._default_orbit)
				this._mouse_operation = 0;
			else
				this._mouse_operation = 1;
		}
		else
			this._mouse_operation = 1;
	}

	//*****************************************************
	//*** Mouse up
	manage_mouse_up(ev) {
		this._touchController.mouseUp(ev);
		if (this._mouse_operation == 2)
			this._scene._ground_controller.drop(this.read_mouse_position(ev.clientX, ev.clientY),this);
		this._mouse_operation = -1;
		if (this._scene._selection_mode == 0) return;
		if (Math.abs(ev.clientX-this._last_mouse_down[0]) + Math.abs(ev.clientY-this._last_mouse_down[1]) > 10)
			return;

		var object = this.get_mouse_object(this._mouse_position);
		if (object == null) return;

		this._scene.select_object(object.BIMID,ev.ctrlKey,true);
	}

	//*****************************************************
	//*** Double click
	manage_double_click(ev) {
		var object = this.get_mouse_object(this.read_mouse_position(ev.clientX,ev.clientY));

		if (typeof(this._scene._cb_double_click) == 'function')
		{
			if (object == null)
				this._scene._cb_double_click(null);
			else
			{
				console.log("double click",object);
				this._scene._cb_double_click(object.BIMID);
			}
		}
	}

	//*****************************************************
	//*** Mouse leave
	manage_mouse_leave(ev) {
		if (this._mouse_operation == 2)
			this._scene._ground_controller.drop(this.read_mouse_position(ev.clientX, ev.clientY),this);
		this._mouse_operation = -1;
		this._scene.set_mouseover_object(null);
	}

    //*****************************************************
    //*** Mouse wheel
    manage_mouse_wheel(ev) {

    }

	//*****************************************************
	//*** Mouse operations
	manage_mouse_move(ev) {
		var ch0= (this._monitor_perf)?new Date():false;

		var new_mouse = this.read_mouse_position(ev.clientX, ev.clientY);
		this._dxm = new_mouse.x - this._mouse_position.x;
		this._dym = new_mouse.y - this._mouse_position.y;
		this._mouse_position = new_mouse;

		//*** Mouseover management
		if (this._mouse_operation < 0)
		{
			if (this._scene._ground_controller && this._scene._ground_controller.visible && this._scene._ground_controller.passive_move(this._mouse_position,this))
			{
				this._scene.set_mouseover_object(null);
			}
			else
			{
				var object = this.get_mouse_object(this._mouse_position);
				this._scene.set_mouseover_object(object);
			}
		}
		else if (this._mouse_operation == 2)
			this._scene._ground_controller.drag(this._mouse_position,this)
		if (ch0)
			this._update_duration(5,ch0);
	}

	//*****************************************************
	//*** Touch operations
	manage_touch_start(ev) {
		return this._touchController.touchStart(ev);
	}

	manage_touch_move(ev) {
		return this._touchController.touchMove(ev);
	}

	manage_touch_end(ev) {
		return this._touchController.touchEnd(ev);
	}

	//********************************************************
	//*** transform mouse coordinate of an event into screen coordinates
	read_mouse_position(x, y) {
		var container_rect = this._container.getBoundingClientRect();
		return new Vector3(2 * (x - container_rect.left) / this._container.clientWidth - 1,1 - 2 * (y - container_rect.top) / this._container.clientHeight);
	};

	//*** apply mouse position to camera
	set_raycaster(mouse)
	{
		this._raycaster.setFromCamera(mouse, this._camera);
		this._raycaster.near = 0;
		this._raycaster.far = 1000000;

		if (this._renderer.clippingPlanes.length > 0  && this._scene._bounding_box)
		{
			var orz = this._raycaster.ray.origin.z;
			var dirz = this._raycaster.ray.direction.z;
			var z = this._scene._bounding_box.min.z + (0.05 + this._clipping_plane_position) * ( this._scene._bounding_box.max.z -  this._scene._bounding_box.min.z);
			if (dirz > 0 && orz > z)
				return false;
			if (dirz > 0 && orz < z)
				this._raycaster.far = (z - orz)/dirz;
			if (dirz < 0 && orz > z)
				this._raycaster.near = (z - orz)/dirz;
		}

		return true;
	}

	//********************************************************
	/** Compute what's visible at a given point of the screen
	* @param {number[]} screen_pos : screen position, in pixels
	* @return {{object:object,position:number[],normal:number[]}}
	*/
	get_screen_object(screen_pos)
	{ 
		const mouse = new Vector3(2 * screen_pos[0] / this._container.clientWidth - 1,1 - 2 * screen_pos[1] / this._container.clientHeight);
		if (!this.set_raycaster(mouse)) return null;

		this.update_visibility_list();
		var intersects = this._raycaster.intersectObjects(this._scene._scene.children, true);
		var intersection = null;
		for (var i=0;i<intersects.length;i++)
		{
			if (intersects[i].face) 
			{
				intersection = intersects[i];
				break;
			}
		}
		if (!intersection) 
			return null;
		
		const impact = {object:intersection.object.parent, position:[intersection.point.x,intersection.point.y,intersection.point.z], normal:[0,0,0]};
		impact.normal[0] = intersection.face.normal.x;
		impact.normal[1] = intersection.face.normal.y;
		impact.normal[2] = intersection.face.normal.z;
		const incident = [this._raycaster.ray.direction.x,this._raycaster.ray.direction.y,this._raycaster.ray.direction.z];
		if (fh_dot(incident,impact.normal) > 0)
		{
			for (var k=0;k<3;k++) impact.normal[k] *= -1;
		}
		return impact;
	}

	//********************************************************
	/** Compute what's visible at a given point of the screen
	* @param {number[]} screen_pos : screen position, in pixels
	* @return {Array<{object:object,position:number[],normal:number[]}>}
	*/
	get_screen_objects(screen_pos)
	{ 
		const impacts = [];
		const mouse = new Vector3(2 * screen_pos[0] / this._container.clientWidth - 1,1 - 2 * screen_pos[1] / this._container.clientHeight);
		if (!this.set_raycaster(mouse)) return impacts;

		this.update_visibility_list();
		var intersects = this._raycaster.intersectObjects(this._scene._scene.children, true);
		for (var i=0;i<intersects.length;i++)
		{
			if (intersects[i].face) 
			{
				var intersection = intersects[i];
				const impact = {object:intersection.object.parent, position:[intersection.point.x,intersection.point.y,intersection.point.z], normal:[0,0,0]};
				impact.normal[0] = intersection.face.normal.x;
				impact.normal[1] = intersection.face.normal.y;
				impact.normal[2] = intersection.face.normal.z;
				const incident = [this._raycaster.ray.direction.x,this._raycaster.ray.direction.y,this._raycaster.ray.direction.z];
				if (fh_dot(incident,impact.normal) > 0)
				{
					for (var k=0;k<3;k++) impact.normal[k] *= -1;
				}
				impacts.push(impact);
			}
		}
		return impacts;
	}
s
	/**
	 * Returns the ray in world corrdinates from a given point.
	 * @param {number[]} screen_pos 
	 * @returns {{origin:number[], direction:number[]}}
	 */
	get_screen_ray(screen_pos) {
		const mouse = new Vector3(2 * screen_pos[0] / this._container.clientWidth - 1,1 - 2 * screen_pos[1] / this._container.clientHeight);
		this.set_raycaster(mouse);
		return {
			origin:[this._raycaster.ray.origin.x,this._raycaster.ray.origin.y,this._raycaster.ray.origin.z],
			direction:[this._raycaster.ray.direction.x,this._raycaster.ray.direction.y,this._raycaster.ray.direction.z]};
	}

	//********************************************************
	//*** Compute intersection of camera ray with scene
	get_mouse_impact(mouse, check_bounding_planes=true)
	{
		if (!this.set_raycaster(mouse)) return false;

		this.update_visibility_list();
		var intersects = this._raycaster.intersectObjects(this._scene._scene.children, true);
		if (intersects.length > 0)
			return intersects[0].point;

		if (!check_bounding_planes) return false;

		//*** try intersection with bounding box planes
		var lambda = -1;
		var ll;
		if (this._raycaster.ray.direction.x > 0.01)
			ll = (this._scene._bounding_box.max.x - this._raycaster.ray.origin.x) / this._raycaster.ray.direction.x;
		else if (this._raycaster.ray.direction.x < -0.01)
			ll = (this._scene._bounding_box.min.x - this._raycaster.ray.origin.x) / this._raycaster.ray.direction.x;
		else ll = 0;
		if (ll > 0 && (lambda < 0 || lambda > ll))
			lambda = ll;

		if (this._raycaster.ray.direction.y > 0.01)
			ll = (this._scene._bounding_box.max.y - this._raycaster.ray.origin.y) / this._raycaster.ray.direction.y;
		else if (this._raycaster.ray.direction.y < -0.01)
			ll = (this._scene._bounding_box.min.y - this._raycaster.ray.origin.y) / this._raycaster.ray.direction.y;
		else ll = 0;
		if (ll > 0 && (lambda < 0 || lambda > ll))
			lambda = ll;

		if (this._raycaster.ray.direction.z > 0.01)
			ll = (this._scene._bounding_box.max.z - this._raycaster.ray.origin.z) / this._raycaster.ray.direction.z;
		else if (this._raycaster.ray.direction.z < -0.01)
			ll = (this._scene._bounding_box.min.z - this._raycaster.ray.origin.z) / this._raycaster.ray.direction.z;
		else ll = 0;
		if (ll > 0 && (lambda < 0 || lambda > ll))
			lambda = ll;

		if (lambda < 0) return false;

		var impact = this._raycaster.ray.direction.clone();
		impact.multiplyScalar(lambda);
		impact.add(this._raycaster.ray.origin);
		return impact;
	}

	//********************************************************
	//*** Checjk if mouse os over a handle
	get_mouse_handle(mouse) {
		if (!this.set_raycaster(mouse)) return null;
		
		var intersects = this._raycaster.intersectObjects(this._scene._scene.children, true);
		if (intersects.length == 0) return null;

		for (var obj = intersects[0].object;obj;obj = obj.parent)
		{
			//if (obj instanceof fh_handle) return obj;
		}
		return null;
	}

	//********************************************************
	//*** Compute impact with first selectable object
	get_mouse_object(mouse)	{

		if (this._scene._selection_mode == 0) return null;
		if (!this.set_raycaster(mouse)) return null;

		this.update_visibility_list();

		var selectable_objects = [];
		if (this._scene._selection_mode == 1)
			selectable_objects = this._scene._flats;
		else if (this._scene._selection_mode == 3)
			selectable_objects = this._scene._products;
		else if (this._scene._current_flat)
			selectable_objects = this._scene._current_flat.children;
		else if (this._scene._all_flats)
			selectable_objects = this._scene._spaces;

		selectable_objects = selectable_objects.filter(obj => obj.selectable === true);

		var intersects = this._raycaster.intersectObjects(selectable_objects, true);
		if (intersects.length == 0) return null;
		var object = intersects[0].object.parent;

		if (typeof(object.bim_type) == "undefined") return null;

		if (this._scene._selection_mode == 1 && object.bim_type == "space")
			object = object.parent;

		if (typeof object == "undefined") return null;

		if (object.selectable == false) return null;
		if (this._scene._selection_mode == 1)
		{
			if (object.bim_type != "flat")  return null;
			return object;
		}
		if (this._scene._selection_mode == 2)
		{
			if (object.bim_type != "space") return null;
			return object;
		}
		if (this._scene._selection_mode == 3)
		{
			if (object.bim_type == "flat" || object.bim_type == "space") return null;
			return object;
		}
		return null;
	}

	//*****************************************************
	//*** update visibility list
	update_visibility_list() {
		for (var i in this._scene._all_meshes)
			this._scene._all_meshes[i].visible=false;

		var lst = this._scene._meshes_by_view[this._view];
		if (typeof lst != 'undefined')
		{
			for (var i in lst)
				lst[i].visible=true;
		}
	}

	//*****************************************************
	//*** refresh rendering
	refresh_rendering() {
		if (this._need_refresh) return;
		this._need_refresh = true;
		this._rendered_level = -1;
        var obj = this;
		if (!this._need_render)
		{
			this._need_render = true;
			window.requestAnimationFrame(function() {obj._animate();});
		}
		this._update_duration(0);
	}

	//*****************************************************
	//*** animate
	_animate() {
		this._update_duration(1);
		this.do_render();
		this._need_refresh = false;
		this._need_render = false;
		if (this._rendered_level != FH3D_MAX_LOD)
		{
            var obj = this;
			this._need_render = true;
			window.requestAnimationFrame(function() {obj._animate();});
		}
	}

	//*****************************************************
	//*** render geometry. Derivate this to render extra data
	render_geometry() {
		this._renderer.render(this._scene._scene, this._camera);
	}

	//*****************************************************
	//*** rendering method
	do_render() {
		//*** end if rendering is done
		if (this._rendered_level == FH3D_MAX_LOD)  return false;
		this.call("render");
		
		if (typeof(this._camera) != 'object') return false;

		//*** end if scene is empty
		if (this._scene._scene == null)
		{
			this._renderer.clearColor();
			this._rendered_level = FH3D_MAX_LOD;
			return false;
		}
		//*** end if a first rendering has been done, but delay not reached.
		if (this._rendered_level >= 0)
		{
			var chrono = new Date();
			if (chrono.getTime() - this._date.getTime() < 500)
			{
				var obj = this;
				return false;
			}
		}

		this.perform_animation();
		this.manage_window_resize();

		var ch0 = this._monitor_perf ? new Date() : false;

		this._scene._camera_light.position.copy(this._camera.position);
		if (this._renderer.clippingPlanes.length > 0)
		{
			var z = this._scene._bounding_box.min.z + (0.05 + this._clipping_plane_position) * ( this._scene._bounding_box.max.z -  this._scene._bounding_box.min.z);
			this._renderer.clippingPlanes[0].setComponents(0,0,-1,z);
		}
		this.update_visibility_list();

		var render_lod = FH3D_MAX_LOD;
		if (this._render_lods && this._rendered_level < 0)
		{
			render_lod = 0;
			for (var i = FH3D_MAX_LOD-1; i>0; i--)
			{
				if (this._average_render[i] >= 100) continue;
				render_lod = i;
				break;
			}
			//console.log(this._average_render);
		}

		//console.log("rendering lod : " + render_lod,this._average_render);

		for(var key in this._scene._product_types)
		{
			var pt = this._scene._product_types[key];
			pt.visible = (pt.tmp_visible && (pt.tmp_LOD <= render_lod));
		}

		var chrono_start = new Date();
		this._renderer.clearColor();
		this._renderer.clearDepth();
		this.render_geometry();
		this._update_duration(4,chrono_start);

		for(var key in this._scene._product_types)
		{
			var pt = this._scene._product_types[key];
			pt.visible = pt.tmp_visible;
		}

		this._rendered_level = render_lod;

		this._date = new Date();

		var chrono_end = new Date();
		var duration = chrono_end.getTime() - chrono_start.getTime();

		for (var i = 0; i<=FH3D_MAX_LOD; i++) this._average_render[i] *= 0.9;
		this._average_render[render_lod] += 0.1 * duration;

		if (this._animation)
		{
            this._rendered_level = -1;
		}

		this._update_duration(3,ch0);
		this._update_duration(2);

		return true;
	}

	//*****************************************************
	//*** start animation
	start_animation() {
	}

	//*****************************************************
	//*** start animation
	perform_animation() {
	}

	//*****************************************************
	//*** animate camera from a given position
	animate_camera(start_camera){

	};

	//*****************************************************
	//*** camera reset
	reset_camera() {
	}

	//***********************************************************************************
	//**** Projection on screen
	world_to_screen (x, y, z) {
		var p = new Vector3(x,y,z);
		p.project(this._camera);
		if (p.z>1) return false;
		return {left:Math.floor(this._render_width * 0.5 * (p.x+1)),top:Math.floor(this._render_height * 0.5 * (-p.y+1))};
	}

	camera_to_screen(p)
	{
		return {left:Math.floor(this._render_width * 0.5 * (p.x+1)),top:Math.floor(this._render_height * 0.5 * (-p.y+1))};
	}
	//***********************************************************************************
	//**** zoom on selection
	zoom_on_selection() {
	}
};

//********************************************************
//*** transforms a cartesian vector into a polar (phi, theta, r) vector.
export function cartesian_to_polar(cartesian) {
	var phi = 0, theta = 0;
	var norm = Math.sqrt(cartesian.x * cartesian.x + cartesian.y * cartesian.y + cartesian.z * cartesian.z);
	var invnorm = 1 / norm;
	var vz = cartesian.z * invnorm;
	if (vz >= 1) {
		theta = 0.5 * Math.PI;
		phi = 0;
	}
	else if (vz <= -1) {
		theta = -0.5 * Math.PI;
		phi = 0;
	}
	else {
		theta = Math.asin(vz);
		var vy = cartesian.x * invnorm / Math.cos(theta);
		if (vy >= 1)
			phi = 0;
		else if (vy <= -1)
			phi = Math.PI;
		else
			phi = Math.acos(vy);
		if (cartesian.y < 0) phi = -phi;
	}
	return new Vector3(phi,theta,norm);
}

//********************************************************
//*** transforms a polar vector into a cartesian
export function polar_to_cartesian(polar) {
	var cos_theta = Math.cos(polar.y);
	return new Vector3(polar.z * cos_theta * Math.cos(polar.x),polar.z * cos_theta * Math.sin(polar.x),polar.z * Math.sin(polar.y));
}

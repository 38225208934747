import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { cn_building, cn_storey, cn_svg_configurator } from '@acenv/cnmap-editor';
import { BuildingService, MM_TO_POINT_IMAGE_PRINT, PlanParam, SvgParameter } from '@acenv/cnmap-angular-editor-lib';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/commons-lib';
import { ResizeObserverEntry } from 'ngx-resize-observer';
import { Bien } from '../../../../model/bien.model';
import { ScreenshotSvg } from '../../../../model/screenshot-svg.model';
import { SVG_FILTER } from '../../../../shared/constants/svg.constants';
import { TypePrestation } from 'src/app/model/type-prestation.model';
import { Espace } from '../../../../model/espace.model';

export interface SvgData {
    biens: Bien[];
    espaces: Espace[];
    typePrestation: TypePrestation;
}

const DEFAULT_MM_SIZE = 200;

@Component({
    selector: 'app-export-svg',
    templateUrl: './export-svg.component.html',
    styleUrls: ['./export-svg.component.scss'],
})
export class ExportSvgComponent extends BaseComponent implements OnInit {
    @ViewChild('viewerElement') viewerElement: ElementRef;

    readonly svgFilter = SVG_FILTER;
    biens: Bien[];
    selectedBien: Bien;
    currentBienToDisplay: string;
    selectedStorey: cn_storey;
    /** Nom de l'étage courant */
    currentStairToDisplay: string;

    svgParameter: SvgParameter;

    /** Taille du conteneur du svg */
    viewWidth: number;
    viewHeight: number;

    /** Dimension de la div du SVG */
    divWidth: string;
    divHeight: string;

    /** Dimension du plan en millimètre */
    heightMm = 210;
    widthMm = 297;

    ratio: number;

    /** Bâtiment courant */
    building: cn_building;

    /** SVG Configurator pour la page courante */
    private configurator: cn_svg_configurator;

    constructor(
        public dialogRef: MatDialogRef<ExportSvgComponent>,
        private buildingService: BuildingService,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: SvgData
    ) {
        super();
    }

    ngOnInit(): void {
        this.svgParameter = new SvgParameter();
        // Récupère le bâtiment courant
        if (this.data) {
            this.biens = this.data.biens;
            this.selectedBien = this.data.biens[0];
            this.changeBuilding(this.selectedBien);
            // Sélectionne le premier étage de la liste
            setTimeout(() => this.updateStorey(this.building.storeys[0]), 300);
        }
    }

    changeSelectedStorey(event: string) {
        const index = this.selectedBien.description.find((niveau) => niveau.nom === event).index;
        if (index !== undefined) {
            const storey = this.building.storeys.find((st) => st.storey_index === index);
            this.updateStorey(storey);
        }
    }

    handleContainerResize(ignored: ResizeObserverEntry) {
        this.initSvgDivSize();
    }

    handleDivResize(ignored: ResizeObserverEntry) {
        if (this.configurator) {
            this.configurator.refresh();
        }
    }

    onScaleChange() {
        this.configurator.set_scale(this.svgParameter.scale);
        this.configurator.refresh();
    }

    onResolutionChange() {
        this.configurator.set_resolution(this.svgParameter.resolution);
        this.configurator.refresh();
    }

    onCenterViewChange() {
        this.configurator.center_camera();
    }

    onFixedScaleChange() {
        this.configurator.set_fixed_scale(this.svgParameter.fixedScale);
        this.configurator.refresh();
    }

    onFixedPositionChange() {
        this.configurator.set_fixed_position(this.svgParameter.fixedPosition);
        this.configurator.refresh();
    }

    onPlanParamChange(planParam: PlanParam) {
        this.configurator.set_svg_param(planParam.code, planParam.value);
        this.configurator.refresh();
    }

    // Mise à jour de la taille du conteneur du SVG en détermient quelle dimension doit être adaptée
    updateSvgDivSize() {
        this.ratio = this.widthMm / this.heightMm;
        if (this.viewWidth > this.viewHeight * this.ratio) {
            this.divHeight = String(this.viewHeight) + 'px';
            this.divWidth = String(Math.round(this.viewHeight * this.ratio)) + 'px';
        } else {
            this.divWidth = String(this.viewWidth) + 'px';
            this.divHeight = String(Math.round(this.viewWidth / this.ratio)) + 'px';
        }
        if (this.configurator) {
            this.configurator.set_print_height(this.heightMm);
            this.configurator.refresh();
        }
    }

    cancel() {
        this.dialogRef.close(false);
    }

    changeCurrentBien(nomBienSelected: string) {
        this.selectedBien = this.biens.find((b) => b.nom === nomBienSelected);
        this.changeBuilding(this.selectedBien);
        // Sélectionne le premier étage de la liste
        setTimeout(() => this.updateStorey(this.building.storeys[0]), 300);
    }

    export() {
        const screenshotSvg = new ScreenshotSvg();
        const height = Math.round(this.configurator.get_print_height() * MM_TO_POINT_IMAGE_PRINT(300));
        const width = Math.round(height * this.ratio);
        let svgTxt = "<svg width='" + width + "' height='" + height + "'";
        svgTxt += " xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'>";
        svgTxt += this.svgFilter.replace('</defs>', '');
        svgTxt += this.configurator.render(width, height).replace('<defs>', '');
        svgTxt += '</svg>';
        this.configurator.refresh();

        screenshotSvg.nom = this.building.name + '_Niveau_' + this.selectedStorey.get_storey_name();
        screenshotSvg.jsonPlan = svgTxt;
        screenshotSvg.storeyId = this.selectedStorey.ID;
        this.dialogRef.close({ screenshotSvg: screenshotSvg, bienId: this.selectedBien.id });
    }

    private changeBuilding(bien: Bien) {
        this.building = cn_building.unserialize(JSON.parse(bien.jsonPlan));
        this.currentBienToDisplay = bien.nom;
        this.currentStairToDisplay = bien.description[0].nom;
    }

    private initSvgDivSize() {
        if (this.viewerElement) {
            this.viewWidth = this.viewerElement.nativeElement.offsetWidth - 14;
            this.viewHeight = this.viewerElement.nativeElement.offsetHeight - 14;
            this.divWidth = String(this.viewWidth) + 'px';
            this.divHeight = String(this.viewHeight) + 'px';

            // Calcul proportionnel en millimètre de la deuxième dimension
            let mmPerPxRatio = 1;
            if (this.viewWidth > this.viewHeight) {
                mmPerPxRatio = DEFAULT_MM_SIZE / this.viewHeight;
                this.heightMm = DEFAULT_MM_SIZE;
                this.widthMm = Math.round(this.viewWidth * mmPerPxRatio);
            } else {
                mmPerPxRatio = DEFAULT_MM_SIZE / this.viewWidth;
                this.widthMm = DEFAULT_MM_SIZE;
                this.heightMm = Math.round(this.viewHeight * mmPerPxRatio);
            }
            this.changeDetectorRef.detectChanges(); // pour éviter ExpressionChangedAfterItHasBeenCheckedError
        }
    }

    private updateStorey(storey: cn_storey) {
        this.selectedStorey = storey;
        if (this.configurator) {
            this.configurator.stop_listening();
        }
        this.configurator = new cn_svg_configurator('svg_view', this.selectedStorey, false);
        if (this.svgParameter.svgDefaultParams.length === 0) {
            this.svgParameter.svgDefaultParams = this.configurator.get_svg_params();
            this.svgParameter.svgParamValues = this.configurator.get_svg_params_by_code();
        } else {
            Object.keys(this.svgParameter.svgParamValues).forEach((key) =>
                this.configurator.set_svg_param(key, this.svgParameter.svgParamValues[key])
            );
            this.configurator.set_fixed_scale(this.svgParameter.fixedScale);
            this.configurator.set_fixed_position(this.svgParameter.fixedPosition);
            if (this.configurator.get_fixed_scale()) {
                this.configurator.set_scale(this.svgParameter.scale);
            }
        }
        this.configurator.set_resolution(this.svgParameter.resolution);
        // ne conserve que les 2 dernières décimales sur l'échelle
        this.configurator.on(
            'scale_change',
            () => (this.svgParameter.scale = parseFloat(this.configurator.get_scale().toFixed(2)))
        );
        this.configurator.center_camera();
        this.configurator.refresh();
    }
}

import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { BaseComponent } from '../utils/base.component';
import {
    shoe_icon,
    vent_icon,
    aerosol_icon,
    ventilateur_icon,
    recuperation_icon,
    gas_mask,
    loupe_eil,
} from 'src/assets/icons/icons.js';
import { Couleur } from 'src/app/model/icone';

export type IconTheme = 'angular-material-native' | 'mdi' | 'fa' | 'wizy';

export class Icon {
    constructor(public name: string, public theme: IconTheme = 'angular-material-native') {}
}

/**
 * Affichage d'une icône parmi les 3 librairies suivantes :
 * - Angular Material
 * - Material Design
 * - Font Awesome
 *
 * Usage :
 *
 * - Angular Material : https://www.angularjswiki.com/angular/angular-material-icons-list-mat-icon-list/#angular-material-icons-list-categories
 *
 * `<lib-icon>home</lib-icon>`
 *
 * ou
 *
 * `<lib-icon iconName="home"></lib-icon>`
 *
 * ou
 *
 * `<lib-icon theme="angular-material-native" iconName="home"></lib-icon>`
 *
 * - Material Design : https://pictogrammers.com/library/mdi/  Version : 5.8.55
 *
 * `<lib-icon theme="mdi" iconName="home"></lib-icon>`
 *
 * - Font Awesome : https://fontawesome.com/v5/search?o=r&m=free
 *
 * `<lib-icon theme="fa" iconName="home"></lib-icon>`
 */
@Component({
    selector: 'lib-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent extends BaseComponent implements OnInit {
    /** Thème d'icône à utiliser */
    @Input() theme: IconTheme = 'angular-material-native';

    /** Nom de l'icône dans le thème */
    @Input() iconName: string;

    /** Couleur de l'icône */
    @Input() color: Couleur;

    /** Classe à appliquer sur l'élément <mat-icon> généré */
    @Input() class: undefined | string | string[];

    @ViewChild('template', { static: true }) template;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
        super();
        iconRegistry.addSvgIconLiteral('shoe', sanitizer.bypassSecurityTrustHtml(shoe_icon));
        iconRegistry.addSvgIconLiteral('wind', sanitizer.bypassSecurityTrustHtml(vent_icon));
        iconRegistry.addSvgIconLiteral('aerosol', sanitizer.bypassSecurityTrustHtml(aerosol_icon));
        iconRegistry.addSvgIconLiteral('ventilateur', sanitizer.bypassSecurityTrustHtml(ventilateur_icon));
        iconRegistry.addSvgIconLiteral('recuperation', sanitizer.bypassSecurityTrustHtml(recuperation_icon));
        iconRegistry.addSvgIconLiteral('gas-mask', sanitizer.bypassSecurityTrustHtml(gas_mask));
        iconRegistry.addSvgIconLiteral('loupe-eil', sanitizer.bypassSecurityTrustHtml(loupe_eil));
    }

    ngOnInit() {
        this.viewContainerRef.createEmbeddedView(this.template);
    }
}

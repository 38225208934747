import {
    EtapeDiagnosticGenerique,
    EtapeDiagnosticInitialisationElectricite,
} from '../../../../model/diagnostic-etape.model';
import { ContenuDiagnostic } from '../../../../model/diagnostic-contenu.model';
import { TypePrestation } from '../../../../model/type-prestation.model';
import { AnnexeData, DocumentData, IDiagReportData, ReportagePhotoData } from '../../../../model/rapport.model';
import { ScreenshotSvg } from '../../../../model/screenshot-svg.model';

export class Electricite implements ContenuDiagnostic {
    dateInstallation: EtapeDiagnosticInitialisationElectricite = new EtapeDiagnosticInitialisationElectricite();
    fournisseur: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    sousTension: EtapeDiagnosticInitialisationElectricite = new EtapeDiagnosticInitialisationElectricite();
    typeBien: EtapeDiagnosticInitialisationElectricite = new EtapeDiagnosticInitialisationElectricite();
    numeroCompteur: EtapeDiagnosticGenerique = new EtapeDiagnosticGenerique();
    capotsDemontables: EtapeDiagnosticInitialisationElectricite = new EtapeDiagnosticInitialisationElectricite();
    presenceDispositif30mA: EtapeDiagnosticInitialisationElectricite = new EtapeDiagnosticInitialisationElectricite();
}

export class ElectriciteConfig {
    id: string;
    dateDeCreation: Date;
    idInterne: string;
    chapitres: Chapitre[];
    conclusionsCategoriesEvalRisques: ConclusionCategorieEvalRisque[];
    conclusionsEvalRisques: ConclusionEvalRisque[];
    constationsDiverses: ConstatationDiverse[];
    distributeurs: Distributeur[];
    domainesAnomalies: DomaineAnomalie[];
    //groupesConstatationsDiverses : GroupeConstationDiverse[];
    //groupesConstatationsDiverses_ConstatationsDiverses : GroupeConstatationDiverse_ConstatationDiverse[];
    mesuresCompensatoires: MesureCompensatoire[];
    //mesuresCompensatoires_PointsControles: MesureCompensatoire_PointControle[];
    //motifsNonVerifiables: MotifNonVerifiable[];
    pointsControles: PointControle[];
    pointsControlesAides: PointControleAide[];
    risquesEncourus: RisqueEncouru[];
    boutons: Bouton[];
    cochagesAutomatiquesConstatationsDiverses: CochageAutomatiqueConstatationDiverse[];
    photos: Photo[];
    anomaliesChapitres: AnomaliesChapitre[];
    anomalies: Anomalie[];
}
export class MesureCompensatoire {
    id: string;
    idInterne: string;
    pcMesureCompensatoire: string;
    lstPointControlesAssocies: string[];
    description: string;
}
export class ConclusionCategorieEvalRisque {
    id: string;
    idInterne: string;
    description: string;
    ordre: number;
}
export class ConclusionEvalRisque {
    id: string;
    idInterne: string;
    idCategorieEvalRisque: string;
    description: string;
    ordre: number;
    reference: string;
    checked: boolean;
}
export class RisqueEncouru {
    idInterne: string;
    description: string;
    ordre: number;
    idEvalRisques: string[];
}

export class Anomalie {
    id: string;
    idInterne: string;
    idRisqueEncouru: string;
    idAnomaliesChapitre: string;
    reference: string;
    description: string;
    ordre: number;
    checked: boolean;
}

export class AnomaliesChapitre {
    id: string;
    idInterne: string;
    description: string;
    ordre: number;
}

export class Photo {
    id: string;
    idInterne: string;
    idPhoto: string;
    description: string;
}

export class Bouton {
    id: string;
    idInterne: string;
    idChapitreParent: string;
    description: string;
    ordre: number;
    lstPointsControleReponse: PointControleReponse[];
}

export class PointControleReponse {
    id: string;
    idInterne: string;
    idPointControle: string;
    reponse: string;
    isGrey: boolean;
    greyInitialisationExplication: string;
    greyLienPointControleExplication: string;
}

export class ConstatationDiverse {
    id: string;
    idInterne: string;
    idConstatationDiverseParent: string;
    idConstatationDiverseRacine: string;
    chemin: string;
    description: string;
    reference: string;
    idTypeConstatationDiverse: string;
    idAccessibiliteConsatatationDiverse: string;
    idCochageAutomatiqueConstatationDiverse: string;
    diminutif: string;
    isFluke: boolean;
    referenceConclusionEvalRisque: string;
}

export class Chapitre {
    id: string;
    idInterne: string;
    idChapitreParent: string;
    idChapitreRacine: string;
    description: string;
    chemin: string;
}

export class Distributeur {
    id: string;
    idInterne: string;
    description: string;
}

export class DomaineAnomalie {
    id: string;
    idInterne: string;
    idRisqueEncouru: string;
    reference: string;
    description: string;
    ordre: number;
}

export class PointControleAide {
    id: string;
    idInterne: string;
    idPointControle: string;
    ordre: number;
    idPhoto: string;
    description: string;
}

export class PointControle {
    id: string;
    idInterne: string;
    idPointControleParent: string;
    idPointControleRacine: string;
    idConstatationDiverseSansObjet: string;
    idConstatationDiverseNonVerifiable: string;
    idConstatationDiverseNon: string;
    idConstatationDiverseOui: string;
    idRisqueEncouru: string;
    idChapitre: string;
    ordreChapitre: number;
    peutEtreRepondu: boolean;
    chemin: string;
    numeroArticle: string;
    numeroFiche: string;
    description: string;
    libelleRapport: string;
    isFluke: boolean;
    isGrey: boolean;
    greyExplication: string;
    greyLienPointControleExplication: string;
    lstPointControleReponseOui: PointControleReponse[];
    lstPointControleReponseNon: PointControleReponse[];
    lstPointControleReponseNonVerifiable: PointControleReponse[];
    lstPointControleReponseSansObjet: PointControleReponse[];
    lstReponsesImpossibles: string[];
    idAnomalie: string;
}

export class ElectriciteReportData implements IDiagReportData {
    id: string;
    typePrestation: TypePrestation;
    conformiteGlobale: boolean;
    refRapport: string;
    optionPlan: boolean;
    dateInstallation: string;
    recommandations: string[] = []; // CommentaireBlockComponent
    constatationsDiverses: string[] = []; // CommentaireBlockComponent
    reportagesPhotos: ReportagePhotoData[] = [];
    annexes: AnnexeData[] = [];
    documentsData: DocumentData[] = [];
    screenshotsPlan: ScreenshotSvg[];
    nombreReponsePointControleNON: number;
    anomalieDetectee: boolean = false;
    mesureCompensatoireDetectee: boolean = false;
    locationVente: 'LOCATION' | 'VENTE';
    listDocuments: DocumentData[];
    conclusionText: string;
    volumesNonVisites: any;
    volumesVisites: any;
    conclusionEvalRisque: {
        idInterne: string;
        reference: string;
        description: string;
        ordre: number;
        ordreCategorie: number;
        conclusionCategorieEvalRisque: string;
        checked: boolean;
    }[];
    anomaliesPointControle: {
        pointControle: any;
        mesureCompensatoire: any;
        conclusionEvalRisque: any;
    }[];
    nonVerifiablePointDeControle: {
        pointControle: any;
        mesureCompensatoire: any;
        conclusionEvalRisque: any;
    }[];
    constatationDiverse: {
        idInterne: string;
        description: string;
        referenceConclusionEvalRisque: string;
        reference: string;
        idTypeConstatationDiverse: string;
        commentaires: any[];
    }[];
}

export class ValeurCheckpoint {
    valeur?: string | boolean | number;
    commentairesId?: string[] = [];
    isGrey?: boolean;
    greyExplication: string;
    greyLienPointControleExplication: string;
}

export class CochageAutomatiqueConstatationDiverse {
    id: string;
    idInterne: string;
    description: string;
}
export interface ICheckpoints {
    chapitre: Chapitre;
    chapitres: { chapitre: Chapitre; pointsControles: PointControle[] }[];
    path?: string;
}

import { Component, Input, OnInit } from '@angular/core';
import { Document } from '../../../../../model/document.model';
import { MatDialog } from '@angular/material/dialog';
import { Intervention } from '../../../../../model/intervention.model';
import { NotificationService } from 'src/app/commons-lib';
import { Diagnostic } from '../../../../../model/diagnostic.model';
import { InterventionFileService } from '../../../../../services/intervention-file.service';
import { InterventionService } from '../../../../../services/intervention.service';
import { DocumentsService } from '../../../../../services/documents.service';
import { TypeReferenceFichier } from '../../../../../model/intervention-file.model';
import { switchMap } from 'rxjs/operators';
import { EditFichierModalComponent } from '../../edit-fichier-modal/edit-fichier-modal.component';
import { CnSpinnerService } from '../../../cn-spinner/service/cn-spinner.service';
import { DocumentFileService } from '../../../../../services/document-file.service';
import {
    CommentLightModalComponent,
    CommentLightModalResult,
} from '../../../comment-light-modal/comment-light-modal.component';

@Component({
    selector: 'app-documents-detail-attachment',
    templateUrl: './documents-detail-attachment.component.html',
    styleUrls: ['./documents-detail-attachment.component.scss'],
})
export class DocumentsDetailAttachmentComponent implements OnInit {
    @Input() document: Document;
    @Input() diagnostic: Diagnostic;
    @Input() intervention: Intervention;
    @Input() readonlyMode: boolean;

    constructor(
        private readonly matDialog: MatDialog,
        private readonly notificationService: NotificationService,
        private readonly interventionFile: InterventionFileService,
        private readonly interventionService: InterventionService,
        private readonly documentsService: DocumentsService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly documentFileService: DocumentFileService
    ) {}

    ngOnInit(): void {}

    /**
     * Action lorsque l'utilisateur clique sur le bouton permettant de supprimer le fichier
     */
    onClickDelete(): void {
        this.matDialog
            .open(CommentLightModalComponent, {
                data: {
                    title: 'Suppression du fichier',
                    label: 'Veuillez indiquer un motif de suppression du fichier',
                },
            })
            .afterClosed()
            .subscribe((result: CommentLightModalResult) => {
                if (result && result.commentaire) {
                    this.interventionFile
                        .deleteInterventionFileAndFile(
                            this.intervention.id,
                            this.diagnostic && this.diagnostic.id ? this.diagnostic.id : undefined,
                            this.document.id,
                            TypeReferenceFichier.FICHIER_DOCUMENT,
                            this.document.idFichier
                        )
                        .pipe(
                            switchMap(() => {
                                // Ajout dans l'historique
                                this.document.documentsHistory.push(
                                    this.documentsService.prepareDataHistory(this.document, result.commentaire)
                                );
                                // Suppression des donnes relatives au fichier.
                                this.documentsService.prepareDeleteFile(this.document);
                                // Mise à jour de l'intervention ( le document est mis à jour par référence)
                                this.intervention.commentaires.push(result.commentaire);
                                return this.interventionService.updateIntervention(this.intervention);
                            })
                        )
                        .subscribe(() => {
                            this.notificationService.success('Le document a bien été supprimé');
                            // Il faut recharger l'intervention pour être sûr de travailler sur la dernière version
                            this.interventionService.reloadCurrentIntervention();
                        });
                }
            });
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton permettant de mettre à jour le fichier
     */
    onClickUpdateFile(): void {
        this.openModalFile(true);
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton permettant de visualiser le fichier
     */
    onClickView(): void {
        this.documentFileService.downloadFileByFileId(this.document).subscribe();
    }

    /**
     * Action lorsque l'utilisateur clique sur le bouton permettant d'ajouter un fichier
     */
    onClickAddFile(): void {
        this.openModalFile(false);
    }

    /**
     * Ouvre la modale permettant d'ajouter un fichier
     * @param updateMode
     */
    private openModalFile(updateMode: boolean): void {
        this.matDialog
            .open(EditFichierModalComponent, {
                data: {
                    idIntervention: this.intervention.id,
                    diagnosticId: this.diagnostic ? this.diagnostic.id : null,
                    document: { ...this.document },
                    updateMode: updateMode,
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result && result !== false && result.document) {
                    Object.assign(this.document, result.document);

                    this.cnSpinnerService
                        .withSpinner(this.interventionService.updateIntervention(this.intervention))
                        .subscribe(() => {
                            this.notificationService.success('Le document a été ajouté');
                            // Il faut recharger l'intervention pour être sûr de travailler sur la dernière version
                            this.interventionService.reloadCurrentIntervention();
                        });
                }
            });
    }
}

import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { BackgroundMapApiService } from '../../../../services/background-map-api.service';
import { Intervention } from '../../../../model/intervention.model';
import { Observable } from 'rxjs';
import { FormArray, FormGroup } from '@angular/forms';

import { HapFormService } from './hap-form.service';
import { Zone } from '../../../../model/zone.model';
import * as moment from 'moment';
import { DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT } from '../../../../shared/constants/cndiag.constants';
import { Hap } from '../model/hap.model';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { InterventionReportData } from '../../../../model/rapport.model';
import { BonCommandeAnalyseAdmin, BonCommandeAnalyseState } from '../../../../model/bon-commande.model';
import { MongoUtils } from 'src/app/commons-lib';
import { TypeReport } from '../../../../model/reference-prestation.model';
import { BonCommandeAnalyseApiService } from '../../../../services/bon-commande-analyse-api.service';
import {
    SEUIL_ENTRE_501_ET_1000,
    SEUIL_ENTRE_51_ET_500,
    SEUIL_INF_50,
    SEUIL_SUP_1000,
} from '../constantes/hap.constantes';
import { Perimetre } from '../../../../model/perimetre.model';
import {
    Prelevement,
    PrelevementEchantillonageAdmin,
    PrelevementEchantillonageADO,
    TypeAnalyse,
    TypeEchantillonage,
} from '../../../../model/prelevement-generique.model';
import { EchantilonBonCommandeData, HapBonCommandeData } from '../model/hap-bon-commande.model';
import { Espace } from 'src/app/model/espace.model';

/**
 * Service pour le diagnostic hap.
 */
@Injectable({
    providedIn: 'root',
})
export class HapService {
    constructor(
        private readonly backgroundMapApiService: BackgroundMapApiService,
        private readonly bonCommandeAnalyseService: BonCommandeAnalyseApiService
    ) {}

    /**
     * Lors du changement d'une reference d'un prelevement ou d'un besoin,
     * on recalcule la validité de chaque prélèvements et besoins dans le cas où il y aurait
     * un soucis d'unicité avec un autre prélèvement ou besoin
     */
    onChangeReference(formArrayPerimetre: FormArray, formGroupEdited: FormGroup) {
        formArrayPerimetre.controls.forEach((perimetreFormGroup) => {
            (perimetreFormGroup.get('formArrayZones') as FormArray).controls.forEach((zoneFormGroup) => {
                (zoneFormGroup.get('formArrayPrelevement') as FormArray).controls.forEach((prelevementFormGroup) => {
                    // On revalide tous les prélèvements sauf celui courant sinon on tombe dans une boucle infini.
                    // Il est validé automatiquement lors du changement de valeur par son validator
                    if (prelevementFormGroup.get('formGeneral') !== formGroupEdited) {
                        prelevementFormGroup.get('formGeneral').get('reference').updateValueAndValidity();
                    }
                });
            });
            (perimetreFormGroup.get('formArrayBesoins') as FormArray).controls.forEach((besoinFormGroup) => {
                if (besoinFormGroup !== formGroupEdited) {
                    besoinFormGroup.get('formGeneral').get('reference').updateValueAndValidity();
                }
            });
        });
    }

    /**
     * fonction pour changer de zone ou rebasculer dans la liste des besoins un prélèvement où un besoin
     * @param formArrayPerimetre
     * @param perimetreFormGroup
     * @param formGroupEdited
     * @param listePerimetre
     * @param hapFormService
     * @param zoneNew
     */

    /**
     * renvoie la requette pour récupérer l'ensemble des backgroundImage d'une intervention :
     * lors d'un subscribe au résultat de cette fonction, on récupérera la liste des backgroundImage de toute l'intervention
     * @param intervention
     */
    requestAllBackgroundImage(intervention: Intervention): Observable<void>[] {
        const backgroundMapsObs$: Observable<void>[] = [];
        intervention.relationInterventionBiens.forEach((relationInterventionBienTemp) => {
            relationInterventionBienTemp.bien.backgroundMaps.forEach((backgroundMapTemp) => {
                backgroundMapsObs$.push(
                    this.backgroundMapApiService
                        .downloadBackgroundImage(
                            intervention.id,
                            relationInterventionBienTemp.id,
                            backgroundMapTemp.fileId
                        )
                        .pipe(
                            map((res) => {
                                if (res) {
                                    backgroundMapTemp.imageUrl = res.fileContent;
                                }
                            })
                        )
                );
            });
        });
        return backgroundMapsObs$;
    }

    prepareDiagnosticBonCommandeData(diagnostic: Diagnostic) {
        const diagReportData = new HapBonCommandeData();
        diagReportData.id = diagnostic.id;
        diagReportData.typePrestation = diagnostic.typePrestation;

        const dateDuJour = moment().format(DATE_FORMAT_FRANCAIS_SANS_HEURE_MOMENT);
        diagReportData.datePrelevement = dateDuJour;
        diagReportData.dateEnvoi = dateDuJour;
        diagReportData.typePrestation = diagnostic.typePrestation;

        const contenuDiagnostic = diagnostic.contenuDiagnostic as Hap;
        diagReportData.echantillons = this.buildEchantillonsBonCommande(contenuDiagnostic);

        return diagReportData;
    }

    /**
     * Génère une ligne dans le tableau pour chaque échantillon de prélèvement existant
     * @param contenuDiagnostic
     * @returns
     */
    private buildEchantillonsBonCommande(contenuDiagnostic: Hap): EchantilonBonCommandeData[] {
        const echantilonBonCommandeDatas: EchantilonBonCommandeData[] = [];
        // Pour chaque espace
        contenuDiagnostic.espaces.valeur.forEach((espace) => {
            // Pour chaque périmètre
            espace.listePerimetres.forEach((perimetre) => {
                // Pour chaque zone
                perimetre.listeZones.forEach((zone) => {
                    // Pour chaque prélèvement réalisé
                    zone.listePrelevements.forEach((prelevement) => {
                        if (prelevement.isRealize) {
                            // Pour chaque échantillon, on crée une entrée dans le tableau de data
                            prelevement.echantillonages.forEach((echantillon) => {
                                const newEchantilonBonCommandeData = new EchantilonBonCommandeData();
                                newEchantilonBonCommandeData.reference = echantillon.reference;
                                newEchantilonBonCommandeData.ouvrage = perimetre.typeOuvrage
                                    ? perimetre.typeOuvrage.name
                                    : 'n.c';
                                newEchantilonBonCommandeData.partieOuvrage = zone.partieOuvrage
                                    ? zone.partieOuvrage.name
                                    : 'n.c';
                                newEchantilonBonCommandeData.materiaux = echantillon.materiaux
                                    .map((materiau) => materiau.nom)
                                    .join(',');
                                newEchantilonBonCommandeData.amiantePresent =
                                    prelevement.donneesTechniques.analyseAmiante.value;
                                newEchantilonBonCommandeData.typeEchantillon =
                                    echantillon.type === TypeEchantillonage.AGREGAT
                                        ? 'Agrégat\nConcassé à l’aide d’une massette'
                                        : 'Mat. de construction';
                                newEchantilonBonCommandeData.nomPerimetre = perimetre.nom ? perimetre.nom : 'n.c';
                                newEchantilonBonCommandeData.nomZone = zone.nom ? zone.nom : 'n.c';
                                newEchantilonBonCommandeData.lave = prelevement.donneesTechniques.lave.value;
                                newEchantilonBonCommandeData.dissociation =
                                    prelevement.donneesTechniques.couchesADissocier.value;
                                newEchantilonBonCommandeData.analyseToutesCouches =
                                    prelevement.donneesTechniques.analyseCouches.value;
                                newEchantilonBonCommandeData.limitationFibres =
                                    prelevement.donneesTechniques.limitationFibre.value;
                                newEchantilonBonCommandeData.pollutionSurfacique =
                                    prelevement.donneesTechniques.pollutionSurfacique.value;

                                echantilonBonCommandeDatas.push(newEchantilonBonCommandeData);
                            });
                        }
                    });
                });
            });
        });
        return echantilonBonCommandeDatas;
    }

    /**
     * Génère l'objet bon de commande pour le diagnostic
     * @param intervention
     * @param diagnostic
     * @param interReportData
     */
    generateDiagnosticBonCommande(
        intervention: Intervention,
        diagnostic: Diagnostic,
        interReportData: InterventionReportData
    ): BonCommandeAnalyseAdmin {
        const date = moment(intervention.dateHeureDebutEffective).format('DD-MM-YY');
        const bonCommande: BonCommandeAnalyseAdmin = {
            id: MongoUtils.generateObjectId(),
            dataReport: JSON.stringify(interReportData),
            interventionId: intervention.id,
            interventionName: intervention.nom,
            typeReport: TypeReport.BON_COMMANDE,
            refReport: (
                TypeReport.BON_COMMANDE +
                ' ' +
                intervention.agence.nom.substring(0, 2) +
                ' ' +
                date +
                ' ' +
                Intervention.getRelationInterventionBienPrincipal(intervention).bien.nom.substring(0, 5) +
                ' ' +
                diagnostic.typePrestation.substring(0, 6)
            ).toUpperCase(),
            diagnosticId: diagnostic.id,
            numeroCommande: intervention.numeroCommande,
            creationDate: moment().toISOString(),
            state: BonCommandeAnalyseState.NON_ENVOYE,
            listeEchantillons: this.getAllEchantillonsFromDiagnostic(diagnostic),
            emailResults: interReportData.operateur.email,
            idOperators: intervention.prestataires.map((it) => it.id),
            idAgence: intervention.agence.id,
            signed: false,
            idsBien: intervention.relationInterventionBiens.map((it) => it.bien.id),
        };
        return bonCommande;
    }

    /**
     * Renvoie la liste des échantillons contenus dans ce diagnostic
     * @param diagnostic
     */
    getAllEchantillonsFromDiagnostic(diagnostic: Diagnostic) {
        let listeEchantillons: PrelevementEchantillonageAdmin[] = [];
        (diagnostic.contenuDiagnostic as Hap).espaces.valeur.forEach((espace) => {
            espace.listePerimetres.forEach((perimetre) => {
                perimetre.listeZones.forEach((zone) => {
                    zone.listePrelevements.forEach((prelevement) => {
                        const echantillonages = [...prelevement.echantillonages];
                        const echantillonagesAdmin: PrelevementEchantillonageAdmin[] = echantillonages.map(
                            (prelevementEchantillonage) => {
                                return {
                                    id: prelevementEchantillonage.id,
                                    reference: prelevementEchantillonage.reference,
                                    prelevementId: prelevementEchantillonage.prelevementId,
                                    type: prelevementEchantillonage.type,
                                    hauteur: prelevementEchantillonage.hauteur,
                                    typePrelevement: prelevement.details.typePrelevement,
                                    typesAnalyse: this.getTypesAnalyse(TypeAnalyse.HAP, prelevement),
                                    validated: false,
                                    resultatsAnalyse: [],
                                };
                            }
                        );
                        listeEchantillons = listeEchantillons.concat(echantillonagesAdmin);
                    });
                });
            });
        });
        return listeEchantillons;
    }

    /**
     * Renvoie les types d'analyse
     * @param type
     * @param prelevement
     */
    getTypesAnalyse(type: TypeAnalyse, prelevement: Prelevement): TypeAnalyse[] {
        const response: TypeAnalyse[] = [type];
        switch (type) {
            case TypeAnalyse.HAP:
                if (prelevement.donneesTechniques.analyseAmiante.value) {
                    response.push(TypeAnalyse.ANALYSE_AMIANTE);
                }
                break;
        }
        return response;
    }

    computeResults(pvts: PrelevementEchantillonageADO[]) {
        pvts.length &&
            pvts.forEach((pvt) => {
                pvt.resultatsAnalyse &&
                    pvt.resultatsAnalyse.length &&
                    pvt.resultatsAnalyse.forEach((res) => {
                        if (res.typeAnalyse === TypeAnalyse.HAP) {
                            if (res.number) {
                                res.groupResultat = this.computeResultatHAP(res.number);
                            }
                        }
                        // TODO FVI voir pour l'amiante
                        if (res.typeAnalyse === TypeAnalyse.ANALYSE_AMIANTE) {
                            res.groupResultat = undefined;
                            //     if (res.description) {
                            //         if (res.description === 'Oui') {
                            //             res.groupResultat = ResultSeuil.PRESENCE;
                            //         } else if (res.description === 'Non') {
                            //             res.groupResultat = ResultSeuil.ABSENCE;
                            //         }
                            //     }
                        }
                    });
            });
    }

    computeResultatHAP(resultat) {
        if (resultat <= 50) {
            return SEUIL_INF_50;
        } else if (resultat > 50 && resultat <= 500) {
            return SEUIL_ENTRE_51_ET_500;
        } else if (resultat > 500 && resultat <= 1000) {
            return SEUIL_ENTRE_501_ET_1000;
        } else if (resultat > 1000) {
            return SEUIL_SUP_1000;
        }
    }

    /**
     * Récupération des résultats à partir de l'idAgence et de la liste des ids de prélèvements
     * @param idAgence
     * @param diagnostic
     */
    preparePrelevementsWithResults(
        idAgence: string,
        diagnostic: Diagnostic
    ): Observable<PrelevementEchantillonageADO[]> {
        const pvts = this.getAllEchantillonsFromDiagnostic(diagnostic);
        return this.bonCommandeAnalyseService
            .getResultatsEchantillons(idAgence, [...new Set(pvts.map((it) => it.prelevementId))])
            .pipe(
                tap((prelevementsEchantillonagesADOs) => {
                    this.computeResults(prelevementsEchantillonagesADOs);
                    this.updatePrelevementsFromDiagnosticWithBDC(diagnostic, prelevementsEchantillonagesADOs);
                    return prelevementsEchantillonagesADOs;
                })
            );
    }

    /**
     * A partir des résultats d'échantillons récupérés dans les analyses de BDC, on maj les prélèvements du diagnostic, avec ces résultats et on précise l'id du BDC utilisé
     * @param diagnostic
     * @param res
     * @private
     */
    private updatePrelevementsFromDiagnosticWithBDC(diagnostic: Diagnostic, res: PrelevementEchantillonageADO[]) {
        (diagnostic.contenuDiagnostic as Hap).espaces.valeur.forEach((espace: Espace) => {
            espace.listePerimetres.length &&
                espace.listePerimetres.forEach((perimetre) => {
                    perimetre.listeZones.length &&
                        perimetre.listeZones.forEach((zone) => {
                            zone.listePrelevements.length &&
                                zone.listePrelevements.forEach((prelevement) => {
                                    prelevement.echantillonages.length &&
                                        prelevement.echantillonages.forEach((echantillon: any) => {
                                            const prelevementEchantillonageADO = res.find(
                                                (it) => it.id === echantillon.id
                                            );
                                            echantillon.resultatsAnalyse =
                                                prelevementEchantillonageADO?.resultatsAnalyse;
                                            echantillon.idBonDeCommande =
                                                prelevementEchantillonageADO?.idBonCommandeAnalyse;
                                            echantillon.validated = prelevementEchantillonageADO?.validated;
                                            prelevementEchantillonageADO.commentairesId = echantillon.commentairesId;
                                        });
                                });
                        });
                });
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { TypeCommentaire } from '../../../../model/type-commentaire.model';
import { BaseComponent } from 'src/app/commons-lib';
import { Commentaire } from '../../../../model/commentaire.model';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../model/diagnostic.model';
import { EtatProgressionService } from '../../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-constatations-diverses',
    templateUrl: './constatations-diverses.component.html',
    styleUrls: ['./constatations-diverses.component.scss'],
})
export class ConstatationsDiversesComponent extends BaseComponent implements OnInit {
    typeCommentaire: TypeCommentaire = 'CONSTATATION_DIVERSE_GENERALE';
    diagnostic: Diagnostic;

    constructor(
        private readonly diagnosticService: DiagnosticService,
        private readonly etatProgressionService: EtatProgressionService,
        private readonly route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        this.diagnosticService
            .getCurrentDiagnostic()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((diag) => {
                this.diagnostic = diag;
                this.checkValidity();
            });
    }

    commentairesChanged(recoms: Commentaire[]) {
        this.diagnostic.constatationsFinales = recoms;
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.diagnostic);
    }
}

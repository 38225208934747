"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_roof_lines : a SVG tool to build roof lines
//***********************************************************************************
//***********************************************************************************

import {cn_dist, cn_dot, cn_middle, cn_mul, cn_normalize, cn_sub} from "../utils/cn_utilities";
import {cn_snap} from "./cn_snap";
import {cn_roof_line} from "../model/cn_roof_line";
import {cn_roof_vertex} from "../model/cn_roof_vertex";
import {cn_svg_tool_creation} from "./cn_svg_tool_creation";

export class cn_svg_tool_roof_line_creation extends cn_svg_tool_creation {
	constructor(map) {
		super(map);

		this._mouse_position = [0,0];
		this._current_point = [0,0];
		this._current_line = new cn_roof_line(null,null);
		this.clear_selection();
		this.element_filter = function(element) {return element.constructor == cn_roof_line || element.constructor == cn_roof_vertex};
	}

	//***********************************************************************************
	//**** Returns current line
	//***********************************************************************************
	get_current_line() {
		return this._current_line;
	}

	//***********************************************************************************
	//**** Clear selection
	//***********************************************************************************
	clear_selection() {
		this._can_close_contour = false;
		this._mouseover_vertex = null;
		this._mouseover_line = null;
		this._tmp_vertices = [];
		this._tmp_lines = [];
		this._vertex_mode = 0;
		this._snap_svg = "";
	}

	//***********************************************************************************
	//**** Close tool
	//***********************************************************************************

	open_tool() {
		super.open_tool();
		this.clear_selection();
	}

	close_tool() {
		super.close_tool();
		this.clear_selection();
	}

	//***********************************************************************************
	//**** Draws  specific svg for the tool. Returns svg string
	//***********************************************************************************
	draw(camera) {
		var html = "";

		for (var i in this._tmp_lines)
			html += this._tmp_lines[i].draw(camera);

		html += this._snap_svg;

		var p = camera.world_to_screen(this._current_point);
		var color='wrong';
		if (this._vertex_mode == 1)
			color = 'start';
		else if (this._vertex_mode == 2)
			color = 'intermediate';
		else if (this._vertex_mode == 3)
			color = 'end';

		html += "<circle class='roof_vertex " + color + "' cx='" + p[0] + "' cy='" + p[1] + "' r='6' />";

		return html;
	}

	//***********************************************************************************
	//**** Mouse callbacks
	//***********************************************************************************

	click(ev) {
		return this.drop(ev);
	}

	grab (ev) {
		return false;
	}

	drop (ev) {
		if (this.update_contour(ev))
		{
			if (this._tmp_lines.length == 0)
				this.initiate_contour();
			else if (this._can_close_contour)
			{
				this.close_contour();
				return true;
			}
			else
				this.resume_contour();
			this.update_contour(ev);
		}

		return true;
	}

	move (ev) {
		this.update_contour(ev);
		return true;
	}

	drag (ev) {
		this.update_contour(ev);
		return true;
	}

	//***********************************************************************************
	//**** Initiate a contour
	//***********************************************************************************
	initiate_contour()
	{
		var v0 = null;
		this._start_line = null;
		this._start_slab = null;
		this._current_slab = null;

		//** We may start from an existing vertex
		if (this._mouseover_vertex)
		{
			v0 = this._mouseover_vertex;
		}
		//*** We may start by a line
		else if (this._mouseover_line)
		{
			this._start_line = this._mouseover_line;
			v0 = new cn_roof_vertex(this._current_point);
		}
		else
			return false;

		this._tmp_vertices.push(v0);
		var v1 = new cn_roof_vertex(this._current_point);
		this._tmp_vertices.push(v1);
		this._tmp_lines.push(new cn_roof_line(v0,v1));
		this._can_close_contour = false;
		return true;
	}

	//***********************************************************************************
	//**** Resume contour
	//***********************************************************************************

	resume_contour() {
		//*** can we decide in which slab we play ?
		var v0 = new cn_roof_vertex(this._current_point);
		if (this._tmp_lines.length > 0)
			this._tmp_lines[this._tmp_lines.length-1].build_self();
		this._tmp_lines.push(new cn_roof_line(this._tmp_vertices[this._tmp_vertices.length-1],v0));
		this._tmp_vertices.push(v0);
		return true;
	}
	//***********************************************************************************
	//**** Close contour
	//***********************************************************************************
	close_contour()
	{
		//** we need to end either on a line, either on a vertex.
		if (this._mouseover_vertex == null && this._mouseover_line == null)
			return false;

		//*** do we end on an existing vertex ? if yes we remove it.
		if (this._mouseover_vertex)
		{
			this._tmp_vertices.splice(this._tmp_vertices.length-1,1);
			var last_line = this._tmp_lines[this._tmp_lines.length-1];
			last_line.vertices[1] = this._mouseover_vertex;
		}
		if (this._tmp_lines.length == 0) return false;

		//*** Start transaction now
		var scene = this._scene;
		this.push_transaction("Création de ligne de toit");

		//*** maybe split start line
		if (this._start_line)
		{
			var start_line = this._start_line;
			var start_vertex = this._tmp_vertices[0];
			var new_start_line = scene.split_line(start_line,start_vertex);

			this.push_item_set(start_line,[],function(){
				if (scene.lines.indexOf(new_start_line) >= 0)
					scene.merge_line(start_line,new_start_line);
				else
					scene.split_line(start_line,start_vertex,new_start_line);
			});

		}

		//*** maybe split end line
		if (this._mouseover_line)
		{
			//*** Potential problem is start line is the same as end line.
			//*** We have already split start line, which part of it is to be split again ?
			if (this._mouseover_line == this._start_line)
			{
				var dir = cn_sub(this._start_line.vertices[1].position,this._start_line.vertices[0].position);
				cn_normalize(dir);
				var d = cn_sub(this._tmp_vertices[this._tmp_vertices.length-1].position,this._start_line.vertices[1].position);
				var dist = cn_dot(d,dir);
				if (Math.abs(dist) < 0.01)
				{
					this._mouseover_line = null;
					this._mouseover_vertex = this._tmp_vertices[0];
					this._tmp_vertices[this._tmp_vertices.length-1] = this._mouseover_vertex;
					this._tmp_lines[this._tmp_lines.length-1].vertices[1] = this._mouseover_vertex;
				}
				else if (cn_dot(d,dir) > 0)
					this._mouseover_line = new_start_line;
			}

			//*** Split end line
			if (this._mouseover_line)
			{
				var end_line = this._mouseover_line;
				var end_vertex = this._tmp_vertices[this._tmp_vertices.length-1];
				var new_end_line = scene.split_line(end_line,end_vertex);

				this.push_item_set(end_line,[],function(){
					if (scene.lines.indexOf(new_end_line) >= 0)
						scene.merge_line(end_line,new_end_line);
					else
						scene.split_line(end_line,end_vertex,new_end_line);
				});
			}
		}

		//*** Encapsulate in a function to keep 'lines' in the stack
		var obj = this;
		function insert_lines(lines) {
			obj.push_item_set(scene,[],function(){
				if (scene.lines.indexOf(lines[0]) >= 0)
					scene.remove_lines(lines);
				else
					scene.insert_lines(lines);
			});
			scene.insert_lines(lines);
		}

		insert_lines(this._tmp_lines);

		this.clear_selection();
		this.call("creation");
		this._svg_parent.call("roof_change");
	}

	//***********************************************************************************
	//**** update contour
	//***********************************************************************************

	update_contour(ev) {
		//*** compute mouse position in both referencials

		this._snap_svg = "";
		this._vertex_mode = 0;
		this._mouseover_vertex = null;
		this._mouseover_line = null;

		var previous_point = (this._tmp_lines.length > 0)?this._tmp_lines[this._tmp_lines.length-1].vertices[0].position:null;
		var snap = new cn_snap(ev.mouse_world,ev.camera.snap_world_distance,previous_point,ev.camera);

		//*** Try to find if current line being drawed intersects an existing line
		if (this._tmp_lines.length > 0)
		{
			var last_line = this._tmp_lines[this._tmp_lines.length-1];
			var direction = cn_sub(ev.mouse_world,last_line.vertices[0].position);
			var max_distance = cn_normalize(direction);
			if (max_distance > 0.01)
			{
				var impact = this._scene.raytrace(last_line.vertices[0].position,direction,max_distance,last_line.vertices[0],this._start_line);
				if (impact)
				{
					snap.start_position = impact.point;
					snap.position = impact.point;
				}
			}
		}

		//*** Snap on existing vertex ?
		for (var i in this._scene.vertices)
		{
			if (snap.check_point(this._scene.vertices[i].position))
			{
				this._mouseover_vertex = this._scene.vertices[i];
				break;
			}
		}

		//*** find line under the mouse
		if (snap.freedoms > 0)
		{
			for (var i in this._scene.lines)
			{
				if (snap.check_segment(this._scene.lines[i].vertices[0].position,this._scene.lines[i].vertices[1].position,true))
				{
					this._mouseover_line = this._scene.lines[i];
					break;
				}
			}
		}

		//*** Snap on start vertex ?
		if (this._tmp_lines.length == 1)
		{
			var vertex = this._tmp_lines[0].vertices[0];
			for (var i in vertex.lines)
			{
				var line = vertex.lines[i];
				if (line == this._tmp_lines[0]) continue;
				if (snap.freedoms > 0)
					snap.check_angles(line.vertices[0].position,line.vertices[1].position);
			}
		}

		//*** Snap on start line ?
		if (snap.freedoms > 0 && this._tmp_lines.length == 1 && this._start_line)
			snap.check_angles(this._start_line.vertices[0].position,this._start_line.vertices[1].position);

		//*** snap with lines from previous vertex
		if (snap.freedoms > 0 && this._tmp_lines.length == 1)
		{
			var vertex = this._tmp_lines[0].vertices[0];
			for (var k=0;k<vertex.lines.length;k++)
			{
				var line = vertex.lines[k];
				if (snap.check_angles(line.vertices[0].position,line.vertices[1].position))
					break;
			}
		}

		//*** snap with previous line
		if (snap.freedoms > 0 && this._tmp_lines.length > 1)
		{
			var line = this._tmp_lines[this._tmp_lines.length-2];
			snap.check_angles(line.vertices[0].position,line.vertices[1].position);
		}

		//*** snap with end line
		if (snap.freedoms > 0 && this._tmp_lines.length > 0 && this._mouseover_line)
		{
			snap.check_angles(this._mouseover_line.vertices[0].position,this._mouseover_line.vertices[1].position);
		}

		//*** snap with end vertex
		if (snap.freedoms > 0 && this._tmp_lines.length > 0 && this._mouseover_vertex)
		{
			var vertex = this._mouseover_vertex;
			for (var k=0;k<vertex.lines.length;k++)
			{
				var line = vertex.lines[k];
				if (snap.check_angles(line.vertices[0].position,line.vertices[1].position))
					break;
			}
		}

		//*** snap on grid
		if (snap.freedoms > 0)
			snap.check_grid();

		this._snap_svg = snap.svg;

		//*** maybe snap on this scene ?
		this._current_point = snap.position;

		//*** First vertex */
		if (this._tmp_lines.length == 0)
		{
			if (this._mouseover_vertex == null && this._mouseover_line == null)
				return false;
			this._vertex_mode = 1;
			return true;
		}

		//*** Check distance from previous vertex */
		const previous_vertex = this._tmp_vertices[this._tmp_vertices.length - 2];
		if (cn_dist(this._current_point, previous_vertex.position) < 0.1)
			return false;

		//*** Check intersections on previous lines */
		if (this._tmp_lines.length > 2)
		{
			const origin = previous_vertex.position;
			const ray_dir = cn_sub(this._current_point,origin);
			const ray_dist = cn_normalize(ray_dir)+0.1;
			for (var n=0;n<this._tmp_lines.length-2;n++)
			{
				if (this._tmp_lines[n].raytrace(origin,ray_dir,ray_dist)) return false;
			}
		}

		//*** No U-turn */
		if (this._tmp_lines.length >= 2)
		{
			const dir = this._tmp_lines[this._tmp_lines.length-2].bounds.direction;
			const d = cn_sub(this._current_point,previous_vertex.position);
			cn_normalize(d);
			if (cn_dot(dir,d) < -0.99) return false;
		}

		//*** check start of contour */
		if (this._tmp_lines.length == 1)
		{
			var directions = [];
			if (this._start_line)
				directions.push(this._start_line.bounds.direction,cn_mul(this._start_line.bounds.direction, -1));
			else
			{
				this._tmp_vertices[0].lines.forEach(l => {
					if (l.vertices[0] == this._tmp_vertices[0])
						directions.push(l.bounds.direction);
					else
						directions.push(cn_mul(l.bounds.direction,-1));
				});
			}
			const dd = cn_sub(this._current_point,this._tmp_vertices[0].position);
			cn_normalize(dd);
			if (directions.some(d => cn_dist(d,dd) < 0.01))
				return false;
		}

		//*** check end of contour */
		if (this._mouseover_vertex || this._mouseover_line)
		{
			directions = [];
			if (this._mouseover_vertex)
			{
				this._mouseover_vertex.lines.forEach(l => {
					if (l.vertices[0] == this._mouseover_vertex)
						directions.push(l.bounds.direction);
					else
						directions.push(cn_mul(l.bounds.direction,-1));
				});
			}
			else if (this._mouseover_line)
				directions.push(this._mouseover_line.bounds.direction,cn_mul(this._mouseover_line.bounds.direction, -1));

			const dd = cn_sub(previous_vertex.position, this._current_point);
			cn_normalize(dd);
			if (directions.some(d => cn_dist(d,dd) < 0.01))
				return false;
		}

		//*** Check slab */
		if (this._tmp_lines.length == 1 || (this._mouseover_vertex == null && this._mouseover_line == null))
		{
			const slab = (this._mouseover_vertex || this._mouseover_line)?this._scene.find_slab(cn_middle(this._current_point,previous_vertex.position)):this._scene.find_slab(this._current_point);
			if (slab == null) return false;

			if (this._tmp_lines.length == 1)
				this._current_slab = slab;
			else if (this._current_slab != slab)
				return false;
		}

		//*** move current line end to mouse position
		this._can_close_contour = (this._mouseover_vertex != null || this._mouseover_line != null);
		this._tmp_vertices[this._tmp_vertices.length - 1].position = [this._current_point[0],this._current_point[1]];

		this._vertex_mode = (this._can_close_contour)?3:2;
		return true;
	}

	//***********************************************************************************
	//**** keydown
	//***********************************************************************************

	keydown(ev) {
		if (this._tmp_lines.length == 0) return false;
		if (ev.key == "Escape")
		{
			this._tmp_lines.splice(this._tmp_lines.length-1,1);
			if (this._tmp_lines.length == 0)
				this._tmp_vertices = [];
			else
				this._tmp_vertices.splice(this._tmp_vertices.length-1,1);
			this.update_contour(ev);
			return true;
		}

		return false;
	}

	//***********************************************************************************
	//**** Find mouseover vertex
	//***********************************************************************************
	find_mouseover_vertex(camera, mouse) {
		return super.find_mouseover_vertex(camera,mouse,this._scene.vertices);
	}

	//***********************************************************************************
	//**** Find mouseover line
	//***********************************************************************************

	find_mouseover_line(camera, mouse) {
		return super.find_mouseover_line(camera,mouse,this._scene.lines);
	}

}


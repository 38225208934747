import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { BaseComponent } from '../../utils/base.component';
import { ConfirmationService } from '../../confirmation/confirmation.service';
import { NotificationService } from '../../notification/notification.service';
import { User } from '../../authentication/user.model';
import { UserManagementService } from '../user-management.service';
import { MatDialog } from '@angular/material/dialog';
import { UserDeleteDialogComponent } from '../user-delete-dialog/user-delete-dialog.component';
import { Environment } from '../../environment';
import { UserSetPasswordDialogComponent } from '../user-set-password-dialog/user-set-password-dialog.component';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FileUtils } from '../../utils/file.utils';

@Component({
    selector: 'lib-users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent extends BaseComponent implements OnInit, OnDestroy {
    /** Classe CSS sur le conteneur principal */
    @Input() containerClass = '';

    /** Si les sociétés sont gérées ailleurs, permet de passer les ids des utilisateur membres par nom de chaque société */
    @Input() userIdsByCompaniesNames: Map<string, string[]> = null;

    /** Si les sociétés sont gérées ailleurs, permet de passer les noms des sociétés désactivées */
    @Input() deletedCompanies: string[];

    displayedColumns: string[] = ['email', 'firstName', 'lastName', 'company', 'authorities', 'state', 'actions'];
    dataSource = new MatTableDataSource<User>();

    /** Possibilité de simplement archiver l'utilisateur (en plus de la suppression classique) */
    readonly canArchiveUser = Environment.canArchiveUser;

    /** Possibilité de remplacer l'utilisateur par un autre */
    readonly canReplaceUser = Environment.canReplaceUser;

    /** Possibilité de modifier le mot de passe d'un utilisateur */
    readonly canSetUserPassword = Environment.canSetUserPassword;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    private filterValue$ = new Subject<string>();

    constructor(
        private userManagementService: UserManagementService,
        private confirmationService: ConfirmationService,
        private notificationService: NotificationService,
        private router: Router,
        private dialog: MatDialog
    ) {
        super();
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.userManagementService.findAll().subscribe((users) => {
            if (this.userIdsByCompaniesNames != null) {
                users.forEach((user) => {
                    const userCompanies = [];
                    this.userIdsByCompaniesNames.forEach((userIds, companyName) => {
                        if (userIds.includes(user.id)) {
                            userCompanies.push(companyName);
                        }
                    });
                    if (
                        this.deletedCompanies != null &&
                        userCompanies.length &&
                        userCompanies.every((userCompany) => this.deletedCompanies.includes(userCompany))
                    ) {
                        user['companyDeleted'] = true;
                    }
                    user.company = userCompanies.sort().join(', ');
                });
            }
            this.dataSource.data = users;
        });

        this.filterValue$.pipe(debounceTime(500), takeUntil(this.ngUnsubscribe)).subscribe((filterValue) => {
            this.dataSource.filter = filterValue.trim().toLowerCase();
        });
    }

    exportEmails() {
        this.userManagementService.findAll().subscribe((users) => {
            const emailsList = users
                .filter((it) => it.authorities.length > 0)
                .sort((a, b) => {
                    const splitA = a.email.split('@');
                    const domainA = splitA.length > 1 ? splitA[1] : a.email;
                    const splitB = b.email.split('@');
                    const domainB = splitB.length > 1 ? splitB[1] : b.email;
                    return domainA.localeCompare(domainB);
                })
                .map((it) => it.email)
                .join('\n');
            FileUtils.saveFile(`emailsList_${Environment.appName}`, emailsList, 'text/plain');
        });
    }

    new() {
        this.router.navigate([`/admin/users/new`]);
    }

    edit(user: User) {
        this.router.navigate([`/admin/users/${user.email}`]);
    }

    delete(user: User, archiveOnly: boolean) {
        this.dialog
            .open(UserDeleteDialogComponent, {
                data: {
                    user,
                    archiveOnly,
                    canReplaceUser: this.canReplaceUser,
                },
            })
            .afterClosed()
            .subscribe((dialogResult) => {
                if (dialogResult !== false) {
                    const replacementUserEmail: string = dialogResult.replacementUserEmail
                        ? dialogResult.replacementUserEmail
                        : null;
                    this.userManagementService.delete(user.email, archiveOnly, replacementUserEmail).subscribe(() => {
                        this.notificationService.success(archiveOnly ? 'Utilisateur archivé' : 'Utilisateur supprimé');
                        this.userManagementService.findAll().subscribe((users) => (this.dataSource.data = users));
                    });
                }
            });
    }

    restore(user: User) {
        this.confirmationService.confirm("Voulez-vous restaurer l'utilisateur " + user.email + ' ?', () => {
            // Pour restaurer, on enlève l'id de l'utilisateur à poster et on le recrée (petit hack)
            delete user.id;
            this.userManagementService.create(user).subscribe(() => {
                this.notificationService.success('Utilisateur restauré');
                this.userManagementService.findAll().subscribe((users) => (this.dataSource.data = users));
            });
        });
    }

    changePassword(user: User) {
        this.dialog
            .open(UserSetPasswordDialogComponent, {})
            .afterClosed()
            .subscribe((dialogResult) => {
                if (dialogResult !== false) {
                    const newPassword: string = dialogResult.newPassword;
                    const notifyUserByEmail: boolean = dialogResult.notifyUserByEmail;
                    if (newPassword) {
                        this.userManagementService
                            .setPassword(user.email, newPassword, notifyUserByEmail)
                            .subscribe(() => {
                                this.notificationService.success(
                                    `Le mot de passe de l'utilisateur a bien été mis à jour.`
                                );
                                this.userManagementService
                                    .findAll()
                                    .subscribe((users) => (this.dataSource.data = users));
                            });
                    }
                }
            });
    }

    filterUsers(filterValue: string) {
        this.filterValue$.next(filterValue);
    }
}

<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Reportage photo' | uppercase }}
    </div>
    <div class="block-content px-5 ml-2 mb-5">
        <ng-container *ngIf="currentDiagnostic?.reportagesPhoto?.length === 0">
            <span class="text-center">Aucun reportage photo</span>
        </ng-container>
        <ng-container *ngFor="let reportagePhoto of currentDiagnostic?.reportagesPhoto; let indexReportage = index">
            <mat-divider *ngIf="indexReportage > 0" class="divider"></mat-divider>
            <ng-container
                *ngFor="
                    let t of array(math.ceil(mapImagesFileByReportagePhotoId[reportagePhoto.id]?.length / 4)).fill(4);
                    let i = index
                "
            >
                <app-reportage-photo-edit-preview-page
                    [imagesFilesPage]="mapImagesFileByReportagePhotoId[reportagePhoto.id].slice(4 * i, 4 * i + 4)"
                    [titreReportagePhoto]="i === 0 ? reportagePhoto.nom : undefined"
                ></app-reportage-photo-edit-preview-page>
            </ng-container>
        </ng-container>
    </div>
</div>

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_handler_object
//***********************************************************************************
//***********************************************************************************


import {cn_handler_object} from "./cn_handler_object";
import {cn_handler_wall_drag} from "./cn_handler_wall_drag";
import {cn_handler_space_drag2} from "./cn_handler_space_drag2";
import {cn_object_instance} from "../model/cn_object_instance";

export class cn_initiate_handler_object {
    constructor(object, scene) {
        this.object = object;
        this.scene = scene;
        this._ghost = new cn_object_instance();
    }

    get_handler() {
        this._ghost.object = this.object;
        var anchor_position = null;
        var anchor_normal = null;
        if (this.object.place_on_wall()) {
            anchor_position = this.object.get_anchor_position();
            anchor_normal = this.object.get_anchor_normal();
        }
        var handler_object = new cn_handler_object(this.object.size[0], this.object.size[1], this._ghost.position, this._ghost.orientation * Math.PI / 180, anchor_position, anchor_normal);
        if (anchor_position)
            this._handler = new cn_handler_wall_drag(handler_object, this.scene);
        else
            this._handler = new cn_handler_space_drag2(handler_object, this.scene);

        return this._handler;
    }

}


import { DrawingPicture, IDrawingPictureHandler } from '@acenv/cnmap-angular-editor-lib';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { TypeReferenceFichier } from '../../../../../model/intervention-file.model';
import { catchError, map, startWith, switchMap, tap, take } from 'rxjs/operators';
import { combineLatestOrEmpty } from '../../../../../utils/rxjs.utils';
import { InterventionService } from '../../../../../services/intervention.service';
import { InterventionFileApiService } from '../../../../../services/intervention-file-api.service';
import { InterventionFileService } from '../../../../../services/intervention-file.service';
import { BienService } from '../../../../../services/bien.service';
import { ImageSize, ImageUtils, NotificationService } from 'src/app/commons-lib';
import { FileApiService } from '../../../../../services/file-api.service';
import { blobToDataUrl } from '../../../../../shared/offline/file.utils';
import { cn_background_map } from '@acenv/cnmap-editor';
import { RelationInterventionBien } from '../../../../../model/intervention.model';
import { BackgroundMapApiService } from '../../../../../services/background-map-api.service';
import { EventEmitter, Injectable } from '@angular/core';
import { CnSpinnerService } from '../../../cn-spinner/service/cn-spinner.service';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable({
    providedIn: 'root',
})
export class DrawingPictureHandler implements IDrawingPictureHandler {
    backgroundMaps: DrawingPicture[] = [];
    private reload = new Subject();
    private mapUploaded = new EventEmitter();

    constructor(
        private interventionService: InterventionService,
        private interventionFileApiService: InterventionFileApiService,
        private interventionFileService: InterventionFileService,
        private bienService: BienService,
        private notificationService: NotificationService,
        private fileApiService: FileApiService,
        private backgroundMapApiService: BackgroundMapApiService,
        private cnSpinnerService: CnSpinnerService,
        private imageCompress: NgxImageCompressService
    ) {
        (cn_background_map as any).image_id_to_url = (fileId) => {
            return (this.backgroundMaps.find((bgmu) => bgmu.fileId == fileId) || ({} as DrawingPicture)).imageUrl;
        };
    }
    deletePictures(filesIds: string[]): Observable<any> {
        filesIds.forEach(function (f) {
            this.deletePicture(f);
        });
        return of(null);
    }
    getDrawingPictures(): Observable<DrawingPicture[]> {
        return this.getDrawingPicturesBackgroundMaps();
    }

    deletePicture(fileId: string): Observable<any> {
        this.backgroundMapApiService.deleteBackgroundImage(
            this.interventionService.getCurrentInterventionValue().id,
            this.bienService.getCurrentBienValue().id,
            fileId
        );
        this.getDrawingPicturesBackgroundMaps();
        return of(null);
    }

    renamePicture(fileId: string, fileName: string): Observable<any> {
        let intervention = null;
        this.interventionService
            .getCurrentIntervention()
            .pipe(take(1))
            .subscribe((inter) => (intervention = inter));
        const file = intervention.relationInterventionBiens[0].bien.backgroundMaps.find((bm) => bm.fileId === fileId);
        file.fileName = fileName;
        return this.cnSpinnerService.withSpinner(
            from(this.interventionService.updateIntervention(intervention)).pipe(
                switchMap((v) => of(v)),
                tap((res) => {
                    this.notificationService.success(`L'image a bien été renommée`);
                })
            ),
            `Renommage de l'image en cours...`
        );
    }

    getDrawingPicturesBackgroundMaps(): Observable<DrawingPicture[]> {
        return combineLatest([
            this.bienService.getCurrentBien(),
            this.mapUploaded.asObservable().pipe(startWith(undefined)),
        ]).pipe(
            // tap(() => this.backgroundMaps = []),
            switchMap(([currentBien]) => {
                return this.loadBackgroundmaps(currentBien);
            })
        );
    }

    getDrawingPicturesComments(): Observable<DrawingPicture[]> {
        return this.reload.asObservable().pipe(
            startWith(''),
            switchMap(() =>
                this.bienService.getCurrentBien().pipe(
                    switchMap((currentBien) => {
                        let observable = combineLatestOrEmpty(
                            currentBien.bien.pictures.map((p) => this.fileApiService.downloadFile(p.fileId))
                        ).pipe(
                            map((files) =>
                                (files || [])
                                    .filter((f) => !!f)
                                    .map((file) => ({
                                        fileId: file.fileId,
                                        fileName: file.fileName,
                                        imageUrl: file.fileContent,
                                        alreadyUsed: true,
                                        display: true,
                                        drawingId: '',
                                        usedHasBackgroundMap: 0,
                                        usedHasComment: 1,
                                    }))
                            )
                        );
                        return observable;
                    })
                )
            )
        );
    }

    purgeUnusedDrawingPictures(usedDrawingPicturesFilesIds: string[]): Observable<void> {
        return this.bienService.getCurrentBien().pipe(
            switchMap((bien) =>
                combineLatestOrEmpty(
                    bien.bien.pictures
                        .filter((p) => usedDrawingPicturesFilesIds.indexOf(p.fileId) === -1)
                        .map((p) => this.deleteCommentPicture(p))
                ).pipe(map(() => bien))
            ),
            switchMap((bien) =>
                combineLatestOrEmpty(
                    bien.bien.backgroundMaps
                        .filter((p) => usedDrawingPicturesFilesIds.indexOf(p.fileId) === -1)
                        .map((p) => this.deleteBackgroundImage(p))
                )
            ),
            map((result) => {})
        );
    }

    uploadDrawingPictureFunction(formData: FormData): Observable<HttpEvent<any>> {
        return this.uploadPicture(formData);
    }

    private uploadPicture(picture: FormData): Observable<HttpEvent<any>> {
        const fileData = picture.get('file') as File;
        let backgroundMapSize: ImageSize;
        if (fileData) {
            return this.cnSpinnerService.withSpinner(
                from(blobToDataUrl(fileData)).pipe(
                    switchMap((dataUrl) =>
                        from(this.imageCompress.compressFile(dataUrl, -1, 100, 60)).pipe(
                            switchMap((backgroundmapCompressed) =>
                                forkJoin([
                                    this.backgroundMapApiService.uploadBackgroundImage(
                                        this.interventionService.getCurrentInterventionValue().id,
                                        this.bienService.getCurrentBienValue().id,
                                        backgroundmapCompressed
                                    ),
                                    ImageUtils.getImgSize(dataUrl),
                                ])
                            )
                        )
                    ),
                    switchMap(([imgBg, sizes]) => {
                        console.log(sizes);
                        console.log(imgBg);
                        console.log(fileData);
                        // Récupération du nom du fichier joint
                        const fileName = fileData ? fileData.name.replace(/\.[^/.]+$/, '') : '';
                        const img = imgBg;
                        // Récupération des dimensions du fichier joint
                        backgroundMapSize = sizes as ImageSize;
                        const newBm = new DrawingPicture(img.fileId, '', fileName, new Date().toISOString());
                        // Ajout du nouveau fond de carte au bien
                        const bien = this.bienService.getCurrentBienValue().bien;
                        bien.backgroundMaps = bien.backgroundMaps.concat(newBm);
                        return this.interventionService
                            .updateIntervention(this.interventionService.getCurrentInterventionValue())
                            .pipe(
                                // recharger directement les backgrounds maps, car sinon on ne les voit pas sur la map
                                switchMap(() => this.loadBackgroundmaps(this.bienService.getCurrentBienValue())),
                                map(() => newBm)
                            );
                    }),

                    tap((res) => {
                        this.notificationService.success(`L'image a bien été ajouté`);
                        // this.reloadIntervention();
                    }),
                    map((newBm) => new HttpResponse<DrawingPicture>({ body: newBm }))
                ),
                `Chargement de l'image en cours...`
            );
        } else {
            return of(new HttpResponse());
        }
    }

    private deleteCommentPicture(commentPictures: DrawingPicture): Observable<boolean> {
        const currentIntervention = this.interventionService.getCurrentInterventionValue();
        return this.interventionFileApiService
            .findByIdInterventionFileId(
                currentIntervention.id,
                TypeReferenceFichier.PHOTO_COMMENTAIRE_PLAN,
                commentPictures.fileId
            )
            .pipe(
                switchMap((interventionFiles) =>
                    combineLatestOrEmpty(
                        interventionFiles.map((iF) => this.interventionFileService.deleteInterventionFile(iF, true))
                    )
                ),
                switchMap(() => {
                    // TODO sauvegarder le bien !!
                    this.bienService.getCurrentBienValue().bien.pictures = this.bienService
                        .getCurrentBienValue()
                        .bien.pictures.filter((it) => it.fileId !== commentPictures.fileId);
                    return this.interventionService.updateIntervention(
                        this.interventionService.getCurrentInterventionValue()
                    );
                }),
                tap(() => this.notificationService.success('La photo a bien été supprimée')),
                tap(() => this.reload.next()),
                map(() => true),
                catchError((error) => {
                    console.log(error);
                    return of(false);
                })
            );
    }

    private loadBackgroundmaps(currentBien: RelationInterventionBien): Observable<DrawingPicture[]> {
        if (currentBien.bien.backgroundMaps.length > 0) {
            return forkJoin(
                currentBien.bien.backgroundMaps
                    .slice()
                    .sort((a, b) => a.createdDate.localeCompare(b.createdDate))
                    .map((bg) => {
                        return this.backgroundMapApiService
                            .downloadBackgroundImage(
                                this.interventionService.getCurrentInterventionValue().id,
                                currentBien.id,
                                bg.fileId
                            )
                            .pipe(
                                map((res) => {
                                    const background = { ...bg };
                                    if (res) {
                                        background.imageUrl = res.fileContent;
                                    }
                                    return background;
                                })
                            );
                    })
            ).pipe(
                map((backgrounds) => {
                    this.backgroundMaps = backgrounds;
                    return this.backgroundMaps;
                })
            );
        } else {
            this.backgroundMaps = [];
            return of(this.backgroundMaps);
        }
    }

    /**
     * Supprime le fond de plan sélectionné ainsi que l'image associée
     */
    private deleteBackgroundImage(backgroundMap: DrawingPicture): Observable<boolean> {
        return this.backgroundMapApiService
            .deleteBackgroundImage(
                this.interventionService.getCurrentInterventionValue().id,
                this.bienService.getCurrentBienValue().id,
                backgroundMap.fileId
            )
            .pipe(
                map(() => {
                    this.bienService.getCurrentBienValue().bien.backgroundMaps = this.bienService
                        .getCurrentBienValue()
                        .bien.backgroundMaps.filter((it) => it.fileId !== backgroundMap.fileId);
                    this.interventionService
                        .updateIntervention(this.interventionService.getCurrentInterventionValue())
                        .subscribe(() => {
                            this.notificationService.success('Le fond de carte a bien été supprimé');
                        });
                    return true;
                }),
                switchMap(() => {
                    return this.loadBackgroundmaps(this.bienService.getCurrentBienValue());
                }),
                map(() => true),
                catchError(() => of(false))
            );
    }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/commons-lib';
import { BehaviorSubject } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { EtatWorkflow } from 'src/app/model/etat-workflow.model';
import { ReferencePrestation } from 'src/app/model/reference-prestation.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { ReferenceApiService } from 'src/app/services/reference-api.service';
import { ReferenceService } from 'src/app/services/reference.service';
import {
    SESSION_STORAGE_KEY_PRESTATION,
    DEBOUNCE_TIME_VALUE,
    INITIAL_PAGE_SIZE,
} from 'src/app/shared/constants/admin.constants';
import { PaginatedDataSource } from 'src/app/shared/paging/page';
import { SessionStoragePaginationUtils } from 'src/app/utils/session-storage-pagination.utils';
import {
    URL_GESTION_PRESTATIONS_CONSULTER,
    URL_GESTION_PRESTATIONS_EDIT,
} from 'src/app/shared/constants/url.constants';

export const DEFAULT_SORT = { direction: 'asc', active: 'nom' };

@Component({
    selector: 'app-tableau-diagnostics',
    templateUrl: './tableau-diagnostics.component.html',
    styleUrls: ['./tableau-diagnostics.component.scss'],
})
export class TableauDiagnosticsComponent extends BaseComponent implements OnInit {
    @Input() initialPageSize = INITIAL_PAGE_SIZE;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    displayedColumns: string[] = ['nom', 'idSalesforce', 'typePrestation', 'etatPrestation', 'actions'];

    dataSource: PaginatedDataSource<ReferencePrestation>;
    sort = SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_PRESTATION, DEFAULT_SORT);
    searchValue = SessionStoragePaginationUtils.getInitialReseach(SESSION_STORAGE_KEY_PRESTATION);
    private search = new BehaviorSubject(this.searchValue);

    constructor(
        private readonly referenceService: ReferenceService,
        private readonly referenceApiService: ReferenceApiService,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly router: Router
    ) {
        super();
    }

    ngOnInit(): void {
        this.search
            .pipe(debounceTime(DEBOUNCE_TIME_VALUE), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
            .subscribe((search) => {
                this.dataSource.fetch(0, this.dataSource.size);
            });
        this.dataSource = new PaginatedDataSource<ReferencePrestation>(
            (pageRequest) => {
                return this.cnSpinnerService.withSpinner(
                    this.referenceApiService.findAllReferencePrestationsPage(this.search.getValue(), pageRequest)
                );
            },
            SessionStoragePaginationUtils.getInitialSort(SESSION_STORAGE_KEY_PRESTATION, DEFAULT_SORT),
            SessionStoragePaginationUtils.getInitialPageSize(SESSION_STORAGE_KEY_PRESTATION, this.initialPageSize)
        );
    }

    onClickDupliquerPrestationDiagnostic(prestation: ReferencePrestation) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_PRESTATIONS_EDIT, prestation.id, 'duplicate']);
    }

    onClickEditerPrestationDiagnostic(prestation: ReferencePrestation) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_PRESTATIONS_EDIT, prestation.id]);
    }

    onClickConsulter(prestation: ReferencePrestation) {
        this.setSessionStorageItem();
        this.router.navigate([URL_GESTION_PRESTATIONS_CONSULTER, prestation.id]);
    }

    onClickChangerEtatPrestationDiagnostic(prestation: ReferencePrestation) {
        this.referenceService.findOneReferencePrestation(prestation.id, false).subscribe((ref) => {
            ref.etatPrestation = ref.etatPrestation === EtatWorkflow.ACTIF ? EtatWorkflow.INACTIF : EtatWorkflow.ACTIF;
            this.referenceApiService.updateReferencePrestation(ref).subscribe(() => {
                prestation.etatPrestation = ref.etatPrestation;

                // On réinitialise la liste des referencePrestation dans le cache de l'appli
                this.referenceApiService.buildReferencePrestation$();
            });
        });
    }

    /**
     * Set les valeurs du tableau dans le session storage (pagination, recherche, tri ..)
     * @private
     */
    private setSessionStorageItem() {
        SessionStoragePaginationUtils.setSessionStorageItem(
            SESSION_STORAGE_KEY_PRESTATION,
            this.dataSource.size,
            this.search.getValue(),
            this.sort
        );
    }

    /**
     * Action déclenchée lors de la saisie d'un caractère dans la barre de recherche
     * @param $event
     */
    onKeyupSearchDiagnotics($event: any) {
        this.search.next($event.target.value);
        this.setSessionStorageItem();
    }

    /**
     * Action déclenchée lors d'un tri dans le tableau
     * @param $event
     */
    onChangeSortDiagnotics($event: Sort) {
        this.sort = $event;
        this.dataSource.sortBy($event);
        this.setSessionStorageItem();
    }
}

import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ArrayUtils, BaseComponent } from 'src/app/commons-lib';
import { StateChoiceBoxes } from '../../../model/states.model';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { notValidatedOption, validatedOption } from '../../../shared/constants/states.constants';

@Component({
    selector: 'app-state-input',
    templateUrl: './state-input.component.html',
    styleUrls: ['./state-input.component.scss'],
})
export class StateInputComponent extends BaseComponent implements ControlValueAccessor {
    /** Liste des choix */
    @Input() set choices(choices: StateChoiceBoxes[]) {
        this._choices = choices;
        if (this.choiceValueToSet) {
            this.setChoiceValue(this.choiceValueToSet, false);
            this.choiceValueToSet = null;
        }
    }

    /** Valeurs actuellement sélectionnées */
    @Input()
    set choiceValue(choiceValue: string | boolean | (string | boolean)[]) {
        if (this._choices) {
            this.setChoiceValue(choiceValue, false);
        } else {
            this.choiceValueToSet = [].concat(choiceValue);
        }
    }

    /** Multisélection */
    @Input()
    multiple = false;

    /** Si multisélection, choix exclusifs (si sélectionnés, annulent les autres) */
    @Input()
    exclusiveChoices: StateChoiceBoxes[] = [];

    /** Afficher les grosses icônes et les labels ? */
    @Input()
    labels = false;

    /** Afficher les grosses icônes et les labels ? */
    @Input()
    persistentColor = false;

    @Input()
    tooltips: string[] = [];

    /** Sélection globale (multiline ?) */
    @Input()
    globalStateInput = false;

    /** Désactive ? */
    @Input()
    disabled = false;

    /** Peut-on décocher un état ? */
    @Input()
    reset = false;

    /** Classe CSS sur le conteneur global (par défaut "px-2") */
    @Input()
    containerClass: any = 'px-2';

    /** Taille de la marge entre les éléments, entre 1 et 5 (par défaut "1" si label, "0" sinon) */
    @Input()
    spaceBetweenLabels: '0' | '1' | '2' | '3' | '4' | '5' | undefined = undefined;

    /** Changement de valeur */
    @Output()
    choiceValueChange = new EventEmitter<string | boolean | (string | boolean)[]>();

    /**
     * Changement de la valeur par utilisateur (non émit si le changement vient de l'@Input)
     */
    @Output()
    valueChangeFromUser = new EventEmitter<string | boolean | (string | boolean)[]>();

    currentChoicesValues: (string | boolean)[] = [];

    _choices: StateChoiceBoxes[] = [validatedOption, notValidatedOption];

    private choiceValueToSet: (string | boolean)[];

    value: any = '';

    constructor(
        @Self()
        @Optional()
        private ngControl: NgControl
    ) {
        super();
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    clickChoiceValue(value: string) {
        if (this.disabled) {
            return;
        }
        if (this.globalStateInput) {
            // Les boutons doivent se comporter comme des boutons, et non comme des radiobuttons. La value n'est donc pas stockée, mais juste émise.
            this.choiceValueChange.emit(value);
        } else {
            // Les boutons se comportent comme des radiobuttons. La value est stockée, et émise.
            const exclusivesChoicesValues = this.exclusiveChoices.map((it) => it.value);

            if (exclusivesChoicesValues.includes(value)) {
                // Choix exclusif
                this.setChoiceValue(value, true);
            } else if (this.currentChoicesValues.includes(value)) {
                // Choix non-exclusif déjà inclu dans les choix courants
                if (this.reset) {
                    // Reset
                    this.setChoiceValue(
                        this.currentChoicesValues.filter((it) => it != value),
                        true
                    );
                } else {
                    // Reset = false => Rien à faire, le choix est déjà sélectionné et on ne peut pas le décocher
                }
            } else {
                // Choix non-exclusif non inclu dans les choix courants
                if (this.multiple) {
                    this.setChoiceValue(
                        this.currentChoicesValues.filter((it) => !exclusivesChoicesValues.includes(it)).concat(value),
                        true
                    );
                } else {
                    this.setChoiceValue(value, true);
                }
            }
        }
    }

    /**
     * Write form value to the DOM element (model => view)
     */
    writeValue(value: any): void {
        this.value = value;
    }

    /**
     * Write form disabled state to the DOM element (model => view)
     */
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Update form when DOM element value changes (view => model)
     */
    registerOnChange(fn: any): void {
        // Store the provided function as an internal method.
        this.onChange = fn;
    }

    /**
     * Update form when DOM element is blurred (view => model)
     */
    registerOnTouched(fn: any): void {
        // Store the provided function as an internal method.
        this.onTouched = fn;
    }

    onChange(value) {}

    private onTouched() {}

    private setChoiceValue(choiceValue: string | boolean | (string | boolean)[], fromUser: boolean) {
        const choiceValues = [].concat(choiceValue);
        let changed = false;
        if (!ArrayUtils.arraysContainsSameElements(this.currentChoicesValues, choiceValues)) {
            changed = true;
        }
        this.currentChoicesValues = choiceValues;
        if (changed) {
            this.onChange(choiceValue);
            this.choiceValueChange.emit(choiceValue);
            if (fromUser) {
                this.onChange(choiceValue);
                this.valueChangeFromUser.emit(choiceValue);
            }
        }
    }
}

"use strict";

import {cn_element} from './cn_element';
import {cn_facing_layer} from './cn_facing_layer';

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************



//***********************************************************************************
/**
 * @class cn_facing
 * Class to modelize the facing of a wall, slab, etc.
 */
export class cn_facing extends cn_element {
	//***********************************************************************************
	/**
	 * Constructor
     * @param { 'floor' | 'ceiling' | 'wall' | 'facade' | 'exterior' } support
	 */
	constructor(parent, support) {
        super(parent);
        this.support = support
		this.name = '';
        this.category = '';
        this.texture = '';
        this.color = '';
        this.description = '';
        this.facing_layers = [];
	}

	//***********************************************************************************
	/**
	 * serialize
	 * @returns {object}
	 */
	serialize() {
		const json = {};
        json.ID = this.ID;
		json.name = this.name;
        json.support = this.support;
        json.description = this.description;
        json.texture = this.texture;
        json.color = this.color;
        json.category = this.category;
        json.facing_layers = this.facing_layers.map(layer => layer.serialize());
		return json;
	}

	//***********************************************************************************
	/**
	 * unserialize
     * @param {cn_element} parent
	 * @param {object} json
	 * @returns {cn_facing | null}
	 */
	static unserialize(parent, json) {
		const result = new cn_facing(parent, json.support);
        result.ID = json.ID;
        result.name = json.name;
        result.description = json.description;
        result.category = json.category;
        result.texture = json.texture;
        result.facing_layers = json.facing_layers.map(layer => cn_facing_layer.unserialize(layer));
        result.color = json.color;
		return result;
	}

}


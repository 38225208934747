"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_tool wall : a tool to build walls
//***********************************************************************************
//***********************************************************************************

import {cn_svg_tool_edition} from "./cn_svg_tool_edition";
import {cn_opening} from "../model/cn_opening";
import {cn_opening_handler} from "./cn_opening_handler";

export class cn_svg_tool_opening_edition extends cn_svg_tool_edition {
	constructor(map) {
		super(map);
	}

	//***********************************************************************************
	//**** Selection callback
	//***********************************************************************************
	on_selection_change() {
		const obj = this;
		this._handlers = [];
		
		//*** We expect the selection to contain only openings */
		var sel = this._controller.get_selection();
		if (sel.length == 0 || sel.some(elt => elt.constructor != cn_opening)) 
			return false;

		if (sel.length == 1) console.log();
		this._handlers.push(new cn_opening_handler(sel,this._map));
		return true;
	}
}


import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LaboratoireAdmin } from '../../../../../model/laboratoire.model';
import { BaseComponent, ConfirmationService } from 'src/app/commons-lib';
import { DATE_FORMAT_FRANCAIS_SANS_HEURE } from 'src/app/shared/constants/cndiag.constants';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
    PrelevementEchantillonageAdmin,
    ResultatAnalyse,
    typeAnalyseToLabelSimple,
} from '../../../../../model/prelevement-generique.model';

class EchantillonResultatModalData {
    constructor(public echantillonToEdit: PrelevementEchantillonageAdmin) {}
}

@Component({
    selector: 'app-edit-resultat-modal',
    templateUrl: './edit-resultat-modal.component.html',
    styleUrls: ['./edit-resultat-modal.component.scss'],
})
export class EditResultatModalComponent extends BaseComponent implements OnInit {
    form: FormGroup;
    laboratoires: LaboratoireAdmin[] = [];
    echantillonToEdit = new PrelevementEchantillonageAdmin();
    listTypesAnalyse: any[];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns = ['typeAnalyse', 'methodeAnalyse', 'description', 'number', 'unit', 'dateAnalyse', 'actions'];
    dataSource;
    editMode = false;

    dateAnalyseCommune = null;
    readonly DATE_FORMAT_FRANCAIS_SANS_HEURE = DATE_FORMAT_FRANCAIS_SANS_HEURE;
    private resultatToEdit: ResultatAnalyse;

    constructor(
        public readonly dialogRef: MatDialogRef<EditResultatModalComponent>,
        private readonly formBuilder: FormBuilder,
        private readonly confirmationService: ConfirmationService,
        @Inject(MAT_DIALOG_DATA) private readonly data: EchantillonResultatModalData
    ) {
        super();
        dialogRef.disableClose = true;
        this.createForm();
    }

    ngOnInit(): void {
        this.dialogRef.addPanelClass('minwidth1000-dialog');
        this.initDataSource([]);
        if (this.data) {
            this.echantillonToEdit = this.data.echantillonToEdit;
            this.listTypesAnalyse = this.echantillonToEdit.typesAnalyse
                .map((it) => {
                    return { value: it, label: typeAnalyseToLabelSimple(it) };
                })
                .sort((a, b) => a.label.localeCompare(b.label));
            this.initDataSource(this.echantillonToEdit.resultatsAnalyse);
        }
    }

    /**
     * Action déclenchée lors de l'envoi du formulaire
     */
    onSubmit() {
        if (this.form.valid) {
            // Edition
            if (this.editMode && this.echantillonToEdit) {
                this.resultatToEdit = this.prepareData(this.resultatToEdit);
                const index = this.echantillonToEdit.resultatsAnalyse.findIndex(
                    (res) => res.id === this.resultatToEdit.id
                );
                this.echantillonToEdit.resultatsAnalyse[index] = this.resultatToEdit;
            } else {
                // Création
                let newResult = new ResultatAnalyse();
                newResult = this.prepareData(newResult);
                this.echantillonToEdit.resultatsAnalyse.push(newResult);
            }
            this.form.reset();
            this.initDataSource(this.echantillonToEdit.resultatsAnalyse);
            this.editMode = false;
            this.resultatToEdit = null;
        }
    }

    /**
     * Action lors du click sur annuler
     */
    onClickCancel() {
        this.dialogRef.close(false);
    }

    /**
     * Initialisation du formulaire
     */
    private createForm() {
        this.form = this.formBuilder.group({
            typeAnalyse: [null, Validators.required],
            methodeAnalyse: [''],
            description: [''],
            number: [''],
            unit: [''],
            dateAnalyse: [''],
        });
    }

    /**
     * Prépare les données du formulaire
     */
    private prepareData(resultat: ResultatAnalyse): ResultatAnalyse {
        resultat.typeAnalyse = this.form.value.typeAnalyse.value;
        resultat.methodeAnalyse = this.form.value.methodeAnalyse;
        resultat.description = this.form.value.description;
        resultat.number = this.form.value.number;
        resultat.unit = this.form.value.unit;
        resultat.dateAnalyse = this.form.value.dateAnalyse;
        this.dateAnalyseCommune = this.form.value.dateAnalyse;
        this.echantillonToEdit.resultatsAnalyse.forEach((res) => (res.dateAnalyse = this.dateAnalyseCommune));
        return resultat;
    }

    initDataSource(values: any[]) {
        if (values) {
            this.prepareValues(values);
            this.dataSource = new MatTableDataSource(values);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }
    }

    onClickEditResultat(row: ResultatAnalyse) {
        this.editMode = true;
        this.resultatToEdit = row;
        this.form.patchValue({
            typeAnalyse: this.listTypesAnalyse.find((it) => it.value === this.resultatToEdit.typeAnalyse),
            methodeAnalyse: this.resultatToEdit.methodeAnalyse,
            description: this.resultatToEdit.description,
            number: this.resultatToEdit.number,
            unit: this.resultatToEdit.unit,
            dateAnalyse: this.resultatToEdit.dateAnalyse,
        });
        this.form.updateValueAndValidity();
    }

    onClickDeleteResultat(row: ResultatAnalyse) {
        this.confirmationService.confirmWarn('Voulez-vous supprimer ce résultat ?', () => {
            this.echantillonToEdit.resultatsAnalyse = this.echantillonToEdit.resultatsAnalyse.filter(
                (it) => it.id !== row.id
            );
            this.initDataSource(this.echantillonToEdit.resultatsAnalyse);
        });
    }

    validateModifications() {
        this.dialogRef.close(this.echantillonToEdit);
    }

    private prepareValues(resultats: any[]) {
        const resultat = resultats?.find((res) => res.dateAnalyse !== null);
        this.dateAnalyseCommune = resultat ? resultat.dateAnalyse : null;
        this.form.patchValue({
            dateAnalyse: this.dateAnalyseCommune,
        });
        this.form.updateValueAndValidity();
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from './user.model';
import { Environment } from '../environment';
import { isServerUnavailableError } from '../http/http-error.service';
import { AuthenticationStore } from './authentication.store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class AuthenticationService {
    constructor(
        private http: HttpClient,
        private authenticationStore: AuthenticationStore,
        private notificationService: NotificationService
    ) {}

    login(value: { email: string; password: string; rememberMe?: boolean }): Observable<User> {
        return this.http
            .post(`${Environment.apiUrl}/authenticate`, {
                username: value.email.trim(),
                password: value.password,
                rememberMe: value.rememberMe == true,
            })
            .pipe(switchMap(() => this.queryAndSetCurrentUser()));
    }

    logout(): Observable<void> {
        return this.http.post<void>(`${Environment.apiUrl}/logout`, {}).pipe(tap(() => this.unsetCurrentUser()));
    }

    /**
     * Vérifie si l'utilisateur est authentifié, et renseigne l'utilisateur courant dans le store le cas échéant.
     */
    queryAndSetCurrentUser(): Observable<User> {
        return this.http.get<User>(`${Environment.apiUrl}/account`).pipe(
            tap((user) => this.authenticationStore.setCurrentUser(user)),
            catchError((err) => {
                // En cas d'erreur (ex : token invalide), sauf service indisponible, on logout
                if (!isServerUnavailableError(err)) {
                    this.notificationService.dismiss(); // Enlève la notification d'erreur 401 dans ce cas
                    return this.logout().pipe(map(() => null));
                } else {
                    return of(null);
                }
            })
        );
    }

    private unsetCurrentUser() {
        this.authenticationStore.unsetCurrentUser();
    }
}

/**
 * TypePrestation
 *
 * @title Type de la prestation
 * @description Correspond au type de la prestation
 */
export type TypePrestation =
    /** Commun */
    | 'COMMUN'
    /** Assainissement */
    | 'ASSAINISSEMENT'
    /** Mesurage */
    | 'MESURAGE'
    /** Mesurage habitable */
    | 'MESURAGE_HABITABLE'
    /** Mesurage utile */
    | 'MESURAGE_UTILE'
    /** HAP */
    | 'HAP_VALIDATION'
    /** HAP partie 1 : Etude de situation */
    | 'HAP_ETUDE_SITUATION'
    /** HAP partie 2 : Visite de reconnaissance */
    | 'HAP_VISITE_RECONNAISSANCE'
    /** HAP partie 3 : Partie terrain */
    | 'HAP_TERRAIN'
    /** HAP : Validation des résultats */
    | 'HAP_VALIDATION_RESULTATS'
    /** Électricité */
    | 'ELECTRICITE'
    /** CEE */
    | 'BAREN101'
    | 'BAREN102'
    | 'BAREN103'
    | 'BARTH160'
    | 'BARTH161'
    /** Polluant étude de situation */
    | 'POLLUANT_ETUDE_SITUATION'
    /** Polluant visite chantier */
    | 'POLLUANT_VISITE_CHANTIER'
    /** Polluant prélèvement */
    | 'POLLUANT_PRELEVEMENT'
    /** Polluant rédaction stratégie */
    | 'POLLUANT_REDACTION_STRATEGIE'
    /** Polluant résultats d'analyses */
    | 'POLLUANT_RESULTATS_ANALYSES';
// À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRESTATIONS...

/**
 * Types de prestation (version itérable)
 */
export const typesPrestation: TypePrestation[] = [
    'COMMUN',
    'ASSAINISSEMENT',
    'MESURAGE',
    'MESURAGE_HABITABLE',
    'MESURAGE_UTILE',
    'HAP_VALIDATION',
    'HAP_ETUDE_SITUATION',
    'HAP_VISITE_RECONNAISSANCE',
    'HAP_TERRAIN',
    'HAP_VALIDATION_RESULTATS',
    'ELECTRICITE',
    'BAREN101',
    'BAREN102',
    'BAREN103',
    'BARTH160',
    'BARTH161',
    'POLLUANT_ETUDE_SITUATION',
    'POLLUANT_VISITE_CHANTIER',
    'POLLUANT_PRELEVEMENT',
    'POLLUANT_REDACTION_STRATEGIE',
    'POLLUANT_RESULTATS_ANALYSES',
    // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRESTATIONS...
];

/**
 * À partir d'un type de prestation, renvoie la route Angular associée,
 * en mettant en minuscule et en remplaçant les underscore par des tirets
 */
export function typePrestationToPath(typePrestation: TypePrestation): string {
    return typePrestation.toLowerCase().replace(/_/g, '-');
}

/**
 * À partir d'un type de prestation, renvoie le label associé.
 */
export function typePrestationToLabel(typePrestation?: string | TypePrestation): string {
    switch (typePrestation) {
        case 'COMMUN':
            return 'Commun';
        case 'ASSAINISSEMENT':
            return 'Assainissement';
        case 'MESURAGE':
            return 'Mesurage Loi Carrez';
        case 'MESURAGE_HABITABLE':
            return 'Mesurage habitable';
        case 'MESURAGE_UTILE':
            return 'Mesurage utile (brute et nette)';
        case 'HAP_VALIDATION':
            return 'HAP Validation';
        case 'HAP_ETUDE_SITUATION':
            return 'HAP Étude de situation';
        case 'HAP_VISITE_RECONNAISSANCE':
            return 'HAP Visite de reconnaissance';
        case 'HAP_TERRAIN':
            return 'HAP Terrain';
        case 'HAP_VALIDATION_RESULTATS':
            return 'HAP Validation des résultats';
        case 'ELECTRICITE':
            return 'Électricité';
        case 'BAREN101':
            return 'Isolation de combles ou de toitures [BAR-EN-101]';
        case 'BAREN102':
            return 'Isolation des murs [BAR-EN-102]';
        case 'BAREN103':
            return 'Isolation d’un plancher [BAR-EN-103]';
        case 'BARTH160':
            return "Isolation d'un réseau hydraulique de chauffage ou d'eau chaude sanitaire [BAR-TH-160]";
        case 'BARTH161':
            return "Isolation de points singuliers d'un réseau [BAR-TH-161]";
        case 'POLLUANT_ETUDE_SITUATION':
            return 'Polluant (Etude de situation)';
        case 'POLLUANT_VISITE_CHANTIER':
            return 'Polluant (Visite de chantier)';
        case 'POLLUANT_PRELEVEMENT':
            return 'Polluant (Prélèvement)';
        case 'POLLUANT_REDACTION_STRATEGIE':
            return 'Polluant (Rédaction de stratégie)';
        case 'POLLUANT_RESULTATS_ANALYSES':
            return "Polluant (Résultats d'analyses)";
        // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE PRESTATIONS...
        default:
            return '';
    }
}

export function getGroupPrestation(typePrestation: String | TypePrestation): String {
    if (
        typePrestation == 'POLLUANT_ETUDE_SITUATION' ||
        typePrestation == 'POLLUANT_VISITE_CHANTIER' ||
        typePrestation == 'POLLUANT_PRELEVEMENT' ||
        typePrestation == 'POLLUANT_REDACTION_STRATEGIE' ||
        typePrestation == 'POLLUANT_RESULTATS_ANALYSES'
    ) {
        return 'POLLUANT';
    } else return typePrestation;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorI18nFr } from '../utils/mat-paginator-i18n';

/**
 * Module de personnalisation du datepicker Material pour un fonctionnement correct en français
 */
@NgModule({
    declarations: [],
    imports: [CommonModule, MatDatepickerModule, MatMomentDateModule],
    exports: [MatDatepickerModule, MatMomentDateModule],
    providers: [
        // Configuration Angular Material
        // -> look & feel
        {
            provide: MAT_TABS_CONFIG,
            useValue: { animationDuration: '0ms' },
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'standard' },
        },
        {
            provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
            useValue: { appearance: 'standard' },
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        {
            provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        },
        // -> paginator en français
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorI18nFr,
        },
        // -> configuration du datepicker Material pour un fonctionnement correct en français
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'fr-FR',
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {
                useUtc: true,
            },
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['DD/MM/YYYY'],
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'DD/MM/YYYY',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
})
export class LibMaterialConfigModule {}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** a tool to build area trimmings
//***********************************************************************************
//***********************************************************************************

import { CN_AREA_REGULATIONS } from "../model/cn_area_context";
import { cn_area_trimming } from "../model/cn_area_trimming";
import { cn_contour } from "../model/cn_contour";
import { cn_element } from "../model/cn_element";
import { cn_vertex } from "../model/cn_vertex";
import { cn_area_trimming_handler } from "./cn_area_trimming_handler";
import { cn_camera } from "./cn_camera";
import { cn_edition_handler } from "./cn_edition_handler";
import { cn_mouse_event } from "./cn_mouse_event";
import { cn_polygon_handler } from "./cn_polygon_handler";
import { cn_svg_tool_creation } from "./cn_svg_tool_creation";

export class cn_svg_tool_area_creation extends cn_svg_tool_creation {
	constructor(map) {
		super(map);
		this._creation_handler = null;
		this._trimming_edition_handler = null;
		//this.initiate_creation();
		this._mouseover_space = null;
		this._mouseover_trimming = null;

		const ac = CN_AREA_REGULATIONS[0];
		this._current_area_context = this._map._building.check_area_context(ac.code);
		this._sub_area_index = 1;

		this._pending_changes = null;

		this.grabbed=false;
	}

	//***********************************************************************************
	/**
	 * Function to call when opening tool. Inistializes context
	 */
	open_tool() {
		super.open_tool();
		this.set_current_area_context(this._current_area_context.label);
	}

	//***********************************************************************************
	/**
	 * Function to call when opening tool. Inistializes context
	 */
	close_tool() {
		super.close_tool();
		if (this._map._space_labelizer) this._map._space_labelizer.set_area_context(null);
	}

	//*****************************************************************
	/**
	 * Must return 'true' if wa are in modal creation.
	 * @returns {boolean}
	 */
	is_creating() {
		if (this._creation_handler)
			return true;
		return false;
	}

	//***********************************************************************************
	/**
	 * Returns all active area contexts in the building
	 * @returns {string[]}
	 */
	get_area_contexts() {
		return CN_AREA_REGULATIONS.map(r => r.label);
	}

	//***********************************************************************************
	/**
	 * Sets current area context, by its label.
	 * @param {string} label
	 */
	set_current_area_context(label) {
		const ca = CN_AREA_REGULATIONS.find(ac => ac.label == label);
		if (!ca) return;
		this._current_area_context = this._map._building.check_area_context(ca.code);
		this._current_area_context.update_deep(this._map._storey);
		if (this._map._space_labelizer) this._map._space_labelizer.set_area_context(this._current_area_context);
		this._sub_area_index = 0;
		this.stop_area_trimming();
		this._terminate_edition();

	}

	//***********************************************************************************
	/**
	 * returns current area context label
	 * @returns {string}
	 */
	get_current_area_context() {
		if (!this._current_area_context) return "";
		return this._current_area_context.label;
	}

	//***********************************************************************************
	/**
	 * returns the list of sub labels of current context
	 * @returns {string[]}
	 */
	get_area_sub_contexts() {
		if (!this._current_area_context) return [];
		if (!this._current_area_context.sub_labels.length) return [this._current_area_context.label];
		return this._current_area_context.sub_labels;
	}

	//***********************************************************************************
	/**
	 * Returns current sub area index
	 * @returns {number}
	 */
	get_sub_area_index() {
		return this._sub_area_index;
	}

	//***********************************************************************************
	/**
	 * Sets index for current sub area
	 * @param {number} i
	 */
	set_sub_area_index(i) {
		this.stop_area_trimming();
		this._sub_area_index = i;
	}

	//***********************************************************************************
	/**
	 * Starts area trimming
	 * @param {number} level : level of trimming
	 */
	start_area_trimming(level) {
		this.initiate_creation(level);
	}

	//***********************************************************************************
	/**
	 * Stops area trimming
	 */
	stop_area_trimming() {
		if (!this._creation_handler) return;
		this.remove_handler(this._creation_handler);
		this._creation_handler = null;
	}

	//***********************************************************************************
	/**
	 * Refresh.
	 * @param {cn_camera} camera
	 * @returns {string} svg code
	 */
	draw(camera) {
		var html = "";
		var obj=this;

		//*** Draw spaces with special colors */
		if (this._current_area_context)
			html += this._current_area_context.draw(this._map._storey,camera);

		//*** Draw area trimmings */
		this._scene.area_trimmings.forEach(function(at) {
			html += at.draw(camera);
		});

		//*** Draw space highlight */
		if (this._mouseover_space)
			html += this._mouseover_space.draw(camera,["mouseover"]);

		html += super.draw(camera);

		return html;
	}

	//***********************************************************************************
	/** clear move effects
	*/
	clear_move() {
		this._mouseover_space = null;
		super.clear_move();
	}

	//***********************************************************************************
	/**
	 * Click callback
	 * @param {object} ev
	 * @returns {boolean} true if the process uses the callback
	 */
	click(ev) {
		if (super.click(ev)) return true;

		this._terminate_edition();

		//*** Apply mouse area type change */
		if (this._current_area_context && this._mouseover_space)
		{
			var current_area_context=this._current_area_context;
			var obj=this;
			this.push_transaction("Type de surface","",function(){current_area_context.update_deep(obj._map._storey);});

			//*** Maybe clear area */
			if (this._current_area_context.get_sub_level(this._mouseover_space) == this._sub_area_index)
			{
				this._current_area_context.remove_space(this._mouseover_space,this._map._building.transaction_manager);
				console.log("remove space");
			}
			//*** Or set area */
			else
			{
				this._current_area_context.add_space(this._mouseover_space,this._sub_area_index,this._map._building.transaction_manager);
				console.log("add space");
			}
			this._current_area_context.update_deep(this._map._storey);
			return true;
		}
		return false;
	}

	//***********************************************************************************
	/**
	 * Grab callback
	 * @param {object} ev
	 * @returns {boolean} true if the process uses the callback
	 */
	grab (ev) {
		if (super.grab(ev)) return true;
		this._terminate_edition();
		return false;
	}

	//***********************************************************************************
	/**
	 * Move callback
	 * @param {object} ev
	 * @returns {boolean} true if the process uses the callback
	 */
	move (ev) {
		this._mouseover_space = null;
		if (super.move(ev)) return true;
		this._mouseover_space = this._scene.find_space(ev.mouse_world,true);
		if (this._mouseover_space && !this._mouseover_space.indoor) this._mouseover_space = null;
		return this._mouseover_space != null;
	}

	//***********************************************************************************
	/**
	 * Initiate creation of area trimming
	 * @param {number} level: level of trimming
	 */
	initiate_creation(level) {
		if (this._creation_handler)
		{
			const index = this._handlers.indexOf(this._creation_handler);
			if (index >= 0) this._handlers.splice(index,1);
		}
		this._trimming_edition_handler = null;
		var obj = this;
		var scene = obj._scene;
		var current_area_context = this._current_area_context;
		this._creation_handler = cn_polygon_handler.create_rectangle(this,2);
		this._handlers.unshift(this._creation_handler);

		this._creation_handler.snap_elements = scene.spaces;

		this._creation_handler.on("end_creation",function() {
			var area_trimming = new cn_area_trimming(scene);
			area_trimming.level = level;
			var ctr = new cn_contour();
			var handler_vertices = obj._creation_handler.vertices;
			for (var i in handler_vertices)
				ctr.vertices.push(new cn_vertex(handler_vertices[i]));
			area_trimming.contours.push(ctr);

			obj.push_transaction("Découpe de surface","",function(){current_area_context.update_deep(obj._map._storey);});
			obj.push_item_set(scene,"area_trimmings",function(){current_area_context.update_deep(obj._map._storey);});

			scene.area_trimmings.push(area_trimming);
			area_trimming.update();

			current_area_context.update_deep(obj._map._storey);

			obj.stop_area_trimming();

			obj.call("creation",[area_trimming]);
			obj._initiate_edition([area_trimming]);
		});
	}

	//***********************************************************************************
	//**** Edition elements
	//***********************************************************************************
	/**
	 * TODO : derivate in order to allow edition of other element in the process of creation
	 * @param {cn_mouse_event} mouse_event
	 * @returns {cn_element}
	 */
	_find_other_element(mouse_event) {
		return this._scene.find_area_trimming(mouse_event.mouse_world,mouse_event.camera.snap_world_distance);
	}

	/**
	 * TODO : derivate in order to provide an edition handler
	 * @param {Array<cn_area_trimming>} elements
	 * @returns {cn_edition_handler}
	 */
	_build_edition_handler(elements) {
		return new cn_area_trimming_handler(elements,this._map, this._current_area_context);
	}

}


import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Contact } from '../model/contact.model';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';

/**
 * Service d'appel aux APIs pour les contacts.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class ContactApiService {
    private http: HttpClient;

    public resourceUrl = environment.apiUrl + '/contacts';

    constructor(private httpBackend: HttpBackend) {
        this.http = new HttpClient(httpBackend);
    }

    findOneContact(id: string): Observable<Contact> {
        return this.http.get<Contact>(`${this.resourceUrl}/${id}`);
    }

    upsertContact(contact: Contact, forceUpdate = false): Observable<Contact> {
        return this.http.post<Contact>(`${this.resourceUrl}`, contact, {
            params: { forceUpdate },
        });
    }

    searchContacts(
        categorie: 'PERSONNES' | 'ENTREPRISES' | 'INDIFFERENT',
        search: string,
        pageRequest: PageRequest<Contact>
    ) {
        return extractPage(
            this.http.get<Contact[]>(`${this.resourceUrl}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), categorie, search },
            })
        );
    }

    findAllContactByName(search: string, pageRequest: PageRequest<Contact>) {
        const searchType = 'NOM';
        const categorie = 'PERSONNES';
        return extractPage(
            this.http.get<Contact[]>(`${this.resourceUrl}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search, searchType, categorie },
            })
        );
    }

    findAllEntreprisesByName(search: string, pageRequest: PageRequest<Contact>) {
        const searchType = 'NOM';
        const categorie = 'ENTREPRISES';
        return extractPage(
            this.http.get<Contact[]>(`${this.resourceUrl}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search, searchType, categorie },
            })
        );
    }

    findAllContactByPhone(search: string, pageRequest: PageRequest<Contact>) {
        const searchType = 'TELEPHONE';
        return extractPage(
            this.http.get<Contact[]>(`${this.resourceUrl}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search, searchType },
            })
        );
    }

    findAllContactByEmail(search: string, pageRequest: PageRequest<Contact>) {
        const searchType = 'EMAIL';
        return extractPage(
            this.http.get<Contact[]>(`${this.resourceUrl}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search, searchType },
            })
        );
    }

    findAllContactBySiret(search: string, pageRequest: PageRequest<Contact>) {
        const searchType = 'SIRET';
        const categorie = 'ENTREPRISES';
        return extractPage(
            this.http.get<Contact[]>(`${this.resourceUrl}`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search, searchType, categorie },
            })
        );
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_svg_tool_slabs  : Manipulation of floor slabs
//***********************************************************************************
//***********************************************************************************

import {cn_svg_tool_creation} from "./cn_svg_tool_creation";
import {cn_svg_map} from "./cn_svg_map";
import {cn_camera} from "./cn_camera";
import {cn_slab_type} from "../model/cn_slab_type";
import { cn_polygon_handler } from "./cn_polygon_handler";
import { cn_slab_opening } from "../model/cn_slab_opening";
import { cn_contour } from "../model/cn_contour";
import { cn_slab_opening_handler } from "./cn_slab_opening_handler";
import { cn_slab } from "../model/cn_slab";
import { cn_mouse_event } from "./cn_mouse_event";
import { cn_element } from "../model/cn_element";
import { cn_edition_handler } from "./cn_edition_handler";
import { cn_roof } from "../model/cn_roof";

/**
 * Events :
 * - 'slab_selection_change' : called when slab selection changes
 */
export class cn_svg_tool_slabs extends cn_svg_tool_creation {
	//***********************************************************************************
	/**
	 * Constructor
	 * @param {cn_svg_map} svg_map
	 * @param {boolean} roofs : true if we are dealing with roof slabs
	 */
	constructor(svg_map, roofs) {
		super(svg_map);

		this._roofs = roofs;
		this._svg = "";
		this._mouseover_slab = null;
		this._current_slab_type = null;
		this._slab_opening_handler = null;
		this._selected_slabs = [];
		this.slabs = [];
		this.element_filter = function(element) {return element.constructor == cn_slab_opening || element.constructor == cn_slab;}
	}

	//***********************************************************************************
	/**
	 * Returns the list of available slab types
	 * @return {Array<cn_slab_type>}
	 */
	get_slab_types()
	{
		if (this._roofs) return this._map._building.get_roof_types();
		return this._map._building.get_floor_types();
	}

	//***********************************************************************************
	/**
	 * Sets slab type for selection
	 * @param {cn_slab_type} st
	 */
	set_current_slab_type(st)
	{
		this._current_slab_type = st;
		if (this._selected_slabs.length)
		{
			var scene = this._scene;
			this.push_transaction("Modification du type de dalle","",function(){scene.update();scene.update_deep();});

			for (var i in this._selected_slabs)
			{
				this.push_item_set(this._selected_slabs[i],["slab_type"]);
				this._selected_slabs[i].slab_type = st;
			}
			this._scene.update();
			this._scene.update_deep();
			this._map.refresh_tool();
		}
	}

	//***********************************************************************************
	/**
	 * Returns current slab type
	 * @return {cn_slab_type}
	 */
	get_current_slab_type() {
		const slab_types = this.get_slab_types();
		if (this._current_slab_type == null || slab_types.indexOf(this._current_slab_type) < 0)
		{
			this._current_slab_type = null;
			if (slab_types.length > 0) this._current_slab_type = slab_types[0];
		}
		return this._current_slab_type;
	}

	//***********************************************************************************
	/**
	 * Select slabs with given type
	 * @param {cn_slab_type} st
	 */
	select_elements_by_type(st)
	{
		this._selected_slabs = [];
		for (var i in this.slabs)
		{
			if (this.slabs[i].slab_type == st)
				this._selected_slabs.push(this.slabs[i]);
		}
		this._map.refresh_tool();
	}

	//***********************************************************************************
	/**
	 * Open tool
	 */
	open_tool() {
		super.open_tool();
		this._slab_opening_handler = null;
		if (!this._roofs)
			this._map._storey.update_slabs();
		this.get_current_slab_type();
	}

	/**
	 * Method to call to initiate the creation of a new slab opening
	 * @param {boolean} on
	 */
	new_slab_opening(on = true) {
		this._terminate_slab_opening();
		if (this._roofs) return;
		var obj = this;
		var scene = obj._scene;
		this._slab_opening_handler = null;
		if (!on) return;
		const slab_opening_handler = cn_polygon_handler.create_rectangle(this,1);
		this._handlers.unshift(slab_opening_handler);
		slab_opening_handler.snap_elements = scene.spaces;
		this._slab_opening_handler = slab_opening_handler;

		//*** Callback upon creation end */
		slab_opening_handler.on("end_creation",function() {
			var slab_opening = new cn_slab_opening(scene);
			slab_opening.contours = [new cn_contour(slab_opening_handler.vertices)];

			obj.push_transaction("Création de trémie");
			obj.push_item_set(scene,"slab_openings",function(){scene.update();scene.update_deep();});

			scene.slab_openings.push(slab_opening);

			obj.remove_handler(slab_opening_handler);
			scene.update();
			scene.update_deep();
			obj._map._storey.update_slabs();
			obj._map.refresh();

			obj.call("creation",[slab_opening]);

			obj._initiate_edition([slab_opening]);
		});
	}

	_terminate_slab_opening() {
		this._terminate_edition();
		if (this._slab_opening_handler)
		{
			const index = this._handlers.indexOf(this._slab_opening_handler);
			if (index >= 0) this._handlers.splice(index,1);
		}
	}

	//***********************************************************************************
	/**
	 * SVG rendering
	 * @param {cn_camera} camera
	 * @returns {string} svg rendered
	 */
	draw(camera) {
		var html = "";

		this.slabs = (this._roofs)?this._scene.slabs:this._map._storey.slabs;

		//*** Create patterns for each slab type */
		var slab_types = [];
		for (var i in this.slabs)
		{
			if (slab_types.indexOf(this.slabs[i].slab_type) >= 0) continue;
			slab_types.push(this.slabs[i].slab_type);
			html += "<pattern id='" + this.slabs[i].slab_type.ID + "' x='0' y='0' width='50' height='50' patternUnits='userSpaceOnUse' viewBox='0 0 50 50'>";
			html += "<g opacity='0.5'>" + this.slabs[i].slab_type.draw_svg_icon(50,50) + "</g>";
			html += "</pattern>";
		}

		//*** Draw slabs */
		for (var i in this.slabs)
		{
			var slab = this.slabs[i];
			var extra = [];
			if (slab == this._mouseover_slab) extra.push("mouseover");
			if (this._selected_slabs.indexOf(slab)>=0) extra.push("selected");
			html += slab.draw(camera, extra, '', true);
		}

		html += super.draw(camera);

		return html;
	}

	//***********************************************************************************
	/**
	 * click management
	 * @param {object} ev
	 * @returns {boolean} true if click was used
	 */
	click(ev) {
		if (super.click(ev)) return true;

		this._selected_slabs = [];
		if (this._mouseover_slab && this._mouseover_slab.slab_type != this._current_slab_type && this._current_slab_type)
		{
			var scene = this._scene;
			this.push_transaction("Modification du type de dalle","",function(){scene.update();scene.update_deep();});

			this.push_item_set(this._mouseover_slab,["slab_type"]);
			this._mouseover_slab.slab_type = this._current_slab_type;
			this._scene.update();
			this._scene.update_deep();
			this._map.refresh_tool();
		}

		return true;
	}

	//***********************************************************************************
	/**
	 * passive move management
	 * @param {object} ev
	 * @returns {boolean} true if passive move was used
	 */
	move(ev) {
		this._mouseover_slab = null;
		if (super.move(ev)) return true;
		this._update_mouseover(ev);
		return !!this._mouseover_slab;
	}

	grab(ev) {
		if (super.grab(ev)) return true;
		this._terminate_slab_opening();
		return false;
	}

	//************************************************
	/**
	 * internal mouseover management
	 * @param {object} ev
	 * @returns {boolean} true in case of change
	 */
	_update_mouseover(ev)
	{
		var previous_mouseover = this._mouseover_slab;
		this._mouseover_slab = null;

		//*** Maybe mouse over a slab ?
		for (var i in this.slabs)
		{
			if (!this.slabs[i].contains(ev.mouse_world)) continue;

			this._mouseover_slab = this.slabs[i];
			break;
		}
		return (previous_mouseover != this._mouseover_slab);
	}

	//***********************************************************************************
	//**** Edition elements
	//***********************************************************************************
	/**
	 * TODO : derivate in order to allow edition of other element in the process of creation
	 * @param {cn_mouse_event} mouse_event 
	 * @returns {cn_element}
	 */
	_find_other_element(mouse_event) {
		if (this._roofs) return null;
		return this._scene.find_slab_opening(mouse_event.mouse_world,mouse_event.camera.snap_world_distance);
	}

	/**
	 * TODO : derivate in order to provide an edition handler
	 * @param {Array<cn_slab_opening>} elements 
	 * @returns {cn_edition_handler}
	 */
	_build_edition_handler(elements) {
		return new cn_slab_opening_handler(elements,this._map);
	}
	
}


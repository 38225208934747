import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Bien } from '../model/bien.model';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FileData, OfflineStorageService } from '../shared/offline/offline-storage.service';
import { extractPage, PageRequest, toHttpParams } from '../shared/paging/page';

export class BienImageFileData implements FileData {
    fileId?: string;
    fileContent?: string;
    fileName?: string;
    interventionId: string;
    idRelationInterventionBien: string;
}

@Injectable({
    providedIn: 'root',
})
export class BienApiService {
    public resourceUrl = `${environment.apiUrl}/biens`;

    private http: HttpClient;

    constructor(private httpBackend: HttpBackend, private offlineStorage: OfflineStorageService) {
        this.http = new HttpClient(httpBackend);
    }

    findAllBiens(): Observable<Bien[]> {
        return this.http.get<Bien[]>(`${this.resourceUrl}/find-all-biens`);
    }

    findAllBiensMinimals(): Observable<Bien[]> {
        return this.http.get<Bien[]>(`${this.resourceUrl}/find-all-biens-minimals`);
    }

    findOneBien(idBien: string): Observable<Bien> {
        return this.http.get<Bien>(`${this.resourceUrl}/${idBien}`);
    }

    createBien(bien: Bien): Observable<Bien> {
        return this.http.post<Bien>(this.resourceUrl, bien);
    }

    updateBien(bien: Bien): Observable<Bien> {
        return this.http.put<Bien>(this.resourceUrl, bien);
    }

    synchroniserBien(idBien: string): Observable<Bien> {
        return this.http.get<Bien>(`${this.resourceUrl}/synchroniser/${idBien}`);
    }

    searchBiens(search: string, pageRequest: PageRequest<Bien>) {
        return extractPage(
            this.http.get<Bien[]>(`${this.resourceUrl}/find-all-biens-page`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    deleteCommentPicture(interventionId: string, idRelationInterventionBien: string, fileId: string) {
        return this.http.delete<any>(
            `${environment.apiUrl}/interventions/${interventionId}/description/${idRelationInterventionBien}/picture/${fileId}`
        );
    }

    uploadImageWithProgressReport(
        interventionId: string,
        idRelationInterventionBien: string,
        formData: FormData,
        params: HttpParams
    ) {
        return this.http.post(
            `${environment.apiUrl}/interventions/${interventionId}/description/${idRelationInterventionBien}/picture`,
            formData,
            {
                params,
                reportProgress: true,
                observe: 'response',
                responseType: 'text',
            }
        );
    }

    findAllBiensByName(search: string, pageRequest: PageRequest<Bien>) {
        return extractPage(
            this.http.get<Bien[]>(`${this.resourceUrl}/find-all-biens-searched`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }

    findAllBiensByNameAndAdress(search: string, pageRequest: PageRequest<Bien>) {
        return extractPage(
            this.http.get<Bien[]>(`${this.resourceUrl}/find-all-biens-page`, {
                observe: 'response',
                params: { ...toHttpParams(pageRequest), search },
            })
        );
    }
}

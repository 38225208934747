<mat-sidenav-container [hasBackdrop]="false" class="sidenav-container" [formGroup]="formDescriptif" *ngIf="!isLoading">
    <mat-sidenav mode="side" opened>
        <app-checkpoint-sidebar
            [pieceSansEquipement]="false"
            [formPointDeControle]="listBienSidebar"
            (currentListRoomIdChange)="listBienUpdated($event)"
            [multipleSelection]="true"
            [listVolumeId]="listVolumeId"
            [controlPieceEmpty]="false"
            [isInEditMode]="isInEditMode"
        >
        </app-checkpoint-sidebar>
    </mat-sidenav>

    <mat-sidenav-content class="main">
        <div
            *ngIf="listePerimetreInvestigation && listePerimetreInvestigation.length > 0"
            class="perimetreSelectorContainer"
        >
            <button
                class="ml-2"
                mat-icon-button
                color="primary"
                [disabled]="
                    !selectedPerimetreInvestigation ||
                    selectedPerimetreInvestigation.id === listePerimetreInvestigation[0].id
                "
                (click)="onClickPreviousPerimetre()"
            >
                <lib-icon iconName="arrow-left-bold-circle" [theme]="'mdi'"></lib-icon>
            </button>
            <app-custom-select
                class="espace-select"
                [matFormFieldClass]="'w-100'"
                [choices]="listePerimetreInvestigation"
                [selectedValue]="selectedPerimetreInvestigation"
                (valueChangeFromUser)="selectPerimetreInvestigation($event)"
                [pathValueDisplay]="['nom']"
                [filter]="false"
                [addline]="false"
                [sorted]="false"
            ></app-custom-select>
            <button
                mat-icon-button
                color="primary"
                [disabled]="
                    !selectedPerimetreInvestigation ||
                    selectedPerimetreInvestigation.id ===
                        listePerimetreInvestigation[listePerimetreInvestigation.length - 1].id
                "
                (click)="onClickNextPerimetre()"
            >
                <lib-icon iconName="arrow-right-bold-circle" [theme]="'mdi'"></lib-icon>
            </button>

            <button
                mat-icon-button
                [disabled]="!isInEditMode || listePerimetreInvestigation.length === 1"
                (click)="onClickDeletePerimetre()"
            >
                <lib-icon class="icon-small">delete</lib-icon>
            </button>

            <button
                mat-raised-button
                class="button-create-espace"
                type="button"
                color="accent"
                [disabled]="!isInEditMode"
                (click)="onClickCreatePerimetre()"
            >
                <lib-icon>add</lib-icon>
            </button>
        </div>

        <div class="m-3" *ngIf="typeMesurage === 'POLLUANT_VISITE_CHANTIER'">
            <verification-card
                [showValidateButton]="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
                [isInEditMode]="isInEditMode"
                [isValidated]="isValidated"
                (onCancel)="cancelModification()"
                (onSave)="saveModification()"
                (onValidate)="validateTab()"
                (onEdit)="startModification()"
                [form]="formDescriptif"
            >
                Attention! Modifier le Périmètre entrainera la réinitialisation des données de l'écran Zone
            </verification-card>
        </div>

        <div class="investigation-scope--description">
            <div class="label">Description du périmètre d'investigation:</div>
            <textarea class="card-response" formControlName="description" [readonly]="!isInEditMode"></textarea>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

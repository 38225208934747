import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Polluant } from '../model/polluant.model';
import { PolluantConfig } from '../model/polluant-config.model';
import { OfflineStorageService, ResourceWrapper } from '../../../../shared/offline/offline-storage.service';
import { STORAGE_KEY_CONFIGS_POLLUANT } from '../../../../shared/constants/indexeddb.constants';
import { Observable } from 'rxjs';

/**
 * Service d'appel aux APIs pour les agences.
 * Ne pas appeler directement depuis les composants des pages, uniquement depuis d'autres services.
 * NB : On utilise HttpBackend au lieu de HttpClient ici, pour ne pas passer par les intercepteurs (donc on bypasse l'intercepteur).
 *      Le service appelant doit donc gérer lui-même les erreurs HTTP.
 */
@Injectable({
    providedIn: 'root',
})
export class PolluantApiService {
    private http: HttpClient;
    public resourceUrl = environment.apiUrl + '/polluant';

    private resourceOffline: ResourceWrapper<PolluantConfig, string> = this.offlineStorage.wrapRestResource<
        PolluantConfig,
        string
    >(
        {
            cacheName: STORAGE_KEY_CONFIGS_POLLUANT,
            idField: 'id',
            resourceUrl: this.resourceUrl,
        },
        false
    );

    private resource: ResourceWrapper<PolluantConfig, string> = this.offlineStorage.wrapRestResource<
        PolluantConfig,
        string
    >(
        {
            cacheName: STORAGE_KEY_CONFIGS_POLLUANT,
            idField: 'id',
            resourceUrl: this.resourceUrl,
        },
        true
    );

    constructor(private httpBackend: HttpBackend, private offlineStorage: OfflineStorageService) {
        this.http = new HttpClient(httpBackend);
    }

    findOne(id: string): Observable<Polluant> {
        return this.http.get<Polluant>(`${this.resourceUrl}/${id}`);
    }

    findOnePolluantConfigOffline(id: string): Observable<PolluantConfig> {
        return this.resourceOffline.getOne(id);
    }

    findAllPolluantConfigOffline(): Observable<PolluantConfig[]> {
        return this.resourceOffline.getAll();
    }

    findOnePolluantConfig(id: string): Observable<PolluantConfig> {
        return this.http.get<PolluantConfig>(`${this.resourceUrl}/findOnePolluantConfig/${id}`);
    }

    findLastPolluantConfig(): Observable<PolluantConfig> {
        return this.http.get<PolluantConfig>(`${this.resourceUrl}/findLastPolluantConfig`);
    }

    findAllConfigPolluant(): Observable<PolluantConfig[]> {
        return this.resource.getAll(null, null, `${this.resourceUrl}/findAllPolluantConfig`);
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** Thermal model : a common class for exporting thermal data
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Global functions :
//***********************************************************************************

import { cn_clone, cn_dist, cn_middle, cn_normal, cn_sub, cnx_clone } from "../cn_utilities";
import { fh_add, fh_clone, fh_matrix, fh_mul, fh_polygon, fh_solid } from "@acenv/fh-3d-viewer";
import { cn_storey } from "../../model/cn_storey";
import { cn_space } from "../../model/cn_space";
import { cn_storey_element } from "../../model/cn_storey_element";
import { cn_element } from "../../model/cn_element";
import { cn_wall } from "../../model/cn_wall";

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_space class
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_space {
    /**
     * Constructor
     * @param {fh_solid} solid
     * @param {cn_storey_element} storey_element
     */
    constructor(solid, storey_element) {
        this.solid = solid;
        this.storey_element = storey_element;
        this.zone = null
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_zone class
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_zone {
    /**
     * Constructor
     * @param {cn_element} element
     */
    constructor(element) {
        this.element = element;
        this.spaces = [];
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_wall class
//**** Describes an horizontal wall, or a vertical one.
//**** - horizontal wall : space_0 is below, space_1 is above,  */
//**** - vertical wall : space_0 is inside, space_1 is outside,  */
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_wall {
    /**
     * Constructor
     * @param {boolean} vertical
     * @param {fh_polygon} polygon
     * @param {cn_storey_element} storey_element
     * @param {cn_thermal_space} space_0
     * @param {cn_thermal_space} space_1
     */
    constructor(vertical, polygon, storey_element, space_0, space_1) {
        this.polygon = polygon;
        this.spaces = [space_0, space_1];
        this.cn_spaces = [null, null];
        this.vertical = vertical;
        this.storey_element = storey_element;
        this.openings = [];
        this.element_type = null;
        if (storey_element && storey_element.element)
        {
            const element = storey_element.element;
            // @ts-ignore
            if (typeof(element.wall_type) == 'object')
                // @ts-ignore
                this.element_type = element.wall_type;

            // @ts-ignore
            if (typeof(element.slab_type) == 'object')
                // @ts-ignore
                this.element_type = element.slab_type;
        }
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_opening class
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_opening {
    /**
     * Constructor
     * @param {fh_polygon} polygon
     * @param {cn_storey_element} storey_element
     */
    constructor(polygon, storey_element) {
        this.polygon = polygon;
        this.storey_element = storey_element;
        // @ts-ignore
        this.element_type = storey_element.element.opening_type;
    }
}

//***********************************************************************************
//***********************************************************************************
//**** cn_thermal_model class
//***********************************************************************************
//***********************************************************************************

export class cn_thermal_model {
    //********************************************************************************
    //**** Constructor
    //********************************************************************************
    constructor(building) {
        this.building = building;
        this.walls = [];
        this.spaces = [];
        this.zones = [];
    }

    /**
     *
     * @param {cn_space} space
     * @param {cn_storey} storey
     * @returns
     */
    find_thermal_space(space, storey) {
        const sp = this.spaces.find(s => s.storey_element.element == space && s.storey_element.storey == storey);
        if (sp) return sp;
        return null;
    }

    //********************************************************************************
    /**
     * Main function
     */
    analyse() {
        this.building.rename_storeys();
        this.building.update_roofs();
        this.building.compute_altitudes();

        this.walls = [];
        this.spaces = [];
        this.zones = [];
        this._has_zones = this.building.has_zones();
        this.building.storeys.forEach(storey => this._build_spaces(storey));

        this._ceiling_slabs = [];
        var storey_below = null;
        this.building.storeys.forEach(storey => {
            this._analyse_storey(storey, storey_below);
            storey_below = storey;
        });
    }

    _build_spaces(storey) {
        var default_zone = null;
        if (!this._has_zones)
        {
            default_zone = new cn_thermal_zone(storey);
            this.zones.push(default_zone);
        }

        storey.scene.spaces.filter(sp => sp.indoor).forEach(space => {
            const zone = (this._has_zones)?this.building.find_zone(space, storey):default_zone;
            if (zone)
            {
                const solid = space.build_solid(storey);
                const thermal_space = new cn_thermal_space(solid,new cn_storey_element(space,storey));
                thermal_space.zone = zone;
                this.spaces.push(thermal_space);
                zone.spaces.push(thermal_space);
            }
        });
    }

    _analyse_storey(storey, storey_below) {
        var scene = storey.scene;

        //***************************************
        //*** Build ceiling slabs
        //***************************************
        var ceiling_slabs = [];
        var full_ceiling_slab = new fh_polygon([0,0,0],[0,0,1]);
        for (var i in scene.spaces) {
            var space = scene.spaces[i];
            if (space.outside) continue;
            if (!space.has_roof) continue;
            var pg = space.build_slab_polygon(0, true, false);
            pg.space = this.find_thermal_space(space,storey);
            ceiling_slabs.push(pg);
            full_ceiling_slab.unites(space.build_slab_polygon(0, false, false));
        }

        //***************************************
        //*** Build roof
        //***************************************
        var roof = storey.roof;
        if (roof) {
            //*** Build geometry of roof openings */
            roof.openings.forEach(op => op.gbxml_polygon = op.build_3d_opening(storey.roof_altitude));

            roof.slabs.forEach(slab => {
                const slab_openings = roof.openings.filter(op => op.slab == slab);
                var roof_polygon = slab.build_3d_polygon(storey.roof_altitude);

                //*** Check intersection with ceiling slabs */
                ceiling_slabs.filter(cs => cs.space != null).forEach(cs => {
                    var ceiling_slab = cs.clone();
                    ceiling_slab.project(roof_polygon.get_point(), roof_polygon.get_normal(), [0, 0, 1]);
                    ceiling_slab.intersects(roof_polygon);
                    if (ceiling_slab.get_area() > 0.01)
                    {
                        roof_polygon.substracts(ceiling_slab);
                        const thermal_slab = this._build_thermal_slab(ceiling_slab, slab, storey,  cs.space.storey_element.element, cs.space.storey_element.storey ,null,null);
                        slab_openings.forEach(op => {
                            var opening_pg = op.gbxml_polygon.clone();
                            opening_pg.intersects(ceiling_slab);
                            if (opening_pg.get_area() > 0.1) {
                                const thermal_opening = new cn_thermal_opening(opening_pg, new cn_storey_element(op, storey));
                                thermal_slab.openings.push(thermal_opening);
                            }
                        });
                    }
                });
            });

            //*** Build specific facade walls on roof line discontinuities */
            for (var i in storey.roof.lines) {
                var line = storey.roof.lines[i];
                if (line.is_border()) continue;
                var discontinuity = false;
                for (var nv = 0; nv < 2; nv++) {
                    var h0 = line.slabs[0].compute_height(line.vertices[nv].position);
                    var h1 = line.slabs[1].compute_height(line.vertices[nv].position);
                    if (Math.abs(h0 - h1) < 0.01) continue;
                    discontinuity = true;
                }
                if (!discontinuity) continue;

                var low_h = [0, 0];
                var high_h = [0, 0];
                var high_slab = [-1, -1];
                for (var nv = 0; nv < 2; nv++) {
                    var h0 = storey.roof_altitude + line.slabs[0].compute_height(line.vertices[nv].position);
                    var h1 = storey.roof_altitude + line.slabs[1].compute_height(line.vertices[nv].position);
                    low_h[nv] = (h0 > h1) ? h1 : h0;
                    high_h[nv] = (h0 > h1) ? h0 : h1;
                    if (high_h[nv] - low_h[nv] > 0.01)
                        high_slab[nv] = (h0 > h1) ? 0 : 1;
                }

                if (high_slab[0] < 0 && high_slab[1] < 0) continue;

                var p0 = cn_clone(line.vertices[0].position);
                p0.push(low_h[0]);
                p0.push(high_h[0]);
                var p1 = cn_clone(line.vertices[1].position);
                p1.push(low_h[1]);
                p1.push(high_h[1]);

                //*** maybe intermediate point ? */
                if (high_slab[0] >= 0 && high_slab[1] >= 0 && high_slab[0] != high_slab[1]) {
                    var dh0 = high_h[0] - low_h[0];
                    var dh1 = high_h[1] - low_h[1];
                    var x = dh0 / dh1 / (1 + dh0 / dh1);
                    var p1h = fh_clone(p1);
                    p1h[2] = p1[3];
                    var pp = fh_add(fh_mul(p0, 1 - x), fh_mul(p1h, x));
                    pp.push(pp[2]);

                    this._build_extra_wall(p0, pp, null, storey);
                    this._build_extra_wall(pp, p1, null, storey);
                }
                //*** regular case */
                else {
                    this._build_extra_wall(p0, p1, null, storey);
                }
            }
        }

        //***************************************
        //*** Build walls
        //***************************************
        for (var i in scene.walls)
            this._build_wall(scene.walls[i], storey, storey_below);

        //***************************************
        //*** Build floor slabs
        //***************************************

        var slab_openings = [];
        for (var so in storey.scene.slab_openings) {
            slab_openings.push(storey.scene.slab_openings[so].build_3d_polygon(0));
        }

        for (var s in storey.slabs) {
            var slab = storey.slabs[s];
            var slab_z = storey.altitude - slab.slab_type.thickness;
            if (slab.spaces[1] && !slab.spaces[1].outside) slab_z += slab.spaces[1].slab_offset;
            var polygon = slab.build_polygon(slab_z);
            for (var so in slab_openings)
                polygon.substracts(slab_openings[so]);

            this._build_thermal_slab(polygon, slab, storey, slab.spaces[0], storey_below, slab.spaces[1], storey);
        }
    }

    _build_wall(wall, storey, storey_below) {
        if (!wall.valid) return;
        if (wall.balcony) return;
        if (!wall.spaces[0].indoor && !wall.spaces[1].indoor) return;

        const space0 = this.find_thermal_space(wall.spaces[0], storey);
        const space1 = this.find_thermal_space(wall.spaces[1], storey);
        if (space0 == null && space1 == null) return;

        var sp0 = (wall.spaces[0].outside) ? null : wall.spaces[0];
        var sp1 = (wall.spaces[1].outside) ? null : wall.spaces[1];


        //*** Wall heights */
        var zmin = wall.get_lowest_slab_height();
        var zmax = wall.get_highest_slab_height();
        var lower_thermal_space = (sp0 && sp1 && sp0.slab_offset < sp1.slab_offset) ? space0 : space1;

        var side = (wall.is_lossy())?wall.get_flow_direction()?0:1:-1;

        //*** Wall geometry */
        var normal = [wall.bounds.normal[0], wall.bounds.normal[1], 0];
        var contour = [];
        var direction = cn_clone(wall.bounds.direction);
        direction.push(0);
        if (side >= 0)
        {
            var p00 = cnx_clone(wall.measure_points[side][0],zmax);
            var p10 = cnx_clone(wall.measure_points[side][1],zmax);
        }
        else
        {
            var p00 = cnx_clone(cn_middle(wall.measure_points[0][0],wall.measure_points[1][1]),zmax);
            var p10 = cnx_clone(cn_middle(wall.measure_points[0][1],wall.measure_points[1][0]),zmax);
        }
        var p01 = cnx_clone(p00);
        var p11 = cnx_clone(p10);
        p01[2] = p11[2] = storey.roof_altitude - storey.altitude;

        contour.push(p00);
        contour.push(p01);
        contour.push(p11);
        contour.push(p10);
        var wall_polygon = new fh_polygon(contour[0], normal);
        wall_polygon.add_contour(contour);

        if (storey.roof_volume) {
            var roof_section = storey.roof_volume.plane_intersection(wall_polygon.get_point(), wall_polygon.get_normal());
            if (roof_section)
                wall_polygon.intersects(roof_section);
        }
        if (wall_polygon.get_area() < 0.01) return;
        const thermal_wall = this._build_regular_thermal_wall(wall_polygon, wall, storey, storey.altitude);
        if (!thermal_wall) return;

        //*** Create openings */
        var p00 = cnx_clone(wall.vertex_position(0),zmax);
        for (var o = 0; o < wall.openings.length; o++) {
            var cn_opening = wall.openings[o];
            if (!cn_opening.valid) continue;

            contour = [];
            var x = cn_opening.position;
            var pp0 = fh_add(p00, [0, 0, cn_opening.opening_type.z])
            var pp1 = fh_add(p00, [0, 0, cn_opening.opening_type.z + cn_opening.opening_type.height])

            contour.push(fh_add(pp0, fh_mul(direction, x)));
            contour.push(fh_add(pp0, fh_mul(direction, x + cn_opening.opening_type.width)));
            contour.push(fh_add(pp1, fh_mul(direction, x + cn_opening.opening_type.width)));
            contour.push(fh_add(pp1, fh_mul(direction, x)));

            var opening_polygon = new fh_polygon(pp0, normal);
            opening_polygon.add_contour(contour);
            opening_polygon.intersects(wall_polygon)
            if (opening_polygon.get_area() < 0.01) continue;

            var cn_opening = wall.openings[o];
            thermal_wall.openings.push(new cn_thermal_opening(opening_polygon, new cn_storey_element(cn_opening,storey)));
        }

        //*** The wall below */
        if (zmax - zmin > 0.01) {
            //*** if zero storey, this is a wall on ground */
            if (storey.storey_index == 0) {
                if (lower_thermal_space) {
                    p00 = cn_clone(wall.vertices[0].position);
                    p10 = cn_clone(wall.vertices[1].position);
                    p00.push(zmin);
                    p10.push(zmin);
                    p01 = fh_clone(p00);
                    p11 = fh_clone(p10);
                    p01[2] = p11[2] = zmax;

                    wall_polygon = new fh_polygon(p00, normal);
                    wall_polygon.add_contour([p00, p01, p11, p10]);

                    this._build_thermal_wall(wall_polygon,wall,storey,lower_thermal_space.storey_element.element,lower_thermal_space.storey_element.storey,null,null, storey.altitude);
                }
            }
            else {
                var p0 = cn_clone(wall.vertices[0].position);
                p0.push(zmin + storey.altitude);
                p0.push(zmax + storey.altitude);
                var p1 = cn_clone(wall.vertices[1].position);
                p1.push(zmin + storey.altitude);
                p1.push(zmax + storey.altitude);
                this._build_extra_wall(p0, p1, new cn_storey_element(wall, storey), lower_thermal_space, storey_below);
            }
        }
    }

    _build_extra_wall(p0, p1, storey_wall, space, storey_below) {
        const scene_below = storey_below.scene;
        const impacts = scene_below.pathtrace(p0, p1);
        const length = cn_dist(p0, p1);
        var normal = cn_normal(cn_sub(p1, p0));
        normal.push(0);

        var spaces_under = [null, null]
        if (impacts.length == 0)
            spaces_under[0] = spaces_under[1] = scene_below.find_space(cn_middle(p0, p1));
        else {
            spaces_under[0] = impacts[0].spaces[0];
            spaces_under[1] = impacts[impacts.length - 1].spaces[1];
        }
        impacts.splice(0, 0, { point: cn_clone(p0), distance: 0, spaces: [null, spaces_under[0]] })
        impacts.push({ point: cn_clone(p1), distance: length, spaces: [spaces_under[1], null] });

        for (var n = 0; n < impacts.length; n++) {
            var t = impacts[n].distance / length;
            impacts[n].point.push(p0[2] * (1 - t) + p1[2] * t);
            impacts[n].point.push(p0[3] * (1 - t) + p1[3] * t);
        }

        for (var n = 0; n < impacts.length - 1; n++) {
            var p00 = fh_clone(impacts[n].point);
            var p10 = fh_clone(impacts[n + 1].point);
            var p01 = fh_clone(p00);
            var p11 = fh_clone(p10);
            p01[2] = impacts[n].point[3];
            p11[2] = impacts[n + 1].point[3];
            var wall_polygon = new fh_polygon(p00, normal);
            wall_polygon.add_contour([p00, p10, p11, p01]);
            this._build_thermal_wall(wall_polygon,storey_wall.element, storey_wall.storey,impacts[n].spaces[1], storey_below,(space)?space.element:null,(space)?space.storey:null);
        }
    }

    _build_regular_thermal_wall(polygon, wall, storey, zoffset) {
        return this._build_thermal_wall(polygon,wall, storey, wall.spaces[0],storey, wall.spaces[1],storey, zoffset=0);
    }

    _build_thermal_wall(polygon, wall, storey, space0, space0_storey, space1, space1_storey, zoffset=0) {
        const sp0 = this.find_thermal_space(space0, space0_storey);
        const sp1 = this.find_thermal_space(space1, space1_storey);
        if (sp0 == null && sp1 == null) return null;

        const storey_element = (wall)?new cn_storey_element(wall,storey):null;
        var pg = polygon;
        if (zoffset)
        {
            pg= polygon.clone();
            const matrix = new fh_matrix();
            matrix.load_translation([0,0,zoffset]);
            pg.apply_matrix(matrix);
        }
        const thermal_wall = new cn_thermal_wall(true, pg, storey_element,  sp0, sp1);
        thermal_wall.cn_spaces[0] = space0;
        thermal_wall.cn_spaces[1] = space1;
        this.walls.push(thermal_wall);
        return thermal_wall;
    }

    _build_thermal_slab(polygon, wall, storey, space0, space0_storey, space1, space1_storey) {
        const sp0 = this.find_thermal_space(space0, space0_storey);
        const sp1 = this.find_thermal_space(space1, space1_storey);
        if (sp0 == null && sp1 == null) return null;

        const storey_element = (wall)?new cn_storey_element(wall,storey):null
        const thermal_wall = new cn_thermal_wall(false, polygon, storey_element,  sp0, sp1);
        thermal_wall.cn_spaces[0] = space0;
        thermal_wall.cn_spaces[1] = space1;
        this.walls.push(thermal_wall);
        return thermal_wall;
    }
}

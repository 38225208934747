"use strict";

//***********************************************************************************
//***********************************************************************************
//**** Plugin options
//***********************************************************************************
//****
let numerotation = false;


//*******************************************************
/**
 * Activate or deactivate numerotation features
 * @param {boolean} isActive
 */
export function setNumerotation(isActive) {
    numerotation = isActive;
}

//*******************************************************
/**
 * Return is numerotation feature active
 * @returns {boolean}
 */
export function getNumerotation() {
    return !!numerotation;
}

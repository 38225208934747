<app-header-editor-panel [title]="document?.nom" [showBorder]="false" (closed)="onCloseEditPanel()">
    <div class="d-flex align-items-center mr-3">
        <button
            [disabled]="readonlyMode || !document?.isEditable"
            type="button"
            mat-icon-button
            color="primary"
            (click)="onClickEditDocument()"
        >
            <mat-icon>edit</mat-icon>
        </button>
    </div>
</app-header-editor-panel>

<div class="container-fluid pl-5 pr-5">
    <div class="row">
        <div class="col">
            <app-documents-prestation-chips
                [typesPrestation]="document.typePrestations"
            ></app-documents-prestation-chips>
        </div>
    </div>
    <!-- On n'affiche les lignes de checkpoint uniquement lorsqu'on est dans un diagnostic-->
    <ng-container *ngIf="showCheckpoints">
        <div class="row mt-3">
            <div class="col">
                <h2>Points de contrôles</h2>
            </div>
        </div>
        <!-- On n'affiche les lignes de checkpoint uniquement lorsqu'on est dans un diagnostic et q'il existe des checkpoints-->
        <div *ngIf="hasCheckpoints; else noCheckpoints" class="row">
            <div class="col">
                <app-documents-detail-checkpoint-list
                    [intervention]="intervention"
                    [diagnostic]="diagnostic"
                    [document]="document"
                    [readonlyMode]="readonlyMode"
                    [referencePrestationToShow]="referencePrestationToShow"
                ></app-documents-detail-checkpoint-list>
            </div>
        </div>
        <ng-template #noCheckpoints>
            <div class="col">
                <div class="row">Aucun point de contrôle.</div>
            </div>
        </ng-template>
    </ng-container>
    <div class="row mt-3">
        <div class="col">
            <h2>Détails de la pièce jointe</h2>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <app-documents-detail-attachment
                [document]="document"
                [intervention]="intervention"
                [diagnostic]="diagnostic"
                [readonlyMode]="readonlyMode"
            ></app-documents-detail-attachment>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <h2>Historique des pièces jointes transmises</h2>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <app-documents-detail-header-attachment-history
                [documentsHistory]="document?.documentsHistory"
            ></app-documents-detail-header-attachment-history>
        </div>
    </div>
</div>

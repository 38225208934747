import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Big from 'big.js';

class CalculatriceModaleData {
    constructor(public mode: string, public originalValue: string) {}
}

export type Operator = '-' | '+' | '*' | '/' | '(' | ')';

export function isOperator(token: string): token is Operator {
    return token === '-' || token === '+' || token === '*' || token === '/' || token === '(' || token === ')';
}

@Component({
    selector: 'app-calculatrice-modale',
    templateUrl: './calculatrice-modale.component.html',
    styleUrls: ['./calculatrice-modale.component.scss'],
})
export class CalculatriceModaleComponent implements OnInit {
    tokens: string[] = [];
    showResult = false;
    mode: string;

    constructor(
        public dialogRef: MatDialogRef<CalculatriceModaleComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CalculatriceModaleData
    ) {}

    ngOnInit(): void {
        this.mode = this.data.mode;
        if (this.data.originalValue) {
            // this.tokens = this.data.originalValue.toString().split('');
            this.tokens = [this.data.originalValue.toString()];
        }
    }

    /**
     * Insertion du charactère lié au bouton
     */
    insertChar(character: string): void {
        const lastToken = this.lastToken;
        const doubleMin = lastToken === '-' && isOperator(this.beforeLastToken);

        if (lastToken === undefined || (isOperator(lastToken) && !doubleMin)) {
            if (character === '.') {
                character = '0' + character;
            }

            this.tokens.push(character);
        } else if (this.showResult) {
            this.tokens = [character];
        } else {
            this.tokens[this.tokens.length - 1] = lastToken + character;
        }

        this.showResult = false;
    }

    get lastToken(): string {
        return this.tokens[this.tokens.length - 1];
    }

    get beforeLastToken(): string {
        return this.tokens[this.tokens.length - 2];
    }

    get input(): string {
        if (this.showResult) {
            try {
                return this.format(this.rpn(this.yard(this.tokens)).toString());
            } catch (e) {
                return 'Erreur de calcul';
            }
        }

        return this.format(
            this.tokens
                .slice()
                .reverse()
                .find((t) => !isOperator(t)) || ''
        );
    }

    get formattedTokens(): string {
        return this.tokens.map(this.format).join(' ').replace(/\*/g, 'x') || '0';
    }

    reset(): void {
        this.tokens = [];
        this.showResult = false;
    }

    evaluate(): void {
        // repeat last action
        if (this.showResult && this.tokens.length >= 2) {
            this.tokens = this.tokens.concat(this.tokens.slice(-2));
        }

        this.showResult = true;
    }

    // KEYBOARD SUPPORT
    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const key = event.key.toLowerCase();

        event.preventDefault();

        if (key === 'c' || key === 'backspace' || key === 'delete') {
            this.reset();
        } else if (key === ',' || key === '.') {
            this.insertChar(',');
        } else if (!isNaN(parseInt(key))) {
            this.insertChar(key);
        } else if (key === 'enter') {
            if (this.mode === 'saisie') {
                this.confirm();
            } else {
                this.evaluate();
            }
        } else if (isOperator(key)) {
            this.execOperator(key);
        }
    }

    execOperator(operator: Operator): void {
        // ANS support
        if (this.showResult) {
            this.tokens = [this.rpn(this.yard(this.tokens)).toString()];
        }

        if (!this.lastToken && operator !== '(') {
            this.tokens.push('0');
        }

        this.tokens.push(operator);
        this.showResult = false;
    }

    cancel() {
        this.dialogRef.close(false);
    }

    confirm() {
        this.dialogRef.close({ resultat: this.input });
    }

    reinit() {
        this.reset();
    }

    /**
     * Formate le calcul / saisie
     */
    private format(input: string): string {
        if (isOperator(input)) {
            return input;
        } else {
            return input.replace(',', '.');
        }
    }

    /**
     * Opérateurs de calcul + gestion d'erreur
     */
    private rpn(tokens: string[]): Big {
        const stack: Big[] = [];

        tokens.forEach((token) => {
            if (!isOperator(token)) {
                stack.push(Big(token));
            } else if (stack.length < 2) {
                throw new Error('Erreur de syntaxe.');
            } else {
                const val2 = stack.pop();
                const val1 = stack.pop();

                switch (token) {
                    case '+':
                        stack.push(val1.add(val2));
                        break;

                    case '*':
                        stack.push(val1.mul(val2));
                        break;

                    case '/':
                        if (val2.eq(0)) {
                            throw new Error('Division par zéro.');
                        }
                        stack.push(val1.div(val2));
                        break;

                    case '-':
                        stack.push(val1.minus(val2));
                        break;
                }
            }
        });

        return stack.pop().round(10);
    }

    /**
     *  Gestion des priorités de calculs
     */
    private yard(infix: string[]): string[] {
        const ops = { '+': 1, '-': 1, '*': 2, '/': 2 };
        const peek = (arr) => arr[arr.length - 1];
        const stack = [];

        return infix
            .reduce((output, token) => {
                if (!isOperator(token)) {
                    output.push(token);
                } else {
                    if (token in ops) {
                        while (peek(stack) && ops[token] <= ops[peek(stack)]) {
                            output.push(stack.pop());
                        }

                        stack.push(token);
                    }

                    if (token === '(') {
                        stack.push(token);
                    }

                    if (token === ')') {
                        while (stack.length > 0 && peek(stack) !== '(') {
                            output.push(stack.pop());
                        }

                        stack.pop();
                    }
                }

                return output;
            }, [])
            .concat(stack.reverse());
    }
}

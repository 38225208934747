/**
 * Définition d'un paramètre d'un type élément.
 */
export class DefinitionParametre {
    /**
     * Nom
     */
    public nom = '';

    /**
     * Description
     */
    public description: string = null;

    /**
     * Code BIM
     */
    public codeBim: string;

    /**
     * Type
     */
    public type: TypeParametre;

    /**
     * Pour type LISTE_CHOIX, liste des choix possibles
     */
    public parametreListeChoix: string[];

    /**
     * Pour type NOMBRE, paramétrage du nombre
     */
    public parametreNombre: DefinitionParametreNombre = null;

    public readonly?: boolean = null;
    public canHaveDefaultValue?: boolean;
    public canBeProportional?: boolean;
    public instance?: boolean;
    public catalog?: boolean;
    public groupId?: string;
    public order: number;
}

/**
 * Type de paramètres.
 */
export enum TypeParametre {
    TEXTE,
    NOMBRE,
    BOOLEEN,
    LISTE_CHOIX,
    DATE,
    COULEUR,
    URL,
    LOT,
    PIECE,
    OUVRAGE,
}

/**
 * Définition complémentaire pour un paramètre de type nombre.
 */
export class DefinitionParametreNombre {
    public min: number = null;

    public max: number = null;

    public nbDecimales: number = null;

    public unite: string = null;
}

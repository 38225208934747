import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/commons-lib';
import { Diagnostic } from '../../../../../model/diagnostic.model';
import { DiagnosticService } from '../../../../../services/diagnostic.service';

@Component({
    selector: 'app-validation-docs',
    templateUrl: './validation-docs.component.html',
})
export class ValidationDocsComponent extends BaseComponent implements OnInit {
    currentDiagnostic: Diagnostic;
    typePrestation: [string];

    constructor(private readonly diagnosticService: DiagnosticService) {
        super();
    }
    ngOnInit(): void {
        this.diagnosticService.getCurrentDiagnostic().subscribe((diag) => (this.currentDiagnostic = diag));
        this.typePrestation = [this.currentDiagnostic.typePrestation.toString()];
    }
}

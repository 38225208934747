import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, ConfirmationService, NotificationService, ROLE_USER } from 'src/app/commons-lib';
import { combineLatest, forkJoin, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Agence } from 'src/app/model/agence.model';
import { EditMode } from 'src/app/model/edit-mode.model';
import { Competence, UserWizy } from 'src/app/model/user-wizy.model';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { AgenceService } from 'src/app/services/agence.service';
import { ManagementApiService } from 'src/app/services/management-api.service';
import { URL_GESTION_USERS, URL_GESTION_USERS_EDIT } from 'src/app/shared/constants/url.constants';
import { MAP_ROLE } from 'src/app/shared/constants/authorities.constants';
import { REGEX_EMAIL, REGEX_TELEPHONE } from 'src/app/shared/constants/cndiag.constants';
import { emailExistingValidator } from 'src/app/shared/form-validator/email-validator/email-validator.component';
import { AgenceApiService } from '../../../../services/agence-api.service';
import { DISPLAY_FN_NAME } from '../../../../utils/display-function.utils';
import { FormService } from '../../../../services/form.service';
import { UserInformationApiService } from '../../../../services/user-information-api.service';
import { MatDialog } from '@angular/material/dialog';
import { CreationCompetenceModalComponent } from './creation-competence/creation-competence-modal.component';

@Component({
    selector: 'app-creation-operateur',
    templateUrl: './creation-operateur.component.html',
    styleUrls: ['./creation-operateur.component.scss'],
})
export class CreationOperateurComponent extends BaseComponent implements OnInit, OnDestroy {
    idUser: string;
    userInitial: UserWizy = new UserWizy();
    user: UserWizy = new UserWizy();

    agence: Agence;
    roles: string[] = Array.from(MAP_ROLE.values());
    isSearchingAgences = this.formService.isSearchingAgences$;
    filteredSearchAgences: Agence[];

    formUser: FormGroup;

    mode: EditMode = 'CREATE';
    isReadOnly = false;
    competences: Competence[] = [];

    readonly displayFnNom = DISPLAY_FN_NAME;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly cnSpinnerService: CnSpinnerService,
        private readonly notificationService: NotificationService,
        private readonly agenceService: AgenceService,
        private readonly agenceApiService: AgenceApiService,
        private readonly managementApiService: ManagementApiService,
        private readonly formService: FormService,
        private readonly userInformationService: UserInformationApiService,
        private readonly dialog: MatDialog,
        private readonly confirmationService: ConfirmationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([this.route.paramMap, this.route.data]).pipe(
                    takeUntil(this.ngUnsubscribe),
                    switchMap(([params, data]) => {
                        this.idUser = params.get('idUser');
                        if (this.idUser) {
                            this.mode = 'EDIT';
                            this.isReadOnly = data.consulter;
                            return this.managementApiService.findOneUser(this.idUser);
                        }
                        return of(null);
                    }),
                    switchMap((currentEmployee) =>
                        forkJoin([
                            of(currentEmployee),
                            currentEmployee
                                ? this.userInformationService.findCompetencesByIdUser(currentEmployee.id)
                                : of(null),
                            currentEmployee && currentEmployee.company
                                ? this.agenceService.findOne(currentEmployee.company)
                                : of(null),
                        ])
                    )
                )
            )
            .subscribe(([currentEmployee, competences, agence]) => {
                if (currentEmployee) {
                    this.userInitial = JSON.parse(JSON.stringify(currentEmployee));
                    this.user = currentEmployee;
                }
                if (competences) {
                    this.competences = competences;
                }
                if (agence) {
                    this.agence = agence;
                }
                this.createForm();
                if (this.mode !== 'CREATE') {
                    this.populateForm();
                }
                this.formService
                    .agenceSearchValueChange(this.formUser.get('agenceAppartenance'), this.agenceApiService)
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe((result) => {
                        this.filteredSearchAgences = result.content;
                    });
            });
    }

    /**
     * Crée le formulaire
     * @private
     */
    private createForm(): void {
        this.formUser = this.formBuilder.group({
            prenom: ['', [Validators.required]],
            nom: ['', Validators.required],
            dateSortie: [''],
            telephone: ['', [Validators.pattern(REGEX_TELEPHONE)]],
            email: [
                '',
                [Validators.required, Validators.pattern(REGEX_EMAIL)],
                [emailExistingValidator(this.managementApiService, this.userInitial.email)],
            ],
            roles: ['', Validators.required],
            agenceAppartenance: [
                '',
                [
                    Validators.required,
                    (control) => {
                        if (control.value && typeof control.value.id === 'string') {
                            return null;
                        } else {
                            return { agenceDoesNotExist: true };
                        }
                    },
                ],
            ],
        });
    }

    /**
     * Rempli le formulaire et initialise le formArray
     * @private
     */
    private populateForm(): void {
        let currentRolesUser = this.user.authorities.sort().map((role) => {
            return MAP_ROLE.get(role);
        });
        currentRolesUser = currentRolesUser.filter((e) => e !== undefined);

        this.formUser.patchValue({
            prenom: this.user.firstName,
            nom: this.user.lastName,
            dateSortie: this.user.deletedDate,
            agenceAppartenance: this.agence,
            telephone: this.user.phone,
            email: this.user.email,
            roles: currentRolesUser,
        });
    }

    validerUser() {
        this.cnSpinnerService.show('Sauvegarde en cours...');

        this.completeUserWithForm();
        this.managementApiService.upsertUser(this.user, this.competences).subscribe(
            () => {
                this.cnSpinnerService.hide();
                if (this.mode === 'EDIT') {
                    this.notificationService.success("L'employé a été modifié");
                } else {
                    const message = "L'employé a été créé";
                    this.notificationService.success(message);
                }
                this.back();
            },
            () => this.cnSpinnerService.hide()
        );
    }

    private completeUserWithForm() {
        // Values
        this.user.firstName = this.formUser.value.prenom;
        this.user.lastName = this.formUser.value.nom;
        this.user.deletedDate = this.formUser.value.dateSortie;
        this.user.company = this.formUser.value.agenceAppartenance
            ? this.formUser.value.agenceAppartenance.id
            : undefined;
        this.user.companyName = this.formUser.value.agenceAppartenance
            ? this.formUser.value.agenceAppartenance.nom
            : undefined;
        this.user.phone = this.formUser.value.telephone;
        this.user.email = this.formUser.value.email;

        const nouveauxRoles = this.formUser.value.roles;
        this.user.authorities = [ROLE_USER];
        if (nouveauxRoles != undefined) {
            nouveauxRoles.forEach((role: string) => {
                this.user.authorities.push(Array.from(MAP_ROLE.keys()).find((key) => MAP_ROLE.get(key) === role));
            });
        }
    }

    editer() {
        this.router.navigate([URL_GESTION_USERS_EDIT, this.user.id]);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    back() {
        this.router.navigate([URL_GESTION_USERS]);
    }

    addCompetence() {
        const dialogRef = this.dialog.open(CreationCompetenceModalComponent, {
            data: { competence: new Competence(), nom: this.formUser.value.nom, prenom: this.formUser.value.prenom },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.competences.push(result);
                    this.competences = [...this.competences];
                }
            });
    }

    editCompetence(competence: Competence) {
        const dialogRef = this.dialog.open(CreationCompetenceModalComponent, {
            data: { competence: competence },
        });

        dialogRef
            .afterClosed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((result) => {
                if (result) {
                    this.competences[this.competences.indexOf(competence)] = result;
                    this.competences = [...this.competences];
                }
            });
    }

    deleteCompetence(competence: Competence) {
        // on demande une confirmation
        this.confirmationService.confirm('Êtes-vous sûr(e) de vouloir supprimer cette compétence ?', () => {
            this.competences.splice(this.competences.indexOf(competence), 1);
            this.competences = [...this.competences];
        });
    }
}

"use strict";

import {refferer} from './cn_bbp_bim_refferer';
import {cn_dist} from "../cn_utilities";
import {CN_MATERIAL_TYPE_FACING, CN_MATERIAL_TYPE_INSULATOR, CN_MATERIAL_TYPE_LIGHT_STRUCTURE} from "../../model/cn_material";
import {PARAM_CODE_BIM_COULEUR_BATI} from "../cn_bbp_constants";
import {nearest_bbp_color_label} from "../bbp_colors";

class CodeBim {

    constructor() {
        this.interiorWallFacing = '';
        this.exteriorWallFacing = '';
        this.floorFacing = '';
        this.ceilingFacing = '';
        this.spacePerimeter = '';
        this.spaceHeight = '';
        this.wallStructure = '';
        this.wallThickness = '';
        this.wallLength = '';
        this.wallHeight = '';
        this.wallUValue = '';
        this.wallInsulationWidth = '';
        this.wallInsulationMaterial = '';
        this.wallInsulationWidth2 = '';
        this.wallInsulationMaterial2 = '';
        this.wallFacing = '';
        this.wallLightStructure = '';
        this.openingThickness = '';
        this.openingHeight = '';
        this.openingWidth = '';
        this.openingShift = '';
        this.openingArea = '';
        this.openingGlazingArea = '';
        this.openingFrame = '';
        this.openingGlaze = '';
        this.openingGlazingGaz = '';
        this.openingGlazingThickness = '';
        this.openingOpener = '';
        this.openingPanels = '';
        this.openingTransom = '';
        this.openingSill = '';
        this.openingClosing = '';
        this.openingUw = '';
        this.openingSw = '';
        this.openingTl = '';
        this.balconyThickness = '';
        this.balconyWidth = '';
        this.balconyHeight = '';
        this.beamWidth = '';
        this.beamThickness = '';
        this.beamBBWidth = '';
        this.beamBBLength = '';
        this.beamBBHeight = '';
        this.beamMaterial = '';
        this.beamShape = '';
        this.pipeLength = '';
        this.pipeDiameter = '';
        this.pipeMaterial = '';
        this.stairHeight = '';
        this.stairNumber = '';
        this.stairDepth = '';
        this.slabStructure = '';
        this.slabThickness = '';
        this.slabWidth = '';
        this.slabLength = '';
        this.roofArea = '';
        this.roofThickness = '';
        this.objectBBHeight = '';
        this.objectBBWidth = '';
        this.objectBBLength = '';
    }

}

export class BbpBimTranscoder {

    constructor() {
        this.interiorWallFacingTypes = new Map();
        this.exteriorWallFacingTypes = new Map();
        this.floorFacingTypes = new Map();
        this.ceilingFacingTypes = new Map();
        this.wallStructures = new Map();
        this.wallInsulators = new Map();
        this.wallFacings = new Map();
        this.wallLightStructures = new Map();
        this.openingFrames = new Map();
        this.openingGlazings = new Map();
        this.openingTypes = new Map();
        this.openingTransoms = new Map();
        this.openingSills = new Map();
        this.openingClosings = new Map();
        this.beamMaterials = new Map();
        this.beamShapes = new Map();
        this.pipeMaterials = new Map();
        this.slabStructures = new Map();
        this.codesBim = new CodeBim();
        this._initTypeList();
        this._initCodesBim();
    }

    _initTypeList() {
        this.interiorWallFacingTypes = this._populateTypeList('interior_wall_facing.type');
        this.exteriorWallFacingTypes = this._populateTypeList('exterior_wall_facing.type');
        this.floorFacingTypes = this._populateTypeList('floor_facing.type');
        this.ceilingFacingTypes = this._populateTypeList('ceiling_facing.type');
        this.wallStructures = this._populateTypeList('wall.structure');
        this.wallInsulators = this._populateTypeList('wall.insulation_material');
        this.wallFacings = this._populateTypeList('wall.facing');
        this.wallLightStructures = this._populateTypeList('wall.light_structure');
        this.openingFrames = this._populateTypeList('opening.frame');
        this.openingGlazings = this._populateTypeList('opening.glazing');
        this.openingTypes = this._populateTypeList('opening.opening');
        this.openingTransoms = this._populateTypeList('opening.transom');
        this.openingSills = this._populateTypeList('opening.sill');
        this.openingClosings = this._populateTypeList('opening.closing');
        this.beamMaterials = this._populateTypeList('beam.material');
        this.beamShapes = this._populateTypeList('beam.shape');
        this.pipeMaterials = this._populateTypeList('pipe.material');
        this.slabStructures = this._populateTypeList('slab.structure');
    }

    _initCodesBim() {
        this.codesBim.interiorWallFacing = this._getCodeBim('interior_wall_facing.type.codeBim');
        this.codesBim.exteriorWallFacing = this._getCodeBim('exterior_wall_facing.type.codeBim');
        this.codesBim.floorFacing = this._getCodeBim('floor_facing.type.codeBim');
        this.codesBim.ceilingFacing = this._getCodeBim('ceiling_facing.type.codeBim');
        this.codesBim.spacePerimeter = this._getCodeBim('space.perimeter');
        this.codesBim.spaceHeight = this._getCodeBim('space.height');
        this.codesBim.wallStructure = this._getCodeBim('wall.structure.codeBim');
        this.codesBim.wallThickness = this._getCodeBim('wall.thickness');
        this.codesBim.wallLength = this._getCodeBim('wall.length');
        this.codesBim.wallHeight = this._getCodeBim('wall.height');
        this.codesBim.wallUValue = this._getCodeBim('wall.u_value');
        this.codesBim.wallInsulationMaterial = this._getCodeBim('wall.insulation_material.codeBim');
        this.codesBim.wallInsulationWidth = this._getCodeBim('wall.insulation_width');
        this.codesBim.wallInsulationMaterial2 = this._getCodeBim('wall.insulation_material_2');
        this.codesBim.wallInsulationWidth2 = this._getCodeBim('wall.insulation_width_2');
        this.codesBim.wallFacing = this._getCodeBim('wall.facing.codeBim');
        this.codesBim.wallLightStructure = this._getCodeBim('wall.light_structure.codeBim');
        this.codesBim.openingThickness = this._getCodeBim('opening.thickness');
        this.codesBim.openingWidth = this._getCodeBim('opening.width');
        this.codesBim.openingHeight = this._getCodeBim('opening.height');
        this.codesBim.openingShift = this._getCodeBim('opening.shift');
        this.codesBim.openingArea = this._getCodeBim('opening.area');
        this.codesBim.openingGlazingArea = this._getCodeBim('opening.glazing_area');
        this.codesBim.openingFrame = this._getCodeBim('opening.frame.codeBim');
        this.codesBim.openingGlaze = this._getCodeBim('opening.glazing.codeBim');
        this.codesBim.openingGlazingGaz = this._getCodeBim('opening.glazing_gaz');
        this.codesBim.openingGlazingThickness = this._getCodeBim('opening.glazing_thickness');
        this.codesBim.openingOpener = this._getCodeBim('opening.opening.codeBim');
        this.codesBim.openingPanels = this._getCodeBim('opening.panels');
        this.codesBim.openingTransom = this._getCodeBim('opening.transom.codeBim');
        this.codesBim.openingSill = this._getCodeBim('opening.sill.codeBim');
        this.codesBim.openingClosing = this._getCodeBim('opening.closing.codeBim');
        this.codesBim.openingUw = this._getCodeBim('opening.uw');
        this.codesBim.openingSw = this._getCodeBim('opening.sw');
        this.codesBim.openingTl = this._getCodeBim('opening.tl');
        this.codesBim.balconyThickness = this._getCodeBim('balcony.thickness');
        this.codesBim.balconyHeight = this._getCodeBim('balcony.height');
        this.codesBim.balconyWidth = this._getCodeBim('balcony.width');
        this.codesBim.beamWidth = this._getCodeBim('beam.width');
        this.codesBim.beamThickness = this._getCodeBim('beam.thickness');
        this.codesBim.beamBBWidth = this._getCodeBim('beam.bbwidth');
        this.codesBim.beamBBLength = this._getCodeBim('beam.bblength');
        this.codesBim.beamBBHeight = this._getCodeBim('beam.bbheight');
        this.codesBim.beamMaterial = this._getCodeBim('beam.material.codeBim');
        this.codesBim.beamShape = this._getCodeBim('beam.shape.codeBim');
        this.codesBim.pipeLength = this._getCodeBim('pipe.length');
        this.codesBim.pipeDiameter = this._getCodeBim('pipe.diameter');
        this.codesBim.pipeMaterial = this._getCodeBim('pipe.material.codeBim');
        this.codesBim.stairHeight = this._getCodeBim('stair.stairHeight');
        this.codesBim.stairNumber = this._getCodeBim('stair.stairNumber');
        this.codesBim.stairDepth = this._getCodeBim('stair.stairDepth');
        this.codesBim.slabStructure = this._getCodeBim('slab.structure.codeBim');
        this.codesBim.slabThickness = this._getCodeBim('slab.thickness');
        this.codesBim.slabWidth = this._getCodeBim('slab.width');
        this.codesBim.slabLength = this._getCodeBim('slab.length');
        this.codesBim.roofArea = this._getCodeBim('roof.area');
        this.codesBim.roofThickness = this._getCodeBim('roof.thickness');
        this.codesBim.objectBBHeight = this._getCodeBim('object.bbheight');
        this.codesBim.objectBBWidth = this._getCodeBim('object.bbwidth');
        this.codesBim.objectBBLength = this._getCodeBim('object.bblength');
    }

    _populateTypeList(key) {
        const result = new Map()
        const chain = key.split('.');
        const entry = chain.reduce((a, b) => a[b] || {}, refferer).type;
        Object.entries(entry).forEach(element => {
            result.set(element[0], element[1]);
        });
        return result;
    }

    _getCodeBim(key) {
        const chain = key.split('.');
        return chain.reduce((a, b) => a[b] || {}, refferer);
    }

    getSpaceParameters(space, storey) {
        const result = {};
        result[this.codesBim.spacePerimeter] = space.perimeter.toFixed(2);
        result[this.codesBim.spaceHeight] = space.ceiling_height !== -1 ? space.ceiling_height : storey.height;
        return result;
    }

    getWallParameters(wall, storey) {
        const result = {};
        if (!wall.balcony) {
            result[this.codesBim.wallThickness] = wall.wall_type.thickness;
            result[this.codesBim.wallLength] = wall.bounds.length;
            result[this.codesBim.wallHeight] = storey.height;
            const structure = wall.wall_type.layers.find(l => !!this.wallStructures.get(l.code))
            if (structure) {
                result[this.codesBim.wallStructure] = this.wallStructures.get(structure.code);
            }
            const uValue = wall.wall_type.get_U();
            if (uValue) {
                result[this.codesBim.wallUValue] = uValue;
            }
            const insulationMaterials = wall.wall_type.layers.filter(it => it.type === CN_MATERIAL_TYPE_INSULATOR)
            const insulationMaterial1 = insulationMaterials.length >= 1 ? insulationMaterials[0] : null;
            const insulationMaterial2 = insulationMaterials.length >= 2 ? insulationMaterials[1] : null;
            const insulationMaterial1Code = insulationMaterial1 ? insulationMaterial1.code : null;
            const insulationMaterial1Width = insulationMaterial1 ? insulationMaterial1.thickness : null;
            const insulationMaterial2Code = insulationMaterial2 ? insulationMaterial2.code : null;
            const insulationMaterial2Width = insulationMaterial2 ? insulationMaterial2.thickness : null;
            if (insulationMaterial1) {
                result[this.codesBim.wallInsulationMaterial] = this.wallInsulators.get(insulationMaterial1Code);
                result[this.codesBim.wallInsulationWidth] = insulationMaterial1Width * 1000; // Convert meters to millimeters !
            }
            if (insulationMaterial2) {
                result[this.codesBim.wallInsulationMaterial2] = this.wallInsulators.get(insulationMaterial2Code);
                result[this.codesBim.wallInsulationWidth2] = insulationMaterial2Width * 1000; // Convert meters to millimeters !
            }
            const facingMaterial = wall.wall_type.layers.find(it => it.type === CN_MATERIAL_TYPE_FACING);
            if (facingMaterial && facingMaterial.code) {
                result[this.codesBim.wallFacing] = this.wallFacings.get(facingMaterial.code);
            }
            const lightStructureMaterial = wall.wall_type.layers.find(it => it.type === CN_MATERIAL_TYPE_LIGHT_STRUCTURE);
            if (lightStructureMaterial && lightStructureMaterial.code) {
                result[this.codesBim.wallLightStructure] = this.wallLightStructures.get(lightStructureMaterial.code);
            }
        } else {
            result[this.codesBim.balconyThickness] = wall.wall_type.thickness;
            result[this.codesBim.wallLength] = wall.bounds.length;
            result[this.codesBim.balconyHeight] = wall.wall_type.height;
        }
        return result;
    }

    getOpeningParameters(opening) {
        const result = {};
        result[this.codesBim.openingThickness] = opening.thickness;
        result[this.codesBim.openingWidth] = opening.opening_type.width;
        result[this.codesBim.openingHeight] = opening.opening_type.height;
        result[this.codesBim.openingShift] = opening.opening_type.z;
        result[this.codesBim.openingPanels] = opening.opening_type.panels;

        if (typeof (opening.opening_type.get_area) == 'function')
            result[this.codesBim.openingArea] = opening.opening_type.get_area();

        if (typeof (opening.opening_type.get_glazing_area) == 'function')
            result[this.codesBim.openingGlazingArea] = opening.opening_type.get_glazing_area();

        const openingFrame = this.openingFrames.get(opening.opening_type.frame)
        if (!!openingFrame) {
            result[this.codesBim.openingFrame] = openingFrame;
        }

        const openingGlazing = this.openingGlazings.get(opening.opening_type.glazing);
        if (!!openingGlazing) {
            result[this.codesBim.openingGlaze] = openingGlazing;
        }
        // Export glazing gaz only for double glazing
        if (opening.opening_type.glazing == 'double') {
            const glazing_gaz = opening.opening_type.glazing_gaz;
            let bbpGlazingGaz, bbpGlazingThickness;
            switch (glazing_gaz) {
                case 'air_6': {
                    bbpGlazingGaz = 'Air';
                    bbpGlazingThickness = '6';
                    break;
                }
                case 'air_8': {
                    bbpGlazingGaz = 'Air';
                    bbpGlazingThickness = '8';
                    break;
                }
                case 'air_12': {
                    bbpGlazingGaz = 'Air';
                    bbpGlazingThickness = '12';
                    break;
                }
                case 'air_16': {
                    bbpGlazingGaz = 'Air';
                    bbpGlazingThickness = '16';
                    break;
                }
                case 'argon_16': {
                    bbpGlazingGaz = 'Argon';
                    bbpGlazingThickness = '16';
                    break;
                }
            }
            result[this.codesBim.openingGlazingGaz] = bbpGlazingGaz;
            result[this.codesBim.openingGlazingThickness] = bbpGlazingThickness;
        }


        const openingType = this.openingTypes.get(opening.opening_type.opening);
        if (!!openingType) {
            result[this.codesBim.openingOpener] = openingType;
        }
        const openingTransom = this.openingTransoms.get(opening.opening_type.transom);
        if (!!openingTransom) {
            result[this.codesBim.openingTransom] = openingTransom;
        }
        if (opening.opening_type.category === 'window') {
            const openingSill = this.openingSills.get(opening.opening_type.sill);
            if (!!openingSill) {
                result[this.codesBim.openingSill] = openingSill;
            }
            const openingClosing = this.openingClosings.get(opening.opening_type.closing);
            if (!!openingClosing) {
                result[this.codesBim.openingClosing] = openingClosing;
            }
        }

        result[this.codesBim.openingUw] = opening.opening_type.Uw;
        result[this.codesBim.openingSw] = opening.opening_type.Sw;
        result[this.codesBim.openingTl] = opening.opening_type.Tl;

        return result;
    }

    getStairParameters(stair) {
        const result = {};
        result[this.codesBim.stairHeight] = stair.stair_height;
        result[this.codesBim.stairNumber] = stair.actual_stair_number;
        result[this.codesBim.stairDepth] = stair.stair_depth;
        return result;
    }

    getBeamParameters(beam, storey) {
        const result = {};
        result[this.codesBim.beamWidth] = beam.element_type.width * 1000;
        result[this.codesBim.beamThickness] = beam.element_type.thickness * 1000;
        // BB
        result[this.codesBim.beamBBWidth] = beam.element_type.width;
        if (beam.vertices) {
            let a = beam.vertices[0]
            let b = beam.vertices[1]
            const distance = (Math.sqrt(Math.pow(cn_dist(a, b), 2) + Math.pow(a[2] - b[2], 2))).toFixed(2);
            result[this.codesBim.beamBBLength] = distance;
        }
        if (beam.height !== undefined && beam.height !== null) {
            result[this.codesBim.beamBBHeight] = beam.height ? beam.height.toFixed(2) : storey.height.toFixed(2);
        }
        const beamMaterial = this.beamMaterials.get(beam.element_type.material);
        if (!!beamMaterial) {
            result[this.codesBim.beamMaterial] = beamMaterial;
        }
        const beamShape = this.beamShapes.get(beam.element_type.shape);
        if (!!beamShape) {
            result[this.codesBim.beamShape] = beamShape;
        }
        return result;
    }

    getPipeParameters(pipe) {
        const result = {};
        result[this.codesBim.pipeDiameter] = pipe.element_type.diameter;

        let a = pipe.vertices[0]
        let b = pipe.vertices[1]
        if (a[0] === b[0]) {
            // Vertical
            result[this.codesBim.pipeLength] = Math.abs(a[2] - b[2]).toFixed(2);
        } else {
            result[this.codesBim.pipeLength] = (Math.sqrt(Math.pow(cn_dist(a, b), 2) + Math.pow(a[2] - b[2], 2))).toFixed(2);
        }

        const pipeMaterial = this.pipeMaterials.get(pipe.element_type.material);
        if (!!pipeMaterial) {
            result[this.codesBim.pipeMaterial] = pipeMaterial;
        }
        return result;
    }

    getSlabParameters(slab) {
        const result = {};
        const bb = slab.get_bounding_box();
        result[this.codesBim.slabThickness] = slab.slab_type.thickness;
        result[this.codesBim.slabWidth] = bb.size[0];
        result[this.codesBim.slabLength] = bb.size[1];
        const structure = slab.slab_type.layers.find(l => !!this.slabStructures.get(l.code));
        if (structure) {
            result[this.codesBim.slabStructure] = this.slabStructures.get(structure.code);
        }
        return result;
    }

    getRoofParameters(slab) {
        const result = {};
        //*** full area  */
        const polygon = slab.build_3d_polygon(0,true);
        result[this.codesBim.roofArea] = polygon.get_area();
        result[this.codesBim.roofThickness] = slab.slab_type.thickness;
        return result;
    }

    getObjectParameters(object) {
        const result = {};
        result[this.codesBim.objectBBWidth] = object.size[0];
        result[this.codesBim.objectBBLength] = object.size[1];
        result[this.codesBim.objectBBHeight] = object.size[2];
        return result;
    }

    getWallFacingParameters(wall, side) {
        const result = {};
        const facing = wall.facings[side];
        if (facing) {
            if (wall.spaces[side].outside) {
                result[this.codesBim.exteriorWallFacing] = this.exteriorWallFacingTypes.get(facing.category);
            } else {
                result[this.codesBim.interiorWallFacing] = this.interiorWallFacingTypes.get(facing.category);
            }
            result[PARAM_CODE_BIM_COULEUR_BATI] = nearest_bbp_color_label(facing.color);
        }
        return result;
    }

    getFloorFacingParameters(space) {
        const result = {};
        const facing = space.facings[0];
        if (facing) {
            result[this.codesBim.floorFacing] = this.floorFacingTypes.get(facing.category);
            result[PARAM_CODE_BIM_COULEUR_BATI] = nearest_bbp_color_label(facing.color);
        }
        return result;
    }

    getCeilingFacingParameters(space) {
        const result = {};
        const facing = space.facings[1];
        if (facing) {
            result[this.codesBim.ceilingFacing] = this.ceilingFacingTypes.get(facing.category);
            result[PARAM_CODE_BIM_COULEUR_BATI] = nearest_bbp_color_label(facing.color);
        }
        return result;
    }
}


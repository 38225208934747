<div class="d-flex h-100 w-100">
    <div class="h-100 border-left-side-panel" [ngClass]="!diagnostic ? 'w-50' : 'w-25'">
        <app-documents-list
            *ngIf="intervention"
            [intervention]="intervention"
            [diagnostic]="diagnostic"
            [readonlyMode]="readonlyMode"
            [documentsFiltered]="documentsFiltered"
            (addedOrRemovedDocument)="onAddOrRemoveDocument()"
            (documentClicked)="onClickDocument($event)"
        >
        </app-documents-list>
    </div>
    <!--  
     * Si on est dans un diagnotic, la liste des documents fait 1/4 de la page et le détail fait 3/4 de la page
     * Sinon, la liste des document et le détail font chacun la moitié de la page.
    -->
    <div class="h-100" [ngClass]="!diagnostic ? 'w-50' : 'w-75'">
        <mat-drawer-container class="h-100 w-100" [hasBackdrop]="true">
            <mat-drawer #drawer [mode]="'over'">
                <app-documents-detail
                    *ngIf="documentSelected"
                    [document]="documentSelected"
                    [readonlyMode]="readonlyMode"
                    [diagnostic]="diagnostic"
                    [intervention]="intervention"
                    (closedEditPanel)="onCloseEditPanel()"
                    [showCheckpoints]="forDiagnostic"
                ></app-documents-detail>
            </mat-drawer>
            <mat-drawer-content></mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>

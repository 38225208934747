<div class="container d-flex flex-column h-100" *ngIf="isLoaded">
    <div class="verification-card-container">
        <verification-card
            *ngIf="typeMesurage === 'POLLUANT_VISITE_CHANTIER'"
            [isInEditMode]="isInEditMode"
            [isValidated]="isValidated"
            (onCancel)="cancelModification()"
            (onSave)="saveModification()"
            (onValidate)="validateTab()"
            (onEdit)="startModification()"
            [form]="formActivites"
        >
            Veuillez vérifier le type d'activité sélectionné
        </verification-card>
    </div>
    <div class="m-auto">
        <mat-card class="px-2 py-2 mb-4 d-flex flex-column justify-content-between">
            <mat-card-title>
                <app-section-title label="Types d'activité amiante" [helpToDisplay]="helpTypeDActivites">
                </app-section-title>
            </mat-card-title>

            <mat-card-content [formGroup]="formActivites">
                <div class="row px-4">
                    <div class="flex-grow-1">
                        <app-state-input-reactive
                            formControlName="activitesAmiante"
                            [disabled]="!isInEditMode"
                            [radioControl]="formActivites.get('activitesAmiante')"
                            [reset]="true"
                            [labels]="true"
                            [labelsAsIcons]="true"
                            [multiple]="true"
                            [choices]="activityTypes"
                            [(choiceValue)]="contenuDiagnostic.activitesAmiante.valeurs"
                        >
                        </app-state-input-reactive>
                    </div>
                    <app-notes-buttons [from]="contenuDiagnostic.activitesAmiante" [diagnostic]="diagnostic">
                    </app-notes-buttons>
                </div>
                <app-form-error
                    [control]="formActivites.get('activitesAmiante')"
                    message="Une des valeurs doit être choisie"
                ></app-form-error>
            </mat-card-content>
        </mat-card>
    </div>
</div>

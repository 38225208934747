import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseComponent, NotificationService } from 'src/app/commons-lib';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { EtatProgressionService } from '../../../../../../services/etat-progression.service';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CnSpinnerService } from 'src/app/modules/shared/cn-spinner/service/cn-spinner.service';
import { InterventionService } from 'src/app/services/intervention.service';
import { Intervention } from 'src/app/model/intervention.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PolluantPrelevement } from '../../../model/polluant-prelevement.model';
import { Bien, Niveau } from 'src/app/model/bien.model';
import { Besoin } from '../../../model/besoin.model';
import { Zone } from '../../../model/zone.model';
import { PolluantService } from '../../../services/polluant.service';
import { ConditionsPrelevement } from '../../../model/polluant-config.model';
import { PrelevementFormMateriauxComponent } from './form/meta/prelevement-form-materiaux/prelevement-form-materiaux.component';
import { PrelevementFormGeneralComponent } from './form/meta/prelevement-form-general/prelevement-form-general.component';
import { PrelevementFormPoseComponent } from './form/meta/prelevement-form-pose/prelevement-form-pose.component';
import { PrelevementFormRecuperationComponent } from './form/meta/prelevement-form-recuperation/prelevement-form-recuperation.component';
import { PrelevementFormAvantComponent } from './form/metop/prelevement-form-avant/prelevement-form-avant.component';
import { PrelevementFormPendantComponent } from './form/metop/prelevement-form-pendant/prelevement-form-pendant.component';
import { PrelevementFormApresComponent } from './form/metop/prelevement-form-apres/prelevement-form-apres.component';
import { StaticJsonService } from 'src/app/services/static-json.service';
import { PrelevementFormMestGeneralComponent } from './form/mest/prelevement-form-mest-general/prelevement-form-mest-general.component';
import { PrelevementFormAcrGeneralComponent } from './form/acr/prelevement-form-mest-general/prelevement-form-acr-general.component';
import { MatDialog } from '@angular/material/dialog';
import { PlanificationBesoinsComponent } from '../../../../../gestion-interventions/admin-creation-intervention/planification-besoins/planification-besoins.component';
import { MpcaItemOld } from '../../../model/mpca.model.old';

@Component({
    selector: 'app-prelevement-prelevement',
    templateUrl: './prelevement-prelevement.component.html',
    styleUrls: ['./prelevement-prelevement.component.scss'],
})
export class PrelevementComponent extends BaseComponent implements OnInit {
    diagnostic: Diagnostic;
    intervention: Intervention;
    contenuDiagnostic: PolluantPrelevement;
    isLoaded: Boolean = false;
    isInEditMode: Boolean = false;
    isValidated: Boolean = false;

    form: FormGroup;

    indexBesoinSelected: number;
    indexZoneSelected: number = 0;
    besoins: Besoin[] = [];
    biens: Bien[] = [];
    zones: Zone[] = [];
    selectedZones: Zone[] = [];
    private selectedZone: Zone;
    selectedBesoin: Besoin;
    availableVolumesIdInZone: string[];
    selectedTab: number = 0;
    selectedMpcaIndex: number = -1;

    polluantConfig: any;

    onglets = [];

    @ViewChild('dynamicComponent', { static: false, read: ViewContainerRef }) containerRef: ViewContainerRef;
    componentRef: any;

    constructor(
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService,
        public interventionService: InterventionService,
        private etatProgressionService: EtatProgressionService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private polluantService: PolluantService,
        private resolver: ComponentFactoryResolver,
        private readonly notificationService: NotificationService,
        private staticJsonService: StaticJsonService,
        private readonly matDialog: MatDialog
    ) {
        super();
    }

    ngOnInit(): void {
        this.cnSpinnerService
            .withSpinner(
                combineLatest([
                    this.interventionService.getCurrentIntervention(),
                    this.diagnosticService.getCurrentDiagnostic(),
                ])
            )
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ([intervention, diag]) => {
                    this.intervention = intervention;
                    this.diagnostic = diag;
                    combineLatest([this.polluantService.findOnePolluantConfigIndexedDB(this.diagnostic.idConfig)])
                        .pipe(takeUntil(this.ngUnsubscribe))
                        .subscribe(([polluantConfig]) => {
                            if (polluantConfig == null) {
                                this.notificationService.error(
                                    'Erreur : Merci de faire une synchro pour récupérer les configs en local.'
                                );
                                this.cnSpinnerService.hide();
                                return;
                            }
                            this.contenuDiagnostic = this.diagnostic.contenuDiagnostic as PolluantPrelevement;
                            this.polluantConfig = polluantConfig;
                            this.restoreData();
                            this.createForm();
                            this.isLoaded = true;

                            this.cnSpinnerService.hide();
                        });
                },
                () => this.cnSpinnerService.hide()
            );
    }

    createForm() {
        this.form = this.formBuilder.group({});
    }

    private restoreData(): void {
        this.zones = this.contenuDiagnostic.listeZones.data.listeZones;
        if (!this.zones) {
            const zoneHorsStrategie = new Zone();
            zoneHorsStrategie.nom = 'Hors stratégie';
            this.contenuDiagnostic.listeZones.data = {
                listeZones: [zoneHorsStrategie],
            };
            const niveaux = this.intervention.relationInterventionBiens[0].bien.description;
            let listeIdVolume: string[] = [];
            niveaux.forEach((niv) => {
                listeIdVolume = listeIdVolume.concat(niv.volumes.filter((v) => v.nom != 'Extérieur').map((v) => v.id));
            });
            zoneHorsStrategie.listeSurfaces[0].listeIdVolume = listeIdVolume;
            this.zones = this.contenuDiagnostic.listeZones.data.listeZones;
        }

        const zoneHS = this.zones.find((z) => z.nom === 'Hors stratégie');
        // Init Objectif mesurage
        if (!zoneHS.objectifMesurage?.length) {
            zoneHS.objectifMesurage = [];
            const conditionsPrelevement = [];
            this.polluantConfig.objectifsMesurage
                .filter((config) => !!config.conditionsPrelevement)
                .map((conf) => conf.conditionsPrelevement)
                .forEach((datas) => {
                    conditionsPrelevement.push(...datas);
                });
            conditionsPrelevement.forEach((cond) => {
                zoneHS.objectifMesurage.push(cond);
            });
        }
        // Init MPCA
        if (!this.contenuDiagnostic.prelevement.data.mpcaList?.length) {
            this.contenuDiagnostic.prelevement.data.mpcaList = [new MpcaItemOld()];
        }
        // Init processus
        zoneHS.listeMpca = this.contenuDiagnostic.prelevement.data.mpcaList;

        this.biens = this.intervention.relationInterventionBiens.map(
            (relationBienPrincipal) => relationBienPrincipal.bien
        );

        this.selectedZone = this.zones[this.indexZoneSelected];

        if (this.contenuDiagnostic.prelevement.data.besoinsList?.length) {
            this.besoins = this.contenuDiagnostic.prelevement.data.besoinsList.map((v) => v.besoinPrelevement);
        }

        this.besoins = this.besoins || [];
        if (!this.selectedBesoin) {
            this.besoinSelected({
                besoinIdToDisplay: this.besoins.length ? 0 : undefined,
            });
        }
        this.besoins.forEach((besoin) => (besoin.todo = besoin.interventionId === this.intervention.id));
    }

    besoinSelected(data: any) {
        // Si le besoin sélectionné est le même
        if (this.indexBesoinSelected == data.besoinIdToDisplay) {
            return;
        }

        // On récupère les données et on met à jour la liste des besoins
        this.indexBesoinSelected = data.besoinIdToDisplay;
        if (this.indexBesoinSelected == undefined) {
            return;
        }
        if (data.indexZoneSelected == this.zones.length) {
            this.selectedZones = this.zones;
        } else {
            this.selectedZones = [this.zones[this.indexZoneSelected] as Zone];
        }
        if (this.selectedZones[0].listeSurfaces == undefined && this.selectedZones[0].nom == 'Toutes les zones') {
            this.selectedZones = this.zones;
        }

        if (this.selectedZone) {
            this.availableVolumesIdInZone = this.selectedZone.listeSurfaces.reduce((acc, surface) => {
                return acc.concat(surface.listeIdVolume);
            }, []);
        } else {
            // Toutes les zones
            this.availableVolumesIdInZone = [];
            this.zones.forEach((zone) => {
                this.availableVolumesIdInZone = zone.listeSurfaces.reduce((acc, surface) => {
                    return acc.concat(surface.listeIdVolume);
                }, this.availableVolumesIdInZone);
            });
            this.availableVolumesIdInZone = this.availableVolumesIdInZone.filter(
                (v, i, a) => a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
            );
        }

        this.selectedBesoin = this.besoins[this.indexBesoinSelected];

        this.updateTabs();
    }

    /**
     * @description Met à jour les onglets en fonction du besoin sélectionné
     */
    private updateTabs(): void {
        this.selectedTab = 0;

        const _obj: ConditionsPrelevement = this.selectedBesoin.objectifMesurage;
        if (_obj.typePrelevement == 'META') {
            this.onglets = [
                {
                    nom: 'Matériaux',
                    component: PrelevementFormMateriauxComponent,
                    showMpca: true,
                },
                {
                    nom: 'Besoin',
                    component: PrelevementFormGeneralComponent,
                    showMpca: false,
                },
                {
                    nom: 'Pose',
                    component: PrelevementFormPoseComponent,
                    showMpca: false,
                },
                {
                    nom: 'Récupération',
                    component: PrelevementFormRecuperationComponent,
                    showMpca: false,
                },
            ];

            this.containerRef.clear();

            this.navigationTab(this.selectedTab, true);
        } else if (_obj.typePrelevement == 'METOP') {
            this.onglets = [
                {
                    nom: 'Matériaux',
                    component: PrelevementFormMateriauxComponent,
                    showMpca: true,
                },
                {
                    nom: 'Besoin',
                    component: PrelevementFormGeneralComponent,
                    showMpca: false,
                },
                {
                    nom: 'Avant',
                    component: PrelevementFormAvantComponent,
                    showMpca: false,
                },
                {
                    nom: 'Pendant',
                    component: PrelevementFormPendantComponent,
                    showMpca: false,
                },
                {
                    nom: 'Après',
                    component: PrelevementFormApresComponent,
                    showMpca: false,
                },
            ];

            this.containerRef.clear();

            this.navigationTab(this.selectedTab, true);
        } else if (_obj.typePrelevement == 'MEST') {
            this.onglets = [
                {
                    nom: 'Matériaux',
                    component: PrelevementFormMateriauxComponent,
                    showMpca: true,
                },
                {
                    nom: 'Besoin',
                    component: PrelevementFormMestGeneralComponent,
                    showMpca: false,
                },
            ];

            this.containerRef.clear();

            this.navigationTab(this.selectedTab, true);
        } else if (_obj.typePrelevement == 'ACR') {
            this.onglets = [
                {
                    nom: 'Matériaux',
                    component: PrelevementFormMateriauxComponent,
                    showMpca: true,
                },
                {
                    nom: 'Général',
                    component: PrelevementFormAcrGeneralComponent,
                    showMpca: false,
                },
            ];

            this.containerRef.clear();

            this.navigationTab(this.selectedTab, true);
        } else {
            this.onglets = [];
            // Fallback
            this.containerRef.clear();
        }

        // Reset index MPCA
        if (this.componentRef != undefined) {
            this.selectedMpcaIndex = -1;
            this.componentRef.instance['selectedMpcaId'] = undefined;
        }
    }

    /**
     * @description Affiche le formulaire qui correspond à l'onglet cliqué
     * @param index Index de l'onglet cliqué dans la liste des onglets
     */
    navigationTab(index: number, force: boolean = false) {
        // Si l'onglet cliqué est le même que celui qui est déjà sélectionné
        // alors rien ne se passe
        if (index == this.selectedTab && !force) {
            return;
        }

        // Mise à jour de l'index de l'onglet sélectionné
        this.selectedTab = index;

        // Nettoyage du container
        this.containerRef.clear();

        // Création du composant associé à l'onglet cliqué dans le container
        let selectedComponent: any = this.onglets[this.selectedTab].component;
        let resolver = this.resolver.resolveComponentFactory(selectedComponent);
        this.componentRef = this.containerRef.createComponent(resolver);

        // Mise à jour des inputs et outputs du composant
        if (this.componentRef) {
            this.componentRef.instance['diagnostic'] = this.diagnostic;
            this.componentRef.instance['polluantConfig'] = this.polluantConfig;
            this.componentRef.instance['selectedBesoinId'] = this.selectedBesoin.id;
            this.componentRef.instance['availableVolumesIdInZone'] = this.availableVolumesIdInZone;
            this.componentRef.instance['selectedZones'] = this.selectedZones;
            this.componentRef.instance['selectedMpcaId'] = undefined;

            this.componentRef.instance['volumes'] =
                this.intervention.relationInterventionBiens[0].bien.description.reduce((acc: any, niveau: Niveau) => {
                    return acc.concat(niveau.volumes);
                }, []);
            // Output
            this.componentRef.instance.formChange.subscribe((contenuDiagnostic) => {
                this.diagnostic.contenuDiagnostic = contenuDiagnostic;
                this.checkValidity();
                this.restoreData();
            });
        }
    }

    /**
     * Sélectionne un MPCA et affiche le formulaire chargé avec ses données
     * @param index Index du MPCA sélectionné
     */
    selectMpca(index: number) {
        this.selectedMpcaIndex = index;
        this.componentRef.instance['selectedMpcaId'] = this.contenuDiagnostic.prelevement.data.mpcaList[index].id;
    }

    /**
     * Duplique le MPCA sélectionné
     * @param index Index du MPCA à dupliquer
     */
    dupliquerMpca(index: number) {
        this.contenuDiagnostic.prelevement.data.mpcaList.splice(
            index,
            0,
            this.contenuDiagnostic.prelevement.data.mpcaList[0]
        );
    }

    /**
     * Supprime un MPCA de la liste globale
     * @param index Index du MPCA à supprimer
     */
    supprimerMpca(index: number) {
        // Si le MPCA supprimé est le MPCA sélectionné, alors on le déselectionne
        if (index == this.selectedMpcaIndex) {
            this.selectedMpcaIndex = -1;
        }

        this.contenuDiagnostic.prelevement.data.mpcaList.splice(index, 1);
    }

    /**
     * Modifie le MPCA sélectionné
     * @param index Index du MPCA à modifier
     */
    modifierMpca(index: number) {
        console.log(this.contenuDiagnostic.prelevement.data.mpcaList[index]);
    }

    /**
     * Défini si la liste des MPCA doit être affichée
     * Renvoi 'true' uniquement si l'onglet sélectionné est Materiaux
     */
    showMpcaList() {
        if (this.selectedBesoin == undefined || this.onglets.length == 0) {
            return false;
        }

        const _obj: ConditionsPrelevement = this.selectedBesoin.objectifMesurage;
        if (_obj.typePrelevement != 'METOP' && _obj.typePrelevement != 'META') {
            return false;
        }

        return this.onglets[this.selectedTab].showMpca;
    }

    private checkValidity() {
        const code = this.route.snapshot.data['code'];
        this.etatProgressionService.updateDiagnostic(code, 'VOID', this.diagnostic, true);
    }
    onPlanifierBesoin() {
        const refDialog = this.matDialog.open(PlanificationBesoinsComponent);
        refDialog.componentInstance.isCreation = false;
        refDialog.componentInstance.intervention = this.intervention;
        refDialog.componentInstance.diagnostic = this.diagnostic;
        refDialog.afterClosed().subscribe(() => this.restoreData());
    }
}

export type TypeCommentaire =
    /** Recommandation générale pour un diagnostic */
    | 'RECOMMANDATION'

    /** Constatation diverse spécifique pour un élément */
    | 'CONSTATATION_DIVERSE'

    /** Constatation diverse générale pour un diagnostic */
    | 'CONSTATATION_DIVERSE_GENERALE'

    /** Note personnelle */
    | 'NOTE_PERSONNELLE'

    /** Justification de non conformité */
    | 'JUSTIFICATION_NON_CONFORMITE'

    /** Justification de réserve */
    | 'JUSTIFICATION_RESERVE'

    /** Justification de non visite */
    | 'JUSTIFICATION_NON_VISITE'

    /** Justification de non conformitée */
    | 'JUSTIFICATION_NON_CONFORMITE'

    /** Justification d'annulation de la prestation */
    | 'JUSTIFICATION_ANNULATION_PRESTATION'

    /** Justification de la modification des techniques d'analyses d'un prélèvement */
    | 'JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT'

    /** Motif de modification du fichier */
    | 'MOTIF_MODIFICATION_FICHIER'
    | 'JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT'

    /** Justification de la non vérification d'un point de contrôle en électricité */
    | 'MOTIF_NON_VERIFIABLE'

    /** Remarques en électricité */
    | 'REMARQUE'
    /** Motif non renseigné pour numéro de compteur - électricité */
    | 'MOTIF_NON_RENSEIGNE'
    /** Motif non démontable pour les capots des tableaux en électricité */
    | 'MOTIF_NON_DEMONTABLE';

export const typesCommentaire: TypeCommentaire[] = [
    'RECOMMANDATION',
    'CONSTATATION_DIVERSE',
    'CONSTATATION_DIVERSE_GENERALE',
    'NOTE_PERSONNELLE',
    'JUSTIFICATION_NON_CONFORMITE',
    'JUSTIFICATION_RESERVE',
    'JUSTIFICATION_NON_VISITE',
    'JUSTIFICATION_ANNULATION_PRESTATION',
    'JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT',
    'MOTIF_MODIFICATION_FICHIER',
    'MOTIF_NON_VERIFIABLE',
    'REMARQUE',
    'MOTIF_NON_DEMONTABLE',
    'MOTIF_NON_RENSEIGNE',
    'JUSTIFICATION_NON_CONFORMITE',
    // À COMPLÉTER AVEC LES DIFFÉRENTS TYPES DE COMMENTAIRES...
];

/**
 * À partir d'un type de commentaire, renvoie le label associé.
 */
export function typeCommentaireToLabel(typeCommentaire: string | TypeCommentaire): string {
    switch (typeCommentaire) {
        case 'NOTE_PERSONNELLE':
            return 'Note personnelle';

        case 'RECOMMANDATION':
            return 'Recommandation';

        case 'CONSTATATION_DIVERSE':
            return 'Constatation diverse';

        case 'CONSTATATION_DIVERSE_GENERALE':
            return 'Constatation diverse générale';

        case 'JUSTIFICATION_NON_VISITE':
            return 'Justification de non visite';

        case 'JUSTIFICATION_NON_CONFORMITE':
            return 'Justification de non conformité';

        case 'JUSTIFICATION_RESERVE':
            return 'Justification de réserve';

        case 'JUSTIFICATION_ANNULATION_PRESTATION':
            return `Justification d'annulation de la prestation`;

        case 'JUSTIFICATION_MODIFICATION_TECHNIQUES_ANALYSES_PRELEVEMENT':
            return `Justification de la modification de l'analyse technique`;

        case 'MOTIF_MODIFICATION_FICHIER':
            return 'Motif de la modification du fichier';

        case 'MOTIF_NON_VERIFIABLE':
            return `Motif non vérifiable`;

        case 'REMARQUE':
            return `Remarque`;

        case 'MOTIF_NON_DEMONTABLE':
            return `Motif non démontable`;

        case 'JUSTIFICATIF_NON_CONFORMITE':
            return `Justificatif de non conformitée`;

        case 'MOTIF_NON_RENSEIGNE':
            return `Motif non renseigné`;

        default:
            return typeCommentaire;
    }
}

<div class="pb-4">
    <!--SYNTHÈSE-->
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">SYNTHÈSE</div>
    <div class="block-synthese px-5 py-4 d-flex align-items-center">
        <div class="block-icon mr-3" [ngClass]="noAnomalie ? 'conforme' : 'non-conforme'">
            <i *ngIf="noAnomalie === false" class="fas fa-ban"></i>
            <i *ngIf="noAnomalie" class="fas fa-check"></i>
        </div>
        <div class="block-label mr-5 text-justify">
            <span *ngIf="noAnomalie === false"
                >L'installation intérieure d'électricité comporte une ou plusieurs anomalies</span
            >
            <span *ngIf="noAnomalie">L'installation intérieure d'électricité ne comporte aucune anomalie</span>
        </div>
    </div>
</div>

<!--CARD ANO-->
<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        CONCLUSION RELATIVE À L'ÉVALUATION DES RISQUES POUVANT PORTER ATTEINTE À LA SÉCURITÉ DES PERSONNES
    </div>
    <table class="mb-2" [style.width]="'100%'">
        <ng-template ngFor let-item [ngForOf]="conclusionLibelles">
            <tr>
                <td class="p-2">
                    <mat-checkbox
                        [checked]="
                            item.order == 1
                                ? !diagnosticData.anomalieDetectee
                                : item.order === 2
                                ? diagnosticData.anomalieDetectee
                                : item.order === 3
                                ? diagnosticData.anomalieDetectee && diagnosticData.mesureCompensatoireDetectee
                                : false
                        "
                        class="flex-grow-0"
                        color="warn"
                        disabled
                    ></mat-checkbox>
                </td>
                <td class="p-2">{{ item.description }}</td>
            </tr>
        </ng-template>
    </table>
    <!-- CONCLUSION EVAL RISQUE-->
    <ng-template ngFor let-eval [ngForOf]="evalRisque">
        <div class="block-subtitle ml-4 mb-2">{{ eval.titre }}</div>
        <div class="px-5 ml-2 mb-4 bloc-content">
            <table class="mt-4" [style.width]="'100%'">
                <ng-template ngFor let-ano [ngForOf]="eval.conclusionEvalRisque">
                    <tr>
                        <td class="p-2">
                            <mat-checkbox
                                [checked]="ano.checked"
                                class="flex-grow-0"
                                color="warn"
                                disabled
                            ></mat-checkbox>
                        </td>
                        <td class="p-2">{{ ano.reference }}</td>
                        <td class="p-2">{{ ano.description }}</td>
                    </tr>
                </ng-template>
            </table>
        </div>
    </ng-template>
    <!-- TABLE DETAIL SUR LES INSTAL. PARTI.-->
    <div *ngIf="diagnosticData.constatationDiverse?.length" class="px-5 ml-2 mb-4 bloc-content">
        <table [style.width]="'100%'">
            <tr>
                <th class="p-2">Domaines</th>
                <th class="p-2">Libellé et localisations des installations particulères</th>
            </tr>
            <ng-container *ngFor="let cDiverse of diagnosticData.constatationDiverse">
                <tr>
                    <td class="warn p-2">{{ cDiverse.referenceConclusionEvalRisque }}</td>
                    <td class="p-2">{{ cDiverse.description }}</td>
                </tr>
            </ng-container>
        </table>
    </div>
    <!-- LISTE DES AnomaliesPointControle -->
    <div *ngIf="diagnosticData.anomaliesPointControle?.length" class="px-5 ml-2 mb-4 bloc-content">
        <table [style.width]="'100%'">
            <tr>
                <th class="p-2">Domaines</th>
                <th class="p-2">Libellé et localisations des anomalies</th>
                <th class="p-2">Libellé des mesures compensatoires correctement mises en oeuvre</th>
                <th class="p-2"></th>
            </tr>
            <ng-container *ngFor="let item of diagnosticData.anomaliesPointControle">
                <tr>
                    <td class="warn p-2">{{ item.conclusionEvalRisque?.reference }}</td>
                    <td class="p-2">
                        {{
                            item.pointControle.libelleRapport
                                ? item.pointControle.libelleRapport
                                : item.pointControle.description
                        }}
                        <br />
                        <span class="primary">
                            <p *ngFor="let com of item.pointControle.commentaires">
                                <i>- {{ com.contenu }}</i>
                            </p>
                        </span>
                    </td>
                    <td class="p-2">{{ item.mesureCompensatoire?.libelleRapport }}</td>
                    <td class="p-2">
                        <button
                            *ngIf="!readonlyMode && item.pointControle.commentaires?.length"
                            mat-icon-button
                            class="small-button button-group primary"
                            (click)="editDisplayedComments(item.pointControle.commentaires)"
                        >
                            <mat-icon class="icon-small">edit</mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-container>
        </table>
    </div>

    <!-- AVERTISSEMENT PARTICULIER -->
    <ng-template [ngIf]="diagnosticData?.nonVerifiablePointDeControle?.length">
        <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">AVERTISSEMENT PARTICULIER</div>
        <div class="block-subtitle ml-4 mb-2">Points de contrôle n'ayant pu être vérifiés</div>
        <div class="px-5 ml-2 mb-4 bloc-content">
            <table [style.width]="'100%'">
                <tr>
                    <th class="p-2">Domaines</th>
                    <th class="p-2">Libellé et localisations des anomalies</th>
                    <th class="p-2">Commentaires</th>
                    <th class="p-2"></th>
                </tr>
                <ng-container *ngFor="let noVerifPC of diagnosticData.nonVerifiablePointDeControle">
                    <tr class="warn">
                        <td class="p-2">{{ noVerifPC.conclusionEvalRisque?.reference }}</td>
                        <td class="p-2">
                            {{
                                noVerifPC.pointControle.libelleRapport
                                    ? noVerifPC.pointControle.libelleRapport
                                    : noVerifPC.pointControle.description
                            }}
                        </td>
                        <td class="p-2 primary">
                            <p *ngFor="let item of noVerifPC.pointControle.commentaires">
                                <i>- {{ item.contenu }}</i>
                            </p>
                        </td>
                        <td class="p-2">
                            <button
                                *ngIf="!readonlyMode && noVerifPC.pointControle.commentaires?.length"
                                mat-icon-button
                                class="small-button button-group primary"
                                (click)="editDisplayedComments(noVerifPC.pointControle.commentaires)"
                            >
                                <mat-icon class="icon-small">edit</mat-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </ng-template>

    <!-- CONCLUSION RELATIVE À L'ÉVALUATION DES RISQUES RELEVANT DU DEVOIR DE CONSEIL DE PROFESSIONNEL -->
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        CONCLUSION RELATIVE À L'ÉVALUATION DES RISQUES RELEVANT DU DEVOIR DE CONSEIL DE PROFESSIONNEL
    </div>
    <div
        *ngIf="!conclusionText && !editing"
        (click)="editing = true"
        class="block-subtitle ml-4 mb-2 primary underlined"
    >
        Ajouter une conclusion
    </div>
    <div class="px-5 ml-2 mb-4 bloc-content">
        <p *ngIf="!editing" (click)="editing = true">{{ conclusionText }}</p>
        <textarea class="w-100" *ngIf="editing" [(ngModel)]="conclusionText" (blur)="saveConclusion()"></textarea>
    </div>
</div>

<div>
    <div class="block-title p-2 pl-5 mt-3 mb-4 d-inline-block">
        {{ 'Liste des volumes visités ou non visités' | uppercase }}
    </div>
    <div class="block-subtitle ml-4 mb-2">Liste des volumes visités</div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container *ngFor="let bien of diagnosticData.volumesVisites.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th class="niveau">Niveau</th>
                    <th class="volume">Volume</th>
                    <ng-container *ngIf="diagnosticData.optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="diagnosticData.optionPlan ? '7' : '5'" class="text-center p-4">
                        Aucun volume visité
                    </td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr *ngFor="let vol of lv.volumes">
                        <td>
                            <b>{{ lv.niveau }}</b>
                        </td>
                        <td>
                            {{ vol.volume }}
                        </td>
                        <ng-container *ngIf="diagnosticData.optionPlan">
                            <td>
                                <ng-container *ngFor="let screen of vol.plans"> {{ screen.nom }} <br /> </ng-container>
                            </td>
                            <td></td>
                        </ng-container>
                        <td>
                            <ng-container *ngFor="let com of vol.commentaires">
                                <div [ngClass]="exclusionVolumesVisites.includes(com.id) ? 'hidden-com' : ''">
                                    {{ com.contenu }} <br />
                                </div>
                            </ng-container>
                        </td>
                        <td></td>
                    </tr>
                </ng-container>
            </table>
        </ng-container>
    </div>

    <div class="block-subtitle ml-4 mb-2">Liste des volumes non visités et partiellement visités</div>
    <div class="px-5 ml-2 text-justify" *ngIf="disclaimer">
        {{ disclaimer }}
    </div>
    <div class="block-content px-5 ml-2 mb-4">
        <ng-container *ngFor="let bien of diagnosticData.volumesNonVisites.biens">
            <div class="block-subtitle-2 mt-4 mb-2">{{ bien.bien }}</div>
            <table>
                <tr>
                    <th class="niveau">Niveau</th>
                    <th class="volume">Volume</th>
                    <ng-container *ngIf="diagnosticData.optionPlan">
                        <th class="plan">Plan</th>
                        <th class="edit-comment"></th>
                    </ng-container>
                    <th class="visite">État</th>
                    <th>Commentaires</th>
                    <th class="edit-comment"></th>
                </tr>

                <tr *ngIf="bien.niveaux.length === 0">
                    <td [attr.colspan]="diagnosticData.optionPlan ? '7' : '5'" class="text-center p-4">
                        Aucun volume non visité
                    </td>
                </tr>

                <ng-container *ngFor="let lv of bien.niveaux">
                    <tr *ngFor="let vol of lv.volumes">
                        <td>
                            <b>{{ lv.niveau }}</b>
                        </td>
                        <td>
                            {{ vol.volume }}
                        </td>
                        <ng-container *ngIf="diagnosticData.optionPlan">
                            <td>
                                <ng-container *ngFor="let screen of vol.plans"> {{ screen.nom }} <br /> </ng-container>
                            </td>
                            <td></td>
                        </ng-container>
                        <td>
                            {{ vol.visite.valeur }}
                        </td>
                        <td>
                            <ng-container *ngFor="let com of vol.commentaires">
                                <div [ngClass]="exclusionVolumesNonVisites.includes(com.id) ? 'hidden-com' : ''">
                                    {{ com.contenu }} <br />
                                </div>
                            </ng-container>
                        </td>
                        <td></td>
                    </tr>
                </ng-container>
            </table>
        </ng-container>
    </div>
</div>

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CSVRecord } from './CSVModel';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-app-info',
    templateUrl: './app-info.component.html',
    styleUrls: ['./app-info.component.scss'],
})
export class AppInfoComponent implements OnInit, AfterViewInit {
    packageJson = require('../../../../package.json');
    appVersion = this.packageJson.version as string;
    cnmapVersion = this.packageJson.dependencies['@acenv/cnmap-angular-editor-lib'] as string;
    apiVersion$: Observable<string>;
    csvData: any[] = []; // Déclarez une variable pour stocker les données CSV
    dataSource: MatTableDataSource<CSVRecord> = new MatTableDataSource<CSVRecord>([]);
    displayedColumns: string[] = ['Version', 'Date', 'Prestation', 'RefTicket', 'Descriptif'];
    filtre: any[] = [{ value: '', viewValue: 'Aucun' }];
    selectedValue: string = '';
    selectedFilter: string = '';

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private readonly http: HttpClient) {}

    ngOnInit(): void {
        // Récupération des versions du logiciel
        this.apiVersion$ = this.http.get<{ version: string }>(`${environment.apiUrl}/version/wizydiag`).pipe(
            map((s) => (s.version ? s.version : 'Inconnu')),
            catchError((e) => 'Erreur lors de la récupération du numéro de version API')
        );

        const csvFilePath = 'assets/Changelog.csv';

        // Récupération des données CSV
        this.http.get(csvFilePath, { responseType: 'text' }).subscribe(
            (data) => {
                this.csvData = this.parseCSV(data); // Stock les données CSV dans une variable
                this.updateFilterOptions(); // Met à jour la liste de filtres
                this.dataSource.data = this.csvData;
                this.dataSource.filterPredicate = (data: CSVRecord, filter: string) =>
                    data.Prestation.toLowerCase().includes(filter.toLowerCase());
            },
            (error) => {
                console.error(error);
            }
        );
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    /**
     * Récupère ligne par ligne des données CSV
     * @param {string} csvText
     * @returns {any[]}
     * @memberof AppInfoComponent
     */
    parseCSV(csvText: string): any[] {
        const lines = csvText.split('\n');
        const headers = lines[0].split('","');
        const results: any[] = [];

        for (let i = 1; i < lines.length; i++) {
            const line = lines[i].split('","');
            if (line.length === headers.length) {
                const obj: { [key: string]: string } = {};
                for (let j = 0; j < headers.length; j++) {
                    // Nettoyez les noms de colonnes et les valeurs
                    const cleanedHeader = headers[j].replace(/"/g, '').trim();
                    const cleanedValue = line[j].replace(/"/g, '').replace(/\r/g, '').trim();
                    obj[cleanedHeader] = cleanedValue;
                }
                results.push(obj);
            } else {
                console.log('Erreur nombre colonne : ');
                console.log(line);
            }
        }

        return results;
    }

    /**
     * Applique un filtre sur la liste des données CSV
     * @param selectedValue
     */
    applyFilter() {
        if (this.selectedValue === '') {
            // Si "Aucun" est sélectionné, réinitialisez le filtre
            this.dataSource.filter = '';
        } else {
            // Appliquez le filtre sur la colonne "Prestation" en recherchant une correspondance exacte
            this.dataSource.filter = this.selectedValue.trim().toLowerCase();
        }

        // Réinitialisez la pagination après le filtrage
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    /**
     * Ajoute à la liste des filtres les différentes préstations
     */
    updateFilterOptions() {
        // Extrait les valeurs uniques de la colonne "Prestation/Catégorie"
        const uniqueValues = Array.from(new Set(this.csvData.map((item) => item.Prestation)));

        // Créez un tableau d'objets pour la liste de filtres
        this.filtre.push(...uniqueValues.map((value) => ({ value: value?.toLowerCase(), viewValue: value })));
    }

    /**
     * Recherche les données dans la liste
     * @param event
     * @memberof AppInfoComponent
     */
    reserchFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

        // Appliquez le filtre spécifique à la colonne "Prestation"
        this.dataSource.filter = this.selectedValue.trim().toLowerCase();

        // Appliquez la recherche sur toutes les colonnes
        const customFilter = (data: CSVRecord): boolean => {
            const prestationMatch = data.Prestation.toLowerCase().includes(this.selectedValue.trim().toLowerCase());
            const searchMatch = Object.values(data).some((value: string) => value.toLowerCase().includes(filterValue));
            return prestationMatch && searchMatch;
        };

        // Utilisez la fonction de filtrage personnalisée pour filtrer les données
        this.dataSource.data = this.csvData.filter(customFilter);

        // Réinitialisez la pagination après le filtrage
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
    /**
     * Export les données dans un fichier CSV
     * @memberof AppInfoComponent
     */
    exportToExcel() {
        // Appliquez le filtre actuel au dataSource
        const filteredData = this.dataSource.filteredData || this.dataSource.data;

        // Créez une nouvelle feuille de calcul Excel
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData);

        // Personnalisez la mise en page de la feuille de calcul
        ws['!cols'] = [{ wpx: 150 }, { wpx: 200 }, { wpx: 150 }, { wpx: 150 }, { wpx: 850 }];

        // Créez un classeur Excel et ajoutez la feuille de calcul
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');

        // Exportez le fichier Excel
        XLSX.writeFile(wb, 'exported-data.xlsx');
    }
}

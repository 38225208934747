<h1 mat-dialog-title class="primary d-flex justify-content-between">
    <span *ngIf="newNiveau">Ajouter un niveau</span>
    <span *ngIf="!newNiveau">Éditer un niveau</span>
    <button mat-button (click)="cancel()">
        <lib-icon>close</lib-icon>
    </button>
</h1>

<div mat-dialog-content [formGroup]="form">
    <div class="d-flex">
        <div class="w-100">
            <mat-form-field class="w-100">
                <input placeholder="Nom" matInput type="text" formControlName="nom" />
                <mat-error *ngIf="form.get('nom').hasError('erreurNomNiveau')"
                    >Un autre niveau dans ce bien possède déjà le même nom.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>

<div mat-dialog-actions class="justify-content-end">
    <button mat-button color="primary" (click)="cancel()">
        <lib-icon>close</lib-icon>
        <span>{{ 'Annuler' | uppercase }}</span>
    </button>
    <button
        mat-raised-button
        color="accent"
        [disabled]="!form.valid"
        (click)="confirm()"
        data-cy="confirmer-ajouter-niveau-button"
    >
        <lib-icon>check</lib-icon>
        <span>{{ 'Confirmer' | uppercase }}</span>
    </button>
</div>

"use strict";

//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Object
//***********************************************************************************
//***********************************************************************************

import {fh_box, fh_clone, fh_matrix, fh_scene, fh_solid} from "@acenv/fh-3d-viewer";
import {cn_object_instance} from "./cn_object_instance";
import {cn_color_hexa_to_rgb} from "../utils/cn_utilities";
import {cn_element_type} from "./cn_element_type";
import {cn_element_type_visitor, CODE_BIM_INCONNU} from '..';

export class cn_object extends cn_element_type {
    constructor() {
        super();
        this.removable = false;
        this.name = "";
        this.icon_id = "";
        this.size = [1, 1, 1]; // 0 = depth, 1 = width, 2 = height
        this.set_shape("square");
        this.set_contact("floor");
    }

    //*******************************************************
    /**
     * clone this
     * @returns {cn_object}
     */
    clone() {
        return cn_object.unserialize(this.serialize());
    }

    //***********************************************************************************
    //**** keys
    //***********************************************************************************
    model_keys() {
        return ["name", "icon_id", "size", "source"];
    }

    //*******************************************************
    /**
     * serialize
     * @returns {object} json object
     */
    serialize() {
        const json = {};
        json.class_name = "cn_object";
        json.ID = this.ID;
        json.name = this.name;
        json.icon_id = this.icon_id;
        json.size = fh_clone(this.size);
        // Shallow copy source
        json.source = Object.assign({}, this.source);
        json.source.parameters = Object.assign({}, this.source.parameters);
        return json;
    }

    //*******************************************************
    /**
     * Unserialize
     * @param {object} json : json object
     * @returns {cn_object}
     */
    static unserialize(json) {
        if (typeof (json.ID) != 'string') return null;
        if (typeof (json.name) != 'string') return null;
        if (typeof (json.icon_id) != 'string') return null;
        if (typeof (json.size) != 'object') return null;

        const obj = new cn_object();
        obj.ID = json.ID;
        obj.name = json.name;
        obj.icon_id = json.icon_id;
        obj.size = fh_clone(json.size);
        if (typeof (json.source) == 'object')
            obj.source = json.source;

        obj.update_geometry();
        return obj;
    }

    //*******************************************************
    /**
     * returns displayable label
     * @returns {string}
     */
    get_label() {
        if (this.name != "") return this.name;

        if (this.get_source_type() == "wikipim")
            return this.source.model;
        return "Forme simple " + (100 * this.size[0]).toFixed(0) + " x " + (100 * this.size[1]).toFixed(0) + " x " + (100 * this.size[2]).toFixed(0);
    }

    //*******************************************************
    /**
     * Copy contents of object inside this (copies fields except ID)
     * @param {cn_object} object
     */
    copy_from(object) {
        for (let k in this) {
            // @ts-ignore
            if (k != "ID") this[k] = object[k];
        }
    }

    //***********************************************************************************
    /**
     * Draw object in a svg area (icon id if present, orelse top view)
     * @param {number} width in pixels
     * @param {number} height in pixels
     * @returns {string} svg string
     */
    draw_svg_icon(width, height) {
        if (!this.icon_id) {
            return this.draw_top_view(width, height);
        }

        const sz0 = 30;
        const sz1 = 30;
        const x = (width - sz0) / 2;
        const y = (height - sz1) / 2;
        const url = cn_object_instance.image_id_to_url(this.icon_id);
        if (url == "") return "";
        return `<image xlink:href='${url}' x='${x}' y='${y}' width='${sz0}' height='${sz1}' preserveAspectRatio='none' />`;
    }

    /**
     * Draw object top view in a svg area
     * @param {number} width in pixels
     * @param {number} height in pixels
     * @returns {string} svg string
     */
    draw_top_view(width, height) {
        const image_ratio = width / height;
        const object_ratio = this.size[0] / this.size[1];
        const scale = (object_ratio > image_ratio) ? width / this.size[0] : height / this.size[1];
        const sz0 = this.size[0] * scale;
        const sz1 = this.size[1] * scale;
        const x = (width - sz0) / 2;
        const y = (height - sz1) / 2;
        const source_type = this.get_source_type();
        let html = "";
        const top_view_data = this.get_top_view_data();
        if (top_view_data) {
            let url = cn_object_instance.image_id_to_url(top_view_data);
            if (url !== '') {
                html += `<image xlink:href="${url}" x='${x}' y='${y}' width='${sz0}' height='${sz1}' preserveAspectRatio='none' />`;
            }
        } else if (source_type == "circle")
            html += "<ellipse cx='" + (width / 2) + "' cy='" + (height / 2) + "' rx='" + (sz0 / 2) + "' ry='" + (sz1 / 2) + "' ";
        else
            html += "<rect x='" + x + "' y='" + y + "' width='" + sz0 + "' height='" + sz1 + "' ";

        html += " style='stroke:black; stroke-width: 1px; fill:" + this.get_color() + "' />";
        return html;
    }

    /**
     * Returns default height, in meters (always 0 is contact is not wall)
     * @returns {number}
     */
    get_default_height() {
        const inst = this.get_instanciation();
        if (!inst) return 0;
        if (typeof (inst.anchor_point) != 'object') return 0;

        if (this.get_contact() === "wall")
            return -inst.anchor_point[2];

        return 0;
    }

    /**
     * Returns contact : "floor","wall" or "ceiling"
     * @returns {string}
     */
    get_contact() {
        const inst = this.get_instanciation();
        if (!inst) return "floor";
        if (typeof (inst.anchor_normal) != 'object') return "floor";
        if (inst.anchor_normal[2] < -0.5) return "floor";
        if (inst.anchor_normal[2] > 0.5) return "ceiling";
        return "wall";
    }

    /**
     *
     * Returns color as hexadecimal string
     * @returns {string}
     */
    get_color() {
        this._init_default_source_if_empty();
        return this.source.color;
    }

    /**
     * sets size for given index, in meters
     * @param {number} index (0 = depth, 1 = width, 2 = height)
     * @param {number} v
     */
    set_size(index, v) {
        if (this.size[index] == v) return;
        if (this.get_source_type() == "wikipim")
        {
            this.size[index] = v;
        }
        else
        {
            const old_size = fh_clone(this.size);
            this.size[index] = v;
            const inst = this.get_instanciation();
            if (inst && typeof (inst.anchor_point) == "object") {
                const contact = this.get_contact();
                if (contact == "wall")
                    inst.anchor_point[1] = 0.5 * this.size[1];
                else if (contact == "ceiling") {
                    inst.anchor_point[2] += this.size[2] - old_size[2];
                }
            }

            this.update_geometry();
        }
    }

    /**
     * Sets contact : "floor","wall" or "ceiling"
     * @param {string} v
     */
    set_contact(v) {
        if (this.get_instanciation() && v === this.get_contact()) return;

        const inst = this.get_instanciation(true);

        if (v === "floor") {
            inst.anchor_point = [0, 0, 0];
            inst.anchor_normal = [0, 0, -1];
        } else if (v === "ceiling") {
            inst.anchor_point = [0, 0, this.size[2]];
            inst.anchor_normal = [0, 0, 1];
        } else if (v === "wall") {
            inst.anchor_point = [0,this.size[1] * 0.5, 0];
            inst.anchor_normal = [1, 0, 0];
        }
    }

    /**
     * Sets default height in meters, if contact is wall.
     * Nothing is done if contact is not wall, set_contact must be called before.
     * @param {number} v
     */
    set_default_height(v) {
        if (this.get_instanciation() && v === this.get_default_height()) return;

        const inst = this.get_instanciation(true);

        if (this.get_contact() !== "wall") return;

        if (v != null) {
            inst.anchor_point[2] = -v;
        } else {
            inst.anchor_point[2] = 0;
        }
    }


    /**
     * Sets color for a personalized object
     * @param {string} color hexadecimal value : 'ffffff'
     */
    set_color(color) {
        if (!this.source) this.set_shape("square");
        if (this.source.color === color) return;
        this.source.color = color;
        this.update_geometry();
    }

    //***********************************************************************************
    //**** Object source
    //***********************************************************************************
    /**
     * returns object source type
     * @returns {"square"|"circle"|"wikipim"}
     */
    get_source_type() {
        if (typeof (this.source) != 'object' || typeof (this.source.type) != 'string')
            this.source = {type: "square"};
        return this.source.type;
    }

    /**
     * returns true if bbo source
     * @returns {boolean}
     */
    is_bbo_source() {
        return this.get_source_type() === 'wikipim';
    }

    /**
     * returns bbo source, or null if source is not bbo
     * @return {Object | null}
     */
    get_bbo_source() {
        if (!this.is_bbo_source())
            return null;
        return this.source;
    }

    /**
     * removes bbo source from an object
     */
    remove_bbo_source() {
        if (!this.is_bbo_source())
            return null;
        this.source = {type: "square"};
    }

    /**
     * Sets custom source
     * @param {string} name
     * @param {"square"|"circle"|""} shape (can be empty for wikipim objects)
     * @param {string} icon_id
     * @param {number} width
     * @param {number} depth
     * @param {number} height
     * @param {string} contact
     * @param {number} defaultHeight
     * @param {string} color
     * @param {string} product_type
     * @param {string} product_category
     * @param {Object} parameters
     */
    set_custom_source(name,
                      shape,
                      icon_id,
                      width,
                      depth,
                      height,
                      contact,
                      defaultHeight,
                      color,
                      product_type,
                      product_category,
                      parameters) {
        if (this.can_update_shape() && (shape === 'square' || shape === 'circle')) {
            this.set_shape(shape);
        }
        this.set_object_icon_id(icon_id?.length ? icon_id : '');
        this.set_size(0, depth);
        this.set_size(1, width);
        this.set_size(2, height);
        if (this.can_update_contact()) {
            this.set_contact(contact);
        }
        this.set_default_height(defaultHeight);
        if (this.can_update_color()) {
            this.set_color(color);
        }
        if (product_type) {
            this.set_product_type(product_type);
            this.set_product_category(product_category);
            this.set_parameters(parameters);
        } else {
            this.set_product_type(CODE_BIM_INCONNU);
            this.set_product_category('Inconnue');
            this.set_parameters({});
        }
        this.name = name;
    }

    get_top_view_data() {
        this._init_default_source_if_empty();
        if (this.source.images) {
            const top_view = this.source.images.find(it => it.role === 'top_view');
            if (top_view && top_view.data) {
                if (top_view.data.indexOf('data:image') === 0) {
                    return top_view.data;
                } else {
                    return "data:image/jpeg;base64," + top_view.data;
                }
            }
        }
        return '';
    }

    /**
     * Sets BBO source
     * @param {object} bbo_object
     */
    set_bbo_source(bbo_object) {
        this.source = bbo_object;
        this.source.type = "wikipim";

        for (let k = 0; k < 3; k++)
            this.size[k] = bbo_object.size[k];

        const icon = bbo_object.images.find(it => it.role === 'icon');
        if (icon) {
            if (icon.data.indexOf('data:image') === 0) {
                this.icon_id = icon.data;
            } else {
                this.icon_id = "data:image/jpeg;base64," + icon.data;
            }
        }
    }

    /**
     * If source is empty, init with "square"
     */
    _init_default_source_if_empty() {
        if (typeof (this.source) != 'object' || typeof (this.source.type) != 'string')
            this.source = {type: "square"};
    }

    /**
     * Can size be reset : if source contains size & source size different from object size
     * @returns {boolean}
     */
    can_reset_size() {
        if (!this.source || !this.source.size || !this.source.size.length) {
            return false;
        }
        for (let k = 0; k < 3; k++) {
            // Converts in rounded cm for comparison
            if (Math.round(this.size[k] * 100) !== Math.round(this.source.size[k] * 100)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Can size be reset : if source contains icon & source icon different from object icon
     * @returns {boolean}
     */
    can_reset_icon() {
        if (!this.source || !this.source.images) {
            return false;
        }
        const sourceIcon = this.source.images.find(it => it.role === 'icon');
        if (!sourceIcon || !sourceIcon.data) {
            return false;
        }
        const sourceIconData = (sourceIcon.data.indexOf('data:image') === 0) ? sourceIcon.data : "data:image/jpeg;base64," + sourceIcon.data;
        return sourceIconData !== this.icon_id;
    }

    /**
     * Resets size from source
     */
    reset_size() {
        if (!this.source || !this.source.size || !this.source.size.length) {
            return;
        }
        for (let k = 0; k < 3; k++)
            this.size[k] = this.source.size[k];
    }

    /**
     * Resets icon from source
     */
    reset_icon() {
        const sourceIcon = this.source.images.find(it => it.role === 'icon');
        if (!sourceIcon || !sourceIcon.data) {
            return;
        }
        const sourceIconData = (sourceIcon.data.indexOf('data:image') === 0) ? sourceIcon.data : "data:image/jpeg;base64," + sourceIcon.data;
        this.icon_id = sourceIconData;
    }

    /**
     * @returns {boolean}
     */
    can_update_shape() {
        return !this.is_bbo_source();
    }

    /**
     * @returns {boolean}
     */
    can_update_contact() {
        return !this.is_bbo_source();
    }

    /**
     * @returns {boolean}
     */
    can_update_color() {
        return !this.is_bbo_source();
    }

    //***********************************************************************************
    /**
     * Sets shape (can be "square" or "circle")
     * Error is thrown if object has bbo source.
     * @param {'square' | 'circle'} shape
     */
    set_shape(shape) {
        const this_source_type = this.get_source_type();
        if (shape === this_source_type) return;
        /*if (this_source_type === "wikipim") {
            throw Error(`Impossible de mettre une forme générique sur un objet BBO`);
        } else */{
            this.source.type = shape;
        }

        this.update_geometry();
    }

    /**
     * Gets shape (can be "square" or "circle")
     * Returns empty if object has bbo source.
     * @returns {'square' | 'circle' | ''} shape
     */
    get_shape() {
        if (this.is_bbo_source()) {
            return '';
        }
        // @ts-ignore
        return this.get_source_type();
    }

    //***********************************************************************************
    set_object_icon_id(icon_id) {
        this.icon_id = icon_id;
    }


    //***********************************************************************************
    get_object_icon_id() {
        if (this.icon_id) {
            return this.icon_id;
        }
        return "";
    }

    //***********************************************************************************
    /**
     * Sets product type code bim
     * @param {string} product_type
     */
    set_product_type(product_type) {
        this._init_default_source_if_empty();
        this.source.product_type = product_type;
    }

    //***********************************************************************************
    /**
     * Returns product type code bim, or empty string if not defined.
     * @returns {string}
     */
    get_product_type() {
        this._init_default_source_if_empty();
        if (typeof (this.source.product_type) == 'string')
            return this.source.product_type;
        return "";
    }

    //***********************************************************************************
    /**
     * Sets product type name
     * @param {string} product_category
     */
    set_product_category(product_category) {
        this._init_default_source_if_empty();
        this.source.product_category = product_category;
    }

    //***********************************************************************************
    /**
     * Returns product type name, or empty string if not defined.
     * @returns {string}
     */
    get_product_category() {
        this._init_default_source_if_empty();
        if (this.source.product_category)
            return this.source.product_category;

        return "";
    }

    //***********************************************************************************
    /**
     * Sets parameters
     * @param {object} parameters
     */
    set_parameters(parameters) {
        this._init_default_source_if_empty();
        this.source.parameters = parameters;
    }

    //***********************************************************************************
    /**
     * Returns parameters, or empty object if not defined.
     * @returns {object}
     */
    get_parameters() {
        this._init_default_source_if_empty();
        if (typeof (this.source.parameters) == 'object')
            return this.source.parameters;
        return {};
    }

    //***********************************************************************************
    /**
     * Updates geometry for personalized geometry
     */
    update_geometry() {
        const source_type = this.get_source_type();
        if (source_type == "square" || source_type == "circle") {
            const solid = new fh_solid();
            const ori = [0,0, 0];
            const dx = [this.size[0], 0, 0];
            const dy = [0, this.size[1], 0];
            const dz = [0, 0, this.size[2]];
            if (source_type == "square")
                solid.brick(ori, dx, dy, dz);
            else
                solid.cylinder(ori, dx, dy, dz);

            const geometry = {};
            solid.compute_tesselation();
            geometry.vertices = solid.tesselation_vertices.flat();
            geometry.triangles = solid.tesselation_triangles.concat([]);
            if (typeof (this.source.color) == 'string')
                geometry.color = cn_color_hexa_to_rgb(this.source.color);
            else
                geometry.color = [0.8, 0.75, 0.7];
            this.source.geometries = [geometry];
        }
    }

    //***********************************************************************************
    //**** Build 3D solid geometry
    //***********************************************************************************
    build_solid() {
        const solid = new fh_solid();
        const ori = [0,0, 0];
        const dx = [this.size[0], 0, 0];
        const dy = [0, this.size[1], 0];
        const dz = [0, 0, this.size[2]];
        if (this.source.type == "square")
            solid.brick(ori, dx, dy, dz);
        else
            solid.cylinder(ori, dx, dy, dz);

        return solid;
    }

    /**
     * @param {boolean} build_default: builds default instaniation of not full.
     * @returns {object} returns first instanciation, or null.
     */
    get_instanciation(build_default = false) {
        if (typeof (this.source) != 'object' || this.source == null) {
            if (!build_default) return null;
            this.source = {type: "square"};
        }
        if (typeof (this.source.instanciations) != 'object') {
            if (!build_default) return null;
            this.source.instanciations = [];
        }
        if (typeof (this.source.instanciations[0]) != 'object') {
            if (!build_default) return null;
            this.source.instanciations.push({});
        }

        const inst = this.source.instanciations[0];
        if (!build_default) return inst;

        if (typeof (inst.anchor_point) != 'object') inst.anchor_point = [0, 0, 0];
        if (typeof (inst.anchor_normal) != 'object') inst.anchor_normal = [0, 0, -1];

        return inst;
    }

    /**
     * Returns adapted size geometries
     * @returns {Array<{color: Array<number>, vertices:Array<number>, triangles:Array<number>}>}
     */
    get_geometries() {
        const source_type = this.get_source_type();
        if (source_type == "square" || source_type == "circle")
        {
            this.update_geometry();
            return this.source.geometries;
        }
        const geometries = [];
        const ratio = this.get_size_ratio();
        this.source.geometries.forEach(geo => {
            geometries.push({color: geo.color, triangles: geo.triangles, vertices: geo.vertices.map((x,index) => x * ratio[index%3])});
        });
        return geometries;
    }

    //***********************************************************************************
    //**** Wall anchoring
    //***********************************************************************************
    /**
     * is object placed againts a wall ?
     * @returns {boolean}
     */
    place_on_wall() {
        if (typeof (this.source.instanciations) != 'object') return false;
        if (typeof (this.source.instanciations[0]) != 'object') return false;
        if (typeof (this.source.instanciations[0].anchor_normal) != 'object') return false;
        return (Math.abs(this.source.instanciations[0].anchor_normal[2]) < 0.5)
    }

    /**
     * Returns anchor 3D position
     * @returns {number[]}
     */
    get_anchor_position() {
        if (this.get_source_type() == 'wikipim')
        {
            const ratio = this.get_size_ratio();
            const a = this.source.instanciations[0].anchor_point;
            return [a[0]*ratio[0],a[1]*ratio[1],a[2]*ratio[2]];
        }
        return this.source.instanciations[0].anchor_point;
    }

    /**
     * Returns anchor 3D normal
     * @returns {number[]}
     */
    get_anchor_normal() {
        return this.source.instanciations[0].anchor_normal;
    }

    /**
     * Returns ratio between actual size and geometry size
     * @returns {Array<number>}
     */
    get_size_ratio() {
        if (this.get_source_type() == 'wikipim')
        {
            const gbox = this.compute_source_bounding_box();
            return  [this.size[0]/gbox.size[0],this.size[1]/gbox.size[1],this.size[2]/gbox.size[2]];
        }
        return [1,1,1];
    }

    //***********************************************************************************
    //**** Self placement matrix
    get_matrix() {
        const matrix = new fh_matrix();
        if (!this.source) return matrix;

        const box = this.compute_source_bounding_box();
        const ratio = this.get_size_ratio();

        let anchor_delta = box.position[2]*ratio[2];
        const inst = this.get_instanciation();
        if (inst && typeof (inst.anchor_point) == 'object')
            anchor_delta = inst.anchor_point[2]*ratio[2];

        matrix.load_translation([-box.position[0]*ratio[0] - box.size[0]*ratio[0] * 0.5, -box.position[1]*ratio[1] - box.size[1]*ratio[1] * 0.5, -anchor_delta]);
        return matrix;
    }

    /**
     * Accept element type visitor
     *
     * @param {cn_element_type_visitor} element_type_visitor
     */
    accept_visitor(element_type_visitor) {
        element_type_visitor.visit_object_type(this);
    }

    /**
     * Fill a 3D scene with an object
     * @param {fh_scene} scene
     */
    fill_3d_scene(scene) {
        const size_ratio = this.get_size_ratio();
        const source_with_geometries = {
            ID: this.ID,
            Code_BIM: this.source.product_type,
            instanciations: [this.get_instanciation()],
            geometries: this.get_geometries()
        };
        scene.load_bbo(source_with_geometries, size_ratio, true);
    }

    compute_source_bounding_box() {
        const box = new fh_box();
        this.source.geometries.forEach(geo =>  {
            for (let k = 0; k < geo.vertices.length; k += 3) {
                const p = [geo.vertices[k], geo.vertices[k + 1], geo.vertices[k + 2]];
                box.enlarge_point(p);
            }
        });
        return box;
    }
}


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent, ConfirmationService, NotificationService } from 'src/app/commons-lib';
import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TypeElementAControler } from '../../../../model/type-element-a-controler.model';
import { Bien, ElementAControler, Niveau, Volume } from '../../../../model/bien.model';
import {
    PARAM_TOOLTIP_NON_VISITEE,
    PARAM_TOOLTIP_PARTIELLEMENT_VISITEE,
    PARAM_TOOLTIP_VISITEE,
    PARAM_VOLUME_VISITE,
} from '../../../../shared/constants/cndiag.constants';
import { AddVolumeModalComponent } from '../add-volume-modal/add-volume-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Intervention } from '../../../../model/intervention.model';
import { DiagnosticService } from '../../../../services/diagnostic.service';
import { CnSpinnerService } from '../../../shared/cn-spinner/service/cn-spinner.service';
import { DiagnosticHandlerService } from '../../../../services/diagnostic-handler.service';
import { Diagnostic, EtatDiagnostic } from '../../../../model/diagnostic.model';
import { ListUtils } from '../../../../utils/list.utils';
import { InterventionService } from '../../../../services/intervention.service';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable, of, OperatorFunction } from 'rxjs';
import { EtatProgressionService } from '../../../../services/etat-progression.service';
import { notValidatedOption, validatedOption, warningOption } from '../../../../shared/constants/states.constants';
import {
    ModalTransfertUsageComponent,
    ModalTransfertUsageSelection,
    TransfertUsageModalData,
    TypeAssociation,
} from '../description-bien/modal-transfert-usage/modal-transfert-usage.component';
import { BienService } from '../../../../services/bien.service';
import { TypeAssainissementService } from '../../../diagnostics/assainissement/services/type-assainissement.service';
import { MergeBienService } from '../../../../services/merge-bien.service';

function also<T, O>(param: (result: T) => Observable<O>): OperatorFunction<T, [T, O]> {
    // @ts-ignore
    return switchMap((result: T) => param(result).pipe(map((it) => [result, it])));
}

@Component({
    selector: 'app-description-room-section',
    templateUrl: './description-room-section.component.html',
    styleUrls: ['./description-room-section.component.scss'],
})
export class DescriptionRoomSectionComponent extends BaseComponent implements OnInit {
    @Input() niveau: Niveau;

    @Input() readonlyMode = false;

    @Input() readonlyName = false;

    @Input() intervention: Intervention;

    @Input() diagnostic: Diagnostic;

    @Input() room: Volume;

    @Input() bien: Bien;

    @Input() displayHumidity = false;

    @Input() roomIndex = '';

    @Output() deleteThisRoom = new EventEmitter<any>();

    @Output() copyThisRoom = new EventEmitter<any>();

    @Output() comChange = new EventEmitter<any>();

    // Paramètres de visite
    readonly validatedChoiceOption = validatedOption;
    readonly notValidatedChoiceOption = notValidatedOption;
    readonly warningChoiceOption = warningOption;
    readonly paramVolumeVisite = PARAM_VOLUME_VISITE;

    tooltipsVisite = [PARAM_TOOLTIP_VISITEE, PARAM_TOOLTIP_PARTIELLEMENT_VISITEE, PARAM_TOOLTIP_NON_VISITEE];

    /**
     * Hauteur du header de l'accordeon
     */
    customCollapsedHeight = '60px';
    customExpandedHeight = '60px';

    constructor(
        private confirmationService: ConfirmationService,
        private interventionService: InterventionService,
        private diagnosticService: DiagnosticService,
        private cnSpinnerService: CnSpinnerService,
        private diagnosticHandlerService: DiagnosticHandlerService,
        private notificationService: NotificationService,
        private matDialog: MatDialog,
        private etatProgression: EtatProgressionService,
        private bienService: BienService,
        private mergeBienService: MergeBienService
    ) {
        super();
    }

    ngOnInit() {
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
        ])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(([intervention, diagnostic]) => {
                this.intervention = intervention;
                this.diagnostic = diagnostic;
            });

        this.etatProgression.etatProgressions$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((diagn) => {
            this.readonlyMode = this.diagnosticService.isReadOnlyMode(this.intervention, this.diagnostic);
        });
    }

    editRoom() {
        return this.matDialog
            .open(AddVolumeModalComponent, {
                data: {
                    niveau: this.niveau,
                    volumeToEdit: this.room,
                    readonlyName: this.readonlyName,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res) {
                    this.interventionService.updateIntervention(this.intervention).subscribe(
                        () => {
                            this.cnSpinnerService.hide();
                        },
                        () => this.cnSpinnerService.hide()
                    );
                }
            });
    }

    copyRoom() {
        this.copyThisRoom.emit();
    }

    deleteRoom() {
        this.deleteThisRoom.emit();
    }

    /**
     * Vérification des justifications de non visite, si nécessaire
     */
    currentVisiteChanged(visiteValue: string) {
        // mise à jour de la valeur du paramètre visité de la pièce dans la relation intervention/bien de l'intervention
        this.interventionService.updateRelationInterventionBien(
            this.intervention,
            this.bien.id,
            this.niveau.id,
            this.room.id,
            this.paramVolumeVisite,
            visiteValue
        );
    }

    /**
     * Suppression d'un équipement
     * Vérification si l'équipement a déjà été complété dans les points de contrôle d'un diagnostic
     */
    deleteEquipment(equipment: ElementAControler) {
        let msg = 'Êtes-vous sûr de vouloir supprimer cet équipement ?';
        let isAlreadyUsed = false;
        this.diagnosticService
            .getAllDiagnosticsForCurrentIntervention(this.intervention)
            .subscribe((diagnostics: Diagnostic[]) => {
                if (diagnostics) {
                    for (const diagnostic of diagnostics) {
                        const typePrestationService = this.diagnosticHandlerService.getTypePrestationService(
                            diagnostic.typePrestation
                        );
                        if (typePrestationService) {
                            isAlreadyUsed = typePrestationService.isItemAlreadyFilled(
                                diagnostic,
                                'element',
                                equipment.id
                            );
                        }
                        if (isAlreadyUsed) {
                            break;
                        }
                    }
                    if (isAlreadyUsed) {
                        msg =
                            '<div class="warn">Attention, cet équipement a déjà été renseigné dans un diagnostic.</div>Êtes-vous sûr de vouloir le supprimer ?';
                    }
                }
                this.confirmationService.confirmWarn(msg, () => {
                    const index = this.room.elementsAControler.indexOf(equipment);
                    if (index > -1) {
                        this.room.elementsAControler.splice(index, 1);
                    }
                    this.interventionService.updateIntervention(this.intervention).subscribe(
                        () => {
                            this.notificationService.success("L'équipement a bien été supprimé.");
                            this.cnSpinnerService.hide();
                        },
                        () => this.cnSpinnerService.hide()
                    );
                });
            });
    }

    onDrop(event: CdkDragDrop<TypeElementAControler[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            const nouvelElement = new ElementAControler();
            nouvelElement.nom = ListUtils.createUniqueNameElementAControler(
                this.room.elementsAControler,
                event.item.data
            );
            nouvelElement.codeBim = event.item.data.codeBim;
            nouvelElement.idTypeElementAControler = event.item.data.id;
            nouvelElement.valeursParametres = { ...event.item.data.valeursDefautParametres };
            nouvelElement.typesPrestationsAssocie = [...event.item.data.typesPrestationsAssocie];
            this.room.elementsAControler.push(nouvelElement);
        }
    }

    onlyElementPredicate(item: CdkDrag<TypeElementAControler>) {
        return !!item.data.codeBim;
    }

    mergeVolume(volume: Volume) {
        return this.matDialog
            .open<ModalTransfertUsageComponent, TransfertUsageModalData, ModalTransfertUsageSelection>(
                ModalTransfertUsageComponent,
                {
                    data: {
                        typeAssociation: TypeAssociation.VOLUME,
                        niveaux: this.bien.description,
                        sourceElement: volume,
                    },
                }
            )
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                switchMap((result) => {
                    if (!result.volume) {
                        return this.mergeBienService.transfertVolumeToNiveau(
                            this.bien,
                            volume,
                            result.niveau,
                            this.intervention
                        );
                    } else {
                        return this.mergeBienService.mergeVolumes(this.bien, volume, result.volume, this.intervention);
                    }
                })
            )
            .subscribe();
    }
}

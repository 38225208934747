import { Component, OnInit } from '@angular/core';
import { Intervention } from '../../../../../../model/intervention.model';
import { InterventionService } from '../../../../../../services/intervention.service';
import { Niveau } from '../../../../../../model/bien.model';
import { first } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { DiagnosticService } from '../../../../../../services/diagnostic.service';
import { Diagnostic } from '../../../../../../model/diagnostic.model';
import { ValeurCheckpoint } from '../../../model/electricite.model';

@Component({
    selector: 'app-resume',
    templateUrl: './resume.component.html',
    styleUrls: ['./resume.component.scss'],
})
export class ResumeComponent implements OnInit {
    readonly ITEMS = [
        { codeBim: 'piscine', libelle: 'Piscine(s)' },
        { codeBim: 'fontaine', libelle: 'Fontaine(s)' },
    ];
    public niveaux: Niveau[];
    public diagnostic: Diagnostic;
    constructor(protected diagnosticService: DiagnosticService, private interventionService: InterventionService) {}

    ngOnInit(): void {
        combineLatest([
            this.interventionService.getCurrentIntervention(),
            this.diagnosticService.getCurrentDiagnostic(),
        ])
            .pipe(first())
            .subscribe(([intervention, diagnostic]) => {
                this.diagnostic = diagnostic;
                const relationBienPrincipal = Intervention.getRelationInterventionBienPrincipal(intervention);
                this.niveaux = relationBienPrincipal.bien.description;
                this.initForComment(this.ITEMS[0].codeBim);
                this.initForComment(this.ITEMS[1].codeBim);
            });
    }

    public initForComment(codeBim: string) {
        if (!this.diagnostic.pointsDeControleBiens[0].valeursParametres[codeBim]) {
            this.diagnostic.pointsDeControleBiens[0].valeursParametres[codeBim] = new ValeurCheckpoint();
        }
    }
    public haslementsAControler(codeBim: string): boolean {
        try {
            for (const niveau of this.niveaux) {
                for (const volume of niveau.volumes) {
                    for (const element of volume.elementsAControler) {
                        if (element.codeBim == codeBim) {
                            return true;
                        }
                    }
                }
            }
        } catch (e) {}
        return false;
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//******     CN-Map    **************************************************************
//******     Copyright(C) 2019-2020 EnerBIM                        ******************
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//***********************************************************************************
//**** Roof slab
//***********************************************************************************
//***********************************************************************************

import {cn_element} from "./cn_element";
import {cn_pastille} from "../svg/cn_pastille";
import {
    cn_add,
    cn_box,
    cn_clone,
    cn_dist, cn_dot,
    cn_mul,
    cn_normal,
    cn_normalize,
    cn_polar,
    cn_sub,
	cn_simplify_contour,
	cnx_clone,
	cnx_dist
} from "../utils/cn_utilities";
import {cn_roof_contour} from "./cn_roof_contour";
import {fh_clone, fh_dot, fh_normalize, fh_polygon} from "@acenv/fh-3d-viewer";

var SUN_DIRECTION = null;
var SLOPE_ROTATION_RADIUS = 80;

export const ROOF_SLAB_EXTERIOR_LABEL = 'Extérieur';

export class cn_roof_slab extends cn_element {
	constructor() {
		super();
		//*** Constant data
		this.draw_priority = 0;

		//*** Modele data
		this.outside = false;
		this.contours = [];
		this.slope = 0;
		this.slope_direction = [1,0];
		this.normal = [0,0,1];
		this.plane = 0;

		//*** Volatile data
		this.area = 0;
		this.center = [0,0];
		this.slope_pastille = new cn_pastille([0,0],"0 °");
		this.slope_pastille.svg_class = "pastille_neutral";

		this.slab_type = null;

		this.heights = [];
		this.removable = false;
        this.name = "";
	}

	//***********************************************************************************
	//**** serialize
	//***********************************************************************************
	serialize() {
		var json = {};
		json.ID = this.ID;
		json.outside = this.outside;
		json.contours = [];
		for (var i in this.contours)
			json.contours.push(this.contours[i].serialize());
		json.slope = this.slope;
		json.slope_direction = cn_clone(this.slope_direction);
		json.normal = fh_clone(this.normal);
		json.plane = this.plane;
		json.slab_type = (this.slab_type)?this.slab_type.ID:"";
		return json;
	}

	static unserialize(json,scene) {
		if (typeof(json.contours) != 'object') return false;

		var roof_slab = new cn_roof_slab();

		if (typeof(json.ID) == 'string') roof_slab.ID = json.ID;

		if (typeof(json.outside) == 'boolean')
			roof_slab.outside = json.outside;

		for (var i in json.contours)
		{
			var contour = cn_roof_contour.unserialize(json.contours[i],scene);
			roof_slab.add_contour(contour);
		}

		if (typeof(json.slope) == 'number')
			roof_slab.slope = json.slope;

		if (typeof(json.slope_direction) == 'object')
			roof_slab.slope_direction = cn_clone(json.slope_direction)

		if (typeof(json.normal) == 'object')
			roof_slab.normal = fh_clone(json.normal);

		if (typeof(json.plane) == 'number')
			roof_slab.plane = json.plane;

		if (!roof_slab.outside)
		{
			if (typeof(json.slab_type) == 'string')
				roof_slab.slab_type = scene.building.get_element_type(json.slab_type);
			if (roof_slab.slab_type == null)
			{
				console.log("use default roof type");
				roof_slab.slab_type = scene.building.get_roof_types()[0];
			}
		}

		scene.slabs.push(roof_slab);
		return roof_slab;
	}

	//***********************************************************************************
	//**** update geometry
	//***********************************************************************************
	update() {
		this.area = 0;
		for (var i in this.contours)
		{
			 this.contours[i].update();
			 if (this.contours[i].clockwise)
				 this.area += this.contours[i].area;
			 else
				 this.area -= this.contours[i].area;
		}
		this.area = Math.abs(this.area);

		this.center = this.find_best_center();

		//*** Compute normal
		var angle = Math.PI * this.slope / 180;
		var cosangle = Math.cos(angle);
		var sinangle = Math.sin(angle);
		this.normal[0] = sinangle * this.slope_direction[0];
		this.normal[1] = sinangle * this.slope_direction[1];
		this.normal[2] = cosangle;
	}

	update_deep() {

	}

	//***********************************************************************************
	//**** Initialize slope
	//***********************************************************************************
	initialize_slope() {
		var largest_length = 0;
		var largest_line = null;
		for (var i in this.contours)
		{
			var contour = this.contours[i];
			for (var j in contour.lines)
			{
				var line = contour.lines[j];
				if (line.slabs[0] && line.slabs[1]) continue;
				if (line.bounds.length <= largest_length) continue;
				largest_length = line.bounds.length;
				largest_line = line;
			}
		}
		if (largest_line == null) return;
		if (largest_line.slabs[0] == this)
			this.slope_direction = cn_clone(largest_line.bounds.normal);
		else
			this.slope_direction = cn_mul(largest_line.bounds.normal,-1);
	}

	//***********************************************************************************
	//**** Draw the roof_slab in svg
	//***********************************************************************************
	draw(camera, add_classes, fill = '', draw_pattern = false) {
		var html = "";
		if (this.outside) return html;

		var path = "d='";
		for (var i in this.contours)
		{
			var contour = this.contours[i];
			for (var j=0;j<contour.vertices.length;j++)
			{
				if (j == 0) path += "M ";
				else if (j == 1) path += "L ";
				var p = camera.world_to_screen(contour.vertices[j].position);
				path += "" + p[0] + " " + p[1] + " ";
			}
			path += "Z ";
		}
		path += "'";

		if (draw_pattern)
			html += "<path " + path + " fill='url(#" + this.slab_type.ID + ")' />";

		var draw_class = "roof_slab";
		if (add_classes)
			draw_class += " " + add_classes.join(" ");

		html += "<path class='" + draw_class + "' " + fill + " " +  path + " fill-rule='evenodd' ";
		const col = this.get_color();
		html += `fill="rgba(${col[0]},${col[1]},${col[2]},${col[3]})"`;
		html += "/>";

		return html;
	}

	get_color()
	{
		if (Math.abs(this.slope) < 0.1)
			return [200,200,200,0.3];

		if (SUN_DIRECTION == null)
		{
			SUN_DIRECTION = [0.2,0.3,1];
			fh_normalize(SUN_DIRECTION);
		}
		var ps = fh_dot(SUN_DIRECTION,this.normal);
		if (ps < 0) ps = 0;
		return [255*ps,150*ps,150*ps,0.3];
	}

	//***********************************************************************************
	//**** Draw the slope
	//***********************************************************************************
	draw_slope(camera, selected) {
		var html = "";

		var center = camera.world_to_screen(this.center);

		//*** Draw alignment
		if (selected)
		{
			var direction = this.slope_direction;
			for (var i in this.contours)
			{
				var contour = this.contours[i];
				for (var j in contour.lines)
				{
					var line = contour.lines[j];
					if (Math.abs(cn_dot(line.bounds.direction,direction)) > 0.001) continue;
					var v0 = camera.world_to_screen(line.vertices[0].position);
					var v1 = camera.world_to_screen(line.vertices[1].position);
					html += "<line class='roof_slope_rotation' x1='" + v0[0] + "' y1='" + v0[1] + "' x2='" + v1[0] + "' y2='" + v1[1] + "' />";
				}
			}
		}

		//*** Draw slope direction
		if (!camera.is_3d() && Math.abs(this.slope) >= 1)
		{
			var slope_polar = cn_polar(this.slope_direction);
			var angle = - slope_polar[1] * 180 / Math.PI;
			html += "<g ";
			html += "transform='translate(" + center[0] + "," + center[1] + ") rotate(" + angle + ") '>";

			var arrow_width = 5;
			var arrow_length_x = 20 * Math.cos(Math.PI/6);
			var arrow_length_y = 20 * Math.sin(Math.PI/6);

			html += "<path class='roof_slope_direction";
			html += " neutral";
			html += "' d='";
			html += "M 0 " + arrow_width + " ";
			html += "L " + (SLOPE_ROTATION_RADIUS - arrow_length_x) + " " + arrow_width + " ";
			html += "" + (SLOPE_ROTATION_RADIUS - arrow_length_x) + " " + arrow_length_y + " ";
			html += "" + SLOPE_ROTATION_RADIUS + " 0 ";
			html += "" + (SLOPE_ROTATION_RADIUS - arrow_length_x) + " -" + arrow_length_y + " ";
			html += "" + (SLOPE_ROTATION_RADIUS - arrow_length_x) + " -" + arrow_width + " ";
			html += "0 -" + arrow_width + " ";
			html += "Z' />";
			html += "</g>";
		}

		//*** Draw slope value
		this.slope_pastille.label = this.slope.toFixed(0) + " °";
		this.slope_pastille.position = cnx_clone(this.center);
		if (camera.is_3d())
		{
			this.slope_pastille.position[2] = this.compute_height(this.center);
			if (!camera.check_visibility(this.slope_pastille.position))
				return html;
		}

		html += this.slope_pastille.draw(camera);
		return html;
	}

	//***********************************************************************************
	//**** Contains
	//***********************************************************************************
	contains(p, inner=false) {
		var inside = 0;
		for (var i in this.contours)
		{
			if (!this.contours[i].contains(p,inner)) continue;
			if (this.contours[i].clockwise)
				inside++;
			else
				inside--;
		}
		return (inside > 0);
	}

	//***********************************************************************************
	//**** Contains
	//***********************************************************************************
	contained_by_box(box) {
		for (var i in this.contours)
		{
			if (!this.contours[i].contained_by_box(box))
				return false;
		}
		return true;
	}

	//***********************************************************************************
	//**** get box
	//***********************************************************************************
	get_bounding_box() {
		var box = new cn_box();
		for (var i in this.contours)
			box.enlarge_box(this.contours[i].get_bounding_box());
		return box;
	}

	//***********************************************************************************
	//**** Removes a contour
	//***********************************************************************************
	remove_contour(contour) {
		var index = this.contours.indexOf(contour);
		if (index < 0) return;
		this.contours.splice(index,1);
		contour.slab = null;
	}

	//***********************************************************************************
	//**** Adds a contour
	//***********************************************************************************
	add_contour(contour) {
		contour.slab = this;
		for (var i=0; i<contour.lines.length;i++)
		{
			var side = (contour.line_orientations[i])?0:1;

			contour.lines[i].slabs[side] = this;
			var ct = contour.lines[i].contours[side];
			if (ct && ct.slab && ct != contour)
				ct.slab.remove_contour(ct);
			contour.lines[i].contours[side] = contour;
		}
		this.contours.push(contour);
	}

	//***********************************************************************************
	//**** Build a 3D polygon that forms the slab of the roof_slab
	//***********************************************************************************
	build_slab_polygon(z) {
		var polygon = new fh_polygon([0,0,z],[0,0,1]);
		for (var i in this.contours)
		{
			polygon.add_contour(this.contours[i].build_3d_contour(z));
		}

		return polygon;
	}

	//***********************************************************************************
	//**** Build a polygon
	//***********************************************************************************
	build_3d_polygon(z, build_overhang = false) {
		var pz = z + this.compute_height([0,0]);
		var polygon = new fh_polygon([0,0,pz],this.normal);
		for (var nct=0;nct<this.contours.length;nct++)
		{
			var contour_3d = [];
			var contour = this.contours[nct];
			for (var i=0;i<contour.vertices.length;i++)
			{
				var vtx = contour.vertices[i].position;
				contour_3d.push([vtx[0],vtx[1],z + this.compute_height(vtx)]);
			}
			polygon.add_contour(cn_simplify_contour(contour_3d));
		}

		if (build_overhang)
		{
			this.contours.forEach(ctr => {
				ctr.lines.forEach(line => {
					const pg = line.build_overhang_polygon(z);
					if (pg) polygon.unites(pg);
				});
			});
		}
		return polygon;
	}

	//***********************************************************************************
	//**** raytrace
	//***********************************************************************************
	raytrace(origin, direction, max_distance, inner) {
		var res = null;
		var max_d = max_distance;
		for (var i in this.contours)
		{
			var new_res = this.contours[i].raytrace(origin, direction, max_d, inner);
			if (new_res == null) continue;
			res = new_res;
			max_d = res.distance;
		}
		return res;
	}

    //***********************************************************************************
    //**** find best space center
    //***********************************************************************************
    find_best_center() {
        var display_res = false;

        var box = this.get_bounding_box();

        //*** first check the center */
        var c = cn_add(box.posmin,cn_mul(box.size,0.5));
        if (this.contains(c, true))
        {
            return c;
        }

        //*** We make a grid on bounding box of approx 1000 samples.
        var area = box.size[0] * box.size[1];
        if (area <= 0) return [0, 0];
        var a = Math.sqrt(area / 1000);

        box.posmin = cn_sub(box.posmin, [2 * a, 2 * a]);
        box.size = cn_add(box.size, [4 * a, 4 * a]);

        var nx = 1 + Math.round(box.size[0] / a);
        var ny = 1 + Math.round(box.size[1] / a);
        var grid = new Array(nx * ny);
        grid.fill(false);

        //*** Draw contours on that grid
        for (var ctr in this.contours) {
            var contour = this.contours[ctr];
            var p0 = contour.vertices[contour.vertices.length - 1].position;
            for (var v = 0; v < contour.vertices.length; v++) {
                var p1 = contour.vertices[v].position;
                if (p1[1] == p0[1]) {
                    p0 = p1;
                    continue;
                }
                var pp0, pp1, sense;
                if (p1[1] > p0[1]) {
                    pp0 = p0;
                    pp1 = p1;
                    sense = true;
                }
                else {
                    pp0 = p1;
                    pp1 = p0;
                    sense = false;
                }

                var j0 = Math.ceil((pp0[1] - box.posmin[1]) / a);
                var j1 = Math.floor((pp1[1] - box.posmin[1]) / a);
                var coef = (pp1[0] - pp0[0]) / (pp1[1] - pp0[1]);
                for (var j = j0; j <= j1; j++) {
                    var y = box.posmin[1] + a * j;
                    var x = pp0[0] + (y - pp0[1]) * coef;
                    var i0 = Math.floor((x - box.posmin[0]) / a);
                    if (!sense) i0++;
                    if (i0 >= 0 && i0 < nx)
                        grid[i0 + j * nx] = !grid[i0 + j * nx];
                }
                p0 = p1;
            }
        }

        function console_filter() {
            for (var j = 0; j < ny; j++) {
                var xx = "";
                for (var i = 0; i < nx; i++)
                    xx += (grid[i + j * nx]) ? "#" : " ";
                xx += " " + j;
                console.log(xx);
            }
        }

        if (display_res)
            console_filter();

        //*** Fill using even odd rule
        for (var j = 0; j < ny; j++) {
            var cnt = 0;
            for (var i = 0; i < nx; i++)
                if (grid[i + j * nx]) cnt++;
            if (cnt & 1) continue;

            var val = false;
            for (var i = 0; i < nx; i++) {
                if (grid[i + j * nx])
                    val = !val;
                else
                    grid[i + j * nx] = val;
            }
        }

        if (display_res)
            console_filter();

        //*** Apply median filter until all image is blank
        var sz = 3;
        var threshold = 9;
        var imax = Math.floor(nx / 2);
        var jmax = Math.floor(ny / 2);
        for (var niter = 0; niter < 30; niter++) {
            if (display_res)
                console.log("Iteration  " + niter + " seuil " + threshold);
            var ngrid = new Array(nx * ny);
            ngrid.fill(false);
            var ok = false;
            for (var j = 1; j < ny - 1; j++) {
                for (var i = 1; i < nx - 1; i++) {
                    var n = 0;
                    for (var ki = 0; ki < sz; ki++) {
                        for (var kj = 0; kj < sz; kj++)
                            if (grid[i - 1 + ki + (j - 1 + kj) * nx]) n++;
                    }
                    if (n < threshold) continue;

                    ngrid[i + j * nx] = true;
                    ok = true;
                    imax = i;
                    jmax = j;
                }
            }

            //*** If image is blank, we first try to reduce threshold
            if (!ok) {
                threshold--;
                if (threshold < 5) break;
                continue;
            }

            grid = ngrid;
            if (display_res)
                console_filter();
        }

        if (display_res)
            console_filter();

        return [box.posmin[0] + a * imax, box.posmin[1] + a * jmax];
    }

	//***********************************************************************************
	//**** find closest distance
	//***********************************************************************************
	find_closest_element(position)
	{
		var best_distance = 10000;
		var best_element = null;
		for (var kct in this.contours)
		{
			var contour = this.contours[kct];
			var cl = contour.vertices.length;
			for (var i=0; i<cl;i++)
			{
				var dst0 = cn_dist(position,contour.vertices[i].position);
				if (dst0 < best_distance)
				{
					best_distance = dst0;
					best_element = contour.vertices[i];
				}
			}

			cl = contour.lines.length;
			for (var i=0; i<cl;i++)
			{
				var p0 = contour.lines[i].vertices[0].position;
				var p1 = contour.lines[i].vertices[1].position;
				var dir = cn_sub(p1,p0);
				var length = cn_normalize(dir);
				if (length < 0.01) continue;
				var nor = cn_normal(dir);
				var dst = Math.abs(cn_dot(nor,cn_sub(position,p0)));
				if (dst >= best_distance) continue;
				var x = cn_dot(dir,cn_sub(position,p0));
				if (x<0.25 * length) continue;
				if (x > 0.75 * length) continue;
				best_distance = dst;
				best_element = contour.lines[i];
			}
		}
		return best_element;
	}

	//***********************************************************************************
	//**** Returns all vertices of the slab
	//***********************************************************************************
	get_all_vertices() {
		var vv = [];
		for (var i in this.contours)
			vv = vv.concat(this.contours[i].vertices);
		return vv;
	}

	//***********************************************************************************
	//**** Returns all lines of the slab
	//***********************************************************************************
	get_all_lines() {
		var vv = [];
		for (var i in this.contours)
			vv = vv.concat(this.contours[i].lines);
		return vv;
	}

	//***********************************************************************************
	/**
	 * Retruns all segments of the slab
	 * @returns {object[]}
	 */
	get_segments() {
		var vv = [];
		for (var i in this.contours)
		{
			for (var j in this.contours[i].lines)
			{
				var line = this.contours[i].lines[j];
				vv.push([cn_clone(line.vertices[0].position),cn_clone(line.vertices[1].position)]);
			}
		}
		return vv;
	}
	//***********************************************************************************
	//**** Compute height of a given point on the slab
	//***********************************************************************************
	compute_height(p) {
		return (this.plane - p[0]*this.normal[0] - p[1]*this.normal[1]) / this.normal[2];
	}

}


export class StringUtils {
    static accents = new RegExp('[\u0300-\u036f]', 'g');

    /**
     * Enlève les accents et met en minuscule.
     * @param value
     */
    static normalizeLowerCase(value: string): string {
        return value.normalize('NFD').replace(StringUtils.accents, '').toLocaleLowerCase();
    }

    /**
     * Renvoie true si la/les chaînes de caractères en entrée contiennent la chaîne à rechercher, en normalisant et en enlevant les accents.
     * @param needle aiguille
     * @param haystack botte de foin
     */
    static filterNormalizeLowerCase(needle: string, haystack: string | string[]): boolean {
        const haystacks: string[] = Array.isArray(haystack) ? haystack : [haystack];
        return haystacks
            .filter((it) => it != null)
            .some((it) => StringUtils.normalizeLowerCase(it).includes(StringUtils.normalizeLowerCase(needle)));
    }

    /**
     * Génère une chaîne alphanumérique aléatoire (a-zA-Z0-9) de longueur demandée.
     */
    static randomAlphaNumeric(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}

"use strict";
//***********************************************************************************
//***********************************************************************************
//**** cn_param  : parameters for svg configurator
//***********************************************************************************
//***********************************************************************************

//***********************************************************************************
//**** Base abstract class
//***********************************************************************************
export class cn_svg_param {
	constructor(code, label) {
		this.code = code;
		this.label = label;
	}
}

//***********************************************************************************
//**** Param group
//***********************************************************************************
export class cn_svg_param_group extends cn_svg_param {
	constructor(code, label) {
		super(code,label);
		this.children = [];
	}
}

//***********************************************************************************
//**** Boolean param
//***********************************************************************************
export class cn_svg_param_boolean extends cn_svg_param {
	constructor(code, label, value=false) {
		super(code,label);
		this.children = [];
		this.value = value;
	}

	to_string(value = "") {
		var v = (value === "")?this.value:value;
		if (v) return "Oui";
		return "Non";
	}
}

//***********************************************************************************
//**** enum param. Value is an index in the codes / labels array.
//***********************************************************************************
export class cn_svg_param_enum extends cn_svg_param {
	constructor(code, label, value=0, codes = [], labels = []) {
		super(code,label);
		this.value = value;
		this.codes = codes;
		this.labels = labels;
	}

	to_string(value = false) {
		var v = (value === false)?this.value:value;
		if (v < 0 || v >= this.labels.length)
			return 'indéfini';
		// @ts-ignore
		return this.labels[v];
	}
}

//***********************************************************************************
//**** number param
//***********************************************************************************
export class cn_svg_param_number extends cn_svg_param {
	constructor(code, label, value=0, min_value=0, max_value=100, decimals = 0, unit="") {
		super(code,label);
		this.value = value;
		this.min_value = min_value;
		this.max_value = max_value;
		this.decimals = decimals;
		this.unit = unit;
	}

	to_string(value = false) {
		var v = (value === false)?this.value:value;
		// @ts-ignore
		var txt = v.toFixed(this.decimals);
		if (this.unit != "")
			txt += " " + this.unit;
		return txt;
	}
}

//***********************************************************************************
//**** array param. Multi values selection
//***********************************************************************************
export class cn_svg_param_array extends cn_svg_param {
	constructor(code, label, value) {
		super(code,label);
		this.value = value;
	}

    addValue(value) {
        this.value.push(value);
    }

    removeValue(value) {
        const index = this.value.indexOf(value);
        if (index >= 0) {
            this.value.splice(index, 1);
        }
    }

}

//***********************************************************************************
//**** object param. Specifications object
//***********************************************************************************
export class cn_svg_param_object extends cn_svg_param {
	constructor(code, label, value) {
		super(code,label);
		this.value = value || {};
	}

}

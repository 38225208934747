import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MpcaEchantillonnage, MpcaGeneralOld } from '../../../../../model/mpca.model.old';

@Component({
    selector: 'app-mpca-form-echantillonnage',
    templateUrl: './mpca-form-echantillonnage.component.html',
    styleUrls: ['./mpca-form-echantillonnage.component.scss'],
})
export class MpcaFormEchantillonnageComponent implements OnInit {
    @Output()
    generalDataChange = new EventEmitter<MpcaGeneralOld>();

    @Input()
    formEchantillonnage: FormGroup | AbstractControl;

    computedForm: FormGroup;
    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.initForm();
        let computedValues = calculateValues(
            (this.formEchantillonnage as FormGroup).getRawValue() as MpcaEchantillonnage
        );
        this.computedForm.patchValue(computedValues);

        this.formEchantillonnage.valueChanges.subscribe((changedValue) => {
            let computedValues = calculateValues(
                (this.formEchantillonnage as FormGroup).getRawValue() as MpcaEchantillonnage
            );
            this.computedForm.patchValue(computedValues);
            this.generalDataChange.emit();
        });
    }

    private initForm() {
        this.computedForm = this.formBuilder.group({
            tMin: [{ value: '', disabled: true }],
            tMin100: [{ value: '', disabled: true }],
            tMinRetenu: [{ value: '', disabled: true }],
            objMesures: [{ value: '', disabled: true }],
            tSatA: [{ value: '', disabled: true }],
            tSatARetenu: [{ value: '', disabled: true }],
            typeCas: [{ value: '', disabled: true }],
            schemaPrelevement: [{ value: '', disabled: true }],
            debitVolumePreleve: [{ value: '', disabled: true }],
            schemaAnalyse: [{ value: '', disabled: true }],
        });
    }
}

/**
 * @description Compute the values of the form and output the result
 * @param formData
 */
export function calculateValues(formData: MpcaEchantillonnage): any {
    let computedValues = {
        tMin: undefined, //Number
        tMin100: undefined, //Number
        tMinRetenu: undefined, //Number
        objMesures: undefined, //String
        tSatA: undefined, //Number
        tSatARetenu: undefined,
        typeCas: undefined,
        schemaPrelevement: undefined,
        debitVolumePreleve: undefined,
        schemaAnalyse: undefined,
    };

    //Tmin(SA=1)
    if (formData.debitPrelevement == undefined) {
        computedValues.tMin = undefined;
    } else {
        computedValues.tMin = 1 / (0.0015 * formData.debitPrelevement);
    }

    //Tmin (comptage 100 fibres)
    if (formData.concentrationAttendue == undefined) {
        computedValues.tMin100 = undefined;
    } else {
        computedValues.tMin100 = 22000 / formData.concentrationAttendue;
    }

    //Tmin retenu
    if (computedValues.tMin100 == undefined) {
        computedValues.tMinRetenu = undefined;
    } else {
        if (computedValues.tMin == undefined) {
            computedValues.tMinRetenu = computedValues.tMin100;
        } else if (computedValues.tMin100 == undefined) {
            computedValues.tMinRetenu = computedValues.tMin;
        } else {
            computedValues.tMinRetenu = Math.min(computedValues.tMin, computedValues.tMin100);
        }
    }

    //Objectif de mesure
    if (computedValues.tMin100 == undefined) {
        computedValues.objMesures = '';
    } else {
        if (computedValues.tMin < computedValues.tMin100) {
            computedValues.objMesures = 'SA=1';
        } else {
            computedValues.objMesures = 'Comptage de 100 fibres';
        }
    }

    //TsatA
    if (
        formData.concentrationAttendue == undefined ||
        formData.debitPrelevement == undefined ||
        formData.fractionFiltre == ''
    ) {
        computedValues.tSatA = undefined;
    } else {
        computedValues.tSatA =
            (7000 * 250) /
            (formData.concentrationAttendue * formData.debitPrelevement * parseFloat(formData.fractionFiltre));
    }

    //TsatRetenu
    if (computedValues.tSatA == undefined) {
        computedValues.tSatARetenu = undefined;
    } else {
        if (formData.dureeSaturationPoussieres == undefined) {
            computedValues.tSatARetenu = computedValues.tSatA;
        } else {
            computedValues.tSatARetenu = Math.min(formData.dureeSaturationPoussieres, computedValues.tSatA);
        }
    }

    debugger;
    //Type de cas retenu
    //=IF(OR(D14="";D15="";D16="";D17="";D18="");"";
    //IF(AND(D15>D22;D15<D25);"Cas1";
    //IF(AND(D15>D22;D15>=D25);"Cas2";
    //IF(AND(D15<D22;D22<D25);"Cas3";
    //IF(AND(D15<D22;D22>D25;D25>D15);"Cas4";
    //IF(AND(D15<D22;D22>D25;D25<=D15);"Cas5";"Aucun cas identifié"))))))
    let nbPrelevements, dureePrelevements, volumePreleve;
    if (
        formData.concentrationAttendue == undefined ||
        formData.dureeRepresentativeProcessus == undefined ||
        formData.dureeSaturationPoussieres == undefined ||
        formData.debitPrelevement == undefined ||
        formData.fractionFiltre == ''
    ) {
        computedValues.typeCas = '';
    } else if (
        formData.dureeRepresentativeProcessus > computedValues.tMinRetenu &&
        formData.dureeRepresentativeProcessus < computedValues.tSatARetenu
    ) {
        computedValues.typeCas = 'Cas 1';
        nbPrelevements = 1;
        dureePrelevements = formData.dureeRepresentativeProcessus / nbPrelevements;
        computedValues.schemaAnalyse = 'Analyse du filtre';
        volumePreleve = nbPrelevements * dureePrelevements * formData.debitPrelevement;
    } else if (
        formData.dureeRepresentativeProcessus > computedValues.tMinRetenu &&
        formData.dureeRepresentativeProcessus >= computedValues.tSatARetenu
    ) {
        computedValues.typeCas = 'Cas 2';
        nbPrelevements = 2;
        dureePrelevements = formData.dureeRepresentativeProcessus / nbPrelevements;
        volumePreleve = nbPrelevements * dureePrelevements * formData.debitPrelevement;
        computedValues.schemaAnalyse = 'Analyse séparée de chaque filtre';
    } else if (
        formData.dureeRepresentativeProcessus < computedValues.tMinRetenu &&
        computedValues.tMinRetenu < computedValues.tSatARetenu
    ) {
        computedValues.typeCas = 'Cas 3';
        nbPrelevements = 1;
        dureePrelevements = formData.dureeRepresentativeProcessus;
        volumePreleve = nbPrelevements * dureePrelevements * formData.debitPrelevement;
        computedValues.schemaAnalyse = 'Analyse conjointe de fractions identiques des filtres';
    } else if (
        formData.dureeRepresentativeProcessus < computedValues.tMinRetenu &&
        computedValues.tMinRetenu > computedValues.tSatARetenu &&
        computedValues.tSatARetenu > formData.dureeRepresentativeProcessus
    ) {
        computedValues.typeCas = 'Cas 4';
        nbPrelevements = 1;
        dureePrelevements = formData.dureeRepresentativeProcessus;
        volumePreleve = nbPrelevements * dureePrelevements * formData.debitPrelevement;
        computedValues.schemaAnalyse = 'Analyse des filtres séparément ou par sous-groupes';
    } else if (
        formData.dureeRepresentativeProcessus < computedValues.tMinRetenu &&
        computedValues.tMinRetenu > computedValues.tSatARetenu &&
        computedValues.tSatARetenu <= formData.dureeRepresentativeProcessus
    ) {
        computedValues.typeCas = 'Cas 5';
        nbPrelevements = 1;
        dureePrelevements = computedValues.tSatARetenu;
        volumePreleve = nbPrelevements * dureePrelevements * formData.debitPrelevement;
        computedValues.schemaAnalyse = 'Analyse séparée des filtres';
    } else {
        computedValues.typeCas = 'Aucun cas identifié';
    }

    if (nbPrelevements != undefined && dureePrelevements != undefined)
        computedValues.schemaPrelevement = `${nbPrelevements} prélèvement de ${dureePrelevements} minutes`;

    if (volumePreleve != undefined)
        computedValues.debitVolumePreleve = `Volume prélevé : ${volumePreleve}L, avec un débit de ${formData.debitPrelevement}L/min`;

    return computedValues;
}
